<template>
  <div class="card" :class="{ 'card--current': isCurrent }">
    <a-tag v-if="isCurrent" class="card__tag" color="#108ee9">Estás aquí</a-tag>
    <div class="card__body">
      <!--Nombre y acciones-->
      <a-row type="flex" class="mrg-bottom-8">
        <avatar-company
          class="card__avatar mrg-right-8"
          :color="dataSource.color"
          :url="dataSource.avatar"
          size="small"
        />
        <p class="regular-14 mrg-bottom-0">{{ dataSource.name }}</p>
        <div style="flex: 1 1 0%"></div>
        <!--Eliminar-->
        <a-tooltip placement="bottom">
          <span v-if="exist_only_one_company" slot="title">
            Debes contar mínimo con una subcuenta creada
          </span>
          <a
            class="card__icon--delete mrg-right-12"
            :class="{ 'icon--disabled': exist_only_one_company || isCurrent }"
            @click="handleDelete"
          >
            <a-icon type="delete" />
          </a>
        </a-tooltip>
        <!--Editar-->
        <a @click="handleUpdate">
          <a-icon type="edit" />
        </a>
      </a-row>
      <p class="regular-14 mrg-bottom-8 card__description">
        <ever-icon>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm-8.934 9.907c.291.373 1.102.851 2.342 1.24.489.154 1.024.289 1.598.403A27.195 27.195 0 017 12c0-3.328.606-6.333 1.655-8.358a9.01 9.01 0 00-5.59 7.265zm4.063 3.704c-1.606-.28-2.983-.702-4.026-1.245a9.012 9.012 0 005.552 6.992c-.766-1.479-1.296-3.48-1.526-5.747zm2.05.266c.897.081 1.842.123 2.821.123.963 0 1.912-.055 2.825-.156C14.375 18.442 13.127 21 12 21c-1.123 0-2.367-2.543-2.821-6.123zm5.807-2.072c-.95.123-1.958.195-2.986.195a28.82 28.82 0 01-2.985-.15A23.58 23.58 0 019 12c0-5.016 1.594-9 3-9s3 3.984 3 9c0 .271-.005.54-.014.805zm1.893 1.722c-.225 2.302-.758 4.334-1.534 5.83a9.01 9.01 0 005.576-7.16c-1.06.55-2.456 1.012-4.042 1.33zm4.05-3.667c-.471.528-1.955 1.156-3.933 1.594.003-.15.004-.302.004-.454 0-3.328-.606-6.333-1.655-8.358a9.01 9.01 0 015.583 7.218z" fill="currentColor"/></svg>
        </ever-icon>
        País: {{ dataSource.country.name }}
      </p>
      <p class="regular-14 mrg-bottom-0 card__description">
        <ever-icon>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm5-10h-4V6h-2v7h6v-2z" fill="currentColor"/></svg>
        </ever-icon>
        Tipo de operación:
        <span v-if="operationsCompany.length === 1">{{
          operationsCompany[0].value
        }}</span>
        <a-popover v-else title="Tipos de operación" placement="topLeft">
          <template slot="content">
            <ul class="card__list">
              <li
                v-for="operationCompany in operationsCompany"
                :key="operationCompany.key"
              >
                {{ operationCompany.value }}
              </li>
            </ul>
          </template>

          <a>Ver todos</a>
        </a-popover>
      </p>
    </div>
    <div class="card__footer">
      <a-button
        :disabled="exist_only_one_company || isCurrent"
        @click="handleChangeCompany"
      >
        Cambiar a esta subcuenta
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AvatarCompany from '@/app/shared/components/avatars/AvatarCompany'
import EverIcon from '@/app/shared/components/icons/EverIcon'
import companiesMixin from '@/app/companies/mixins/companiesMixin'

export default {
  name: 'CardCompany',
  props: {
    dataSource: {
      type: Object,
    },
  },
  mixins: [companiesMixin],
  components: {
    AvatarCompany,
    EverIcon,
  },
  computed: {
    ...mapGetters(['company', 'operations', 'exist_only_one_company']),

    /**
     * @return {Boolean} - verifica si es la empresa actual
     */
    isCurrent() {
      return (
        this.company &&
        this.dataSource &&
        this.company._id === this.dataSource._id
      )
    },
    /**
     * Operaciones de la empresa
     * @return {Object[]} operations
     * @return {String} operations[].key
     */
    operationsCompany() {
      if (!this.dataSource) return []
      const operations = this.operations.filter((operation) =>
        this.dataSource.operation.includes(operation.key)
      )
      return operations
    },
  },
  methods: {
    /**
     * Emite el evento de eliminar
     */
    handleDelete() {
      // TODO: Modificar esto para que no se pueda eliminar solo cuando quede uno
      if (this.exist_only_one_company || this.isCurrent) return
      this.$emit('onDelete', {
        data: this.dataSource,
        isCurrent: this.isCurrent,
      })
    },
    /**
     * Emite el evento de actualizar empresa
     */
    handleUpdate() {
      this.$emit('onUpdate', this.dataSource)
    },
    /**
     * Emite el evento de cambio de empresa
     */
    handleChangeCompany() {
      this.$emit('onChange', this.dataSource)
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  background-color: $white_000
  border: 1px solid $gray_dark_000
  border-radius: 4px
  width: 100%
  position: relative
  &--current
    border: 1px solid $blue_6
  &__body
    padding: 20px 20px 16px 20px
    border-bottom: 1px solid $gray_5
    text-align: left
  &__tag
    display: block
    position: absolute
    margin-right: 0
    left: 50%;
    transform: translate(-50%, -50%);
  &__icon--delete
    color: $red_6
  &__description
    color: $gray_7
  &__footer
    padding: 8px 20px
  &__list
    padding-left: 20px
    margin-bottom: 0px
</style>
