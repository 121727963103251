<template>
  <component :is="nameComponent" />
</template>

<script>
import AddStrategyTemplate from '@/app/strategies/components/templates/AddStrategyTemplate'
import StrategiesTemplate from '@/app/strategies/components/templates/StrategiesTemplate'
import { mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'Strategies',
  components: {
    AddStrategyTemplate,
    StrategiesTemplate,
  },
  mixins: [attemptMixin],
  computed: {
    ...mapGetters(['company']),

    /**
     * @return {String} nombre del componente
     */
    nameComponent() {
      const create = this.$route.query.create
      if (create) return 'AddStrategyTemplate'
      else return 'StrategiesTemplate'
    },
  },
}
</script>

<style scoped></style>
