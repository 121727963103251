<script>
import { Line } from 'vue-chartjs'
export default {
  name: 'BubbleChart',
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      elements: {
        point: {
          pointStyle: 'circle',
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                // callback: (value, index, values) => {
                // console.log(value, index,values)
                return `${value} min`
              },
            },
          },
        ],
      },
    },
  }),
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options)
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
