/* eslint-disable no-console */

import { register } from 'register-service-worker'
import vari from '@/app/shared/utils/variables'

// if (process.env.NODE_ENV === 'production') {

const registerWorker = ({ onSubscribe = null, onUpdated = null }) => {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(sw) {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )

      if (!onSubscribe) return
      sw.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: vari.WEB_PUSH_PUBLIC_VAPID_KEY,
        })
        .then((sub) => {
          console.log('sending sub', sub)
          onSubscribe(sub)
        })
    },
    registered(sw) {
      console.log('Service worker has been registered.', sw)
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(sw) {
      console.log('New content is available; please refresh.', sw)
      if (!onUpdated)
        return document.dispatchEvent(
          new CustomEvent('onUpdatedSw', { detail: sw })
        )
      onUpdated({ detail: sw })
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
// }

export default registerWorker
