<template>
  <base-custom-drawer title="Campos personalizados">
    <custom-section-scroll
      :master-styles="{
        height: sectionHeight,
      }"
      :content-styles="{ padding: '0px 20px' }"
    >
      <!-- FORMULARIO DE CAMPOS -->
      <a-form-model
        v-if="currentFields.length > 0"
        id="form-content2"
        layout="vertical"
        class="form-client form-content"
      >
        <a-form-model-item
          v-for="field in currentFields"
          :key="field._id"
          :label="field.name"
          :prop="field.name"
          style="margin-bottom: 0"
        >
          <template v-if="field.type === 'text'">
            <a-input
              v-model="selectedFields[field._id]"
              :maxLength="30"
              placeholder="Escribir aquí..."
              :disabled="profileRemote ? true : false"
              @blur="saveFieldCustom(field._id, selectedFields[field._id])"
              @input="grantSave($event, true)"
              :size="inputs_size"
            />
          </template>
          <template v-if="field.type === 'dropdown'">
            <a-select
              v-model="selectedFields[field._id]"
              :mode="field.multiple ? 'multiple' : ''"
              @change="handleChange($event, field._id)"
              :disabled="profileRemote ? true : false"
              placeholder="Seleccione una opción"
              :size="inputs_size"
              :allowClear="true"
            >
              <a-select-option
                v-for="option in field.options"
                :key="option.idOption"
                :value="option.idOption"
                :title="option.name"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </template>
          <template v-if="field.type === 'date'">
            <a-date-picker
              v-model="selectedFields[field._id]"
              @change="handleChangeDate($event, field._id)"
              placeholder="2020-05-25"
              class="form__date-picker"
              :disabled="profileRemote ? true : false"
              :size="inputs_size"
              :locale="localePicker"
            />
          </template>
        </a-form-model-item>
      </a-form-model>
      <p v-else>No hay campos</p>
    </custom-section-scroll>
  </base-custom-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import BaseCustomDrawer from '@/app/tickets/components/organisms/sections/BaseCustomDrawer'
import attemptMixin from '@/app/shared/mixins/attempt'
import formatMixin from '@/app/shared/mixins/format'
import moment from 'moment'

export default {
  name: 'SectionTicketFields',
  components: {
    CustomSectionScroll,
    BaseCustomDrawer,
  },
  data: () => ({
    allowSave: false, // permite guardar los cambios
    selectedFields: {},
    moment,
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      this.loadFirstData()
    })
  },
  mixins: [attemptMixin, formatMixin],
  watch: {
    'ticketSelected.fields'() {
      this.setCurrentFields()
    },
  },
  computed: {
    ...mapGetters([
      'profileRemote',
      'company',
      'ticketSelected',
      'company_custom_fields_tickets',
      'profile',
      'inputs_size',
      'active_breakpoint',
    ]),

    /**
     * @return {Object[]} campos personalizados
     */
    currentFields() {
      return [...this.company_custom_fields_tickets]
    },
    /**
     * @return {String} altura de la seccion
     */
    sectionHeight() {
      return this.active_breakpoint.is_mobile
        ? 'calc(100% - 12px)'
        : 'calc(100% - 60px)'
    },
  },
  methods: {
    ...mapActions(['updateFieldsToTicket', 'getListCustomFields']),

    /**
     * Setea los campos personalizados a uno local
     */
    setCurrentFields() {
      if (!this.ticketSelected) return {}
      this.selectedFields = { ...this.ticketSelected.fields }

      // identificar que campo dropdown es, para formatear sus valores
      this.currentFields.forEach((currentField) => {
        if (currentField.type === 'dropdown') {
          const existFieldDropdown = Object.prototype.hasOwnProperty.call(
            this.selectedFields,
            currentField._id
          )
          const idOptions = currentField.options.map(
            (option) => option.idOption
          )
          if (existFieldDropdown)
            this.selectedFields[currentField._id] = this.selectedFields[
              currentField._id
            ]
              .filter((selectField) => idOptions.includes(selectField.idOption))
              .map((e) => e.idOption)
        }
      })
    },
    grantSave(event, validateValue) {
      if (validateValue) {
        const value = event.target.value

        if (value.length === 0 || value.trim().length > 0) {
          this.allowSave = true
        } else {
          this.$message.error('No se admiten espacios en blanco')
          this.allowSave = false
        }
      } else this.allowSave = true
    },
    /**
     * Guarda el campo customizado
     * @param {String} idField
     * @param {String} value
     */
    async saveFieldCustom(idField, value) {
      if (!this.allowSave) return

      const currentField = this.currentFields.find((ele) => ele._id === idField)
      let newField = {}

      if (currentField === undefined) return
      else if (currentField.type === 'dropdown') {
        const valueField = !value ? [] : value
        newField[idField] =
          typeof valueField !== 'string' ? valueField : [valueField]
      } else if (currentField.type === 'text') {
        newField[idField] = value || ''
      } else {
        newField[idField] = value
      }

      const response = await this.updateFieldsToTicket({
        ticket: this.ticketSelected,
        field: newField,
      })
      if (response.success) {
        this.$message.success('Se guardó correctamente')
      } else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Escucha el cambio del valor del campo customizado
     * @param {String} value
     * @param {String} idField
     */
    handleChange(value, idField) {
      this.grantSave()
      this.saveFieldCustom(idField, value)
    },
    /**
     * Escucha cuando la fecha cambia
     * @param {String|Array|Date} value
     * @param {String} idField
     */
    handleChangeDate(value, idField) {
      this.handleChange(value ? value.format() : null, idField)
    },
    async loadFirstData() {
      await this.getListCustomFields()
      this.$nextTick(() => this.setCurrentFields())
    },
  },
}
</script>

<style lang="sass" scoped>
.form__date-picker
  width: 100%
</style>
