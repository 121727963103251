const mutations = {
  /**
   * Setea el id del webchat
   * @param {*} state
   * @param {String} webchatId
   */
  SET_WEBCHAT_ID(state, webchatId) {
    state.webchat_id = webchatId
  },
  /**
   * Setea nuevos valores al demo de webchat
   * @param {*} state
   * @param {Object} webchatDemo
   * @param {String} webchatDemo.color
   * @param {String} webchatDemo.avatar
   * @param {String} webchatDemo.title
   */
  SET_WEBCHAT_DEMO(state, webchatDemo) {
    for (let property in webchatDemo) {
      state.webchat_demo[property] = webchatDemo[property]
    }
  },
  /**
   * Setea nuevos valores a webchat
   * @param {*} state
   * @param {Object} webchat
   * @param {String} webchat.color
   * @param {String} webchat.avatar
   * @param {String} webchat.title
   * @param {String} webchat.domain
   * @param {Boolean} webchat.isResponsive
   * @param {String} webchat.position
   * @param {String} webchat.timeOfLifeAfterLastMessage
   * @param {Boolean} webchat.isRegistrationRequired
   * @param {Boolean} webchat.isFreeVersion
   * @param {String} webchat.welcomeMessage
   * @param {String} webchat.messageToFinish

   */
  SET_WEBCHAT(state, webchat) {
    state.webchat = webchat
  },
  /**
   * Resetea los valores del widget demo
   * @param {*} state
   */
  RESET_WEBCHAT_DEMO(state) {
    state.webchat_demo = {
      color: '#1890FF', // blue_6
      avatarUrl: null,
      title: 'Ticker title',
      isFreeVersion: true,
      messageToFinish:
        '¡Gracias por charlar con nosotros hoy! Hasta la próxima 🖐',
      welcomeMessage:
        'Hola buenos días, un gusto saludarte, cuentanos en qué podemos ayudarte?',
    }
  },
}

export default mutations
