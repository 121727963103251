<template>
  <div class="filter-menu-billing-container">
    <h6 class="heading-h6">{{ title }}</h6>
    <div class="filter-menu-billing-content">
      <a-input-search
        placeholder="Buscar por número de pago"
        class="filters__item mrg-right-12"
        v-model="search"
        @search="onChange"
      />
      <a-range-picker
        :default-value="defaultValue"
        :value="valuePicker"
        :disabled-date="disabledDate"
        :show-time="{
          format: localePicker.lang.dateTimeFormat,
          defaultValue: [
            moment('00:00:00', 'HH:mm:ss'),
            moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        :placeholder="['Fecha de inicio', 'Fecha de fin']"
        :format="localePicker.lang.dateTimeFormat"
        :locale="localePicker"
        @change="onChange"
        @ok="onOk"
        style="width: 18em"
        :popupStyle="stylesPopup"
        v-model="seletedDate"
        :allowClear="false"
        class="filters__item--range mrg-right-12"
      />
      <a-select
        v-model="statusSelected"
        default-value="allChannels"
        class="filters__item"
        @change="onChange"
      >
        <a-select-option value="all"> Todos los estados </a-select-option>
        <a-select-option v-for="status in payments_statuses" :key="status.key">
          {{ status.value }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'

export default {
  name: 'FilterMenuBilling',
  mixins: [formatMixin, supportMixin, attemptMixin],
  data: () => ({
    moment,
    title: 'Mi historial de pagos',
    search: '',
    statusSelected: 'all',
    seletedDate: [
      moment(
        `${moment().subtract(1, 'month').format('L')} 00:00`,
        'D/M/YYYY HH:mm'
      ),
      moment(`${moment().format('L')} 23:59`, 'D/M/YYYY HH:mm'),
    ],
    valuePicker: [],
    stylesPopup: {
      zIndex: 1,
    },
    defaultValue: [
      moment(`${moment().format('L')} 00:00`, 'D/M/YYYY HH:mm'),
      moment(`${moment().format('L')} 23:59`, 'D/M/YYYY HH:mm'),
    ],
  }),
  mounted() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'no company'
      this.onChange()
    })
  },
  computed: {
    ...mapGetters(['payments_pagination', 'payments_statuses', 'company']),
  },
  methods: {
    ...mapActions(['fetchPayments']),
    onSearch() {
      this.endWaitTyping(() => {
        this.onChange()
      })
    },
    onChange() {
      this.filterPayments()
    },
    onOk() {},
    handleDownload() {},
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    filterPayments() {
      this.$emit('onFilter', {
        page: 1,
        search: this.search,
        startDate: this.seletedDate[0],
        endDate: this.seletedDate[1],
        status: this.statusSelected,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-menu-billing
  &-container
    text-align: left
    margin-top: 21px
  &-content
    display: flex
    .blue-5
      background-color: $blue_5
.filters__item
  min-width: 18em
.filters__item--range
  min-width: 19em
</style>
