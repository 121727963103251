<template>
  <a-row class="picker" type="flex" align="middle">
    <a-row type="flex" :gutter="12" class="picker__content">
      <a-col :span="12">
        <h6 v-if="withLabel" class="semibold-14 mrg-bottom-8">Canal</h6>
        <a-select
          v-model="channelSelected"
          @change="($event) => filterChannels($event, null)"
          class="picker__item"
          :disabled="disabled"
        >
          <a-select-option
            v-for="channel in enabledChannelsFiltered"
            :key="channel.channel"
            :disabled="disabledChannel(channel.channelIds, disabledOptions)"
          >
            <a-row type="flex" align="middle">
              <social-icon
                :type="channel.channel"
                :size="16"
                role="img"
                class="mrg-right-4"
                :disabled="disabled"
              />
              <span>
                {{ channel.name }}
              </span>
            </a-row>
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="12">
        <h6 v-if="withLabel" class="semibold-14 mrg-bottom-8">Identificador</h6>
        <a-select
          v-model="channelFilteredSelected"
          @change="handleChangeChannelCompany"
          class="picker__item"
          :disabled="disabled"
        >
          <a-select-option
            v-for="channelFiltered in channelsCompanyFiltered"
            :key="channelFiltered._id"
            :disabled="disabledOption(channelFiltered._id)"
            >{{ channelFiltered.title }}
            {{
              channelFiltered.alias ? `( ${channelFiltered.alias} )` : ''
            }}</a-select-option
          >
        </a-select>
      </a-col>
    </a-row>
    <a-icon
      type="delete"
      @click="!disabled && $emit('onDelete')"
      class="picker__icon"
      :class="{ 'mrg-top-30': withLabel, 'icon--disabled': disabled }"
    />
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'PickerChannels',
  props: {
    withLabel: { type: Boolean, required: false, default: false },
    defaultValue: {
      type: Object,
      required: false,
      default: () => ({ channel: null, channelCompanyId: null }),
    },
    disabledOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabledChannels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    SocialIcon,
  },
  data: () => ({
    channelsCompanyFiltered: [],
    channelSelected: '',
    channelFilteredSelected: '',
  }),
  mounted() {
    this.loadInitialData(
      this.defaultValue.channel,
      this.defaultValue.channelCompanyId
    )
  },
  mixins: [supportMixin],
  computed: {
    ...mapGetters(['company', 'simple_company_channels']),

    enabledChannelsFiltered() {
      if (!this.enabledChannels || !this.enabledChannels.length)
        return this.company.channels

      return Object.values(this.company.channels).filter((channel) =>
        this.enabledChannels.includes(channel.channel)
      )
    },
  },
  methods: {
    /**
     * Carga la data inicial
     * @param {String} channel
     * @param {String} channelCompanyId
     */
    loadInitialData(channel = null, channelCompanyId = null) {
      this.channelSelected = channel || this.simple_company_channels[0].channel
      this.filterChannels(this.channelSelected, channelCompanyId)
    },
    /**
     * Filtra los canales por empres y id del canal de la empresa
     * @param {String} channel
     * @param {String} channelCompanyId
     */
    filterChannels(channel, channelCompanyId = null) {
      this.channelsCompanyFiltered = this.filterSimpleByChannel({ channel })
      this.channelFilteredSelected =
        channelCompanyId || this.channelsCompanyFiltered[0]._id

      this.$emit('onChangeChannelCompanyId', this.channelFilteredSelected)
      this.$emit('onChangeChannel', channel)
    },
    /**
     * Emite el cambio del canal de la empresa seleccionada
     * @param {String} channelCompanyId
     */
    handleChangeChannelCompany(channelCompanyId) {
      this.$emit('onChangeChannelCompanyId', channelCompanyId)
    },
    /**
     * Deshabilita un canal si todas las ids de canales de la empresa
     * se encuentran en array de opciones deshabilitadas
     * @param {String[]} channelIds
     * @param {String[]} disabledOptions
     * @return {Boolean}
     */
    disabledChannel(channelIds, disabledOptions) {
      const disabled = channelIds.every((channelId) =>
        disabledOptions.includes(channelId)
      )
      return disabled
    },
    /**
     * Deshabilita una opcion excepto la seleccionada si esta incluida
     * en el array de opciones deshabilitadas
     * @param {String} idOption
     * @return {Boolean}
     */
    disabledOption(idOption) {
      if (this.channelFilteredSelected === idOption) return false
      else return this.disabledOptions.includes(idOption)
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-top-30
  margin-top: 30px
.picker
  width: 100%
  min-width: 420px
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0px !important
  &__content
    flex-grow: 1
  &__icon
    font-size: 20px
    color: $red_6
    margin-left: 16px
  &__item
    width: 100%
</style>
