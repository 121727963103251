var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":`Sección ${_vm.order}`}},[_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthTitleItem || 0,"text":_vm.form.getFieldValue('title') ? _vm.form.getFieldValue('title') : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
              {
                pattern: /^([A-Za-z0-9-%ñÑ&À-ú\s])+$/g,
                message: 'No se admiten caracteres especiales',
              },
            ],
          },
        ]),expression:"[\n          'title',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n              {\n                pattern: /^([A-Za-z0-9-%ñÑ&À-ú\\s])+$/g,\n                message: 'No se admiten caracteres especiales',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthTitleItem || 0,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1)],1),_c('a-form-item',{staticClass:"section-form-item"},[_c('template',{slot:"label"},[_c('span',{staticClass:"mrg-right-8"},[_vm._v(" Guarda la selección de un Item en una variable ")]),_c('a-switch',{staticClass:"form__switch",attrs:{"size":"small"},on:{"change":function($event){return _vm.setIsSavedItem(false)}},model:{value:(_vm.checkedVarToSave),callback:function ($$v) {_vm.checkedVarToSave=$$v},expression:"checkedVarToSave"}})],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'varToSave',
        {
          rules: [
            {
              required: _vm.checkedVarToSave,
              message: 'Por favor rellene el campo',
            },
          ],
        },
      ]),expression:"[\n        'varToSave',\n        {\n          rules: [\n            {\n              required: checkedVarToSave,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona una de las variables","disabled":_vm.onlyRead || !_vm.checkedVarToSave},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVarsForSave),function(simpleVar){return _c('a-select-option',{key:simpleVar._id,attrs:{"disabled":(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id) ||
          simpleVar.deleted,"title":_vm.formatNameSimpleVar(simpleVar)}},[_vm._v(" "+_vm._s(simpleVar.name)+" "),(simpleVar.deleted)?_c('i',[_vm._v("( Variable eliminada )")]):(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id)?_c('i',[_vm._v("( Usado en otro nodo )")]):_vm._e()])}),1),_c('p',{staticClass:"text--left regular-14 mrg-bottom-0",attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" Puedes crear tus propias variables. "),_c('router-link',{attrs:{"to":{ name: 'manage-chatbots-vars' },"target":"_blank"}},[_vm._v("Click aquí.")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }