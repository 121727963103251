import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import createMutationsSharer from 'vuex-shared-mutations'

Vue.use(Vuex)

const actions = {
  async cleanAllModules(context) {
    context.dispatch('resetMoLine')
    context.dispatch('resetMoLogin')
    context.dispatch('resetMoNav')
    context.dispatch('resetMoSupervision')
    context.dispatch('resetMoDashboard')
    context.dispatch('resetMoTicket')
    context.dispatch('resetMoUser')
    context.dispatch('resetMoManageTickets')
    context.dispatch('resetMoChatbot')
    context.dispatch('resetMoSchedule')
    context.dispatch('resetMoProduct')
  },
}

export default new Vuex.Store({
  actions,
  modules: app.store.modules,
  plugins: [createMutationsSharer({ predicate: ['ADD_DOWNLOAD'] })],
})
