<template>
  <a-row :gutter="12" class="transfer">
    <a-col :span="12" class="transfer__col">
      <div class="transfer-source">
        <div class="transfer-source__header">
          <a-checkbox
            :checked="selectAllItems"
            @change="handleSelectAllItems"
            :disabled="disabledSelectAllItems"
            >Seleccionar todo</a-checkbox
          >
        </div>
        <div class="transfer-source__body">
          <a-input-search
            v-if="showSearch"
            v-model="search"
            placeholder="Buscar..."
            class="transfer-source__search"
          />
          <div class="transfer-source__list scrollbar_basic">
            <div
              v-for="item in dataSourceFiltered"
              :key="item.key"
              class="transfer-source__list-item"
            >
              <a-checkbox
                :checked="isChecked(item.key)"
                @change="handleSelectItem($event, item)"
                :disabled="item.disabled"
                >{{ item.title }}</a-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <!-- Vista agentes seleccionados -->
    <a-col :span="12" class="transfer__col">
      <div class="transfer-target">
        <div
          v-if="newSelectedItems.length > 0"
          class="transfer-target__list scrollbar_basic"
        >
          <Alert
            v-for="item in newSelectedItems"
            :key="item.key"
            :message="`Asignado a ${item.title}`"
            @close="removeSelectedItem(item.key)"
            class="transfer-target__list-item"
            :closable="item.disabled"
          />
        </div>
        <div v-else class="transfer-target__list--empty">
          <a-empty
            :image="simpleImage"
            description="No hay colas seleccionadas"
          />
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { Empty } from 'ant-design-vue'
import Alert from '@/app/accounts/components/Alert'

export default {
  name: 'TransferAssign',
  props: {
    dataSource: {
      type: Array,
      required: false,
      default: () => [
        { key: '1', title: 'First item', disabled: true },
        { key: '2', title: 'Second item', disabled: false },
        { key: '3', title: 'Second item', disabled: false },
        { key: '4', title: 'Second item', disabled: false },
        { key: '5', title: 'Second item', disabled: false },
        { key: '6', title: 'Second item', disabled: false },
        { key: '7', title: 'Second item', disabled: false },
        { key: '8', title: 'Second item', disabled: false },
        { key: '9', title: 'Second item', disabled: false },
        { key: '10', title: 'Second item', disabled: false },
      ],
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  components: {
    Alert,
  },
  data: () => ({
    newSelectedItems: [],
    search: '',
  }),
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },

  mounted() {
    this.newSelectedItems = [...this.selectedItems]
  },
  watch: {
    newSelectedItems(newValues) {
      this.$emit('onSelectedItems', newValues)
    },
    selectedItems() {
      this.newSelectedItems = [...this.selectedItems]
    },
  },
  computed: {
    selectAllItems() {
      const itemKeys = this.newSelectedItems.map((item) => item.key)
      return !this.dataSourceFiltered.some(
        (item) => !itemKeys.includes(item.key)
      )
    },
    dataSourceFiltered() {
      const newData = this.dataSource
      const dataItemsKeys = newData.map((data) => data.key)
      const notExistItems = this.selectedItems.filter((item) => {
        return !dataItemsKeys.includes(item.key)
      })
      if (notExistItems.length > 0)
        notExistItems.map((notExistItem) => newData.push(notExistItem))

      return newData.filter((item) => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    disabledSelectAllItems() {
      return !this.dataSource.some((item) => !item.disabled)
    },
  },
  methods: {
    /**
     * Pushea un nuevo item a los nuevos items seleccionados
     * @param {Object} e - evento onchange
     * @param {Object} item - item para seleccionar
     * @param {String} item.key
     * @param {String} item.title
     * @param {Boolean} item.disabled
     */
    handleSelectItem(e, item) {
      let checked = e.target.checked
      if (checked) {
        this.newSelectedItems.push(item)
      } else {
        this.newSelectedItems = this.newSelectedItems.filter(
          (newItem) => newItem.key !== item.key
        )
      }
    },
    /**
     * Verifica si ya esta checkado
     * @param {String} itemKey - key del item
     * @return {Boolean}
     */
    isChecked(itemKey) {
      const itemKeys = this.newSelectedItems.map((item) => item.key)
      let checked = itemKeys.includes(itemKey)
      return checked
    },
    /**
     * Remueve un item segun la key de los nuevos items seleccionados
     * @param {String} itemKey
     */
    removeSelectedItem(itemKey) {
      const item = this.newSelectedItems.find((item) => item.key === itemKey)
      const index = this.newSelectedItems.indexOf(item)
      this.newSelectedItems.splice(index, 1)
    },
    /**
     * Seleccciona todos los items dependiendo de la data filtrada
     */
    handleSelectAllItems(e) {
      const checked = e.target.checked
      const keyNewSelectedItems = this.newSelectedItems.map((item) => item.key)
      if (checked) {
        const unselectedItems = this.dataSourceFiltered.filter(
          (item) => !keyNewSelectedItems.includes(item.key)
        )
        if (unselectedItems.length > 0) {
          unselectedItems.forEach((item) => this.newSelectedItems.push(item))
        }
      } else {
        const selectedItems = this.dataSourceFiltered.filter((item) =>
          keyNewSelectedItems.includes(item.key)
        )
        if (selectedItems.length > 0) {
          selectedItems.forEach((item) => {
            this.newSelectedItems = this.newSelectedItems.filter(
              (newItem) => newItem.key !== item.key
            )
          })
        }
      }
    },
    handleReset() {
      this.newSelectedItems = []
      this.search = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.transfer
  height: 240px
  &__col
    height: 100%
.transfer-source
  height: 100%
  width: 100%
  border: 1px solid $gray_5
  border-radius: 4px
  &__header
    padding: 8px 12px
    border-bottom: 1px solid $gray_5
  &__body
    padding: 4px
  &__search
    margin-bottom: 8px
  &__list
    height: 150px
    overflow: auto
  &__list-item
    margin-bottom: 2px

.transfer-target
  height: 100%
  width: 100%
  border: 1px solid $gray_5
  border-radius: 4px
  padding: 8px 0px 8px 8px
  &__list
    height: 100%
    overflow: auto
    &--empty
      height: 100%
      display: flex
      align-items: center
      justify-content: center
  &__list-item
    margin-bottom: 8px
    margin-right: 8px
</style>
