<template>
  <a class="document-file" :href="src">
    <a-icon :type="icon" class="document-file__icon" />
    <p
      v-if="file.message && file.message.caption"
      class="regular-12 ellipsis mrg-top-2 mrg-bottom-0 document-file__caption"
    >
      {{ file.message.caption }}
    </p>
    <div class="overlay">
      <a-icon type="download" class="icon" style="font-size: 20px" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'DocumentFile',
  props: {
    file: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'file',
    },
    src: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.document-file
  height: 80px
  background-color: $gray_7
  position: relative
  padding: 6px
  +flex(column, center, center)
  &__icon
    font-size: 28px
    color: $gray_1
  &__caption
    color: $gray_1
  .overlay
    cursor: pointer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    transition: .3s ease
    display: flex
    align-items: center
    justify-content: center
    .icon
      color: transparent
  &:hover
    .overlay
      background: $gray_dark_900
      opacity: 0.7
      .icon
        color: $white_000
</style>
