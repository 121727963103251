const STATUS_ACCOUNT = [
  {
    value: 'all',
    title: 'Todos los estados',
  },
  {
    value: 'active',
    title: 'Activos',
  },
  {
    value: 'inactive',
    title: 'Inactivos',
  },
]

const STATUS_PAYMENT = [
  {
    value: 'all',
    title: 'Todos los estados de pago',
  },
  {
    value: 'approved',
    title: 'Aprobados',
  },
  {
    value: 'pending',
    title: 'Pendientes',
  },
  {
    value: 'rejected',
    title: 'Rechazados',
  },
]

const USERS = {
  master_agent: {
    value: 'master_agent',
    title: 'Master',
    for_endpoint: 'master',
    for_endpoint_delete: 'admin',
    enabled: true,
    content: {
      title: 'Master',
      content:
        'Los usuarios con rol Master son <br /> los únicos con acceso y manejo <br /> del módulo de Subcuentas, <br /> además del acceso absoluto <br /> a todos los demás módulos.',
    },
    assign_lines: true,
    id_property: 'idMaster',
    id_property_in_create: '_id',
  },
  admin_agent: {
    value: 'admin_agent',
    title: 'Administrador',
    for_endpoint: 'admin',
    for_endpoint_delete: 'admin',
    enabled: true,
    assign_lines: true,
    id_property: 'idAdmin',
    id_property_in_create: '_id',
  },
  admin: {
    value: 'admin',
    title: 'Administrador',
    for_endpoint: 'admin',
    for_endpoint_delete: 'admin',
    enabled: true,
    assign_lines: false,
    id_property: 'idAdmin',
    id_property_in_create: '_id',
  },
  supervisor: {
    value: 'supervisor',
    title: 'Supervisor',
    for_endpoint: 'supervisor',
    for_endpoint_delete: 'supervisor',
    enabled: false,
    assign_lines: true,
    id_property: 'idSupervisor',
    id_property_in_create: '_id',
  },
  agent: {
    value: 'agent',
    title: 'Agente',
    for_endpoint: 'agent',
    for_endpoint_delete: 'agent',
    enabled: true,
    assign_lines: true,
    id_property: 'idUser',
    id_property_in_create: 'userId',
  },
}

export { STATUS_ACCOUNT, STATUS_PAYMENT, USERS }
