import Schedule from '@/app/schedule/views/Schedule'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: Schedule,
    meta: {
      requiresAuth: true,
      requiresType: [
        'agent',
        'admin_agent',
        'supervisor_agent',
        'master_agent',
      ],
      title: `Agenda | ${VUE_APP_NAME}`,
    },
  },
]
