const initialState = () => ({
  chatbots: [], // Lista de Chatbots
  paginate_chatbots: null, // Datos de la paginación de los chatbots
  listNodesChatbot: [], // lista de los nodos de un chatbot
  changes: 0, // cambios de opciones y opcion, se suma cuando se agrega una opcion, se resta cuando se guarda una opcion
  isSavedNode: true, // se ha guardado los cambios de un item
  nodes: [],
  selectedNodeId: null,
  chatbot: {},
  filters: { page: '1', status: 'all', name: '', channel: '' },
  nodeSelected: null,
  validations_chatbot: {
    message: {
      maxLengthMessage: 0,
      maxLengthErrorMessage: 0,
    },
    options: {
      maxLengthMessage: 0,
      maxLengthErrorMessage: 0,
      maxLengthOption: 0,
      maxOptions: 0,
    },
    cards: {
      maxLengthMessage: 0,
      maxLengthErrorMessage: 0,
      maxCards: 0,
      maxLengthMessageOption: 0,
      maxLengthTitleCard: 0,
      maxLengthSubtitleCard: 0,
    },
    catcher: {
      maxLengthMessage: 0,
    },
    buttons: {
      maxLengthMessage: 0,
      maxLengthErrorMessage: 0,
      maxLengthMessageButton: 0,
      maxButtons: 0,
    },
  },
})

export default initialState
