<template>
  <a-modal
    :visible="visible"
    @cancel="$emit('onCancel')"
    :footer="null"
    :bodyStyle="bodyStyle"
    width="456px"
    centered
    :closable="false"
  >
    <img src="/img/img_features/solid/notifications.png" />
    <h5
      class="heading-h5"
      style="margin-top: 20px; margin-bottom: 12px; color: #262a31"
    >
      ¿Quieres activar las notificaciones?
    </h5>
    <p class="body-2" style="text-align: center; margin-bottom: 20px">
      Déjanos tu mensaje al siguiente correo:
      <span style="color: #51555a">{{ app_email }}</span> y en breve nos
      estaremos comunicando contigo
    </p>
    <a-button type="primary" style="width: 162px" @click="$emit('onCancel')"
      >Aceptar</a-button
    >
  </a-modal>
</template>

<script>
export default {
  name: 'ModalCallback',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    app_email: process.env.VUE_APP_OFICIAL_EMAIL,
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '44px',
      color: '#262A31',
    },
  }),
}
</script>

<style scoped></style>
