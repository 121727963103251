import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_AGENT_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },

  /**
   * Setea Agentes a la Lista de Agentes
   * @param {*} state
   * @param {Array} agents Arreglo de Agentes
   */
  SET_AGENTS(state, agents) {
    state.agents = agents
  },
  /**
   * Agrega un agente al la lista de agentes
   * @param {*} state
   * @param {Object} agent Objeto de un agent
   */
  ADD_AGENT(state, agent) {
    state.agents.push(agent)
  },
  /**
   * Setea los agente simples
   */
  SET_SIMPLE_AGENTS(state, agents) {
    state.simpleAgents = agents
  },
}

export default mutations
