<template>
  <div class="line-list">
    <a-table
      :data-source="sectionLine.list.data"
      :pagination="pagination"
      :loading="sectionLine.list.loading.table"
      rowKey="_id"
      :locale="localeTable"
      @change="handleTableChange"
      size="middle"
    >
      <!--primera columna-->
      <a-table-column key="order" title="N°" data-index="order" :width="60">
        <template slot-scope="order">
          <span>{{ order }}</span>
        </template>
      </a-table-column>
      <!--segunda columna-->
      <a-table-column
        key="name"
        title="Nombre de cola"
        data-index="name"
        :width="300"
        :ellipsis="true"
      >
        <template slot-scope="name">
          <span>{{ name }}</span>
        </template>
      </a-table-column>
      <!--tercera columna -->
      <a-table-column
        v-if="roles && isAllowedFor('supervisor', roles)"
        key="supervisorsAsigned"
        title="Supervisores asignados"
        data-index="supervisorsAsigned"
      >
        <template slot-scope="supervisorsAsigned, record">
          <a
            href="#"
            @click="viewMoreSupervisor(record)"
            :disabled="record.supervisors.length < 1"
            >{{ record.supervisors.length }} supervisores</a
          >
        </template>
      </a-table-column>
      <!--cuarta columna -->
      <a-table-column
        key="agentsAsigned"
        title="Agentes asignados"
        data-index="agentsAsigned"
      >
        <template slot-scope="agentsAsigned, record">
          <a
            href="#"
            @click="viewMoreAgents(record)"
            :disabled="record.agents.length < 1"
            >{{ record.agents.length }} agentes</a
          >
        </template>
      </a-table-column>
      <!--quinta columna  -->
      <a-table-column
        key="channels_companies"
        title="Canales asignados"
        data-index="channels_companies"
      >
        <template slot-scope="channels_companies, record">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <span
              v-for="channel in channelsAssignedToLine(channels_companies)"
              :key="channel.channel"
              :class="{
                'mrg-right-12': channel.titles.length > 0,
              }"
            >
              <popover-channel
                :channel="channel.channel"
                :list-titles="channel.titles"
                :actionEnabled="true"
                @handleModal="handleEditOnChannels(record)"
              />
            </span>
          </div>
        </template>
      </a-table-column>
      <a-table-column key="active" title="Estado" data-index="active">
        <template slot-scope="active">
          <a-tag :color="active ? 'blue' : ''">{{
            active ? 'Activo' : 'Inactivo'
          }}</a-tag>
        </template>
      </a-table-column>
      <!--sexta columna acciones -->
      <a-table-column key="action" title="Acciones">
        <template slot-scope="text, record">
          <a @click="handleEdit(record)">Editar</a>
          <template v-if="!record.default">
            <a-divider type="vertical" />
            <a @click="$emit('onChangeActive', record.active, record)">
              <template v-if="record.active">Inactivar</template>
              <template v-else>Activar</template>
            </a>
            <!--Si la cola no está protegida, se puede eliminar-->
            <template v-if="!record.protected">
              <a-divider type="vertical" />
              <a @click="$emit('onDelete', record.active, record)">Eliminar</a>
            </template>
          </template>
        </template>
      </a-table-column>
    </a-table>
    <!--Modal agentes-->
    <a-modal
      v-if="roles && isAllowedFor('agent', roles)"
      v-model="visible"
      :title="`Agentes de ${nameLine}`"
      :footer="null"
      :bodyStyle="bodyStyle"
      centered
      :afterClose="close"
    >
      <div id="master-container" class="master-container">
        <div
          id="modal-container"
          class="modal-container scrollbar_basic"
          @scroll="setShadows"
        >
          <div id="modal-content" class="modal-content">
            <div
              v-for="agent in dataAgents"
              :key="agent._id"
              class="agent-contain"
              :class="{ 'border-bottom': dataAgents.length > 1 }"
            >
              <div style="display: flex; align-items: center">
                <img
                  v-if="agent.avatar"
                  class="modal__avatar"
                  :src="agent.avatar"
                />
                <DefaultIconLetter v-else :names="agent.names" :size="40" />
                <p class="body-2">{{ agent.names }} {{ agent.surnames }}</p>
              </div>
              <a-button @click="handleModalAgents(agent)"
                >Retirar agente</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!--Modal supervisores-->
    <a-modal
      v-if="roles && isAllowedFor('supervisor', roles)"
      v-model="visibleSupervisor"
      :title="`Supervisores de ${nameLine}`"
      :footer="null"
      :bodyStyle="bodyStyle"
      centered
    >
      <div id="master-container" class="master-container">
        <div
          id="modal-container"
          class="modal-container scrollbar_basic"
          @scroll="setShadows"
        >
          <div id="modal-content" class="modal-content">
            <div
              v-for="supervisor in dataSupervisors"
              :key="supervisor._id"
              class="agent-contain"
              :class="{ 'border-bottom': dataSupervisors.length > 1 }"
            >
              <div style="display: flex; align-items: center">
                <DefaultIconLetter :names="supervisor.names" :size="40" />
                <p class="body-2">
                  {{ supervisor.names }} {{ supervisor.surnames }}
                </p>
              </div>
              <a-button
                v-if="isAllowedFor(profile.type, ['admin', 'master_agent'])"
                @click="handleModalSupervisor(supervisor)"
                >Retirar supervisor</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import transformWordGender from '@/app/shared/mixins/transform'
import compareMixin from '@/app/shared/mixins/compare'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'LineList',
  props: {
    sectionLine: {
      // ##-> Qué datos tiene este objeto?
      type: Object,
    },
  },
  components: {
    DefaultIconLetter,
    PopoverChannel,
  },
  data: () => ({
    visible: false,
    visibleSupervisor: false,
    bodyStyle: {
      padding: '0',
    },
    idLine: '', // id de cola,
    dataAgents: [],
    dataSupervisors: [],
    currentPage: 1,
    localeTable: {
      emptyText: 'No hay registro de colas',
    },
    nameLine: '',
  }),
  mixins: [transformWordGender, compareMixin, supportMixin],
  watch: {
    reloadTableLines() {
      this.handleTableChange({ current: this.currentPage })
    },
  },
  computed: {
    ...mapGetters([
      'lines',
      'profile',
      'reloadTableLines',
      'agentsAccounts',
      'company',
      'roles',
    ]),
    objLine() {
      // objeto de cola
      return this.lines.find((line) => line._id === this.idLine)
    },
    pagination() {
      let pg = {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.sectionLine.list.total || 0,
      }
      return pg
    },
  },
  methods: {
    ...mapActions([
      'removeSupervisor',
      'removeAgent',
      'getLines',
      'getSimplyAgents',
    ]),
    handleEditOnChannels(record) {
      this.$emit('handleEdit', record, 'channels')
    },
    handleEdit(record) {
      this.$emit('handleEdit', record)
    },
    close() {
      this.visible = false
    },
    async viewMoreAgents(record) {
      this.idLine = record._id
      this.dataAgents = await this.getSimplyAgents({
        usersId: record.agents.map((agent) => agent.userId),
        extraFields: ['avatar'],
      })
      this.visible = true
      this.nameLine = record.name
    },
    viewMoreSupervisor(record) {
      this.idLine = record._id
      this.dataSupervisors = record.supervisors
      this.visibleSupervisor = true
      this.nameLine = record.name
    },
    handleModalAgents(agent) {
      console.log(agent)
      const self = this
      const wordTranformated = this.transformWordGender(
        'seguro',
        this.profile.gender
      )
      this.$confirm({
        title: `¿Estás ${wordTranformated} de retirar al agente ${agent.names} ${agent.surnames} de cola - ${this.objLine.name}?`,
        content:
          'Recuerda que los tickets de este agente serán repartidos a otros agentes',
        okText: 'Retirar',
        cancelText: 'Cancelar',
        centered: true,
        onOk() {
          self.sectionLine.createoredit.agents = [
            self.agentsAccounts.find((ac) => ac.userId === agent.userId),
          ]
          self.sectionLine.createoredit.lineId = self.objLine._id
          self.removeAgent({ self: self, userId: agent.userId })

          const find = self.dataAgents.findIndex(
            (dataAgent) => dataAgent.userId === agent.userId
          )
          if (find !== -1) {
            self.destroyAll()
            return self.dataAgents.splice(find, 1)
          } else {
            self.destroyAll()
            return self.dataAgents
          }
        },
        class: 'test',
      })
    },
    handleModalSupervisor(supervisor) {
      let self = this
      const wordTranformated = this.transformWordGender(
        'seguro',
        this.profile.gender
      )
      this.$confirm({
        title: `¿Estás ${wordTranformated} de retirar al supervisor ${supervisor.names} ${supervisor.surnames} de cola - ${this.objLine.name}?`,
        content:
          'Recuerda que este supervisor no podrá tener acceso a esta cola',
        okText: 'Retirar',
        cancelText: 'Cancelar',
        centered: true,
        onOk() {
          self.sectionLine.createoredit.supervisorsId = [supervisor._id]
          self.sectionLine.createoredit.lineId = self.objLine._id
          self.removeSupervisor({ self: self })

          const find = self.dataSupervisors.findIndex(
            (dataSupervisor) => dataSupervisor._id === supervisor._id
          )
          if (find !== -1) {
            self.destroyAll()
            return self.dataSupervisors.splice(find, 1)
          } else {
            self.destroyAll()
            return self.dataSupervisors
          }
        },
        class: 'test',
      })
    },
    destroyAll() {
      this.$destroyAll()
    },
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleTableChange(pagination) {
      this.currentPage = pagination.current
      this.sectionLine.list.page = pagination.current
      await this.getLines({ self: this })
    },
    setShadows() {
      const masterContainer = document.getElementById('master-container')
      const container = document.getElementById('modal-container')
      const content = document.getElementById('modal-content')
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
    filterByChannel(channelsCompanyAssigned, channel) {
      if (!channelsCompanyAssigned || !channel) return []
      const channelsFiltered = this.filterSimpleByChannel({
        channel,
        dataSource: channelsCompanyAssigned,
      })
      return channelsFiltered.map((channelFiltered) => channelFiltered.title)
    },
    /**
     * Canales asignado a una cola
     * @return {Object}
     */
    channelsAssignedToLine(channelsCompanyAssigned) {
      const channels = [...channelsCompanyAssigned].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        const alias = this.channelCompanyAlias(current._id)
        if (!isIncludeInAcc)
          acc[current.channel] = {
            _id: current.channel, // id del canal global
            channel: current.channel,
            titles: [alias ? `${current.title} ( ${alias} )` : current.title], // ids de los canales conectados que tienen este canal
            name: this.transformWord(current.channel, 'capitalize'),
          }
        else
          acc[current.channel].titles.push(
            alias ? `${current.title} ( ${alias} )` : current.title
          )
        return acc
      }, {})
      return channels
    },
  },
}
</script>

<style lang="sass" scoped>
.border-bottom
  border-bottom: 1px solid #C4C4C4
.master-container
  position: relative
  padding-top: 2px
  padding-bottom: 2px
  .modal-container
    height: 498px
    overflow-y: auto
    padding: 0 12px
    .modal-content
      .agent-contain
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 8px
        p
          margin-bottom: 0px
          margin-left: 12px
.shadow-top
  &::before
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid $gray_light_100
    position: absolute
    height: 1px
    top: 0
.shadow-bottom
  &::after
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid #D9D9D9
    position: absolute
    height: 1px
    bottom: 0
.span-agents:hover
  cursor: pointer
  border-bottom: 1px solid #595959
.btn-more
  cursor: pointer
  border-bottom: 1px solid #FFFFFF
.span-supervisor:hover
  cursor: pointer
  border-bottom: 1px solid #595959
.list__channel
  margin-right: 8px
.modal__avatar
  border-radius: 50%
  height: 40px
  width: 40px
</style>
<style lang="sass">
.test
  .ant-modal-content
    .ant-modal-body
      .ant-modal-confirm-body-wrapper
        .ant-modal-confirm-btns
          .ant-btn
            min-width: 86px
</style>
