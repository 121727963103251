<template>
  <a-form :form="form" layout="vertical" class="form">
    <a-form-item :label="`Item ${order}`" class="section-form-item">
      <counter-text
        :maxLength="validations.maxLengthTitleOption"
        :text="form.getFieldValue('title') ? form.getFieldValue('title') : ''"
      >
        <a-input
          v-decorator="[
            'title',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                {
                  pattern: /^([A-Za-z0-9-%ñÑ&À-ú\s])+$/g,
                  message: 'No se admiten caracteres especiales',
                },
              ],
            },
          ]"
          @input="onInput"
          placeholder="Escribir aquí"
          :maxLength="validations.maxLengthTitleOption"
          :disabled="onlyRead"
        />
      </counter-text>
    </a-form-item>
    <a-form-item label="Descripción" class="section-form-item">
      <counter-text
        :maxLength="validations.maxLengthDescriptionOption"
        :text="
          form.getFieldValue('description')
            ? form.getFieldValue('description')
            : ''
        "
      >
        <a-input
          v-decorator="[
            'description',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          @input="onInput"
          placeholder="Escribir aquí"
          :maxLength="validations.maxLengthDescriptionOption"
          :disabled="onlyRead"
        />
      </counter-text>
    </a-form-item>
    <a-form-item
      label="Selecciona una de las acciones"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'action',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
            ],
          },
        ]"
        placeholder="Selecciona"
        @change="handleChangeAction"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="actionToNode in actionsToNodeSelected"
          :key="actionToNode.value"
          :disabled="
            actionToNode.actionToSave && actionToNode.actionToSave.disabled
          "
        >
          {{ actionToNode.title }}
          <i
            v-if="
              actionToNode.value !== action &&
              actionToNode.actionToSave &&
              actionToNode.actionToSave.disabled
            "
            >( No hay variables disponibles )</i
          >
        </a-select-option>
      </a-select>
    </a-form-item>
    <!--Si la accion es transferir-->
    <template v-if="action === 'transfer'">
      <a-form-item label="Selecciona una cola" class="section-form-item">
        <a-select
          v-decorator="[
            'lineId',
            {
              rules: [{ required: true, message: 'Completa el campo' }],
            },
          ]"
          placeholder="Selecciona"
          @change="handleChangeLines"
          :disabled="onlyRead"
        >
          <a-select-option
            v-for="line in dataSourceLines"
            :key="line.key"
            :value="line.key"
          >
            {{ line.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-switch
        class="form__switch"
        size="small"
        v-model="activeMessageTransfer"
        @change="setIsSavedItem(false)"
        :disabled="onlyRead"
      />
      <a-form-item label="Mensaje de transferencia" class="section-form-item">
        <counter-text
          :maxLength="350"
          :text="
            form.getFieldValue('message') ? form.getFieldValue('message') : ''
          "
        >
          <a-mentions
            v-decorator="[
              'message',
              {
                initialValue:
                  'En un momento, uno de nuestros agentes lo estará atendiendo',
                rules: [
                  {
                    required: activeMessageTransfer,
                    message: 'Por favor rellene el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            ref="message_form_text"
            rows="3"
            placeholder="Escribe aquí..."
            @change="handleChangeText"
            :maxLength="350"
            class="text--left"
            :prefix="PREFIX_VARS"
            :notFoundContent="notFoundVars"
            placement="bottom"
            :disabled="!activeMessageTransfer || onlyRead"
            :filterOption="filterOption"
          >
            <a-mentions-option
              v-for="simpleVar in simpleVars"
              :key="simpleVar._id"
              :value="simpleVar.name"
            >
              {{ simpleVar.name }}</a-mentions-option
            >
          </a-mentions>
        </counter-text>
        <menu-options-content
          :show-wait="false"
          @onChangeEmoji="handleSetEmoji"
          :disabled-emoji="!activeMessageTransfer || onlyRead"
          :disabled-vars="!activeMessageTransfer || onlyRead"
          @onOpenVars="handleOpenVars"
        />
      </a-form-item>
    </template>
    <template v-else-if="action === 'none'">
      <a-form-item label="Mensaje final" class="section-form-item">
        <p class="help mrg-bottom-8 text--left" style="margin-top: 4px">
          Recuerda que este mensaje se mostrará al cliente cuando elija esta
          opción.
        </p>
        <counter-text
          :maxLength="350"
          :text="
            form.getFieldValue('message') ? form.getFieldValue('message') : ''
          "
        >
          <a-mentions
            v-decorator="[
              'message',
              {
                initialValue:
                  'Actualmente no atendemos por este medio, gracias.',
                rules: [
                  {
                    required: false,
                    message: 'Por favor rellene el campo',
                  },
                  {
                    whitespace: false,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            ref="message_form_text"
            rows="3"
            placeholder="Escribe aquí..."
            @change="handleChangeText"
            :maxLength="350"
            class="text--left"
            :prefix="PREFIX_VARS"
            :notFoundContent="notFoundVars"
            placement="bottom"
            :disabled="onlyRead"
            :filterOption="filterOption"
          >
            <a-mentions-option
              v-for="simpleVar in simpleVars"
              :key="simpleVar._id"
              :value="simpleVar.name"
            >
              {{ simpleVar.name }}</a-mentions-option
            >
          </a-mentions>
        </counter-text>
        <menu-options-content
          :show-wait="false"
          @onChangeEmoji="handleSetEmoji"
          :disabled-emoji="onlyRead"
          :disabled-vars="onlyRead"
          @onOpenVars="handleOpenVars"
        />
      </a-form-item>
    </template>
    <template v-if="action === 'rollback'">
      <p class="body-2 text--left">
        Recuerda que se volverán a mostrar las opciones del menú anterior.
      </p>
    </template>
    <template v-else-if="description">
      <p class="body-2 text--left">
        {{ description }}
      </p>
    </template>
  </a-form>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import transformWordGender from '@/app/shared/mixins/transform'
import transformActionNode from '@/app/shared/mixins/transform'
import CounterText from '@/app/shared/components/molecules/CounterText'
import actionsToNodeSelected from '@/app/chatbots/mixins/actionsToNodes'
import verifyAddDefaultNode from '@/app/chatbots/mixins/actionsToNodes'
import MenuOptionsContent from '@/app/chatbots/components/molecules/MenuOptionsContent'
import { PREFIX_VARS, NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'ListItemForm',
  components: {
    CounterText,
    MenuOptionsContent,
  },
  mixins: [
    transformWordGender,
    transformActionNode,
    actionsToNodeSelected,
    verifyAddDefaultNode,
  ],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    subchildId: {
      type: String,
      required: true,
    },
    parentNodeId: {
      type: String,
      required: true,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    PREFIX_VARS,
    activeMessageTransfer: true,
    order: 1,
    action: null,
    actionNodeId: null,
    actionNodeType: null,
    isChangeAction: false,
    simulateParentNodeId: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'list-item-form-chatbot' })
  },
  computed: {
    ...mapGetters(['treeNodes', 'profile', 'nodes', 'validations_chatbot']),

    /**
     * @returns {String} - descripcion de la accion seleccionada
     */
    description() {
      if (!this.action) return null
      const actionSelected = this.actionsToNodeSelected.find(
        (actionToNodeSelected) => actionToNodeSelected.action === this.action
      )
      return actionSelected.description || null
    },
    /**
     * @returns {Boolean} - verifica si existe un nodo de tipo catcher
     */
    existCatcher() {
      return this.verifyExistCatcher({ parentNodeId: this.parentNodeId })
    },
    /**
     * Filtra las variables simples de acuerdo al id del nodo de su padre
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVars() {
      return !this.existCatcher
        ? []
        : this.filterSimpleVarsBySave({ parentNodeId: this.parentNodeId })
    },
    /**
     * @returns {String} - mensaje que aparecerá cuando no haya datos
     */
    notFoundVars() {
      return !this.existCatcher
        ? 'Aún no haz agregado "Capturar variable"'
        : 'No se han encontrado variables'
    },
    /**
     * Validaciones del nodo de acuerdo al soporte
     * @return {Object} validations
     * @return {Number} validations.maxLengthMessageOption
     */
    validations() {
      const node = 'lists'
      return this.validations_chatbot && this.validations_chatbot[node]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions(['updateSubchild']),

    /**
     * Setear valores a los formularios
     * @param {Object} args
     * @param {String} args.title
     * @param {String} args.description
     * @param {Object} args.action
     * @param {String} args.action.lineId
     * @param {String} args.action.message
     * @param {Boolean} args.action.withMessage
     * @param {String} args.action.type
     * @param {String} args.action.nodeId
     * @param {String} args.action.nodeType
     * @param {String} args.order
     * @param {String} args.simulateParentNodeId - id del nodo que simula ser su padre
     */
    setValues({ title, description, action, order, simulateParentNodeId }) {
      this.action = this.transformActionNode({
        type: action.type,
        nodeType: action.nodeType,
      })
      this.order = order
      this.actionNodeId = action?.nodeId
      this.actionNodeType = action?.nodeType

      this.simulateParentNodeId = simulateParentNodeId

      this.activeMessageTransfer =
        action.type === 'transfer' ? action.withMessage : false

      this.$nextTick(() => {
        // se agrega un tiempo para estar seguros de que todo se
        // renderizo correctamente
        setTimeout(() => {
          this.form.setFieldsValue({
            title: title,
            description: description,
            action: this.action,
            message: action?.message,
            lineId: action?.lineId,
          })
        }, 0)
      })
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        // si la accion del nodo se cambio y tiene un hijo
        if (this.isChangeAction && this.hasChild) {
          const textSure = this.transformWordGender(
            'seguro',
            this.profile.gender
          )
          let self = this
          this.$confirm({
            title: `¿Estás ${textSure} de cambiar la acción del Item`,
            content:
              'Recuerda que al cambiar la acción, los mensajes siguientes serán eliminados.',
            okText: 'Guardar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
              self.handleUpdateItem(values)
              self.setIsSavedItem(true)
              self.isChangeAction = false
            },
          })
          return
        }
        this.handleUpdateItem(values)
        this.setIsSavedItem(true)
        this.isChangeAction = false
      })
    },
    /**
     * Subir cambios a una opcion
     * @param {Object} values - valores del formulario
     * @param {String} values.nameOption - nombre de la opcion
     * @param {String} values.action - transfer, continue_message, continue_options, none
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     */
    async handleUpdateItem(values) {
      const settings = {
        order: this.order,
        action: { nodeId: this.actionNodeId, nodeType: this.actionNodeType },
        parentNodeId: this.parentNodeId,
        simulateParentNodeId: this.simulateParentNodeId,
        idItem: this.subchildId,
      }
      const newItem = {
        order: settings.order,
        title: values.title,
        description: values.description || null,
        type_action: values.action,
        lineId_action: values.lineId ? values.lineId : null,
        message_action: values.message ? values.message : null,
        withMessage: this.activeMessageTransfer,
      }

      const { nodeType, type_action, nodeId_action } =
        await this.verifyAddDefaultNode({
          action: values.action,
          nodeIdAction: settings.action?.nodeId,
          nodeTypeAction: settings.action.nodeType,
          parentNodeId: this.parentNodeId,
          isChild: false,
          nodeId: this.subchildId,
          isSubchild: true,
          childId: this.simulateParentNodeId,
        })
      if (type_action) newItem.type_action = type_action
      if (nodeType) newItem.nodeType = nodeType
      if (nodeId_action) newItem.nodeId_action = nodeId_action

      // validar el tipo de padre que tiene
      const nodeParent = this.nodes.find(
        (node) => node._id === settings.parentNodeId
      )

      const structureParent = NODES[nodeParent.drawer.question.type]
      const response = await this.updateSubchild({
        chatbotId: this.chatbotId,
        nodeId: settings.parentNodeId,
        nodeType: structureParent.type,
        newSubchild: newItem,
        childId: settings.simulateParentNodeId,
        childIdProperty: structureParent.child.id_property,
        subchildId: settings.idItem,
        subchildType: structureParent.child.child.group,
        subchildIdProperty: structureParent.child.child.id_property,
      })

      if (response.success) {
        this.$emit('onChangeSettings', response)
        this.$message.success('Se actualizó correctamente')
      } else {
        this.$message.error(response.details || 'No se pudo ejecutar la acción')
      }
    },
    /**
     * Cambia de accion
     * @param {String} value - transfer, contine_message, continue_options, none, rollback
     */
    handleChangeAction(value) {
      this.action = value
      this.setIsSavedItem(false)
      this.isChangeAction = true
    },
    /**
     * Escucha el cambio de colas
     */
    handleChangeLines() {
      this.setIsSavedItem(false)
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      let content = this.form.getFieldValue('message')
      content = content + emoji
      this.form.setFieldsValue({
        content,
      })
      this.setIsSavedItem(false)
    },
    handleChangeText() {
      this.setIsSavedItem(false)
    },
    /**
     * Muestra el listado de variables
     * @param {String} field - nombre de campo en el formulario
     */
    handleOpenVars() {
      let text = this.form.getFieldValue('message')
      const textArray = text.split('')
      // si el ultimo valor no es el prefijo de variables, seteará el prefijo al texto
      if (textArray.pop() !== this.PREFIX_VARS) {
        text = text + this.PREFIX_VARS
        this.form.setFieldsValue({
          message: text,
        })
      }

      this.$refs.message_form_text.focus()
      // Importante no borrar, porque si no, no renderizara el listado de variables
      setTimeout(() => {
        this.simulateKey(this.PREFIX_VARS, 'up')
      }, 0)
    },
    /**
     * Simula el #
     * @param {Number} keyCode The keyCode of the key to simulate
     * @param {String} type (optional) The type of event : down, up or press. The default is down
     * @param {Object} modifiers (optional) An object which contains modifiers keys { ctrlKey: true, altKey: false, ...}
     */
    simulateKey(keyCode, type, modifiers) {
      const mentions = document.getElementById('option-form-chatbot_message')
      var evtName = typeof type === 'string' ? 'key' + type : 'keydown'
      var modifier = typeof modifiers === 'object' ? modifier : {}
      var event = document.createEvent('HTMLEvents')
      event.initEvent(evtName, true, false)
      event.key = keyCode
      for (var i in modifiers) {
        event[i] = modifiers[i]
      }
      mentions.dispatchEvent(event)
    },
    /**
     * Filtra el valor de una opcion
     * @param {String} input
     * @param {Object} option
     */
    filterOption(input, option) {
      const optionFound = option.children[0].text.toLowerCase()
      const optionTrim = optionFound.trim()

      if (input.toLowerCase().includes(optionTrim)) {
        return optionFound
      } else {
        return optionFound.indexOf(input.toLowerCase()) >= 0
          ? optionFound
          : null
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  position: relative
  &__switch
    position: absolute
    right: 12px
    transform: translateY(3px)
    z-index: 1
</style>
