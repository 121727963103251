import Round from '@/app/shared/utils/round'

const getters = {
  company: (state) => state.company,
  mailboxes: (state) => {
    // Obtiene los buzones del objeto de la empresa
    if (!state.company) return []
    return state.company.settings.mailboxes.sort((a, b) => {
      // Ordena, "General" al principio y "Finalizados" al final
      if (a.default) return -1
      else if (b.end) return 1
      else return 0
    })
  },
  maxMailboxes: (state) => state.maxMailboxes,
  // Arreglo de canales de la empresa disponibles en la plataforma
  channelsCompany: (state) => {
    if (!state.company) return state.sandboxChannels
    // Transforma el objeto en array
    return Object.keys(state.company.channels)
      .map((ch) => {
        const channelCompany = state.company.channels[ch]
        channelCompany.channel = ch
        return channelCompany
      })
      .concat(state.sandboxChannels)
  },
  notificationsPhones: (state) => state.notificationsPhones,
  industries: (state) => state.industries,
  languages: (state) => state.languages,
  operations: (state) => state.operations,
  company_wallet: (state) => {
    if (state.company_wallet && state.company_wallet.total)
      state.company_wallet.total = Round.round10(state.company_wallet.total, -2)
    return state.company_wallet
  },
}

export default getters
