<template>
  <a-row
    v-if="profile && profile.deprecatedPwd && profile.deprecatedPwd.deprecated"
    class="template full-height"
    type="flex"
    justify="center"
    align="middle"
  >
    <a-col
      :xs="{ span: 24 }"
      :sm="{ span: 24 }"
      :md="{ span: 16 }"
      :lg="{ span: 20 }"
      :xl="{ span: 19 }"
      :xxl="{ span: 18 }"
      class="box-shadow border-radius template__content"
      :class="{ 'display-flex': successComponent }"
    >
      <!--password form-->
      <div v-if="!successComponent" class="">
        <!--HEADER-->
        <div class="template__header">
          <a class="link-button" @click="logout"
            ><a-icon type="arrow-left" class="mrg-right-4" />Salir</a
          >
          <Logo class="logo" :app="owner_app" type="dark" size="medium" />
        </div>
        <!--BODY-->
        <a-row type="flex" justify="space-between" align="start" class="">
          <!--PRIMERA COLUMNA-->
          <a-col
            class=""
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 24 }"
            :lg="{ span: 13 }"
            :xl="{ span: 13 }"
          >
            <h4 class="heading-h4 align-left gray-dark-900 mrg-bottom-20">
              {{ title }}
            </h4>
            <p class="body-2 align-left gray-dark-900">
              La contraseña deberá contener 8 caracteres como mínimo, 1 carácter
              en mayúscula, 1 carácter en minúscula, 1 número y 1 carácter
              especial.
            </p>
            <p class="body-2 align-left gray-dark-900">{{ subtext }}</p>
          </a-col>
          <!--SEGUNDA COLUMNA-->
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 24 }"
            :lg="{ span: 10 }"
            :xl="{ span: 10 }"
          >
            <PasswordForm
              @success="handleSuccess"
              :onSavePassword="saveNewPassword"
              style="width: 100%"
            />
          </a-col>
        </a-row>
      </div>
      <!--container success-->
      <div v-else class="template__content-success">
        <a-icon type="check-circle" style="font-size: 80px; color: #f5d540" />
        <h4 class="heading-h4 margin-top-24 gray-dark-900">
          Su contraseña ha sido actualizada correctamente
        </h4>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PasswordForm from '@/app/auth/components/forms/PasswordForm.vue'
import Logo from '@/app/shared/components/logos/Logo.vue'
import transformWordGender from '@/app/shared/mixins/transform'

export default {
  name: 'PasswordTemplate',
  components: {
    PasswordForm,
    Logo,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    owner_app: !process.env.VUE_APP_OWNER_APP
      ? process.env.VUE_APP_NAME
      : process.env.VUE_APP_OWNER_APP,
    successComponent: false,
  }),
  mixins: [transformWordGender],
  computed: {
    ...mapGetters(['profile']),

    wordTransformated() {
      const word = this.transformWordGender(
        'Bienvenido',
        this.profile && this.profile.gender ? this.profile.gender : null
      )
      return word
    },
    /**
     * Reason = 'expired', 'new', null
     */
    title() {
      return this.profile &&
        this.profile.deprecatedPwd &&
        this.profile.deprecatedPwd.reason === 'new'
        ? `¡Hola, ${this.profile ? this.profile.names : ''}! ${
            this.wordTransformated
          } a ${this.app}, por tu seguridad debes cambiar tu contraseña`
        : `Hey ${
            this.profile ? this.profile.names : ''
          }, por tu seguridad cada 60 días debes volver a cambiar tu contraseña y ahora es el momento`
    },
    subtext() {
      return this.profile &&
        this.profile.deprecatedPwd &&
        this.profile.deprecatedPwd.reason === 'new'
        ? 'Recuerda que la creación de tu nueva contraseña expirará dentro de 60 días.'
        : 'Recuerda que tu nueva contraseña debe ser diferente a las 5 anteriores y volverá a expirar dentro de 60 días.'
    },
  },
  methods: {
    ...mapActions(['logout', 'saveNewPassword']),
    handleSuccess() {
      this.successComponent = true
      setTimeout(() => {
        location.href = '/redirect'
      }, 3000)
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.display-flex
  +flex(column, center, center)
.margin-top-24
  margin-top: 24px
.template
  @include xs
    padding: 20px
  @include sm
    padding: 20px
  &__header
      width: 100%
      margin-bottom: 40px
      +flex(row, center, flex-end)
      position: relative
      @include xs
        +flex(row, flex-start, flex-end)
        margin-bottom: 16px
      @include sm
        +flex(row, flex-start, flex-end)
        margin-bottom: 16px
      .link-button
        margin: 3px 0px
        position: absolute
        left: 0
        @include xs
          position: relative
        @include sm
          position: relative
      .logo
        @include xs
          display: none
        @include sm
          display: none
  &__content
    background-color: $white_000
    padding: 82px 112px
    @include xs
      padding: 24px
    @include sm
      padding: 24px 32px
    @include md
      padding: 24px 60px
    &-success
      min-height: 350px
      max-width: 360px
      +flex(column, center, center)
</style>
