<template>
  <div class="section-user">
    <h5 class="heading-h5">Mi perfil</h5>
    <a-form
      v-if="profile && company"
      :form="form"
      @submit.prevent="handleSubmit"
      class="form"
      hideRequiredMark
    >
      <!--el cuerpo del formulario-->
      <div id="menu-user-master-container" class="master-container">
        <div
          id="menu-user-container"
          class="container scrollbar_basic"
          @scroll="
            setShadows(
              'menu-user-master-container',
              'menu-user-container',
              'menu-user-content'
            )
          "
        >
          <div id="menu-user-content" class="content">
            <p class="body-2">
              Puedes cambiar tu avatar y tus datos personales.
            </p>
            <div class="upload-container">
              <a-upload
                class="upload"
                name="avatar"
                :multiple="false"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                :customRequest="selfUpload"
              >
                <DefaultIconLetter
                  v-if="!imageUrl && profile"
                  :names="profile.names"
                  :size="96"
                />
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  style="object-fit: cover; border-radius: 50px"
                  height="96px"
                  width="96px"
                  alt="Avatar de perfil"
                />
                <div class="overlay" :class="{ 'opacity-1': loading }">
                  <a-icon
                    v-if="!loading"
                    type="edit"
                    style="font-size: 20px; color: white"
                  />
                  <a-icon
                    v-else
                    type="loading"
                    style="font-size: 20px; color: white"
                  />
                </div>
              </a-upload>
              <div class="text">
                <p class="heading-h8 subtitle" style="margin-bottom: 0">
                  Sube aquí tu nuevo avatar
                </p>
                <p class="body-2" style="margin-bottom: 0">
                  El tamaño máximo permitido es de 2MB
                </p>
                <p class="body-2" style="margin-bottom: 0">
                  Las dimensiones ideales son 500px x 500px
                </p>
              </div>
            </div>
            <h6 class="heading-h6">Datos personales</h6>
            <div class="form-items">
              <a-form-item
                v-bind="formItemLayout"
                label="Nombres"
                class="form-item"
              >
                <a-input
                  placeholder="Escribe aquì..."
                  v-decorator="[
                    'names',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Este campo es requerido',
                        },
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  :disabled="is_guest"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Apellidos"
                class="form-item"
              >
                <a-input
                  placeholder="Escribe aquì..."
                  v-decorator="[
                    'surnames',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Este campo es requerido',
                        },
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  :disabled="is_guest"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Correo electrónico"
                class="form-item"
              >
                <a-input
                  :disabled="disabledEmail"
                  placeholder="Escribe aquì..."
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Este campo es requerido',
                        },
                        { type: 'email', message: 'No es un email válido' },
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Telefono"
                class="form-item"
              >
                <a-row :gutter="8">
                  <a-col :span="24">
                    <div class="phone-number">
                      <a-input
                        v-decorator="[
                          'phone',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Por favor rellena el campo',
                              },
                              {
                                whitespace: true,
                                message: 'No se aceptan espacios vacios',
                              },
                            ],
                          },
                        ]"
                        placeholder="Escribe aquí..."
                        :disabled="is_guest"
                      />
                      <a-select
                        show-search
                        v-decorator="['code']"
                        style="
                          width: 50%;
                          margin-right: 8px;
                          paddign-bottom: 0px;
                        "
                        option-filter-prop="children"
                        placeholder="Seleccione"
                        @change="handleSelectCountry"
                        :disabled="is_guest"
                      >
                        <a-select-option
                          v-for="country in countries"
                          :key="country._id"
                          :title="country.nameSpanish"
                        >
                          {{ country.dialCode }} {{ country.nameSpanish }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </a-col>
                </a-row>
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="ID de usuario"
                class="form-item"
              >
                <a-input
                  v-model="profile.username"
                  placeholder="Escribe aquì..."
                  disabled
                />
              </a-form-item>
              <template v-if="showFieldsPassword">
                <h6 class="heading-h6">Cambiar contraseña</h6>
                <a-row>
                  <a-col :span="18">
                    <p class="body-2" style="color: #60636d">
                      La contraseña deberá contener 8 caracteres como mínimo , 1
                      carácter en mayúscula, 1 carácter en minúscula, 1 número y
                      1 carácter especial.
                    </p>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="18">
                    <a-form-item label="Contraseña actual" has-feedback>
                      <a-input-password
                        v-decorator="[
                          'currentPassword',
                          {
                            rules: [
                              {
                                min: 8,
                                message: '8 caracteres a más.',
                              },
                              {
                                pattern: /[A-Z]/g,
                                message: '1 carácter en mayúscula.',
                              },
                              {
                                pattern: /[a-z]/g,
                                message: '1 carácter en minúscula.',
                              },
                              {
                                pattern: /[0-9]/g,
                                message: '1 número.',
                              },
                              {
                                pattern: /[^A-Za-z0-9]/g,
                                message: '1 carácter especial',
                              },
                            ],
                          },
                        ]"
                        placeholder="Escribir contraseña actual"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="18">
                    <a-form-item label="Nueva contraseña" has-feedback>
                      <a-input-password
                        v-decorator="[
                          'password',
                          {
                            rules: [
                              {
                                min: 8,
                                message: '8 caracteres a más.',
                              },
                              {
                                pattern: /[A-Z]/g,
                                message: '1 carácter en mayúscula.',
                              },
                              {
                                pattern: /[a-z]/g,
                                message: '1 carácter en minúscula.',
                              },
                              {
                                pattern: /[0-9]/g,
                                message: '1 número.',
                              },
                              {
                                pattern: /[^A-Za-z0-9]/g,
                                message: '1 carácter especial',
                              },
                              {
                                validator: validateToNextPassword,
                              },
                            ],
                          },
                        ]"
                        placeholder="Escribir nueva contraseña"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="18">
                    <a-form-item
                      label="Confirmar nueva contraseña"
                      has-feedback
                    >
                      <a-input-password
                        placeholder="Escribir nueva contraseña"
                        v-decorator="[
                          'confirm',
                          {
                            rules: [
                              {
                                required: requireConfirm,
                                message: 'Es necesario',
                              },
                              {
                                validator: compareToFirstPassword,
                              },
                            ],
                          },
                        ]"
                        @blur="handleConfirmBlur"
                      />
                    </a-form-item>
                  </a-col> </a-row
              ></template>
            </div>
          </div>
        </div>
      </div>
      <!--el footer del formulario(botones)-->
      <div class="footer">
        <a-button
          @click="handleCancel"
          :disabled="hasNewValues(form.getFieldsValue()) && !newAvatar"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          html-type="submit"
          style="margin-left: 8px"
          :loading="btnSubmit.loading"
          :disabled="hasNewValues(form.getFieldsValue()) && !newAvatar"
          >Guardar cambios</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'SectionMenuUser',
  components: {
    DefaultIconLetter,
  },
  data: () => ({
    formItemLayout: {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    },
    tailFormItemLayout: {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    },
    disabled: {
      names: true,
      surnames: true,
      email: true,
      phone: true,
    },
    loading: false,
    imageUrl: '', // url de imagen
    fileImage: null,
    newAvatar: false, // si se carga un nuevo avatar
    codes: ['+51', '+52'],
    currentProfileId: null,
    currentUserId: null,
    btnSubmit: {
      // boton de submit de formulario
      loading: false, // valor de loading
    },
    confirmDirty: false,
    requireConfirm: false, // requiere confirmar password
    countrySelected: null, // Objeto de país seleccionado
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-profile-user' })
  },
  mounted() {
    this.setValues()
  },
  mixins: [compareMixin],
  computed: {
    ...mapGetters(['profile', 'company', 'countries', 'admins', 'is_guest']),

    /**
     * Estará deshbabilitado solo para los usuarios con rol agente
     * o para los usuarios de tipo guest
     * @return {Boolean} deshabilita o no el campo mail
     */
    disabledEmail() {
      if (!this.profile) return true
      return (
        this.isAllowedFor(this.profile.type, 'agent', { literal: true }) ||
        this.is_guest
      )
    },
    /**
     * Muestra los campos para cambios de contraseña
     * @return {Boolean}
     */
    showFieldsPassword() {
      return !this.profile.socialLogin && !this.is_guest
    },
  },
  methods: {
    ...mapActions(['updateUserProfile', 'transferOwnerShip']),
    ...mapMutations(['SET_PROFILE']),
    /**
     * Comprueba si hay nuevos valores en el formulario
     * @param {Object} values // objeto de valores de todo el formulario
     */
    hasNewValues(values) {
      const fields = Object.keys(values).some((field) => {
        // si password esta vacio o si confirm esta vacio
        if (
          (field === 'password' && values[field] === '') ||
          (field === 'confirm' && values[field] === '')
        ) {
          this.requireConfirm = false
          return false
        } else {
          return values[field] !== this.profile[field]
        }
      })
      return !fields
    },
    setValues() {
      setTimeout(() => {
        this.currentProfileId = this.profile._id
        this.currentUserId = this.profile.userId
        this.form.setFieldsValue({
          _id: this.profile._id,
          names: this.profile.names,
          surnames: this.profile.surnames,
          email: this.profile.email,
          phone: this.profile.phone,
          username: this.profile.username,
          code: this.profile.code,
        })
        this.imageUrl = this.profile.avatar ? this.profile.avatar : null
      }, 150)
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.code = this.countrySelected
            ? this.countrySelected.dialCode
            : values.code
          let userProfile = { ...values }
          userProfile.profileId = this.currentProfileId
          userProfile.userId = this.currentUserId // Validar contraseña
          // ##-> Para cuando se habiliten los codigos de pais
          /* userProfile.code = this.countrySelected.dialCode */
          if (userProfile.currentPassword) {
            if (userProfile.currentPassword.length > 0) {
              if (!userProfile.password || !userProfile.confirm) {
                delete userProfile.currentPassword
                delete userProfile.password
                delete userProfile.confirm
              } else {
                if (userProfile.password !== userProfile.confirm)
                  this.$message.error('Las contraseñas no coinciden')
              }
            }
          } else {
            delete userProfile.currentPassword
            delete userProfile.password
            delete userProfile.confirm
          }

          if (this.fileImage) {
            userProfile.avatar = this.fileImage
          }

          this.btnSubmit.loading = true
          const response = await this.updateUserProfile(userProfile)
          this.btnSubmit.loading = false
          if (response.success) {
            this.newAvatar = false
            this.fileImage = null
            this.SET_PROFILE(response.result)
            this.$message.success('Tus datos se actualizaron exitosamente')
          } else {
            if (response.result === 'duplicated') {
              if (response.fields.email !== undefined)
                this.$message.error(
                  'Lo sentimos, el Correo del usuario ya existe'
                )
            } else if (
              response.result === 'exists' ||
              response.result === 'external-duplicated'
            ) {
              if (response.fields.email !== undefined)
                this.$message.error(
                  'Lo sentimos, el Correo del usuario ya existe'
                )
            } else {
              if (response.details) this.$message.error(response.details)
              else this.$message.error('Ocurrió un error')
            }
          }
          this.form.resetFields(['currentPassword'])
          this.form.resetFields(['password'])
          this.form.resetFields(['confirm'])
        }
      })
    },
    handleShowEdit(refInput, namedisabled) {
      this.disabled[namedisabled] = false
      this.$refs[refInput].focus()
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png, jpeg o jpg')
      }
      const isLt2M = file.size / 1024 / 1024 <= 2
      if (!isLt2M) {
        this.$message.error('El tamaño máximo permitido es de 2MB')
      }
      return isJpgOrPng && isLt2M
    },
    selfUpload({ action, file, onSuccess, onError, onProgress }) {
      console.log(action, file, onSuccess, onError, onProgress)
      this.loading = true
      const base64 = new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.imageUrl = fileReader.result
          resolve(fileReader.result)
          this.loading = false
          this.newAvatar = true
        }
      })
      console.log('base 64', base64)
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback('La contraseña nueva no coincide')
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    handleCancel() {
      this.setValues()
      this.newAvatar = false
    },
    setShadows(idMaster, idContainer, idContent) {
      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
    /**
     * @param {String} codeId
     */
    handleSelectCountry(codeId) {
      this.countrySelected = this.countries.find((el) => el._id === codeId)
    },
  },
}
</script>

<style lang="sass" scoped>
.owner
  >p
    margin-bottom: 0px
  .list
    padding-left: 19px
    margin-bottom: 12px
  .question
    display: flex
    justify-content: space-between
    align-items: center
    color: $gray_dark_800
    ul
      li
        color: $gray_dark_800
    p
      margin-bottom: 0px
</style>
<style lang="sass">
.opacity-1
  opacity: 1!important
.section-user
  height: 100%
  background-color: $white_000
  border-radius: 4px
  text-align: left
  padding-top: 40px
  border: 1px solid $gray_dark_000
  >h5
    margin-left: 40px
  .form
    height: calc( 100% - 96px )
    .master-container
      position: relative
      padding-top: 2px
      padding-bottom: 2px
      height: 100%
      .container
        overflow-y: auto
        height: 100%
        .content
          padding-left: 40px
          padding-bottom: 20px
          .upload-container
            margin-bottom: 32px
            display: flex
            flex-direction: row
            .text
              margin-left: 20px
              .subtitle
                color: $gray_light_800
              p:not(.subtitle)
                color: $gray_light_700
            .upload
              position: relative
              cursor: pointer
              .overlay
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                height: 100%
                width: 100%
                opacity: 0
                transition: .3s ease
                background: rgba(0, 0, 0, 0.4)
                display: flex
                align-items: center
                justify-content: center
                border-radius: 50px
              &:hover
                .overlay
                  opacity: 1
          .form-items
            width: 512px
            .form-item
              .ant-form-item-label
                text-align: left
.phone-number
  display: flex
  flex-direction: row-reverse

.footer
  border-top: 1px solid $gray_dark_100
  padding: 12px
  display: flex
  flex-direction: row
  justify-content: flex-end
</style>
