<template>
  <svg
    class="icon-coding"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7954 9.48507L15.1704 4.58504C14.8861 4.28355 14.4116 4.2698 14.1101 4.5543C13.8091 4.83856 13.7951 5.31329 14.0796 5.61454L18.2189 9.99956L14.0796 14.3851C13.7951 14.6863 13.8091 15.1608 14.1101 15.4453C14.2551 15.5821 14.4404 15.6498 14.6249 15.6498C14.8242 15.6498 15.0232 15.5708 15.1704 15.4151L19.7954 10.5148C20.0681 10.2256 20.0681 9.77405 19.7954 9.48507Z"
    />
    <path
      d="M5.92154 14.3849L1.78253 9.99962L5.92154 5.61436C6.2058 5.31311 6.19205 4.83834 5.8908 4.55412C5.58978 4.26986 5.11478 4.28361 4.83056 4.58486L0.205529 9.48489C-0.0672074 9.77388 -0.0672074 10.2256 0.205529 10.5147L4.83079 15.4149C4.97829 15.5712 5.17704 15.6499 5.3763 15.6499C5.56079 15.6499 5.74607 15.5819 5.8908 15.4452C6.19232 15.1609 6.2058 14.6862 5.92154 14.3849Z"
    />
    <path
      d="M11.2129 2.05837C10.8036 1.99638 10.4206 2.27736 10.3581 2.68662L8.15811 17.0867C8.09561 17.4962 8.37686 17.879 8.78635 17.9415C8.82487 17.9472 8.86284 17.95 8.90061 17.95C9.26487 17.95 9.58436 17.6842 9.64112 17.3132L11.8411 2.91315C11.9036 2.50361 11.6224 2.12087 11.2129 2.05837Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChatbot',
  props: {
    fill: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
