<template>
  <div class="view-previous-file">
    <div class="view-previous-file-container">
      <a-icon
        :type="icon"
        style="font-size: 24px; color: #262a31; margin-right: 12px"
      />
      <p v-if="percent === 100" class="body-2 ellipsis">{{ nameFile }}</p>
      <a-progress :percent="percent" size="small" v-if="percent !== 100" />
    </div>
    <a-icon
      class="icon-delete"
      type="delete"
      style="font-size: 24px; color: #262a31; margin-left: 12px"
      @click="deleteFile"
    />
  </div>
</template>
<script>
export default {
  name: 'ViewPreviousFile',
  props: {
    nameFile: {
      type: String,
      required: true,
    },
    percent: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      enum: ['pdf', 'word', 'excel', 'ppt'],
      required: true,
    },
  },
  methods: {
    deleteFile() {
      this.$emit('delete')
    },
  },
}
</script>
<style lang="sass" scope>
.view-previous-file
  margin: 12px
  padding: 12px 10px
  display: flex
  justify-content: space-between
  &-container
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    p
      margin-bottom: 0px
      text-align: left
      color: $gray_dark_900
  &:hover
    background-color: #F3FBFF
</style>
