<template>
  <div>
    <SectionSchedule />
  </div>
</template>
<script>
import SectionSchedule from '@/app/schedule/components/organisms/SectionSchedule.vue'

export default {
  name: 'ScheduleTemplate',
  components: {
    SectionSchedule,
  },
  data: () => ({}),
}
</script>
