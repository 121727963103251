import initialState from './initialState'

const mutations = {
  /**
   * Setea las divisas de la empresa
   * @param {*} state
   * @param {Array} currencies
   */
  SET_DEFAULT_CURRENCIES(state, currencies) {
    state.default_currencies = currencies
  },
  /**
   * Añade el producto a la empresa
   * @param {*} state
   * @param {Object} product
   */
  ADD_COMPANY_PRODUCT(state, product) {
    state.company_products.unshift(product)
  },
  /**
   * Actualiza el producto
   * @param {} state
   * @param {Object} product
   * @param {String} idProduct
   * @param {Object} newProduct
   * @returns
   */
  UPDATE_COMPANY_PRODUCT(state, { idProduct, newProduct }) {
    let index = state.company_products.findIndex(
      (item) => item._id === idProduct
    )
    if (index === -1) return
    state.company_products[index] = newProduct
  },
  /**
   * Remueve el producto
   * @param {*} state
   * @param {*} idProduct
   */
  REMOVE_COMPANY_PRODUCT(state, idProduct) {
    state.company_products = state.company_products.filter(
      (product) => product._id !== idProduct
    )
  },
  /**
   * Setea los productos
   * @param {*} state
   * @param {Array} company_products
   */
  SET_COMPANY_PRODUCTS(state, company_products) {
    state.company_products = company_products
  },

  /**
   * Agrega los productos
   * @param {*} state
   * @param {Array} company_products
   */
  ADD_COMPANY_PRODUCTS(state, company_products) {
    const newProducts = [...state.company_products]
    const idsProducts = newProducts.map((product) => product._id)
    // if (!company_products.length) state.company_products = company_products
    company_products.forEach((newProduct) => {
      if (!idsProducts.includes(newProduct._id))
        state.company_products.push(newProduct)
    })
  },
  /**
   * Setea la paginación de los productos
   * @param {*} state
   * @param {Object} paginate
   */
  SET_PAGINATE_PRODUCTS(state, paginate) {
    state.paginate_company_products = paginate
  },
  /**
   * Actualiza el estado del producto
   * @param {*} state
   * @param {Object} product
   * @param {String} idProduct
   * @param {String} status
   * @returns
   */
  UPDATE_STATUS_PRODUCT(state, { idProduct, status }) {
    let index = state.company_products.findIndex(
      (item) => item._id === idProduct
    )
    if (index === -1) return
    state.company_products[index].active = status
  },
  /**
   * Setea los campos para productos
   * @param {*} state
   * @param {Array} fields
   */
  SET_FIELDS_PRODUCT(state, fields) {
    state.fields_company_products = fields
  },
  /**Setea la paginación de productos
   *
   * @param {*} state
   * @param {Object} paginate
   */
  SET_PAGINATE_FIELDS_PRODUCT(state, paginate) {
    state.paginate_fields_products = paginate
  },
  /**
   * Añade un campo para productos
   * @param {*} state
   * @param {Object} newField
   * @returns
   */
  ADD_FIELD_PRODUCT(state, newField) {
    const foundField = state.fields_company_products.some(
      (elem) => elem._id === newField._id
    )
    console.log(foundField)
    if (foundField) return // evitar que se repita

    state.fields_company_products.unshift(newField)
  },
  /**
   * Remueve un campo personalizado
   * @param {*} state
   * @param {String} idField
   */
  REMOVE_FIELD_PRODUCT(state, idField) {
    state.fields_company_products = state.fields_company_products.filter(
      (elem) => elem._id !== idField
    )
  },
  /**
   * Setea el valor a un campo personalizado existente
   * @param {*} state
   * @param {Object} field
   */
  UPDATE_CUSTOM_FIELD(state, field) {
    let foundField = state.fields_company_products.find(
      (elem) => elem._id === field._id
    )
    if (!foundField) return
    if (foundField.name) foundField.name = field.name
    if (foundField.type) foundField.type = field.type
    if (foundField.options) foundField.options = field.options
  },
  /** RESET MODULE */
  RESET_PRODUCT_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
}
export default mutations
