import moment from 'moment'

const COLUMNS_BLACKLIST = [
  {
    title: 'N°',
    key: 'order',
    dataIndex: 'order',
    width: 60,
    fixed: 'left',
  },
  {
    title: 'Número telefónico',
    dataIndex: 'apiClientId',
    key: 'apiClientId',
  },
  {
    title: 'ID de canal',
    key: 'channels_companies',
    scopedSlots: { customRender: 'channels_companies' },
  },
  {
    title: 'Bloqueado por',
    dataIndex: 'blockedFor',
    key: 'user_names',
    customRender: (value) => `${value.names || ''} ${value.surnames || ''}`,
  },
  {
    title: 'Fecha',
    dataIndex: 'created_at',
    key: 'created_at',
    customRender: (value) => `${moment(value).format('L')}`,
  },
  {
    title: 'Motivo',
    dataIndex: 'reason',
    key: 'reason',
    ellipsis: true,
  },
  {
    title: 'Acción',
    key: 'action',
    fixed: 'right',
    width: '240px',
    scopedSlots: { customRender: 'action' },
  },
]

export { COLUMNS_BLACKLIST }
