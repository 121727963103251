<template>
  <a-modal
    :visible="visible"
    :title="title"
    width="416px"
    :bodyStyle="bodyStyle"
    centered
    @cancel="$emit('onClose')"
  >
    <div class="connected-page">
      <!--Icono del canal-->
      <social-icon
        v-if="socialIcon"
        :type="socialIcon"
        :size="32"
        class="mrg-right-12"
      />
      <!--Imagen de página conectada-->
      <img
        v-else-if="picture"
        :src="picture"
        width="32px"
        height="32px"
        class="mrg-right-12"
      />
      <div>
        <h6 class="heading-h7 connected-page-name">
          {{ name }}
        </h6>
        <div class="connected-page-status">
          <p class="body-1">Conectado</p>
          <a-badge status="success" />
        </div>
      </div>
    </div>
    <template slot="footer">
      <a-button
        @click="onDisconnect"
        :loading="okLoading"
        :disabled="okDisabled"
        >Desvincular página</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'ModalSimpleChannel',
  components: {
    SocialIcon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    picture: {
      type: String,
      required: false,
      default: null,
    },
    socialIcon: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    onDisconnect: {
      type: Function,
      required: false,
      default: () => ({}),
    },
    okLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
}
</script>

<style lang="sass" scoped>
.connected-page
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  border: 1px solid $gray_dark_200
  border-radius: 8px
  width: 256px
  padding: 8px 12px
  &-name
    margin-bottom: 0
    color: $gray_dark_900
  &-status
    display: flex
    flex-direction: row
    align-items: center
    p
      margin-bottom: 0
      margin-right: 4px
      color: $gray_dark_800
</style>
