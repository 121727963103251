<template>
  <div class="list-cards">
    <CustomSectionScroll
      :masterStyles="{ height: '100%' }"
      idMater="list-cards-master"
    >
      <a-row :gutter="[20, 20]" style="margin-right: 0px">
        <a-col :span="12">
          <div class="add-new-card" @click="handleAddCard">
            <a-icon type="plus" style="font-size: 24px; color: #7d7f83" />
            <p class="body-2">Agregar nueva tarjeta</p>
          </div>
        </a-col>
        <template v-if="profile.cards">
          <template v-for="card in profile.cards">
            <a-col :key="card.idCard" :span="12">
              <CreditCard
                :name-owner="`${card.name} ${card.surnames}`"
                :numberCard="card.lastDigits"
                :type="card.type"
                :dateExpired="card.expirationDate"
                :selected="localIdCardSelected === card.idCard ? true : false"
                :subscription="card.forSuscription ? true : false"
                width="100%"
                @handleSelected="handleSelectCard(card)"
              />
            </a-col>
          </template>
        </template>
      </a-row>
    </CustomSectionScroll>
  </div>
</template>

<script>
import CreditCard from '@/app/shared/components/cards/CreditCard.vue'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListCards',
  components: {
    CreditCard,
    CustomSectionScroll,
  },
  props: {
    idCardSelected: { type: String, default: null, required: false },
  },
  data: () => ({
    localIdCardSelected: null,
  }),
  mounted() {
    this.localIdCardSelected = this.idCardSelected
  },
  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    /**Emite el cambio de tipo  de formulaio
     * @param {String} type - tipo de formulario add, edit
     * @param {Object} card - los datos de la card
     */
    handleEmitTypeForm(type, card) {
      if (type === 'edit') this.localIdCardSelected = card.idCard
      else this.localIdCardSelected = null
      this.$emit('handleChangeTypeForm', type, card ?? null)
    },
    /**
     * Cuando se selecciona una tarjeta
     * @param {Object} card
     * @param {String} card.idCard
     */
    handleSelectCard(card) {
      this.localIdCardSelected = card.idCard
      this.$emit('onSelect', card ?? null)
    },
    /**
     * Cuando se agrega una tarjeta
     * @param {Object} card
     * @param {String} card.idCard
     */
    handleAddCard(card) {
      this.localIdCardSelected = null
      this.$emit('onAdd', card ?? null)
    },
  },
}
</script>

<style lang="sass" scoped>
.list-cards
  height: calc( 100% - 68px )
.add-new-card
  border: 1px dashed $gray_dark_400
  background-color: $gray_2
  height: 146px
  width: 100%
  border-radius: 8px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  p
    margin-top: 8px
    color: $gray_dark_600
  &:hover
    border-color: $blue_6
    cursor: pointer
</style>
