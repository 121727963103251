<template>
  <div class="calendar-shedule">
    <a-calendar @panelChange="handlerCalendarChange" :locale="localePicker">
      <ul slot="dateCellRender" slot-scope="value" class="events">
        <PopOverEvent
          v-for="item in getListDaysMonth(value)"
          :key="item.schedule._id"
          :scheduleSelected="scheduleSelected"
          :item="item"
          @handleSchedule="handleSchedule"
          @handleChat="handleChat"
          @handleEdit="showModalSchedule"
          @handleComplete="handleComplete"
          @handleDelete="handleDelete"
        />
      </ul>
      <template slot="monthCellRender" slot-scope="value">
        <div class="notes-month">
          <i v-if="!getMonthData(value)" class="gray_6">Sin agendas</i>
          <span v-else-if="getMonthData(value) === 1"
            >{{ getMonthData(value) }} agenda</span
          >
          <span v-else>{{ getMonthData(value) }} agendas</span>
        </div>
      </template>
    </a-calendar>
    <!--MODAL AGENDAR-->
    <a-modal
      v-model="visibleMoSchedule"
      title="Editar evento"
      width="400px"
      :footer="null"
      centered
    >
      <a-form layout="vertical" :form="form" @submit="handleSubmitEdit">
        <a-form-item label="Título">
          <a-input
            v-decorator="[
              'title',
              {
                rules: [
                  { required: true, message: 'Por favor ingresa el titulo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Nombre example"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Fecha y hora">
          <a-date-picker
            v-decorator="['range', config]"
            :show-time="showTime"
            format="LLL"
            :locale="localePicker"
            style="width: 100%"
            size="large"
          />
        </a-form-item>
        <a-form-item label="Descripción">
          <a-textarea
            v-decorator="['description']"
            placeholder="Describe el asunto de la reunión"
            :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-item>
        <div class="btns">
          <a-form-item>
            <a-button
              type="default"
              size="large"
              class="btn-cancel"
              @click="handleCancelEdit"
            >
              Cancelar
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="btn-ok"
            >
              Agendar
            </a-button>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import attemptMixin from '@/app/shared/mixins/attempt'
import { mapGetters, mapActions } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import compareMixin from '@/app/shared/mixins/compare'
import PopOverEvent from '@/app/schedule/components/organisms/popovers/PopOverEvent.vue'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'ScheduleCalendar',
  components: {
    PopOverEvent,
  },

  data: () => ({
    moment,
    scheduleSelected: null,
    visiblePopover: false,
    visibleMoSchedule: false,
    showTime: {
      format: 'HH:mm', // formato para la hora
    },
    config: {
      // reglas para datepicker
      rules: [
        {
          type: 'object',
          required: true,
          message: 'Por favor selecciona fecha y hora',
        },
      ],
    },
    style: {
      //
      width: '220px',
    },
    date: {
      month: null,
      year: null,
      mode: 'month',
    },
  }),
  computed: {
    ...mapGetters(['schedules']),
  },
  mounted() {
    const today = new Date()
    this.date.month = today.getMonth() + 1
    this.date.year = today.getFullYear()
    this.intervalAttempt(() => {
      this.filterSchedules()
    })
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  mixins: [formatMixin, compareMixin, attemptMixin],
  methods: {
    ...mapActions([
      'selectTicketFromSchedule',
      'updateSchedule',
      'listSchedules',
    ]),
    /**
     * @param {Object} item Objeto del contenido del bagde de un evento
     * @param {Object} item.schedule Objeto del evento de la agenda
     */
    filterSchedules() {
      this.listSchedules({
        month: this.date.month,
        year: this.date.year,
        mode: this.date.mode,
      })
    },
    handlerCalendarChange(date, mode) {
      this.date.month = date.format('M')
      this.date.year = date.format('YYYY')
      this.date.mode = mode
      this.filterSchedules()
    },
    handleSchedule(item) {
      this.scheduleSelected = item.schedule
    },
    /**
     * Recorre cada día del calendario y busca si hay informacion para listar
     * @param {Date} value Valor del día del calendario
     * @return {Array} Devuelve la lista de pendientes correspondiente al día
     */
    getListDaysMonth(value) {
      let listData = []

      if (value.date) {
        /** Filtra los eventos del día, mes y año correspondiente */
        const scheduleFiltered = this.schedules.filter((sch) => {
          let date = moment(sch.scheduled_for)
          if (
            date.date() === value.date() &&
            date.month() === value.month() &&
            date.year() === value.year()
          )
            return true
          else return false
        })
        listData = scheduleFiltered.map((sh) => {
          let item = {}
          item.schedule = sh
          if (sh.completed_at) item.type = 'success'
          // Finalizado
          else if (this.dateIsLessThan(new Date(sh.scheduled_for), new Date()))
            item.type = 'error'
          // Como: Vencido
          else item.color = '#F4D234' // Como: Pendiente
          item.content = sh.title
          return item
        })
      }
      return listData.sort((a, b) =>
        a.schedule.scheduled_for > b.schedule.scheduled_for
          ? 1
          : a.schedule.scheduled_for < b.schedule.scheduled_for
          ? -1
          : 0
      ) // Reordena los eventos de una fecha por orden de fecha
    },
    getMonthData(value) {
      const indexMonth = value.month()

      return this.schedules[indexMonth]?.quantity
    },
    /** Ir al Chat correspondiente a la Agenda */
    handleChat() {
      this.selectTicketFromSchedule({
        schedule: this.scheduleSelected,
        attempt: true,
      })
    },
    /** Dar el evento de la agenda como completado */
    handleComplete() {
      const schedule = this.scheduleSelected
      schedule.completed_at = new Date()
      this.updateSchedule(schedule)
    },
    /** Eliminar Agenda */
    handleDelete() {
      const schedule = this.scheduleSelected
      schedule.deleted = true
      this.updateSchedule(schedule)
    },
    /**
     * @param {Object} item Objeto del contenido del bagde de un evento
     * @param {Object} item.schedule Objeto del evento de la agenda
     */
    showModalSchedule() {
      this.visibleMoSchedule = true
      setTimeout(() => {
        this.form.setFieldsValue({
          title: this.scheduleSelected.title,
          range: moment(this.scheduleSelected.scheduled_for),
          description: this.scheduleSelected.description,
        })
      }, 100)
    },
    /** Mostrar Formulario de Editar */
    hideModalSchedule() {
      this.visibleMoSchedule = false
    },
    /** Guardar cambios de Formulario de Editar */
    handleSubmitEdit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const schedule = this.scheduleSelected
          schedule.title = values.title
          ;(schedule.scheduled_for = values.range._d),
            (schedule.description = values.description)
          this.updateSchedule(schedule)
          this.visibleMoSchedule = false
        }
      })
    },
    /** Ocultar Formulario de Editar */
    handleCancelEdit() {
      this.visibleMoSchedule = false
    },
  },
}
</script>

<style lang="sass" scoped>

.gray_6
  color: $gray_6
.calendar-shedule
  border: 1px solid $gray_light_200
  height: 92%
.title
  color: $gray_dark_900
  margin: 12px 0
.options-popover
  display: flex
  flex-direction: row
  justify-content: flex-end
  min-width: 183px
.option-action
  font-size: 16px
  margin-left: 12px
  cursor: pointer
.calendar-event-select:hover
  background-color: $background_000
.calendar-event-select:active
  background-color: $gray_light_200
.events
  list-style: none
  margin: 0
  padding: 0

.events .ant-badge-status
  overflow: hidden
  white-space: nowrap
  width: 100%
  text-overflow: ellipsis
  font-size: 12px

.notes-month
  text-align: center
  font-size: 28px

.notes-month section
  font-size: 28px

.ant-form-item
  margin-bottom: 0px
.btn-ok
  margin-left: 8px
.btns
  margin-top: 20px
  display: flex
  flex-direction: row
  justify-content: flex-end
</style>

<style lang="sass">
.calendar-shedule
  .ant-fullcalendar
    &-fullscreen
      height: 95%
    &-calendar-body
      height: 100%
      overflow-y: auto
      &::-webkit-scrollbar
        width: 10px
      &::-webkit-scrollbar-track
        background: transparent
        border-left: solid 5px transparent
      &::-webkit-scrollbar-thumb
        border-radius: 8px
        box-shadow: inset 0 0 10px 10px $gray_dark_100
        border: solid 3px transparent
      &::-webkit-scrollbar-thumb:hover
        box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
