<template>
  <div class="card_provider" :class="{ card_provider__default: type }">
    <a-tag v-if="type" color="#108ee9" class="card_provider__tag">
      Recomendado
    </a-tag>
    <a-row v-if="images">
      <template v-for="(image, index) in images">
        <!--Icono de proveedor-->
        <img
          :src="require(`@/app/channels/assets/${image}`)"
          alt="Card de proveedor"
          :key="index"
        />
        <!--Icono de suma-->
        <a-icon
          v-if="images[index + 1]"
          type="plus"
          :key="`icon${index}`"
          class="icon_plus"
        />
      </template>
    </a-row>
    <h6 class="heading-h6 card_provider__title">{{ title }}</h6>
    <ul class="card_provider__list">
      <li
        v-for="(benefit, index) in benefits"
        :key="index"
        class="card_provider__list__item"
      >
        <a-icon
          type="check"
          style="font-style: 16px; margin-right: 12px; color: #108ee9"
        />
        <p class="body-2">{{ benefit }}</p>
      </li>
    </ul>
    <!--mas informacion-->
    <template v-if="showMoreInformation">
      <span class="content-link">
        ¿Quieres saber más?
        <a :href="link" class="link" target="_blank" rel="noopener noreferrer">
          Mira los detalles aquí
        </a>
      </span>
    </template>
    <div class="card_provider__footer">
      <a-button :type="typeButton" @click="() => $emit('onClickButton')">
        {{ nameButton }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CardProvider',
  props: {
    type: {
      type: String,
      require: false,
    },
    title: {
      type: String,
      require: false,
      default: 'Integrate with provider',
    },
    benefits: {
      type: Array,
      require: false,
      default: () => ['Gana más puntos', 'Gana más puntos', 'Gana más puntos'],
    },
    nameButton: {
      type: String,
      require: false,
      default: 'Integrate with provider',
    },
    images: {
      type: [Array, String],
      require: false,
    },
    link: {
      type: String,
      require: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['show_documentation']),

    /**
     * @return {String} tipo de boton
     */
    typeButton() {
      if (this.type === 'default') return 'primary'
      else return ''
    },
    /**
     * @return {Boolean} muestra o no más información
     */
    showMoreInformation() {
      return this.type === 'default' && this.show_documentation
    },
  },
}
</script>

<style lang="sass" scoped>
.icon_plus
  margin: auto 12px auto 12px
.card_provider
  border: 1px solid $gray_5
  padding: 32px 20px
  border-radius: 8px
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  height: 100%
  &__tag
    position: absolute
    top: -12px
    margin-right: 0
  &__title
    margin: 12px auto 12px
  &__default
    border: 1px solid $blue_6
    .card_provider__list
      color: $gray_dark_900
  &__list
    padding-left: 0px
    width: 100%
    margin-bottom: 0px
    &__item
      list-style: none
      margin-bottom: 8px
      display: flex
      align-items: center
      p
        margin-bottom: 0px
  &__footer
    display: flex
    width: 100%
    height: 100%
    align-items: flex-end
    justify-content: center
  .content-link
    margin: 12px auto 24px
    display: flex
    .link
      margin-left: 4px
</style>
