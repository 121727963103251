<template>
  <a-form
    :form="form"
    layout="vertical"
    :style="{ width: `${width}` }"
    :class="{ background_default: showBackground }"
    @submit.prevent="handleSubmit"
  >
    <CustomSectionScroll
      idMaster="form-base-account"
      :contentStyles="{ padding: '16px 24px' }"
      :masterStyles="{ height: '500px' }"
    >
      <h6 class="heading-h7 form_subtitle">Información personal</h6>
      <a-divider class="form_divider" />

      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item label="Nombres">
            <a-input
              v-decorator="[
                'names',
                {
                  rules: [
                    { required: true, message: 'Por favor rellena el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              placeholder="Escribir nombres"
              :maxLenght="30"
              :disabled="isGuest"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Apellidos">
            <a-input
              v-decorator="[
                'surnames',
                {
                  rules: [
                    { required: true, message: 'Por favor rellena el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              placeholder="Escribir apellidos"
              :maxLenght="30"
              :disabled="isGuest"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Género">
            <a-select
              v-decorator="[
                'gender',
                {
                  rules: [
                    { required: true, message: 'Por favor rellena el campo' },
                  ],
                },
              ]"
              placeholder="Seleccione el género"
              :disabled="isGuest"
            >
              <a-select-option value="female">Femenino</a-select-option>
              <a-select-option value="male">Masculino</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <h6 class="heading-h7 form_subtitle">Información de contacto</h6>
      <a-divider class="form_divider" />
      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item>
            <span slot="label">
              Correo&nbsp;
              <a-tooltip placement="right">
                <template slot="title">
                  Este email será usado para iniciar sesión en {{ app }}.
                </template>
                <a-icon type="info-circle" style="margin-top: 3px" />
              </a-tooltip>
            </span>
            <a-input
              v-model="newEmail"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo.',
                    },
                    {
                      type: 'email',
                      message: 'Ingresa un correo válido.',
                    },
                    {
                      whitespace: true,
                      message: 'No se aceptan espacios en blanco.',
                    },
                    {
                      min: 6,
                      message: '6 caracteres como mínimo.',
                    },
                    {
                      max: 50,
                      message: '50 caracteres como máximo.',
                    },
                  ],
                },
              ]"
              placeholder="Escribir correo"
              :maxLenght="60"
              :disabled="isGuest"
            >
              <a-icon
                slot="suffix"
                type="copy"
                v-clipboard:copy="newEmail"
                v-clipboard:success="onCopyEmail"
                v-clipboard:error="onError"
                class="icon-copy"
              />
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Teléfono">
            <a-row :gutter="8" type="flex">
              <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
              <a-col :span="16" :order="2">
                <a-input
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor rellena el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  :maxLenght="20"
                  :disabled="!countrySelected || isGuest ? true : false"
                  placeholder="Escribe aquí..."
                >
                  <a-tooltip
                    slot="suffix"
                    :title="`Ingresa un móvil real porque podrás usarlo por ${app} para alertas SMS`"
                  >
                    <a-icon
                      type="info-circle"
                      style="color: rgba(0, 0, 0, 0.45)"
                    />
                  </a-tooltip>
                </a-input>
              </a-col>
              <a-col :span="8" :order="1">
                <a-select
                  show-search
                  v-decorator="[
                    'code',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Por favor rellena el campo',
                        },
                      ],
                    },
                  ]"
                  option-filter-prop="children"
                  placeholder="País"
                  @change="handleSelectCountry('_id', $event)"
                  :disabled="isGuest"
                >
                  <a-select-option
                    v-for="country in countries"
                    :key="country._id"
                    :title="country.nameSpanish"
                  >
                    {{ country.dialCode }}
                    {{ country.nameSpanish }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <h6 class="heading-h7 form_subtitle">Información en {{ app }}</h6>
      <a-divider class="form_divider" />
      <a-row :gutter="12">
        <!-- Tipo de usuario -->
        <a-col :span="12">
          <a-form-item>
            <span slot="label">
              Tipo de usuario&nbsp;
              <a-tooltip placement="right">
                <template slot="title">
                  Conoce los perfiles de usuario de {{ app }}
                  <anchor
                    title="aquí"
                    :href="`${vari.WEB_DOC}primeros-pasos/gestion-usuarios/`"
                  />
                </template>
                <a-icon type="info-circle" style="margin-top: 3px" />
              </a-tooltip>
            </span>
            <a-select
              v-decorator="[
                'typeUser',
                {
                  rules: [
                    { required: true, message: 'Por favor rellena el campo' },
                  ],
                },
              ]"
              placeholder="Seleccione tipo de usuario"
              :disabled="
                disabledFieldUserType ||
                profile.type === 'supervisor' ||
                isGuest
              "
              @change="handleChangeTypeUser"
              class="form__selector__option"
            >
              <a-select-option
                v-for="typeUser in typeUsersAllowed"
                :key="typeUser.value"
              >
                <a-popover
                  v-if="typeUser.content"
                  :title="typeUser.content.title"
                  placement="right"
                >
                  <template slot="content">
                    <p
                      class="mrg-bottom-0"
                      v-html="typeUser.content.content"
                    ></p>
                  </template>
                  <div class="selector__option-container">
                    {{ typeUser.title }}
                  </div>
                </a-popover>
                <span v-else>
                  {{ typeUser.title }}
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <!-- Contraseña -->
        <a-col v-if="!account.socialLogin" :span="12">
          <a-form-item :label="passwordLabel">
            <a
              class="form-item-link__password"
              :disabled="isGuest"
              @click="handleGeneratePassword"
              >Generar contraseña</a
            >
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: account.socialLogin ? false : requirePassword,
                      message: 'Por favor rellena este campo',
                    },
                  ],
                },
              ]"
              :disabled="disabledPassword || isGuest"
              placeholder="Escribir contraseña"
            >
              <a-icon
                slot="suffix"
                type="copy"
                v-clipboard:copy="newPassword"
                v-clipboard:success="onCopyPassword"
                v-clipboard:error="onError"
                class="icon-copy"
              />
            </a-input>
          </a-form-item>
        </a-col>
        <!-- Integrción CRM externo -->
        <a-col :span="12">
          <a-form-item
            v-if="hasCRMIntegration"
            :label="`${externalCRMIntegration.field.name} (${externalCRMIntegration.platform})`"
          >
            <a-input
              v-decorator="[
                'id_user_external_crm',
                {
                  initialValue: '',
                  rules: [
                    { type: '', message: 'No es un ID válido.' },
                    {
                      pattern: /^([0-9])*$/g,
                      message: 'Los caracteres deben ser números',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              placeholder="10000"
              :maxLength="11"
              :disabled="isGuest"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <!-- Colas -->
      <div class="form__label">
        <p class="body-2 mrg-bottom-0">Asignar colas</p>
        <a-tooltip placement="right">
          <template slot="title">
            Conoce cómo te ayudan las colas
            <a
              :href="`${vari.WEB_DOC}caracteristicas/colas/`"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
          </template>
          <a-icon
            type="info-circle"
            style="margin-top: 3px; margin-left: 4px"
          />
        </a-tooltip>
      </div>
      <TransferAssign
        ref="transfer_assign"
        :data-source="formattedLines(lines, profile)"
        :selectedItems="validateAssignLines"
        @onSelectedItems="handleSelectedItems"
      />
      <div v-if="!isGuest && !account.owner" class="form__actions">
        <a
          v-if="!requirePassword || payment.status == 'approved'"
          class="form__action"
          @click="
            handleChangeActive(
              account.active,
              `${account.names} ${account.surnames}`
            )
          "
          :class="{
            link__primary: account.active === 'inactive',

            link__default: account.active === 'active',
          }"
          >{{
            account.active === 'active' ? 'Inactivar' : 'Activar usuario'
          }}</a
        >
        <a
          v-if="!isGuest && showDelete"
          class="link__danger form__action"
          @click="$emit('onDelete')"
        >
          Eliminar cuenta</a
        >
      </div>
    </CustomSectionScroll>
    <div class="form_footer">
      <a-button
        class="form_footer_button__default"
        @click="() => $emit('onCancel')"
        >{{ cancelText }}</a-button
      >
      <a-button
        key="submit"
        type="primary"
        html-type="submit"
        class="form_footer_button__primary"
        :disabled="is_guest && !isGuest"
        :loading="loading"
        >{{ okText }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import vari from '@/app/shared/utils/variables'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import Anchor from '@/app/shared/components/atoms/Anchor'
import { mapGetters, mapActions } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import TransferAssign from '@/app/accounts/components/TransferAssign'
import accountsMixin from '@/app/accounts/mixins/accountsMixin'
import { USERS } from '@/app/accounts/utils/accountsUtil'
import {
  generatePassword,
  generateUsername,
} from '@/app/accounts/utils/generate'

export default {
  name: 'FormBaseAccount',
  mixins: [compareMixin, accountsMixin],
  components: {
    CustomSectionScroll,
    TransferAssign,
    Anchor,
  },
  props: {
    width: {
      type: String,
      require: false,
      default: '460px',
    },
    showBackground: {
      type: Boolean,
      require: false,
      default: true,
    },
    passwordLabel: {
      type: String,
      require: false,
      default: 'Contraseña',
    },
    lines: {
      // todas las colas activas de la empresa
      type: Array,
      require: false,
      default: () => [{}],
    },
    countries: {
      type: Array,
      require: false,
      default: () => [{}],
    },
    okText: {
      type: String,
      require: false,
      default: 'Ok',
    },
    cancelText: {
      type: String,
      require: false,
      default: 'Cancel',
    },
    active: {
      type: String,
      require: false,
    },
    disabledFieldUserType: {
      type: Boolean,
      require: false,
      default: false,
    },
    disabledFieldStatusUser: {
      type: Boolean,
      require: false,
      default: false,
    },
    requirePassword: {
      type: Boolean,
      require: false,
      default: false,
    },
    showFieldStatusUser: {
      type: Boolean,
      require: false,
      default: true,
    },
    account: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    assignedLines: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    payment: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    vari,
    USERS,
    newPassword: null,
    countrySelected: null,
    disabledPassword: true,
    newUsername: null,
    newEmail: null,
    disabledFieldLines: false,
    newLinesAssigned: [],
    enableForIdUserExternal: true, // Para comprobar si se debe habilitar el campo de ID_USUARIO_EXTERNO si tiene el modo agente activado
    app: process.env.VUE_APP_NAME,
    titleIntegration: '',
    localTypeUser: '',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'FormBaseAccount' })
  },
  mounted() {
    this.setFieldsValue()
  },
  watch: {
    account: {
      handler() {
        setTimeout(() => {
          this.setFieldsValue()
        }, 2000)
      },
    },
  },
  computed: {
    ...mapGetters(['profile', 'company', 'roles', 'is_guest']),

    /**
     * Colas asignadas validadas
     * @return {Object[]}
     */
    validateAssignLines() {
      const typeUser = this.account.typeUser || this.localTypeUser
      if (typeUser) {
        // Valida si el usuario está habilitado para tener asignadas colas
        const enabledAssignedLines = USERS[typeUser].assign_lines
        return enabledAssignedLines ? this.assignedLines : []
      } else return []
    },
    /**Deshabilita el campo de username */
    disabledUsername() {
      if (
        (this.account.username && this.account.username.length > 0) ||
        (this.newUsername && this.newUsername.length > 0)
      )
        return false
      else return true
    },
    /**
     * Muestra el campo solo si tiene integracion con un CRM externo
     * @returns {Object|Null}
     */
    externalCRMIntegration() {
      if (!this.company) return null
      const crmIntegration = this.company.integrations.find(
        (int) => int.active === true && int.field.name && int.field.key
      )
      return crmIntegration || null
    },
    /**
     * Tiene alguna integración CRM
     * @returns {Boolean}
     */
    hasCRMIntegration() {
      return (
        this.externalCRMIntegration &&
        this.enableForIdUserExternal &&
        this.externalCRMIntegration.field
      )
    },
    /**
     * Tipos de usuarios permitidos
     */
    typeUsersAllowed() {
      if (this.typeUserExtra)
        return [...this.typeUsersPerPermissions, this.typeUserExtra]
      else return this.typeUsersPerPermissions
    },
    /**
     * Si el tipo de la cuenta no existe en los permitidos,
     * entonces insertará esa opción.
     */
    typeUserExtra() {
      if (this.account.typeUser) {
        const typeUsers = this.typeUsersPerPermissions.map(
          (typeUser) => typeUser.value
        )
        if (!typeUsers.includes(this.account.typeUser))
          return USERS[this.account.typeUser]
        return null
      } else return null
    },
    /**
     * @returns {Boolean} - si el usuario del formulario es tipo guest
     */
    isGuest() {
      if (this.account && this.account.subType === 'guest') return true
      else return false
    },
  },
  methods: {
    ...mapActions(['addNotification']),

    /**Setea valores a los campos del formulario */
    async setFieldsValue() {
      let newTypeUser = null
      if (this.account.typeUser) {
        newTypeUser = USERS[this.account.typeUser].value
      } else newTypeUser = undefined
      this.form.setFieldsValue({
        names: this.account.names ? this.account.names : '',
        surnames: this.account.surnames ? this.account.surnames : '',
        gender: this.account.gender ? this.account.gender : undefined,
        email: this.account.email ? this.account.email : '',
        phone: this.account.phone ? this.account.phone : '',
        code: this.account.code ? this.account.code : undefined,
        typeUser: newTypeUser,
        active: this.account.active ? this.account.active : undefined,
        agentMode: this.account.agentMode ? this.account.agentMode : undefined,
      })
      // Si no se agregar este delay, no se setea el dato ya que tarda en renderizar el componente
      setTimeout(() => {
        this.form.setFieldsValue({
          id_user_external_crm: this.account.id_user_external_crm,
          agentMode: this.account.agentMode,
        })
      }, 1000)
      this.newUsername = this.account.username
      this.newEmail = this.account.email
      // compare.js
      if (this.isAllowedFor(newTypeUser, ['master'])) {
        if (this.account.agentMode) {
          // Master modo agente
          this.disabledFieldLines = false
          this.enableForIdUserExternal = true
        } else {
          // Master
          this.disabledFieldLines = true
          this.enableForIdUserExternal = false
        }
      }
      if (this.isAllowedFor(newTypeUser, ['admin'])) {
        if (this.company.settings.userMode.adminAgent) {
          // Admin modo agente
          this.disabledFieldLines = false
          this.enableForIdUserExternal = true
        } else {
          // Admin
          this.disabledFieldLines = true
          this.enableForIdUserExternal = false
        }
      } else if (this.isAllowedFor(newTypeUser, ['agent'], { literal: true })) {
        this.disabledFieldLines = false
        this.enableForIntegrationCompany = true
        this.enableForIdUserExternal = true
      } else {
        this.disabledFieldLines = false
        this.enableForIdUserExternal = false
      }
      this.handleSelectCountry('dialCode', this.account.code)
    },
    /**Contraseña copiada correctamente */
    onCopyPassword() {
      this.$message.success('Contraseña copiada')
    },
    /**Evento de error para el copiado */
    onError() {
      this.$message.error('Fallo')
    },
    /**Id de usuario copiado correctamente */
    onCopyIdUser() {
      this.$message.success('ID de usuario copiado')
    },
    /**Correo copiado correctamente */
    onCopyEmail() {
      this.$message.success('Correo copiado')
    },
    /**Submit de formulario */
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.email = values.email.toLowerCase()
          values.lines = this.newLinesAssigned.map((newLine) => newLine.key)
          values.code = this.countrySelected
            ? this.countrySelected.dialCode
            : values.code
          values.externalCRMIntegration = this.externalCRMIntegration
          this.$emit('onSubmit', values)
          // Crea notificaciones si fueron asignados o desasignado de colas
          const linesAssigned = this.newLinesAssigned.filter(
            (newLine) =>
              !this.validateAssignLines.some((line) => line.key === newLine.key)
          )
          // Si hubieron colas asignadas
          if (linesAssigned.length > 0) {
            linesAssigned.forEach((line) => {
              const activity = {
                type: 'assign-to-line',
                line: line.title,
                users: [this.account.userId],
              }
              this.addNotification(activity)
            })
          }
          const linesDeassigned = this.validateAssignLines.filter(
            (line) =>
              !this.newLinesAssigned.some((newLine) => newLine.key === line.key)
          )
          // Si hubieron colas desasignadas
          if (linesDeassigned.length > 0) {
            linesDeassigned.forEach((line) => {
              const activity = {
                type: 'deassign-to-line',
                line: line.title,
                users: [this.account.userId],
              }
              this.addNotification(activity)
            })
          }
        } else {
          this.$message.error('Hay campos sin completar')
        }
      })
    },
    /**
     * Selecciona el código de país
     * @param {String} key Nombre del atributo a buscar
     * @param {String} value Valor del código seleccionado
     */
    handleSelectCountry(key, value) {
      this.countrySelected = this.countries.find(
        (country) => country[key] === value
      )
    },
    /** deshabilita el select si value es Administrador
     * @param {String} value - valor del usuario seleccionado
     */
    handleChangeTypeUser(value) {
      this.localTypeUser = value
      if (value === 'admin') {
        this.disabledFieldLines = true
      } else if (value === 'agent') {
        this.disabledFieldLines = false
      } else {
        this.disabledFieldLines = false
      }
    },
    /**Genera la contraseña */
    handleGeneratePassword() {
      if (this.isGuest) return
      this.disabledPassword = false
      const length = 10
      const hasLower = true
      const hasUpper = true
      const hasNumber = true
      const hasSymbol = true
      this.newPassword = generatePassword(
        hasLower,
        hasUpper,
        hasNumber,
        hasSymbol,
        length
      )
      this.form.setFieldsValue({
        password: this.newPassword,
      })
    },
    /**Genera el id de usuario */
    handleGenerateUsername() {
      const names = this.form.getFieldValue('names')
      const surnames = this.form.getFieldValue('surnames')
      const sufix = this.company.sufix
      const newUserName = generateUsername(names, surnames, sufix)
      this.newUsername = newUserName
    },
    /**Limpia todos los datos */
    handleResetForm() {
      this.$refs.transfer_assign.handleReset()
      this.newPassword = null
      this.countrySelected = null
      this.disabledPassword = true
      this.newUsername = null
      this.newEmail = null
      this.form.resetFields()
      this.newLinesAssigned = []
    },
    /**
     * Formateo de colas asignadas
     * @param {Array} lines - todas las colas
     */
    formattedLines(lines) {
      const newFormatted = []
      lines.map((line) => {
        const newObj = {}
        const selectedUserType = this.form.getFieldValue('typeUser')

        const typeUser = this.account.typeUser || selectedUserType
        if (typeUser) {
          // solo si es master, verificará la propiedad agentMode
          if (typeUser.includes('master')) {
            if (Object.prototype.hasOwnProperty.call(this.account, 'agentMode'))
              newObj.disabled = !this.account.agentMode
            else newObj.disabled = false
          } else newObj.disabled = !USERS[typeUser].assign_lines
        }

        newObj.key = line._id
        newObj.title = line.name
        newFormatted.push(newObj)
      })
      return newFormatted
    },
    /**
     * Escucha la asignacion de colas
     * @param {String[]} selectedItems
     */
    handleSelectedItems(selectedItems) {
      this.newLinesAssigned = selectedItems
    },
    /**
     * Escucha el cambio de estado para el filtro
     * @param {String} active - active, inactive
     * @param {String} fullname
     */
    handleChangeActive(active, fullname) {
      const convertBoolean = active === 'active' ? true : false
      this.$emit('onChangeActive', convertBoolean, fullname)
    },
  },
}
</script>
<style lang="sass" scoped>
.icon-copy
  cursor: pointer
.icon-left
  margin-right: 4px
.background_default
  background-color: $white_000
.form_subtitle
  margin-bottom: 0px
  text-align: left
  color: $gray_dark_900
.form_divider
  margin: 2px auto 8px
.form-item-link__password
  position: absolute
  top: -35px
  right: 0
.form_footer
  padding: 12px
  display: flex
  justify-content: flex-end
  border-top: 1px solid $gray_5
  &_button__primary
    margin-left: 12px
.form__label
  margin-bottom: 8px
  display: flex
  align-items: center
.form
  &__actions
    margin-top: 16px
    display: flex
    justify-content: flex-end
  &__action
    &:last-child
      margin-left: 12px
.link__danger
  color: $red_6
.link__default
  color: $gray_7
.linnk__primary
  color: $blue_6

.form__selector__option
  width: 100%
.selector__option-container
  width: calc(100% + 24px)
  margin-left: -12px;
  padding-left: 12px;
</style>
