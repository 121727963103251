var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tree"},[(_vm.structureNode.layout === 'basic-node')?_c('basic-node',{staticClass:"tree__width",class:{ tree__connection: _vm.dataSource.parentNodeId },attrs:{"title":_vm.structureNode.title,"icon-type":_vm.structureNode.icon,"description":_vm.dataSource.drawer.question.content,"action":_vm.formattedAction(
        _vm.dataSource.drawer.action.type,
        _vm.dataSource.drawer.action.nodeType,
        _vm.dataSource.drawer.action.wait
      ),"selected":_vm.selectedNodeId === _vm.dataSource._id ? true : false,"tooltip-action":_vm.dataSource.drawer.action.type === 'none'
        ? _vm.structureNode.tooltip_text
        : null,"disabled":_vm.disabledActions,"only-read":_vm.dataSource.onlyRead,"attachment":_vm.dataSource.drawer.question.media
        ? _vm.dataSource.drawer.question.media.type
        : null},on:{"onPreviewAttachment":function($event){return _vm.handlePreviewAttachment(_vm.dataSource.drawer.question.media)},"onDelete":function($event){return _vm.handleShowConfirmToDelete({
        type: _vm.dataSource.drawer.question.type,
        nodeId: _vm.dataSource._id,
        parentNodeId: _vm.dataSource.parentNodeId,
        parentNodeType: _vm.dataSource.parentNodeType,
      })},"onSelect":function($event){return _vm.handleOpenDrawer(_vm.dataSource)}}}):(_vm.structureNode.layout === 'parent-node')?_c('parent-node',{staticClass:"tree__width",class:{
      tree__connection__card: _vm.structureNode.type === 'cards',
      tree__connection:
        _vm.structureNode.type !== 'cards' && _vm.dataSource.parentNodeId,
    },attrs:{"title":_vm.structureNode.title,"icon-type":_vm.structureNode.icon,"description":_vm.dataSource.drawer.question[_vm.structureNode.description_property],"selected":_vm.selectedNodeId === _vm.dataSource._id ? true : false,"disabled":_vm.disabledActions || _vm.disabledParentActionsLocal,"disabled-add":_vm.disabledParentNodes,"addText":_vm.structureNode.add_text,"only-read":_vm.dataSource.onlyRead,"node-type":_vm.structureNode.type},on:{"onAdd":function($event){return _vm.handleAddChild(_vm.dataSource._id, _vm.dataSource.drawer.question.type)},"onDelete":function($event){return _vm.handleShowConfirmToDelete({
        type: _vm.dataSource.drawer.question.type,
        nodeId: _vm.dataSource._id,
        parentNodeId: _vm.dataSource.parentNodeId,
        parentNodeType: _vm.dataSource.parentNodeType || null,
      })},"onSelect":function($event){return _vm.handleOpenDrawer(_vm.dataSource)}}}):_vm._e(),(_vm.structureNode.child)?[_c('div',{staticClass:"tree__options"},_vm._l((_vm.children),function(child,index){return _c('div',{key:index,class:{
          'tree__option__connection-brothers': _vm.children[index + 1],
          tree__option__first: index === 0 ? true : false,
        }},[(!_vm.structureNode.child.simulate_parent)?[_c('child-node',{staticClass:"tree__width tree__option",class:{ tree__option__connection: child.parentNodeId },attrs:{"title":_vm.structureNode.child.title,"description":child.text,"order":child.order,"quantity-orders":_vm.children.length,"data-source":child,"selected":_vm.selectedNodeId === child[_vm.structureNode.child.id_property]
                ? true
                : false,"show-delete":child.order === 1 ? false : true,"action":_vm.formattedAction(
                child.action.type,
                child.action.nodeType,
                child.action.wait
              ),"isAllowedChange":_vm.isSavedNode,"disabled":_vm.disabledActions,"node-type-parent":_vm.structureNode.type},on:{"onDelete":function($event){return _vm.handleShowConfirmToDelete({
                type: _vm.structureNode.child.type,
                parentNodeId: child.parentNodeId,
                childId: child[_vm.structureNode.child.id_property],
                parentNodeType: _vm.structureNode.type,
              })},"onSelect":function($event){return _vm.handleOpenDrawer(
                { ...child, onlyRead: _vm.dataSource.onlyRead },
                _vm.structureNode.child.id_property,
                _vm.dataSource._id
              )},"onChangeOrder":_vm.handleChangeOrderChild}}),(child.child)?_c('div',{staticClass:"tree__child"},[_c('tree',{attrs:{"data-source":{
                ...child.child,
                parentNodeType: _vm.structureNode.child.type,
              },"disabled-actions":_vm.disabledActions},on:{"onOpenDrawer":_vm.handleOpenDrawer,"onPreviewAttachment":_vm.handlePreviewAttachment}})],1):_vm._e()]:[_c('child-node',{staticClass:"tree__width tree__option",class:{ tree__option__connection: child.parentNodeId },attrs:{"title":_vm.structureNode.child.title,"description":child.title,"order":child.order,"quantity-orders":_vm.children.length,"data-source":child,"selected":_vm.selectedNodeId === child[_vm.structureNode.child.id_property],"simulate-parent":true,"show-delete":child.order === 1 ? false : true,"isAllowedChange":_vm.isSavedNode,"disabled":_vm.disabledActions || _vm.disabledChildActionsLocal,"node-type-parent":_vm.structureNode.type,"add-text":_vm.structureNode.child.add_text,"attachment":"image"},on:{"onDelete":function($event){return _vm.handleShowConfirmToDelete({
                type: _vm.structureNode.child.type,
                childId: child[_vm.structureNode.child.id_property],
                parentNodeId: child.parentNodeId,
                parentNodeType: _vm.structureNode.type,
              })},"onSelect":function($event){return _vm.handleOpenDrawer(
                child,
                _vm.structureNode.child.id_property,
                _vm.dataSource._id
              )},"onChangeOrder":_vm.changeOrderSimulateParent,"onAdd":function($event){return _vm.handleAddSubchild(
                child.parentNodeId,
                _vm.structureNode.type,
                child[_vm.structureNode.child.id_property]
              )},"onPreviewAttachment":function($event){return _vm.handlePreviewAttachment(
                child.media ? { type: 'image', ...child.media } : null
              )}}}),_c('div',{staticClass:"tree__options tree__subchildren"},_vm._l((child[
                _vm.structureNode.child.child.group
              ]),function(subchild,indexButton){return _c('div',{key:indexButton,class:{
                'tree__option__connection-brothers':
                  child[_vm.structureNode.child.child.group][indexButton + 1],
                tree__option__first: indexButton === 0 ? true : false,
              }},[_c('child-node',{staticClass:"tree__width tree__option",class:{ tree__option__connection: subchild.parentNodeId },attrs:{"title":_vm.structureNode.child.child.title,"description":subchild[_vm.structureNode.child.child.title_property],"order":subchild.order,"quantity-orders":child[_vm.structureNode.child.child.group].length,"data-source":subchild,"selected":_vm.selectedNodeId ===
                  subchild[_vm.structureNode.child.child.id_property],"action":_vm.formattedAction(
                    subchild.action.type,
                    subchild.action.nodeType,
                    false
                  ),"show-delete":subchild.order === 1 ? false : true,"isAllowedChange":_vm.isSavedNode,"disabled":_vm.disabledActions,"node-type-parent":_vm.structureNode.type},on:{"onDelete":function($event){return _vm.handleExecuteDeleteSubchild({
                    type: _vm.structureNode.child.child.type,
                    childId: child[_vm.structureNode.child.id_property],
                    subchildId:
                      subchild[_vm.structureNode.child.child.id_property],
                    parentNodeId: child.parentNodeId,
                    parentNodeType: _vm.structureNode.type,
                  })},"onSelect":function($event){return _vm.handleOpenDrawer(
                    subchild,
                    _vm.structureNode.child.child.id_property,
                    _vm.dataSource._id,
                    true,
                    child[_vm.structureNode.child.id_property]
                  )},"onChangeOrder":_vm.changeOrderSubchild}}),(subchild.child)?_c('div',{staticClass:"tree__child"},[_c('tree',{attrs:{"data-source":{
                    ...subchild.child,
                    parentNodeId:
                      subchild[_vm.structureNode.child.child.id_property],
                    parentNodeType: _vm.structureNode.child.child.type,
                  },"disabled-actions":_vm.disabledActions},on:{"onOpenDrawer":_vm.handleOpenDrawer,"onPreviewAttachment":_vm.handlePreviewAttachment}})],1):_vm._e()],1)}),0)]],2)}),0)]:_vm._e(),(_vm.dataSource.child)?_c('div',{staticClass:"tree__child"},[_c('tree',{attrs:{"data-source":_vm.dataSource.child,"disabled-actions":_vm.disabledActions},on:{"onOpenDrawer":_vm.handleOpenDrawer,"onPreviewAttachment":_vm.handlePreviewAttachment}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }