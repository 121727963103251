import { render, staticRenderFns } from "./CardQueue.vue?vue&type=template&id=35319819&scoped=true&"
import script from "./CardQueue.vue?vue&type=script&lang=js&"
export * from "./CardQueue.vue?vue&type=script&lang=js&"
import style0 from "./CardQueue.vue?vue&type=style&index=0&id=35319819&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35319819",
  null
  
)

export default component.exports