<template>
  <div
    class="
      content
      display-flex
      direction-column
      align--center
      justify-content--center
    "
  >
    <div
      v-if="data.sound"
      class="
        content__content-icon
        cursor-pointer
        display-flex
        justify-content--center
        align--center
      "
      type="button"
      @click="handlePlaySound(!isPlay)"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isPlay" v-bind:svg-inline="''" v-bind:class="'cursor-pointer content__icon-sound'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.996 18.7a.408.408 0 01-.229-.07c-1.4-.946-2.616-1.853-3.792-2.73-1.139-.849-2.316-1.726-3.656-2.636H.41A.408.408 0 010 12.856V7.144c0-.225.183-.408.408-.408H3.32c2.757-1.872 7.386-5.32 7.433-5.356a.408.408 0 01.652.328v16.584a.408.408 0 01-.408.408zM.816 12.447h2.628c.082 0 .161.025.229.07 1.399.946 2.614 1.853 3.79 2.729.986.735 2 1.492 3.125 2.272v-15C9.181 3.56 5.82 6.032 3.673 7.484a.408.408 0 01-.229.07H.816v4.894zM13.501 11.23c-.167-.152-.148-.41.004-.577a.91.91 0 000-1.305.408.408 0 11.573-.582c.334.33.518.768.518 1.234 0 .467-.174.916-.518 1.234a.421.421 0 01-.577-.004zM15.703 13.238a.407.407 0 01-.287-.699A3.545 3.545 0 0016.48 10c0-.959-.378-1.86-1.065-2.539a.408.408 0 11.573-.58A4.355 4.355 0 0117.298 10a4.356 4.356 0 01-1.309 3.12.406.406 0 01-.287.117z" fill="currentColor"/><path d="M17.613 15.124a.407.407 0 01-.287-.699A6.178 6.178 0 0019.184 10c0-1.671-.66-3.243-1.858-4.425a.408.408 0 01.574-.581A6.99 6.99 0 0120 10c0 1.892-.746 3.67-2.1 5.007a.407.407 0 01-.287.117z" fill="currentColor"/></svg>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'cursor-pointer content__icon-sound'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.752.894a.408.408 0 00-.427.037c-.05.037-4.935 3.678-7.841 5.65H.408A.408.408 0 000 6.99v6.02c0 .226.183.409.408.409h3.076c1.415.96 2.658 1.887 3.86 2.784 1.24.924 2.522 1.88 3.996 2.877a.408.408 0 00.637-.339V1.26a.408.408 0 00-.225-.365zm-.591 17.073c-1.2-.831-5.85-4.298-7.323-5.295a.408.408 0 00-.229-.07H.817V7.398h2.792a.408.408 0 00.229-.07C6.119 5.786 9.7 3.15 11.16 2.07v15.897zM16.897 6.897a3.103 3.103 0 100 6.206 3.103 3.103 0 000-6.206zm0 5.362A2.262 2.262 0 0114.637 10c0-.545.195-1.046.517-1.436l2.607 3.523c-.266.11-.558.172-.864.172zm1.55-.618L15.78 8.037A2.262 2.262 0 0119.156 10c0 .646-.273 1.229-.709 1.641z" fill="currentColor"/></svg>
    </div>
    <!--por dibujo svg-->
    <template v-if="data.graphIlustration === 'draw'">
      <div v-html="data.ilustration"></div>
    </template>
    <!--por ruta-->
    <img
      v-else-if="data.graphIlustration === 'image'"
      :src="data.ilustration"
      :alt="data.ilustration"
      style="cursor: auto"
      class="content__picture"
    />
    <!--ilustracion LOTTIE-->
    <lottie-vue-player
      v-else-if="data.graphIlustration === 'lottie'"
      autoplay
      loop
      mode="normal"
      :src="data.ilustration"
    />
    <!--si tiene audio-->
    <div v-if="data.sound" id="sounds_news" v-show="false">
      <audio
        ref="sound_news"
        :src="require(`@/app/commons/assets/sounds/${data.soundName}`)"
        :muted="!isPlay"
      ></audio>
    </div>
    <a-row
      v-if="showButton"
      class="display-block content__footer"
      type="flex"
      justify="center"
    >
      <a-button
        class="content__button"
        type="primary"
        size="large"
        @click="$emit('onClose')"
        >{{ data.buttonCloseText }}</a-button
      >
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'FullImageContent',
  props: {
    data: { type: Object, required: true },
  },
  data: () => ({
    isPlay: false,
  }),
  computed: {
    /**
     * @return {Boolean} Mostrar boton de cerrado
     */
    showButton() {
      return this.data.buttonCloseText && this.data.buttonCloseText.length
    },
  },
  methods: {
    /**
     * Activa o no el sonido
     * @param {Boolean} isPlay - true: activar sonido, false: parar sonido
     */
    handlePlaySound(isPlay) {
      const sound = this.$refs.sound_news
      this.isPlay = isPlay
      sound.muted = false
      sound.volume = 0.05
      if (isPlay) sound.play()
      else sound.muted = true
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  padding: 40px 4em
  position: relative
  min-width: 20em
.content__content-icon
  position: absolute
  width: 56px
  height: 56px
  top: 0
  left: 0
  color: rgba(0, 0, 0, 0.45)
  transition: color 0.3s
  &:hover
    color: rgba(0, 0, 0, 0.75)
.content__icon-sound
  color: inherit
.content__picture
  width: 50em
.content__footer
  margin-top: 40px
.content__button
  min-width: 148px
</style>
