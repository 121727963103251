<template>
  <div
    class="section-preview"
    v-if="profile"
    :class="{
      'height-without-stripe': $route.meta.is_workspace,
      'height-with-stripe': $route.meta.is_monitor,
    }"
  >
    <p class="body-4">Selecciona un ticket y comencemos</p>
    <!-- <img src="/img/chat_begin/img-chat-begin.png" width="auto" height="45%"/> -->
    <!-- MOTION DESIGN -->
    <div class="motion">
      <!-- <iframe src="/motion/login.html"></iframe> -->
      <img src="@/app/tickets/assets/img-chat-begin.png" style="cursor: auto" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SectionDefaultTickets',
  data: () => ({}),
  computed: {
    ...mapGetters(['profile']),
  },
}
</script>

<style lang="sass">
.height-without-stripe
  height: calc(100vh - #{$padding-heigth-inner * 2})
.height-with-stripe
  height: calc((100vh - 52px) - #{$padding-heigth-inner * 2})
.section-preview
  height: 100%
  width: 100%
  background-color: $white_000
  border-radius: 8px
  border: 1px solid #EBEBEB
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  p
    margin-bottom: 32px
    color: $gray-light-600
</style>
