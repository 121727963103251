import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'
import { message } from 'ant-design-vue'
import format from '@/app/shared/utils/format'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Obtener lista de chatbots
   * @param {vuex} context
   * @param {Object} payload
   * @param {Number} payload.page Número de página
   * @param {String} payload.status Estado del chatbot
   * @param {String} payload.name Nombre del chatbot
   * @param {String} payload.channel Canal del chatbot
   * @param {String} payload.channelCompanyId
   * @param {Boolean} [payload.restart=true] Limpia la lista de chatbots e inserta os nuevos datos
   * @returns {Object} {data,pagination}
   */
  async getListChatbots(
    context,
    {
      page,
      status,
      name,
      channel = 'all',
      channelCompanyId = 'all',
      restart = true,
      all = false,
    }
  ) {
    try {
      const url = `${
        vari.UHR
      }/bot/trees/${page}?all=${all}&status=${status}&name=${name}&channel=${channel}&channelCompanyId=${channelCompanyId}&date=${new Date().getTime()}`
      const response = await axios.get(url)
      if (restart) context.commit('SET_CHATBOTS', response.data.data)
      else context.commit('ADD_CHATBOTS', response.data.data)
      const paginate_chatbots = { ...response.data }
      delete paginate_chatbots.data
      context.commit('SET_PAGINATE_CHATBOTS', paginate_chatbots)
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },

  /**
   * Añade un nuevo chatbot
   * @param {*} context
   * @param {Object} chatbot
   */
  async addChatbot(context, chatbot) {
    try {
      const url = `${vari.UHR}/bot/tree`
      const response = await axios.post(url, chatbot)
      context.commit('ADD_CHATBOT', chatbot)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },

  /**
   * Elimina un chatbot
   * @param {*} context
   * @param {String} chatbotId -id del chatbot
   */
  async deleteChatbot(context, chatbotId) {
    try {
      const url = `${vari.UHR}/bot/tree/${chatbotId}`
      const response = await axios.delete(url)
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false }
    }
  },

  /**
   * Activa o inactiva un chatbot
   * @param {*} context
   * @param {Boolean} active - estado del chatbot
   * @param {String} chatbotId
   * @param {String} action - transfer, finish
   * @param {Object} actionsTransferLine - solo si la accion es de transferencia
   * @param {String} actionsTransferLine.action
   * @param {Object} actionsTransferLine.channels
   * @param {String} commentFinishTicket
   */
  async activeChatbot(
    context,
    { active, chatbotId, action, actionsTransferLine, commentFinishTicket }
  ) {
    try {
      const url = `${vari.UHR}/bot/tree/active/${chatbotId}`
      const response = await axios.patch(url, {
        active,
        action,
        actionsTransferLine,
        commentFinishTicket,
      })
      return response.data
    } catch (error) {
      console.error('error', error)
      return error
    }
  },

  /**
   * Actualiza los datos de un chatbot
   * @param {*} context
   * @param {String} chatbotId - id del chatbot
   * @param {Object} chatbotEdited - chatbot editado
   */
  async updatedChatbot(context, { chatbotId, chatbotEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/${chatbotId}`
      const response = await axios.put(url, chatbotEdited)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error?.response?.data
    }
  },

  /**
   * Obtiene toda la lista de nodos un chatbot
   * @param {*} context
   * @param {String} chatbotId id de chatbot
   */
  async getNodes(context, chatbotId) {
    try {
      const url = `${
        vari.UHR
      }/bot/tree/${chatbotId}/questions?date=${new Date().getTime()}`
      const response = await axios.get(url)
      context.commit('SET_NODES', response.data)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },

  /**
   * Obtiene un chatbot
   * @param {*} context
   * @param {String} chatbotId id de chatbot
   */
  async getChatbot(context, chatbotId) {
    try {
      const url = `${
        vari.UHR
      }/bot/tree/${chatbotId}?date=${new Date().getTime()}`
      const response = await axios.get(url)
      context.commit('SET_CHATBOT', response.data)
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },
  /**Limpia tree Nodes */
  resetTreeNodes(context) {
    context.commit('SET_TREE_NODES', null)
  },
  /**Limpia los Nodes */
  resetNodes(context) {
    context.commit('SET_NODES', [])
  },
  /**
   * Agrega un nodo de opciones al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeOptions
   * @param {String} nodeOptions.message
   * @param {String} nodeOptions.parentNodeId
   * @param {String} nodeOptions.optionNodeId - si proviene de una opcion multiple
   * @param {Array} nodeOptions.options
   * @param {Number} nodeOptions.options[].order - numero de orden de la opcion
   * @param {String} nodeOptions.options[].text
   * @param {Object} nodeOptions.action
   * @param {String} nodeOptions.action.type - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   * @param {String} nodeOptions.errorMessage
   */
  async addNodeOptions(context, { chatbotId, nodeOptions }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}`
      const response = await axios.post(url, { ...nodeOptions })
      if (response.data && response.data.drawer && response.data.drawer.options)
        context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('error addNodeOptions', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo mensaje
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeMessage
   * @param {String} nodeMessage.message
   * @param {String} nodeMessage.parentNodeId - id del nodo padre que lo contiene
   * @param {String} nodeMessage.optionNodeId - si proviene de una opcion
   * @param {String} nodeMessage.action - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   */
  async addNodeMessage(context, { chatbotId, nodeMessage }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/message/${chatbotId}`
      const response = await axios.post(url, { ...nodeMessage })
      if (response.data.chatbotId) context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('error addNodeMessage', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo mensaje
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeCatcher
   * @param {String} nodeCatcher.message
   * @param {String} nodeCatcher.parentNodeId - id del nodo padre que lo contiene
   * @param {String} nodeCatcher.optionNodeId - si proviene de una opcion
   * @param {String} nodeCatcher.action - continue, transfer, none
   * @param {String} nodeCatcher.action.nodeId
   * @param {Boolean} nodeCatcher.action.wait
   * @param {String} nodeCatcher.chatbotVarId
   */
  async addNodeCatcher(context, { chatbotId, nodeCatcher }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/catcher/${chatbotId}`
      const response = await axios.post(url, { ...nodeCatcher })
      context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('error addNodeCatcher', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo de cards  al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeList
   * @param {String} nodeList.parentNodeId
   * @param {Object} nodeList.childParent - si su padre es una opcion, boton o tarjeta
   * @param {String} nodeList.childParent.childId - id del nodo opcion, boton o tarjeta
   * @param {String} nodeList.childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
   * @param {String} nodeList.card.title
   * @param {Object} nodeList.card.button
   * @param {Object} nodeList.card.button.action
   * @param {String} nodeList.card.button.action.type - continue, transfer, none
   * @param {String} nodeList.card.button.action.message
   * @param {String} nodeList.errorMessage
   */
  async addNodeLists(context, { chatbotId, nodeList }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/lists/${chatbotId}`
      const response = await axios.post(url, { ...nodeList })
      if (response.data && response.data.drawer && response.data.drawer.lists)
        context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('[addNodeLists]', error)
      return error.response.data
    }
  },
  /**
   * Agregar opcion a opciones multiples
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   * @param {Object} newOption
   */
  async addOptionToNode(context, { chatbotId, nodeId, newOption }) {
    const node = context.getters.nodes.find((node) => node._id === nodeId)
    if (node !== undefined) newOption.order = node.drawer.options.length + 1
    else return
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}`
      const response = await axios.post(url, { ...newOption })
      if (response.data.success)
        context.commit('ADD_CHILD', {
          nodeId,
          child: response.data.data,
          typeNode: 'options',
        })
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo de opciones al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeButtons
   * @param {String} nodeButtons.message
   * @param {String} nodeButtons.parentNodeId
   * @param {String} nodeButtons.optionNodeId - si proviene de una opcion multiple
   * @param {Array} nodeButtons.options
   * @param {Number} nodeButtons.options[].order - numero de orden de la opcion
   * @param {String} nodeButtons.options[].text
   * @param {Object} nodeButtons.action
   * @param {String} nodeButtons.action.type - continue, transfer, none
   * @param {String} nodeButtons.action.nodeId
   * @param {String} nodeButtons.errorMessage
   */
  async addNodeButtons(context, { chatbotId, nodeButtons }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/buttons/${chatbotId}`
      const response = await axios.post(url, { ...nodeButtons })
      if (response.data && response.data.drawer && response.data.drawer.buttons)
        context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('error addNodeButtons', error)
      return error.response.data
    }
  },
  /**
   * Agrega un hijo a un nodo padre
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId
   * @param {String} args.nodeId
   * @param {Object} args.newChild
   * @param {String} args.typeNode
   * @param {String} args.propertyText
   */
  async addChildToNode(
    context,
    { chatbotId, nodeId, newChild, typeNode, propertyText }
  ) {
    const node = context.getters.nodes.find((node) => node._id === nodeId)
    if (node !== undefined) {
      const order = node.drawer[typeNode].length + 1
      newChild.order = order
      newChild[propertyText] = `${newChild[propertyText]} ${order}`
    } else return
    try {
      const url = `${vari.UHR}/bot/tree/question/${typeNode}/${chatbotId}/${nodeId}`
      const response = await axios.post(url, { ...newChild })
      if (response.data.success)
        context.commit('ADD_CHILD', {
          nodeId,
          child: response.data.data,
          typeNode,
        })
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Agrega un subhijo a un nodo de tipo hijo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId
   * @param {String} args.nodeId
   * @param {String} args.childId
   * @param {String} args.subchildType
   * @param {Object} args.newSubchild
   * @param {String} args.propertyText
   */
  async addSubchildToNode(
    context,
    {
      chatbotId,
      nodeId,
      nodeType,
      childId,
      subchildType,
      newSubchild,
      propertyText,
    }
  ) {
    const node = context.getters.nodes.find((node) => node._id === nodeId)

    const childStructure = NODES[nodeType].child
    if (node !== undefined) {
      const father = node.drawer[nodeType]

      const child = father.find(
        (child) => child[childStructure.id_property] === childId
      )

      const order = child[subchildType].length + 1
      newSubchild.order = order
      newSubchild[propertyText] = `${newSubchild[propertyText]} ${order}`
    } else return
    try {
      const url = `${vari.UHR}/bot/tree/question/${nodeType}/${chatbotId}/${nodeId}/${childId}`
      const response = await axios.post(url, { ...newSubchild })
      if (response.data.success)
        context.commit('ADD_SUBCHILD', {
          nodeId,
          childId,
          subchild: response.data.data,
          nodeType,
          subchildType,
          childIdProperty: childStructure.id_property,
        })
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo de cards  al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeCards
   * @param {String} nodeCards.parentNodeId
   * @param {Object} nodeCards.childParent - si su padre es una opcion, boton o tarjeta
   * @param {String} nodeCards.childParent.childId - id del nodo opcion, boton o tarjeta
   * @param {String} nodeCards.childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
   * @param {String} nodeCards.card.title
   * @param {Object} nodeCards.card.button
   * @param {Object} nodeCards.card.button.action
   * @param {String} nodeCards.card.button.action.type - continue, transfer, none
   * @param {String} nodeCards.card.button.action.message
   * @param {String} nodeCards.errorMessage
   */
  async addNodeCards(context, { chatbotId, nodeCards }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/cards/${chatbotId}`
      const response = await axios.post(url, { ...nodeCards })
      if (response.data && response.data.drawer && response.data.drawer.cards)
        context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('[addNodeCards]', error)
      return error.response.data
    }
  },
  /**
   * Actualiza una opcion
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId - id del nodo que lo contien
   * @param {String} idOption - id de la opcion
   * @param {Object} optionEdited - opcion editada
   */
  async updateOption(context, { chatbotId, nodeId, idOption, optionEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}/${idOption}`
      const response = await axios.put(url, { ...optionEdited })

      if (response.data.idOption) {
        context.commit('UPDATE_CHILD', {
          nodeId,
          nodeType: 'options',
          newChild: response.data,
          childId: idOption,
          childIdProperty: 'idOption',
        })
        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected.idOption === idOption

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', {
            parentNodeId: nodeId,
            ...response.data,
          })
        }

        return { success: true, ...response.data }
      } else return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Elimina un hijo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId id del chatbot
   * @param {String} args.nodeId - id del nodo padre
   * @param {String} args.nodeType - tipo del nodo padre
   * @param {String} args.childIdProperty - nombre de la propiedad del id
   * @param {String} args.childId - id del hijo
   */
  async deleteChild(
    context,
    { chatbotId, nodeId, nodeType, childIdProperty, childId }
  ) {
    const type = nodeType === 'options' ? 'multiple' : nodeType

    try {
      const url = `${vari.UHR}/bot/tree/question/${type}/${chatbotId}/${nodeId}/${childId}`
      const response = await axios.delete(url)

      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer[nodeType]
      ) {
        context.commit('DELETE_CHILD', {
          nodeId,
          nodeType,
          childIdProperty,
          childId,
        })

        response.data.drawer[nodeType].forEach((child) => {
          context.commit('UPDATE_CHILD', {
            nodeId: response.data._id,
            nodeType,
            childId: child[childIdProperty],
            childIdProperty,
            replaceAll: false,
            specificProperties: { order: child.order },
          })
        })

        return { success: true, data: response.data }
      }
      return { success: false, data: response.data }
    } catch (error) {
      console.error('error', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * Elimina un subhijo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId id del chatbot
   * @param {String} args.nodeId - id del nodo padre
   * @param {String} args.nodeType
   * @param {String} args.childIdProperty
   * @param {String} args.childId - id del hijo que lo contiene
   * @param {String} args.subChildId - id del subhijo al eliminar
   * @param {String} args.subchildIdProperty - el nombre de la propiedad del subhijo
   */
  async deleteSubchild(
    context,
    {
      chatbotId,
      nodeId,
      nodeType,
      childIdProperty,
      childId,
      subchildId,
      subchildType,
      subchildIdProperty,
    }
  ) {
    try {
      const url = `${vari.UHR}/bot/tree/question/${nodeType}/${chatbotId}/${nodeId}/${childId}/${subchildId}`
      const response = await axios.delete(url)

      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer[nodeType]
      ) {
        context.commit('DELETE_SUBCHILD', {
          nodeId,
          nodeType,
          childId,
          childIdProperty,
          subchildId,
          subchildType,
          subchildIdProperty,
        })

        const indexChild = response.data.drawer[nodeType].findIndex(
          (child) => child[childIdProperty] === childId
        )
        response.data.drawer[nodeType][indexChild][subchildType].forEach(
          (subchild) => {
            context.commit('UPDATE_SUBCHILD', {
              nodeId: response.data._id,
              nodeType,
              childId,
              childIdProperty,
              subchildId: subchild[subchildIdProperty],
              subchildType,
              subchildIdProperty,
              replaceAll: false,
              specificProperties: { order: subchild.order },
            })
          }
        )

        return { success: true, data: response.data }
      }
      return { success: false, data: response.data }
    } catch (error) {
      console.error('error', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * Actualiza el nodo de tipo opciones
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeOptionsEdited - nodo editado
   */
  async updateNodeOptions(context, { chatbotId, nodeId, nodeOptionsEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeOptionsEdited })
      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer.options
      ) {
        context.commit('UPDATE_NODE', { nodeId, node: response.data })

        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected._id === nodeId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', response.data)
        }

        return { success: true, ...response.data }
      }
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * Actualiza el nodo de tipo mensaje
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeMessage - nodo editado
   */
  async updateNodeMessage(context, { chatbotId, nodeId, nodeMessage }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/message/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeMessage })
      if (response.data._id) {
        context.commit('UPDATE_NODE', { nodeId, node: response.data })
        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected._id === nodeId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', response.data)
        }
      }
      return response.data
    } catch (error) {
      console.error('error updateNodeMessage', error)
      return error.response.data
    }
  },
  /**
   * Actualiza el nodo de tipo capturador
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeCatcher - nodo editado
   */
  async updateNodeCatcher(context, { chatbotId, nodeId, nodeCatcher }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/catcher/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeCatcher })
      if (response.data._id) {
        context.commit('UPDATE_NODE', { nodeId, node: response.data })
        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected._id === nodeId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', response.data)
        }
      }

      return response.data
    } catch (error) {
      console.error('error updateNodeCatcher', error)
      return error.response.data
    }
  },
  /**
   * Actualiza el nodo de tipo botones
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeButtonsEdited - nodo editado
   */
  async updateNodeButtons(context, { chatbotId, nodeId, nodeButtonsEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/buttons/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeButtonsEdited })
      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer.buttons
      ) {
        context.commit('UPDATE_NODE', { nodeId, node: response.data })

        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected._id === nodeId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', response.data)
        }

        return { success: true, ...response.data }
      }
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * Actualiza un nodo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId id del chatbot
   * @param {String} args.nodeId - id del nodo
   * @param {String} args.nodeType
   * @param {Object} args.nodeUpdated - nodo editado
   */
  async updateNode(context, { chatbotId, nodeId, nodeType, nodeUpdated }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/${nodeType}/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeUpdated })
      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer[nodeType]
      ) {
        context.commit('UPDATE_NODE', { nodeId, node: response.data })

        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected._id === nodeId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', response.data)
        }

        return { success: true, ...response.data }
      }
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * Actualiza un subhijo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId
   * @param {String} args.nodeId - id del nodo que lo contiene
   * @param {String} args.nodeType
   * @param {Object} args.newChild
   * @param {String} args.childId - id del hijo que se quiere eliminar
   * @param {String} args.childIdProperty - nombre de la propiedad del id del hijo
   */
  async updateChild(
    context,
    { chatbotId, nodeId, nodeType, newChild, childId, childIdProperty }
  ) {
    try {
      const type = nodeType === 'options' ? 'multiple' : nodeType

      const url = `${vari.UHR}/bot/tree/question/${type}/${chatbotId}/${nodeId}/${childId}`
      const response = await axios.put(url, { ...newChild })
      if (response.data?.success === false) {
        message.error(response.data?.details)
      }

      if (
        response.data[childIdProperty] ||
        response.data.data[childIdProperty]
      ) {
        const responseChild = response.data[childIdProperty]
          ? response.data
          : response.data.data

        context.commit('UPDATE_CHILD', {
          nodeId,
          nodeType,
          newChild: responseChild,
          childId,
          childIdProperty,
        })
        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected[childIdProperty] === childId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', {
            parentNodeId: nodeId,
            ...responseChild,
          })
        }

        return { success: true, data: responseChild }
      } else return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Actualiza un subhijo
   * @param {*} context
   * @param {Object} args
   * @param {String} args.chatbotId
   * @param {String} args.nodeId - id del nodo padre que lo contiene
   * @param {String} args.nodeType
   * @param {Object} args.newSubchild
   * @param {String} args.childId - id del hijo que lo contiene
   * @param {String} args.childIdProperty
   * @param {String} args.subchildId - id del subhijo a eliminar
   * @param {String} args.subchildType
   * @param {String} args.subchildIdProperty
   */
  async updateSubchild(
    context,
    {
      chatbotId,
      nodeId,
      nodeType,
      newSubchild,
      childId,
      childIdProperty,
      subchildId,
      subchildType,
      subchildIdProperty,
    }
  ) {
    try {
      const url = `${vari.UHR}/bot/tree/question/${nodeType}/${chatbotId}/${nodeId}/${childId}/${subchildId}`
      const response = await axios.put(url, { ...newSubchild })

      if (response.data.success && response.data.data[subchildIdProperty]) {
        context.commit('UPDATE_SUBCHILD', {
          nodeId,
          nodeType,
          newSubchild: { ...response.data.data, simulateParentNodeId: childId },
          childId,
          childIdProperty,
          subchildId,
          subchildType,
          subchildIdProperty,
        })
        // actualiza el nodo seleccionado si es el que esta siendo actualizado
        const isNodeSelected =
          context.getters.nodeSelected &&
          context.getters.nodeSelected[subchildIdProperty] === subchildId

        if (isNodeSelected) {
          context.commit('SET_NODE_SELECTED', {
            parentNodeId: nodeId,
            is_subchild: true,
            ...response.data.data,
            simulateParentNodeId: childId,
          })
        }

        return { success: true, ...response.data }
      } else return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Elimina un nodo
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   */
  async deleteNode(context, { chatbotId, nodeId }) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/bot/tree/question/message/${chatbotId}/${nodeId}`
      )
      if (response.data.success) {
        context.commit('DELETE_NODE', nodeId)
      }
      return response.data
    } catch (err) {
      console.error('[deleteNodeOptions]', err)
      return err.response.data
    }
  },
  /**
   * Obtiene los tickets activos del chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async getTotalTicketsOfChatbot(context, chatbotId) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/bot/tree/pre-inactive/${chatbotId}`
      )
      return response.data
    } catch (err) {
      console.error('[getTotalTicketsOfChatbot]', err)
      return err.response.data
    }
  },
  /**
   * Duplicar chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async duplicateChatbot(context, chatbotId) {
    try {
      const response = await axios.post(
        `${vari.UHR}/bot/tree/clon/${chatbotId}`
      )
      if (response.data.success) {
        const chatbot = response.data.chatbot
        chatbot.isCopy = true
        context.commit('ADD_CHATBOT', response.data.chatbot)
      }
      return response.data
    } catch (err) {
      console.error('[duplicateChatbot]', err)
      return err.response.data
    }
  },
  /**
   * Subir archivo
   * @param {*} context
   * @param {Object}  args
   * @param {String} args.type - document, image, video
   * @param {File} args.file
   * @return {Object} Objeto con resultado exitoso
   */
  async uploadFileToNode(context, { type, file }) {
    try {
      if (!file) return
      const formData = new FormData()
      formData.append('fileMedia', file)

      const response = await axios({
        method: 'post',
        url: `${vari.UHR}/bot/tree/question/upload-file?type=${type}`,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': '1024',
          },
        },
      })
      return response.data
    } catch (error) {
      console.error(error)
      return { success: false, ...error.response }
    }
  },
  /**
   * Obtiene las validaciones de caracteres de acuerdo al canal soportado
   * @param {*} context
   * @param {String} supportedChannels
   */
  async getValidationsForChabot(context, supportedChannels) {
    try {
      const response = await axios.get(
        `${vari.UHR}/bot/tree/validations/${supportedChannels}`
      )
      context.commit(
        'SET_VALIDATIONS',
        response.data.data.validationsByNodeType
      )
      return response.data
    } catch (err) {
      console.error('[getTotalTicketsOfChatbot]', err)
      return err.response.data
    }
  },
  /**
   * Exporta la actividad del chatbot
   * @param {String} chatbotId
   * @returns
   */
  async exportActivityChatbot(context, { chatbotId, dateRange }) {
    try {
      let response = null
      const chatbotIdCrypto = format.fixedEncodeURIComp(
        simpleCrypto.encrypt(chatbotId)
      )
      const dateCrypto = format.fixedEncodeURIComp(
        simpleCrypto.encrypt(dateRange)
      )
      const token = format.fixedEncodeURIComp(context.getters.token)
      const url = `${
        vari.UHM
      }/export/tracking-chatbot/${chatbotIdCrypto}?token=${token}&timezoneOffset=${new Date().getTimezoneOffset()}&date=${dateCrypto}`

      response = window.open(url)
      return response
    } catch (err) {
      console.error('[exportActivityChatbot]', err)
      return err.response.data
    }
  },
}

export default actions
