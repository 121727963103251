import Vue from 'vue'
import Antd from 'ant-design-vue'
import Ticker from './index.vue'
import router from './router'
import store from './store'
import VueChatScroll from 'vue-chat-scroll'
import VueClipboard from 'vue-clipboard2'
// import './registerServiceWorker'
import 'ant-design-vue/dist/antd.css'
import './app/commons/utils/console'
import VueTagManager from 'vue-tag-manager'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { loadFacebook, onWarn, onErr } from './app/commons/services'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VueChatScroll)
Vue.use(VueClipboard)
Vue.use(VueTagManager, {
  gtmId: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
})
Vue.use(LottieVuePlayer)

// Handle all Vue errors
Vue.config.warnHandler = onWarn
Vue.config.errorHandler = onErr

new Vue({
  router,
  store,
  render: (h) => h(Ticker),
}).$mount('#app')

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: process.env.VUE_APP_SENTRY_ORIGINS.split(','),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  tracesSampleRate: 1.0,
})
loadFacebook()
