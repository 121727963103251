<template>
  <div>
    <component :is="nameComponent" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import SectionAddWebchat from '@/app/channels/components/webchat/SectionAddWebchat'
import SectionSettings from '@/app/channels/components/webchat/SectionSettings'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SectionWebchat',
  data: () => ({
    nameComponent: '',
  }),
  components: {
    SectionAddWebchat,
    SectionSettings,
    LottieAnimation,
  },
  destroyed() {
    this.RESET_WEBCHAT_DEMO()
  },
  created() {
    this.intervalAttempt(async () => {
      if (!this.company) throw 'No company'
      this.loadFirstData()
    })
  },
  mixins: [attemptMixin],
  watch: {
    // escucha el cambio de rutas
    $route() {
      this.loadFirstData()
    },
  },
  computed: {
    ...mapGetters(['company', 'spent_channels']),
  },
  methods: {
    ...mapMutations(['RESET_WEBCHAT_DEMO']),

    loadFirstData() {
      const create = this.$route.query.create

      if (
        create &&
        this.spent_channels.webchat &&
        !this.spent_channels.webchat.isFull
      )
        return (this.nameComponent = 'SectionAddWebchat')
      else return (this.nameComponent = 'SectionSettings')
    },
  },
}
</script>

<style lang="sass" scoped>
$border-width: 1px

.gray_7
  color: $gray_7
.container-loader
  height: calc( (100vh - #{$padding-heigth-inner * 2}) - #{$border-width*2})
</style>
