import {
  generateTreeNodes,
  verifyUsedNodes,
} from '@/app/chatbots/utils/generateTree'

const getters = {
  chatbots: (state) => state.chatbots,
  paginate_chatbots: (state) => state.paginate_chatbots,
  chatbotSelected: (state) => state.chatbotSelected,
  listNodesChatbot: (state) => state.listNodesChatbot,
  treeNodes: (state) => {
    return generateTreeNodes([...state.nodes])
  },
  changes: (state) => state.changes,
  isSavedNode: (state) => state.isSavedNode,
  nodes: (state) => {
    const idUsedNodes = verifyUsedNodes(state.nodes)
    return state.nodes.filter((node) => idUsedNodes.includes(node._id))
  },
  selectedNodeId: (state) => state.selectedNodeId,
  chatbot: (state) => state.chatbot,
  filters: (state) => state.filters,
  nodeSelected: (state) => state.nodeSelected,
  validations_chatbot: (state) => state.validations_chatbot,
}

export default getters
