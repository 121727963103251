import Notifications from '@/app/notifications/views/Notifications'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
      requiresType: [
        'agent',
        'admin_agent',
        'supervisor_agent',
        'master_agent',
      ],
      title: `Notificaciones | ${VUE_APP_NAME}`,
    },
  },
]
