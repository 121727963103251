<template>
  <div class="body full-width">
    <a-icon type="lock" class="section__icon" />
    <h5 class="heading-h5">¡Yupi! Ahora solo falta que crees tu contraseña</h5>
    <p class="body-2 description">
      Tu contraseña debe contener 8 caracteres como mínimo , <br />
      1 carácter en mayúscula, 1 carácter en minúscula, 1 número y <br />
      1 carácter especial.
    </p>
    <CreatePasswordForm
      class="full-width"
      :username="username"
      :email="email"
    />
  </div>
</template>

<script>
import CreatePasswordForm from '@/app/signup/components/organisms/CreatePasswordForm.vue'

export default {
  components: {
    CreatePasswordForm,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    // si no se copio correctamente
    onError() {
      this.$message.error('Fallo')
    },
    // si se copio correctamente
    onCopyUserId() {
      this.$message.success('ID de usuario copiado')
    },
  },
}
</script>

<style lang="sass" scoped>
.body
  display: flex
  flex-direction: column
  align-items: center
  color: $gray_dark_900
  .description
    color: $gray_dark_800
    margin-bottom: 20px
.section
  &__icon
    font-size: 60px
    margin: 4px 0 12px 0
    @include xs
      font-size: 40px
    @include sm
      font-size: 40px
</style>
