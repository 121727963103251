const COMPANY_COLORS = {
  red: {
    bgColor: '#fff1f0',
    color: '#f5222d',
  },
  volcano: {
    bgColor: '#fff2e8',
    color: '#fa541c',
  },
  orange: {
    bgColor: '#fff7e6',
    color: '#fa8c16',
  },
  gold: {
    bgColor: '#fffbe6',
    color: '#faad14',
  },
  lime: {
    bgColor: '#fcffe6',
    color: '#a0d911',
  },
  green: {
    bgColor: '#f6ffed',
    color: '#52c41a',
  },
  cyan: {
    bgColor: '#e6fffb',
    color: '#13c2c2',
  },
  blue: {
    bgColor: '#e6f4ff',
    color: '#1677ff',
  },
  geek_blue: {
    bgColor: '#f0f5ff',
    color: '#2f54eb',
  },
  purple: {
    bgColor: '#f9f0ff',
    color: '#722ed1',
  },
  magenta: {
    bgColor: '#fff0f6',
    color: '#eb2f96',
  },
}

/**
 * Obtiene el nuevo colo para la empresa
 * @param {Object[]} companies
 * @param {String} companies[].color
 * @returns {Object} color
 * @returns {String} color.color
 * @returns {String} color.bgColor
 */
const NEXT_COLOR_FOR_COMPANY = (companies) => {
  const colorsUsed = companies.map((company) => company.color)
  const allColors = Object.keys(COMPANY_COLORS)

  const colorsEnabled = allColors.filter((color) => !colorsUsed.includes(color))
  const nextColor = colorsEnabled.length ? colorsEnabled[0] : allColors[0]

  return nextColor
}

export { COMPANY_COLORS, NEXT_COLOR_FOR_COMPANY }
