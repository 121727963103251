import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import router from '@/router'
import format from '@/app/shared/utils/format'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY_RESTMAIN
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Obtiene si el correo o el nombre de la empresa ya xiste
   * @param {*} context
   * @param {String} user email
   * @param {String} nombre de empresa
   */
  async getExistCompany(context, { user, company }) {
    try {
      const url = `${vari.UHR}/account/exist?user=${format.fixedEncodeURIComp(
        user
      )}&company=${format.fixedEncodeURIComp(company)}`
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error('[authModule][revokeToken]', error)
      return error
    }
  },
  /**
   * Registra un usuario de forma inactiva y envia el correo
   * @param {*} context
   * @param {Object} user
   * @param {Object} company
   */
  async registerUser(context, { user, company, socialLogin }) {
    try {
      const url = `${vari.UHR}/register`
      const response = await axios.post(url, {
        user,
        company,
        acceptSubscribe: company.acceptSubscribe,
        socialLogin,
      })
      if (response.data.success) {
        const profile = { ...user }
        profile.userId = response.data.user?.idUser
        profile.type = response.data.user?.type
        const license = router.currentRoute.query.license || 'free'
        context.dispatch('pushNewCompany', {
          license: license,
          user: profile,
          company,
        })
      }
      return response.data
    } catch (error) {
      console.error('[signupModule]', error)
      return error
    }
  },
  /**
   * Verifica una cuenta
   * @param {*} context
   * @param {String} token
   * @param {String} password
   * @param {String} username
   */
  async getVerificationInfo(context, token) {
    try {
      const url = `${vari.UHR}/verify?token=${token}`
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error('[signupModule]', error)
    }
  },
  /**
   * Verifica una cuenta
   * @param {*} context
   * @param {String} token
   * @param {String} password
   * @param {String} username
   */
  async verifyCompany(context, { token, password, username, email }) {
    try {
      const url = `${vari.UHR}/verify`
      const response = await axios.post(url, {
        token,
        password,
        username,
        email,
      })
      if (response.data.success) {
        const profile = { email }
        context.dispatch('pushActiveCompany', {
          // license: 'free',
          user: profile,
        })
      }
      return response.data
    } catch (error) {
      console.error('[signupModule]', error)
      return error
    }
  },

  /**
   * Verifica una cuenta
   * @param {*} context
   * @param {String} token
   * @param {String} password
   * @param {String} username
   */
  async reSendVerificationEmail(context, token) {
    try {
      const url = `${vari.UHR}/verify`
      const response = await axios.put(url, { token })
      console.log('verify company', response)
      return response.data
    } catch (error) {
      console.error('[signupModule]', error)
    }
  },
  /**Pagar un plan de pago como Advanced, Business
   *
   */
  async paymentPlan(
    context,
    { token, canceled, card, purchase, fromSocialLogin }
  ) {
    try {
      const url = `${vari.UHR}/verify/payment`
      const response = await axios.post(url, {
        token,
        canceled,
        card: simpleCrypto.encrypt(card),
        purchase,
        fromSocialLogin,
      })
      if (fromSocialLogin) return response.data
      let newResponse = { success: false }
      if (
        response.data.planPayment.success ||
        response.data.planPayment.fromFree
      ) {
        newResponse.success = true
      }
      newResponse.status = response.data.planPayment?.payment?.status
      newResponse.detail = response.data.planPayment?.payment?.rejectionDetail
      return newResponse
    } catch (error) {
      console.error('[signupModule]', error)
      return error.response?.data
    }
  },
}

export default actions
