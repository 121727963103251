import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_ADMIN_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },

  /**
   * Setea Supervisores a la Lista de Supervisores
   * @param {*} state
   * @param {Array} supervisors Arreglo de Supervisores
   */
  SET_SUPERVISORS(state, supervisors) {
    state.supervisors = supervisors
  },
  /**
   * Agrega un supervisor al la lista de Supervisores
   * @param {*} state
   * @param {Object} supervisor Objeto de un supervisor
   */
  ADD_SUPERVISOR(state, supervisor) {
    state.supervisors.push(supervisor)
  },
  /**
   * Completar la Lista de Supervisores Asignados, filtrando la Cola Seleccionada
   * @param {*} state
   * @param {String} lineId Id de la Cola
   */
  REFILL_SUPERVISORS_ASIGNED(state, lineId) {
    state.supervisorsAsigned = state.supervisors.filter((supervisor) =>
      supervisor.company.linesId.some((line) => line._id === lineId)
    )
  },
  /**
   * Setea los perfiles de supervisores a la lista de Supervisores Asignados a la cola seleccionada
   * @param {*} state
   * @param {Array} supervisorsAsigned Arreglo de Supervisores
   */
  SET_SUPERVISORS_ASIGNED(state, supervisorsAsigned) {
    state.supervisorsAsigned = supervisorsAsigned
  },
  /**
   * Agrega el perfil de un supervisor a la lista de Supervisores Asignados a la cola seleccionada
   * @param {*} state
   * @param {Object} supervisor Objeto del Supervisor
   */
  ADD_SUPERVISOR_ASIGNED(state, supervisor) {
    state.supervisorsAsigned.push(supervisor)
  },
  /**
   * Setea administradores a la Lista de administradores
   * @param {*} state
   * @param {Array} supervisors Arreglo de Supervisores
   */
  SET_ADMINS(state, admins) {
    state.admins = admins
  },
}

export default mutations
