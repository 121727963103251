import Round from '@/app/shared/utils/round'

const getters = {
  company_strategies: (state) => {
    state.company_strategies.forEach((strategy) => {
      const stats_import = { ...strategy.stats_import }
      const stats_send = { ...strategy.stats_send }
      // no cuenta a lo exitosos
      delete stats_import.successes
      // no cuenta al total de fallidos si es que lo tuviera
      if (stats_import.total_failed) delete stats_import.total_failed
      const total_failed = Object.values(stats_import).reduce((a, b) => a + b)
      strategy.stats_import.total_failed = total_failed
      // no cuenta a los que estan en cola
      delete stats_send.queued
      // no cuenta al total de enviados si es que lo tuviera
      if (stats_send.total_sent) delete stats_send.total_sent
      const total_sent = Object.values(stats_send).reduce((a, b) => a + b)
      strategy.stats_send.total_sent = total_sent

      // formatea el total del wallet
      if (strategy.stats_wallet && strategy.stats_wallet.total_spent) {
        strategy.stats_wallet.total_spent = Round.round10(
          strategy.stats_wallet.total_spent,
          -4
        )
      }
      return strategy
    })
    return state.company_strategies
  },
  paginate_company_strategies: (state) => state.paginate_company_strategies, // paginacion de campañas
  statuses_strategies: (state) => state.statuses_strategies,
  simple_templates: (state) => state.simple_templates,
  selected_strategy: (state) => {
    // formatea el total del wallet
    const strategy = state.selected_strategy
    if (strategy.stats_wallet && strategy.stats_wallet.total_spent) {
      state.selected_strategy.stats_wallet.total_spent = Round.round10(
        strategy.stats_wallet.total_spent,
        -4
      )
    }
    return state.selected_strategy
  },
}

export default getters
