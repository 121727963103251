<template>
  <a-table
    :data-source="dataOrder"
    :columns="COLUMNS_BLACKLIST"
    size="middle"
    :loading="loading"
    :pagination="pagination"
    @change="handleChangeTable"
  >
    <!--Canales-->
    <template slot="channels_companies" slot-scope="text, record">
      <div
        v-for="channel in channelsCompanyInNumber(record.channelCompanies)"
        :key="channel.channel"
        class="'mrg-right-12'"
      >
        <popover-channel
          :channel="channel.channel"
          :list-titles="channel.titles"
          @handleModal="handleEditOnChannels(record)"
        />
      </div>
    </template>
    <!--Acciones-->
    <template slot="action" slot-scope="text, record">
      <anchor @click.native="handleShowPreview(record)"> Ver </anchor>
      <a-divider type="vertical" />
      <anchor @click.native="handleShowUpdate(record)"> Editar </anchor>
      <a-divider type="vertical" />
      <anchor @click.native="handleUnblock(record)"> Desbloquear </anchor>
    </template>
  </a-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { COLUMNS_BLACKLIST } from './utils/columnsBlacklistUtil'
import Anchor from '@/app/shared/components/atoms/Anchor'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import supportMixin from '@/app/shared/mixins/support'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'TableBlacklist',
  props: {
    dataSource: {
      type: Array,
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Anchor,
    PopoverChannel,
  },
  data: () => ({
    COLUMNS_BLACKLIST,
  }),
  mixins: [supportMixin, transformMixin],
  computed: {
    ...mapGetters(['channels', 'paginate_blacklist']),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 10,
        size: 'default',
        total: this.paginate_blacklist.total || 0,
        current: this.paginate_blacklist.page,
      }
    },

    dataOrder() {
      if (!this.dataSource) return []
      //Le agrega el alias al titulo del canal

      //Ordena el array de estrategias
      let startOrder =
        1 + (this.paginate_blacklist.page - 1) * this.pagination.defaultPageSize
      return this.dataSource.map((blacklist) => {
        blacklist.order = startOrder++
        return blacklist
      })
    },
  },
  methods: {
    /**
     * Emite la previsualiacion
     * @param {Object} record
     * @param {String} record.apiClientId
     * @param {String} record.channel
     * @param {Date} record.created_at
     * @param {Object[]} record.channelCompanies
     */
    handleShowPreview(record) {
      this.$emit('onPreview', record)
    },
    /**
     * Emite el evento de desbloquear
     * @param {Object} record
     * @param {String} record.apiClientId
     * @param {String} record.channel
     * @param {Date} record.created_at
     * @param {Object[]} record.channelCompanies
     */ handleUnblock(record) {
      this.$emit('onUnBlock', record)
    },
    /**
     * Escucha y emite el cambio de paginacion de la tabla
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleChangeTable(pagination) {
      this.$emit('onChangeTable', pagination.current)
    },
    /**
     * Emite el evento de actualizar
     * @param {Object} record
     * @param {String} record.apiClientId
     * @param {String} record.channel
     * @param {Date} record.created_at
     * @param {Object[]} record.channelCompanies
     */
    handleShowUpdate(record) {
      this.$emit('onUpdate', record)
    },
    /**
     * Canales donde el numero esta bloqeueado
     * @return {Object}
     */
    channelsCompanyInNumber(channelCompanies) {
      const channels = [...channelCompanies].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        const alias = this.channelCompanyAlias(current._id)
        current.title = this.channelCompanyNumber(current._id)
        if (!isIncludeInAcc)
          acc[current.channel] = {
            _id: current.channel, // id del canal global
            channel: current.channel,
            titles: [alias ? `${current.title} ( ${alias} )` : current.title], // ids de los canales conectados que tienen este canal
            name: this.transformWord(current.channel, 'capitalize'),
          }
        else
          acc[current.channel].titles.push(
            alias ? `${current.title} ( ${alias} )` : current.title
          )
        return acc
      }, {})
      return channels
    },
  },
}
</script>

<style scoped></style>
