import Vue from 'vue'
import VueRouter from 'vue-router'
import app from './app'
import store from './store'
import { verifyBreakpoint } from './app/commons/services/init-match-query'
import { breakpoints } from './app/commons/utils/breakpoints'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: app.routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  },
})

/** REDIRECCIÓN DE RUTAS */
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title
  else document.title = process.env.VUE_APP_NAME
  /** EN RUTA PROTEGIDA */
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    /** AL PRINCIPAL EN CASO ESTÉ AUTENTICADO */
    if (localStorage.getItem('access_token')) {
      // console.log('continuar_auth')

      let typeUser = localStorage.getItem('type_user')
      const profileUser = store.getters.profile
      if (profileUser) typeUser = profileUser.type

      if (to.name != 'redirect') {
        if (to.meta.requiresType) {
          if (
            to.meta.requiresType.includes(typeUser) ||
            to.meta.requiresType.includes(typeUser.replace('_agent', ''))
          ) {
            // encuentra el breakpoint actual
            const { aditional } = verifyBreakpoint(breakpoints)
            const activeBreakpoint = aditional
            const device = activeBreakpoint.is_mobile ? 'mobile' : 'desktop'
            // si no existe requiresDevice en la ruta se asume que solo está disponible para desktop
            const requiresDevice = to.meta.requiresDevice
              ? to.meta.requiresDevice
              : ['desktop']
            // valida si la ruta puede entrar a una vista de acuerdo al breakpoint actual
            // si la ruta coincide con el breakpoint, le dejará pasar a la ruta
            console.log(requiresDevice.includes(device), 'is in device')
            if (requiresDevice.includes(device)) next()
            // si no coincide redirigira a la ruta de sin soporte
            else next({ name: 'unsupport' })
          } else {
            next('err')
          }
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      // console.log('ir_a_login_no_auth')
      next({ name: 'login' })
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    /** EN RUTA DE VISITANTE */
    /** AL PRINCIPAL EN CASO ESTÉ AUTENTICADO */
    if (localStorage.getItem('access_token')) {
      // console.log('ir_al_modulo_por_defecto')
      next({ name: 'redirect' })
    } else {
      // console.log('continuar_no_auth')
      next()
    }
  } else {
    // console.log('continuar')
    next()
  }
})

export default router
