import initialState from './initialState'
import Round from '@/app/shared/utils/round'

const mutations = {
  /** RESET MODULE */
  RESET_MARKET_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Añade una compra a las compras que se estan haciendo
   * @param {*} state
   * @param {Object} purchase - Objeto de compra
   * @param {Number} purchase.total - Precio total del producto
   */
  ADD_PRODUCT_TO_PURCHASE(state, purchase) {
    state.purchase.products.push(purchase)
  },
  /**
   * Remueve una compra de las compras
   * @param {*} state
   * @param {String} idPurchase - id de compra
   */
  REMOVE_PRODUCT_TO_PURCHASE(state, idPurchase) {
    state.purchase.products = state.purchase.products.filter(
      (purchase) => purchase._id !== idPurchase
    )
  },
  /**Setea el costo total a pagar
   * @param {Number} total - precio toal
   */
  SET_TOTAL_TO_PURCHASE(state, total) {
    state.purchase.total = Round.round10(total, -2)
  },
  /**
   * Setea valor a permitir comprar
   * @param {*} state
   * @param {Boolean} isAllow - valor que permite o no permite
   */
  SET_IS_ALLOW_BUY_PLANS(state, isAllow) {
    state.isAllowBuyPlans = isAllow
  },
  /**
   * Setea los productos
   * @param {*} state
   * @param {Object[]} products - productos como sms, plantillas
   */
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  RESET_PURCHASE(state) {
    state.purchase.products = []
    state.purchase.total = 0
  },
  /**
   * Setea para mostrar el modal de expiración
   * @param {vuex} state
   * @param {Boolean} expirationPlanModal Mostrar modal?
   */
  SET_EXPIRATION_PLAN_MODAL(state, expirationPlanModal) {
    state.expirationPlanModal = expirationPlanModal
  },
  /**
   * Setea los datos de compras y pagos
   * @param {*} state
   * @param {Object[]} payments - datos de compras y pagos
   */
  SET_PAYMENTS(state, payments) {
    state.payments = payments
  },
  /**
   * Setea los datos de paginación de compras y pagos
   * @param {*} state
   * @param {Object} payments_pagination - datos de paginación de compras y pagos
   */
  SET_PAYMENTS_PAGINATION(state, payments_pagination) {
    state.payments_pagination = payments_pagination
  },
}

export default mutations
