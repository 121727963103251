var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submenu-item",class:{ 'submenu-item--disabled': _vm.disabled }},[_c('div',{staticClass:"submenu-item__content",class:{
      'submenu-item--active': _vm.active,
      'submenu-item--selected': _vm.selected,
    },style:({ width: _vm.width }),on:{"click":_vm.handleSelectSubMenu}},[(!_vm.icon)?_c('a-icon',{staticClass:"submenu-item__icon",class:{ 'mrg-right-12': _vm.showTitle },attrs:{"type":"user","rotate":180}}):_c('div',{staticClass:"submenu-item__icon",class:{ 'mrg-right-12': _vm.showTitle },domProps:{"innerHTML":_vm._s(_vm.icon)}}),(_vm.showTitle)?[_c('p',{staticClass:"submenu-item__title body-3 mrg-bottom-0 ellipsis"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:{
          'submenu-item__icon--collapse': _vm.collapse,
          'submenu-item__icon--no-collapse': !_vm.collapse,
        }},[_c('i',{staticClass:"submenu-item__icon--arrow"})])]:_vm._e()],2),(_vm.showTitle && _vm.collapse)?_c('div',{staticClass:"submenu-item__collapse"},[_vm._t("items")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }