<template>
  <a-modal
    :visible="visible"
    title="Agregar respuesta rápida"
    :footer="null"
    @cancel="$emit('onClose')"
    :body-style="{ ...bodyStyle, 'min-height': minHeightBody }"
    centered
  >
    <p>
      En Respuestas con multimedia que contengan texto, los archivos podrían
      enviarse en un mensaje separado al texto.
    </p>
    <a-input-search
      v-model="list.search"
      @search="handleChangeTable(list.page)"
      placeholder="Busca por titulo"
      style="margin-bottom: 12px; margin-right: 12px"
      class="modal__search"
    />

    <a-list
      :data-source="filterAnswers(company_fast_answers)"
      :pagination="pagination"
      :loading="list.loading"
      :locale="{ emptyText: 'No hay respuestas rápidas' }"
      class="list-scroll list-height"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="item"
        class="modal__list__item"
      >
        <a-list-item-meta>
          <a-row slot="title">
            <span
              v-if="item.media"
              class="regular-12 mrg-bottom-12 modal__list__item__top"
              >Multimedia:
              {{
                MULTIMEDIA_SUPPORT[item.media.typeAttachment].title_singular
              }}</span
            >
            <h6 class="semibold-14 mrg-bottom-0 modal__list__item__title">
              {{ item.title }}
            </h6>
          </a-row>
          <span
            v-if="item.body"
            slot="description"
            class="ellipsis modal__list__item__description"
          >
            {{ item.body }}</span
          >
        </a-list-item-meta>
        <div class="modal__list__buttons">
          <a-button class="mrg-right-8" @click="$emit('onPreview', item)"
            >Previsualizar</a-button
          >
          <a-tooltip placement="right">
            <span v-if="disabledAdd(item.lines)" slot="title">
              {{ disabledDescription.disabled_lines }}
            </span>
            <span
              v-else-if="
                item.media && !allowedMedias.includes(item.media.typeAttachment)
              "
              slot="title"
            >
              {{
                disabledDescription[
                  `disabled_media_${item.media.typeAttachment}`
                ]
              }}
            </span>
            <a-button
              type="primary"
              @click="handleAdd(item)"
              ghost
              :disabled="
                item.media && !allowedMedias.includes(item.media.typeAttachment)
              "
              >Agregar</a-button
            >
          </a-tooltip>
        </div>
      </a-list-item>
    </a-list>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MULTIMEDIA_SUPPORT } from '@/app/tickets/utils/multimediaUtil'

export default {
  name: 'ModalListFastAnswers',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowedMedias: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    MULTIMEDIA_SUPPORT,
    list: {
      loading: false,
      search: '',
      page: 1,
    },
    bodyStyle: {
      maxHeight: '45em',
      minHeight: '40em',
      padding: '12px 0px 20px 20px',
    },
    assignedLines: [],
  }),
  computed: {
    ...mapGetters([
      'company_fast_answers',
      'paginate_fast_answers',
      'profile',
      'active_breakpoint',
    ]),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 10,
        total: this.paginate_fast_answers.total || 0,
        current: this.paginate_fast_answers.page,
        onChange: (page) => this.handleChangeTable(page),
      }
    },
    minHeightBody() {
      return this.active_breakpoint.is_mobile ? '35em' : '40em'
    },
    /**
     * @return {String} descripcion del tooltip para cuando el boton esta deshabilitado
     */
    disabledDescription() {
      return {
        disabled_lines: 'No estás asignado a la cola de esta respuesta rápida.',
        disabled_media_document:
          'El canal de este ticket no tiene soporte para envios de documentos.',
        disabled_media_image:
          'El canal de este ticket no tiene soporte para envios de imágenes.',
        disabled_media_video:
          'El canal de este ticket no tiene soporte para envios de videos.',
      }
    },
  },
  methods: {
    ...mapActions(['getFastAnswers']),

    /**
     * Emite la respuesta agregada
     * @param {Object} data
     */
    handleAdd(data) {
      this.$emit('onAdd', data)
      this.$emit('onClose')
    },

    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} page
     */
    async handleChangeTable(page) {
      this.list.loading = true
      await this.getFastAnswers({
        page: page,
        search: this.list.search,
        module: 'workspace',
      })
      this.list.loading = false
    },
    /**
     * Deshabilita el add si el usuario no esta asignado a la cola de la respuesta
     * @param {*} linesAllowedForAnswer
     */
    disabledAdd(linesAllowedForAnswer) {
      const idAssignedLines = this.profile.company.linesId

      const disabled = !linesAllowedForAnswer.some((lineAllowed) =>
        idAssignedLines.includes(lineAllowed._id)
      )
      return disabled
    },
    /**
     * Filtra las respuesta rapidas
     */
    filterAnswers(fastAnswers) {
      return fastAnswers.filter((fastAnswer) => {
        return !this.disabledAdd(fastAnswer.lines)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__search
    width: calc( 100% - 20px )
  &__list
    &__item
      @include xs
        +flex(column, center, flex-start)
      @include sm
        +flex(column, center, flex-start)
      @include md
        +flex(column, center, flex-start)
      &__title, &__description
        color: $gray_8
      &__top
        margin-left: 3px
        color: $gray_7
      &__description
        margin-top: -2px
    &__buttons
      margin-left: 12px
      @include xs
        margin-top: 12px
        margin-left: 0px
        +flex(row, center, center)
        width: 100%
      @include sm
        margin-top: 12px
        margin-left: 0px
        +flex(row, center, center)
        width: 100%
      @include md
        margin-top: 12px
        margin-left: 0px
        +flex(row, center, center)
        width: 100%
</style>
<style lang="sass">
.list-height
  .ant-spin-nested-loading
    max-height: 34em
    overflow-y: scroll
    padding-right: 12px
  .ant-list-pagination
    padding-right: 20px
</style>
