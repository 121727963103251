<template>
  <div>
    <header-module
      title="CHATBOTS"
      tipography="heading-h5"
      text-before-link="Diseña e implementa chatbots para tus canales activos."
      :link="`${vari.WEB_DOC}chatbots/creacion-chatbot/`"
      text-after-link="si deseas conocer más."
      position-tooltip="right"
    />

    <a-row type="flex" justify="space-between">
      <a-row class="filters">
        <!-- Filtro -->
        <a-input-search
          placeholder="Buscar por nombre"
          style="width: 312px; margin-right: 12px"
          v-model="name"
          @search="onSearch"
        />
        <!--Canales-->
        <a-select
          v-model="channel"
          class="filters__item"
          placeholder="Escoge un canal"
          @change="handleChangeChannel"
        >
          <a-select-option
            v-for="channel in channelsCompanyForFilters"
            :key="channel._id"
            :title="channel.name"
          >
            {{ channel.name }}
          </a-select-option>
        </a-select>
        <!-- Ids de canales -->
        <a-select
          v-model="channelCompanyId"
          @change="loadData"
          class="filters__item"
          placeholder="Escoge un Id"
        >
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex" align="middle">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>{{
                simpleChannel.alias ? `(${simpleChannel.alias})` : ''
              }}</span>
            </a-row>
          </a-select-option>
        </a-select>
        <!--Estados-->
        <a-select
          style="width: 256px"
          @change="handleChangeStatus"
          v-model="status"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option value="active"> Activos </a-select-option>
          <a-select-option value="inactive"> Inactivos </a-select-option>
        </a-select>
      </a-row>
      <a-button type="primary" @click="showModalCreate">
        Crear chatbot
      </a-button>
      <!--Modal de crear-->
      <modal-create-chatbot
        :visible="modalCreateChatbot.visible"
        @onClose="modalCreateChatbot.visible = false"
        @onVisible="showModalCreate"
      />
      <modal-details-chatbot
        :visible="modalDetails.visible"
        :data-source="modalDetails.chatbot"
        @onOk="handleGoToChatbot"
        @onCancel="modalDetails.visible = false"
      />
    </a-row>
    <a-divider style="margin-top: 12px; margin-bottom: 12px; color: #d4d4d6" />

    <!---Lista de chatbots-->
    <ChatbotsList
      :dataChatbots="chatbots"
      :loading="loading"
      @loadData="loadData"
      ref="list"
      @onSeeSchedule="handleShowSchedule"
    />
  </div>
</template>
<script>
import ChatbotsList from '@/app/chatbots/components/organisms/ChatbotsList.vue'
import vari from '@/app/shared/utils/variables'
import ModalCreateChatbot from '@/app/chatbots/components/organisms/modals/ModalCreateChatbot'
import ModalDetailsChatbot from '@/app/chatbots/components/organisms/modals/ModalDetailsChatbot'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import HeaderModule from '@/app/shared/components/headers/HeaderModule.vue'

export default {
  name: 'SectionChatbots',
  components: {
    ChatbotsList,
    ModalCreateChatbot,
    ModalDetailsChatbot,
    SocialIcon,
    HeaderModule,
  },
  data: () => ({
    vari,
    loading: true,
    chatbotsFiltered: null, // chatbots filtrados segun estados
    modalBodyStyle: {
      padding: 0,
    },
    page: 1,
    channel: 'all',
    channelCompanyId: 'all',
    name: '',
    status: 'all', // estado de los chatbots segun el selector
    loadingForm: false,
    lastChild: null,
    observer: null,
    morePaginate: true,
    modalUpdateChatbot: { visible: false },
    modalDetails: { visible: false },
    simpleChannelsFiltered: [{ _id: 'all', title: 'Todos los IDs' }],
  }),
  created() {
    // setea la respuesta de chatbots a una data local
    this.intervalAttempt(() => {
      if (!this.company) throw 'Not company found'
      this.loadData()
      this.handleChangeChannel(this.channel, true, false)
    })
  },
  mixins: [attemptMixin, supportMixin],
  watch: {
    simple_company_channels() {
      this.handleChangeChannel(this.channel, true, false)
    },
  },
  computed: {
    ...mapGetters([
      'company',
      'chatbots',
      'modalCreateChatbot',
      'simple_company_channels',
    ]),
    /**
     * Arreglo de canales activos de la empresa
     * @returns {Object[]}
     */
    channelsCompanyForFilters() {
      let channels = [{ _id: 'all', name: 'Todos los canales' }]
      if (this.company) {
        for (const key in this.company.channels) {
          if (this.company.channels[key])
            channels.push({ _id: key, name: this.company.channels[key].name })
        }
      }
      return channels
    },
  },
  methods: {
    ...mapActions([
      'getListChatbots',
      'getAssignedLines',
      'addChatbot',
      'activeChatbot',
      'setAnalytics',
    ]),
    ...mapMutations(['SET_MODAL_CREATE_CHATBOT', 'SET_FILTERS']),

    async loadData() {
      this.loading = true
      await this.setChatbots()
      // this.filterChatbots(active)
      this.loading = false
      // si esta al tope de su primera paginacion
      const maxPerPage = 20
      // se le resta 1 porque buscara que tome al ultimo de la primera paginacion
      if (this.chatbots.length > maxPerPage - 1) this.setLastChild()
    },
    /**
     * Setea chatbots
     */
    async setChatbots() {
      this.page = 1
      this.SET_FILTERS({
        page: this.page,
        status: this.status,
        name: this.name,
        channel: this.channel,
        channelCompanyId: this.channelCompanyId,
      })
      await this.getListChatbots({
        page: this.page,
        status: this.status,
        name: this.name,
        channel: this.channel,
        channelCompanyId: this.channelCompanyId,
        restart: true,
      })
      this.morePaginate = true
    },

    /** Cuando se presiona enter en el buscador */
    onSearch() {
      this.loadData()
    },
    /** Se ejecuta al cambio de una de las opciones del select del filtro por 'activo' o 'inactivo' */
    handleChangeStatus() {
      this.loadData()
    },
    /**
     * Escucha el cambio de canales
     * @param {String} channel
     */
    handleChangeChannel(channel, setDefault = true, getData = true) {
      //supportMixin
      const simpleChannels = this.filterSimpleByChannel({
        channel,
        defaultChannel: {
          _id: 'all',
          title: 'Todos los IDs',
        },
      })
      if (setDefault) this.channelCompanyId = simpleChannels[0]._id
      this.simpleChannelsFiltered = simpleChannels
      if (getData) this.loadData()
    },
    /**Abre el modal de crear */
    showModalCreate() {
      this.modalCreateChatbot.visible = true
    },
    /**Cierra el modal de crear */
    hidenModalCreate() {
      // this.modalCreateChatbot.visible = false
      this.SET_MODAL_CREATE_CHATBOT(false)
      this.setAnalytics({ eventName: 'chatbot_modal_cancel' })
    },
    /**Crear chatbot
     * @param {Object} values
     */
    async handleCreateChatbot(values) {
      this.loadingForm = true
      values.schedule.timezone =
        new window.Intl.DateTimeFormat().resolvedOptions().timeZone
      const response = await this.addChatbot(values)
      if (response.type === 'tree') {
        // this.modalCreateChatbot.visible = false
        this.SET_MODAL_CREATE_CHATBOT(false)
        this.$router.push({ name: 'id', params: { id: response._id } })
        this.$message.success('Su chatbot se ha creado exitosamente')
      } else this.$message.error(response.details)
      this.loadingForm = false
    },
    async nextPageChatbots() {
      const response = await this.getListChatbots({
        page: this.page,
        status: this.status,
        name: this.name,
        channel: this.channel,
        restart: false,
      })
      if (response.data.length == 0) this.morePaginate = false
      else this.morePaginate = true
    },
    afterCloseCreate() {
      this.$refs.formCreateChatbot.handleReset()
      this.setAnalytics({ eventName: 'chatbot_modal_close' })
    },
    setLastChild() {
      this.intervalAttempt(() => {
        const childs = document.getElementsByClassName('card-chatbot')
        this.lastChild = childs[childs.length - 1]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 1.0,
        })
        this.observer.observe(this.lastChild)
      })
    },
    async handleBottomReached(entries) {
      const entry = entries[0]
      if (entry.intersectionRatio >= 1 && this.morePaginate && !this.loading) {
        this.page++
        await this.nextPageChatbots()
        this.setLastChild()
      }
    },
    handleShowSchedule(idChatbot) {
      this.modalDetails.visible = true
      const chatbot = this.chatbots.find((chatbot) => chatbot._id == idChatbot)
      this.modalDetails.chatbot = chatbot
    },
    handleGoToChatbot(idChatbot) {
      this.$router.push({ name: 'id', params: { id: idChatbot } })
      this.modalDetails.visible = false
    },
  },
}
</script>

<style lang="sass" scoped>
.filters__item
  width: 256px
  margin-right: 12px
</style>
