<template>
  <a-sub-menu
    v-if="type === 'submenu'"
    title="Cambiar mi estado a:"
    id="states"
    class="menu-footer__submenu--states"
    :popupClassName="popupClassNameStates"
    key="3"
  >
    <a-menu-item
      v-for="status in profile_states_for_list"
      :key="status._id"
      :id="status.state"
      class="fix-center"
      style="display: flex; flex-direction: row; align-items: center"
      @click="handleSelectStatus(status.state, status.action_when_select)"
    >
      <a-badge :color="status.color" :text="status.title_for_footer_menu" />
    </a-menu-item>
  </a-sub-menu>
  <a-menu v-else id="states" class="menu-footer__submenu--states">
    <a-menu-item
      v-for="status in profile_states_for_list"
      :key="status._id"
      :id="status.state"
      class="fix-center"
      style="display: flex; flex-direction: row; align-items: center"
      @click="handleSelectStatus(status.state, status.action_when_select)"
    >
      <a-badge :color="status.color" :text="status.title_for_footer_menu" />
    </a-menu-item>
  </a-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'MenuStates',
  props: {
    type: {
      type: String,
      default: 'submenu',
      required: false,
    },
  },
  data: () => ({}),
  mixins: [compareMixin],
  computed: {
    ...mapGetters(['profile', 'profile_states_for_list']),

    componentParent() {
      return this.type === 'submenu' ? 'a-sub-menu' : 'a-menu'
    },

    /**
     * Clase del popup de estados
     */
    popupClassNameStates() {
      const className = this.isAllowedFor(this.profile.type, ['admin'])
        ? 'menu-footer__popup-states--admin'
        : 'menu-footer__popup-states--agent'
      return `menu-footer__popup-states ${className}`
    },
  },
  methods: {
    handleSelectStatus(status, actions) {
      this.$emit('onSelectStatus', status, actions)
    },
  },
}
</script>

<style scoped></style>
