const getters = {
  totalTags: (store) => store.totalTags,
  stateAgends: (store) => store.stateAgends,
  dataNewTickets: (store) => store.dataNewTickets,
  statusesTime: (store) => store.statusesTime,
  timeAnswer: (store) => store.timeAnswer,
  stateTickets: (store) => store.stateTickets,
  stateSMS: (store) => store.stateSMS,
  stateTemplate: (store) => store.stateTemplate,
}

export default getters
