<template>
  <a-list-item class="list-item" type="flex" align="middle">
    <a-icon type="file-excel" class="list-item__icon" />
    <div>
      <a-popover title="Detalles de la descarga" placement="right">
        <template slot="content">
          <p>
            <span class="semibold-14">Dashboard</span> <br />
            <span>{{ sectionDownload }}</span>
            <br />
            <br />
            <span class="semibold-14">Filtros</span> <br />
            <span>Rango de fecha: </span><br />
            <span>{{ filtersDownload }}</span>
          </p>
        </template>
        <h5 class="semibold-14 list-item__title">
          {{ file.caption }}
          <a-icon type="info-circle" />
        </h5>
      </a-popover>
      <p class="list-item__description text--left">
        <span class="mrg-right-24"> Fecha de descarga: {{ date }}</span>
        <span v-if="typeModule"> Módulo: {{ typeModule }}</span>
      </p>
    </div>
    <div style="flex: 1 1 0%"></div>
    <a-row slot="actions" class="list-item__actions text--right">
      <!--Estado-->
      <a-tooltip>
        <template v-if="disabled" slot="title">
          Ya no se puede descargar este archivo porque han pasado 24 horas.
        </template>
        <a-tag v-if="tagStatus" class="list-item__tag" :color="tagStatus.color"
          >{{ tagStatus.title }}
          <a-icon v-if="tagStatus.icon" :type="tagStatus.icon" />
        </a-tag>
      </a-tooltip>
      <!--Acciones-->
      <a-row>
        <a-tooltip v-if="showActionDownload">
          <template v-if="disabled" slot="title">
            Ya no se puede descargar este archivo porque han pasado 24 horas.
          </template>
          <a-button
            icon="download"
            type="primary"
            @click="handleDownloadFile"
            ghost
            >Descargar</a-button
          >
        </a-tooltip>
        <a v-else-if="showActionCancel">Cancelar descarga</a>
        <a v-else-if="showActionDelete" @click="handleShowDeleteConfirmation"
          >Eliminar registro</a
        >
        <template v-if="showActionRetry">
          <a-divider type="vertical" />
          <a @click="retryDownload()">Reintentar descarga</a>
        </template>
      </a-row>
    </a-row>
  </a-list-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  DOWNLOADS_STATUS,
  DOWNLOADS_DASHBOARDS,
} from '@/app/downloads/utils/downloadsUtil'
import moment from 'moment'
import transformMixin from '@/app/shared/mixins/transform'
import { dowloadFile } from '@/app/downloads/utils/downloadsUtil'

export default {
  name: 'ListItemDownload',
  props: {
    typeModule: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    file: {
      type: Object,
      required: true,
    },
    created_at: {
      type: [String, Date],
      required: true,
    },
    _id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    subType: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
    },
  },
  mixins: [transformMixin],
  computed: {
    ...mapGetters(['profile']),

    // ACCIONES DE LA DESCARGA
    hasDisabled() {
      return (
        this.status &&
        DOWNLOADS_STATUS[this.status] &&
        this.disabled &&
        DOWNLOADS_STATUS[this.status].disabled
      )
    },
    showActionDownload() {
      if (!this.status) return
      return this.hasDisabled
        ? DOWNLOADS_STATUS[this.status].disabled.actions.includes('download')
        : DOWNLOADS_STATUS[this.status].actions.includes('download')
    },
    showActionCancel() {
      return (
        this.status && DOWNLOADS_STATUS[this.status].actions.includes('cancel')
      )
    },
    showActionDelete() {
      if (!this.status) return
      return this.hasDisabled
        ? DOWNLOADS_STATUS[this.status].disabled.actions.includes('delete')
        : DOWNLOADS_STATUS[this.status].actions.includes('delete')
    },
    showActionRetry() {
      return (
        this.status && DOWNLOADS_STATUS[this.status].actions.includes('retry')
      )
    },
    date() {
      return moment(this.created_at).format('LLL')
    },
    tagStatus() {
      if (!this.status) return
      return this.hasDisabled
        ? DOWNLOADS_STATUS[this.status].disabled.tag
        : DOWNLOADS_STATUS[this.status].tag
    },
    sectionDownload() {
      return this.subType && DOWNLOADS_DASHBOARDS[this.subType].title
    },
    filtersDownload() {
      return this.params
        ? `${moment(this.params.dateStart).format('LLL')} - ${moment(
            this.params.dateEnd
          ).format('LLL')}`
        : null
    },
  },
  methods: {
    ...mapActions([
      'deleteDownload',
      'exportNewTickets',
      'exportStatusesTickets',
      'exportAverageAnswer',
      'exportTemplate',
      'exportTotalTags',
      'exportSMS',
      'exportSchedules',
      'exportAverageStatuses',
    ]),

    /**
     * Descarga el archivo
     */
    handleDownloadFile() {
      if (this.file && this.file.url) {
        dowloadFile(this.file.url)
        this.$message.success('Se descargó su archivo exitosamente')
      }
    },
    /**
     * Muestra la confirmacion para eliminar
     */
    handleShowDeleteConfirmation() {
      this.$confirm({
        title: `¿Estás  ${this.transformWordGender(
          'seguro',
          this.profile.gender
        )} de eliminar la descarga?`,
        content:
          'Recuerda que la descarga desaparecerá de tu registro y su proceso no continuará.',
        cancelText: 'Cancelar',
        okText: 'Eliminar',
        okType: 'danger',
        onOk: async () => {
          await this.deleteItem()
        },
        centered: true,
      })
    },
    /**
     * Eliminar item
     */
    async deleteItem() {
      const response = await this.deleteDownload(this._id)
      if (response.success) this.$message.success('Se eliminó exitosamente')
      else this.$message.error('Ocurrio un error' || response.details)
    },
    retryDownload() {
      switch (this.subType) {
        case 'newTickets':
          return this.exportNewTickets(this.params)
        case 'ticketsStates':
          return this.exportStatusesTickets(this.params)
        case 'averageAnswer':
          return this.exportAverageAnswer(this.params)
        case 'templates':
          return this.exportTemplate(this.params)
        case 'tagsLine':
          return this.exportTotalTags(this.params)
        case 'sms':
          return this.exportSMS(this.params)
        case 'resumeSchedules':
          return this.exportSchedules(this.params)
        case 'averageStateAgents':
          this.exportAverageStatuses(this.params)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.list-item
  border-bottom: 1px solid $gray_5
  height: 96px
  padding: 24px 20px
  &__icon
    margin-right: 16px
    font-size: 32px
  &__title
    margin-bottom: 12px
    text-align: left
    width: fit-content
  &__description
    margin-bottom: 0px
  &__tag
    margin: 0px 0px 12px 0px
  &__actions
    margin-left: -20px
  &--bg
    &-green
      background-color: $polar-green_6
      -webkit-animation: blink 1.33s ease-out 3s
      -moz-animation: blink 1.33s ease-out 3s
      animation: blink 1.33s ease-out 3s
    &-red
      background-color: $red_1
    &-orange
      background-color: $orange_1

@keyframes blink
  0%
    opacity: 1
  20%
    opacity: .3
  100%
    opacity: 1
</style>
