<template>
  <a-avatar
    v-if="src"
    :src="src"
    :size="sizeResponsive"
    class="avatar mrg-right-12"
  />
  <a-avatar
    v-else
    :size="sizeResponsive"
    class="avatar mrg-right-12"
    icon="user"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AvatarClient',
  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: Number,
      default: 24,
      required: false,
    },
    xs: {
      type: Number,
      default: null,
      required: false,
    },
    sm: {
      type: Number,
      default: null,
      required: false,
    },
    md: {
      type: Number,
      default: null,
      required: false,
    },
    lg: {
      type: Number,
      default: null,
      required: false,
    },
    xl: {
      type: Number,
      default: null,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['active_breakpoint']),

    sizeResponsive() {
      const breakpoint = this.active_breakpoint.breakpoint
      const sizeResponsive = this[breakpoint]
      if (sizeResponsive) return sizeResponsive
      else return this.size
    },
  },
}
</script>

<style scoped></style>
