<template>
  <div class="channel">
    <ChannelTemplate />
  </div>
</template>
<script>
import ChannelTemplate from '@/app/channels/components/templates/ChannelTemplate.vue'

export default {
  name: 'Channel',
  components: { ChannelTemplate },
}
</script>
