import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_ROLE_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },

  /**
   * Setea Roles a la Lista de Roles
   * @param {*} state
   * @param {String[]} roles Arreglo de Roles
   */
  SET_ROLES(state, roles) {
    state.roles = roles
  },
}

export default mutations
