<template>
  <div
    class="section-mode-agent"
    :class="switchSupervisor.active ? 'active' : 'inactive'"
    style="margin-bottom: 20px"
  >
    <div class="section-mode-agent-header">
      <h6 class="heading-h6 title">Habilitar para supervisores</h6>
      <a-switch v-model="switchSupervisor.active" @change="handleValueSwitch" />
    </div>
    <p class="body-2 description">
      Recuerda que los supervisores también podrán ser supervisados.
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'SectionModeSupervisorAgent',
  mixins: [compareMixin],
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setValue()
  },
  computed: {
    ...mapGetters(['profile']),
    switchSupervisor() {
      if (!this.company) return { active: false }
      return { active: this.company.settings.userMode.supervisorAgent }
    },
  },
  methods: {
    ...mapActions(['updateSupervisorModeAgent', 'listAgentsAsigned']),
    /**Setea los valores al switch */
    setValue() {
      this.switchSupervisor.active =
        this.company.settings.userMode.supervisorAgent
    },
    async handleValueSwitch() {
      const self = this
      if (!this.switchSupervisor.active) {
        this.$confirm({
          title:
            '¿Estás segur@ de inactivar el modo agente para los supervisores?',
          content:
            'Recuerda que todos los tickets abiertos serán transferidos a otro agente de la misma cola.',
          okText: 'Inactivar',
          cancelText: 'Cancelar',
          centered: true,
          async onOk() {
            await self.submitChanges(self.switchSupervisor)
          },
          onCancel() {
            self.switchSupervisor.active = !self.switchSupervisor.active
          },
        })
      } else {
        await self.submitChanges(this.switchSupervisor)
      }
    },
    /**
     * Enviar y guardar cambios
     * @param {Object} switchSupervisor
     * @param {Boolean} switchSupervisor.active
     */
    async submitChanges(switchSupervisor) {
      const response = await this.updateSupervisorModeAgent(switchSupervisor)
      if (response.success) {
        this.$message.success('Se guardó correctamente')
        if (this.isAllowedFor(this.profile.type, ['supervisor']))
          window.location.reload()
        else this.listAgentsAsigned()
      } else return this.$message.error('Ocurrió un error')
    },
  },
}
</script>

<style lang="sass" scoped>
$width-switch: 44px

.section-mode-agent
  border: 1px solid #D9D9D9
  border-radius: 8px
  padding: 12px 20px
  &-header
    display: flex
    flex-direction: row
    justify-content: space-between
    .title
      text-align: left
      color: $gray_dark_800
  .description
    text-align: left
    margin-right: calc( #{$width-switch + 32px} )
    margin-bottom: 0
.active
  color: $gray_dark_800
  .section-mode-agent-header
    .title
      color: $gray_dark_800
.inactive
  color: $gray_dark_700
  .section-mode-agent-header
    .title
      color: $gray_dark_700
</style>
