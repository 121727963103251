const STATUSES_TICKETS = [
  {
    _id: 'queued',
    name: 'Cola de espera',
    title: 'Tickets que aun no son transferidos a un agente.',
    color: '#C22FB3',
    visible: true,
  },
  {
    _id: 'issued',
    name: 'Entregado',
    title: 'Tickets que han sido entregados a los agentes.',
    color: '#CE8934',
    visible: true,
  },
  {
    _id: 'opened',
    name: 'Respondido',
    title: 'Tickets que han sido respondidos por un agente por primera vez.',
    color: '#2FC25B',
    visible: true,
  },
  {
    _id: 'ended',
    name: 'Finalizado',
    title: 'Tickets que han sido resueltos por los agentes.',
    color: '#1890FF',
    visible: true,
  },
  {
    _id: 'unanswered',
    name: 'Sin respuesta',
    color: '#2F61C2',
    visible: false,
  },
]

const STATUSES_TEMPLATE = [
  {
    _id: 'queued',
    name: 'En cola',
    color: '#C343B7',
  },
  {
    _id: 'sent',
    name: 'Enviado',
    color: '#8BC343',
  },
  {
    _id: 'delivered',
    name: 'Entregado',
    color: '#C19908',
  },
  {
    _id: 'read',
    name: 'Leído',
    color: '#43ACC3',
  },
  {
    _id: 'failed',
    name: 'Fallido',
    color: '#CF3636',
  },
]

const COLUMNS_FILTERS_TICKETS = [
  {
    title: 'ID del ticket',
    key: 'ticket',
    checked: true,
    disabled: true,
    show: true,
  },
  {
    title: 'Nombre Cliente',
    key: 'client',
    checked: true,
    disabled: true,
    show: true,
  },
  {
    title: 'Agente asignado',
    key: 'agent',
    checked: true,
    show: true,
  },
  {
    title: 'Estado',
    key: 'status',
    checked: true,
    show: true,
  },
  {
    title: 'Estados de plantilla de campaña',
    key: 'lastStatusFirstTemplate',
    checked: false,
    show: false,
  },
  {
    title: 'Canal',
    key: 'channel',
    checked: true, // true
    show: true,
  },
  {
    title: 'ID Canal',
    key: 'channelCompanyId',
    checked: true, // true
    show: true,
  },
  {
    title: 'Cola',
    key: 'line',
    checked: true,
    show: true,
  },
  {
    title: 'Favorito',
    key: 'favorite',
    checked: false,
    show: true,
  },
  {
    title: 'Etiquetas',
    key: 'tags',
    checked: false,
    show: true,
  },
  {
    title: 'Buzón',
    key: 'mailbox',
    checked: true,
    show: true,
  },
  {
    title: 'Fecha de creación',
    key: 'created',
    checked: true, // true
    show: true,
  },
  {
    title: 'Teléfono',
    key: 'phone',
    checked: true, // true
    show: true,
  },
  {
    title: 'Email del cliente',
    key: 'client_email',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Tipo de documento del cliente',
    key: 'client_documentType',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Nro documento del cliente',
    key: 'client_documentNumber',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'País del cliente',
    key: 'client_country',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Ciudad del cliente',
    key: 'client_city',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Dirección del cliente',
    key: 'client_address',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Nacimiento del cliente',
    key: 'client_birth',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Sexo del cliente',
    key: 'client_gender',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Observación del cliente',
    key: 'client_remark',
    checked: false,
    isFieldClient: true,
    show: true,
  },
  {
    title: 'Productos asignados',
    key: 'productsAssigned',
    checked: false, // true
    show: true,
  },
  {
    title: 'Campaña',
    key: 'strategy',
    checked: true, // true
    show: true,
  },
]

const COLUMNS_TABLE_TICKETS = {
  ticket: {
    title: 'ID',
    dataIndex: 'ticket',
    key: 'ticket',
    width: '110px',
    scopedSlots: { customRender: 'ticket' },
  },
  client: {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    width: '280px',
    scopedSlots: { customRender: 'client' },
    ellipsis: true,
  },
  agent: {
    title: 'Agente asignado',
    dataIndex: 'agent',
    key: 'agent',
    width: '280px',
    scopedSlots: { customRender: 'agent' },
    ellipsis: true,
  },
  status: {
    title: 'Estado',
    dataIndex: 'subStatus',
    key: 'status',
    width: '189px',
    scopedSlots: { customRender: 'status' },
  },
  status_template: {
    title: 'Estados de plantilla de campañas',
    key: 'status_template',
    dataIndex: 'lastStatusFirstTemplate.status',
    width: '158px',
    scopedSlots: { customRender: 'status_template' },
  },
  channel: {
    title: 'Canal',
    dataIndex: 'channel',
    key: 'channel',
    width: '85px',
    scopedSlots: { customRender: 'channel' },
  },
  channelCompanyId: {
    title: 'ID de Canal',
    dataIndex: 'channelCompanyId',
    key: 'channelCompanyId',
    width: '200px',
    ellipsis: true,
    scopedSlots: { customRender: 'channelCompanyId' },
  },
  line: {
    title: 'Cola',
    dataIndex: 'company.lineName',
    key: 'line',
    width: '149px',
    scopedSlots: { customRender: 'line' },
  },
  favorite: {
    title: 'Favorito',
    align: 'center',
    dataIndex: 'settings.starred',
    key: 'favorite',
    width: '84px',
    scopedSlots: { customRender: 'favorite' },
  },
  mailbox: {
    title: 'Buzón',
    dataIndex: 'mailboxId',
    key: 'mailbox',
    width: '150px',
    scopedSlots: { customRender: 'mailbox' },
  },
  created: {
    title: 'Fecha de creación',
    dataIndex: 'created_at',
    key: 'created',
    width: '200px',
    scopedSlots: { customRender: 'created' },
    ellipsis: true,
  },
  phone: {
    title: 'Teléfono',
    key: 'phone',
    dataIndex: 'phone',
    width: '150px',
    scopedSlots: { customRender: 'phone' },
  },
  client_email: {
    title: 'Email Cliente',
    key: 'client_email',
    dataIndex: 'client.email',
    width: '150px',
    scopedSlots: { customRender: 'client_email' },
  },
  client_documentType: {
    title: 'Tipo documento Cliente',
    key: 'client_documentType',
    dataIndex: 'client.documentType',
    width: '150px',
    scopedSlots: { customRender: 'client_documentType' },
  },
  client_documentNumber: {
    title: 'Nro documento Cliente',
    key: 'client_documentNumber',
    dataIndex: 'client.documentNumber',
    width: '150px',
    scopedSlots: { customRender: 'client_documentNumber' },
  },
  client_country: {
    title: 'País Cliente',
    key: 'client_country',
    dataIndex: 'client.country',
    width: '150px',
    scopedSlots: { customRender: 'client_country' },
  },
  client_city: {
    title: 'Ciudad Cliente',
    key: 'client_city',
    dataIndex: 'client.city',
    width: '150px',
    scopedSlots: { customRender: 'client_city' },
  },
  client_address: {
    title: 'Dirección Cliente',
    key: 'client_address',
    dataIndex: 'client.address',
    width: '150px',
    scopedSlots: { customRender: 'client_address' },
  },
  client_birth: {
    title: 'Nacimiento Cliente',
    key: 'client_birth',
    dataIndex: 'client.birth',
    width: '150px',
    scopedSlots: { customRender: 'client_birth' },
  },
  client_gender: {
    title: 'Sexo Cliente',
    key: 'client_gender',
    dataIndex: 'client.gender',
    width: '150px',
    scopedSlots: { customRender: 'client_gender' },
  },
  client_remark: {
    title: 'Observación Cliente',
    key: 'client_remark',
    dataIndex: 'client.remark',
    width: '150px',
    scopedSlots: { customRender: 'client_remark' },
  },
  productsAssigned: {
    title: 'Productos asignados',
    key: 'productsAssigned',
    checked: true, // true
    width: '180px',
    scopedSlots: { customRender: 'productsAssigned' },
  },
  strategy: {
    title: 'Campaña',
    key: 'strategy',
    dataIndex: 'strategy.name',
    width: '200px',
    ellipsis: true,
  },
  action: {
    title: 'Acción',
    key: 'action',
    fixed: 'right',
    width: '158px',
    scopedSlots: { customRender: 'action' },
    default: true,
    isLast: true,
  },
}

const STATUSES_TICKETS_OBJECT = new Object()
STATUSES_TICKETS.forEach((status) => {
  STATUSES_TICKETS_OBJECT[status._id] = status
})

export {
  STATUSES_TICKETS,
  STATUSES_TEMPLATE,
  COLUMNS_FILTERS_TICKETS,
  COLUMNS_TABLE_TICKETS,
  STATUSES_TICKETS_OBJECT,
}
