const CHANNELS_SUPPORTED = [
  {
    channel: 'whatsapp',
    value: 'whatsapp',
    title: 'WhatsApp',
    description: 'Envía mensajes masivos usando plantillas de  WhastApp.',
  },
  {
    channel: 'sms',
    value: 'sms',
    title: 'SMS',
    description: 'Envía mensaje masivos por  mensajes de SMS.',
  },
]

const STEPS_BY_CHANNEL = {
  whatsapp: {
    steps: [
      {
        step: 1,
        default: true,
        step_title: 'Datos generales',
        title: 'Datos generales de la campaña',
        ok_text: 'Continuar',
        cancel_text: 'Cancelar',
      },
      {
        step: 2,
        step_title: 'Plantilla',
        title: 'Campos relacionados a la plantilla',
        ok_text: 'Continuar',
        cancel_text: 'Volver',
      },
      {
        step: 3,
        step_title: 'Subida de contactos',
        title: 'Subida de contactos',
        ok_text: 'Crear campaña',
        cancel_text: 'Volver',
        with_tier: true,
      },
    ],
    preview_mobile: {
      type: 'whatsapp',
      allowed_media: true,
      default_message:
        'Selecciona una plantilla, para previsualizar el contenido de una plantilla.',
    },
  },
  sms: {
    steps: [
      {
        step: 1,
        default: true,
        step_title: 'Datos y mensaje',
        title: 'Datos generales y mensaje',
        ok_text: 'Continuar',
        cancel_text: 'Cancelar',
      },
      {
        step: 2,
        step_title: 'Subida de contactos',
        title: 'Subida de contactos',
        ok_text: 'Crear campaña',
        cancel_text: 'Volver',
      },
    ],
    preview_mobile: {
      type: 'sms',
      allowed_media: true,
      default_message: 'Visualiza el contenido de tu mensaje SMS aquí. ',
    },
  },
}

export { CHANNELS_SUPPORTED, STEPS_BY_CHANNEL }
