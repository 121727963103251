import { mapGetters } from 'vuex'
import { USERS } from '@/app/accounts/utils/accountsUtil'

export default {
  computed: {
    ...mapGetters(['permissions', 'roles']),

    /**
     * Permisos del modulo de cuentas
     * @returns {Object} permissions
     * @returns {Boolean} permissions.filter - si se pueden filtrar los usuarios
     * @returns {String[]} permissions.users_to_manage - tipo de usuarios que puede gestionar
     */
    permissionsModule() {
      const name = this.$route.name
      return this.permissions[name]
    },

    /**
     * Tipo de usuarios de acuerdo a los permisos
     * @returns {Object[]} typeUsers
     * @returns {String} typeUsers[].value
     * @returns {String} typeUsers[].title
     * @returns {String} typeUsers[].enabled
     */
    typeUsersPerPermissions() {
      const name = this.$route.name
      const usersToManage = this.permissions[name]?.users_to_manage

      const usersArray = Object.values(USERS)
      return usersArray.filter(
        (user) =>
          this.roles.includes(user.value) &&
          usersToManage.includes(user.value) &&
          user.enabled
      )
    },
  },
}
