import { render, staticRenderFns } from "./AvatarClient.vue?vue&type=template&id=96529bc0&scoped=true&"
import script from "./AvatarClient.vue?vue&type=script&lang=js&"
export * from "./AvatarClient.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96529bc0",
  null
  
)

export default component.exports