/**
 * Filtra los mensajes para que no se repitan
 * @param {Object[]} messages - mensajes de un ticket
 * @param {String} message[]._id
 * @param {String} message[].apiMessageId
 * @param {String} message[].scheduleId
 * @return {Object[]} messages
 */
const filterMessagesNoRepeated = ({ messages }) => {
  let hash = {} // objeto donde se guardará identifier como key

  const formattedMessages = messages.map((message) => {
    // si el mensaje no tiene apiMessageId, tomará el valor de scheduleId, sino tomará el valor de _id
    // identifier: nueva propiedad que almacenará a apiMessageId
    message.identifier =
      message.apiMessageId || message.scheduleId || message._id
    return message
  })

  // verifica que el indentifier no se repita
  const filteredMessages = formattedMessages.filter((message) =>
    hash[message.identifier] && message.identifier
      ? false
      : (hash[message.identifier] = true)
  )
  return filteredMessages
}

/**
 * Filtra los tickets de acuerdo al userId
 * @param {Object[]} tickets
 * @param {String} userId
 * @returns
 */
const filterTicketsByUserId = (tickets, userId) => {
  const mineTickets = tickets.filter((ticket) => {
    if (!ticket) return false
    // si el ticket tiene el objeto agente
    if (ticket.agent && ticket.agent.userId)
      return ticket.agent.userId === userId
    // si no tiene buscará entre el array de usuarios
    const activeUser = ticket.users.find((user) => user.active)
    return activeUser && activeUser.userId === userId
  })
  const ticketsId = mineTickets.map((ticket) => ticket._id)
  return { tickets: mineTickets, ticketsId }
}

/**
 * Filtra los tickets no leidos
 * @param {Object[]} tickets
 * @param {Number} tickets[].pendings
 * @returns
 */
const filterUnreadTickets = (tickets) => {
  if (!tickets) return []
  const filtered = tickets.filter(
    (ticket) => ticket.pendings && ticket.pendings > 0
  )
  return filtered
}
/**
 * Filtra los tickets no leidos por usuario
 * @param {Object[]} dataTickets
 * @param {Number} dataTickets[].pendings
 * @param {String} userId
 * @returns
 */
const filterTicketsUnreadByUserId = (dataTickets, userId) => {
  const { tickets } = filterTicketsByUserId(dataTickets, userId)
  const unreadTickets = filterUnreadTickets(tickets)
  const ticketsId = unreadTickets.map((ticket) => ticket._id)
  return { ticketsId, tickets: unreadTickets }
}

export default {
  filterMessagesNoRepeated,
  filterTicketsByUserId,
  filterUnreadTickets,
  filterTicketsUnreadByUserId,
}
