import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key_restmain = process.env.VUE_APP_CRYPT_KEY_RESTMAIN
const simpleCrypto = new SimpleCrypto(crypt_key_restmain)

const actions = {
  /**
   * Integra el canal de SMS
   * @param {*} context
   * @param {Object} args
   * @param {String} args.apiChannelId
   * @param {String} args.user
   * @param {String} args.password
   * @returns
   */
  async integrateProviderSms(context, { apiChannelId, user, password }) {
    try {
      const response = await axios.post(
        `${vari.UHR}/admin/company/channels/sms/${apiChannelId}`,
        {
          user,
          password: simpleCrypto.encrypt(password),
        }
      )
      if (response.data.success) {
        // para verficar los canales usados
        context.dispatch('getSimpleCompanyChannels')
        context.dispatch('getCompanyPricing', false)
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response?.data
    }
  },
  /**
   * Elimina el canal de SMS
   * @param {*} context
   * @param {String} channelCompanyId
   * @returns
   */
  async deleteChannelSms(context, channelCompanyId) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/admin/company/channels/sms/${channelCompanyId}`
      )
      if (response.data.success) {
        // actualiza el valor active en los canales paginados
        context.commit('UPDATE_ACTIVE_COMPANY_CHANNEL', {
          channelCompanyId: channelCompanyId,
          active: false,
        })
        // Elimina el canal de la lista de canales simples
        context.commit('DELETE_SIMPLE_COMPANY_CHANNEL', channelCompanyId)
        // hace la peticion para verificar el permiso del canal
        context.dispatch('getCompanyPricing', false)
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response?.data
    }
  },
}

export default actions
