<template>
  <div class="view">
    <restore-template />
  </div>
</template>
<script>
import RestoreTemplate from '@/app/auth/components/templates/RestoreTemplate.vue'

export default {
  name: 'RestorePassword',
  components: {
    RestoreTemplate,
  },
}
</script>
<style lang="sass" scoped></style>
