<template>
  <div class="section-ticket-details scrollbar_basic">
    <h6 v-if="showTitle" class="heading-h7 section__title">
      Detalles de ticket
    </h6>
    <!--Canal del ticket-->
    <div class="group">
      <h6 class="heading-h7 section__subtitle">Canal e identificador</h6>
      <p v-if="!originChannel" class="empty">No hay canal para este ticket</p>
      <a-row v-else type="flex" align="middle">
        <social-icon :size="16" :type="originChannel.channel" />
        <span class="regular-14 mrg-bottom-0 mrg-left-4">{{
          originChannel.title
        }}</span>
      </a-row>
    </div>
    <!--Campos personalizados del ticket-->
    <ul class="group custom-fields">
      <h6 class="heading-h7 section__subtitle">
        Campos personalizados del ticket
      </h6>
      <p v-if="customFieldsTicket.length === 0" class="empty">
        No hay campos personalizados
      </p>
      <li
        v-for="field in customFieldsTicket"
        :key="field.customFieldId"
        class=""
      >
        <p class="body-2">{{ field.name }}:</p>
        <p class="body2">
          {{ valueCustomField(field) }}
          <i v-if="field.deleted">( Campo eliminado )</i>
        </p>
      </li>
    </ul>
    <!--Campos personalizados del cliente-->
    <ul class="group custom-fields">
      <h6 class="heading-h7 section__subtitle">
        Campos personalizados del cliente
      </h6>
      <p v-if="customFieldsClient.length === 0" class="empty">
        No hay campos personalizados
      </p>
      <li
        v-for="field in customFieldsClient"
        :key="field.customFieldId"
        class=""
      >
        <p class="body-2">{{ field.name }}:</p>
        <p class="body2">
          {{ valueCustomField(field) }}
          <i v-if="field.deleted">( Campo eliminado )</i>
        </p>
      </li>
    </ul>
    <!--Etiquetas-->
    <ul class="group">
      <h6 class="heading-h7 section__subtitle">Etiquetas</h6>
      <p v-if="tags.length === 0" class="empty">No hay etiquetas</p>
      <li v-for="tag in tags" :key="tag.tagId">
        <ColorPick
          :color="tag.color"
          :pickStyles="{ borderRadius: '8px', height: '8px', width: '25px' }"
        />
        <p style="margin-left: 8px" class="body-2">{{ tag.title }}</p>
      </li>
    </ul>
    <!--integraciones-->
    <ul class="group">
      <h6 class="heading-h7 section__subtitle">Integraciones</h6>
      <p v-if="integrations.length === 0" class="empty">No hay integraciones</p>
      <li v-for="integration in integrations" :key="integration.integrationId">
        <p class="body-2">{{ integration.platform }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import ColorPick from '@/app/shared/components/pickers/ColorPick.vue'
import moment from 'moment'

export default {
  name: 'SectionTicketDetails',
  props: {
    originChannel: {
      type: Object,
      required: true,
    },
    customFieldsTicket: { require: false, type: Array, default: () => [] },
    customFieldsClient: { require: false, type: Array, default: () => [] },
    tags: { require: false, type: Array },
    integrations: { require: false, type: Array },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ColorPick,
    SocialIcon,
  },
  data: () => ({}),
  computed: {},
  methods: {
    valueCustomField(customField) {
      switch (customField.type) {
        case 'text':
          return customField.value.text
        case 'dropdown': {
          let options = ''

          customField.value.options.forEach((option) => {
            options.length > 0
              ? (options += `, ${option.name}`)
              : (options += `${option.name}`)
          })
          return options
        }
        case 'date':
          return moment(customField.value.date).format('LLL')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.section-ticket-details
  padding: 20px
  height: 100%
  overflow-y: auto
  @include xs
    padding: 12px 20px
    border-top: 1px solid $gray_6
  @include sm
    padding: 12px 20px
    border-top: 1px solid $gray_6
  @include md
    padding: 12px 20px
    border-top: 1px solid $gray_6
  >h6
    color: $gray_dark_900
    margin-bottom: 0
  .section__title
    margin-bottom: 8px
    @include xs
      display: none
    @include sm
      display: none
    @include md
      display: none
  .section__subtitle
    color: $gray_dark_900
  .group
    margin-top: 16px
    margin-bottom: 16px
    padding: 0
    @include sm
      margin-top: 0px
    @include xs
      margin-top: 0px
    @include md
      margin-top: 0px
    &:first-child
      margin-top: 0px !important
    h6
      color: $gray_dark_900
    li
      list-style: none
      margin-bottom: 4px
      display: flex
      flex-direction: row
      align-items: center
      p
        margin-bottom: 0
        color: $gray_dark_600
        display: inline-block
    .empty
      color: $gray_dark_500
  .custom-fields
    li
      display: block
      p:first-child
        margin-right: 12px
        color: $gray_dark_900
        display: inline-block
</style>
