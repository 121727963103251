<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="$emit('onClose')"
    width="34em"
    :bodyStyle="bodyStyle"
    :afterClose="handleReset"
    :maskClosable="false"
    centered
  >
    <a-icon :type="icon" class="modal__icon" :class="`modal__icon--${type}`" />
    <!--Titulo-->
    <h6 class="heading-h6 modal__title text--center" v-html="title"></h6>
    <!--Alerta de error -->
    <a-alert
      v-if="error.visible"
      class="mrg-bottom-8 full-width"
      type="error"
      :message="error.message"
      show-icon
    />

    <!--Subtitulo-->
    <p v-if="subTitle" class="body-2 mrg-bottom-16 mrg-top-16">
      {{ subTitle }}
    </p>
    <!--Descripcion-->
    <slot name="description">
      <div v-html="description"></div>
    </slot>
    <!--Lista-->
    <ul v-if="list" class="body-2">
      <li v-for="(item, index) in list" :key="index">
        {{ item }}
      </li>
    </ul>

    <!--Cuerpo del modal personalizado-->
    <slot></slot>
    <!--Validación de palabra-->
    <template v-if="showValidationWord">
      <p v-if="strongWord" class="body-2 text--left full-width">
        Escribe la palabra "<strong>{{ strongWord }}</strong
        >"
      </p>
      <a-input v-model="valueInput" placeholder="Escribe aquí..." />
    </template>
    <!--Footer del modal-->
    <a-divider v-if="showCancel" class="modal__divider" />
    <footer
      :class="{
        'modal__footer--default': !showCancel,
        'modal__footer--horizontal': showCancel,
      }"
    >
      <a-button
        v-if="showCancel"
        class="modal__button"
        :class="{ 'mrg-right-12': showCancel }"
        @click="$emit('onClose')"
        >Cancelar</a-button
      >
      <a-button
        class="modal__button"
        :type="okType"
        @click="handleOk"
        :disabled="okDisabled || !isFullValidationWord || button.disabled"
        :loading="button.loading"
        >{{ okLocalText }}</a-button
      >
    </footer>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalType',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
    },
    onOk: {
      type: Function,
      required: false,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'confirmation',
    },
    okText: {
      type: String,
      required: false,
    },
    messageSuccess: {
      type: String,
      required: false,
      default: 'Se ejecutó exitosamente',
    },
    customRequest: {
      type: Function,
      required: false,
      default: null,
    },
    list: {
      type: Array,
      required: false,
      default: null,
    },
    showValidationWord: {
      type: Boolean,
      required: false,
      default: false,
    },
    strongWord: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 36px',
    },
    button: {
      loading: false,
      disabled: false,
    },
    error: {
      visible: false,
      message: '',
    },
    valueInput: '',
  }),
  computed: {
    ...mapGetters(['profile']),

    icon() {
      switch (this.type) {
        case 'confirmation':
          return 'question-circle'
        case 'delete':
          return 'delete'
        default:
          return ''
      }
    },

    okLocalText() {
      switch (this.type) {
        case 'confirmation':
          return this.okText
        case 'delete':
          return this.button.loading ? 'Eliminando' : 'Eliminar'
        default:
          return this.okText
      }
    },

    okType() {
      switch (this.type) {
        case 'confirmation':
          return 'primary'
        case 'delete':
          return 'danger'
        default:
          return ''
      }
    },
    /**
     * @return {Boolean} si la palabra validada está completa
     */
    isFullValidationWord() {
      if (!this.showValidationWord) return true
      return this.valueInput.length > 0 && this.valueInput === this.strongWord
    },
  },
  methods: {
    /**
     * Ejecuta la eliminacion de una variable
     */
    async handleOk() {
      this.button.loading = true
      this.button.disabled = true
      let response = null
      if (this.customRequest) response = await this.customRequest()
      else response = await this.onOk()
      this.button.loading = false
      this.button.disabled = false

      // si hay una peticion personalizada, ya no se ejecutará lo siguiente
      if (this.customRequest) return
      if (response.success) {
        this.$message.success(this.messageSuccess)
        this.$emit('onClose')
        this.handleReset()
      } else {
        const message =
          response.message || response.details || 'Ocurrió un error 😥'
        this.error.visible = true
        this.error.message = message
      }
    },
    /**
     * Resetea el modal
     */
    handleReset() {
      this.button.loading = false
      this.button.disabled = false
      this.error = {
        visible: false,
        message: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__icon
    font-size: 64px
    &--delete
      color: $red_6
    &--confirmation
      color: $gold_6
  &__title
    margin: 16px auto
  &__description
    text-align: left
    width: 100%
    margin: 0px 0px 8px
  &__button
    width: 100%
  &__list
    width: 100%
    min-height: 200px
  &__footer
    width: 100%
    &--default
      .modal__button
      width: 100%
    &--horizontal
      +flex(row, center, center)
</style>
