<template>
  <div>
    <div class="custom-field">
      <div class="custom-field__content">
        <a-select
          class="filter-item__select"
          @change="onCustomFieldChange"
          :default-value="field.customFieldId"
        >
          <a-select-opt-group v-for="item in listTypeFields" :key="item.type">
            <span slot="label"
              ><a-icon :type="item.typeIcon" class="mrg-right-8" />{{
                item.title
              }}</span
            >
            <a-select-option
              v-for="custom in filterTypes(item.type)"
              :key="custom._id"
              :value="custom._id"
              :disabled="custom.disabled"
            >
              {{ custom.name }}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
        <p class="regular-12 custom-field__text-middle">Es:</p>
        <a-form-item>
          <a-select
            v-if="customField.type == 'dropdown'"
            class="filter-item__select"
            v-decorator="[
              field.customFieldId,
              {
                rules: rules.date,
              },
            ]"
            @change="onChangeValue($event, 'dropdown')"
            :mode="customField.multiple ? 'multiple' : ''"
            placeholder="Selecciona"
          >
            <a-select-option
              v-for="option in customField.options"
              :key="option.idOption"
              :value="option.idOption"
              :title="option.name"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
          <a-input
            @input="onChangeValue($event.target.value)"
            v-else-if="customField.type == 'text'"
            v-decorator="[
              field.customFieldId,
              {
                rules: rules.default,
              },
            ]"
            placeholder="Escribe aquí..."
          />
          <template v-else-if="customField.type == 'date'">
            <a-range-picker
              v-if="hasRange"
              @change="onChangeValue"
              v-decorator="[
                field.customFieldId,
                {
                  rules: rules.date,
                },
              ]"
              :allowClear="false"
            />
            <a-date-picker
              v-else
              class="full-width"
              placeholder="2020-05-25"
              v-decorator="[
                field.customFieldId,
                {
                  rules: rules.date,
                },
              ]"
              @change="onChangeValue"
              :allowClear="false"
            />
          </template>
        </a-form-item>
      </div>
      <div class="custom-field__remove">
        <a-icon
          @click="$emit('onRemove')"
          style="cursor-pointer custom-field__icon-close"
          type="close"
        />
      </div>
    </div>
    <a-divider
      class="custom-field__divider"
      :class="{ 'display-none': isLast }"
    />
  </div>
</template>
<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasRange: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    rules: {
      default: [
        { required: true, message: 'Completa este campo' },
        {
          whitespace: true,
          message: 'No se admiten espacios en blanco.',
        },
      ],
      date: [{ required: true, message: 'Completa este campo' }],
    },
    listTypeFields: [
      {
        id: 'cardfield-text',
        type: 'text',
        title: 'Texto',
        typeIcon: 'font-size',
      },
      {
        id: 'cardfield-dropdown',
        type: 'dropdown',
        title: 'Dropdown',
        typeIcon: 'down-square',
      },
      {
        id: 'cardfield-date',
        type: 'date',
        title: 'Date',
        typeIcon: 'calendar',
      },
    ],
  }),
  computed: {
    customField() {
      const customField = this.dataSource.find(
        (customField) => customField._id == this.field.customFieldId
      )
      return customField
    },
  },
  methods: {
    /**
     * Escucha los cambios del valor del campo y emite hacia el padre
     * @param {String} newVal - nuevo valor del campo
     * @param {String} type
     */
    onChangeValue(newVal, type) {
      let value = newVal
      if (type === 'dropdown')
        value = typeof value === 'string' ? [value] : value
      this.$emit('onChangeValue', this.field.key, value)
    },
    /**
     * Escucha los cambios del campo y emite el cambio hacia el padre
     * @param {String} idField
     */
    onCustomFieldChange(idField) {
      const newField = this.dataSource.find((elem) => elem._id === idField)
      if (newField !== undefined)
        this.$emit(
          'onChangeField',
          this.field.customFieldId,
          newField,
          this.field
        )
    },
    /**
     * Filtra por los tipos de campos
     * @param {String} type - text, dropdown, date
     * @return {Array} - data de acuerdo a type
     */
    filterTypes(type) {
      switch (type) {
        case 'text':
          return [...this.dataSource].filter((elem) => elem.type === 'text')
        case 'dropdown':
          return [...this.dataSource].filter((elem) => elem.type === 'dropdown')
        case 'date':
          return [...this.dataSource].filter((elem) => elem.type === 'date')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.custom-field
  display: flex
  justify-content: space-between
.custom-field__content
  width: 90%
.custom-field__remove
  display: flex
  justify-content: center
  align-items: flex-start
  width: 10%
  font-size: 10px
  margin-top: 10px
.custom-field__text-middle
  margin: 8px auto 8px auto
.custom-field__icon-close
  font-size: 14px
.custom-field__divider
  margin: 0px auto 24px auto
</style>
