<template>
  <div class="payment-rejecte-downgrade">
    <a-modal
      :visible="visible"
      width="440px"
      :footer="null"
      :closable="false"
      centered
      class="form-choose-downgrade-content-modal"
      :bodyStyle="{ padding: '36px' }"
    >
      <a-row type="flex" justify="center">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32 4C16.5375 4 4 16.5375 4 32C4 47.4625 16.5375 60 32 60C47.4625 60 60 47.4625 60 32C60 16.5375 47.4625 4 32 4ZM32 55.25C19.1625 55.25 8.75 44.8375 8.75 32C8.75 19.1625 19.1625 8.75 32 8.75C44.8375 8.75 55.25 19.1625 55.25 32C55.25 44.8375 44.8375 55.25 32 55.25Z"
            fill="#F5222D"
          />
          <path
            d="M29 21C29 21.7956 29.3161 22.5587 29.8787 23.1213C30.4413 23.6839 31.2044 24 32 24C32.7956 24 33.5587 23.6839 34.1213 23.1213C34.6839 22.5587 35 21.7956 35 21C35 20.2044 34.6839 19.4413 34.1213 18.8787C33.5587 18.3161 32.7956 18 32 18C31.2044 18 30.4413 18.3161 29.8787 18.8787C29.3161 19.4413 29 20.2044 29 21ZM33.5 28H30.5C30.225 28 30 28.225 30 28.5V45.5C30 45.775 30.225 46 30.5 46H33.5C33.775 46 34 45.775 34 45.5V28.5C34 28.225 33.775 28 33.5 28Z"
            fill="#F5222D"
          />
        </svg>
      </a-row>
      <a-row
        type="flex"
        justify="center"
        class="payment-rejecte-downgrade__info"
      >
        <h2 class="payment-rejecte-downgrade__title heading-h5">
          Procederemos a bajar al plan Free
        </h2>
        <p class="payment-rejecte-downgrade__subtitle">
          Selecciona los datos que se quedarán contigo
        </p>
      </a-row>
      <a-row
        class="payment-rejecte-downgrade__warning"
        type="flex"
        justify="center"
      >
        <p>
          <span>
            Los datos que no se muestren, se eliminarán de acuerdo a la fecha de
            creación.
          </span>
          <a :href="vari.WEB_DOC">Ver más detalles</a>
        </p>
      </a-row>
      <div class="payment-rejecte-downgrade__form">
        <!-- Selección de usuarios -->
        <a-row class="payment-rejecte-downgrade__form-item">
          <p>Selecciona un segundo usuario</p>
          <a-select
            class="input"
            placeholder="Seleccione un usuario"
            style="width: 100%"
            v-model="userSelected"
          >
            <a-select-option
              v-for="user in usersToSelect"
              :key="user.userId"
              :value="user.userId"
              >{{ user.names + ' ' + user.surnames }}</a-select-option
            >
          </a-select>
        </a-row>
        <!-- Selección de colas -->
        <a-row
          class="
            payment-rejecte-downgrade__form-item
            payment-rejecte-downgrade__form-item--big
          "
        >
          <p>Selecciona 1 cola adicional a la general</p>
          <a-select
            class="input"
            placeholder="Seleccione cola"
            style="width: 100%"
            :filter-option="true"
            v-model="lineSelected"
          >
            <a-select-option v-for="line in availableLines" :key="line._id">
              {{ line.name }}
            </a-select-option>
          </a-select>
        </a-row>
        <!-- Selección de chatbots -->
        <a-row
          class="
            payment-rejecte-downgrade__form-item
            payment-rejecte-downgrade__form-item--big
          "
        >
          <p>Selecciona 1 chatbot</p>
          <a-select
            class="input"
            placeholder="Seleccione cola"
            style="width: 100%"
            @search="handleGetChatbots"
            :filter-option="true"
            v-model="chatbotSelected"
            show-search
          >
            <a-select-option v-for="chatbot in chatbots" :key="chatbot._id">
              {{ chatbot.name }}
            </a-select-option>
          </a-select>
        </a-row>

        <a-row class="payment-rejecte-downgrade__form-item">
          <p>
            Escribe la palabra <b>“{{ keyword }}”</b> para continuar
          </p>
          <a-input
            class="input"
            v-model="inputKeyword"
            placeholder="Escribe aqui..."
          />
        </a-row>
        <div class="payment-rejecte-downgrade__actions">
          <a-row>
            <a-button
              type="primary"
              :disabled="!buttonEnabled"
              :loading="loading"
              @click="handleOk"
              block
              >Bajar de plan</a-button
            >
          </a-row>
          <a-row class="text--center">
            <a-button
              class="payment-rejecte-downgrade__cancel"
              type="link"
              @click="handleCancelDowngrade"
            >
              Continuar en mi plan actual.
            </a-button>
          </a-row>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import vari from '@/app/shared/utils/variables'
import attemptMixin from '@/app/shared/mixins/attempt'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [attemptMixin],
  props: {
    // Mostrar modal
    visible: {
      type: Boolean,
    },
    keyword: {
      type: String,
      default: 'CONFIRMAR',
    },
  },
  data: () => ({
    vari: vari,
    inputKeyword: '',
    userSelected: null,
    lineSelected: null,
    chatbotSelected: null,
    maxChatbotsAvailables: 1,
    loading: false,
    usersToFilter: [],
  }),
  async created() {
    this.usersToFilter = await this.getUsersToSelectInDowngrade()
    await this.getAccountsUsers()

    this.intervalAttempt(() => {
      if (!this.profile) throw 'Not profile found'
      this.handleGetChatbots()
    })
  },
  computed: {
    ...mapGetters(['lines', 'profile', 'agentsAccounts', 'chatbots']),
    buttonEnabled() {
      return this.keyword === this.inputKeyword
    },
    availableLines() {
      if (!this.lines) return []
      return this.lines.filter((line) => !line.default)
    },
    usersToSelect() {
      return this.usersToFilter.filter(
        (user) => user.userId !== this.profile.userId
      )
    },
  },
  methods: {
    ...mapActions([
      'downgrade',
      'getListChatbots',
      'getCompanyPricing',
      'getAccountsUsers',
      'getUsersToSelectInDowngrade',
    ]),
    async handleOk() {
      this.loading = true
      const res = await this.downgrade({
        type: 'payment-rejected',
        pricingId: '',
        featuresSelected: {
          lines: this.lineSelected ? [this.lineSelected] : [],
          user: this.userSelected,
          chatbots: [this.chatbotSelected],
        },
      })

      this.loading = false
      if (res.success !== true) {
        this.$message.error('Hubo un error al realizar el downgrade')
      } else {
        this.getCompanyPricing()
        this.$message.success('Plan actualizado correctamente')
        this.$emit('hide')
      }
    },

    handleCancelDowngrade() {
      this.$emit('hide')
      this.$emit('updatePayment')
    },
    async handleGetChatbots(name) {
      await this.getListChatbots({
        page: 1,
        status: 'all',
        name: name ? name : '',
        channel: '',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.payment-rejecte-downgrade
  &__info
   margin-top: 20px
   margin-bottom: 4px
  &__title
   text-align: center
  &__subtitle
    margin: 0
  &__form-item
    margin-bottom: 24px
    p
      margin: 0
    &--big
      margin-bottom: 33px
  &__warning
    margin-bottom: 16px
    p
      margin: 0
  &__cancel
    font-size: 14px
    line-height: 22px
    padding: 12px 0 0 0
  &__actions
    padding: 12px 0 0 0
</style>
