<template>
  <authTemplate>
    <template slot="body">
      <a-row type="flex" justify="center" class="full-width">
        <a-col
          :xs="{ span: 24 }"
          :sm="{ span: 24 }"
          :md="{ span: 12 }"
          :lg="{ span: 8 }"
          :xl="{ span: 8 }"
          :xxl="{ span: 7 }"
          class="template__body__content"
        >
          <!--Formulario de obtener mail-->
          <mail-form v-if="showMailForm" class="full-width" />
          <!--Crear nueva contraseña - TODO SE DEBERIA PONER EN UN COMPONENTE-->
          <div class="full-width" v-if="isValid">
            <a-alert
              v-if="alert.visible"
              :message="alert.message"
              :type="alert.type"
              show-icon
              class="template__alert"
            />
            <h4 class="heading-h4">Crea tu nueva contraseña</h4>
            <p class="body-2">
              La contraseña deberá contener 8 caracteres como mínimo, 1 carácter
              en mayúscula, 1 carácter en minúscula, 1 número y 1 carácter
              especial.
            </p>
            <password-form
              style="width: 100%"
              class="form-password"
              name-button="Crear nueva contraseña"
              :onSavePassword="saveRestorePassword"
              @onSubmit="handleSubmitPasswordForm"
              :email="email"
            />
          </div>
        </a-col>
      </a-row>
      <token-status-section v-if="!showMailForm && isLoading" state="load" />
      <token-status-section
        v-else-if="isExpired"
        state="expired"
        :email="email"
        :onRequest="reSendVerificationEmail"
      />
      <token-status-section v-else-if="isVerify" state="verified" />
      <token-status-section
        v-else-if="!showMailForm && !isValid"
        state="fail"
      />
    </template>
  </authTemplate>
</template>

<script>
import AuthTemplate from '@/app/shared/components/templates/AuthTemplate'
import MailForm from '@/app/auth/components/forms/MailForm'
import PasswordForm from '@/app/auth/components/forms/PasswordForm'
import TokenStatusSection from '@/app/shared/components/sections/TokenStatusSection'
import { mapActions } from 'vuex'

export default {
  name: 'RestoreTemplate',
  components: {
    MailForm,
    AuthTemplate,
    PasswordForm,
    TokenStatusSection,
  },
  data: () => ({
    alert: {
      visible: false,
      type: '',
      message: '',
    },
    username: '', // valor de id user
    isLoading: true,
    isExpired: false,
    isValid: false,
    isVerify: false,
    goToNext: false,
    payPlan: {}, // el plan por el que se va a pagar
    email: null, // email
  }),
  created() {
    if (this.$route.query.token) this.setVerificationInfo()
  },
  computed: {
    showMailForm() {
      const token = this.$route.query.token
      return !token ? true : false
    },
  },
  methods: {
    ...mapActions(['getRestoreInfo', 'saveRestorePassword', 'initSession']),

    /**
     * Submit del formulario de password
     * @param {Object} response - respuesta de la peticion
     */
    handleSubmitPasswordForm(response) {
      if (response.success) {
        this.initSession(response.token)
      } else {
        this.alert.visible = true
        this.alert.type = 'error'
        this.alert.message =
          'Ocurrió un error mientras se trataba de enviar el correo'
      }
    },
    /**
     * Setear informacion de la verificacion
     */
    async setVerificationInfo() {
      const token = this.$route.query.token
      const response = await this.getRestoreInfo(token)
      this.isLoading = false
      if (response.success) {
        this.username = response.username
        this.email = response.email
        this.isValid = true // es valido para cambiar la contraseña
        this.goToNext = response.pricing ? false : true
      } else {
        switch (response.result) {
          case 'ready verify':
            this.isVerify = true // ya esta verificado
            break
          case 'expired':
            this.isExpired = true // ya expiro
            this.email = response.email
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form-password
  margin: auto
.template
  &__body__content
    @include xs
      padding-top: 12px
    @include sm
      padding-top: 4px
    @include md
      padding-top: 24px
  &__alert
    margin-bottom: 24px
    @include xs
      margin-bottom: 16px
    @include sm
      margin-bottom: 16px
    @include md
      margin-bottom: 16px
</style>
