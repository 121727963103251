<template>
  <a-modal
    width="40em"
    :visible="visible"
    :bodyStyle="modalSettingCard.styles"
    :maskClosable="false"
    :closable="!loading"
    :footer="null"
    :afterClose="resetForm"
    centered
    @cancel="onCancel"
  >
    <h5 class="heading-h5" style="margin-bottom: 12px">
      Configura tu tarjeta de crédito o débito
    </h5>
    <div class="credit-cards-container">
      <img :src="`/img/img_credit_cards/visa.png`" alt="visa" />
      <img :src="`/img/img_credit_cards/mastercard.png`" alt="mastercard" />
      <img :src="`/img/img_credit_cards/diners.png`" alt="diners club" />
    </div>
    <p class="body-2" style="margin-bottom: 8px">
      Completa los campos para poder realizar el cobro correctamente.
    </p>
    <a-alert v-if="showError" :type="typeError" show-icon class="modal__alert">
      <p slot="message">
        {{ messageError }}. Si necesitas ayuda,
        <router-link
          :to="{ name: 'support' }"
          target="_blank"
          rel="noopener noreferrer"
          >contáctate con nuestro soporte</router-link
        >.
      </p>
    </a-alert>
    <a-form
      :form="form"
      layout="vertical"
      class="form"
      @submit.prevent="handleSubmit"
    >
      <a-form-item label="Nombres">
        <a-input
          placeholder="Escribe aquí..."
          v-decorator="[
            'names',
            {
              rules: [
                { required: true, message: 'Por favor rellene el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Apellidos">
        <a-input
          placeholder="Escribe aquí..."
          v-decorator="[
            'surnames',
            {
              rules: [
                { required: true, message: 'Por favor rellene el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Número de tarjeta" has-feedback>
        <a-input
          placeholder="Escribe aquí..."
          v-decorator="[
            'number',
            {
              rules: [
                { required: true, message: 'Por favor rellene el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                { validator: checkTypeCard },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-row :gutter="[12]">
        <a-col :span="12">
          <a-form-item label="Fecha de vencimiento">
            <a-month-picker
              :disabled-date="disabledDate"
              placeholder="Selecciona la fecha"
              v-decorator="[
                'expirationDate',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                  ],
                },
              ]"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Código de seguridad (CVV)">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'securityCode',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :maxLength="4"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <div class="form__extra-container display-flex">
        <a-icon type="info-circle" class="form__icon-info mrg-right-8" />
        <p class="mrg-bottom-0 regular-12">
          Para validar tu tarjeta, {{ app }} realizará un cobro de $3 que luego
          será reembolsado.
          <span v-if="false"> Más información <a href="">aquí</a></span>
        </p>
      </div>

      <div class="footer">
        <a-button
          @click="onCancel"
          :disabled="loading"
          style="margin-right: 12px"
          >Cancelar</a-button
        >
        <a-button html-type="submit" type="primary" :loading="loading"
          >Pagar ${{ purchase.total }}</a-button
        >
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ModalFormSettingsCredit',
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    messageError: {
      type: String,
      require: false,
    },
    showError: {
      type: Boolean,
      require: false,
      default: false,
    },
    typeError: {
      type: String,
      default: 'error',
      require: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    modalSettingCard: {
      styles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px 32px 0',
        // height: '36em',
      },
    },
    checkPending: null,
    paymentMethod: '', // metodo de pago
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-settings-credit' })
  },
  computed: {
    ...mapGetters(['purchase']),
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day').subtract(0, 'month')
    },
    onCancel() {
      this.$emit('hideModal')
    },
    onOk() {
      this.$emit('onOk')
    },
    checkTypeCard(rule, value, callback) {
      clearTimeout(this.checkPending)
      this.checkPending = setTimeout(() => {
        const regexDiners =
          /(^[35](?:0[0-5]|[68][0-9])[0-9]{11}$)|(^30[0-5]{11}$)|(^3095(\\d{10})$)|(^36{12}$)|(^3[89](\\d{12})$)/gm
        const regexVisa = /^(4)(\d{12}|\d{15})$|^(606374\d{10}$)/gm
        const regexMastercard =
          /^(5[1-5]\d{14}$)|^(2(?:2(?:2[1-9]|[3-9]\d)[3-6]\d\d|7(?:[01]\d|20))\d{12}$)/gm
        // console.log(regexDiners.test(value))
        if (regexDiners.test(value)) {
          this.paymentMethod = 'diners'
          callback()
        } else if (regexVisa.test(value)) {
          this.paymentMethod = 'visa'
          callback()
        } else if (regexMastercard.test(value)) {
          this.paymentMethod = 'mastercard'
          callback()
        } else {
          callback(new Error('No es una tarjeta valida'))
        }
      }, 500)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const settings = {
            isNew: true,
            name: values.names,
            surnames: values.surnames !== undefined ? values.surnames : '',
            number: values.number,
            expirationDate: values.expirationDate,
            paymentMethod: this.paymentMethod,
            securityCode: values.securityCode,
          }
          // this.showConfirm(settings)
          this.$emit('onSubmit', settings)
        }
      })
    },
    showConfirm(settings) {
      let self = this
      this.$confirm({
        title: '¿Estás segur@ de pagar con esta tarjeta?',
        content:
          'Se verificará que esta tarjeta sea valida y se procederá a debitar tu compra.',
        okText: 'Pagar',
        cancelText: 'Cancelar',
        onOk() {
          self.$emit('onSubmit', settings)
        },
      })
    },
    /**Resetea todos los campos del formualrio */
    resetForm() {
      this.form.resetFields()
      this.onCancel()
    },
  },
}
</script>
<style lang="sass" scoped>
.credit-cards-container
  margin-top: 8px
  margin-bottom: 20px
  img
    cursor: auto
    margin-right: 8px
    &:last-child
      margin-right: -8px
.form
  width: 100%
  .footer
    width: calc( 100% + 64px ) // 64px de la suma de los paddings hacia los costados
    margin-left: -32px // para que este centrado
    display: flex
    flex-direction: row
    justify-content: center
    padding: 12px
  &__extra-container
    margin: 12px 0px 24px
    color: $gray_8
  &__icon-info
    font-size: 17px
    color: $gray_8
.modal__alert
  width: 100%
  margin-bottom: 20px
</style>
