import { mapGetters } from 'vuex'
import { MENUS } from '@/app/profile/utils/profileUtil'

export default {
  computed: {
    ...mapGetters(['permissions', 'profile', 'show_user_guide']),

    /**
     * Permisos del modulo de cuentas
     * @returns {Object} permissions
     * @returns {Boolean} permissions.filter - si se pueden filtrar los usuarios
     * @returns {String[]} permissions.users_to_manage - tipo de usuarios que puede gestionar
     */
    permissionsModule() {
      const name = 'profile'
      return this.permissions[name]
    },

    /**
     * Menu para el perfil
     * @returns {Object[]} menuItems
     * @returns {String} menuItems.order
     * @returns {String} menuItems.title
     * @returns {String} menuItems.key
     */
    menuItemsProfile() {
      if (!this.profile) return []
      const menus = this.permissionsModule?.menus
      if (!menus) return []
      if (!this.show_user_guide) delete MENUS['menu-guide']
      const menusArray = Object.values(MENUS)
      const menuFilter = menusArray.filter((menu) => menus.includes(menu.key))

      return menuFilter.sort((a, b) => a.order - b.order)
    },
  },
}
