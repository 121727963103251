<template>
  <a-modal
    :visible="visible"
    title="Actualizar alias de WhatsApp"
    @cancel="$emit('onCancel')"
    centered
  >
    <a-form-item label="Alias de WhatsApp">
      <a-input v-model="alias" placeholder="Escribe el alias" />
    </a-form-item>
    <basic-footer
      okText="Actualizar alias"
      cancelText="Cancelar"
      :okLoading="loading"
      @onOk="handleUpdateAliasWba"
      @onCancel="$emit('onCancel')"
    />
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'

export default {
  name: 'ModalUpdateAlias',
  props: {
    visible: {
      type: Boolean,
      require: false,
    },
    defaultAlias: {
      type: String,
      require: true,
    },
    channelCompanyId: {
      type: String,
      require: true,
    },
  },
  components: {
    BasicFooter,
  },
  data: () => ({
    alias: '',
    loading: false,
  }),
  mounted() {
    this.alias = this.defaultAlias
  },
  methods: {
    ...mapActions(['updateAliasWba']),

    /**
     * Actualiza el alias de whatsapp
     */
    async handleUpdateAliasWba() {
      this.loading = true
      const response = await this.updateAliasWba({
        alias: this.alias,
        channelCompanyId: this.channelCompanyId,
      })
      if (response.success) {
        this.$emit('onCancel')
        this.$emit('onUpdate', response.data.alias)
        this.alias = ''
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped></style>
