<template>
  <span> </span>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Notifications from '@/app/shared/utils/notification'
import mixinAttempt from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import registerWorker from '@/registerServiceWorker'

export default {
  name: 'PushNotifications',
  mixins: [mixinAttempt, compareMixin],
  data: () => ({
    app: process.env.VUE_APP_NAME,
    serviceWorker: null,
  }),
  mounted() {
    this.loadInitialData()
  },
  watch: {
    $route() {
      this.checkUpdates()
    },
  },
  computed: mapGetters(['profile', 'profileRemote']),
  methods: {
    ...mapActions([
      'subscribeToPushNotification',
      'unsubscribeToPushNotification',
    ]),

    /**
     * Lanza una notificación
     * @param {String} title Titulo de la notificación
     * @param {String} description Mensaje de la notificación
     * @param {Object} ok Función para cuando se acepta la notificacion
     * @param {Callback} ok.fun Función de la accion primaria
     * @param {String} ok.name Nombre de la accion primaria
     * @param {Callback} cancel Funcion para cerrar
     * @param {Object} icon - icono que mostrará junto a la notificacion
     * @param {String} icon.type - tipo de icono, esto es de ant design
     * @param {String} icon.color - color del icono
     * @param {String} key - id de la notificacion
     * */
    launchNotification(
      title,
      description,
      ok,
      cancel,
      icon = { type: 'bell', color: '#1890FF' },
      placement = 'topRight',
      key = `open${Date.now()}`
    ) {
      const keyNotification = key
      this.$notification.open({
        message: title,
        description: description,
        icon: (h) => {
          return h('a-icon', {
            props: {
              type: icon.type,
            },
            style: { color: icon.color },
          })
        },
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => {
                  ok.fun()
                  this.$notification.close(keyNotification)
                },
              },
            },
            ok.name
          )
        },
        key: keyNotification,
        onClose: cancel,
        placement,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },
    /**
     * Evento que se ejecuta cuando se cierra la notificación
     * sin aceptar o denegar el permiso
     */
    handleClosePermission() {
      // se setea en el local storage el permiso denegado
      Notifications.deniedPermission()
      // se desuscribe al usuario de las notificaciones
      this.unsubscribeToPushNotification()
    },
    /**
     * Evento que se ejecuta cuando se deniega el permiso de notificaciones
     * o cuando el navegador no ha podido activar las notificaciones
     */
    handleDeniedPermission() {
      this.launchNotification(
        'No se pudo activar las notificaciones',
        'El navegador que está usando no permitió que activemos las notificaciones',
        { name: 'Entendido', fun: () => {} },
        () => {}
      )
      // se desuscribe al usuario de las notificaciones
      this.unsubscribeToPushNotification()
    },
    /**
     * Evento que se ejecuta cuando el usuario acepta recibir notificaciones
     */
    handleSuccessPermission() {
      registerWorker({
        onSubscribe: this.subscribeToPushNotification,
      })
    },
    /**
     * Carga la data inicial
     */
    loadInitialData() {
      // agrega el listener de actualización
      this.listenUpdateSw()
      // registra el worker
      registerWorker({
        onUpdated: this.handleShowUpdateNotification,
      })
      this.$nextTick(() => {
        // Verifica y actualiza las notificaciones
        this.intervalAttempt(() => {
          if (!this.profile) throw 'profile'
          Notifications.updatePermission()
          // si no tiene el rol de agente, no podrá ver las notificaciones
          if (!this.isAllowedFor(this.profile.type, ['agent'])) return
          const permission = Notifications.getPermission()
          // si no existe el permiso, se lanzará una notificación para preguntar
          if (permission == null)
            this.launchNotification(
              Notifications.requestNotificationMessages.title,
              Notifications.requestNotificationMessages.description,
              { name: 'Habilitar', fun: this.handleEnabledPermission },
              this.handleClosePermission
            )
          // si ya tiene el permiso, volverá a registrar
          else if (permission === 'granted') {
            this.handleSuccessPermission()
          }
        })
      })
    },
    /**
     * Habilita las notificaciones
     */
    handleEnabledPermission() {
      Notifications.requestPermission(
        this.handleDeniedPermission,
        this.handleSuccessPermission
      )
    },
    /**
     * Sí el service worker está esperando por actualizar, entonces se recargará
     */
    refreshApp() {
      if (!this.serviceWorker || !this.serviceWorker.waiting) return
      // se envia un mensaje para cambiar skip waiting hacia el service worker,
      this.serviceWorker.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    },
    /**
     * Muestra la notificación de actualización
     * @param {Event} event
     * @param {Object} event.detail
     */
    handleShowUpdateNotification(event) {
      this.serviceWorker = event.detail
      // mostrar notificacion
      this.launchNotification(
        'Hay una nueva versión disponible',
        `Se encuentra disponible una nueva versión de ${this.app}, actualiza la aplicación para obtener los últimos cambios.`,
        { fun: this.refreshApp, name: 'Actualizar' },
        () => {},
        { type: 'fire', color: '#fa8c16' },
        'bottomRight',
        'update-notification'
      )
    },
    /**
     * Escucha la actualización del service worker y muestra la notificacion
     */
    listenUpdateSw() {
      document.addEventListener(
        'onUpdatedSw',
        this.handleShowUpdateNotification
      )
    },
    /**
     * Verfica si hay actualizaciones en el service worker
     */
    checkUpdates() {
      console.log('check updates')
      navigator.serviceWorker
        .getRegistration('/')
        .then(function (registration) {
          registration.update()
        })
    },
  },
}
</script>

<style scoped></style>
