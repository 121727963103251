<template>
  <a-modal
    :visible="visible"
    width="464px"
    :bodyStyle="modalSuccessPayment.styles"
    centered
    @cancel="onCancel"
    :footer="null"
  >
    <svg
      width="302"
      height="132"
      viewBox="0 0 302 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.3231 96.3293C86.6127 96.3293 86.8494 96.5557 86.866 96.8412L86.8669 96.8732V115.381C86.8669 115.681 86.6234 115.924 86.3231 115.924C86.0335 115.924 85.7968 115.698 85.7802 115.413L85.7793 115.381V96.8732C85.7793 96.5728 86.0228 96.3293 86.3231 96.3293Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.4573 72.71C66.7576 72.71 67.0011 72.9534 67.0011 73.2538C67.0011 73.5434 66.7747 73.7801 66.4892 73.7967L66.4573 73.7976H38.1249C36.7164 73.7976 35.5631 74.9961 35.5334 76.4936L35.5328 76.5529V78.6556C35.5328 79.0047 35.2085 79.2634 34.8681 79.1858C33.8564 78.955 32.8277 78.8368 31.7987 78.8368C23.7997 78.8368 17.279 85.715 17.279 94.1903C17.279 102.654 23.8057 109.544 31.7987 109.544C32.839 109.544 33.8493 109.427 34.8681 109.195C35.1978 109.12 35.5125 109.36 35.5319 109.693L35.5328 109.725V115.616C35.5328 115.917 35.2893 116.16 34.989 116.16C34.6994 116.16 34.4626 115.934 34.4461 115.648L34.4452 115.616V110.391L34.3359 110.411C33.5619 110.546 32.7865 110.62 31.9965 110.63L31.7987 110.631C23.1915 110.631 16.1914 103.242 16.1914 94.1903C16.1914 85.1269 23.1857 77.7492 31.7987 77.7492C32.6485 77.7492 33.4974 77.8238 34.3373 77.9701L34.4452 77.9896V76.5529C34.4452 74.4616 36.0502 72.7453 38.0626 72.7105L38.1249 72.71H66.4573Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7987 83.6042C26.2528 83.6042 21.7383 88.3679 21.7383 94.1904C21.7383 100.019 26.2407 104.776 31.7987 104.776C32.9785 104.776 34.1146 104.569 35.183 104.161C35.3937 104.08 35.5329 103.878 35.5329 103.653V84.7281C35.5329 84.5026 35.3937 84.3005 35.183 84.2201C34.1146 83.8121 32.9785 83.6042 31.7987 83.6042ZM31.9351 84.6929C32.7975 84.7061 33.6299 84.8445 34.4226 85.1041L34.4452 85.1117V103.269L34.4226 103.277C33.5882 103.55 32.7098 103.689 31.7987 103.689C26.8552 103.689 22.8259 99.4316 22.8259 94.1904C22.8259 88.9559 26.8669 84.6919 31.7987 84.6919L31.9351 84.6929Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.0582 60.4018L66.5506 60.3474C66.2607 60.3466 66.0232 60.5726 66.0062 60.8583L65.9981 64.9762L65.9689 84.6305L65.9688 85.2216C65.9674 88.5291 66.1349 90.5177 66.8389 92.5812C67.8752 95.6187 69.9103 97.4125 73.1464 97.5253L73.1653 97.5256H85.0566C85.3569 97.5256 85.6004 97.2822 85.6004 96.9818V60.9456C85.6004 60.6459 85.3579 60.4027 85.0582 60.4018ZM67.0921 61.4365L84.5131 61.4876V96.438L73.1846 96.4384C70.4403 96.3427 68.7693 94.8698 67.8686 92.23C67.2262 90.3472 67.0606 88.4723 67.0568 85.3729L67.075 71.5937L67.0921 61.4365Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.0095 66.6013L82.4657 66.6194C82.7661 66.6199 83.0091 66.8638 83.0087 67.1641C83.0082 67.4537 82.7814 67.6901 82.4959 67.7062L82.464 67.7071L71.0078 67.6889C70.7075 67.6885 70.4644 67.4446 70.4648 67.1443C70.4653 66.8547 70.6921 66.6183 70.9776 66.6022L71.0095 66.6013Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.9919 71.7676L82.4481 71.7857C82.7485 71.7862 82.9916 72.03 82.9911 72.3304C82.9906 72.62 82.7639 72.8564 82.4784 72.8725L82.4464 72.8733L70.9902 72.8552C70.6899 72.8547 70.4468 72.6109 70.4473 72.3105C70.4477 72.0209 70.6745 71.7845 70.96 71.7685L70.9919 71.7676Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.9724 76.9517L82.4467 76.9698C82.7471 76.9703 82.9902 77.2141 82.9897 77.5144C82.9892 77.804 82.7625 78.0404 82.477 78.0565L82.445 78.0574L70.9707 78.0393C70.6703 78.0388 70.4273 77.7949 70.4277 77.4946C70.4282 77.205 70.655 76.9686 70.9404 76.9525L70.9724 76.9517Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.9724 82.1179L82.4286 82.136C82.7289 82.1365 82.972 82.3804 82.9715 82.6807C82.9711 82.9703 82.7443 83.2067 82.4588 83.2228L82.4269 83.2237L70.9707 83.2055C70.6703 83.2051 70.4273 82.9612 70.4277 82.6609C70.4282 82.3713 70.655 82.1349 70.9404 82.1188L70.9724 82.1179Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.9548 87.3022L82.4291 87.3204C82.7295 87.3208 82.9725 87.5647 82.9721 87.865C82.9716 88.1547 82.7449 88.391 82.4594 88.4071L82.4274 88.408L70.9531 88.3899C70.6528 88.3894 70.4097 88.1455 70.4102 87.8452C70.4106 87.5556 70.6374 87.3192 70.9229 87.3031L70.9548 87.3022Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.235 97.0906L73.166 96.9818C78.3141 97 82.4832 92.8308 82.5014 87.6828L83.0633 50.3958C83.0814 45.2477 87.2506 41.0967 92.3986 41.0967L91.456 41.1329C96.6041 41.1511 100.755 45.3202 100.755 50.4683V52.7704L100.447 65.7492C100.447 65.7492 99.9757 76.7522 110.598 73.8519C110.489 74.142 104.235 97.0906 104.235 97.0906Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.081 1.21748C129.921 2.54075 124.61 7.34437 124.592 13.0725L124.211 29.5317L126.314 28.3172L152.199 28.2084L152.163 13.1631C152.163 6.4199 143.389 -0.160159 137.081 1.21748Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.3825 40.5711C94.4088 40.5811 96.5282 41.4131 98.1392 42.884C99.9443 44.5321 100.999 46.8499 101.044 49.6077L101.056 50.4086C101.087 52.6561 101.088 54.5305 101.067 57.1203L101.031 61.1708L101.022 63.6778C101.021 65.3372 101.063 66.3279 101.215 67.4753C101.741 71.417 103.403 73.623 106.864 73.7436C106.887 73.7444 106.91 73.7467 106.933 73.7505L106.984 73.7578C107.027 73.7635 107.088 73.7705 107.167 73.7777C108.125 73.8659 109.259 73.7855 110.389 73.4087C110.674 73.3137 110.982 73.4677 111.077 73.7526C111.172 74.0375 111.018 74.3455 110.733 74.4405C109.483 74.857 108.246 74.9555 107.173 74.8699L107.067 74.8607C106.987 74.8534 106.919 74.8458 106.864 74.8389L106.793 74.8293L106.719 74.8262C104.613 74.7281 103.023 73.9185 101.92 72.4959L101.864 72.423C100.94 71.1965 100.4 69.5866 100.137 67.619L100.115 67.4478C99.9654 66.2567 99.9295 65.2048 99.9353 63.4366L99.9385 62.7021L99.9406 61.6212C99.9428 61.1387 99.9471 60.599 99.9548 59.7754L99.9809 56.9342C100.002 54.1464 99.9978 52.1832 99.9569 49.6252C99.9168 47.1685 98.9909 45.1344 97.4058 43.6872C95.9934 42.3976 94.1333 41.6674 92.3771 41.6587C92.0767 41.6572 91.8345 41.4125 91.836 41.1122C91.8375 40.8118 92.0821 40.5696 92.3825 40.5711Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M103.187 44.7764L112.468 44.8126C112.768 44.8138 113.01 45.0582 113.009 45.3585C113.008 45.6482 112.781 45.884 112.495 45.8994L112.463 45.9002L103.182 45.864C102.882 45.8628 102.639 45.6184 102.641 45.318C102.642 45.0284 102.869 44.7926 103.155 44.7772L103.187 44.7764Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.092 48.9819L113.753 49.0001C114.054 49.0006 114.297 49.2445 114.296 49.5449C114.296 49.8345 114.069 50.0708 113.783 50.0868L113.751 50.0877L104.09 50.0695C103.789 50.069 103.546 49.825 103.547 49.5247C103.547 49.2351 103.774 48.9988 104.06 48.9828L104.092 48.9819Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.055 53.1692L114.587 53.2054C114.888 53.2065 115.13 53.4508 115.129 53.7511C115.128 54.0407 114.901 54.2767 114.615 54.2922L114.583 54.293L104.052 54.2568C103.751 54.2557 103.509 54.0114 103.51 53.7111C103.511 53.4215 103.738 53.1856 104.023 53.17L104.055 53.1692Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.055 56.9033L114.37 56.9396C114.67 56.9406 114.913 57.185 114.912 57.4853C114.911 57.7749 114.683 58.0108 114.398 58.0264L114.366 58.0272L104.052 57.9909C103.751 57.9899 103.509 57.7455 103.51 57.4452C103.511 57.1556 103.738 56.9197 104.024 56.9041L104.055 56.9033Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.039 60.6555L114.371 60.6736C114.672 60.6742 114.915 60.9181 114.914 61.2184C114.914 61.508 114.687 61.7444 114.401 61.7604L114.369 61.7613L104.037 61.7431C103.737 61.7426 103.494 61.4987 103.494 61.1984C103.495 60.9088 103.721 60.6724 104.007 60.6564L104.039 60.6555Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.039 64.3896L113.411 64.4078C113.711 64.4084 113.954 64.6523 113.953 64.9526C113.953 65.2423 113.726 65.4785 113.44 65.4945L113.408 65.4954L104.037 65.4773C103.737 65.4767 103.494 65.2327 103.494 64.9324C103.495 64.6428 103.722 64.4065 104.007 64.3905L104.039 64.3896Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.039 68.1238L112.287 68.1419C112.587 68.1426 112.83 68.3866 112.829 68.6869C112.829 68.9765 112.602 69.2128 112.316 69.2287L112.284 69.2295L104.037 69.2114C103.736 69.2107 103.493 68.9667 103.494 68.6664C103.495 68.3768 103.722 68.1405 104.007 68.1246L104.039 68.1238Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.826 0.619386C146.209 0.637849 152.491 5.882 152.597 12.9133L152.599 13.1269V46.6254C152.599 46.9258 152.355 47.1692 152.055 47.1692C151.765 47.1692 151.528 46.9428 151.512 46.6574L151.511 46.6254L151.511 13.1255C151.528 6.63191 145.727 1.72441 137.824 1.707C137.524 1.70634 137.281 1.46233 137.281 1.16199C137.282 0.861658 137.526 0.618725 137.826 0.619386Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M109.473 40.408C111.507 40.408 113.633 41.2385 115.246 42.7144C117.051 44.3645 118.102 46.6871 118.138 49.4462C118.142 49.7465 117.901 49.9931 117.601 49.997C117.301 50.001 117.054 49.7607 117.05 49.4604C117.018 47.003 116.096 44.9653 114.512 43.517C113.124 42.2474 111.302 41.5222 109.569 41.4963L109.473 41.4956H92.3251C92.0247 41.4956 91.7812 41.2521 91.7812 40.9518C91.7812 40.6622 92.0076 40.4254 92.2931 40.4089L92.3251 40.408H109.473Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M250.826 36.5831L227.261 36.5469L224.76 51.9185C228.856 54.8913 232.518 58.4079 234.186 62.2327C235.654 65.568 238.5 75.0121 241.781 86.5952L253.69 39.9729C254.125 38.2508 252.729 36.5831 250.826 36.5831Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M185.443 101.405C182.941 98.2326 184.047 96.0937 186.041 94.0272C180.494 91.9789 176.107 89.3142 175.02 87.3565C173.751 85.0544 175.799 81.9728 177.104 80.3958C174.24 79.1269 171.648 76.9879 173.479 71.7311C173.86 70.6254 174.603 69.8097 175.6 69.2478L176.524 63.6103C175.817 63.8641 175.11 64.136 174.385 64.426C165.231 68.0876 161.406 59.3867 167.805 55.8157C169.854 54.6737 174.548 52.5348 178.627 50.7765L179.189 47.3505L152.144 47.3142V36.5106L132.802 36.4019C131.443 36.4019 130.246 37.2719 129.938 38.5046L111.394 102.221C110.959 103.943 112.355 105.592 114.258 105.61L190.21 105.755C188.343 104.921 187.092 103.508 185.443 101.405Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M228.623 27.864L267.2 27.9365C270.003 27.9564 272.316 31.7483 271.679 34.6809L271.659 34.7695L251.393 119.857C251.323 120.149 251.03 120.33 250.738 120.26C250.456 120.193 250.278 119.918 250.328 119.636L250.335 119.605L270.601 34.517C271.15 32.2208 269.29 29.1018 267.257 29.0255L267.195 29.0241L228.621 28.9516C228.321 28.9511 228.078 28.7071 228.078 28.4068C228.079 28.1172 228.306 27.8809 228.591 27.8649L228.623 27.864Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M126.712 27.6829L151.999 27.7372C152.3 27.7379 152.543 27.9819 152.542 28.2822C152.542 28.5718 152.315 28.8081 152.029 28.824L151.997 28.8249L126.711 28.7705C125.419 28.7705 123.95 30.6341 123.45 32.5996L123.433 32.669L123.427 32.6932L99.3179 115.334C99.2338 115.622 98.9319 115.787 98.6436 115.703C98.3656 115.622 98.2017 115.339 98.2658 115.06L98.2739 115.029L122.379 32.4002L122.394 32.3394C122.993 29.9695 124.747 27.729 126.653 27.6836L126.712 27.6829Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M212.923 129.375C212.923 129.375 51.6122 130.118 43.2739 130.1C26.8146 130.063 29.6606 114.764 29.6606 114.764L98.9778 114.873L211.872 114.855L212.923 129.375Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M261.576 27.9729C264.552 27.9729 266.591 30.0768 265.985 32.8211L265.966 32.9044L248.074 106.844C248.004 107.136 247.71 107.315 247.418 107.245C247.137 107.177 246.96 106.901 247.011 106.619L247.017 106.588L264.908 32.6501C265.398 30.6033 263.925 29.0605 261.576 29.0605C261.276 29.0605 261.032 28.817 261.032 28.5167C261.032 28.2164 261.276 27.9729 261.576 27.9729Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M156.168 9.08472L179.189 9.12097C179.489 9.12144 179.732 9.3653 179.732 9.66563C179.731 9.95524 179.505 10.1916 179.219 10.2077L179.187 10.2086L156.166 10.1723C155.866 10.1719 155.623 9.928 155.623 9.62767C155.624 9.33806 155.85 9.10168 156.136 9.08559L156.168 9.08472Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.383 14.6313L180.404 14.6676C180.704 14.6681 180.947 14.9119 180.947 15.2123C180.946 15.5019 180.719 15.7382 180.434 15.7543L180.402 15.7552L157.381 15.719C157.081 15.7185 156.837 15.4746 156.838 15.1743C156.838 14.8847 157.065 14.6483 157.351 14.6322L157.383 14.6313Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.328 20.1602L180.331 20.2145C180.632 20.2152 180.875 20.4593 180.874 20.7596C180.873 21.0492 180.646 21.2854 180.361 21.3013L180.329 21.3021L157.326 21.2478C157.025 21.2471 156.782 21.003 156.783 20.7027C156.784 20.4131 157.011 20.1769 157.296 20.161L157.328 20.1602Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.309 25.0908L180.33 25.1452C180.63 25.1459 180.873 25.39 180.872 25.6903C180.872 25.9799 180.645 26.2161 180.359 26.232L180.327 26.2328L157.306 26.1784C157.006 26.1777 156.763 25.9337 156.764 25.6333C156.764 25.3437 156.991 25.1075 157.277 25.0917L157.309 25.0908Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.309 30.0212L180.312 30.0756C180.612 30.0763 180.855 30.3204 180.854 30.6207C180.854 30.9103 180.627 31.1465 180.341 31.1624L180.309 31.1632L157.306 31.1088C157.006 31.1081 156.763 30.8641 156.764 30.5638C156.764 30.2741 156.991 30.038 157.277 30.0221L157.309 30.0212Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.293 34.9519L180.314 35.0063C180.615 35.007 180.858 35.251 180.857 35.5514C180.856 35.841 180.629 36.0772 180.344 36.093L180.312 36.0939L157.291 36.0395C156.99 36.0388 156.747 35.7948 156.748 35.4944C156.749 35.2048 156.976 34.9686 157.261 34.9528L157.293 34.9519Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M157.293 39.9004L180.296 39.9366C180.596 39.9371 180.839 40.181 180.839 40.4813C180.838 40.7709 180.611 41.0073 180.326 41.0234L180.294 41.0243L157.291 40.988C156.991 40.9875 156.748 40.7437 156.748 40.4433C156.749 40.1537 156.975 39.9174 157.261 39.9013L157.293 39.9004Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M170.434 0.619386C178.901 0.638036 185.226 5.98848 185.207 13.1284C185.206 13.4287 184.962 13.6715 184.661 13.6707C184.361 13.6699 184.118 13.4258 184.119 13.1255C184.136 6.69685 178.451 1.82271 170.67 1.70898L170.433 1.707H138.348C138.048 1.707 137.805 1.46353 137.805 1.16319C137.805 0.873582 138.031 0.636848 138.317 0.620308L138.348 0.619385L170.434 0.619386Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M178.028 50.423C178.303 50.3028 178.624 50.4286 178.744 50.7039C178.864 50.9792 178.738 51.2997 178.463 51.4198L177.636 51.7819C173.355 53.6621 169.7 55.366 168.033 56.2905C165.291 57.8208 164.453 60.4837 165.699 62.5525C167.03 64.7633 170.212 65.4978 174.142 63.9226L174.378 63.8245C174.431 63.8023 174.485 63.7794 174.54 63.7557L175.229 63.4548L175.394 63.3841C175.525 63.3281 175.645 63.2786 175.767 63.2302C175.915 63.1716 176.062 63.1161 176.214 63.0618C176.497 62.9608 176.808 63.1082 176.909 63.391C177.01 63.6739 176.862 63.9851 176.58 64.0861C176.44 64.136 176.304 64.1871 176.168 64.2411C176.055 64.2861 175.942 64.3325 175.818 64.3855L175.147 64.678C174.93 64.7725 174.748 64.8501 174.551 64.9307C170.157 66.6914 166.399 65.8239 164.767 63.1135C163.192 60.4982 164.243 57.1603 167.504 55.3401L167.674 55.2471C169.563 54.2207 173.503 52.3976 178.028 50.423Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M224.265 51.5457C224.441 51.3022 224.781 51.2475 225.024 51.4234C229.733 54.8255 233.094 58.3714 234.685 61.9968C237.355 68.1044 244.761 94.459 253.945 129.236C254.021 129.526 253.848 129.824 253.558 129.9C253.267 129.977 252.97 129.804 252.893 129.514L252.074 126.417C243.299 93.2869 236.244 68.2787 233.688 62.4331C232.191 59.0194 228.954 55.6038 224.387 52.305C224.144 52.1291 224.089 51.7891 224.265 51.5457Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M198.391 106.145C198.575 105.909 198.917 105.867 199.154 106.051C202.711 108.828 207.15 111.414 212.56 113.776C212.736 113.853 212.857 114.017 212.881 114.206L212.885 114.242L213.809 129.45C213.827 129.75 213.599 130.008 213.299 130.026C213.01 130.044 212.76 129.832 212.726 129.548L212.724 129.516L211.819 114.639L211.612 114.548C206.438 112.249 202.143 109.738 198.655 107.042L198.485 106.909C198.248 106.724 198.206 106.382 198.391 106.145Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M185.655 93.7694C185.867 93.557 186.211 93.557 186.424 93.7694C186.636 93.9818 186.636 94.3261 186.424 94.5384C185.258 95.7037 184.676 96.5628 184.515 97.4545C184.332 98.4638 184.71 99.5849 185.806 100.989L186.024 101.265C188.008 103.758 189.109 104.749 190.784 105.382C192.741 106.123 195.484 106.234 200.269 105.758L200.539 105.73C204.907 105.255 207.783 103.975 208.788 102.228C209.75 100.557 208.95 98.4146 206.319 96.1668C205.858 95.7825 205.432 95.4663 205 95.1942C204.746 95.0341 204.67 94.6984 204.83 94.4442C204.99 94.1901 205.326 94.1138 205.58 94.2738C206.056 94.5739 206.52 94.9181 207.021 95.3356C210.009 97.889 211.005 100.556 209.731 102.77C208.489 104.929 205.211 106.358 200.377 106.84L199.925 106.884C195.26 107.324 192.487 107.189 190.399 106.4C188.445 105.661 187.211 104.524 185.015 101.743C183.694 100.078 183.196 98.6363 183.444 97.2612C183.655 96.0972 184.346 95.0784 185.655 93.7694Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M176.831 80.1376C177.024 79.9071 177.367 79.8763 177.597 80.0688C177.828 80.2614 177.859 80.6043 177.666 80.8348C176.748 81.9337 176.095 82.8668 175.647 83.844C175.065 85.1152 174.981 86.2094 175.477 87.1112C176.292 88.5841 179.359 90.6409 183.507 92.45C188.009 94.4132 193.193 95.8536 197.917 96.3503C202.28 96.8161 204.845 95.4188 205.346 93.0338C205.798 90.8819 204.402 88.4563 202.054 87.5569L201.828 87.4721C200.98 87.1566 199.937 86.7922 198.813 86.4228C198.528 86.329 198.373 86.0216 198.466 85.7363C198.56 85.451 198.868 85.2957 199.153 85.3895L199.461 85.4914C200.582 85.864 201.614 86.228 202.44 86.5403C205.28 87.628 206.974 90.5718 206.41 93.2574C205.78 96.257 202.67 97.9515 197.802 97.4319C188.156 96.4174 176.592 91.3744 174.525 87.6364C173.836 86.384 173.947 84.9459 174.658 83.3913C175.155 82.3057 175.856 81.3048 176.831 80.1376Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M175.372 68.7537C175.635 68.6082 175.966 68.7033 176.111 68.9661C176.257 69.2288 176.162 69.5598 175.899 69.7052C174.968 70.2203 174.334 70.9458 173.992 71.9102C173.015 74.7086 173.292 76.6523 174.573 78.0686C175.499 79.0923 176.673 79.7129 178.951 80.5539L182.166 81.7201L183.275 82.1199C183.992 82.3772 184.665 82.6175 185.32 82.8494L186.165 83.1472L186.61 83.3029C189.622 84.3546 191.966 85.1187 193.532 85.5404L193.612 85.562C196.063 86.2144 197.939 85.8415 199.142 84.6517C200.231 83.5741 200.725 81.8427 200.509 79.9311C200.475 79.6326 200.69 79.3633 200.988 79.3296C201.287 79.2958 201.556 79.5104 201.59 79.8088C201.841 82.0308 201.253 84.0938 199.907 85.425C198.427 86.8885 196.189 87.3476 193.435 86.6397L193.332 86.6129C191.76 86.1942 189.422 85.4349 186.411 84.3852L185.35 84.0133L184.958 83.8747C184.169 83.5957 183.355 83.3047 182.472 82.9867L178.931 81.7046C176.255 80.7366 174.899 80.0505 173.766 78.7982C172.2 77.0672 171.864 74.7054 172.966 71.5491C173.4 70.3261 174.214 69.3946 175.372 68.7537Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M185.178 6.87486C185.656 3.9871 188.344 2.03114 191.224 2.44383L191.312 2.45707L227.006 8.23984C229.893 8.71782 231.849 11.4059 231.437 14.287L231.423 14.3744L224.027 59.7641C223.979 60.0605 223.7 60.2617 223.403 60.2134C223.117 60.1668 222.92 59.9053 222.95 59.6209L222.954 59.5892L230.35 14.2012C230.717 11.9022 229.185 9.74149 226.899 9.32524L226.83 9.31316L191.139 3.53087C188.84 3.16368 186.679 4.6953 186.263 6.9815L186.251 7.05101L175.865 70.8742C175.497 73.1731 177.029 75.3339 179.315 75.7502L179.385 75.7623L212.684 81.1822C212.98 81.2305 213.181 81.5099 213.133 81.8063C213.087 82.0922 212.825 82.2894 212.541 82.2599L212.509 82.2557L179.209 76.8355C176.321 76.3575 174.365 73.6695 174.778 70.7884L174.791 70.7011L185.178 6.87486Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M201.168 12.2473L199.625 21.5578C199.517 22.1337 199.913 22.6881 200.497 22.7975L204.097 23.3976C204.673 23.5058 205.227 23.1098 205.337 22.5263L206.88 13.2159C206.988 12.6399 206.592 12.0856 206.008 11.9762L202.419 11.378C201.832 11.2679 201.277 11.6639 201.168 12.2473ZM202.236 12.4502L205.805 13.0449L204.267 22.3235L200.699 21.7286L202.236 12.4502Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M198.957 25.5159L197.414 34.8263L197.411 34.8478C197.339 35.422 197.712 35.9584 198.286 36.066L201.886 36.6661C202.462 36.7743 203.016 36.3784 203.126 35.7949L204.669 26.4845C204.777 25.9085 204.381 25.3542 203.797 25.2448L200.208 24.6466C199.621 24.5365 199.066 24.9324 198.957 25.5159ZM200.026 25.7187L203.595 26.3135L202.057 35.5921L198.489 34.9972L200.026 25.7187Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M193.175 10.9058L191.633 20.2162L191.63 20.2376C191.558 20.8063 191.942 21.3436 192.498 21.4547L196.104 22.056C196.68 22.1642 197.235 21.7683 197.344 21.1848L198.887 11.8744C198.995 11.2984 198.599 10.7441 198.016 10.6347L194.427 10.0365C193.839 9.92635 193.285 10.3223 193.175 10.9058ZM194.244 11.1086L197.813 11.7034L196.275 20.982L192.707 20.3871L192.707 20.3847C192.711 20.3829 192.71 20.3789 192.709 20.3742L194.244 11.1086Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M190.963 24.2471L189.42 33.5575L189.417 33.579C189.346 34.1476 189.729 34.6849 190.285 34.796L193.891 35.3973C194.468 35.5055 195.022 35.1096 195.131 34.5261L196.674 25.2157C196.782 24.6397 196.386 24.0854 195.803 23.976L192.214 23.3778C191.626 23.2677 191.072 23.6636 190.963 24.2471ZM192.031 24.4499L195.6 25.0447L194.063 34.3233L190.494 33.7284L190.494 33.726C190.498 33.7243 190.497 33.7203 190.496 33.7155L192.031 24.4499Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M196.745 38.93L195.202 48.2404C195.091 48.7976 195.492 49.371 196.074 49.4801L199.674 50.0802C200.25 50.1884 200.804 49.7924 200.914 49.2089L202.457 39.8985C202.565 39.3226 202.169 38.7682 201.585 38.6588L197.996 38.0606C197.409 37.9505 196.854 38.3465 196.745 38.93ZM197.813 39.1328L201.382 39.7275L199.845 49.0062L196.276 48.4112L197.813 39.1328Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M194.553 52.1265L193.01 61.4369C192.902 62.0129 193.298 62.5672 193.881 62.6766L197.481 63.2767C198.058 63.3849 198.612 62.989 198.721 62.4055L200.264 53.0951C200.372 52.5191 199.976 51.9648 199.393 51.8554L195.804 51.2572C195.216 51.1471 194.662 51.543 194.553 52.1265ZM195.621 52.3293L199.19 52.9241L197.652 62.2027L194.083 61.6078L195.621 52.3293Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M207.07 64.5744C207.309 64.4118 207.632 64.4655 207.806 64.6915L207.826 64.7183C208.032 65.0222 208.485 65.2674 209.04 65.3574C209.336 65.4055 209.538 65.6848 209.49 65.9812C209.442 66.2777 209.162 66.479 208.866 66.431C208.038 66.2967 207.319 65.9072 206.926 65.3299C206.757 65.0815 206.822 64.7433 207.07 64.5744Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M205.971 66.3286C206.221 66.1631 206.559 66.2321 206.724 66.4827C207.224 67.2397 208.637 67.7442 209.9 67.5843C210.198 67.5466 210.47 67.7576 210.508 68.0555C210.546 68.3535 210.335 68.6256 210.037 68.6633C208.393 68.8714 206.568 68.2195 205.817 67.0821C205.651 66.8315 205.72 66.4941 205.971 66.3286Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M204.28 67.5814C204.53 67.4142 204.867 67.4809 205.035 67.7303C205.999 69.1694 208.718 70.0162 210.938 69.532C211.232 69.468 211.522 69.654 211.586 69.9475C211.65 70.2409 211.464 70.5307 211.17 70.5947C208.542 71.1676 205.366 70.1781 204.131 68.3359C203.964 68.0864 204.031 67.7486 204.28 67.5814Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M201.87 68.578C202.12 68.411 202.458 68.4779 202.625 68.7275C204.161 71.0231 208.322 72.3821 211.883 71.7395C212.178 71.6861 212.461 71.8825 212.515 72.178C212.568 72.4736 212.372 72.7564 212.076 72.8098C208.12 73.5237 203.52 72.0216 201.721 69.3323C201.554 69.0827 201.621 68.745 201.87 68.578Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M210.857 23.9124L212.398 14.6677C212.488 14.1239 212.996 13.7614 213.54 13.852L221.606 15.1934C222.15 15.284 222.512 15.7916 222.422 16.3354L220.881 25.5801C220.79 26.1239 220.283 26.4864 219.739 26.3958L211.673 25.0544C211.147 24.9638 210.784 24.4562 210.857 23.9124Z"
        fill="#FFB039"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M222.042 19.2719L222.223 18.2024L212.09 16.5166L211.909 17.5861L214.175 17.9668L213.594 21.4652L211.365 21.1027L211.184 22.1722L221.171 23.8217L221.353 22.7522L219.087 22.3716L219.667 18.8731L222.042 19.2719ZM218.579 18.728L218.017 22.0634L214.681 21.5014L215.243 18.1661L218.579 18.728Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M222.957 60.3263L221.741 58.9318L221.477 58.6259C221.193 58.2971 220.944 58.0027 220.704 57.7138L220.491 57.4545C218.064 54.485 216.494 51.7459 215.612 48.413L215.568 48.2458C214.609 44.4801 211.107 43.0271 208.273 44.8105C206.823 45.7229 205.783 47.3778 205.4 49.6057C204.951 52.2145 205.413 55.4761 206.903 59.2599L207.199 60.007C208.389 63.0004 209.384 65.3037 210.332 67.3232L210.514 67.7088C210.888 68.4858 212.09 70.9332 212.362 71.5588C212.703 72.3414 212.969 73.081 213.226 73.9832C213.331 74.3528 213.436 74.7521 213.542 75.1923C213.961 76.9233 214.024 78.3973 213.712 79.7504L213.687 79.8542C212.866 83.205 212.544 87.4097 213.015 92.0185C213.639 98.1196 215.595 104.412 219.143 110.709C219.29 110.97 219.198 111.302 218.936 111.449C218.675 111.597 218.343 111.504 218.195 111.243C214.573 104.813 212.572 98.3768 211.933 92.1292C211.457 87.4667 211.771 83.1979 212.592 79.7555L212.631 79.5947C212.926 78.398 212.876 77.0652 212.485 75.4479L212.417 75.1696C212.337 74.8517 212.259 74.5573 212.18 74.2813C211.951 73.4792 211.718 72.819 211.425 72.1312L211.365 71.9932C211.085 71.3504 209.737 68.6121 209.464 68.034L209.288 67.6605C208.271 65.4837 207.198 62.9782 205.891 59.6583C204.333 55.7008 203.843 52.2422 204.328 49.4215C204.761 46.8996 205.973 44.9731 207.694 43.8901C211.158 41.7095 215.483 43.5035 216.622 47.9767C217.45 51.2106 218.958 53.8596 221.333 56.7663L221.542 57.0204C221.824 57.3604 222.121 57.7093 222.473 58.1161L223.848 59.6938L224.086 59.9715C224.967 61.0063 225.541 61.7634 225.989 62.5167L227.843 65.6637L230.809 70.7245L235.707 79.1064C235.858 79.3658 235.771 79.6988 235.511 79.8502C235.252 80.0016 234.919 79.914 234.768 79.6546L228.162 68.3561C226.727 65.9085 225.656 64.0869 225.054 63.0721C224.631 62.3604 224.066 61.6211 223.174 60.5787L222.957 60.3263Z"
        fill="#273067"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M300.981 128.976C301.281 128.975 301.525 129.217 301.527 129.518C301.528 129.807 301.302 130.045 301.017 130.062L300.985 130.063L1.1299 131.224C0.829561 131.225 0.585151 130.982 0.583989 130.682C0.582868 130.392 0.808341 130.155 1.09374 130.137L1.12569 130.136L300.981 128.976Z"
        fill="#273067"
      />
    </svg>

    <h5 class="heading-h5" style="margin-top: 32px; margin-bottom: 12px">
      {{ title }}
    </h5>
    <p class="body-2" style="margin-bottom: 24px">
      {{ subtitle }}
    </p>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalSuccessPayment',
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false,
    },
    title: {
      type: String,
      require: false,
      default: '¡Mejoraste tu suscripción de Ticker!',
    },
    subtitle: {
      type: String,
      require: false,
      default:
        'Tu nuevo plan mensual se cargará a tu método de pago predeterminado.',
    },
  },
  data: () => ({
    modalSuccessPayment: {
      styles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '30em',
        justifyContent: 'center',
        paddingRight: '32px',
        paddingLeft: '32px',
      },
    },
  }),
  methods: {
    onCancel() {
      this.$emit('hideModal')
    },
  },
}
</script>
