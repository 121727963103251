<template>
  <a-drawer
    :title="drawer_filters.title"
    :width="drawer_filters.width"
    :visible="visible"
    class="drawer"
    :mask="false"
    @close="onClose"
  >
    <a-form :form="form" class="filters" layout="vertical" hide-required-mark>
      <!-- Estados -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">Estados</label>
        </template>
        <a-select
          v-model="localFilters.status"
          default-value="all"
          class="mrg-right-12"
          placeholder="Selecciona un estado"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option
            v-for="(value, key) in statuses_strategies"
            :key="key"
          >
            {{ value.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Creado por -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">Creado por</label>
        </template>
        <a-select
          class="filter-item__select"
          v-model="localFilters.userId"
          show-search
          option-filter-prop="children"
          placeholder="Selecciona un usuario"
        >
          <a-select-option value=""> Todos los usuarios </a-select-option>
          <a-select-option
            v-for="agent in simpleAgents"
            :key="agent.userId"
            :title="agent.names"
          >
            {{ agent.names }} {{ agent.surnames }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Colas -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">Colas</label>
        </template>
        <a-select
          class="filters__item"
          v-model="localFilters.lineId"
          placeholder="Selecciona una cola"
        >
          <a-select-option value="">Todos las colas</a-select-option>
          <a-select-option v-for="line in linesWithWhatsapp" :key="line._id">
            {{ line.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--Numero de whatsapp-->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">ID de WhatsApp</label>
        </template>
        <a-select
          v-model="localFilters.channelCompanyId"
          placeholder="Selecciona un número"
          @change="handleChannelCompany"
        >
          <a-select-option value="all"> Todos los ID</a-select-option>
          <a-select-option
            v-for="channel in filterSimpleChannelWhatsAppByType({
              type: 'gupshup',
            })"
            :key="channel._id"
          >
            {{ channel.title }}
            ( {{ channel.alias ? channel.alias : 'Sin alias' }} )
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">Tipo de plantillas</label>
        </template>
        <a-select
          class="filters__item"
          v-model="localFilters.typeTemplate"
          placeholder="Selecciona una plantilla"
        >
          <a-select-option value="">Todos los tipos</a-select-option>
          <a-select-option
            v-for="typeTemplate in TYPE_TEMPLATES_FOR_LIST"
            :key="typeTemplate.value"
          >
            {{ typeTemplate.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Plantillas -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="semibold-14">Plantillas</label>
        </template>
        <a-select
          class="filters__item"
          v-model="localFilters.templateId"
          placeholder="Selecciona una plantilla"
        >
          <a-select-option value="">Todos las plantillas</a-select-option>
          <a-select-option
            v-for="template in templatesFilteredByType"
            :key="template._id"
          >
            {{ template.friendlyName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <basic-footer
      :okText="okText"
      cancelText="Cancelar"
      @onCancel="handleCancel"
      @onOk="handleSubmit"
      :okLoading="loadingOk"
      :okDisabled="disabledOk"
      :cancelDisabled="disabledCancel"
    />
  </a-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import { TYPE_TEMPLATES_FOR_LIST } from '@/app/channels/utils/componentsDataTemplates'

export default {
  name: 'DrawerFiltersStrategies',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  components: {
    BasicFooter,
  },
  data: () => ({
    TYPE_TEMPLATES_FOR_LIST,
    localFilters: {},
    loadingOk: false,
    disabledCancel: false,
    disabledOk: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'FormFiltersStrategies' })
  },
  created() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'no profile'
      if (this.isAllowedFor(this.profile.type, ['admin', 'master']))
        this.getSimpleAgents({ excludeChatbotUser: true })
    })
  },
  mixins: [supportMixin, compareMixin, attemptMixin],
  watch: {
    /**
     * Observa si el valor de visible cambia, si es verdadero seteara los valores de los filtros a los locales
     */
    visible(val) {
      if (val) this.localFilters = this.filters
    },
  },
  computed: {
    ...mapGetters([
      'profile',
      'simpleAgents',
      'drawer_filters',
      'statuses_strategies',
      'companyLines',
      'simple_templates',
    ]),
    /**
     * @returns {String} - Aplicando o Aplicar
     */
    okText() {
      return this.loadingOk ? 'Aplicando' : 'Aplicar'
    },
    /**
     * @returns {Object[]} colas del canal whatsapp
     */
    linesWithWhatsapp() {
      return this.companyLines.filter((line) => {
        if (!line.channelsAsigned) return false
        else return line.channelsAsigned.includes('whatsapp')
      })
    },
    /**
     * Plantillas filtradas por tipo
     * @return {Object[]} templates
     * @return {String} templates._id
     * @return {String} templates.friendlyName
     */
    templatesFilteredByType() {
      const typesAllowed = [this.localFilters.typeTemplate]
      return [...this.simple_templates].filter(
        (simpleTemplate) =>
          typesAllowed.includes(simpleTemplate.type) ||
          typesAllowed.includes(simpleTemplate.typeAttachment)
      )
    },
  },
  methods: {
    ...mapActions([
      'getCompanyStrategies',
      'getSimpleAgents',
      'getSimpleTemplates',
    ]),
    /**
     * Cerrar y resetear
     */
    onClose() {
      this.$emit('onClose')
      this.disabledCancel = false
      this.disabledOk = false
      this.loadingOk = false
    },
    /**
     * Submit del formulario
     */
    async handleSubmit() {
      this.form.validateFields(async (err) => {
        if (!err) {
          this.loadingOk = true
          this.disabledCancel = true
          this.onSetFilters()
          this.$emit('onLoading', true)
          const response = await this.getCompanyStrategies(this.localFilters)
          if (!response.success) this.$message.error('Ocurrío un error')

          this.$emit('onLoading', false)
          this.loadingOk = false
          this.onClose()
        }
      })
    },
    /**
     * Emitir el seteo de filtros
     */
    onSetFilters() {
      this.$emit('onSetFilters', this.localFilters)
    },
    /**
     * Cancela los filtros
     */
    handleCancel() {
      this.onClose()
    },
    /**
     * Escucha el evento de cambio de canal
     * @param {String} channelCompanyId
     */
    handleChannelCompany(channelCompanyId) {
      this.getSimpleTemplates(channelCompanyId)
    },
  },
}
</script>

<style scoped></style>
