// Console Warnings

console.log.bind(
  console,
  '%c¡Detente!',
  'color: #FF0000; font-size:32px; font-weight:bold;'
)()
console.log.bind(
  console,
  '%cSi no sabes lo que estas haciendo, lo mejor será que te detengas. No copies ni pegues nada aquí, o podrías estar exponiendo tus datos a terceros con malas intenciones.\n\n\n',
  'color: #2c3e50; font-size:18px; font-weight:normal;'
)()

if (process.env.VUE_APP_PRINT_LOGS !== 'true') console.log = () => {}
