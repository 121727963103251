var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-form"},[_c('a-form',{staticClass:"form",attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"section-form-item",staticStyle:{"padding-bottom":"0px"},attrs:{"label":"Nombre","hasFeedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'names',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'names',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-form-item',{staticClass:"section-form-item",staticStyle:{"padding-bottom":"0px"},attrs:{"label":"Apellidos","hasFeedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surnames',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'surnames',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-form-item',{staticClass:"section-form-item",staticStyle:{"padding-bottom":"0px"},attrs:{"label":"Correo Corporativo","hasFeedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                message: 'Por favor ingresa tu correo electrónico.',
              },
              {
                type: 'email',
                message: 'Ingresa un correo válido.',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios en blanco.',
              },
              {
                min: 6,
                message: '6 caracteres como mínimo.',
              },
              {
                max: 50,
                message: '50 caracteres como máximo.',
              },
              {
                pattern:
                  '^(?!.*?(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*',
                message: 'Sólo se aceptan correos corporativos',
              },
              { validator: _vm.compareMails },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor ingresa tu correo electrónico.',\n              },\n              {\n                type: 'email',\n                message: 'Ingresa un correo válido.',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios en blanco.',\n              },\n              {\n                min: 6,\n                message: '6 caracteres como mínimo.',\n              },\n              {\n                max: 50,\n                message: '50 caracteres como máximo.',\n              },\n              {\n                pattern:\n                  '^(?!.*?(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*',\n                message: 'Sólo se aceptan correos corporativos',\n              },\n              { validator: compareMails },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-form-item',{attrs:{"label":"Teléfono de contacto"}},[_c('a-row',{attrs:{"gutter":8,"type":"flex"}},[_c('a-col',{attrs:{"span":14,"order":2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                  {
                    pattern: /^([0-9])*$/g,
                    message: 'Solo puedes ingresar números.',
                  },
                ],
              },
            ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                  {\n                    pattern: /^([0-9])*$/g,\n                    message: 'Solo puedes ingresar números.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"maxLenght":20,"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-col',{attrs:{"span":10,"order":1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                initialValue: _vm.defaultValue,
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                ],
              },
            ]),expression:"[\n              'code',\n              {\n                initialValue: defaultValue,\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","placeholder":"País","option-filter-prop":"children","size":_vm.inputs_size}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country._id,attrs:{"title":country.nameSpanish}},[_vm._v(" "+_vm._s(country.dialCode)+" "+_vm._s(_vm.normalizeStr(country.nameSpanish))+" ")])}),1)],1)],1)],1),_c('a-button',{staticClass:"personal-form-btn personal-form-btn--first",attrs:{"html-type":"submit","type":"primary","loading":_vm.loading,"disabled":_vm.disabledOk,"size":_vm.buttons_size}},[_vm._v("Continuar")]),_c('a-divider',{staticClass:"personal-form__divider"},[_c('span',{staticClass:"body-2 mrg-bottom-0"},[_vm._v("o")])]),(_vm.enable_register_facebook)?_c('a-button',{staticClass:"personal-form-btn",on:{"click":_vm.facebookSignUp}},[_c('span',{staticStyle:{"margin-right":"8px"}},[_c('img',{attrs:{"src":"img/icon_login/facebook.png"}})]),_vm._v(" Continua con Facebook")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }