<template>
  <div class="channels">
    <ChannelsTemplate />
  </div>
</template>
<script>
import ChannelsTemplate from '@/app/channels/components/templates/ChannelsTemplate.vue'
export default {
  name: 'Channels',
  components: {
    ChannelsTemplate,
  },
  data: () => ({}),
  methods: {},
}
</script>
<style lang="sass" scoped>
.channels
  height: 100vh
</style>
