var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{staticClass:"mrg-bottom-16",attrs:{"type":"flex","align":"middle"}},[_c('a-checkbox',{attrs:{"checked":_vm.waitAnswer,"disabled":_vm.action === 'none'},on:{"change":_vm.handleChangeWait}},[_vm._v(" Esperar una respuesta ")]),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Tu chatbot esperará una respuesta de tu cliente para continuar. ")]),_c('a-icon',{staticClass:"radio-message__icon",staticStyle:{"height":"fit-content","margin-top":"2px"},attrs:{"type":"info-circle"}})],2)],1),_c('a-form-item',{staticClass:"section-form-item section-form-item--message",attrs:{"label":"Mensaje"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que será un mensaje de tipo texto para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthMessage,"text":_vm.form.getFieldValue('text') ? _vm.form.getFieldValue('text') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'text',
          {
            rules: [
              {
                required: _vm.requiredText,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'text',\n          {\n            rules: [\n              {\n                required: requiredText,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"message_form_text",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","disabled":_vm.onlyRead,"filterOption":_vm.filterOption},on:{"change":_vm.handleChangeText}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"disabled-wait-answer":_vm.action === 'none' || _vm.onlyRead,"value-check":_vm.waitAnswer,"disabled-emoji":_vm.onlyRead,"show-wait":false},on:{"onChangeEmoji":_vm.handleSetEmoji,"onOpenVars":_vm.handleOpenVars}})],1),_c('a-form-item',{staticClass:"text--left",attrs:{"label":"Archivo adjunto"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'fileMedia',
        {
          valuePropName: 'fileList',
          getValueFromEvent: _vm.normFile,
          rules: [
            {
              required: _vm.requiredFile,
              message: 'Por favor sube el archivo',
            },
          ],
        },
      ]),expression:"[\n        'fileMedia',\n        {\n          valuePropName: 'fileList',\n          getValueFromEvent: normFile,\n          rules: [\n            {\n              required: requiredFile,\n              message: 'Por favor sube el archivo',\n            },\n          ],\n        },\n      ]"}],attrs:{"name":"fileMedia","multiple":false,"customRequest":_vm.uploadFile,"before-upload":_vm.beforeUpload,"remove":_vm.handleRemoveFile,"accept":_vm.formatsToUpload},on:{"preview":_vm.handlePreviewModal}},[_c('p',[_c('a-icon',{staticClass:"form__icon--inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_c('span',[_vm._v(" Solo se aceptan "+_vm._s(_vm.descriptionUpload)+". ")])])])],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una de las acciones"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'action',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            },
          ],
        },
      ]),expression:"[\n        'action',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeAction}},_vm._l((_vm.actionsToNodeSelected),function(actionToNode){return _c('a-select-option',{key:actionToNode.value,staticClass:"form__selector__option",attrs:{"disabled":(actionToNode.value === 'continue_message' && _vm.disabledAddMessage) ||
          (actionToNode.actionToSave && actionToNode.actionToSave.disabled)}},[(
            actionToNode.value === 'continue_message' && _vm.disabledAddMessage
          )?_c('a-popover',{attrs:{"title":"Agregar un nuevo mensaje","placement":"left"}},[_c('template',{slot:"content"},[_c('p',[_vm._v(" Recuerda que solo puedes tener "),_c('br'),_vm._v(" 2 mensajes seguidos sin esperar "),_c('br'),_vm._v(" respuesta. ")])]),_c('div',{staticClass:"selector__option-container"},[_vm._v(" "+_vm._s(actionToNode.title)+" ")])],2):_c('span',[_vm._v(" "+_vm._s(actionToNode.title)+" "),(
              actionToNode.value !== _vm.action &&
              actionToNode.actionToSave &&
              actionToNode.actionToSave.disabled
            )?_c('i',[_vm._v("( No hay variables disponibles )")]):_vm._e()])],1)}),1)],1),(_vm.action === 'transfer')?_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una cola"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'lineId',
        {
          rules: [{ required: true, message: 'Completa el campo' }],
        },
      ]),expression:"[\n        'lineId',\n        {\n          rules: [{ required: true, message: 'Completa el campo' }],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeLines}},_vm._l((_vm.dataSourceLines),function(line){return _c('a-select-option',{key:line.key,attrs:{"value":line.key}},[_vm._v(" "+_vm._s(line.title)+" ")])}),1)],1):_vm._e(),(_vm.description)?[_c('p',{staticClass:"body-2 text--left"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]:_vm._e(),_c('modal-preview-media',{attrs:{"visible":_vm.modalPreviewMedia.visible,"title":_vm.modalPreviewMedia.title,"type":_vm.modalPreviewMedia.type,"url":_vm.modalPreviewMedia.url},on:{"onClose":_vm.handleCloseModalPreview}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }