<template>
  <section class="section">
    <div class="section__83w">
      <h5
        class="heading-h5 align-left gray-dark-900"
        style="margin-bottom: 8px"
      >
        MIS CANALES
      </h5>
      <p class="align-left gray-dark-900 body-3 section__description">
        Aquí aparecen listados todos tus canales conectados, en esta sección
        puedes filtrarlos por su estado o saber más información
      </p>
    </div>
    <!--Filtro de canales-->
    <table-filters
      :showButtons="false"
      :showMoreFilters="false"
      class="section__83w"
    >
      <!-- Filtros para mis canales -->
      <template slot="filters">
        <a-select
          class="filters__item"
          @change="handleFilter"
          v-model="filters.channel"
          style="width: 190px"
        >
          <template v-if="company">
            <a-select-option
              v-for="channel in activeChannelsForFilters"
              :key="`${channel.channel}`"
              :title="channel.channel"
            >
              {{ channel.name }}
            </a-select-option>
          </template>
        </a-select>

        <a-select
          class="filters__item"
          dropdownClassName="filters__dropdown--status"
          @change="handleFilter"
          v-model="filters.status"
          style="width: 190px"
        >
          <a-select-option
            v-for="channelStatus in statusChannels"
            :key="channelStatus.value"
            :title="channelStatus.title"
            class="filters__item--status"
          >
            <a-tooltip :overlayStyle="{ width: '200px' }" placement="right">
              <template v-if="channelStatus.tooltip" slot="title">
                {{ channelStatus.tooltip }}
              </template>
              <div class="filters__item--status__container">
                {{ channelStatus.title }}
              </div>
            </a-tooltip>
          </a-select-option>
        </a-select>
      </template>
    </table-filters>
    <!--Alerta para los estados de canales -->
    <alert-status-channel v-if="!allow_show_expired_banner" />
    <!--Listado de mis canales-->
    <list-my-channels
      :data-source="paginate_company_channels"
      class="section__83w"
      :total="pagination_company_channels.total"
      :page="pagination_company_channels.page"
      :loading="loading"
      :status="statusNameOfFilter"
      @onPaginate="handleSetPaginate"
      @onLoading="handleReloadList"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import ListMyChannels from '@/app/channels/components/molecules/ListMyChannels'
import AlertStatusChannel from '@/app/channels/components/molecules/AlertStatusChannel'
import { STATUS_CHANNELS } from '@/app/channels/utils/statusChannelsUtil'

export default {
  name: 'SectionMyChannels',
  components: {
    TableFilters,
    ListMyChannels,
    AlertStatusChannel,
  },
  data: () => ({
    STATUS_CHANNELS,
    filters: {
      channel: 'all',
      status: 'approved',
      page: 1,
    },
    loading: false,
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      const status = this.$route.query?.status
      if (status) this.initialFilterByStatus()
      else this.loadInitialData()
    })
  },
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'company',
      'paginate_company_channels',
      'pagination_company_channels',
      'channels',
      'allow_show_expired_banner',
    ]),

    /**
     * Estados de los canales para filtrar
     * @returns {Object[]} statusChannels
     * @returns {String} statusChannels[].value
     * @returns {String} statusChannels[].title
     * @returns {String} statusChannels[].color
     * @returns {String} statusChannels[].tooltip
     */
    statusChannels() {
      return {
        all: {
          value: 'all',
          title: 'Todos los estados',
        },
        ...STATUS_CHANNELS,
      }
    },
    /**
     * @returns {String} - nombre del status filtrado
     */
    statusNameOfFilter() {
      const statusSelected =
        this.statusChannels[this.filters.status].value.toLowerCase()
      return this.filters.status != 'all' ? statusSelected + 's' : ''
    },
  },
  methods: {
    ...mapActions(['getCompanyChannels']),
    ...mapMutations([
      'UPDATE_ACTIVE_COMPANY_CHANNEL',
      'DELETE_COMPANY_CHANNEL',
    ]),

    /**
     * Filtra todos los canales
     */
    handleFilter() {
      this.resetQueryRoute()
      this.getCompanyChannels({
        page: 1,
        status: this.filters.status,
        channel: this.filters.channel,
      })
    },
    /**
     * Carga la data inicial
     */
    async loadInitialData() {
      this.loading = true
      await this.getCompanyChannels({
        page: 1,
        status: this.filters.status,
      })
      this.loading = false
    },
    /**
     * Setea la pagina
     */
    async handleSetPaginate(page) {
      this.loading = true
      await this.getCompanyChannels({
        page,
        status: this.filters.status,
        channel: this.filters.channel,
      })
      this.loading = false
    },
    /**
     * Vuelve a cargar la lista de canales
     * @param {Object} reason
     */
    handleReloadList(reason) {
      this.loading = true
      if (['unlink_channel', 'reconnect_channel'].includes(reason.reason)) {
        if (this.filters.status === 'all')
          this.UPDATE_ACTIVE_COMPANY_CHANNEL({
            channelCompanyId: reason.channelCompanyId,
            active: false,
          })
        else this.DELETE_COMPANY_CHANNEL(reason.channelCompanyId)
      }
      this.loading = false
    },
    /**
     * Filtra de acuerdo al estado que viene de la ruta
     */
    initialFilterByStatus() {
      const status = this.$route.query?.status
      if (!status) return
      this.filters.status = status
      this.loadInitialData()
    },
    // borra el filtro de la ruta
    resetQueryRoute() {
      const status = this.$route.query?.status
      if (status) this.$router.replace({ query: {} })
    },
  },
}
</script>

<style lang="sass" scoped>
$max_width: 83.33333333%

.section
  padding: 20px 12px 12px
  &__83w
    margin: 0 auto
    max-width: $max_width
.filters
  margin-bottom: 12px
  &__item
    margin-right: 8px
.section
  &__description
    margin-bottom: 16px
.filters__dropdown--status
  .filters__item--status
    width: 100%
  .filters__item--status__container
    width: calc(100% + 24px)
    margin-left: -12px
    padding-left: 12px
</style>
