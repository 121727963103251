<template>
  <card-base class="text--left card">
    <h6 class="heading-h6 card__title">
      Transferir Owner (Dueño de la plataforma)
    </h6>
    <p>
      Usted cuenta con el control total de la plataforma. Este rol puede ser
      transferido solo a los usuarios master. Una vez transferido este permiso
      no se podrá volver a recuperar a menos que el nuevo Owner lo autorice.
    </p>
    <a-button @click="handleShowModalTransfer">Transferir Owner</a-button>
    <!--Modal de transferencia de permiso-->
    <modal-type
      :visible="modalTransferOwner.visible"
      :title="title"
      type="confirmation"
      :ok-disabled="disabledBtnTransfer"
      :ok-text="okText"
      :onOk="handleTransferOwner"
      @onClose="handleCancelTransfer"
    >
      <p class="body-2">
        Recuerda que solo podrás transferir este rol a usuarios de tipo Master.
        Selecciona el nombre del usuario al cual le transferirás el rol de
        Owner.
      </p>
      <h6 class="heading-h7 full-width text--left" style="color: #51555a">
        Seleccionar un nuevo usuario propietario
      </h6>
      <a-select
        class="full-width mrg-bottom-16"
        v-model="modalTransferOwner.newIdOwner"
        placeholder="Selecciona aquí"
      >
        <a-select-option v-for="master in filterMasters" :key="master.userId">
          {{ master.names ? master.names : 'Sin nombre' }}
          {{ master.surnames ? master.surnames : 'Sin apellido' }}
        </a-select-option>
      </a-select>
      <h6 class="heading-h7 full-width text--left" style="color: #51555a">
        Escribe la palabra
        <span style="color: #262a31">“Transferir”</span>
      </h6>
      <a-input
        v-model="wordTransfer"
        class="full-width mrg-bottom-20"
        placeholder="Escribe aquí..."
      />
    </modal-type>
  </card-base>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardBase from '@/app/shared/components/cards/CardBase'
import ModalType from '@/app/shared/components/modals/ModalType'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'CardTransferOwner',
  components: {
    CardBase,
    ModalType,
  },
  mixins: [transformMixin],
  data: () => ({
    modalTransferOwner: {
      visible: false,
      newIdOwner: undefined,
    },
    wordTransfer: '',
    okLoading: false,
  }),
  mounted() {},
  computed: {
    ...mapGetters([
      'profile',
      'company',
      'simple_companies',
      'master_settings',
      'masters',
    ]),

    /**
     * @return {String}
     */
    title() {
      if (!this.profile) return ''
      return `¿Estás  ${this.transformWordGender(
        'seguro',
        this.profile.gender
      )} de transferir el rol de Owner a un nuevo usuario"?`
    },
    /**
     * @return {String}
     */
    okText() {
      return this.okLoading ? 'Transfiriendo' : 'Transferir permiso'
    },

    /**Deshabilitar boton de transferencia */
    disabledBtnTransfer() {
      return this.modalTransferOwner.newIdOwner &&
        this.wordTransfer === 'Transferir'
        ? false
        : true
    },
    /**
     * Filtrar administradores excepto el usuario en sesión
     **/
    filterMasters() {
      const currentUserId = this.profile.userId
      return this.masters.filter((item) => item.userId != currentUserId)
    },
  },
  methods: {
    ...mapActions(['transferMasterOwner']),

    handleShowModalTransfer() {
      this.modalTransferOwner.visible = true
    },
    handleCancelTransfer() {
      this.modalTransferOwner.visible = false
      this.modalTransferOwner.newIdOwner = undefined
      this.wordTransfer = ''
    },
    async handleTransferOwner() {
      this.okLoading = true
      const response = await this.transferMasterOwner(
        this.modalTransferOwner.newIdOwner
      )
      this.okLoading = false
      return response
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  color: $gray_dark_800
  &__title
    color: $gray_dark_800
  &__selector
    width: 20em
    margin-right: 16px
  &__action-icon
    margin-bottom: 6px
    font-size: 16px
    color: $blue-6
</style>
