<template>
  <a-modal
    :visible="visible"
    :title="channelModal.title"
    :footer="null"
    :bodyStyle="{
      minHeight: '666px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 5%',
    }"
    width="70em"
    @cancel="() => $emit('onClose')"
    centered
    :maskClosable="false"
    :afterClose="afterClose"
  >
    <a-steps progress-dot :current="currentStep" class="modal_steps">
      <a-step title="Seleccionar" />
      <a-step title="Completar" />
      <a-step title="Finalizar" />
    </a-steps>
    <div v-if="channelModal.steps" class="modal_content">
      <template v-if="currentStep === 0">
        <h5 class="heading-h5 title">
          {{ channelModal.steps[currentStep].title }}
        </h5>
        <a-row :gutter="[36]" class="row" type="flex" justify="center">
          <a-col
            :span="10"
            v-for="(provider, index) in enabledProviders"
            :key="index"
            class="row_col"
          >
            <CardProvider
              @onClickButton="handleSelectProvider(provider.name)"
              :type="provider.type"
              :title="provider.title"
              :images="provider.images"
              :nameButton="provider.nameButton"
              :benefits="provider.benefits"
              :link="`${vari.WEB || ''}whatsapp-business-api/precios/`"
            />
          </a-col>
        </a-row>
        <p class="body-3 feedback">
          ¿No encuentras el proveedor que deseas? Escríbenos a
          {{ vari.WEB_EMAIL_SUPPORT }}
          y cuéntanos sobre él.
        </p>
      </template>
      <template v-if="currentStep === 1">
        <!--Formulario para el proveedor de securitec-->
        <template v-if="provider === 'securitec'">
          <a-row :gutter="[30]" class="content-sec">
            <a-col :span="12" class="col">
              <h5 class="heading-h5 title">
                Antes de completar la solicitud, te recomendamos cumplir con lo
                siguiente:
              </h5>
              <div class="item">
                <span class="check">✅</span>
                <p class="body-2">
                  Debes contar con una página de Facebook de tu empresa.
                </p>
              </div>
              <div class="item" style="margin-bottom: 8px">
                <span class="check">✅</span>
                <div>
                  <p class="body-2 mrg-bottom-0">
                    Contar con Facebook Business Manager. Si quieres crearlo,
                    sigue estos pasos:
                  </p>
                  <a
                    href="https://www.facebook.com/business/help/1710077379203657?id=180505742745347&_rdc=2&_rdr"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Crea tu FB Business Manager</a
                  >
                  <br />
                  <a
                    href="https://www.facebook.com/business/help/720478807965744?id=420299598837059&_rdc=1&_rdr"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Asocia tu página de Facebook a FB Business Manager</a
                  >
                </div>
              </div>
              <h6 class="heading-h7 content-sec__title">
                ¡Mira que dicen nuestros clientes de {{ app }}! 😍
              </h6>
              <Bubble
                v-for="(bubble, index) in bubbles"
                :key="index"
                :message="bubble.message"
                :subText="bubble.subtext"
                avatar="/img/clients/kontigo-logo.jpg"
              />
            </a-col>
            <a-col :span="12">
              <FormRequestSec
                class="modal_form"
                @onSubmit="handleSubmit"
                @onCancel="handleBackStep"
                :loading="loadingFormRequest"
                :industries="industries"
                :languages="languages"
                :countries="countriesNotRepeatByName"
                :company="company"
                :profile="profile"
              />
            </a-col>
          </a-row>
        </template>
        <!--Formulario para el proveedor de twilio-->
        <template v-if="provider === 'twilio'">
          <a-row type="flex" justify="center">
            <a-col :span="15">
              <h5 class="heading-h5 title">
                Completa los datos para integrar con Twilio
              </h5>
              <p class="body-2 description">
                Recuerda que los datos pedidos a continuación los podrás
                encontrar en tu cuenta con Twilio.
              </p>
            </a-col>
            <a-col :span="10">
              <FormProviderTwilio
                class="modal_form"
                @onSubmit="handleSubmit"
                @onCancel="handleBackStep"
                :countries="countries"
              />
            </a-col>
          </a-row>
        </template>
        <!--Formulario para el proveedor de tedexis-->
        <template v-if="provider === 'tedexis'">
          <a-row type="flex" justify="center">
            <a-col :span="15">
              <h5 class="heading-h5 title">
                Completa los datos para integrar con Tedexis
              </h5>
              <p class="body-2 description">
                Recuerda que los datos pedidos a continuación los podrás
                encontrar en tu cuenta con Tedexis.
              </p>
            </a-col>
            <a-col :span="10">
              <FormProviderTedexis
                class="modal_form"
                @onSubmit="handleSubmit"
                @onCancel="handleBackStep"
                :countries="countries"
                :default-step="currentStep - 1"
              />
            </a-col>
          </a-row>
        </template>
      </template>
      <template v-if="currentStep === 2">
        <!--Proveedor Twilio - url de conexiones -->
        <template v-if="provider === 'twilio'">
          <h5 class="heading-h5 title">
            Finaliza tu integración copiando esta url
          </h5>
          <p class="body-2 description">
            En tu cuenta de Twilio encontrarás una sección llamada webhooks,
            donde tendrás que pegar la siguiente url.
          </p>
          <a-row type="flex" justify="center">
            <a-col :span="10">
              <a-form layout="vertical" class="modal_form">
                <a-form-item
                  v-for="webhook in webhooks"
                  :key="webhook._id"
                  :label="webhook.name"
                >
                  <a-input
                    v-model="webhook.uri"
                    placeholder="Escribe aquí..."
                    readOnly
                  >
                    <a-icon
                      slot="suffix"
                      type="copy"
                      v-clipboard:copy="webhook.uri"
                      v-clipboard:success="onCopyWebhook"
                      v-clipboard:error="onError"
                      class="icon_copy"
                    />
                  </a-input>
                </a-form-item>
              </a-form>
              <div class="form_webhooks__footer">
                <a-button type="primary" @click="handleFinishIntegrateTwilio"
                  >Finalizar integración</a-button
                >
              </div>
            </a-col>
          </a-row>
        </template>
        <!--Proveedor Securitec - Enviado correctamente-->
        <template v-else-if="provider === 'securitec'">
          <div class="content-success">
            <img
              :src="
                require('/src/app/shared/assets/whoooa_graphics/solid/success-send.png')
              "
            />
            <h5 class="heading-h5 content-success__title">
              Tu solicitud se ha enviado correctamente a
              {{ transformNameProvider('Beex') }}
            </h5>
            <p class="body-2 content-success__description">
              Tu solicitud ha sido enviada y pasará por el proceso de revisión.
              El tiempo máximo es de 1.5 a 2 semanas. ¡Gracias!
            </p>
            <a-button type="primary" @click="handleFinishIntegrate"
              >Aceptar e ir a mis canales</a-button
            >
          </div>
        </template>
        <template v-else-if="provider === 'tedexis'">
          <FormProviderTedexis
            class="modal_form"
            :countries="countries"
            :default-step="currentStep - 1"
            @onClose="handleFinishIntegrate"
          />
        </template>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardProvider from '@/app/channels/components/organisms/cards/CardProvider.vue'
import FormRequestSec from '@/app/channels/components/organisms/forms/FormRequestSec.vue'
import FormProviderTwilio from '@/app/channels/components/organisms/forms/FormProviderTwilio'
import FormProviderTedexis from '@/app/channels/components/organisms/forms/FormProviderTedexis'
import Bubble from '@/app/shared/components/molecules/Bubble'
import supportMixin from '@/app/shared/mixins/support'
import { CHANNELS_MODAL_STEPS } from '@/app/channels/utils/utilChannels'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalIntegrateChannel',
  components: {
    CardProvider,
    FormRequestSec,
    FormProviderTwilio,
    FormProviderTedexis,
    Bubble,
  },
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false,
    },
    channel: {
      type: String,
      require: true,
      enum: ['whatsapp', 'sms'],
    },
  },
  mixins: [supportMixin, transformMixin],
  data: () => ({
    vari,
    app: process.env.VUE_APP_NAME,
    CHANNELS_MODAL_STEPS,
    currentStep: 0,
    provider: null,
    webhooks: [],
    bubbles: [
      {
        message:
          'El API de WhatsApp ha mejorado nuestra atención al cliente, ya que podemos comunicarnos con nuestros clientes por su App preferida. Las acciones personalizadas por este canal nos ha permitido mejorar nuestros tiempos de respuesta.',
        subtext: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
      },
      // {
      //   message:
      //     'La ayuda que tuve de Securitec para dar de alta mi API fue importante. Gracias a esto, la implementamos y pude crear chatbots para no perder ninguna oportunidad de venta.',
      //   subtext: 'Microempresaria',
      // },
    ],
    loadingFormRequest: false,
    newChannelId: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'form-integrate-provider',
    })
  },
  computed: {
    ...mapGetters([
      'industries',
      'languages',
      'countries',
      'company',
      'profile',
      'countriesNotRepeatByName',
      'enable_twilio_provider',
      'channels',
    ]),

    /**
     * @return {Object[]} provider
     * @return {String} provider.name
     * @return {String[]} provider.benefits
     * @return {String} provider.title
     * @return {String} provider.nameButton
     * @return {String[]} provider.images
     */
    enabledProviders() {
      if (this.enable_twilio_provider) return this.providers
      else
        return this.providers.filter((provider) => provider.name !== 'twilio')
    },
    providers() {
      const channel = this.channel
      if (!channel) return []
      return CHANNELS_MODAL_STEPS[channel].providers
    },
    channelModal() {
      const channel = this.channel
      if (!channel)
        return {
          title: 'Integrar canal',
        }
      return CHANNELS_MODAL_STEPS[channel]
    },
  },
  methods: {
    ...mapActions(['sendRequestToSec', 'integrateProviderWhatsapp']),

    /**
     * Seleccionar proveedor
     * @param {String} provider
     */
    handleSelectProvider(provider) {
      this.provider = provider
      this.currentStep++
    },
    /**Submit del formulario
     * @param {Object} values
     */
    async handleSubmit(values) {
      values.channel = 'whatsapp'
      this.loadingFormRequest = true
      switch (this.provider) {
        case 'twilio':
          {
            values.provider = 'twilio_v3'
            const response = await this.integrateProviderWhatsapp(values)
            this.loadingFormRequest = false
            if (response.success) {
              this.newChannelId = response.channelCompanyId
              this.webhooks = response.webhooks
              this.currentStep++
            } else this.$message.error(response.details)
          }
          break
        case 'securitec':
          {
            const response = await this.sendRequestToSec(values)
            this.loadingFormRequest = false
            if (response.success) {
              this.currentStep++
            } else this.$message.error(`${response.details}`)
          }
          break
        default: {
          this.currentStep++
        }
      }
    },
    /**Regresar de paso */
    handleBackStep() {
      this.currentStep--
    },
    /**
     * Se ejecuta esta accion despues de cerrado el modal
     */
    afterClose() {
      this.currentStep = 0
      this.provider = null
      this.form.resetFields()
      this.webhooks = {
        webhookError: null,
        webhookMessage: null,
        webhookStatus: null,
      }
    },
    /**
     * Copia el webhook de menssaje
     */
    onCopyWebhookMessage() {
      this.$message.success('La webhook de mensaje se copio')
    },
    /**
     * Copia el webhook de estado
     */
    onCopyWebhookStatus() {
      this.$message.success('La webhook de estado se copio')
    },
    /**
     * Copia el webhook de error
     */
    onCopyWebhookError() {
      this.$message.success('La webhook de error se copio')
    },
    /**
     * Copia uan webhook cualquiera
     */
    onCopyWebhook() {
      this.$message.success('La webhook se copio correctamente')
    },
    /**
     * Error en el copiado
     */
    onError() {
      this.$message.error('Fallo')
    },
    /**
     * Finaliza la integracion
     */
    handleFinishIntegrate() {
      this.$emit('onClose')
      this.$router.push({
        name: 'my-channels',
      })
    },
    /**
     * Finaliza la integracion de Twilio
     */
    handleFinishIntegrateTwilio() {
      this.$emit('onClose')
      this.$router.push({
        name: 'channel',
        params: { channel: 'whatsapp' },
        query: { channelId: this.newChannelId },
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.align-items
  display: flex
  flex-direction: column
  align-items: center
.icon_copy
  cursor: pointer
.modal_steps
  // width: 40em
.modal_content
  width: 100%
  .title
    text-align: center
    margin: 24px auto 24px
    color: $gray_dark_900
  .description
    color: $gray_dark_800
    // width: 28em
    text-align: center
    margin-bottom: 12px
  .feedback
    text-align: center
    margin-top: 24px
  .modal_form
    // width: 28em
    // position: relative
    &__buttons
      width: inherit
      position: absolute
      bottom: 32px
      display: flex
      justify-content: flex-end
      .button
        margin-right: 12px
  .row
    padding-top: 10px
    .row_col
      &:first-child
        padding-left: 0px !important
      &:last-child
        padding-right: 0px !important
.content-success
  height: 500px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &__title
    margin: 24px auto 12px
    color: $gray_dark_900
  &__description
    margin-bottom: 24px
    width: 72%
    text-align: center
    color: $gray_dark_800
.content-sec
  margin-top: 24px
  .col
    .title
      color: $gray_dark_900
      text-align: left
      margin-top: 0px
    .item
      display: flex
      .check
        margin-right: 12px
        color: black
  &__title
    margin-top: 20px
    margin-bottom: 16px
.form_webhooks__footer
  display: flex
  justify-content: flex-end
</style>
