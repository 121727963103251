<template>
  <div>
    <header-module
      title="GESTOR DE VARIABLES"
      tipography="heading-h5"
      position-tooltip="right"
      text-before-link="Crea variables y haz que tu chatbot guarde los datos de tus clientes."
      text-after-link="para que sepas cómo hacerlo."
      :link="`${vari.WEB_DOC || ''}chatbots/chatbot-variables/`"
    />
    <a-row type="flex" justify="space-between" class="mrg-bottom-12">
      <div>
        <a-input-search
          v-model="filters.search"
          placeholder="Buscar por nombre"
          class="section__input--search mrg-right-8"
          @search="handleFilterVars"
        />
      </div>
      <a-row type="flex" align="middle">
        <template v-if="false">
          <a-row type="flex" align="middle">
            <p class="regular-12 mrg-bottom-0 section__description">
              Guardar datos solo de la primera respuesta
              <a-tooltip :title="textTooltip">
                <a-icon type="info-circle" />
              </a-tooltip>
            </p>
            <a-switch
              v-model="saveFirstAnswer.value"
              :loading="saveFirstAnswer.loading"
              :disabled="saveFirstAnswer.disabled"
              @change="handleShowConfirmToFirstAnswer"
            />
          </a-row>
          <a-divider type="vertical" class="section__divider" />
        </template>
        <a-button type="primary" @click="showModalVarChatBot('create')"
          >Crear nueva variable</a-button
        >
      </a-row>
    </a-row>
    <vars-chatbot-table
      :loading="loadingTable"
      @onShowEdit="(record) => showModalVarChatBot('update', record)"
      @onShowDelete="handleShowModalVarDelete"
      @onPaginate="handlePaginateVars"
    />
    <modal-var-chatbot
      ref="modalVarChatBot"
      :visible="modalVarChatBot.visible"
      :title="modalVarChatBot.title"
      :okText="modalVarChatBot.okText"
      :onOk="modalVarChatBot.onOk"
      @onClose="modalVarChatBot.visible = false"
    />
    <modal-var-delete
      :visible="modalVarDelete.visible"
      :var-name="modalVarDelete.varName"
      :var-id="modalVarDelete.varId"
      @onClose="handleCloseModalVarDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import HeaderModule from '@/app/shared/components/headers/HeaderModule.vue'
import ModalVarChatbot from '@/app/chatbots/components/organisms/modals/ModalVarChatbot'
import VarsChatbotTable from '@/app/chatbots/components/organisms/tables/VarsChatbotTable.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import transformMixin from '@/app/shared/mixins/transform'
import ModalVarDelete from '@/app/chatbots/components/organisms/modals/ModalVarDelete'

export default {
  name: 'SectionManageChatbotsVars',
  components: {
    HeaderModule,
    ModalVarChatbot,
    VarsChatbotTable,
    ModalVarDelete,
  },
  mixins: [attemptMixin, transformMixin],
  data: () => ({
    vari,
    modalVarChatBot: {
      visible: false,
      title: 'Crear una nueva variable',
      okText: '',
      onOk: () => {},
    },
    filters: {
      type: 'all',
      search: '',
      page: 1,
    },
    loadingTable: false,
    saveFirstAnswer: {
      value: false,
      loading: true,
      disabled: true,
    },
    modalVarDelete: {
      visible: false,
      varName: '',
      varId: '',
    },
  }),
  created() {
    this.intervalAttempt(async () => {
      if (!this.company) throw 'Not company found'
      this.loadInitialData()
    })
  },
  computed: {
    ...mapGetters(['type_vars', 'company']),

    /**
     * @param {String} - texto del tooltip
     */
    textTooltip() {
      return this.saveFirstAnswer.value
        ? 'Solo se guardarán los datos de la primera respuesta. Al desactivarse, con cada nueva respuesta del cliente, se reemplazará los datos que se guardarón previamente.'
        : 'Al activarse, solo se guardarán los datos de la primera respuesta del cliente.'
    },
  },
  methods: {
    ...mapActions([
      'getChatbotsVars',
      'getListCustomFields',
      'addChatbotVar',
      'updateChatbotVar',
      'updateCompanySettingsChatbots',
      'getSimpleChatbotsVars',
    ]),

    /**
     * Muestra el modal de variable
     * @param {String} typeAction - create, edit
     */
    showModalVarChatBot(typeAction, record) {
      switch (typeAction) {
        case 'create':
          {
            this.modalVarChatBot.title = 'Crear una nueva variable'
            this.modalVarChatBot.okText = 'Crear'
            this.modalVarChatBot.onOk = this.handleAddChatbotVar
          }
          break
        case 'update': {
          this.modalVarChatBot.title = 'Edita la variable'
          this.modalVarChatBot.okText = 'Editar'
          this.modalVarChatBot.onOk = this.handleUpdateChatbotVar
          this.modalVarChatBot.chatbotVarId = record._id
          this.$nextTick(() => {
            this.$refs.modalVarChatBot.setFieldsValue(record)
          })
        }
      }
      this.modalVarChatBot.visible = true
    },
    /**
     * Carga la data inicial
     */
    async loadInitialData() {
      await this.handleFilterVars()
      this.getListCustomFields()
      this.getSimpleChatbotsVars()
      this.saveFirstAnswer.value =
        this.company.settings.chatbot.saveDataFirstTimeChatbotVars
      this.saveFirstAnswer.disabled = false
      this.saveFirstAnswer.loading = false
    },
    /**
     * Filtra las variables
     */
    async handleFilterVars() {
      this.loadingTable = true
      await this.getChatbotsVars(this.filters)
      this.loadingTable = false
    },
    /**
     * Añade una variable
     * @param {String} values.name
     * @param {String} values.customFieldId
     * @param {String[]} values.excludeWords
     */
    async handleAddChatbotVar(values) {
      this.loadingTable = true
      const response = await this.addChatbotVar(values)
      this.loadingTable = false

      if (response.success) {
        this.modalVarChatBot.visible = false
        this.$message.success('Tu variable se creó con exito')
      } else {
        this.$message.error(response.details || 'Ocurrió un error 😪')
      }
    },
    /**
     * Actualiza una variable
     * @param {String} values.name
     * @param {String} values.customFieldId
     * @param {String[]} values.excludeWords
     */
    async handleUpdateChatbotVar(values) {
      this.loadingTable = true
      const response = await this.updateChatbotVar({
        ...values,
        chatbotVarId: this.modalVarChatBot.chatbotVarId,
      })
      this.loadingTable = false

      if (response.success) {
        this.modalVarChatBot.visible = false
        this.$message.success('Tu variable se actualizo con exito')
      } else {
        this.$message.error(response.details || 'Ocurrió un error 😪')
      }
    },
    /**
     * Muestra la confirmacion para la primera respuesta
     * @param {Boolean} activeFirstAnswer
     */
    handleShowConfirmToFirstAnswer(activeFirstAnswer) {
      this.saveFirstAnswer.loading = true
      const confirmContent = {
        title: `¿Estás  ${this.transformWordGender(
          'seguro',
          this.profile.gender
        )} de ${
          activeFirstAnswer ? 'activar' : 'desactivar'
        } el guardado de datos de solo la primera respuesta?`,
        okText: activeFirstAnswer ? 'Activar' : 'Desactivar',
      }
      let self = this
      this.$confirm({
        title: confirmContent.title,
        content:
          'Recuerda que tu configuración y todos los cambios no guardados  se perderán.',
        okText: confirmContent.okText,
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          const response = await self.updateCompanySettingsChatbots({
            saveDataFirstTimeChatbotVars: activeFirstAnswer,
          })
          self.saveFirstAnswer.loading = false
          if (response.success) {
            self.$message.success(
              response.details || 'Se actualizó tu configuración'
            )
          } else {
            self.$message.error(response.details || 'Ocurrió un error 😥')
          }
        },
        onCancel() {
          self.$destroyAll()
          self.saveFirstAnswer.loading = false
          self.saveFirstAnswer.value = !self.saveFirstAnswer.value
        },
      })
    },
    /**
     * Muestra el modal de eliminacion
     * @param {String} record.name
     * @param {String} record._id
     */
    handleShowModalVarDelete(record) {
      this.modalVarDelete.visible = true
      this.modalVarDelete.varName = record.name
      this.modalVarDelete.varId = record._id
    },
    /**
     * Cierra el modal de eliminacion
     */
    handleCloseModalVarDelete() {
      this.modalVarDelete.visible = false
      this.modalVarDelete.varName = ''
      this.modalVarDelete.varId = ''
    },
    /**
     * Paginacion de las variables
     * @param {Number} page
     */
    handlePaginateVars(page) {
      this.filters.page = page
      this.handleFilterVars()
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  &__input--search
    width: 312px
  &__selector
    width: 224px
  &__divider
    height: 32px
    margin: 0px 14px 0px 13px
  &__description
    width: 160px
</style>
