<template>
  <a-popover
    trigger="click"
    overlayClassName="popover-emojis"
    :overlayStyle="styles"
  >
    <template slot="content">
      <div class="popover__content scrollbar_basic">
        <emoji-list @onSelect="handleSelecteIcon" />
      </div>
    </template>
    <a-tooltip>
      <template slot="title" v-if="showTooltip">Emojis</template>
      <a-icon
        type="smile"
        class="popover__icon"
        :class="{ 'popover__icon--disabled': disabled }"
        :style="iconStyles"
      />
    </a-tooltip>
  </a-popover>
</template>

<script>
import EmojiList from '@/app/tickets/components/molecules/EmojiList'

export default {
  name: 'EmojiPicker',
  props: {
    iconStyles: { type: Object },
    disabled: { type: Boolean },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    EmojiList,
  },
  data: () => ({
    styles: {
      width: '300px',
    },
  }),
  methods: {
    handleSelecteIcon(emoji) {
      this.$emit('onChangeEmoji', emoji)
    },
  },
}
</script>

<style lang="sass" scoped>
.popover
  &__content
    height: 300px
    width: 300px
    overflow-y: auto
    padding-right: 8px
  &__icon
    font-size: 26px
    cursor: pointer
    color: $gray_dark_700
    &--disabled
      cursor: not-allowed
      pointer-events: none
      color: #BFBFBF
    &:hover
      color: $blue_light_900
    &::focus
      color: $blue_light_900
</style>
<style lang="sass">
.popover-emojis
  .ant-popover-content
    .ant-popover-inner
      width: max-content
      .ant-popover-inner-content
        padding: 12px 0px 12px 8px
</style>
