<template>
  <div class="payment-rejected-upgrade">
    <modal-select-credit-card
      :visible="visible"
      :loading="upgradeLoading"
      @onOk="hadleActiveUpgrade"
      @hideModal="hideModalUpgrade"
      @addOtherCredit="handleAddOtherCredit"
      :showError="modalSelectCard.error.visible"
      :messageError="modalSelectCard.error.message"
    />
    <modal-form-settings-credit
      :visible="modalSettingCard.visible"
      @hideModal="hideModalSettingCard"
      @onSubmit="hadleActiveUpgrade"
      :loading="modalSettingCard.loading"
      :showError="modalSettingCard.error.visible"
      :messageError="modalSettingCard.error.message"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import ModalSelectCreditCard from '@/app/shop/components/market/settingsCreditCard/ModalSelectCreditCard.vue'
import ModalFormSettingsCredit from '@/app/shop/components/market/settingsCreditCard/ModalFormSettingsCredit.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'PaymentRejectedUpgrade',
  mixins: [attemptMixin],
  components: {
    ModalSelectCreditCard,
    ModalFormSettingsCredit,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    upgradeLoading: false, // Loading del modal de upgrade
    modalSettingCard: {
      visible: false,
      loading: false,
      error: {
        message: '',
        visible: false,
      },
    },
    modalSelectCard: {
      visible: false,
      loading: false,
      error: {
        message: '',
        visible: false,
      },
    },
  }),
  mounted() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'Company not loaded'
      this.SET_TOTAL_TO_PURCHASE(this.company.settings.subscription.total)
    })
  },
  computed: {
    ...mapGetters(['company']),
  },
  methods: {
    ...mapMutations(['SET_TOTAL_TO_PURCHASE']),
    ...mapActions(['paymentManual']),
    hideModalSettingCard() {
      this.modalSettingCard = {
        visible: false,
        loading: false,
        error: {
          message: '',
          visible: false,
        },
      }
    },
    /**
     * @async
     * @param {Object}  card
     * @param {String}  card.idCard
     * @param {Boolean} card.isFirstTime
     * @param {Boolean} card.isNew
     */
    async hadleActiveUpgrade(card) {
      this.upgradeLoading = true
      if (this.modalSettingCard.visible) this.modalSettingCard.loading = true

      const response = await this.paymentManual({
        card: { ...card },
      })
      this.modalSettingCard.loading = false
      this.upgradeLoading = false

      if (
        response.planPayment?.status === 'approved' ||
        response.planPayment?.fromFree
      ) {
        this.$emit('upgrade')
        this.hideModalSettingCard()
        this.hideModalUpgrade()
      } else if (response.planPayment?.status === 'pending') {
        this.successText.title = 'Tu compra aun esta pendiente'
        this.successText.subtitle =
          'Podrás usar todos los beneficios de tu compra sin problemas'
        this.hideModalUpgrade()
        this.hideModalSettingCard()
      } else if (response.planPayment?.status === 'rejected') {
        this.$message.error(
          'No pudimos procesar tu pago 😓, por favor revisa tus datos'
        )
        let modal = null
        if (this.modalSettingCard.visible) modal = 'modalSettingCard'
        else modal = 'modalSelectCard'
        this[modal].error = {
          message: response.planPayment.rejectionDetail
            ? `No pudimos procesar su pago 😓. ${response.planPayment.rejectionDetail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
            : 'No pudimos procesar su pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta',
          visible: true,
        }
      } else {
        this.$message.error('Ocurrió un error')
      }
    },
    hideModalUpgrade() {
      this.$emit('hide')
      this.modalSelectCard = {
        visible: false,
        loading: false,
        error: {
          message: '',
          visible: false,
        },
      }
    },
    handleAddOtherCredit() {
      this.modalSettingCard.visible = true
    },
  },
}
</script>
