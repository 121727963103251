<template>
  <a-modal
    :visible="visible"
    title="Conectar con el nuevo Messenger"
    :body-style="{ height: '44em' }"
    width="47em"
    @cancel="handleClose"
  >
    <carousel-new-fb :key="key" channel="messenger" />
    <basic-footer
      slot="footer"
      style="height: 53px"
      :show-cancel="false"
      ok-text="Conectar con Messenger"
      justify="justify-content--center"
      @onOk="handleOk"
    />
  </a-modal>
</template>

<script>
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CarouselNewFb from '@/app/channels/components/messenger/CarouselNewFb.vue'

export default {
  name: 'ModalFbStart',
  components: {
    BasicFooter,
    CarouselNewFb,
  },
  props: {
    visible: { type: Boolean, default: false },
  },
  data: () => ({
    key: 0,
  }),
  methods: {
    /**
     * Cierra el modal
     */
    handleClose() {
      this.$emit('onClose')
      this.key += 1
    },
    handleOk() {
      this.$emit('onOk')
    },
  },
}
</script>
