<template>
  <card-base class="text--left card">
    <h6 class="heading-h6 card__title">Subcuenta por defecto</h6>
    <p>
      Cada que inices sesión nuevamente, esta será la subcuenta en la que
      aparecerás contectado por defecto.
    </p>

    <a-row type="flex" align="bottom">
      <a-row>
        <p class="regular-14 mrg-bottom-2 card__label">
          Subcuenta seleccionada
        </p>
        <a-select
          class="card__selector"
          v-model="currentCompany._id"
          :disabled="!isEditing"
        >
          <a-select-option
            v-for="simpleCompany in localSimpleCompanies"
            :key="simpleCompany._id"
            :disabled="
              master_settings &&
              simpleCompany._id === master_settings.defaultCompany._id
            "
            >{{ simpleCompany.name }}</a-select-option
          >
        </a-select>
      </a-row>
      <a-row>
        <a-tooltip
          v-if="!isEditing"
          title="Edita tu subcuenta por defecto"
          placement="right"
        >
          <a-button @click="isEditing = true">Editar</a-button>
        </a-tooltip>
        <template v-else>
          <a-button
            class="mrg-right-12"
            :disabled="disabled.actions"
            @click="handleCancel"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            :disabled="disabled.actions"
            :loading="loadingOk"
            @click="handleOk"
            >{{ okText }}</a-button
          >
        </template>
      </a-row>
    </a-row>
  </card-base>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardBase from '@/app/shared/components/cards/CardBase'

export default {
  name: 'CardDefaultCompany',
  components: {
    CardBase,
  },
  data: () => ({
    currentCompany: {
      name: '',
      _id: '',
    },
    disabled: {
      actions: false,
      select: false,
    },
    loadingOk: false,
    isEditing: false,
  }),
  watch: {
    master_settings(values) {
      if (values.defaultCompany) this.currentCompany = values.defaultCompany
    },
  },
  computed: {
    ...mapGetters(['company', 'simple_companies', 'master_settings']),

    /**
     * @return {String}
     */
    okText() {
      return this.loadingOk ? 'Guardando' : 'Guardar'
    },
    /**
     * Empresas locales
     * @return {Object[]} localSimpleCompanies
     * @return {String} localSimpleCompanies[].name
     * @return {String} localSimpleCompanies[]._id
     */
    localSimpleCompanies() {
      const simpleCompanies = [...this.simple_companies]
      if (this.company)
        simpleCompanies.push({ name: this.company.name, _id: this.company._id })
      return simpleCompanies
    },
  },
  methods: {
    ...mapActions(['updateSettingsMaster']),

    /**
     * Cancela la acción y regresa los cambios que se hicieron
     */
    handleCancel() {
      this.isEditing = false
      this.currentCompany = this.master_settings.defaultCompany
    },
    /**
     * Ejecuta la actualización del tiempo de transferencia
     */
    async handleOk() {
      this.loadingOk = true
      this.disabled.actions = true
      const response = await this.updateSettingsMaster({
        defaultCompanyId: this.currentCompany._id,
      })
      if (response.success) {
        this.isEditing = false
        this.$message.success(response.details || 'Se actualizó con éxito')
      } else {
        this.isEditing = true
        this.$message.error(response.details || 'Ocurrió un error 😥')
      }
      this.loadingOk = false
      this.disabled.actions = false
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  color: $gray_dark_800
  &__title
    color: $gray_dark_800
  &__selector
    width: 20em
    margin-right: 16px
  &__action-icon
    margin-bottom: 6px
    font-size: 16px
    color: $blue-6
</style>
