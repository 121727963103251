<template>
  <div class="sign-up">
    <SignupTemplate />
  </div>
</template>

<script>
import SignupTemplate from '@/app/signup/components/templates/SignupTemplate.vue'
export default {
  name: 'Signup',
  components: {
    SignupTemplate,
  },
}
</script>

<style lang="sass" scoped>
.sign-up
</style>
