<template>
  <!--si el tipo de icon es button-->
  <i v-if="type === 'button'" class="anticon" aria-label="icon">
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="8" cy="8" r="7.5" fill="#E6F7FF" stroke="#1890FF"/><circle cx="8" cy="8" r="4" fill="#1890FF"/></svg>
  </i>
  <!--si el tipo de icon es browser-->
  <i v-else-if="type === 'browser'" class="anticon" aria-label="icon">
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 9H3v10h18V9zm0-2V5H3v2h18zM3 21a2 2 0 01-2-2V5a2 2 0 012-2h18a2 2 0 012 2v14a2 2 0 01-2 2H3z" fill="currentColor"/></svg>
  </i>
  <a-icon v-else :type="type" />
</template>

<script>
export default {
  name: 'IconNode',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped></style>
