<template>
  <div class="login">
    <LoginTemplate />
  </div>
</template>
<script>
import LoginTemplate from '@/app/auth/components/templates/LoginTemplate.vue'
export default {
  name: 'Login',
  components: {
    LoginTemplate,
  },
}
</script>
<style lang="sass" scoped></style>
