<template>
  <div class="profile-template">
    <a-row :gutter="[12]" class="row">
      <a-col :span="6" class="col">
        <MenuProfile :itemSelected="itemSelected" />
      </a-col>
      <a-col :span="18" class="col" v-if="profile && company">
        <router-view></router-view>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuProfile from '@/app/profile/components/organisms/MenuProfile.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'ProfileTemplate',
  mixins: [attemptMixin],
  components: {
    MenuProfile,
  },
  created() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'profile'
      this.listAdmins()
    })
  },
  computed: {
    ...mapGetters(['profile', 'company']),
    // menu seleccionado de configuracion
    itemSelected() {
      if (this.$route.name === 'profile') return null
      return this.$route.name
    },
  },
  methods: {
    ...mapActions(['listAdmins']),
  },
}
</script>

<style lang="sass">
.profile-template
  padding: $padding-heigth-inner
  height: 100vh
  .row
    height: 100%
    margin-right: 0px
    .col
      height: 100%
</style>
