const getters = {
  minimalQuantityDay: (state) => state.minimalQuantityDay,
  expirationPlanModal: (state) => state.expirationPlanModal,
  purchase: (state) => state.purchase,
  isAllowBuyPlans: (state) =>
    !state.purchase.products.some((purchase) => purchase.type === 'plan'),
  isAllowBuyPacks: (state) =>
    !state.purchase.products.some((purchase) => purchase.type === 'pack'),
  products: (state) => state.products,
  payments: (state) => state.payments,
  payments_pagination: (state) => state.payments_pagination,
  payments_statuses: (state) => state.payments_statuses,
}

export default getters
