<template>
  <div
    :id="id"
    class="card-field"
    :class="{ selected: selected, 'no-selected': !selected }"
    @click="clickCard"
  >
    <slot name="icon"></slot>
    <h6
      class="heading-h8 text-center"
      style="margin-bottom: 2px; margin-top: 4px"
    >
      {{ title }}
    </h6>
    <p class="body-1 text-center description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'CardField',
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
      default: 'Name',
    },
    description: {
      type: String,
      default: 'Description',
    },
    selected: { type: Boolean, required: false, default: false },
  },
  data: () => ({}),
  mounted() {},
  methods: {
    clickCard() {
      this.$emit('onSelect')
    },
  },
}
</script>

<style lang="sass" scoped>
.text-center
  text-align: center
.selected
  border: 1px solid #096DD9
.no-selected
  border: 1px solid $gray_light_100
.card-field
  width: 136px
  display: flex
  flex-direction: column
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 8px
  padding: 12px
  color: $gray_dark_900
  cursor: pointer
  .description
    color: $gray_dark_700
  &:hover
    border: 1px solid #1890FF
</style>
