import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_ADMIN_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Setea todos los datos de pricing de una empres(plan actual, benefiicio, etc)
   * @param {*} state
   * @param {Object} companyPricing // Objeto de company pricing
   */
  SET_COMPANY_PRICING(state, companyPricing) {
    state.companyPricing = companyPricing
  },
  /**
   * Setea los planes en pricings
   */
  SET_PRICINGS(state, pricings) {
    state.pricings = pricings
  },
}

export default mutations
