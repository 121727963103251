<template>
  <a-row>
    <a-table
      class="scrollbar_basic table-middle"
      :columns="columns"
      :data-source="newDataSource"
      :pagination="false"
      :scroll="{ y: 'calc(90.2vh - 19em - 250px)' }"
      size="small"
      rowKey="_id"
    >
      <template slot="action" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <!-- <a @click="() => handleSave(record)">Guardar</a>
            <a-divider type="vertical" />
            <a @click="handleCancel(record)">Cancelar</a> -->
          </span>
          <span v-else>
            <!-- <a
              :disabled="!record.editableMailbox.value || editingKey !== ''"
              @click="handleEdit(record)"
              >Editar</a
            >
            <a-divider type="vertical" /> -->
            <a @click="$emit('onDelete', record)" :disabled="disabled"
              >Eliminar</a
            >
          </span>
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
export default {
  name: 'EditableTable',
  props: {
    columns: { type: Array, required: true },
    dataSource: { type: Array, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  computed: {
    newDataSource() {
      if (this.dataSource.length === 0) return []
      let n = 0
      return this.dataSource.map((elem) => {
        n = n + 1
        elem.order = n
        return elem
      })
    },
  },
}
</script>

<style lang="sass">
.table-middle
  .ant-table-body, .ant-table-body-inner
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
