import axios from 'axios'
import vari from '@/app/shared/utils/variables'

import {
  BANNER_CHANNElS,
  replaceMessageAlertChannel,
} from '@/app/commons/utils/banners'

const actions = {
  /**
   * Validará si se se debe mostrar el banner de canales expirados
   */
  async showExpiredBanner(context) {
    try {
      const response = await context.dispatch('getSimpleCompanyChannels', {
        params: { channel: 'instagram,messenger', status: 'troubled' },
        set: false,
      })
      const allow_show_expired_banner =
        context.getters.allow_show_expired_banner
      const channelsTroubled = response.data
      const channelsTroubledLength = channelsTroubled.length
      // si la respuesta fue exitosa y hay canales expirados
      if (response.success && channelsTroubledLength) {
        const typeBanner = allow_show_expired_banner ? 'banner' : 'alert'

        let text = ''
        // si los canales expirados son más de uno
        if (channelsTroubledLength > 1) {
          text = replaceMessageAlertChannel({
            type_message: 'message_plural',
            type_data: typeBanner,
            values: {
              channel: 'Facebook',
              total_pages: channelsTroubledLength,
            },
          })
        }
        // si los canales expirados es solamente 1
        else
          text = replaceMessageAlertChannel({
            type_data: typeBanner,
            values: {
              page: channelsTroubled[0].title,
              channel: channelsTroubled[0].channel,
            },
          })

        context.commit('SET_ALERT_BANNER_CHANNEL', {
          visible: allow_show_expired_banner,
          ...BANNER_CHANNElS.troubled,
          textHTML: text,
          text: text,
          type: typeBanner,
        })
      }
    } catch (error) {
      console.error('error', error)
    }
  },
  async readExpiredBanner(context) {
    try {
      console.log('readExpiredBanner')
      const response = axios.put(
        `${vari.UHR}/user/update-expired-banner-counter`
      )
      console.log(response)
      context.commit('RESET_ALERT_BANNER_CHANNEL')
    } catch (error) {
      console.error('error', error)
    }
  },
}

export default actions
