<template>
  <full-template
    :parent-styles="{ height: '100vh', backgroundColor: '#FFFFFF' }"
  >
    <div class="redirect">
      <!--conectando a otra empresa-->
      <section-connect v-if="isConnecting" :company-name="companyName" />
      <!--redireccionando-->
      <span v-else>Redireccionando...</span>
    </div>
  </full-template>
</template>

<script>
import FullTemplate from '@/app/shared/components/templates/FullTemplate'
import SectionConnect from '@/app/commons/components/organisms/SectionConnect'

export default {
  name: 'Redirect',
  components: {
    SectionConnect,
    FullTemplate,
  },
  computed: {
    isConnecting() {
      return this.$route.query.connecting
    },
    companyName() {
      return this.$route.query.company_name
    },
  },
  created() {
    const connecting = this.$route.query.connecting
    // si esta conectando a otra empresa
    if (connecting) {
      setTimeout(() => {
        this.$router.push({ name: 'redirect' })
        window.location.reload()
      }, 8000)
    }
    if (connecting) return
    const afterLogin = this.$route.query.al // Identificar que es una redirección exactamente despues de haberse logueado a la plataforma
    this.$store.dispatch('validateSignin', { afterLogin: afterLogin })
  },
}
</script>
