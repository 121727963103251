<template>
  <div
    ref="section_board"
    class="section-board scrollbar_basic"
    v-chat-scroll="{
      always: false,
      smooth: true,
      scrollonremoved: true,
      smoothonremoved: false,
    }"
    @v-chat-scroll-top-reached="loadMoreMessages"
    @scroll="scrollBottom"
  >
    <div
      class="content"
      ref="section_board_content"
      :class="{ space: typingRemote }"
    >
      <template v-if="loading">
        <BubleChat
          v-for="(message, index) in messagesSkeleton"
          :key="index"
          :message="message"
          :loading="loading"
        />
      </template>
      <template v-else-if="!loading && firstLoadExecuted">
        <BubleChat
          v-for="(message, index) in messages"
          :key="index"
          :id="message._id"
          :message="message"
          :client="dataClient"
          :formats="enabledTextFormats"
          :blocked-actions="blockedActions"
        />
      </template>
    </div>
    <!-- Escribiendo -->
    <transition name="slide-fade">
      <div
        v-if="profile && profile.type !== 'agent'"
        class="typing"
        v-show="typingRemote"
      >
        <WaitPointsAnimation>
          <template>
            <span>Agente escribiendo</span>
          </template>
        </WaitPointsAnimation>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BubleChat from '@/app/tickets/components/organisms/BubleChat.vue'
import WaitPointsAnimation from '@/app/shared/components/animations/WaitPointsAnimation.vue'
import moment from 'moment'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import filters from '@/app/shared/utils/filters'
moment.locale('es')

export default {
  name: 'SectionBoard',
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataClient: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  components: {
    BubleChat,
    WaitPointsAnimation,
  },
  data: () => ({
    moment: moment,
    usersAsigned: [], // usuarios asignados
    messagesSkeleton: [
      { method: 'received', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 16 } },
      { method: 'sent', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 16 } },
    ],
    usersWithRanges: [],
    firstLoadExecuted: false,
    lastScrollTop: 0,
    historyPages: [], // historial de paginas que ya fueron consultadas
  }),
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'profile',
      'ticketSelected',
      'typingRemote',
      'profileRemote',
      'agents',
      'channelsCompany',
    ]),
    /**
     * Agrega propiedad marginTop diferente si hay dos mensajes seguidos con diferente metodo
     * @returns {Object[]} Message
     */
    messagesStyle() {
      if (!this.data) return []
      let lastMethod = null
      return this.data.messages.map((message) => {
        if (message.method !== lastMethod) {
          lastMethod = message.method
          message.styles = { marginTop: 24 }
        } else {
          message.styles = { marginTop: 16 }
        }
        return message
      })
    },
    /**
     * Agrega propiedad marginTop diferente si hay dos mensajes seguidos con diferente metodo
     * @returns {Object[]} Message
     */
    messages() {
      let messages = this.messagesStyle.map((message) => {
        if (
          message.method === 'sent' &&
          !message.message.auto &&
          !message.user
        ) {
          // Se busca en el arreglo de usuarios
          let user = this.usersAsigned.find(
            (userAssigned) => userAssigned.userId === message.userId
          )
          // si es que se encuentra al usuario
          if (user !== undefined) message.user = { ...user }
          // si es que el mensaje viene de una estrategia
          else if (message.strategy) {
            message.user = {
              names: `Campaña: ${message.strategy.name}`,
              surnames: '',
              type: 'strategy',
            }
          }
          // si es que usuario es el mismo de la sesion
          else if (
            this.currentAgent &&
            message.userId === this.currentAgent.userId
          ) {
            message.user = this.currentAgent
          } else message.user = { names: 'ERROR_NOT_FOUND' }
          return message
        } else {
          if (!message.apiReceivedAt) {
            message.apiReceivedAt = message.created_at
          }
          return message
        }
      })
      // filtra los mensajes para que no se repitan
      messages = filters.filterMessagesNoRepeated({ messages })
      // ordenar los mensajes
      const orderMessages = messages.sort(
        (a, b) => new Date(a.apiReceivedAt) - new Date(b.apiReceivedAt)
      )
      return orderMessages
    },
    /**Agente actual
     * @returns {Object}
     */
    currentAgent() {
      const agent = this.profileRemote
        ? {
            names: this.profileRemote.names,
            surnames: this.profileRemote.surnames,
            avatar: this.profileRemote.avatar
              ? this.profileRemote.avatar
              : null,
            userId: this.profileRemote.userId,
          }
        : {
            names: this.profile.names,
            surnames: this.profile.surnames,
            avatar: this.profile.avatar ? this.profile.avatar : null,
            userId: this.profile.userId,
          }
      return agent
    },
    enabledTextFormats() {
      const channel = this.channelCompanyOfTicket(this.data)
      if (!channel || !channel.api) return false
      return channel.api.chat_options?.textFormats
    },
    /**
     * Paginación de los mensajes
     * @return {Object} pagination
     * @return {Number} pagination.page
     */
    pagination() {
      if (!this.data) return { page: 1 }
      return this.data.pagination_messages
    },
    /**
     * @return {String[]} - acciones bloqueadas para le mensaje
     */
    blockedActions() {
      const actions = []
      if (this.data && this.data.status === 'ended') actions.push('add-comment')
      if (this.type === 'history-manage') actions.push('add-comment')
      if (this.type !== 'inbox')
        actions.push('reply-with-templates', 'update-comment', 'delete-comment')
      return actions
    },
  },
  watch: {
    ticketSelected: {
      handler() {
        this.loadFirstData()
      },
    },
    data: {
      handler() {
        if (!this.data) return
        this.scrollToBottom()
      },
    },
    'data.pagination_messages'(newPagination) {
      if (newPagination && newPagination.page)
        this.historyPages.push(newPagination.page)
    },
  },
  created() {
    this.loadFirstData()
  },
  methods: {
    ...mapActions(['paginateMessages', 'getSimplyAgents']),

    /** Mandar el scroll hasta el final */
    async scrollToBottom() {
      const sectionBoard = this.$refs.section_board
      if (!sectionBoard) return
      let heightBoard = sectionBoard.scrollHeight
      this.$refs.section_board.scrollTo(0, heightBoard)
    },
    /**
     * Carga más mensajes
     */
    loadMoreMessages() {
      const currentPage = this.pagination.page
      const nextPage = currentPage + 1
      this.scrollSamePosition(async () => {
        await this.paginateMessages({
          target: this.type,
          page: nextPage,
        })
      })
    },
    /**
     * @param {Function} callback
     */
    async scrollSamePosition(callback) {
      const sectionBoard = this.$refs.section_board
      if (!sectionBoard) return
      let heightBoardSaved = sectionBoard.scrollHeight
      await callback()
      let heightBoardNew = this.$refs.section_board.scrollHeight
      this.$refs.section_board.scrollTo(0, heightBoardNew - heightBoardSaved)
    },
    /**
     * Completa los datos de los usuarios asignados a un ticket
     * @param {Array} - usuarios asignados al ticket
     */
    async completeUsersAsigned(usersAsigned) {
      if (usersAsigned.length === 0) {
        return
      }
      const usersId = []
      usersAsigned.map((user) => usersId.push(user.userId))
      const response = await this.getSimplyAgents({
        usersId: usersId,
        extraFields: ['avatar'],
      })
      if (response.length > 0) {
        usersAsigned.forEach((user) => {
          response.forEach((elem) => {
            if (elem.userId === user.userId) {
              const obj = Object.assign({}, user, elem)
              this.usersAsigned.push(obj)
            }
          })
        })
      } else return (this.usersAsigned = [])
    },
    /**
     * Cargar los ranges de los usuarios asignados
     */
    loadFirstData() {
      this.firstLoadExecuted = false
      this.intervalPromiseAttempt(async () => {
        if (!this.usersAsigned.length && !this.data) throw 'no users assigned'
        this.scrollToBottom()
        this.usersAsigned = []
        await this.completeUsersAsigned(this.data.users ? this.data.users : [])
        this.firstLoadExecuted = true

        this.$nextTick(() => this.verifyShowScroll())
      })
    },
    /**
     * Detecta el scroll bottom y ejecuta la peticion de paginas
     * que aun no han sido usadas
     */
    async scrollBottom() {
      const sectionBoard = this.$refs.section_board
      const st = sectionBoard.scrollTop

      if (st > this.lastScrollTop) {
        // si se scrollea hacia abajo, y la pagina anterior no esta en el historial
        // realizará la peticion de la pagina anterior
        const backPage = this.pagination.page - 1
        if (backPage > 0 && !this.historyPages.includes(backPage)) {
          await this.paginateMessages({
            target: this.type,
            page: backPage,
          })
        }
      }

      this.lastScrollTop = Math.max(st, 0) // For mobile or negative scrolling
    },
    /**
     * Verifica si no existe scroll para traer más mensajes
     */
    verifyShowScroll() {
      const element = this.$refs.section_board
      const show = element.scrollHeight > element.clientHeight
      // si no hay scroll y los mensajes mostrados son menor al total de mensajes
      if (
        !show &&
        this.data.pagination_messages &&
        this.data.messages.length < this.data.pagination_messages.total
      ) {
        this.loadMoreMessages()
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.slide-fade-enter
  transform: translate(0, 20px)
  transition: all .6s ease-in
.slide-fade-enter-to
  transform: translate(0,-20px)
  transition: all .6s ease-in-out
.slide-fade-leave-active
  transform: translate(0,20px)
  transition: all .6s ease

.section-board
  height: inherit
  padding: 20px
  overflow-y: auto
  .typing
    background: $blue_light_000
    width: fit-content
    padding: 8px 20px
    border-radius: 16px
    margin: 0 auto
    cursor: default
    position: absolute
    bottom: 20px
    left: 0
    right: 0
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  .space
    margin-bottom: 60px
</style>
