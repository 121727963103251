import axios from 'axios'
import router from '@/router'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import filters from '@/app/shared/utils/filters'
// import sort from '@/app/shared/utils/sort'
const delayMiliseconds = 1000

const actions = {
  /**
   * Obtiene y setea los ids de los tickets sin leer
   * @param {vuex}    context
   * @param {Boolean} [reset=false] Resetea los buzones
   * @returns
   */
  async getTotalUnreadTickets(context, reset = false) {
    try {
      let agentUserId = ''
      if (router.currentRoute.meta.is_monitor) {
        if (!context.getters.profileRemote) return
        agentUserId = context.getters.profileRemote.userId
      }

      const response = await axios.get(
        `${vari.UHR}/agent/tickets/unread?agentUserId=${agentUserId}`
      )
      const quantity = response.data.reduce(
        (acc, mailbox) => acc + mailbox.count,
        0
      )
      context.commit('SET_TOTAL_UNREAD_TICKETS', quantity)
      if (reset)
        await response.data.forEach((mailbox) => {
          context.commit('SET_NOTIFICATIONS_MAILBOX', {
            idMailbox: mailbox.mailboxId,
            quantity: 0,
          })
        })
      // recorre el array de mailboxes y setea el contador a cada uno
      await response.data.forEach((mailbox) => {
        context.commit('SET_NOTIFICATIONS_MAILBOX', {
          idMailbox: mailbox.mailboxId,
          quantity: mailbox.count,
        })
      })

      if (router.currentRoute.meta.is_workspace)
        context.dispatch('saveEventTicket') // Envía los ticket sin leer de manera correcta
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtener tickets de la base de datos
   * @async
   * @param {*} context
   * @param {String} status [active, ended] Estado del ticket: activo o finalizado
   */
  async listStatusTickets(context, status) {
    try {
      if (!['active', 'ended'].includes(status)) throw 'status invalid'
      let box = {}
      if (status === 'active')
        box = context.getters.company.settings.mailboxes.find(
          (box) => box.default
        )
      else
        box = context.getters.company.settings.mailboxes.find((box) => box.end)
      context.dispatch('searchTickets', {
        idMailbox: box.idMailbox,
        seek: '',
        status,
        page: 1,
        order: 'new',
        line: 'all',
        channel: 'all',
        tag: 'all',
        read: 'all',
      })
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Buscar tickets de la base de datos
   * @param {vuex}    context
   * @param {Object}  args
   * @param {String}  args.idMailbox Id del buzón a paginar
   * @param {String}  args.seek Numero de telefono del cliente, nombres o numero ticket
   * @param {String}  args.status Estado del ticket: [active, ended]
   * @param {Number}  args.skip Saltos para la paginación
   */
  async searchTickets(
    context,
    {
      idMailbox,
      seek,
      status,
      page = 1,
      order,
      tag,
      line,
      read,
      channel,
      channelCompanyId = 'all',
    }
  ) {
    try {
      if (!idMailbox || !['active', 'ended'].includes(status) || page < 1)
        throw 'args invalid'
      if (page === 1) context.commit('SET_TICKETS', { status, tickets: [] })

      let response = null
      if (router.currentRoute.meta.is_monitor) {
        response = await context.dispatch('listTicketsAgent', {
          agentUserId: context.getters.profileRemote.userId,
          idMailbox,
          seek,
          status,
          page,
          order,
          tag,
          line,
          read,
          channel,
          channelCompanyId,
        })
      } else if (router.currentRoute.meta.is_workspace) {
        response = await axios.post(`${vari.UHR}/agent/tickets/search`, {
          idMailbox,
          search: seek,
          status,
          page,
          order,
          tag,
          line,
          read,
          channel,
          channelCompanyId,
        })
      }
      if (!response) return []
      const dataResponse = response.data.data

      const tickets = dataResponse.reverse()
      tickets.forEach((ticket) => {
        ticket.pagination_messages = { page: 1 }
        ticket.messages = [] // es necesario definir mensajes en ticket para la reactividad
        ticket.abstract = ticket.lastMessage
      })
      await context.commit('ADD_TICKETS', { status, tickets })

      // elimina data de la respuesta para setear la paginacion
      delete response.data.data

      // solo si existen datos en la respuesta, seteará la paginacion
      !!dataResponse.length &&
        context.commit('SET_PAGINATION_TICKETS', response.data)

      if (status === 'ended')
        context.commit(
          'UPDATE_MAILBOXNAMES_IN_TICKETS_ENDED',
          context.getters.mailboxes
        )
      else
        context.commit(
          'UPDATE_MAILBOXNAMES_IN_TICKETS',
          context.getters.mailboxes
        )
    } catch (error) {
      console.error(error)
    }
  },
  /**
   *
   * @param {vuex} context
   * @param {Object} args
   * @param {String} args.ticketId
   * @param {Boolean} args.playSound - reproducir sonido de nuevo mensaje
   */
  async getTicket(context, { ticketId, playSound = true }) {
    try {
      let agentUserId = ''
      let prefixRole = null
      if (router.currentRoute.meta.is_monitor) {
        prefixRole = 'admin'
        agentUserId = context.getters.profileRemote.userId
      } else {
        prefixRole = 'agent'
        agentUserId = context.getters.profile.userId
      }
      const response = await axios.get(
        `${vari.UHR}/${prefixRole}/ticket/${ticketId}?agentUserId=${agentUserId}`
      )
      context.commit('ADD_TICKET', response.data)
      /** Comprobar si el ticket actual se encuentra en la lista de tickets NO leidas */
      const mailbox = context.getters.company.settings.mailboxes.find(
        (box) => box.idMailbox === response.data.mailboxId
      )
      if (playSound) context.dispatch('playSound', 'message')

      context.dispatch('addTicketUnread', {
        idTicket: response.data._id,
        mailboxId: mailbox.idMailbox,
        quantity: response.data.pendings || 0,
      })
      return { success: true, data: response.data }
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },
  /** Limpia el ticket seleccionado y emite el evento
   * @param {Boolean} redirect - si despues de limpiarse redirige a workspace
   */
  async cleanTicketSelected(context, redirect) {
    if (redirect === undefined) redirect = true
    try {
      context.commit('SET_SELECTED_TICKET', null)
      context.commit('SET_OLD_TICKETS', [])
      context.commit('SET_LAST_EVENT', null)
      context.commit('SET_CLIENT', null)
      context.commit('SET_OLD_TICKET_SELECTED', null)
      context.commit('SET_MULTIMEDIAS', [])
      context.commit('SET_IS_LOADING_OLD_TICKETS', false)
      // redirige a la vista de workspace
      if (redirect) router.push({ name: 'workspace' })
      context.commit('SET_IS_LOADING_SELECTED_TICKET', false)
    } catch (error) {
      console.error('[ticketModule][cleanTicketSelected]', error)
    }
  },
  /** Limpia los tickets seleccionados y emite el evento */
  async cleanTicketsSelected(context) {
    try {
      context.commit('SET_TICKETSID_SELECTED', [])
      context.commit('SET_LAST_EVENT', null)
      // context.dispatch('saveEventTicket')
    } catch (error) {
      console.error('[ticketModule][cleanTicketsSelected]', error)
    }
  },
  /**
   * Guarda el ticket ingresado como un ticket seleccioando de manera global
   * @param {*}       context
   * @param {Object}  ticket Objeto del ticket seleccionado, para deseleccionar enviar: null
   */
  async selectTicket(context, ticket) {
    try {
      /** Limpieza del ticket anterior */
      context.commit('SET_MULTIMEDIAS', [])
      context.commit('SET_OLD_TICKETS', [])
      context.commit('SET_OLD_TICKET_SELECTED', null)
      /** Asignación de datos del ticket actual */
      context.commit('SET_SELECTED_TICKET', ticket)
      if (ticket)
        context.dispatch('paginateMessages', {
          target: 'inbox',
        })

      /** No se setea como otro canal cuando esta en llamada, POR PRIORIDAD */
      if (context.getters.lastEvent !== 'calling') {
        if (ticket) context.commit('SET_LAST_EVENT', ticket.channel.type)
        else context.commit('SET_LAST_EVENT', null)
      }

      if (router.currentRoute.meta.is_workspace) {
        if (ticket)
          await context.dispatch('clearPendings', {
            ticketId: ticket._id,
            pendings: true,
          })

        await context.dispatch('saveEventTicket')
      }
      if (!ticket) return
      await context.dispatch('getClientById', {
        clientId: ticket.client.clientId,
        channel: ticket.channel.type,
      })
      if (['whatsapp'].includes(ticket.channel.type))
        context.commit('SET_CLIENT_PHONE', ticket.channel.phoneFrom)
    } catch (error) {
      console.error('[ticketModule][selectTicket]', error)
    }
  },
  /**
   * Quitar notificaciones al chat y actualizar notificaciones globales
   * @param {*}         context Objeto de Vuex
   * @param {Object}    args
   * @param {String}    args.ticketId Id del ticket
   * @param {Boolean}   args.pendings Hay o no pendientes sin leer
   */
  async clearPendings(context, { ticketId, pendings }) {
    try {
      if (pendings) {
        /** Comprobar si el ticket actual se encuentra en la lista de tickets NO leidas */
        if (router.currentRoute.meta.is_monitor) {
          const ticketRemote = context.getters.tickets.find(
            (ticket) => ticket._id === ticketId
          )
          if (!ticketRemote) return

          if (ticketRemote.pendings > 0)
            context.commit('UPDATE_TOTAL_UNREAD_TICKETS') // Actualiza el contador del total de tickets no leidos
          // Esta mutación debe ejecutarse despues de actualiza el contador
          context.commit('CLEAR_PENDINGS', ticketId)

          // se obtiene el mailbox del ticket remoto
          // para verificar que la cantidad de tickets no sea igual a pendings
          const company = context.getters.company
          const mailboxRemote = company?.settings.mailboxes.find(
            (mailbox) => mailbox.idMailbox === ticketRemote.mailboxId
          )
          // validacion para que no se descuente doble vez en un mismo buzon
          const isEqualOrLess =
            mailboxRemote && mailboxRemote.notifications <= pendings
          if (isEqualOrLess) return

          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: ticketRemote.mailboxId,
            quantity: -1,
          })
        } else {
          const response = await axios.put(
            `${vari.UHR}/room/no-pendings/${ticketId}`
          ) // Limpia los no leidos del ticket
          if (response.data.result === 'updated') {
            // En caso se haya actualizado correctamente, se limpia tambien el front
            await new Promise((resolve) => {
              return setTimeout(() => {
                const ticket = context.getters.tickets.find(
                  (ticket) => ticket._id === ticketId
                )
                const mailboxId = ticket?.mailboxId
                if (!mailboxId) return resolve('success')
                if (ticket.pendings > 0) {
                  context.commit('SUM_NOTIFICATIONS_MAILBOX', {
                    idMailbox: mailboxId,
                    quantity: -1,
                  })
                  context.commit('UPDATE_TOTAL_UNREAD_TICKETS') // Actualiza el contador del total de tickets no leidos
                }
                // Esta mutación debe ejecutarse despues de actualiza el contador
                context.commit('CLEAR_PENDINGS', ticketId)
                const payload = {
                  userId: context.getters.profile.userId,
                  mailboxId: mailboxId,
                  pendings:
                    context.getters.company?.settings.mailboxes.find(
                      (box) => box.idMailbox === mailboxId
                    ).notifications | 0,
                }
                this._vm.$socket.emit('server:chat:mailbox:pendings', payload) // Emite los no leídos del buzón
                axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
                return resolve('success')
              }, delayMiliseconds)
            })
          }
        }
      }
    } catch (error) {
      console.error('[ticketModule][clearPendings]', error)
    }
  },
  /**
   * Agrega el id de un ticket a la lista de ticketss no leidos e incrementa el contador
   * @param {*}       context
   * @param {Object}  args
   * @param {String}  args.idTicket Id del ticket
   * @param {String}  args.mailboxId Id del buzon - Opcional
   * @param {Number}  args.quantity Cantidad de pendientes sin leer de un ticket - Opcional
   */
  async addTicketUnread(
    context,
    { idTicket, mailboxId, quantity = 1, sync = true }
  ) {
    try {
      if (!quantity || context.getters.ticketsIdUnread.includes(idTicket))
        return false

      context.commit('ADD_TICKET_ID_UNREAD', idTicket)
      context.commit('SET_UNREAD_IN_TICKET', { idTicket, quantity })
      if (quantity === 1) {
        context.commit('UPDATE_TOTAL_UNREAD_TICKETS', 1)
        if (mailboxId) {
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: mailboxId,
            quantity: 1,
          })
        } else {
          const defaultMailbox =
            context.getters.company.settings.mailboxes.find(
              (box) => box.default
            )
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: defaultMailbox.idMailbox,
            quantity: 1,
          })
        }
      }
      // Emitir evento
      if (!router.currentRoute.meta.is_monitor) {
        const ticket = context.getters.tickets.find(
          (ticket) => ticket._id === idTicket
        )
        if (!ticket) return
        const ticketsPending = context.getters.total_unread_tickets
        const idCompany = context.getters.profile.company.companyId
        const idArea = ticket.company.lineId
        const userId = context.getters.profile.userId
        window.socket = this._vm.$socket

        this._vm.$socket.emit('server:area:gross:mode:agent', {
          idCompany,
          idArea,
          userId,
          ticketsPending,
          idTicket,
          mailboxId,
        })
        if (sync)
          setTimeout(() => {
            // Espera a q se ejecute el action: updateAsUnread
            axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
          }, 1500)
      }
      //
      return true
    } catch (error) {
      console.error('[ticketModule][addTicketUnread]', error)
      return false
    }
  },
  /**
   * Obtener tickets antiguos de un cliente
   * @param {vuex}    context
   * @param {Object}  args
   * @param {Number}  args.skip
   */
  async listOldTickets(context, { skip }) {
    try {
      const clientId = context.getters.client._id
      if (!clientId) return
      const status = 'ended'
      const response = await axios.get(
        `${vari.UHR}/agent/tickets/${status}/${clientId}/${skip}`
      )
      const tickets = response.data

      /** Busca entre los mensajes para completar el abstract de cada ticket */
      for (let ticket of tickets) {
        /** Se le agrega el campo del nombre del buzón para no perder reactividad */
        ticket.mailboxName = null
        ticket.pagination_messages = { page: 1 }
        ticket.messages = []
        /** Setea el ultimo mensaje al abstract de un ticket */
        ticket.abstract = ticket.lastMessage
      }
      if (!skip) context.commit('SET_OLD_TICKETS', tickets)
      else context.commit('ADD_TICKETS', { tickets, status: 'old' })
    } catch (error) {
      console.error('[ticketModule][listTickets]', error)
    }
  },
  /**
   * Setea el objeto del ticket antiguo del cliente como seleccionado
   * @param {*} context
   * @param {Object} oldTicket Objeto del ticket antiguo
   */
  async selectOldTicket(context, oldTicket) {
    try {
      if (!oldTicket.messages) oldTicket.mesages = []
      context.commit('SET_OLD_TICKET_SELECTED', oldTicket)
    } catch (error) {
      console.error('[ticketModule][selectOldTicket]', error)
    }
  },
  /**
   * Quita todos los tickets de una cola y sincroniza los numeros de los buzones
   * @param {vuex}    context
   * @param {String}  lineId Id de la Cola
   */
  async cleanTicketsLine(context, lineId) {
    try {
      const { ticketsId } = filters.filterTicketsByUserId(
        context.getters.tickets,
        context.getters.profile.userId
      )
      const ticketsIdUnread = ticketsId

      for (let ticket of context.getters.tickets) {
        if (ticket.company.lineId === lineId && ticket.pendings > 0) {
          // si está incluido entre los tickets no leidos del usuario de la sesion
          if (ticketsIdUnread.includes(ticket._id))
            context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: ticket.mailboxId,
            quantity: -1,
          })
        }
      }
      context.commit('QUIT_TICKETS_IN_LINE', lineId)
    } catch (error) {
      console.error('[ticketModule][cleanTicketsLine]', error)
    }
  },
  async cleanTicketsLineChannel(context, { lineId, channel }) {
    try {
      const { ticketsId } = filters.filterTicketsUnreadByUserId(
        context.getters.tickets,
        context.getters.profile.userId
      )
      const ticketsIdUnread = ticketsId

      for (let ticket of context.getters.tickets) {
        if (
          ticket.company.lineId === lineId &&
          ticket.pendings > 0 &&
          ticket.channel.type == channel
        ) {
          if (ticketsIdUnread.includes(ticket._id)) {
            context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
            context.commit('SUM_NOTIFICATIONS_MAILBOX', {
              idMailbox: ticket.mailboxId,
              quantity: -1,
            })
          }
        }
      }
      context.commit('QUIT_TICKETS_IN_LINE_CHANNEL', {
        lineId: lineId,
        channel: channel,
      })
      if (await compare.isAllowedFor(context, ['agent'])) {
        const ticketsPending = context.getters.total_unread_tickets
        const idCompany = context.getters.profile.company.companyId
        const userId = context.getters.profile.userId
        window.socket = this._vm.$socket

        this._vm.$socket.emit('server:area:gross:mode:agent', {
          idCompany,
          userId,
          ticketsPending,
          id: '1',
        })
        axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
      }
    } catch (error) {
      console.error('[ticketModule][cleanTicketsLine]', error)
    }
  },
  /**
   * Obtiene datos simple de usuarios específicos
   * @param {vuex}      context
   * @param {Object}    args
   * @param {String[]}  args.usersId Id de usuarios a traer datos
   * @param {String[]}  [args.extraFields=[]] Datos extras a traer de los usuarios
   * @param {Boolean}   [args.mutate=false] guardar los datos obtenidos en el Store
   * @returns {Object[]}
   */
  async getSimplyAgents(
    context,
    { usersId, mutate = false, extraFields = [] }
  ) {
    try {
      const url = `${vari.UHR}/agent/simply`
      const response = await axios.post(url, { usersId, extraFields })
      const agents = response.data
      if (mutate) context.commit('SET_AGENTS', agents)
      return agents
    } catch (error) {
      console.error('error', error)
    }
  },
  /**
   * Obtiene los estados de los mensajes para parcharlos
   * @param {*} context
   * @param {*} ticketId
   * @returns
   */
  async patchStatusMessagesInTicket(context, ticketId) {
    try {
      if (!ticketId) throw new Error("'ticketId' required")
      const response = await axios.get(
        `${vari.UHR}/chat/patch/status/${ticketId}`
      )
      if (!response.data.patchStatuses)
        return console.error("attrib 'patchStatuses' not found")
      for (const statusMessage of response.data.patchStatuses) {
        context.commit('SET_MESSAGE_STATUS', {
          idTicket: ticketId,
          apiMessageId: statusMessage.apiMessageId,
          status: statusMessage.status,
          failureDetails: statusMessage?.failureDetails,
        })
      }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtiene el total de tickets asignados del agente
   * @param {*} context
   */
  async getTotalAssignedTickets(context) {
    try {
      let agentUserId = context.getters.profile.userId
      if (router.currentRoute.meta.is_monitor) {
        if (!context.getters.profileRemote) return
        agentUserId = context.getters.profileRemote.userId
      }

      const response = await axios.get(
        `${vari.UHR}/agent/get-total-tickets-by-agent/${agentUserId}`
      )
      if (response.data.success) {
        context.commit(
          'SET_TOTAL_ASSIGNED_TICKETS',
          response.data.result.ticketsAssigned
        )
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export default actions
