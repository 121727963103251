<template>
  <div class="form-edit-template">
    <div class="form-edit-template-content">
      <div id="form-edit-template-master-container" class="master-container">
        <div
          id="form-edit-template-container"
          class="form-edit-container scrollbar_basic"
          @scroll="
            setShadows(
              'form-edit-template-master-container',
              'form-edit-template-container',
              'form-edit-template-content'
            )
          "
        >
          <div id="form-edit-template-content" class="form-edit-content">
            <a-form layout="vertical" :form="form">
              <a-form-item label="Selecciona colas">
                <a-select
                  mode="multiple"
                  placeholder="Seleccione las colas"
                  style="width: 100%"
                  class="select-multiple"
                  v-model="templateLines"
                  @change="handleAssignLines"
                  option-filter-prop="children"
                >
                  <a-select-option
                    v-for="line in listLines"
                    :key="line.line"
                    :title="line.name"
                  >
                    {{ line.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Selecciona agentes">
                <a-select
                  mode="multiple"
                  placeholder="Seleccione los agentes"
                  style="width: 100%"
                  class="select-multiple"
                  v-model="templateUsers"
                  @change="handleAssignAgents"
                  option-filter-prop="children"
                >
                  <a-select-option
                    v-for="account in listAccounts"
                    :key="account.user"
                    :title="account.name"
                  >
                    {{ account.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button style="min-width: 90px" @click="handleCloseModal"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        @click="handleSubmitAssign"
        style="margin-left: 8px; min-width: 90px"
        :loading="loadBtnAssign"
        >Asignar</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import transformWordGender from '@/app/shared/mixins/transform'

export default {
  name: 'FormAssignTemplate',
  props: {
    template: {
      required: true,
    },
  },
  data: () => ({
    originalLines: [],
    originalUsers: [],
    newLines: [],
    newUsers: [],
    removedLines: [],
    removedUsers: [],
    templateLines: [],
    templateUsers: [],
    loadBtnAssign: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-assign-template' })
  },
  mounted() {
    this.setValue()
  },
  watch: {
    /**Se ejecuta cada vez que template cambia */
    template() {
      this.setValue()
    },
  },
  mixins: [transformWordGender],
  computed: {
    ...mapGetters(['lines', 'agentsAccounts', 'profile']),

    /**
     * @return {Object[]} listLines - lista de colas
     * @return {String} listLines[].user - id de cola
     * @return {String} listLines[].name - nombre de cola
     */
    listLines() {
      return this.lines.map((line) => ({ line: line._id, name: line.name }))
    },
    /**
     * @return {Object[]} listAccounts - lista de cuentas de usuarios
     * @return {String} listAccounts[].user - id de usuario
     * @return {String} listAccounts[].name - nombre de usuario
     */
    listAccounts() {
      return this.agentsAccounts.map((account) => ({
        user: account.userId,
        name: account.names + ' ' + account.surnames,
      }))
    },
    /**
     * @return {String[]} - id de agentes asignados por plantilla
     */
    agentsPerTemplate() {
      const result = this.template.users.map((user) => user.user)
      return result
    },
    /**
     * @return {String[]} - id de colas asignadas por plantilla
     */
    linesPerTemplate() {
      const result = this.template.lines.map((obj) => obj.line)
      return result
    },
  },
  methods: {
    ...mapActions(['updateTemplate']),
    setValue() {
      setTimeout(() => {
        this.templateUsers = this.agentsPerTemplate
        this.templateLines = this.linesPerTemplate
        this.originalUsers = this.templateUsers
        this.originalLines = this.templateLines
      }, 300)
    },
    handleSubmitAssign() {
      this.form.validateFieldsAndScroll(async (err) => {
        if (!err) {
          const values = this.form.getFieldsValue()
          values.lines = this.templateLines
          values.users = this.templateUsers
          if (this.removedUsers.length || this.removedLines.length)
            this.handleModalConfirmation(values)
          else {
            this.loadBtnAssign = true
            await this.callUpdateTemplate(values)
            this.loadBtnAssign = false
          }
        }
      })
    },
    async callUpdateTemplate(values) {
      values.linesRemoved = this.removedLines || []
      values.usersRemoved = this.removedUsers || []
      values.users = this.newUsers || []
      values.lines = this.newLines || []
      // Chequear si se manda el lang y el category
      const response = await this.updateTemplate({
        templateId: this.template._id,
        template: values,
      })
      if (response.success) {
        this.$message.success({
          content: `Plantilla editada`,
          duration: 2,
        })
        this.templateLines = []
        this.templateUsers = []
        this.originalLines = []
        this.originalUsers = []
        this.newLines = []
        this.newUsers = []
        this.$emit('onSubmit')
      } else {
        this.$message.error({
          content:
            response.details || `Ocurrió un error al editar la plantilla`,
          duration: 2,
        })
      }
    },
    handleModalConfirmation(values) {
      const self = this
      const wordSure = this.profile.gender
        ? this.transformWordGender('seguro', this.profile.gender)
        : 'segur@'
      setTimeout(() => {
        const containListLines = document.getElementById('contain-list-lines')
        const arrLines = this.removedLines
        if (!arrLines.length) containListLines.innerHTML = ''
        else {
          let html = `<div><h5 class="heading-h7" style="color: #262A31">Colas</h5>`
          arrLines.forEach((elem) => {
            html += `<p style="margin-bottom: 6px">${
              this.lines.find((line) => line._id == elem).name
            }</p>`
          })
          html += `</div>`
          containListLines.innerHTML = html
        }
      }, 300)
      setTimeout(() => {
        const containListAgents = document.getElementById('contain-list-agents')
        const arrAgents = this.removedUsers
        if (!arrAgents.length) containListAgents.innerHTML = ''
        else {
          let html = `<div><h5 class="heading-h7" style="color: #262A31">Agentes</h5>`
          arrAgents.forEach((elem) => {
            const agent = this.agentsAccounts.find(
              (agent) => agent.userId == elem
            )
            html += `<p style="margin-bottom: 6px">${
              agent.names + ' ' + agent.surnames
            }</p>`
          })
          html += `</div>`
          containListAgents.innerHTML = html
        }
      }, 300)

      this.$confirm({
        title: `¿Estás ${wordSure} de aplicar estos cambios en  esta plantilla?`,
        content: (
          <div>
            <p id="text-warning-desasigned">
              Recuerda que los agentes asignados a las colas que seleccionaste
              podrán usar esta plantilla. <br />
              También, los agentes que seleccionaste podrán usarla en cualquier
              cola.
            </p>
            <div id="contain-list-lines">
              <div style="text-align: center">
                <a-spin />
              </div>
            </div>
            <div id="contain-list-agents"></div>
          </div>
        ),
        okText: 'Guardar',
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          self.loadBtnAssign = true
          await self.callUpdateTemplate(values)
          self.loadBtnAssign = false
        },
        class: 'test',
      })
    },
    /**
     * Escucha la asignacion o desasignacion de agentes
     * @param {Array} values
     * @param {String} values[] - id de agente
     */
    handleAssignAgents(values) {
      const newUsers = values.filter((user) => {
        return !this.originalUsers.includes(user)
      })
      this.newUsers = newUsers
      const removed = this.originalUsers.filter((user) => {
        return !values.includes(user)
      })
      this.removedUsers = removed
    },
    /**
     * Escucha la asignacion o desasignacion de colas
     * @param {Array} values
     * @param {String} values[] - id de cola
     */
    handleAssignLines(values) {
      const newLines = values.filter((line) => {
        return !this.originalLines.includes(line)
      })
      this.newLines = newLines
      const removed = this.originalLines.filter((line) => {
        return !values.includes(line)
      })
      this.removedLines = removed
    },
    // setea sombras
    setShadows(idMaster, idContainer, idContent) {
      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
    // emite para cerrar un modal
    handleCloseModal() {
      this.templateLines = []
      this.templateUsers = []
      this.originalLines = []
      this.originalUsers = []
      this.newLines = []
      this.newUsers = []
      this.removedUsers = []
      this.removedLines = []
      this.$emit('closeModal')
    },
    resetForm() {
      this.form.resetFields()
      this.templateLines = []
      this.templateUsers = []
      this.originalLines = []
      this.originalUsers = []
      this.newLines = []
      this.newUsers = []
      this.removedUsers = []
      this.removedLines = []
    },
  },
}
</script>

<style lang="sass" scoped>
.form-edit-template
  &-content
  .footer
    padding: 12px
    border-top: 1px solid #E8E8E8
    display: flex
    flex-direction: row
    justify-content: flex-end

.master-container
  position: relative
  padding-top: 2px
  padding-bottom: 2px
  height: 500px
  .form-edit-container
    overflow-y: auto
    height: 100%
    .form-edit-content
      position: relative
      padding: 20px 20px 0 20px
      width: 100%
.shadow-top
  &::before
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    top: 0
.shadow-bottom
  &::after
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    bottom: 0
</style>

<style lang="sass">
.select-multiple
  .ant-select-selection
    max-height: 86px
    overflow-y: auto
    &::-webkit-scrollbar
      width: 10px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
