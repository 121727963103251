import axios from 'axios'
import router from '@/router'
import { message } from 'ant-design-vue'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import jwt_decode from 'jwt-decode'
import modals from '@/app/commons/utils/modals'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key_restmain = process.env.VUE_APP_CRYPT_KEY_RESTMAIN
const simpleCrypto = new SimpleCrypto(crypt_key_restmain)

const actions = {
  /**
   * Eliminar token
   * @param {*} context
   */
  async revokeToken(context) {
    try {
      localStorage.removeItem('access_token')
      context.commit('SET_TOKEN', null)
    } catch (error) {
      console.error('[authModule][revokeToken]', error)
    }
  },

  /**
   * Guarda el token en Storage
   * @param {*} context
   * @param {String} token Access Token
   */
  async saveToken(context, token) {
    try {
      localStorage.setItem('access_token', token)
      context.commit('SET_TOKEN', token)
    } catch (error) {
      console.error(error)
    }
  },

  /**  Obtiene el token del Storage */
  async getToken(context) {
    try {
      const token = localStorage.getItem('access_token')
      context.commit('SET_TOKEN', token)
      context.commit('SET_LOGGEDIN', true)
    } catch (error) {
      console.error('[authModule][saveToken]', error)
    }
  },

  /** Guarda la sesión */
  saveSession(context, token) {
    try {
      axios.patch(
        `${vari.UHR}/session/save`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, //the token is a variable which holds the token
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
  },

  /**
   * Guarda el tipo del usuario actual
   * @param {*} context
   * @param {String} typeUser Tipo de usuario
   */
  async saveTypeUser(context, typeUser) {
    try {
      if (!context) throw null
      localStorage.setItem('type_user', typeUser)
    } catch (error) {
      console.error('[authModule][saveTypeUser]', error)
    }
  },

  /** Elimina el tipo de usuario del Storage */
  async revokeTypeUser() {
    try {
      localStorage.removeItem('type_user')
    } catch (error) {
      console.error('[authModule][revokeTypeUser]', error)
    }
  },

  /** Eliminar el estado del usaurio logueado */
  async revokeStatusUser() {
    try {
      localStorage.removeItem('status')
    } catch (error) {
      console.error('[authModule][revokeStatusUser]', error)
    }
  },

  /**
   * Guarda el estado del usuario en Storage, en la base y lo emite
   * @param {vuex}    context
   * @param {Object}  args Objeto para el estado
   * @param {Boolean} [args.persistence=true] Guardar el estado de manera persistente - Opcional, por defecto: Sï
   * @param {String}  [args.status='connected'] Nuevo estado del usuario - Opcional, por defecto: conectado
   * @param {Date}    [args.startedIn=new Date()] Fecha del inicio del estado - Opcional, por defecto ahora
   * @param {String} [args.cleanTicketSelected]
   */
  async saveStatusUser(
    context,
    {
      persistence = true,
      status = 'connected',
      startedIn = new Date(),
      cleanTicketSelected = false,
    }
  ) {
    try {
      let response = { data: {} }
      if (persistence === true) {
        response = await axios.put(`${vari.UHR}/user/status`, {
          status,
          startedIn,
        })
        const stringifyStatus = JSON.stringify({ status, startedIn })
        localStorage.setItem('status', stringifyStatus)
        context.commit('SET_STATUS_USER', status)
        context.commit('SET_CLIENT', null)
        if (context.getters.profile) {
          // si esta en workspace y tiene un ticket seleccionado
          // limpiará el ticket y redirigirá
          // a la vista por  defecto de workspace
          if (
            cleanTicketSelected &&
            router.currentRoute.meta.is_workspace &&
            router.currentRoute.name === 'drawer-ticket'
          )
            context.dispatch('cleanTicketSelected')
          // de lo contrario solo limpiara el ticket
          else context.dispatch('cleanTicketSelected', false)
          const states = context.getters.profile_states
          if (states[status].allow_navigated) {
            let menuItem = { route: router.history.current.name }
            context.dispatch('selectMenu', menuItem)
          }
        }
      }

      /** Se emite el nuevo estado */
      const statusObj = {
        status: status,
        startedInMenu_at: startedIn,
      }
      context.dispatch('emitSocketUserStatus', statusObj)

      return response.data
    } catch (error) {
      console.error('[authModule][saveStatusUser]', error)
    }
  },

  /**  Obtiene el estado del usuario del Storage */
  async getStatusUser(context) {
    try {
      const stringifyStatus = localStorage.getItem('status')
      if (!stringifyStatus) return context.dispatch('saveStatusUser', {})

      const statusObj = JSON.parse(stringifyStatus)
      if (!statusObj.startedIn) {
        context.dispatch('saveStatusUser', {})
      } else {
        context.commit('SET_STATUS_USER', statusObj.status)
      }
    } catch (error) {
      console.error('[authModule][getStatusUser]', error)
    }
  },

  /**
   * Validar Inicio Sesion
   * @param {*}       context
   * @param {Object}  credentials Credenciales de acceso
   * @param {String}  credentials.email Nombre de usuario
   * @param {String}  credentials.password Contraseña
   * @param {Boolean} credentials.once Primer intento de la recursividad
   */
  async signin(context, { email, password, once = true, user, socialLogin }) {
    try {
      const response = await axios.post(`${vari.UHR}/login`, {
        email,
        password: simpleCrypto.encrypt(password),
        user,
        socialLogin,
      })
      const success = response.data.success

      if (success) {
        const token = response.data.token
        if (response.data.result === 'new' || !once) {
          //Cerrar modal
          context.commit('SET_MODAL_SESSIONS', {
            statusModal: false,
            typeModal: 'pending',
          })
          //Mostrar modal
          context.commit('SET_MODAL_SESSIONS', {
            statusModal: true,
            typeModal: 'success',
          })

          setTimeout(() => {
            //Cerrar modal
            context.commit('SET_MODAL_SESSIONS', {
              statusModal: false,
              typeModal: '',
            })

            context.dispatch('saveToken', token) // Guarda el token el localStorage
            context.commit('SET_LOGGEDIN', success)
            context.dispatch('setMenuCollapse', false) // Contrae el sidebar
            router.push({ name: 'redirect', query: { al: true } }) // al: abreviación de 'after login'

            context.dispatch('saveSession', token)
          }, 2500)
        } else if (response.data.result === 'session') {
          const decoded = jwt_decode(response.data.token)
          // Valida la doble sesion
          await context.dispatch('validDobleSession', {
            email,
            password,
            userId: decoded.uid,
            user,
            socialLogin,
          })
        }
      }

      return response.data // Retorna si tuvo exito o no
    } catch (error) {
      return { success: false, result: 'error' }
    }
  },

  /**
   * Valida la doble sesion
   * @param {vuex} context
   * @param {Object} credentials Credenciales de acceso
   * @param {String} credentials.email Correo de quien se está logueando
   * @param {String} credentials.userId userId del usuario quien se está logueando
   */
  async validDobleSession(context, credentials) {
    try {
      await context.dispatch('joinHoldConnection', credentials)
      const userId = credentials.userId
      context.dispatch('emitValidateSession', userId)
      context.dispatch('listenRejectSession')
      context.dispatch('listenAcceptSession')

      setTimeout(() => {
        //Cerrar modal
        context.commit('SET_MODAL_SESSIONS', {
          statusModal: false,
          typeModal: 'pending',
        })

        //Mostrar modal
        context.commit('SET_MODAL_SESSIONS', {
          statusModal: true,
          typeModal: 'success',
        })
        credentials.once = false
        context.dispatch('signin', credentials)

        this._vm.$socket.removeListener('client:accept:session') // Desconexión Subscripción Canal de Sesion para aceptar
        this._vm.$socket.removeListener('client:reject:session') // Desconexión Subscripción Canal de Sesion para rechazar
      }, 12500)

      return { success: true, result: 'success' }
    } catch (error) {
      console.error('[authModule][validDobleSession]', error)
      return { success: false, result: 'error' }
    }
  },

  /**
   * Cerrar Sesion y eliminar token
   * @param {vuex}    context
   * @param {Boolean} remote Es logout remoto?
   */
  async logout(context) {
    try {
      if (!context.state.loggedIn) return
      const token = localStorage.getItem('access_token')
      // remueve el service worker activo
      context.dispatch('removeServiceWorker')

      await context.dispatch('updateUserStatus', {
        status: 'disconnected',
        force: true,
      })

      await axios.delete(`${vari.UHR}/session/remove`) // Elimina la sesión activa para tomarlo como desconectado
      await axios.post(`${vari.UHR}/logout`, { token: token }) // Desloguea al usuario
      await context.dispatch('cleanData')
      // context.dispatch('validateSignin')
      location.assign('/login')

      // if (await compare.isAllowedFor(context, ['supervisor', 'admin'])) {
      //   axios.post(
      //     `${vari.UHM}/auth/disconnect?token=${format.fixedEncodeURIComp(
      //       token
      //     )}`
      //   ) // Elimina la sesion del masivo
      // }
    } catch (error) {
      console.error('[authModule][logout]', error)
    }
  },

  /** Limpia los datos locales y conexion al servidor */
  async cleanData(context) {
    await context.dispatch('revokeToken')
    localStorage.removeItem('type_user')
    localStorage.removeItem('status')
    delete axios.defaults.headers.common['Authorization'] // Elimina el token de axios
    await context.dispatch('closeSocket')
    context.dispatch('cleanAllModules')
    await context.dispatch('revokeTypeUser')
    await context.dispatch('revokeMenuCollapse')
  },

  /**
   *  Validar la sesion actual y redirigir
   * @param {vuex}    context
   * @param {Object}  args
   * @param {Boolean} [args.afterLogin=false] Viene por el haberse logueado?
   * @param {Boolean} [args.reconnection=false] Viene por la reconexión del socket?
   */
  async validateSignin(context, { afterLogin = false, reconnection = false }) {
    try {
      await context.dispatch('getToken')
      if (!context.state.loggedIn) {
        throw 'no logged'
      }

      // Autorización para conectarse al servidor
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${context.state.token}`
      await context.dispatch('getProfile')

      // Rutas de error exoneradas para continuar
      if (router.currentRoute.name === 'err') {
        if (['429', '500', 'connection'].includes(router.currentRoute.query.st))
          return context.commit('SET_MENU_COLLAPSE', true)
      }

      await context.dispatch('listMenu')
      const deprecatedPwd = context.getters.profile.deprecatedPwd

      // redirige a la ruta de password si deprecated es true
      if (deprecatedPwd && deprecatedPwd.deprecated) {
        context.commit('SET_MENU_COLLAPSE', true)
        const uniqueRouteAllow = { route: 'password' }
        router.push({ name: uniqueRouteAllow.route })
        context.dispatch('joinHoldConnection', {
          userId: context.getters.profile.userId,
        })
        context.dispatch('selectMenu', { route: uniqueRouteAllow.route })
        context.dispatch('updateUserStatus', {
          status: 'connected',
          force: true,
        })
        // context.dispatch('joinAgentThread') // Realiza la conexion con los hilos del socket
        return
      }

      const typeUser = context.getters.profile.type
      // Solo redirige a la ruta por defecto si viene de redirect
      if (
        router.history.current.name === 'redirect' ||
        router.history.current.name === 'password'
      ) {
        let menuItem = context.getters.menuItems.find((item) => item.default)
        const active_breakpoint = context.getters.active_breakpoint
        // si esta en mobile deberia redirigir a una vista por defecto
        switch (typeUser) {
          case 'master_agent':
          case 'securitec':
          case 'supervisor':
          case 'admin':
            {
              const defaultRouteForMobile = { name: 'unsupport' }
              router.push(
                active_breakpoint.is_mobile
                  ? defaultRouteForMobile
                  : { name: menuItem.route }
              )
            }
            break
          case 'supervisor_agent':
          case 'admin_agent':
          case 'agent': {
            const defaultRouteForMobile = { name: 'workspace' }
            router.push(
              active_breakpoint.is_mobile
                ? defaultRouteForMobile
                : { name: menuItem.route }
            )

            break
          }
          default: {
            //Se le agrego un validador de error para el cambio de rutas
            //El error se produce porque la accion se esta realiza en la vista del componente "Login" y se intenta hacer router.push para ir de nuevo a esa misma vista
            router.push({ name: 'login' }).catch((error) => {
              if (
                error.name !== 'NavigationDuplicated' &&
                !error.message.includes(
                  'Avoided redundant navigation to current location'
                )
              ) {
                console.error(error)
              }
            })
            return
          }
        }
      }
      // si esta haciendo una reconexion y esta en workspace
      if (reconnection && router.history.current.meta.is_workspace) {
        const numberTicketSelected = router.history.current.params?.ticketId
        const statusTicketSelected = router.history.current.query?.status
        if (numberTicketSelected) {
          setTimeout(() => {
            let tickets =
              statusTicketSelected === 'active'
                ? context.getters.tickets
                : statusTicketSelected === 'ended'
                ? context.getters.ticketsEnded
                : []
            const ticketSelected = tickets.find(
              (tk) => tk.ticket == numberTicketSelected // Solo doble igual
            )
            context.dispatch('selectTicket', ticketSelected)
          }, 2500)
        }
      }
      // si esta haciendo una reconexion y esta en supervision
      else if (reconnection && router.history.current.name === 'supervision') {
        context.dispatch('listSupervision', {
          self: {
            sectionSupervision: {
              list: { search: null, line: 'allLines', state: 'general' },
            },
          },
        })
      }
      await context.dispatch('joinThread') // Realiza la conexion con los hilos del socket
      context
        .dispatch('getInitialData', { typeUser }) // Obtiene todos los datos correspondientes al tipo de usuario
        .then(() => {
          if (afterLogin) {
            context.dispatch('setAnalytics', { eventName: 'login' })
          }
        })

      if (afterLogin) context.dispatch('setInitialUserStatus')
      else context.dispatch('updateUserStatus', { status: 'connected' })

      // Obtiene y guarda la ruta actual
      if (
        await compare.isAllowedFor(context, [
          'agent',
          'admin_agent',
          'supervisor_agent',
          'master_agent',
        ])
      ) {
        const profile = context.getters.profile
        let routeSelected = router.currentRoute

        if (routeSelected.meta.is_workspace) routeSelected = 'workspace'
        else if (routeSelected.meta.is_monitor) routeSelected = 'supervision'
        else routeSelected = router.currentRoute.name
        if (
          !compare.isAllowedFor(context, ['admin', 'supervisor'], {
            literal: true,
          })
        ) {
          context.dispatch('saveMenuSelected', {
            routeSelected: routeSelected,
            profile,
          })
        }
      }
    } catch (error) {
      // console.error("[authModule][validateSignin]", error)
      router.push({ name: 'login' })
    }
  },

  async setInitialUserStatus(context) {
    try {
      const status = 'connected'
      // setTimeout(async () => { // Retraso mientras se espera que se conecten los demas sockets
      context.dispatch('updateUserStatus', { status, force: true })
      // },2000)
    } catch (error) {
      console.error('[authModule][setInitialUserStatus]', error)
    }
  },

  /**
   *  Guarda la nueva contraseña
   * @param {*} context
   * @param {String} newPassword la nueva contraseña
   */
  async saveNewPassword(context, newPassword) {
    try {
      let response = null
      const url = `${vari.UHR}/auth/password`
      const params = { password: newPassword }
      response = await axios.put(url, params)
      return response
    } catch (error) {
      return error.response
    }
  },

  /**
   * Inicia sesión después de registrado o recuperar la contraseña
   * @param {*} context
   * @param {String} token
   */
  async initSession(context, token) {
    context.dispatch('saveToken', token) // Guarda el token el localStorage
    context.commit('SET_LOGGEDIN', true)
    context.dispatch('setMenuCollapse', true) // Contrae el sidebar
    router.push({ name: 'redirect', query: { al: true } }) // al: abreviación de 'after login'
  },

  /**
   * Intercepta las peticiones hechas con axios
   */
  async interceptor(context) {
    try {
      axios.interceptors.response.use(
        (response) => {
          return Promise.resolve(response)
        },
        (error) => {
          if (error.response) {
            const { status } = error.response
            if (status === 402) {
              context.dispatch('openUpgradeModals')
            } else if (status === 403 || status === 412) {
              //TODO: Borrar esto despues
              if (status === 403) {
                context.dispatch('patchDisconnection')
                return
              }
              router.push({ name: 'redirect' }).catch((error) => {
                // Se agrega esto para no caer en el bucle de redirect, cuando
                // una de las primeras acciones de validateSignin cae en alguno de los estados
                // si se captura un error en la ruta, entonces deslogueara al usuario
                console.error(`[interceptor, ${status}]`, error)
                context.dispatch('cleanData')
                location.assign('/login')
              })
            } else if (status === 413) {
              message.error('El archivo es demasiado pesado para adjuntarlo')
            } else if (status === 401) {
              if (error.response.data.result === 'session-expired') {
                localStorage.setItem(
                  'session_expired',
                  new Date().toISOString()
                )
                context.commit('SET_MODAL_SESSION_EXPIRED', {
                  statusModal: true,
                })
              }
              context.dispatch('cleanData')
              location.assign('/login')
            } else if (status === 429) {
              location.assign(`/err?st=${status}`)
            } else if (status === 500) {
              message.error(
                'Ocurrió un error interno, inténtelo nuevamente más tarde'
              )
            } else if (status === 409) {
              if (
                error.response.data.result === 'overflow' &&
                router.currentRoute.meta.is_workspace
              )
                context.commit(
                  'SET_BASIC_MODAL',
                  modals['WITHOUT_BALANCE_WHATSAPP']
                )
            }
          }
          if (error.message === 'Network Error') {
            message.error('No se pudo establecer la conexión')
            // if (router.currentRoute.name !== 'err') {
            //   let st = 'connection'
            //   location.assign(`/err?st=${st}`)
            // }
          }
          return Promise.reject(error)
        }
      )
    } catch (error) {
      console.error('[authModule][nose]', error)
    }
  },

  async openUpgradeModals(context) {
    switch (router.currentRoute.name) {
      case 'accounts':
        return // Evita abrir el modal de recomendación en esta ruta
      default:
        return context.commit('SET_MODAL_UPGRADE', { visible: true })
    }
  },

  /**
   * Enviar token de restauración de contraseña
   */
  async sendMailToRestore(context, email) {
    try {
      const response = await axios.put(`${vari.UHR}/account/password`, {
        email: email,
      })
      switch (response.data.result) {
        case 'User not found':
          response.data.details =
            'Este correo no existe, por favor verifica que esté correcto.'
          break
      }

      return response.data
    } catch (err) {
      return { success: false }
    }
  },
  /**
   * Obtener información de cambio de contraseña
   */
  async getRestoreInfo(context, token) {
    try {
      const url = `${vari.UHR}/account/password?token=${token}`
      const response = await axios.get(url)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
  /**
   *  Guarda la contraseña restaurada
   * @param {*} context
   * @param {String} password la nueva contraseña
   */
  async saveRestorePassword(context, { password, email }) {
    try {
      let response = null
      const url = `${vari.UHR}/account/password`
      const token = router.history.current.query.token

      const params = { password, email, token }
      response = await axios.post(url, params)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  /** TODO: Parche para cuando esté como 'diconnected' aunque esté conectado */
  async patchDisconnection(context) {
    try {
      const statusLocal = localStorage.getItem('status')
      const statusObj = JSON.parse(statusLocal)
      if (!statusObj || statusObj.status === 'disconnected') {
        const profile = context.getters.profile
        if (profile) context.dispatch('saveStatusUser', { status: 'connected' })
      }
    } catch (error) {
      console.error('patchDisconnection', error)
    }
  },
}

export default actions
