const initialState = () => ({
  lastEvent: null,
  supervision: [], // Lista de agentes en supervisión
  supervisionSimply: null, // Objeto simplificado de la supèrvision de un agente
  ticketIdRemoteSelected: null,
  mailboxIdRemoteSelected: null,
  ticketsPending: null,
  typingRemote: false,
  reloadTableSupervision: 0,
})

export default initialState
