<template>
  <div class="list-message">
    <button-menu-message-list
      v-if="list"
      :text="list.globalOptionTitle"
      @click.native="handleShowModal"
    />
    <a-modal v-bind="modal" @cancel="handleCloseModal">
      <custom-section-scroll
        :master-styles="{ height: '30em' }"
        :content-styles="{ padding: '24px 18px 24px 24px' }"
      >
        <div
          v-for="(section, index) in list.lists"
          :key="`section_${index}`"
          class="list-message__section"
        >
          <h5 class="regular-14 list-message__section-title">
            {{ section.title }}
          </h5>
          <item-message-list
            v-for="(item, index) in section.options"
            :key="`item_${index}`"
            :title="item.title"
            :description="item.description"
          />
        </div>
      </custom-section-scroll>
    </a-modal>
  </div>
</template>

<script>
import ButtonMenuMessageList from './ButtonMenuMessageList'
import ItemMessageList from './ItemMessageList'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ListMessage',
  components: {
    ButtonMenuMessageList,
    ItemMessageList,
    CustomSectionScroll,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    modal: {
      visible: false,
      title: 'Menú',
      centered: true,
      bodyStyle: {
        padding: '0px',
      },
      footer: null,
      width: '30em',
    },
  }),
  methods: {
    /**
     * Muestra el modal
     */
    handleShowModal() {
      this.modal.visible = true
    },
    /**
     * Cierra el modal
     */
    handleCloseModal() {
      this.modal.visible = false
    },
  },
}
</script>

<style lang="sass" scoped>
.list-message
  max-width: 100%
  margin-top: 12px
  &__section
    margin-bottom: 12px
  &__section-title
    color: $polar-green_7
</style>
