import Supervision from '@/app/supervision/views/Supervision'
import Tickets from '@/app/tickets/views/Tickets'
import Ticket from '@/app/tickets/views/Ticket'
import DrawerTicket from '@/app/tickets/components/organisms/DrawerTicket'
import SectionDefaultTickets from '@/app/tickets/components/molecules/SectionDefaultTickets.vue'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/supervision',
    name: 'supervision',
    component: Supervision,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'master_agent'],
      title: `Supervisión | ${VUE_APP_NAME}`,
    },
  },
  {
    path: '/workspacemonitor/:agentUserId',
    name: 'workspace-remote',
    component: Tickets,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'master_agent'],
      title: `Monitor Workspace | ${VUE_APP_NAME}`,
      is_monitor: true,
    },
    children: [
      {
        path: ':ticketId',
        name: 'ticket-remote',
        component: Ticket,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'supervisor', 'master_agent'],
          title: `Monitor Workspace | ${VUE_APP_NAME}`,
          is_monitor: true,
        },
        children: [
          {
            path: 'drawer/:drawerId',
            name: 'drawer-remote-ticket',
            component: DrawerTicket,
            meta: {
              requiresAuth: true,
              requiresType: ['admin', 'supervisor', 'master_agent'],
              title: `Monitor Workspace | ${VUE_APP_NAME}`,
              is_monitor: true,
            },
          },
        ],
        redirect: {
          name: 'default-remote',
        },
      },
      {
        path: 'default',
        name: 'default-remote',
        component: SectionDefaultTickets,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'supervisor', 'master_agent'],
          title: `Monitor Workspace | ${VUE_APP_NAME}`,
          is_monitor: true,
        },
      },
    ],
    redirect: {
      name: 'default-remote',
    },
  },
]
