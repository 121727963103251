<template>
  <div class="card" @click="$emit('onSelect')">
    <div class="card__body">
      <h6 class="heading-h7">{{ title }}</h6>
      <p class="body-1 mrg-bottom-0">{{ description }}</p>
    </div>
    <a
      v-if="src"
      @click="
        setAnalytics({
          eventName: 'onboarding_review_info',
          extraData: { link: src },
        })
      "
      :href="src"
      target="_blank"
      rel="noopener noreferrer"
      >Revisar info</a
    >
    <a v-else @click="$emit('onSelect')">Revisar info</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CardTuto',
  props: {
    title: { type: String, default: 'Ticker' },
    description: {
      type: String,
      default:
        'Exercitation et ad sit do mollit tempor excepteur sit ex exercitation cupidatat occaecat laboris exercitation.',
    },
    src: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions(['setAnalytics']),
  },
}
</script>

<style lang="sass" scoped>
.card
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 8px
  border: 1px solid $gray_5
  padding: 12px 20px
  color: $gray_dark_800
  &:hover
    border: 1px solid $blue_6
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)
    cursor: pointer
</style>
