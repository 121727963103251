<template>
  <div class="table-menu-billing-container">
    <div class="table-menu-billing-content">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        class="table-contact__table"
        :scroll="{ y: 'calc(100vh - 484px)' }"
        @change="handlePagination"
        size="middle"
        rowKey="_id"
      >
        <span slot="state" slot-scope="state" class="state">
          <a-tag
            :color="
              ['Aprobado', 'Reposición'].includes(state.value) ? 'green' : 'red'
            "
          >
            {{ state.value }}
          </a-tag>
          <a-popover
            v-if="state.detail && state.detail.error"
            title="Detalle de pago rechazado"
            :overlayStyle="popStyles"
          >
            <template slot="content">
              <p class="mrg-bottom-0">
                Este pago no se efectúo porque
                {{ state.detail.error.toLowerCase() }}. Si tienes dudas
                <router-link :to="{ name: 'support' }" target="_blank"
                  >comunícate con nosotros</router-link
                >.
              </p>
            </template>
            <a-icon type="info-circle" />
          </a-popover>
        </span>
        <span slot="action" slot-scope="record">
          <a
            @click="handleAction('download', record)"
            :disabled="record.state.status === 'rejected'"
            >Descargar</a
          >
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
moment.locale('es')
const INFINITY = { value: 'Infinity', title: 'Ilimitado' }

export default {
  name: 'TableMenuBilling',
  props: {
    filters: { type: Object, required: true },
  },
  data: () => ({
    // Columnas de la tabla
    columns: [
      {
        title: 'Fecha',
        key: 'date',
        dataIndex: 'date',
      },
      {
        title: 'Nro de pago',
        dataIndex: 'paycode',
        key: 'paycode',
      },
      {
        title: 'Método',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Estado',
        key: 'state',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    popStyles: {
      width: '16.5em',
    },
  }),
  computed: {
    ...mapGetters([
      'payments',
      'payments_pagination',
      'payments_statuses',
      'fetchPayments',
    ]),
    /**
     * @returns {Object}
     */
    pagination() {
      if (!this.payments_pagination) return {}

      let pg = {
        hideOnSinglePage: true,
        defaultPageSize: 7,
        pageSize: 7,
        size: 'default',
        total: this.payments_pagination.total || 0,
        current: parseInt(this.payments_pagination.page),
      }
      return pg
    },
    /**
     * @returns {Object[]}
     */
    data() {
      if (!this.payments) return []
      return this.payments.map((pay) => {
        const status = this.payments_statuses[pay.status]
        pay.cardToUse = pay.cardToUse || pay.card
        const dataTable = {
          key: pay._id,
          _id: pay._id,
          date: moment(pay.created_at).format('lll'),
          paycode: `#${pay.paycode}`,
          method: `${pay.cardToUse.type} *${pay.cardToUse.lastDigits?.substring(
            3,
            6
          )}`,
          quantity: this.transformTotal(pay.total),
          state: {
            value: status.value,
            detail: { error: pay.rejectionDetail },
            status: status.key,
          },
        }
        return dataTable
      })
    },
  },
  methods: {
    ...mapMutations(['SET_PAYMENTS_PAGINATION']),
    ...mapActions(['exportPayment']),

    handlePagination(pagination) {
      this.$emit('onChangePagination', { page: pagination.current })
      this.SET_PAYMENTS_PAGINATION({
        lastPage: Math.round(pagination.total / pagination.defaultPageSize),
        page: pagination.current,
        perPage: pagination.defaultPageSize,
        total: pagination.total,
      })
    },
    /**
     * Click en algún botón de acciones
     * @param {String} type [details]
     * @param {Object} record
     */
    async handleAction(type, record) {
      const statusNotAllowed = ['rejected', 'annulled']
      if (statusNotAllowed.includes(record.status)) return
      if (type === 'download') {
        await this.exportPayment(record._id)
      }
    },
    /**
     * Transforma el total a infinito
     * @param {String} text
     * @return {String} total
     */
    transformTotal(text) {
      const total =
        text === INFINITY.value ? INFINITY.title : `$${text.toFixed(2)}`
      return total
    },
  },
}
</script>

<style lang="sass" scoped>
.table-menu-billing
  &-container
  &-content
</style>
<style lang="sass">
.table-menu-billing
  .ant-table-body, .ant-table-body-inner
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
