<template>
  <modal-type
    :visible="visible"
    :title="title"
    :description="description"
    type="delete"
    :on-ok="handleDeleteCompany"
    :ok-disabled="disabledOk"
    @onClose="handleCloseModal"
    message-success="Se eliminó exitosamente"
  >
    <a-form layout="vertical" :form="form">
      <!-- Subcuentas -->
      <template v-if="isCurrentCompany">
        <p>
          Te encuentras en la subcuenta a eliminar. Selecciona la subcuenta a la
          cual te moverás.
        </p>
        <a-form-item label="Seleccionar subcuenta">
          <a-select
            v-decorator="[
              'company',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                ],
              },
            ]"
            placeholder="Selecciona aquí"
            @change="hasErrorInForm"
          >
            <a-select-option
              v-for="simpleCompany in simple_companies"
              :key="simpleCompany._id"
            >
              {{ simpleCompany.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <!--contraseña-->
      <p>
        Ingresa tu contraseña con la que iniciaste sesión para validar el
        eliminado.
      </p>
      <a-form-item label="Contraseña">
        <a-input-password
          v-decorator="[
            'password',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @input="hasErrorInForm"
        />
      </a-form-item>
    </a-form>
  </modal-type>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalType from '@/app/shared/components/modals/ModalType'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalDeleteCompany',
  mixins: [transformMixin],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: true,
    },
    isCurrentCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ModalType,
  },
  data: () => ({
    description:
      '<p>Recuerda que <strong> perderás toda la información </strong> relacionada a dicha cuenta (chatbots, colas, usuarios, canales, clientes e integraciones que hayas incluido).</p>',
    disabledOk: true,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_delete_company' })
  },
  computed: {
    ...mapGetters(['profile', 'simple_companies']),

    /**
     * @return {String}
     */
    title() {
      if (!this.profile) return ''
      return `¿Estás  ${this.transformWordGender(
        'seguro',
        this.profile.gender
      )} de eliminar la subcuenta "${this.dataSource.name}"?`
    },
  },
  methods: {
    ...mapActions(['deleteCompany']),

    /**
     * Cambia de empresa
     */
    async handleDeleteCompany() {
      // validar nuevamente
      const hasErrors = this.hasErrorInForm()
      if (hasErrors) return
      // obtiene los valores del formulario
      const values = this.form.getFieldsValue()

      const response = await this.deleteCompany({
        companyId: this.dataSource._id,
        password: values.password,
      })
      return response
    },
    /**
     * Verifica si hay errores en el formulario
     */
    hasErrorInForm() {
      if (!this.form) this.disabledOk = true
      const fieldsError = this.form.getFieldsError()
      this.disabledOk = Object.keys(fieldsError).some(
        (field) => fieldsError[field]
      )
      return this.disabledOk
    },
    /**
     * Cierra el modal
     */
    handleCloseModal() {
      this.$emit('onClose')
      this.form.resetFields()
    },
  },
}
</script>

<style scoped></style>
