<script>
import { PolarArea } from 'vue-chartjs'
export default {
  name: 'PolarChart',
  extends: PolarArea,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'left',
      },
      animation: {
        animateRotate: false,
        animateScale: true,
      },
    },
  }),
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options)
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
