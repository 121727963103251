<template>
  <div class="img-modal">
    <img
      v-if="file.message.imageUrl"
      :src="file.message.imageUrl"
      class="img"
      height="80px"
      width="100%"
      alt=" No disponible"
    />
    <video
      v-if="file.message.videoUrl"
      :src="file.message.videoUrl"
      class="img"
      height="80px"
      width="100%"
      alt=" No disponible"
    />
    <div class="overlay" @click="handleShowModal">
      <a-icon type="eye" class="icon" style="font-size: 24px" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImgModal',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleShowModal() {
      this.$emit('onShowModal')
    },
  },
}
</script>

<style lang="sass" scoped>
.img-modal
  // margin: 0 0.2em 0.3em 0
  position: relative
  // max-width: 80px
  // flex-grow: 1
  .img
    object-fit: cover
    background-color: $gray_5
  .overlay
    cursor: pointer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    transition: .3s ease
    display: flex
    align-items: center
    justify-content: center
    .icon
      color: transparent
  &:hover
    .overlay
      background: $gray_dark_900
      opacity: 0.7
      .icon
        color: $white_000
.image-modal
  max-height: 100%
  max-width: 100%
  background: $gray_5
</style>
