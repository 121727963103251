<template>
  <div class="button-menu" type="button" role="button">
    <a-icon type="bars" class="mrg-right-4" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ButtonMenuList',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.button-menu
  background-color: $white_000
  color: $gray_7
  border: 1px solid $gray_5
  height: 24px
  border-radius: 6px
  max-width: 100%
  min-width: 24em
  padding: 1px 8px
  cursor: pointer
  font-weight: 400
  transition: all .3s cubic-bezier(.645,.045,.355,1)
  user-select: none
  touch-action: manipulation
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))
  text-align: center
  &:hover
    color: $blue_6
</style>
