<template>
  <div class="section-queue">
    <div class="section-queue-card">
      <h6 class="heading-h6 title">Configurar cola de espera</h6>
      <a-row>
        <p class="body-2 description" style="margin-bottom: 20px">
          Decide la cantidad de tickets que están en espera y que se
          transferirán a tus agentes cuando se conecten. Así como el tiempo que
          esperará {{ app }} para transferir al resto de agentes cuando se
          conecten. Tenemos una configuración por defecto.
        </p>
      </a-row>
      <a-row>
        <a-table
          :columns="columns"
          :data-source="rows"
          :pagination="false"
          size="small"
          class="table"
        >
          <template
            v-for="col in ['quantity', 'time']"
            :slot="col"
            slot-scope="attr, record"
          >
            <div :key="col">
              <a-select
                v-if="record.editable"
                style="margin: -5px 0; width: 100%"
                :defaultValue="attr.value"
                @change="(val) => handleChange(val, record.key, col)"
              >
                <a-select-option
                  v-for="(configOtion, index) in configOtions.filter(
                    (c) => c.for === col
                  )"
                  :key="`config${index}`"
                  :value="configOtion.value"
                >
                  {{ configOtion.value }} {{ configOtion.label }}
                </a-select-option>
              </a-select>
              <template v-else> {{ attr.value }} {{ attr.label }} </template>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record)">Guardar</a>
                <a-divider type="vertical" />
                <a @click="cancel(record.key)">Cancelar</a>
              </span>
              <span v-else>
                <a :disabled="editingKey !== ''" @click="edit(record.key)"
                  >Editar</a
                >
              </span>
            </div>
          </template>
        </a-table>
      </a-row>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'CardQueue',
  data: () => ({
    rows: [],
    columns: [
      {
        title: 'Cantidad de tickets por repartir',
        key: 'quantity',
        dataIndex: 'quantity',
        // width: '25%',
        scopedSlots: { customRender: 'quantity' },
      },
      {
        title: 'Tiempo de repartición',
        key: 'time',
        dataIndex: 'time',
        // width: '15%',
        scopedSlots: { customRender: 'time' },
      },
      {
        title: 'Acción',
        key: 'action',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    editingKey: '',
    configOtions: [
      // Opciones de Configuración para las Colas
      { id: '1', for: 'time', value: 5, label: 'minutos' },
      { id: '2', for: 'time', value: 10, label: 'minutos' },
      { id: '3', for: 'time', value: 20, label: 'minutos' },
      { id: '4', for: 'time', value: 30, label: 'minutos' },
      { id: '5', for: 'quantity', value: 3, label: 'Tickets' },
      { id: '6', for: 'quantity', value: 5, label: 'Tickets' },
      { id: '7', for: 'quantity', value: 10, label: 'Tickets' },
      { id: '8', for: 'quantity', value: 20, label: 'Tickets' },
    ],
    app: process.env.VUE_APP_NAME,
  }),
  props: {
    queueConfig: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapActions(['updateQueue']),
    /** Carga las filas de la tabla */
    load() {
      this.rows = []
      let row = {
        key: '1',
        quantity: { value: this.queueConfig.quantity, label: 'Tickets' },
        time: { value: this.queueConfig.delay / (1000 * 60), label: 'minutos' }, // ms a seg
      }
      this.rows.push(row)
    },
    /** Guarda los cambios al escribir en un inout editable de una de las filas */
    handleChange(value, key, column) {
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      if (target) {
        target[column].value = value
        this.rows = newData
      }
    },
    /** Habilita una fila a un input editable */
    edit(key) {
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.rows = newData
      }
    },
    /** Guarda los cambios de una fila */
    async save(record) {
      console.log('save', record)
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)
      if (target) {
        delete target.editable
        this.rows = newData
        let queueConfig = {
          quantity: record.quantity.value,
          delay: record.time.value,
        }
        await console.log('save queueConfig', queueConfig) //GUARDAR CAMBIOS DE ACCION SIN AGENTES
        await this.updateQueue(queueConfig)
        this.$message.success({
          content: `La configuración se guardó correctamente`,
          key: 'updatable',
          duration: 2,
        })
      }
      this.editingKey = ''
    },
    /** Devuelve el input editable a una fila no editable */
    cancel(key) {
      console.log('cancel', key)
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      this.editingKey = ''
      if (target) {
        delete target.editable
        this.rows = newData
        let row = this.rows.find((r) => r.key === key)
        Object.assign(row.quantity, { value: this.queueConfig.quantity })
        Object.assign(row.time, {
          value: this.queueConfig.delay / (1000 * 60),
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
// $max-table: 637px

.section-queue
  padding: 12px 20px
  border: 1px solid $gray_5
  border-radius: 8px
  &-card
    .title
      text-align: left
      color: $gray_dark_800
    .description
      text-align: left
      color: $gray_light_900
</style>
