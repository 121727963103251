<template>
  <div class="section-board">
    <div class="section-board__header">
      <h1 class="heading-h6 section-board__title mrg-bottom-0">
        Configura tu chatbot 🤖
      </h1>
    </div>
    <custom-section-scroll :master-styles="{ height: 'calc(100vh - 180px)' }">
      <section
        class="section-board__body"
        :class="{ 'section-board__body--empty': treeNodes === undefined }"
      >
        <container-tree
          :chatbotId="$route.params.id"
          @onOpenDrawer="handleOpenDrawer"
        >
          <a-row
            slot="empty-extra"
            type="flex"
            justify="center"
            align="middle"
            class="section-board__container-cards"
          >
            <card-add-node
              v-for="cardNode in cardNodes"
              :key="cardNode.type"
              class="mrg-right-16"
              :node-type="cardNode.type"
              :name-add-function="cardNode.add_function"
              :node-description="cardNode.description"
            />
          </a-row>
        </container-tree>
      </section>
    </custom-section-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import ContainerTree from '@/app/chatbots/components/organisms/tree/ContainerTree'
import CardAddNode from '@/app/chatbots/components/organisms/cards/CardAddNode'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'SectionBoard',
  components: {
    CustomSectionScroll,
    ContainerTree,
    CardAddNode,
  },
  data: () => ({
    NODES,
  }),
  computed: {
    ...mapGetters(['nodes', 'treeNodes', 'chatbot']),

    cardNodes() {
      if (!this.chatbot) return []
      const arrNodes = Object.values(this.NODES)
      const supportedChannels = this.chatbot.supportedChannels

      return arrNodes.filter(
        (node) =>
          !node.support_channels ||
          node.support_channels.includes(supportedChannels)
      )
    },
  },
  methods: {
    handleOpenDrawer(settings) {
      this.$emit('onOpenDrawer', settings)
    },
  },
}
</script>

<style lang="sass" scoped>
.section-board
  height: 100%
  border: 1px solid $gray_dark_100
  width: 100%
  background-color: $white_000
  border-radius: 8px

  &__header
    padding: 12px 20px
    border-bottom: 1px solid $gray_dark_100
  &__title
    width: 100%
    text-align: left
  &__actions
    width: 100%
    display: flex
  &__action
    margin-right: 12px
  &__body
    padding: 12px 20px
    &--empty
      height: 500px
      @include flex(column, center, center)
  &__container-cards
    margin-bottom: 5em
</style>
