var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h5',{staticClass:"semibold-20 text--center mrg-bottom-20"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.channel)?_c('SocialIcon',{attrs:{"type":_vm.channel}}):_vm._e()],1),_c('a-carousel',{attrs:{"arrows":"","afterChange":_vm.handleChangeCurrent,"dots-class":"modal-fb__carousel__dots"}},[_c('div',{staticClass:"carousel__arrow",class:{
        'carousel__arrow-icon--disabled icon--disabled': _vm.current === 0,
      },staticStyle:{"left":"10px","z-index":"1"},attrs:{"slot":"prevArrow"},slot:"prevArrow"},[_c('a-icon',{staticClass:"carousel__arrow-icon",class:{
          'carousel__arrow-icon--disabled icon--disabled': _vm.current === 0,
        },attrs:{"type":"left-circle","theme":"filled"}})],1),_c('div',{ref:"fb_start_icon_next",staticClass:"carousel__arrow",class:{
        'carousel__arrow-icon--disabled icon--disabled': _vm.current === 2,
      },staticStyle:{"right":"10px","z-index":"1"},attrs:{"slot":"nextArrow"},slot:"nextArrow"},[_c('a-icon',{staticClass:"carousel__arrow-icon",class:{
          'carousel__arrow-icon--disabled icon--disabled': _vm.current === 2,
        },attrs:{"type":"right-circle","theme":"filled"}})],1),_c('div',{staticClass:"carousel__content"},[_c('div',{staticClass:"carousel__content__image"},[_c('img',{staticClass:"carousel__image",attrs:{"src":require("@/app/channels/assets/first-image-msg.png")}})]),_c('p',{staticClass:"regular-16 text--center mrg-top-16 carousel__text"},[_c('span',{staticClass:"semibold-16"},[_vm._v("¿Tu usuario no aparece aquí?")]),_c('br'),_vm._v(" Te ayudamos a conectar tu página en el siguiente paso. "),_c('br'),_c('a',{on:{"click":_vm.handleNextCarousel}},[_vm._v("Continuar con la información")])])]),_c('div',{staticClass:"carousel__content"},[_c('div',{staticClass:"carousel__content__image"},[_c('img',{staticClass:"carousel__image",attrs:{"src":require("@/app/channels/assets/second-image-msg.png")}})])]),_c('div',{staticClass:"carousel__content"},[_c('div',{staticClass:"carousel__content__image"},[_c('img',{staticClass:"carousel__image",attrs:{"src":require("@/app/channels/assets/third-image-msg.png")}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }