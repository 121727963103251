<template>
  <div class="container">
    <!--Cargando nodos del chatbot-->
    <div v-if="loading" class="container--loading">
      <a-spin />
      <p class="mrg-top-12">Cargando datos</p>
    </div>
    <!--Si ya termino de cargar-->
    <template v-else>
      <!--Si existe el arbol de nodos-->
      <tree
        v-if="treeNodes"
        :data-source="treeNodes"
        :key="count"
        @onOpenDrawer="handleOpenDrawer"
        :disabled-actions="disabledActions"
        @onPreviewAttachment="handlePreviewModal"
      />
      <!--Si no existe el arbol de nodos-->
      <div
        v-else
        class="container--loading display-flex direction-column align--center"
      >
        <slot name="empty-extra"></slot>
        <img
          src="@/app/shared/assets/whoooa/solid/chatbot-3.png"
          alt="Chatbot saludando"
        />
        <p class="mrg-top-12">Este chatbot aún no tiene un flujo</p>
      </div>
      <modal-preview-media
        :visible="modalPreviewMedia.visible"
        :title="modalPreviewMedia.title"
        :type="modalPreviewMedia.type"
        :url="modalPreviewMedia.url"
        @onClose="handleCloseModalPreview"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import Tree from '@/app/chatbots/components/organisms/tree/Tree'
import ModalPreviewMedia from '@/app/chatbots/components/organisms/modals/ModalPreviewMedia'

export default {
  name: 'ContainerTree',
  props: {
    chatbotId: { type: String, required: true },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tree,
    ModalPreviewMedia,
  },
  data: () => ({
    count: 0,
    loading: true,
    modalPreviewMedia: {
      visible: false,
      type: '',
      url: '',
      title: '',
    },
  }),
  mixins: [attemptMixin],
  mounted() {
    this.$nextTick(() => {
      // si el id del chatbot existe y si aun no hay nodos
      if (this.chatbotId && !this.nodes.length)
        this.loadInitialData(this.chatbotId)
      else this.loading = false
    })
  },
  beforeDestroy() {
    // antes de destruirse el componente resetea
    this.handleReset()
  },
  watch: {
    chatbotId(value) {
      // si el id del chatbot existe y si aun no hay nodos
      if (value && !this.nodes && !this.nodes.length)
        this.loadInitialData(this.value)
      else this.loading = false
    },
    nodes: {
      deep: true,
      handler: function () {
        this.$nextTick(() => this.count++)
      },
    },
  },
  computed: {
    ...mapGetters(['nodes', 'treeNodes', 'company']),
  },
  methods: {
    ...mapActions(['resetTreeNodes', 'getNodes', 'resetNodes']),

    /**
     * Carga los nodos de un chatbot sengun el id
     * @param {String} chatbotId
     */
    loadInitialData(chatbotId) {
      if (!chatbotId) return
      this.intervalPromiseAttempt(() => {
        if (!this.company) throw 'Not company found'
        this.loadNodes(chatbotId)
      })
    },
    /**
     * Carga los nodos de acuerdo al id de chatbot
     * @param {String} chatbotId
     */
    async loadNodes(chatbotId) {
      if (!chatbotId) return
      this.loading = true
      await this.getNodes(chatbotId)
      this.loading = false
    },
    /**
     * Emitir evento para abrir el drawer
     * @param {Object} settings
     * @param {Object} settings.drawer - para nodos principales: mensaje u opciones
     * @param {Object} settings.drawer.question
     * @param {String} settings.drawer.question.type
     * @param {String} settings.drawer.action.message
     * @param {String} settings.drawer.action.lineId
     * @param {Boolean} settings.drawer.action.withMessage
     * @param {Object} settings.action - para opciones
     * @param {String} settings.action.nodeType
     * @param {String} settings.action.type
     * @param {String} settings.action.message
     * @param {String} settings.action.lineId
     * @param {Boolean} settings.action.withMessage
     */
    handleOpenDrawer(settings) {
      this.$emit('onOpenDrawer', settings)
    },
    /**
     * Resetea los nodos y el arbol de nodos
     */
    handleReset() {
      this.resetTreeNodes()
      this.resetNodes()
    },
    /**
     * Previsualiza un archivo en el modal
     * @param {File} file
     */
    handlePreviewModal(file) {
      // si es de tipo documento
      if (file.type === 'document') return window.open(file.urlStorage)
      // si es de tipo video o imagen
      this.modalPreviewMedia.title = file.originalName
      this.modalPreviewMedia.type = file.type
      this.modalPreviewMedia.url = file.urlStorage
      this.modalPreviewMedia.visible = true
    },
    /**
     * Cierra el modal de previsualizacion
     */
    handleCloseModalPreview() {
      this.modalPreviewMedia = {
        visible: false,
        type: '',
        url: '',
        title: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  width: 100%
  &--loading
    height: 100%
    color: $gray_6
    @include flex(column, center, center)
</style>
