<template>
  <base-custom-drawer title="Archivos">
    <custom-section-scroll
      :master-styles="{
        padding: '0px 20px 20px 20px',
        height: 'calc(100% - 58px)',
      }"
    >
      <ListItems id="list-content" />
    </custom-section-scroll>
  </base-custom-drawer>
</template>

<script>
import ListItems from '@/app/tickets/components/organisms/lists/ListItems.vue'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import BaseCustomDrawer from '@/app/tickets/components/organisms/sections/BaseCustomDrawer'
import { mapActions } from 'vuex'

export default {
  name: 'SectionTicketFiles',
  components: {
    CustomSectionScroll,
    ListItems,
    BaseCustomDrawer,
  },
  created() {
    this.listMultimedia()
  },
  methods: {
    ...mapActions(['listMultimedia']),
  },
}
</script>

<style scoped></style>
