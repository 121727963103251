import { render, staticRenderFns } from "./BasicFooter.vue?vue&type=template&id=5543e600&scoped=true&"
import script from "./BasicFooter.vue?vue&type=script&lang=js&"
export * from "./BasicFooter.vue?vue&type=script&lang=js&"
import style0 from "./BasicFooter.vue?vue&type=style&index=0&id=5543e600&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5543e600",
  null
  
)

export default component.exports