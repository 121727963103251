<template>
  <unsupport-template />
</template>

<script>
import UnsupportTemplate from '@/app/commons/components/templates/UnsupportTemplate'

export default {
  name: 'Unsupport',
  components: {
    UnsupportTemplate,
  },
}
</script>

<style lang="sass" scoped></style>
