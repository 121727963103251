var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"text--left",attrs:{"form":_vm.form,"layout":"vertical"}},[_c('router-link',{attrs:{"to":{ name: 'campaigns' }}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Regresar a campañas ")],1),_c('h4',{staticClass:"semibold-20 mrg-bottom-8 mrg-top-12"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"regular-14",class:{
      form__description: !_vm.error.visible,
      'mrg-bottom-0': _vm.error.visible,
    }},[_vm._v(" Completa todos los campos. Recuerda que la longitud máxima de un mensaje de texto depende de la codificación utilizada. Si tienes dudas sobre el funcionamiento del envío de SMS "),_c('anchor',{attrs:{"href":`${_vm.vari.WEB_DOC}/campanas/campana-masiva-whatsapp/`}},[_vm._v(" revisa nuestros docs")]),_vm._v(". ")],1),(_vm.error.visible)?_c('a-alert',{staticClass:"form__alert",attrs:{"type":"error","message":_vm.error.message,"show-icon":""}}):_vm._e(),(_vm.currentStep === 0)?[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),(!_vm.channelCompanyId)?_c('a-alert',{staticClass:"mrg-bottom-24",attrs:{"type":"warning","message":"No tienes un canal de SMS conectado","show-icon":""}}):_vm._e(),_c('a-row',[_c('a-form-item',{attrs:{"label":"Nombre de campaña"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.channelCompanyId}})],1)],1),_c('a-row',[_c('a-form-item',{attrs:{"label":"Mensaje"}},[_c('counter-text',{attrs:{"text":_vm.currentMessage,"maxLength":500}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'text',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'text',\n              {\n                rules: [\n                  { required: true, message: 'Por favor rellena el campo' },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 6, maxRows: 6 },"disabled":!_vm.channelCompanyId,"maxLength":500},on:{"change":_vm.handleChangeMessage}})],1)],1)],1),_c('calculator-sms',{staticClass:"form__calculator",attrs:{"text":_vm.currentMessage}})]:(_vm.currentStep === 1)?[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),_c('a-row',{staticClass:"mrg-bottom-8",attrs:{"type":"flex","align":"middle"}},[_c('p',{staticClass:"semibold-14 mrg-bottom-0 mrg-right-8 form__label--black"},[_vm._v(" Formato para campaña ")]),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_c('anchor',{attrs:{"href":`${_vm.vari.WEB_DOC}/campanas/plantilla-predefinida-whatsapp/`}},[_vm._v(" Click aquí")]),_vm._v(". si deseas más info de cómo completar la plantilla predefinida. ")],1),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"form__container-download"},[_c('a',{on:{"click":function($event){return _vm.handleGenerateAndDownloadFileCsv(false)}}},[_vm._v("Click aquí para descargar archivo .CSV")])]),_c('p',{staticClass:"semibold-14 mrg-bottom-8 form__label--black"},[_vm._v(" Sube tu formato con los datos completos ")]),_c('p',{staticClass:"mrg-bottom-8"},[_vm._v(" Tienes un envío "),_c('strong',[_vm._v(" máximo de 10 000 contactos ")]),_vm._v(" y recuerda que el tiempo de envío es de 5 segundos por contacto. ")]),_c('a-tooltip',{attrs:{"placement":"right"}},[(
          _vm.hasNewValues({
            valuesForm: _vm.form.getFieldsValue(),
            except: 'fileMessage',
          })
        )?_c('template',{slot:"title"},[_vm._v(" Completa todos los campos para poder subir tu archivo.")]):_vm._e(),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fileMessage',
            {
              valuePropName: 'fileList',
              getValueFromEvent: _vm.normFile,
              rules: [
                { required: true, message: 'Por favor sube el archivo' },
              ],
            },
          ]),expression:"[\n            'fileMessage',\n            {\n              valuePropName: 'fileList',\n              getValueFromEvent: normFile,\n              rules: [\n                { required: true, message: 'Por favor sube el archivo' },\n              ],\n            },\n          ]"}],attrs:{"name":"file","multiple":false,"customRequest":_vm.uploadFile,"file-list":_vm.fileList,"before-upload":_vm.beforeUpload}},[_c('p',[_c('a-icon',{staticClass:"form__icon-inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v("Solo se aceptan archivos .csv")])])],1)],2),_c('div',[_c('p',{staticClass:"semibold-14 mrg-bottom-8"},[_vm._v("Recuerda")]),_c('p',{staticClass:"info_i2"},[_vm._v(" Esta campaña solo podrá ser lanzada durante los próximos 7 días desde la fecha de su creación. Luego se detendrá forzosamente. ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }