import navModule from '@/app/commons/store/nav'
import socketModule from '@/app/commons/store/socket'
import globalModule from '@/app/commons/store/global'
import bannersModule from '@/app/commons/store/banners'

const modules = {
  navModule,
  ...socketModule.modules,
  globalModule,
  bannersModule,
}

export default {
  modules,
}
