<template>
  <div class="pop-over-event">
    <a-popover
      v-model="visible"
      trigger="click"
      placement="topLeft"
      :overlayStyle="style"
    >
      <!-- <a slot="content" @click="hide">Close</a> -->
      <template slot="content">
        <!-- Botones de acciones del evento seleccionado-->
        <div class="options">
          <!--boton de ir al chat-->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Ir al mensaje</span>
            </template>
            <a-icon
              type="message"
              style="margin-left: 12px; font-size: 16px"
              @click="handleChat"
            />
          </a-tooltip>
          <!--boton de editar evento-->
          <a-tooltip placement="bottom" v-if="!item.schedule.completed_at">
            <template slot="title">
              <span>Editar</span>
            </template>
            <a-icon
              type="edit"
              style="margin-left: 12px; font-size: 16px"
              @click="handleEdit"
            />
          </a-tooltip>
          <!--boton para resolver evento-->
          <a-tooltip placement="bottom" v-if="!item.schedule.completed_at">
            <template slot="title">
              <span>Resolver</span>
            </template>
            <a-icon
              type="check-circle"
              style="margin-left: 12px; font-size: 16px"
              @click="handleComplete"
            />
          </a-tooltip>
          <!--boton para eliminar evento-->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Eliminar</span>
            </template>
            <a-icon
              type="delete"
              style="margin-left: 12px; font-size: 16px"
              @click="handleDelete"
            />
          </a-tooltip>
          <!--boton para cerrar el popover-->
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>Cerrar</span>
            </template>
            <a-icon
              type="close"
              @click="hide"
              style="margin-left: 12px; font-size: 16px"
            />
          </a-tooltip>
        </div>
        <!--contenido de popover-->
        <div class="header">
          <a-badge :color="item.color" class="badge-status"></a-badge>
          <p class="title body-2">{{ item.schedule.title }}</p>
        </div>
        <div class="container scrollbar_basic linear-gradient">
          <div class="flex-row">
            <a-icon
              type="clock-circle"
              style="color: #262a31; font-size: 12px; margin-top: 4px"
            />
            <p class="body-1 mrg-left-8" style="color: #262a31">
              {{ firstMayDateText(item.schedule.scheduled_for) }}
            </p>
          </div>
          <div class="flex-row">
            <a-icon
              type="user"
              style="color: #262a31; font-size: 12px; margin-top: 4px"
            />
            <p class="body-1 mrg-left-8" style="color: #262a31">
              {{ item.schedule.clientNames }}
            </p>
          </div>
          <div class="flex-row" v-if="item.schedule.description">
            <a-icon
              type="align-left"
              style="color: #262a31; font-size: 12px; margin-top: 4px"
            />
            <p class="body-1 mrg-left-8" style="color: #262a31">
              {{ item.schedule.description }}
            </p>
          </div>
        </div>
      </template>
      <!--lista los eventos-->
      <li class="list-event">
        <a-badge
          :status="item.type"
          :text="item.schedule.title"
          :color="item.color"
          @click="handleSchedule(item)"
        />
        <!-- <span class="title-list">{{item.schedule.title}}</span> -->
        <!-- {{item.schedule.title}} -->
      </li>
    </a-popover>
  </div>
</template>

<script>
import formatMixin from '@/app/shared/mixins/format'

export default {
  name: 'PopOverEvent',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    visible: false,
    style: {
      width: '220px',
    },
  }),
  mixins: [formatMixin],
  methods: {
    hide() {
      this.visible = false
    },
    handleSchedule(item) {
      this.$emit('handleSchedule', item)
      this.hide()
    },
    handleChat() {
      this.$emit('handleChat')
      this.hide()
    },
    handleEdit() {
      this.$emit('handleEdit')
      this.hide()
    },
    handleComplete() {
      this.$emit('handleComplete')
      this.hide()
    },
    handleDelete() {
      this.$emit('handleDelete')
      this.hide()
    },
  },
}
</script>
<style lang="sass" scoped>
.flex-row
  display: flex
  flex-direction: row
.mrg-left-8
  margin-left: 8px

.pop-over-event
.options
  display: flex
  flex-direction: row
  justify-content: flex-end
.header
  display: flex
  flex-direction: row
  align-items: flex-start
  margin-top: 10px
  margin-bottom: 8px
  .title
    color: $gray_dark_900
    margin-bottom: 0
.container
  height: 110px
  overflow-y: auto
  .flex-row
    align-items: flex-start
    margin-bottom: 8px
    p
      margin-bottom: 0
.list-event
  // display: flex
  // flex-direction: row
  // align-items: center
  // .title-list
  span
    max-width: 80%
    display: -webkit-inline-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    word-break: break-all
</style>
<style lang="sass">
.badge-status
  .ant-badge-status-dot
    width: 12px
    height: 12px
</style>
