<template>
  <div class="instagram-message" :class="{ 'mrg-bottom-12': !date }">
    <!--mensaje recibido-->
    <div v-if="show" class="">
      <template v-if="loading">
        <a-skeleton
          :loading="loading"
          active
          :avatar="{ size: 32 }"
          :paragraph="{ rows: 1 }"
          class="skeleton received"
        >
        </a-skeleton>
      </template>
      <template v-else-if="!loading && message">
        <p class="instagram-title body-1">{{ title }}</p>
        <div class="instagram-media text--left">
          <div class="display-flex justify-content--start">
            <img
              v-if="message.imageUrl"
              :src="message.imageUrl"
              @click="showModal"
              class="image"
              alt=" Este contenido no está disponible"
              style="margin-bottom: 0px"
            />
            <!-- Video -->
            <video
              v-if="message.videoUrl"
              controls
              style="margin-bottom: 0px"
              class="border-radius-8 message__video"
              alt=" Este contenido no está disponible"
            >
              <source
                :src="message.videoUrl"
                :type="message.videoType"
                alt=" Este contenido no está disponible"
              />
            </video>
          </div>
          <p
            v-if="date"
            class="regular-12 instagram__date mrg-top-8 mrg-bottom-0"
          >
            {{ date }}
          </p>
        </div>
      </template>
    </div>

    <!-- Modal de Vista Maximizada -->
    <a-modal
      v-if="!loading && show"
      v-model="visible"
      :footer="null"
      :bodyStyle="bodyStyle"
      class="preview"
      centered
    >
      <picture>
        <img
          :src="message.imageUrl"
          alt=" Este contenido no está disponible"
          class="modal__picture"
        />
      </picture>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
moment.locale('es')

export default {
  name: 'instagram-messages',
  props: {
    message: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    date: {
      type: Date,
      required: false,
      default: null,
    },
  },
  data: () => ({
    moment: moment,
    visible: false,
    bodyStyle: {
      padding: '40px',
      height: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titles: {
      share: 'Te compartió una publicación',
      reply: 'Respondió a tu historia',
      story_mention: 'Te mencionó en su historia',
    },
  }),
  computed: {
    title() {
      return this.titles[this.message.type]
    },
  },
  methods: {
    /**
     * Mostrar modal de vista previa
     */
    showModal() {
      this.visible = true
    },
  },
}
</script>

<style lang="sass">
.instagram-media
  border-left: 1px solid #c4c4c4
  padding-left: 10px
.instagram-title
  margin-bottom: 4px
  text-align: initial
  color: $gray_8
.instagram__date
  color: $gray_dark_600
</style>
<style lang="sass">
.skeleton-container
  .skeleton
    background-color: $white_000
    display: flex
    align-items: flex-end
    .ant-skeleton-header
      // vertical-align: bottom
    .ant-skeleton-content
      background-color: $gray_2
      padding: 12px
      width: 80%
      .ant-skeleton-title
        margin-top: 0px !important
      .ant-skeleton-paragraph
        margin-top: 12px !important
        margin-bottom: 0px !important
  .received
    .ant-skeleton-header
      padding-right: 8px
    .ant-skeleton-content
      border-radius: 16px 16px 16px 0px
  .sent
    flex-direction: row-reverse
    .ant-skeleton-header
      padding-left: 8px
      padding-right: 0px
    .ant-skeleton-content
      border-radius: 16px 16px 0px 16px
.message__video
  width: auto
  height: 260px
  max-width: 100%
</style>
