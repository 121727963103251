import profileModule from './profileModule'
import adminModule from './adminModule'
import agentModule from './agentModule'
import companyModule from './companyModule'
import roleModule from './roleModule'
import accountModule from './accountModule'
import companyPricing from './companyPricingModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo User */
  resetMoUser(context) {
    context.commit('RESET_PROFILE_MODULE')
    context.commit('RESET_COMPANY_MODULE')
    context.commit('RESET_ADMIN_MODULE')
    context.commit('RESET_ROLE_MODULE')
    context.commit('RESET_AGENT_MODULE')
    context.commit('RESET_ACCOUNT_MODULE')
  },
}

const modules = {
  profileModule,
  adminModule,
  companyModule,
  roleModule,
  agentModule,
  accountModule,
  companyPricing,
}

export default {
  modules,
  actions,
}
