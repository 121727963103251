import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import persistent from '@/app/shared/utils/persistent'

const initialState = () => ({
  countries: [], //paises
})

const state = initialState

const getters = {
  countries: (state) => state.countries,
  countriesNotRepeatByName: (state) =>
    state.countries.reduce((acc, item) => {
      const exist = acc.find(
        (country) => country.nameEnglish === item.nameEnglish
      )
      if (!exist) {
        acc.push(item)
      }
      return acc
    }, []), // paises sin nombres repetidos
}

const mutations = {
  /**
   * Setea los paises
   * @param {*} state
   * @param {Array} countries
   */
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
}

const actions = {
  /**Obtiene los paises */
  async getCountries(context) {
    try {
      /**
       * Verifica sessionStorage antes de realizar una petición para traer los datos de los países
       */
      persistent.vloadData('countries', async (found, data) => {
        if (!found) {
          const url = `${vari.UHR}/countries`
          const response = await axios.get(url)
          context.commit('SET_COUNTRIES', response.data)
          persistent.saveData('countries', response.data)
        } else {
          context.commit('SET_COUNTRIES', data)
        }
      })
    } catch (error) {
      console.error('[countriesModule]', error)
      return error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
