var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template"},[_c('header-module',{attrs:{"title":"CAMPAÑAS","positionTooltip":"right","tipography":"heading-h5","link":`${_vm.vari.WEB_DOC}campanas/gestion-campanas/`,"textBeforeLink":"Crea y lanza campañas de envío masivo, como WhatsApp.","textAfterLink":"si deseas conocer más."}}),_c('table-filters',{attrs:{"columns":_vm.columns,"onOk":_vm.handleRedirectToAdd,"onMoreFilters":() => (_vm.filters.visible = !_vm.filters.visible),"okText":"Crear campaña"},on:{"onChangeCol":_vm.handleChangeColumn}},[_c('template',{slot:"filters"},[_c('a-input-search',{staticClass:"filters__item",attrs:{"placeholder":"Buscar por nombre de campaña"},on:{"search":_vm.handleFilterStrategies},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('a-select',{staticClass:"filters__item mrg-left-12",attrs:{"placeholder":"Selecciona un estado"},on:{"change":_vm.handleFilterStrategies},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}},[_c('a-select-option',{attrs:{"value":"all"}},[_vm._v(" Todos los estados ")]),_vm._l((_vm.statuses_strategies),function(value,key){return _c('a-select-option',{key:key},[_vm._v(" "+_vm._s(value.value)+" ")])})],2),_c('a-range-picker',{staticClass:"filters__item mrg-left-12",attrs:{"disabled-date":_vm.disabledDate,"show-time":{
          format: _vm.localePicker.lang.dateTimeFormat,
          defaultValue: [
            _vm.moment('00:00:00', 'HH:mm:ss'),
            _vm.moment('23:59:59', 'HH:mm:ss'),
          ],
        },"placeholder":['Fecha de inicio', 'Fecha de fin'],"format":_vm.localePicker.lang.dateTimeFormat,"locale":_vm.localePicker,"allowClear":false},on:{"ok":_vm.handleFilterStrategies},model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}})],1)],2),_c('table-strategies',{attrs:{"data-source":_vm.company_strategies,"columns":_vm.showColumns,"page":_vm.filters.page,"total":_vm.paginate_company_strategies.total,"loading":_vm.loadingTable},on:{"onShowDashboard":_vm.handleShowDashboard,"onPaginate":_vm.handleSetPaginate,"onShowPreview":_vm.handleShowPreview}}),_c('drawer-filter-strategies',{attrs:{"visible":_vm.filters.visible,"filters":{ ..._vm.filters }},on:{"onClose":_vm.handleCloseFilters,"onSetFilters":_vm.handleSetFilters}}),_c('modal-dashboard',{attrs:{"visible":_vm.modalDashboard.visible,"dataSource":_vm.selected_strategy,"wallet":_vm.walletPerStrategy},on:{"onClose":_vm.handleCloseDashboard}}),_c('modal-preview',{attrs:{"visible":_vm.modalPreview.visible,"dataSource":_vm.modalPreview.dataSource,"channel":_vm.modalPreview.channel},on:{"onClose":_vm.handleClosePreview}}),_c('modal-select-channel',{attrs:{"visible":_vm.modalSelectChannel.visible},on:{"onClose":_vm.handleCloseModalSelectChannel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }