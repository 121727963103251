const initialState = () => ({
  introductoryModal: {
    visible: false,
  },
})

const state = initialState

const getters = {
  introductoryModal: (state) => state.introductoryModal,
}

const mutations = {
  /**
   * Setea los paises
   * @param {*} state
   * @param {Array} introduction
   */
  SET_INTRODUCTORY_MODAL(state, introduction) {
    state.introductoryModal = introduction
  },
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
