<template>
  <div class="down-license">
    <div class="down-license-content">
      <a-modal
        width="440px"
        :visible="visible"
        :footer="footer"
        :maskClosable="true"
        closable
        centered
        class="down-license-content-modal"
        @cancel="hideModal"
      >
        <template v-if="['info', 'info-input', 'details-input'].includes(type)">
          <!-- Ícono -->
          <a-row class="icon">
            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M58.8879 30.3875C52.9629 17.9063 44.0066 11.625 32.0004 11.625C19.9879 11.625 11.0379 17.9062 5.11286 30.3937C4.87521 30.897 4.75195 31.4466 4.75195 32.0031C4.75195 32.5597 4.87521 33.1093 5.11286 33.6125C11.0379 46.0938 19.9941 52.375 32.0004 52.375C44.0129 52.375 52.9629 46.0938 58.8879 33.6063C59.3691 32.5938 59.3691 31.4188 58.8879 30.3875ZM32.0004 47.875C21.9191 47.875 14.5379 42.7625 9.33161 32C14.5379 21.2375 21.9191 16.125 32.0004 16.125C42.0816 16.125 49.4629 21.2375 54.6691 32C49.4691 42.7625 42.0879 47.875 32.0004 47.875ZM31.7504 21C25.6754 21 20.7504 25.925 20.7504 32C20.7504 38.075 25.6754 43 31.7504 43C37.8254 43 42.7504 38.075 42.7504 32C42.7504 25.925 37.8254 21 31.7504 21ZM31.7504 39C27.8816 39 24.7504 35.8687 24.7504 32C24.7504 28.1313 27.8816 25 31.7504 25C35.6191 25 38.7504 28.1313 38.7504 32C38.7504 35.8687 35.6191 39 31.7504 39Z"
                fill="#FF7061"
              />
            </svg>
          </a-row>
          <!-- Título -->
          <a-row>
            <h2>{{ title }}</h2>
          </a-row>
          <!-- Descripción -->
          <a-row>
            <p>
              <span>{{ description }}</span>
              <a
                v-if="type === 'details-input'"
                :href="detailsURL"
                target="_blank"
                rel="noopener noreferrer"
                >&nbsp;{{ detailsText }}</a
              >
            </p>
          </a-row>
          <!-- Campo de Validación -->
          <a-row>
            <div v-if="type.includes('input')" class="mini-form">
              <p>
                Escribe la palabra <span>“{{ keyword }}”</span>
              </p>
              <a-input v-model="input" placeholder="Escribe aqui..." />
            </div>
          </a-row>
          <!-- Botones -->
          <a-row>
            <div class="buttons">
              <a-row v-if="primaryButton">
                <a-button
                  :name="primaryButton"
                  role="button"
                  type="primary"
                  @click="handleOk"
                  :disabled="!buttonEnabled"
                  block
                  >{{ primaryButton }}</a-button
                >
              </a-row>
              <a-row v-if="secondaryButton">
                <a-button
                  :name="secondaryButton"
                  role="button"
                  class="btn-secondary"
                  @click="hideModal"
                  block
                  >{{ secondaryButton }}</a-button
                >
              </a-row>
            </div>
          </a-row>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownLicense',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
      enum: ['info', 'info-input', 'details-input'],
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    detailsText: {
      type: String,
    },
    detailsURL: {
      type: String,
    },
    primaryButton: {
      type: String,
    },
    secondaryButton: {
      type: String,
    },
    plan: {
      type: Object,
    },
    keyword: {
      type: String,
      default: 'Comenzar',
    },
  },
  data: () => ({
    footer: null,
    input: '',
  }),
  computed: {
    buttonEnabled() {
      return this.keyword === this.input
    },
  },
  methods: {
    hideModal() {
      this.$emit('hideModal')
      this.resetData()
    },
    handleOk() {
      this.$emit('done')
      this.hideModal()
    },
    resetData() {
      this.input = ''
    },
  },
}
</script>

<style lang="sass" scoped>
.down-license
  &-content
    &-modal
      color: $gray_dark_800
      .icon
        text-align: center
        svg
          margin: 0 auto
          width: 64px
          height: 64px
      h2
        font-size: 20px
        font-weight: 600
        text-align: center
      p
        font-size: 14px
        text-align: center
      .mini-form
        p
          text-align: left
          font-size: 14px
          margin: 0
          span
            font-weight: 700
        input
          margin: 0 0 32px 0
      .buttons
        .btn-secondary
          border: none
</style>
