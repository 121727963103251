<template>
  <svg
    class="icon-ticket"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 20 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1663 11.9997C19.1663 12.9201 18.4202 13.6663 17.4997 13.6663H2.49967C1.5792 13.6663 0.833008 12.9201 0.833008 11.9997V9.40641L1.24907 9.16573C2.01728 8.72134 2.49967 7.90298 2.49967 6.99967C2.49967 6.09637 2.01728 5.27801 1.24907 4.83362L0.833008 4.59294V1.99967C0.833008 1.0792 1.5792 0.333008 2.49967 0.333008H17.4997C18.4202 0.333008 19.1663 1.0792 19.1663 1.99967V4.59294L18.7503 4.83362C17.9821 5.27801 17.4997 6.09637 17.4997 6.99967C17.4997 7.90298 17.9821 8.72134 18.7503 9.16573L19.1663 9.40641V11.9997ZM15.833 6.99967C15.833 5.66592 16.4655 4.44166 17.4997 3.6659V1.99967L12.4997 1.99967C12.4997 2.45991 12.1266 2.83301 11.6663 2.83301C11.2061 2.83301 10.833 2.45991 10.833 1.99967L2.49967 1.99967V3.6659C3.53384 4.44166 4.16633 5.66592 4.16633 6.99967C4.16633 8.33343 3.53384 9.55768 2.49967 10.3335V11.9997H10.833C10.833 11.5394 11.2061 11.1663 11.6663 11.1663C12.1266 11.1663 12.4997 11.5394 12.4997 11.9997H17.4997V10.3335C16.4655 9.55768 15.833 8.33343 15.833 6.99967ZM11.6663 10.333C12.1266 10.333 12.4997 9.95991 12.4997 9.49967C12.4997 9.03944 12.1266 8.66634 11.6663 8.66634C11.2061 8.66634 10.833 9.03944 10.833 9.49967C10.833 9.95991 11.2061 10.333 11.6663 10.333ZM12.4997 6.99967C12.4997 7.45991 12.1266 7.83301 11.6663 7.83301C11.2061 7.83301 10.833 7.45991 10.833 6.99967C10.833 6.53944 11.2061 6.16634 11.6663 6.16634C12.1266 6.16634 12.4997 6.53944 12.4997 6.99967ZM11.6663 5.33301C12.1266 5.33301 12.4997 4.95991 12.4997 4.49967C12.4997 4.03944 12.1266 3.66634 11.6663 3.66634C11.2061 3.66634 10.833 4.03944 10.833 4.49967C10.833 4.95991 11.2061 5.33301 11.6663 5.33301Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChatbot',
  props: {
    fill: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
