const actions = {
  /** Conecta el hilo del usuario con el socket del usuario actual */
  async joinUserThread(context) {
    try {
      const userId = context.getters.profile.userId
      context.dispatch('joinUserIdThread', userId)
    } catch (error) {
      console.error('[userThread][joinUserThread]', error)
    }
  },
  /**
   * Conecta el hilo del usuario a una sala socket
   * @param {*} context
   * @param {String} idUser Id de usuario
   */
  async joinUserIdThread(context, idUser) {
    try {
      await this._vm.$socket.emit('server-create-channel-user', { idUser })
    } catch (error) {
      console.error('[userThread][joinUserIdThread]', error)
    }
  },
}

export default {
  actions,
}
