<template>
  <div class="chatbot">
    <ChatbotTemplate />
  </div>
</template>
<script>
import ChatbotTemplate from '@/app/chatbots/components/templates/ChatbotTemplate.vue'

export default {
  name: 'Chatbot',
  components: { ChatbotTemplate },
}
</script>
<style lang="sass" scoped>
.chatbot
  height: 100vh
</style>
