<template>
  <a-modal
    :visible="visible"
    :title="title"
    :footer="null"
    width="28em"
    centered
    @cancel="handleClose"
  >
    <div class="modal__content">
      <counter-text :maxLength="1000" :text="comment">
        <a-textarea
          v-model="comment"
          placeholder="Escribe aquí"
          :auto-size="{ minRows: 10, maxRows: 10 }"
          :maxLength="1000"
        />
      </counter-text>
    </div>
    <basic-footer
      :okText="okText"
      :cancelText="cancelText"
      :okDisabled="okDisabled"
      :okLoading="okLoading"
      @onOk="handleSubmit"
      @onCancel="handleClose"
    />
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CounterText from '@/app/shared/components/molecules/CounterText'

export default {
  name: 'ModalComment',
  components: {
    BasicFooter,
    CounterText,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    okText: {
      type: String,
      required: false,
      default: 'ok',
    },
    cancelText: {
      type: String,
      required: false,
      default: 'cancel',
    },
    action: {
      type: String,
      required: false,
      default: 'add',
    },
    messageId: {
      type: [String, Number],
      required: false,
    },
    commentId: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    comment: '',
    okLoading: false,
  }),
  computed: {
    ...mapGetters(['ticketSelected']),

    /**
     * @return {Boolean} boton de ok deshabilitado
     */
    okDisabled() {
      return !this.comment.trim().length
    },
  },
  methods: {
    ...mapActions(['addComment', 'updateComment']),

    /**
     * Cierra el modal
     */
    handleClose() {
      this.comment = ''
      this.okLoading = false
      this.$emit('onClose')
    },
    /**
     * Setea el comentario
     * @param {String} comment
     */
    setComment(comment) {
      this.comment = comment
    },
    /**
     * Submit del modal
     */
    async handleSubmit() {
      switch (this.action) {
        case 'update':
          {
            this.okLoading = true
            await this.updateComment({
              commentId: this.commentId,
              ticketId: this.ticketSelected._id,
              messageId: this.messageId,
              text: this.comment,
            })
            this.okLoading = false
            this.handleClose()
          }
          break
        case 'add':
          {
            this.okLoading = true
            const response = await this.addComment({
              ticketId: this.ticketSelected._id,
              messageId: this.messageId,
              text: this.comment,
            })
            this.okLoading = false
            if (response.success) {
              this.handleClose()
              this.$message.success('Se agregó un comentario')
            } else this.$message.error('Ocurrió un error 😥')
          }
          break
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__content
  height: 20em
</style>
