/**
 * Comprueba si el usuario logueado se  encuentra dentro del arreglo de los permitidos
 * @param {vuex}    context
 * @param {Object}  context.getters
 * @param {Object}  context.getters.profile
 * @param {String}  context.getters.profile.type
 * @param {Array}   userTypesAllowed Tipos de usuario permitidos ['agent','admin','supervisor','admin_agent','supervisor_agent','securitec']
 * @param {Object}  [options] Opciones de devolución de respuesta
 * @param {Boolean} [options.literal] Interpretar el userTypesAllowed textualmente sin compararlo con roles equivalentes
 * @param {Boolean} [options.exception] En lugar de devolver un boolean, ejecuta un throw exception (para ejecutar un catch) - opcional
 * @return {Boolean} Está permitido
 */
const isAllowedFor = (context, userTypesAllowed, options = {}) => {
  let isAllow = userTypesAllowed.includes(context.getters.profile.type)
  if (!context.getters.profile)
    throw new Error('"profile" in getters is required')

  if (!isAllow) {
    if (userTypesAllowed.includes('master')) {
      if (!options.literal) userTypesAllowed.push('master_agent')
    }
    if (userTypesAllowed.includes('master_agent')) {
      if (!options.literal) userTypesAllowed.push('master')
    }
    if (userTypesAllowed.includes('admin')) {
      if (!options.literal) userTypesAllowed.push('admin_agent')
    }
    if (userTypesAllowed.includes('supervisor')) {
      if (!options.literal) userTypesAllowed.push('supervisor_agent')
    }
    if (userTypesAllowed.includes('agent')) {
      if (!options.literal) {
        userTypesAllowed.push('master_agent')
        userTypesAllowed.push('admin_agent')
        userTypesAllowed.push('supervisor_agent')
      }
    }
    isAllow = userTypesAllowed.includes(context.getters.profile.type)
  }

  if (options.exception === true) {
    if (isAllow) return isAllow
    else throw isAllow
  } else {
    return isAllow
  }
}

/**
 * Administrador como agente
 * @param context
 */
const isAdminAgent = (context) => {
  return context.getters.company.settings.userMode.adminAgent
}

/**
 * Supervisor como agente
 * @param context
 */
const isSupervisorAgent = (context) => {
  return context.getters.company.settings.userMode.supervisorAgent
}

/**
 * Pausa la instrucción y/o valida si hay valores null o undefined
 * @param {Object} args
 * @return {Promise<Boolean>|Promise<throw>}
 */
const arentNull = (args) => {
  return new Promise((resolve, reject) => {
    let res = ''
    const obj = {}
    let success = true
    for (const el in args) {
      res += `${el}: ${!!args[el]}, `
      obj[el] = args[el]
      if (args[el] === null || args[el] === undefined) success = false
    }
    if (!success)
      return reject({ success, throw: res, got: obj, error: 'Missings params' })
    return resolve(true)
  })
}

export default {
  isAllowedFor,
  isAdminAgent,
  isSupervisorAgent,
  arentNull,
}
