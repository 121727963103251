const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  ADD_NOTIFICATION(state, notification) {
    state.notifications.unshift(notification)
  },
  SET_NEXT_PAGE_NOTIFICATIONS(state, nextNotifications) {
    nextNotifications.forEach((nextNotification) =>
      state.notifications.push(nextNotification)
    )
  },
  REMOVE_NEW_NOTIFICATIONS(state, idNotification) {
    const indexNotification = state.notifications.findIndex(
      (notification) => notification._id === idNotification
    )
    if (indexNotification !== -1) {
      state.notifications[indexNotification].new = false
    }
  },
}
export default mutations
