<template>
  <div class="template">
    <a-tabs
      type="card"
      class="template__tabs"
      :tabBarStyle="{
        display: 'flex',
        marginBottom: '0px',
      }"
      v-model="tabSelected"
      :tabBarGutter="8"
      @change="handleChangeTab"
    >
      <a-tab-pane
        v-for="tab in tabs"
        :key="tab.key"
        :tab="tab.title"
        class="template__tab"
      >
        <div class="template__tab-content">
          <router-view />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: 'ChannelsTemplate',
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },
    defaultTabSelected: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tabSelected: 'all-channels',
  }),
  mounted() {
    this.tabSelected = this.defaultTabSelected
  },
  watch: {
    // Se agrego un observador para verificar el cambio correcto de tab al cambia la ruta
    '$route.name': {
      handler: function (value) {
        this.tabSelected = value
      },
      deep: true,
      immediate: true,
    },
    defaultTabSelected() {
      this.tabSelected = this.defaultTabSelected
    },
  },
  methods: {
    handleChangeTab(key) {
      //Se le agrego un validador de error para el cambio de rutas
      this.$router.push({ name: key }).catch((error) => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes(
            'Avoided redundant navigation to current location'
          )
        ) {
          console.error(error)
        }
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.template
  overflow: hidden
  padding: 12px
  &__tab-content
    background: $white_000
    padding: 16px
    height: calc(100vh - 64px)
</style>
