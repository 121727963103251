<template>
  <span>
    {{ title }}
    <a-tooltip :title="tooltip">
      <a-icon type="info-circle" class="mrg-right-4" />
    </a-tooltip>
  </span>
</template>

<script>
export default {
  name: 'HeaderTableInfo',
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped></style>
