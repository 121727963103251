<template>
  <a-drawer
    :title="`Editar ticket ${ticketNumberFormatted(ticket.ticket)}`"
    :width="drawer_filters.width"
    :visible="visible"
    class="update-ticket"
    :mask="false"
    :headerStyle="{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      'border-bottom': '1px solid #e8e8e8',
      zIndex: '100',
    }"
    :bodyStyle="{
      'margin-top': '3.3rem',
    }"
    @close="onClose"
  >
    <!--Collapse-->
    <CollapseTicketDetails :ticket="ticket" />

    <a-form
      v-if="localTicket"
      :form="form"
      class="filters"
      layout="vertical"
      hide-required-mark
    >
      <a-form-item class="form__item">
        <template v-slot:label>
          <label class="heading-h7">Favorito</label>
        </template>
        <a-row type="flex" align="middle">
          <a-icon
            @click="
              localTicket.settings.starred = !localTicket.settings.starred
            "
            class="star"
            type="star"
            theme="filled"
            :style="`font-size: 20px ;${
              localTicket.settings.starred
                ? 'color: #f8e479;'
                : 'color: #E8E8E8;'
            }`"
          />
          {{ localTicket.settings.starred ? 'Es favorito' : 'No es favorito' }}
        </a-row>
      </a-form-item>
      <a-form-item class="form__item">
        <template v-slot:label>
          <label class="heading-h7">Etiquetas</label>
        </template>
        <a-select class="select" mode="multiple" v-model="ticketTags">
          <a-select-option
            v-for="tag in tagsToSelect"
            :key="`Tags${tag.key}`"
            :value="tag.key"
            :title="tag.title"
          >
            {{ tag.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="form__item form__item--last">
        <template v-slot:label>
          <label class="heading-h7">Campos personalizados</label>
        </template>
        <div
          class="custom-field"
          v-for="customField in company_custom_fields_tickets"
          :key="customField._id"
        >
          <label for="" class="heading-h8 custom-field__label">{{
            customField.name
          }}</label>
          <a-input
            v-if="customField.type == 'text'"
            v-model="localTicket.fields[customField._id]"
          />
          <a-select
            class="select"
            v-else-if="customField.type == 'dropdown'"
            v-model="localTicket.fields[customField._id]"
            :mode="customField.multiple ? 'multiple' : ''"
            placeholder="Seleccione una opción"
            :allowClear="true"
          >
            <a-select-option
              v-for="option in customField.options"
              :key="option.idOption"
              :value="option.idOption"
              :title="option.name"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
          <a-date-picker
            v-else-if="customField.type == 'date'"
            v-model="localTicket.fields[customField._id]"
            placeholder="2020-05-25"
          />
        </div>
      </a-form-item>
    </a-form>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="onClose">
        Cancelar
      </a-button>
      <a-button
        v-if="showAplyChanges"
        type="primary"
        :loading="loading"
        @click="onSaveTicket"
      >
        Guardar cambios
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import CustomFieldOption from './CustomFieldOption.vue'
import format from '@/app/shared/mixins/format'
import CollapseTicketDetails from '@/app/manageTickets/components/organisms/CollapseTicketDetails.vue'
import moment from 'moment'

export default {
  name: 'UpdateTicketDrawer',
  components: { CollapseTicketDetails },
  mixins: [format],

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: () => ({
    moment,
    localTicket: {},
    ticketTags: [],
    loading: false,
    currentFields: {},
  }),
  watch: {
    ticket: {
      handler() {
        if (this.ticket) {
          const localTicket = JSON.parse(JSON.stringify(this.ticket))
          this.localTicket = this.formmatedFields(localTicket)
          this.ticketTags = []
          this.ticket.settings.tags.forEach((tag) => {
            if (
              this.tagsToSelect.some(
                (companyTag) => companyTag.key == tag.tagId
              )
            )
              this.ticketTags.push(tag.tagId)
            else this.ticketTags.push(tag.title)
          })
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_ticket-drawer' })
  },
  created() {
    this.localTicket = JSON.parse(JSON.stringify(this.ticket))
    this.localTicket = this.formmatedFields(this.localTicket)
  },
  computed: {
    ...mapGetters([
      'company',
      'mailboxes',
      'profile',
      'lines',
      'simple_tags',
      'agents',
      'company_custom_fields_tickets',
      'drawer_filters',
    ]),
    /**
     * Los tags a filtrar
     * @returns {Object[]}
     */
    tagsToSelect() {
      if (!this.company) return []
      let toSelect = []
      toSelect = this.simple_tags.map((tag) => ({
        title: tag.title,
        key: tag._id,
        idTag: tag._id,
        tagId: tag._id,
        color: tag.color,
      }))
      return toSelect
    },
    /**
     * @returns {Boolean}
     */
    showAplyChanges() {
      return this.ticket.status === 'active'
    },
  },
  methods: {
    ...mapActions([
      'updateAdminTicketTags',
      'updateAdminStarred',
      'updateFieldsToTicket',
      'updateStoredTicket',
      'addNotification',
    ]),
    onClose() {
      this.$emit('close')
    },
    /**
     * Se guardan todos los cambios del ticket
     */
    async onSaveTicket() {
      this.loading = true
      this.$emit('onLoading', true)
      await this.updateStarredTicket(this.localTicket)
      await this.updateFields(this.localTicket, this.localTicket.fields)
      const newTags = this.ticketTags.map((tag) => {
        let founded = this.tagsToSelect.find(
          (companyTag) => companyTag.key == tag
        )
        if (!founded) {
          founded = this.ticket.settings.tags.find(
            (showTag) => showTag.title == tag
          )
        }
        return founded
      })
      await this.updateTags(this.localTicket._id, newTags)
      this.localTicket.settings.tags = newTags
      const copyLocalTicket = this.localTicket
      copyLocalTicket.fields = this.currentFields
      // actualiza todo el store relacionado a un ticket
      this.updateStoredTicket(copyLocalTicket)
      this.$message.success(`Se actualizo el ticket correctamente`)
      this.loading = false
      this.$emit('onLoading', false)
      this.$emit('close')
    },
    async updateStarredTicket(localeTicket) {
      await this.updateAdminStarred({
        idTicket: localeTicket._id,
        starred: localeTicket.settings.starred,
      })

      if (localeTicket.settings.starred !== this.ticket.settings.starred) {
        const activity = {
          type: 'update-ticket-starred',
          ticket: {
            _id: localeTicket._id,
            new: localeTicket.settings.starred,
          },
        }
        this.addNotification(activity)
      }
    },
    /**
     * Actualiza todos los campos
     * @param {Object} ticket
     * @param {String[]} customFields - campos locales editados
     */
    async updateFields(ticket, customFields) {
      const originalCustomFields = []

      for (let property in this.ticket.fields) {
        originalCustomFields.push({
          _id: property,
          value: this.ticket.fields[property],
          name: this.company_custom_fields_tickets.find(
            (elem) => elem._id === property
          ).name,
        })
      }

      // aqui se adjuntara los nuevos campos example: {'id del campo': valor del campo}
      let currentFields = {}
      // se recorre para formatear el valor de los campos dropdown
      for (let property in customFields) {
        const currentField = this.company_custom_fields_tickets.find(
          (ele) => ele._id === property
        )

        if (currentField === undefined) return
        if (currentField.type === 'dropdown') {
          const valueField = !customFields[property]
            ? []
            : customFields[property]
          currentFields[property] =
            typeof valueField !== 'string' && valueField
              ? valueField
              : [valueField]
          this.currentFields[property] = []

          // Busca el id de la opcion seleccionada para encontar el nombre
          currentFields[property].forEach((idOption) => {
            const foundOption = currentField.options.find(
              (elem) => elem.idOption === idOption
            )
            let name = ''
            if (foundOption) name = foundOption.name

            this.currentFields[property].push({
              idOption: idOption,
              name: name,
            })
          })
        } else {
          currentFields[property] = customFields[property]
          this.currentFields[property] = customFields[property]
        }
      }
      // actualiza todos los campos del ticket
      await this.updateFieldsToTicket({
        ticket,
        field: currentFields,
        isAgent: false,
      })
      let newFields = []
      for (let property in currentFields) {
        const originFieldFound = originalCustomFields.find(
          (originalCustomField) => originalCustomField._id === property
        )
        if (originFieldFound !== undefined) {
          // si es de tipo dropdown
          if (Array.isArray(currentFields[property])) {
            const originOptions = originFieldFound.value.map(
              (option) => option.idOption
            )
            // si se valida que no sea igual al valor original en longitud
            const isEqual =
              currentFields[property].length ===
                originFieldFound.value.length &&
              currentFields[property].every((val) =>
                originOptions.includes(val)
              )
            if (!isEqual) {
              const customFieldFound = this.company_custom_fields_tickets.find(
                (customField) => customField._id === property
              )
              newFields.push({
                _id: property,
                name: customFieldFound.name,
                value: currentFields[property].map((idNewOption) =>
                  customFieldFound.options.find(
                    (option) => option.idOption === idNewOption
                  )
                ),
              })
            }
          }
          // si es de tipo de fecha
          else if (typeof currentFields[property] === 'object') {
            if (
              new Date(originFieldFound.value).getTime() !==
              new Date(currentFields[property]).getTime()
            )
              newFields.push({
                _id: property,
                name: originFieldFound.name,
                value: currentFields[property],
              })
          } else {
            if (originFieldFound.value !== currentFields[property])
              newFields.push({
                _id: property,
                name: originFieldFound.name,
                value: currentFields[property],
              })
          }
        } else {
          newFields.push({
            _id: property,
            value: currentFields[property],
            ...this.company_custom_fields_tickets.find(
              (elem) => elem._id === property
            ),
          })
        }
      }
      newFields.forEach((newField) => {
        let newVal = ''
        // si es array
        if (Array.isArray(newField.value)) {
          newField.value.forEach((item) => {
            if (!item.name) {
              const option = newField.options.find(
                (option) => option.idOption === item
              )
              item = { ...item, name: option.name }
            }
            newVal.length > 1
              ? (newVal += `, ${item.name}`)
              : (newVal = item.name)
          })
        } else if (typeof newField.value === 'object') {
          newVal = moment(newField.value).format('YYYY MMMM DD, h:mm:ss a')
        } else newVal = newField.value
        const activity = {
          type: 'update-ticket-field',
          ticket: {
            _id: ticket._id,
            new: newVal,
            field: newField.name,
          },
        }
        this.addNotification(activity)
      })
    },
    async updateTags(ticketId, tags) {
      const originalTags = this.ticket.settings.tags

      const deassignTags = originalTags.filter((originalTag) => {
        return !tags.some((tag) => tag.tagId === originalTag.tagId)
      })
      const assignedtags = tags.filter((tag) => {
        return !originalTags.some(
          (originalTag) => originalTag.tagId === tag.tagId
        )
      })
      await this.updateAdminTicketTags({
        tags,
        idTicket: ticketId,
        ticket: this.ticket,
        tagsRemoved: deassignTags,
        tagsAdded: assignedtags,
      })
      const activity = {
        ticket: { _id: ticketId },
      }
      if (deassignTags.length > 0) {
        activity.type = 'deassign-ticket-tags'
        activity.ticket.tags = deassignTags.map(
          (deassignTag) => deassignTag.title
        )
        this.addNotification(activity)
      } else if (assignedtags.length > 0) {
        activity.type = 'assign-ticket-tags'
        activity.ticket.tags = assignedtags.map((assignTag) => assignTag.title)
        this.addNotification(activity)
      }
    },
    /**
     * Formatea los campos personalizados de local Ticket
     * @param {Object} localTicket
     */
    formmatedFields(localTicket) {
      const currentFields = [...this.company_custom_fields_tickets]
      // identificar que campo dropdown es, para formatear sus valores
      currentFields.forEach((currentField) => {
        if (currentField.type === 'dropdown') {
          const existFieldDropdown = Object.prototype.hasOwnProperty.call(
            this.ticket.fields,
            currentField._id
          )
          const idOptions = currentField.options.map(
            (option) => option.idOption
          )
          if (existFieldDropdown)
            localTicket.fields[currentField._id] = localTicket.fields[
              currentField._id
            ]
              .filter((localField) => idOptions.includes(localField.idOption))
              .map((e) => e.idOption)
        }
      })
      return localTicket
    },
  },
}
</script>

<style lang="sass" scoped>
.form__item
  margin-bottom: 12px

.star
  margin-right: 10px
.custom-field
  display: flex
  flex-flow: column
  margin-bottom: 8px
.form__item--last
  margin-bottom: 20px
</style>
<style lang="sass">
.update-ticket
  .ant-drawer-wrapper-body
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
