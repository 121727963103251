import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['countries']),
  },
  methods: {
    /**
     * Busca el país de acuerdo a la propiedad y el valor pasado
     * @param {String} key
     * @param {String} value
     * @return {Object} country
     * @return {String} country.dialCode
     * @return {String} country.nameSpanish
     * @return {String} country.icon4x3
     * @return {String} country._id
     */
    searchCountry(key, value) {
      const country = this.countries.find((country) => country[key] === value)
      return country
    },
  },
}
