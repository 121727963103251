import Vue from 'vue'

const mutations = {
  /**
   * Setea las variables de los chatbots paginados
   * @param {*} state
   * @param {*} paginate_chatbots_vars
   */
  SET_PAGINATE_CHATBOTS_VARS(state, paginate_chatbots_vars) {
    state.paginate_chatbots_vars = paginate_chatbots_vars
  },
  /**
   * Setea los variables de los chatbots sin paginar
   * @param {*} state
   * @param {Object[]} simpleVars
   */
  SET_SIMPLE_CHATBOTS_VARS(state, simpleVars) {
    state.simple_chatbots_vars = simpleVars
  },
  /**
   * Agrega una variable a las variables sin paginacion
   * @param {*} state
   * @param {Object} simpleChatbotVar
   * @param {String} simpleChatbotVar.name
   * @param {String} simpleChatbotVar.type - por defecto será de tipo TEXTO
   * @param {String[]} simpleChatbotVar.excludeWords
   * @param {String} simpleChatbotVar.customFieldId
   */
  ADD_SIMPLE_CHATBOT_VAR(state, simpleChatbotVar) {
    state.simple_chatbots_vars.push(simpleChatbotVar)
  },
  /**
   * Agrega una variable a las variables sin paginacion
   * @param {*} state
   * @param {Object} simpleChatbotVar
   * @param {String} simpleChatbotVar.name
   * @param {String} simpleChatbotVar.type - por defecto será de tipo TEXTO
   * @param {String[]} simpleChatbotVar.excludeWords
   * @param {String} simpleChatbotVar.customFieldId
   */
  SET_CUSTOM_FIELD_TO_SIMPLE_CHATBOT_VAR(state, { chatbotVarId, customField }) {
    const simpleVarFound = state.simple_chatbots_vars.find(
      (simpleVar) => simpleVar._id === chatbotVarId
    )
    if (!simpleVarFound) return
    simpleVarFound.customField = customField
  },
  /**
   * Elimina una variable de las variables sin paginacion
   * @param {*} state
   * @param {String} chatbotVarId
   */
  REMOVE_SIMPLE_CHATBOT_VAR(state, chatbotVarId) {
    state.simple_chatbots_vars = state.simple_chatbots_vars.filter(
      (simpleVar) => simpleVar._id !== chatbotVarId
    )
  },
  /**
   * Setea la paginacion de los chatbots paginados
   * @param {*} state
   * @param {*} pagination_chatbots_vars
   */
  SET_PAGINATION_CHATBOTS_VARS(state, pagination_chatbots_vars) {
    state.pagination_chatbots_vars = pagination_chatbots_vars
  },
  /**
   * Agrega una variable a las variables paginadas
   * @param {*} state
   * @param {Object} chatbotVar
   * @param {String} chatbotVar.name
   * @param {String} chatbotVar.type - por defecto será de tipo TEXTO
   * @param {String[]} chatbotVar.excludeWords
   * @param {String} chatbotVar.customFieldId
   */
  ADD_CHATBOT_VAR(state, chatbotVar) {
    state.paginate_chatbots_vars.unshift(chatbotVar)
  },
  /**
   * Actualiza una variable de las variables paginadas
   * @param {*} state
   * @param {Object} args
   * @param {String} args.chatbotVarId
   * @param {String} args.chatbotVar.name
   */
  UPDATE_CHATBOT_VAR(state, { chatbotVarId, chatbotVar }) {
    let index = state.paginate_chatbots_vars.findIndex(
      (chatbotVar) => chatbotVar._id === chatbotVarId
    )
    if (index === -1) return
    Vue.set(state.paginate_chatbots_vars, index, chatbotVar)
  },
  /**
   * Elimina una variable de las variables paginadas
   * @param {*} state
   * @param {String} chatbotVarId
   */
  DELETE_CHATBOT_VAR(state, chatbotVarId) {
    state.paginate_chatbots_vars = state.paginate_chatbots_vars.filter(
      (chatbotVar) => chatbotVar._id !== chatbotVarId
    )
  },
}
export default mutations
