<template>
  <div class="section-menu-general">
    <header-module
      title="General"
      tipography="heading-h5"
      class="section__header"
      :link="`${vari.WEB_DOC}caracteristicas/configuracion-general/`"
      :text-before-link="`Preferencias generales de ${app}`"
      text-after-link="si deseas conocer más."
      textLink="Click aquí"
      positionTooltip="right"
    />
    <custom-section-scroll
      :master-styles="{
        height: `calc(100% - (${size_title} + ${margin_bottom_title} + ${padding_top_header} + 12px))`,
      }"
      :content-styles="{ margin: `0 20px` }"
    >
      <template v-if="company">
        <card-queue
          :queueConfig="company.settings.queue"
          class="section__card"
        />
        <card-timeout-chatbot class="section__card" />
        <card-switch
          class="section__card"
          title="Envía más de una plantilla de WhatsApp"
          :description="descriptionTemplates"
          :active="company.settings.chat.sendMoreTemplates"
          @onChangeActive="handleUpdateSendTemplates"
          :disabled="!isActiveWBA"
          :show-tooltip="!isActiveWBA ? true : false"
          tooltip-text="Activa el canal de WhatsApp, para habilitar esta opción"
        />
        <card-switch
          class="section__card"
          title="Preferencia de envío de mensajes"
          description="Permite a los agentes enviar mensajes rápidos con un solo enter (INTRO)"
          :active="company.settings.chat.sendWithIntro"
          @onChangeActive="handleUpdateSendWithIntro"
        />
        <section-mode-agent :company="company" />
      </template>
    </custom-section-scroll>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardQueue from '@/app/settings/components/organisms/generalMenu/CardQueue'
import SectionModeAgent from '@/app/settings/components/organisms/generalMenu/SectionModeAgent'
import CardSwitch from '@/app/shared/components/cards/CardSwitch'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import CardTimeoutChatbot from '@/app/settings/components/organisms/generalMenu/CardTimeoutChatbot'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionMenuGeneral',
  components: {
    CardQueue,
    SectionModeAgent,
    CardSwitch,
    CustomSectionScroll,
    CardTimeoutChatbot,
    HeaderModule,
  },
  data: () => ({
    vari,
    app: process.env.VUE_APP_NAME,
    descriptionTemplates:
      'Los agentes podrán enviar más de una plantilla de WhatsApp para continuar la conversación con sus clientes, siempre y cuando el cliente haya respondido al último mensaje',
    size_title: '24px',
    margin_bottom_title: '12px',
    padding_top_header: '20px',
  }),
  computed: {
    ...mapGetters(['company']),

    /** Si tiene activo el canal de whatsapp
     * @return {Boolean}
     */
    isActiveWBA() {
      return (
        this.company &&
        this.company.channels.whatsapp &&
        this.company.channels.whatsapp.active
      )
    },
  },
  methods: {
    ...mapActions([
      'updateActiveTemplates',
      'addNotification',
      'updateSendWithIntro',
    ]),

    /**
     * Actualiza el valor de active del envio de mas plantillas
     * @param {Boolean} active
     */
    async handleUpdateSendTemplates(active) {
      const response = await this.updateActiveTemplates(active)
      console.log(response)
      if (response.success) {
        this.$message.success('Se guardó correctamente')
        /**Crear notificacion */
        let activity = {}
        if (this.activeSwitch) activity.type = 'active-templates'
        else activity.type = 'inactive-templates'
        this.addNotification(activity)
      } else return this.$message.error('Ocurrió un error')
    },
    /**
     * Actualiza el valor de activo del envio de preferencias
     * @param {Boolean} active
     */
    async handleUpdateSendWithIntro(active) {
      const response = await this.updateSendWithIntro({
        sendWithIntro: active,
      })
      if (response.success) {
        this.$message.success('Los datos se guardaron correctamente')
        let activity = {}
        if (this.valueSwitch) activity.type = 'active-enter'
        else activity.type = 'inactive-enter'
        /**Crear notificacion */
        this.addNotification(activity)
      } else {
        this.$message.error(
          response.details || 'No pudimos guardar la configuración 😓'
        )
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$size-title: 24px
$margin-bottom-title: 12px
$padding-top-header: 20px

.section-menu-general
  height: 100%
  .section
    &__header
      padding: $padding-top-header 20px 0
      display: flex
      align-items: center
      margin-bottom: $margin-bottom-title
      .title
        text-align: left
    &__icon
      margin-left: 8px
      font-size: 18px
      cursor: pointer
    &__card
      margin-bottom: 20px
</style>
