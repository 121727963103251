const actions = {
  /**
   * Escucha los eventos de los updates de la campañas
   * @param {vuex} context

   */
  listenStatusStrategy(context) {
    try {
      /**
       * @param {String} strategyId
       * @param {String} status
       * @param {Object} stats
       * @param {Object} stats.stats_import
       * @param {Object} stats.stats_send
       * @param {Object} stats.stats_wallet
       */
      this._vm.$socket.on(
        'client:strategy:stats',
        ({ strategyId, status, stats }) => {
          context.commit('UPDATE_STRATEGY', {
            strategyId,
            status,
            ...stats,
          })
        }
      )
    } catch (err) {
      console.error('[listenStatusStrategy]', err)
    }
  },
}
export default { actions }
