const CHANNELS_MODAL_STEPS = {
  whatsapp: {
    title: 'Integra con WhatsApp Business API',
    steps: [
      {
        step: 0,
        step_title: 'Seleccionar',
        title: 'Selecciona un proveedor para integrar WhatsApp Business API',
      },
      {
        step: 1,
        step_title: 'Completar',
      },
      {
        step: 2,
        step_title: 'Finalizar',
      },
    ],
    providers: [
      {
        name: 'securitec',
        type: 'default',
        benefits: [
          'Ideal para pequeñas y medianas empresas',
          'Solo pagas por los mensajes que consumes.',
          'Precios más bajos. Desde $0.003 multidestino.',
          'Puedes compartir fotos, audios, videos y documentos.',
        ],
        title: 'Integra WhatsApp con Gupshup',
        nameButton: 'Solicitar WhatsApp Business API',
        images: ['gupshup-logo-44x44.png'],
      },
      {
        name: 'twilio',
        benefits: [
          'Conecta la API de WhatsApp creada en Twilio.',
          'Solo pagas por los mensajes que consumes.',
          'Precios de mensajes dependiendo el destino.',
          '$0.0100  por mensaje de plantillas según el volumen ',
        ],
        title: 'Integra con Twilio',
        nameButton: 'Integrar con Twilio',
        images: ['twilio-logo-44x44.png'],
      },
    ],
  },
  sms: {
    title: 'Integra SMS API',
    steps: [
      {
        step: 0,
        step_title: 'Seleccionar',
        title: 'Selecciona un proveedor para integrar SMS API',
      },
      {
        step: 1,
        step_title: 'Completar',
      },
      {
        step: 2,
        step_title: 'Finalizar',
      },
    ],
    providers: [
      {
        name: 'tedexis',
        type: 'default',
        benefits: [
          'Uso de números aleatorios para cada envío.',
          'Envío de grandes volúmenes de mensajes masivos.',
          'Envía campañas masivas con SMS salientes.',
          '$0.05 por mensaje enviado. ',
        ],
        title: 'Integra con Tedexis',
        nameButton: 'Integrar SMS API',
        images: ['tedexis-logo-128x44.png'],
      },
    ],
  },
}

export { CHANNELS_MODAL_STEPS }
