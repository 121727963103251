<template>
  <div class="video-player">
    <img
      :src="picture"
      :width="widthPicture"
      :height="heightPicture"
      class="video-player__picture"
    />
    <div
      class="video-player__overlay"
      :style="{ width: `${widthPicture}`, height: `${heightPicture}` }"
      @click="handleShowVideo"
    >
      <a-icon type="play-circle" class="video-player__icon" theme="filled" />
    </div>
    <a-modal
      :visible="visibleModalVideo"
      :footer="null"
      width="80%"
      :bodyStyle="{
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }"
      centered
      dialogClass="modal-player__dialog"
      @cancel="handleHideVideo"
    >
      <template slot="closeIcon">
        <a-icon type="close" style="color: white" @click="handleHideVideo" />
      </template>
      <div v-if="loadVideo" class="video-player--loading">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 40px; color: white"
            spin
          />
        </a-spin>
      </div>
      <div
        v-if="!loadVideo && visibleModalVideo"
        class="video-player__content-frame"
        v-html="video"
        @click="handleHideVideo"
      ></div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    picture: {
      type: String,
      required: false,
      default:
        'https://i.pinimg.com/originals/f4/f3/e0/f4f3e051093851eeca4e311f4d2e4567.jpg',
    },
    video: {
      type: String,
      required: false,
      default:
        '<iframe width="90%" height="515" src="https://www.youtube.com/embed/Zq8_XppDXdk?rel=0&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    heightPicture: { type: String, required: false, default: '343px' },
    widthPicture: { type: String, required: false, default: '614px' },
  },
  data: () => ({
    visibleModalVideo: false,
    loadVideo: false,
  }),
  methods: {
    handleShowVideo() {
      console.log('mostarr video')
      this.visibleModalVideo = true
      this.loadVideo = true
      this.$emit('onOpenVideo')
      setTimeout(() => {
        this.loadVideo = false
      }, 800)
    },
    handleHideVideo() {
      this.visibleModalVideo = false
      this.$emit('onCloseVideo')
    },
  },
}
</script>

<style lang="sass" scoped>
.video-player
  background-color: $gray_4
  border-radius: 8px
  padding: 12px
  position: relative
  &__picture
    object-fit: cover
  &__overlay
    position: absolute
    top: 0px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    &:hover
      .video-player__icon
        transition: font-size 0.5s
        font-size: 120px
  &__icon
    font-size: 92px
    color: $blue_6
    opacity: 0.8
    transition: font-size 0.5s
  &__content-frame
    display: flex
    align-items: center
    justify-content: center
  &--loading
    display: flex
    align-items: center
    justify-content: center
    height: 100%
</style>
<style lang="sass">
.modal-player__dialog
  .ant-modal-content
    background-color: transparent
    box-shadow: none
</style>
