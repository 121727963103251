import Logout from '@/app/commons/views/Logout'
import Redirect from '@/app/commons/views/Redirect'
import Unsupport from '@/app/commons/views/Unsupport'

export default [
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: true,
      requiresDevice: ['mobile', 'desktop'],
      title: `Saliendo...`,
    },
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Redirect,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'agent', 'supervisor', 'master_agent'],
      requiresDevice: ['mobile', 'desktop'],
      title: `Redireccionando...`,
    },
  },
  {
    path: '/unsupport',
    name: 'unsupport',
    component: Unsupport,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'agent', 'supervisor', 'master_agent'],
      title: 'En construcción',
      requiresDevice: ['desktop', 'mobile'],
    },
  },
]
