import {
  addListenerBreakpoint,
  verifyBreakpoint,
} from '../../services/init-match-query'
import { breakpoints } from '../../utils/breakpoints'

const initialState = () => ({
  // responsive
  active_breakpoint: {
    breakpoint: null,
    components: {
      buttons: {
        size: 'default',
      },
      sidebar: {
        visible: true,
      },
      navbar: {
        visible: true,
        submenu_workspace: false,
      },
      inputs: {
        size: 'default',
      },
      drawer_profile: {
        width: '332px',
      },
      title_module: {
        visible: false,
      },
      banner_selection: {
        visible: false,
      },
      section_tickets: {
        styles: {},
      },
    },
    main_layout: {
      padding: '12px',
    },
    views: {},
    is_mobile: false,
  },
})

const state = initialState

const getters = {
  active_breakpoint: (state) => state.active_breakpoint, // breakpoint activa
  buttons_size: (state) => state.active_breakpoint.components.buttons.size, // tamaño de los botones
  inputs_size: (state) => state.active_breakpoint.components.inputs.size, // tamaño de los inputs
  workspace_selector_mailbox_visible: (state) => {
    const visible = state.active_breakpoint.components
      .workspace_selector_mailbox
      ? state.active_breakpoint.components.workspace_selector_mailbox.visible
      : false
    return visible
  }, // selector de buzones visible o no
  workspace_layout: (state) => state.active_breakpoint.modules.workspace.layout, // layout de workspace
  show_title_module: (state) =>
    state.active_breakpoint.components.title_module.visible, // mostrar titulo de modulos
  main_layout: (state) => state.active_breakpoint.main_layout, // layout principañ
  banner_selection: (state) =>
    state.active_breakpoint.components.banner_selection, // baner de seleccion de tickets
  section_tickets: (state) =>
    state.active_breakpoint.components.section_tickets, // seccion de tickets
  navbar: (state) => state.active_breakpoint.components.navbar, // navbar
}

const mutations = {
  /**
   * @param {Object} breakpoint
   * @param {String} breakpoint.breakpoint
   * @param {Object} breakpoint.components
   * @param {Object} breakpoint.main_layout
   * @param {Object} breakpoint.views
   * @param {Boolean} breakpoint.is_mobile
   */
  SET_ACTIVE_BREAKPOINT: (state, breakpoint) =>
    (state.active_breakpoint = breakpoint),
}

const actions = {
  /**
   * Inicializa la captura de breakpoints
   * @param {*} context
   */
  initBreakpoints(context) {
    // verificar en que breakpoint se encuentra la app en una carga inicial
    // setear el valor encontrado como breakpoint activo
    // recorrer todos los breakpoints y añadir los listeners
    // la funcion del listener debería poder, setear si la breakpoint esta activa
    const { breakpoint } = verifyBreakpoint(breakpoints)
    context.dispatch('setActiveBreakpoint', breakpoint)
    context.dispatch('loadBreakpoints')
  },
  /**
   * Carga todos los breakpoints en listeners
   * @param {*} context
   */
  loadBreakpoints(context) {
    for (let breakpoint in breakpoints) {
      addListenerBreakpoint(
        breakpoint,
        breakpoints[breakpoint].restrictions,
        (active_breakpoint) =>
          context.dispatch('setActiveBreakpoint', active_breakpoint)
      )
    }
  },
  /**
   * Setea la breakpoint activa
   * @param {*} context
   * @param {String} breakpoint
   */
  setActiveBreakpoint(context, breakpoint) {
    if (breakpoint) {
      const foundBreakpoint = breakpoints[breakpoint]
      const newBreakpoint = {
        breakpoint,
        ...foundBreakpoint,
      }
      context.commit('SET_ACTIVE_BREAKPOINT', newBreakpoint)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
