import initialState from './initialState'
import transform from '@/app/shared/utils/transform'

const mutations = {
  /** RESET MODULE */
  RESET_COMPANY_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },

  /**
   * Setea una empresa
   * @param {*} state
   * @param {Object} args
   * @param {Object} args.company - empresa nueva
   * @param {Boolean} args.setAll - setear toda la empresa nueva o no
   */
  SET_COMPANY(state, { company, setAll = true }) {
    if (setAll) state.company = company
    else state.company = { ...state.company, ...company }
  },

  /**
   * Agrega un buzón a la lista de buzones dentro de los ajustes de la empresa
   * @param {*} state
   * @param {Object} mailbox Objeto de un buzón
   */
  ADD_MAILBOX_IN_COMPANY(state, mailbox) {
    const mailboxFound = state.company.settings.mailboxes.some(
      (mbx) => mbx.title === mailbox.title
    )
    if (mailboxFound) return

    state.company.settings.mailboxes.push(mailbox)
  },
  /**
   * Quita al buzón de los ajustes de la empresa, utilizando el id del buzón
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idMailbox
   */
  QUIT_MAILBOX_IN_COMPANY(state, { idMailbox }) {
    state.company.settings.mailboxes.forEach((mailbox, i, mailboxes) => {
      if (mailbox.idMailbox === idMailbox) mailboxes.splice(i, 1)
    })
  },
  /**
   * Suma una notificación al buzón
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idMailbox Id del buzón
   * @param {Number} args.quantity Cantidad a sumar (puede ser positivo o negativo)
   */
  SUM_NOTIFICATIONS_MAILBOX(state, { idMailbox, quantity }) {
    if (!state.company) return // Corregir error del logout
    const mailbox = state.company.settings.mailboxes.find(
      (box) => box.idMailbox === idMailbox
    )
    if (!mailbox || mailbox.end) return // No cuenta si no hay buzón o si es el de finalizados
    let newNotifications = mailbox.notifications + quantity
    if (newNotifications < 0) return // No se puede setear un número menor a cero como notificación
    mailbox.notifications = newNotifications
  },
  /**
   * Setea la cantidad de una notificación a un buzón
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idMailbox Id del buzón
   * @param {Number} args.quantity Cantidad a setear
   */
  SET_NOTIFICATIONS_MAILBOX(state, { idMailbox, quantity }) {
    if (!state.company) return // Corregir error del logout
    const mailbox = state.company.settings.mailboxes.find(
      (box) => box.idMailbox === idMailbox
    )
    if (!mailbox) return
    mailbox.notifications = quantity
  },
  /**
   * Actualiza los valores de la configuracion de las "Colas de tickets" de la empresa
   * @param {*} state
   * @param {Object} queueConfig Objeto de la configuracion de las colas de tickets o "Accion sin agentes"
   */
  SET_QUEUE_CONFIG(state, queueConfig) {
    state.company.settings.queue = queueConfig
  },
  ADD_NEW_MAILBOX(state, mailbox) {
    mailbox.created_at = mailbox.created_at.toISOString()
    state.company.settings.mailboxes.push(mailbox)
  },
  SET_MAILBOX_TITLE(state, mailbox) {
    state.company.settings.mailboxes.forEach((v) => {
      if (v.idMailbox === mailbox.idMailbox) {
        v.title = mailbox.title
      }
    })
  },
  /**
   * Quita el buzon de la lista
   * @param {*} state
   * @param {Object} mailbox Objeto completo del buzon
   * @param {String} mailbox.idMailbox Id del Buzon
   */
  QUIT_MAILBOX(state, mailbox) {
    state.company.settings.mailboxes = state.company.settings.mailboxes.filter(
      (box) => box.idMailbox !== mailbox.idMailbox
    )
  },
  /**
   * Setea los telefonos de las configuraciones de notificaciones
   * @param {*} state
   * @param {Object}    args
   * @param {String}    args.action
   * @param {Object}    args.phones Cuando action es '@all'
   * @param {String[]}  args.phones Arreglo de telefonos
   */
  SET_NOTIFICATIONS_PHONES(state, { action, phones }) {
    if (action === '@all') state.notificationsPhones = phones
    else state.notificationsPhones[action] = phones
  },

  /**
   * Setear configuraciones de la notificación
   * @param {*} state
   * @param {Object} args
   * @param {String} args.module
   * @param {String} args.action
   * @param {Object} args.config
   */
  SET_NOTIFICATIONS_CONFIG(state, { module, action, config }) {
    if (!['ticket'].includes(module)) throw `module "${module}" not supported`
    delete config.phones
    state.company.settings.notifications[module][action] = config
  },

  /**Setea las preferencias de envio */
  SET_CHAT_PREFERENCES(state, preferences) {
    state.company.settings.chat[preferences.config] = preferences.value
  },
  SET_ADMIN_AGENT(state, payload) {
    state.company.settings.userMode.adminAgent = payload
  },
  SET_SUPERVISOR_AGENT(state, payload) {
    state.company.settings.userMode.supervisorAgent = payload
  },
  /**
   * Setea los apis de los canales de la sandbox para el uso de la empresa
   * @param {*} state
   * @param {Object} sandboxChannels
   */
  SET_SANDBOX_CHANNELS(state, sandboxChannels) {
    state.sandboxChannels = sandboxChannels
  },
  /**
   * Actualiza el wallet de una empresa
   * @param {*} state
   * @param {Object} wallet
   */
  SET_COMPANY_WALLET(state, wallet) {
    state.company_wallet = wallet
  },
  SET_GENERAL_COMPANY_CHANNELS(state, simple_channels) {
    // filtra solo los canales que está habilitados para tickets
    const simple_company_channels = [...simple_channels].filter(
      (simple_channel) =>
        simple_channel.enableFor && simple_channel.enableFor.workInTickets
    )
    // canales conectados de la empresa pero sin el tipo de canal repetido
    const generals = simple_company_channels.reduce((acc, current) => {
      const isIncludeInAcc = acc[current.channel]
      if (!isIncludeInAcc)
        acc[current.channel] = {
          active: true,
          _id: current.channelId, // id del canal global
          channel: current.channel,
          channelIds: [current._id], // ids de los canales conectados que tienen este canal
          name: transform.transformWord(current.channel, 'capitalize'),
        }
      else acc[current.channel].channelIds.push(current._id)
      return acc
    }, {})
    state.company.channels = generals
  },
  /**
   * Setea el tiempo para la transferencia
   * @param {*} state
   * @param {Number} hours
   */
  SET_HOURS_TRANSFER_TICKETS(state, hours) {
    state.company.settings.chatbot.minutesTransferTickets = hours
  },
}

export default mutations
