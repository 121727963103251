<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="handleCancel"
    :afterClose="handleCancel"
    centered
  >
    <p>
      Recuerda ser precisa y concisa con el nombre del campo y de las opciones
      para evitar confusiones con tus agentes.
    </p>
    <p class="regular-14 modal__label mrg-bottom-8">Avatar de empresa</p>
    <a-row type="flex" align="middle" class="flex-nowrap mrg-bottom-20">
      <uploader-avatar
        width="60px"
        height="60px"
        color-default="red"
        icon-default="company"
        :default-avatar="avatar.avatar"
        @onChange="onChangeAvatar"
      />
      <div class="mrg-left-12">
        <p class="mrg-bottom-0">
          El tamaño máximo permitido es de
          {{ max_size_to_upload_avatar_company }}MB.
          <br />
          Las dimensiones ideales son 500px x 500px
        </p>
      </div>
    </a-row>
    <a-form layout="vertical" :form="form">
      <a-form-item label="Nombre de empresa">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                { max: 35, message: '35 caracteres máximo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :maxLength="50"
        />
      </a-form-item>
      <!-- País -->
      <a-form-item label="País">
        <a-select
          show-search
          v-decorator="[
            'country',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          placeholder="Selecciona aquí"
          @popupScroll="handleLoadMoreCountries"
          @search="handleSearchCountry"
        >
          <a-select-option
            v-for="localCountry in countriesNotRepeatByName"
            :key="localCountry.nameSpanish"
          >
            {{ localCountry.nameSpanish }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Tipo de operación -->
      <a-form-item label="Tipo de operación">
        <a-select
          v-decorator="[
            'operation',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          mode="multiple"
          placeholder="Selecciona aquí"
        >
          <a-select-option v-for="operation in operations" :key="operation.key">
            {{ operation.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <basic-footer
      :ok-text="okText"
      :ok-loading="okButton.loading"
      :cancel-loading="cancelButton.loading"
      :ok-disabled="okButton.disabled"
      :cancel-disabled="cancelButton.disabled"
      cancelText="Cancelar"
      @onOk="handleOk"
      @onCancel="handleCancel"
    />
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import companiesMixin from '@/app/companies/mixins/companiesMixin'
import UploaderAvatar from '@/app/shared/components/uploaders/UploaderAvatar.vue'

export default {
  name: 'ModalFormCompany',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    okText: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  mixins: [companiesMixin],
  components: { BasicFooter, UploaderAvatar },
  data: () => ({
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      loading: false,
      disabled: false,
    },
    message: '',
    localCountries: [],
    loadMoreCountries: false,
    avatar: { default: true, avatar: null, color: null, file: null },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_company' })
  },
  mounted() {
    this.$nextTick(() => this.setInitialValueToLocalCountries())
  },
  watch: {
    /**
     * Verifica que camnbia la data source
     */
    dataSource() {
      this.$nextTick(() => {
        this.setValues()
      })
    },
  },
  computed: {
    ...mapGetters([
      'operations',
      'countriesNotRepeatByName',
      'max_size_to_upload_avatar_company',
    ]),
  },
  methods: {
    /**
     * Setea valores al formulario
     */
    setValues() {
      if (!this.dataSource || !Object.keys(this.dataSource).length) return
      this.setInitialValueToLocalCountries(this.dataSource.country)
      this.form.setFieldsValue({
        name: this.dataSource.name,
        country: this.dataSource.country.name,
        operation: this.dataSource.operation,
      })
      this.avatar = {
        default: !this.dataSource.avatar,
        avatar: this.dataSource.avatar || null,
        color: this.dataSource.color || null,
        file: this.dataSource.avatar || null,
      }
    },
    /**
     * Evento para cancelar
     */
    handleCancel() {
      this.$emit('onClose')
      this.form.resetFields()
      this.avatar = { default: true, avatar: null, color: null, file: null }
      this.message = ''
      this.localCountries = []
      this.loadMoreCountries = false
    },
    /**
     * Evento ok para el modal
     */
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.dataSource && this.dataSource._id)
            values.companyId = this.dataSource._id
          this.okButton.loading = true
          this.okButton.disabled = true
          this.cancelButton.disabled = true
          await this.onOk({ ...values, avatar: this.avatar.file })
          this.okButton.loading = false
          this.okButton.disabled = false
          this.cancelButton.disabled = false
        }
      })
    },
    /**
     * Setear el valor al mensaje
     * @param {String}
     */
    handleSetMessage(value) {
      this.message = value
    },
    /**
     * Setea un valor inicial para los paises locales
     * @param {Object | Null} countryCompany
     * @param {String} countryCompany.code
     * @param {String} countryCompany.name
     */
    setInitialValueToLocalCountries(countryCompany) {
      const countries = this.setInitialValueToCountries(countryCompany)
      this.localCountries = countries
    },
    handleLoadMoreCountries() {
      if (!this.loadMoreCountries) {
        this.loadMoreCountries = true

        let indexStart = this.localCountries.length - 1
        // para que el listado de paises encontrados no se vea de forma bruca
        setTimeout(() => {
          this.localCountries.push(
            [...this.countriesNotRepeatByName].slice(
              indexStart,
              indexStart + 10
            )
          )
          indexStart = indexStart + 10
        }, 100)
        setTimeout(() => {
          this.localCountries = [...this.countriesNotRepeatByName]
        }, 200)
      }
    },
    /**
     * Busca por pais
     * @param {String}
     */
    handleSearchCountry(value) {
      const countries = this.searchCountry(value)
      this.localCountries = countries
    },
    /**
     * Escucha el cambio de avatar
     * @param {Object} args
     * @param {File} args.file
     * @param {String} args.imageUrl
     */
    onChangeAvatar({ file, imageUrl }) {
      this.avatar.default = false
      this.avatar.file = file
      this.avatar.avatar = imageUrl
    },
  },
}
</script>

<style scoped></style>
