import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import router from '@/router'

const actions = {
  /**
   * Comienza webchat
   */
  async startWebchat(context) {
    try {
      const response = await axios.post(`${vari.UHR}/webchat/start`)
      if (response.data.success)
        context.commit('SET_WEBCHAT_ID', response.data.result._id)
    } catch (err) {
      return err?.response?.data
    }
  },
  /**
   * Actualiza el webchat
   * @param {vuex}    context
   * @param {Object}  args
   * @param {Object}  args.newValues
   * @param {String}  args.action - activate
   * @returns {Object}
   */
  async updatedWebchat(context, { newValues, action }) {
    try {
      const formData = new FormData()
      const idWebchat = context.getters.webchat_id
      for (const property in newValues) {
        let value = newValues[property]
        if (Array.isArray(value)) value = JSON.stringify(value)
        formData.append(property, value)
      }
      const response = await axios.put(
        `${vari.UHR}/webchat/${idWebchat}?action=${action}`,
        formData
      )
      // obtiene a company
      if (action && action === 'activate') {
        context.commit('SET_WEBCHAT_ID', idWebchat)
        context.dispatch('getCompanyPricing', false)
        context.dispatch('getSimpleCompanyChannels') // Listar los canales disponibles de la empresa
      }
      if (response.data.success) {
        context.commit('SET_WEBCHAT', response.data.result)
      }
      return response.data
    } catch (err) {
      return err?.response?.data
    }
  },
  /**
   * Enviar el script
   * @param {*} context
   * @param {string} email
   */
  async sendScriptWebchat(context, email) {
    try {
      const webchatId = context.getters.webchat_id
      const response = await axios.post(
        `${vari.UHR}/webchat/send-script-email`,
        { webchatId, email }
      )
      return response.data
    } catch (err) {
      return err?.response?.data
    }
  },
  /**
   * Eliminar webchat
   * @param {*} context
   * @returns {Object}
   */
  async deleteWebchat(context) {
    try {
      const webchatId = context.getters.webchat_id
      const response = await axios.delete(`${vari.UHR}/webchat/${webchatId}`)
      if (response.data.success) {
        // Elimina el canal de la lista de canales simples
        context.commit('DELETE_SIMPLE_COMPANY_CHANNEL', webchatId)
        // hace la peticion para verificar el permiso del canal
        context.dispatch('getCompanyPricing', false)

        router.push({ name: 'channels' })
      }
      context.commit('RESET_WEBCHAT_DEMO')
      return response.data
    } catch (err) {
      return err?.response?.data
    }
  },
}
export default actions
