<template>
  <div class="display-flex direction-row flex-wrap">
    <color-pick
      v-for="color in colors"
      :key="color"
      :color="color"
      :selectable="true"
      size="custom"
      shape="round"
      :has-check="true"
      :is-selected="localColorSelected === color"
      @onChange="handleChangeColor"
    />
    <a-form :form="form" class="picker-custom">
      <a-form-item>
        <a-input
          v-decorator="[
            'codeColor',
            {
              rules: [
                {
                  pattern: /^(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/gm,
                  message: 'Debe ser un color hexadecimal.',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                {
                  pattern: /[^[F|f]{3,6}$/gm,
                  message: 'No se acepta el color blanco.',
                },
              ],
            },
          ]"
          prefix="#"
          placeholder="E8E8E8"
          class="picker-custom__input"
          :maxLength="6"
          @input="handleChangeCustomColor(`#${$event.target.value}`)"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import ColorPick from '@/app/shared/components/pickers/ColorPick'

export default {
  name: 'ColorPicker',
  components: {
    ColorPick,
  },
  props: {
    defaultColor: {
      type: String,
      default: '#1890FF',
      required: false,
    },
  },
  data: () => ({
    colors: [
      '#1890FF', // blue_6
      '#597EF7', // geek_blue_5
      '#9254DE', // purple_5
      '#EB2F96', // magenta_6
      '#F5222D', // red_6
      '#FA8C16', // orange_6
      '#FADB14', // yellow_6
      '#52C41A', // polar-green_6
    ],
    localColorSelected: null,
    codeColor: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'color-picker-form' })
  },
  watch: {
    /**Observa si default color cambia y setea un color */
    defaultColor(val) {
      if (this.colors.includes(val)) this.localColorSelected = val
      else {
        this.codeColor = this.removeHashFromColor(val)
        this.form.setFieldsValue({ codeColor: this.codeColor })
      }
    },
  },
  computed: {
    /**
     * @return {String} color customizado
     */
    customColor() {
      return this.codeColor ? `#${this.codeColor}` : '#C4C4C4'
    },
  },
  methods: {
    /**
     * Cambia de color y emite el color seleccionado
     * @param {String} color
     */
    handleChangeColor(color) {
      if (this.codeColor) this.codeColor = null
      this.localColorSelected = color
      this.$emit('onChange', color)
      this.$emit('onError', null)
      this.form.resetFields()
    },
    handleChangeCustomColor(color) {
      this.localColorSelected = color
      this.form.validateFields(async (err, values) => {
        if (!err) this.$emit('onChange', `#${values.codeColor}`)
        this.$emit('onError', err)
      })
    },
    /**
     * Remueve # del color
     * @return {String} color sin #
     */
    removeHashFromColor(color) {
      return color.slice(1)
    },
  },
}
</script>

<style lang="sass" scoped>
.picker-custom
  margin-top: 24px
.picker-custom__input
  margin-right: 12px
  width: 114px
</style>
