import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const initialState = () => ({
  listCustomFields: [], // lista de los campos personalizados
})

const state = initialState

const getters = {
  listCustomFields: (state) => state.listCustomFields,
  company_custom_fields_tickets: (state) =>
    state.listCustomFields.filter(
      (customField) => customField.module === 'workspace'
    ),
  company_custom_fields_clients: (state) =>
    state.listCustomFields.filter(
      (customField) => customField.module === 'clients'
    ),
}

const mutations = {
  /**
   * [SETTINGS] Setea los campos personalizados
   * @param {*} state
   * @param {Array} fields
   */
  SET_COMPANY_CUSTOM_FIELDS(state, fields) {
    state.listCustomFields = fields
  },
  /**
   * [SETTINGS] Actualiza el valor de un campo personalizado existente
   * @param {*} state
   * @param {Object} field
   * @param {String} field.name
   * @param {String} field.type
   * @param {String} field.options - solo para el tipo dropdown
   * @param {Boolean} field.multiple - solo para el tipo dropdown
   */
  UPDATE_COMPANY_CUSTOM_FIELD(state, field) {
    let foundField = state.listCustomFields.find(
      (elem) => elem._id === field._id
    )
    if (!foundField) return
    if (foundField.name) foundField.name = field.name
    if (foundField.type) foundField.type = field.type
    if (foundField.options) foundField.options = field.options
    if (foundField.type === 'dropdown') foundField.multiple = field.multiple
  },
  /**
   * [SETTINGS] Añade un campo personalizado
   * @param {*} state
   * @param {Object} field
   * @param {String} field.name
   * @param {String} field.type
   * @param {String} field.options - solo para el tipo dropdown
   * @param {Boolean} field.multiple - solo para el tipo dropdown
   */
  ADD_COMPANY_CUSTOM_FIELD(state, field) {
    const foundField = state.listCustomFields.some(
      (elem) => elem._id === field._id
    )
    if (foundField) return

    state.listCustomFields.push(field)
  },
  /**
   * [SETTINGS] Remueve un campo personalizado
   * @param {*} state
   * @param {String} idField
   */
  REMOVE_COMPANY_CUSTOM_FIELD(state, idField) {
    state.listCustomFields = state.listCustomFields.filter(
      (elem) => elem._id !== idField
    )
  },
  /**
   * [SETTINGS] Setea los campos personalizados
   * @param {*} state
   * @param {Object[]} fields
   * @param {String} fields[].name
   * @param {String} fields[].type
   * @param {String} fields[].options - solo para el tipo dropdown
   * @param {Boolean} fields[].multiple - solo para el tipo dropdown
   */
  SET_CUSTOM_FIELDS_CLIENTS(state, fields) {
    state.listCustomFields = fields
  },
}

const actions = {
  /**
   * Crea un campo personalizado
   * @param {*} context
   * @param {Object} field
   * @param {String} field.name
   * @param {String} field.type - date, text, dropdown
   * @param {Array} field.options
   * @param {String} field.options.name - nombre de la opcion
   * @param {Boolean} field.multiple - solo para type dropdown
   * @param {String} field.module - products, workspace, clients
   */
  async createCustomField(context, { name, type, options, multiple, module }) {
    try {
      const response = await axios.post(`${vari.UHR}/custom-fields`, {
        name,
        type,
        options,
        multiple,
        module,
      })
      if (response.data.success) {
        switch (module) {
          case 'workspace':
          case 'clients':
            context.commit(
              'ADD_COMPANY_CUSTOM_FIELD',
              response.data.customField
            )
            break
          case 'products':
            context.commit('ADD_FIELD_PRODUCT', response.data.customField)
            break
        }
      }
      return response.data
    } catch (error) {
      console.error('[createCustomField]', error)
      return error.response.data
    }
  },
  /**
   * Obtiene la lista de los campos personalizados
   * @param {*} context
   */
  async getListCustomFields(context) {
    try {
      const response = await axios.get(`${vari.UHR}/custom-fields`)
      context.commit('SET_COMPANY_CUSTOM_FIELDS', response.data)
    } catch (error) {
      console.error('get list custom', error)
    }
  },
  /**
   * Editar un campo personalizado
   * @param {*} context
   * @param {Object} customField
   * @param {String} customField.idCustomField
   * @param {String} customField.name
   * @param {String} customField.type - date, text, dropdown
   * @param {Array} customField.options
   * @param {String} customField.options[].name
   * @param {Boolean} customField.multiple - solo si es de type dropdown
   * @param {String} customField.module - products, workspace
   */
  async updateCustomField(
    context,
    { idCustomField, name, type, options, multiple, module }
  ) {
    try {
      const response = await axios.put(
        `${vari.UHR}/custom-fields/${idCustomField}`,
        { name, type, options, multiple, module }
      )
      if (response.data.success)
        switch (module) {
          case 'workspace':
          case 'clients':
            context.commit(
              'UPDATE_COMPANY_CUSTOM_FIELD',
              response.data.customField
            )
            break
          case 'products':
            context.commit('UPDATE_CUSTOM_FIELD', response.data.customField)
            break
        }
      return response.data
    } catch (error) {
      console.error('editCustomField', error)
      return error.response
    }
  },
  /**
   * Elimina un campo personalizado
   * @param {*} context
   * @param {Object} customField
   * @param {String} customField.idCustomField
   * @param {String} customField.module - products, workspace
   */
  async deleteCustomField(context, { idCustomField, module }) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/custom-fields/${idCustomField}`
      )
      if (response.data.success) {
        switch (module) {
          case 'workspace':
          case 'clients':
            context.commit('REMOVE_COMPANY_CUSTOM_FIELD', idCustomField)
            break
          case 'products':
            context.commit('REMOVE_FIELD_PRODUCT', idCustomField)
            break
        }
      }
      return response.data
    } catch (error) {
      console.error('deleteCustomField', error)
      return error.response
    }
  },
}

export default { state, getters, mutations, actions }
