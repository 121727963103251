<template>
  <audio :src="src" :controls="controls" class="audio-message"></audio>
</template>

<script>
export default {
  name: 'AudioMessage',
  props: {
    src: {
      type: String,
      required: true,
      default: null,
    },
    controls: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.audio-message
  min-width: 100px
  max-width: 100%
</style>
