<template>
  <div class="active-account">
    <ActiveAccountTemplate />
  </div>
</template>

<script>
import ActiveAccountTemplate from '@/app/signup/components/templates/ActiveAccountTemplate.vue'

export default {
  name: 'ActiveAccount',
  components: {
    ActiveAccountTemplate,
  },
}
</script>
<style lang="sass" scoped>
.active-account
</style>
