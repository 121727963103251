import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /** Lista Los Roles de la Empresa */
  async listRoles(context) {
    try {
      const result = await axios.get(`${vari.UHR}/admin/roles`)
      const roles = result.data
      context.commit('SET_ROLES', roles)
    } catch (error) {
      console.error('[roleModule][listRoles]', error)
    }
  },
}

export default actions
