<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="$emit('onClose')"
    width="34em"
    :bodyStyle="bodyStyle"
    :afterClose="handleReset"
    centered
  >
    <a-icon type="delete" class="modal__icon" />
    <h6 class="heading-h6 modal__title text--center">{{ title }}</h6>
    <p class="regular-14 modal__description">
      Esta varibale ya no podrá ser utilizada en los nuevos flujos de tus
      chatbots, pero seguirán activos en donde ya han sido empleados. Esta
      variable se encuentra activa en los siguientes chatbots:
    </p>
    <a-list
      size="small"
      class="modal__list"
      :data-source="list.data"
      :pagination="pagination"
      :loading="list.loading"
      :locale="{ emptyText: 'No hay chatbots que usen esta variable' }"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        {{ item.name }}
      </a-list-item>
      <div slot="header">
        <h6 class="semibold-14 mrg-bottom-0">Chatbots</h6>
      </div>
    </a-list>
    <a-button
      class="modal__button"
      type="danger"
      @click="handleDeleteVar"
      :disabled="button.disabled"
      :loading="button.loading"
      >Eliminar</a-button
    >
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalVarDelete',
  mixins: [transformMixin],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    varName: {
      type: String,
      required: true,
    },
    varId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 36px',
    },
    button: {
      loading: false,
      disabled: false,
    },
    list: {
      page: 1,
      loading: false,
      data: [],
      pagination: { total: 0, page: 1 },
    },
  }),
  watch: {
    varId() {
      if (this.visible) this.handleChangeList(this.list.page)
    },
  },
  computed: {
    ...mapGetters(['profile']),

    /**
     * @return {String}
     */
    title() {
      if (!this.profile) return ''
      return `¿Estás  ${this.transformWordGender(
        'seguro',
        this.profile.gender
      )} de eliminar la variable "${this.varName}"?`
    },
    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 4,
        total: this.list.pagination.total || 0,
        current: this.list.pagination.page,
        onChange: (page) => this.handleChangeList(page),
      }
    },
  },
  methods: {
    ...mapActions(['getChatbotsWithVarDelete', 'deleteChatbotVar']),

    /**
     * Escucha el cambio de la lista
     * @param {Number} page
     */
    async handleChangeList(page) {
      this.list.loading = true
      const response = await this.getChatbotsWithVarDelete({
        page,
        chatbotVarId: this.varId,
      })
      if (response.success) {
        this.list.data = response.data.data
        delete response.data.data
        this.list.pagination = response.data
      }
      this.list.loading = false
    },
    /**
     * Ejecuta la eliminacion de una variable
     */
    async handleDeleteVar() {
      this.button.loading = true
      this.button.disabled = true
      const response = await this.deleteChatbotVar(this.varId)
      this.button.loading = false
      this.button.disabled = false
      if (response.success) {
        this.$message.success('Se eliminó exitosamente')
        this.$emit('onClose')
        this.handleReset()
      } else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Resetea el modal
     */
    handleReset() {
      this.button.loading = false
      this.button.disabled = false
      this.list = {
        page: 1,
        loading: false,
        data: [],
        pagination: { total: 0, page: 1 },
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__icon
    font-size: 64px
    color: $red_6
  &__title
    margin: 16px auto
  &__description
    text-align: left
    width: 100%
    margin: 0px 0px 8px
  &__button
    width: 100%
  &__list
    width: 100%
    min-height: 200px
</style>
