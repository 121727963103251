<template>
  <a-row
    class="buttons"
    style="margin-top: 12px"
    type="flex"
    :gutter="[12, 12]"
    justify="end"
  >
    <a-col
      v-for="item in buttons"
      :key="item.idButton"
      :class="{ 'flex-grow': channel === 'whatsapp' }"
    >
      <component :is="button" :type="item.type || null">
        {{ item.text }}
      </component>
    </a-col>
  </a-row>
</template>

<script>
import ButtonMsg from './ButtonMsg'
import ButtonWhatsApp from './ButtonWhatsApp'

export default {
  name: 'ButtonsMessage',
  components: {
    ButtonMsg,
    ButtonWhatsApp,
  },
  props: {
    channel: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    button() {
      switch (this.channel) {
        case 'whatsapp':
          return ButtonWhatsApp
        default:
          return ButtonMsg
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.buttons
  width: 100%
  margin-top: 12px
  flex-wrap: wrap
</style>
