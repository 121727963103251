<template>
  <div class="form-choose-downgrade">
    <div class="form-choose-downgrade-content">
      <a-modal
        :visible="visible"
        width="440px"
        :footer="null"
        :closable="false"
        centered
        class="form-choose-downgrade-content-modal"
      >
        <a-row class="icon">
          <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5518 7.21948L12.7806 3.44824L3.44727 12.7816L7.2185 16.5528L16.5518 7.21948ZM47.4473 7.21948L51.2185 3.44824L60.5518 12.7816L56.7806 16.5528L47.4473 7.21948ZM31.9994 58.6671C17.2718 58.6671 5.33276 46.728 5.33276 32.0004C5.33276 17.2728 17.2718 5.33373 31.9994 5.33373C46.727 5.33373 58.6661 17.2728 58.6661 32.0004C58.6661 46.728 46.727 58.6671 31.9994 58.6671ZM31.9994 53.3337C43.7815 53.3337 53.3328 43.7825 53.3328 32.0004C53.3328 20.2183 43.7815 10.6671 31.9994 10.6671C20.2173 10.6671 10.6661 20.2183 10.6661 32.0004C10.6661 43.7825 20.2173 53.3337 31.9994 53.3337ZM45.3328 29.3337H34.6661V16.0004H29.3328V34.6671H45.3328V29.3337Z"
              fill="#FA8C16"
            />
          </svg>
        </a-row>
        <a-row class="title">
          <h2 class="heading-h2">
            Estás a
            {{ minimalQuantityDay == 0 ? 'pocos' : minimalQuantityDay }} días de
            bajar de plan
          </h2>
          <p class="body-2">Selecciona los datos que se quedarán contigo</p>
        </a-row>
        <a-row
          ><p>
            <span
              >Los datos que no se muestren, se eliminarán de acuerdo a la fecha
              de creación.</span
            >&nbsp;
            <a :href="vari.WEB_DOC" target="_blank" rel="noopener noreferrer">
              Ver más detalles
            </a>
          </p></a-row
        >
        <a-row class="form">
          <div>
            <template v-if="localPlan && localPlan.key == 'free'">
              <a-row>
                <p>Selecciona un segundo usuario</p>
                <a-select
                  class="input"
                  placeholder="Seleccione un usuario"
                  style="width: 100%"
                  v-model="userSelected"
                >
                  <a-select-option
                    v-for="user in usersToSelect"
                    :key="user.userId"
                    :value="user.userId"
                    >{{ user.names + ' ' + user.surnames }}</a-select-option
                  >
                </a-select>
              </a-row>
              <a-row>
                <p>Selecciona 1 cola adicional a la general</p>
                <a-select
                  class="input"
                  placeholder="Seleccione cola"
                  style="width: 100%"
                  :filter-option="true"
                  v-model="lineSelected"
                >
                  <a-select-option
                    v-for="line in availableLines"
                    :key="line._id"
                  >
                    {{ line.name }}
                  </a-select-option>
                </a-select>
              </a-row>
              <a-row><p>Selecciona 1 chatbot</p></a-row>
              <a-select
                v-if="chatbots"
                class="input"
                placeholder="Seleccione un chatbot"
                style="width: 100%"
                v-model="chatbotSelected"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
              >
                <a-select-option
                  v-for="(chatbot, index) in chatbots"
                  :key="index"
                  :value="chatbot._id"
                  >{{ chatbot.name }}</a-select-option
                >
              </a-select>
            </template>
            <template v-else>
              <a-row>
                <p>Selecciona 4 colas adicionales a la general</p>
                <a-select
                  mode="multiple"
                  label-in-value
                  placeholder="Seleccione colas"
                  style="width: 100%"
                  :filter-option="false"
                  class="input"
                  @change="handleChangeLine"
                >
                  <a-select-option
                    v-for="line in availableLines"
                    :key="line._id"
                    :disabled="
                      linesSelected.length === 4 &&
                      !linesSelected.includes(line._id)
                    "
                  >
                    {{ line.name }}
                  </a-select-option>
                </a-select>
              </a-row>

              <a-row
                ><p>
                  Selecciona
                  {{ localPlan && localPlan.key === 'advanced' ? 2 : 4 }}
                  chatbot
                </p></a-row
              >
              <a-select
                v-if="chatbots"
                class="input"
                placeholder="Seleccione un chatbot"
                style="width: 100%"
                v-model="chatbotsSelected"
                @search="handleSearchChatbot"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                mode="multiple"
              >
                <a-select-option
                  v-for="(chatbot, index) in chatbots"
                  :key="index"
                  :value="chatbot._id"
                  :disabled="
                    chatbotsSelected &&
                    chatbotsSelected.length === quantityChatbots &&
                    !chatbotsSelected.includes(chatbot._id)
                  "
                  >{{ chatbot.name }}</a-select-option
                >
              </a-select>
            </template>
            <a-row>
              <p>
                Escribe la palabra <span>“{{ keyword }}”</span>
              </p>
              <a-input
                class="input"
                v-model="inputKeyword"
                placeholder="Escribe aqui..."
              />
            </a-row>
            <a-row>
              <a-button
                type="primary"
                :disabled="!buttonEnabled"
                :loading="loading"
                @click="handleOk"
                block
                >Bajar de plan</a-button
              >
            </a-row>
          </div>
        </a-row>
      </a-modal>
    </div>
  </div>
</template>

<script>
import vari from '@/app/shared/utils/variables'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'FormChooseDowngrade',
  props: {
    // Mostrar modal
    visible: {
      type: Boolean,
    },
    // Mínima cantidad de días
    minimalQuantityDay: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      enum: ['downgrade', 'expired'],
    },
    plan: {
      type: Object,
    },
    keyword: {
      type: String,
      default: 'CONFIRMAR',
    },
  },
  mixins: [attemptMixin],
  data: () => ({
    vari: vari,
    inputKeyword: '',
    chatbotSelected: undefined,
    linesSelected: [],
    chatbotsSelected: undefined,
    maxLinesAvailables: 4,
    maxChatbotsAvailables: 1,
    loading: false,
    userSelected: null,
    lineSelected: null,
    localPlan: null,
    usersToFilter: [],
  }),
  async created() {
    await this.getAccountsUsers()
    this.intervalAttempt(() => {
      if (!this.company) throw 'Not company found'
      this.handleGetChatbots()
    })
    if (this.type == 'expired') {
      const actualDowngrade = await this.getActualDowngrade()
      this.localPlan = actualDowngrade.planTo
      this.usersToFilter = await this.getUsersToSelectInDowngrade()
    } else {
      this.localPlan = this.plan
      this.usersToFilter = this.agentsAccounts
    }
  },

  computed: {
    ...mapGetters([
      'lines',
      'profile',
      'agentsAccounts',
      'company',
      'chatbots',
    ]),
    buttonEnabled() {
      return this.keyword === this.inputKeyword
    },
    availableLines() {
      return this.lines.filter((line) => !line.default)
    },
    usersToSelect() {
      return this.usersToFilter.filter(
        (user) => user.userId !== this.profile.userId
      )
    },
    quantityChatbots() {
      return this.localPlan && this.localPlan.key === 'advanced' ? 2 : 4
    },
  },
  methods: {
    ...mapActions([
      'getListChatbots',
      'downgrade',
      'getCompanyPricing',
      'getAccountsUsers',
      'getActualDowngrade',
      'getUsersToSelectInDowngrade',
    ]),
    ...mapMutations(['SET_EXPIRATION_PLAN_MODAL']),
    async handleOk() {
      this.loading = true
      // this.SET_EXPIRATION_PLAN_MODAL(false)
      // Crear downgrade en el market
      const res = await this.downgrade({
        type: this.type,
        pricingId: this.localPlan._id,
        featuresSelected: {
          lines: this.lineSelected ? [this.lineSelected] : this.linesSelected,
          user: this.userSelected,
          chatbots: this.chatbotSelected
            ? [this.chatbotSelected]
            : this.chatbotsSelected,
        },
      })

      if (res.success !== true) {
        this.$message.error('Hubo un error al realizar el downgrade')
        this.loading = false
      } else {
        this.getCompanyPricing()
        this.$message.success('Plan actualizado correctamente')
        this.$emit('hide')
      }
    },
    handleChangeLine(value) {
      this.linesSelected = value.map((item) => item.key)
    },
    handleChangeChatbot(value) {
      this.chatbotsSelected.push(value)
    },
    handleSearchChatbot(value) {
      this.handleGetChatbots(value)
    },
    async handleGetChatbots(name) {
      await this.getListChatbots({
        page: 1,
        status: 'all',
        name: name ? name : '',
        channel: '',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.form-choose-downgrade
  &-content
    &-modal
      color: $gray_dark_800
      .icon
        text-align: center
        svg
          margin: 0 auto
          width: 64px
          height: 64px
      .title
        text-align: center
        h2
          font-size: 20px
          font-weight: 600
      .form
        p
          margin: 0
        .input
          margin: 0 0 24px 0
</style>
