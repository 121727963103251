<template>
  <a-dropdown v-model="visibleMenu" :trigger="['click']">
    <span @click="visibleMenu = !visibleMenu">
      <slot>
        <span class="dropdown__container-icon--more">
          <i class="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5a2 2 0 104 0 2 2 0 00-4 0zm2 9a2 2 0 110-4 2 2 0 010 4zm0 7a2 2 0 110-4 2 2 0 010 4z" fill="currentColor"/></svg>
          </i>
        </span>
      </slot>
    </span>
    <!--Menu de las acciones principales-->
    <a-menu
      v-if="currentMenu === 'menu-main'"
      @click="handleSelectMenu"
      slot="overlay"
    >
      <a-menu-item
        v-if="showMenuFavorite"
        key="item-favorite"
        @click="$emit('onMarkFavorite')"
        class="dropdown-item"
      >
        <a-icon
          type="star"
          :theme="isFavorite ? 'outlined' : 'filled'"
          class="dropdown__icon"
          :class="{ 'dropdown__icon--star': !isFavorite }"
        />
        {{ isFavorite ? 'Desmarcar como favorito' : 'Marcar favorito' }}
      </a-menu-item>
      <a-menu-item
        key="menu-transfer"
        class="dropdown-item"
        @click="handleShowMenuTransfer"
      >
        <a-icon type="swap" class="dropdown__icon" /> Transferir ticket
      </a-menu-item>
      <a-menu-item
        v-if="showMenuSchedule"
        key="item-schedule"
        class="dropdown-item"
        @click="$emit('onSchedule')"
      >
        <a-icon type="calendar" class="dropdown__icon" />
        Agendar evento
      </a-menu-item>
      <a-menu-item
        key="menu-mailbox"
        class="dropdown-item"
        @click="handleShowMenuMailbox"
      >
        <a-icon type="inbox" class="dropdown__icon" /> Cambiar de buzón
      </a-menu-item>
      <a-menu-item
        key="item-unread"
        class="dropdown-item"
        @click="$emit('onUnread')"
      >
        <i class="anticon dropdown__icon">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.385 2.998L2.38 1 1 2.44l.895.892a1.99 1.99 0 00-.89 1.659v13.95c0 1.1.895 1.992 1.998 1.992h16.543L21.619 23 23 21.56 4.4 3.013l.016-.015h-.031zM3.558 4.99h-.554v2.042l5.171 2.562L3.558 4.99zm8.974 8.948l-.037.02-9.491-4.702v9.683h14.543l-5.015-5zm8.454-6.41l-6.702 3.319 1.49 1.486 5.212-2.582v7.78l1.93 1.924a1.99 1.99 0 00.068-.516V4.991c0-1.1-.895-1.993-1.998-1.993H6.414L8.412 4.99h12.574v2.538z" fill="currentColor"/></svg>
        </i>
        Marcar como no leído
      </a-menu-item>
    </a-menu>
    <!--Menu para tranferir-->
    <a-menu
      v-else-if="currentMenu === 'menu-transfer'"
      slot="overlay"
      @click="handleSelectMenu"
    >
      <a-menu-item class="dropdown-item" @click="handleBack">
        <a-icon type="arrow-left" class="dropdown__icon" /> Volver atrás
      </a-menu-item>
      <a-menu-item
        v-if="showMenuAgents"
        key="item-transfer-agent"
        class="dropdown-item"
        @click="$emit('onTransferToAgent')"
      >
        Transferir a un agente
      </a-menu-item>
      <a-menu-item
        key="item-transfer-line"
        class="dropdown-item"
        @click="$emit('onTransferToLine')"
      >
        Transferir a una cola
      </a-menu-item>
    </a-menu>
    <!--Menu para buzones-->
    <a-menu
      v-else-if="currentMenu === 'menu-mailbox'"
      slot="overlay"
      @click="handleSelectMenu"
      style="max-height: 300px; overflow-y: auto; background-clip: initial"
      class="scrollbar_basic"
    >
      <a-menu-item class="dropdown-item" @click="handleBack">
        <a-icon type="arrow-left" class="dropdown__icon" /> Volver atrás
      </a-menu-item>
      <a-menu-item
        v-for="mailbox in dataSourceMailboxes"
        :key="`item-mailbox_${mailbox.idMailbox}`"
        class="dropdown-item"
        @click="$emit('onMoveMailbox', mailbox)"
      >
        Cambiar a {{ mailbox.title }}
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  name: 'MenuActionsToTicket',
  props: {
    dataSourceMailboxes: {
      type: Array,
      required: true,
    },
    showMenuAgents: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMenuFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMenuSchedule: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    currentMenu: 'menu-main', // 'menu-main', 'menu-mailbox', 'menu-transfer'
    visibleMenu: false,
  }),
  methods: {
    /**
     * Escucha cuando se selecciona un menu
     */
    handleSelectMenu(e) {
      const keys = [
        'item-unread',
        'item-transfer-agent',
        'item-transfer-line',
        'item-favorite',
        'item-schedule',
      ]
      if (keys.includes(e.key) || e.key.includes('item-mailbox_')) {
        this.visibleMenu = false
        this.currentMenu = 'menu-main'
      }
    },
    /**
     * Regresa al menu principal
     */
    handleBack() {
      this.currentMenu = 'menu-main'
    },
    /**
     * Muestra el menu de transferencia
     */
    handleShowMenuTransfer() {
      this.currentMenu = 'menu-transfer'
    },
    /**
     * Muestra el menu de buzones
     */
    handleShowMenuMailbox() {
      this.currentMenu = 'menu-mailbox'
    },
  },
}
</script>

<style lang="sass" scoped>

.dropdown__icon
  font-size: 20px!important
.dropdown__icon--star
  color: $yellow_6
.dropdown-item
  padding: 12px 12px
</style>
