<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :bodyStyle="bodyStyle"
    width="32em"
    @cancel="handleCancel"
    centered
  >
    <div class="upgrade-modal">
      <!-- <a-icon type="fire" style="font-size: 72px" /> -->
      <img
        svg-inline
        :src="require(`@/app/commons/assets/${plan}.svg`)"
        width="120px"
        class="modal__picture"
      />
      <h5 class="heading-h5 title">{{ title }}</h5>
      <h6 class="heading-h6 description">{{ description }}</h6>
      <ul class="benefits">
        <template v-for="(benefit, index) in benefits">
          <li :key="`benefit-${index}`" class="benefit">
            <a-icon :type="benefit.nameIcon" style="font-size: 24px" />
            <p class="body-2">{{ benefit.description }}</p>
          </li>
        </template>
      </ul>
      <a
        :href="vari.WEB_PRICING"
        target="_blank"
        class="link"
        rel="noopener noreferrer"
        >y muchos beneficios más</a
      >
      <template v-if="showButtons">
        <a-button
          type="primary"
          class="modal__button"
          @click="onUpgrade"
          :disabled="disabledOk"
          >Subir de plan</a-button
        >
        <a class="link-cancel" @click="handleCancel">Quizás más tarde</a>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'UpgradeModal',
  props: {
    title: {
      // titulo, podria ser el nombre del plan
      type: String,
      require: false,
      default: 'Upgrade',
    },
    description: {
      type: String,
      require: false,
      default: 'Subir de plan es super facil',
    },
    benefits: {
      // lista de los beneficios de un plan
      type: Array,
      require: false,
      default: () => [{ nameIcon: 'robot', description: '1 chatbot gratis' }],
    },
    visible: {
      // hacer visible o no el modal
      type: Boolean,
      require: false,
      default: false,
    },
    plan: {
      type: String,
      require: false,
      default: 'free',
    },
  },
  data: () => ({
    bodyStyle: {
      padding: '40px 36px',
    },
    vari: vari,
  }),
  mixins: [compareMixin],
  computed: {
    ...mapGetters(['profile', 'active_breakpoint']),

    /**
     * @return {Boolean} - solo se muestran los botones para los administradores
     */
    showButtons() {
      // si el usuario es solo agente no debería ver el boton de subir de plan
      return this.isAllowedFor(this.profile.type, ['admin', 'master'])
    },
    /**
     * @return {Boolean} - solo se habilita si esta en desktop
     */
    disabledOk() {
      return this.active_breakpoint.is_mobile
    },
  },
  methods: {
    handleCancel() {
      this.$emit('handleClose')
      this.$store.dispatch('setAnalytics', {
        eventName: 'close_modal_upgrade',
      })
    },
    onUpgrade() {
      this.$emit('onUpgrade')
    },
  },
}
</script>

<style lang="sass" scoped>
.upgrade-modal
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .title
    margin-top: 20px
    margin-bottom: 4px
    color: $gray_dark_900
  .description
    margin-bottom: 20px
    color: $gray_dark_800
  .benefits
    padding: 0px
    margin-bottom: 0px
    margin-right: -24px
    display: flex
    flex-direction: row
    justify-content: center
    flex-wrap: wrap
    .benefit
      list-style: none
      display: inline-flex
      align-items: center
      color: $gray_dark_900
      margin-bottom: 8px
      margin-right: 24px
      p
        margin-bottom: 0px
        margin-left: 8px
        color: $gray_dark_800
  .link
    margin-bottom: 24px
  .link-cancel
    margin-top: 12px
    color: $gray_dark_800
.modal__picture
  cursor: auto
.modal__button
  width: 100%
</style>
