import { mapActions } from 'vuex'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  methods: {
    ...mapActions([
      'deleteChild',
      'deleteSubchild',
      'deleteNode',
      'updateChild',
      'updateNodeMessage',
      'updateNodeCatcher',
    ]),

    /**
     * Nodo que se eliminará
     * @param {Object} args
     * @param {String} args.type - tipo de nodo padre o hijo que se eliminará
     * @param {String} args.nodeId - id de nodo padre o hijo que se eliminará
     * @param {String} args.childId - id del nodo padre que lo contiene
     * @param {String} args.subchildId
     * @param {String} args.parentNodeId
     * @param {String} args.parentNodeType
     * @returns
     */
    handleShowConfirmToDelete({
      type,
      nodeId,
      childId,
      subchildId,
      parentNodeId,
      parentNodeType,
    }) {
      switch (type) {
        case 'message':
          this.handleShowConfirmToDeleteMessage(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'options':
          this.handleShowConfirmToDeleteOptions(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'option':
          this.handleShowConfirmToDeleteOption(
            childId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'catcher':
          this.handleShowConfirmToDeleteCatcher(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'buttons':
          this.handleShowConfirmToDeleteButtons(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'button':
          this.handleShowConfirmToDeleteButton(
            childId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'cards':
          this.handleShowConfirmToDeleteCards(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'card':
          this.handleShowConfirmToDeleteCard(
            childId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'card_button':
          this.handleShowConfirmToDeleteButtonCard(
            childId,
            subchildId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'lists':
          this.handleShowConfirmToDeleteLists(
            nodeId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'list_section':
          this.handleShowConfirmToDeleteSection(
            childId,
            parentNodeId,
            parentNodeType
          )
          break
        case 'list_item':
          this.handleShowConfirmToDeleteItem(
            childId,
            subchildId,
            parentNodeId,
            parentNodeType
          )
          break
        default:
          return null
      }
    },
    /**
     * Modal de confirmacion
     * @param {String} title
     * @param {String} content
     * @param {String} okText
     * @param {String} cancelText
     * @param {Function} onOkey
     * @param {Function} onCancel
     */
    handleShowConfirm(title, content, okText, fnOk) {
      this.$confirm({
        title: title,
        content: content,
        okText: okText,
        cancelText: 'Cancelar',
        onOk() {
          fnOk()
        },
        centered: true,
        okType: 'danger',
      })
    },
    /**
     * Muestra confirmacion para eliminar un nodo mensaje
     * @param {String} nodeId
     * @param {String} parentNodeId
     */
    handleShowConfirmToDeleteMessage(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar este Mensaje?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo opciones
     * @param {String} nodeId
     * @param {String} parentNodeId
     */
    handleShowConfirmToDeleteOptions(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar esta Opción múltiple?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo opciones
     * @param {String} nodeId
     * @param {String} parentNodeId
     */
    handleShowConfirmToDeleteButtons(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar este nodo Botones?',
        'Recuerda que se eliminarán todas los mensajes pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo opcion
     * @param {String} childId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteOption(childId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar esta Opción?',
        'Recuerda que se eliminará su configuración y se removerá del listado de opciones múltiples.',
        'Eliminar',
        () => this.handleDeleteChild(childId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo botones
     * @param {String} childId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteButton(childId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar esta Botón?',
        'Recuerda que se eliminará su configuración y se removerá del listado de botones.',
        'Eliminar',
        () => this.handleDeleteChild(childId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo catcher
     * @param {String} nodeId
     * @param {String} parentNodeId
     */
    handleShowConfirmToDeleteCatcher(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar Capturar variable?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo cards
     * @param {String} nodeId
     * @param {String} parentNodeId
     */
    handleShowConfirmToDeleteCards(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar las Tarjetas con botones?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo card
     * @param {String} childId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteCard(childId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar la Tarjeta?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteChild(childId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo boton en el card
     * @param {String} childId
     * @param {String} subchildId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteButtonCard(
      childId,
      subchildId,
      parentNodeId,
      parentNodeType
    ) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar el Botón de la tarjeta?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () =>
          this.handleDeleteSubchild(
            childId,
            subchildId,
            parentNodeId,
            parentNodeType
          )
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo listas
     * @param {String} nodeId
     * @param {String} parentNodeId - id del nodo padre que lo contiene
     * @param {String} parentNodeType - tipo del nodo padre que lo contiene
     */
    handleShowConfirmToDeleteLists(nodeId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar este nodo Listas?',
        'Recuerda que se eliminarán todas los mensajes pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo card
     * @param {String} childId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteSection(childId, parentNodeId, parentNodeType) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar la Sección de Listas?',
        'Recuerda que se eliminarán todas los items pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteChild(childId, parentNodeId, parentNodeType)
      )
    },
    /**
     * Muestra confirmacion para eliminar un nodo boton en el card
     * @param {String} childId
     * @param {String} subchildId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    handleShowConfirmToDeleteItem(
      childId,
      subchildId,
      parentNodeId,
      parentNodeType
    ) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar el Item de la Sección?',
        'Recuerda que se eliminarán todos los Items pertenecientes así como sus configuraciones.',
        'Eliminar',
        () =>
          this.handleDeleteSubchild(
            childId,
            subchildId,
            parentNodeId,
            parentNodeType
          )
      )
    },
    /**
     * Elimina un nodo basico o padre
     * @param {String} nodeId - id del nodo
     * @param {String} parentNodeId - id de su padre
     * @param {String} parentNodeType
     */
    async handleDeleteNode(nodeId, parentNodeId, parentNodeType) {
      try {
        if (parentNodeId !== undefined) {
          // si no tiene el tipo de nodo
          if (!parentNodeType) {
            const node = this.nodes.find((node) => node._id === parentNodeId)
            if (!node) return
            const nodeParent = {
              message: node.drawer.question.content,
              wait: node.drawer.action.wait ? node.drawer.action.wait : false,
              type_action: 'none',
              nodeId_action: node.drawer.action.nodeId,
              lineId_action: node.drawer.action.lineId
                ? node.drawer.action.lineId
                : null,
              message_action: node.drawer.action.message
                ? node.drawer.action.message
                : null,
              withMessage: node.drawer.action.withMessage,
            }
            if (node.drawer.question.type === 'message') {
              await this.updateNodeMessage({
                chatbotId: this.chatbotId,
                nodeId: parentNodeId,
                nodeMessage: nodeParent,
              })
            } else if (node.drawer.question.type === 'catcher') {
              await this.updateNodeCatcher({
                chatbotId: this.chatbotId,
                nodeId: parentNodeId,
                nodeCatcher: {
                  ...nodeParent,
                  chatbotVarId: node.drawer.question.varToSave._id,
                },
              })
            }
          } else {
            switch (parentNodeType) {
              case 'button':
                {
                  const nodesButtons = this.nodes.filter(
                    (node) => node.drawer.question.type === 'buttons'
                  )
                  const nodeButtons = nodesButtons.find((nodeButton) =>
                    nodeButton.drawer.buttons.find(
                      (card) => card.idButton === parentNodeId
                    )
                  )
                  const child = nodeButtons.drawer.buttons.find(
                    (card) => card.idButton === parentNodeId
                  )

                  const newButton = {
                    order: child.order,
                    text: child.text,
                    type_action: 'none',
                    lineId_action: null,
                    message_action: 'Gracias por tu atención',
                    withMessage: child.withMessage,
                  }

                  await this.updateChild({
                    chatbotId: this.chatbotId,
                    nodeId: nodeButtons._id,
                    nodeType: 'buttons',
                    newChild: newButton,
                    childId: child.idButton,
                    childIdProperty: 'idButton',
                  })
                }
                break
              case 'card_button': {
                const nodesCards = this.nodes.filter(
                  (node) => node.drawer.question.type === 'cards'
                )

                let nodeId = null
                let childId = null
                let subchild = null
                // TODO: Modificar esto para despues de release
                for (const nodeCard of nodesCards) {
                  for (const card of nodeCard.drawer.cards) {
                    for (const button of card.buttons) {
                      if (button.idButton === parentNodeId) {
                        nodeId = nodeCard._id
                        childId = card.idCard
                        subchild = button
                      }
                    }
                  }
                }

                await this.updateSubchild({
                  chatbotId: this.chatbotId,
                  nodeId: nodeId,
                  nodeType: 'cards',
                  newSubchild: {
                    ...subchild,
                    type_action: 'none',
                    message_action: 'Gracias por tu atención',
                  },
                  childId: childId,
                  childIdProperty: 'idCard',
                  subchildId: parentNodeId,
                  subchildType: 'buttons',
                  subchildIdProperty: 'idButton',
                })
              }
            }
          }
        }
        const response = await this.deleteNode({
          chatbotId: this.chatbotId,
          nodeId,
        })
        if (response.success)
          this.$message.success('Se eliminó el nodo correctamente')
        else this.$message.error(response.details || 'Ocurrió un error 😥')
      } catch (e) {
        console.error(e)
        this.$message.error(e.message)
      }
    },
    /**
     * Muestra confirmacion para eliminar un nodo hijo
     * @param {String} childId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    async handleDeleteChild(childId, parentNodeId, parentNodeType) {
      const childIdProperty = NODES[parentNodeType].child.id_property

      let response = { success: false }
      response = await this.deleteChild({
        chatbotId: this.chatbotId,
        nodeId: parentNodeId,
        nodeType: parentNodeType,
        childIdProperty,
        childId,
      })
      if (response.success) this.$message.success('Se eliminó el nodo')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Muestra confirmacion para eliminar un nodo subhijo
     * @param {String} childId
     * @param {String} subchildId
     * @param {String} parentNodeId
     * @param {String} parentNodeType
     */
    async handleDeleteSubchild(
      childId,
      subchildId,
      parentNodeId,
      parentNodeType
    ) {
      const childIdProperty = NODES[parentNodeType].child.id_property
      const subchildType = NODES[parentNodeType].child.child.group
      const subchildIdProperty = NODES[parentNodeType].child.child.id_property

      let response = { success: false }
      response = await this.deleteSubchild({
        chatbotId: this.chatbotId,
        nodeId: parentNodeId,
        nodeType: parentNodeType,
        childIdProperty,
        childId,
        subchildId,
        subchildType,
        subchildIdProperty,
      })
      if (response.success) this.$message.success('Se eliminó el nodo')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
