const initialState = () => ({
  globalTimezones: [
    // Algunos pueden variar según horario de verano
    { timezone: 'Pacific/Midway', utc: 'UTC-11' },
    { timezone: 'Pacific/Niue', utc: 'UTC-11' },
    { timezone: 'Pacific/Pago_Pago', utc: 'UTC-11' },
    { timezone: 'America/Adak', utc: 'UTC-10' },
    { timezone: 'Pacific/Honolulu', utc: 'UTC-10' },
    { timezone: 'Pacific/Rarotonga', utc: 'UTC-10' },
    { timezone: 'Pacific/Tahiti', utc: 'UTC-10' },
    { timezone: 'Pacific/Marquesas', utc: 'UTC-9:30' },
    { timezone: 'America/Anchorage', utc: 'UTC-9' },
    { timezone: 'America/Juneau', utc: 'UTC-9' },
    { timezone: 'America/Metlakatla', utc: 'UTC-9' },
    { timezone: 'America/Nome', utc: 'UTC-9' },
    { timezone: 'America/Sitka', utc: 'UTC-9' },
    { timezone: 'America/Yakutat', utc: 'UTC-9' },
    { timezone: 'Pacific/Gambier', utc: 'UTC-9' },
    { timezone: 'America/Los_Angeles', utc: 'UTC-8' },
    { timezone: 'America/Tijuana', utc: 'UTC-8' },
    { timezone: 'America/Vancouver', utc: 'UTC-8' },
    { timezone: 'Pacific/Pitcairn', utc: 'UTC-8' },
    { timezone: 'America/Boise', utc: 'UTC-7' },
    { timezone: 'America/Cambridge_Bay', utc: 'UTC-7' },
    { timezone: 'America/Chihuahua', utc: 'UTC-7' },
    { timezone: 'America/Creston', utc: 'UTC-7' },
    { timezone: 'America/Dawson', utc: 'UTC-7' },
    { timezone: 'America/Dawson_Creek', utc: 'UTC-7' },
    { timezone: 'America/Denver', utc: 'UTC-7' },
    { timezone: 'America/Edmonton', utc: 'UTC-7' },
    { timezone: 'America/Fort_Nelson', utc: 'UTC-7' },
    { timezone: 'America/Hermosillo', utc: 'UTC-7' },
    { timezone: 'America/Inuvik', utc: 'UTC-7' },
    { timezone: 'America/Mazatlan', utc: 'UTC-7' },
    { timezone: 'America/Ojinaga', utc: 'UTC-7' },
    { timezone: 'America/Phoenix', utc: 'UTC-7' },
    { timezone: 'America/Whitehorse', utc: 'UTC-7' },
    { timezone: 'America/Yellowknife', utc: 'UTC-7' },
    { timezone: 'America/Bahia_Banderas', utc: 'UTC-6' },
    { timezone: 'America/Belize', utc: 'UTC-6' },
    { timezone: 'America/Chicago', utc: 'UTC-6' },
    { timezone: 'America/Costa_Rica', utc: 'UTC-6' },
    { timezone: 'America/El_Salvador', utc: 'UTC-6' },
    { timezone: 'America/Guatemala', utc: 'UTC-6' },
    { timezone: 'America/Indiana/Knox', utc: 'UTC-6' },
    { timezone: 'America/Indiana/Tell_City', utc: 'UTC-6' },
    { timezone: 'America/Managua', utc: 'UTC-6' },
    { timezone: 'America/Matamoros', utc: 'UTC-6' },
    { timezone: 'America/Menominee', utc: 'UTC-6' },
    { timezone: 'America/Merida', utc: 'UTC-6' },
    { timezone: 'America/Mexico_City', utc: 'UTC-6' },
    { timezone: 'America/Monterrey', utc: 'UTC-6' },
    { timezone: 'America/North_Dakota/Beulah', utc: 'UTC-6' },
    { timezone: 'America/North_Dakota/Center', utc: 'UTC-6' },
    { timezone: 'America/North_Dakota/New_Salem', utc: 'UTC-6' },
    { timezone: 'America/Rainy_River', utc: 'UTC-6' },
    { timezone: 'America/Rankin_Inlet', utc: 'UTC-6' },
    { timezone: 'America/Regina', utc: 'UTC-6' },
    { timezone: 'America/Resolute', utc: 'UTC-6' },
    { timezone: 'America/Swift_Current', utc: 'UTC-6' },
    { timezone: 'America/Tegucigalpa', utc: 'UTC-6' },
    { timezone: 'America/Winnipeg', utc: 'UTC-6' },
    { timezone: 'Pacific/Easter', utc: 'UTC-6' },
    { timezone: 'Pacific/Galapagos', utc: 'UTC-6' },
    { timezone: 'America/Atikokan', utc: 'UTC-5' },
    { timezone: 'America/Bogota', utc: 'UTC-5' },
    { timezone: 'America/Cancun', utc: 'UTC-5' },
    { timezone: 'America/Cayman', utc: 'UTC-5' },
    { timezone: 'America/Detroit', utc: 'UTC-5' },
    { timezone: 'America/Eirunepe', utc: 'UTC-5' },
    { timezone: 'America/Grand_Turk', utc: 'UTC-5' },
    { timezone: 'America/Guayaquil', utc: 'UTC-5' },
    { timezone: 'America/Havana', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Indianapolis', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Marengo', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Petersburg', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Vevay', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Vincennes', utc: 'UTC-5' },
    { timezone: 'America/Indiana/Winamac', utc: 'UTC-5' },
    { timezone: 'America/Iqaluit', utc: 'UTC-5' },
    { timezone: 'America/Jamaica', utc: 'UTC-5' },
    { timezone: 'America/Kentucky/Louisville', utc: 'UTC-5' },
    { timezone: 'America/Kentucky/Monticello', utc: 'UTC-5' },
    { timezone: 'America/Lima', utc: 'UTC-5' },
    { timezone: 'America/Nassau', utc: 'UTC-5' },
    { timezone: 'America/New_York', utc: 'UTC-5' },
    { timezone: 'America/Nipigon', utc: 'UTC-5' },
    { timezone: 'America/Panama', utc: 'UTC-5' },
    { timezone: 'America/Pangnirtung', utc: 'UTC-5' },
    { timezone: 'America/Port-au-Prince', utc: 'UTC-5' },
    { timezone: 'America/Rio_Branco', utc: 'UTC-5' },
    { timezone: 'America/Thunder_Bay', utc: 'UTC-5' },
    { timezone: 'America/Toronto', utc: 'UTC-5' },
    { timezone: 'America/Anguilla', utc: 'UTC-4' },
    { timezone: 'America/Antigua', utc: 'UTC-4' },
    { timezone: 'America/Aruba', utc: 'UTC-4' },
    { timezone: 'America/Asuncion', utc: 'UTC-4' },
    { timezone: 'America/Barbados', utc: 'UTC-4' },
    { timezone: 'America/Blanc-Sablon', utc: 'UTC-4' },
    { timezone: 'America/Boa_Vista', utc: 'UTC-4' },
    { timezone: 'America/Campo_Grande', utc: 'UTC-4' },
    { timezone: 'America/Caracas', utc: 'UTC-4' },
    { timezone: 'America/Cuiaba', utc: 'UTC-4' },
    { timezone: 'America/Curacao', utc: 'UTC-4' },
    { timezone: 'America/Dominica', utc: 'UTC-4' },
    { timezone: 'America/Glace_Bay', utc: 'UTC-4' },
    { timezone: 'America/Goose_Bay', utc: 'UTC-4' },
    { timezone: 'America/Grenada', utc: 'UTC-4' },
    { timezone: 'America/Guadeloupe', utc: 'UTC-4' },
    { timezone: 'America/Guyana', utc: 'UTC-4' },
    { timezone: 'America/Halifax', utc: 'UTC-4' },
    { timezone: 'America/Kralendijk', utc: 'UTC-4' },
    { timezone: 'America/La_Paz', utc: 'UTC-4' },
    { timezone: 'America/Lower_Princes', utc: 'UTC-4' },
    { timezone: 'America/Manaus', utc: 'UTC-4' },
    { timezone: 'America/Marigot', utc: 'UTC-4' },
    { timezone: 'America/Martinique', utc: 'UTC-4' },
    { timezone: 'America/Moncton', utc: 'UTC-4' },
    { timezone: 'America/Montserrat', utc: 'UTC-4' },
    { timezone: 'America/Porto_Velho', utc: 'UTC-4' },
    { timezone: 'America/Port_of_Spain', utc: 'UTC-4' },
    { timezone: 'America/Puerto_Rico', utc: 'UTC-4' },
    { timezone: 'America/Santiago', utc: 'UTC-4' },
    { timezone: 'America/Santo_Domingo', utc: 'UTC-4' },
    { timezone: 'America/St_Barthelemy', utc: 'UTC-4' },
    { timezone: 'America/St_Kitts', utc: 'UTC-4' },
    { timezone: 'America/St_Lucia', utc: 'UTC-4' },
    { timezone: 'America/St_Thomas', utc: 'UTC-4' },
    { timezone: 'America/St_Vincent', utc: 'UTC-4' },
    { timezone: 'America/Thule', utc: 'UTC-4' },
    { timezone: 'America/Tortola', utc: 'UTC-4' },
    { timezone: 'Atlantic/Bermuda', utc: 'UTC-4' },
    { timezone: 'America/St_Johns', utc: 'UTC-3:30 ' },
    { timezone: 'America/Araguaina', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Buenos_Aires', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Catamarca', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Cordoba', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Jujuy', utc: 'UTC-3' },
    { timezone: 'America/Argentina/La_Rioja', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Mendoza', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Rio_Gallegos', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Salta', utc: 'UTC-3' },
    { timezone: 'America/Argentina/San_Juan', utc: 'UTC-3' },
    { timezone: 'America/Argentina/San_Luis', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Tucuman', utc: 'UTC-3' },
    { timezone: 'America/Argentina/Ushuaia', utc: 'UTC-3' },
    { timezone: 'America/Bahia', utc: 'UTC-3' },
    { timezone: 'America/Belem', utc: 'UTC-3' },
    { timezone: 'America/Cayenne', utc: 'UTC-3' },
    { timezone: 'America/Fortaleza', utc: 'UTC-3' },
    { timezone: 'America/Godthab', utc: 'UTC-3' },
    { timezone: 'America/Maceio', utc: 'UTC-3' },
    { timezone: 'America/Miquelon', utc: 'UTC-3' },
    { timezone: 'America/Montevideo', utc: 'UTC-3' },
    { timezone: 'America/Paramaribo', utc: 'UTC-3' },
    { timezone: 'America/Punta_Arenas', utc: 'UTC-3' },
    { timezone: 'America/Recife', utc: 'UTC-3' },
    { timezone: 'America/Santarem', utc: 'UTC-3' },
    { timezone: 'America/Sao_Paulo', utc: 'UTC-3' },
    { timezone: 'Antarctica/Palmer', utc: 'UTC-3' },
    { timezone: 'Antarctica/Rothera', utc: 'UTC-3' },
    { timezone: 'Atlantic/Stanley', utc: 'UTC-3' },
    { timezone: 'America/Noronha', utc: 'UTC-2' },
    { timezone: 'Atlantic/South_Georgia', utc: 'UTC-2' },
    { timezone: 'America/Scoresbysund', utc: 'UTC-1' },
    { timezone: 'Atlantic/Azores', utc: 'UTC-1' },
    { timezone: 'Atlantic/Cape_Verde', utc: 'UTC-1' },
    { timezone: 'Africa/Abidjan', utc: 'UTC+0' },
    { timezone: 'Africa/Accra', utc: 'UTC+0' },
    { timezone: 'Africa/Bamako', utc: 'UTC+0' },
    { timezone: 'Africa/Banjul', utc: 'UTC+0' },
    { timezone: 'Africa/Bissau', utc: 'UTC+0' },
    { timezone: 'Africa/Casablanca', utc: 'UTC+0' },
    { timezone: 'Africa/Conakry', utc: 'UTC+0' },
    { timezone: 'Africa/Dakar', utc: 'UTC+0' },
    { timezone: 'Africa/El_Aaiun', utc: 'UTC+0' },
    { timezone: 'Africa/Freetown', utc: 'UTC+0' },
    { timezone: 'Africa/Lome', utc: 'UTC+0' },
    { timezone: 'Africa/Monrovia', utc: 'UTC+0' },
    { timezone: 'Africa/Nouakchott', utc: 'UTC+0' },
    { timezone: 'Africa/Ouagadougou', utc: 'UTC+0' },
    { timezone: 'Africa/Sao_Tome', utc: 'UTC+0' },
    { timezone: 'America/Danmarkshavn', utc: 'UTC+0' },
    { timezone: 'Antarctica/Troll', utc: 'UTC+0' },
    { timezone: 'Atlantic/Canary', utc: 'UTC+0' },
    { timezone: 'Atlantic/Faroe', utc: 'UTC+0' },
    { timezone: 'Atlantic/Madeira', utc: 'UTC+0' },
    { timezone: 'Atlantic/Reykjavik', utc: 'UTC+0' },
    { timezone: 'Atlantic/St_Helena', utc: 'UTC+0' },
    { timezone: 'Europe/Dublin', utc: 'UTC+0' },
    { timezone: 'Europe/Guernsey', utc: 'UTC+0' },
    { timezone: 'Europe/Isle_of_Man', utc: 'UTC+0' },
    { timezone: 'Europe/Jersey', utc: 'UTC+0' },
    { timezone: 'Europe/Lisbon', utc: 'UTC+0' },
    { timezone: 'Europe/London', utc: 'UTC+0' },
    { timezone: 'Africa/Algiers', utc: 'UTC+1' },
    { timezone: 'Africa/Bangui', utc: 'UTC+1' },
    { timezone: 'Africa/Brazzaville', utc: 'UTC+1' },
    { timezone: 'Africa/Ceuta', utc: 'UTC+1' },
    { timezone: 'Africa/Douala', utc: 'UTC+1' },
    { timezone: 'Africa/Kinshasa', utc: 'UTC+1' },
    { timezone: 'Africa/Lagos', utc: 'UTC+1' },
    { timezone: 'Africa/Libreville', utc: 'UTC+1' },
    { timezone: 'Africa/Luanda', utc: 'UTC+1' },
    { timezone: 'Africa/Malabo', utc: 'UTC+1' },
    { timezone: 'Africa/Ndjamena', utc: 'UTC+1' },
    { timezone: 'Africa/Niamey', utc: 'UTC+1' },
    { timezone: 'Africa/Porto-Novo', utc: 'UTC+1' },
    { timezone: 'Africa/Tunis', utc: 'UTC+1' },
    { timezone: 'Africa/Windhoek', utc: 'UTC+1' },
    { timezone: 'Arctic/Longyearbyen', utc: 'UTC+1' },
    { timezone: 'Europe/Amsterdam', utc: 'UTC+1' },
    { timezone: 'Europe/Andorra', utc: 'UTC+1' },
    { timezone: 'Europe/Belgrade', utc: 'UTC+1' },
    { timezone: 'Europe/Berlin', utc: 'UTC+1' },
    { timezone: 'Europe/Bratislava', utc: 'UTC+1' },
    { timezone: 'Europe/Brussels', utc: 'UTC+1' },
    { timezone: 'Europe/Budapest', utc: 'UTC+1' },
    { timezone: 'Europe/Copenhagen', utc: 'UTC+1' },
    { timezone: 'Europe/Gibraltar', utc: 'UTC+1' },
    { timezone: 'Europe/Ljubljana', utc: 'UTC+1' },
    { timezone: 'Europe/Luxembourg', utc: 'UTC+1' },
    { timezone: 'Europe/Madrid', utc: 'UTC+1' },
    { timezone: 'Europe/Malta', utc: 'UTC+1' },
    { timezone: 'Europe/Monaco', utc: 'UTC+1' },
    { timezone: 'Europe/Oslo', utc: 'UTC+1' },
    { timezone: 'Europe/Paris', utc: 'UTC+1' },
    { timezone: 'Europe/Podgorica', utc: 'UTC+1' },
    { timezone: 'Europe/Prague', utc: 'UTC+1' },
    { timezone: 'Europe/Rome', utc: 'UTC+1' },
    { timezone: 'Europe/San_Marino', utc: 'UTC+1' },
    { timezone: 'Europe/Sarajevo', utc: 'UTC+1' },
    { timezone: 'Europe/Skopje', utc: 'UTC+1' },
    { timezone: 'Europe/Stockholm', utc: 'UTC+1' },
    { timezone: 'Europe/Tirane', utc: 'UTC+1' },
    { timezone: 'Europe/Vaduz', utc: 'UTC+1' },
    { timezone: 'Europe/Vatican', utc: 'UTC+1' },
    { timezone: 'Europe/Vienna', utc: 'UTC+1' },
    { timezone: 'Europe/Warsaw', utc: 'UTC+1' },
    { timezone: 'Europe/Zagreb', utc: 'UTC+1' },
    { timezone: 'Europe/Zurich', utc: 'UTC+1' },
    { timezone: 'Africa/Blantyre', utc: 'UTC+2' },
    { timezone: 'Africa/Bujumbura', utc: 'UTC+2' },
    { timezone: 'Africa/Cairo', utc: 'UTC+2' },
    { timezone: 'Africa/Gaborone', utc: 'UTC+2' },
    { timezone: 'Africa/Harare', utc: 'UTC+2' },
    { timezone: 'Africa/Johannesburg', utc: 'UTC+2' },
    { timezone: 'Africa/Juba', utc: 'UTC+2' },
    { timezone: 'Africa/Khartoum', utc: 'UTC+2' },
    { timezone: 'Africa/Kigali', utc: 'UTC+2' },
    { timezone: 'Africa/Lubumbashi', utc: 'UTC+2' },
    { timezone: 'Africa/Lusaka', utc: 'UTC+2' },
    { timezone: 'Africa/Maputo', utc: 'UTC+2' },
    { timezone: 'Africa/Maseru', utc: 'UTC+2' },
    { timezone: 'Africa/Mbabane', utc: 'UTC+2' },
    { timezone: 'Africa/Tripoli', utc: 'UTC+2' },
    { timezone: 'Asia/Amman', utc: 'UTC+2' },
    { timezone: 'Asia/Beirut', utc: 'UTC+2' },
    { timezone: 'Asia/Damascus', utc: 'UTC+2' },
    { timezone: 'Asia/Famagusta', utc: 'UTC+2' },
    { timezone: 'Asia/Gaza', utc: 'UTC+2' },
    { timezone: 'Asia/Hebron', utc: 'UTC+2' },
    { timezone: 'Asia/Jerusalem', utc: 'UTC+2' },
    { timezone: 'Asia/Nicosia', utc: 'UTC+2' },
    { timezone: 'Europe/Athens', utc: 'UTC+2' },
    { timezone: 'Europe/Bucharest', utc: 'UTC+2' },
    { timezone: 'Europe/Chisinau', utc: 'UTC+2' },
    { timezone: 'Europe/Helsinki', utc: 'UTC+2' },
    { timezone: 'Europe/Kaliningrad', utc: 'UTC+2' },
    { timezone: 'Europe/Kiev', utc: 'UTC+2' },
    { timezone: 'Europe/Mariehamn', utc: 'UTC+2' },
    { timezone: 'Europe/Riga', utc: 'UTC+2' },
    { timezone: 'Europe/Sofia', utc: 'UTC+2' },
    { timezone: 'Europe/Tallinn', utc: 'UTC+2' },
    { timezone: 'Europe/Uzhgorod', utc: 'UTC+2' },
    { timezone: 'Europe/Vilnius', utc: 'UTC+2' },
    { timezone: 'Europe/Zaporozhye', utc: 'UTC+2' },
    { timezone: 'Africa/Addis_Ababa', utc: 'UTC+3' },
    { timezone: 'Africa/Asmara', utc: 'UTC+3' },
    { timezone: 'Africa/Dar_es_Salaam', utc: 'UTC+3' },
    { timezone: 'Africa/Djibouti', utc: 'UTC+3' },
    { timezone: 'Africa/Kampala', utc: 'UTC+3' },
    { timezone: 'Africa/Mogadishu', utc: 'UTC+3' },
    { timezone: 'Africa/Nairobi', utc: 'UTC+3' },
    { timezone: 'Antarctica/Syowa', utc: 'UTC+3' },
    { timezone: 'Asia/Aden', utc: 'UTC+3' },
    { timezone: 'Asia/Baghdad', utc: 'UTC+3' },
    { timezone: 'Asia/Bahrain', utc: 'UTC+3' },
    { timezone: 'Asia/Kuwait', utc: 'UTC+3' },
    { timezone: 'Asia/Qatar', utc: 'UTC+3' },
    { timezone: 'Asia/Riyadh', utc: 'UTC+3' },
    { timezone: 'Europe/Istanbul', utc: 'UTC+3' },
    { timezone: 'Europe/Kirov', utc: 'UTC+3' },
    { timezone: 'Europe/Minsk', utc: 'UTC+3' },
    { timezone: 'Europe/Moscow', utc: 'UTC+3' },
    { timezone: 'Europe/Simferopol', utc: 'UTC+3' },
    { timezone: 'Europe/Volgograd', utc: 'UTC+3' },
    { timezone: 'Indian/Antananarivo', utc: 'UTC+3' },
    { timezone: 'Indian/Comoro', utc: 'UTC+3' },
    { timezone: 'Indian/Mayotte', utc: 'UTC+3' },
    { timezone: 'Asia/Tehran', utc: 'UTC+3:30' },
    { timezone: 'Asia/Baku', utc: 'UTC+4' },
    { timezone: 'Asia/Dubai', utc: 'UTC+4' },
    { timezone: 'Asia/Muscat', utc: 'UTC+4' },
    { timezone: 'Asia/Tbilisi', utc: 'UTC+4' },
    { timezone: 'Asia/Yerevan', utc: 'UTC+4' },
    { timezone: 'Europe/Astrakhan', utc: 'UTC+4' },
    { timezone: 'Europe/Samara', utc: 'UTC+4' },
    { timezone: 'Europe/Saratov', utc: 'UTC+4' },
    { timezone: 'Europe/Ulyanovsk', utc: 'UTC+4' },
    { timezone: 'Indian/Mahe', utc: 'UTC+4' },
    { timezone: 'Indian/Mauritius', utc: 'UTC+4' },
    { timezone: 'Indian/Reunion', utc: 'UTC+4' },
    { timezone: 'Asia/Kabul', utc: 'UTC+4:30' },
    { timezone: 'Antarctica/Mawson', utc: 'UTC+5' },
    { timezone: 'Asia/Aqtau', utc: 'UTC+5' },
    { timezone: 'Asia/Aqtobe', utc: 'UTC+5' },
    { timezone: 'Asia/Ashgabat', utc: 'UTC+5' },
    { timezone: 'Asia/Atyrau', utc: 'UTC+5' },
    { timezone: 'Asia/Dushanbe', utc: 'UTC+5' },
    { timezone: 'Asia/Karachi', utc: 'UTC+5' },
    { timezone: 'Asia/Oral', utc: 'UTC+5' },
    { timezone: 'Asia/Qyzylorda', utc: 'UTC+5' },
    { timezone: 'Asia/Samarkand', utc: 'UTC+5' },
    { timezone: 'Asia/Tashkent', utc: 'UTC+5' },
    { timezone: 'Asia/Yekaterinburg', utc: 'UTC+5' },
    { timezone: 'Indian/Kerguelen', utc: 'UTC+5' },
    { timezone: 'Indian/Maldives', utc: 'UTC+5' },
    { timezone: 'Asia/Colombo', utc: 'UTC+5:30' },
    { timezone: 'Asia/Kolkata', utc: 'UTC+5:30' },
    { timezone: 'Asia/Kathmandu', utc: 'UTC+5:45' },
    { timezone: 'Antarctica/Vostok', utc: 'UTC+6' },
    { timezone: 'Asia/Almaty', utc: 'UTC+6' },
    { timezone: 'Asia/Bishkek', utc: 'UTC+6' },
    { timezone: 'Asia/Dhaka', utc: 'UTC+6' },
    { timezone: 'Asia/Omsk', utc: 'UTC+6' },
    { timezone: 'Asia/Qostanay', utc: 'UTC+6' },
    { timezone: 'Asia/Thimphu', utc: 'UTC+6' },
    { timezone: 'Asia/Urumqi', utc: 'UTC+6' },
    { timezone: 'Indian/Chagos', utc: 'UTC+6' },
    { timezone: 'Asia/Yangon', utc: 'UTC+6:30' },
    { timezone: 'Indian/Cocos', utc: 'UTC+6:30' },
    { timezone: 'Antarctica/Davis', utc: 'UTC+7' },
    { timezone: 'Asia/Bangkok', utc: 'UTC+7' },
    { timezone: 'Asia/Barnaul', utc: 'UTC+7' },
    { timezone: 'Asia/Hovd', utc: 'UTC+7' },
    { timezone: 'Asia/Ho_Chi_Minh', utc: 'UTC+7' },
    { timezone: 'Asia/Jakarta', utc: 'UTC+7' },
    { timezone: 'Asia/Krasnoyarsk', utc: 'UTC+7' },
    { timezone: 'Asia/Novokuznetsk', utc: 'UTC+7' },
    { timezone: 'Asia/Novosibirsk', utc: 'UTC+7' },
    { timezone: 'Asia/Phnom_Penh', utc: 'UTC+7' },
    { timezone: 'Asia/Pontianak', utc: 'UTC+7' },
    { timezone: 'Asia/Tomsk', utc: 'UTC+7' },
    { timezone: 'Asia/Vientiane', utc: 'UTC+7' },
    { timezone: 'Indian/Christmas', utc: 'UTC+7' },
    { timezone: 'Asia/Brunei', utc: 'UTC+8' },
    { timezone: 'Asia/Choibalsan', utc: 'UTC+8' },
    { timezone: 'Asia/Hong_Kong', utc: 'UTC+8' },
    { timezone: 'Asia/Irkutsk', utc: 'UTC+8' },
    { timezone: 'Asia/Kuala_Lumpur', utc: 'UTC+8' },
    { timezone: 'Asia/Kuching', utc: 'UTC+8' },
    { timezone: 'Asia/Macau', utc: 'UTC+8' },
    { timezone: 'Asia/Makassar', utc: 'UTC+8' },
    { timezone: 'Asia/Manila', utc: 'UTC+8' },
    { timezone: 'Asia/Shanghai', utc: 'UTC+8' },
    { timezone: 'Asia/Singapore', utc: 'UTC+8' },
    { timezone: 'Asia/Taipei', utc: 'UTC+8' },
    { timezone: 'Asia/Ulaanbaatar', utc: 'UTC+8' },
    { timezone: 'Australia/Perth', utc: 'UTC+8' },
    { timezone: 'Australia/Eucla', utc: 'UTC+8:45' },
    { timezone: 'Asia/Chita', utc: 'UTC+9' },
    { timezone: 'Asia/Dili', utc: 'UTC+9' },
    { timezone: 'Asia/Jayapura', utc: 'UTC+9' },
    { timezone: 'Asia/Khandyga', utc: 'UTC+9' },
    { timezone: 'Asia/Pyongyang', utc: 'UTC+9' },
    { timezone: 'Asia/Seoul', utc: 'UTC+9' },
    { timezone: 'Asia/Tokyo', utc: 'UTC+9' },
    { timezone: 'Asia/Yakutsk', utc: 'UTC+9' },
    { timezone: 'Pacific/Palau', utc: 'UTC+9' },
    { timezone: 'Australia/Adelaide', utc: 'UTC+9:30' },
    { timezone: 'Australia/Broken_Hill', utc: 'UTC+9:30' },
    { timezone: 'Australia/Darwin', utc: 'UTC+9:30' },
    { timezone: 'Antarctica/DumontDUrville', utc: 'UTC+10' },
    { timezone: 'Antarctica/Macquarie', utc: 'UTC+10' },
    { timezone: 'Asia/Ust-Nera', utc: 'UTC+10' },
    { timezone: 'Asia/Vladivostok', utc: 'UTC+10' },
    { timezone: 'Australia/Brisbane', utc: 'UTC+10' },
    { timezone: 'Australia/Currie', utc: 'UTC+10' },
    { timezone: 'Australia/Hobart', utc: 'UTC+10' },
    { timezone: 'Australia/Lindeman', utc: 'UTC+10' },
    { timezone: 'Australia/Melbourne', utc: 'UTC+10' },
    { timezone: 'Australia/Sydney', utc: 'UTC+10' },
    { timezone: 'Pacific/Chuuk', utc: 'UTC+10' },
    { timezone: 'Pacific/Guam', utc: 'UTC+10' },
    { timezone: 'Pacific/Port_Moresby', utc: 'UTC+10' },
    { timezone: 'Pacific/Saipan', utc: 'UTC+10' },
    { timezone: 'Australia/Lord_Howe', utc: 'UTC+10:30' },
    { timezone: 'Antarctica/Casey', utc: 'UTC+11' },
    { timezone: 'Asia/Magadan', utc: 'UTC+11' },
    { timezone: 'Asia/Sakhalin', utc: 'UTC+11' },
    { timezone: 'Asia/Srednekolymsk', utc: 'UTC+11' },
    { timezone: 'Pacific/Bougainville', utc: 'UTC+11' },
    { timezone: 'Pacific/Efate', utc: 'UTC+11' },
    { timezone: 'Pacific/Guadalcanal', utc: 'UTC+11' },
    { timezone: 'Pacific/Kosrae', utc: 'UTC+11' },
    { timezone: 'Pacific/Norfolk', utc: 'UTC+11' },
    { timezone: 'Pacific/Noumea', utc: 'UTC+11' },
    { timezone: 'Pacific/Pohnpei', utc: 'UTC+11' },
    { timezone: 'Antarctica/McMurdo', utc: 'UTC+12' },
    { timezone: 'Asia/Anadyr', utc: 'UTC+12' },
    { timezone: 'Asia/Kamchatka', utc: 'UTC+12' },
    { timezone: 'Pacific/Auckland', utc: 'UTC+12' },
    { timezone: 'Pacific/Fiji', utc: 'UTC+12' },
    { timezone: 'Pacific/Funafuti', utc: 'UTC+12' },
    { timezone: 'Pacific/Kwajalein', utc: 'UTC+12' },
    { timezone: 'Pacific/Majuro', utc: 'UTC+12' },
    { timezone: 'Pacific/Nauru', utc: 'UTC+12' },
    { timezone: 'Pacific/Tarawa', utc: 'UTC+12' },
    { timezone: 'Pacific/Wake', utc: 'UTC+12' },
    { timezone: 'Pacific/Wallis', utc: 'UTC+12' },
    { timezone: 'Pacific/Chatham', utc: 'UTC+12:45' },
    { timezone: 'Pacific/Apia', utc: 'UTC+13' },
    { timezone: 'Pacific/Enderbury', utc: 'UTC+13' },
    { timezone: 'Pacific/Fakaofo', utc: 'UTC+13' },
    { timezone: 'Pacific/Tongatapu', utc: 'UTC+13' },
    { timezone: 'Pacific/Kiritimati', utc: 'UTC+14' },
  ],
})
const state = initialState

const getters = {
  timezones: (state) =>
    state.globalTimezones.map((elem) => `${elem.utc} ${elem.timezone}`),
  globalTimezones: (state) => state.globalTimezones,
}

const actions = {
  /**
   * @async
   * @param {*} context
   * @param {Object} args
   * @param {String} args.timezone
   */
  async getUTCByTimezone(context, { timezone }) {
    const tz = context.state.globalTimezones.find(
      (el) => el.timezone === timezone
    )
    return tz?.utc
  },
}

export default {
  state,
  getters,
  actions,
}
