<template>
  <div class="status-avatar">
    <div
      class="status bg-color-inactive"
      :class="{
        'bg-color-active':
          status === 'Tickets' ||
          status === 'Agenda' ||
          status === 'connected' ||
          status === 'conectado',
        'bg-color-busy': status === 'ocupado' || status === 'bussy',
        'bg-color-services': status === 'servicios' || status === 'services',
        'bg-color-training': status === 'capacitación' || status === 'training',
        'bg-color-paused': status === 'paused',
        'bg-color-snacking': status === 'snacking',
        'status-list': component === 'List',
        'status-drawer': component === 'Drawer',
      }"
    ></div>
    <a-avatar
      v-if="info.subType === 'chatbot'"
      :src="require('@/app/tickets/assets/icons/bot.png')"
      class="avatar-chat"
      style="margin-left: 4px"
      :size="size"
    />
    <a-avatar
      v-else-if="info.avatar"
      :src="info.avatar"
      class="avatar-chat"
      style="margin-left: 4px"
      :size="size"
    />
    <DefaultIconLetter v-else :size="size" :names="info.names" />
  </div>
</template>
<script>
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'

export default {
  name: 'StatusAvatar',
  props: {
    size: {
      type: Number,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
    component: {
      type: String,
      required: false,
    },
    info: {
      type: Object,
      required: false,
    },
    src: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    DefaultIconLetter,
  },
}
</script>
<style lang="sass" scoped>
@mixin bg-color($color)
  background-color: $color
.bg-color-inactive
  @include bg-color($disconnected)
.bg-color-active
  @include bg-color($connected)
.bg-color-busy
  @include bg-color($busy)
.bg-color-services
  @include bg-color($services)
.bg-color-training
  @include bg-color($training)
.bg-color-paused
  @include bg-color($paused)
.bg-color-snacking
  @include bg-color($snacking)
.status-list
  height: 12px
  width: 12px
  margin-left: 29px
  position: absolute

.status-drawer
  height: 16px
  width: 16px
  margin-left: 47px
  position: absolute
.status-avatar
  margin-right: 8px
.status
  border-radius: 50%
  border: 1px solid white
  z-index: 1
</style>
