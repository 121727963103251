import generalModule from './generalModule'
import responsiveModule from './responsiveModule'
import permissionsModule from './permissionsModule'
import pushNotificationsModule from './pushNotificationsModule'

const modules = {
  generalModule,
  responsiveModule,
  permissionsModule,
  pushNotificationsModule,
}

export default {
  modules,
}
