const getters = {
  // para los canales
  channels: (state) => state.channels,
  paginate_company_channels: (state) => state.paginate_company_channels, // canales de la empresa paginados
  pagination_company_channels: (state) => state.pagination_company_channels,
  origin_simple_company_channels: (state) => {
    // canales de la empresa sin paginación
    const simple_company_channels = [...state.simple_company_channels]
    simple_company_channels.forEach((simple_channel) => {
      // encuentra el canal general
      const channelGeneral =
        state.channels &&
        state.channels.find(
          (channel) => channel.channel === simple_channel.channel
        )
      if (channelGeneral) {
        const api = channelGeneral.apis[simple_channel.api_version]
        simple_channel.api = api
      }
      return simple_channel
    })
    return simple_company_channels
  },
  simple_company_channels: (state) => {
    // canales de la empresa sin paginación
    const origin_simple_company_channels = [...state.simple_company_channels]

    // filtra solo los canales que está habilitados para tickets
    const simple_company_channels = [...origin_simple_company_channels].filter(
      (simple_channel) =>
        simple_channel.enableFor && simple_channel.enableFor.workInTickets
    )
    simple_company_channels.forEach((simple_channel) => {
      // encuentra el canal general
      const channelGeneral =
        state.channels &&
        state.channels.find(
          (channel) => channel.channel === simple_channel.channel
        )
      if (channelGeneral) {
        const api = channelGeneral.apis[simple_channel.api_version]
        simple_channel.api = api
      }
      return simple_channel
    })
    return simple_company_channels
  },
  current_channel: (state) => {
    const channelGeneral =
      state.channels &&
      state.channels.find(
        (channel) => channel.channel === state.current_channel.channel
      )
    if (channelGeneral) {
      const api = channelGeneral.apis[state.current_channel.api_version]
      state.current_channel.api = api
    }

    return state.current_channel
  },
  // para las plantillas
  allTemplates: (state) => state.allTemplates,
}

export default getters
