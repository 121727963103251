<template>
  <div class="accounts">
    <AccountsTemplate />
  </div>
</template>
<script>
import AccountsTemplate from '@/app/accounts/components/templates/AccountsTemplate.vue'
export default {
  name: 'Accounts',
  components: {
    AccountsTemplate,
  },
}
</script>
