<template>
  <div class="logout">
    <span>Saliendo...</span>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  created() {
    setTimeout(() => {
      this.$store.dispatch('logout')
    }, 1500)
  },
}
</script>
