<template>
  <div class="form-edit-template">
    <div class="form-edit-template-content">
      <div id="form-edit-template-master-container" class="master-container">
        <div
          id="form-edit-template-container"
          class="form-edit-container scrollbar_basic"
          @scroll="
            setShadows(
              'form-edit-template-master-container',
              'form-edit-template-container',
              'form-edit-template-content'
            )
          "
        >
          <div id="form-edit-template-content" class="form-edit-content">
            <a-form layout="vertical" :form="form" style="width: 396px">
              <a-form-item label="Nombre de plantilla">
                <a-input
                  v-decorator="['name']"
                  placeholder="Escribir aquí..."
                  disabled
                />
              </a-form-item>
              <a-form-item label="Nombre de visualización">
                <a-input
                  v-decorator="[
                    'friendlyName',
                    {
                      rules: [
                        { required: true, message: 'Completa este campo' },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  placeholder="Escribir aquí..."
                />
              </a-form-item>
              <a-form-item label="Selecciona la categoría">
                <a-select
                  v-decorator="[
                    'category',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor completa este campo',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                  disabled
                >
                  <a-select-option
                    v-for="category in CATEGORIES_TEMPLATES"
                    :key="category.value"
                    :value="category.value"
                  >
                    {{ category.title }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Selecciona el lenguaje">
                <a-select
                  v-decorator="[
                    'lang',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor completa este campo',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                  disabled
                >
                  <a-select-option value="es"> Español </a-select-option>
                  <a-select-option value="en"> Inglés </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Selecciona el tipo de plantilla">
                <a-select
                  v-decorator="[
                    'typeAttachment',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor completa este campo',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                  disabled
                >
                  <a-select-option
                    v-for="typeTemplateForList in TYPE_TEMPLATES_FOR_LIST"
                    :key="typeTemplateForList.value"
                  >
                    {{ typeTemplateForList.title }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Contenido de plantilla">
                <a-input
                  v-decorator="['content']"
                  placeholder="Escribir aquí..."
                  type="textarea"
                  :auto-size="{ minRows: 3, maxRows: 4 }"
                  disabled
                />
              </a-form-item>
              <template v-for="(param, index) in parameters">
                <a-form-item
                  :label="`Nombre de parámetro ${index + 1}`"
                  :key="param.idParameter"
                >
                  <a-input
                    v-model="param.value"
                    placeholder="Escribir aquí..."
                    disabled
                  />
                </a-form-item>
              </template>
            </a-form>
            <mobile-wsp
              class="form__mobile"
              type="small"
              :message="template.body"
              :media-type="mediaTypeForPreview"
              :buttons="template.buttons || []"
              default-message="--"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button style="min-width: 90px" @click="handleCloseModal"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        @click="handleEditTemplate"
        style="margin-left: 8px; min-width: 90px"
        :loading="loadBtnEdit"
        >Editar</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MobileWsp from '@/app/strategies/components/MobileWsp'
import { TYPE_TEMPLATES_FOR_LIST } from '@/app/channels/utils/componentsDataTemplates'
import { CATEGORIES_TEMPLATES } from '@/app/channels/utils/categoriesTemplatesUtil'

export default {
  name: 'FormEditTemplate',
  props: {
    template: {
      required: true,
    },
  },
  components: {
    MobileWsp,
  },
  data: () => ({
    TYPE_TEMPLATES_FOR_LIST,
    CATEGORIES_TEMPLATES,
    parameters: [],
    loadBtnEdit: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-edit-template' })
  },
  mounted() {
    this.setValue()
  },
  computed: {
    /**
     * @return {String} - text, video, document, image
     */
    mediaTypeForPreview() {
      if (!this.template || this.template.type === 'TEXT') return 'text'
      return (
        this.template.typeAttachment &&
        this.template.typeAttachment.toLowerCase()
      )
    },
  },
  methods: {
    ...mapActions(['updateTemplate']),
    setValue() {
      setTimeout(() => {
        this.form.setFieldsValue({
          name: this.template.name,
          friendlyName: this.template.friendlyName,
          lang: this.template.lang,
          category: this.template.category,
          content: this.template.body,
          typeAttachment: this.template.typeAttachment || 'TEXT',
        })
        this.parameters = [...this.template.parameters].map((param) => {
          param.value = param.title
          return param
        })
      }, 300)
    },
    // extraer los parametros del contenido de la plantilla
    extractParams(content) {
      const regex = /{{([1-9]|10)}}/gm
      const found = content.match(regex)
      return found
    },
    // elimina los elementos repetidos
    deleteRepeated(arr) {
      return arr.filter((item, index, array) => {
        return array.indexOf(item) === index
      })
    },
    handleEditTemplate() {
      this.form.validateFieldsAndScroll(async (err) => {
        if (!err) {
          const values = this.form.getFieldsValue()
          this.loadBtnEdit = true
          await this.callUpdateTemplate(values)
          this.loadBtnEdit = false
        }
      })
    },
    async callUpdateTemplate(values) {
      const response = await this.updateTemplate({
        templateId: this.template._id,
        template: { friendlyName: values.friendlyName },
      })
      if (response.success) {
        this.$message.success({
          content: `Plantilla editada`,
          duration: 2,
        })
        this.$emit('onEdit')
      } else {
        this.$message.error({
          content:
            response.details || `Ocurrió un error al editar la plantilla`,
          duration: 2,
        })
      }
    },
    // setea sombras
    setShadows(idMaster, idContainer, idContent) {
      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
    // emite para cerrar un modal
    handleCloseModal() {
      this.$emit('closeModal')
    },
    resetForm() {
      this.form.resetFields()
      this.parameters = []
    },
  },
}
</script>

<style lang="sass" scoped>
.form-edit-template
  &-content
    // padding: 20px
    // display: flex
    // flex-direction: row
    // align-items: flex-start
    position: relative
  .footer
    padding: 12px
    border-top: 1px solid #E8E8E8
    display: flex
    flex-direction: row
    justify-content: flex-end

.master-container
  position: relative
  padding-top: 2px
  padding-bottom: 2px
  height: 500px
  .form-edit-container
    overflow-y: auto
    height: 100%
    .form-edit-content
      position: relative
      padding: 20px 20px 0 20px
      width: 100%
.shadow-top
  &::before
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    top: 0
.shadow-bottom
  &::after
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    bottom: 0

.form__mobile
  position: absolute
  top: 20px
  right: 24px
</style>

<style lang="sass">
.select-multiple
  .ant-select-selection
    max-height: 86px
    overflow-y: auto
    &::-webkit-scrollbar
      width: 10px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
