<template>
  <a-avatar
    :size="size"
    class="default-icon-letter"
    @click="handleClick"
    style="color: #f56a00; background-color: #fde3cf"
    >{{ firsLetter }}</a-avatar
  >
</template>

<script>
export default {
  name: 'DefaultIconLetter',
  props: {
    names: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    },
  },
  computed: {
    /**
     * @return {String} - la primera letra del nombre
     */
    firsLetter() {
      return this.names ? this.names[0].toUpperCase() : ''
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="sass" scoped>
// .default-icon-letter
//   color: #f56a00
//   background-color: #fde3cf
</style>
