<template>
  <div class="group-selected-buttons">
    <div
      class="content"
      :class="{
        vertical: orientation === 'vertical',
        horizontal: orientation === 'horizontal',
      }"
    >
      <div
        v-for="(button, index) in dataButtons"
        :key="index"
        class="button"
        :class="{
          'button-selected': buttonSelecteds.includes(index),
          'button-all-selected': allSelecteds,
        }"
        @click="selected(index)"
      >
        {{ button }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupSelectedButtons',
  props: {
    dataButtons: {
      type: Array,
      default: () => ['1'],
    },
    orientation: {
      type: String,
      default: 'horizontal',
    },
    allSelecteds: {
      type: Boolean,
      required: false,
    },
    selecteds: { type: Array, default: () => [] },
  },
  data: () => ({
    buttonSelecteds: [],
  }),
  mounted() {
    setTimeout(() => {
      this.buttonSelecteds = this.selecteds
    }, 300)
  },
  watch: {
    selecteds() {
      this.buttonSelecteds = this.selecteds
    },
  },
  methods: {
    /**
     * Adjunta los botones seleccionados a los botones seleccionados
     * @param {String} button
     * */
    selected(button) {
      const include = this.buttonSelecteds.includes(button)
      if (include) {
        this.buttonSelecteds = this.buttonSelecteds.filter(
          (btnSelected) => btnSelected !== button
        )
        this.$emit('buttonSelecteds', this.buttonSelecteds)
      } else {
        this.buttonSelecteds.push(button)
        this.$emit('buttonSelecteds', this.buttonSelecteds)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.group-selected-buttons
  border: 1px solid $gray_5
  border-radius: 4px
  padding: 8px 12px
  width: fit-content
  .content
    display: flex
    &-vertical
      flex-direction: column
    &-horizontal
      flex-direction: row
    .button
      margin: 4px
      border: none
      background-color: $gray_6
      cursor: pointer
      color: white
      min-width: 32px
      padding: 5px
      text-align: center
      border-radius: 50%
      height: 32px
      &:hover
        background-color: #40a9ff
      &:focus
        background-color: #096DD9
    .button-selected
      background-color: #096DD9
    .button-all-selected
      background-color: #096DD9
      pointer-events: none
</style>
