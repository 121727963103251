<template>
  <div class="alert">
    <p class="body-2 mrg-bottom-0 alert__message ellipsis" :title="message">
      {{ message }}
    </p>
    <a @click="$emit('close')" :disabled="closable">
      <a-icon type="close" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Information Notes',
    },
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.alert
  display: flex
  justify-content: space-between
  background-color: $blue_1
  border: 1px solid $blue_3
  border-radius: 4px
  padding: 8px 16px
  &__message
    margin-right: 8px
</style>
