<template>
  <a-modal
    :visible="visible"
    title="Agregar teléfono"
    @cancel="handleClose"
    :afterClose="handleReset"
  >
    <p class="body-2">
      Selecciona el país e ingresa el número telefónico para el envío de alertas
      SMS.
    </p>
    <a-form :form="form" layout="vertical">
      <a-form-item>
        <a-row :gutter="8" type="flex">
          <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
          <a-col :span="16" :order="2">
            <a-input
              v-decorator="[
                'number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :maxLenght="20"
              placeholder="Escribe aquí..."
            />
          </a-col>
          <a-col :span="8" :order="1" v-if="smsPack">
            <a-select
              show-search
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                  ],
                },
              ]"
              option-filter-prop="children"
              placeholder="País"
              @change="handleSelectCountry"
            >
              <a-select-option
                v-for="country in smsPack.countries"
                :key="country.countryCode"
                :title="country.name"
              >
                {{ country.dialCode }}
                {{ country.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <div slot="footer">
      <a-button @click="handleClose">Cancelar</a-button>
      <a-button type="primary" @click="handleAddPhone">Agregar</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalAddPhone',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    countrySelected: '',
    number: '',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'FormBaseAccount' })
  },
  computed: {
    ...mapGetters(['products', 'coutries']),

    smsPack() {
      return this.products.find((product) => product.name === 'SMS')
    },
  },
  methods: {
    handleClose() {
      this.$emit('onCancel')
    },
    /**
     * @param {String} code
     */
    handleSelectCountry(code) {
      this.countrySelected = this.smsPack.countries[code]
    },
    handleAddPhone() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const phone = {
            code: this.countrySelected.dialCode,
            number: values.number,
            country: this.countrySelected.name,
          }
          this.$emit('onAddPhone', phone)
        }
      })
    },
    handleReset() {
      this.form.resetFields()
    },
  },
}
</script>

<style scoped></style>
