<template>
  <div class="button-msg regular-14">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonMsg',
}
</script>

<style lang="sass" scoped>
.button-msg
  color: $gray_9
  background-color: $blue_light_000
  border-radius: 10px
  padding: 1px 8px
</style>
