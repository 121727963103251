<template>
  <grid-template>
    <downloads-template></downloads-template>
  </grid-template>
</template>

<script>
import GridTemplate from '@/app/shared/components/templates/GridTemplate.vue'
import DownloadsTemplate from '@/app/downloads/components/templates/DownloadsTemplate.vue'

export default {
  name: 'Downloads',
  components: {
    DownloadsTemplate,
    GridTemplate,
  },
}
</script>

<style scoped></style>
