import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_CHATBOT_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**Setea valores a chatbots */
  SET_CHATBOTS(state, chatbots) {
    state.chatbots = chatbots
  },
  /**Setea valores a chatbots */
  ADD_CHATBOTS(state, chatbots) {
    state.chatbots = state.chatbots.concat(chatbots)
  },
  /**Setea valores a chatbots */
  SET_PAGINATE_CHATBOTS(state, paginate_chatbots) {
    state.paginate_chatbots = paginate_chatbots
  },
  /**Añade un chatbot */
  ADD_CHATBOT(state, chatbot) {
    state.chatbots.push(chatbot)
  },
  SET_CHATBOT(state, chatbot) {
    state.chatbot = chatbot
  },
  /**Setea los nodoa a un chatbot */
  SET_NODES(state, nodes) {
    state.nodes = nodes
  },
  ADD_NODE(state, node) {
    state.nodes.push(node)
  },

  SET_TREE_NODES(state, treeNodes) {
    state.treeNodes = treeNodes
  },
  SUM_CHANGES(state, action) {
    if (action !== 'reset' && state.changes >= 0) {
      if (action === 'add') state.changes++
      else {
        if (state.changes >= 1) state.changes--
      }
    } else {
      state.changes = 0
    }
  },
  SET_IS_SAVED_NODE(state, saved) {
    state.isSavedNode = saved
  },
  DELETE_NODE(state, nodeId) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    state.nodes.splice(index, 1)
  },
  /**
   * Elimina un hijo
   * @param {*} state
   * @param {*} args
   * @param {String} args.nodeId
   * @param {String} args.nodeType
   * @param {String} args.childIdProperty
   * @param {String} args.childId
   */
  DELETE_CHILD(state, { nodeId, nodeType, childIdProperty, childId }) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    state.nodes[index].drawer[nodeType] = state.nodes[index].drawer[
      nodeType
    ].filter((child) => child[childIdProperty] !== childId)
  },
  /**
   * Elimina un subhijo
   * @param {*} state
   * @param {*} args
   * @param {String} args.nodeId
   * @param {String} args.nodeType
   * @param {String} args.childIdProperty
   * @param {String} args.childId
   * @param {String} args.subchildType
   * @param {String} args.subchildIdProperty
   */
  DELETE_SUBCHILD(
    state,
    {
      nodeId,
      nodeType,
      childIdProperty,
      childId,
      subchildId,
      subchildType,
      subchildIdProperty,
    }
  ) {
    const indexFather = state.nodes.findIndex((node) => node._id === nodeId)
    if (indexFather === -1) return

    const indexChild = state.nodes[indexFather].drawer[nodeType].findIndex(
      (child) => child[childIdProperty] === childId
    )
    if (indexChild === -1) return

    const child = state.nodes[indexFather].drawer[nodeType][indexChild]

    state.nodes[indexFather].drawer[nodeType][indexChild][subchildType] = child[
      subchildType
    ].filter((subchild) => subchild[subchildIdProperty] !== subchildId)
  },
  /**
   * Agrega un nodo hijo
   * @param {*} state
   * @param {Object} args
   * @param {String} args.nodeId
   * @param {Object} args.child
   * @param {String} args.typeNode
   * @returns
   */
  ADD_CHILD(state, { nodeId, child, typeNode }) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    if (index === -1) return
    state.nodes[index].drawer[typeNode].push(child)
  },
  /**
   * Agrega un nodo subhijo
   * @param {*} state
   * @param {Object} args
   * @param {String} args.nodeId
   * @param {String} args.childId
   * @param {Object} args.subchild
   * @param {String} args.nodeType
   * @param {String} args.subchildType
   * @param {String} args.childIdProperty
   * @returns
   */
  ADD_SUBCHILD(
    state,
    { nodeId, childId, subchild, nodeType, subchildType, childIdProperty }
  ) {
    const indexFather = state.nodes.findIndex((node) => node._id === nodeId)
    if (indexFather === -1) return

    const indexChild = state.nodes[indexFather].drawer[nodeType].findIndex(
      (child) => child[childIdProperty] === childId
    )
    if (indexChild === -1) return

    state.nodes[indexFather].drawer[nodeType][indexChild][subchildType].push(
      subchild
    )
  },
  /**
   * Actualiza un nodo
   * @param {*} state
   * @param {Object} args
   * @param {String} args.nodeId
   * @param {Object} args.node
   * @returns
   */
  UPDATE_NODE(state, { nodeId, node }) {
    const indexNode = state.nodes.findIndex((node) => node._id === nodeId)
    if (indexNode === -1) return
    state.nodes.splice(indexNode, 1, node)
  },
  /**
   * Actualiza un hijo
   * @param {*} state
   * @param {Object} args
   * @param {String} args.nodeId
   * @param {String} args.nodeType
   * @param {Object} args.newChild
   * @param {String} args.childId
   * @param {String} args.childIdProperty
   * @param {Boolean} args.replaceAll
   * @param {Object} args.specificProperties
   * @param {String} args.specificProperties.order
   */
  UPDATE_CHILD(
    state,
    {
      nodeId,
      nodeType,
      newChild = {},
      childId,
      childIdProperty,
      replaceAll = true,
      specificProperties,
    }
  ) {
    const indexNode = state.nodes.findIndex((node) => node._id === nodeId)

    if (indexNode === -1) return
    const indexChild = state.nodes[indexNode].drawer[nodeType].findIndex(
      (child) =>
        child[childIdProperty] === newChild?.[childIdProperty] ||
        child[childIdProperty] === childId
    )
    if (indexChild === -1) return
    // reemplaza toda la opcion
    if (replaceAll)
      return state.nodes[indexNode].drawer[nodeType].splice(
        indexChild,
        1,
        newChild
      )
    else if (!replaceAll && specificProperties) {
      // reemplazara solo algunas propiedades
      for (let property in specificProperties) {
        state.nodes[indexNode].drawer[nodeType][indexChild][property] =
          specificProperties[property]
      }
    }
  },
  /**
   * Actualiza un subhijo
   * @param {*} state
   * @param {Object} args
   * @param {String} args.nodeId
   * @param {String} args.nodeType
   * @param {Object} args.newSubchild
   * @param {String} args.childId
   * @param {String} args.childIdProperty
   * @param {String} args.subchildId
   * @param {String} args.subchildType
   * @param {String} args.subchildIdProperty
   * @param {Boolean} args.replaceAll
   * @param {Object} args.specificProperties
   * @param {String} args.specificProperties.order
   */
  UPDATE_SUBCHILD(
    state,
    {
      nodeId,
      nodeType,
      newSubchild = {},
      childId,
      childIdProperty,
      subchildId,
      subchildType,
      subchildIdProperty,
      replaceAll = true,
      specificProperties,
    }
  ) {
    const indexNode = state.nodes.findIndex((node) => node._id === nodeId)

    if (indexNode === -1) return
    const children = state.nodes[indexNode].drawer[nodeType]
    const indexChild = children.findIndex(
      (child) => child[childIdProperty] === childId
    )

    if (indexChild === -1) return
    const child = children[indexChild]
    const indexSubchild = child[subchildType].findIndex(
      (subchild) =>
        subchild[subchildIdProperty] === newSubchild?.[subchildIdProperty] ||
        subchild[subchildIdProperty] === subchildId
    )

    // reemplaza toda la opcion
    if (replaceAll)
      return child[subchildType].splice(indexSubchild, 1, newSubchild)
    else if (!replaceAll && specificProperties) {
      // reemplazara solo algunas propiedades
      for (let property in specificProperties) {
        child[subchildType][indexSubchild][property] =
          specificProperties[property]
      }
    }
  },
  SET_SELECTED_NODE_ID(state, nodeId) {
    state.selectedNodeId = nodeId
  },
  /**
   * Setear los filtros
   * @param {} state
   * @param {Number} page - número de página
   * @param {String} status - 'all' | 'active' | 'inactive'
   * @param {String} name - filtro por nombre
   * @param {String} channel - '' si es para todos o whatsapp, messenger, instagram
   */
  SET_FILTERS(state, { page, status, name, channel }) {
    if (page) state.filters.page = page
    else if (status) state.filters.status = status
    else if (name) state.filters.name = name
    else if (channel) state.filters.channel = channel
  },
  /**
   * Setea el nodo seleccionado
   * @param {*} state
   * @param {*} node
   */
  SET_NODE_SELECTED(state, node) {
    state.nodeSelected = node
    if (!state.nodeSelected.media) state.nodeSelected.media = {}
  },
  /**
   * Setea las validaciones del chatbot
   * @param {*} state
   * @param {Object} validations
   * @param {Object} validations.options
   * @param {Number} validations.options.maxChildren
   * @param {Object} validations.buttons
   * @param {Number} validations.buttons.maxChildren
   * @param {Object} validations.cards
   * @param {Number} validations.cards.maxChildren
   * @param {Number} validations.cards.maxSubchildren
   * @param {Object} validations.lists
   * @param {Number} validations.lists.maxChildren
   * @param {Number} validations.lists.maxSubchildren
   */
  SET_VALIDATIONS(state, validations) {
    if (validations.options)
      validations.options.maxChildren = validations.options.maxOptions
    if (validations.buttons)
      validations.buttons.maxChildren = validations.buttons.maxButtons
    if (validations.cards) {
      validations.cards.maxChildren = validations.cards.maxCards
      if (validations.cards.maxButtonsByCard)
        validations.cards.maxSubchildren = validations.cards.maxButtonsByCard
    }
    if (validations.lists) {
      validations.lists.maxChildren = validations.lists.maxItems
      if (validations.lists.maxOptions)
        validations.lists.maxSubchildren = validations.lists.maxOptions
    }

    state.validations_chatbot = validations
  },
}

export default mutations
