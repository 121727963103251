<template>
  <div class="chatbots">
    <ChatbotsTemplate />
  </div>
</template>
<script>
import ChatbotsTemplate from '@/app/chatbots/components/templates/ChatbotsTemplate.vue'

export default {
  name: 'Chatbots',
  components: { ChatbotsTemplate },
  computed: {
    // channel() {
    //   return this.$route
    // },
  },
}
</script>
<style lang="sass" scoped>
.chatbots
  // height: 100vh
</style>
