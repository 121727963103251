import { notification, message } from 'ant-design-vue'
import router from '@/router'

const DOWNLOADS_MODULES = [
  {
    value: 'all',
    title: 'Todos los módulos',
  },
  {
    value: 'Dashboard',
    title: 'Dashboards',
  },
]
const DOWNLOADS_STATUS = {
  all: {
    title: 'Todos los estados',
    value: 'all',
  },
  waiting: {
    // estado en cola de espera
    actions: ['delete'],
    title: 'En cola ',
    value: 'waiting',
    tag: {
      color: '',
      title: 'En cola',
    },
  },
  active: {
    // estado
    actions: ['delete'],
    title: 'En proceso',
    value: 'active',
    tag: {
      color: 'blue',
      icon: 'loading',
      title: 'Generando archivo',
    },
  },
  completed: {
    actions: ['download'],
    title: 'Completos',
    value: 'complete',
    disabled: {
      actions: ['delete'],
      tag: {
        color: 'orange',
        title: 'Vencido',
      },
    },
  },
  failed: {
    actions: ['delete', 'retry'],
    title: 'Fallidos',
    value: 'fail',
    tag: {
      color: 'red',
      title: 'Fallido',
    },
  },
}
const NOTIFICATIONS_DOWNLOADS = {
  ready: {
    description:
      'Tu descarga ya se encuentra lista. Puedes ver tu descarga en Mis Descargas o descargarlo directamente aquí.',
  },
  waiting: {
    description:
      'Podrás ver el progreso de esta y todas tus descargas en “Mis descargas”. Recuerda que el proceso podría tomar unos minutos.',
    message: 'Se ha agregado su descarga a la cola',
    type: 'info',
  },
  active: {
    description:
      'Podrás ver el progreso de esta y todas tus descargas en “Mis descargas”. Recuerda que el proceso podría tomar unos minutos.',
    message: 'Se está generando tu descarga',
    type: 'open',
    icon: 'download',
  },
  completed: {
    description:
      'Podrás ver el progreso de esta y todas tus descargas en “Mis descargas”. Recuerda que el proceso podría tomar unos minutos.',
    message: 'Se ha generado un archivo listo para descargar',
    type: 'success',
  },
  failed: {
    description:
      'Podrás ver el progreso de esta y todas tus descargas en “Mis descargas”. Recuerda que el proceso podría tomar unos minutos.',
    message: 'Ocurrió un error con una  descarga',
    type: 'error',
  },
}

const DOWNLOADS_DASHBOARDS = {
  averageAnswer: {
    value: 'averageAnswer',
    title: 'Tiempo promedio de respuesta de un ticket',
  },
  averageStateAgents: {
    value: 'averageStateAgents',
    title: 'Tiempo promedio de estados de agentes',
  },
  newTickets: {
    value: 'newTickets',
    title: 'Resumen de tickets nuevos',
  },
  resumeSchedules: {
    value: 'resumeSchedules',
    title: 'Resumen de agendas',
  },
  sms: {
    value: 'sms',
    title: 'Resumen de envios SMS',
  },
  tagsLine: {
    value: 'tagsLine',
    title: 'Total de etiquetas en colas',
  },
  templates: {
    value: 'templates',
    title: 'Resumen de plantillas de WhatsApp',
  },
  ticketsStates: {
    value: 'ticketsStates',
    title: 'Resumen de estados de tickets ',
  },
}

/**
 * Descarga el archivo
 * @param {String} url - url del archivo
 */
const dowloadFile = (url) => {
  const a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop()
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Muestra o no la notificacion de descarga
 */
const showNotificationDownload = (response, context) => {
  if (response.link) {
    message.success(response.message)
    return notificationDownloadReady(response)
  } else {
    context.commit('ADD_DOWNLOAD', response, context)
  }
}

/**
 * Notificación para descargas que ya habian sido generadas
 * @param {Object} args
 * @param {String} args.message
 * @param {String} args.link
 */
const notificationDownloadReady = ({ message, link }) => {
  const key = `open${Date.now()}`
  notification.success({
    message,
    description: NOTIFICATIONS_DOWNLOADS.ready.description,
    btn: (h) => {
      return (
        link &&
        h(
          'a-button',
          {
            props: {
              type: 'primary',
              size: 'small',
            },
            on: {
              click: () => {
                dowloadFile(link)
                notification.close(key)
              },
            },
          },
          'Descargar reporte'
        )
      )
    },
    key,
  })
}
/**
 * Notificación para descargas el cambio de estado de una descarga
 * @param {Object} args
 * @param {String} args.status
 */
const notificationDownload = ({ status }) => {
  const typeNotification = NOTIFICATIONS_DOWNLOADS[status]
  const key = `download${Date.now()}`

  const body = {
    message: typeNotification.message,
    description: typeNotification.description,
  }
  if (typeNotification.icon)
    body.icon = (h) => {
      return h('a-icon', {
        props: {
          type: typeNotification.icon,
        },
        style: { color: '#108ee9' },
      })
    }

  notification[typeNotification.type]({
    ...body,
    btn: (h) => {
      return h(
        'a-button',
        {
          props: {
            type: 'primary',
            size: 'small',
          },
          on: {
            click: () => {
              router.push({ name: 'my-downloads' })
              notification.close(key)
            },
          },
        },
        'Ir hacia mis descargas'
      )
    },
    key,
  })
}

export {
  DOWNLOADS_MODULES,
  DOWNLOADS_STATUS,
  DOWNLOADS_DASHBOARDS,
  showNotificationDownload,
  notificationDownload,
  dowloadFile,
}
