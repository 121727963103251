<template>
  <section class="section">
    <a-row class="full-width section__filters text--left mrg-bottom-16">
      <p class="mrg-bottom-12">
        Visualiza y gestiona tus descargas. Podrás descargar tus archivos hasta
        24 horas después que se completo su procesamiento.
      </p>
      <a-select
        default-value="all"
        v-model="filters.moduleType"
        class="section__selector"
        placeholder="Selecciona un módulo"
        @change="handleChangeFilter"
      >
        <a-select-option v-for="item in DOWNLOADS_MODULES" :key="item.value">
          {{ item.title }}
        </a-select-option>
      </a-select>
      <a-select
        default-value="all"
        class="section__selector"
        placeholder="Selecciona un estado"
        v-model="filters.status"
        @change="handleChangeFilter"
      >
        <a-select-option v-for="item in DOWNLOADS_STATUS" :key="item.value">
          {{ item.title }}
        </a-select-option>
      </a-select>
    </a-row>
    <list-my-downloads
      :data-source="downloads"
      :loading="loading || filters.loading"
      :onNextSearch="searchNextPage"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListMyDownloads from '@/app/downloads/components/lists/ListMyDownloads.vue'
import {
  DOWNLOADS_MODULES,
  DOWNLOADS_STATUS,
} from '@/app/downloads/utils/downloadsUtil'

export default {
  name: 'SectionMyDownloads',
  components: {
    ListMyDownloads,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    DOWNLOADS_MODULES,
    DOWNLOADS_STATUS,
    filters: {
      moduleType: 'all',
      status: 'all',
      loading: false,
    },
  }),
  computed: {
    ...mapGetters(['downloads', 'paginate_downloads']),
  },
  methods: {
    ...mapActions(['getDownloads']),

    async handleChangeFilter() {
      this.filters.loading = true
      await this.getDownloads(this.filters)
      this.filters.loading = false
    },
    async searchNextPage() {
      this.filters.loading = true
      const response = await this.getDownloads({
        ...this.filters,
        next: true,
        page: this.paginate_downloads.page + 1,
      })
      this.filters.loading = false
      if (!response.success) return { morePaginate: false }
      return { morePaginate: response.morePaginate }
    },
  },
}
</script>

<style lang="sass" scoped>
$height_section: calc( 100vh - 92px )
.section
  background-color: $white_000
  border-radius: 8px
  border: 1px solid $gray_5
  padding: 20px 0px
  height: $height_section
  overflow: hidden
  &__selector
    width: 15em
    margin-right: 8px
  &__filters
    padding: 0px 24px
</style>
