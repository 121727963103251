<template>
  <button
    class="button"
    @mouseenter="handleShowTitle(true)"
    @mouseleave="handleShowTitle(false)"
  >
    <a-icon
      class="button__icon"
      :class="`button__icon--${colorIcon}`"
      type="message"
    />
    <span v-if="showTitle" class="mrg-left-4">Ver notas</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonMessageComment',
  props: {
    isLinked: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFullButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showTitle: false,
  }),
  computed: {
    /**
     * @return {String} color del icono
     */
    colorIcon() {
      return this.isLinked ? 'lime' : 'blue'
    },
  },
  methods: {
    /**
     * Muestra el titulo del boton
     * @param {Boolean} show
     */
    handleShowTitle(show) {
      if (!this.showFullButton) return
      else {
        this.showTitle = show
        this.$emit('onShowTitle', show)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.button
  background-color: $white_000
  padding: 8px
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  border-radius: 10px
  border-color: transparent
  cursor: pointer
  &__icon
    &--blue
      color: $blue_6
    &--lime
      color: $lime_6
  &:hover
    .button__icon
      &--blue
        color: $blue_4
</style>
