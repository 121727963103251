<template>
  <div class="accounts-template">
    <SectionAccounts />
  </div>
</template>
<script>
import SectionAccounts from '@/app/accounts/components/SectionAccounts.vue'
export default {
  name: 'AccountsTemplate',
  components: {
    SectionAccounts,
  },
}
</script>

<style lang="sass" scoped>
.accounts-template
  margin: $padding-heigth-inner
</style>
