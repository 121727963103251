import axios from 'axios'
import vari from '@/app/shared/utils/variables'
// import compare from '@/app/shared/utils/compare'

const actions = {
  /**
   * Obtiene los datos del plan en el que se encuentra la empresa
   * @param {*} context
   * @param {Boolean} [canGetCompany=true] - si se desea pedir company
   */
  async getCompanyPricing(context, canGetCompany = true) {
    try {
      const response = await axios.get(`${vari.UHR}/license/resume`)
      context.commit('SET_COMPANY_PRICING', response.data.result)
      if (canGetCompany) context.dispatch('getCompany')
    } catch (error) {
      console.error('getCompanyPricing', error)
    }
  },
  /**
   * Obtiene los datos de todos los planes
   * @param {*} context
   */
  async getPricings(context) {
    try {
      const response = await axios.get(`${vari.UHR}/license`)
      context.commit('SET_PRICINGS', response.data.result)
    } catch (error) {
      console.error('getPricings', error)
    }
  },
}

export default actions
