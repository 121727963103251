<template>
  <a-drawer
    title="Productos asignados"
    :width="410"
    :visible="visible"
    @close="handleCloseProducts"
    :body-style="{ padding: '0px' }"
    :mask="false"
  >
    <custom-section-scroll
      :master-styles="{ height: 'calc(100vh - 108px)' }"
      :content-styles="{ padding: '24px' }"
    >
      <template v-for="product in dataSource">
        <list-item-product
          :key="product._id"
          :data-source="product"
          :disabled="true"
          :showTickets="showTickets"
        />
        <a-divider :key="`divider${product._id}`" class="drawer__divider" />
      </template>
    </custom-section-scroll>
    <basic-footer
      cancelText="Cerrar"
      :show-ok="false"
      @onCancel="handleCloseProducts"
    />
  </a-drawer>
</template>

<script>
import ListItemProduct from '@/app/tickets/components/organisms/lists/ListItemProduct'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'AssignedProductsDrawer',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    showTickets: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ListItemProduct,
    BasicFooter,
    CustomSectionScroll,
  },
  methods: {
    // Emite el evento para cerrar el drawer
    handleCloseProducts() {
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.drawer__divider
  margin: 12px auto
</style>
