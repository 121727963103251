<template>
  <div>
    <p>
      Elige el tipo de configuración que tendrá tu chatbot según el canal a
      utilizar. Recuerda que <strong>no se podrá modificar luego</strong>.
      <anchor href="">Click aquí</anchor> para saber más.
    </p>
    <a-row :gutter="[20, 20]">
      <a-col
        v-for="channel in SUPPORTED_CHANNELS"
        :key="channel.value"
        :span="8"
      >
        <!-- canales -->
        <card-channel-setting
          class="card-channel"
          :title="channel.title"
          :channel="channel.channel"
          :description="channel.description"
          :selected="supportSelected === channel.value"
          @click.native="handleSelect(channel)"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CardChannelSetting from '@/app/chatbots/components/organisms/cards/CardChannelSetting'
import Anchor from '@/app/shared/components/atoms/Anchor'
import { SUPPORTED_CHANNELS } from '@/app/chatbots/utils/supportedChannels'

export default {
  name: 'SupportedChannels',
  components: {
    CardChannelSetting,
    Anchor,
  },
  props: {
    defaultSelected: {
      type: String,
      required: false,
      enum: ['whatsapp', 'instagram_messenger_webchat'],
    },
  },
  data: () => ({
    SUPPORTED_CHANNELS,
    supportSelected: '',
  }),
  mounted() {
    this.supportSelected = this.defaultSelected
  },
  methods: {
    handleSelect(channel) {
      this.supportSelected = channel.value
      this.$emit('onSelect', channel)
    },
  },
}
</script>
