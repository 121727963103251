<template>
  <a-list
    id="list_my-channels"
    item-layout="horizontal"
    :data-source="dataSource"
    class="list"
    :locale="{
      emptyText: 'No se han encontrado canales de acuerdo a los filtros.',
    }"
    :pagination="pagination"
    :loading="loading"
  >
    <list-item-channel
      slot="renderItem"
      slot-scope="channel"
      :channel="channel"
      @onOpenDetails="showDetails"
      @onLoading="(reason) => $emit('onLoading', reason)"
      @onReconnectFbChannel="handleReconnectFbChannel(channel)"
    >
    </list-item-channel>
    <!-- Modales -->
    <template v-if="dataSource && !!dataSource.length">
      <!-- Conectar WhatsApp -->
      <modal-integrate-channel
        :visible="modal_integrate_channel.visible"
        :channel="modal_integrate_channel.channel"
        @onClose="handleCloseModalIntegrateChannel"
      />
      <!-- Ver datos solicitados -->
      <ModalDetailsRequest
        :visible="visibleModalRequest"
        :details="channelSelected"
        @onCancel="visibleModalRequest = false"
        @onRestart="handleReturnConnect"
      />
      <!--Modal de error de reconexión para canales de Facebook-->
      <basic-modal v-bind="modalBasic" @onClose="handleCloseModalBasic" />
      <modal-fb-login
        ref="modalFbLogin"
        :channel="modalFbLogin.channel.channel"
        :channel-id="modalFbLogin.channel.channelId"
        :reconnect="true"
        @onSuccessReconnect="handleSuccessReconnect"
      />
    </template>
  </a-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ListItemChannel from '@/app/channels/components/molecules/ListItemChannel'
import ModalIntegrateChannel from '@/app/channels/components/organisms/modals/ModalIntegrateChannel'
import ModalDetailsRequest from '@/app/channels/components/whatsapp/modals/ModalDetailsRequest.vue'
import BasicModal from '@/app/commons/components/organisms/BasicModal.vue'
import ModalFbLogin from '@/app/channels/components/molecules/ModalFbLogin'

export default {
  name: 'ListMyChannels',
  props: {
    dataSource: { type: Array, required: true }, // channels[]
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    ListItemChannel,
    ModalIntegrateChannel,
    ModalDetailsRequest,
    BasicModal,
    ModalFbLogin,
  },
  data: () => ({
    channelSelected: null,
    visibleModalRequest: false,
    modalBasic: {
      visible: false,
      title: 'No pudimos reconectar tu canal',
      icon: {
        type: 'anticon',
        path_or_name: 'close-circle',
        style: { color: '#F5222D', fontSize: '65px' },
      },
      okText: 'Volver a conectar canal',
      typeCancel: 'button',
      cancelText: 'Cancelar',
      description: [
        'No se seleccionó el canal de “Rimac seguros”.',
        'Se intentó conectar otro canal diferente a “Rimac Seguros”.',
        'Su usuario de Facebook ya no cuenta con los permisos suficientes.',
      ],
    },
    modalFbLogin: {
      channel: {
        channel: '',
        channelCompanyId: '',
      },
    },
  }),
  computed: {
    ...mapGetters(['modal_integrate_channel']),
    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.total || 0,
        current: this.page,
        onChange: this.handleChangeList,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'canales ' + this.status : 'canal conectado'}
          </span>
        ),
      }
    },
  },
  methods: {
    ...mapActions(['setAnalytics']),
    ...mapMutations(['SET_MODAL_INTEGRATION_CHANNELS']),
    /**
     * MUestra los detalles de whatsapp
     * @param {Object} channelObj
     */
    showDetails(channelObj) {
      this.channelSelected = channelObj
      this.visibleModalRequest = true
    },
    /**
     * Vuelve a conectar
     */
    handleReturnConnect(channel) {
      this.visibleModalRequest = false
      this.SET_MODAL_INTEGRATION_CHANNELS({ visible: true, channel })
    },
    /**
     * Cierra el modal y resetea los datos del modal
     */
    handleCloseModalIntegrateChannel(channel) {
      this.SET_MODAL_INTEGRATION_CHANNELS({ visible: false, channel: '' })
      // si el canal es whatsapp, enviará a analytics
      if (channel === 'whatsapp') {
        this.setAnalytics({ eventName: 'modal_wab_request_close' })
      }
    },
    /**
     * Cambia la paginacion de la lista
     * @param {Number} pagination
     */
    handleChangeList(pagination) {
      this.$emit('onPaginate', pagination)
    },
    /**
     * Reconecta un canal de Facebook
     */
    handleReconnectFbChannel(channel) {
      console.log(channel)
      this.modalFbLogin.channel = channel
      // this.modalBasic.visible = true
      this.$refs.modalFbLogin.onGuideFacebookLogin({
        reconnect: true,
        idFacebookPage: channel.credentials.idFacebookPage,
        onErrorReconnect: () => {
          this.modalBasic.visible = true
        },
      })
    },
    handleCloseModalBasic() {
      this.modalBasic.visible = false
    },
    /**
     * Función de exito de la reconexion
     */
    handleSuccessReconnect() {
      if (!this.modalFbLogin.channel) return

      this.$emit('onLoading', {
        reason: 'reconnect_channel',
        channelCompanyId: this.modalFbLogin.channel.channelId,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.list
</style>
<style lang="sass">


#list_my-channels
  .list_info
    padding-top: 10px
    padding-bottom: 10px
    font-weight: normal
  .ant-spin-nested-loading
    border: 1px solid $gray_5
    padding: 16px
    border-radius: 4px
    height: calc( 100vh - 18rem )
    overflow: scroll
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px #D4D4D6
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px #D4D4D6
</style>
