<template>
  <a-modal
    :visible="visible"
    :title="title"
    :body-style="bodyStyles"
    :footer="null"
    @cancel="$emit('onClose')"
  >
    <div class="modal__media">
      <!--IMAGEN-->
      <img v-if="type === 'image'" :src="url" class="modal__media__content" />
      <!--VIDEO-->
      <video v-if="type === 'video'" class="modal__media__content" controls>
        <source :src="url" type="video/mp4" />
      </video>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalPreviewMedia',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Previsualización de multimedia',
    },
    type: {
      type: String,
      required: true,
      enum: ['image', 'video', 'document'],
    },
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    bodyStyles: {
      height: '400px',
    },
  }),
}
</script>

<style lang="sass" scoped>
.modal__media
  height: 100%
  max-width: 100%
  text-align: center
  &__content
    max-height: 100%
    max-width: 100%
    background: $gray_5
    min-height: 20em
</style>
