<template>
  <div>
    <div
      class="
        form__pictures
        display-flex
        align-items--center
        justify-content--center
      "
    >
      <img
        :src="`/img/img_credit_cards/visa.png`"
        alt="visa"
        class="form__picture"
      />
      <img
        :src="`/img/img_credit_cards/mastercard.png`"
        alt="mastercard"
        class="form__picture"
      />
      <img
        :src="`/img/img_credit_cards/diners.png`"
        alt="diners club"
        class="form__picture"
      />
    </div>
    <p
      class="regular-14 text--center form__description"
      style="margin-bottom: 8px"
    >
      Completa los campos para poder realizar el cobro correctamente.
    </p>
    <a-alert
      v-if="showError"
      :type="typeError"
      show-icon
      style="width: 100%"
      class="form__alert"
    >
      <p slot="message" class="mrg-bottom-0">
        {{ messageError }}. Si necesitas ayuda,
        <router-link
          :to="{ name: 'support' }"
          target="_blank"
          rel="noopener noreferrer"
          >contáctate con nuestro soporte</router-link
        >.
      </p>
    </a-alert>
    <a-form
      :form="form"
      layout="vertical"
      class="form"
      @submit.prevent="handleSubmit"
    >
      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item label="Nombres">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'names',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Apellidos">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'surnames',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="Número de tarjeta" has-feedback>
        <a-input
          placeholder="Escribe aquí..."
          v-decorator="[
            'number',
            {
              rules: [
                { required: true, message: 'Por favor rellene el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                { validator: checkTypeCard },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-row :gutter="[12]">
        <a-col :span="12">
          <a-form-item label="Fecha de vencimiento">
            <a-month-picker
              :disabled-date="disabledDate"
              placeholder="Selecciona la fecha"
              v-decorator="[
                'expirationDate',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                  ],
                },
              ]"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Código de seguridad (CVV)">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'securityCode',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :maxLength="4"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FormSettingCredit',
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    messageError: {
      type: String,
      require: false,
    },
    showError: {
      type: Boolean,
      require: false,
      default: false,
    },
    typeError: {
      type: String,
      default: 'error',
      require: false,
    },
  },
  data: () => ({
    moment,
    checkPending: null,
    paymentMethod: '', // metodo de pago
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-settings-credit' })
  },
  computed: {},
  methods: {
    /**
     * Deshabilita los día a partir de mañana
     * @param {Date} current - dia actual
     */
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day').subtract(0, 'month')
    },
    /**
     * Verifica el tipo de tarjeta
     * @param {Object} rule - regla definida en el formulario
     * @param {String} value - valor insertado en el campo
     * @param {Function} callback - funcion ejecutado para devolver errores o success
     */
    checkTypeCard(rule, value, callback) {
      clearTimeout(this.checkPending)
      this.checkPending = setTimeout(() => {
        const regexDiners =
          /(^[35](?:0[0-5]|[68][0-9])[0-9]{11}$)|(^30[0-5]{11}$)|(^3095(\\d{10})$)|(^36{12}$)|(^3[89](\\d{12})$)/gm
        const regexVisa = /^(4)(\d{12}|\d{15})$|^(606374\d{10}$)/gm
        const regexMastercard =
          /^(5[1-5]\d{14}$)|^(2(?:2(?:2[1-9]|[3-9]\d)[3-6]\d\d|7(?:[01]\d|20))\d{12}$)/gm
        // console.log(regexDiners.test(value))
        if (regexDiners.test(value)) {
          this.paymentMethod = 'diners'
          callback()
        } else if (regexVisa.test(value)) {
          this.paymentMethod = 'visa'
          callback()
        } else if (regexMastercard.test(value)) {
          this.paymentMethod = 'mastercard'
          callback()
        } else {
          callback(new Error('No es una tarjeta válida'))
        }
      }, 500)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const settings = {
            isNew: true,
            name: values.names,
            surnames: values.surnames !== undefined ? values.surnames : '',
            number: values.number,
            expirationDate: values.expirationDate,
            paymentMethod: this.paymentMethod,
            securityCode: values.securityCode,
          }
          this.$emit('onSubmit', settings)
        }
      })
    },
    /**Resetea todos los campos del formualrio */
    resetForm() {
      this.form.resetFields()
      this.onCancel()
    },
    onCancel() {
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.form__pictures
  margin-bottom: 16px
.form__picture
  margin-right: 20px
.form__description
  margin-bottom: 16px
.form__alert
  margin-bottom: 20px
</style>
