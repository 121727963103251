import Login from '@/app/auth/views/Login'
import Password from '@/app/auth/views/Password'
import RestorePassword from '@/app/auth/views/RestorePassword'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresVisitor: true,
      requiresType: ['admin', 'agent', 'supervisor', 'master_agent'],
      title: `Ingresa a tu cuenta | ${VUE_APP_NAME}`,
      requiresDevice: ['desktop', 'mobile'],
    },
  },
  {
    path: '/password',
    name: 'password',
    component: Password,
    meta: {
      requiresAuth: true,
      title: `Actualizar credenciales | ${VUE_APP_NAME}`,
      requiresDevice: ['desktop', 'mobile'],
    },
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: RestorePassword,
    meta: {
      requiresVisitor: true,
      title: `Restaurar contraseña | ${VUE_APP_NAME}`,
      requiresDevice: ['desktop', 'mobile'],
    },
  },
]
