import logo_inbox from '@/app/commons/assets/logo-Inbox.png'
import logo_ticker from '@/app/commons/assets/logo-Ticker.png'

const requestNotificationMessages = {
  title: '¿Deseas habilitar las notificaciones del navegador?',
  description:
    'La llegada de las notificaciones depende del navegador web que uses y su configuración.',
}

/**
 * Actualiza el permiso de notificaciones
 */
const updatePermission = async () => {
  const permission = Notification.permission

  switch (permission) {
    case 'granted': // permiso concedido
      localStorage.setItem('notificationPermission', 'granted')
      break
    case 'default': // volver a preguntar permiso
      {
        const localPermission = getPermission()
        // si el permiso ya ha sigo negado, no se ejecutará nada
        if (localPermission && localPermission === 'blocked') return
        localStorage.removeItem('notificationPermission')
      }
      break
    default:
      // permiso bloqueado
      localStorage.setItem('notificationPermission', 'blocked')
      break
  }
}

/**
 * Pregunta por el permiso de notificaciones
 * @param {Callback} onDenied
 * @param {Callback} onSuccess
 */
const requestPermission = async (onDenied, onSuccess) => {
  const permission = await Notification.requestPermission()
  // si el permiso de local storage esta bloqueado

  if (permission === 'granted') {
    localStorage.setItem('notificationPermission', permission)
    onSuccess()
    return
  }
  onDenied()
  localStorage.setItem('notificationPermission', 'blocked')
}

/**
 * Setea el permiso denegado a localStorage
 */
const deniedPermission = () => {
  localStorage.setItem('notificationPermission', 'blocked')
}

/**
 * Obtiene el permiso de localStorage
 */
const getPermission = () => {
  return localStorage.getItem('notificationPermission')
}

/**
 * Envia una notificación al dispositivo
 * @param {Object} notification Datos a mostrar en la notificación
 * @param {string} notification.title Titulo de la notificación
 * @param {string} notification.body Mensaje de la notificación
 * @param {Object} notification.events Eventos de la notificación
 */
const buildNotification = ({ title, body, events }) => {
  const owner_app = () =>
    !process.env.VUE_APP_OWNER_APP
      ? process.env.VUE_APP_NAME
      : process.env.VUE_APP_OWNER_APP ?? '-'

  const logo =
    owner_app() === 'Inbox'
      ? logo_inbox
      : owner_app() === 'Ticker'
      ? logo_ticker
      : null
  const notification = new Notification(title, { body, icon: logo })
  notification.onclick = events.onClick
  return notification
}
/**
 * Envento predeterminado para los tickets
 * @param {string} ticket Ticket que ha lanzado la notificación
 */
const notificationTicketOnClick = (ticket) => {
  const onClick = function () {
    console.log(ticket)
    window.focus()
  }
  return onClick
}

/**
 * Envia una notificación al dispositivo
 * @param {string} title Titulo de la notificación
 * @param {string} body Mensaje de la notificación
 * @param {Object} events Eventos de la notificación
 * @param {Object} events.onClick Callback al hacer click
 */
const notify = (title, body, events) => {
  const permission = getPermission()
  if (permission != 'granted' || document.hasFocus()) return
  return buildNotification({ title, body, events })
}

/**
 * Remueve el service worker actual
 */
const unRegisterWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      //returns installed service workers
      if (registrations.length) {
        for (let registration of registrations) {
          registration.unregister()
        }
      }
    })
  }
}
export default {
  notify,
  notificationTicketOnClick,
  requestNotificationMessages,
  requestPermission,
  getPermission,
  deniedPermission,
  updatePermission,
  unRegisterWorker,
}
