<template>
  <tabs-card-template :default-tab-selected="tabSelected" :tabs="tabs" />
</template>
<script>
import TabsCardTemplate from '@/app/shared/components/templates/TabsCardTemplate'

export default {
  name: 'ChannelsTemplate',
  components: {
    TabsCardTemplate,
  },
  data: () => ({
    tabSelected: 'all-channels',
    tabs: [
      { key: 'all-channels', title: 'Canales' },
      { key: 'my-channels', title: 'Mis canales' },
    ],
  }),
  mounted() {
    this.tabSelected = this.$route.name
  },
}
</script>
<style lang="sass" scoped>
.template
  overflow: hidden
  padding: 12px
  &__tab-content
    background: $white_000
    padding: 16px
    height: calc(100vh - 64px)
</style>
