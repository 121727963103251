<template>
  <products-template />
</template>

<script>
import ProductsTemplate from '@/app/products/components/templates/ProductsTemplate'

export default {
  name: 'Products',
  components: {
    ProductsTemplate,
  },
}
</script>

<style scoped></style>
