<template>
  <div class="password">
    <PasswordTemplate />
  </div>
</template>
<script>
import PasswordTemplate from '@/app/auth/components/templates/PasswordTemplate.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Password',
  components: {
    PasswordTemplate,
  },
  created() {
    this.validateSignin({})
  },
  methods: {
    ...mapActions(['validateSignin']),
  },
}
</script>
<style lang="sass" scoped>
.password
  height: 100vh
  overflow-y: auto
</style>
