import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**Integrar proveedor con whatsapp
   * @param {Object} provider - objeto del poveedor
   * @param {String} provider.channel - canal
   * @param {String} provider.provider - nombre del proveedor
   * @param {String} provider.uuid - uuid del proveedor de Twilio
   * @param {String} provider.sid - sid del proveedor de Twilio
   * @param {String} provider.token - token del proveedor de Twilio
   */
  async integrateProviderWhatsapp(context, provider) {
    try {
      const response = await axios.post(
        `${vari.UHR}/admin/company/channel/provider`,
        provider
      )
      if (
        response.data.webhooks.statusCode &&
        response.data.webhooks.statusCode == 409
      ) {
        return { success: false, details: 'Tu empresa no tiene el API key' }
      } else {
        // vuelve a traer al listado de canales simples
        context.dispatch('getSimpleCompanyChannels')
        // hace la peticion para verificar el permiso del canal
        context.dispatch('getCompanyPricing', false)
        return response.data
      }
    } catch (err) {
      console.log(err)
      return err.response.data
    }
  },
  /**Enviar solicitud a securitec */
  async sendRequestToSec(context, values) {
    try {
      console.log(context, values)
      const response = await axios.post(
        `${vari.UHR}/admin/company/channel/provider/form/request`,
        values
      )
      // vuelve a traer al listado de canales simples
      context.dispatch('getSimpleCompanyChannels')
      // hace la peticion para verificar el permiso del canal
      context.dispatch('getCompanyPricing', false)

      context.dispatch('setAnalytics', {
        eventName: 'securitec_wab_api_request',
      })
      return response.data
    } catch (err) {
      console.log(err)
      return err.response.data
    }
  },
  /**
   * Enviar solicitud para editar datos
   * @param {String} apply - 'change'
   * @param {Object} whatsapp
   * @param {String} whatsapp.image
   * @param {String} whatsapp.description
   * @param {Strong} channelCompanyId
   * @returns
   */
  async sendRequestToEdit(context, values) {
    const formData = new FormData()
    formData.append('apply', 'change')
    for (const property in values) {
      formData.append(property, values[property])
    }

    try {
      const response = await axios.post(
        `${vari.UHR}/admin/company/channel/provider/${values.channelCompanyId}/apply`,
        formData
      )
      // context.dispatch('getCompany')
      return response.data
    } catch (err) {
      console.log(err.response)
      return err.response.data
    }
  },
  /**
   * Enviar solicitud para dar de baja un numero
   * @param {Object} contact
   * @param {Object} reason
   * @param {String} channelCompanyId
   */
  async sendRequestToDown(context, { contact, reason, channelCompanyId }) {
    try {
      const formData = new FormData()
      formData.append('apply', 'down')
      formData.append('reason', reason)
      formData.append('contact_email', contact.email)
      formData.append('contact_phone_code', contact.code)
      formData.append('contact_phone_number', contact.phone)
      const response = await axios.post(
        `${vari.UHR}/admin/company/channel/provider/${channelCompanyId}/apply`,
        formData
      )
      // context.dispatch('getCompany')
      return response.data || {}
    } catch (err) {
      console.log(err)
      return err?.data
    }
  },
  /**
   * Enviar solicitud para bajar
   * @returns
   */
  async updateAliasWba(context, { alias, channelCompanyId }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/my-channels/update-alias/${channelCompanyId}`,
        { alias }
      )
      return response.data || {}
    } catch (err) {
      console.log(err)
      return err?.data
    }
  },
  /**
   * Obtener el tier por cada numero de gupshup
   * @param {String} channelCompanyId
   */
  async getTierWhatsAppChannel(context, channelCompanyId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/admin/company/tier/${channelCompanyId}`
      )
      return response.data || {}
    } catch (err) {
      console.error(err)
      return err?.data
    }
  },
}
export default actions
