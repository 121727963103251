const getters = {
  supervisors: (state) => state.supervisors,
  supervisorsAsigned: (state) => state.supervisorsAsigned,
  supervisorsNoAsigned: (state) => {
    let diff = []
    for (let i = 0; i < state.supervisors.length; i++) {
      if (state.supervisorsAsigned.indexOf(state.supervisors[i]) === -1) {
        diff.push(state.supervisors[i])
      }
    }
    for (let i = 0; i < state.supervisorsAsigned.length; i++) {
      if (state.supervisors.indexOf(state.supervisorsAsigned[i]) === -1) {
        diff.push(state.supervisorsAsigned[i])
      }
    }
    return diff
  },
  admins: (state) => state.admins,
}

export default getters
