<template>
  <a-modal
    :visible="visible"
    width="34em"
    :bodyStyle="modalSelectCard.styles"
    centered
    @cancel="onCancel"
    :afterClose="onCancel"
  >
    <h5 class="heading-h5" style="margin-bottom: 12px">
      Tus tarjetas afiliadas
    </h5>
    <p class="body-2" style="margin-bottom: 12px">
      Selecciona una de tus tarjetas para realizar el pago
    </p>
    <CustomSectionScroll
      id-master="modal-select-credit"
      :masterStyles="{
        height: 'calc( 100% - 42px )',
        width: 'calc(100% + 48px)',
      }"
      :contentStyles="{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '24px',
      }"
    >
      <a-alert
        v-if="showError"
        :type="typeError"
        show-icon
        class="modal__alert"
      >
        <p slot="message" class="mrg-bottom-0">
          {{ messageError }}. Si necesitas ayuda,
          <router-link
            :to="{ name: 'support' }"
            target="_blank"
            rel="noopener noreferrer"
            >contáctate con nuestro soporte</router-link
          >.
        </p>
      </a-alert>
      <a-radio-group v-if="profile" v-model="creditCardSelected.idCard">
        <a-radio
          v-for="creditCard in profile.cards"
          :key="creditCard.idCard"
          :style="radioStyle"
          :value="creditCard.idCard"
        >
          <CreditCard
            :name-owner="creditCard.name"
            :number-card="creditCard.lastDigits"
            :type="creditCard.type"
            :dateExpired="creditCard.expirationDate"
            :selected="
              creditCard.idCard === creditCardSelected.idCard ? true : false
            "
            style="margin-left: 16px"
          />
        </a-radio>
        <a @click="addOtherCredit" style="text-align: center; display: block"
          >Agregar nueva tarjeta</a
        >
      </a-radio-group>
    </CustomSectionScroll>
    <template slot="footer">
      <a-button @click="onCancel">Cancelar</a-button>
      <a-button
        type="primary"
        @click="handlePurchase"
        :disabled="!creditCardSelected.idCard"
        :loading="loading"
        >Pagar ${{ purchase.total }}</a-button
      >
    </template>
    <a-modal width="300px" centered :closable="false" :visible="insertCvvModal">
      <h4>Ingresa tu código de seguridad (CVV). Para procesar tu pago.</h4>
      <a-input
        v-model="creditCardSelected.securityCode"
        placeholder="CVV"
        :maxLength="4"
      />
      <template slot="footer">
        <a-button key="back" @click="insertCvvModal = false">
          Cancelar
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="confirmCvv"
        >
          Pagar
        </a-button>
      </template>
    </a-modal>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CreditCard from '@/app/shared/components/cards/CreditCard.vue'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'ModalSelectCreditCard',
  mixins: [attemptMixin],
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    messageError: {
      type: String,
      require: false,
    },
    showError: {
      type: Boolean,
      require: false,
      default: false,
    },
    typeError: {
      type: String,
      default: 'error',
      require: false,
    },
  },
  components: {
    CreditCard,
    CustomSectionScroll,
  },
  data: () => ({
    radioStyle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '24px',
    },
    creditCards: [
      {
        idCard: 'credit1',
        owner: 'Gabriela Ignacio',
        numberCard: '234',
        type: 'visa',
        dateExpired: '12/13/13',
      },
      {
        idCard: 'credit2',
        owner: 'Gabriela Ignacio',
        numberCard: '434',
        type: 'mastercard',
        dateExpired: '12/13/13',
      },
      {
        idCard: 'credit3',
        owner: 'Gabriela Ignacio',
        numberCard: '434',
        type: 'diners',
        dateExpired: '12/13/13',
      },
    ],
    insertCvvModal: false,
    creditCardSelected: {
      idCard: null,
      isNew: false,
      securityCode: null,
    },
    modalSelectCard: {
      styles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '36em',
      },
    },
  }),
  computed: {
    ...mapGetters(['purchase', 'profile']),
  },
  mounted() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'Profile not loaded'
      this.creditCardSelected.idCard = this.profile.cards[0].idCard
    })
  },
  methods: {
    onCancel() {
      this.$emit('hideModal')
      this.insertCvvModal = false
      this.creditCardSelected.securityCode = null
    },
    addOtherCredit() {
      this.$emit('addOtherCredit')
    },
    handlePurchase() {
      const creditCard = this.profile.cards.find(
        (card) => card.idCard === this.creditCardSelected.idCard
      )
      this.creditCardSelected.isFirstTime = creditCard.isFirstTime
      if (!this.creditCardSelected.isFirstTime) {
        this.$emit('onOk', this.creditCardSelected)
        this.$emit('done', this.creditCardSelected)
        return
      }
      this.insertCvvModal = true
    },
    confirmCvv() {
      if (!this.creditCardSelected.securityCode) return
      this.$emit('onOk', this.creditCardSelected)
      this.$emit('done', this.creditCardSelected)
      this.insertCvvModal = false
      this.creditCardSelected.securityCode = null
    },
    onOk() {
      this.$emit('onOk')
    },
  },
}
</script>
<style lang="sass" scoped>
.modal__alert
  margin: 0px 24px 20px
</style>
