<template>
  <div class="supervision">
    <SupervisionTemplate />
  </div>
</template>
<script>
import SupervisionTemplate from '@/app/supervision/components/templates/SupervisionTemplate.vue'
export default {
  name: 'Supervision',
  components: {
    SupervisionTemplate,
  },
}
</script>
