<template>
  <a-row
    type="flex"
    justify="space-between"
    class="banner flex-no-wrap"
    :style="banner_selection.styles"
  >
    <a-row type="flex" align="middle" class="flex-grow flex-no-wrap">
      <span
        class="banner__container-icon banner__container-icon--close"
        @click="$emit('onClose')"
      >
        <a-icon type="close" class="banner__icon" />
      </span>
      <span class="regular-16 ellipsis mrg-bottom-0 mrg-left-4">
        {{ quantity }}
        {{ quantity === 1 ? 'ticket seleccionado' : 'tickets seleccionados' }}
      </span>
    </a-row>
    <dropdown-actions-ticket
      class="banner__dropdown"
      :show-menu-agents="showMenuAgents"
      :data-source-mailboxes="dataSourceMailboxes"
      @onUnread="$emit('onUnread')"
      @onTransferToAgent="$emit('onTransferToAgent')"
      @onTransferToLine="$emit('onTransferToLine')"
      @onMoveMailbox="(mailbox) => $emit('onMoveMailbox', mailbox)"
    >
      <span class="banner__container-icon banner__container-icon--more">
        <i class="anticon banner__icon">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5a2 2 0 104 0 2 2 0 00-4 0zm2 9a2 2 0 110-4 2 2 0 010 4zm0 7a2 2 0 110-4 2 2 0 010 4z" fill="currentColor"/></svg>
        </i>
      </span>
    </dropdown-actions-ticket>
    <menu-actions-ticket
      class="banner__menu"
      :show-menu-agents="showMenuAgents"
      :data-source-mailboxes="dataSourceMailboxes"
      @onUnread="$emit('onUnread')"
      @onTransferToAgent="$emit('onTransferToAgent')"
      @onTransferToLine="$emit('onTransferToLine')"
      @onMoveMailbox="(mailbox) => $emit('onMoveMailbox', mailbox)"
    />
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'
import DropdownActionsTicket from '@/app/tickets/components/molecules/DropdownActionsTicket'
import MenuActionsTicket from '@/app/tickets/components/molecules/MenuActionsTicket'

export default {
  name: 'BannerSelection',
  props: {
    quantity: {
      type: [String, Number],
      required: true,
    },
    dataSourceMailboxes: {
      type: Array,
      required: true,
    },
    showMenuAgents: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DropdownActionsTicket,
    MenuActionsTicket,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['banner_selection']),
  },
}
</script>

<style lang="sass" scoped>
$padding: 20px
.banner
  background-color: $gold_2
  width: 100%
  margin-left: - $padding;
  position: fixed
  z-index: 20
  top: 0
  &__container-icon
    padding-top: 10px
    padding-bottom: 10px
    display: flex
    align-items: center
    &--close
      padding-left: 20px
      padding-right: 8px
    &--more
      padding-left: 16px
      padding-right: 10px
  &__icon
    font-size: 24px
  &__dropdown
    display: none
    @include xs
      display: flex
    @include sm
      display: flex
    @include md
      display: none
  &__menu
    display: none
    @include xs
      display: none
    @include sm
      display: none
    @include md
      display: flex
</style>
