<template>
  <a-modal
    :visible="visible"
    :title="`Agentes disponibles de la cola ${nameLine}`"
    :footer="null"
    :bodyStyle="bodyStyle"
    centered
    @cancel="$emit('onClose')"
  >
    <a-input-search
      v-model="seeker"
      placeholder="Buscar agente"
      style="width: 100%; margin-bottom: 4px"
    />
    <template v-if="filterAgents.length > 0">
      <a-row
        v-for="agent in filterAgents"
        :key="`Agent${agent.userId}`"
        type="flex"
        align="middle"
        class="agent-contain flex-no-wrap"
      >
        <!--Contenedor del avatar-->
        <div class="mrg-right-12">
          <avatar-user :src="agent.avatar" :names="agent.names" :size="40" />
        </div>
        <!--Nombre del agente-->
        <span class="modal__name ellipsis-2 flex-grow mrg-right-12"
          >{{ agent.names }} {{ agent.surnames }}</span
        >
        <!--modal de confirmacion-->
        <a-popconfirm
          placement="bottomRight"
          ok-text="Transferir"
          cancel-text="Cancelar"
          @confirm="handleTransfer(agent)"
        >
          <template slot="title">
            <p style="margin-bottom: 2px">
              ¿Estás segur@ de transferir el ticket a este agente?
            </p>
            <p style="margin-bottom: 0; color: #bfbfbf">
              Recuerda que el ticket será derivado al agente
            </p>
          </template>
          <a-button
            :disabled="userIdSelected"
            :size="buttons_size"
            :loading="verifyLoading(agent.userId, loading)"
            >Transferir</a-button
          >
        </a-popconfirm>
      </a-row>
    </template>
    <template v-else>
      <div style="text-align: center; margin: 1rem">
        <span>No se encontraron agentes disponibles para esta cola.</span>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AvatarUser from '@/app/shared/components/avatars/AvatarUser'

export default {
  name: 'ModalTransferAgent',
  props: {
    ticket: {
      type: Object,
      default: null,
      required: false,
    },
    tickets: {
      type: Array,
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
      require: false,
    },
    role: {
      type: String,
      required: true,
      enum: ['agent', 'admin'],
    },
    userIdExclude: {
      type: String,
      require: false,
      default: null,
    },
  },
  components: {
    AvatarUser,
  },
  data: () => ({
    agents_local: [],
    seeker: '',
    bodyStyle: {
      height: '480px',
      overflowY: 'auto',
      padding: '12px',
    },
    loading: false,
    userIdSelected: null,
  }),
  watch: {
    /**
     * Observa a visible
     * @param {Boolean} nvalue - nuevo valor
     */
    visible(nvalue) {
      if (nvalue) {
        if (this.profile.type == 'agent') {
          this.agents_local = this.agents.filter(
            (agent) => agent.userId !== this.userIdExclude
          )
        } else {
          this.getAccountsUsers().then(() => {
            let agentsId = this.agentsAccounts.map((el) => el.userId)
            agentsId = agentsId.filter(
              (userId) => userId !== this.userIdExclude
            )
            this.listAgents(agentsId)
          })
        }
      } else {
        this.agents_local = []
      }
    },
  },
  computed: {
    ...mapGetters(['profile', 'agentsAccounts', 'agents', 'buttons_size']),
    /**
     * Filtrar agentes a listar para transferir
     * @returns {Object[]}
     */
    filterAgents() {
      let regex = new RegExp(this.seeker, 'gi')
      if (this.ticket) {
        return this.agents_local
          .filter((agent) => {
            if (agent.company) {
              return agent.company.linesId.some(
                (idLines) => idLines === this.ticket.company.lineId
              )
            }
            return true
          }) // Según la Cola del ticket
          .filter(
            (agent) => agent.names.match(regex) || agent.surnames.match(regex)
          )
          .filter((agent) => agent.userId != this.ticket.agent?.userId)
      }

      return []
    },
    /**
     * Nombre de cola
     * @returns {String} - nombre de cola
     */
    nameLine() {
      return `"${this.ticket?.company?.lineName}"`
    },
  },
  methods: {
    ...mapActions([
      'transferAdminTicket',
      'getSimplyAgents',
      'getAccountsUsers',
      'getTotalUnreadTickets',
      'playSound',
      'transferTickets',
    ]),
    async listAgents(agentsId = []) {
      this.agents_local = await this.getSimplyAgents({
        usersId: agentsId,
        extraFields: ['company', 'avatar'],
      })
    },
    /**
     * Prepara la transferencia de un ticket, puede ser hacia un agente o una cola
     * @param {Object} agent Objeto del agente de destino
     */
    async handleTransfer(agent) {
      this.loading = true
      this.userIdSelected = agent.userId
      const currentTicket = { ...this.ticket }
      const response = await this.transferAccordingToRole({ agent })
      if (response.success) {
        this.$message.success(`Se transfirió el ticket a ${agent.names}`)
        this.$emit('onSuccess', agent, currentTicket)
        // si la transferencia es a  si mismo
        if (
          agent.userId === this.profile.userId ||
          currentTicket.agent?.userId === this.profile.userId
        ) {
          this.playSound('message') // Reproducir sonido
          this.getTotalUnreadTickets(true)
        }
        this.$emit('onClose')
      } else {
        this.$message.error(
          response.details || 'Al parecer este ticket ya no se puede transferir'
        )
        this.$emit('onError')
      }

      this.loading = false
      this.userIdSelected = null
    },
    /**
     * Transfiere de acuerdo al rol
     * @param {Object} agent - el agente al cual se le transferira
     */
    async transferAccordingToRole({ agent }) {
      const currentTicket = { ...this.ticket }
      switch (this.role) {
        case 'admin': {
          return await this.transferAdminTicket({
            typeTransfer: 'agent',
            ticket: currentTicket,
            agent,
            massive: false,
          })
        }
        case 'agent': {
          return this.transferTickets({
            typeTransfer: 'agent',
            ticketsIds: [currentTicket._id],
            agent,
            massive: false,
          })
        }
      }
    },
    /**
     * Verifica la carga del boton de tranferencia
     * @param {String} agentUserId
     * @param {Boolean} load
     */
    verifyLoading(agentUserId, load) {
      return this.userIdSelected === agentUserId && load
    },
  },
}
</script>

<style lang="sass" scoped>
.agent-contain
  border-bottom: 1px solid $x_gray_light
  padding: 8px
  .modal__name
    margin-bottom: 0px
    color: $gray_dark_900
  .agent-avatar
    border-radius: 50%
    height: 40px
    width: 40px
</style>
