<template>
  <card-base
    @click.native="handleSelectNode"
    :box-shadow="boxShadow"
    class="card"
    :class="{ 'card--loading': disabled }"
  >
    <icon-node v-if="iconNode.ant" :type="iconNode.name" class="card__icon" />

    <p class="regular-12 mrg-bottom-0 card__description">
      {{ nodeDescription }}
    </p>
    <p
      v-if="reasonToDisabled"
      class="regular-12 mrg-top-4 mrg-bottom-0 card__reason"
    >
      {{ reasonToDisabled }}
    </p>
  </card-base>
</template>

<script>
import { mapGetters } from 'vuex'
import CardBase from '@/app/shared/components/cards/CardBase'
import IconNode from '@/app/chatbots/components/atoms/IconNode'
import addDefaultNodesMixin from '@/app/chatbots/mixins/addDefaultNodes'

export default {
  name: 'CardAddNode',
  components: {
    CardBase,
    IconNode,
  },
  mixins: [addDefaultNodesMixin],
  props: {
    nodeType: {
      type: String,
      required: true,
    },
    nodeDescription: {
      type: String,
      required: true,
    },
    nameAddFunction: {
      // nombre de la función para agregar nodos
      type: String,
      required: true,
    },
  },
  data: () => ({
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  computed: {
    ...mapGetters(['simple_chatbots_vars']),

    /**
     * @return {Object} iconNode
     * @return {Boolean} iconNode.ant
     * @return {String} iconNode.name
     */
    iconNode() {
      let icon = {}
      switch (this.nodeType) {
        case 'options':
          {
            icon.ant = true
            icon.name = 'profile'
          }
          break
        case 'message':
          {
            icon.ant = true
            icon.name = 'message'
          }
          break
        case 'catcher':
          {
            icon.ant = true
            icon.name = 'number'
          }
          break
        case 'buttons':
          {
            icon.ant = true
            icon.name = 'button'
          }
          break
        case 'cards':
          {
            icon.ant = true
            icon.name = 'browser'
          }
          break
        case 'lists':
          {
            icon.ant = true
            icon.name = 'bars'
          }
          break
      }
      return icon
    },
    /**
     * @return {Boolean} - verifica si la card debería estar deshabilitada
     */
    disabled() {
      if (this.nodeType === 'catcher') return !this.simple_chatbots_vars.length
      else return false
    },
    /**
     * @return {String} - la razón por lo cual se deshabilito la card
     */
    reasonToDisabled() {
      let reason = null
      switch (this.nodeType) {
        case 'catcher':
          reason = 'No tienes variables'
      }
      return this.disabled ? reason : null
    },
  },
  methods: {
    /**
     * Esucha cuando se selecciona un nodo
     */
    handleSelectNode() {
      if (this.disabled) return
      // Función dinámica para agregar nodos
      this[this.nameAddFunction](null)
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  width: 136px
  height: 124px
  &__icon
    font-size: 20px
    color: $blue_6
    margin-bottom: 12px
  &__description
    color: $gray_8
  &__reason
    color: $gray_8
  &:hover
    border-color: $blue_6
    cursor: pointer
  &--loading
    cursor: not-allowed
    height: 10em
    width: 12em
    & ~ .card
      height: 10em
      width: 12em
    .card__icon
      color: $gray_6
    .card__description
      color: $gray_6
    &:hover
      border-color: $gray_6
      cursor: not-allowed
</style>
