<template>
  <a-drawer
    :title="drawer_filters.title"
    :width="drawer_filters.width"
    :visible="visible"
    @close="onClose"
  >
    <a-form :form="form" class="filters" layout="vertical" hide-required-mark>
      <a-form-item class="filters__item">
        <!--Renderiza el label customizado del formulario-->
        <template v-slot:label>
          <label class="semibold-14">Estados</label>
        </template>
        <a-select
          v-model="localFilters.status"
          placeholder="Selecciona"
          class="section__button"
        >
          <a-select-option value="all">Todos los estados</a-select-option>
          <a-select-option value="enable">Activos</a-select-option>
          <a-select-option value="disable">Inactivos</a-select-option>
        </a-select>
      </a-form-item>
      <!-- Campos personalizados -->
      <a-form-item class="filters__item">
        <h6 class="semibold-14">Campos personalizados</h6>
        <!--Si no hay campos-->
        <template
          v-if="
            !fields_company_products || fields_company_products.length === 0
          "
        >
          <p class="empty">No hay campos personalizados</p>
        </template>
        <!--Si hay data, recorrera todos los campos-->
        <template
          v-if="
            localFilters.customFields && localFilters.customFields.length > 0
          "
        >
          <template v-for="(customField, index) in localFilters.customFields">
            <custom-field-option
              :key="customField.key"
              :field="customField"
              @onRemove="removeCustomFilter(customField.key)"
              @onChangeValue="updateCustomFilterValue"
              @onChangeField="updateCustomFilter"
              :data-source="disabledFields(fields_company_products)"
              :isLast="localFilters.customFields.length - 1 === index"
            />
          </template>
        </template>
        <!--Si hay campos personalizados de la empresa -->
        <template
          v-if="fields_company_products && fields_company_products.length > 0"
        >
          <a @click="addCustomFieldFilter" class="add">
            <a-icon type="plus" /> Agregar campo customizado
          </a>
        </template>
      </a-form-item>
    </a-form>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" :disabled="disabledCancel">
        Cancelar
      </a-button>
      <a-button
        type="primary"
        :loading="loadingOk"
        :disabled="disabledOk"
        @click="handleSubmit"
      >
        {{ okText }}
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CustomFieldOption from '@/app/shared/components/molecules/CustomFieldOption'
import supportMixin from '@/app/shared/mixins/support'
import formatMixin from '@/app/shared/mixins/format'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'FilterDrawer',
  mixins: [supportMixin, formatMixin, attemptMixin, compareMixin],
  components: { CustomFieldOption },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data: () => ({
    localFilters: {},
    loadingOk: false,
    disabledCancel: false,
    disabledOk: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'filter-drawer-products' })
  },
  watch: {
    /**
     * Observa si el valor de visible cambia, si es verdadero seteara los valores de los filtros a los locales
     */
    visible(val) {
      if (val) this.localFilters = this.filters
    },
  },
  computed: {
    ...mapGetters(['fields_company_products', 'drawer_filters']),
    /**
     * @returns {String} - Aplicando o Aplicar
     */
    okText() {
      return this.loadingOk ? 'Aplicando' : 'Aplicar'
    },
  },
  methods: {
    ...mapActions(['getCompanyProducts']),

    onClose() {
      this.$emit('onClose')
      this.disabledCancel = false
      this.disabledOk = false
      this.loadingOk = false
      this.onSetFilters()
    },
    async handleSubmit() {
      this.form.validateFields(async (err) => {
        if (!err) {
          this.loadingOk = true
          this.disabledCancel = true
          this.onSetFilters()
          this.$emit('onLoading', true)
          await this.getCompanyProducts({
            page: this.localFilters.page,
            search: this.localFilters.search,
            status: this.localFilters.status,
            customFields: this.localFilters.customFields,
          })
          this.$emit('onLoading', false)
          this.loadingOk = false
          this.onClose()
        }
      })
    },
    addCustomFieldFilter() {
      const idsLocalFields = this.localFilters.customFields.map(
        (localField) => localField.customFieldId
      )
      const defaultField = this.fields_company_products.filter(
        (field) => !idsLocalFields.includes(field._id)
      )[0]

      this.localFilters.customFields.push({
        customFieldId: defaultField._id,
        value: [],
        key: Date.now(),
        type: defaultField.type,
      })
    },
    /**
     * Remueve el campo filtro
     * @param {String} key - id del campo
     */
    removeCustomFilter(key) {
      this.localFilters.customFields = this.localFilters.customFields.filter(
        (customField) => customField.key != key
      )
    },
    /**
     * Actualizar el campo filtro
     *  @param {String} key - id del campo
     *  @param {Object} value - objeto del campo
     */
    updateCustomFilterValue(key, value) {
      const index = this.localFilters.customFields.findIndex(
        (customField) => customField.key == key
      )
      this.localFilters.customFields[index].value = value
    },
    /**
     * Actualizar el campo filtro
     *  @param {String} key - id del campo que se cambiara
     *  @param {Object} newCustomField - nuevo campo customizado
     */
    updateCustomFilter(key, newCustomField) {
      const index = this.localFilters.customFields.findIndex(
        (customField) => customField.customFieldId == key
      )
      this.localFilters.customFields[index].value =
        newCustomField.type == 'text' ? '' : []
      this.localFilters.customFields[index].type = newCustomField.type
      this.localFilters.customFields[index].customFieldId = newCustomField._id
    },
    /**
     * Emitir el seteo de filtros
     */
    onSetFilters() {
      this.$emit('onSetFilters', {
        page: this.localFilters.page,
        search: this.localFilters.search,
        status: this.localFilters.status,
        customFields: this.localFilters.customFields,
      })
    },
    /**
     * Deshabilitar campos personalizados
     */
    disabledFields(fields) {
      const arrFields = JSON.parse(JSON.stringify(fields))
      const idsLocalFields = this.localFilters.customFields.map(
        (localField) => localField.customFieldId
      )
      arrFields.forEach((field) => {
        if (idsLocalFields.includes(field._id)) field.disabled = true
        else field.disabled = false
      })
      return arrFields
    },
  },
}
</script>

<style lang="sass" scoped>
.filters__item
  margin-bottom: 12px
  .add
    padding: 0
    margin-bottom: 10px
</style>
