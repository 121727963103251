const actions = {
  async listenRoutes(context) {
    try {
      this._vm.$socket.on('client:user:routes', ({ id, completed }) => {
        context.commit('UPDATE_PROFILE_ROUTE', { id, completed })
      })
    } catch (err) {
      console.log(err)
    }
  },
}

export default { actions }
