<template>
  <div>
    <!--Modal informativo para los canales de Facebook-->
    <ModalInfoList
      v-if="hasModalInfo && !!Object.keys(dataModalInfoList).length"
      :data="dataModalInfoList"
      :enumerated="enumetaredModalInfoList"
      @handleClose="closeModalInfo"
      @handleOk="okModalInfo"
    />
    <!--Modal de selección de página para los canales de Facebook-->
    <ModalSelectPage
      v-if="listPages.length > 1"
      :visible="modalSelectPage.visible"
      :list-pages="listPages"
      :login-type="loginType"
      @onCancel="handleCancelSelected"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalInfoList from '@/app/channels/components/organisms/modals/ModalInfoList'
import ModalSelectPage from '@/app/channels/components/messenger/ModalSelectPage'

export default {
  name: 'ModalFbLogin',
  components: {
    ModalInfoList,
    ModalSelectPage,
  },
  props: {
    channel: {
      type: String,
    },
    reconnect: {
      type: Boolean,
      default: false,
    },
    channelId: {
      type: String,
    },
  },
  data: () => ({
    modalSelectPage: { visible: false },
    listPages: [], // lista de paginas vinculadas
    loginType: '',
    dataModalInfoList: {}, // Modales informativos para mostrar
    otherFbIntegration: false, // Existe otra conexión de integración con otra aplicación de Facebook?
    stepModal: 1, // Numero del paso de los modales a mostrar
  }),
  watch: {
    listPages() {
      // sale el modal de seleccion de páginas si es mayor a una
      if (this.listPages.length > 1) this.modalSelectPage.visible = true
      else {
        // si la página es solo una, entonces la suscripción se hace defrente
        this.subscribePages(this.listPages[0])
      }
    },
  },
  computed: {
    ...mapGetters(['simple_company_channels']),
    /**
     * @return {Boolean}
     */
    hasModalInfo() {
      return ['instagram', 'messenger'].includes(this.channel)
    },
    /**
     * Lista del modal enumerado? Lo enumera si la lista tiene más de un elemento
     * @return {Boolean}
     */
    enumetaredModalInfoList() {
      return (
        this.dataModalInfoList.list && this.dataModalInfoList.list.length > 1
      )
    },
  },
  methods: {
    ...mapActions([
      'msgrMetaLogin',
      'igMetaLogin',
      'fbLongUserAccessToken',
      'fbAccounts',
      'fbSubscribeApps',
      'saveFacebookPage',
      'getInstagramBusiness',
      'getSimpleCompanyChannels',
      'reconnectFbChannel',
    ]),

    /**
     * !IMPORTANTE: Se llama desde otros componentes
     * Abre los modales de Facebook para la vinculación
     * @param {Number} [step=1] El número del paso en el que se encuentra de la llamada de modales
     * @return {Boolean} último modal?
     */
    onGuideFacebookLogin(
      { step = 1, reconnect = false, idFacebookPage, onErrorReconnect } = {
        step: 1,
      }
    ) {
      this.stepModal = step
      // TODO: Esto se solucionará para cuando los canales de Facebook no dependan entre si
      // TODO: Se mostrará el modal de información cuando exista un canal de instagram conectado
      const isInstagramConnected = false

      // si la app ya es dependiente este valor se falsea
      const isDependent = true

      this.otherFbIntegration = isInstagramConnected && isDependent
      if (this.otherFbIntegration) {
        switch (this.stepModal) {
          case 1: {
            this.dataModalInfoList = {
              key: 'considerations',
              channel: 'messenger',
              width: '600px',
              title: 'Facebook Messenger',
              okButtonName: 'Conectar mi canal de Facebook Messenger',
              okButtonType: 'primary',
              subtitle:
                '¡Hey! Te dejamos unos consejos para conectar con éxito tu canal de Facebook Messenger',
              list: [
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Messenger es la misma que tienes conectada en el canal de Instagram',
                  description:
                    'Solo necesitaras darle en continuar  a todo el proceso de vinculación',
                },
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Messenger es diferente a la que tienes conectada en el canal de Instagram',
                  description:
                    'No quites el check de la página de Facebook que seleccionaste para conectar tu canal de Instagram, sino removerás esta página de ese canal.',
                },
                {
                  title:
                    'Si tu página de Facebook tiene otra cuenta administradora de Facebook',
                  description:
                    'Para el proceso de vinculación solo selecciona “Iniciar sesión en otra cuenta”, ingresa tus credenciales y selecciona la página que deseas vincular.',
                },
              ],
            }
            break
          }
          default: {
            return true
          }
        }
        // return false
      } else {
        this.onFbLogin({ reconnect, idFacebookPage, onErrorReconnect })
        return false
      }
    },
    /**
     * Logueo de Facebook, se levanta un modal propio de facebook
     * @param {Boolean} reconnect
     * @param {String} idFacebookPage
     */
    async onFbLogin({ reconnect = false, idFacebookPage, onErrorReconnect }) {
      this.disabledBtnConect = true
      this.loginType = 'messenger'

      await this.msgrMetaLogin()
        .then(async (response) => {
          await this.fbLongUserAccessToken({
            accessToken: response.authResponse.accessToken,
            channel: this.loginType,
          })
            .then(async (responseToken) => {
              await this.fbAccounts({
                userId: response.authResponse.userID,
                userAccessToken: responseToken.access_token,
              })
                .then(async (responseAccounts) => {
                  if (responseAccounts.data.length) {
                    // valida si entre las cuentas se encuentra la pagina que se desea reconectar
                    if (reconnect) {
                      const foundPageToReconnect = responseAccounts.data.find(
                        (account) => account.id === idFacebookPage
                      )
                      // si no se ha encontrado la página que se desea reconectar, dará error
                      if (!foundPageToReconnect) return onErrorReconnect()
                    }
                    this.listPages = responseAccounts.data
                  } else {
                    this.listPages = []
                    this.$message.warning(
                      'No existen los permisos suficientes para conectar tu página'
                    )
                  }
                })
                .catch((error) => {
                  console.error('errror', error)
                  this.$message.warning(
                    'No existen los permisos suficientes para conectar tu página'
                  )
                })
            })
            .catch((error) => {
              console.error('errror', error)
              this.$message.warning(
                'No existen los permisos suficientes para conectar tu página'
              )
            })
        })
        .catch(() => {
          this.$message.error('No logueado')
        })
      this.disabledBtnConect = false
    },
    /**
     * Abre los modales de Instagram
     * @param {Number} [step=1] El número del paso en el que se encuentra de la llamada de modales
     * @return {Boolean} último mnodal?
     */
    onGuideInstagramLogin(step = 1) {
      this.stepModal = step

      const isMessengerConnected =
        this.simple_company_channels &&
        this.simple_company_channels.some(
          (company_channel) => company_channel.channel === 'messenger'
        )

      // si la app ya es dependiente este valor se falsea
      const isDependent = true

      this.otherFbIntegration = isMessengerConnected && isDependent
      if (this.otherFbIntegration) {
        switch (this.stepModal) {
          // Modal de confirmar los requisitos de Instagram
          case 1: {
            this.dataModalInfoList = {
              key: 'considerations',
              width: '650px',
              channel: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: 'Conectar mi canal de Instagram',
              okButtonType: 'primary',
              subtitle:
                'Necesitamos validar la siguiente información para conectar con éxito tu canal de Instagram Business',
              list: [
                {
                  title: '¿Tienes una cuenta comercial de Instagram?',
                  description:
                    'Necesitas una cuenta comercial de Instagram para continuar.',
                  checkbox: 'Tengo una cuenta comercial de Instagram.',
                  info: '¿No es una cuenta comercial? Sigue <a href="https://help.instagram.com/502981923235522?fbclid=IwAR1uXVY0-thX1fFZmEZxebofptZWbsEfswxfBtqn8pUmROQC7VDSiN_30AI" target="_blank" rel="noopener noreferrer" >estos pasos</a> para actualizar tu cuenta. Necesitarás una página de Facebook y acceso de administrador.',
                },
                {
                  title:
                    '¿Está tu cuenta comercial de Instagram vinculada con una página de Facebook?',
                  description:
                    'Para continuar, debes tener acceso de administrador y una página de Facebook vinculada a tu cuenta de Instagram. Esto solo se puede realizar desde la App móvil de Instagram.',
                  checkbox:
                    'Tengo mi cuenta  de Facebook vinculada a mi cuenta comercial de Instagram.',
                  info: '¿No tienes acceso? Ponte en contacto con tu admin de Facebook para proceder. ¿No estás seguro/a de cómo vincular una página de Facebook? <a href="https://help.instagram.com/399237934150902/?helpref=related" target="_blank" rel="noopener noreferrer">Descubre cómo, aquí.</a>',
                },
                {
                  title:
                    'Habilitar "Permitir acceso a los mensajes" en Instagram',
                  description:
                    'Asegúrate de tener activado "Permitir acceso a los mensajes" en tu configuración de Instagram. Solo puede hacerse desde la App móvil de Instagram.',
                  checkbox:
                    'Tengo habilitado "Permitir acceso a los mensajes".',
                  info: `<p>¿Cómo permitir el acceso a los mensajes en Instagram?</p>
              <p style="margin: 4px 0;">✅ Abre la App móvil de Instagram y accede a "Configuración".</p>
              <p style="margin: 4px 0;">✅ Accede a "Privacidad" y selecciona "Mensajes".</p>
              <p>✅ Activa "Permitir acceso a los mensajes" en "Herramientas conectadas".</p>
              <p style="margin: 4px 0;"><i>Configuración>Privacidad>Mensajes</i></p>
              <img style="max-width: 280px;" src="/img/items/switch-access-messges-instagram-min.png"/>
              `,
                },
              ],
            }
            break
          }
          // Modal de consejos de vinculación en la plataforma cuando hay messenger vinculado
          /*case 2: {
            this.dataModalInfoList = {
              key: 'doble_connection',
              channel: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: '¡Conectar Instagram!',
              okButtonType: 'primary',
              subtitle:
                '¡Hey! Te dejamos unos consejos para conectar con éxito tu canal de Instagram',
              list: [
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Instagram es la misma que tienes conectada en el canal de Messenger',
                  description:
                    'Solo necesitas darle continuar a todo el proceso de vinculación, ya que tu página de Facebook estará seleccionada porque conectaste la misma en canal de Messenger anteriormente.',
                },
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Instagram es diferente a la que tienes conectada en el canal de Messenger',
                  description:
                    'No quites el check de la página de Facebook que seleccionaste para conectar tu canal de Messenger, sino removerás esta página de ese canal.',
                },
                {
                  title:
                    'Si tu página de Instagram tiene otra cuenta administradora de Facebook',
                  description:
                    'Para el proceso de vinculación solo haz click en “Iniciar sesión en otra cuenta”, ingresa tus credenciales y selecciona la página que deseas vincular.',
                },
              ],
            }
            break
          }*/
          default: {
            return true
          }
        }
        return false
      } else {
        this.dataModalInfoList = {
          key: 'considerations',
          width: '650px',
          channel: 'instagram',
          title: 'Instagram para empresas',
          okButtonName: 'Conectar mi canal de Instagram',
          okButtonType: 'primary',
          subtitle:
            'Necesitamos validar la siguiente información para conectar con éxito tu canal de Instagram Business',
          list: [
            {
              title: '¿Tienes una cuenta comercial de Instagram?',
              description:
                'Necesitas una cuenta comercial de Instagram para continuar.',
              checkbox: 'Tengo una cuenta comercial de Instagram.',
              info: '¿No es una cuenta comercial? Sigue <a href="https://help.instagram.com/502981923235522?fbclid=IwAR1uXVY0-thX1fFZmEZxebofptZWbsEfswxfBtqn8pUmROQC7VDSiN_30AI" target="_blank" rel="noopener noreferrer">estos pasos</a> para actualizar tu cuenta. Necesitarás una página de Facebook y acceso de administrador.',
            },
            {
              title:
                '¿Está tu cuenta comercial de Instagram vinculada con una página de Facebook?',
              description:
                'Para continuar, debes tener acceso de administrador y una página de Facebook vinculada a tu cuenta de Instagram. Esto solo se puede realizar desde la App móvil de Instagram.',
              checkbox:
                'Tengo mi cuenta  de Facebook vinculada a mi cuenta comercial de Instagram.',
              info: '¿No tienes acceso? Ponte en contacto con tu admin de Facebook para proceder. ¿No estás seguro/a de cómo vincular una página de Facebook? <a href="https://help.instagram.com/399237934150902/?helpref=related" target="_blank" rel="noopener noreferrer">Descubre cómo, aquí.</a>',
            },
            {
              title: 'Habilitar "Permitir acceso a los mensajes" en Instagram',
              description:
                'Asegúrate de tener activado "Permitir acceso a los mensajes" en tu configuración de Instagram. Solo puede hacerse desde la App móvil de Instagram.',
              checkbox: 'Tengo habilitado "Permitir acceso a los mensajes".',
              info: `<p>¿Cómo permitir el acceso a los mensajes en Instagram?</p>
              <p style="margin: 4px 0;">✅ Abre la App móvil de Instagram y accede a "Configuración".</p>
              <p style="margin: 4px 0;">✅ Accede a "Privacidad" y selecciona "Mensajes".</p>
              <p>✅ Activa "Permitir acceso a los mensajes" en "Herramientas conectadas".</p>
              <p style="margin: 4px 0;"><i>Configuración>Privacidad>Mensajes</i></p>
              <img style="max-width: 280px;" src="/img/items/switch-access-messges-instagram-min.png"/>
              `,
            },
          ],
        }
        return true
      }
    },
    closeModalInfo() {
      this.dataModalInfoList = {}
    },
    /**
     * Evento ok del ModalInfo
     * @param {String} channel
     * @param {String} key
     */
    okModalInfo(channel, key) {
      this.dataModalInfoList = {}
      switch (channel) {
        case 'messenger':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            } else this.onFbLogin()
          }
          break
        case 'instagram':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            } else {
              if (!this.otherFbIntegration) {
                this.onConfirmInstagramLogin()
              } else {
                const lastModal = this.onGuideInstagramLogin(this.stepModal + 1)
                if (lastModal) this.onConfirmInstagramLogin()
              }
            }
          }
          break
      }
    },
    /**
     * Confirma el logeo a instagram
     */
    async onConfirmInstagramLogin() {
      this.disabledBtnConect = true
      this.loginType = 'instagram'
      try {
        const responseLogin = await this.igMetaLogin()
        const responseToken = await this.fbLongUserAccessToken({
          accessToken: responseLogin.authResponse.accessToken,
          channel: this.loginType,
        })
        const responseAccounts = await this.fbAccounts({
          userId: responseLogin.authResponse.userID,
          userAccessToken: responseToken.access_token,
        })
        if (responseAccounts.data.length) {
          this.listPages = responseAccounts.data
        } else {
          this.listPages = []
          this.$message.warning(
            'No existen los permisos suficientes para conectar tu página'
          )
        }
      } catch (error) {
        console.error(error)
        this.$message.warning(
          'No existen los permisos suficientes para conectar tu página'
        )
      }

      this.disabledBtnConect = false
    },
    /**
     * Suscribe la página a Facebook
     * @param {Object} page
     */
    async subscribePages(page) {
      await this.fbSubscribeApps({
        pageId: page.id,
        pageAccessToken: page.access_token,
      })
        .then(async () => {
          this.savePage(page)
          await this.getSimpleCompanyChannels()
          this.$router.push({ name: 'my-channels' }).catch(() => {})
        })
        .catch((error) => {
          console.error(error)
          this.$message.warning(
            'No existen los permisos suficientes para conectar tu página'
          )
        })
    },
    /**Guarda la página seleccionado en base
     * @param {Object} page
     * @param {String} page.channel
     * @param {String} page.access_token
     * @param {String} page.name
     * @param {Object} page.picture
     * @param {Object} page.picture.data
     * @param {String} page.picture.data.url
     */
    async savePage(page) {
      page.channel = this.loginType
      let pageId = page.id
      let igId = null
      if (page.channel == 'instagram') {
        const pageBusinessResponse = await this.getInstagramBusiness({
          pageId: page.id,
          pageAccessToken: page.access_token,
        })
        if (!pageBusinessResponse.instagram_business_account?.id) {
          this.$confirm({
            title: `No se pudo vincular tu página "${page.name}"`,
            icon: () => (
              <a-icon type="close-circle" style={{ color: '#F5222D' }} />
            ),
            content: `Asegúrate que tu página esté correctamente asociada a tu cuenta de Instagram Business seleccionada.`,
            okText: 'Aceptar',
          })
          return
        }
        igId = pageBusinessResponse.instagram_business_account.id
      }
      const params = {
        channel: page.channel,
        idFacebookPage: pageId,
        idBusinessIg: igId,
        pageAccessToken: page.access_token,
        name: page.name,
        picture: page.picture.data.url,
      }
      let response = null
      // para reconectar
      if (this.reconnect) {
        response = await this.reconnectFbChannel({
          channelCompanyId: this.channelId,
          ...params,
        })
        this.$emit('onSuccessReconnect', this.channelId)
      }
      // conexion normal
      else response = await this.saveFacebookPage(params)

      if (!this.reconnect && response.data?.result === 'credentials-saved') {
        this.$message.success(`Página "${page.name}" vinculada correctamente`)
      } else if (response.success && this.reconnect)
        this.$message.success(response?.details)
      else {
        this.$message.error(
          response.details ||
            `La pagina "${page.name}" ya se encuentra conectada.`
        )
      }
    },
    // cancela la pagina de seleccionar paginas
    handleCancelSelected() {
      this.modalSelectPage.visible = false
    },
  },
}
</script>

<style scoped></style>
