import marketModule from './marketModule'
import methodsModule from './methodsModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo de Market */
  resetMoMarket(context) {
    context.commit('RESET_MARKET_MODULE')
  },
}

const modules = {
  marketModule,
  methodsModule,
}

export default {
  modules,
  actions,
}
