<template>
  <a-row class="menu" type="flex" align="middle">
    <!-- transferencia -->
    <a-dropdown :trigger="['click']">
      <span class="menu__container-icon">
        <a-icon
          class="menu__icon"
          type="swap"
          @click="(e) => e.preventDefault()"
        />
      </span>
      <a-menu slot="overlay">
        <!-- Transferencia única a agente -->
        <a-menu-item
          v-if="showMenuAgents"
          class="menu-item"
          @click="$emit('onTransferToAgent')"
        >
          Transferir a un agente
        </a-menu-item>
        <!-- Transferencia única a Cola -->
        <a-menu-item class="menu-item" @click="$emit('onTransferToLine')">
          Transferir a una cola
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- cambiar de buzon -->
    <a-dropdown :trigger="['click']" placement="bottomRight">
      <span class="menu__container-icon">
        <a-icon class="menu__icon" type="inbox" />
      </span>
      <a-menu slot="overlay">
        <a-menu-item
          v-for="mailbox in dataSourceMailboxes"
          :key="`item-mailbox_${mailbox.idMailbox}`"
          class="menu-item"
          @click="$emit('onMoveMailbox', mailbox)"
        >
          Cambiar a {{ mailbox.title }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- Marcar como no leído -->
    <span
      class="menu__container-icon menu__container-icon--last"
      @click="$emit('onUnread')"
    >
      <i class="anticon menu__icon">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.385 2.998L2.38 1 1 2.44l.895.892a1.99 1.99 0 00-.89 1.659v13.95c0 1.1.895 1.992 1.998 1.992h16.543L21.619 23 23 21.56 4.4 3.013l.016-.015h-.031zM3.558 4.99h-.554v2.042l5.171 2.562L3.558 4.99zm8.974 8.948l-.037.02-9.491-4.702v9.683h14.543l-5.015-5zm8.454-6.41l-6.702 3.319 1.49 1.486 5.212-2.582v7.78l1.93 1.924a1.99 1.99 0 00.068-.516V4.991c0-1.1-.895-1.993-1.998-1.993H6.414L8.412 4.99h12.574v2.538z" fill="currentColor"/></svg>
      </i>
    </span>
  </a-row>
</template>

<script>
export default {
  name: 'MenuActionsTicket',
  props: {
    dataSourceMailboxes: {
      type: Array,
      required: true,
    },
    showMenuAgents: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>

.menu__container-icon
  height: 100%
  display: flex
  align-items: center
  padding: 10px 20px
.menu__icon
  font-size: 24px
  color: $gray_9
.menu-item
  padding: 12px
</style>
