<template>
  <section-custom-fields
    module="products"
    :listCustomFields="fields_company_products"
    :border="false"
    :description="description"
    :pagination="pagination"
    size-table="middle"
    :scroll="scroll"
    class="section"
  >
    <header-module
      slot="title"
      title="Campos personalizados"
      text-before-link="Crea datos customizados a tus productos para que cuentes con más detalles de ellos."
      text-after-link="si deseas conocer más."
      :link="`${vari.WEB_DOC}productos-ventas/campos-personalizados-para-productos/`"
    />
  </section-custom-fields>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import SectionCustomFields from '@/app/settings/components/organisms/workspaceMenu/SectionCustomFields'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionFields',
  components: {
    SectionCustomFields,
    HeaderModule,
  },
  data: () => ({
    vari,
    description:
      'Crea campos personalizados para tus productos. Administra los colores, tamaños y todas las propiedades específicas desde aquí.',
    page: 1,
  }),
  watch: {
    /**
     * Observa si la paginación de campos personalizados cambia
     */
    paginate_fields_products: {
      handler(val) {
        if (val) this.page = val.page
      },
    },
  },
  computed: {
    ...mapGetters(['fields_company_products', 'paginate_fields_products']),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        pageSize: 12,
        size: 'default',
      }
    },
    /**
     * @returns {Object} - propiedades para el scroll de la tabla
     */
    scroll() {
      return {
        y: 'calc(90vh - 14em)',
      }
    },
  },
  methods: {
    ...mapActions(['getFieldsProducts']),

    /**
     * Setear paginacion de los productos
     * @param {Number} page
     */
    async handleSetPaginate(page) {
      this.page = page
      await this.getFieldsProducts({ page })
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  padding: 20px 24px
  height: 100%
</style>
