<template>
  <div class="section">
    <div>
      <template v-if="state === 'load'">
        <a-icon type="sync" spin style="font-size: 40px; color: #bdbfc1" />
        <h4 class="heading-h4 title">Autenticando token...</h4>
      </template>
      <template v-else-if="state === 'fail'">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'section__icon mrg-bottom-12'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-4.702c1.32 0 2.53.642 3.275 1.702l1.636-1.15A5.993 5.993 0 0012 14.297a5.993 5.993 0 00-4.91 2.551L8.724 18A3.993 3.993 0 0112 16.298zM14 12v-2h2v2h-2zm-6-2v2h2v-2H8z" fill="currentColor"/></svg>
        <h5 class="heading-h5">Token inválido</h5>
        <p class="body-2 color-gray-dark-800">
          Asegura haber copiado correctamente el link en tu navegador.
        </p>
      </template>
      <template v-else-if="state === 'expired'">
        <a-alert
          v-if="showAlert.visible"
          :message="showAlert.message"
          :type="showAlert.type"
          show-icon
          style="margin-bottom: 24px"
        />
        <h5 class="heading-h5">Tu token ha vencido</h5>
        <p class="body-2">
          Habíamos enviado un correo de verificación a
          <span style="color: #262a31">{{ email }}</span
          >, pero ya pasaron más de 24hrs y el token ha expirado.
        </p>
        <p class="body-2" style="margin-bottom: 0">
          Para reenviar el correo de verificación, haz click en el siguiente
          botón
        </p>
        <a-button
          :disabled="isDisabled"
          @click="requestResend"
          type="primary"
          style="margin-top: 32px; width: 90%"
          :size="buttons_size"
          >Reenviar token</a-button
        >
      </template>
      <template v-else-if="state === 'verified'">
        <h5 class="heading-h5">Tu cuenta ya ha sido verificada</h5>
        <p class="body-2 color-gray-dark-800">
          Actualmente tu cuenta ya se encuentra activa. Haz click en el botón de
          abajo para iniciar sesión.
        </p>
        <a-button
          type="primary"
          style="width: 90%; margin-top: 32px"
          @click="goToLogin"
          :size="buttons_size"
          >Iniciar sesión</a-button
        >
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TokenStatusSection',
  props: {
    state: {
      type: String,
      default: 'load', // load, fail, expired, verified
      required: false,
    },
    onRequest: {
      type: Function,
      required: false,
    },
    email: { type: String },
  },
  data: () => ({
    isDisabled: false,
    showAlert: {
      visible: false,
    },
    // success: false,
  }),
  computed: {
    ...mapGetters(['buttons_size']),
  },
  methods: {
    async requestResend() {
      const response = await this.onRequest(this.$route.query.token)
      if (response.success) {
        this.showAlert = {
          visible: true,
          type: 'info',
          message:
            'Se ha enviado el correo de verificación. Por favor, revisa tu bandeja de entrada, incluyendo la carpeta de Spam.',
        }
        this.isDisabled = true
      } else {
        this.showAlert = {
          visible: true,
          type: 'info',
          message: 'Ocurrió un error mientras se trataba de enviar el correo',
        }
        this.isDisabled = false
      }
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  display: flex
  justify-content: center
  align-items: center
  max-width: 34em
  &__icon
    font-size: 74px
    color: $yellow_800
    @include xs
      font-size: 40px
    @include sm
      font-size: 40px
    @include md
      font-size: 80px
</style>
x
