/* eslint-disable require-atomic-updates */
import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'

const actions = {
  /**
   * Obtener colas asignadas según el rol del usuario
   * admin - obtiene todas la colas de la empresa
   * admin agent - obtiene todas las colas asignadas
   * agent - obtiene todas las colas asignadas
   * @param {*} context
   */
  async getAssignedLines(context) {
    try {
      const response = await axios.get(
        `${vari.UHR}/admin/line/list?simply=true`
      )
      const lines = response.data.data
      context.commit('SET_ASSIGNED_LINES', lines)
    } catch (error) {
      console.error('[lineModule][getAssignedLines]', error)
    }
  },
  /**
   * Obtiene todas las colas de la empresa sin importar el rol
   * Devuelve las 100 primeras colas
   * @param {*} context
   */
  async listCompanyLines(context) {
    try {
      const response = await axios.get(`${vari.UHR}/admin/line/company/list`)
      const companyLines = response.data.data
      context.commit('SET_COMPANY_LINES', companyLines)
    } catch (error) {
      console.error('[lineModule][listCompanyLines]', error)
    }
  },
  /**
   * Obtener datos de una cola
   * @param {*}       context
   * @param {String}  lineId
   * @returns {Promise<Line>}
   */
  async getLine(context, lineId) {
    if (!(await compare.isAllowedFor(context, ['admin', 'supervisor']))) return
    try {
      const response = await axios.get(`${vari.UHR}/supervisor/line/${lineId}`)
      return response.data
    } catch (error) {
      console.error('[lineModule][getLine]', error)
    }
  },
  /**
   * Obtener colas directamente a un componente
   * @param {vuex} context
   * @param {Component} self componente.vue
   * @param {Object} self.sectionLine objeto en data
   * @param {Object} self.sectionLine.list objeto para filtrar, buscar, loading, etc
   * @param {String} self.sectionLine.list.search texto para busqueda y filtro de las colas
   * @param {Number} self.sectionLine.list.page Página de la lista de las colas
   * @param {Number} self.sectionLine.list.total Cantidad total de colas encontradas
   * @param {Object[]} self.sectionLine.list.data Lista de datos de las colas
   * @param {Object} self.sectionLine.list.loading
   * @param {Boolean} self.sectionLine.list.loading.table Loading de la tabña
   * @returns {Promise<void>}
   */
  async getLines(context, { self }) {
    self.sectionLine.list.data = []
    self.sectionLine.list.loading.table = true
    const url = `${vari.UHR}/admin/line/list`
    await axios
      .get(url, { params: self.sectionLine.list })
      .then((r) => {
        if (r.status === 200) {
          self.sectionLine.list.loading.table = false
          self.sectionLine.list.data = r.data.data
          self.sectionLine.list.total = r.data.total
          context.dispatch('getAssignedLines')
        }
      })
      .catch((e) => {
        self.sectionLine.list.loading.table = false
        console.error('[lineModule][getLines]', e)
      })
  },
  /**
   * Crear cola
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.createoredit: objeto para crear o actualizar
   * @returns {Promise<void>}
   */
  async createLine(context, { self }) {
    const url = `${vari.UHR}/admin/line/create`
    await axios
      .post(url, self.sectionLine.createoredit)
      .then((r) => {
        //Obtener y enviar ID cola creada
        self.sectionLine.createoredit.lineId = r.data.lineInserted._id

        context.commit('ADD_LINE', r.data.lineInserted)
        context.commit('ADD_COMPANY_LINE', r.data.lineInserted)
        // Cuando se crea la cola y se asigno al usuario que lo creo
        const idProfile = context.getters.profile._id
        if (self.sectionLine.createoredit.agentsId.includes(idProfile)) {
          context.commit('SET_LINE_IN_PROFILE', {
            lineId: r.data.lineInserted._id,
            asigned: true,
          })
        }

        //Emitir evento socket.io
        const line = r.data.lineInserted

        //Asignar agente
        const url = `${vari.UHR}/admin/line/agent/asign`
        axios
          .put(url, self.sectionLine.createoredit)
          .then((r) => {
            if (r.status === 200) {
              //Asignar supervisor
              const url = `${vari.UHR}/admin/line/supervisor/asign`
              axios
                .put(url, self.sectionLine.createoredit)
                .then((r) => {
                  if (r.status === 200) {
                    //Emitir evento socket.io
                    this._vm.$socket.emit('server:company:new:line', line)

                    //Obtener colas
                    context.dispatch('getLines', { self: self })

                    //Mostrar notificación
                    self.$message.success('Cola creada con éxito')
                    //Mostrar notificación para los agentes Asignados
                    const activity = {
                      type: 'assign-to-line',
                      line: self.name,
                      users: self.agentsAsigned.map((agent) => agent.userId),
                    }
                    context.dispatch('addNotification', activity)

                    self.$emit('handleOk')
                  }
                })
                .catch((e) => {
                  self.sectionLine.modal.loading.button = false
                  console.error('[lineModule][createLine]', e)
                })
            }
          })
          .catch((e) => {
            self.sectionLine.modal.loading.button = false
            console.error('[lineModule][createLine]', e)
          })
      })
      .catch((e) => {
        self.sectionLine.modal.loading.button = false
        const response = e.response.data
        self.$message.error(
          response.details ? response.details : 'Ocurrió un error 😥'
        )
        console.error('[lineModule][createLine]', e)
      })
  },
  /**
   * Obtener agentes
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.list: objeto para filtrar, buscar, loading, etc
   * @returns {Promise<void>}
   */
  async getAgents(context, { self }) {
    self.sectionLine.list.data = []
    self.sectionLine.list.loading.table = true
    const url = `${vari.UHR}/admin/line/list`
    await axios
      .get(url, { params: self.paramsLines })
      .then((r) => {
        if (r.status === 200) {
          self.sectionLine.list.loading.table = false
          self.sectionLine.list.data = r.data
        }
      })
      .catch((e) => {
        self.sectionLine.list.loading.table = false
        console.error('[lineModule][getLines]', e)
      })
  },

  /**
   * Actualizar cola general welcome
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.createoredit: objeto para crear o actualizar
   * @returns {Promise<void>}
   */
  async updateLineWelcome(context, { self }) {
    const nameModule = 'lines'
    const permissionsModule = context.getters.permissions[nameModule]

    if (!permissionsModule.update) return
    try {
      // Mensaje automático
      await axios.put(
        `${vari.UHR}/admin/line/welcome`,
        self.sectionLine.createoredit
      )
      this._vm.$socket.emit('server:line:settings', {
        idArea: self.sectionLine.createoredit._id,
        settings: { welcome: self.sectionLine.createoredit.welcome },
      })
      const payload = {
        lineId: self.sectionLine.createoredit.lineId,
        agents: self.sectionLine.createoredit.agentsId,
        agentsUnasigned: self.usersDesassigned.filter((u) =>
          compare.isAllowedFor({ getters: { profile: { type: u.type } } }, [
            'agent',
          ])
        ),
        supervisors: self.sectionLine.createoredit.supervisorsId,
        supervisorsUnasigned: self.usersDesassigned
          .filter((u) =>
            compare.isAllowedFor({ getters: { profile: { type: u.type } } }, [
              'supervisor',
            ])
          )
          .map((u) => u._id),
      }

      await axios.put(`${vari.UHR}/admin/line`, payload)
      /**Crear notificacion para agentes si fueron asignados */
      const agentsAssigned = self.usersAssigned.agents
      if (agentsAssigned.length > 0) {
        const activity = {
          type: 'assign-to-line',
          line: self.sectionLine.createoredit.name,
          users: agentsAssigned.map((agent) => agent.userId),
        }
        context.dispatch('addNotification', activity)
      }
      /**Crear notificacion para agentes si fueron desasignados */
      const agentsDeassigned = self.usersDesassigned.filter(
        (user) => user.type === 'agent' || user.type === 'admin_agent'
      )
      if (agentsDeassigned.length > 0) {
        const activity = {
          type: 'deassign-to-line',
          line: self.sectionLine.createoredit.name,
          users: agentsDeassigned.map((agent) => agent.userId),
        }
        context.dispatch('addNotification', activity)
      }

      //Obtener colas
      context.dispatch('getLines', { self: self })

      //Mostrar notificación
      self.$message.success('Cola actualizada con éxito')

      self.disabledBtnCreate = false
      self.loadingBotonCreate = false
      self.textBtn = 'Actualizar'
      self.$emit('okModalEdit')

      // Actualiza los datos del perfil (incluido sus colas asignadas)
      if (
        self.sectionLine.createoredit.agentsId.some(
          (userId) => userId === context.getters.profile._id
        ) ||
        payload.supervisorsUnasigned.some(
          (userId) => userId === context.getters.profile._id
        )
      ) {
        await context.dispatch('getProfile')
      }
    } catch (e) {
      console.error('[lineModule][updateLineWelcome]', e)
      //Mostrar notificacion de error
      self.$message.error(e.response.data.details)

      self.disabledBtnCreate = false
      self.loadingBotonCreate = false
      self.textBtn = 'Actualizar'
    }
  },
  /**
   * Actualizar cola
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.createoredit: objeto para crear o actualizar
   * @returns {Promise<void>}
   */
  async updateLine(context, { self }) {
    try {
      const nameModule = 'lines'
      const permissionsModule = context.getters.permissions[nameModule]

      if (!permissionsModule.update) return

      let response = null
      if (compare.isAllowedFor(context, ['admin', 'master_agent'])) {
        // Actualizar nombre Cola
        const payload = {
          name: self.sectionLine.createoredit.name,
          lineId: self.sectionLine.createoredit.lineId,
          channels: self.channelsSelected,
          channelsUnasigned: self.channelsDesasigned,
          agents: self.sectionLine.createoredit.agentsId,
          agentsUnasigned: self.usersDesassigned.filter((u) =>
            compare.isAllowedFor({ getters: { profile: { type: u.type } } }, [
              'agent',
            ])
          ),
          supervisors: self.sectionLine.createoredit.supervisorsId,
          supervisorsUnasigned: self.usersDesassigned
            .filter((u) =>
              compare.isAllowedFor({ getters: { profile: { type: u.type } } }, [
                'supervisor',
              ])
            )
            .map((u) => u._id),
        }
        response = await axios.put(`${vari.UHR}/admin/line`, payload)

        // Actualiza los datos del perfil (incluido sus colas asignadas)
        if (
          self.sectionLine.createoredit.agentsId.some(
            (userId) => userId === context.getters.profile._id
          ) ||
          payload.supervisorsUnasigned.some(
            (userId) => userId === context.getters.profile._id
          )
        ) {
          await context.dispatch('getProfile')
        }
      } else {
        const payload = {
          name: self.sectionLine.createoredit.name,
          lineId: self.sectionLine.createoredit.lineId,
          channels: self.channelsSelected,
          channelsUnasigned: self.channelsDesasigned,
          agents: self.sectionLine.createoredit.agentsId,
          agentsUnasigned: self.usersDesassigned.filter((u) =>
            compare.isAllowedFor({ getters: { profile: { type: u.type } } }, [
              'agent',
            ])
          ),
        }

        response = await axios.put(`${vari.UHR}/admin/line`, payload)
      }
      /**Crear notificacion para agentes si fueron asignados */
      const agentsAssigned = self.usersAssigned.agents
      if (agentsAssigned.length > 0) {
        const activity = {
          type: 'assign-to-line',
          line: self.sectionLine.createoredit.name,
          users: agentsAssigned.map((agent) => agent.userId),
        }
        context.dispatch('addNotification', activity)
      }
      /**Crear notificacion para agentes si fueron desasignados */
      const agentsDeassigned = self.usersDesassigned.filter(
        (user) => user.type === 'agent' || user.type === 'admin_agent'
      )
      if (agentsDeassigned.length > 0) {
        const activity = {
          type: 'deassign-to-line',
          line: self.sectionLine.createoredit.name,
          users: agentsDeassigned.map((agent) => agent.userId),
        }
        context.dispatch('addNotification', activity)
      }
      self.disabledBtnCreate = false
      self.loadingBotonCreate = false
      self.usersDesassigned = []
      self.textBtn = 'Actualizar'

      // si ocurrio un error
      if (response.data && !response.data.success) {
        self.error = {
          visible: true,
          message: response.data.details || 'Ocurrió un error',
        }
        return
      }
      // mostrar mensaje de exitoso
      self.$emit('okModalEdit')
      //Mostrar notificación
      self.$message.success('Cola actualizada con éxito')
      //Obtener colas
      context.dispatch('getLines', { self: self })
    } catch (e) {
      self.sectionLine.modal.loading.button = false
      console.error('[lineModule][createLine]', e)
    }
  },
  /**
   * Remover agente de la cola
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.createoredit: objeto para crear o actualizar
   * @param {String} userId - el userId del agente
   * @returns {Promise<void>}
   */
  async removeAgent(context, { self, userId }) {
    const url = `${vari.UHR}/admin/line/agent/desasign`
    await axios
      .put(url, self.sectionLine.createoredit)
      .then((r) => {
        if (r.status === 200) {
          //Mostrar mensaje de success
          self.$message.success('Agente retirado correctamente')
          /**Crear notificacion para agentes si fueron desasignados */
          const activity = {
            type: 'deassign-to-line',
            line: self.objLine.name,
            users: [userId],
          }
          context.dispatch('addNotification', activity)
          if (self.dataAgents.length < 1) {
            //Cerrar modal
            self.visible = false
            self.destroyAll()
          }
          //Obtener colas
          context.dispatch('getLines', { self: self })
        }
      })
      .catch((e) => {
        self.sectionLine.modal.loading.button = false
        console.error('[lineModule][removeAgent]', e)
      })
  },
  /**
   * Remover supervisor de la cola
   * @param context
   * @param self componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.createoredit: objeto para crear y actualizar
   * @returns {Promise<void>}
   */
  async removeSupervisor(context, { self }) {
    const url = `${vari.UHR}/admin/line/supervisor/desasign`
    await axios
      .put(url, self.sectionLine.createoredit)
      .then((r) => {
        if (r.status === 200) {
          //Mostrar notificación
          self.$message.success('Supervisor retirado correctamente')
          if (self.dataAgents.length < 1) {
            //Cerrar modal
            self.visibleSupervisor = false
            self.destroyAll()
          }
        }
      })
      .catch((e) => {
        self.sectionLine.modal.loading.button = false
        console.error('[lineModule][removeSupervisor]', e)
      })
  },
  /**
   * Obtener todas las cuentas de usuario agentes y supervisores
   * @param context
   * @returns {Promise<void>}
   */
  async getAccountsUsers(context) {
    const url = `${vari.UHR}/admin/accounts/simply`
    await axios
      .get(url)
      .then((r) => {
        if (r.status === 200) {
          context.commit('SET_ACCOUNT_AGENTS', r.data.agents)
          context.commit('SET_ACCOUNT_SUPERVISORS', r.data.supervisors)
        }
      })
      .catch((e) => {
        console.error('[lineModule][getAccountsUsers]', e)
      })
  },
  async changeActiveLine(context, { idLine, active, action }) {
    try {
      const response = await axios.patch(`${vari.UHR}/admin/line/${idLine}`, {
        active,
        action,
      })
      context.dispatch('listCompanyLines')
      return response.data
    } catch (err) {
      console.error(err)
      return err.response.data
    }
  },

  async deleteLine(context, { idLine, active, action }) {
    try {
      const response = await axios.delete(`${vari.UHR}/admin/line/${idLine}`, {
        data: {
          active,
          action,
        },
      })

      context.commit('SET_LINE_IN_PROFILE', {
        lineId: idLine,
        asigned: false,
      })
      context.dispatch('listCompanyLines')

      return response.data
    } catch (err) {
      console.error(err)
      return err.response.data
    }
  },
}

export default actions
