<template>
  <section class="section">
    <h5 class="heading-h5 section__title">Permisos</h5>
    <custom-section-scroll
      :master-styles="{ height: 'calc( 100% - 50px )' }"
      :content-styles="{ padding: '0px 40px' }"
    >
      <!--Modo agente para master-->
      <card-switch
        class="section__card"
        title="Mi modo agente"
        :active="master_settings.agentMode"
        :loading="loadingMode"
        @onChangeActive="handleChangeModeAgent"
        image-src="outlined/support-3.svg"
      >
        <template slot="description">
          Elige si quieres que tu cuenta en
          <strong>{{ company.name }}</strong> presente funcionalidades de
          agente. Si está activada, tendrás acceso total al Workspace de
          {{ app }}
          para conversar con tus clientes y agendar recordatorios. Esta opción
          solo afectará a tu cuenta personal en esta única empresa.
        </template>
      </card-switch>
      <card-default-company class="section__card" />
      <card-transfer-owner
        v-if="profile && profile.owner"
        class="section__card"
      />
    </custom-section-scroll>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import CardSwitch from '@/app/shared/components/cards/CardSwitch'
import CardDefaultCompany from '@/app/profile/components/molecules/CardDefaultCompany'
import CardTransferOwner from '@/app/profile/components/molecules/CardTransferOwner'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SectionMenuGuide',
  components: {
    CustomSectionScroll,
    CardSwitch,
    CardDefaultCompany,
    CardTransferOwner,
  },
  mixins: [attemptMixin],
  data: () => ({
    app: process.env.VUE_APP_NAME,
    loadingMode: false,
  }),
  created() {
    this.intervalAttempt(async () => {
      if (!this.company) throw 'not company'
      this.loadInitialData()
    })
  },
  computed: {
    ...mapGetters(['profile', 'company', 'master_settings']),
  },
  methods: {
    ...mapActions(['getMasters', 'getSettingsMaster', 'updateSettingsMaster']),
    ...mapMutations(['SET_RELOAD_NOTIFICATION']),

    /**
     * Carga la data inicial
     */
    async loadInitialData() {
      await this.getSettingsMaster()
      await this.getMasters()
    },
    /**
     * Escucha el cambio de modo agente
     * @param {Boolean} agentMode
     */
    async handleChangeModeAgent(agentMode) {
      const self = this
      if (!agentMode) {
        this.$confirm({
          title: '¿Estás segur@ de inactivar tu modo agente?',
          content:
            'Recuerda que todos tus tickets abiertos serán transferidos a otro agente de la misma cola.',
          okText: 'Inactivar',
          cancelText: 'Cancelar',
          centered: true,
          async onOk() {
            await self.handleUpdateMasterAgentMode(agentMode)
          },
        })
      } else {
        await self.handleUpdateMasterAgentMode(agentMode)
      }
    },
    /**
     * Actualiza el modo agente del master
     * @param {Boolean} agentMode
     */
    async handleUpdateMasterAgentMode(agentMode) {
      this.loadingMode = true
      const response = await this.updateSettingsMaster({ agentMode })
      this.loadingMode = false
      if (response.success) {
        this.$message.success(response.details || 'Se actualizó exitosamente')

        const title = agentMode
          ? 'Se ha activado tu modo agente'
          : 'Se ha inactivado tu modo agente'

        const description = agentMode
          ? 'Usted ahora podrá atender tickets en sus colas asignadas con la mismas funciones de agente.'
          : 'Usted ya no podrá atender tickets en sus colas asignadas.'

        this.SET_RELOAD_NOTIFICATION({
          visible: true,
          title,
          description,
        })
      } else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  height: 100%
  background-color: $white_000
  border-radius: 4px
  text-align: left
  padding-top: 40px
  color: $gray_dark_900
  border: 1px solid $gray_dark_000
  &__title
    margin-left: 40px
  &__description
  &__card
    margin-bottom: 20px
</style>
