<template>
  <div class="personal-form">
    <a-form layout="vertical" :form="form" @submit="handleSubmit" class="form">
      <a-form-item
        label="Nombre"
        class="section-form-item"
        style="padding-bottom: 0px"
        hasFeedback
      >
        <a-input
          v-decorator="[
            'names',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :size="inputs_size"
        />
      </a-form-item>
      <a-form-item
        label="Apellidos"
        class="section-form-item"
        style="padding-bottom: 0px"
        hasFeedback
      >
        <a-input
          v-decorator="[
            'surnames',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :size="inputs_size"
        />
      </a-form-item>
      <a-form-item
        label="Correo Corporativo"
        class="section-form-item"
        style="padding-bottom: 0px"
        hasFeedback
      >
        <a-input
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa tu correo electrónico.',
                },
                {
                  type: 'email',
                  message: 'Ingresa un correo válido.',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios en blanco.',
                },
                {
                  min: 6,
                  message: '6 caracteres como mínimo.',
                },
                {
                  max: 50,
                  message: '50 caracteres como máximo.',
                },
                {
                  pattern:
                    '^(?!.*?(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*',
                  message: 'Sólo se aceptan correos corporativos',
                },
                { validator: compareMails },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :size="inputs_size"
        />
      </a-form-item>
      <a-form-item label="Teléfono de contacto">
        <a-row :gutter="8" type="flex">
          <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
          <a-col :span="14" :order="2">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                    {
                      pattern: /^([0-9])*$/g,
                      message: 'Solo puedes ingresar números.',
                    },
                  ],
                },
              ]"
              :maxLenght="20"
              placeholder="Escribe aquí..."
              :size="inputs_size"
            />
          </a-col>
          <a-col :span="10" :order="1">
            <a-select
              show-search
              v-decorator="[
                'code',
                {
                  initialValue: defaultValue,
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                  ],
                },
              ]"
              placeholder="País"
              option-filter-prop="children"
              :size="inputs_size"
            >
              <a-select-option
                v-for="country in countries"
                :key="country._id"
                :title="country.nameSpanish"
              >
                {{ country.dialCode }}
                {{ normalizeStr(country.nameSpanish) }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-item>
      <a-button
        html-type="submit"
        type="primary"
        class="personal-form-btn personal-form-btn--first"
        :loading="loading"
        :disabled="disabledOk"
        :size="buttons_size"
        >Continuar</a-button
      >
      <a-divider class="personal-form__divider"
        ><span class="body-2 mrg-bottom-0">o</span>
      </a-divider>
      <!-- Registro con Google. TODO: Oculto hasta resolver problemas con Google -->
      <!-- <a-button class="personal-form-btn" @click="googleSignUp">
        <span style="margin-right: 8px">
          <img src="img/icon_login/google.png" />
        </span>
        Continua con Google
      </a-button> -->
      <!-- Registro con Facebook: Oculto hasta realizar una proxima integración con facebook -->
      <a-button
        v-if="enable_register_facebook"
        class="personal-form-btn"
        @click="facebookSignUp"
      >
        <span style="margin-right: 8px">
          <img src="img/icon_login/facebook.png" />
        </span>
        Continua con Facebook</a-button
      >
    </a-form>
  </div>
</template>

<script>
// import FormItemPhone from '@/app/shared/components/molecules/FormItemPhone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PersonalForm',
  props: {
    values: {
      type: Object,
      required: false,
    },
  },
  components: {
    // FormItemPhone,
  },
  data: () => ({
    // codes: ['+51', '+52', '+1290'],
    isChanges: false,
    codeNumber: '', // codigo de numero
    mails: [], // correos que ya fueron usados
    disabledOk: false,
    loading: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-signup-personal' })
  },
  created() {
    if (this.values) {
      this.setValues(this.values)
    }
  },
  watch: {
    values(value) {
      if (value) {
        this.setValues(value)
      }
    },
  },
  computed: {
    ...mapGetters([
      'countries',
      'buttons_size',
      'inputs_size',
      'enable_register_facebook',
    ]),
    /**
     * Valor por defecto
     */
    defaultValue() {
      const defaultCode = 'PE'
      if (!this.countries.length) return '+51'
      const country = this.countries.find(
        (country) => country.code === defaultCode
      )
      return country._id
    },
    // filteredOptions() {
    //   return OPTIONS.filter((o) => !this.selectedItems.includes(o))
    // },
  },
  methods: {
    ...mapActions(['getExistCompany']),

    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true // carga el loading del boton del formulario
          let isSocial = false
          this.isChanges = false
          this.$emit('isChanges', this.isChanges)
          // comprueba si el mail ya existe
          const response = await this.getExistCompany({ user: values.email })
          this.loading = false // deja de carga el loading del boton del formulario
          // si la respuesta es duplicated significa que ya existe y esta activo
          if (response.success && response.result === 'duplicated') {
            // si el campo que se esta duplicando es el mail
            if (response.fields.email)
              this.$message.error('Este correo ya existe')
            this.mails.push(values.email)
            // se opto por poner un set time out, porque cuando
            // se ejecuta la primera validacion es correcto pero cuando llega a la segunda ya no la toma en cuenta
            setTimeout(() => {
              this.form.validateFields(['email'], { force: true })
            }, 500)
          }
          // si la respuesta es found significa que ya existe pero que esta inactivo
          else if (!response.success && response.result === 'found') {
            this.disabledOk = true

            this.$message.info(
              'Tu cuenta está inactiva, se ha vuelto a reenviar el correo de verificación'
            )
            this.mails.push(values.email)

            // se opto por poner un set time out, porque cuando
            // se ejecuta la primera validacion es correcto pero cuando llega a la segunda ya no la toma en cuenta
            setTimeout(() => {
              this.form.validateFields(['email'], { force: true })
            }, 500)
          } else {
            isSocial = false
            /**Si la respuesta fue exitosa */
            this.$emit('onNext')
          }
          this.$emit('onSetValues', { form: values, isSocial: isSocial })
        }
      })
    },
    onInput() {
      this.isChanges = true
      this.$emit('isChanges', this.isChanges)
    },
    handleChange() {
      this.isChanges = true
      this.$emit('isChanges', this.isChanges)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    setValues(value) {
      setTimeout(() => {
        this.form.setFieldsValue({
          names: value.names,
          surnames: value.surnames,
          email: value.email,
          code: value.code,
          phone: value.phone,
        })
      }, 100)
    },
    googleSignUp() {
      this.$emit('googleSignUp')
    },
    /**
     * Registro con facebook
     */
    facebookSignUp() {
      if (this.enable_register_facebook) this.$emit('facebookSignUp')
    },
    normalizeStr(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    /**
     * Validador para verificar si el correo ya fue usado antes
     * @param {String} value - valor del campo
     * @param {Function} callback - función usada para retornas errores o success
     */
    compareMails(rule, value, callback) {
      if (this.mails.includes(value)) {
        callback(new Error('Este correo ya existe'))
        this.disabledOk = true
      } else {
        this.disabledOk = false
        callback()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.personal-form
  width: 100%
  .form
    width: 100%
    .phone-number
      display: flex
      flex-direction: row-reverse
  &__divider
    margin: 16px auto 4px !important
    display: flex !important
    align-items: center
    height: auto !important
.personal-form-btn
  width: 100%
  margin-bottom: 8px
  &--first
    margin-top: 4px
</style>
