<template>
  <div class="section-summary-calendar">
    <div class="header">
      <p class="title heading-h6">Resumen de agendas</p>
      <div>
        <a-tooltip placement="bottomRight" :arrowPointAtCenter="true">
          <template slot="title">
            Visualiza la cantidad de agendas con sus estados. También puedes
            filtrar por agente en específico. Se mostrarán según el rango de
            fecha y hora que decidas en el filtro.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!--POPOVER DE CONFIRMACION-->
        <a-popconfirm
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template v-if="!isLoading" slot="title">
              Descargar en excel
            </template>
            <a-icon
              type="download"
              class="section__icon--download"
              :class="{
                'btn-dowload': !isLoading,
                'icon--disabled': isLoading,
              }"
            />
          </a-tooltip>
        </a-popconfirm>
      </div>
    </div>
    <div class="body">
      <div class="contain-select">
        <a-select
          :value="agentId"
          size="small"
          style="min-width: 140px"
          @change="handleAgent"
        >
          <a-select-option
            v-for="agent in arrAgents"
            :key="agent.userId"
            :title="`${agent.names} ${agent.surnames ? agent.surnames : ''}`"
            >{{ agent.names }} {{ agent.surnames }}</a-select-option
          >
        </a-select>
        <p class="body-2" style="margin-bottom: 0">
          Total de agendas: {{ total }}
        </p>
      </div>
      <PolarChart
        v-if="stateAgends && stateAgends.length > 0"
        :styles="myStyles"
        :chartdata="chartdata"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PolarChart from '@/app/dashboard/components/organisms/charts/PolarChart.vue'
import moment from 'moment'

export default {
  name: 'SectionSummaryCalendar',
  components: {
    PolarChart,
  },
  props: {
    lineId: { type: String, default: 'allLines' },
    date: { type: Array, required: true },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '300px',
      margin: '12px 30px 0',
      position: 'relative',
    },
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
    agentId: 'allAgents',
  }),
  watch: {
    lineId() {
      this.agentId = 'allAgents'
    },
  },
  computed: {
    ...mapGetters(['agentsAccounts', 'stateAgends']),
    arrAgents() {
      if (!this.agentsAccounts) return []
      let arrAgents = []
      const allAgent = {
        userId: 'allAgents',
        names: 'Todos los agentes',
        default: true,
      }
      if (this.lineId === 'allLines') {
        arrAgents = this.agentsAccounts.slice()
      } else {
        const filtered = this.agentsAccounts.filter((agent) =>
          agent.company.linesId.includes(this.lineId)
        )
        arrAgents = filtered
      }
      arrAgents.unshift(allAgent)
      return arrAgents
    },
    chartdata() {
      const data = {
        labels: this.stateAgends.map((item) => item.state),
        datasets: [
          {
            data: this.stateAgends.map((item) => item.quantity),
            backgroundColor: [
              'rgba(47, 194, 91, 0.5)',
              'rgba(255, 232, 24, 0.5)',
              'rgba(255, 58, 58, 0.5)',
            ],
            borderWidth: 0.5,
            borderColor: [
              'rgba(47, 194, 91, 0.5)',
              'rgba(255, 232, 24, 0.5)',
              'rgba(255, 58, 58, 0.5)',
            ],
          },
        ],
      }
      return data
    },
    total() {
      return this.stateAgends.reduce((acc, item) => acc + item.quantity, 0)
    },
  },
  methods: {
    ...mapActions(['exportSchedules', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'status_calendar' },
      })
    },
    //se ejecuta cada vez q se cambia el select de agentes
    handleAgent(value) {
      this.agentId = value
      this.$emit('handleAgentSchedule', value)
    },
    async exportDashboard() {
      const startDate = new Date(
        moment(this.date[0]).format('YYYY-MM-DD HH:mm')
      ).toISOString()
      // Las agendas deben ser del día
      const endDate = new Date(
        `${moment(this.date[1]).format('YYYY-MM-DD')} 23:59:59`
      ).toISOString()
      const date = `${startDate}/${endDate}`
      const lineId = this.lineId
      const agentId = this.agentId
      await this.exportSchedules({ date, lineId, agentId })
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-summary-calendar
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
  .body
    color: $gray_dark_900
    .contain-select
      margin: 20px 20px 0
      display: flex
      justify-content: space-between
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.section__icon--download
  font-size: 20px
  color: #595959
  margin-right: 8px
</style>
