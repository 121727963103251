<template>
  <a-modal
    :visible="visible"
    title="Enviar mensajes de reconexión"
    centered
    @cancel="handleClose"
    :footer="null"
    :bodyStyle="bodyStyle"
  >
    <p class="regular-14 mrg-bottom-12">
      Los mensajes de reconexión, sirven para volver a comunicarte con tu
      cliente después de las 24 horas de tu último mensaje enviado, dentro de un
      periodo de 7 días como máximo. Más información <anchor>aquí</anchor>
    </p>
    <a-alert
      message="
        Recuerda ser conciso en tu mensaje, responder en relación a la consulta de tu usuario y no enviar mensajes de tipo anuncio, ya que tu página podría ser sancionada por Meta.
      "
      show-icon
    />
    <p class="regular-14 text--center mrg-top-12 mrg-bottom-12">
      Te quedan <strong>{{ attempts }} intentos</strong> de envio de reconexión
    </p>
    <a-form-model
      ref="form_message"
      layout="vertical"
      class="modal__form"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item label="Contenido de mensaje" prop="message">
        <counter-text :text="form.message" :maxLength="200">
          <a-textarea
            v-model="form.message"
            placeholder="Escribe aquí..."
            :auto-size="{ minRows: 4, maxRows: 5 }"
          />
        </counter-text>
      </a-form-model-item>
    </a-form-model>
    <basic-footer
      cancel-text="Cancelar"
      ok-text="Enviar mensaje"
      :ok-disabled="ok.disabled"
      :ok-loading="ok.loading"
      @onOk="handleSubmit"
      @onCancel="handleClose"
    >
      <i slot="ok-icon" class="anticon">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.74 2.252l1.365 9.555.772.193-.772.193-1.365 9.555L22.236 12 2.74 2.252zm3.155 7.94L5.26 5.749 17.764 12 5.26 18.252l.635-4.445L13.123 12l-7.228-1.807z" fill="currentColor"/></svg>
      </i>
    </basic-footer>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CounterText from '@/app/shared/components/molecules/CounterText'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'ModalContinueFbMessage',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BasicFooter,
    CounterText,
    Anchor,
  },
  data: () => ({
    form: {
      message: '',
    },
    rules: {
      message: [{ required: true, message: 'Completa este campo' }],
    },
    bodyStyle: {
      paddingBottom: '72px',
    },
    ok: {
      disabled: false,
      loading: false,
    },
  }),
  mounted() {},
  computed: {
    ...mapGetters(['active_breakpoint', 'ticketSelected']),

    /**
     * @return {Boolean}
     */
    okDisabled() {
      return !this.form.message.length
    },
    /**
     * Retorna el número de intentos que se puedan usar para envios
     * @return {Number} attempts
     */
    attempts() {
      const property = 'attemptsForTaggedMessage'

      if (this.ticketSelected && this.ticketSelected[property])
        return this.ticketSelected[property]
      else return 0
    },
  },
  methods: {
    ...mapActions(['addMessageInTicket']),

    /**
     * Escucha el submit del formulario
     */
    handleSubmit() {
      this.$refs.form_message.validate(async (valid) => {
        if (valid) {
          this.ok.disabled = true
          this.ok.loading = true

          try {
            const response = await this.addMessageInTicket({
              message: {
                text: this.form.message,
              },
              tagging: true,
            })
            console.log(response)

            this.ok.disabled = false
            this.ok.loading = false
            // cierra el modal
            this.handleClose()
            this.$message.success('Su mensaje se envió con éxito 🎉')
          } catch (e) {
            // si la respuesta no fue exitosa
            this.ok.disabled = false
            this.ok.loading = false
            console.error(e)
            this.$message.error('Ocurrió un error 😥 en el envio')
          }
        } else {
          return false
        }
      })
    },
    /**
     * Cierra el modal y resetea todos los cambios
     */
    handleClose() {
      this.$refs.form_message.resetFields()
      this.$emit('onClose')
      this.ok.disabled = false
      this.ok.loading = false
    },
  },
}
</script>

<style lang="sass" scoped></style>
