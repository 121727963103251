<template>
  <a-form :form="form" layout="vertical">
    <!--Titulo de la tarjeta-->
    <a-form-item :label="`Sección ${order}`" class="section-form-item">
      <counter-text
        :maxLength="validations.maxLengthTitleItem || 0"
        :text="form.getFieldValue('title') ? form.getFieldValue('title') : ''"
      >
        <a-input
          v-decorator="[
            'title',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                {
                  pattern: /^([A-Za-z0-9-%ñÑ&À-ú\s])+$/g,
                  message: 'No se admiten caracteres especiales',
                },
              ],
            },
          ]"
          @input="onInput"
          placeholder="Escribir aquí"
          :maxLength="validations.maxLengthTitleItem || 0"
          :disabled="onlyRead"
        />
      </counter-text>
    </a-form-item>
    <!-- Lista de variables -->
    <a-form-item class="section-form-item">
      <template slot="label">
        <span class="mrg-right-8">
          Guarda la selección de un Item en una variable
        </span>
        <a-switch
          class="form__switch"
          v-model="checkedVarToSave"
          size="small"
          @change="setIsSavedItem(false)"
        />
      </template>

      <a-select
        v-decorator="[
          'varToSave',
          {
            rules: [
              {
                required: checkedVarToSave,
                message: 'Por favor rellene el campo',
              },
            ],
          },
        ]"
        placeholder="Selecciona una de las variables"
        @change="setIsSavedItem(false)"
        :disabled="onlyRead || !checkedVarToSave"
      >
        <a-select-option
          v-for="simpleVar in simpleVarsForSave"
          :key="simpleVar._id"
          :disabled="
            (simpleVar.isUsed && simpleVar._id !== varToSave._id) ||
            simpleVar.deleted
          "
          :title="formatNameSimpleVar(simpleVar)"
        >
          {{ simpleVar.name }}
          <i v-if="simpleVar.deleted">( Variable eliminada )</i>
          <i v-else-if="simpleVar.isUsed && simpleVar._id !== varToSave._id"
            >( Usado en otro nodo )</i
          >
        </a-select-option>
      </a-select>
      <p slot="extra" class="text--left regular-14 mrg-bottom-0">
        Puedes crear tus propias variables.
        <router-link :to="{ name: 'manage-chatbots-vars' }" target="_blank"
          >Click aquí.</router-link
        >
      </p>
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import transformWordGender from '@/app/shared/mixins/transform'
import actionsToNodeSelected from '@/app/chatbots/mixins/actionsToNodes'
import CounterText from '@/app/shared/components/molecules/CounterText'

export default {
  name: 'ListSectionForm',
  components: {
    CounterText,
  },
  mixins: [transformWordGender, actionsToNodeSelected],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentNodeId: {
      type: String,
      required: true,
    },
    childId: {
      type: String,
      required: true,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
    varToSave: {
      name: null,
      _id: null,
    }, // solo se usará para comprobar si la variable, existe en el array de variables
    checkedVarToSave: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'list-section-form-chatbot',
    })
  },
  computed: {
    ...mapGetters([
      'nodes',
      'selectedNodeId',
      'validations_chatbot',
      'simple_chatbots_vars',
    ]),

    /**
     * Validaciones del nodo de acuerdo al soporte
     * @return {Object} validations
     * @return {Number} validations.maxLengthTitleCard
     * @return {Number} validations.maxLengthSubtitleCard
     */
    validations() {
      const node = 'lists'
      return this.validations_chatbot[node]
    },
    /**
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVarsForSave() {
      const existVarInSimple = this.varToSave._id
        ? this.simple_chatbots_vars.some(
            (simpleVar) => simpleVar._id === this.varToSave._id
          )
        : true
      if (existVarInSimple)
        return this.formatSimpleVars({
          parentNodeId: this.parentNodeId,
          nodeId: this.childId,
          simpleVars: this.simple_chatbots_vars,
        })
      else {
        const simpleVars = [...this.simple_chatbots_vars]
        return this.formatSimpleVars({
          parentNodeId: this.parentNodeId,
          nodeId: this.childId,
          simpleVars,
        })
      }
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions(['updateChild']),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.title
     * @param {String} args.subtitle
     * @param {Object} args.media
     * @param {Number} args.order
     * @param {Object} args.varToSave
     * @param {String} args.varToSave.name
     * @param {String} args.varToSave._id
     */
    setValues({ title, order, varToSave }) {
      this.order = order

      this.$nextTick(() => {
        this.form.setFieldsValue({
          title,
          varToSave: varToSave ? varToSave._id : undefined,
        })
      })
      if (varToSave) {
        this.varToSave = varToSave
        this.checkedVarToSave = true
      } else {
        this.varToSave = {
          name: null,
          _id: null,
        }
        this.checkedVarToSave = false
      }
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        this.handleUpdateCard(values)
        this.setIsSavedItem(true)
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.text - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     */
    async handleUpdateCard(values) {
      const settings = {
        order: this.order,
        parentNodeId: this.parentNodeId,
        childId: this.childId,
      }
      const newChild = {
        order: settings.order,
        title: values.title,
        chatbotVarId: this.checkedVarToSave ? values.varToSave : null,
      }

      const response = await this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: settings.parentNodeId,
        nodeType: 'lists',
        childId: settings.childId,
        newChild,
        childIdProperty: 'idItem',
      })
      if (response.success) {
        this.$emit('onChangeSettings', response)
        this.$message.success('Se actualizó correctamente')
      } else {
        this.$message.error(response.details || 'No se pudo ejecutar la acción')
      }
    },
    /**
     * Escucha el cambio de texto y verifica si el archivo es requerido
     */
    handleChangeText() {
      this.setIsSavedItem(false)
    },
  },
}
</script>

<style lang="sass" scoped>
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 14px
.form__selector__option
  width: 100%
.selector__option-container
  width: calc(100% + 12px)
  margin-left: -12px
  padding-left: 12px
.form__icon--inbox
  font-size: 24px
</style>
