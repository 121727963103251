const initialState = () => ({
  minimalQuantityDay: 3, // Mínima cantidad de días para mostrar el modal de selección
  expirationPlanModal: false, // Mostrar modal de expiracion del plan
  purchase: {
    products: [], // todos los porductos agregados al carro
    total: 0, // precio total a pagar
  },
  isAllowBuyPlans: true, // permitir comprar planes
  isAllowBuyPacks: true, // permitir comprar packetes o bolsas
  products: [], // todos los productos
  payments: [], // todos las compras y pagos realizadas
  payments_pagination: null, // paginación de las compras y pagos
  // Estados de las compras y pagos
  payments_statuses: {
    approved: {
      key: 'approved',
      value: 'Aprobado',
    },
    rejected: {
      key: 'rejected',
      value: 'Rechazado',
    },
    pending: {
      key: 'pending',
      value: 'Pendiente',
    },
    readjustment: {
      key: 'readjustment',
      value: 'Reposición',
    },
    annulled: {
      key: 'annulled',
      value: 'Anulado',
    },
  },
})

export default initialState
