<template>
  <div
    class="credit-card"
    :class="{ 'selected box-shadow': selected }"
    :style="`width: ${width}`"
    @click="handleSelected"
  >
    <div class="content">
      <div class="header">
        <p class="chak-pet-semi-bold-8px title mrg-bottom-0">
          TARJETA DE CRÉDITO
        </p>
        <img
          :src="`/img/img_credit_cards/${type.toLowerCase()}.png`"
          :alt="type"
          height="20px"
        />
      </div>
      <div class="number">
        <p class="chak-pet-medium-14px">****</p>
        <p class="chak-pet-medium-14px">****</p>
        <p class="chak-pet-medium-14px">****</p>
        <p class="chak-pet-medium-14px">**{{ lastTwoDigits }}</p>
      </div>
      <p class="chak-pet-medium-12px name-owner ellipsis" :title="nameOwner">
        {{ nameOwner }}
      </p>
      <div style="display: flex; justify-content: space-between">
        <p class="chak-pet-normal-12px mrg-bottom-0 date-expired">
          Exp {{ moment(dateExpired).format('YY/MM') }}
        </p>
        <p v-if="subscription" class="chak-pet-normal-12px mrg-bottom-0">
          Método de pago actual
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'CreditCard',
  props: {
    nameOwner: {
      type: String,
      require: false,
      default: 'Ant design',
    },
    numberCard: {
      type: [String, Number],
      require: false,
      default: '1234 1234 1234 1234',
    },
    type: {
      type: String,
      require: false,
      default: 'visa',
    },
    dateExpired: {
      type: String,
      require: false,
      default: 'Today',
    },
    selected: {
      type: Boolean,
      require: false,
      default: false,
    },
    width: {
      type: String,
      default: '285px',
      require: false,
    },
    subscription: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data: () => ({
    moment,
  }),
  computed: {
    ...mapGetters(['profile']),

    lastTwoDigits() {
      return this.numberCard.slice(-2)
    },
  },
  methods: {
    handleSelected() {
      this.$emit('handleSelected')
    },
  },
}
</script>

<style lang="sass" scoped>
.credit-card
  border-radius: 8px
  border: 1px solid $gray_dark_300
  padding: 20px
  cursor: pointer
  // width: 285px
  .content
    text-align: left
    display: flex
    flex-direction: column
    // justify-content: space-between
    // align-items: flex-end
    .header
      width: 100%
      margin-bottom: 12px
      display: flex
      justify-content: space-between
      .title
        color: $gray_dark_700
    .name-owner
      text-transform: uppercase
      margin-bottom: 12px
      color: $gray_dark_800
    .number
      margin-bottom: 4px
      color: $gray_dark_900
      display: flex
      p
        margin-bottom: 0px
        margin-right: 20px
    .date-expired
      color: $gray_dark_800
    // .number-card, .owner-card
    //   text-align: left
    //   p
    //     color: $gray_dark_900
    //   .title
    //     color: $gray_dark_800
    // &:first-child
    //   margin-bottom: 12px
    //   align-items: flex-start
.selected
  border: 1px solid $blue_6
  background-color: $blue_1
</style>
