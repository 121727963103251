import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_DASHBOARD_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * @param {*} state
   * @param {Array} totalTags
   */
  SET_GRAPH_TOTAL_TAGS(state, totalTags) {
    state.totalTags = totalTags
  },
  /**
   * @param {*} state
   * @param {Array} stateAgends
   */
  SET_GRAPH_SCHEDULES(state, stateAgends) {
    state.stateAgends = stateAgends
  },
  /**
   * @param {*} state
   * @param {Array} dataNewTickets
   */
  SET_GRAPH_NEW_TICKETS(state, dataNewTickets) {
    state.dataNewTickets = dataNewTickets
  },
  /**
   * @param {*} state
   * @param {Array} averageStatuses
   */
  SET_GRAPH_AVERAGE_STATUSES(state, averageStatuses) {
    state.statusesTime = averageStatuses
  },
  /**
   * @param {*} state
   * @param {Array} averageAnswerLine
   */
  SET_GRAPH_AVERAGE_ANSWERS_LINES(state, averageAnswerLine) {
    state.timeAnswer.lines = averageAnswerLine
  },
  /**
   * @param {*} state
   * @param {Array} stateTickets
   */
  SET_GRAPH_STATUSES_TICKETS(state, stateTickets) {
    state.stateTickets = stateTickets
  },
  /**
   * @param {*} state
   * @param {Array} stateSMS
   */
  SET_GRAPH_SMS(state, stateSMS) {
    state.stateSMS = stateSMS
  },
  /**
   * @param {*} state
   * @param {Array} stateTemplate
   */
  SET_GRAPH_TEMPLATE(state, stateTemplate) {
    state.stateTemplate = stateTemplate
  },
}

export default mutations
