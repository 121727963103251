/** Archivos de variables globales */

var waitTimeout = setTimeout

/**
 * Ejecuta una función en cuanto se haya terminado de intentar una función repetidas veces seguidas
 * @param {Function}  callback Función a ejecutar tras terminar de escribir
 * @param {Number}    delay tiempo de retraso - opcional
 */
const endWaitAttempting = (callback, delay = 1000) => {
  if (waitTimeout) clearTimeout(waitTimeout)

  waitTimeout = setTimeout(async () => {
    await callback()
  }, delay)
}

export default {
  endWaitAttempting,
}
