let tasks = []
let indexCurrent = 0
let start = false

/**
 * Agrega una tarea a la cola
 * @param {Function} task
 */
function addTask(task) {
  indexCurrent = indexCurrent + 1
  const index = indexCurrent
  tasks.push({ index, task })
  startQueue()
}

/**
 * Limpia todas las tareas y resetea el indexCurrent
 */
function cleanTasks() {
  tasks = []
  indexCurrent = 0
}

/**
 * Inicia la cola de tareas
 */
function startQueue() {
  if (!start) {
    // si no se inició la cola, correrá la cola de tareas
    console.log('allowed start 🎉')
    runQueue()
  } else console.log('is processing queue 🕔')
}

/**
 * Corre la cola de tareas
 */
async function runQueue() {
  start = true

  for await (const task of tasks) {
    // esperará a que se terminé para poder ejecutar
    // la siguiente tarea
    await task.task()
  }
  // terminó de ejecutarse todo lo que estaba en el for
  start = false
  cleanTasks()
}

export { addTask, runQueue, cleanTasks }
