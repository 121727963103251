import momentTimezone from 'moment-timezone'

export default {
  methods: {
    /**
     * Realiza la reconversion de la fecha relativa utc-0 a fechas con la zona horaria seleccionada y local
     * @async
     * @param {String} selectedTimeZone
     * @returns {Object} { currentSelectedTime, timezone, utc }
     */
    async recalcRelativeTime(selectedTimeZone) {
      let currentSelectedTime = momentTimezone().tz(selectedTimeZone)
      if (!currentSelectedTime) return {}
      const diffUTC = currentSelectedTime.utcOffset() / 60
      let utc = `UTC ${Math.sign(diffUTC) == 1 ? '+' : '-'} ${Math.abs(
        diffUTC
      )}`
      return {
        currentSelectedTime,
        timezone: selectedTimeZone,
        utc,
      }
    },
  },
}
