var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Título"}},[_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthTitle,"text":_vm.form.getFieldValue('title') ? _vm.form.getFieldValue('title') : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'title',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthTitle},on:{"input":function($event){return _vm.setIsSavedItem(false)}}})],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de listas"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que sera un mensaje de introducción para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthBody,"text":_vm.form.getFieldValue('body') ? _vm.form.getFieldValue('body') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'body',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'body',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"body",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthBody,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","filterOption":_vm.filterOption,"disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":(emoji) => _vm.handleSetEmoji(emoji, 'body'),"onOpenVars":function($event){return _vm.handleOpenVars('body')}}})],1),_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Botón principal  "),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_vm._v(" Este botón será aquel que despliegue la lista de items que podrá seleccionar el cliente. ")]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthTitleGlobalOption,"text":_vm.form.getFieldValue('globalOptionTitle')
          ? _vm.form.getFieldValue('globalOptionTitle')
          : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'globalOptionTitle',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'globalOptionTitle',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthTitleGlobalOption},on:{"input":function($event){return _vm.setIsSavedItem(false)}}})],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje por respuesta incorrecta"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda este mensaje se mostrará al cliente cuando mande un nuevo mensaje y no seleccione ninguno de los botones mostrados. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthErrorMessage,"text":_vm.form.getFieldValue('errorMessage')
          ? _vm.form.getFieldValue('errorMessage')
          : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'errorMessage',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'errorMessage',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"errorMessage",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthErrorMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","filterOption":_vm.filterOption,"disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":(emoji) => _vm.handleSetEmoji(emoji, 'errorMessage'),"onOpenVars":function($event){return _vm.handleOpenVars('errorMessage')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }