<template>
  <div class="card">
    <div class="card__body">
      <div class="card__title">
        <img v-if="showRocket" src="../../assets/rocket.png" alt="Cohete" />
        <h5 class="heading-h5 mrg-bottom-0">{{ title }}</h5>
        <template v-if="check">
          <a-icon type="check-circle" theme="filled" class="card__icon" />
          <span class="body-1 card__check-text">Completo</span>
        </template>
      </div>
      <p class="body-2 card__description">{{ description }}</p>
      <a-tag v-for="(tag, index) in tags" :key="index" :color="tag.color">
        {{ tag.text }}
      </a-tag>
    </div>
    <div class="card__contain-picture">
      <img
        v-if="picture"
        :src="require(`../../assets/${picture}`)"
        alt="Imagen de ruta"
        class="card__picture"
        height="40px"
      />
      <a v-if="redirectTo" :href="redirectTo" target="_blank"
        >Comencemos <a-icon type="arrow-right"
      /></a>
      <a v-else @click="handleSelected"
        >Comencemos <a-icon type="arrow-right"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardRoute',
  props: {
    title: { type: String, default: 'Ruta Ticker' },
    description: { type: String, default: 'This is description Ticker' },
    tags: { type: Array, default: null },
    showRocket: { type: Boolean, default: true },
    picture: { type: String, default: null },
    selected: { type: Boolean, required: false, default: false },
    check: { type: Boolean, required: false, default: false },
    redirectTo: { type: String, required: false, default: null },
  },
  data: () => ({}),
  methods: {
    handleSelected() {
      this.$emit('onSelect')
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  border: 1px solid $gray_5
  border-radius: 8px
  padding: 20px
  display: flex
  justify-content: space-between
  align-items: center
  &__title
    margin-bottom: 8px
    display: flex
    align-items: center
  &__description
    color: $gray_dark_800
  &__picture
  &__icon
    color: $polar-green_6
    font-size: 14px
    margin-left: 8px
  &__check-text
    margin-left: 4px
    color: $gray_dark_700
  // &:hover
  //   border: 1px solid $blue_6
  //   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)
  //   cursor: pointer
  &__contain-picture
    height: 120px
    min-width: 140px
    margin-left: 40px
    display: flex
    justify-content: space-between
    flex-direction: column
    align-items: flex-end
</style>
