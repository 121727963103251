/**
 * Ordena los mensajes de un ticket segun la fecha, del mas antiguo al mas reciente
 * @param {Object[]} messages - mensajes de un ticket
 * @param {Date} message[].created_at
 * @param {Date} message[].apiReceivedAt
 */
const sortMessages = ({ messages }) => {
  // setea apiReceivedAt a los mensajes que no tienen esta propiedad
  const messagesFormatted = [...messages].map((message) => {
    if (!message.apiReceivedAt) {
      if (message.channel.type === 'webchat')
        console.log('Este mensaje no tiene apiReceivedAt', message)
      message.apiReceivedAt = message.created_at
    } else {
      if (message.channel.type === 'webchat')
        console.log('Este mensaje SI tiene apiReceivedAt', message)
    }
    return message
  })
  // ordenar los mensajes por la hora de llegada
  const orderMessages = messagesFormatted.sort(
    (a, b) => new Date(a.apiReceivedAt) - new Date(b.apiReceivedAt)
  )
  return orderMessages
}
/**
 * Filtra las etiquetas de mayor a menor
 * @param {Object} args
 * @param {Object} args.tags
 * @returns {Object[]}
 */
const sortTags = ({ tags }) => {
  return [...tags].sort(
    (a, b) => new Date(b.assigned_at) - new Date(a.assigned_at)
  )
}

export default {
  sortMessages,
  sortTags,
}
