<template>
  <div
    class="menu-item-footer"
    :class="{
      'menu-item-footer--collapse': collapse,
      'menu-item-footer--no-collapse': !collapse,
    }"
  >
    <div class="menu-item-footer__content">
      <slot name="avatar">
        <avatar-status
          shape="circle"
          :status="profileStatus.state"
          :border="profileStatus.borderMin"
          :padding="profileStatus.paddingMin"
          class="menu-item-footer__avatar"
          :class="{
            'menu-item-footer__avatar--show-status':
              profileStatus.borderMin > 0,
          }"
        >
          <a-avatar
            v-if="avatar"
            :src="avatar"
            :size="32"
            alt="Avatar de perfil"
          />
          <DefaultIconLetter v-else :names="title" />
        </avatar-status>
      </slot>
      <div v-if="collapse" class="menu-item-footer__texts">
        <p
          class="
            body-3
            menu-item-footer__text menu-item-footer__text--username
            mrg-bottom-0
            ellipsis
          "
          style="display: inherit"
        >
          {{ title }}
        </p>
        <p
          v-if="subtitle"
          class="
            body-1
            menu-item-footer__text menu-item-footer__text--subtitle
            mrg-bottom-0
            ellipsis
          "
          style="display: inherit"
        >
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import AvatarStatus from '@/app/shared/components/avatars/AvatarStatus.vue'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'MenuItemFooter',
  components: {
    DefaultIconLetter,
    AvatarStatus,
  },
  mixins: [supportMixin],
  props: {
    title: { type: String, required: false, default: 'Ticketita' },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    collapse: { type: Boolean, required: false, default: false },
    avatar: { type: String, required: false },
  },
  computed: {
    ...mapGetters(['profile', 'profile_states']),
  },
}
</script>

<style lang="sass" scoped>
.menu-item-footer
  height: 72px
  position: relative
  &__content
    display: flex
    justify-content: space-between
    align-items: center
  &__avatar
  &__text
    text-align: left
    &--username
      color: $white_000
    &--subtitle
      color: $gray_dark_600
  &__texts
    width: 140px
    margin-right: 20px
  &--collapse
    width: 204px
    margin-left: -3px
  &--no-collapse
    width: inherit
    right: 20px
    height: 100%
    display: flex
    align-items: center
    .menu-item-footer
      &__content
      &__avatar
        left: 17px
        &--show-status
          left: 12px
</style>
