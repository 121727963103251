<template>
  <ManageTicketsTemplate />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ManageTicketsTemplate from '@/app/manageTickets/components/templates/ManageTicketsTemplate.vue'
import mixinAttempt from '@/app/shared/mixins/attempt'

export default {
  name: 'ManageTickets',
  mixins: [mixinAttempt],
  components: {
    ManageTicketsTemplate,
  },
  computed: {
    ...mapGetters(['profile']),
  },
  created() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'profile manage ticket'
      this.getAssignedLines()
    })
  },
  methods: {
    ...mapActions(['getAssignedLines']),
  },
}
</script>
