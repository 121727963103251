import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'

export default {
  computed: {
    ...mapGetters(['profile']),

    /**
     * @return {string} - seguro, segura, segur@
     */
    wordSure() {
      return this.profile && this.profile.gender
        ? this.transformWordGender('segura', this.profile.gender)
        : 'segur@'
    },
  },
  methods: {
    /**
     * Transforma el tipo de usuario de la bd, a un texto mas agradable segun el idioma
     * @param {string} type Tipo de usuario
     * @param {string} subType - subtipo de usuario para el guest user
     * @return {string} Devuelve el tipo de usuario transformado a español
     */
    transformTypeUser(type, subType = null) {
      switch (type) {
        // type a Español
        case 'agent':
          return 'Agente'
        case 'supervisor':
          return 'Supervisor'
        case 'supervisor_agent':
          return 'Supervisor'
        case 'admin':
          return 'Administrador'
        case 'admin_agent':
          return 'Administrador'
        case 'securitec':
          return 'Superusuario'
        case 'master_agent': {
          if (subType && subType === 'guest') return 'User Support'
          else return 'Master'
        }
        // Español a type
        case 'Agente':
          return 'agent'
        case 'Supervisor':
          return 'supervisor'
        case 'Administrador':
          return 'admin'
        default:
          return null
      }
    },
    /**
     * Transforma el nombre de un canal de la bd, respetando las mayúsculas segun su nombre oficial
     * @param {string} channel [whatsapp,instagram,...] Nombre del canal
     * @return {string} Devuelve el nombre del canal, pero respetando las mayúsculas segun su nombre oficial
     */
    transformApiChannel(channel) {
      switch (channel) {
        case 'whatsapp':
          return 'WhatsApp'
        case 'messenger':
          return 'Messenger'
        case 'telegram':
          return 'Telegram'
        case 'instagram':
          return 'Instagram'
        case 'webchat':
          return 'Webchat'
        case 'sms':
          return 'SMS'
        default:
          return null
      }
    },
    /**
     * Cambiar letra de una palabra según el género que le corresponde
     * @param {string}        word Palabra para cambiar según el género
     * @param {string}        [gender=null] Género [male, female, null]
     * @param {Number}        index Índice de la letra que se modificará - Opcional (Por defecto tomará al último)
     * @param {Array[string]} values Valores por los que se reemplazarán las letras de las palabras - Opcional (Por defecto se reemplazarán por "o" y "a" respectivamente)
     * @returns {string} palabra formateada
     */
    transformWordGender(word, gender = null, index, values) {
      // word y gender son obligatorios
      // Validación de entradas
      if (!word) throw new Error('word required')
      if (!['male', 'female', null].includes(gender))
        throw new Error('gender only must be male, female or null')
      if (!values) values = ['o', 'a', '@']
      if (!index) index = word.length - 1
      // Comparaciones
      let split = word.split('')
      split[index] =
        gender === 'male'
          ? values[0]
          : gender === 'female'
          ? values[1]
          : values[2]
      return split.join('')
    },
    /**
     * Muestra el nombre del género en un idioma a partir de una key
     * @param {string} gender [male,female]
     * @param {string} [lang='es']
     * @returns {string}
     */
    transforGender(gender, lang = 'es') {
      switch (lang) {
        case 'es': {
          if (gender === 'male') return 'Masculino'
          else if (gender === 'female') return 'Femenino'
          break
        }
      }
      throw Error(`gender: ${gender} not supported`)
    },
    /**
     * Transforma los dias a partir de los indices que se pasan y devuelve la abreviación y el nombre del día
     * @param {Number[]} indexDays - array de los indices de los dias (del 0 al 6)
     * @return {Object[]} [{ abreviation, day }]
     */
    transformIndexDays(indexDays) {
      const days = [
        { abreviation: 'Do', day: 'Domingo' },
        { abreviation: 'Lu', day: 'Lunes' },
        { abreviation: 'Ma', day: 'Martes' },
        { abreviation: 'Mi', day: 'Miércoles' },
        { abreviation: 'Ju', day: 'Jueves' },
        { abreviation: 'Vi', day: 'Viernes' },
        { abreviation: 'Sa', day: 'Sábado' },
      ]
      return days.filter((day, index) => indexDays.includes(index))
    },
    /**
     * Transforma el nombre de los planes de acuerdo al tipo
     * @param {string} key
     * @param {string} type - uppercase, lowercase, capitalize
     * @returns {string}
     */
    transformNamePlan(key, type) {
      let namePlan = null
      switch (key) {
        case 'free':
          namePlan = 'FREE'
          break
        case 'advanced':
          namePlan = 'ADVANCED'
          break
        case 'business':
          namePlan = 'BUSINESS'
          break
        case 'enterprise':
          namePlan = 'ENTERPRISE'
      }
      if (type === 'lowercase') return namePlan.toLowerCase()
      else if (type === 'capitalize')
        return (
          namePlan.charAt(0).toUpperCase() + namePlan.toLowerCase().slice(1)
        )
      else return namePlan.toUpperCase()
    },
    /**
     * Transforma una palabra a lowercase, uppercase, capitalize
     * @param {string} word
     * @param {string} type lowercase, uppercase, capitalize
     * @return {string}
     */
    transformWord(word, type) {
      switch (type) {
        case 'lowercase':
          return word.toLowerCase()
        case 'capitalize':
          return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
        case 'uppercase':
          return word.toUpperCase()
        default:
          return word
      }
    },
    /**
     * Transforma la accion de cada nodo
     * @param {string} action - message, options
     * @returns {string}
     */
    transformActionNode({ type, nodeType }) {
      let transformAction = 'continue_'
      const nodeTypesContinue = [
        'message',
        'options',
        'catcher',
        'buttons',
        'cards',
        'lists',
      ]
      if (type === 'continue' && nodeTypesContinue.includes(nodeType))
        return transformAction + nodeType
      else return type
    },
    /**
     * Transforma el nombre del proveedor
     * @param {string} supplier
     * @return {string} nombre del proveedor
     */
    transformNameProvider(supplier) {
      if (vari.APP_NAME === 'Beex Conversations') supplier = 'Beex'
      else if (vari.APP_NAME === 'Tedexis') supplier = 'Tedexis'
      return supplier
    },
    /**
     * Obtiene el nombre de la empresa
     * @return {string} nombre de la empresa
     */
    getOwnerCopyright() {
      if (vari.OWNER_APP === 'Beex') return 'Beex Latam'
      else return vari.OWNER_APP ?? vari.APP_NAME
    },
  },
}
