<template>
  <a-form :form="form" layout="vertical" class="modal__form">
    <a-form-item
      label="Nombre de la variable"
      extra="El nombre debe ser en minúsculas, sin espacios y sin caracteres especiales. Puede tener guiones bajos. Ejemplo: producto_123"
    >
      <a-input
        placeholder="Escribe aquí..."
        v-decorator="[
          'name',
          {
            rules: [
              { required: true, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
              {
                pattern: /^[a-z0-9_ñ]+$/g,
                message:
                  'El nombre debería ser en minúsculas separados por guión bajo, sin caracteres especiales',
              },
            ],
          },
        ]"
        :maxLength="30"
        :disabled="disabledName"
      />
    </a-form-item>
    <!--Asociar un campo personalizado-->
    <a-row
      class="mrg-bottom-8"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h6 class="semibold-14 mrg-bottom-0">
        Asociar a un campo personalizado (Opcional)
      </h6>
      <a-switch
        size="small"
        v-model="activeAsociation"
        :disabled="disabledAsociation"
      />
    </a-row>

    <a-form-item label="Destino del campo personalizado">
      <a-select
        v-decorator="[
          'destinity',
          {
            rules: [{ required: false, message: 'Por favor rellene el campo' }],
          },
        ]"
        placeholder="Selecciona el campo personalizado"
        :disabled="!activeAsociation"
        @change="handleChangeDestinity"
        :allowClear="true"
      >
        <a-select-option value="clients">
          Campos personalizados del cliente
        </a-select-option>
        <a-select-option value="workspace">
          Campos personalizados del ticket
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Campo personalizado">
      <a-select
        placeholder="Selecciona el campo personalizado"
        v-decorator="[
          'customFieldId',
          {
            rules: [{ required: false, message: 'Por favor rellene el campo' }],
          },
        ]"
        :disabled="!activeAsociation"
        :allowClear="true"
      >
        <a-select-option
          v-for="customField in customFieldsLocal"
          :key="customField._id"
          :disabled="
            customField.deleted || disabledCustomField(customField._id)
          "
        >
          {{ customField.name }}
          {{
            disabledCustomField(customField._id)
              ? '( Ya está usado en otra variable )'
              : ''
          }}
          {{ customField.deleted ? '( Campo eliminado )' : '' }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <h6 class="semibold-14 mrg-bottom-8">
      Palabras que se desea obviar de las respuestas
    </h6>
    <!--Palabras obviadas de las variables-->
    <a-form-item class="mrg-bottom-0">
      <p class="form__item__description">
        Ejm: Si capturas la variable “Nombre” y el cliente responde “Mi nombre
        es Maria”, agrega aquí “mi nombre es” para que solo se guarde “Maria”.
      </p>
      <div class="form__tags">
        <a-tag
          v-for="localAvoidWord in localAvoidWords"
          :key="localAvoidWord"
          class="mrg-bottom-4"
          :closable="true"
          @close="() => handleRemoveAvoidWord(localAvoidWord)"
        >
          {{ localAvoidWord }}
        </a-tag>
        <a-input
          v-if="showInputAvoidWord"
          ref="input_avoid_word"
          type="text"
          size="small"
          class="form__input--add"
          :value="newAvoidWord"
          @change="handleInputChangeAvoidWord"
          @blur="handleInputConfirmAvoidWord"
          @keyup.enter="handleInputConfirmAvoidWord"
          placeholder="Escribe aquí"
          :maxLength="100"
        />
        <a-tag v-else class="form__tag--add" @click="handleShowInputAvoidWord">
          <a-icon type="plus" /> Agregar palabras
        </a-tag>
      </div>
    </a-form-item>
    <a-divider class="form__divider" />
    <h6 class="semibold-14">Recuerda</h6>
    <i
      >Estas variables estarán disponibles para la configuración de todos los
      chatbots.
    </i>
  </a-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VarsForm',
  props: {},
  data: () => ({
    showInputAvoidWord: false,
    localAvoidWords: [], // copia de las palabras obviadas de la variable
    newAvoidWord: '',
    activeAsociation: false, // activo la asociacion de campo personalizado
    typeCustomField: null,
    disabledAsociation: false,
    disabledName: false,
    originField: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'vars-form-chatbot' })
  },
  computed: {
    ...mapGetters([
      'company_custom_fields_tickets',
      'company_custom_fields_clients',
      'simple_chatbots_vars',
    ]),

    /**
     * @returns {Object[]} customFields
     * @returns {String} customFields.name
     * @returns {String} customFields._id
     */
    customFields() {
      if (!this.typeCustomField) return []
      let customFields = []
      switch (this.typeCustomField) {
        case 'workspace':
          customFields = this.company_custom_fields_tickets
          break
        case 'clients':
          customFields = this.company_custom_fields_clients
          break
        default:
          customFields = []
      }
      const typesAllowed = ['text']
      return customFields.filter((field) => typesAllowed.includes(field.type))
    },
    /**
     * Campos que se usarán localmente
     * @returns {Object[]} customFields
     * @returns {String} customFields.name
     * @returns {String} customFields._id
     */
    customFieldsLocal() {
      if (!this.originField) return [...this.customFields]
      const field = this.customFields.find(
        (customField) => customField._id === this.originField.customFieldId
      )
      if (!field) {
        return [
          ...this.customFields,
          {
            deleted: true,
            _id: this.originField.customFieldId,
            ...this.originField,
          },
        ]
      } else return [...this.customFields]
    },
  },
  methods: {
    /**
     * Remueve una palabra obviada
     * @param {Number} avoidWord
     */
    handleRemoveAvoidWord(avoidWord) {
      const localAvoidWords = [...this.localAvoidWords].filter(
        (value) => value !== avoidWord
      )
      this.localAvoidWords = localAvoidWords
    },
    /**
     * Muestra el input para agregar una palabra obviada
     */
    handleShowInputAvoidWord() {
      this.showInputAvoidWord = true
      this.$nextTick(function () {
        this.$refs.input_avoid_word.focus()
      })
    },
    /**
     * Escucha el cambio del valor de la palabra obviada que se esta agregando
     * @param {Object} e
     */
    handleInputChangeAvoidWord(e) {
      this.newAvoidWord = e.target.value
    },
    /**
     * Escucha otros eventos
     */
    handleInputConfirmAvoidWord() {
      const newAvoidWord = this.newAvoidWord
      let localAvoidWords = this.localAvoidWords

      const valuesLocalAvoidWords = localAvoidWords

      const noExistInLocalAvoid =
        valuesLocalAvoidWords.indexOf(newAvoidWord) === -1

      const maxLengthAvoidWords = 20
      if (newAvoidWord && noExistInLocalAvoid) {
        if (localAvoidWords.length < maxLengthAvoidWords)
          localAvoidWords = [...localAvoidWords, newAvoidWord]
        else this.$message.error('No se pueden agregar más palabras')
      }

      Object.assign(this, {
        localAvoidWords,
        showInputAvoidWord: false,
        newAvoidWord: '',
      })
    },
    /**
     * Escucha del cambio del destino de variable
     * @param {String} typeCustomField
     */
    handleChangeDestinity(typeCustomField) {
      this.typeCustomField = typeCustomField
    },
    /**
     * Setea valores a los campos del formulario
     * @param {Object} values
     * @param {String} values.name
     * @param {String[]} values.excludeWords
     * @param {Object} values.customField
     * @param {String} values.customField.module
     * @param {String} values.customField.customFieldId
     * @param {String} values.default
     */
    setFieldsValue(values) {
      this.form.setFieldsValue({
        name: values.name,
        destinity: values.customField.module || undefined,
        customFieldId: values.customField.customFieldId || undefined,
      })
      this.localAvoidWords = values.excludeWords
      this.typeCustomField = values.customField.module
      this.activeAsociation = false
      this.disabledAsociation = values.default
      this.disabledName = values.default || false
      this.originField = values.customField
    },
    /**
     * Resetea los campos del formulario
     */
    resetFields() {
      this.form.resetFields()
      this.showInputAvoidWord = false
      this.localAvoidWords = [] // copia de las palabras obviadas de la variable
      this.newAvoidWord = ''
      this.activeAsociation = false // activo la asociacion de campo personalizado
      this.typeCustomField = null
      this.disabledAsociation = false
      this.disabledName = false
      this.originField = null
    },
    /**
     * Verifica si un campo ya esta siendo usado en otra variable
     * @param {String} customFieldId
     * @return {Boolean}
     */
    disabledCustomField(customFieldId) {
      const currentFieldIdInVar = this.form.getFieldValue('customFieldId')

      return (
        currentFieldIdInVar !== customFieldId &&
        this.simple_chatbots_vars.some(
          (simpleVar) =>
            simpleVar.customField &&
            simpleVar.customField.customFieldId === customFieldId
        )
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  &__tags
    min-height: 74px
  &__divider
    margin: 0px 0px 12px 0px
  &__input--add
    width: 124px
  &__tag--add
    min-width: 124px
    background: $white_000
    border-style: dashed
  &__item
    &__description
      color: $gray_7
</style>
