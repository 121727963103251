<template>
  <section>
    <underline-title title="Configurar alerta para tickets en cola de espera" />
    <card-switch
      v-if="roles && isAllowedFor('supervisor', roles)"
      class="section__card"
      title="Supervisores asignados a la cola"
      :description="`Se notificará a todos los supervisores de las distintas colas, cuando existan tickets nuevos en espera. Recuerda configurar la cantidad mínima de tickets en cola de espera para que ${app} empiece a notificar.`"
      :active="ticketsInQueue.supervisors.active"
      @onChangeActive="handleChangeActive('tickets-in-queue-supervisors')"
    >
      <div slot="extra-content" class="section__extra-content">
        <p class="body-1 text-left" style="margin-bottom: 2px">
          Ingresa la cantidad mínima de tickets
        </p>
        <a-input-number
          size="small"
          v-model="ticketsInQueue.supervisors.minTickets"
          style="width: 189px"
          :disabled="!ticketsInQueue.supervisors.active"
          @blur="handleUpdateTicketsInQueue"
        />
      </div>
    </card-switch>
    <card-switch
      class="section__card"
      title="Otros destinatarios"
      :active="ticketsInQueue.others.active"
      :description="descriptionOthers"
      @onChangeActive="handleChangeActive('tickets-in-queue-others')"
    >
      <div slot="extra-content" class="section__extra-content">
        <div class="section__head">
          <div>
            <p class="body-1 text-left" style="margin-bottom: 2px">
              Ingresa la cantidad mínima de tickets
            </p>
            <a-input-number
              size="small"
              v-model="ticketsInQueue.others.minTickets"
              style="width: 189px"
              :disabled="!ticketsInQueue.others.active"
              @blur="handleUpdateTicketsInQueue('minTickets')"
            />
          </div>
          <a-button
            type="primary"
            icon="plus"
            size="small"
            @click="modalAddPhone.visible = true"
            class="section__button"
            :disabled="!ticketsInQueue.others.active"
            >Agregar nuevo teléfono</a-button
          >
        </div>
        <editable-table
          :columns="columns"
          :data-source="ticketsInQueue.phones"
          @onDelete="handleDeletePhone"
          :disabled="!ticketsInQueue.others.active"
        />
      </div>
    </card-switch>
    <modal-add-phone
      :visible="modalAddPhone.visible"
      @onCancel="modalAddPhone.visible = false"
      @onAddPhone="handleUpdateTicketsInQueue"
    />
    <modal-callback
      :visible="modalCallback.visible"
      @onCancel="handleCancelCallback"
    />
  </section>
</template>

<script>
import CardSwitch from '@/app/shared/components/cards/CardSwitch'
import UnderlineTitle from '@/app/settings/components/organisms/alertsMenu/UnderlineTitle'
import EditableTable from '@/app/settings/components/organisms/alertsMenu/EditableTable'
import ModalAddPhone from '@/app/settings/components/organisms/alertsMenu/ModalAddPhone'
import ModalCallback from '@/app/settings/components/organisms/alertsMenu/ModalCallback'
import { mapGetters, mapActions } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'SectionTicketsInQueue',
  mixins: [compareMixin],
  components: {
    CardSwitch,
    UnderlineTitle,
    EditableTable,
    ModalAddPhone,
    ModalCallback,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    app_email: process.env.VUE_APP_OFICIAL_EMAIL,
    columns: [
      {
        title: 'País',
        key: 'country',
        dataIndex: 'country',
      },
      {
        title: 'Código de país',
        key: 'code',
        dataIndex: 'code',
      },
      {
        title: 'Número de teléfono',
        key: 'number',
        dataIndex: 'number',
      },
      {
        title: 'Acción',
        key: 'action',
        dataIndex: 'action',
        width: '30%',
        scopedSlots: { customRender: 'action' },
      },
    ],
    ticketsInQueue: {
      supervisors: { active: false, minTickets: 0 },
      others: { active: false, minTickets: 0 },
      phones: [],
    },
    modalAddPhone: { visible: false },
    modalCallback: { visible: false },
    loadingSupervisors: false,
    loadingOthers: false,
  }),
  mounted() {
    this.setValue()
  },
  computed: {
    ...mapGetters(['company', 'notificationsPhones', 'roles']),
    descriptionOthers() {
      return `Se alertará a los teléfonos que registres cuando existan tickets nuevos en espera. Recuerda seleccionar el país y agregar el número telefónico para el envío de las alertas SMS. Es importante que escojas la cantidad mínima de tickets en cola de espera para que ${this.app} empiece a alertar.`
    },
  },
  watch: {
    notificationsPhones() {
      this.setValue()
    },
  },
  methods: {
    ...mapActions(['updateNotificationsQueue', 'setAnalytics']),

    setValue() {
      this.ticketsInQueue = {
        supervisors: {
          active: this.company.settings.notifications.ticket.queue.active,
          minTickets:
            this.company.settings.notifications.ticket.queue
              .quantityForSupervisors,
        },
        others: {
          active:
            this.company.settings.notifications.ticket.queue.activeForOthers,
          minTickets:
            this.company.settings.notifications.ticket.queue.quantityForOthers,
        },
        phones: this.notificationsPhones.queue
          ? this.notificationsPhones.queue
          : [],
        inputValue: '',
      }
    },
    handleChangeActive(type) {
      let phones_target = null
      if (!this.company.settings.notifications.editable) {
        if (type === 'tickets-in-queue-supervisors')
          this.loadingSupervisors = true
        else this.loadingOthers = true
        return (this.modalCallback.visible = true)
      }
      if (type === 'tickets-in-queue-supervisors') {
        this.ticketsInQueue.supervisors.active =
          !this.ticketsInQueue.supervisors.active
        phones_target = 'supervisors'
      } else {
        this.ticketsInQueue.others.active = !this.ticketsInQueue.others.active
        phones_target = 'others'
      }
      this.handleUpdateTicketsInQueue()
      this.setAnalytics({
        eventName: 'alert_sms_tickets_parking',
        extraData: {
          phones_target,
          switch: this.ticketsInQueue.others.active ? 'on' : 'off',
        },
      })
    },
    async handleUpdateTicketsInQueue(phone) {
      if (phone && phone !== 'minTickets')
        this.ticketsInQueue.phones.push(phone)
      const updateNotificationsQueue = await this.updateNotificationsQueue({
        quantityForSupervisors: this.ticketsInQueue.supervisors.minTickets,
        quantityForOthers: this.ticketsInQueue.others.minTickets,
        active: this.ticketsInQueue.supervisors.active,
        activeForOthers: this.ticketsInQueue.others.active,
        phones: this.ticketsInQueue.phones,
      })
      this.modalAddPhone.visible = false
      if (updateNotificationsQueue.success)
        this.$message.success('Se actualizó exitósamente')
      else {
        const message = updateNotificationsQueue.details
          ? updateNotificationsQueue.details
          : 'No se pudo actualizar'
        this.$message.error(message)
      }
    },
    handleDeletePhone(record) {
      console.log(record)
      let n = 0
      const phones = this.ticketsInQueue.phones.map((elem) => {
        n = n + 1
        elem.order = n
        return elem
      })
      console.log(phones)
      const newPhones = phones.filter((elem) => elem.order !== record.order)
      console.log(newPhones)
      this.ticketsInQueue.phones = newPhones
      this.handleUpdateTicketsInQueue()
    },
    handleCancelCallback() {
      this.modalCallback.visible = false
      this.loadingSupervisors = false
      this.loadingOthers = false
      this.setValue()
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  &__card
    margin-top: 12px
  &__extra-content
    margin-top: 12px
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
  &__button
  &__head
    width: 100%
    display: flex
    justify-content: space-between
    align-items: flex-end
    margin-bottom: 8px
</style>
