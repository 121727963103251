const MULTIMEDIA = [
  {
    type: 'image',
    title: 'imágenes',
    formats: {
      titles: ['JPG', 'JPEG', 'PNG'],
      values: ['JPG', 'JPEG', 'PNG', 'jpg', 'png', 'jpeg'],
      accept: 'image/jpeg, image/png, image/jpg',
    },
    max_size: { size: 5, title: '5MB' },
    enabled_nodes: ['message', 'card'],
  },
  {
    type: 'video',
    title: 'videos',
    formats: { titles: ['MP4'], values: ['MP4'], accept: 'video/mp4' },
    max_size: { size: 5, title: '5MB' },
    enabled_nodes: ['message'],
  },
  {
    type: 'document',
    title: 'documentos',
    formats: {
      titles: ['PDF', 'EXCEL', 'WORD', 'PPT'],
      values: ['PDF'],
      accept:
        'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    max_size: { size: 5, title: '5MB' },
    enabled_nodes: ['message'],
  },
]

export { MULTIMEDIA }
