const mutations = {
  /**
   * Setea empresas paginadas
   * @param {*} state
   * @param {Array} companies Arreglo de objetos de empresas
   */
  SET_PAGINATE_COMPANIES(state, companies) {
    state.paginate_companies = companies
  },
  /**
   * Setea la paginacion de empresas
   * @param {*} state
   * @param {Array} companies Arreglo de objetos de empresas
   */
  SET_PAGINATION_COMPANIES(state, companies) {
    state.pagination_companies = companies
  },
  /**
   * Agrega una empresa a la lista de empresas
   * @param {*} state
   * @param {Object} company Objeto de una empresa
   */
  ADD_PAGINATE_COMPANY(state, company) {
    state.paginate_companies.push(company)
  },
  /**
   * Actualiza una empresa a la lista de empresas
   * @param {*} state
   * @param {Object} args
   * @param {String} args.companyId
   * @param {Object} args.company Objeto de una empresa
   */
  UPDATE_PAGINATE_COMPANY(state, { companyId, company }) {
    let foundCompany = state.paginate_companies.find(
      (item) => item._id === companyId
    )
    if (!foundCompany) return
    foundCompany.name = company.name
    foundCompany.country = company.country
    foundCompany.operation = company.operation
    foundCompany.avatar = company.avatar || null
  },
  /**
   * Remueve una empresa paginada
   * @param {*} state
   * @param {String} companyId
   */
  REMOVE_PAGINATE_COMPANY(state, companyId) {
    state.paginate_companies = state.paginate_companies.filter(
      (company) => company._id !== companyId
    )
  },
  /**
   * Setea empresas no paginadas
   * @param {*} state
   * @param {Array} companies Arreglo de objetos de empresas
   */
  SET_SIMPLE_COMPANIES(state, companies) {
    state.simple_companies = companies
  },
  /**
   * Agrega una empresa a la lista de empresas
   * @param {*} state
   * @param {Object} company Objeto de una empresa
   */
  ADD_SIMPLE_COMPANY(state, company) {
    state.simple_companies.push(company)
  },
  /**
   * Actualiza una empresa a la lista de empresas
   * @param {*} state
   * @param {Object} args
   * @param {String} args.companyId
   * @param {Object} args.company Objeto de una empresa
   */
  UPDATE_SIMPLE_COMPANY(state, { companyId, company }) {
    let foundCompany = state.simple_companies.find(
      (item) => item._id === companyId
    )
    if (!foundCompany) return
    foundCompany.name = company.name
    if (company.avatar) foundCompany.avatar = company.avatar
  },
  /**
   * Remueve una empresa paginada
   * @param {*} state
   * @param {String} companyId
   */
  REMOVE_SIMPLE_COMPANY(state, companyId) {
    state.simple_companies = state.simple_companies.filter(
      (company) => company._id !== companyId
    )
  },
}
export default mutations
