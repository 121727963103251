<template>
  <!--Modal de seleccionar plantilla-->
  <a-modal
    :visible="visible"
    title="Enviar plantilla"
    :bodyStyle="bodyStyle"
    :footer="null"
    :afterClose="afterCloseModalSelect"
    :maskClosable="false"
    @cancel="$emit('onClose')"
    :width="widthModal"
    centered
  >
    <FormContinueMessage
      ref="formContinueMessage"
      @closeModal="$emit('onClose')"
      :channel-companyId="channelCompanyId"
    />
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import FormContinueMessage from '@/app/shared/components/forms/FormContinueMessage'

export default {
  name: 'ModalContinueMessage',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    FormContinueMessage,
  },
  data: () => ({
    bodyStyle: {
      height: 'max-content',
      padding: '0',
    },
  }),
  computed: {
    ...mapGetters(['active_breakpoint']),

    /**
     * @return {String} id del canal de la empresa
     */
    channelCompanyId() {
      return this.ticketSelected && this.ticketSelected.channelCompanyId
    },
    /**
     * @return {String} ancho del modal
     */
    widthModal() {
      return this.active_breakpoint.is_mobile ? '38em' : 'max-content'
    },
  },
  methods: {
    /**Activa la funcion del hijo */
    afterCloseModalSelect() {
      this.$refs.formContinueMessage.emitCloseModal()
      this.$refs.formContinueMessage.resetFields()
    },
  },
}
</script>

<style scoped></style>
