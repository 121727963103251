<template>
  <div class="content">
    <a-row
      class="modal-contain"
      style="{'width': '793px'}"
      type="flex"
      align="middle"
    >
      <a-col :span="12" class="first-column">
        <!--graph ilustration puede tomar los valores de draw, image, lottie-->
        <!--ilustracion SVG-->
        <template v-if="data.graphIlustration === 'draw'">
          <div v-html="data.ilustration"></div>
        </template>
        <!--ilustracion SRC-->
        <template v-else-if="data.graphIlustration === 'image'">
          <img
            :src="data.ilustration"
            :alt="data.ilustration"
            style="cursor: auto"
          />
        </template>
        <!--ilustracion LOTTIE-->
        <lottie-vue-player
          v-else-if="data.graphIlustration === 'lottie'"
          :src="data.ilustration"
          autoplay
          loop
          mode="normal"
        />
      </a-col>

      <a-col :span="12" class="second-column">
        <h2 class="heading-h2 color-gray-dark-900">
          <i v-if="titleIconsStart.length > 0" class="title-icon start">
            <i v-for="titleIcon in titleIconsStart" :key="titleIcon._id">
              <img
                v-if="titleIcon.graphIcon === 'image'"
                :src="titleIcon.src"
              />
              <i
                v-else-if="titleIcon.graphIcon === 'draw'"
                v-html="titleIcon.src"
              ></i>
            </i>
          </i>
          {{ data.title }}
          <i v-if="titleIconsEnd.length > 0" class="title-icon end">
            <i v-for="titleIcon in titleIconsEnd" :key="titleIcon._id">
              <img
                v-if="titleIcon.graphIcon === 'image'"
                :src="titleIcon.src"
              />
              <i
                v-else-if="titleIcon.graphIcon === 'draw'"
                v-html="titleIcon.src"
              ></i>
            </i>
          </i>
        </h2>

        <template v-for="item in data.news">
          <div class="feature-description" :key="item._id">
            <!--por svg-->
            <template v-if="item.graphIcon === 'draw'">
              <div v-html="item.icon"></div>
            </template>
            <!--por ruta-->
            <template v-else-if="item.icon">
              <img :src="item.icon" style="cursor: auto" />
            </template>
            <div class="feature-contain">
              <div class="feature-stretch">
                <h6 class="heading-h6 color-gray-dark-900">
                  {{ item.subtitle }}
                </h6>
                <div>
                  <a-tag v-if="item.tag" :color="item.tag.color">
                    {{ item.tag.text }}
                  </a-tag>
                </div>
              </div>
              <p
                v-html="item.description"
                class="body-2 color-gray-dark-800"
                style="margin-bottom: 0"
              ></p>
            </div>
          </div>
        </template>

        <div class="modal-footer" :class="{ 'margin-off': !!data.extraInfo }">
          <a-button
            type="primary"
            style="width: fit-content"
            size="large"
            @click="call_to_action"
          >
            {{ data.buttonCloseText }}
          </a-button>
        </div>
      </a-col>
    </a-row>
    <a-row v-if="data.extraInfo">
      <div class="body-2 color-gray-13 extraInfo" v-html="data.extraInfo"></div>
    </a-row>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex/dist/vuex.common.js'

export default {
  name: 'BasicContent',
  props: {
    data: {
      _id: { type: String, required: true },
      title: { type: String, default: 'Titulo' },
      subtitle: { type: String, default: 'Subtítulo' },
      showFirstIlustration: { type: Boolean, default: false },
      description: {
        type: String,
        default:
          'Ipsum culpa non nostrud ex tempor quis ad. Duis aliquip velit reprehenderit eu mollit sunt cupidatat. Occaecat proident laboris Lorem pariatur qui ad cupidatat qui velit Lorem minim. Eu sit culpa laborum nisi fugiat sit. Sunt laboris laboris ea minim Lorem. Irure veniam exercitation consequat pariatur laboris fugiat occaecat enim nostrud do aute qui ipsum cupidatat. Officia voluptate cupidatat fugiat eu nulla sit cupidatat dolor et est do.',
      },
      graphIlustration: {
        type: String,
        enum: ['image', 'draw'],
      },
      ilustration: {
        type: String,
        default: null,
      },
      buttonCloseText: { type: String, require: true },
      buttonCloseCTA: [
        {
          action: {
            type: String,
            enum: ['redirection', 'email-notification', 'restmain'],
          },
          url: { type: String },
        },
      ],
    },
  },
  computed: {
    titleIconsStart() {
      if (!this.data.titleIcons) return []
      return this.data.titleIcons.filter((ti) => ti.position === 'start')
    },
    titleIconsEnd() {
      if (!this.data.titleIcons) return []
      return this.data.titleIcons.filter((ti) => ti.position === 'end')
    },
  },
  methods: {
    ...mapActions(['chiefNotify']),
    /**
     * @return {Promise<void>}
     */
    async call_to_action() {
      /** @type Array<{action:string,url:string}> */
      const ctas = this.data.buttonCloseCTA
      await Promise.all(
        ctas.map((el) => {
          switch (el.action) {
            case 'redirection':
              window.open(el.url, '_blank', 'noopener')
              break
            case 'email-notification':
              this.chiefNotify({ newsId: this.data._id })
              break
            case 'restmain':
              axios.post(el.url, {
                newsId: this.data._id,
              })
              break
          }
        })
      )
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  &__title
    margin-bottom: 32px
  &__picture
    margin-bottom: 32px
.text-content
  margin: auto 24px 32px 24px
.second-column
  text-align: center
.modal-footer
  margin-top: 36px
</style>
