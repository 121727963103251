<template>
  <a-row :gutter="[12, 12]">
    <custom-section-scroll
      :master-styles="{
        height: `calc(100vh - (${heightFilters} + ${margin}))`,
      }"
    >
      <a-col :span="6" v-for="(item, index) in paginate_companies" :key="index">
        <card-company
          :data-source="item"
          @onUpdate="handleShowModalFormCompany"
          @onDelete="handleShowModalDeleteCompany"
          @onChange="handleShowModalChangeCompany"
        />
      </a-col>
    </custom-section-scroll>
    <modal-form-company
      :visible="modalFormCompany.visible"
      :title="modalFormCompany.title"
      :data-source="modalFormCompany.dataSource"
      :onOk="handleUpdateCompany"
      :ok-text="modalFormCompany.okText"
      @onClose="handleCloseModalFormCompany"
    />
    <modal-delete-company
      :visible="modalDeleteCompany.visible"
      :data-source="modalDeleteCompany.dataSource"
      :is-current-company="modalDeleteCompany.isCurrent"
      @onClose="handleCloseModalDeleteCompany"
    />
    <modal-change-company
      :visible="modalChangeCompany.visible"
      :data-source="modalChangeCompany.dataSource"
      @onClose="handleCloseModalChangeCompany"
    />
  </a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardCompany from '@/app/companies/components/organisms/CardCompany'
import ModalFormCompany from '@/app/companies/components/organisms/ModalFormCompany'
import ModalDeleteCompany from '@/app/companies/components/organisms/ModalDeleteCompany'
import ModalChangeCompany from '@/app/companies/components/organisms/ModalChangeCompany'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ListCompanies',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    CardCompany,
    ModalFormCompany,
    ModalDeleteCompany,
    CustomSectionScroll,
    ModalChangeCompany,
  },
  data: () => ({
    modalFormCompany: {
      visible: false,
      title: '',
      dataSource: {},
      okText: 'Actualizar',
    },
    modalDeleteCompany: {
      visible: false,
      dataSource: {},
      isCurrent: false,
    },
    modalChangeCompany: {
      visible: false,
      dataSource: {},
    },
    margin: '24px',
    heightFilters: '80px',
  }),
  computed: {
    ...mapGetters(['paginate_companies']),
  },
  methods: {
    ...mapActions(['updateCompany']),

    /**
     * Muetsrta el modal de edicion de la empresa
     * @param {Object} company
     * @param {String} company.name
     */
    handleShowModalFormCompany(company) {
      this.modalFormCompany.dataSource = company
      this.modalFormCompany.title = `Editar ${company.name}`
      this.modalFormCompany.visible = true
    },
    /**
     * Cierra el modal de edicion de la empresa
     */
    handleCloseModalFormCompany() {
      this.modalFormCompany.visible = false
      this.modalFormCompany.dataSource = {}
      this.modalFormCompany.title = ''
    },
    /**
     * Muestra el modal de eliminacion de la empresa
     * @param {Object} args
     * @param {Object} args.data
     * @param {String} args.data.name
     * @param {String} args.data.operations
     * @param {String} args.data.country
     * @param {Boolean} args.isCurrent
     */
    handleShowModalDeleteCompany({ data, isCurrent }) {
      this.modalDeleteCompany.dataSource = data
      this.modalDeleteCompany.isCurrent = isCurrent
      this.modalDeleteCompany.visible = true
    },
    /**
     * Muestra el modal de cambio de empresa
     * @param {Object} company
     * @param {String} commpany.name
     * @param {String} commpany.operations
     * @param {String} commpany.country
     */
    handleShowModalChangeCompany(company) {
      this.modalChangeCompany.dataSource = company
      this.modalChangeCompany.visible = true
    },
    /**
     * Cierra el modal de eliminacion de la empresa
     */
    handleCloseModalDeleteCompany() {
      this.modalDeleteCompany.visible = false
      this.modalDeleteCompany.dataSource = {}
    },
    /**
     * Cierra el modal de cambio de la empresa
     */
    handleCloseModalChangeCompany() {
      this.modalChangeCompany.visible = false
      this.modalChangeCompany.dataSource = {}
    },
    /**
     * Actualiza la empresa
     * @param {Object} values
     * @param {String} values.name
     * @param {String} values.operations
     * @param {String} values.country
     */
    async handleUpdateCompany(values) {
      const response = await this.updateCompany(values)
      if (response.success) {
        this.$message.success('Se actualizó con éxito')
        this.handleCloseModalFormCompany()
      } else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
</script>

<style scoped></style>
