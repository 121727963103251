import Vue from 'vue'

const mutations = {
  /**
   * Setea los comentarios paginados de un ticket
   * @param {*} state
   * @param {Object[]} paginate_comments_ticket
   * @param {String} paginate_comments_ticket._id
   * @param {Object} paginate_comments_ticket.user
   * @param {String} paginate_comments_ticket.user.avatar
   * @param {String} paginate_comments_ticket.user._id
   * @param {String} paginate_comments_ticket.text
   * @param {Date} paginate_comments_ticket.created_at
   */
  SET_PAGINATE_COMMENTS_TICKET(state, paginate_comments_ticket) {
    state.paginate_comments_ticket = paginate_comments_ticket
  },
  /**
   * Setea la paginación de los comentarios de un ticket
   * @param {*} state
   * @param {Object} pagination_comments_ticket
   * @param {Number} pagination_comments_ticket.total
   * @param {Number} pagination_comments_ticket.page
   * @param {Number} pagination_comments_ticket.last_page
   */
  SET_PAGINATION_COMMENTS_TICKET(state, pagination_comments_ticket) {
    state.pagination_comments_ticket = pagination_comments_ticket
  },
  /**
   * Agrega un comentario al ticket
   * @param {*} state
   * @param {Object} comment_ticket
   * @param {String} comment_ticket._id
   * @param {Object} comment_ticket.user
   * @param {String} comment_ticket.user.avatar
   * @param {String} comment_ticket.user._id
   * @param {String} comment_ticket.text
   * @param {Date} comment_ticket.created_at
   */
  ADD_COMMENT_TICKET(state, comment_ticket) {
    state.paginate_comments_ticket.unshift(comment_ticket)
  },
  /**
   * Actualiza un comentario del ticket
   * @param {*} state
   * @param {Object} comment_ticket
   * @param {String} comment_ticket._id
   * @param {Object} comment_ticket.user
   * @param {String} comment_ticket.user.avatar
   * @param {String} comment_ticket.user._id
   * @param {String} comment_ticket.text
   * @param {Date} comment_ticket.created_at
   */
  UPDATE_COMMENT_TICKET(state, comment_ticket) {
    let index = state.paginate_comments_ticket.findIndex(
      (comment) => comment._id === comment_ticket._id
    )
    if (index === -1) return
    Vue.set(state.paginate_comments_ticket, index, comment_ticket)
  },
  /**
   * Elimina un comentario paginado del ticket
   * @param {*} state
   * @param {String} commentId
   */
  DELETE_COMMENT_TICKET(state, commentId) {
    state.paginate_comments_ticket = state.paginate_comments_ticket.filter(
      (comment) => comment._id !== commentId
    )
  },
}
export default mutations
