/**
 * Reemplaza todas las coincidencias clave:valor del objeto en el string
 * @param {String} str
 * @param {Object} mapObj
 * @return {String}
 */

const replaceAllStr = (str, mapObj) => {
  const regObject = new RegExp(Object.keys(mapObj).join('|'), 'gi')

  return str.replace(regObject, function (matched) {
    return mapObj[matched.toUpperCase()]
  })
}

export default {
  replaceAllStr,
}
