<template>
  <a-tabs
    default="client-basic-fields-form"
    :tabBarGutter="0"
    :tabBarStyle="{ display: 'flex', justifyContent: 'center' }"
  >
    <a-tab-pane key="client-basic-fields-form" tab="Datos personales">
      <form-client :client="dataSourceClient" @update="onUpdateClient" />
    </a-tab-pane>
    <a-tab-pane key="client-custom-fields-form" tab="Personalizados">
      <client-custom-fields-form :data-source-client="dataSourceClient" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import FormClient from '@/app/shared/components/forms/FormClient'
import ClientCustomFieldsForm from '@/app/shared/components/forms/ClientCustomFieldsForm'

export default {
  name: 'ClientFields',
  props: {
    dataSourceClient: { type: Object, required: true },
  },
  components: {
    FormClient,
    ClientCustomFieldsForm,
  },
  methods: {
    /**
     * Actualizar datos del cliente
     * @param {Object} client
     */
    onUpdateClient(client) {
      this.$emit('update', client)
    },
  },
}
</script>

<style scoped></style>
|
