<template>
  <i class="anticon" aria-label="icon">
    <slot>
      <img
        svg-inline
        class="evericon"
        :src="`@/app/shared/assets/evericons/${type}.svg`"
        :height="size"
        :width="size"
      />
    </slot>
  </i>
</template>

<script>
export default {
  name: 'EverIcon',

  props: {
    type: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: [String, Number],
      required: false,
      default: 14,
    },
  },
}
</script>

<style lang="sass" scoped>
.evericon
  color: green
</style>
