const initialState = () => ({
  totalTags: [],
  stateAgends: [],
  dataNewTickets: [],
  statusesTime: [], // tiempo de estados
  timeAnswer: { lines: [] }, // ##-> Corregir esto!!! debería ser un arreglo, afeta al getters y al mutation
  stateTickets: { pendings: [], receiveds: [], opens: [], finished: [] },
  stateSMS: { queued: [], sent: [], delivered: [], failed: [] },
  stateTemplate: { failed: [], sent: [], read: [], delivered: [] },
})

export default initialState
