<template>
  <div class="password-form">
    <a-form layout="vertical" :form="form" @submit="handleSubmit">
      <a-form-item label="Nueva contraseña" has-feedback>
        <a-input-password
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Este campo es requerido.',
                },
                {
                  min: 8,
                  message: '8 caracteres a más.',
                },
                {
                  pattern: /[A-Z]/g,
                  message: '1 carácter en mayúscula.',
                },
                {
                  pattern: /[a-z]/g,
                  message: '1 carácter en minúscula.',
                },
                {
                  pattern: /[0-9]/g,
                  message: '1 número.',
                },
                {
                  pattern: /[^A-Za-z0-9]/g,
                  message: '1 carácter especial',
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            },
          ]"
          :size="inputs_size"
          placeholder="Escribir nueva contraseña"
        />
      </a-form-item>
      <a-form-item label="Confirmar nueva contraseña" has-feedback>
        <a-input-password
          placeholder="Escribir nueva contraseña"
          v-decorator="[
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor confirme su contraseña',
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            },
          ]"
          @blur="handleConfirmBlur"
          @input="onInputConfirm"
          :size="inputs_size"
        />
      </a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        style="width: 100%; margin-top: 4%"
        :loading="loadingBtn"
        :disabled="disabledBtn"
        :size="buttons_size"
        >{{ nameButton }}</a-button
      >
    </a-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PasswordForm',
  props: {
    nameButton: {
      type: String,
      required: false,
      default: 'Cambiar y continuar',
    },
    onSavePassword: {
      type: Function,
      required: true,
      default: null,
    },
    email: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    confirmDirty: false,
    autoCompleteResult: [],
    submit: false, // acompañan a handleSubmit para evitar mas de 1 enter . se obliga a entrar en el primer if en el primer de handlesubmit
    loadingBtn: false,
    disabledBtn: true,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' })
  },
  computed: {
    ...mapGetters(['buttons_size', 'inputs_size']),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          if (!this.submit) {
            this.submit = true
            this.loadingBtn = true
            let params = null
            if (this.email)
              params = { password: values.password, email: this.email }
            else params = values.password
            const response = await this.onSavePassword(params)
            this.loadingBtn = false
            this.$emit('onSubmit', response) // usado para la restauración de password
            if (response.data.success === true) {
              this.$emit('success')
            } else {
              this.form.resetFields()
              this.$message.error(response.data.result)
            }
            return (this.submit = false)
          } else {
            return
          }
        }
      })
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback('La contraseña nueva no coincide')
        this.disabledBtn = true
      } else {
        callback()
        this.disabledBtn = false
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    onInputConfirm(e) {
      const value = e.target.value
      if (value.length == 0) this.disabledBtn = true
    },
  },
}
</script>
<style lang="sass" scoped>
.password-form
  width: 349px
  height: 253px
</style>
<style lang="sass">
.ant-form-explain
  text-align: left
</style>
