import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_ACCOUNT_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Crear store lista de cuenta de usuario
   * @param state
   * @param payload
   * @constructor
   */
  SET_LIST_ACCOUNT(state, payload) {
    state.accounts = payload
  },
  /**
   * Crear store cuenta de usuario
   * @param state
   * @param account
   * @constructor
   */
  SET_CREATE_ACCOUNT(state, account) {
    account.created_at = account.created_at.toISOString()
    state.accounts.push(account)
    state.totalAccounts++
  },
  /**
   * Actualizar store cuenta de usuario
   * @param state
   * @param account
   * @constructor
   */
  SET_EDIT_ACCOUNT(state, account) {
    let current_account = state.accounts.find((v) => v._id === account._id)
    Object.assign(current_account, account)
  },
  /**
   * Actualizar store total cuentas de usuario
   * @param state
   * @param totalAccounts
   * @constructor
   */
  SET_TOTAL_ACCOUNTS(state, totalAccounts) {
    state.totalAccounts = totalAccounts
  },
  SET_ACCOUNT_AGENT_LINES(state, payload) {
    let current_account = state.accounts.find((v) => v._id === payload.agentId)
    current_account.company.linesId.push(payload.lineId)
  },
  SET_ACCOUNT_SUPERVISOR_LINES(state, payload) {
    let current_account = state.accounts.find(
      (v) => v._id === payload.supervisorId
    )
    current_account.company.linesId.push(payload.lineId)
  },
}

export default mutations
