<template>
  <a-table
    :data-source="dataSource"
    :pagination="pagination"
    :loading="loading"
    :locale="localeTable"
    :columns="columns"
    @change="handleChangeTable"
    size="middle"
    :scroll="{ x: 'max-content', y: 'calc(100vh - 246px)' }"
    class="table table-scroll"
  >
    <!--Id del producto-->
    <template slot="code" slot-scope="code"> #{{ code }} </template>
    <template slot="name" slot-scope="name, record">
      <div class="display-flex">
        <a-avatar
          v-for="(picture, index) in record.pictures"
          :key="index"
          shape="square"
          class="table__picture"
          :size="52"
          :src="picture ? picture : ''"
        >
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!picture" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.655 8.004l9.459-4.62m-9.459 4.62l9.459 4.519M3.655 8.004L1.34 5.792a.32.32 0 01.085-.522l8.94-4.178a.32.32 0 01.354.056l2.396 2.237m-9.459 4.62l-2.3 2.066a.32.32 0 00.073.526l2.093 1.017m9.593-8.23l9.61 4.645m-9.61-4.644l2.292-2.229a.32.32 0 01.363-.059l8.843 4.26a.32.32 0 01.097.505l-1.985 2.167m0 0l-9.61 4.494m9.61-4.494l1.985 2.168a.32.32 0 01-.097.505l-1.888.912m-9.61.909l2.419 2.33a.32.32 0 00.361.058l6.83-3.297m-9.61.909l-2.444 2.332a.32.32 0 01-.361.056L3.52 11.614m9.593.909V25m0 0l9.437-4.906a.32.32 0 00.173-.284v-8.196M13.114 25l-9.42-4.906a.32.32 0 01-.173-.284v-8.196" stroke="#BFBFBF" stroke-width="1.2"/></svg>
        </a-avatar>
        <div class="table__description-content">
          <p class="semibold-12 mrg-bottom-0">{{ name }}</p>
          <p class="mrg-bottom-0 regular-12 gray_7 table__description ellipsis">
            {{ record.description }}
          </p>
        </div>
      </div>
    </template>
    <!--Precio del producto-->
    <template slot="price" slot-scope="price, record">
      {{ record.currency.symbol }} {{ price }} {{ record.currency.code }}
    </template>
    <!--Fecha de creacion del producto-->
    <template slot="created_at" slot-scope="created_at">
      {{ moment(created_at).format('LLL') }}
    </template>
    <!--Estado del producto-->
    <template slot="active" slot-scope="active, record">
      <a-switch
        :checked="active"
        size="small"
        @change="$emit('onChangeStatus', record._id, !record.active)"
      />
    </template>
    <!--acciones en la tabla-->
    <span slot="action" slot-scope="text, record">
      <a @click="$emit('onShowUpdate', record)">Editar</a>
      <a-divider type="vertical" />
      <a @click="$emit('onShowDelete', record._id)">Eliminar</a>
    </span>
  </a-table>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TableProducts',
  props: {
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data: () => ({
    moment,
    localeTable: {
      emptyText: 'No se han encontrado productos',
    },
  }),
  computed: {
    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.total || 0,
        current: this.page,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'productos encontrados' : 'producto encontrado'}
          </span>
        ),
      }
    },
  },
  methods: {
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleChangeTable(pagination) {
      this.$emit('onPaginate', pagination.current)
    },
  },
}
</script>

<style lang="sass" scoped>
.table
  margin-top: 20px
.table__picture
  margin-right: 12px
  background-color: $gray_3
.gray_7
  color: $gray_7
.table__description
  -webkit-line-clamp: 2 !important
.table__description-content
  width: 80%
</style>
