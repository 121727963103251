<template>
  <a-tooltip placement="left">
    <!--Tooltip-->
    <span v-if="showTooltip" slot="title">{{ title }}</span>
    <router-link
      :to="route"
      class="menu-item"
      :class="{ 'menu-item--mobile': active_breakpoint.is_mobile }"
    >
      <a-row type="flex" align="middle">
        <!--Icono del menu-->
        <div
          class="menu-item__container-icon"
          :class="{
            'menu-item__container-avatar': avatar,
            'menu-item__container-avatar--mobile':
              avatar && active_breakpoint.is_mobile,
          }"
        >
          <!--Icono de Ant design-->
          <a-icon
            v-if="icon"
            :type="icon"
            class="menu-item__icon"
            :class="{ 'menu-item__icon--mobile': active_breakpoint.is_mobile }"
            :style="{ fontSize: `${iconSize}px` }"
          />
          <i
            v-else-if="image"
            v-html="image"
            aria-label="icon"
            class="menu-item__icon anticon"
            :class="{ 'menu-item__icon--mobile': active_breakpoint.is_mobile }"
            :style="{ fontSize: `${iconSize}px` }"
          >
          </i>
          <!--Icono componente-->
          <component
            v-else-if="iconComponent"
            :is="component(iconComponent)"
            class="menu-item__icon"
            :class="{ 'menu-item__icon--mobile': active_breakpoint.is_mobile }"
            :style="{ fontSize: `${iconSize}px` }"
          />
          <!--Avatar-->
          <template v-else-if="avatar">
            <a-avatar
              v-if="avatar.src"
              :src="avatar.src"
              :size="iconSize"
              class="menu-item__avatar"
            />
            <a-avatar v-else :size="iconSize" class="avatar" icon="user" />
          </template>
        </div>
        <!--Titulo del menu-->
        <span v-if="showTitle">{{ title }}</span>
      </a-row>
    </router-link>
  </a-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
import IconShoppingBag from '@/app/shared/components/icons/IconShoppingBag'
import IconShoppingCoupon from '@/app/shared/components/icons/IconShoppingCoupon'

export default {
  name: 'TicketMenuItem',
  props: {
    title: { type: String, default: 'item', required: true },
    disabled: { type: Boolean, default: false, required: false },
    route: { type: Object, default: () => {}, required: false },
    icon: { type: String, default: null, required: false },
    iconSize: { type: [String, Number], default: 20, required: false },
    image: { type: String, default: null, required: false },
    avatar: { type: Object, default: null, required: false },
    color: { type: String, default: 'yellow', required: false },
    showTitle: { type: Boolean, default: false, required: false },
    showTooltip: { type: Boolean, default: true, required: false },
    iconComponent: { type: String, default: null, required: false },
  },
  components: { IconShoppingBag, IconShoppingCoupon },
  computed: {
    ...mapGetters(['active_breakpoint']),
  },
  methods: {
    component(nameComponent) {
      switch (nameComponent) {
        case 'IconShoppingCoupon':
          return IconShoppingCoupon
        case 'IconShoppingBag':
          return IconShoppingBag
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.menu-item
  &__container-icon
    color: $gray_7
  &__icon
    padding: 6px
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out
    border-radius: 50%
    border: 2px solid $white_000
  &__container-avatar
    border: 3px solid $white_000
    border-radius: 50%
  &__avatar
  &:active
    .menu-item__icon
      box-shadow: 0 0 40px 40px $yellow_3 inset
  &:hover
    .menu-item__icon
      border: 2px solid $yellow_3
      color: $gray_9
      transition: border 0.5s, color 0.5s
    .menu-item__container-avatar
      border: 3px solid $yellow_3
      transition: border 0.5s, color 0.5s
  &--mobile
    .menu-item__container-avatar
      margin-right: 10px
      display: flex
      padding: 6px
      border-width: 0px
    .menu-item__icon
      border-width: 0px
    .menu-item__container-icon
      display: flex
    &:hover
      .menu-item__container-avatar
        border-width: 0px
      .menu-item__icon
        border-width: 0px
        color: $blue_6
    &:active
      .menu-item__icon
        box-shadow: none
.router-link-active
  .menu-item__icon
    box-shadow: 0 0 40px 40px $yellow_3 inset
    border: 2px solid $yellow_3
    color: $gray_9
  .menu-item__icon--mobile
    border-width: 0px
    box-shadow: none
    color: $blue_6
  .menu-item__container-avatar
    border: 3px solid $yellow_3
  .menu-item__container-avatar--mobile
    border-width: 0px
</style>
