<template>
  <ever-icon>
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 3h4a2 2 0 012 2v1h4a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h4V5a2 2 0 012-2zM4 19v-4h7v1h2v-1h7v4H4zm9-6h7V8H4v5h9zm1-8v1h-4V5h4z" fill="currentColor"/></svg>
  </ever-icon>
</template>

<script>
import EverIcon from '@/app/shared/components/icons/EverIcon'

export default {
  name: 'IconCase',
  components: {
    EverIcon,
  },
}
</script>

<style scoped></style>
