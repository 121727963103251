<template>
  <span :class="{ info_i2: newValue.empty, empty: newValue.empty }">{{
    newValue.text
  }}</span>
</template>

<script>
export default {
  name: 'EmptyCell',
  props: {
    value: {
      type: [String, Number],
    },
    textEmpty: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    /**
     * Nuevo valor para mostrar
     * @returns {Object} newValue
     * @returns {Boolean} newValue.isEmpty
     * @returns {String} newValue.text
     */
    newValue() {
      let isEmpty = false
      if (!this.value) isEmpty = true
      else
        switch (typeof this.value) {
          case 'string':
            isEmpty = !this.value || !this.value.length
            break
          case 'number':
            isEmpty = !this.value
            break
          default:
            isEmpty = false
            break
        }

      return {
        empty: isEmpty,
        text: isEmpty ? this.textEmpty : this.value,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.empty
  color: $gray_6
</style>
