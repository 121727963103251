import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const initialState = () => ({
  company_tags: [],
  paginate_tags: { page: 1, total: 0 },
  simple_tags: [],
})

const state = initialState

const getters = {
  company_tags: (state) => state.company_tags,
  paginate_tags: (state) => state.paginate_tags,
  simple_tags: (state) => {
    const tags = state.simple_tags
    const formattedTags = tags.map((tag) => {
      if (tag.lines) tag.linesIds = tag.lines.map((line) => line._id)
      return tag
    })
    return formattedTags
  },
}

const mutations = {
  /**
   * Setea etiquetas de la empresa
   * @param {*} state
   * @param {Array} tags
   */
  SET_COMPANY_TAGS(state, tags) {
    state.company_tags = tags
  },
  SET_PAGINATE_TAGS(state, pagination) {
    state.paginate_tags = pagination
  },
  SET_SIMPLE_TAGS(state, simple_tags) {
    state.simple_tags = simple_tags
  },
  /**
   * Agrega una etiqueta a la lista de tags dentro de los ajustes de la empresa
   * @param {*} state
   * @param {Object} tag Objeto de una etiqueta
   */
  ADD_COMPANY_TAG(state, tag) {
    state.company_tags.unshift(tag)
  },
  UPDATE_COMPANY_TAG(state, { tagId, tag }) {
    const indexTag = state.company_tags.findIndex((item) => item._id === tagId)
    if (indexTag === -1) return
    state.company_tags.splice(indexTag, 1, tag)
  },
  /**
   * Quita a la etiqueta de los ajustes de la empresa
   * @param {*} state
   * @param {String} tagId // id de la etiqueta
   */
  REMOVE_COMPANY_TAG(state, tagId) {
    state.company_tags = state.company_tags.filter((item) => item._id !== tagId)
  },
}
const actions = {
  /**
   *
   */
  async getCompanyTags(
    context,
    { page = 1, limit = 5, search, moduleSource = 'crm' }
  ) {
    try {
      const params = {
        page,
        limit,
        search,
        moduleSource,
      }
      const response = await axios.get(`${vari.UHR}/tags`, { params })
      context.commit('SET_COMPANY_TAGS', response.data.data.data)
      const pagination = { ...response.data.data }
      delete pagination.data
      context.commit('SET_PAGINATE_TAGS', pagination)
    } catch (err) {
      console.error(err)
    }
  },
  /**
   * Agregar etiqueta
   * @param {*} context
   * @param {}
   * @returns
   */
  async addCompanyTag(
    context,
    { title, color, allLines = true, linesIds = [] }
  ) {
    try {
      const tag = {
        title,
        color,
        allLines,
        linesIds,
      }
      if (!tag.title) return
      const url = `${vari.UHR}/tags`

      const response = await axios.post(url, {
        ...tag,
      })
      console.log(response)
      if (response.data.success)
        context.commit('ADD_COMPANY_TAG', response.data.data)

      //Evento socket.io
      const current_company = context.getters.company
      const payload_socket = {
        idCompany: current_company._id,
      }
      this._vm.$socket.emit('server:company:settings', payload_socket)
      return response.data
    } catch (error) {
      console.error('[companyModule][addNewMailbox] error-', error)
      return error.response.data
    }
  },
  /**
   * Agregar etiqueta
   * @param {*} context
   * @param {}
   * @returns
   */
  async updateCompanyTag(
    context,
    { tagId, title, color, allLines = true, linesIds = [] }
  ) {
    try {
      const tag = {
        title,
        color,
        allLines,
        linesIds,
      }
      if (!tag.title) return
      const url = `${vari.UHR}/tags/${tagId}`

      const response = await axios.put(url, {
        ...tag,
      })
      if (response.data.success)
        context.commit('UPDATE_COMPANY_TAG', { tagId, tag: response.data.data })

      //Evento socket.io
      const current_company = context.getters.company
      const payload_socket = {
        idCompany: current_company._id,
      }
      this._vm.$socket.emit('server:company:settings', payload_socket)

      return response.data
    } catch (error) {
      console.error('[companyModule][addNewMailbox] error-', error)
      return error.response.data
    }
  },
  /**
   * Elimina una etiqueta
   * @param {*} context
   * @param {String} tagId
   * @returns
   */
  async deleteCompanyTag(context, tagId) {
    try {
      const url = `${vari.UHR}/tags/${tagId}`
      const response = await axios.delete(url)

      if (response.data.success) {
        context.commit('REMOVE_COMPANY_TAG', tagId)

        //Evento socket.io
        const current_company = context.getters.company
        const payload_socket = {
          idCompany: current_company._id,
        }
        this._vm.$socket.emit('server:company:settings', payload_socket)
      }
      return response.data
    } catch (error) {
      console.error('[companyModule][addNewMailbox] error-', error)
      return error.response.data
    }
  },
  /**
   * Obtiene las etiquetas con datos básicos
   * @param {Object} args
   * @param {String} args.search
   */
  async getSimpleTags(context, { search = '' }) {
    try {
      const params = {
        search,
      }
      const response = await axios.get(`${vari.UHR}/tags/simply`, { params })
      context.commit('SET_SIMPLE_TAGS', response.data.data)
    } catch (err) {
      console.error(err)
    }
  },
}

export default { state, getters, mutations, actions }
