var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-type',{attrs:{"visible":_vm.visible,"title":_vm.title,"description":_vm.description,"type":"delete","on-ok":_vm.handleDeleteCompany,"ok-disabled":_vm.disabledOk,"message-success":"Se eliminó exitosamente"},on:{"onClose":_vm.handleCloseModal}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[(_vm.isCurrentCompany)?[_c('p',[_vm._v(" Te encuentras en la subcuenta a eliminar. Selecciona la subcuenta a la cual te moverás. ")]),_c('a-form-item',{attrs:{"label":"Seleccionar subcuenta"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]),expression:"[\n            'company',\n            {\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona aquí"},on:{"change":_vm.hasErrorInForm}},_vm._l((_vm.simple_companies),function(simpleCompany){return _c('a-select-option',{key:simpleCompany._id},[_vm._v(" "+_vm._s(simpleCompany.name)+" ")])}),1)],1)]:_vm._e(),_c('p',[_vm._v(" Ingresa tu contraseña con la que iniciaste sesión para validar el eliminado. ")]),_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              { required: true, message: 'Este campo es requerido' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              { required: true, message: 'Este campo es requerido' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí..."},on:{"input":_vm.hasErrorInForm}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }