import dashboardModule from './dashboardModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo de Dashboard */
  resetMoDashboard(context) {
    context.commit('RESET_DASHBOARD_MODULE')
  },
}
const modules = {
  dashboardModule,
}

export default {
  modules,
  actions,
}
