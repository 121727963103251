<template>
  <div
    class="cursor-pointer location"
    title="Click para abrir en el mapa"
    @click="goLocation"
  >
    <img src="@/app/tickets/assets/placeholder-map.jpg" :alt="name" />
    <p class="link_text2">
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'LocationMessage',
  props: {
    name: {
      type: String,
      required: false,
      default: 'Ubicación',
    },
    location: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * @return {String} nombre de la ubicacion
     */
    nameLocation() {
      return this.name && this.name.length ? this.name : 'Ubicación'
    },
  },
  methods: {
    /**
     * Ir a la ubicacion
     */
    goLocation() {
      window.open(
        `https://maps.google.com/?q=${this.location.latitude},${this.location.longitude}`
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.location
  width: 205px
  img
    width: 100%
    object-fit: cover
    height: 100px
    border-radius: 16px
  p
    line-height: 20px
    text-align: left
    margin-bottom: 8px
</style>
