const mutations = {
  SET_MASTER_SETTINGS(state, settings) {
    state.master_settings = settings
  },
  UPDATE_MASTER_SETTINGS(state, settings) {
    state.master_settings.agentMode = settings.agentMode
    state.master_settings.defaultCompany = settings.defaultCompany
  },
  SET_MASTERS(state, masters) {
    state.masters = masters
  },
}

export default mutations
