<template>
  <div class="section">
    <!--Filtros-->
    <template v-if="permissionsModule.filter">
      <a-row class="full-width text--left">
        <p class="mrg-bottom-4">Subcuentas</p>
        <a-select
          default-value="all"
          class="section__selector"
          placeholder="Selecciona una subcuenta"
          @change="handleChangeCompany"
        >
          <a-select-option value="all"> Todas las subcuentas </a-select-option>
          <a-select-option v-if="company" :value="company._id">
            {{ company.name }}
          </a-select-option>
          <a-select-option
            v-for="simpleCompany in simple_companies"
            :key="simpleCompany._id"
          >
            {{ simpleCompany.name }}
          </a-select-option>
        </a-select>
      </a-row>

      <a-divider class="section__divider" />
    </template>
    <!--Listado de notificaciones-->
    <div class="section__list scrollbar_basic" :style="`height: ${heightList}`">
      <h6 v-if="currentNotifications.length > 0" class="heading-h6">
        Recientes
      </h6>
      <a-list
        v-if="currentNotifications.length > 0"
        item-layout="horizontal"
        :data-source="currentNotifications"
        class="list"
        :locale="{ emptyText: 'No hay notificaciones' }"
      >
        <a-list-item
          slot="renderItem"
          slot-scope="notification"
          class="list-item"
          :class="{
            'last-list-item': oldNotification._id === notification._id,
            'list-item__new': notification.new,
          }"
          @mouseenter="handleExecuteTimeout"
          @mouseleave="handleClearTimeout(notification)"
        >
          <a-list-item-meta>
            <template slot="description">
              <p class="body-1 text-align-left">
                <strong class="heading-h8 text-name">
                  {{ notification.user.fullName }}
                </strong>
                {{
                  notification.message
                    ? notification.message.es
                    : 'Sin comentarios'
                }}
              </p>
              <p
                v-if="permissionsModule.show_company"
                class="body-1 text--left mrg-bottom-4"
              >
                <icon-case class="mrg-right-4" />
                <span
                  >Subcuenta:
                  {{
                    nameCompany(notification.userTo.company.companyId).name
                  }}</span
                >
              </p>
              <p class="body-1 text-align-left date">
                {{ notification.date }}
              </p>
            </template>
            <a-avatar
              v-if="notification.user.avatar"
              slot="avatar"
              :src="notification.user.avatar"
              :alt="`Avatar del usuario ${notification.user.fullName}`"
            />
            <DefaultIconLetter
              v-else
              slot="avatar"
              :names="notification.user.fullName"
              :alt="`Avatar con las iniciales de  ${notification.user.fullName}`"
            />
          </a-list-item-meta>
          <a-tag v-if="notification.new" color="volcano">Nuevo</a-tag>
        </a-list-item>
      </a-list>
      <h6 class="heading-h6">Pasados</h6>
      <a-list
        item-layout="horizontal"
        :data-source="pastNotifications"
        class="list"
        :loading="isLoading"
        :locale="{ emptyText: 'No hay notificaciones' }"
      >
        <a-list-item
          slot="renderItem"
          slot-scope="notification"
          class="list-item"
          :class="{
            'last-list-item': oldNotification._id === notification._id,
          }"
        >
          <a-list-item-meta>
            <template slot="description">
              <p class="body-1 text-align-left">
                <strong class="heading-h8 text-name">
                  {{ notification.user.fullName }}
                </strong>
                {{
                  notification.message
                    ? notification.message.es
                    : 'Sin comentarios'
                }}
              </p>
              <p
                v-if="permissionsModule.show_company"
                class="body-1 text--left mrg-bottom-4"
              >
                <icon-case class="mrg-right-4" />
                <span
                  >Subcuenta:
                  {{
                    nameCompany(notification.userTo.company.companyId).name
                  }}</span
                >
              </p>
              <p class="body-1 text-align-left date">
                {{ notification.date }}
              </p>
            </template>
            <a-avatar
              v-if="notification.user.avatar"
              slot="avatar"
              :src="notification.user.avatar"
            />
            <DefaultIconLetter
              v-else
              slot="avatar"
              :names="notification.user.fullName"
            />
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IconCase from '@/app/shared/components/icons/IconCase'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import moment from 'moment'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'ListNotifications',
  mixins: [attemptMixin, compareMixin],
  components: {
    DefaultIconLetter,
    IconCase,
  },
  props: {
    isLoading: {
      type: Boolean,
      require: false,
      default: false,
    },
    morePaginate: {
      type: Boolean,
      require: false,
      default: false,
    },
    notifications: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data: () => ({
    moment,
    lastChild: null,
    observer: null,
    oldNotification: null,
    readyToRead: false,
  }),
  mounted() {
    this.setLastChild()
  },
  computed: {
    ...mapGetters(['simple_companies', 'company', 'profile', 'permissions']),

    /**Notificaciones de las ultimas 24 horas */
    currentNotifications() {
      // vetifica si hay alguna notificación de 24 horas
      const thereAnyCurrent = this.notifications.some(
        (notification) => this.diffTime(notification.created_at) === 0
      )
      if (!thereAnyCurrent) return []
      const newNotifications = []
      this.notifications.map((notification) => {
        this.isMoreOld(notification)
        const diffTime = this.diffTime(notification.created_at)
        if (diffTime === 0) {
          notification.date = moment(notification.created_at)
            .startOf()
            .fromNow()
          notification.isCurrent = true
          newNotifications.push(notification)
        }
      })
      // evitar que se repitan
      const hash = {}

      return newNotifications.filter((current) => {
        const exists = !hash[current._id]
        hash[current._id] = true
        return exists
      })
    },
    /**Notificaciones pasadas */
    pastNotifications() {
      const newNotifications = []
      this.notifications.map((notification) => {
        this.isMoreOld(notification)
        const diffTime = moment(new Date()).diff(
          notification.created_at,
          'days'
        )
        if (diffTime > 0) {
          notification.date = moment(notification.created_at).calendar()
          notification.isCurrent = false
          newNotifications.push(notification)
          if (diffTime > 7)
            notification.date = moment(notification.created_at).format('LLL')
        }
      })
      // evitar que se repitan
      const hash = {}

      return newNotifications.filter((current) => {
        const exists = !hash[current._id]
        hash[current._id] = true
        return exists
      })
    },
    /**
     * Permisos del modulo
     * @return {Object}
     */
    permissionsModule() {
      if (!this.profile) return false
      const name = this.$route.name
      return this.permissions && this.permissions[name]
        ? this.permissions[name]
        : { filter: false, show_company: false }
    },
    /**
     * @return {Boolean} muestra o no los filtros por empresa
     */
    showFilters() {
      return this.permissionsModule && this.permissionsModule.filter
    },
    heightList() {
      const heightDefault = 'calc( 100vh - 120px )'
      const heightFilters = '116px'
      const heightWithFilters = `calc( calc( 100vh - 110px ) - ${heightFilters})`
      return this.showFilters ? heightWithFilters : heightDefault
    },
  },
  methods: {
    ...mapActions(['readNewNotification']),
    ...mapMutations(['REMOVE_NEW_NOTIFICATIONS']),

    setLastChild() {
      this.intervalAttempt(() => {
        const childs = document.getElementsByClassName('last-list-item')
        this.lastChild = childs[0]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 1.0,
        })
        this.observer.observe(this.lastChild)
      })
    },
    /**
     * Verifica si primer elemento cumple con las condiciones dadas
     * @param {Array} entries - elementos del DOM
     * @param {Object} entries[].target
     * @param {String} entries[].target.className
     * @param {String} entries[].isIntersecting - si el elemento es visible en el DOM
     */
    handleBottomReached(entries) {
      const entry = entries[0]
      if (
        entry.target.className.includes('last-list-item') &&
        entry.isIntersecting &&
        this.morePaginate &&
        !this.isLoading
      ) {
        this.$emit('onBottomReached')
      }
    },
    /**
     * Verifica si una notificacion es mas antigua que otra
     * @param {Object} notification
     * @param {Date} notification.created_at
     *
     */
    isMoreOld(notification) {
      const diffTimeNewNotification = moment(new Date()).diff(
        notification.created_at
      )
      if (!this.oldNotification) this.oldNotification = notification
      else {
        const diffTimeOldNotification = moment(new Date()).diff(
          this.oldNotification.created_at
        )
        if (diffTimeNewNotification > diffTimeOldNotification)
          this.oldNotification = notification
      }
    },
    /**
     * Actualiza la notificación como leida
     * @param {Object} notification
     * @param {String} notification._id
     * @param {Boolean} notification.new
     */
    async handleReadNotification(notification) {
      if (notification.new) {
        await this.readNewNotification(notification._id)
      }
    },
    handleExecuteTimeout() {
      setTimeout(() => {
        this.readyToRead = true
      }, 2000)
    },
    handleClearTimeout(notification) {
      this.readyToRead = false
      if (notification.new) {
        this.handleReadNotification(notification)
      }
    },
    /**
     * Retorna el nombre de la empresa
     * @param {String} companyId
     */
    nameCompany(companyId) {
      const companies = [...this.simple_companies, this.company]
      return companies.find((simpleCompany) => simpleCompany._id === companyId)
    },
    /**
     * Escucha el cambio de empresas
     */
    handleChangeCompany(companyId) {
      this.$emit('onChangeCompany', companyId, () => {
        this.oldNotification = null
        this.lastChild = null
        this.observer = null
        this.readyToRead = false
        this.setLastChild()
      })
    },
    diffTime(createdAt) {
      const diff = moment(new Date()).diff(createdAt, 'days')
      return diff
    },
  },
}
</script>
<style lang="sass" scoped>
$height_section: calc( 100vh - 92px )
.text-align-left
  text-align: left
.section
  background-color: $white_000
  border-radius: 8px
  border: 1px solid $gray_5
  padding: 20px 24px
  height: $height_section
  overflow: hidden
  h6
    text-align: left
  &__selector
    width: 19em
  &__divider
    margin: 16px 0px
  &__list
    overflow: auto
    margin-right: -20px
    padding-right: 20px
.list-item
  color: $gray_dark_900
  align-items: flex-start
  padding: 12px 8px
  .ant-list-item-meta
    &-content
      .ant-list-item-meta-description
        color: $gray_dark_900
        p
          margin-bottom: 0px
  &__new
    background-color: $volcano_1
.date
  color: $gray_dark_700
</style>
