import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const initialState = () => ({
  company_blacklist: [],
  paginate_blacklist: { page: 1, total: 0 },
})

const state = initialState

const getters = {
  company_blacklist: (state) => state.company_blacklist,
  paginate_blacklist: (state) => state.paginate_blacklist,
}

const mutations = {
  /**
   * Añadir un número a la lista negra
   * @param {*} state
   * @param {Object} blacklist
   * @param {String} blacklist.channel
   * @param {String[]} blacklist.channelsId
   * @param {String} blacklist.apiClientId
   * @param {String} blacklist.reason
   */
  ADD_COMPANY_BLACKLIST(state, blacklist) {
    state.company_blacklist.push(blacklist)
  },
  /**
   * Setea los números de la lista negra
   * @param {*} state
   * @param {Object[]} blacklist
   */
  SET_COMPANY_BLACKLIST(state, blacklist) {
    state.company_blacklist = blacklist
  },
  /**
   * Setea la paginacion de la lista negra
   * @param {*} state
   * @param {Object} pagination
   */
  SET_PAGINATE_BLACKLIST(state, pagination) {
    state.paginate_blacklist = pagination
  },
  /**
   * Actualiza el total de números de la lista negra
   * @param {*} state
   * @param {Number} total
   */
  UPDATE_TOTAL_BLACKLIST(state, { total, resetPage = false }) {
    state.paginate_blacklist.total = total
    // si se resetea la pagina
    if (resetPage)
      state.paginate_blacklist.page = state.paginate_blacklist.page - 1
  },
  /**
   * Actualiza un número de la lista negra
   * @param {*} state
   * @param {Object} args
   * @param {String} args.blacklistId
   * @param {String} args.blacklist
   */
  UPDATE_COMPANY_BLACKLIST(state, { blacklistId, blacklist }) {
    const indexBlacklist = state.company_blacklist.findIndex(
      (item) => item._id === blacklistId
    )
    if (indexBlacklist === -1) return
    state.company_blacklist.splice(indexBlacklist, 1, blacklist)
  },
  /**
   * Remueve un número de la lista negra
   * @param {*} state
   * @param {String} blacklistId
   */
  REMOVE_COMPANY_BLACKLIST(state, blacklistId) {
    state.company_blacklist = state.company_blacklist.filter(
      (number) => number._id !== blacklistId
    )
  },
}

const actions = {
  /**
   * Agrega un número a la lista negra
   * @param {*} context
   * @param {Object} blacklist
   * @param {String} blacklist.channel
   * @param {String[]} blacklist.channelsId
   * @param {String} blacklist.apiClientId
   * @param {String} blacklist.reason
   * @param {String} blacklist.countryId
   * @returns
   */
  async addNumberToBlacklist(
    context,
    { channel, channelsId, apiClientId, reason, countryId }
  ) {
    try {
      const params = { channel, channelsId, apiClientId, reason, countryId }
      const response = await axios.post(`${vari.UHR}/black-lists`, params)
      if (response.data.success) {
        context.commit('ADD_COMPANY_BLACKLIST', response.data.data)

        const total = context.getters.paginate_blacklist.total
        context.commit('UPDATE_TOTAL_FAST_ANSWERS', total + 1)
        // si en la pagina ya no hay mas items vuelve a traer datos
        if (!context.getters.paginate_blacklist.length) {
          const page = context.getters.paginate_blacklist.page - 1
          context.dispatch('getBlacklist', {
            page,
          })
        }
      }
      return response.data
    } catch (err) {
      console.error(err)
      return err.response?.data
    }
  },
  /**
   * Edita un número de la lista negra
   * @param {*} context
   * @param {Object} blacklist
   * @param {String} blacklist.blacklistId
   * @param {String} blacklist.channel
   * @param {String[]} blacklist.channelsId
   * @param {String} blacklist.apiClientId
   * @param {String} blacklist.reason
   * @param {String} blacklist.countryId
   * @returns
   */
  async updateNumberToBlacklist(
    context,
    { blacklistId, channel, channelsId, apiClientId, reason, countryId }
  ) {
    try {
      const params = { channel, channelsId, apiClientId, reason, countryId }
      const response = await axios.put(
        `${vari.UHR}/black-lists/${blacklistId}`,
        params
      )
      if (response.data.success) {
        context.commit('UPDATE_COMPANY_BLACKLIST', {
          blacklistId,
          blacklist: response.data.data,
        })
      }
      return response.data
    } catch (err) {
      console.error(err)
      return err.response?.data
    }
  },
  /**
   * Desbloquear un número de la lista negra
   * @param {*} context
   * @param {String} blacklistId
   * @returns
   */
  async unBlockNumberInBlacklist(context, blacklistId) {
    try {
      const response = await axios.put(
        `${vari.UHR}/black-lists/unblock/${blacklistId}`
      )
      context.commit('REMOVE_COMPANY_BLACKLIST', blacklistId)
      return response.data
    } catch (err) {
      console.error(err)
      return err.response?.data
    }
  },
  /**
   * Obtiene la lista negra
   * @param {*} context
   * @param {Number} args.page
   * @param {Number} args.limit
   * @param {String} args.search
   * @param {String} args.channelId
   */
  async getBlacklist(
    context,
    { page = 1, limit = 10, search, channelId = 'all' }
  ) {
    try {
      const params = {
        page,
        limit,
        search,
        channelId,
      }
      const response = await axios.get(`${vari.UHR}/black-lists/`, { params })
      context.commit('SET_COMPANY_BLACKLIST', response.data.data.data)
      const pagination = { ...response.data.data }
      delete pagination.data
      context.commit('SET_PAGINATE_BLACKLIST', pagination)
    } catch (err) {
      console.error(err)
    }
  },
}

export default { state, getters, mutations, actions }
