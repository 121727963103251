<template>
  <div>
    <svg
      width="443"
      height="340"
      viewBox="0 0 443 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M244.993 289.419C179.064 289.419 125.618 235.984 125.618 170.069C125.618 104.154 179.064 50.7192 244.993 50.7192C310.921 50.7192 364.367 104.154 364.367 170.069C364.367 235.984 310.921 289.419 244.993 289.419Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
        stroke-dasharray="12.15"
      />
      <path
        opacity="0.6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M244.823 339.084C151.445 339.084 75.7468 263.401 75.7468 170.042C75.7468 76.6827 151.445 1 244.823 1C338.201 1 413.899 76.6827 413.899 170.042C413.899 263.401 338.201 339.084 244.823 339.084Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
        stroke-dasharray="12.15"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.6173 195.766L118.895 194.714L104.052 208.565L98.6173 195.766Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.6173 195.766L118.895 194.714L104.052 208.565L98.6173 195.766V195.766Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.0379 208.337L62.2895 199.743L75.9543 231.927L55.7027 240.52L42.0379 208.337V208.337Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.8668 192.779L63.8636 190.659L83.5853 237.106L78.588 239.226L58.8668 192.779Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.8668 192.779L63.8636 190.659L83.5853 237.106L78.588 239.226L58.8668 192.779Z"
        fill="#F0F2F5"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.4853 250.368L82.2368 242.411L105.911 298.168L87.1599 306.125L63.4853 250.368V250.368Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.0683 200.56L71.531 199.091L82.844 225.734L79.3807 227.203L68.0683 200.56V200.56Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.7826 203.784L81.8045 200.38L82.5696 202.182L74.5477 205.586L73.7826 203.784Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.7826 203.784L81.8045 200.38L82.5696 202.182L74.5477 205.586L73.7826 203.784V203.784Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.2835 219.095L88.3054 215.691L89.0705 217.493L81.0485 220.897L80.2835 219.095Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.2835 219.095L88.3054 215.691L89.0705 217.493L81.0485 220.897L80.2835 219.095V219.095Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.4365 238.376L72.8423 246.398L71.0403 247.162L67.6345 239.141L69.4365 238.376V238.376Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.4332 216.499L42.0377 208.337L55.7029 240.521L46.6313 238.162L37.4332 216.499V216.499Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.4332 216.499L31.7804 212.69L45.4456 244.874L46.6312 238.162L37.4332 216.499V216.499Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.1165 218.555L28.345 217.39L32.4193 226.985L35.5901 222.025L34.1165 218.555V218.555Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.3745 235.649L35.603 234.484L39.6773 244.079L42.8481 239.119L41.3745 235.649V235.649Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.4977 200.758L74.9084 199.735L78.2081 207.505L75.7971 208.529L72.4977 200.758Z"
        fill="#F0F2F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.4977 200.758L74.9084 199.735L78.2081 207.505L75.7971 208.529L72.4977 200.758V200.758Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M79.0165 216.111L81.4272 215.088L84.7268 222.858L82.3159 223.881L79.0165 216.111Z"
        fill="#F0F2F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M79.0165 216.111L81.4272 215.088L84.7268 222.858L82.3159 223.881L79.0165 216.111V216.111Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.4098 236.402L69.0105 234.874L70.6601 238.759L67.0593 240.287L65.4098 236.402Z"
        fill="#F5F5F5"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.8168 208.408C80.7219 194.054 81.6734 179.462 88.4759 172.891C89.6007 171.805 92.1779 169.983 94.2238 174.802C96.2691 179.62 114.134 221.693 115.997 226.082C117.861 230.47 115.09 231.832 113.529 231.887C104.074 232.216 92.9121 222.764 86.8168 208.408Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.8168 208.408C80.7219 194.054 81.6734 179.462 88.4759 172.891C89.6007 171.805 92.1779 169.983 94.2238 174.802C96.2691 179.62 114.134 221.693 115.997 226.082C117.861 230.47 115.09 231.832 113.529 231.887C104.074 232.216 92.9121 222.764 86.8168 208.408Z"
        fill="#F0F2F5"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.153 198.846L83.5637 197.823L92.1147 217.962L89.7039 218.985L81.153 198.846Z"
        fill="#F0F2F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.153 198.846L83.5637 197.823L92.1147 217.962L89.7039 218.985L81.153 198.846V198.846Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.73 197.045C119.274 197.662 117.594 196.983 116.976 195.528C116.359 194.073 117.037 192.392 118.492 191.775C119.948 191.157 121.628 191.836 122.246 193.291C122.864 194.747 122.186 196.427 120.73 197.045Z"
        fill="#A3B1BF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.6452 249.919L79.752 247.816L81.8559 246.923L82.7491 249.027L80.6452 249.919Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.0969 253.338L81.2036 251.235L83.3075 250.342L84.2007 252.446L82.0969 253.338Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.5484 256.757L82.6552 254.653L84.7591 253.761L85.6523 255.864L83.5484 256.757Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85 260.176L84.1068 258.072L86.2107 257.179L87.1039 259.283L85 260.176Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.4891 251.259L76.5959 249.155L78.6998 248.262L79.593 250.366L77.4891 251.259Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.9407 254.678L78.0475 252.574L80.1514 251.681L81.0446 253.785L78.9407 254.678Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.3924 258.096L79.4992 255.993L81.603 255.1L82.4963 257.204L80.3924 258.096Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.8439 261.515L80.9507 259.412L83.0546 258.519L83.9478 260.622L81.8439 261.515Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.333 252.598L73.4398 250.494L75.5437 249.602L76.4369 251.705L74.333 252.598Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.7846 256.017L74.8914 253.913L76.9953 253.02L77.8885 255.124L75.7846 256.017Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.2363 259.436L76.3431 257.332L78.447 256.439L79.3402 258.543L77.2363 259.436Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.6879 262.855L77.7946 260.751L79.8985 259.858L80.7917 261.962L78.6879 262.855Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.1769 253.937L70.2837 251.834L72.3876 250.941L73.2808 253.045L71.1769 253.937Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.6285 257.356L71.7353 255.253L73.8392 254.36L74.7324 256.463L72.6285 257.356Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.0802 260.775L73.187 258.671L75.2909 257.779L76.1841 259.882L74.0802 260.775Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.5318 264.194L74.6385 262.09L76.7424 261.197L77.6356 263.301L75.5318 264.194Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.0209 255.277L67.1276 253.173L69.2315 252.28L70.1248 254.384L68.0209 255.277Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.4725 258.696L68.5792 256.592L70.6831 255.699L71.5764 257.803L69.4725 258.696Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.9241 262.114L70.0309 260.011L72.1348 259.118L73.028 261.222L70.9241 262.114Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.3757 265.533L71.4825 263.429L73.5864 262.537L74.4796 264.64L72.3757 265.533Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.2476 267.824L87.3544 265.721L89.4582 264.828L90.3515 266.932L88.2476 267.824Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.6991 271.243L88.8059 269.139L90.9098 268.247L91.803 270.35L89.6991 271.243Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M91.1508 274.662L90.2576 272.558L92.3615 271.666L93.2547 273.769L91.1508 274.662Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.6024 278.081L91.7091 275.977L93.813 275.084L94.7063 277.188L92.6024 278.081Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.0915 269.164L84.1983 267.06L86.3021 266.167L87.1954 268.271L85.0915 269.164Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.5431 272.582L85.6499 270.479L87.7537 269.586L88.647 271.69L86.5431 272.582Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.9946 276.001L87.1014 273.898L89.2053 273.005L90.0985 275.109L87.9946 276.001Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.4463 279.42L88.5531 277.316L90.657 276.424L91.5502 278.527L89.4463 279.42Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.9354 270.503L81.0422 268.399L83.1461 267.507L84.0393 269.61L81.9354 270.503Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.387 273.922L82.4937 271.818L84.5976 270.925L85.4908 273.029L83.387 273.922Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.8385 277.341L83.9453 275.237L86.0492 274.344L86.9424 276.448L84.8385 277.341Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.2902 280.759L85.397 278.656L87.5009 277.763L88.3941 279.867L86.2902 280.759Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.7793 271.842L77.8861 269.739L79.99 268.846L80.8832 270.95L78.7793 271.842Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.2309 275.261L79.3377 273.157L81.4416 272.265L82.3348 274.368L80.2309 275.261Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.6825 278.68L80.7893 276.576L82.8932 275.683L83.7864 277.787L81.6825 278.68Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.1341 282.099L82.2409 279.995L84.3448 279.102L85.238 281.206L83.1341 282.099Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.6232 273.182L74.7299 271.078L76.8338 270.185L77.727 272.289L75.6232 273.182Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.0748 276.6L76.1816 274.497L78.2855 273.604L79.1787 275.708L77.0748 276.6Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.5264 280.019L77.6332 277.916L79.7371 277.023L80.6303 279.126L78.5264 280.019Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M79.978 283.438L79.0848 281.334L81.1887 280.442L82.0819 282.545L79.978 283.438Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.8499 285.729L94.9567 283.626L97.0606 282.733L97.9538 284.837L95.8499 285.729Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.3015 289.148L96.4083 287.044L98.5122 286.152L99.4054 288.255L97.3015 289.148Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.7531 292.567L97.8599 290.463L99.9638 289.57L100.857 291.674L98.7531 292.567Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100.205 295.986L99.3115 293.882L101.415 292.989L102.309 295.093L100.205 295.986Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.6938 287.069L91.8006 284.965L93.9044 284.072L94.7977 286.176L92.6938 287.069Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M94.1454 290.487L93.2522 288.384L95.3561 287.491L96.2493 289.595L94.1454 290.487Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.597 293.906L94.7037 291.803L96.8076 290.91L97.7009 293.013L95.597 293.906Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.0486 297.325L96.1554 295.221L98.2593 294.329L99.1525 296.432L97.0486 297.325Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.5377 288.408L88.6445 286.304L90.7483 285.411L91.6416 287.515L89.5377 288.408Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.9893 291.827L90.0961 289.723L92.2 288.83L93.0932 290.934L90.9893 291.827Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.4409 295.246L91.5477 293.142L93.6516 292.249L94.5448 294.353L92.4409 295.246Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M93.8926 298.664L92.9993 296.561L95.1032 295.668L95.9965 297.772L93.8926 298.664Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.3816 289.747L85.4884 287.644L87.5922 286.751L88.4855 288.854L86.3816 289.747Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.8332 293.166L86.94 291.062L89.0439 290.17L89.9371 292.273L87.8332 293.166Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.2848 296.585L88.3916 294.481L90.4955 293.588L91.3887 295.692L89.2848 296.585Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.7364 300.004L89.8432 297.9L91.9471 297.007L92.8403 299.111L90.7364 300.004Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.2255 291.087L82.3323 288.983L84.4362 288.09L85.3294 290.194L83.2255 291.087Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.6771 294.505L83.7839 292.402L85.8877 291.509L86.781 293.613L84.6771 294.505Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.1287 297.924L85.2355 295.82L87.3394 294.928L88.2326 297.031L86.1287 297.924Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.5803 301.343L86.6871 299.239L88.791 298.346L89.6842 300.45L87.5803 301.343Z"
        fill="#A3B1BF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.0042 199.675L49.5984 191.654L47.7964 192.419L51.2022 200.44L53.0042 199.675V199.675Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.6279 203.943L55.2287 202.415L53.5792 198.53L49.9784 200.058L51.6279 203.943Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.6279 203.943L55.2287 202.415L53.5792 198.53L49.9784 200.058L51.6279 203.943Z"
        fill="white"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M52.4847 183.596L53.3779 185.7L55.4818 184.807L54.5886 182.703L52.4847 183.596Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.0331 180.177L51.9263 182.281L54.0302 181.388L53.137 179.285L51.0331 180.177Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.5815 176.758L50.4747 178.862L52.5786 177.969L51.6854 175.866L49.5815 176.758Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1299 173.34L49.0231 175.443L51.127 174.551L50.2338 172.447L48.1299 173.34Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.3286 184.935L50.2218 187.039L52.3257 186.146L51.4325 184.043L49.3286 184.935Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.877 181.517L48.7703 183.62L50.8741 182.728L49.9809 180.624L47.877 181.517Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.4254 178.098L47.3186 180.201L49.4225 179.309L48.5293 177.205L46.4254 178.098Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.9738 174.679L45.867 176.783L47.9709 175.89L47.0777 173.786L44.9738 174.679Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.1725 186.275L47.0658 188.378L49.1696 187.486L48.2764 185.382L46.1725 186.275Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.7209 182.856L45.6142 184.96L47.718 184.067L46.8248 181.963L44.7209 182.856Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.2693 179.437L44.1625 181.541L46.2664 180.648L45.3732 178.544L43.2693 179.437Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.8177 176.018L42.7109 178.122L44.8148 177.229L43.9216 175.126L41.8177 176.018Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.0164 187.614L43.9096 189.718L46.0135 188.825L45.1203 186.721L43.0164 187.614Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.5648 184.195L42.458 186.299L44.5619 185.406L43.6687 183.302L41.5648 184.195Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.1132 180.776L41.0064 182.88L43.1103 181.987L42.2171 179.884L40.1132 180.776Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.6616 177.358L39.5549 179.461L41.6587 178.569L40.7655 176.465L38.6616 177.358Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.8603 188.953L40.7536 191.057L42.8574 190.164L41.9642 188.061L39.8603 188.953Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.4087 185.535L39.3019 187.638L41.4058 186.745L40.5126 184.642L38.4087 185.535Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.9571 182.116L37.8503 184.219L39.9542 183.327L39.061 181.223L36.9571 182.116Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5054 178.697L36.3987 180.801L38.5026 179.908L37.6093 177.804L35.5054 178.697Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.8823 165.691L45.7756 167.795L47.8795 166.902L46.9862 164.798L44.8823 165.691Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.4308 162.272L44.324 164.376L46.4279 163.483L45.5347 161.38L43.4308 162.272Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.9792 158.854L42.8724 160.957L44.9763 160.065L44.0831 157.961L41.9792 158.854Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.5275 155.435L41.4208 157.538L43.5247 156.646L42.6314 154.542L40.5275 155.435Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.7262 167.031L42.6195 169.134L44.7234 168.241L43.8301 166.138L41.7262 167.031Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.2747 163.612L41.1679 165.715L43.2718 164.823L42.3786 162.719L40.2747 163.612Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.823 160.193L39.7162 162.297L41.8201 161.404L40.9269 159.3L38.823 160.193Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.3714 156.774L38.2646 158.878L40.3685 157.985L39.4753 155.881L37.3714 156.774Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.5701 168.37L39.4634 170.474L41.5673 169.581L40.674 167.477L38.5701 168.37Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.1186 164.951L38.0118 167.055L40.1157 166.162L39.2225 164.058L37.1186 164.951Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.6669 161.532L36.5602 163.636L38.6641 162.743L37.7708 160.639L35.6669 161.532Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.2153 158.113L35.1086 160.217L37.2125 159.324L36.3192 157.221L34.2153 158.113Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.4141 169.709L36.3073 171.813L38.4112 170.92L37.518 168.816L35.4141 169.709Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9625 166.29L34.8557 168.394L36.9596 167.501L36.0664 165.398L33.9625 166.29Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.5108 162.872L33.4041 164.975L35.508 164.082L34.6147 161.979L32.5108 162.872Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.0592 159.453L31.9525 161.556L34.0564 160.664L33.1631 158.56L31.0592 159.453Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.258 171.048L33.1512 173.152L35.2551 172.259L34.3619 170.156L32.258 171.048Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8064 167.63L31.6996 169.733L33.8035 168.841L32.9103 166.737L30.8064 167.63Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.3547 164.211L30.248 166.315L32.3519 165.422L31.4586 163.318L29.3547 164.211Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.9032 160.792L28.7964 162.896L30.9003 162.003L30.0071 159.899L27.9032 160.792Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.28 147.786L38.1733 149.89L40.2772 148.997L39.3839 146.894L37.28 147.786Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.8284 144.367L36.7216 146.471L38.8255 145.578L37.9323 143.475L35.8284 144.367Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3768 140.949L35.27 143.052L37.3739 142.16L36.4807 140.056L34.3768 140.949Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.9252 137.53L33.8185 139.634L35.9224 138.741L35.0291 136.637L32.9252 137.53Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.8185 139.634L35.9224 138.741L35.0291 136.637L32.9252 137.53L33.8185 139.634Z"
        fill="#B2BECA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.124 149.126L35.0172 151.229L37.1211 150.337L36.2279 148.233L34.124 149.126Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.6723 145.707L33.5655 147.811L35.6694 146.918L34.7762 144.814L32.6723 145.707Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.2207 142.288L32.114 144.392L34.2179 143.499L33.3246 141.395L31.2207 142.288Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.7691 138.869L30.6623 140.973L32.7662 140.08L31.873 137.976L29.7691 138.869Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.9678 150.465L31.861 152.569L33.9649 151.676L33.0717 149.572L30.9678 150.465Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.5162 147.046L30.4094 149.15L32.5133 148.257L31.6201 146.153L29.5162 147.046Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.0646 143.627L28.9579 145.731L31.0618 144.838L30.1685 142.735L28.0646 143.627Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.613 140.208L27.5062 142.312L29.6101 141.419L28.7169 139.316L26.613 140.208Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.8117 151.804L28.7049 153.908L30.8088 153.015L29.9156 150.911L27.8117 151.804Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.3602 148.385L27.2534 150.489L29.3573 149.596L28.464 147.493L26.3602 148.385Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.9086 144.967L25.8018 147.07L27.9057 146.178L27.0125 144.074L24.9086 144.967Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.4569 141.548L24.3501 143.652L26.454 142.759L25.5608 140.655L23.4569 141.548Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.6557 153.144L25.5489 155.247L27.6528 154.354L26.7595 152.251L24.6557 153.144Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2041 149.725L24.0973 151.828L26.2012 150.936L25.3079 148.832L23.2041 149.725Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7524 146.306L22.6456 148.41L24.7495 147.517L23.8563 145.413L21.7524 146.306Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3008 142.887L21.194 144.991L23.2979 144.098L22.4047 141.994L20.3008 142.887Z"
        fill="#A3B1BF"
        fill-opacity="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128.976 189.804C127.35 185.974 126.686 182.592 127.494 182.249C128.302 181.906 130.275 184.733 131.902 188.563C133.527 192.393 134.191 195.775 133.383 196.118C132.575 196.461 130.602 193.634 128.976 189.804Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128.976 189.804C127.35 185.974 126.686 182.592 127.494 182.249C128.302 181.906 130.275 184.733 131.902 188.563C133.527 192.393 134.191 195.775 133.383 196.118C132.575 196.461 130.602 193.634 128.976 189.804Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M135.406 187.075C132.157 179.423 130.831 172.664 132.446 171.979C134.06 171.294 138.002 176.942 141.251 184.595C144.501 192.248 145.827 199.007 144.212 199.692C142.598 200.377 138.656 194.729 135.406 187.075Z"
        fill="#F5F5F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M135.406 187.075C132.157 179.423 130.831 172.664 132.446 171.979C134.06 171.294 138.002 176.942 141.251 184.595C144.501 192.248 145.827 199.007 144.212 199.692C142.598 200.377 138.656 194.729 135.406 187.075Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145.789 182.669C141.435 172.414 139.658 163.356 141.822 162.438C143.985 161.52 149.268 169.09 153.623 179.345C157.977 189.601 159.754 198.659 157.591 199.577C155.427 200.495 150.144 192.925 145.789 182.669Z"
        fill="#F5F5F5"
        fill-opacity="0.35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145.789 182.669C141.435 172.414 139.658 163.356 141.822 162.438C143.985 161.52 149.268 169.09 153.623 179.345C157.977 189.601 159.754 198.659 157.591 199.577C155.427 200.495 150.144 192.925 145.789 182.669Z"
        stroke="#A3B1BF"
        stroke-width="1.62"
        stroke-linejoin="round"
      />
      <path
        opacity="0.85"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.8896 86.6709C63.2186 85.915 61.0634 85.8723 60.7801 86.6508C60.4964 87.4302 62.1788 88.7848 64.7155 89.9235C63.9845 92.5375 63.932 94.6256 64.6726 94.8952C65.4132 95.1648 66.7152 93.5314 67.8355 91.0591C70.5106 91.8174 72.6702 91.8611 72.9539 91.0817C73.2372 90.3032 71.5588 88.9506 69.0268 87.8127C69.7898 85.1431 69.8554 82.995 69.1035 82.7214C68.3516 82.4477 67.0212 84.1354 65.8896 86.6709Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.776 329.505L118.425 329.891C117.522 330.884 115.985 330.957 114.992 330.054C114.597 329.695 114.331 329.215 114.236 328.689L114.143 328.177L113.669 327.964C112.446 327.412 111.901 325.974 112.452 324.75C112.672 324.263 113.046 323.861 113.517 323.608L113.975 323.362L114.032 322.844C114.179 321.51 115.379 320.547 116.713 320.694C117.244 320.752 117.741 320.984 118.127 321.353L118.504 321.714L119.013 321.608C120.327 321.335 121.614 322.179 121.887 323.493C121.995 324.016 121.929 324.561 121.696 325.042L121.471 325.51L121.729 325.962C122.394 327.128 121.989 328.612 120.824 329.277C120.36 329.543 119.822 329.647 119.292 329.575L118.776 329.505Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M432.965 120.366C431.88 121.011 430.526 120.132 430.674 118.879C429.725 118.046 430.143 116.487 431.381 116.24C431.88 115.08 433.492 114.996 434.109 116.097C435.366 116.213 435.945 117.72 435.088 118.647C435.367 119.879 434.112 120.894 432.965 120.366Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.2602 38.874L97.427 39.0019C95.4363 39.3075 93.9593 37.1978 94.9275 35.4318L95.3323 34.6935L94.9537 33.942C94.0475 32.1432 95.5975 30.0861 97.5765 30.4613L98.4038 30.6181L99.0026 30.0249C100.433 28.6076 102.868 29.446 103.123 31.4437L103.23 32.2798L103.978 32.6656C105.769 33.5883 105.724 36.1636 103.902 37.0232L103.141 37.3823L103.006 38.2133C102.681 40.2011 100.219 40.9542 98.838 39.4878L98.2602 38.874Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M396.631 306.109L396.098 305.973C394.798 305.643 394.011 304.32 394.342 303.019C394.474 302.501 394.773 302.041 395.192 301.71L395.623 301.37L395.588 300.823C395.5 299.484 396.515 298.327 397.854 298.24C398.387 298.205 398.917 298.347 399.362 298.644L399.818 298.949L400.329 298.745C401.576 298.248 402.989 298.856 403.486 300.103C403.684 300.599 403.713 301.147 403.568 301.661L403.419 302.191L403.77 302.613C404.628 303.645 404.486 305.177 403.454 306.035C403.043 306.376 402.531 306.573 401.997 306.594L401.449 306.616L401.156 307.08C400.439 308.215 398.939 308.554 397.804 307.838C397.352 307.552 397.007 307.126 396.822 306.625L396.631 306.109Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M440.263 146.659C439.292 147.466 437.817 146.81 437.767 145.548C436.699 144.875 436.868 143.269 438.052 142.832C438.364 141.608 439.943 141.273 440.725 142.264C441.985 142.182 442.792 143.58 442.091 144.629C442.558 145.802 441.478 147.001 440.263 146.659Z"
        fill="#A3B1BF"
        fill-opacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M244.622 220.978C277.159 220.978 303.536 194.607 303.536 162.076C303.536 129.546 277.159 103.174 244.622 103.174C212.084 103.174 185.708 129.546 185.708 162.076C185.708 194.607 212.084 220.978 244.622 220.978Z"
        fill="#A3B1BF"
        fill-opacity="0.66"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M245.346 141.425C241.147 141.425 237.881 142.707 235.548 145.272C233.215 147.72 232.107 150.984 232.107 155.064H236.714C236.714 152.208 237.356 149.993 238.639 148.361C240.039 146.437 242.196 145.505 245.112 145.505C247.562 145.505 249.486 146.146 250.828 147.545C252.111 148.827 252.811 150.634 252.811 152.965C252.811 154.597 252.227 156.113 251.061 157.57C250.711 158.036 250.011 158.736 249.078 159.668C245.929 162.466 244.004 164.681 243.188 166.429C242.488 167.887 242.138 169.577 242.138 171.5V172.841H246.804V171.5C246.804 169.927 247.154 168.528 247.912 167.245C248.495 166.196 249.37 165.147 250.653 164.04C253.219 161.767 254.794 160.251 255.377 159.552C256.835 157.628 257.593 155.355 257.593 152.732C257.593 149.235 256.485 146.496 254.327 144.514C252.052 142.416 249.078 141.425 245.346 141.425ZM244.471 176.921C243.479 176.921 242.663 177.212 241.963 177.912C241.263 178.553 240.972 179.369 240.972 180.36C240.972 181.351 241.263 182.167 241.963 182.866C242.663 183.507 243.479 183.857 244.471 183.857C245.462 183.857 246.279 183.507 246.979 182.866C247.678 182.225 248.028 181.409 248.028 180.36C248.028 179.369 247.678 178.553 247.037 177.912C246.337 177.212 245.462 176.921 244.471 176.921Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M223.262 133.505C229.077 130.148 239.632 126.193 236.274 120.379C232.916 114.564 220.566 115.409 214.75 118.766C208.934 122.123 202.73 131.989 206.087 137.803C209.445 143.618 217.446 136.862 223.262 133.505Z"
        fill="#FAFAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M249.679 120.185C252.365 120.185 254.543 118.008 254.543 115.323C254.543 112.637 252.365 110.46 249.679 110.46C246.993 110.46 244.815 112.637 244.815 115.323C244.815 118.008 246.993 120.185 249.679 120.185Z"
        fill="#FAFAFA"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ImageError',
}
</script>
