import { mapActions, mapGetters } from 'vuex'
import {
  ACTIONS_TO_NODES,
  NODES,
  NODES_TO_SAVE_VARS,
} from '@/app/chatbots/utils/componentsDataNodes'
import addDefaultNodesMixin from './addDefaultNodes'

import {
  filterVarsToSaveOfParents,
  filterVarsToSaveOfChildren,
} from '@/app/chatbots/utils/generateTree'

export default {
  mixins: [addDefaultNodesMixin],
  data: () => ({
    ACTIONS_TO_NODES,
  }),
  computed: {
    ...mapGetters([
      'nodeSelected',
      'nodes',
      'selectedNodeId',
      'simple_chatbots_vars',
      'chatbot',
    ]),

    /**
     * Retorna el tipo de nodo
     * @returns {String}
     */
    typeNodeSelected() {
      if (!this.nodeSelected) return ''
      else {
        if (!this.nodeSelected.drawer) {
          const nodeParent = this.nodes.find(
            (node) => node._id === this.nodeSelected.parentNodeId
          )
          const structureParent = NODES[nodeParent.drawer.question.type]
          if (this.nodeSelected.is_subchild)
            return structureParent.child.child.type
          else return structureParent.child.type
        } else return this.nodeSelected.drawer.question.type
      }
    },
    /**
     * Acciones de acuerdo al nodo seleccionado
     * @returns {Object[]} actionsToNodeSelected
     * @returns {Object} actionsToNodeSelected.actionToSave
     * @returns {String} actionsToNodeSelected.actionToSave.nodeType
     * @returns {String} actionsToNodeSelected.actionToSave.type_action
     */
    actionsToNodeSelected() {
      const supportedChannels = this.chatbot.supportedChannels

      const actionsAllowed = this.ACTIONS_TO_NODES.filter(
        (action) =>
          action.allowed &&
          action.allowed_to_support_channels.includes(supportedChannels)
      )

      const actionsExcludeRollback = actionsAllowed.filter((actionAllowed) => {
        if (actionAllowed.action === 'rollback') return this.allowedRollback
        else return true
      })
      const actionsToNodeSelected = actionsExcludeRollback.filter(
        (actionAllowed) =>
          actionAllowed.allowed_to_nodes.includes(this.typeNodeSelected)
      )

      return actionsToNodeSelected.map((actionToNodeSelected) => {
        const action = actionToNodeSelected.action
        let actionToSave = {}

        switch (action) {
          case 'continue_options':
            {
              actionToSave.nodeType = 'options'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeOptions
              actionToNodeSelected.actionToSave = actionToSave
            }
            break
          case 'continue_message':
            {
              actionToSave.nodeType = 'message'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeMessage
              actionToNodeSelected.actionToSave = actionToSave
            }
            break
          case 'continue_catcher':
            {
              actionToSave.nodeType = 'catcher'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeCatcher

              const simpleVars = this.nodeSelected
                ? this.formatSimpleVars({
                    nodeId:
                      this.nodeSelected._id || this.nodeSelected.parentNodeId,
                    simpleVars: [...this.simple_chatbots_vars],
                  })
                : []

              const notUsedSimpleVars = simpleVars.filter(
                (simpleVar) => !simpleVar.isUsed
              )
              actionToSave.disabled = !notUsedSimpleVars.length
              actionToSave.varToAdd = !notUsedSimpleVars.length
                ? null
                : notUsedSimpleVars[0]._id

              actionToNodeSelected.actionToSave = actionToSave
            }
            break
          case 'continue_buttons':
            {
              actionToSave.nodeType = 'buttons'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeButtons
              actionToNodeSelected.actionToSave = actionToSave
            }
            break
          case 'continue_cards':
            {
              actionToSave.nodeType = 'cards'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeCards
              actionToNodeSelected.actionToSave = actionToSave
            }
            break
          case 'continue_lists':
            {
              actionToSave.nodeType = 'lists'
              actionToSave.type_action = 'continue'
              actionToSave.onAdd = this.addDefaultNodeLists
              actionToNodeSelected.actionToSave = actionToSave
            }
            break
        }
        return actionToNodeSelected
      })
    },
    /**
     * Permitir el regreso al menu anterior
     * @returns {Boolean}
     */
    allowedRollback() {
      const actionRollback = ACTIONS_TO_NODES.find(
        (action) => action.action === 'rollback'
      )
      if (!actionRollback?.allowed_to_nodes.includes(this.typeNodeSelected))
        return false

      const nodeTypes = ['options', 'buttons', 'cards', 'lists']
      const beforeNodes = this.nodesAboveCurrent({
        nodeTypes: nodeTypes,
        parentNodeId: this.nodeSelected.parentNodeId,
      })
      return beforeNodes.length > 0
    },
  },
  methods: {
    ...mapActions([
      'updateOption',
      'deleteButton',
      'deleteOption',
      'updateChild',
      'updateSubchild',
    ]),

    /**
     * Verifica si se debe añadir un nodo por defecto
     * @param {Object} args
     * @param {String} args.action
     * @param {String} args.nodeIdAction
     * @param {String} args.nodeTypeAction
     * @param {String} args.parentNodeId - si viene de un nodo padre
     * @param {Boolean} args.isChild - si viene de un nodo hijo
     * @param {String} args.nodeId - si viene de un nodo hijo, se le pasará el id del nodo
     * @param {Boolean} args.isSubchild - si viene de un hijo de otro
     * @returns
     */
    async verifyAddDefaultNode({
      action,
      nodeIdAction,
      nodeTypeAction,
      parentNodeId = null,
      isChild = false,
      nodeId = null,
      isSubchild = false,
      childId = null,
    }) {
      const actionSelected = this.actionsToNodeSelected.find(
        (actionToNodeSelected) => actionToNodeSelected.action === action
      )

      // si la accion elegida continua hacia otro nodo
      if (action.includes('continue')) {
        let response = null
        const nodeAction = !nodeIdAction
          ? null
          : this.nodes.find((node) => node._id === nodeIdAction)
        // si la accion no tiene el nodo entonces creara uno por defecto
        if (!nodeAction || !action.includes(nodeTypeAction)) {
          // si es un nodo hijo
          if (isChild && nodeId)
            response = await actionSelected.actionToSave.onAdd(
              parentNodeId,
              {
                childId: nodeId,
              },
              actionSelected.actionToSave.varToAdd
            )
          // si es un nodo hijo
          else if (isSubchild && nodeId)
            response = await actionSelected.actionToSave.onAdd(
              parentNodeId,
              {
                childId: childId,
                subChildId: nodeId,
              },
              actionSelected.actionToSave.varToAdd
            )
          // si es un nodo padre
          else {
            response = await actionSelected.actionToSave.onAdd(
              this.nodeId,
              {},
              actionSelected.actionToSave.varToAdd
            )
          }
        }

        // elimina la funcion antes de retornarlo
        delete actionSelected.actionToSave.onAdd

        actionSelected.actionToSave.nodeId_action = response
          ? response._id
          : nodeIdAction

        if (response && !response.chatbotId)
          this.$message.error('No se pudo ejecutar la acción')
        return {
          ...actionSelected.actionToSave,
        }
      } else
        return {
          nodeType: null,
          type_action: null,
          nodeId_action: null,
        }
    },
    /**
     * Verifica si existe un nodo catcher
     * @param {Object} args
     * @param {String} nodeId
     * @param {String} parentNodeId
     * @returns
     */
    verifyExistCatcher({ nodeId, parentNodeId }) {
      const indexNode = this.nodes.findIndex(
        (node) => node._id === nodeId || node._id === parentNodeId
      )
      const beforeNodes = this.nodes.slice(0, indexNode)
      return beforeNodes.some(
        (beforeNode) =>
          beforeNode.drawer &&
          beforeNode.drawer.question &&
          NODES_TO_SAVE_VARS.includes(beforeNode.drawer.question.type)
      )
    },
    /**
     * Filtra las variables simples para poder
     * verificar si ya han sido guardado en nodos catcher
     * @param {Object} args
     * @param {String} args.nodeId
     * @param {String} args.parentNodeId
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    filterSimpleVarsBySave({ nodeId, parentNodeId }) {
      const currentNode = this.nodes.find(
        (node) => node._id === nodeId || node._id === parentNodeId
      )
      if (!currentNode) return []

      const simpleVars = filterVarsToSaveOfParents(
        currentNode.connection.sourceId,
        this.nodes,
        nodeId || parentNodeId
      )
      if (!simpleVars) return []
      const hash = {} // aqui se adjuntara los ids de las variables
      return simpleVars.filter((simpleVar) =>
        simpleVar === undefined || !simpleVar?._id || hash[simpleVar?._id]
          ? false
          : (hash[simpleVar._id] = true)
      )
    },
    /**
     * Fomatea las variables simples para verificar si son usadas
     * @param {Object} args
     * @param {String} args.nodeId
     * @param {Array} args.simpleVars
     */
    formatSimpleVars({ simpleVars, nodeId, parentNodeId }) {
      const beforeNodes = this.filterSimpleVarsBySave({
        nodeId,
        parentNodeId,
      })
      const afterNodes = filterVarsToSaveOfChildren(nodeId, this.nodes)

      const simpleVarsBySave = [...beforeNodes, ...afterNodes]

      const currentNode = this.nodes.find((node) => node._id === nodeId)
      const isCatcher =
        currentNode &&
        currentNode.drawer &&
        currentNode.drawer.question &&
        currentNode.drawer.question.type === 'catcher'

      if (isCatcher) {
        simpleVarsBySave.push(currentNode.drawer.question.varToSave)
      }

      const simpleVarsFiltered = simpleVarsBySave.filter(
        (simpleVar) => simpleVar && simpleVar._id
      )
      const idsVarsInCatcher = simpleVarsFiltered.map(
        (simpleVarBySave) => simpleVarBySave._id
      )

      const simpleVarsFormatted = [...simpleVars].map((simpleVar) => {
        const isUsed = [...idsVarsInCatcher].includes(simpleVar._id)
        simpleVar.isUsed = isUsed
        return simpleVar
      })
      return simpleVarsFormatted
    },
    /**
     * Formatea el nombre de una variable
     * @param {Object} simpleVar
     * @param {Boolean} simpleVar.delete
     * @param {Boolean} simpleVar.isUsed
     * @param {String} simpleVar.name
     * @returns {Object[]} simpleVars - variables sin paginacion formateadas
     * @returns {String} simpleVars[].name
     * @returns {String} simpleVars[]._id
     */
    formatNameSimpleVar(simpleVar) {
      let extra = ''
      if (simpleVar.delete) extra = '( Variable eliminada )'
      else if (simpleVar.isUsed) extra = '( Usada en otro nodo )'

      return `${simpleVar.name} ${extra}`
    },
    /**
     * Agrega hijos por defecto al nodo padre
     * @param {String} nodeId
     * @param {String} nodeType
     */
    async addDefaultChild(nodeId, nodeType) {
      switch (nodeType) {
        case 'options':
          await this.addDefaultOption(nodeId, nodeType)
          break
        case 'buttons':
          await this.addDefaultButton(nodeId, nodeType)
          break
        case 'cards':
          await this.addDefaultCard(nodeId, nodeType)
          break
        case 'lists':
          await this.addDefaultListSection(nodeId, nodeType)
          break
      }
    },
    /**
     * Cambia el orden de un hijo
     * @param {String} child
     * @param {String} newOrder
     * @param {String} nodeType
     * @returns
     */
    async changeOrderChild(child, newOrder, nodeType) {
      const node = this.nodes.find((node) => node._id === child.parentNodeId)
      const structureNode = NODES[nodeType]
      if (node === undefined) return
      /**La opcion que entrara en en el nuevo ordern */
      const incomingChild = {
        order: newOrder,
        text: child.text,
        type_action: child.action.type,
        message_action: child.action.message,
        nodeId_action: child.action.nodeId,
        lineId_action: child.action.lineId,
        nodeType: child.action.nodeType,
      }
      /**La opción que saldra para ser colocado en el antiguo orden de la posicion entrante */
      const outgoingChild = {
        order: child.order,
        text: node.drawer[nodeType][newOrder - 1].text,
        type_action: node.drawer[nodeType][newOrder - 1].action.type,
        message_action: node.drawer[nodeType][newOrder - 1].action.message,
        nodeId_action: node.drawer[nodeType][newOrder - 1].action.nodeId,
        lineId_action: node.drawer[nodeType][newOrder - 1].action.lineId,
        nodeType: node.drawer[nodeType][newOrder - 1].action.nodeType,
      }

      const rptaIncoming = await this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: child.parentNodeId,
        nodeType,
        newChild: incomingChild,
        childId: child[structureNode.child.id_property],
        childIdProperty: structureNode.child.id_property,
      })
      this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: node._id,
        nodeType,
        newChild: outgoingChild,
        childId:
          node.drawer[structureNode.child.group][newOrder - 1][
            structureNode.child.id_property
          ],
        childIdProperty: structureNode.child.id_property,
      })

      // console.log('rptaIncoming', rptaIncoming)

      this.SET_SELECTED_NODE_ID(
        rptaIncoming.data[structureNode.child.id_property]
      )
    },
    /**
     * Cambia el orden de un nodo que simula ser padre
     * @param {String} child
     * @param {String} newOrder
     * @param {String} nodeType
     * @returns
     */
    async changeOrderSimulateParent(child, newOrder, nodeType) {
      const node = this.nodes.find((node) => node._id === child.parentNodeId)
      const structureNode = NODES[nodeType]
      if (node === undefined) return
      /**La opcion que entrara en en el nuevo ordern */
      const incomingChild = {
        order: newOrder,
        title: child.title,
        subtitle: child.subtitle,
        media: child.media,
      }
      /**La opción que saldra para ser colocado en el antiguo orden de la posicion entrante */
      const outgoingChild = {
        order: child.order,
        title: node.drawer[nodeType][newOrder - 1].title,
        subtitle: node.drawer[nodeType][newOrder - 1].subtitle,
        media: node.drawer[nodeType][newOrder - 1].media,
      }

      const rptaIncoming = await this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: child.parentNodeId,
        nodeType,
        newChild: incomingChild,
        childId: child[structureNode.child.id_property],
        childIdProperty: structureNode.child.id_property,
      })

      this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: node._id,
        nodeType,
        newChild: outgoingChild,
        childId:
          node.drawer[structureNode.child.group][newOrder - 1][
            structureNode.child.id_property
          ],
        childIdProperty: structureNode.child.id_property,
      })

      this.SET_SELECTED_NODE_ID(
        rptaIncoming.data[structureNode.child.id_property]
      )
    },
    /**
     * Cambia el orden de un subhijo
     * @param {String} subchild
     * @param {String} newOrder
     * @param {String} nodeType
     * @returns
     */
    async changeOrderSubchild(subchild, newOrder, nodeType) {
      const node = this.nodes.find((node) => node._id === subchild.parentNodeId)
      const structureNode = NODES[nodeType]
      if (node === undefined) return

      const childId = subchild.simulateParentNodeId
      const indexChild = node.drawer[nodeType].findIndex(
        (child) => child[structureNode.child.id_property] === childId
      )

      const structureSubchild = structureNode.child.child
      const titleProperty = structureSubchild.title_property
      /**La opcion que entrara en en el nuevo orden */
      const incomingSubchild = {
        order: newOrder,
        text: subchild.text,
        [titleProperty]: subchild[titleProperty],
        type_action: subchild.action.type,
        message_action: subchild.action.message,
        nodeId_action: subchild.action.nodeId,
        lineId_action: subchild.action.lineId,
        nodeType: subchild.action.nodeType,
      }
      // si tiene parametros extras para agregar al incoming
      if (structureSubchild.extra_params_for_change_order) {
        const extraParams = structureSubchild.extra_params_for_change_order
        extraParams.forEach((param) => {
          incomingSubchild[param] = subchild[param]
        })
      }

      /**La opción que saldra para ser colocado en el antiguo orden de la posicion entrante */
      const subChildren =
        node.drawer[nodeType][indexChild][structureNode.child.child.group]

      const outgoingSubchild = {
        order: subchild.order,
        [titleProperty]: subChildren[newOrder - 1][titleProperty],
        type_action: subChildren[newOrder - 1].action.type,
        message_action: subChildren[newOrder - 1].action.message,
        nodeId_action: subChildren[newOrder - 1].action.nodeId,
        lineId_action: subChildren[newOrder - 1].action.lineId,
        nodeType: subChildren[newOrder - 1].action.nodeType,
      }

      // si tiene parametros extras para agregar al outgoing
      if (structureSubchild.extra_params_for_change_order) {
        const extraParams = structureSubchild.extra_params_for_change_order
        extraParams.forEach((param) => {
          outgoingSubchild[param] = subChildren[newOrder - 1][param]
        })
      }

      const rptaIncoming = await this.updateSubchild({
        chatbotId: this.chatbotId,
        nodeId: subchild.parentNodeId,
        nodeType,
        newSubchild: incomingSubchild,
        childId,
        childIdProperty: structureNode.child.id_property,
        subchildId: subchild[structureNode.child.child.id_property],
        subchildType: structureNode.child.child.group,
        subchildIdProperty: structureNode.child.child.id_property,
      })

      this.updateSubchild({
        chatbotId: this.chatbotId,
        nodeId: node._id,
        nodeType,
        newSubchild: outgoingSubchild,
        childId,
        childIdProperty: structureNode.child.id_property,
        subchildId:
          subChildren[newOrder - 1][structureNode.child.child.id_property],
        subchildType: structureNode.child.child.group,
        subchildIdProperty: structureNode.child.child.id_property,
      })

      this.SET_SELECTED_NODE_ID(
        rptaIncoming.data[structureNode.child.child.id_property]
      )
    },
    /**
     * Regresa los nodos que están sobre el nodo que se le pasa
     * @param {Object} args
     * @param {String[]} args.nodeTypes - options, buttons, lists, cards
     * @param {String} args.nodeId
     * @param {String} args.parentNodeId
     * @returns {Object[]}
     */
    nodesAboveCurrent({ nodeTypes, nodeId, parentNodeId }) {
      const nodesForType = this.nodes.filter(
        (node) =>
          node.drawer.question && nodeTypes.includes(node.drawer.question.type)
      )
      const indexNode = nodesForType.findIndex(
        (node) => node._id === nodeId || node._id === parentNodeId
      )
      const beforeNodes = nodesForType.slice(0, indexNode)
      return beforeNodes
    },
    /**
     * Agrega un subhijo por defecto
     * @param {String} nodeId
     * @param {String} nodeType
     * @param {String} childId
     */
    async addDefaultSubchild(nodeId, nodeType, childId) {
      switch (nodeType) {
        case 'cards':
          await this.addDefaultButtonCard(nodeId, childId)
          break
        case 'lists':
          await this.addDefaultListItem(nodeId, childId)
          break
      }
    },
  },
}
