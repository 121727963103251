<template>
  <div class="carousel">
    <!--Botones de accion-->
    <a-button
      class="carousel__button-action carousel__button-action--back"
      @click="handlePrev"
      shape="circle"
      :disabled="disabledPrev"
    >
      <a-icon type="left" />
    </a-button>
    <a-button
      class="carousel__button-action carousel__button-action--next"
      @click="handleNext"
      shape="circle"
      :disabled="disabledNext"
    >
      <a-icon type="right" />
    </a-button>
    <!--Contenido-->
    <div class="carousel__viewport" ref="carousel_viewport">
      <div v-for="item in dataSource" :key="item._id" class="carousel__card">
        <div class="carousel__contain-picture">
          <img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            class="carousel__picture"
          />
          <a-icon v-else type="picture" class="carousel__icon" />
        </div>
        <div class="carousel__content">
          <h6 class="semibold-12 mrg-bottom-0 text--left carousel__title">
            {{ item.title }}
          </h6>
          <p class="regular-12 mrg-bottom-0 text--left carousel__description">
            {{ item.subtitle }}
          </p>
        </div>
        <ul class="carousel__buttons">
          <li
            v-for="button in item.buttons"
            :key="button.idButton"
            class="carousel__button"
          >
            {{ button.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselCards',
  props: {
    dataSource: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    currentItem: 1,
  }),
  computed: {
    /**
     * @return {Boolean} deshabilita el boton de atras
     */
    disabledPrev() {
      return this.currentItem === 1
    },
    /**
     * @return {Boolean} deshabilita el boton de siguiente
     */
    disabledNext() {
      return this.dataSource && this.currentItem === this.dataSource.length
    },
  },
  methods: {
    /**
     * Ejecuta el evento de siguiente
     */
    handleNext() {
      const width = 190
      const carousel = this.$refs.carousel_viewport

      carousel.scrollLeft = this.currentItem * width
      this.currentItem += 1
    },
    /**
     * Ejecuta el evento de atras
     */
    handlePrev() {
      const width = 190
      const carousel = this.$refs.carousel_viewport

      this.currentItem -= 1
      carousel.scrollLeft = carousel.scrollLeft - width
    },
  },
}
</script>

<style lang="sass" scoped>
$border-radius: 14px

.carousel
  width: 190px
  position: relative
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)
  border-radius: $border-radius
  &__card
    border-radius: $border-radius
    background-color: $white_000
    position: relative
    flex: 0 0 100%
    width: 100%
  &__contain-picture
    height: 96px
    background-color: $gray_5
    border-radius: $border-radius $border-radius 0px 0px
  &__picture
    height: 100%
    width: 100%
    object-fit: cover
    border-radius: inherit
  &__content
    padding: 8px 12px
  &__title
    color: $gray_8
  &__description
    color: $gray_7
  &__buttons
    padding: 0px
    margin-bottom: 0px
  &__button
    padding: 1px 12px
    width: 100%
    border-top: 1px solid $gray_5
    color: $gray_6
    list-style: none
    text-align: center
  &__icon
    font-size: 32px
    margin-top: 30px
    color: $gray_7
  &__button-action
    background-color: $gray_1
    box-shadow: 0px 1.42038px 5.68153px rgba(0, 0, 0, 0.15)
    position: absolute
    top: 80px
    z-index: 1
    &--next
      right: -14px
    &--back
      left: -14px
  &__viewport
    display: flex
    overflow-x: hidden
    counter-reset: item
    scroll-behavior: smooth
    scroll-snap-type: x mandatory
</style>
