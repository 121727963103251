<template>
  <div class="redirect"><span>Seleccionando Plan...</span></div>
</template>

<script>
export default {
  name: 'RedirectPlan',
  created() {
    const plansAvailable = {
      free: 'free',
      advanced: process.env.VUE_APP_ADVANCED_OID,
      business: process.env.VUE_APP_BUSINESS_OID,
      enterprise: process.env.VUE_APP_ENTERPRISE_OID,
    }
    const namePlan = this.$route.params.namePlan // Identificar que es una redirección exactamente despues de haberse logueado a la plataforma
    if (
      namePlan === 'free' ||
      !Object.keys(plansAvailable).includes(namePlan)
    ) {
      return this.$router.push({
        name: 'signup',
        query: { license: 'free' },
      })
    }

    return this.$router.push({
      name: 'signup',
      query: { license: namePlan, plan: plansAvailable[namePlan] },
    })
  },
}
</script>
