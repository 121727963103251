<template>
  <div class="view-previous noselect box-shadow-bottom">
    <div class="view-previous-container">
      <img :src="urlFile" v-if="percent === 100 && urlFile" />
      <a-progress v-if="percent !== 100" :percent="percent" size="small" />
      <div class="overlay">
        <a-icon
          type="eye"
          style="font-size: 24px; color: white; margin-right: 8px"
          @click="showModal"
        />
        <a-modal
          v-model="visible"
          :title="`${name}`"
          @ok="handleOk"
          :footer="null"
        >
          <img :src="urlFile" width="100%" />
        </a-modal>
        <a-icon
          type="delete"
          style="font-size: 24px; color: white"
          @click="deleteImg"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewPrevious',
  props: {
    urlFile: {
      type: String,
      requred: true,
    },
    percent: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    visible: false,
  }),
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk() {
      this.visible = false
    },
    deleteImg() {
      this.$emit('delete')
    },
  },
}
</script>
<style lang="sass" scoped>
.view-previous
  border: 1px solid $gray_light_000
  box-sizing: border-box
  max-width: 102px
  margin: 12px 8px
  padding: 4px
.view-previous-container
  height: 66px
  width: 100%
  max-width: 102px
  position: relative
  img
    width: 100%
    height: 100%
    display: block
    object-fit: cover
.noselect
  user-select: none
  -webkit-user-select: ninguno
  -moz-user-select: -moz-ninguno
  .overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    opacity: 0
    transition: .3s ease
    background: rgba(0, 0, 0, 0.4)
    display: flex
    align-items: center
    justify-content: center
.view-previous-container:hover .overlay
  opacity: 1
</style>
