<template>
  <div class="section">
    <lottie-animation
      path="img/lottie/product_launch_02_solid.json"
      :width="500"
      :height="390"
    />
    <a-row class="section__content">
      <h3 class="semibold-30 mrg-bottom-8">
        Te estamos conectando a "{{ companyName }}"
      </h3>
      <p class="regular-20">
        "Todo es posible, siempre y cuando realmente creas en ello" -
        <strong> Ashley Qualls</strong>
      </p>
    </a-row>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs

export default {
  name: 'SectionConnect',
  props: {
    companyName: {
      type: String,
      required: true,
    },
  },
  components: {
    LottieAnimation,
  },
}
</script>

<style lang="sass" scoped>
.section
  width: 40em
  margin: auto
  display: flex
  align-items: center
  flex-direction: column
  &__content
    width: 30em
</style>
