<template>
  <div class="resume-enterprise">
    <div class="resume-enterprise-content">
      <!-- Modal -->
      <a-modal
        :visible="visible"
        @cancel="handleOk"
        width="830px"
        :maskClosable="true"
        :footer="null"
        closable
        centered
        class="resume-enterprise-content-modal"
      >
        <div class="body">
          <div class="icon">
            <IconMessageSky />
          </div>
          <div class="text">
            <h3 class="heading-h3">
              Tu cambio de plan ha sido solicitado a {{ app }}
            </h3>
            <p class="body-2">
              Tus datos han sido enviados y pronto un experto se pondrá en
              contacto contigo para llevar a cabo tu upgrade a Enterprise.
              ¡Gracias!
            </p>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import IconMessageSky from '@/app/shop/components/icons/IconMessageSky.vue'

export default {
  name: 'ResumeEnterprise',
  components: {
    IconMessageSky,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
  }),
  methods: {
    handleOk() {
      this.$emit('reset')
    },
  },
}
</script>

<style lang="sass" scoped>
.resume-enterprise
  &-content
    &-modal
      .body
        margin: 0 auto
        padding: 150px 0
        max-width: 432px
        .icon
          text-align: center
        .text
          text-align: center
          p
            text-align: center
</style>
