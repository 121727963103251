var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-template scrollbar_basic template"},[_c('div',{staticClass:"header-dashboard width-no-collapsed"},[_c('header-module',{staticClass:"template__header",attrs:{"title":"DASHBOARDS","tipography":"heading-h5","link":`${_vm.vari.WEB_DOC}caracteristicas/dashboards/`,"text-before-link":`Visualiza y descarga datos de tu gestión en ${_vm.app}`,"text-after-link":"si deseas conocer más.","textLink":"Click aquí","positionTooltip":"right"}}),_c('div',{ref:"firstSection",staticClass:"first-section"},[_c('a-select',{staticStyle:{"width":"312px","margin-right":"12px"},attrs:{"default-value":"allLines","dropdownStyle":_vm.stylesDropdown},on:{"change":_vm.handleLine}},_vm._l((_vm.allLines),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1),_c('a-range-picker',{staticStyle:{"min-width":"312px"},attrs:{"value":_vm.valuePicker,"disabled-date":_vm.disabledDate,"show-time":{
          format: _vm.localePicker.lang.dateTimeFormat,
          defaultValue: [
            _vm.moment('00:00:00', 'HH:mm:ss'),
            _vm.moment('23:59:59', 'HH:mm:ss'),
          ],
        },"placeholder":['Fecha de inicio', 'Fecha de fin'],"format":_vm.localePicker.lang.dateTimeFormat,"locale":_vm.localePicker,"popupStyle":_vm.stylesPopup,"allowClear":false},on:{"change":_vm.onChange,"ok":_vm.onOk}}),_c('a-button',{staticClass:"btn-dashed",attrs:{"type":"dashed","disabled":_vm.disabledLoad},on:{"click":_vm.handleGetData}},[_c('a-icon',{attrs:{"type":"sync","spin":_vm.spin}}),_vm._v("Traer datos recientes ")],1)],1)],1),_c('a-row',{staticClass:"contain-dashboards",staticStyle:{"margin":"0"},attrs:{"gutter":[12, 12]}},_vm._l((_vm.components),function(component){return _c('a-col',{key:component.name,attrs:{"span":component.span,"id":component.id}},[_c(component.name,{tag:"component",attrs:{"lineId":_vm.lineId,"date":_vm.valuePicker,"isLoading":_vm.disabledLoad},on:{"handleAgentSchedule":_vm.handleAgentSchedule,"onChangeAgent":_vm.handleFilterTimeAverageStates,"onChangeState":_vm.handleFilterTimeAverageStates}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }