<template>
  <a-card
    class="card-billing-container"
    style="width: -webkit-fill-available"
    :bodyStyle="{ padding: '16px 20px' }"
  >
    <div class="card-billing-content">
      <!-- Cabecera -->
      <a-row class="card-billing-content-header">
        <a-col :span="24">
          <p class="heading-h8 mrg-bottom-0">{{ secondaryText }}</p>
        </a-col>
      </a-row>
      <!-- Precios -->
      <a-row class="card-billing-content-body">
        <a-col :span="12">
          <h5 class="heading-h5 mrg-bottom-0">{{ primaryText }}</h5>
          <p class="caption-c2">
            <span>{{ description }}.</span>
            <a
              v-if="secondaryDescription"
              :href="secondaryDescription.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ secondaryDescription.text }}
            </a>
          </p>
        </a-col>
        <a-col :span="12">
          <h2>
            <span class="heading-h4">{{ badge }}</span>
            <span class="heading-h2">{{ price }}</span>
          </h2>
          <p class="body-b1">{{ priceDescription }}</p>
        </a-col>
      </a-row>
      <!-- Footer -->
      <a-row class="card-billing-content-footer">
        <a-col :span="12" class="note">
          <p class="body-3">{{ note }}</p>
        </a-col>
        <a-col :span="12">
          <a-button
            v-if="textButton"
            :type="type"
            :class="{ 'blue-5': type === 'primary' }"
            @click="handleAction"
          >
            {{ textButton }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'CardBilling',
  props: {
    type: { type: String, enum: ['primary', 'secondary'], default: 'primary' },
    secondaryText: { type: String },
    primaryText: { type: String },
    description: { type: String },
    secondaryDescription: {
      text: { type: String },
      url: { type: String },
    },
    badge: { type: String, default: '$' },
    price: { type: String },
    priceDescription: { type: String },
    note: { type: String },
    textButton: { type: String, default: 'Comenzar' },
    action: { type: String, required: false },
  },
  methods: {
    ...mapMutations(['SET_MODAL_UPGRADE']),

    handleAction() {
      if (this.action === 'redirect')
        this.$router.push({ name: 'menu-payment-methods' })
      else if (this.action === 'upgrade')
        this.SET_MODAL_UPGRADE({ visible: true })
    },
  },
}
</script>

<style lang="sass" scoped>
.card-billing
  &-container
    border-radius: 8px
    border: 1px solid #BFBFBF
  &-content
    &-header
      p
        text-align: left
    &-body
      h2
        margin: 0
    &-footer,
      display: flex
      align-items: center
      .blue-5
        background-color: $blue_5
      p
        margin: 0
    .ant-col-12
      &:first-child
        text-align: left
      &:last-child
        text-align: right
</style>
