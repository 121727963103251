<template>
  <div class="container-template">
    <a-row :gutter="[12]" class="row">
      <a-col :span="4" style="" class="column">
        <div class="menu-column">
          <MenuShop :itemSelected="itemSelected" />
        </div>
      </a-col>
      <a-col :span="20" class="column">
        <div class="content-column">
          <router-view></router-view>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import MenuShop from '@/app/shop/components/MenuShop.vue'

export default {
  name: 'ShopTemplate',
  components: {
    MenuShop,
  },
  computed: {
    // menu seleccionado de configuracion
    itemSelected() {
      if (this.$route.name === 'shop') return null
      return this.$route.name
    },
  },
}
</script>

<style lang="sass" scoped>
$size-title: 24px
$margin-bottom-title: 12px

.container-template
  // margin: 20px $padding-heigth-inner $padding-heigth-inner
  // height: calc( 100vh - 24px )
  padding: $padding-heigth-inner
  height: 100vh
  .row
    height: calc( 100vh - #{$padding-heigth-inner * 2 })
    .column
      height: -webkit-fill-available
      .menu-column, .content-column
        height: 100%
        background-color: $white_000
        border-radius: 8px
        border: 1px solid #EBEBEB
      .menu-column
        padding-top: 20px
      .content-column
        // padding: 20px
        overflow: hidden
</style>
