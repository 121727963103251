<template>
  <li
    class="list-style-none display-flex full-width direction-column"
    :class="{
      'list-item--inactive': status && !status.active,
      'list-item--default': !status,
    }"
  >
    <!--Skeleton de ticket-->
    <template v-if="loading">
      <a-skeleton
        :loading="loading"
        active
        :avatar="{ size: 32, shape: 'square' }"
        :paragraph="{ rows: 1 }"
        class="list-item__skeleton"
      >
      </a-skeleton>
    </template>
    <template v-else>
      <p v-if="showTickets" class="regular-12 mrg-bottom-2">
        <span class="gray_9"
          >Encontrado en
          {{ dataSource.tickets ? 'los tickets' : 'el ticket' }}:</span
        >
        <span class="gray_7"> {{ numberTickets }}</span>
      </p>
      <div class="display-flex align--center full-width">
        <a-avatar
          v-for="(picture, index) in dataSource.pictures"
          :key="index"
          shape="square"
          class="list-item__picture"
          :size="44"
          :src="picture ? picture : ''"
        >
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!picture" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.655 8.004l9.459-4.62m-9.459 4.62l9.459 4.519M3.655 8.004L1.34 5.792a.32.32 0 01.085-.522l8.94-4.178a.32.32 0 01.354.056l2.396 2.237m-9.459 4.62l-2.3 2.066a.32.32 0 00.073.526l2.093 1.017m9.593-8.23l9.61 4.645m-9.61-4.644l2.292-2.229a.32.32 0 01.363-.059l8.843 4.26a.32.32 0 01.097.505l-1.985 2.167m0 0l-9.61 4.494m9.61-4.494l1.985 2.168a.32.32 0 01-.097.505l-1.888.912m-9.61.909l2.419 2.33a.32.32 0 00.361.058l6.83-3.297m-9.61.909l-2.444 2.332a.32.32 0 01-.361.056L3.52 11.614m9.593.909V25m0 0l9.437-4.906a.32.32 0 00.173-.284v-8.196M13.114 25l-9.42-4.906a.32.32 0 01-.173-.284v-8.196" stroke="#BFBFBF" stroke-width="1.2"/></svg>
        </a-avatar>
        <div class="list-item__text flex-grow text--left">
          <p class="regular-12 mrg-bottom-0">
            <span class="list-item__code">#{{ dataSource.code }}</span>
            <span v-if="status" :class="status.color">{{ status.name }}</span>
          </p>
          <h6 class="semibold-12 mrg-bottom-0 list-item__name ellipsis">
            {{ dataSource.name }}
          </h6>
          <p class="regular-14 mrg-bottom-0">
            <span class="list-item__price"
              >{{ dataSource.currency ? dataSource.currency.symbol : ''
              }}{{ dataSource.price }}</span
            >
            <span
              >DCTO:
              {{ dataSource.currency ? dataSource.currency.symbol : '' }}
              {{ dataSource.maximum_discount }}</span
            >
          </p>
        </div>
        <a-checkbox
          v-if="!disabled"
          :checked="dataSource.assign"
          @change="handleChangeCheckbox"
        />
      </div>
    </template>
  </li>
</template>

<script>
import formatMixin from '@/app/shared/mixins/format'

export default {
  name: 'ListItemProduct',
  props: {
    dataSource: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    showTickets: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    checkboxLocal: false,
  }),
  mixins: [formatMixin],
  computed: {
    /**
     * @return {Object} objeto con el nombre del estado y el color
     */
    status() {
      // debe tener el mismo nombre que la clase
      if (this.loading) return null
      if (this.dataSource.deleted)
        return { name: 'Eliminado', color: 'red_6', active: false }
      else if (!this.dataSource.active)
        return { name: 'Inactivo', color: 'gray_8', active: false }
      else return null
    },
    /**
     * @return {String} numero de los tickes donde se encontraron esos productos
     */
    numberTickets() {
      if (!this.showTickets) return
      let numbers = null
      if (this.dataSource.tickets) {
        numbers = this.dataSource.tickets.map((elem) =>
          this.ticketNumberFormatted(elem)
        )
        numbers = numbers.join(', ')
      } else numbers = this.ticketNumberFormatted(this.dataSource.ticket)
      return numbers
    },
  },
  methods: {
    handleChangeCheckbox(e) {
      this.$emit('onChecked', e.target.checked)
    },
  },
}
</script>

<style lang="sass" scoped>
.red_6
  color: $red_6
.gray_8
  color: $gray_8
.gray_9
  color: $gray_9
.gray_7
  color: $gray_7
.list-item__picture
.list-item
  &__text
    margin-right: 12px
  &__code
    margin-right: 8px
  &__picture
    border: 0.2px solid $gray_2
    background-color: $gray_3
    margin-right: 12px
  &__price
    margin-right: 12px
  &--default
    .list-item__name
      color: $gray_9
  &--inactive
    color: $gray_6
    .list-item__name
      color: $gray_6
</style>
<style lang="sass">
.list-item__skeleton
  >.ant-skeleton-content
    .ant-skeleton-title
      margin-top: 0px
    .ant-skeleton-paragraph
      margin-top: 12px !important
</style>
