var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('h5',{staticClass:"align-left mrg-bottom-0",class:[
      {
        'align-left': _vm.position === 'left',
        'align-center': _vm.position === 'center',
        'align-right': _vm.position === 'right',
      },
      _vm.tipography,
    ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.showTooltip && _vm.show_documentation)?_c('a-tooltip',{attrs:{"placement":_vm.positionTooltip}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.textBeforeLink)+" "),(_vm.showLink)?_c('a',{attrs:{"href":_vm.link,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.textLink)+" ")]):_vm._e(),_vm._v(_vm._s(_vm.textAfterLink)+" ")]),_c('a-icon',{staticClass:"header__icon",attrs:{"type":"info-circle"}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }