<template>
  <div class="modal-purchase" v-if="enable">
    <div class="modal-purchase-content">
      <component
        v-if="userProduct && userProduct.presentations"
        :is="componentStep"
        :visible="enable"
        :price="totalPrice"
        :loading="loading"
        @back="previousStep"
        @done="nextStep"
        @addOtherCredit="modalSettingCard.visible = true"
        @created="handleCreated"
        @hideModal="hideModalSelectCredit"
        :showError="modalSettingCard.error.visible"
        :messageError="modalSettingCard.error.message"
        :typeError="modalSettingCard.error.type"
      />
    </div>
    <ModalFormSettingsCredit
      :visible="modalSettingCard.visible"
      @hideModal="hideModalSettingCard"
      @onSubmit="processPayment"
      :loading="modalSettingCard.loading"
      :showError="modalSettingCard.error.visible"
      :messageError="modalSettingCard.error.message"
      :typeError="modalSettingCard.error.type"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StartBuyAccount from '@/app/accounts/components/forms/StartBuyAccount.vue'
import ModalSelectCreditCard from '@/app/shop/components/market/settingsCreditCard/ModalSelectCreditCard.vue'
import ModalFormSettingsCredit from '@/app/shop/components/market/settingsCreditCard/ModalFormSettingsCredit.vue'

export default {
  name: 'ModalPurchaseAccount',
  components: {
    StartBuyAccount,
    ModalSelectCreditCard,
    ModalFormSettingsCredit,
  },
  props: {
    enable: { type: Boolean },
    account: { type: Object },
  },
  data: () => ({
    step: 1, // Numero del paso del modal
    lastStep: 2, // Numero del ultimo paso disponible
    amount: 1, // Cantidad de usuarios a adquirir
    totalPrice: 0, // Precio prorrateado del usuario
    loading: false,
    modalSettingCard: {
      visible: false,
      loading: false,
      error: {
        message: '',
        visible: false,
      },
    },
    visibleComponent: true,
  }),
  computed: {
    ...mapGetters(['products', 'companyPricing']),
    /**
     * @returns {Component}
     */
    componentStep() {
      if (this.step === 1) return StartBuyAccount
      else if (this.step === 2) return ModalSelectCreditCard
      return null
    },
    /**
     * @returns {Object}
     */
    userProduct() {
      if (!this.products) return {}
      return this.products.find((prod) =>
        prod.permission.includes('create_user')
      )
    },
  },
  methods: {
    ...mapActions(['paymentPurchase', 'pricePerUser']),
    hideModalSettingCard() {
      this.modalSettingCard = {
        visible: false,
        loading: false,
        error: {
          message: '',
          visible: false,
        },
      }
    },
    hideModalSelectCredit() {
      this.step = 1
      this.$emit('cancel')
      this.modalSelectCard = {
        visible: false,
        loading: false,
        error: {
          message: '',
          visible: false,
        },
      }
    },
    /**
     * Se ejecuta cuando un de los componentes dinámicos, es creado
     */
    handleCreated() {
      if (this.step === 1) this.getPrice()
    },
    /**
     * Paso anterior
     */
    previousStep() {
      if (this.step === 1) this.$emit('cancel')
      else this.step--
    },
    /**
     * Siguiente paso
     * @param {Object} card - propiedades de un tarjeta de credito
     */
    nextStep(card) {
      this.step++
      if (this.step > this.lastStep) {
        this.step = 2
        this.processPayment(card)
      }
    },
    /**
     * Obtiene el precio prorrateado
     * @async
     */
    async getPrice() {
      const price = await this.pricePerUser({
        pricingId: this.companyPricing.pricingId,
        single: true,
      })
      this.totalPrice = price.total
    },
    /**
     * Inicia el proceso del pago
     * @async
     * @param {Object} card - propiedades de un tarjeta de credito
     * @param {Boolean} card.new - si es nuevo o no
     * @param {String} card.name - nombre y apellido juntos
     * @param {Number} card.number - numero de tarjeta
     * @param {String} card.expirationDate - fecha de expiracion de la tarjeta
     * @param {String} card.paymentMethod - metodo de pago
     */
    async processPayment(card) {
      this.loading = true
      if (this.modalSettingCard.visible) this.modalSettingCard.loading = true

      const productSelected = {
        amount: this.amount,
        idPresentation: this.userProduct.presentations[0].idPresentation,
        idProduct: this.userProduct._id,
        permission: 'create_admin_account',
        price: this.totalPrice,
        total: this.totalPrice,
        type: this.userProduct.type,
        _id: this.userProduct._id,
      }
      const response = await this.paymentPurchase({
        card: { ...card },
        purchase: {
          products: [{ ...productSelected, profile: this.account }],
        },
      })
      this.loading = false
      this.modalSettingCard.loading = false
      if (response.success == false) {
        if (response.result == 'exists' || response.result == 'duplicated') {
          this.$message.error(
            'Lo sentimos, el correo o ID del usuario ya existe'
          )
        } else {
          this.$message.error(
            'No pudimos procesar tu pago 😓, por favor revisa tus datos'
          )
        }

        return
      }
      // si es una compra de productos o de planes
      const payment = response.presentationsPayment
      // si el estado es aprobado o pendiente se considerará como exitoso
      switch (payment.payment ? payment.payment.status : payment.status) {
        case 'approved':
          {
            this.$message.success('El pago de tu usuario se realizó con éxito')
          }
          this.$emit('done')
          this.step = 1
          this.hideModalSettingCard()
          break
        case 'pending': {
          if (payment.payment.pendingDetail)
            this.modalSettingCard.error = {
              message: payment.payment.pendingDetail,
              visible: true,
              type: 'warning',
            }
          this.$message.success(
            'Tu pago aún esta pendiente pero igual podras usar tu usuario mientras confirmamos el pago'
          )
          this.$emit('done')
          this.step = 1
          this.hideModalSettingCard()

          break
        }
        case 'rejected': {
          // Verificar el codigo de rechazo
          const errorCode = payment.payment?.responseCode
          let messageErrorDetails = ''

          // Si el codigo es 'SUSCRIPTION_REJECTED' se muestra el mensaje de rechazo que viene del backend
          if (errorCode === 'SUSCRIPTION_REJECTED') {
            messageErrorDetails = payment.payment.rejectionDetail
          } else {
            messageErrorDetails = payment.payment.rejectionDetail
              ? `No pudimos procesar tu pago 😓. ${payment.payment.rejectionDetail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
              : 'No pudimos procesar tu pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta'
          }

          this.modalSettingCard.error = {
            message: messageErrorDetails,
            visible: true,
            type: 'error',
          }
          this.$message.error('El pago de tu usuario fue rechazado')
          this.$emit('updateList')
          break
        }
        default: {
          this.modalSettingCard.error = {
            message:
              'No pudimos procesar tu pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta',
            visible: true,
            type: 'error',
          }
        }
      }
    },
  },
}
</script>
