const initialState = () => ({
  webchat_id: null,
  webchat_demo: {
    color: '#1890FF', // blue_6
    avatarUrl: null,
    title: 'Ticker title',
    isFreeVersion: true,
    messageToFinish:
      '¡Gracias por charlar con nosotros hoy! Hasta la próxima 🖐',
    welcomeMessage:
      'Hola buenos días, un gusto saludarte, cuentanos en qué podemos ayudarte?',
  },
  webchat: null,
})

export default initialState
