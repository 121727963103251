const initialState = () => ({
  sound_message: null,
  sound_notification: null,
  sound_news: null,
})

const state = initialState

const getters = {
  sound_message: (state) => state.sound_message,
  sound_notification: (state) => state.sound_notification,
  sound_news: (state) => state.sound_news,
}

const mutations = {
  /** RESET MODULE */
  RESET_SOUNDS_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Setea el tag completo del div del audio
   * @param {*} state
   * @param {<audio/>} sound_message Tag html del audio
   */
  SET_SOUND_MESSAGE(state, sound_message) {
    state.sound_message = sound_message
  },
  /**
   * Setea el tag completo del div del audio
   * @param {*} state
   * @param {<audio/>} sound_notification Tag html del audio
   */
  SET_SOUND_NOTIFICATION(state, sound_notification) {
    state.sound_notification = sound_notification
  },
  /**
   * Setea el tag completo del div del audio
   * @param {*} state
   * @param {<audio/>} sound_news Tag html del audio
   */
  SET_SOUND_NEWS(state, sound_news) {
    state.sound_news = sound_news
  },
}

const actions = {
  /**
   * Reproducir sonido de mensaje
   * @param {String} type - message, notification
   */
  async playSound(context, type) {
    try {
      if (type === 'message') {
        const sound_message = context.state.sound_message
        sound_message.play()
      } else if (type === 'notification') {
        const sound_notification = context.state.sound_notification
        sound_notification.play()
      }
    } catch (error) {
      console.error('[soundModule][playSound]', error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
