<template>
  <div class="position-relative fit-width">
    <template v-if="action === 'update'">
      <!--Actualización para MOBILES-->
      <router-link
        v-if="showButton && active_breakpoint.is_mobile"
        :to="routeComments"
      >
        <button-comment-linked
          :class="`button-comment--${position}`"
          @click.native="handleClickButton"
          :is-linked="!!commentId"
        />
      </router-link>
      <!--Actualización para DESKTOP-->
      <a-popover
        v-else
        v-model="previewComment.visible"
        trigger="click"
        :placement="position"
      >
        <a-row
          v-if="previewComment.visible"
          slot="content"
          class="popover-comment"
        >
          <a-row class="mrg-bottom-12" type="flex" justify="end">
            <a-tooltip>
              <span slot="title">{{ updateAction.tooltip }} </span>
              <a-icon
                type="edit"
                class="mrg-right-12 popover__icon"
                :class="{ 'icon--disabled': !updateAction.enabled }"
                @click="updateAction.enabled && handleShowModalComment()"
              />
            </a-tooltip>
            <a-tooltip>
              <span slot="title">{{ deleteAction.tooltip }} </span>
              <a-icon
                type="delete"
                class="popover__icon popover__icon--delete"
                :class="{ 'icon--disabled': !deleteAction.enabled }"
                @click="deleteAction.enabled && handleShowDelete()"
              />
            </a-tooltip>

            <a-icon
              type="close"
              class="popover__icon mrg-left-12"
              @click="previewComment.visible = false"
            />
          </a-row>
          <p v-if="previewComment.loading">Cargando...</p>
          <template v-else>
            <a-row class="mrg-bottom-4" type="flex" align="bottom">
              <h6 class="semibold-16 mrg-bottom-0 mrg-right-4 popover__title">
                {{ previewComment.user.name }}
              </h6>
              <span class="regular-12 popover__title">
                ( {{ previewComment.date }} )
              </span>
            </a-row>
            <div class="popover-comment__content scrollbar_basic">
              <p class="regular-14">
                {{ previewComment.content }}
              </p>
            </div>
          </template>
        </a-row>
        <button-comment-linked
          v-if="showButton"
          :class="`button-comment--${position}`"
          @click.native="handleClickButton"
          :is-linked="!!commentId"
        />
      </a-popover>
    </template>
    <!--Crear comentario-->
    <template v-else>
      <button-comment-linked
        v-if="showButton"
        :class="`button-comment--${position}`"
        @click.native="handleClickButton"
        :is-linked="!!commentId"
      />
    </template>
    <!--Contenido-->
    <slot></slot>
    <!--Modal para crear o actualizar comentario-->
    <modal-comment
      ref="modal_comment"
      :visible="modalComment.visible"
      :title="modalComment.title"
      :okText="modalComment.okText"
      :cancelText="modalComment.cancelText"
      :message-id="messageId"
      @onClose="handleCloseModalComment"
      :action="action"
      :commentId="commentId"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ButtonCommentLinked from '@/app/tickets/components/molecules/ButtonCommentLinked'
import ModalComment from '@/app/tickets/components/molecules/ModalComment'
import moment from 'moment'
import showConfirmDeleteComment from '@/app/tickets/mixins/comments'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'AddCommentLinked',
  components: {
    ButtonCommentLinked,
    ModalComment,
  },
  mixins: [showConfirmDeleteComment, supportMixin],
  props: {
    position: {
      type: String,
      enum: ['right', 'left'],
      required: false,
      default: 'left',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    action: {
      type: String,
      enum: ['update', 'add'],
      required: true,
    },
    commentId: {
      type: String,
      required: false,
    },
    messageId: {
      type: [String, Number],
      required: true,
    },
    comment: {
      type: Object,
      required: false,
      default: null,
    },
    blockedActions: {
      type: Array,
      required: false,
      default: () => [],
      enum: ['update-comment', 'delete-comment'],
    },
  },
  data: () => ({
    modalComment: {
      visible: false,
      title: '',
      okText: '',
      cancelText: 'Cancelar',
      onOk: () => {},
    },
    previewComment: {
      visible: false,
      user: {
        name: '',
        _id: '',
      },
      content: '',
      loading: false,
      date: '',
      ticketId: null,
    },
  }),
  computed: {
    ...mapGetters(['active_breakpoint', 'profile']),

    /**
     * Regresa la ruta de comentarios para el responsive
     * @return {Object} routeComments
     * @return {String} routeComments.name
     * @return {Object} routeComments.params
     */
    routeComments() {
      const menuComments = this.menuItemsTicket.find(
        (menuItem) => menuItem.route.params.drawerId === 'comments'
      )
      return menuComments.route
    },
    /**
     * Estado del ticket
     * @return {String}
     */
    statusTicket() {
      return this.$route.query ? this.$route.query.status : 'ended'
    },
    /**
     * Accion de actualizar
     * @return {Object} updateAction
     * @return {Boolean} updateAction.enabled
     * @return {String} updateAction.tooltip
     */
    updateAction() {
      const enabled =
        this.statusTicket === 'active' &&
        this.previewComment.user._id === this.profile?.userId &&
        !this.blockedActions.includes('update-comment')

      let tooltip = 'Editar'

      // si el ticket ya esta finalizado
      if (
        this.blockedActions.includes('update-comment') ||
        this.statusTicket === 'ended'
      )
        tooltip =
          'Solo se puede editar la nota mientras se encuentre sin resolver.'
      // si el ticket esta activo pero el creador no es el mismo de la sesion
      else if (
        this.statusTicket === 'active' &&
        this.previewComment.user._id !== this.profile.userId
      )
        tooltip = 'Solo el propietario de la nota puede editarlo.'

      return {
        enabled,
        tooltip,
      }
    },
    /**
     * Accion de eliminar
     * @return {Object} updateAction
     * @return {Boolean} updateAction.enabled
     * @return {String} updateAction.tooltip
     */
    deleteAction() {
      const enabled =
        this.statusTicket === 'active' &&
        this.previewComment.user._id === this.profile?.userId &&
        !this.blockedActions.includes('delete-comment')

      let tooltip = 'Eliminar'

      // si el ticket ya esta finalizado
      if (
        this.blockedActions.includes('delete-comment') ||
        this.statusTicket === 'ended'
      )
        tooltip =
          'Solo se puede eliminar la nota mientras se encuentre sin resolver.'
      // si el ticket esta activo pero el creador no es el mismo de la sesion
      else if (
        this.statusTicket === 'active' &&
        this.previewComment.user._id !== this.profile.userId
      )
        tooltip = 'Solo el propietario de la nota puede eliminarlo.'

      return {
        enabled,
        tooltip,
      }
    },
  },
  methods: {
    ...mapActions(['getComment', 'deleteComment']),

    /**
     * Escucha el evento de click en el botón de
     * agregar comentario
     */
    async handleClickButton() {
      if (this.action === 'update') {
        this.previewComment.visible = true
        this.previewComment.loading = true
        let comment
        if (this.comment) comment = this.comment
        else
          comment = await this.getComment({
            commentId: this.commentId,
            set: true,
          })
        this.setPreviewComment(comment)
        this.previewComment.loading = false
      } else this.handleShowModalComment()
    },
    /**
     * Mostrar el modal de comentario
     */
    handleShowModalComment() {
      switch (this.action) {
        case 'update':
          {
            if (this.previewComment.user._id !== this.profile.userId) return
            this.modalComment.visible = true
            this.modalComment.title = 'Editar comentario'
            this.modalComment.okText = 'Guardar cambios'
            this.$refs.modal_comment?.setComment(this.previewComment.content)
          }
          break
        case 'add':
          {
            this.modalComment.visible = true
            this.modalComment.title = 'Agregar comentario'
            this.modalComment.okText = 'Agregar'
          }
          break
      }
    },
    /**
     * Cierra el modal de comentario
     */
    handleCloseModalComment() {
      this.modalComment = {
        visible: false,
        title: '',
        okText: '',
        cancelText: 'Cancelar',
      }
    },
    /**
     * Setea los datos de comentario a la previsualización
     * @param {Object} comment
     * @param {Object} comment.user
     * @param {String} comment.user.names
     * @param {String} comment.user.surnames
     * @param {String} comment.user._id
     * @param {String} comment.text
     * @param {Date} comment.created_at
     * @param {String} comment.ticketId
     */
    setPreviewComment(comment) {
      this.previewComment.user.name = `${comment.user?.names} ${comment.user?.surnames}`
      this.previewComment.user._id = comment.user._id
      this.previewComment.content = comment.text
      this.previewComment.date = moment(comment.created_at).calendar()
      this.previewComment.ticketId = comment.ticketId
    },
    /**
     * Muestra la eliminación
     */
    handleShowDelete() {
      if (this.previewComment.user._id !== this.profile.userId) return
      this.previewComment.visible = false
      this.showConfirmDeleteComment(this.handleDeleteComment)
    },
    /**
     * Elimina el comentario
     */
    async handleDeleteComment() {
      const response = await this.deleteComment({
        commentId: this.commentId,
        messageId: this.messageId,
        ticketId: this.previewComment.ticketId,
      })
      if (response.success) this.$message.success('Se eliminó un comentario')
      else this.$message.error('Ocurrió un error 😥')
    },
  },
}
</script>

<style lang="sass" scoped>
.button-comment--right
  position: absolute
  right: -50px
  top: 20%
  @include xs
    right: -15px
    top: auto
    bottom: 0
.button-comment--left
  position: absolute
  left: -50px
  top: 20%
  @include xs
    left: -15px
    top: auto
    bottom: 0
.popover-comment
  max-width: 300px
  min-width: 260px
  max-height: 472px
  &__content
    overflow-y: auto
    max-height: 400px
    margin-right: -12px
    padding-right: 12px
    white-space: pre-wrap
    word-wrap: break-word
.popover
  &__icon
    font-size: 16px
    &--delete
      color: $red_6
  &__title
    color: $gray_8
</style>
