<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :bodyStyle="bodyStyle"
    width="32em"
    @cancel="handleCancel"
    centered
  >
    <div class="modal">
      <!-- ICONO puede ser: socialicon, anticon y every -->
      <!--Icono de canal-->
      <social-icon
        v-if="icon.type == 'socialicon'"
        :type="icon.path_or_name"
        size="52"
        class="modal__picture"
      />
      <!--Icono de Ant design-->
      <a-icon
        v-if="icon.type == 'anticon'"
        :type="icon.path_or_name"
        :style="icon.style || {}"
      />
      <!--Icono de Evericon-->
      <img
        v-if="icon.type == 'evericon'"
        :src="require(`@/app/commons/assets/${icon.path_or_name}`)"
        alt=""
      />
      <!--TITULO-->
      <h5 class="heading-h5 title">{{ title }}</h5>
      <!--SUBTITULO-->
      <h6 v-if="subtitle" class="heading-h6 subtitle">{{ subtitle }}</h6>
      <!--DESCRIPCION-->
      <ul v-if="isDescriptionList" class="modal__description--list">
        <li v-for="(item, index) in description" :key="`description_${index}`">
          {{ item }}
        </li>
      </ul>
      <p v-else class="body-2 description">{{ description }}</p>
      <!--BOTONES-->
      <template v-if="showButtons">
        <a-button type="primary" class="modal__button" @click="handleOk">{{
          okText
        }}</a-button>
        <!--Boton de cancelar tipo link-->
        <a
          v-if="typeCancel === 'link'"
          class="link-cancel"
          @click="handleCancel"
          >{{ cancelText }}</a
        >
        <a-button
          v-else-if="typeCancel === 'button'"
          class="modal__button mrg-top-12"
          @click="handleCancel"
        >
          {{ cancelText }}
        </a-button>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import compareMixin from '@/app/shared/mixins/compare'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'BasicModal',
  props: {
    title: {
      // titulo, podria ser el nombre del plan
      type: String,
      require: false,
      default: 'Title Ticker',
    },
    subtitle: {
      type: String,
      require: false,
      default: null,
    },
    icon: {
      type: Object,
      default: () => {
        return { type: 'socialicon', path_or_name: 'sms', style: {} }
      },
    },
    routeNameButtonOk: {
      type: String,
      require: false,
      default: 'shop',
    },
    id: {
      type: String,
      require: false,
      default: null,
    },
    description: {
      type: [String, Array],
      require: false,
      default:
        'Ad deserunt tempor reprehenderit fugiat nisi veniam occaecat. Magna magna consequat qui nisi qui dolore. Minim mollit exercitation incididunt exercitation aliqua exercitation elit laborum fugiat adipisicing ad ea ex.',
    },
    visible: {
      // hacer visible o no el modal
      type: Boolean,
      require: false,
      default: false,
    },
    okText: {
      type: String,
      require: false,
      default: 'ok',
    },
    typeCancel: {
      type: String,
      enumn: ['link', 'button'],
      default: 'link',
    },
    cancelText: {
      type: String,
      require: false,
      default: 'cancel',
    },
  },
  components: {
    SocialIcon,
  },
  data: () => ({
    bodyStyle: {
      padding: '40px 36px',
    },
    vari: vari,
  }),
  mixins: [compareMixin],
  computed: {
    ...mapGetters(['profile', 'active_breakpoint']),

    /**
     * @return {Boolean} - solo se muestran los botones para los administradores
     */
    showButtons() {
      // si el usuario es solo agente no debería ver el boton de subir de plan
      return (
        this.profile &&
        this.isAllowedFor(this.profile.type, ['admin', 'master']) &&
        !this.active_breakpoint.is_mobile
      )
    },
    /**
     * @return {Boolean} si la descripcion es lista o no
     */
    isDescriptionList() {
      return Array.isArray(this.description)
    },
  },
  methods: {
    handleCancel() {
      this.$emit('onClose', this.id)
    },
    handleOk() {
      this.$emit('onOk', this.routeNameButtonOk, this.id)
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .title
    margin-top: 20px
    margin-bottom: 4px
    color: $gray_dark_900
  .subtitle
    margin-top: 8px
    margin-bottom: 12px
    color: $gray_dark_800
  .link
    margin-bottom: 24px
  .link-cancel
    margin-top: 12px
    color: $gray_dark_800
  &__description--list
    text-align: left
    margin-top: 12px
    margin-bottom: 24px
.modal__picture
  cursor: auto
.modal__button
  width: 100%
</style>
