<template>
  <div class="section-company">
    <h5 class="heading-h5">Mi empresa</h5>
    <a-form
      v-if="profile && company"
      :form="form"
      @submit.prevent="handleSubmit"
      class="form"
      hideRequiredMark
    >
      <!--el cuerpo del formulario-->
      <div id="menu-company-master-container" class="master-container">
        <div
          id="menu-company-container"
          class="container scrollbar_basic"
          @scroll="
            setShadows(
              'menu-company-master-container',
              'menu-company-container',
              'menu-company-content'
            )
          "
        >
          <div id="menu-company-content" class="content">
            <p class="body-2 description">
              Recuerda que el banner de tu empresa lo tendrán todos tus
              usuarios.
            </p>
            <div class="upload-container">
              <a-upload
                class="upload"
                name="avatar"
                :multiple="false"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                :customRequest="selfUpload"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  alt="Banner de compañia"
                  style="object-fit: cover; border-radius: 4px"
                  height="96px"
                  width="246px"
                />
                <div class="overlay" :class="{ 'opacity-1': loading }">
                  <a-icon
                    v-if="!loading"
                    type="edit"
                    style="font-size: 20px; color: white"
                  />
                  <a-icon
                    v-else
                    type="loading"
                    style="font-size: 20px; color: white"
                  />
                </div>
              </a-upload>
              <div class="text">
                <p class="heading-h8 subtitle" style="margin-bottom: 0">
                  Subir aquí un nuevo banner
                </p>
                <p class="body-2" style="margin-bottom: 0">
                  El tamaño máximo permitido es de 2MB
                </p>
                <p class="body-2" style="margin-bottom: 0">
                  Las dimensiones ideales son 332px x 87px
                </p>
              </div>
            </div>
            <h6 class="heading-h6">Datos de mi empresa</h6>
            <!-- Nombre empresa -->
            <div class="form-items">
              <a-form-item
                v-bind="formItemLayout"
                label="Nombres"
                class="form-item"
                has-feedback
              >
                <a-input
                  placeholder="Escribe aquì..."
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Este campo es requerido',
                        },
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                        {
                          validator: validateNameCompany,
                        },
                      ],
                    },
                    ,
                  ]"
                />
              </a-form-item>
              <!-- País -->
              <a-form-item
                v-bind="formItemLayout"
                label="País"
                class="form-item"
              >
                <a-select
                  show-search
                  v-decorator="[
                    'country',
                    {
                      rules: [
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                >
                  <a-select-option
                    v-for="country in countriesNotRepeatByName"
                    :key="country.nameSpanish"
                  >
                    {{ country.nameSpanish }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Industria"
                class="form-item"
              >
                <a-select
                  v-decorator="[
                    'industry',
                    {
                      rules: [
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                >
                  <a-select-option
                    v-for="industry in industries"
                    :key="industry"
                  >
                    {{ industry }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <!-- Operación -->
              <a-form-item
                v-bind="formItemLayout"
                label="Operación"
                class="form-item"
              >
                <a-select
                  v-decorator="[
                    'operation',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  mode="multiple"
                  placeholder="Selecciona aquí"
                >
                  <a-select-option
                    v-for="operation in operations"
                    :key="operation.key"
                  >
                    {{ operation.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="N° de colaboradores"
                class="form-item"
              >
                <a-select
                  v-decorator="[
                    'workers',
                    {
                      rules: [
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  placeholder="Selecciona aquí"
                >
                  <a-select-option
                    v-for="collaborator in collaborators"
                    :key="collaborator"
                  >
                    {{ collaborator }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Dirección"
                class="form-item"
              >
                <a-input
                  placeholder="Escribe aquì..."
                  v-decorator="[
                    'address',
                    {
                      rules: [
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item
                v-bind="formItemLayout"
                label="Web page"
                class="form-item"
              >
                <a-auto-complete
                  v-decorator="[
                    'webpage',
                    {
                      rules: [
                        {
                          whitespace: true,
                          message: 'No se aceptan espacios vacios',
                        },
                      ],
                    },
                  ]"
                  placeholder="Escribe aquí..."
                  @change="handleWebsiteChange"
                >
                  <template slot="dataSource">
                    <a-select-option
                      v-for="website in autoCompleteResult"
                      :key="website"
                    >
                      {{ website }}
                    </a-select-option>
                  </template>
                  <a-input />
                </a-auto-complete>
              </a-form-item>
            </div>
          </div>
        </div>
      </div>
      <!--el footer del formulario(botones)-->
      <div class="footer">
        <a-button
          @click="handleCancel"
          :disabled="hasNewValues(form.getFieldsValue()) && !newBanner"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          html-type="submit"
          style="margin-left: 8px"
          :loading="btnSubmit.loading"
          :disabled="hasNewValues(form.getFieldsValue()) && !newBanner"
          >Guardar cambios</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionMenuCompany',
  data: () => ({
    formItemLayout: {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    },
    tailFormItemLayout: {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    },
    disabled: {
      country: true,
      industry: true,
      operation: true,
      workers: true,
      address: true,
      webpage: true,
    },
    imageUrl: null,
    fileImage: null,
    loading: false,
    operations: [
      { value: 'Atención al cliente', key: 'ATC' },
      { value: 'Ventas', key: 'tele_ventas' },
      { value: 'Marketing', key: 'tele_marketing' },
      { value: 'Cobranzas', key: 'Cobranzas' },
    ],
    collaborators: ['1-10', '11-20', '21-50', '51-100', '100-200', '200+'],
    currentCompanyId: null,
    btnSubmit: {
      // boton de submit de formulario
      loading: false, // valor de loading
    },
    newBanner: false, // si se carga un nuevo banner
    autoCompleteResult: [], // para website
    checkPending: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-profile-company' })
  },
  created() {
    this.setValues()
  },
  computed: {
    ...mapGetters([
      'industries',
      'profile',
      'company',
      'countriesNotRepeatByName',
    ]),
  },
  methods: {
    ...mapActions(['updateCompanyProfile', 'getExistCompany']),
    /**
     * Comprueba si hay nuevos valores en el formulario
     * @param {Object} values // objeto de valores de todo el formulario
     */
    hasNewValues(values) {
      const fields = Object.keys(values).some((field) => {
        return values[field] !== this.company[field]
      })
      return !fields
    },
    setValues() {
      setTimeout(() => {
        this.currentCompanyId = this.company._id
        this.form.setFieldsValue({
          name: this.company.name,
          country: this.company.country?.name,
          industry: this.company.industry,
          operation: this.company.operation,
          workers: this.company.workers,
          address: this.company.address,
          webpage: this.company.webpage,
        })
        this.imageUrl = this.company.banner
          ? this.company.banner
          : require('@/app/shared/assets/bg-profile.png')
      }, 100)
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let companyProfile = values
          companyProfile.dialCode = this.countriesNotRepeatByName.find(
            (c) => c.nameSpanish === values.country
          )?.dialCode
          companyProfile.operation = values.operation.join(';')
          companyProfile.companyId = this.currentCompanyId

          if (this.newBanner && this.fileImage) {
            companyProfile.banner = this.fileImage
          }

          this.btnSubmit.loading = true
          const response = await this.updateCompanyProfile(companyProfile)
          this.btnSubmit.loading = false
          if (response.success) {
            this.newBanner = false
            this.fileImage = null

            this.$message.success(
              'Los datos de tu empresa se actualizaron exitosamente'
            )
          } else this.$message.error('Ocurrió un error')
        }
      })
    },
    handleShowEdit(refInput, nameDisabled) {
      this.disabled[nameDisabled] = false
      this.$refs[refInput].focus()
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png, jpeg o jpg')
      }
      const isLt2M = file.size / 1024 / 1024 <= 2
      if (!isLt2M) {
        this.$message.error('El tamaño máximo permitido es de 2MB')
      }
      return isJpgOrPng && isLt2M
    },
    /**
     * @param {Object} args
     * @param {*} args.action
     * @param {*} args.file
     * @param {*} args.onSuccess
     * @param {*} args.onError
     * @param {*} args.onProgress
     */
    selfUpload({ file }) {
      this.loading = true
      new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.imageUrl = fileReader.result
          resolve(fileReader.result)
          this.loading = false
          this.newBanner = true
        }
      })
    },
    handleCancel() {
      this.setValues()
      this.newBanner = false
    },
    handleWebsiteChange(value) {
      let autoCompleteResult
      if (!value) {
        autoCompleteResult = []
      } else {
        autoCompleteResult = ['.com', '.org', '.net'].map(
          (domain) => `${value}${domain}`
        )
      }
      this.autoCompleteResult = autoCompleteResult
    },
    setShadows(idMaster, idContainer, idContent) {
      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
    /**
     * Valida el nombre de la empresa
     */
    async validateNameCompany(rule, value, callback) {
      const nameCompany = this.company.name
      if (nameCompany === value) return callback()
      const response = await this.getExistCompany({ company: value })

      if (response.success && response.result === 'duplicated') {
        callback(new Error('Este nombre ya existe'))
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="sass">
.section-company
  height: 100%
  background-color: $white_000
  border-radius: 4px
  text-align: left
  padding-top: 40px
  color: $gray_dark_900
  border: 1px solid $gray_dark_000
  >h5
    margin-left: 40px
    color: $gray_dark_900
  .form
    height: calc( 100% - 96px )
    .master-container
      position: relative
      padding-top: 2px
      padding-bottom: 2px
      height: 100%
      .container
        overflow-y: auto
        height: 100%
        .content
          padding-left: 40px
          .description
            color: $gray_dark_700
          .upload-container
            margin-bottom: 32px
            display: flex
            flex-direction: row
            .text
              margin-left: 20px
              color: $gray_light_700
              .subtitle
                color: $gray_light_800
              p:not(.subtitle)
                color: $gray_light_700
            .upload
              position: relative
              cursor: pointer
              .overlay
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                height: 100%
                width: 100%
                opacity: 0
                transition: .3s ease
                background: rgba(0, 0, 0, 0.4)
                display: flex
                align-items: center
                justify-content: center
                border-radius: 4px
              &:hover
                .overlay
                  opacity: 1
          .form-items
            width: 500px
            .form-item
              .ant-form-item-label
                text-align: left
.footer
  border-top: 1px solid $gray_dark_100
  padding: 12px
  display: flex
  flex-direction: row
</style>
