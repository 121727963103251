<template>
  <div class="profile">
    <ProfileTemplate />
  </div>
</template>

<script>
import ProfileTemplate from '@/app/profile/components/templates/ProfileTemplate.vue'

export default {
  name: 'Profile',
  components: {
    ProfileTemplate,
  },
}
</script>
