<template>
  <i class="anticon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9455 2L21.9394 19.8891C22.0006 20.9919 21.1563 21.9357 20.0534 21.9969C20.0257 21.9982 20.0118 21.9988 19.9979 21.9992C19.9841 21.9996 19.9702 21.9997 19.9424 22H4.05664C2.95207 22 2.05664 21.1046 2.05664 20C2.05715 19.9445 2.05715 19.9445 2.05972 19.8891L3.05356 2H20.9455ZM4.94553 4L4.05664 20H19.9424L19.0536 4H4.94553ZM8.99954 6H6.99954V8.5C6.99954 10.9007 9.32278 13 11.9995 13C14.6763 13 16.9995 10.9007 16.9995 8.5V6H14.9995V8.5C14.9995 9.74837 13.6144 11 11.9995 11C10.3847 11 8.99954 9.74837 8.99954 8.5V6Z"
        fill="currentColor"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'IconShoppingBag',
}
</script>

<style scoped></style>
