<template>
  <div class="pay-plan-body">
    <a-row type="flex" class="pay__content" align="middle">
      <a-col
        class="testimony"
        :xs="{ span: 0 }"
        :sm="{ span: 0 }"
        :md="{ span: 0 }"
        :lg="{ span: 10 }"
        :xl="{ span: 10 }"
        :xxl="{ span: 10 }"
      >
        <h5 class="testimony-title heading-h5">
          {{ namePlan }} es asombroso, si no que te lo cuenten ellos
        </h5>
        <bubble
          v-for="testimony in testimonies"
          :key="testimony._id"
          :message="testimony.text"
          :subText="testimony.chief"
          :avatar="testimony.avatar"
        />
      </a-col>
      <a-col
        class="pay-form"
        :xs="{ span: 22 }"
        :sm="{ span: 20 }"
        :md="{ span: 24 }"
        :lg="{ span: 10 }"
        :xl="{ span: 11 }"
        :xxl="{ span: 10 }"
      >
        <h5 class="heading-h5">Comienza tu suscripción en {{ namePlan }}.</h5>
        <div class="credit-cards-container">
          <img
            :src="`/img/img_credit_cards/visa.png`"
            alt="visa"
            class="picture-card"
          />
          <img
            :src="`/img/img_credit_cards/mastercard.png`"
            alt="mastercard"
            class="picture-card"
          />
          <img
            :src="`/img/img_credit_cards/diners.png`"
            alt="diners club"
            class="picture-card"
          />
        </div>
        <p class="regular-14 gray-8">
          Para validar tu tarjeta, {{ app }} realizará un cobro de $3 que luego
          será reembolsado.
          <span v-if="false"> Más información <a href="">aquí</a></span>
        </p>
        <a-alert
          v-if="showError"
          :type="typeError"
          show-icon
          class="section__alert"
        >
          <p slot="message" class="mrg-bottom-0 text--left">
            {{ messageError }}. Si necesitas ayuda, envíanos un mail a
            {{ vari.WEB_EMAIL_SUPPORT }}
          </p>
        </a-alert>
        <a-form
          :form="form"
          layout="vertical"
          class="form"
          @submit.prevent="handleSubmit"
        >
          <a-form-item label="Nombres">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'names',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :size="inputs_size"
            />
          </a-form-item>
          <a-form-item label="Apellidos">
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'surnames',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :size="inputs_size"
            />
          </a-form-item>
          <a-form-item label="Número de tarjeta" has-feedback>
            <a-input
              placeholder="Escribe aquí..."
              v-decorator="[
                'number',
                {
                  rules: [
                    { required: true, message: 'Por favor rellene el campo' },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                    { validator: checkTypeCard },
                    {
                      pattern: /[0-9]/g,
                      message: 'Solo se aceptan números.',
                    },
                  ],
                },
              ]"
              :size="inputs_size"
            />
          </a-form-item>
          <a-row :gutter="[12]">
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :md="{ span: 24 }"
              :lg="{ span: 12 }"
              :xl="{ span: 12 }"
            >
              <a-form-item label="Fecha de vencimiento">
                <a-month-picker
                  :disabled-date="disabledDate"
                  placeholder="Selecciona la fecha"
                  v-decorator="[
                    'expirationDate',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor rellene el campo',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%"
                  :size="inputs_size"
                />
              </a-form-item>
            </a-col>
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :md="{ span: 24 }"
              :lg="{ span: 12 }"
              :xl="{ span: 12 }"
            >
              <a-form-item label="Código de seguridad (CVV)">
                <a-input-password
                  placeholder="Escribe aquí..."
                  v-decorator="[
                    'securityCode',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor rellene el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  :maxLength="4"
                  :size="inputs_size"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <div class="pay-from__footer">
            <div class="pay-form__total">
              <p class="body3 mrg-bottom-0">
                Total a pagar por el {{ namePlan }}
              </p>
              <h6 class="heading-h6 mrg-bottom-0">${{ price }}</h6>
            </div>
            <a-button
              html-type="submit"
              type="primary"
              class="pay__button"
              :loading="loading"
              :size="buttons_size"
              >Pagar {{ namePlan }}</a-button
            >

            <a-button
              @click="handleCancel"
              :disabled="loading"
              type="link"
              class="pay__cancel-button"
              :size="buttons_size"
              >Continuar con el plan free</a-button
            >
          </div>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Bubble from '@/app/shared/components/molecules/Bubble'
import moment from 'moment'
import vari from '@/app/shared/utils/variables'

export default {
  components: { Bubble },
  props: {
    username: {
      type: String,
      required: false,
    },
    price: {
      type: [Number, String],
      required: false,
      default: 20.0,
    },
    namePlan: {
      type: String,
      required: false,
      default: 'Free',
    },
    idPlan: {
      type: String,
      required: false,
    },
    fromSocialLogin: {
      type: Boolean,
      required: false,
      default: false,
    },
    token: {
      type: String,
      required: false,
    },
    testimonies: {
      type: Array,
      required: false,
      default: () => [],
    },
    typeError: {
      type: String,
      default: 'error',
      require: false,
    },
  },
  data: () => ({
    vari,
    app: process.env.VUE_APP_NAME,
    loading: false,
    showError: false,
    messageError: '',
    visible: false,
    modalSettingCard: {
      styles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px 32px 0',
        // height: '36em',
      },
    },
    checkPending: null,
    paymentMethod: '', // metodo de pago
    cardSettings: {},
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-settings-credit' })
  },
  computed: {
    ...mapGetters(['buttons_size', 'inputs_size']),
  },
  methods: {
    ...mapActions(['paymentPlan']),
    // si no se copio correctamente
    onError() {
      this.$message.error('Fallo')
    },
    // si se copio correctamente
    onCopyUserId() {
      this.$message.success('ID de usuario copiado')
    },

    onOk() {
      this.$emit('onOk')
    },
    checkTypeCard(rule, value, callback) {
      clearTimeout(this.checkPending)
      this.checkPending = setTimeout(() => {
        const regexDiners =
          /(^[35](?:0[0-5]|[68][0-9])[0-9]{11}$)|(^30[0-5]{11}$)|(^3095(\\d{10})$)|(^36{12}$)|(^3[89](\\d{12})$)/gm
        const regexVisa = /^(4)(\d{12}|\d{15})$|^(606374\d{10}$)/gm
        const regexMastercard =
          /^(5[1-5]\d{14}$)|^(2(?:2(?:2[1-9]|[3-9]\d)[3-6]\d\d|7(?:[01]\d|20))\d{12}$)/gm
        // console.log(regexDiners.test(value))
        if (regexDiners.test(value)) {
          this.paymentMethod = 'dinners'
          callback()
        } else if (regexVisa.test(value)) {
          this.paymentMethod = 'visa'
          callback()
        } else if (regexMastercard.test(value)) {
          this.paymentMethod = 'mastercard'
          callback()
        } else {
          callback(new Error('No es una tarjeta valida'))
        }
      }, 500)
    },
    /**Submit del formulario, trae los datos de configuracion de una tarjeta */
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.cardSettings = {
            isNew: true,
            name: values.names,
            surnames: values.surnames,
            number: values.number,
            expirationDate: values.expirationDate,
            paymentMethod: this.paymentMethod,
            securityCode: values.securityCode,
          }
          this.handlePaymentPlan(this.cardSettings, false)
        }
      })
    },
    /**Cancela el pago y continua con el plan free */
    handleCancel() {
      this.handlePaymentPlan(this.cardSettings, true)
    },
    /**
     * Funcion de pagar plan
     */
    async handlePaymentPlan(settings, canceled) {
      this.loading = true
      const token = this.$route.query.token || this.token
      const purchase = {
        products: [
          {
            idPlan: this.idPlan,
            totalUsers: 1,
            price: this.price,
            type: 'plan',
            total: this.price,
            title: this.namePlan,
          },
        ],
        total: this.price,
      }
      this.showError = false
      const response = await this.paymentPlan({
        token: token,
        canceled: canceled,
        card: settings,
        purchase: purchase,
        fromSocialLogin: this.fromSocialLogin,
      })
      this.loading = false
      if (response?.success) {
        this.$emit('onSuccess', response?.token)
      } else {
        this.showError = true
        this.messageError = response.detail
          ? `No pudimos procesar tu pago 😓. ${response.detail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
          : 'No pudimos procesar tu pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta'
      }
    },
    /**Resetea todos los campos del formualrio */
    resetForm() {
      this.form.resetFields()
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day').subtract(0, 'month')
    },
  },
}
</script>

<style lang="sass" scoped>
$avatar_size: 32px

.gray-8
  color: $gray-8

.pay-plan-body
  color: $gray_dark_900
  .testimony
    flex-flow: column
    .testimony-title
      text-align: left
      margin-bottom: 20px
  .pay-form
    display: flex
    flex-flow: column
    align-items: center
    .form
      width: 100%
    .credit-cards-container
      margin-bottom: 12px
      display: flex
  .description
    color: $gray_dark_800
    margin-bottom: 20px
.pay-form__total
  display: flex
  justify-content: space-between
  width: 100%
  margin-bottom: 16px
  @include xs
    margin-bottom: 24px
  @include sm
    margin-bottom: 24px
  @include md
    margin-bottom: 24px

.pay__button
  width: 100%
.pay__cancel-buttonx
  margin-top: 5px
  width: 100%
  color: $gray_dark_500
.picture-card
  margin-right: 8px
.section__alert
  margin-bottom: 20px
.pay__content
  +flex(row, space-between, flex-start)
  @include xs
    +flex(row, center, flex-start)
  @include sm
    +flex(row, center, flex-start)
  @include md
    +flex(row, center, flex-start)
  @include lg
    padding: 0 10em
  @include xl
    padding: 0 12em
  @include xxl
    padding: 0 16em
</style>
