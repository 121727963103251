<template>
  <authTemplate>
    <div slot="body" class="body">
      <token-status-section v-if="isLoading" state="load" />
      <div v-else-if="isValid" class="contain">
        <pay-plan
          v-if="!goToNext"
          :name-plan="payPlan.pricing.name"
          :price="payPlan.pricing.price"
          :id-plan="payPlan.pricing._id"
          :testimonies="testimonies"
          @onSuccess="() => (goToNext = true)"
        />
        <account-found v-if="goToNext" :username="username" :email="email" />
      </div>
      <token-status-section
        v-else-if="isExpired"
        state="expired"
        :email="email"
        :onRequest="reSendVerificationEmail"
      />
      <token-status-section v-else-if="isVerify" state="verified" />
      <token-status-section v-else-if="!isValid" state="fail" />
    </div>
  </authTemplate>
</template>

<script>
import AccountFound from '@/app/signup/components/organisms/activeAccount/AccountFound.vue'
import PayPlan from '@/app/signup/components/organisms/activeAccount/PayPlan.vue'
import TokenStatusSection from '@/app/shared/components/sections/TokenStatusSection'
import { mapActions } from 'vuex'
import AuthTemplate from '@/app/shared/components/templates/AuthTemplate'

export default {
  name: 'ActiveAccountTemplate',
  components: {
    AccountFound,
    PayPlan,
    TokenStatusSection,
    AuthTemplate,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    username: '', // valor de id user
    isLoading: true,
    isExpired: false,
    isValid: false,
    isVerify: false,
    goToNext: false,
    payPlan: {}, // el plan por el que se va a pagar
    email: '', // email
  }),
  created() {
    this.setVerificationInfo()
  },
  computed: {
    /**
     * Testimonios segun plan
     */
    testimonies() {
      if (!this.payPlan.pricing) return []
      const license = this.payPlan.pricing.name?.toLowerCase().split(' ')[1]
      switch (license) {
        case 'advanced':
          return [
            {
              id: 1,
              avatar: '/img/clients/kontigo-logo.jpg',
              chief: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
              text: `El API de WhatsApp ha mejorado nuestra atención al cliente, ya que podemos comunicarnos con nuestros clientes por su App preferida. Las acciones personalizadas por este canal nos ha permitido mejorar nuestros tiempos de respuesta.`,
            },
            {
              id: 2,
              avatar: '/img/clients/impruneta.png',
              chief: 'Anthony Huaman, Experiencia Omnicanal de Impruneta',
              text: `Con ${process.env.VUE_APP_NAME}, hemos logrado centralizar la totalidad de nuestros mensajes en una sola vía. Esto nos ha dado más dinamización en la gestión y, gracias a su intuitividad, monitoreamos fácilmente nuestra producción en tiempo real.`,
            },
          ]

        case 'business':
          return [
            {
              id: 1,
              avatar: '/img/clients/kontigo-logo.jpg',
              chief: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
              text: `${process.env.VUE_APP_NAME} nos ayuda muchísimo en la gestión de los casos de nuestros clientes, así como la asignación según los tipos. Además, nos ha facilitado la comunicación por WhatsApp y Messenger, mejorando en calidad y tiempos de respuesta.`,
            },
            {
              id: 2,
              avatar: '/img/clients/factora.png',
              chief: 'Anthony Huaman, Experiencia Omnicanal de Factora',
              text: `El API de WhatsApp Business en ${process.env.VUE_APP_NAME} nos ha facilitado la comunicación con nuestros clientes. Tener este canal en una plataforma y no depender de un celular nos ha permitido tener métricas, contabilizar las conversaciones y tiempos de nuestra operación.`,
            },
          ]

        default:
          return []
      }
    },
  },
  methods: {
    ...mapActions([
      'getVerificationInfo',
      'paymentPlan',
      'reSendVerificationEmail',
    ]),

    async setVerificationInfo() {
      const token = this.$route.query.token
      const response = await this.getVerificationInfo(token)
      console.log({ response })
      this.isLoading = false
      if (response.success) {
        this.payPlan = response
        this.username = response.username
        this.email = response.email
        this.isValid = true // es valido para cambiar la contraseña
        this.goToNext = response.pricing ? false : true
      } else {
        switch (response.result) {
          case 'ready verify':
            this.isVerify = true // ya esta verificado
            break
          case 'expired':
            this.isExpired = true // ya expiro
            this.email = response.email
        }
      }
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="sass" scoped>
.color-gray-dark-800
  color: $gray_dark_800
.body
  display: flex
  justify-content: center
  .loading-token
    .title
      margin-top: 24px
      color: $gray_dark_700
</style>
