export const DATA_TEST = {
  TICKET: {
    CREATE_TICKET: {
      OPEN_MODAL_BUTTON: 'create-ticket-open-modal-button',
      FORM: 'create-ticket-form',
      NEXT_BUTTON: 'create-ticket-next-button',
      PHONE_TEXTFIELD: 'create-ticket-phone-textfield',
      QUEUE_SELECT: 'create-ticket-queue-select',
      TEMPLATE_SELECT: 'create-ticket-template-select',
      DYNAMIC_TEXTFIELD: 'create-ticket-dynamic-textfield',
      SEND_BUTTON: 'create-ticket-send-button',
    },
    LIST: {
      CARD_ITEM: 'ticket-list-card-item',
      CHECK_CARD_ITEM: 'ticket-list-check-card-item',
      OPEN_FILTER_BUTTON: 'ticket-list-open-filter-button',
      APPLY_FILTER_BUTTON: 'ticket-list-apply-filter-button',
    },
    RESOLVE: {
      OPEN_MODAL_BUTTON: 'resolve-ticket-open-modal-button',
      COMMENT_TEXT_AREA: 'resolve-ticket-comment-text-area',
      BUTTON: 'resolve-ticket-button',
    },
    TRANSFER: {
      SHOW_OPTIONS_ICON: 'transfer-ticket-show-options-icon',
      SHOW_AGENT_SEARCH_MODAL: 'transfer-ticket-show-agent-search-modal',
      SEARCH_AGENT_INPUT: 'transfer-ticket-search-agent-input',
      SELECT_AGENT: 'transfer-ticket-select-agent',
    },
  },
  CHATBOT: {
    EDIT: {
      BUTTON: 'edit-chatbot-button',
      OPTIONS_BOARD: 'edit-chatbot-options-board',
      ADD_OPTION_BUTTON: 'edit-chatbot-add-option-button',
      OPTION_CARD: 'edit-chatbot-option-card',
      RETURN_TO_LIST_BUTTON: 'edit-chatbot-return-to-list-button',
      OPTION_CONTENT_INPUT: 'edit-chatbot-option-content-input',
      SAVE_OPTION_CHANGES_BUTTON: 'edit-chatbot-save-option-changes-button',
      REMOVE_OPTION_BUTTON: 'edit-chatbot-remove-option-button',
      TOGGLE_POSITION_BUTTON: 'edit-chatbot-toggle-position-button',
      TOGGLE_POSITION_OPTION: 'edit-chatbot-toggle-position-option',
      ADD_MULTIPLE_OPTIONS_RADIO_BUTTON:
        'edit-chatbot-add-multiple-options-radio-button',
    },
  },
  SHARED: {
    PREVIEW_PHONE_MESSAGE: 'preview-phone-message',
  },
}
