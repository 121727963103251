<template>
  <a-row class="list" :gutter="[12, 4]">
    <a-col
      v-for="emoji in emojis"
      :key="emoji.key"
      class="list_item"
      @click="$emit('onSelect', emoji.value)"
      :span="4"
    >
      {{ emoji.value }}
    </a-col>
  </a-row>
</template>

<script>
import emojis from '@/app/tickets/utils/emoji.json'

export default {
  name: 'EmojiList',
  data: () => ({
    emojis: emojis.peoples.people,
  }),
}
</script>

<style lang="sass" scoped>
.list
  color: white
  &_item
    font-size: 20px
    cursor: pointer
    text-align: center
    &:hover
      background-color: $gray_5
</style>
