<template>
  <nav class="sidebar full-height display-flex align--center direction-column">
    <ticket-menu-item
      v-for="(item, index) in menuItemsTicket"
      :key="`menu-item${index}`"
      :icon="item.icon"
      :title="item.title"
      :route="item.route"
      :image="item.image"
      :icon-component="item.component"
      :avatar="item.avatar"
      :class="{ 'sidebar__menu-avatar': item.avatar }"
      class="sidebar__menu-item"
    >
    </ticket-menu-item>
  </nav>
</template>

<script>
import TicketMenuItem from '@/app/tickets/components/molecules/TicketMenuItem'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'SubSidebar',
  components: {
    TicketMenuItem,
  },
  data: () => ({}),
  mixins: [supportMixin],
}
</script>

<style lang="sass" scoped>
.sidebar
  min-height: 300px
  background-color: $gray_1
  border-radius: 4px
  padding: 20px 8px
.sidebar__menu-avatar
  margin-bottom: 32px!important
.sidebar__menu-item
  margin-bottom: 14px
</style>
