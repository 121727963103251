import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_LOGIN_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },

  /**
   * SETEA SI HAY UNA CUENTA LOGUEADA O NO
   * @param {*} state
   * @param {String} token Token de acceso
   */
  SET_TOKEN(state, token) {
    if (token) state.token = token
    else state.token = null
  },
  /**
   * SETEA SI HAY UNA CUENTA LOGUEADA O NO
   * @param {*} state
   * @param {Boolean} loggedIn
   */
  SET_LOGGEDIN(state, loggedIn) {
    if (state.token) state.loggedIn = loggedIn
    else state.loggedIn = false
  },
  SET_MODAL_SESSIONS(state, payload) {
    state.modalSessions = payload
  },
  /**Setea propiedades del modal upgrade
   * @param {Boolean} visible
   * @param {String} type - enterprise
   */
  SET_MODAL_UPGRADE(state, { visible, type }) {
    state.modalUpgrade.visible = visible
    state.modalUpgrade.type = type
  },
  /**
   * Setea propiedades al modal basico, usado frecuentemente para el saldo
   * @param {*} state
   * @param {Object} modal
   * @param {Boolean} modal.visible
   * @param {String} modal.title
   * @param {String} modal.subtitle
   * @param {Boolean} modal.description
   * @param {Object} modal.icon
   * @param {String} modal.icon.type // Puede ser anticon, socialicon, evericon
   * @param {String} modal.icon.path_or_name // Si el tipo es evericon este propiedad tiene que ser un archivo que este en la ruta '@/app/commons/assets/'
   * @param {String} modal.routeNameButtonOk // Nombre de la ruta a donde redigirá cuando se presione el boton principal del modal
   * @param {String} modal.id
   * @return {Void}
   */
  SET_BASIC_MODAL(
    state,
    {
      visible,
      title,
      subtitle,
      description,
      nameButton,
      icon,
      routeNameButtonOk,
      id,
    }
  ) {
    state.basicModal.visible = visible
    state.basicModal.title = title
    state.basicModal.subtitle = subtitle
    state.basicModal.description = description
    state.basicModal.nameButton = nameButton
    state.basicModal.icon = icon
    state.basicModal.routeNameButtonOk = routeNameButtonOk
    state.basicModal.id = id
  },
}

export default mutations
