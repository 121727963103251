<template>
  <div class="drawer-footer">
    <a-button
      v-if="showCancel"
      :style="{ marginRight: '8px' }"
      @click="$emit('onCancel')"
    >
      {{ cancelText }}
    </a-button>
    <a-button type="primary" @click="$emit('onOk')" :loading="okLoading">
      {{ okText }}
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'BasicFooter',
  props: {
    cancelText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    okText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    okLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.drawer-footer
  position: absolute
  right: 0
  bottom: 0
  width: 100%
  border-top: 1px solid $gray_5
  padding: 10px 16px
  background: $white_000
  text-align: right
  z-index: 1
</style>
