import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_CLIENT_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },
  /**
   * Setea los datos de un cliente
   * @param {*} state
   * @param {Object} client Objeto de un cliente
   */
  SET_CLIENT(state, client) {
    if (!client) return (state.client = null)
    // client.birth = client.birth ? new Date(client.birth) : null
    state.client = client
  },
  /**
   * Setea el telefono de un cliente
   * @param {*} state
   * @param {String} phone
   */
  SET_CLIENT_PHONE(state, phone) {
    if (!state.client || !phone) return
    // client.birth = client.birth ? new Date(client.birth) : null
    state.client.phone = phone
  },
  /**
   * Setea el valor de si esta cargando los tickets anteriores
   * @param {*} state
   * @param {Boolean} isLoadingOldTickets // Se mostrará la sección de tickets anteriores en lugar del perfil?
   */
  SET_IS_LOADING_OLD_TICKETS(state, isLoadingOldTickets) {
    state.isLoadingOldTickets = isLoadingOldTickets
  },
  /**
   * Setea el valor de si mostrara el chat del ticket seleccionado
   * @param {*} state
   * @param {Boolean} isLoadingSelectedTicket // Se mostrará la sección de tickets anteriores en lugar del perfil?
   */
  SET_IS_LOADING_SELECTED_TICKET(state, isLoadingSelectedTicket) {
    state.isLoadingSelectedTicket = isLoadingSelectedTicket
  },
}

export default mutations
