export default {
  methods: {
    /**
     * Muestra el modal de confirmacion para bloquear o desbloquear
     * @param {Object} values
     * @param {String} values._id
     * @param {Object[]} values.channelCompanies
     * @param {String} action - 'block', 'unblock'
     * @param {Function} okCallback
     */
    showBlacklistConfirmation(values, action, okCallback) {
      const apiClientId = values.apiClientId

      const text = {
        title: '',
        content: '',
        okButton: '',
      }
      if (action === 'block') {
        text.title = `¿Estás seguro de bloquear el número ${apiClientId}?`
        text.content = `Recuerda que los números bloqueados no podrán enviar mensajes a tu empresa. Además, sus tickets activos se finalizarán. Puedes desbloquearlo más adelante.`
        text.okButton = 'Bloquear'
      } else if (action === 'unblock') {
        const channels = values.channelCompanies.map((channel) => channel.title)
        const channelsTitle = channels.join(', ')
        text.title = `¿Estás seguro de desbloquear el número ${values.apiClientId}?`
        text.content = `Recuerda que este número podrá volverse a comunicarse con tu empresa por los siguientes canales: ${channelsTitle}`
        text.okButton = 'Desbloquear'
      }

      this.$confirm({
        title: text.title,
        content: text.content,
        okText: text.okButton,
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          await okCallback()
        },
      })
    },
  },
}
