<template>
  <div
    :class="{
      'color-pick-selectable display-flex justify-content--center align--center':
        selectable,
      'color-pick': !selectable,
    }"
    @click="handleClick"
    :style="{ ...masterStyles }"
  >
    <div :class="cssClasses" :style="{ backgroundColor: color, ...pickStyles }">
      <a-icon v-if="hasCheck && isSelected" type="check" class="pick__icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPick',
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'default', // [default, bold, custom]
    },
    pickStyles: {
      type: [Object, String],
    },
    masterStyles: {
      type: [Object, String],
    },
    selectable: { type: Boolean, required: false, default: false },
    shape: {
      type: String,
      required: false,
      default: 'square', // [square, round]
    },
    hasCheck: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
  },
  computed: {
    cssClasses() {
      const classSelected =
        this.isSelected && this.selectable ? 'pick--selected' : ''
      const classes = `pick--${this.shape} pick--${this.size} ${classSelected} display-flex align--center justify-content--center cursor-pointer`
      return classes
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
      this.$emit('onChange', this.color)
    },
  },
}
</script>

<style lang="sass" scoped>
$custom-height: 20px
$custom-width: 20px

.color-pick
  &>*
    border-radius: 4px
.pick
  &--bold
    height: 16px
    width: 32px
  &--default
    height: 12px
    width: 40px
  &--custom
    height: $custom-height
    width: $custom-width
.color-pick-selectable
  height: calc(#{$custom-height} + 14px)
  width: calc(#{$custom-width} + 24px)
.pick--selected
  height: calc(#{$custom-height} + 14px)
  width: calc(#{$custom-width} + 14px)
.pick--round
  border-radius: 50px
  transition: all 0.5s
  &:hover
    height: calc(#{$custom-height} + 14px)
    width: calc(#{$custom-width} + 14px)
.pick__icon
  color: $white_000
</style>
