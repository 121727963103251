<template>
  <tabs-card-template :default-tab-selected="tabSelected" :tabs="tabs" />
</template>
<script>
import TabsCardTemplate from '@/app/shared/components/templates/TabsCardTemplate.vue'

export default {
  name: 'ChatbotsTemplate',
  components: {
    TabsCardTemplate,
  },
  data: () => ({
    tabSelected: 'all-chatbots',
    tabs: [
      { key: 'all-chatbots', title: 'Chatbots' },
      { key: 'manage-chatbots-vars', title: 'Gestor de variables' },
    ],
  }),
  mounted() {
    this.tabSelected = this.$route.name
  },
}
</script>

<style lang="sass" scoped></style>
