import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**  Obtener archivos multimedia de un ticket */
  async listMultimedia(context) {
    try {
      if (!context.getters.ticketSelected) return
      const idTicket = context.getters.ticketSelected._id
      const response = await axios.get(
        `${vari.UHR}/agent/multimedia/${idTicket}`
      )
      const multimedias = response.data
      await context.commit('SET_MULTIMEDIAS', multimedias)
    } catch (error) {
      console.error('[multimediaModule][listMultimedia]', error)
    }
  },
}

export default actions
