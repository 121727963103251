<template>
  <section class="section">
    <header class="section__header display-flex justify--between">
      <header-module
        title="Market"
        tipography="semibold-24"
        class="section__title"
        :link="`${vari.WEB_DOC}precios-y-pagos/esquema-costos-ticker/`"
        text-after-link="para conocer mejor nuestro esquema de costos fijos y variables."
        textLink="Click aquí"
        positionTooltip="right"
      />
      <div v-if="companyPricing" class="display-flex align--center">
        <div
          v-for="(pack, index) in companyPricing.packs.filter(
            (pack) => pack.image !== 'SMS'
          )"
          :key="index"
          class="display-flex align--center"
        >
          <social-icon
            :type="pack.image.toLowerCase()"
            :size="20"
            class="section__social-icon"
          />
          <span v-if="pack.image === 'whatsapp'">{{ pack.left }} </span>
          <a-tooltip v-else title="Ver más detalles">
            <a
              class="regular-16 mrg-bottom-0"
              @click="modalCountries.visible = true"
            >
              {{ pack.left }}
            </a>
          </a-tooltip>
          <a
            class="regular-16 section__top-link"
            :href="`#${pack.image}`"
            @click="handleGoToPack"
          >
            Recargar</a
          >
          <a-divider
            v-if="index !== companyPricing.packs.length - 1"
            type="vertical"
          />
        </div>
        <h5 class="semibold-14 section-name-plan mrg-bottom-0">
          {{ companyPricing.plan.name.toUpperCase() }}
        </h5>
        <a-badge :dot="showBadge">
          <a-icon
            type="shopping-cart"
            class="section__icon-cart"
            @click="$emit('onShowCart')"
          />
        </a-badge>
      </div>
    </header>
    <a-tabs
      v-model="tabSelected"
      size="small"
      :tabBarStyle="{ margin: 'auto 24px 16px 24px' }"
    >
      <a-tab-pane v-for="tab in tabs" :key="tab.key">
        <span slot="tab">
          <a-icon :type="tab.icon" />
          {{ tab.tab }}
        </span>

        <custom-section-scroll
          :masterStyles="{
            height: 'calc(100vh - 147px)',
            marginTop: '-16.5px',
          }"
          :content-styles="{
            paddingTop: '32px',
          }"
        >
          <h5 class="semibold-24">{{ tab.title }}</h5>
          <p class="regular-20">{{ tab.subtitle }}</p>
          <!--si la key de tab es igual a plans, se mostrará todos los planes -->
          <template v-if="tab.key === 'plans'">
            <a-row
              :gutter="[0, 40]"
              type="flex"
              justify="center"
              style="margin: -0px -0px 32px -0px"
              v-if="pricings && companyPricing"
              class="section__row-first"
            >
              <template v-for="(plan, index) in pricings">
                <a-col :span="spanColumn(plan.key)" :key="plan._id" :offset="1">
                  <a-tooltip>
                    <template v-if="isPlanEnterprise" slot="title">
                      Comunícate con soporte para poder realizar el proceso de
                      downgrade.
                    </template>
                    <CardPlan
                      :name-plan="namePlan(plan.key)"
                      :price="formattedPrice(plan.key, plan.price)"
                      :idPlan="plan._id"
                      :showCoinSymbol="plan.price === null ? false : true"
                      :benefits="plan.features.es"
                      :enterprise="plan.key === 'enterprise' ? true : false"
                      :current-plan="
                        plan._id === companyPricing.pricingId ? true : false
                      "
                      :recommend="indexCurrentPlan + 1 === index ? true : false"
                      :is-downgrade="index < indexCurrentPlan ? true : false"
                      :is-cancel-downgrade="
                        companyPricing.downgrade &&
                        companyPricing.downgrade.planToId === plan._id
                          ? true
                          : false
                      "
                      :is-active-downgrade="
                        companyPricing.downgrade &&
                        companyPricing.downgrade.planToId
                          ? false
                          : true
                      "
                      @downgrade="downgrade"
                      @upgrade="upgrade(plan._id)"
                      @showContact="showModalEnterprise"
                      :picture="plan.key"
                      :two-columns="isTwoColumns(plan.key)"
                    />
                  </a-tooltip>
                </a-col>
              </template>
            </a-row>
            <a
              class="section__link"
              :href="`${vari.WEB_APP}precios/`"
              target="_blank"
              rel="noopener noreferrer"
              ><u>Ver comparación completa</u></a
            >
          </template>
          <!--lista de packetes-->
          <template v-else-if="tab.key === 'packets'">
            <template v-if="allowRechargeWba && wspPack">
              <a-row
                :gutter="[20, 20]"
                type="flex"
                justify="center"
                style="margin: -0px"
                id="whatsapp"
              >
                <a-col :span="20" :key="wspPack._id">
                  <CardPacket
                    :title-packet="wspPack.subtitle.es"
                    :name-packet="wspPack.name"
                    :description="wspPack.description.es"
                    :list-prices="wspPack.presentations"
                    :id-pack="wspPack._id"
                    :subType="wspPack.subType"
                    @addPurchase="addPurchase"
                    :picture="wspPack.picture"
                    order="reverse"
                  />
                </a-col>
              </a-row>
              <a-divider class="section__divider" orientation="center" />
            </template>
            <a-row
              :gutter="[20, 20]"
              type="flex"
              justify="center"
              style="margin: -0px"
              v-if="smsPack"
              id="SMS"
            >
              <a-col :span="20" :key="smsPack._id">
                <CardPacket
                  :title-packet="smsPack.subtitle.es"
                  :name-packet="smsPack.name"
                  :description="smsPack.description.es"
                  :list-prices="smsPack.countries"
                  :id-pack="smsPack._id"
                  type="sms"
                  @addPurchase="addPurchase"
                  :picture="smsPack.picture"
                />
              </a-col>
            </a-row>
          </template>
          <banner-support class="section__banner" />
          <a
            v-if="vari.WEB"
            class="section__link"
            :href="`${vari.WEB}terminos-condiciones-ticker/`"
            target="_blank"
            rel="noopener noreferrer"
            ><u
              >Ver nuestros términos y condiciones sobre nuestros productos</u
            ></a
          >
        </custom-section-scroll>
      </a-tab-pane>
    </a-tabs>
    <modal-countries
      :visible="modalCountries.visible"
      @onCancel="modalCountries.visible = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardPlan from '@/app/shop/components/cards/CardPlan'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import BannerSupport from '@/app/shop/components/market/BannerSupport'
import CardPacket from '@/app/shop/components/cards/CardPacket'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import transformNamePlan from '@/app/shared/mixins/transform'
import ModalCountries from '@/app/shop/components/spendings/ModalCountries'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
const SMS = 'SMS'
const WHATSAPP = 'Recarga'

export default {
  name: 'SectionMarket',
  mixins: [attemptMixin, transformNamePlan, supportMixin],
  components: {
    CardPlan,
    CustomSectionScroll,
    BannerSupport,
    CardPacket,
    SocialIcon,
    ModalCountries,
    HeaderModule,
  },
  data: () => ({
    vari,
    app: process.env.VUE_APP_NAME,
    tabSelected: 'plans',
    modalCountries: { visible: false },
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'profile'
      if (this.$route.params.tabKey)
        this.defaultActiveKey = this.$route.params.tabKey
      else if (!this.hasRequestedWsp) this.defaultActiveKey = 'plans'
      this.getCompanyPricing()
    })
  },
  computed: {
    ...mapGetters([
      'pricings',
      'companyPricing',
      'profile',
      'products',
      'purchase',
      'company',
      'isPlanEnterprise',
    ]),

    /**
     * @returns {Number}
     */
    indexCurrentPlan() {
      if (!this.pricings || this.pricings.length === 0) return []
      const indexCurrentPlan = this.pricings.findIndex(
        (princing) => princing._id === this.companyPricing.pricingId
      )
      return indexCurrentPlan
    },
    /**
     * @returns {Object} pack de sms
     */
    smsPack() {
      const pack = this.products.find((product) => product.name === SMS)
      if (pack !== undefined) {
        pack.picture = 'pack-sms.png'
        pack.icon = {
          custom: true,
          social: 'sms',
        }
      }
      return pack
    },
    /**
     * @returns {Object} pack de whatsapp
     */
    wspPack() {
      const pack = this.products.find((product) => product.name === WHATSAPP)
      if (pack !== undefined) {
        pack.picture = 'pack-whatsapp.png'
        pack.icon = {
          custom: true,
          social: 'whatsapp',
        }
      }
      return pack
    },
    /**
     * @returns {Boolean}
     */
    hasRequestedWsp() {
      if (!this.companyPricing) return false
      return this.companyPricing.packs.some((pack) => pack.image === 'whatsapp')
    },
    /**
     * @returns {Boolean} - si tiene compras en el carro
     */
    showBadge() {
      return this.purchase.products.length > 0
    },
    tabs() {
      return [
        {
          key: 'plans',
          title: 'Adquiere el plan que más te convenga',
          tab: 'Comprar planes',
          subtitle: `Te recomendamos la mejor licencia de ${this.app} según tu negocio.`,
          icon: 'shop',
        },
        {
          key: 'packets',
          title: 'Paga solo por lo que consumirás',
          tab: 'Comprar paquetes',
          subtitle:
            'Compra la cantidad de productos que necesites para no perder comunicación con tus clientes.',
          icon: 'shopping',
        },
      ]
    },
  },
  methods: {
    ...mapActions(['getCompanyPricing', 'getCompany']),
    ...mapMutations(['SET_MODAL_UPGRADE']),

    /**
     * @param {String} _id Id del plan
     */
    upgrade(_id) {
      const plan = this.pricings.find((plan) => plan._id === _id)
      plan.type = 'plan'
      this.$emit('addToCart', plan)
    },

    /**
     * @param {Object} planCard
     * @param {String} planCard.typeAction
     * @param {String} planCard.idPlan Id del plan
     * @param {Number} planCard.price
     * @param {Boolean} planCard.enterprise
     */
    downgrade(planCard) {
      const plan = this.pricings.find((plan) => plan._id === planCard.idPlan)
      if (planCard.typeAction === 'downgrade') {
        this.$emit('downgrade', plan, planCard.typeAction)
      } else if (planCard.typeAction === 'canceldown') {
        this.$emit('cancelDowngrade', plan, planCard.typeAction)
      }

      this.getCompanyPricing()
    },
    /**
     * Mostrar el modal del contacto enterprise
     */
    showModalEnterprise() {
      // this.modalEnterprise.visible = true
      this.SET_MODAL_UPGRADE({ visible: true, type: 'enterprise' })
    },
    /**
     * Nombre de plan
     * @param {String} - id del plan
     * @returns {String} -nombre del plan
     */
    namePlan(key) {
      if (key !== 'enterprise') {
        return this.transformNamePlan(key)
      } else if (key === 'enterprise')
        return 'Sube a PLAN ENTERPRISE y disfruta de  todos nuestros beneficios'
    },
    /**
     * Formatea el precio según el plan
     * @param {String} key key del plan
     * @param {Number} price precio del plan
     * @returns {}
     */
    formattedPrice(key, price) {
      switch (key) {
        case 'free':
          return { custom: true, content: 'Sin costo alguno' }
        case 'advanced':
          return price
        case 'business':
          return price
        case 'enterprise':
          return 'Personalizado'
      }
    },
    /**
     * Si es entreprise tiene dos columnas
     * @returns {Boolean}
     */
    isTwoColumns(key) {
      return key === 'enterprise' ? true : false
    },
    /**
     * Span de columna
     * @returns {Number}
     */
    spanColumn(key) {
      return key === 'enterprise' ? 20 : 6
    },
    /**
     * Emite para añadir el producto a la compra
     */
    addPurchase(item) {
      this.$emit('addToCart', item)
    },
    /**
     * Redirige hacia la seccion del pack
     */
    handleGoToPack(idPack) {
      if (this.tabSelected === 'plans') this.tabSelected = 'packets'
      else window.assign(`#${idPack}`, 'noopener')
    },
  },
}
</script>

<style lang="sass" scoped>

.section
  padding: 24px 0px 24px 0px
.section__icon-cart
  font-size: 24px
  margin-left: 12px
.section__header
  margin: 0px 24px 16px 24px
.section__link
.section__banner
  margin-top: 66px
  margin-bottom: 40px
.section-name-plan
  margin-left: 32px
.section__top-link
  margin-left: 8px
.section__social-icon
  margin-right: 4px
.section__divider
  margin: 60px auto 60px auto
  width: calc(100% - 140px)
  min-width: calc(100% - 140px)
// .section__row-first
//   margin-bottom: 32px
</style>
