import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import format from '@/app/shared/utils/format'
import compare from '@/app/shared/utils/compare'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Obtiene las campañas de acuerdo a los parametros que se pasen
   * @param {} context
   * @param {Number} page
   * @param {Number} limit
   * @param {String} status
   * @param {String} lineId
   * @param {String} templateId
   * @param {String} search
   * @param {String} userId
   * @returns
   */
  async getCompanyStrategies(
    context,
    {
      page,
      limit = 12,
      status,
      lineId,
      templateId,
      search,
      userId,
      dateRange,
      typeTemplate,
    }
  ) {
    try {
      const params = {
        page,
        limit,
        status,
        lineId,
        templateId,
        search,
        userId,
        typeTemplate,
        startCreatedAt: JSON.parse(JSON.stringify(dateRange[0])),
        endCreatedAt: JSON.parse(JSON.stringify(dateRange[1])),
      }
      const response = await axios.get(`${vari.UHR}/strategies`, { params })
      context.commit('SET_COMPANY_STRATEGIES', response.data.data.data)
      delete response.data.data.data
      context.commit('SET_PAGINATE_COMPANY_STRATEGIES', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['getStrategies'])
      return error.response
    }
  },
  /**
   * Obtiene una campaña
   * @param {Vuex} context
   * @param {String} strategyId - id de la campaña
   */
  async getCompanyStrategy(context, strategyId) {
    try {
      const response = await axios.get(`${vari.UHR}/strategies/${strategyId}`)
      const strategy = response.data.data
      // actualizar la campaña seleccionada
      context.commit('SET_SELECTED_STRATEGY', strategy)
      // actualizar la campaña en las campañas
      context.commit('UPDATE_STRATEGY', { strategyId, ...strategy })
      return response.data
    } catch (error) {
      console.error(error, ['getStrategy'])
    }
  },
  /**
   * Agrega una campaña nueva
   * @param {*} context
   * @param {String} name
   * @param {String} lineId
   * @param {String} templateId
   * @param {String} [dataSource='csv']
   * @param {String} filenameCsv
   * @param {File} urlFileCsv
   * @param {String} delimiterCsv
   * @param {Number} hoursOfLife
   * @param {String} channelCompanyId
   * @param {String} text
   * @returns
   */
  async addCompanyStrategy(
    context,
    {
      name,
      lineId,
      templateId,
      dataSource = 'csv',
      filenameCsv,
      urlFileCsv,
      delimiterCsv,
      hoursOfLife,
      channelCompanyId,
      mediaUrl,
      mediaType,
      mediaCaption,
      channel = null,
      text,
    }
  ) {
    try {
      const params = {
        name,
        lineId,
        templateId,
        dataSource,
        filenameCsv,
        urlFileCsv,
        delimiterCsv,
        hoursOfLife,
        channelCompanyId,
        mediaUrl,
        mediaType,
        mediaCaption,
        text,
      }
      let url = ''
      if (channel && channel === 'sms') {
        url = `${vari.UHR}/strategies/${channelCompanyId}?direction=outbound`
      } else {
        url = `${vari.UHR}/strategies/${channelCompanyId}`
      }
      const response = await axios.post(url, params)
      if (response.data.success)
        context.commit('ADD_COMPANY_STRATEGY', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['addCompanyStrategy'])
      return error.response.data
    }
  },
  /**
   * Elimina una campaña de la empresa
   * @param {*} context
   * @param {*} strategyId
   * @returns
   */
  async deleteCompanyStrategy(context, strategyId) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/strategies/${strategyId}`
      )
      if (response.data.success)
        context.commit('REMOVE_COMPANY_STRATEGY', strategyId)
      return response.data
    } catch (error) {
      console.error(error, ['deleteCompanyStrategy'])
      return error.response
    }
  },
  /**
   * Actualiza el estado de una campaña
   * @param {*} context
   * @param {String} strategyId
   * @param {String} status - play, pause, stop
   * @returns
   */
  async updateStatusStrategy(context, { strategyId, status }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/strategies/${strategyId}/${status}`
      )
      if (response.data.success)
        context.commit('UPDATE_STRATEGY', { strategyId, status })
      return response.data
    } catch (error) {
      console.error(error, ['updateStatusStrategy'])
      return error.response
    }
  },
  /**
   * Sube el archivo y genera una url
   * @param {*} context
   * @param {string} args
   * @param {string} args.fileCsv
   * @param {string} args.channelCompanyId
   * @param {string} args.templateId
   */
  async uploadFileStrategy(context, { fileCsv, channelCompanyId, templateId }) {
    try {
      await compare.arentNull({ fileCsv, channelCompanyId })
      const formData = new FormData()
      formData.append('fileCsv', fileCsv)

      let url = ''
      if (templateId) {
        url = `${vari.UHR}/strategies/upload-file/${channelCompanyId}?templateId=${templateId}`
      } else url = `${vari.UHR}/strategies/upload-file/${channelCompanyId}`

      const response = await axios({
        method: 'post',
        url,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': '1024',
          },
        },
      })
      return response.data
    } catch (error) {
      console.error(error, ['uploadFileStrategy'])
      return error.response
    }
  },
  /**
   * genera un archivo csv
   * @param {*} context
   * @param {Object} args
   * @param {String} args.templateId
   * @param {String} args.channel
   */
  async generateFileCsv(context, { templateId, channel }) {
    try {
      await compare.arentNull({ channel })
      const token = format.fixedEncodeURIComp(context.getters.token)
      let url = ''

      if (templateId) {
        const templateIdCrypto = format.fixedEncodeURIComp(
          simpleCrypto.encrypt(templateId)
        )

        url = `${vari.UHM}/export/strategies/${channel}/csv-by-template/${templateIdCrypto}?token=${token}`
      } else {
        url = `${vari.UHM}/export/strategies/${channel}/csv-by-template?token=${token}`
      }

      window.open(url)
      return { success: true }
    } catch (error) {
      console.error(error, ['generateFileCsv'])
      return { success: false }
    }
  },
  /**
   * Obtiene un listado de plantillas que no tienen paginacion
   * @param {*} context
   * @returns
   */
  async getSimpleTemplates(context, channelCompanyId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/strategies/templates/${channelCompanyId}/list`
      )
      context.commit('SET_SIMPLE_TEMPLATES', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['getSimpleTemplates'])
      return error.response
    }
  },
  /**
   * Exportar los registro invalidos de la campaña
   * @param {*} context
   * @param {String} strategyId
   */
  exportInvalidStrategyRecords(context, strategyId) {
    let response = null
    const strategyIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(strategyId)
    )
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${vari.UHM}/export/strategies/${strategyIdCrypto}/invalids?token=${token}`
    response = window.open(url)
    return response
  },
  /**
   * Exportar los registros validos de la campaña
   * @param {*} context
   * @param {String} strategyId
   */
  exportValidStrategyRecords(context, strategyId) {
    let response = null
    const strategyIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(strategyId)
    )
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${vari.UHM}/export/strategies/${strategyIdCrypto}/results?token=${token}`
    response = window.open(url)
    return response
  },
  /**
   * Sube el archivo y genera una url
   * @param {*} context
   * @param {Object} args
   * @param {File} args.file
   * @param {String} args.type
   * @param {String} args.caption
   * @param {String} args.channelCompanyId
   */
  async uploadFileMediaStrategy(
    context,
    { file, type, caption, channelCompanyId }
  ) {
    try {
      const formData = new FormData()
      formData.append('fileMedia', file)
      formData.append('caption', caption)

      const response = await axios({
        method: 'post',
        url: `${vari.UHR}/strategies/upload-media-strategy/${channelCompanyId}?type=${type}`,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': '1024',
          },
        },
      })
      return response.data
    } catch (error) {
      console.error('[uploadFileMediaStrategy]', error)
      return error.response
    }
  },
}
export default actions
