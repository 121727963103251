<template>
  <a-menu mode="inline" :default-selected-keys="[defaultSelected]">
    <a-menu-item
      v-for="item in menuItemsTicket"
      :key="`menu-item-${item.route.params.drawerId}`"
      class="menu-item"
    >
      <ticket-menu-item
        :icon="item.icon"
        :title="item.title"
        :route="item.route"
        :image="item.image"
        :avatar="item.avatar"
        :icon-size="20"
        :icon-component="item.component"
        :show-title="true"
        :show-tooltip="false"
        color="default"
      >
      </ticket-menu-item>
    </a-menu-item>
  </a-menu>
</template>

<script>
import TicketMenuItem from '@/app/tickets/components/molecules/TicketMenuItem'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'MenuTicket',
  components: { TicketMenuItem },
  props: {
    showDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [supportMixin],
  computed: {
    /**
     * @return {String} menu por defecto seleccionado
     */
    defaultSelected() {
      if (!this.showDefault) return ''
      const currentParams = this.$route.params
      const currentDrawerId = currentParams.drawerId || 'client'
      return `menu-item-${currentDrawerId}`
    },
  },
}
</script>

<style lang="sass" scoped>
.menu-item::after
  left: 0px
  right: auto!important
</style>
