var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('a-layout',{style:(_vm.mainStyles)},[(_vm.showMenu)?_c(_vm.currentMenu.component,{tag:"component"}):_vm._e(),_c('a-layout-content',{class:{
        'margin--no-collapsed': _vm.showMenu && _vm.currentMenu.name === 'sidebar',
        'margin--fixed': _vm.hasNavbarFixed,
      },style:(_vm.contentStyles)},[(_vm.alert_banner_channel.visible)?_c('NoConnection',_vm._b({staticClass:"width--no-collapsed",on:{"onClose":_vm.readExpiredBanner}},'NoConnection',_vm.alert_banner_channel,false)):_vm._e(),(_vm.visibleNoAPI)?_c('div',[_vm._l((_vm.errorChannelsConnection),function(errorChannelConnection){return _c('NoConnection',{key:errorChannelConnection.channel,staticClass:"width--no-collapsed",attrs:{"text":_vm.textErrorChannelConnection(
              errorChannelConnection.channel,
              errorChannelConnection.api_status
            ),"url":errorChannelConnection.api_status,"icon":"api"},on:{"onClose":_vm.handleCloseAPI}})}),(_vm.visibleNoConnection)?_c('NoConnection',{staticClass:"width--no-collapsed",attrs:{"text":"Se perdió tu conexión a internet","icon":"internet"},on:{"onClose":_vm.handleCloseConnection}}):_vm._e()],2):_vm._e(),_c('router-view')],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"sounds"}},[_c('audio',{ref:"sound_message",attrs:{"src":require("@/app/commons/assets/sounds/message.ogg")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"sounds_notification"}},[_c('audio',{ref:"sound_notification",attrs:{"src":require("@/app/commons/assets/sounds/notification.ogg")}})]),_c('AppModals'),(_vm.showPushNotification)?_c('push-notifications'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }