import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtiene todas las notificaciones
   * @param {*} context
   * @param {String} companyId - id de la empresa
   * @param {Number} page
   * @param {Boolean} next - si es para obtener las siguientes paginas
   */
  async getNotifications(
    context,
    { companyId = '', page = 1, next = false } = { companyId: '' }
  ) {
    try {
      const response = await axios.get(
        `${vari.UHR}/agent/notifications?page=${page}&companyId=${companyId}`
      )

      if (next)
        context.commit('SET_NEXT_PAGE_NOTIFICATIONS', response.data.result)
      else context.commit('SET_NOTIFICATIONS', response.data.result)
      return response.data
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },

  /**Obtiene todas las notificaciones */
  async cleanNotifications() {
    try {
      const response = await axios.put(`${vari.UHR}/agent/notifications`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  /**Crea una notificacion
   * @param {Object}   activity
   * @param {String}   activity.type
   * @param {String}   activity.line        OPCIONAL Nombre de la cola
   * @param {String}   activity.user        OPCIONAL Usuario transferido
   * @param {Object}   activity.client      OPCIONAL Cliente al que le afecto la acción
   * @param {Object}   activity.client._id  OPCIONAL Id del cliente actualizado
   * @param {String}   activity.client.old  Valor antiguo
   * @param {String}   activity.client.new  Valor nuevo
   * @param {Object}   activity.ticket      OPCIONAL Ticket al que le afecto la acción
   * @param {Object}   activity.ticket._id  OPCIONAL Id del ticket actualizado
   * @param {String}   activity.ticket.old  Valor antiguo
   * @param {String}   activity.ticket.new  Valor nuevo
   */
  async addNotification(context, activity) {
    try {
      const response = await axios.post(`${vari.UHR}/agent/notifications`, {
        activity,
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async readNewNotification(context, notificationId) {
    try {
      const response = await axios.patch(
        `${vari.UHR}/agent/notifications/${notificationId}`
      )
      if (response.data.success)
        context.commit('REMOVE_NEW_NOTIFICATIONS', notificationId)
    } catch (error) {
      console.error(['readNewNotification'], error)
    }
  },
}
export default actions
