<template>
  <a-alert
    v-if="alert"
    class="alert mrg-bottom-12"
    :type="alert.type"
    closable
    show-icon
  >
    <p slot="message" class="mrg-bottom-0">
      {{ alert.message }}
      <anchor
        v-if="alert.link"
        :href="alert.link.href"
        :style="alert.link.style"
        >{{ alert.link.text }}</anchor
      >
    </p>
  </a-alert>
</template>

<script>
import { mapGetters } from 'vuex'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'AlertStatusChannel',
  components: {
    Anchor,
  },
  data: () => ({
    dataTestChannel: {
      channel: { channel: 'messenger', name: 'Facebook Messenger' },
      page: 'Rimac Seguros',
      type: 'troubled',
    },
  }),
  computed: {
    ...mapGetters(['alert_banner_channel']),

    /**
     * Alerta informativa
     * @returns {Object} alert
     * @returns {String} alert.type
     * @returns {String} alert.message
     * @returns {Object} alert.link
     * @returns {String} alert.link.text
     * @returns {String} alert.link.href
     * @returns {String} alert.link.color
     */
    alert() {
      if (
        !this.alert_banner_channel ||
        this.alert_banner_channel.type !== 'alert'
      )
        return
      return {
        type: 'warning',
        message: this.alert_banner_channel.text,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$max_width: 83.33333333%

.alert
  margin: 0 auto
  max-width: $max_width
  text-align: left
</style>
