<template>
  <section>
    <h5 class="semibold-20 text--center mrg-bottom-20">
      {{ title }}
      <SocialIcon v-if="channel" :type="channel" />
    </h5>
    <a-carousel
      arrows
      :afterChange="handleChangeCurrent"
      dots-class="modal-fb__carousel__dots"
    >
      <div
        slot="prevArrow"
        class="carousel__arrow"
        style="left: 10px; z-index: 1"
        :class="{
          'carousel__arrow-icon--disabled icon--disabled': current === 0,
        }"
      >
        <a-icon
          type="left-circle"
          theme="filled"
          class="carousel__arrow-icon"
          :class="{
            'carousel__arrow-icon--disabled icon--disabled': current === 0,
          }"
        />
      </div>
      <div
        ref="fb_start_icon_next"
        slot="nextArrow"
        class="carousel__arrow"
        :class="{
          'carousel__arrow-icon--disabled icon--disabled': current === 2,
        }"
        style="right: 10px; z-index: 1"
      >
        <a-icon
          type="right-circle"
          theme="filled"
          class="carousel__arrow-icon"
          :class="{
            'carousel__arrow-icon--disabled icon--disabled': current === 2,
          }"
        />
      </div>
      <!--Contenido de carousel-->
      <div class="carousel__content">
        <div class="carousel__content__image">
          <img
            class="carousel__image"
            src="@/app/channels/assets/first-image-msg.png"
          />
        </div>
        <p class="regular-16 text--center mrg-top-16 carousel__text">
          <span class="semibold-16">¿Tu usuario no aparece aquí?</span>
          <br />
          Te ayudamos a conectar tu página en el siguiente paso.
          <br />
          <a @click="handleNextCarousel">Continuar con la información</a>
        </p>
      </div>
      <div class="carousel__content">
        <div class="carousel__content__image">
          <img
            class="carousel__image"
            src="@/app/channels/assets/second-image-msg.png"
          />
        </div>
      </div>
      <div class="carousel__content">
        <div class="carousel__content__image">
          <img
            class="carousel__image"
            src="@/app/channels/assets/third-image-msg.png"
          />
        </div>
      </div>
    </a-carousel>
  </section>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'CarouselNewFb',
  components: {
    SocialIcon,
  },
  props: {
    channel: { type: String },
  },
  data: () => ({
    current: 0,
  }),
  computed: {
    title() {
      return this.channel === 'messenger'
        ? 'Conecta con el nuevo Facebook Messenger'
        : 'Conecta con el nuevo Instagram'
    },
  },
  methods: {
    /**
     * Escucha el cambio de carousel
     * @param {Number} current
     */
    handleChangeCurrent(current) {
      this.current = current
    },
    handleNextCarousel() {
      const iconNext = this.$refs.fb_start_icon_next
      iconNext.click()
    },
  },
}
</script>

<style lang="sass" scoped>
.carousel
  &__content
    &__image
      width: 530px
      margin: auto
  &__image
    width: 100%
    object-fit: contain
  &__arrow-icon
    color: $blue_6
    font-size: 20px
    &--disabled
      pointer-events: none
  &__text
    color: $gray_9
</style>
<style lang="sass">
.modal-fb__carousel__dots
  bottom: -20px !important
  li.slick-active button
    background: $gray_7 !important
  li button
    background: $gray_5 !important
</style>
