<template>
  <i class="socialicon anticon" :class="{ 'icon--disabled': disabled }">
    <!-- WhatsApp - Full Color -->
    <svg
      v-if="type === 'whatsapp' && fill === 'full'"
      class="'whatsapp"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0024 2.3999H11.9976C6.70442 2.3999 2.40002 6.7055 2.40002 11.9999C2.40002 14.0999 3.07682 16.0463 4.22762 17.6267L3.03122 21.1931L6.72122 20.0135C8.23922 21.0191 10.05 21.5999 12.0024 21.5999C17.2956 21.5999 21.6 17.2931 21.6 11.9999C21.6 6.7067 17.2956 2.3999 12.0024 2.3999Z"
        :fill="disabled ? gray_dark_400 : '#25D366'"
      />
      <path
        d="M17.5884 15.9564C17.3568 16.6104 16.4376 17.1528 15.7044 17.3112C15.2028 17.418 14.5476 17.5032 12.342 16.5888C9.52076 15.42 7.70396 12.5532 7.56236 12.3672C7.42676 12.1812 6.42236 10.8492 6.42236 9.47157C6.42236 8.09397 7.12196 7.42317 7.40396 7.13517C7.63556 6.89877 8.01836 6.79077 8.38556 6.79077C8.50436 6.79077 8.61116 6.79677 8.70716 6.80157C8.98916 6.81357 9.13076 6.83037 9.31676 7.27557C9.54836 7.83357 10.1124 9.21117 10.1796 9.35277C10.248 9.49437 10.3164 9.68637 10.2204 9.87237C10.1304 10.0644 10.0512 10.1496 9.90956 10.3128C9.76796 10.476 9.63356 10.6008 9.49196 10.776C9.36236 10.9284 9.21596 11.0916 9.37916 11.3736C9.54236 11.6496 10.1064 12.57 10.9368 13.3092C12.0084 14.2632 12.8772 14.568 13.188 14.6976C13.4196 14.7936 13.6956 14.7708 13.8648 14.5908C14.0796 14.3592 14.3448 13.9752 14.6148 13.5972C14.8068 13.326 15.0492 13.2924 15.3036 13.3884C15.5628 13.4784 16.9344 14.1564 17.2164 14.2968C17.4984 14.4384 17.6844 14.5056 17.7528 14.6244C17.82 14.7432 17.82 15.3012 17.5884 15.9564Z"
        fill="#FAFAFA"
      />
    </svg>
    <!-- WhatsApp - - Line -->
    <svg
      v-else-if="type === 'whatsapp' && fill === 'line'"
      :width="size"
      :height="size"
      class="'whatsapp"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9234 0.151123C8.6412 0.151123 0.28553 8.52344 0.28553 18.8261C0.28553 22.5521 1.484 25.9639 3.3545 28.8818L0.151123 37.849L10.1197 35.032C12.763 36.4798 15.6976 37.5011 18.9234 37.5011C29.2055 37.5011 37.5612 29.1287 37.5612 18.8261C37.5612 8.52344 29.2055 0.151123 18.9234 0.151123ZM18.9234 3.02419C27.6486 3.02419 34.6938 10.0834 34.6938 18.8261C34.6938 27.5688 27.6486 34.628 18.9234 34.628C15.9328 34.628 13.1662 33.7863 10.7693 32.3385L10.2429 32.013L4.72098 33.5618L6.51308 28.5788L6.07625 27.9728C4.23935 25.3803 3.15289 22.2379 3.15289 18.8261C3.15289 10.0834 10.1981 3.02419 18.9234 3.02419ZM11.9342 8.77034C11.6094 8.77034 11.0717 8.90501 10.6125 9.44372C10.1645 9.97119 8.88761 11.2618 8.88761 13.888C8.88761 16.5029 10.6573 19.0393 10.9037 19.3985C11.1501 19.7576 14.3871 25.1334 19.3378 27.4453C20.5139 27.9952 21.4323 28.3207 22.1491 28.5676C23.3364 28.9716 24.4117 28.9155 25.2629 28.7808C26.215 28.6237 28.1863 27.4902 28.6007 26.2444C29.0039 24.9987 29.0151 23.9325 28.8807 23.7081C28.7687 23.4836 28.4327 23.3489 27.9399 23.0908C27.447 22.8215 25.0165 21.5308 24.5685 21.3625C24.1205 21.1717 23.7844 21.0931 23.4484 21.6206C23.1236 22.1593 22.1827 23.3602 21.8915 23.7081C21.6003 24.0672 21.3203 24.1121 20.8275 23.8427C20.3234 23.5734 18.733 23.0122 16.8512 21.1941C15.384 19.78 14.3871 18.0405 14.1071 17.5018C13.8159 16.9631 14.0735 16.6825 14.3199 16.4244C14.5439 16.1775 14.8127 15.7959 15.0591 15.4817C15.3056 15.1786 15.384 14.943 15.552 14.595C15.72 14.2359 15.6416 13.9217 15.5184 13.6635C15.384 13.3942 14.3983 10.768 13.9951 9.70184C13.5919 8.66933 13.1774 8.80401 12.875 8.79279C12.595 8.77034 12.259 8.77034 11.9342 8.77034Z"
        :fill="!color ? 'black' : color"
      />
    </svg>

    <!-- Messenger - Full Color -->
    <svg
      v-else-if="type === 'messenger' && fill === 'full'"
      class="messenger"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2.3999C6.59206 2.3999 2.40002 6.36125 2.40002 11.7119C2.40002 14.5107 3.54703 16.9291 5.41496 18.5995C5.57177 18.7399 5.66642 18.9365 5.67286 19.1469L5.72514 20.8546C5.74184 21.3993 6.30448 21.7537 6.80291 21.5337L8.70838 20.6925C8.86991 20.6213 9.0509 20.6081 9.22111 20.6549C10.0967 20.8957 11.0287 21.0239 12 21.0239C17.408 21.0239 21.6 17.0626 21.6 11.7119C21.6 6.36125 17.408 2.3999 12 2.3999Z"
        :fill="disabled ? gray_dark_400 : 'url(#paint0_radial_6398_46882)'"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.23512 14.4352L9.05512 9.96118C9.50369 9.24944 10.4643 9.07222 11.1373 9.57699L13.3802 11.2592C13.586 11.4135 13.8691 11.4127 14.0741 11.2572L17.1032 8.95827C17.5075 8.65145 18.0353 9.13529 17.7647 9.56466L14.9447 14.0387C14.4961 14.7504 13.5355 14.9277 12.8625 14.4228L10.6195 12.7406C10.4138 12.5863 10.1306 12.5871 9.92571 12.7427L6.89656 15.0416C6.49227 15.3484 5.9645 14.8645 6.23512 14.4352Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6398_46882"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.09553 21.4972) scale(20.9202)"
        >
          <stop stop-color="#0099FF" />
          <stop offset="0.609754" stop-color="#A033FF" />
          <stop offset="0.934823" stop-color="#FF5280" />
          <stop offset="1" stop-color="#FF7061" />
        </radialGradient>
      </defs>
    </svg>
    <!-- Messenger - Line -->
    <svg
      v-else-if="type === 'messenger' && fill === 'line'"
      class="messenger"
      :width="size"
      :height="size"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0001 0.151123C8.96517 0.151123 0.726685 8.01481 0.726685 17.7562C0.726685 22.9468 3.11793 27.5574 6.81781 30.7687V37.849L13.5752 34.4523C15.2942 34.9726 17.0906 35.3613 19.0001 35.3613C29.035 35.3613 37.2734 27.4976 37.2734 17.7562C37.2734 8.01481 29.035 0.151123 19.0001 0.151123ZM19.0001 3.21288C27.4646 3.21288 34.2279 9.74302 34.2279 17.7562C34.2279 25.7694 27.4646 32.2995 19.0001 32.2995C17.1799 32.2995 15.4429 31.9527 13.8131 31.3906L13.1945 31.1992L9.86337 32.8736V29.4291L9.29233 28.9507C5.91366 26.2717 3.77225 22.2591 3.77225 17.7562C3.77225 9.74302 10.5355 3.21288 19.0001 3.21288ZM17.2869 12.9244L8.10266 22.6837L16.3352 18.0911L20.7132 22.8272L29.8023 12.9244L21.7601 17.4692L17.2869 12.9244Z"
        :fill="!color ? 'black' : color"
      />
    </svg>

    <!-- Instagram - Full Color -->
    <svg
      v-else-if="type === 'instagram' && fill === 'full'"
      class="instagram"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65243 3.73316C2.14797 5.29586 2.45588 6.95588 2.45588 11.9989C2.45588 16.1869 1.72519 20.3852 5.54937 21.3735C6.74353 21.6807 17.3242 21.6807 18.5168 21.3719C20.109 20.9611 21.4045 19.6696 21.5816 17.4177C21.6063 17.1034 21.6063 6.90004 21.5808 6.57936C21.3925 4.18067 19.916 2.79826 17.9704 2.51826C17.5245 2.45365 17.4351 2.4345 15.1473 2.43052C7.0323 2.4345 5.25342 2.07314 3.65243 3.73316V3.73316Z"
        fill="url(#paint0_linear_6398_46894)"
      />
      <path
        d="M12.0267 4.93456C9.13025 4.93456 6.37977 4.6769 5.3292 7.37313C4.89525 8.48672 4.95827 9.93296 4.95827 12.0038C4.95827 13.821 4.90004 15.5288 5.3292 16.6337C6.37738 19.3315 9.15019 19.073 12.0251 19.073C14.7987 19.073 17.6585 19.3618 18.7218 16.6337C19.1566 15.5089 19.0927 14.0842 19.0927 12.0038C19.0927 9.24215 19.2451 7.45928 17.9058 6.12074C16.5497 4.76465 14.7158 4.93456 12.0235 4.93456H12.0267ZM11.3933 6.20849C17.4351 6.19891 18.2041 5.52725 17.7797 14.858C17.629 18.1581 15.1162 17.7959 12.0275 17.7959C6.39573 17.7959 6.2338 17.6348 6.2338 12.0006C6.2338 6.30102 6.68051 6.21168 11.3933 6.20689V6.20849ZM15.7998 7.38191C15.3316 7.38191 14.9519 7.76161 14.9519 8.22986C14.9519 8.69811 15.3316 9.07782 15.7998 9.07782C16.2681 9.07782 16.6478 8.69811 16.6478 8.22986C16.6478 7.76161 16.2681 7.38191 15.7998 7.38191V7.38191ZM12.0267 8.37345C10.0221 8.37345 8.39716 9.99917 8.39716 12.0038C8.39716 14.0084 10.0221 15.6333 12.0267 15.6333C14.0313 15.6333 15.6555 14.0084 15.6555 12.0038C15.6555 9.99917 14.0313 8.37345 12.0267 8.37345V8.37345ZM12.0267 9.64738C15.1417 9.64738 15.1457 14.3602 12.0267 14.3602C8.91248 14.3602 8.90769 9.64738 12.0267 9.64738Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6398_46894"
          x1="3.68914"
          y1="20.3526"
          x2="21.4822"
          y2="4.95283"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="disabled ? gray_dark_400 : '#FFDD55'" />
          <stop
            offset="0.5"
            :stop-color="disabled ? gray_dark_400 : '#FF543E'"
          />
          <stop offset="1" :stop-color="disabled ? gray_dark_400 : '#C837AB'" />
        </linearGradient>
      </defs>
    </svg>
    <!-- Instagram - Line -->
    <svg
      v-else-if="type === 'instagram' && fill === 'line'"
      class="instagram"
      :width="size"
      :height="size"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1402 1.72665C9.10087 1.72665 1.72668 9.10084 1.72668 18.1402V41.8599C1.72668 50.8992 9.10087 58.2734 18.1402 58.2734H41.8599C50.8993 58.2734 58.2734 50.8992 58.2734 41.8599V18.1402C58.2734 9.10084 50.8993 1.72665 41.8599 1.72665H18.1402ZM18.1402 6.07641H41.8599C48.546 6.07641 53.9237 11.4456 53.9237 18.1402V41.8599C53.9237 48.5459 48.5545 53.9237 41.8599 53.9237H18.1402C11.4542 53.9237 6.07644 48.5544 6.07644 41.8599V18.1402C6.07644 11.4541 11.4457 6.07641 18.1402 6.07641ZM46.3116 10.4262C44.5106 10.4262 43.0493 11.8874 43.0493 13.6885C43.0493 15.4895 44.5106 16.9508 46.3116 16.9508C48.1127 16.9508 49.5739 15.4895 49.5739 13.6885C49.5739 11.8874 48.1127 10.4262 46.3116 10.4262ZM30.0001 14.7759C21.6149 14.7759 14.7759 21.6149 14.7759 30C14.7759 38.3852 21.6149 45.2242 30.0001 45.2242C38.3852 45.2242 45.2242 38.3852 45.2242 30C45.2242 21.6149 38.3852 14.7759 30.0001 14.7759ZM30.0001 19.1257C36.0319 19.1257 40.8744 23.9682 40.8744 30C40.8744 36.0319 36.0319 40.8744 30.0001 40.8744C23.9682 40.8744 19.1257 36.0319 19.1257 30C19.1257 23.9682 23.9682 19.1257 30.0001 19.1257Z"
        :fill="!color ? 'black' : color"
      />
    </svg>

    <!-- SMS - Full Color -->
    <svg
      v-else-if="type === 'sms' && fill === 'full'"
      :width="size"
      :height="size"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9206 23.1372C12.7704 22.6111 13.9469 21.8659 15.276 20.8138C16.3 20.0027 17.1498 19.3452 18.1302 18.293C19.0889 17.2409 20.2001 16.0353 20.9191 14.1284C21.4638 12.6379 21.5292 11.4542 21.5292 11.1912C21.5727 9.70066 21.2459 8.58277 21.137 8.2759C20.6794 6.87307 19.9386 5.95246 19.5247 5.47023C19.176 5.03184 18.566 4.39618 17.7162 3.78244C17.4548 3.60709 16.8229 3.1687 15.9078 2.77415C15.0145 2.37961 14.2737 2.22617 14.0123 2.18233C13.7944 2.13849 12.9229 1.96314 11.7463 2.00698C11.5284 2.00698 10.6569 2.05082 9.54573 2.31385C8.63063 2.53304 7.9552 2.81799 7.65017 2.94951C6.62613 3.38789 5.92891 3.89204 5.75461 4.00164C5.36242 4.28659 4.81772 4.68113 4.25123 5.33871C3.79368 5.86478 3.53223 6.347 3.37971 6.61004C2.8568 7.53065 2.63892 8.29782 2.59534 8.47318C2.3121 9.54722 2.33388 10.4021 2.33388 10.9281C2.35567 11.3665 2.37746 12.1118 2.63892 13.0543C2.70428 13.2735 2.98752 14.2818 3.75011 15.4216C3.9462 15.7285 4.62163 16.6929 5.79818 17.6355C6.10322 17.8766 6.58255 18.2492 7.27977 18.5999C8.23844 19.0821 9.06639 19.2575 9.65467 19.3671C10.2429 19.4767 11.0491 19.5863 12.0078 19.5424C11.986 20.7042 11.9642 21.9316 11.9206 23.1372Z"
        :stroke="disabled ? gray_dark_400 : '#3800D6'"
        stroke-width="2"
        stroke-miterlimit="10"
        style="mix-blend-mode: multiply"
      />
      <circle
        cx="8"
        cy="11"
        r="1"
        :fill="disabled ? gray_dark_400 : '#3800D6'"
      />
      <circle
        cx="12"
        cy="11"
        r="1"
        :fill="disabled ? gray_dark_400 : '#3800D6'"
      />
      <circle
        cx="16"
        cy="11"
        r="1"
        :fill="disabled ? gray_dark_400 : '#3800D6'"
      />
      <path
        d="M22 10.5C22 12.5975 21.1062 14.5176 19.6247 16C18.5296 17.0957 14.6136 21.4875 13 22C11.9107 22.346 13.2313 19 12 19C6.47715 19 2 15.1944 2 10.5C2 5.80558 6.47715 2 12 2C17.5228 2 22 5.80558 22 10.5Z"
        :fill="disabled ? gray_dark_400 : '#3800D6'"
      />
      <circle cx="8" cy="11" r="1" fill="white" />
      <circle cx="12" cy="11" r="1" fill="white" />
      <circle cx="16" cy="11" r="1" fill="white" />
    </svg>
    <!-- SMS - Line -->
    <svg
      v-else-if="type === 'sms' && fill === 'line'"
      :width="size"
      :height="size"
      viewBox="0 0 36 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8673 36.8954C19.2836 36.0187 21.2445 34.7766 23.4596 33.023C25.1663 31.6713 26.5826 30.5754 28.2167 28.8218C29.8145 27.0683 31.6664 25.059 32.8648 21.8807C33.7726 19.3965 33.8816 17.4238 33.8816 16.9854C33.9542 14.5012 33.4095 12.638 33.2279 12.1266C32.4653 9.78853 31.2307 8.25417 30.5407 7.45047C29.9597 6.71982 28.9429 5.66039 27.5267 4.63748C27.0909 4.34523 26.0379 3.61458 24.5127 2.957C23.0238 2.29942 21.7892 2.0437 21.3534 1.97063C20.9903 1.89757 19.5378 1.60531 17.5768 1.67838C17.2137 1.67838 15.7612 1.75144 13.9092 2.18983C12.384 2.55515 11.2583 3.03007 10.7499 3.24926C9.04318 3.97991 7.88115 4.82015 7.59065 5.00281C6.937 5.47773 6.02917 6.13531 5.08502 7.23127C4.32244 8.10804 3.88668 8.91175 3.63248 9.35014C2.76096 10.8845 2.39783 12.1631 2.3252 12.4554C1.85313 14.2455 1.88944 15.6702 1.88944 16.547C1.92575 17.2776 1.96207 18.5197 2.39783 20.0906C2.50677 20.4559 2.97884 22.1364 4.24981 24.0361C4.57663 24.5475 5.70235 26.155 7.66327 27.7258C8.17166 28.1277 8.97056 28.7487 10.1326 29.3333C11.7304 30.137 13.1103 30.4292 14.0907 30.6119C15.0712 30.7945 16.4148 30.9772 18.0126 30.9041C17.9763 32.8403 17.94 34.8862 17.8673 36.8954Z"
        stroke="#262A31"
        stroke-width="3"
        stroke-miterlimit="10"
        fill="none"
      />
      <path
        d="M12.2794 14.0412C12.2794 13.7784 12.2084 13.1401 11.7468 12.5769C11.2142 11.8635 10.4685 11.7133 10.078 11.6007C9.82942 11.5632 9.11928 11.3754 8.30262 11.7133C8.12509 11.7884 7.80553 11.9386 7.52148 12.239C7.45046 12.3141 7.16641 12.6145 6.98887 13.065C6.91786 13.2152 6.81134 13.5531 6.81134 14.0037C6.81134 14.3416 6.88235 14.5669 6.91786 14.642C7.02438 14.9799 7.20191 15.2052 7.34394 15.3929C7.59249 15.6933 7.84104 15.8435 8.08958 16.0312C8.1606 16.0688 8.37364 16.1814 8.76421 16.4442C9.19029 16.6695 9.29681 16.7446 9.50985 16.8948C9.82942 17.0825 9.97144 17.1952 10.1135 17.2702C10.4685 17.5331 10.5751 17.6457 10.6461 17.7584C10.7881 17.9836 10.8236 18.1714 10.8236 18.2089C10.8591 18.3215 10.8946 18.5468 10.7881 18.7721C10.6816 19.11 10.362 19.2602 10.2555 19.3353C9.86492 19.523 9.47435 19.4479 9.36783 19.4479C9.2258 19.4104 8.83523 19.3353 8.58668 18.9974C8.23161 18.5093 8.33813 17.9461 8.33813 17.8334L6.91786 17.871C6.84685 18.1714 6.74033 18.7346 6.91786 19.2978C7.34394 20.5368 8.83523 20.9874 9.86492 20.9498C10.149 20.9498 11.2852 20.8747 11.9598 19.9361C12.3859 19.3353 12.4569 18.697 12.4569 18.4717C12.4569 18.284 12.4924 17.6457 12.1374 16.9699C12.1018 16.8948 11.8533 16.4818 11.4272 16.1438C11.1787 15.9561 10.8946 15.8435 10.7526 15.7684C10.4685 15.6557 10.362 15.6557 9.97144 15.5056C9.82942 15.4305 9.65188 15.3554 9.43884 15.2427C9.04827 15.0174 8.79972 14.8297 8.65769 14.4918C8.48016 14.0412 8.62219 13.5907 8.65769 13.5531C8.6932 13.4405 8.83523 13.1401 9.15479 12.9524C9.50985 12.7647 9.82942 12.9148 9.93594 12.9524C10.0069 12.9899 10.22 13.065 10.362 13.2528C10.504 13.4405 10.5751 13.6282 10.6106 13.816C10.6816 14.0412 10.6816 14.229 10.6816 14.3792L12.2084 14.4167L12.2794 14.0412Z"
        :fill="!color ? 'black' : color"
      />
      <path
        d="M13.9635 20.952V11.5149H16.2114L17.8974 18.0358L19.6208 11.5149H21.8687V20.952H20.1827V15.1196L18.4219 20.952H17.3728L15.7244 15.1196V20.952H13.9635Z"
        :fill="!color ? 'black' : color"
      />
      <path
        d="M28.8427 14.0412C28.8427 13.7784 28.7717 13.1401 28.3101 12.5769C27.7775 11.8635 27.0318 11.7133 26.6413 11.6007C26.3927 11.5632 25.6826 11.3754 24.8659 11.7133C24.6884 11.7884 24.3688 11.9386 24.0848 12.239C24.0138 12.3141 23.7297 12.6145 23.5522 13.065C23.4812 13.2152 23.3746 13.5531 23.3746 14.0037C23.3746 14.3416 23.4456 14.5669 23.4812 14.642C23.5877 14.9799 23.7652 15.2052 23.9072 15.3929C24.1558 15.6933 24.4043 15.8435 24.6529 16.0312C24.7239 16.0688 24.9369 16.1814 25.3275 16.4442C25.7536 16.6695 25.8601 16.7446 26.0731 16.8948C26.3927 17.0825 26.5347 17.1952 26.6768 17.2702C27.0318 17.5331 27.1383 17.6457 27.2094 17.7583C27.3514 17.9836 27.3869 18.1714 27.3869 18.2089C27.4224 18.3215 27.4579 18.5468 27.3514 18.7721C27.2449 19.11 26.9253 19.2602 26.8188 19.3353C26.4282 19.523 26.0376 19.4479 25.9311 19.4479C25.7891 19.4104 25.3985 19.3353 25.15 18.9974C24.7949 18.5093 24.9014 17.9461 24.9014 17.8334L23.4812 17.871C23.4101 18.1714 23.3036 18.7346 23.4812 19.2978C23.9072 20.5368 25.3985 20.9874 26.4282 20.9498C26.7123 20.9498 27.8485 20.8747 28.5231 19.9361C28.9492 19.3353 29.0202 18.697 29.0202 18.4717C29.0202 18.284 29.0557 17.6457 28.7006 16.9699C28.6651 16.8948 28.4166 16.4818 27.9905 16.1438C27.742 15.9561 27.4579 15.8435 27.3159 15.7684C27.0318 15.6557 26.9253 15.6557 26.5347 15.5056C26.3927 15.4305 26.2152 15.3554 26.0021 15.2427C25.6116 15.0174 25.363 14.8297 25.221 14.4918C25.0434 14.0412 25.1855 13.5907 25.221 13.5531C25.2565 13.4405 25.3985 13.1401 25.7181 12.9524C26.0731 12.7647 26.3927 12.9148 26.4992 12.9524C26.5702 12.9899 26.7833 13.065 26.9253 13.2528C27.0673 13.4405 27.1383 13.6282 27.1739 13.816C27.2449 14.0412 27.2449 14.229 27.2449 14.3792L28.7717 14.4167L28.8427 14.0412Z"
        :fill="!color ? 'black' : color"
      />
    </svg>

    <!-- Telegram - Full Color -->
    <svg
      v-else-if="type === 'telegram' && fill === 'full'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        :fill="disabled ? gray_dark_400 : '#039BE5'"
      />
      <path
        d="M5.49102 11.74L17.061 7.27896C17.598 7.08496 18.067 7.40996 17.893 8.22196L17.894 8.22096L15.924 17.502C15.778 18.16 15.387 18.32 14.84 18.01L11.84 15.799L10.393 17.193C10.233 17.353 10.098 17.488 9.78802 17.488L10.001 14.435L15.561 9.41196C15.803 9.19896 15.507 9.07896 15.188 9.29096L8.31702 13.617L5.35502 12.693C4.71202 12.489 4.69802 12.05 5.49102 11.74Z"
        :fill="disabled ? gray_dark_400 : 'white'"
      />
    </svg>

    <!-- Webchat - Full Color -->
    <svg
      v-if="type === 'webchat' && fill === 'full'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6396_46881)">
        <path
          d="M19.5429 2.40063H4.45717C3.32104 2.40063 2.40002 3.32165 2.40002 4.45778V15.4292C2.40002 16.5653 3.32104 17.4863 4.45717 17.4863H6.89146L6.51845 20.8395C6.47686 21.2159 6.74831 21.5547 7.12474 21.5963C7.31912 21.6178 7.51343 21.5552 7.65879 21.4244L12.035 17.4863H19.5429C20.679 17.4863 21.6 16.5653 21.6 15.4292V4.45778C21.6 3.32165 20.679 2.40063 19.5429 2.40063ZM20.2286 15.4292C20.2286 15.8079 19.9216 16.1149 19.5428 16.1149H11.7717C11.6022 16.115 11.4388 16.1778 11.3129 16.2912L8.08047 19.2006L8.33898 16.8768C8.381 16.5004 8.11 16.1612 7.7336 16.1192C7.70829 16.1163 7.68286 16.1149 7.65739 16.1149H4.45717C4.07844 16.1149 3.77144 15.8079 3.77144 15.4292V4.45778C3.77144 4.07905 4.07844 3.77205 4.45717 3.77205H19.5429C19.9216 3.77205 20.2286 4.07905 20.2286 4.45778V15.4292H20.2286Z"
          :fill="disabled ? gray_dark_400 : '#FA541C'"
        />
        <path
          d="M3.20001 3.19995H20.8V16.8H12L8.00001 19.6L7.60001 16.8H3.20001V3.19995Z"
          :fill="disabled ? gray_dark_400 : '#FA541C'"
        />
        <rect
          x="4.80005"
          y="8"
          width="12.8"
          height="0.8"
          rx="0.4"
          fill="white"
        />
        <rect
          x="4.80005"
          y="10.3999"
          width="9.6"
          height="0.8"
          rx="0.4"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6396_46881">
          <rect
            width="19.2"
            height="19.2"
            :fill="disabled ? gray_dark_400 : 'white'"
            transform="translate(2.40002 2.3999)"
          />
        </clipPath>
      </defs>
    </svg>
    <!--VOIP - Full Color-->
    <svg
      v-if="type === 'voip' && fill === 'full'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        :fill="disabled ? gray_dark_400 : '#0266C2'"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6902 9.25367C12.0571 8.73001 12.1887 8.17813 11.7737 7.69098C10.8423 6.31099 10.2159 5.45761 9.82294 5.04665C9.07596 4.26535 7.84716 4.37103 7.20022 5.04598C6.85275 5.4085 6.73511 5.53299 6.37833 5.9167C4.39099 8.02207 5.60297 12.4727 8.82486 15.8875C12.046 19.3015 16.249 20.5854 18.2405 18.4756C18.5698 18.1396 18.8485 17.8443 19.0684 17.5982C19.7023 16.8887 19.7986 15.645 19.0642 14.8371C18.6877 14.4228 17.9191 13.7916 16.5609 12.7629C16.145 12.3691 15.6518 12.4545 15.1996 12.7686C14.9821 12.9197 14.8238 13.0725 14.5249 13.3893L13.9822 13.9643C13.9107 14.04 12.94 13.5253 11.9977 12.5266C11.0549 11.5274 10.5691 10.4995 10.6403 10.4242L11.1834 9.84881C11.2782 9.74831 11.3236 9.69965 11.3817 9.63495C11.5029 9.50015 11.6031 9.37782 11.6902 9.25367ZM14.9834 15.025L15.5262 14.4499C15.6907 14.2755 15.7973 14.1683 15.884 14.0944C17.0738 14.9991 17.7628 15.5679 18.0443 15.8776C18.2133 16.0635 18.187 16.4027 18.0401 16.5671C17.8368 16.7946 17.5727 17.0744 17.2486 17.4054C15.9608 18.7695 12.568 17.7331 9.82625 14.8271C7.0836 11.9203 6.1052 8.32739 7.38326 6.9734C7.73861 6.59127 7.85119 6.47213 8.19371 6.11478C8.32213 5.9808 8.67195 5.95071 8.82817 6.11411C9.13068 6.43052 9.69182 7.19128 10.5173 8.40879C10.4746 8.46496 10.4212 8.52844 10.3562 8.60072C10.3086 8.65377 10.2688 8.6964 10.182 8.78843L9.63942 9.36326C8.71635 10.3405 9.50247 12.0038 10.9963 13.5871C12.4891 15.1693 14.0606 16.0026 14.9834 15.025Z"
        fill="white"
      />
    </svg>
  </i>
</template>
<script>
export default {
  name: 'SocialIcon',
  props: {
    type: {
      type: String,
      enum: [
        'whatsapp',
        'messenger',
        'instagram',
        'sms',
        'telegram',
        'webchat',
      ],
      required: true,
    },
    fill: {
      type: String,
      enum: ['full', 'line'],
      default: 'full',
    },
    color: {
      type: String,
    },
    size: {
      type: [String, Number],
      default: '24',
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    gray_dark_400: '#A8AAAD',
  }),
}
</script>

<style lang="sass" scoped>
.socialicon
  width: min-content
  // display: flex
  // align-items: center
.icon--disabled
  opacity: 0.6
  // svg
  //   fill: $gray_dark_600
</style>
