<template>
  <div class="content">
    <a-row class="row" :gutter="[24]" v-if="profile">
      <a-col :span="14" class="col">
        <header-module
          title="Métodos de pago"
          tipography="heading-h5"
          class="template__header"
          :link="`${vari.WEB_DOC}primeros-pasos/gestion-usuarios/`"
          text-after-link="para conocer mejor nuestro esquema de costos fijos y variables."
          textLink="Click aquí"
          positionTooltip="right"
        />
        <p class="body-2 description">
          Edita una de tus tarjetas o agrega una nueva.
        </p>
        <ListCards @onSelect="handleSelectCard" @onAdd="handleAddCard" />
      </a-col>
      <a-col :span="10" class="col">
        <SectionFormCard
          :type="typeForm"
          :title="titleForm"
          :values="valuesForm"
          :hasSubscription="
            company &&
            company.settings.subscription &&
            company.settings.subscription.total > 0
          "
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import ListCards from '@/app/shop/components/paymentMethods/ListCards.vue'
import SectionFormCard from '@/app/shop/components/paymentMethods/SectionFormCard.vue'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionMenuPaymentMethods',
  components: {
    ListCards,
    SectionFormCard,
    HeaderModule,
  },
  data: () => ({
    vari,
    typeForm: 'add',
    valuesForm: {},
  }),
  computed: {
    ...mapGetters(['profile', 'company']),
    titleForm() {
      if (this.typeForm === 'add') return 'Agregar nueva tarjeta'
      else return 'Configuración de tarjeta'
    },
  },
  methods: {
    /**Escucha el cambio de tipo  de formulaio
     * @param {String} type - tipo de formulario add, edit
     * @param {Object} card - los datos de la card
     */
    handleChangeType(type, card) {
      this.typeForm = type
      if (card && card !== undefined) this.valuesForm = card
      else this.valuesForm = null
    },
    /**
     * Cuando se selecciona una tarjeta
     * @param {Object} card
     * @param {String} card.idCard
     */
    handleSelectCard(card) {
      this.handleChangeType('edit', card)
    },
    /**
     * Cuando se agrega una tarjeta
     * @param {Object} card
     * @param {String} card.idCard
     */
    handleAddCard(card) {
      this.handleChangeType('add', card)
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  height: 100%
  padding: 32px
  .row
    height: 100%
    .col
      text-align: left
      height: 100%
      .title
        margin-bottom: 8px
      .description
        margin-bottom: 16px
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
