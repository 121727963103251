import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_SCHEDULE_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },
  /**
   * Setea las agendas a la lista
   * @param {*} state
   * @param {Array} schedules Lista de agenda
   */
  SET_SCHEDULES(state, schedules) {
    state.schedules = schedules
  },
  /**
   * Agrenda un evento de agenda a la lista
   * @param {*} state
   * @param {Object} schedule Objeto de una agenda
   */
  ADD_SCHEDULE(state, schedule) {
    state.schedules.push(schedule)
  },
  /**
   * Actualiza los datos de una agenda
   * @param {*} state
   * @param {Object} schedule Objeto de la agenda editada
   */
  UPDATE_SCHEDULE(state, schedule) {
    const sch = state.schedules.find((sch) => sch._id === schedule._id)
    if (!sch) return
    sch.title = schedule.title
    sch.description = schedule.description
    sch.scheduled_for = new Date(schedule.scheduled_for).toISOString()
    if (sch.completed_at)
      sch.completed_at = new Date(schedule.completed_at).toISOString()
  },
  /**
   * Quita un evento de la lista agenda
   * @param {*} state
   * @param {Object} schedule Objeto de la agenda
   * @param {String} schedule._id Id de la agenda
   */
  QUIT_SCHEDULE(state, schedule) {
    state.schedules = state.schedules.filter((sh) => sh._id !== schedule._id)
  },
  /**
   * Quita los eventos de la lista agendas de un ticket
   * @param {*} state
   * @param {String} ticketId Id del ticket de la agenda
   */
  QUIT_SCHEDULES_IN_TICKET(state, ticketId) {
    state.schedules = state.schedules.filter((sh) => sh.ticketId !== ticketId)
  },
}

export default mutations
