<template>
  <a-form
    :form="form"
    layout="vertical"
    @submit.prevent="handleSubmit"
    class="form_provider"
  >
    <a-form-item label="WhatsApp Number">
      <a-row :gutter="8" type="flex">
        <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
        <a-col :span="16" :order="2">
          <a-input
            v-decorator="[
              'number',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                  {
                    pattern: /^([0-9])*$/g,
                    message: 'Solo se admiten números.',
                  },
                ],
              },
            ]"
            :maxLenght="20"
            placeholder="Escribe aquí..."
          />
        </a-col>
        <a-col :span="8" :order="1">
          <a-select
            show-search
            v-decorator="[
              'code',
              {
                rules: [
                  {
                    required: false,
                    message: 'Por favor rellena el campo',
                  },
                ],
              },
            ]"
            option-filter-prop="children"
            placeholder="País"
          >
            <a-select-option
              v-for="country in countries"
              :key="country._id"
              :title="country.nameSpanish"
            >
              {{ country.dialCode }}
              {{ country.nameSpanish }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="Account SID">
      <a-input
        v-decorator="[
          'wspsid',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        placeholder="Escribe aquí..."
      />
    </a-form-item>
    <a-form-item label="Auth Token">
      <a-input-password
        v-decorator="[
          'token',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        placeholder="Escribe aquí..."
      />
    </a-form-item>
    <div class="form_provider__footer">
      <a-button @click="$emit('onCancel')" class="footer_button__default"
        >Volver</a-button
      >
      <a-button html-type="submit" type="primary">Integrar con Twilio</a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  name: 'FormProviderTwilio',
  props: {
    countries: {
      type: Array,
      required: false,
      default: () => [
        { _id: 1, nameSpanish: 'Perú', dialCode: '+51' },
        { _id: 2, nameSpanish: 'Argentina', dialCode: '+52' },
      ],
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-provider-twilio' })
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const code = this.foundDialCode(values.code)
          values.wspbusiness = {
            code: code,
            number: values.number,
          }
          delete values.code
          delete values.number
          this.$emit('onSubmit', values)
        }
      })
    },
    foundDialCode(idCountry) {
      const country = this.countries.find(
        (country) => country._id === idCountry
      )
      if (country !== undefined) return country.dialCode
    },
  },
}
</script>

<style lang="sass" scoped>
.form_provider
  // position: relative
  &__footer
    display: flex
    justify-content: flex-end
    // position: absolute
    // bottom: 36px
    .footer_button
      &__default
        margin-right: 12px
</style>
