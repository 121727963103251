<template>
  <div class="card-plan">
    <div
      class="content"
      :class="{
        default: !currentPlan && !recommend,
        current: currentPlan,
        recommend: recommend,
        'display-flex': twoColumns,
        'align--center': twoColumns,
      }"
    >
      <a-tag class="tag" color="#108ee9"> Recomendado </a-tag>
      <a-row
        class="full-width full-height"
        :class="{
          'display-flex': twoColumns,
          'align--center': twoColumns,
        }"
        :gutter="60"
      >
        <a-col v-if="twoColumns" :span="12">
          <img
            svg-inline
            width="360"
            :src="
              require(`@/app/shared/assets/whoooa_graphics/solid/gaming.svg`)
            "
            class="card__picture-full"
          />
        </a-col>
        <a-col
          :span="spanColumn"
          class="display-flex direction-column full-height"
        >
          <img
            v-if="!twoColumns"
            svg-inline
            :src="require(`@/app/commons/assets/${picture}.svg`)"
            height="44"
            class="card__picture"
          />
          <h6
            class="heading-h6 card__title"
            :class="{ 'text--left': twoColumns, 'mrg-bottom-12': twoColumns }"
          >
            {{ namePlan }}
          </h6>
          <h4
            v-if="!twoColumns"
            class="semibold-20 margin-bottom-0 card__price"
          >
            <template v-if="price.custom">{{ price.content }}</template>
            <template v-else>
              <template v-if="showCoinSymbol">
                {{ coinSymbol }}
              </template>
              {{ price }} <span class="regular-12"> por usuario</span>
            </template>
          </h4>
          <ul class="list-benefits">
            <li
              v-for="(benefit, index) in benefits"
              :key="index"
              class="benefit"
            >
              <a-icon
                type="check"
                class="yellow_6"
                style="font-size: 12px; margin-right: 12px"
              />
              <p class="body-2">{{ benefit }}</p>
            </li>
          </ul>
          <div v-if="!isPlanEnterprise" class="content-footer">
            <a-tooltip v-if="!isDowngrade">
              <span v-if="!isAllowBuyPacks" slot="title"
                >Actualmente no se pueden comprar planes y paquetes a la vez 😥
              </span>
              <a-button
                :type="recommend ? 'primary' : ''"
                class="footer_button"
                :disabled="disabledAdd"
                :loading="btnActions.loading"
                @click="emitAction(isDowngrade, nameButton.type)"
              >
                {{ nameButton.name }}
              </a-button>
            </a-tooltip>
            <a-button
              v-else
              @click="emitAction(isDowngrade, nameButton.type)"
              type="link"
              :class="{
                'link-inactive': !isActiveDowngrade && !isCancelDowngrade,
                'link-active': isActiveDowngrade || isCancelDowngrade,
              }"
              >{{ nameButton.name }}</a-button
            >
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CardPlan',
  props: {
    idPlan: {
      // id de cada plan
      type: String,
      require: true,
    },
    namePlan: {
      // nombre de plan : Free, Advanced, enterprise
      type: String,
      require: true,
      default: 'Free',
    },
    price: {
      // price admite Object para customizar el precio, example: {custom: true, content: '90 dollars'}
      type: [String, Object, Number],
      require: true,
      default: '0',
    },
    coinSymbol: {
      type: String,
      require: false,
      default: '$',
    },
    showCoinSymbol: {
      type: Boolean,
      require: false,
      default: true,
    },
    priceDescription: {
      type: String,
      require: false,
      default: 'costo por usuario',
    },
    benefits: {
      // es una lista de todos los beneficios
      type: Array,
      require: false,
      default: () => ['2 usuarios como máximo', 'Todos los canales'],
    },
    currentPlan: {
      // si es el plan actual
      type: Boolean,
      require: false,
      default: false,
    },
    recommend: {
      // si es un plan recomendado(es el plan siguiente al actual)
      type: Boolean,
      require: false,
      default: false,
    },
    isDowngrade: {
      // si se puede hacer downgrade o no
      type: Boolean,
      require: false,
      default: false,
    },
    enterprise: {
      // si la card es enterprise, es una card especial
      type: Boolean,
      require: false,
      default: false,
    },
    disabledBtn: {
      type: Boolean,
      require: false,
      default: false,
    },
    isCancelDowngrade: {
      // si se puede cancelar el downgrade
      type: Boolean,
      require: false,
      default: false,
    },
    isActiveDowngrade: {
      // si el downgrade esta activo, activo: si no hay ningun proceso de baja de plan, inactivo: si hay algun proceso de baja de plan
      type: Boolean,
      require: false,
      default: true,
    },
    picture: {
      type: String,
      require: true,
    },
    twoColumns: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data: () => ({
    btnActions: {
      loading: false,
      disabled: false,
    },
  }),
  computed: {
    ...mapGetters([
      'isAllowBuyPlans',
      'purchase',
      'isAllowBuyPacks',
      'isPlanEnterprise',
    ]),
    /**
     * @returns {Object} { name: String, type: String }
     */
    nameButton() {
      if (this.currentPlan) return { name: 'Actual', type: 'current' }
      else if (this.isDowngrade) {
        if (this.isCancelDowngrade)
          return {
            name: 'Cancelar proceso de baja de plan',
            type: 'canceldown',
          }
        else return { name: `Bajar a ${this.namePlan}`, type: 'downgrade' }
      } else if (this.enterprise) return { name: 'Contáctanos', type: 'concat' }
      else if (
        this.purchase.products.length &&
        this.purchase.products.find(
          (p) => p.type === 'plan' && p.idPlan === this.idPlan
        )
      ) {
        return { name: 'Agregado al carrito', type: 'upgrade' }
      } else return { name: 'Subir de plan', type: 'upgrade' }
    },
    /**
     * @returns {Number} - grillas para la columna
     */
    spanColumn() {
      return this.twoColumns ? 12 : 24
    },
    /**
     * Deshabilitando agregar plan
     * @returns {Boolean}
     */
    disabledAdd() {
      return !this.isAllowBuyPlans || !this.isAllowBuyPacks || this.currentPlan
    },
  },
  methods: {
    ...mapActions(['downgrade', 'checkDowngrade']),
    /**
     * @param {Boolean} downgrade Se está bajando de plan
     * @param {String} typeAction [upgrade,downgrade,canceldown] Tipo de acción a realizar
     */
    async emitAction(downgrade, typeAction) {
      console.log('[emitAction][downgrade,typeAction]', downgrade, typeAction)
      this.btnActions.loading = true
      if (downgrade) {
        const response = await this.checkDowngrade(this.idPlan)
        console.log({ response })
        this.$emit('downgrade', {
          typeAction: typeAction,
          idPlan: this.idPlan,
          price: this.price,
          enterprise: this.enterprise,
        })
      } else if (this.enterprise) this.$emit('showContact')
      else this.$emit('upgrade')
      this.btnActions.disabled = true
      this.btnActions.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.yellow_6
  color: $yellow_6
.margin-bottom-0
  margin-bottom: 0px
.card-plan
  background-color: $white_000
  height: 100%
  text-align: center
  width: 100%
  position: relative
  .content
    padding: 20px 6%
    width: 100%
    height: 100%
    border-radius: 4px
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    border: 1px solid $gray_5
    position: relative
    .tag
      display: none
    .card__title
      margin: 12px auto 4px auto
      color: $gray_dark_900
    .description
      color: $gray_dark_800
    .pricing
      display: flex
      justify-content: center
      align-items: center
      color: $gray_dark_900
      h3, h5
        color: inherit
    .price-description
      margin: 2px auto 12px
      color: $gray_dark_800
    .list-benefits
      padding: 0px
      width: 100%
      color: $gray_7
      .benefit
        list-style: none
        text-align: left
        color: inherit
        display: flex
        align-items: center
        margin-bottom: 2px
        p
          margin-bottom: 0px
          color: inherit
    &-footer
      width: 100%
      height: 100%
      display: flex
      justify-content: flex-end
      flex-direction: column
      &_button
        width: inherit
  .current
    border: 1px solid $gray_5
    .card__title
      color: $gray_dark_700
    .description
      color: $gray_dark_500
    .pricing
      color: $gray_dark_500
      h3, h5
        color: inherit
    .price-description
      color: $gray_dark_500
    .list-benefits
      .benefit
        color: $gray_dark_500
  .recommend
    border: 1px solid $blue_6
    .tag
      display: block
      position: absolute
      top: -12px
      margin-right: 0
.link-active
  color: $gray_dark_700
.link-inactive
  color: $gray_dark_600
  cursor: not-allowed
  opacity: 0.5
  text-decoration: none
  pointer-events: none
.card__price
  margin-bottom: 12px
.card__picture-full
  margin-right:
.mrg-bottom-12
  margin-bottom: 12px !important
</style>
