<template>
  <div class="section-state-tickets">
    <div class="header">
      <p class="title heading-h6">Resumen de envíos SMS</p>
      <div>
        <a-tooltip
          placement="bottomRight"
          :arrowPointAtCenter="true"
          overlayClassName="tooltip-dash2"
        >
          <template slot="title">
            Puedes visualizar la cantidad y estados de los SMS enviados.<br />
            <strong>Pendientes: </strong> Mensajes SMS que están siendo
            procesados para su envío.<br />
            <strong>Enviados: </strong>Mensajes SMS que acaban de ser enviados a
            los clientes.<br />
            <strong>Entregados: </strong>Mensajes SMS recibidos por los
            clientes.<br />
            <strong>Fallidos: </strong> Mensajes SMS que han rebotado.
            <br />
            Para este dashboard solo se requiere el rango de fecha y hora.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!--POPOVER DE CONFIRMACION-->
        <a-popconfirm
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template v-if="!isLoading" slot="title">
              Al descargar este dashboard en un excel solo se respetarán los
              filtros superiores.
            </template>
            <a-icon
              type="download"
              class="section__icon--download"
              :class="{
                'btn-dowload': !isLoading,
                'icon--disabled': isLoading,
              }"
            />
          </a-tooltip>
        </a-popconfirm>
      </div>
    </div>
    <div class="body">
      <p class="text--left body__text">
        Recueda que puedes seleccionar los estados que deseas visualizar.
      </p>
      <LineChart :styles="myStyles" :chartdata="chartdata" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LineChart from '@/app/dashboard/components/organisms/charts/LineChart.vue'
import moment from 'moment'

export default {
  name: 'SectionSMS',
  components: {
    LineChart,
  },
  props: {
    date: { type: Array, required: true },
    lineId: { type: String, default: 'allLines' },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '310px',
      margin: '12px 20px',
      position: 'relative',
    },
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
  }),
  computed: {
    ...mapGetters(['stateSMS']),
    chartdata() {
      const data = {
        labels: this.stateSMS.queued.map((item) => item.date),
        datasets: [
          {
            label: 'Pendientes',
            data: this.stateSMS.queued.map((item) => item.quantity),
            borderColor: '#C22FB3',
            backgroundColor: '#C22FB3',
            fill: false,
            borderWidth: 2,
          },
          {
            label: 'Enviados',
            data: this.stateSMS.sent.map((item) => item.quantity),
            borderColor: '#2FC25B',
            backgroundColor: '#2FC25B',
            fill: false,
            borderWidth: 2,
          },
          {
            label: 'Entregados',
            data: this.stateSMS.delivered.map((item) => item.quantity),
            borderColor: '#CE8934',
            backgroundColor: '#CE8934',
            fill: false,
            borderWidth: 2,
          },

          {
            label: 'Fallidos',
            data: this.stateSMS.failed.map((item) => item.quantity),
            borderColor: '#E10000',
            backgroundColor: '#E10000',
            fill: false,
            borderWidth: 2,
          },
        ],
      }
      return data
    },
  },
  methods: {
    ...mapActions(['exportSMS', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'status_sms' },
      })
    },
    async exportDashboard() {
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      await this.exportSMS({ date, lineId })
    },
  },
}
</script>
<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-state-tickets
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.body__text
  margin: 12px 20px
  color: $gray_8
.section__icon--download
  font-size: 20px
  color: #595959
  margin-right: 8px
</style>
