<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="handleCancel"
    :afterClose="handleCancel"
    :body-style="{ paddingBottom: '8px' }"
    centered
  >
    <p>
      Crea etiquetas, podrán ser asignadas a las conversaciones y así poder
      realizar reportes.
    </p>
    <a-form
      layout="vertical"
      :form="form"
      @submit="handleOk"
      class="modal-form"
    >
      <a-form-item label="Nombre de etiqueta" class="section-form-item">
        <a-input
          :maxLength="50"
          v-decorator="[
            'title',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
      <a-form-item label="Color" class="section-form-item">
        <a-select
          v-decorator="[
            'color',
            { initialValue: colorDefault },
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor seleccione un color',
                },
              ],
            },
          ]"
          class="form__select-color"
          placeholder="Color"
        >
          <a-select-option v-for="color in COLORS_TAGS" :key="`${color}`">
            <ColorPick
              :color="color"
              size="bold"
              :pickStyles="{ width: '80px' }"
            />
          </a-select-option>
        </a-select>
      </a-form-item>
      <h6 class="semibold-14">Asignación de colas</h6>
      <a-radio-group class="modal__radios" v-model="assignAction">
        <!--Todas las colas-->
        <a-radio value="allLines" :style="radioStyle">
          <span class="form__radio__label">
            Asignar a todas las colas actuales y a las colas futuras.
          </span>
          <p class="form__radio__description">
            Esta etiqueta aparecerá para todos los tickets.
          </p>
        </a-radio>
        <!--Colas especificas-->
        <a-radio value="specific" :style="radioStyle">
          <span class="form__radio__label"> Asignar a colas (Opcional) </span>
          <a-form-item class="form__radio__form-item">
            <a-select
              mode="multiple"
              placeholder="Selecciona aquí..."
              v-decorator="[
                'linesIds',
                {
                  rules: [
                    { required: false, message: 'Este campo es requerido' },
                  ],
                },
              ]"
              :disabled="assignAction !== 'specific'"
            >
              <a-select-option v-for="line in lines" :key="line._id">
                {{ line.name }}
              </a-select-option>
            </a-select>
            <span slot="extra" class="text--pre-wrap"
              >Esta etiqueta solo le aparecerá a los tickets asignados a la cola
              seleccionada</span
            >
          </a-form-item>
        </a-radio>
      </a-radio-group>
    </a-form>
    <basic-footer
      :ok-text="okText"
      :ok-loading="okButton.loading"
      :cancel-loading="cancelButton.loading"
      :ok-disabled="hasErrors(form.getFieldsError())"
      :cancel-disabled="cancelButton.disabled"
      cancelText="Cancelar"
      @onOk="handleOk"
      @onCancel="handleCancel"
    />
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ColorPick from '@/app/shared/components/pickers/ColorPick.vue'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import { COLORS_TAGS } from '../../utils/tagsUtil'

export default {
  name: 'ModalFormTag',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    okText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  components: {
    ColorPick,
    BasicFooter,
  },
  data: () => ({
    COLORS_TAGS,
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      loading: false,
      disabled: false,
    },
    assignAction: 'allLines',
    radioStyle: {
      width: '100%',
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form_tag' })
  },
  watch: {
    /**
     * Verifica que camnbia la data source
     */
    dataSource() {
      this.$nextTick(() => {
        this.setValues()
      })
    },
  },
  computed: {
    ...mapGetters(['lines']),

    /**
     * @returns {String} color por defecto
     */
    colorDefault() {
      return this.COLORS_TAGS[0]
    },
  },
  methods: {
    /**
     * Verifica si hay errores en el formulario
     */
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field])
    },
    /**
     * Setea valores al formulario
     */
    setValues() {
      if (!this.dataSource || !Object.keys(this.dataSource).length) return
      this.assignAction = this.dataSource.allLines ? 'allLines' : 'specific'
      this.form.setFieldsValue({
        title: this.dataSource.title,
        color: this.dataSource.color,
        linesIds: this.dataSource.lines?.map((line) => line._id),
      })
    },
    /**
     * Evento ok para el modal
     */
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.dataSource._id) values.tagId = this.dataSource._id
          this.okButton.loading = true
          this.okButton.disabled = true
          this.cancelButton.disabled = true
          await this.onOk({
            allLines: this.assignAction === 'allLines',
            ...values,
          })
          this.okButton.loading = false
          this.okButton.disabled = false
          this.cancelButton.disabled = false
        }
      })
    },
    /**
     * Evento para cancelar
     */
    handleCancel() {
      this.$emit('onClose')
      this.form.resetFields()
    },
  },
}
</script>

<style lang="sass">
.form__radio__label
  color: $gray_9
.form__radio
  &__description
    color: $gray_7
    margin-left: 28px
    margin-top: 2px
    margin-bottom: -4px
  &__form-item
    margin-left: 28px !important
    width: 31.7em
    margin-top: 2px !important
</style>
<style lang="sass">
.form__select-color.ant-select .ant-select-selection__rendered
  height: min-content
  display: flex
  align-items: center
</style>
