export default {
  methods: {
    /**
     * Filtra las etiquetas de mayor a menor
     * @param {Object} args
     * @param {Object} args.tags
     * @returns {Object[]}
     */
    sortTags({ tags }) {
      return [...tags].sort(
        (a, b) => new Date(b.assigned_at) - new Date(a.assigned_at)
      )
    },
  },
}
