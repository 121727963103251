import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import { NEXT_COLOR_FOR_COMPANY } from '@/app/companies/utils/utilsCompanies'
import { message } from 'ant-design-vue'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key_restmain = process.env.VUE_APP_CRYPT_KEY_RESTMAIN
const simpleCrypto = new SimpleCrypto(crypt_key_restmain)

const actions = {
  /**
   * Obtiene las empresas no paginadas
   * @param {*} context
   */
  async getSimpleCompanies(context) {
    try {
      const url = `${vari.UHR}/master/subspaces/simple`
      const response = await axios.get(url)
      const data = response.data
      if (data.success) {
        context.commit('SET_SIMPLE_COMPANIES', data.data)
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },

  /**
   * Obtiene las empresas paginadas
   * @param {*} context
   * @param {Object} args
   * @param {Number} args.page
   * @param {Number} args.limit
   * @param {String} args.name
   * @param {String} args.country
   */
  async getPaginateCompanies(
    context,
    { page = 1, limit = 12, name, operation, country }
  ) {
    try {
      const url = `${vari.UHR}/master/subspaces`
      const params = {
        page,
        limit,
        name,
        operation: operation === 'all' ? null : operation,
        country: country === 'all' ? null : country,
      }
      const response = await axios.get(url, { params })
      const data = response.data
      if (data.success) {
        context.commit('SET_PAGINATE_COMPANIES', data.companies.data)
        delete data.companies.data
        context.commit('SET_PAGINATION_COMPANIES', data.companies)
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },
  /**
   * Agrega una empresa
   * @param {} context
   * @param {Object} args
   * @param {String} args.country
   * @param {String[]} args.operation
   * @returns
   */
  async addCompany(context, { name, country, operation = [], avatar }) {
    try {
      const url = `${vari.UHR}/master/subspaces`
      const color = NEXT_COLOR_FOR_COMPANY([
        ...context.getters.simple_companies,
        context.getters.company,
      ])
      const company = {
        name,
        operation,
        country,
        color,
        avatar,
      }
      const formData = new FormData()
      for (const property in company) {
        let value = company[property]
        if (Array.isArray(value)) value = JSON.stringify(value)
        formData.append(property, value)
      }

      const response = await axios.post(url, formData)
      if (response.data.success) {
        context.commit('ADD_PAGINATE_COMPANY', response.data.company)
        context.commit('ADD_SIMPLE_COMPANY', response.data.company)
      }
      return response.data
    } catch (error) {
      return error?.response?.data
    }
  },
  /**
   * Actualiza una empresa
   * @param {} context
   * @param {Object} args
   * @param {String} args.companyId
   * @param {String} args.name
   * @param {String} args.country
   * @param {String[]} args.operation
   * @returns
   */
  async updateCompany(
    context,
    { avatar, companyId, name, country, operation = [] }
  ) {
    try {
      const url = `${vari.UHR}/master/subspaces/${companyId}`
      const company = {
        avatar,
        name,
        operation,
        country,
      }
      const formData = new FormData()
      for (const property in company) {
        let value = company[property]
        if (Array.isArray(value)) value = JSON.stringify(value)
        formData.append(property, value)
      }
      const response = await axios.put(url, formData)
      if (response.data.success) {
        // Actualiza la empresa paginada
        context.commit('UPDATE_PAGINATE_COMPANY', {
          companyId,
          company: response.data.data,
        })
        // Actualiza las empresas simples
        context.commit('UPDATE_SIMPLE_COMPANY', {
          companyId,
          company: response.data.data,
        })
        // Actualiza la empresa actual si es que es la misma que la actualizada
        const currentCompany = context.getters.company
        if (currentCompany && currentCompany._id === companyId)
          context.commit('SET_COMPANY', {
            company: response.data.data,
            setAll: false,
          })
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },
  /**
   * Elimina una empresa
   * @param {} context
   * @param {Object} args
   * @param {String} args.companyId
   * @param {String} args.password
   * @returns
   */
  async deleteCompany(context, { companyId, password }) {
    try {
      const url = `${vari.UHR}/master/subspaces/${companyId}`
      const params = {
        password: simpleCrypto.encrypt(password),
      }
      const response = await axios.delete(url, { params })
      if (response.data.success) {
        context.commit('REMOVE_PAGINATE_COMPANY', companyId)
        context.commit('REMOVE_SIMPLE_COMPANY', companyId)
      } else if (!response.data.success && response.data.details) {
        message.error(response.data.details)
      }
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },
  /**
   *
   * @param {*} context
   * @param {Object} args
   * @param {String} args.companyId Id de la sub-empresa a cambiar
   * @param {String} args.cryptPassword Password para validar
   * @returns
   */
  async changeCurrentCompany(context, { companyId, cryptPassword }) {
    try {
      const url = `${vari.UHR}/master/auth/signin/company/${companyId}`
      const response = await axios.put(url, { password: cryptPassword })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },
}
export default actions
