import Shop from '@/app/shop/views/Shop'
import SectionMenuMarket from '@/app/shop/components/market/SectionMenuMarket'
import SectionMenuBilling from '@/app/shop/components/billing/SectionMenuBilling'
import SectionMenuSpendings from '@/app/shop/components/spendings/SectionMenuSpendings'
import SectionMenuPaymentMethods from '@/app/shop/components/paymentMethods/SectionMenuPaymentMethods'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
    children: [
      {
        path: 'market',
        name: 'menu-market',
        component: SectionMenuMarket,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'master_agent'],
          title: `Market | ${VUE_APP_NAME}`,
        },
      },
      {
        path: 'billing',
        name: 'menu-billing',
        component: SectionMenuBilling,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'master_agent'],
          title: `Facturación | ${VUE_APP_NAME}`,
        },
      },
      {
        path: 'payment-methods',
        name: 'menu-payment-methods',
        component: SectionMenuPaymentMethods,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'master_agent'],
          title: `Métodos de pago | ${VUE_APP_NAME}`,
        },
      },
      {
        path: 'recharge-spending',
        name: 'menu-recharge-spending',
        component: SectionMenuSpendings,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'master_agent'],
          title: `Recargas y gastos | ${VUE_APP_NAME}`,
        },
      },
    ],
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'master_agent'],
    },
  },
]
