<template>
  <div class="section">
    <a-alert
      v-if="alert.visible"
      :message="alert.message"
      :type="alert.type"
      show-icon
      style="margin-bottom: 24px"
    />
    <h5 class="heading-h5">Restablece tu contraseña</h5>
    <p class="body-2">
      Escribe tu dirección de email y te enviaremos un enlace para restablecer
      tu contraseña. Recuerda que tu correo deberá estar afiliado a {{ app }}.
    </p>
    <a-form
      :form="form"
      layout="vertical"
      class="section__form"
      @submit.prevent="handleSubmit"
    >
      <a-form-item label="Correo">
        <a-input
          placeholder="Escribe aquí..."
          v-decorator="[
            'email',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                { type: 'email', message: 'No es un correo válido' },
                {
                  whitespace: true,
                  message: 'No se permiten espacios en blanco',
                },
              ],
            },
          ]"
          :size="inputs_size"
        />
      </a-form-item>
      <a-row :gutter="{ xs: 8, sm: 8, md: 8, lg: 12, xl: 12 }">
        <a-col :span="7">
          <a-button
            class="section__footer__button"
            @click="handleBack"
            :size="buttons_size"
            >Volver</a-button
          >
        </a-col>
        <a-col :span="17">
          <a-button
            type="primary"
            html-type="submit"
            class="section__footer__button"
            :disabled="disabled"
            :loading="loading"
            :size="buttons_size"
            >Restablece tu contraseña</a-button
          >
        </a-col>
      </a-row>
      <p v-if="visibleMessage" class="regular-14 mrg-top-12 form__message">
        <i>Podrás volver a intentar en {{ timerSeconds }} segundos</i>
      </p>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MailForm',
  data: () => ({
    alert: {
      visible: false,
      type: '',
      message: '',
    },
    app: process.env.VUE_APP_NAME,
    disabled: false,
    loading: false,
    timerSeconds: 0,
    attempts: 0, // numero de intentos
    maxAttempts: 3, // numero maximo de intentos
    timer: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'mail-form' })
  },
  destroyed() {
    /** Detiene el contandor si la vista es destruida*/
    this.stopTimer(this.timer)
  },
  computed: {
    ...mapGetters(['inputs_size', 'buttons_size']),

    /**
     * Visibilidad del mensaje de timer
     * @return {Boolean}
     */
    visibleMessage() {
      if (this.timerSeconds === 0 || this.timerSeconds === 30) return false
      else return true
    },
  },
  methods: {
    ...mapActions(['sendMailToRestore']),

    handleBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true
          this.disabled = true
          const email = values.email.toLowerCase()
          const response = await this.sendMailToRestore(email)
          this.loading = false
          if (response.success) {
            this.alert.visible = true
            this.alert.type = 'info'
            this.alert.message =
              'Se ha enviado el correo de restauración. Por favor, revisa tu bandeja de entrada, incluyendo la carpeta de Spam.'
            this.startTimer()
          } else {
            this.alert.visible = true
            this.alert.type = 'error'
            this.alert.message = response.details
              ? response.details
              : 'Ocurrió un error mientras se trataba de enviar el correo'
            this.disabled = false
            if (this.attempts === this.maxAttempts) {
              this.disabled = true
              this.attempts = 0
              return this.startTimer()
            }
            this.attempts++
          }
        }
      })
    },
    /**
     * Comienza el timer desde 30 seg hacia 0seg cada 1seg
     */
    startTimer() {
      this.timerSeconds = 30
      this.timer = setInterval(() => {
        if (this.timerSeconds === 0) return this.stopTimer(this.timer)
        this.timerSeconds = this.timerSeconds - 1
      }, 1000)
    },
    /**
     * Detiene el intervalo y deshabilita el boton
     * @param {Function} timer - intervalo a limpiar
     */
    stopTimer(timer) {
      clearInterval(timer)
      this.timerSeconds = 30
      this.disabled = false
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  width: auto
  max-width: 50em
  &__form
    margin: auto 6%
    @include xs
      margin: auto
    @include sm
      margin: auto
    @include md
      margin: auto
  &__footer
    &__button
      width: 100%
.form__message
  color: $gray_7
</style>
