import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import format from '@/app/shared/utils/format'

const actions = {
  /**
   * Obtiene todas las descargas
   * @param {*} context
   * @param {Object} args
   * @param {Number} args.limit
   * @param {Number} args.page
   * @param {Boolean} args.next - si es para obtener las siguientes paginas
   * @param {String} args.moduleType - 'all', 'dashboard'
   * @param {String} args.status
   */
  async getDownloads(
    context,
    {
      limit = 12,
      page = 1,
      next = false,
      moduleType = 'all',
      status = 'all',
    } = {
      limit: 12,
    }
  ) {
    try {
      const response = await axios.get(
        `${vari.UHR}/downloads?page=${page}&limit=${limit}&module=${moduleType}&status=${status}`
      )
      const downloadsData = response.data.data
      const morePaginate = !downloadsData.data.length

      if (next) context.commit('ADD_DOWNLOADS', downloadsData.data)
      else context.commit('SET_DOWNLOADS', downloadsData.data)

      delete downloadsData.data
      context.commit('SET_PAGINATE_DOWNLOADS', downloadsData)

      return { ...response.data, morePaginate }
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },
  /**
   * ELimina una descarga
   * @param {String} downloadId
   */
  async deleteDownload(context, downloadId) {
    try {
      const response = await axios.delete(`${vari.UHR}/downloads/${downloadId}`)
      if (response.data.success) {
        context.commit('DELETE_DOWNLOAD', downloadId)
      }
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtiene el limite de descargas
   * @param {*} context
   * @returns
   */
  async getLimitDownloads(context) {
    try {
      const token = format.fixedEncodeURIComp(context.getters.token)
      const company = context.getters.company
      if (!company) return
      const response = await axios.get(
        `${vari.UHM}/queue/download/${company._id}/count?token=${token}`
      )
      context.commit('SET_SPENT_LIMIT', response.data.countDownload)
    } catch (error) {
      console.error('[getLimitDownload]', error)
    }
  },
}

export default actions
