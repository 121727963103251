<template>
  <a-menu
    mode="inline"
    :inlineIndent="40"
    :default-selected-keys="[defaultSelected]"
    v-model="menuSelected"
  >
    <template v-for="item in menuItems">
      <a-menu-item
        :key="item.key"
        style="text-align: left"
        @click="handleSelectMenu(item.key)"
      >
        {{ item.title }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
export default {
  name: 'MenuShop',
  props: {
    itemSelected: {
      type: String,
    },
  },
  data: () => ({
    menuItems: [
      { order: 1, title: 'Market', key: 'menu-market' },
      { order: 2, title: 'Facturación', key: 'menu-billing' },
      { order: 3, title: 'Métodos de pago', key: 'menu-payment-methods' },
      { order: 3, title: 'Recargas y gastos', key: 'menu-recharge-spending' },
    ],
    menuSelected: [''],
  }),
  watch: {
    nameRoute(val) {
      this.menuSelected = [val]
    },
  },
  mounted() {
    this.menuSelected = [this.defaultSelected]
  },
  computed: {
    // menu por default seleccionado
    defaultSelected() {
      if (!this.itemSelected) {
        const menuItem = this.menuItems.find((item) => item.order === 1)
        this.handleSelectMenu(menuItem.key)
        return menuItem.key
      } else return this.itemSelected
    },
    nameRoute() {
      return this.$route.name
    },
  },
  methods: {
    handleSelectMenu(menuItem) {
      this.$router.push({ name: menuItem })
    },
  },
}
</script>
<style lang="sass" scoped></style>
