<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="$emit('onClose')"
    :body-style="{ height: heightBody, padding: '0px' }"
    centered
  >
    <custom-section-scroll
      :master-styles="{ height: heightBody }"
      :content-styles="{ padding: '24px' }"
    >
      <div v-for="(value, key) in dataSource" :key="key">
        <a-row v-if="keysAllowed.includes(key)" class="content">
          <h6 class="semibold-14">{{ transformkey(key) }}</h6>
          <template v-if="transformValue(key, value).media">
            <anchor
              v-if="transformValue(key, value).media.typeAttachment === 'image'"
              :href="transformValue(key, value).media.url"
            >
              <img
                :src="transformValue(key, value).media.url"
                class="modal__picture mrg-top-4"
              />
            </anchor>
            <!--Si es un video-->
            <video
              v-else-if="
                transformValue(key, value).media.typeAttachment === 'video'
              "
              controls
              alt="Video de respuesta rápida"
              class="modal__picture mrg-top-4"
            >
              <source
                type="video/mp4"
                :src="transformValue(key, value).media.url"
              />
            </video>
            <!--Si es un archivo-->
            <anchor
              v-else
              :href="transformValue(key, value).media.url"
              class="mrg-top-4"
            >
              {{ transformValue(key, value).media.caption }}
              <a-icon type="download" />
            </anchor>
            <!--Si tiene mensaje-->
            <p
              v-if="transformValue(key, value).message"
              :class="{ 'mrg-top-12': transformValue(key, value).media }"
              class="text--pre-line mrg-bottom-0"
            >
              {{ transformValue(key, value).message }}
            </p>
          </template>
          <p v-else class="text--pre-line mrg-bottom-0">
            {{ transformValue(key, value) }}
          </p>
        </a-row>
      </div>
    </custom-section-scroll>
    <div slot="footer">
      <a-button @click="$emit('onClose')">Cerrar visualización</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import moment from 'moment'
import Anchor from '@/app/shared/components/atoms/Anchor'
export default {
  name: 'ModalPreviewFastAnswers',
  props: {
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: true,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    keysAllowed: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    moment,
  }),
  components: {
    CustomSectionScroll,
    Anchor,
  },
  computed: {
    ...mapGetters(['active_breakpoint']),

    /**
     * @return {String} altura del cuerpo del modal
     */
    heightBody() {
      return !this.active_breakpoint.is_mobile ? '45em' : '32em'
    },
  },
  methods: {
    /**
     * Transforma la propiedad de la data source
     * @param {String}
     */
    transformkey(key) {
      switch (key) {
        case 'title':
          return 'Título'
        case 'body':
          return 'Mensaje'
        case 'lines':
          return 'Colas asignadas'
        case 'apiClientId':
          return 'Teléfono'
        case 'blockedFor':
          return 'Bloqueado por'
        case 'channelCompanies':
          return 'ID de canales'
        case 'reason':
          return 'Motivo'
        case 'created_at':
          return 'Fecha y hora de creación'
        default:
          return null
      }
    },
    /**
     * Transfroma el valor dependiendo de la propiedad data source
     * @param {Sstring} key
     * @param {*} value
     */
    transformValue(key, value) {
      switch (key) {
        case 'lines': {
          const namesLines = value.map((line) => line.name)
          return namesLines.join(', ')
        }
        case 'channelCompanies': {
          const channels = value.map((channel) => channel.title)
          return channels.join(', ')
        }
        case 'blockedFor':
          return `${value.names || ''} ${value.surnames || ''}`
        case 'created_at':
          return moment(value.created_at).format('LLL')
        case 'body': {
          const media = this.dataSource.media || null
          if (!media) return value

          return {
            media: {
              isLink: media && media.type === 'document',
              ...media,
            },
            message: value,
          }
        }
        default:
          return value
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__picture
  height: 124px
.content
  margin-bottom: 16px
</style>
