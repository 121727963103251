<template>
  <component
    :is="currentView"
    :loading="isLoadingSelectedTicket"
    :ticketId="ticketId"
  />
</template>

<script>
import SectionClientProfile from '@/app/tickets/components/organisms/sections/SectionClientProfile'
import SectionOldTickets from '@/app/tickets/components/organisms/sections/SectionOldTickets'
import SectionTicketFields from '@/app/tickets/components/organisms/sections/SectionTicketFields'
import DrawerListProducts from '@/app/tickets/components/organisms/sections/DrawerListProducts'
import SectionTicketFiles from '@/app/tickets/components/organisms/sections/SectionTicketFiles'
import SectionComments from '@/app/tickets/components/organisms/sections/SectionComments'
import { mapGetters } from 'vuex'

export default {
  name: 'DrawerTicket',
  components: {
    SectionClientProfile,
    SectionOldTickets,
    DrawerListProducts,
    SectionTicketFields,
    SectionTicketFiles,
    SectionComments,
  },
  computed: {
    ...mapGetters(['isLoadingSelectedTicket', 'ticketSelected']),

    /**
     * @return {Component}
     */
    currentView() {
      const id = this.$route.params.drawerId
      let component = null
      switch (id) {
        case 'client':
          component = SectionClientProfile
          break
        case 'old-tickets':
          component = SectionOldTickets
          break
        case 'custom-fields':
          component = SectionTicketFields
          break
        case 'files':
          component = SectionTicketFiles
          break
        case 'products':
          component = DrawerListProducts
          break
        case 'comments':
          component = SectionComments
      }
      return component
    },
    /**
     * @return {String} ticketId
     */
    ticketId() {
      return this.ticketSelected ? this.ticketSelected._id : null
    },
  },
}
</script>

<style lang="sass" scoped></style>
