<template>
  <a
    class="button display-flex align--center elevation-2 text--left"
    :href="link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <a-avatar :src="imageSrc" size="42px" alt="chat" />
    <div class="button__content">
      <h6 class="semibold-16 button__title">
        {{ title }}
      </h6>
      <p class="regular-14 button__description mrg-bottom-0">
        {{ description }}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ButtonCallToAction',
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    link: { type: String, required: true },
    imageSrc: { type: String, required: true },
  },
}
</script>

<style lang="sass" scoped>
.button
  border-radius: 80px
  background-color: $polar-green_1
  padding: 8px 20px 8px 16px
  &:hover
    border: 1px solid $polar-green_8
.button__content
  margin-left: 8px
.button__title
  color: $polar-green_8
  margin-bottom: 4px
.button__description
  color: $polar-green_7
</style>
