<template>
  <div class="submenu-item" :class="{ 'submenu-item--disabled': disabled }">
    <div
      class="submenu-item__content"
      :class="{
        'submenu-item--active': active,
        'submenu-item--selected': selected,
      }"
      :style="{ width: width }"
      @click="handleSelectSubMenu"
    >
      <a-icon
        v-if="!icon"
        class="submenu-item__icon"
        type="user"
        :rotate="180"
        :class="{ 'mrg-right-12': showTitle }"
      />
      <div
        v-else
        class="submenu-item__icon"
        :class="{ 'mrg-right-12': showTitle }"
        v-html="icon"
      ></div>
      <template v-if="showTitle">
        <p class="submenu-item__title body-3 mrg-bottom-0 ellipsis">
          {{ title }}
        </p>
        <div
          :class="{
            'submenu-item__icon--collapse': collapse,
            'submenu-item__icon--no-collapse': !collapse,
          }"
        >
          <i class="submenu-item__icon--arrow"></i>
        </div>
      </template>
    </div>
    <div v-if="showTitle && collapse" class="submenu-item__collapse">
      <slot name="items"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubMenuItem',
  props: {
    title: { type: String, default: 'item' },
    showIcon: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
    width: { type: String, default: 'max-content' },
    active: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    selected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    forceCollapse: { type: Boolean, default: false },
  },
  data: () => ({
    collapse: false,
  }),
  watch: {
    selected(val) {
      if (!val) {
        this.handleChangeCollapse(false)
      }
    },
    forceCollapse(val) {
      if (val) {
        this.collapse = true
      }
    },
  },
  methods: {
    /**
     * Cambia el estado de collapse del submenu
     * @param {Boolean} collapse
     */
    handleChangeCollapse(collapse) {
      // if (!this.selected) this.collapse = false
      this.collapse = collapse
    },
    /**
     * Selecciona el submenu
     */
    handleSelectSubMenu() {
      this.$emit('onSelected')
      this.handleChangeCollapse(!this.collapse)
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-right-12
  margin-right: 12px

.submenu-item
  &--disabled
    pointer-events: none
    opacity: 0.6
  &__content
    display: flex
    flex-direction: row
    align-items: center
    background-color: $gray_dark_900
    padding: 10px 17px
    color: $gray_dark_600
    cursor: pointer
    position: relative
    &:hover
      color: $white_000
      .submenu-item__icon
        fill: $white_000
  &__icon
    height: 24px
    width: 24px
    // font-size: 24px
    fill: $gray_dark_600
    position: relative
  &__title
  &__collapse
    margin-left: 36px
    height: auto
  &--active
    color: $white_000
    .submenu-item__icon
      fill: $white_000
    &::before
      content: ''
      position: absolute
      left: 0
      width: 4px
      height: 24px
      background-color: $white_000
      border-radius: 0 4px 4px 0
  &--selected
    color: $white_000
    .submenu-item__icon
      fill: $white_000
/**Arrow de collapse */
.submenu-item__icon--no-collapse, .submenu-item__icon--collapse
  // position: relative
  position: absolute
  right: 24px
.submenu-item__icon--arrow
  position: absolute
  top: 50%
  // right: 14px
  width: 10px
  transition: transform .3s cubic-bezier(.645,.045,.355,1)
.submenu-item__icon--arrow::before, .submenu-item__icon--arrow::after
  position: absolute
  width: 6px
  height: 1.5px
  background: #fff
  background: rgba(0,0,0,.65)\9
  background-image: linear-gradient(90deg,rgba(0,0,0,.65),rgba(0,0,0,.65))
  background-image: none\9
  border-radius: 2px
  transition: background .3s cubic-bezier(.645,.045,.355,1),transform .3s cubic-bezier(.645,.045,.355,1),top .3s cubic-bezier(.645,.045,.355,1)
  content: ""
.submenu-item__icon--no-collapse .submenu-item__icon--arrow::before
  transform: rotate(-45deg) translateX(2px)
.submenu-item__icon--no-collapse .submenu-item__icon--arrow::after
  transform: rotate(45deg) translateX(-2px)
.submenu-item__icon--collapse .submenu-item__icon--arrow::before
  transform: rotate(45deg) translateX(2px)
.submenu-item__icon--collapse .submenu-item__icon--arrow::after
  transform: rotate(-45deg) translateX(-2px)
</style>
