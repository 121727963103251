<template>
  <div>
    <ShopTemplate />
  </div>
</template>

<script>
import ShopTemplate from '@/app/shop/components/templates/ShopTemplate.vue'

export default {
  name: 'Shop',
  components: {
    ShopTemplate,
  },
}
</script>

<style scoped></style>
