import vari from '@/app/shared/utils/variables'
import axios from 'axios'
import format from '@/app/shared/utils/format'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Realiza una búsqueda de los leads o contactos
   * @param {vuex} context
   * @param {Object} args
   * @param {String} args.search
   * @param {String} args.channel
   * @param {String} args.channelCompanyId
   * @param {String[]} args.dateRange
   * @param {Number} args.page
   * @param {String} args.agent
   * @param {Array} args.products
   */
  async fetchContacs(
    context,
    {
      page,
      search,
      channel,
      channelCompanyId,
      dateRange,
      clientId,
      agent,
      products,
    }
  ) {
    try {
      const url = `${vari.UHR}/client`
      const contacts = []
      const response = await axios.get(url, {
        params: {
          filterSearch: search,
          channel,
          channelCompanyId,
          page,
          startDate: dateRange[0] ? new Date(dateRange[0]) : null,
          endDate: dateRange[1] ? new Date(dateRange[1]) : null,
          clientId,
          agent,
          products: JSON.stringify(products),
        },
      })
      const result = response.data.result
      context.commit('SET_CONTACTS', result.data)
      context.commit('SET_CONTACTS_PAGES_TOTAL', result.total)

      return contacts
    } catch (error) {
      console.error('[contactModule][fetchContacs]', error)
    }
  },
  /**
   * Realiza una búsqueda de los leads o contactos
   * @param {vuex} context
   * @param {Object} args
   * @param {String} args.clientId
   */
  async fetchUsersOfContact(context, { clientId }) {
    try {
      const response = await axios.get(
        `${vari.UHR}/client/assigned/${clientId}`
      )
      return response.data
    } catch (error) {
      console.error('fetchUsersOfContact', error)
    }
  },
  /**
   * Exporta en excel los contactos
   * @param {*}       context
   * @param {Object} args
   * @param {String} args.search
   * @param {String} args.channel
   * @param {String[]} args.dateRange
   * @param {String} args.agent
   * @param {Array} args.products
   */
  exportContacts(
    context,
    { search, channel, channelCompanyId, dateRange, agent, products }
  ) {
    let response = null
    const token = format.fixedEncodeURIComp(context.getters.token)
    const filters = format.fixedEncodeURIComp(
      simpleCrypto.encrypt({
        search,
        channel,
        channelCompanyId,
        dateRange,
        agent,
        products,
      })
    )
    const url = `${
      vari.UHM
    }/export/clients?filters=${filters}&token=${token}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Obtiene los productos asignados a un ticket sin paginacion
   * @param {String} clienId
   */
  async getAssignedProductsClient(context, clienId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/client/${clienId}/products-crm`
      )
      if (response.data.success) {
        // formatea los tickets a un solo objeto
        const productsFormatted = response.data.result.map((item) => {
          const newObj = item.product
          newObj.ticketId = item.ticket._id
          newObj.ticket = item.ticket.ticket
          return newObj
        })
        const assignedProducts = []
        // recorre para verificar si el producto se repite
        productsFormatted.forEach((product) => {
          const assignedProduct = assignedProducts.find(
            (assignedProduct) => assignedProduct._id === product._id
          )
          // si es repetido
          if (assignedProduct) {
            if (!assignedProduct.tickets) assignedProduct.tickets = []
            // agrega el ticket del producto
            assignedProduct.tickets.push(assignedProduct.ticket)
            // agrega el ticket de producto repetido
            assignedProduct.tickets.push(product.ticket)
          } else {
            // si no existe
            assignedProducts.push(product)
          }
        })
        return { success: true, result: assignedProducts }
      } else return response.data.result
    } catch (err) {
      console.error('getAssignedProductsClient', err)
      return err.response?.data
    }
  },
  /**
   * Elimina un contacto
   * @param {*} context
   * @param {String} clientId
   * @returns
   */
  async deleteContact(context, clientId) {
    try {
      const response = await axios.delete(`${vari.UHR}/client/${clientId}/`)
      if (response.data.success) context.commit('SET_DELETE_CONTACT', clientId)
      return response.data
    } catch (error) {
      console.error('[deleteContact]', error)
    }
  },
  /**
   * Elimina contactos masivamente
   * @param {*} context
   * @param {String[]} clientsIds
   * @returns
   */
  async deleteMassiveContacts(context, clientsIds) {
    try {
      const response = await axios.delete(`${vari.UHR}/client`, {
        data: {
          clients: clientsIds,
        },
      })
      if (response.data.success)
        context.commit('SET_DELETE_CONTACTS', clientsIds)
      return response.data
    } catch (error) {
      console.error('[deleteMassiveContacts]', error)
    }
  },
}
export default actions
