import Profile from '@/app/profile/views/Profile'
// import components to profile
import SectionMenuUser from '@/app/profile/components/organisms/SectionMenuUser'
import SectionMenuCompany from '@/app/profile/components/organisms/SectionMenuCompany'
import SectionMenuGuide from '@/app/profile/components/organisms/SectionMenuGuide'
import SectionMenuPermissions from '@/app/profile/components/organisms/SectionMenuPermissions'

const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'menu-user',
        path: 'user',
        component: SectionMenuUser,
        meta: {
          requiresAuth: true,
          title: `Mi Perfil | ${VUE_APP_NAME}`,
        },
      },
      {
        name: 'menu-company',
        path: 'company',
        component: SectionMenuCompany,
        meta: {
          requiresAuth: true,
          title: `Mi Empresa | ${VUE_APP_NAME}`,
        },
      },
      {
        name: 'menu-permissions',
        path: 'permissions',
        component: SectionMenuPermissions,
        meta: {
          requiresAuth: true,
          title: `Mis permisos | ${VUE_APP_NAME}`,
        },
      },
      {
        name: 'menu-guide',
        path: 'guide',
        component: SectionMenuGuide,
        meta: {
          requiresAuth: true,
          title: `Guía de Usuario | ${VUE_APP_NAME}`,
        },
      },
    ],
  },
]
