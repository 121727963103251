import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'

const actions = {
  /** Obtiene los datos de la empresa al que pertenece el usuario actual */
  async getCompany(context) {
    try {
      const response = await axios.get(`${vari.UHR}/admin/company`)
      const company = response.data
      company.settings.mailboxes = company.settings.mailboxes.map((box) => {
        box.notifications = 0
        return box
      })
      if (!company.channels) company.channels = {}
      context.commit('SET_COMPANY', { company })
      if (company && company.wallet)
        context.commit('SET_COMPANY_WALLET', company.wallet)
      // Actualiza los ajustes del nombre del buzon en los tickets
      if (await compare.isAllowedFor(context, ['agent'])) {
        if (context.getters.tickets.length > 0) {
          context.commit(
            'UPDATE_MAILBOXNAMES_IN_TICKETS',
            context.getters.mailboxes
          )
          // pide los tickets no leidos porque en la respuesta de company trae a lo buzones
          // pero con notifications 0
          await context.dispatch('getTotalUnreadTickets', true)
        } else if (context.getters.ticketsEnded.length > 0) {
          context.commit(
            'UPDATE_MAILBOXNAMES_IN_TICKETS_ENDED',
            context.getters.mailboxes
          )
        }
      }
      context.dispatch('listSandboxChannels') // Listar los canales disponibles de la empresa
      await context.dispatch('getSimpleCompanyChannels') // Listar los canales disponibles de la empresa
    } catch (error) {
      console.error('[companyModule][getCompany]', error)
    }
  },
  //Crear buzon
  async addNewMailbox(context, mailbox) {
    try {
      if (!mailbox.title) return
      let response = null
      const url = `${vari.UHR}/admin/line/mailbox/add`

      response = await axios.put(url, { titleMailbox: mailbox.title })
      if (!response.data.success) return response.data

      //Mutacion
      const newMailbox = {
        idMailbox: response.data.idMailbox,
        title: mailbox.title,
        active: true,
        editable: true,
        created_at: new Date(),
        notifications: 0,
      }
      context.commit('ADD_NEW_MAILBOX', newMailbox)

      //Evento socket.io
      const current_company = context.getters.company
      const payload_socket = {
        idCompany: current_company._id,
      }
      this._vm.$socket.emit('server:company:settings', payload_socket)
      return response.data
    } catch (error) {
      console.error('[companyModule][addNewMailbox] error-', error)
      return error.response
    }
  },
  //Actualizar buzon
  async setMailbox(context, mailbox) {
    if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
      return // Se detiene la función si el tipo de usuario no le corresponde
    try {
      let response = null
      const url = `${vari.UHR}/admin/line/mailbox/update`
      response = await axios.put(url, { mailbox_edited: mailbox })
      if (!response.data.success) return response.data

      //Mutacion
      context.commit('SET_MAILBOX_TITLE', mailbox)

      //Evento socket.io
      const current_company = context.getters.company
      const payload_socket = {
        idCompany: current_company._id,
      }
      this._vm.$socket.emit('server:company:settings', payload_socket)
      return response.data
    } catch (error) {
      console.error('[companyModule][setMailbox] error-', error)
      return error
    }
  },
  //Eliminar mailbox
  async deleteMailbox(context, mailbox) {
    if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
      return // Se detiene la función si el tipo de usuario no le corresponde
    try {
      let response = null
      const url = `${vari.UHR}/admin/line/mailbox/update`
      mailbox.deleted = true
      response = await axios.put(url, { mailbox_edited: mailbox })
      if (!response.data.success) return response.data

      //Mutacion
      context.commit('QUIT_MAILBOX', mailbox)

      //Evento socket.io
      const current_company = context.getters.company
      const payload_socket = {
        idCompany: current_company._id,
      }
      this._vm.$socket.emit('server:company:settings', payload_socket)
      return response.data
    } catch (error) {
      console.error('[companyModule][deleteMailbox] error-', error)
      return error
    }
  },
  /**
   * Mueve los tickets de un buzón a otro
   * @param {*} context
   * @param {Object} args
   * @param {Object} args.mailbox_edited Buzón que se está tocando
   * @param {String} args.action Acción a realizar ['move','finish']
   * @param {Object} args.mailbox_merge Buzón de destino
   */
  async moveToMailbox(context, { mailbox_edited, action, mailbox_merge }) {
    // console.log("moveToMailbox",{ mailbox_edited, action, mailbox_merge })
    try {
      let response = null
      const url = `${vari.UHR}/admin/ticket/mailbox/massive`
      // mailbox.deleted = true
      response = await axios.put(url, {
        mailbox_edited,
        action,
        mailbox_merge,
      })
      if (!response.data.success) return response.data

      //Mutacion
      context.commit('QUIT_MAILBOX', mailbox_edited)
      return response.data
    } catch (error) {
      console.error('[companyModule][deleteMailbox] error-', error)
      return { success: false, result: error }
    }
  },
  //Actualizar queue
  async updateQueue(context, queue) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
        return // Se detiene la función si el tipo de usuario no le corresponde
      let response = null
      const url = `${vari.UHR}/admin/company/queue/update`
      response = await axios.put(url, queue)
      if (!response.data.success) return response.data

      //Mutacion
      queue.delay = queue.delay * 1000 * 60 // Para trasladar los minutos a milisegundos
      context.commit('SET_QUEUE_CONFIG', queue)
      return response.data
    } catch (error) {
      console.error('[companyModule][deleteMailbox] error-', error)
      return error
    }
  },
  /**
   * Enviar con intro
   * @param {*} context
   * @param {Boolean} active
   * @param {Array} phones
   */
  async updateSendWithIntro(context, { sendWithIntro }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
        return
      let response = null
      const config = { sendWithIntro }
      const url = `${vari.UHR}/chat-preferences`
      response = await axios.put(url, config)
      context.commit('SET_CHAT_PREFERENCES', {
        config: 'sendWithIntro',
        value: sendWithIntro,
      })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response
    }
  },
  /**
   * enviar sms a agentes de tickets activos
   * @param {*} context
   * @param {Boolean} active
   * @param {Array} phones
   */
  async updateNotificationsTickets(
    context,
    { active, activeForOthers, phones }
  ) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
        return
      let response = null
      const config = { active, activeForOthers, phones }
      const url = `${vari.UHR}/admin/company/notifications/ticket/received`
      response = await axios.put(url, config)
      context.commit('SET_NOTIFICATIONS_CONFIG', {
        module: 'ticket',
        action: 'received',
        config,
      })
      context.commit('SET_NOTIFICATIONS_PHONES', {
        action: 'received',
        phones,
      })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  /**
   * sube las configuraciones para tickets en cola de espera
   * @param {*} context
   * @param {Boolean} active
   * @param {Array} phones
   */
  async updateNotificationsQueue(
    context,
    {
      quantityForSupervisors,
      quantityForOthers,
      active,
      activeForOthers,
      phones,
    }
  ) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
        return
      let response = null
      const config = {
        quantityForSupervisors,
        quantityForOthers,
        active,
        activeForOthers,
        phones,
      }
      const url = `${vari.UHR}/admin/company/notifications/ticket/queue`
      response = await axios.put(url, config)
      context.commit('SET_NOTIFICATIONS_CONFIG', {
        module: 'ticket',
        action: 'queue',
        config,
      })
      context.commit('SET_NOTIFICATIONS_PHONES', { action: 'queue', phones })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  async getNotificationsPhones(context) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'master_agent'])))
        return
      const response = await axios.get(
        `${vari.UHR}/admin/company/notifications/ticket/phones`
      )
      context.commit('SET_NOTIFICATIONS_PHONES', {
        action: '@all',
        phones: response.data,
      })
    } catch (error) {
      console.error(error)
    }
  },
  /** Actualiza el valor del active de los templates */
  async updateActiveTemplates(context, activeTemplate) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/company/settings/templates`,
        {
          activeTemplate,
        }
      )
      return response.data
    } catch (error) {
      console.error('updateActiveTemplates', error)
    }
  },
  /**
   * Actualizar usuario [administrador] a modo [agente]
   * @param context
   * @param switchAdmin
   * @returns {Promise<void>}
   */
  async updateAdminModeAgent(context, switchAdmin) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/company/admin/agent`,
        { switchAdmin }
      )
      if (response.data.success) {
        context.commit('SET_ADMIN_AGENT', switchAdmin.active)

        if (!switchAdmin.active) {
          context.commit('REMOVE_MENU_AGENT')
        }
      }
      return response.data
    } catch (error) {
      console.error('updateAdminModeAgent', error)
    }
  },
  /**
   * Actualizar usuario [supervisor] a modo agente [agente]
   * @param context
   * @param switchSupervisor
   * @returns {Promise<T>}
   */
  async updateSupervisorModeAgent(context, switchSupervisor) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/company/supervisor/agent`,
        { switchSupervisor }
      )
      if (response.data.success) {
        context.commit('SET_SUPERVISOR_AGENT', switchSupervisor.active)
        // if (!switchSupervisor.active) {
        //   context.commit('REMOVE_MENU_AGENT')
        // }
      }
      return response.data
    } catch (error) {
      console.error('updateSupervisorModeAgent', error)
    }
  },
  /**
   * Obtener la wallet de company
   */
  async getCompanyWallet(context) {
    try {
      const response = await axios.get(`${vari.UHR}/license/wallet-whatsapp`)
      if (response.data.success) {
        const wallet = response.data.data.wallet
        context.commit('SET_COMPANY_WALLET', wallet)
      }
      return response
    } catch (error) {
      console.error('getCompanyWallet', error)
      return error.response?.data
    }
  },
  /**
   * Actualiza la configuracion de chatbots en la empresa
   * @param {*} context
   * @param {Object} args
   * @param {Number} args.minutesTransferTickets
   * @param {Boolean} args.saveDataFirstTimeChatbotVars
   */
  async updateCompanySettingsChatbots(
    context,
    { minutesTransferTickets, saveDataFirstTimeChatbotVars }
  ) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/company/settings/chatbot`,
        { minutesTransferTickets, saveDataFirstTimeChatbotVars }
      )
      if (minutesTransferTickets && response.data.success) {
        context.commit('SET_HOURS_TRANSFER_TICKETS', minutesTransferTickets)
      }
      return response.data
    } catch (error) {
      console.error('[updateCompanySettingsChatbots]', error)
      return error.response?.data
    }
  },
}

export default actions
