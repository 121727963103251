import { mapActions, mapGetters } from 'vuex'
import SimpleCrypto from 'simple-crypto-js'
const crypt_key_Restmain = process.env.VUE_APP_CRYPT_KEY_RESTMAIN
const simpleCryptoRestmain = new SimpleCrypto(crypt_key_Restmain)

export default {
  computed: {
    ...mapGetters(['countriesNotRepeatByName', 'countries']),
  },
  methods: {
    ...mapActions(['changeCurrentCompany']),

    /**
     * Setea un valor inicial para los paises locales
     * @param {Object} countryCompany
     * @param {String} countryCompany.code
     * @param {String} countryCompany.name
     */
    setInitialValueToCountries(countryCompany) {
      let start = 0
      let end = 0
      let countries = []

      if (this.countriesNotRepeatByName.length) {
        if (countryCompany) {
          const index = this.countriesNotRepeatByName.findIndex(
            (country) => country._id === countryCompany._id
          )
          start = index - 10 < 0 ? 0 : index - 10
          end = index + 10
          countries = [...this.countriesNotRepeatByName].slice(start, end)
        } else {
          end = 10
          countries = [...this.countriesNotRepeatByName].slice(0, end)
        }
      } else {
        end = 10
        // para que el listado de paises encontrados no se vea de forma bruca
        countries = [...this.countries].slice(0, end)
      }

      return countries
    },
    searchCountry(value) {
      if (!value) return
      value = value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      const foundCountries = this.countriesNotRepeatByName.filter((item) =>
        item.nameSpanish
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(value)
      )
      return foundCountries
    },
    /**
     * @param {String} companyName Nombre de la sub-empresa a cambiar
     * @param {String} companyId ID de la sub-empresa a cambiar
     * @param {String} password COntraseña en plano para validar (Se encriptará posteriormente)
     */
    async executeChangeCurrentCompany({ companyName, companyId, password }) {
      const cryptPassword = simpleCryptoRestmain.encrypt(password)
      password = null
      const responseData = await this.changeCurrentCompany({
        companyId,
        cryptPassword,
      })
      if (responseData.success && responseData.result === 'switched') {
        localStorage.setItem('access_token', responseData.token)
        this.$router.push({
          name: 'redirect',
          query: { connecting: true, company_name: companyName },
        })
      } else {
        this.$message.error(responseData.details)
      }
    },
  },
}
