<template>
  <a-modal
    v-model="visible"
    title=""
    :afterClose="afterClose"
    :footer="null"
    :bodyStyle="{ padding: 0 }"
    width="auto"
    :centered="true"
    :maskClosable="false"
    :closable="typeModal === 'logout' ? true : false"
  >
    <div class="modal__content" :class="`modal__content--${typeModal}`">
      <!-- modal de esperando respuesta -->
      <div v-if="typeModal === 'pending'" class="modal-login">
        <div class="icons-contain">
          <i class="anticon modal__icon mrg-right-16">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 15c0 2.66 3 5.327 9 8 6-2.667 9-5.333 9-8l.004-2.205C21.01 9.837 21.02 5.217 21 5c0-.768-.295-.946-1.11-1.44-.247-.15-.541-.328-.89-.56-1.5-1-4.005-2-7-2-3.437 0-6.838 1.892-7 2-.349.232-.643.41-.89.56C3.295 4.054 3 4.232 3 5v10zm16.002-1.245L19 15c0 1.514-2.228 3.574-7 5.802C7.23 18.569 5 16.509 5 15V5.363l.128-.079c.323-.2.745-.462.981-.62C7.03 4.051 9.84 3 12 3c2.26 0 4.414.68 5.89 1.664.237.158.659.42.982.62l.138.085a1249.314 1249.314 0 01-.004 6.432l-.004 1.954zM9 9h6a2 2 0 012 2v4a2 2 0 01-2 2H9a2 2 0 01-2-2v-4a2 2 0 012-2zm0 6v-4h6v4H9zm2-6H9V7a3 3 0 116 0v2h-2V7a1 1 0 10-2 0v2zm0 4a1 1 0 102 0 1 1 0 00-2 0z" fill="currentColor"/></svg>
          </i>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon--arrow mrg-right-16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.707 5.293L6.293 6.707 11.586 12l-5.293 5.293 1.414 1.414L14.414 12 7.707 5.293zm5 0l-1.414 1.414L16.586 12l-5.293 5.293 1.414 1.414L19.414 12l-6.707-6.707z" fill="currentColor"/></svg>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 9a7 7 0 117 7h-2v2h-2v2H9v2H2v-5.414l6.148-6.148A7.025 7.025 0 018 9zm3 5h4a5 5 0 10-4.786-3.547l.174.573L4 17.414V20h3v-2h2v-2h2v-2zm2-5a2 2 0 104 0 2 2 0 00-4 0z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">
          Esta cuenta se encuentra conectada
        </h4>
        <div style="display: flex; flex-direction: row">
          <p
            class="info_i2 gray-dark-300 text-center"
            style="margin-right: 4px; margin-bottom: 0"
          >
            Solicitando acceso, esperando respuesta...
          </p>
          <a-icon type="sync" spin style="font-size: 20px; color: #bdbfc1" />
        </div>
      </div>
      <!-- Modal de satisfactorio-->
      <div v-else-if="typeModal === 'success'" class="modal-login">
        <div class="icons-contain">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon mrg-right-16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 9a7 7 0 117 7h-2v2h-2v2H9v2H2v-5.414l6.148-6.148A7.025 7.025 0 018 9zm3 5h4a5 5 0 10-4.786-3.547l.174.573L4 17.414V20h3v-2h2v-2h2v-2zm2-5a2 2 0 104 0 2 2 0 00-4 0z" fill="currentColor"/></svg>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon--arrow mrg-right-16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.707 5.293L6.293 6.707 11.586 12l-5.293 5.293 1.414 1.414L14.414 12 7.707 5.293zm5 0l-1.414 1.414L16.586 12l-5.293 5.293 1.414 1.414L19.414 12l-6.707-6.707z" fill="currentColor"/></svg>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12zm20 0a9 9 0 11-18 0 9 9 0 0118 0zm-8.968 7c-3.357 0-5.226-1.768-5.226-5v-1h10.31l.027.972C17.236 17.215 15.4 19 12.033 19zm3.056-4c-.235 1.395-1.18 2-3.056 2-1.876 0-2.85-.61-3.135-2h6.19zM14 8h3v2h-3V8zm-4 0H7v2h3V8z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">
          Cuenta conectada satisfactoriamente
        </h4>
        <div style="display: flex; flex-direction: row">
          <p
            class="info_i2 gray-dark-300 text-center"
            style="margin-right: 4px; margin-bottom: 0"
          >
            Ingresando...
          </p>
          <a-icon type="sync" spin style="font-size: 20px; color: #bdbfc1" />
        </div>
      </div>
      <!--madal de acceso denegado-->
      <div v-else-if="typeModal === 'fail'" class="modal-login">
        <div class="icons-contain">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon mrg-right-16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.293 2.707l6.72 6.72c.02.342.066.68.135 1.01L2 16.587V22h7v-2h2v-2h2v-2h1.586l6.707 6.707 1.414-1.414-20-20-1.414 1.414zM10 11.414L12.586 14H11v2H9v2H7v2H4v-2.586l6-6zm-.762-6.39a7 7 0 119.738 9.738l-1.447-1.447a5 5 0 10-6.843-6.843L9.238 5.023zM15 7a2 2 0 00-1.99 1.796l2.194 2.194A2 2 0 0015 7z" fill="currentColor"/></svg>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon--arrow mrg-right-16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.707 5.293L6.293 6.707 11.586 12l-5.293 5.293 1.414 1.414L14.414 12 7.707 5.293zm5 0l-1.414 1.414L16.586 12l-5.293 5.293 1.414 1.414L19.414 12l-6.707-6.707z" fill="currentColor"/></svg>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-4.702c1.32 0 2.53.642 3.275 1.702l1.636-1.15A5.993 5.993 0 0012 14.297a5.993 5.993 0 00-4.91 2.551L8.724 18A3.993 3.993 0 0112 16.298zM14 12v-2h2v2h-2zm-6-2v2h2v-2H8z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">Acceso denegado</h4>
        <p class="info_i2 gray-dark-300 text-center">
          No ha sido concedido el acceso al sistema
        </p>
      </div>
      <!-- Modal de permitir acceso -->
      <div v-else-if="typeModal === 'question'" class="modal-login">
        <div class="icons-contain justify-center margin-bottom-20">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 15c0 2.66 3 5.327 9 8 6-2.667 9-5.333 9-8l.004-2.205C21.01 9.837 21.02 5.217 21 5c0-.768-.295-.946-1.11-1.44-.247-.15-.541-.328-.89-.56-1.5-1-4.005-2-7-2-3.437 0-6.838 1.892-7 2-.349.232-.643.41-.89.56C3.295 4.054 3 4.232 3 5v10zm16.002-1.245L19 15c0 1.514-2.228 3.574-7 5.802C7.23 18.569 5 16.509 5 15V5.363l.128-.079c.323-.2.745-.462.981-.62C7.03 4.051 9.84 3 12 3c2.26 0 4.414.68 5.89 1.664.237.158.659.42.982.62l.138.085a1249.314 1249.314 0 01-.004 6.432l-.004 1.954zM9 9h6a2 2 0 012 2v4a2 2 0 01-2 2H9a2 2 0 01-2-2v-4a2 2 0 012-2zm0 6v-4h6v4H9zm2-6H9V7a3 3 0 116 0v2h-2V7a1 1 0 10-2 0v2zm0 4a1 1 0 102 0 1 1 0 00-2 0z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">
          Están intentando acceder a tu cuenta
        </h4>
        <h4 class="heading-h4 color-blue text-center">
          ¿Deseas permitir el acceso?
        </h4>
        <p class="info_i2 gray-dark-300 text-center">
          La sesión se cerrará automáticamente en {{ timeStart }} segundos
        </p>
        <div class="contain-btns">
          <a-button class="btn-cancel" size="large" @click="handleCancel">
            Cancelar
          </a-button>
          <a-button
            class="btn-ok"
            size="large"
            type="primary"
            @click="handleAllow"
          >
            Permitir
          </a-button>
        </div>
      </div>
      <!-- Modal de cerrar sesion-->
      <div v-else-if="typeModal === 'logout'" class="modal-login">
        <div class="icons-contain justify-center margin-bottom-20">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-4.702c1.32 0 2.53.642 3.275 1.702l1.636-1.15A5.993 5.993 0 0012 14.297a5.993 5.993 0 00-4.91 2.551L8.724 18A3.993 3.993 0 0112 16.298zM14 12v-2h2v2h-2zm-6-2v2h2v-2H8z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">UPS!</h4>
        <h4 class="heading-h4 color-blue text-center">
          Un
          {{
            typeUser
              ? transformTypeUser(typeUser).toLowerCase()
              : 'administrador'
          }}
          ha cerrado tu sesión
        </h4>
        <p class="info_i2 gray-dark-300 text-center">
          Si la acción te parece incorrecta consúltalo con tu encargado
        </p>
        <a-button type="link" size="large" @click="handleOk">Aceptar</a-button>
      </div>
      <!-- Modal de cerrar sesion-->
      <div v-else-if="typeModal === 'expired'" class="modal-login">
        <div class="icons-contain justify-center margin-bottom-20">
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-4.702c1.32 0 2.53.642 3.275 1.702l1.636-1.15A5.993 5.993 0 0012 14.297a5.993 5.993 0 00-4.91 2.551L8.724 18A3.993 3.993 0 0112 16.298zM14 12v-2h2v2h-2zm-6-2v2h2v-2H8z" fill="currentColor"/></svg>
        </div>
        <h4 class="heading-h4 color-blue text-center">UPS!</h4>
        <h4 class="heading-h4 color-blue text-center">Su sesión ha expirado</h4>
        <p class="info_i2 gray-dark-300 text-center">
          Para volver a usar la plataforma, inicie sesión nuevamente
        </p>
        <a-button type="link" size="large" @click="handleOk">Aceptar</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import transformTypeUser from '@/app/shared/mixins/transform'

export default {
  name: 'ModalLogin',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    typeModal: {
      type: String,
      required: true,
      enum: [
        'pending', // Conectandose
        'success', // Sesion iniciado correctamente
        'fail', // Se rechazó la conexión
        'question', // Modal para decidir si dejar conectar a la otra sesión
        'expired', // Sesión expirada
        'logout', // Sesion cerrada remotamente
      ],
    },
    typeUser: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    timer: null,
    timeStart: null,
  }),
  mixins: [transformTypeUser],
  computed: {
    ...mapGetters(['profile']),
  },
  watch: {
    typeModal(val) {
      if (val === 'question') {
        this.timeOut()
      }
    },
  },
  created() {
    if (this.typeModal === 'question') {
      this.timeOut()
    }
  },
  methods: {
    ...mapActions(['emitRejectSession', 'emitAcceptSession', 'logout']),
    afterClose() {
      this.$emit('handleNoVisible')
      this.timeStart = null
      clearInterval(this.timer)
    },
    /** Ejecuta el temporizador del Modal cuando ingresa una conexion externa */
    timeOut() {
      this.timeStart = 10
      this.timer = setInterval(() => {
        // console.log('[ModalLogin] timeOut',this.timeStart)
        this.timeStart--
        if (this.timeStart === 0) {
          clearInterval(this.timer)
          this.logout()
          this.$emit('handleNoVisible')
        }
      }, 1000)
    },
    /** CUando se Rechaza el acceso a una sesión externa */
    handleCancel() {
      this.emitRejectSession(this.profile.userId)
      this.$emit('handleNoVisible')
      clearInterval(this.timer)
    },
    /** Cuando se Permite el acceso a una sesión externa */
    handleAllow() {
      this.emitAcceptSession(this.profile.userId)
      this.$emit('handleNoVisible')
      clearInterval(this.timer)
    },
    /** Cuando se acepta un modal simple, para ocultarlo */
    handleOk() {
      this.$emit('handleNoVisible')
    },
  },
}
</script>

<style lang="sass" scoped>
.color-blue
  color: #00173D
.gray-dark-300
  color: $gray_dark_300
.justify-center
  justify-content: center!important
.margin-bottom-20
  margin-bottom: 20px!important
.text-center
  text-align: center

.modal
  &__content
    padding: 100px 111px
    &--question, &--logout
      padding: 56px 111px
      @include xs
        padding: 36px 24px
      @include sm
        padding: 36px 32px
      @include md
        padding: 36px 60px
    &--success, &--pending, &--expired, &--fail
      @include xs
        padding: 72px 24px
      @include sm
        padding: 72px 32px
      @include md
        padding: 72px 60px
  &__icon
    font-size: 80px
    color: $yellow_800
    @include xs
      font-size: 54px
    @include sm
      font-size: 54px
    &--arrow
      font-size: 40px
      color: $gray_9
.modal-login
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .icons-contain
    margin-bottom: 40px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
  .contain-btns
    margin-top: 40px
    display: flex
    flex-direction: row
    .btn-cancel
      min-width: 93px
      margin-right: 12px
    .btn-ok
      min-width: 93px
</style>
