<template>
  <svg
    class="icon-chatbot"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 16 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.85938 5.40625C3.85938 5.71705 3.98284 6.01512 4.20261 6.23489C4.42238 6.45466 4.72045 6.57812 5.03125 6.57812C5.34205 6.57812 5.64012 6.45466 5.85989 6.23489C6.07966 6.01512 6.20312 5.71705 6.20312 5.40625C6.20312 5.09545 6.07966 4.79738 5.85989 4.57761C5.64012 4.35784 5.34205 4.23438 5.03125 4.23438C4.72045 4.23438 4.42238 4.35784 4.20261 4.57761C3.98284 4.79738 3.85938 5.09545 3.85938 5.40625ZM14.6406 0.25H1.35938C1.01367 0.25 0.734375 0.529297 0.734375 0.875V13.7656C0.734375 14.1113 1.01367 14.3906 1.35938 14.3906H14.6406C14.9863 14.3906 15.2656 14.1113 15.2656 13.7656V0.875C15.2656 0.529297 14.9863 0.25 14.6406 0.25ZM14.0156 13.1406H1.98438V1.5H14.0156V13.1406ZM9.79688 5.40625C9.79688 5.71705 9.92034 6.01512 10.1401 6.23489C10.3599 6.45466 10.6579 6.57812 10.9688 6.57812C11.2796 6.57812 11.5776 6.45466 11.7974 6.23489C12.0172 6.01512 12.1406 5.71705 12.1406 5.40625C12.1406 5.09545 12.0172 4.79738 11.7974 4.57761C11.5776 4.35784 11.2796 4.23438 10.9688 4.23438C10.6579 4.23438 10.3599 4.35784 10.1401 4.57761C9.92034 4.79738 9.79688 5.09545 9.79688 5.40625ZM14.6836 16.2656H1.31641C0.994141 16.2656 0.734375 16.5449 0.734375 16.8906V17.5938C0.734375 17.6797 0.798828 17.75 0.878906 17.75H15.1191C15.1992 17.75 15.2637 17.6797 15.2637 17.5938V16.8906C15.2656 16.5449 15.0059 16.2656 14.6836 16.2656ZM10.9688 8.92188H5.03125C4.94531 8.92188 4.875 8.99219 4.875 9.07812V10.25C4.875 10.3359 4.94531 10.4062 5.03125 10.4062H10.9688C11.0547 10.4062 11.125 10.3359 11.125 10.25V9.07812C11.125 8.99219 11.0547 8.92188 10.9688 8.92188Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChatbot',
  props: {
    fill: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
