<template>
  <a-popover :title="title">
    <template slot="content">
      <ul class="popover__list">
        <li
          v-for="(title, index) in listTitleWithLimit"
          :key="`title-${index}`"
        >
          {{ title }}
        </li>
      </ul>
      <div
        class="display-flex justify-content--end"
        v-if="listTitles.length > 10 && actionEnabled"
      >
        <span class="popover__redirect" @click="$emit('handleModal')">
          Ver mas canales</span
        >
      </div>
    </template>
    <channel-badge :channel="channel" :count="listTitles.length" />
  </a-popover>
</template>

<script>
import ChannelBadge from '@/app/shared/components/molecules/ChannelBadge'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'PopoverChannel',
  props: {
    channel: {
      type: String,
      required: true,
    },
    listTitles: {
      type: Array,
      required: true,
    },
    actionEnabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChannelBadge,
  },
  mixins: [transformMixin],
  computed: {
    /**
     * Lista de titulos con un limite
     * @return { Array}
     */
    listTitleWithLimit() {
      if (this.listTitles.length > 10 && this.actionEnabled) {
        return this.listTitles.slice(0, 10)
      }
      return this.listTitles
    },
    /**
     * Titulo del popover
     * @return {String}
     */
    title() {
      const channel = this.transformApiChannel(this.channel)
      return `Canales de ${channel}`
    },
  },
}
</script>

<style lang="sass" scoped>
.popover__list
  padding-left: 20px
  margin-bottom: 0px

.popover__redirect
  color: $blue_light_900
  font-weight: 500
  cursor: pointer
</style>
