<template>
  <div class="badge display-flex align--center">
    <div class="badge__count">
      <span class="regular-12">{{ count }}</span>
    </div>
    <social-icon class="badge__icon" :type="channel" :size="16" />
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'ChannelBadge',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    channel: {
      type: String,
      required: true,
    },
  },
  components: {
    SocialIcon,
  },
}
</script>

<style lang="sass" scoped>
.badge
  &__count
    background-color: $blue_1
    padding: 0px 4px
    color: $gray_8
  &__icon
    margin-left: 4px
</style>
