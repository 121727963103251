<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="handleCancel"
    :afterClose="handleCancel"
    :body-style="bodyStyle"
    centered
  >
    <custom-section-scroll
      :master-styles="{ height: '40em' }"
      :content-styles="{ padding: '24px' }"
    >
      <p>
        Para usarlas, desde el módulo de Workspace, selecciona el ícono de
        <a-icon type="thunderbolt" /> para añadir una respuesta rápida
      </p>
      <a-form layout="vertical" :form="form">
        <a-form-item
          label="Título"
          extra="Una palabra que mostrará rápidamente esta respuesta"
        >
          <a-input
            v-decorator="[
              'title',
              {
                rules: [
                  { required: true, message: 'Este campo es requerido' },
                  { max: 34, message: '35 caracteres máximo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            :maxLength="35"
            placeholder="Escribe aquí..."
          />
        </a-form-item>
        <!--MENSAJE-->
        <div class="ant-col ant-form-item-label">
          <label class="ant-form-item-required"
            >Mensaje
            <a-tooltip v-if="checkedMultimedia" placement="right">
              <span slot="title">
                Archivos aceptados: <br />

                <span
                  v-for="(multimedia, index) in MULTIMEDIA_SUPPORT"
                  :key="index"
                >
                  {{ multimedia.title_plural }}:
                  {{ multimedia.formats.titles.join(', ') }} <br />
                </span>
              </span>
              <a-icon type="info-circle" />
            </a-tooltip>
          </label>
        </div>
        <!--mensaje - imagen-->
        <a-form-item
          v-if="checkedMultimedia"
          extra="En algunos canales, el archivo se enviará en un mensaje separado."
        >
          <a-upload
            v-decorator="[
              'picture',
              {
                rules: [
                  {
                    required: isPictureRequired,
                    message: 'Este campo es requerido',
                  },
                ],
              },
            ]"
            name="picture"
            list-type="picture-card"
            class="drawer__uploader drawer-product__uploader"
            :show-upload-list="false"
            :customRequest="uploadMedia"
            :before-upload="beforeUpload"
          >
            <upload-content-preview
              :loading="loading"
              :file-url="fileAttached.url"
              :file-caption="fileAttached.caption"
              :file-type="multimediaSelected.type"
            />
          </a-upload>
        </a-form-item>
        <!--mensaje - texto-->
        <a-form-item style="margin-bottom: 6px">
          <counter-text :text="message" :maxLength="980">
            <a-textarea
              v-decorator="[
                'body',
                {
                  rules: [
                    {
                      required: !checkedMultimedia,
                      message: 'Este campo es requerido',
                    },
                  ],
                },
              ]"
              :auto-size="{ minRows: 4, maxRows: 4 }"
              placeholder="Escribe aquí..."
              @input="handleSetMessage($event.target.value)"
              class="position-relative"
              :maxLength="980"
            >
            </a-textarea>
          </counter-text>
          <emoji-picker
            :icon-styles="{
              fontSize: '16px',
              position: 'absolute',
              top: '8px',
              right: '8px',
            }"
            :show-tooltip="false"
            @onChangeEmoji="handleSetEmoji"
          />
        </a-form-item>

        <a-checkbox
          class="mrg-bottom-24"
          :checked="checkedMultimedia"
          @change="handleCheckMultimedia"
          >Incluir multimedia en el mensaje</a-checkbox
        >
        <a-form-item
          label="Asignar a colas (Opcional)"
          extra="¡RECUERDA! Esta respuesta rápida solo le aparecerá a los agentes asignados a la cola seleccionada"
        >
          <a-select
            mode="multiple"
            placeholder="Selecciona aquí..."
            v-decorator="[
              'linesIds',
              {
                rules: [
                  { required: false, message: 'Este campo es requerido' },
                ],
              },
            ]"
          >
            <a-select-option v-for="line in lines" :key="line._id">
              {{ line.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </custom-section-scroll>
    <basic-footer
      :ok-text="okText"
      :ok-loading="okButton.loading"
      :cancel-loading="cancelButton.loading"
      :ok-disabled="okButton.disabled"
      :cancel-disabled="cancelButton.disabled"
      cancelText="Cancelar"
      @onOk="handleOk"
      @onCancel="handleCancel"
    />
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CounterText from '@/app/shared/components/molecules/CounterText'
import EmojiPicker from '@/app/tickets/components/molecules/EmojiPicker'
import UploadContentPreview from './UploadContentPreview'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import { MULTIMEDIA_SUPPORT } from '@/app/tickets/utils/multimediaUtil'

export default {
  name: 'ModalFormFastAnswers',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    okText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  components: {
    BasicFooter,
    CounterText,
    EmojiPicker,
    UploadContentPreview,
    CustomSectionScroll,
  },
  data: () => ({
    MULTIMEDIA_SUPPORT,
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      loading: false,
      disabled: false,
    },
    message: '',
    checkedMultimedia: false,
    loading: false,
    multimediaSelected: {
      type: '',
    },
    fileAttached: {
      url: '',
      file: '',
      loading: false,
      type: '',
      urlTemp: '',
    },
    bodyStyle: {
      padding: '0px',
    },
    fastAnswerId: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_fast_answers' })
  },
  computed: {
    ...mapGetters(['lines']),

    /**
     * @return {Boolean} - si el archivo es requerido
     */
    isPictureRequired() {
      return !(this.checkedMultimedia && this.fileAttached.url)
    },
  },
  methods: {
    ...mapActions(['uploadFileMessage']),

    /**
     * Setea valores al formulario
     */
    setValues(dataSource) {
      if (!dataSource || !Object.keys(dataSource).length) return
      this.$nextTick(() => {
        this.form.setFieldsValue({
          title: dataSource.title,
          body: dataSource.body,
          linesIds: dataSource.lines.map((line) => line._id),
        })
        this.fastAnswerId = dataSource._id
        // si tiene media
        if (dataSource.media) {
          this.checkedMultimedia = true
          const media = dataSource.media
          this.multimediaSelected = MULTIMEDIA_SUPPORT[media.typeAttachment]
          this.fileAttached.url = media.url
          this.fileAttached.caption = media.caption
          this.fileAttached.type = media.type
          this.fileAttached.urlTemp = media.urlTemp
        }
      })
    },
    /**
     * Evento para cancelar
     */
    handleCancel() {
      this.message = ''
      this.fastAnswerId = null
      this.checkedMultimedia = false
      this.loading = false
      this.multimediaSelected = {
        type: '',
      }
      this.fileAttached = {
        url: '',
        file: '',
        loading: false,
        type: '',
      }
      this.form.resetFields()
      this.$emit('onClose')
    },
    /**
     * Evento ok para el modal
     */
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.fastAnswerId) values._id = this.fastAnswerId
          // si se requiere multimedia
          if (this.checkedMultimedia) {
            values.media = {
              typeAttachment: this.multimediaSelected.type,
              type: this.fileAttached.type,
              url: this.fileAttached.url,
              caption: this.fileAttached.caption,
              urlTemp: this.fileAttached.urlTemp,
            }
          }
          this.okButton.loading = true
          this.okButton.disabled = true
          this.cancelButton.disabled = true
          await this.onOk(values)
          this.okButton.loading = false
          this.okButton.disabled = false
          this.cancelButton.disabled = false
        }
      })
    },
    /**
     * Setear el valor al mensaje
     * @param {String}
     */
    handleSetMessage(value) {
      this.message = value
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      this.message = this.message + emoji
      this.form.setFieldsValue({
        body: this.message,
      })
    },
    handleCheckMultimedia(e) {
      this.checkedMultimedia = e.target.checked
    },
    /**
     * Obtiene solo la propiedad fileList
     * @param {Object} e - evento
     */
    getListFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    /**
     * Checkea el peso de los archivos antes de subirse
     * @param {Object} file - archivo que se subirá
     * @param {Number} file.size
     * @param {String} file.type
     */
    beforeUpload(file) {
      const multimedia = Object.values(MULTIMEDIA_SUPPORT).find((item) =>
        item.formats.accept.includes(file.type)
      )
      if (!multimedia) {
        this.$message.error('Archivo no soportado')
        this.multimediaSelected = {}
        return false
      }
      this.multimediaSelected = multimedia
      const isCorrectSize = file.size / 1024 / 1024 <= multimedia.max_size.size
      if (!isCorrectSize) {
        this.$message.error(
          `El tamaño máximo permitido es de ${multimedia.maxSize.title}`
        )
      }
      return multimedia && isCorrectSize
    },
    /**
     * Subida de archivo
     * @param {Object} file
     */
    async uploadMedia({ file, onSuccess, onError }) {
      try {
        this.loading = true
        const caption = file.name
        const response = await this.uploadFileMessage({
          file,
          caption,
          type: 'fastAnswer',
        })
        this.loading = false
        if (response.success) {
          this.fileAttached.file = file
          this.fileAttached.url = response.urlFile.gcp
          this.fileAttached.urlTemp = response.urlFile.temp
          this.fileAttached.caption = caption
          this.fileAttached.type = file.type
          onSuccess(response.urlFile)
          this.fileList = [file]
        }
      } catch (error) {
        console.error(error)
        this.loading = false
        onError(error)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.drawer__uploader
  width: 100%
  .ant-upload.ant-upload-select-picture-card
    width: 100%
.drawer__picture
  height: 104px
.drawer__content-picture
  height: 104px
  width: 100%
  position: relative
  &:hover
    .drawer__overlay
      display: flex
      opacity: 0.6
.drawer__overlay
  height: 104px
  width: 100%
  border-radius: 2px
  position: absolute
  display: none
  background-color: $gray_9
  opacity: 0.8
  color: $gray_1
  transition: opacity 1s
</style>
<style lang="sass">
.drawer-product__uploader
  width: 100%
  height: 124px
  >.ant-upload.ant-upload-select-picture-card
    width: 100%
    height: 100%
</style>
