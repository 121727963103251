var render = function render(){var _vm=this,_c=_vm._self._c;return _c('box-template',[_c('div',{staticClass:"template__container-steps"},[(_vm.steps)?_c('a-steps',{staticClass:"template__steps",attrs:{"current":_vm.currentStep,"size":"small","direction":"horizontal"}},_vm._l((_vm.steps),function(item){return _c('a-step',{key:item.step_title,staticClass:"template__step template-add-strategy__step",attrs:{"title":item.step_title}})}),1):_vm._e()],1),_c('custom-section-scroll',{attrs:{"master-styles":{
      height: `calc(100vh - (${_vm.heightFooter} + ${_vm.margin} + ${_vm.heightSteps}))`,
      width: '100%',
      padding: '8px 0px',
    },"content-styles":{
      margin: '2.4em auto 0px',
      maxWidth: '1000px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}},[_c('a-row',{attrs:{"gutter":[100],"type":"flex","justify":"center"}},[_c('a-col',{attrs:{"xs":10,"sm":8,"md":12,"lg":11,"xl":12,"xxl":12}},[_c(_vm.addStrategyForm,{ref:"add_strategy_form",tag:"component",attrs:{"current-step":_vm.currentStep,"title":_vm.currentTitle},on:{"onChange":_vm.handleChangeForm,"onLoading":_vm.handleLoadingForm,"onHasNewValues":(hasNew) => (_vm.isDisabledOk = hasNew),"onResetMedia":_vm.handleResetMedia}})],1),_c('a-col',{attrs:{"xs":4,"sm":6,"md":11,"lg":11,"xl":10,"xxl":10}},[_c('a-row',{staticClass:"full-height",attrs:{"type":"flex","justify":"center","align":"middle"}},[_c('mobile-wsp',{attrs:{"channel":_vm.channelPreview.type,"default-message":_vm.channelPreview.default_message,"message":_vm.mobilePreview.message,"media-type":_vm.mobilePreview.mediaType,"media-url":_vm.mobilePreview.mediaUrl,"file-name":_vm.mobilePreview.fileName,"buttons":_vm.mobilePreview.buttons}})],1)],1)],1)],1),_c('basic-footer',{attrs:{"okText":_vm.currentOkText,"cancelText":_vm.currentCancelText,"okLoading":_vm.isLoadingOk,"okDisabled":_vm.isDisabledOk,"cancelDisabled":_vm.isDisabledCancel},on:{"onOk":_vm.handleSubmit,"onCancel":_vm.handleCancel}},[_c('p',{staticClass:"mrg-bottom-0",attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{attrs:{"type":"info-circle"}}),_vm._v(" Las respuestas a estos envíos serán transferidas como tickets directamente a tus agentes, sin pasar por un chatbot. ")],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }