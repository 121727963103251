<template>
  <a-upload
    class="upload uploader-avatar"
    name="avatar"
    :multiple="false"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    :customRequest="selfUpload"
    accept="image/jpeg, image/png, image/jpg"
  >
    <div
      class="upload__content display-flex align--center justify-content--center"
      :style="styles"
    >
      <img
        v-if="imageUrl"
        :src="imageUrl"
        style="object-fit: cover; border-radius: 50px"
        alt="Avatar de perfil"
        class="upload__picture"
      />
      <template v-else>
        <a-icon
          v-if="iconDefault === 'user'"
          type="user"
          class="upload__icon"
          :style="{ color: styles.color }"
        />
        <icon-case
          v-else-if="iconDefault === 'company'"
          class="upload__icon"
          :style="{ color: styles.color }"
        />
      </template>
      <div class="overlay" :class="{ 'opacity-1': loading }">
        <a-icon
          v-if="!loading"
          type="edit"
          style="font-size: 20px; color: white"
        />
        <a-icon v-else type="loading" style="font-size: 20px; color: white" />
      </div>
    </div>
  </a-upload>
</template>

<script>
import { mapGetters } from 'vuex'
import IconCase from '@/app/shared/components/icons/IconCase'
import { COMPANY_COLORS } from '@/app/companies/utils/utilsCompanies'

export default {
  name: 'UploaderAvatar',
  props: {
    height: {
      type: String,
      default: '80px',
    },
    width: {
      type: String,
      default: '80px',
    },
    defaultAvatar: {
      type: String,
      required: false,
    },
    iconDefault: {
      type: String,
      required: false,
      default: 'user',
    },
    colorDefault: {
      type: String,
      required: false,
    },
  },
  components: {
    IconCase,
  },
  data: () => ({
    imageUrl: '', // url de imagen
    loading: false,
  }),
  watch: {
    defaultAvatar(newVal) {
      this.imageUrl = newVal
    },
  },
  computed: {
    ...mapGetters(['max_size_to_upload_avatar_company']),

    styles() {
      const styles = {}
      styles.height = this.height
      styles.width = this.width
      styles.color = this.color.color

      if (this.colorDefault) {
        styles.backgroundColor = this.color.bgColor
        styles.border = 'none'
      }

      return styles
    },
    /**
     * @returns {Object} color
     * @return {String} color.bgColor
     * @return {String} color.color
     */
    color() {
      const color = COMPANY_COLORS[this.colorDefault]
      if (!color) return { bgColor: this.colorDefault, color: '#FFFFFF' }
      return color
    },
  },
  methods: {
    /**
     * Checkea el peso de los archivos antes de subirse
     * @param {Object} file - archivo que se subirá
     * @param {Number} file.size
     * @param {String} file.type
     */
    beforeUpload(file) {
      const typeFiles = ['image/jpeg', 'image/png', 'image/jpg']
      const isJpgOrPng = typeFiles.includes(file.type)
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png o jpg')
      }
      const isLt3M =
        file.size / 1024 / 1024 <= this.max_size_to_upload_avatar_company
      if (!isLt3M) {
        this.$message.error(
          `El tamaño máximo permitido es de ${this.max_size_to_upload_avatar_company}MB`
        )
      }
      return isJpgOrPng && isLt3M
    },
    /**
     * Subida local
     * @param {Object} args
     * @param {String} args.action
     * @param {String} args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    selfUpload({ file }) {
      this.loading = true
      new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.$emit('onChange', {
            file: this.fileImage,
            imageUrl: fileReader.result,
          })
          this.imageUrl = fileReader.result
          resolve(fileReader.result)
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.upload__content
  position: relative
  cursor: pointer
  background-color: $gray_3
  border-radius: 50px
  border: 1px solid $gray_4
  .overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    opacity: 0
    transition: .3s ease
    background: rgba(0, 0, 0, 0.4)
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
  &:hover
    .overlay
      opacity: 1
.upload__picture
  object-fit: cover
  border-radius: 50px
  height: 100%
  width: 100%
.upload__icon
  font-size: 24px
</style>
