<template>
  <a-row class="input-comment flex-no-wrap" type="flex" align="middle">
    <div
      ref="input_comment_editable"
      id="input_comment_editable"
      class="input-comment__content scrollbar_basic"
      :contenteditable="true"
      data-placeholder="Escribir comentario..."
      @input="onInput"
      @paste="onPastePlainText"
    ></div>
    <a-button
      class="mrg-left-12"
      size="small"
      type="primary"
      @click="handleSubmit"
      :disabled="isEmpty || ok.disabled"
      :loading="ok.loading"
      ghost
      >Agregar</a-button
    >
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InputComment',
  data: () => ({
    comment: '',
    ok: {
      disabled: false,
      loading: false,
    },
  }),
  computed: {
    ...mapGetters(['ticketSelected']),

    /**
     * @return {Boolean} si el texto esta vacio o no
     */
    isEmpty() {
      return !this.comment.length
    },
  },
  methods: {
    ...mapActions(['addComment']),

    /** Al escribir */
    onInput(e) {
      this.comment = e.target.innerText
      if (!e.target.innerText?.trim().length) this.comment = '' // verificar que el contenido tenga palabras
    },
    /**
     * Submit para añadir comentario
     */
    async handleSubmit() {
      this.ok.loading = true
      this.ok.disabled = true
      const response = await this.addComment({
        ticketId: this.ticketSelected._id,
        text: this.comment,
      })
      this.ok.loading = false
      this.ok.disabled = false
      if (response.success) {
        this.$message.success('Se agregó un comentario')
        this.cleanInput()
      } else this.$message.error('Ocurrió un error 😥')
    },
    /** Limpia el input */
    cleanInput() {
      if (this.$refs.input_comment_editable) {
        this.comment = ''
        this.$refs.input_comment_editable.innerHTML = ''
        this.$refs.input_comment_editable.focus()
      }
    },
    /** Convierte los elementos que se pegan por defecto con hmtl a texto plano */
    onPastePlainText(evt) {
      evt.preventDefault()
      let paste = (evt.clipboardData || window.clipboardData).getData(
        'text/plain'
      )
      window.document.execCommand('insertText', false, paste)
      this.comment = document.getElementById('input_comment_editable').innerText
    },
  },
}
</script>

<style lang="sass" scoped>
$divider_color: #EBEBEB
.input-comment
  padding: 10px 12px
  border-top: 1px solid $divider_color
  &__content
    flex-grow: 1
    width: inherit
    height: fit-content
    min-height: 20px
    position: relative
    padding: 0px 8px
    margin: 8px 0px 8px 0px
    text-align: left
    max-height: 82px
    overflow-y: auto
    outline: none
    word-break: break-word
    cursor: text
    &:empty:before
      content: attr(data-placeholder)
      color: $gray_dark_400
      // position: absolute
      bottom: 0px
      left: 12px
</style>
