import lineModule from './lineModule'
import lineSettingsModule from './lineSettingsModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Colas */
  resetMoLine(context) {
    context.commit('RESET_LINE_MODULE')
  },
}

const modules = {
  lineModule,
  lineSettingsModule,
}

export default {
  actions,
  modules,
}
