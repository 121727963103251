var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-edit-template"},[_c('div',{staticClass:"form-edit-template-content"},[_c('div',{staticClass:"master-container",attrs:{"id":"form-edit-template-master-container"}},[_c('div',{staticClass:"form-edit-container scrollbar_basic",attrs:{"id":"form-edit-template-container"},on:{"scroll":function($event){return _vm.setShadows(
            'form-edit-template-master-container',
            'form-edit-template-container',
            'form-edit-template-content'
          )}}},[_c('div',{staticClass:"form-edit-content",attrs:{"id":"form-edit-template-content"}},[_c('a-form',{staticStyle:{"width":"396px"},attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Nombre de plantilla"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name']),expression:"['name']"}],attrs:{"placeholder":"Escribir aquí...","disabled":""}})],1),_c('a-form-item',{attrs:{"label":"Nombre de visualización"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'friendlyName',
                  {
                    rules: [
                      { required: true, message: 'Completa este campo' },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'friendlyName',\n                  {\n                    rules: [\n                      { required: true, message: 'Completa este campo' },\n                      {\n                        whitespace: true,\n                        message: 'No se admiten espacios en blanco.',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Escribir aquí..."}})],1),_c('a-form-item',{attrs:{"label":"Selecciona la categoría"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'category',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor completa este campo',
                      },
                    ],
                  },
                ]),expression:"[\n                  'category',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor completa este campo',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selecciona aquí","disabled":""}},_vm._l((_vm.CATEGORIES_TEMPLATES),function(category){return _c('a-select-option',{key:category.value,attrs:{"value":category.value}},[_vm._v(" "+_vm._s(category.title)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Selecciona el lenguaje"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'lang',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor completa este campo',
                      },
                    ],
                  },
                ]),expression:"[\n                  'lang',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor completa este campo',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selecciona aquí","disabled":""}},[_c('a-select-option',{attrs:{"value":"es"}},[_vm._v(" Español ")]),_c('a-select-option',{attrs:{"value":"en"}},[_vm._v(" Inglés ")])],1)],1),_c('a-form-item',{attrs:{"label":"Selecciona el tipo de plantilla"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'typeAttachment',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor completa este campo',
                      },
                    ],
                  },
                ]),expression:"[\n                  'typeAttachment',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor completa este campo',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selecciona aquí","disabled":""}},_vm._l((_vm.TYPE_TEMPLATES_FOR_LIST),function(typeTemplateForList){return _c('a-select-option',{key:typeTemplateForList.value},[_vm._v(" "+_vm._s(typeTemplateForList.title)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Contenido de plantilla"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content']),expression:"['content']"}],attrs:{"placeholder":"Escribir aquí...","type":"textarea","auto-size":{ minRows: 3, maxRows: 4 },"disabled":""}})],1),_vm._l((_vm.parameters),function(param,index){return [_c('a-form-item',{key:param.idParameter,attrs:{"label":`Nombre de parámetro ${index + 1}`}},[_c('a-input',{attrs:{"placeholder":"Escribir aquí...","disabled":""},model:{value:(param.value),callback:function ($$v) {_vm.$set(param, "value", $$v)},expression:"param.value"}})],1)]})],2),_c('mobile-wsp',{staticClass:"form__mobile",attrs:{"type":"small","message":_vm.template.body,"media-type":_vm.mediaTypeForPreview,"buttons":_vm.template.buttons || [],"default-message":"--"}})],1)])])]),_c('div',{staticClass:"footer"},[_c('a-button',{staticStyle:{"min-width":"90px"},on:{"click":_vm.handleCloseModal}},[_vm._v("Cancelar")]),_c('a-button',{staticStyle:{"margin-left":"8px","min-width":"90px"},attrs:{"type":"primary","loading":_vm.loadBtnEdit},on:{"click":_vm.handleEditTemplate}},[_vm._v("Editar")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }