import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_PROFILE_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },
  /**
   * Setea el objeto del perfil logueado
   * @param {*} state
   * @param {Object} profile Objeto del perfil del usuario
   */
  SET_PROFILE(state, profile) {
    state.profile = profile
  },
  /**
   * Setea el estado del usuario
   * @param {*} state
   * @param {String} status Estado del usuario
   */
  SET_STATUS_USER(state, status) {
    if (!status) return
    if (state.profile) {
      state.profile.status = status
    } else {
      setTimeout(() => {
        state.profile
      }, 1000)
    }
  },
  /**
   * Resetea las colas que esta asignado el usuario logueado
   * @param {*} state
   * @param {String[]} linesId Id d las colas que pertenece el usuario logueado
   */
  RESET_LINES_IN_PROFILE(state, linesId) {
    console.log('RESET_LINES_IN_PROFILE')
    state.profile.company.linesId = linesId
  },
  /**
   * Setea el estado del usuario
   * @param {*} state
   * @param {String} status Estado del usuario
   */
  SET_LINE_IN_PROFILE(state, { lineId, asigned }) {
    console.log('[Mutations.SET_LINE_IN_PROFILE]', { lineId, asigned })

    if (!lineId) return
    if (asigned === true) {
      if (!state.profile.company.linesId.includes(lineId))
        state.profile.company.linesId.push(lineId)
    } else {
      state.profile.company.linesId = state.profile.company.linesId.filter(
        (lId) => lId !== lineId
      )
    }
  },
  /**
   * Setea el objeto del perfil logueado
   * @param {*} state
   * @param {Object} profile Objeto del perfil del usuario
   */
  SET_PROFILE_REMOTE(state, profileRemote) {
    state.profileRemote = profileRemote
  },
  /**
   * @param {*} state
   * @param {Object}  payload
   * @param {Boolean} payload.statusModal
   * @param {String}  payload.typeUser
   */
  SET_MODAL_REMOTE_LOGOUT(state, payload) {
    state.modalRemoteLogout = payload
  },
  /**
   * @param {*} state
   * @param {Object}  payload
   * @param {Boolean} payload.statusModal
   * @param {String}  payload.typeUser
   */
  SET_MODAL_SESSION_EXPIRED(state, payload) {
    state.modalSessionExpired = payload
  },
  /**
   * Setea si hay o no conexion a internet
   * @param {*} state
   * @param {Boolean} internet
   */
  SET_INTERNET_STATE(state, internet) {
    state.internet = internet
  },
  /**
   * Setea todos las novedades
   * @param {*}      state
   * @param {Array}  news
   */
  SET_NEWS(state, news) {
    state.news = news
  },
  /**
   * Añade una nueva noticia
   * @param {*}      state
   * @param {Object}  news
   */
  ADD_NEWS(state, news) {
    state.news.push(news)
  },

  /**
   * Quita una novedad del store
   * @param {*}      state
   * @param {String} newsId
   */
  REMOVE_NEWS_EL(state, newsId) {
    state.news = state.news.filter((el) => el._id !== newsId)
  },
  /**Setea una tarjeta a las cards de profile */
  ADD_CARD_PROFILE(state, card) {
    // if (state.profile.cards.length === 0) {
    //   card.forSuscription = true
    // }
    state.profile.cards.push(card)
  },
  /**Setea una tarjeta a las cards de profile */
  UPDATE_CARD_PROFILE(state, { idCard, newCard }) {
    let cardFind = state.profile.cards.find((card) => card.idCard === idCard)
    Object.assign(cardFind, newCard)
  },
  /**
   * Remover una card de profile
   * @param {*} state
   * @param {*} idCard - id de la card
   */
  REMOVE_CARD_PROFILE(state, idCard) {
    console.log('[Mutations.REMOVE_CARD_PROFILE]', idCard)

    state.profile.cards = state.profile.cards.filter(
      (card) => card.idCard !== idCard
    )
  },
  /**
   * Actualizar el metodo de pago
   * @param {*} state
   * @param {*} idCard - id de la tarjeta
   * @param {Boolean} forSuscription
   */
  UPDATE_SUSCRIPTION_CARD(state, { idCard, forSuscription }) {
    let cardFind = state.profile.cards.find((card) => card.idCard === idCard)
    if (cardFind !== undefined) cardFind.forSuscription = forSuscription
  },
  /**
   * Setea un numero a las notificaciones del perfil, usado para limpiar las notificaciones volviendolo a 0
   * @param {Number} count
   */
  SET_PROFILE_NOTIFICATIONS(state, count) {
    state.profile.notifications = count
  },
  /**Setea las rutas */
  UPDATE_PROFILE_ROUTE(state, { id, completed }) {
    console.log(id, completed)
    state.profile.routes[id].completed = completed
  },
  /**Hacer visible un tutorial */
  SET_TUTO_MODAL(state, tuto) {
    state.tutoModal = tuto
  },
  /**
   * Hacer visible el modal de integración de un canal
   * @param {Object} state
   * @param {Object} args
   * @param {Boolean} args.visible
   * @param {String} args.channel
   */
  SET_MODAL_INTEGRATION_CHANNELS(state, { visible, channel }) {
    state.modal_integrate_channel.visible = visible
    state.modal_integrate_channel.channel = channel
  },
  SET_MODAL_CREATE_CHATBOT(state, visible) {
    state.modalCreateChatbot.visible = visible
  },
  /**
   * Hacer visible el modal de estado de conexion
   * @param {*}       state
   * @param {Object}  args
   * @param {Boolean} args.visible
   * @param {String}  args.type
   */
  SET_MODAL_STATE(state, { visible, type }) {
    state.modal_state.visible = visible
    state.modal_state.type = type
  },
  SET_RELOAD_NOTIFICATION(state, { visible, title, description }) {
    state.reloadNotification = {
      visible: visible,
      title: title,
      description: description,
    }
  },
  /**
   * Setea datos para una notificacion con tipo
   * @param {*} state
   * @param {Object} args
   * @param {Boolean} args.visible
   * @param {String} args.type [success, error, warning, info]
   * @param {String} args.title
   * @param {String} args.description
   * @param {Boolean} args.showButton
   * @param {String} args.okText
   * @param {Object} args.route
   * @param {Boolean} args.reload
   */
  SET_NOTIFICATION_WITH_TYPE(
    state,
    {
      visible,
      type,
      title,
      description,
      showButton = false,
      okText,
      route,
      reload = false,
    }
  ) {
    state.notification_with_type = {
      visible,
      type,
      title,
      description,
      showButton,
      okText,
      route,
      reload,
    }
  },
  /**
   * Setea un numero a las notificaciones del perfil, usado para limpiar las notificaciones volviendolo a 0
   * @param {Number} count
   */
  REMOVE_PROFILE_OWNER(state) {
    state.profile.owner = null
  },
}

export default mutations
