const mutations = {
  /**
   * Setea nuevos valores a todas la campañas
   * @param {*} state
   * @param {Object[]} strategies
   */
  SET_COMPANY_STRATEGIES(state, strategies) {
    state.company_strategies = strategies
  },
  /**
   * Agrega una nueva campaña
   * @param {*} state
   * @param {Object} strategy
   */
  ADD_COMPANY_STRATEGY(state, strategy) {
    state.company_strategies.unshift(strategy)
  },
  /**
   * Remueve la campaña
   * @param {*} state
   * @param {String} strategyId
   */
  REMOVE_COMPANY_STRATEGY(state, strategyId) {
    state.company_strategies = state.company_strategies.filter(
      (strategy) => strategy._id !== strategyId
    )
  },
  /**
   * Actualiza el estado de una campaña
   * @param {*} state
   * @param {String} strategyId
   * @param {String} status - upload, uploaded, play, pause, stop, finished
   * @param {Object} stats_import
   * @param {Object} stats_send
   * @param {Object} stats_wallet
   * @returns
   */
  UPDATE_STRATEGY(
    state,
    { strategyId, status, stats_import, stats_send, stats_wallet }
  ) {
    let index = state.company_strategies.findIndex(
      (strategy) => strategy._id === strategyId
    )
    if (index === -1) return
    const properties = { status, stats_import, stats_send, stats_wallet }
    for (let property in properties) {
      if (properties[property])
        state.company_strategies[index][property] = properties[property]
    }
  },
  /**
   * Setea la paginación de las campañas
   * @param {*} state
   * @param {Object} paginate
   * @param {Number} paginate.lastPage
   * @param {Number} paginate.page
   * @param {Number} paginate.perPage
   * @param {Number} paginate.total
   */
  SET_PAGINATE_COMPANY_STRATEGIES(state, paginate) {
    state.paginate_company_strategies = paginate
  },
  /**
   * Setea plantillas simples
   * @param {*} state
   * @param {Object[]} templates
   * @param {String} templates[]._id
   * @param {String} templates[].name
   * @param {String} templates[].friendlyName
   */
  SET_SIMPLE_TEMPLATES(state, templates) {
    state.simple_templates = templates
  },
  /**
   * Setea la campaña seleccionada
   * @param {*} state
   * @param {String} selectedStrategy
   */
  SET_SELECTED_STRATEGY(state, selectedStrategy) {
    state.selected_strategy = selectedStrategy
  },
}
export default mutations
