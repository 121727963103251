<template>
  <div class="collapse">
    <div class="collapse__header">
      <div class="collapse__title">
        <img src="../../assets/notepad.svg" class="collapse__picture" />
        <h5 class="heading-h5 mrg-bottom-0">Otros tutoriales</h5>
      </div>
      <a-icon
        type="up"
        :rotate="collapse ? 0 : 180"
        style="font-size: 24px"
        class="collapse__icon"
        @click="handleChangeCollapse(!collapse)"
      />
    </div>
    <div v-if="collapse" class="collapse__body">
      <a-row :gutter="[0, 8]">
        <a-col :span="24" v-for="(tuto, index) in dataSource" :key="index">
          <card-tuto
            :title="tuto.title"
            :description="tuto.abstract.description"
            :src="tuto.assets.urlDoc"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import CardTuto from '@/app/profile/components/molecules/CardTuto'

export default {
  name: 'TutosCollapse',
  components: {
    CardTuto,
  },
  props: {
    dataSource: { type: Array, default: null },
  },
  data: () => ({
    collapse: true,
  }),
  methods: {
    handleChangeCollapse(collapse) {
      this.collapse = collapse
    },
  },
}
</script>

<style lang="sass" scoped>
.collapse
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 12px 20px
    margin-bottom: 20px
    border-bottom: 1px solid $gray_7

  &__title
    display: flex
    align-items: center
  &__picture
    margin-right: 4px
  &__icon
    color: $gray_6
    &:hover
      cursor: pointer
</style>
