import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_LINE_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Setea la Cola seleccionada
   * @param {*} state
   * @param {Object} line Objeto de la Cola
   */
  SET_LINE(state, line) {
    state.line = line
  },
  /**
   * Setea las colas a la lista
   * @param {*} state
   * @param {Array} lines Arreglo de Colas
   */
  SET_ASSIGNED_LINES(state, lines) {
    state.lines = lines
  },
  /**
   * Setea las colas a la lista
   * @param {*} state
   * @param {Array} companyLines Arreglo de Colas
   */
  SET_COMPANY_LINES(state, companyLines) {
    state.companyLines = companyLines
  },
  /**
   * Agrega una Cola a la lista de Colas
   * @param {*} state
   * @param {Object} line Objeto de una Cola
   */
  ADD_LINE(state, line) {
    state.lines.push({ ...line })
  },
  /**
   * Agrega una Cola a la lista de Colas de la empresa
   * @param {*} state
   * @param {Object} companyLine Objeto de una Cola
   */
  ADD_COMPANY_LINE(state, companyLine) {
    state.companyLines.push({ ...companyLine })
  },
  /**
   * Quita una Cola de la lista
   * @param {*} state
   * @param {String} idLine Id de la Cola que se va a quitar
   */
  QUIT_LINE(state, idLine) {
    for (let i = 0; i < state.lines.length; i++) {
      if (state.lines[i]._id === idLine) {
        state.lines.splice(i, 1)
      }
    }
  },
  /**
   * Actualiza el nombre de una Cola
   * @param {*} state
   * @param {Object} lineEdited Objeto de la Cola editada
   * @param {String} lineEdited._id Id de la Cola editada
   * @param {String} lineEdited.name Nuevo nombre de la Cola
   */
  SET_LINE_NAME(state, lineEdited) {
    const line = state.lines.find((line) => line._id === lineEdited._id)
    line.name = lineEdited.name
  },
  /**
   *
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idLine Id de la Cola
   * @param {Number} args.sum Suma una cantidad de Supervisores asignados a la Cola
   */
  SUM_ASIGNED_SUPERVISORS_IN_LINE(state, { idLine, sum }) {
    const line = state.lines.find((line) => line._id === idLine)
    line.supervisorsAsigned = line.supervisorsAsigned + sum
  },
  /**
   * Actualiza el valor delñ mensaje automatico
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idLine
   * @param {Object} args.welcome
   * @param {Boolean} args.welcome.auto
   * @param {String} args.welcome.message
   */
  SET_WELCOME_IN_LINE(state, { idLine, welcome }) {
    const line = state.lines.find((line) => line._id === idLine)
    if (!line || !line.default) return // La cola debe exisitir y debe ser solo para la cola por defecto
    line.settings.automatic.welcome = welcome
  },
  /**
   * Setear store de agentes para listar al editar una cola
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACCOUNT_AGENTS(state, payload) {
    state.accounts.agents = payload
  },
  /**
   * Setear store de supervisores para listar al editar una cola
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACCOUNT_SUPERVISORS(state, payload) {
    state.accounts.supervisors = payload
  },

  SET_ACTION_DESASIGNED(state, actionDesasigned) {
    state.action_desasigned = actionDesasigned
  },
  SET_ACTION_ASIGNED(state, actionAsigned) {
    state.action_asigned = actionAsigned
  },
  SET_ACTION_CHANNEL_REMOVED(state, actionChannelRemoved) {
    state.action_channel_removed = actionChannelRemoved
  },
  SET_ACTION_CHAT_PREFERENCES_UPDATED(state, actionChatPreferencesUpdted) {
    state.action_chat_preferences_updated = actionChatPreferencesUpdted
  },
  /**
   * Actualiza el valor de la variable para que se ejecute el observador que llama al método de llenar la tabla de Colas
   * @param {*}       state
   * @param {Number} reloadTableLines
   */
  SET_RELOAD_TABLE_LINES(state, reloadTableLines) {
    state.reloadTableLines = reloadTableLines
  },
  /**
   * Actualiza el valor de la variable para que se ejecute el observador que llama al método de llenar la tabla de Cuentas
   * @param {*}       state
   * @param {Number} reloadTableAccounts
   */
  SET_RELOAD_TABLE_ACCOUNTS(state, reloadTableAccounts) {
    state.reloadTableAccounts = reloadTableAccounts
  },
  /**
   * Actualiza los canales de una cola
   * @param {*}      state
   * @param {Object} args
   * @param {String} args.idLine
   * @param {Array} args.channels
   */
  SET_CHANNELS_IN_LINE(state, { idLine, channels }) {
    console.log('SET_CHANNELS_IN_LINE', idLine, channels)
    const line = state.lines.find((line) => line._id === idLine)
    if (!line || line.default) return // La cola debe exisitir y que no sea la cola por defecto
    line.channelsAsigned = channels
  },
}

export default mutations
