const initialState = () => ({
  paginate_downloads: {
    page: 1,
    total: 0,
  },
  downloads: [],
  limit_download: {
    total: 8,
    spent: 0,
  },
})

export default initialState
