import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /** Lista Los Agentas de la Empresa */
  async listAgents(context) {
    try {
      const result = await axios.get(`${vari.UHR}/agent/list`)
      let agents = result.data
      /** Agrega valores para visualizar en la tabla de supervision */
      agents = agents.map((agent) => {
        agent.istyping = false
        agent.unreaded = 0
        return agent
      })
      context.commit('SET_AGENTS', agents)
    } catch (error) {
      console.error('[agentModule][listAgents]', error)
    }
  },
  /** Lista agente que se encuentran por lo menos en una Cola */
  async listAgentsAsigned(context) {
    try {
      const response = await axios.get(`${vari.UHR}/agent/line/agents-asigned`)

      const agentsAsigned = response.data
      context.commit('SET_AGENTS', agentsAsigned)
    } catch (error) {
      console.error('[agentModule][listAgentsAsigned]', error)
    }
  },
  /**
   * Obtiene hasta 100 agentes activos de la lista
   * @param {Boolean} excludeChatbotUser excluye a los usuarios de tipo bot
   */
  async getSimpleAgents(context, { excludeChatbotUser = false } = {}) {
    try {
      const response = await axios.get(
        `${vari.UHR}/admin/tickets/agents-filters?excludeChatbotUser=${excludeChatbotUser}`
      )

      const simpleAgents = response.data.result
      context.commit('SET_SIMPLE_AGENTS', simpleAgents)
    } catch (error) {
      console.error('[agentModule][getSimpleAgents]', error)
    }
  },
}

export default actions
