const MENUS = {
  'menu-user': { order: 1, title: 'Mi perfil', key: 'menu-user' },
  'menu-company': { order: 2, title: 'Mi empresa', key: 'menu-company' },
  'menu-permissions': {
    order: 3,
    title: 'Mis permisos',
    key: 'menu-permissions',
  },
  'menu-guide': { order: 4, title: 'Guía de usuario', key: 'menu-guide' },
}

export { MENUS }
