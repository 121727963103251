<template>
  <a-modal :visible="visible" :title="title" @cancel="handleCancel" centered>
    <p>
      Después de bloquear un número telefónico, tu empresa no volverá a recibir
      mensajes de este. No se notificará del bloqueo al propietario del
      teléfono.
    </p>
    <a-form layout="vertical" :form="form">
      {{ validateNewValuesInDom() }}
      <!--ID DE CANAL-->
      <a-form-item label="ID de canal">
        <p class="regular-14 mrg-bottom-8">
          Selecciona los canales de WhatsApp por los que no quieres recibir
          mensajes del número a bloquear.
        </p>
        <a-select
          placeholder="Selecciona los ID de WhatsApp"
          v-decorator="[
            'channelsId',
            {
              rules: [{ required: true, message: 'Este campo es requerido' }],
            },
          ]"
          mode="multiple"
          :filter-option="true"
          option-filter-prop="title"
          show-search
          notFoundContent="No se encontraron ID de canales de WhatsApp"
        >
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex" align="middle">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>
                {{
                  simpleChannel.alias ? `(${simpleChannel.alias})` : ''
                }}</span
              >
            </a-row>
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--NÚMERO PARA BLOQUEAR-->
      <a-form-item label="Número">
        <p class="regular-14 mrg-bottom-8">
          Recuerda que el número debe estar registrado como contacto en
          {{ app }} para ser bloqueado.
        </p>
        <a-row :gutter="8" type="flex">
          <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
          <a-col :span="16" :order="2">
            <a-input
              v-decorator="[
                'number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                    {
                      pattern: /^([0-9])*$/g,
                      message: 'Solo se admiten números.',
                    },
                  ],
                },
              ]"
              :maxLength="20"
              placeholder="987654321"
              :disabled="disabledSomeFields"
            />
          </a-col>
          <a-col :span="8" :order="1">
            <a-select
              show-search
              v-decorator="[
                'countryId',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Por favor rellena el campo',
                    },
                  ],
                },
              ]"
              option-filter-prop="children"
              placeholder="Código"
              :disabled="disabledSomeFields"
            >
              <a-select-option
                v-for="country in countriesNotRepeatByName"
                :key="country._id"
                :title="country.nameSpanish"
              >
                {{ country.dialCode }}
                {{ country.nameSpanish }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-item>
      <!--RAZON DE BLOQUEO-->
      <a-form-item label="Motivo del bloqueo">
        <counter-text :text="message" :maxLength="500">
          <a-textarea
            v-decorator="[
              'reason',
              {
                rules: [{ required: true, message: 'Este campo es requerido' }],
              },
            ]"
            :auto-size="{ minRows: 4, maxRows: 4 }"
            placeholder="Escribe aquí..."
            @input="handleSetMessage($event.target.value)"
            class="position-relative"
            :maxLength="500"
          >
          </a-textarea>
        </counter-text>
      </a-form-item>
    </a-form>
    <basic-footer
      :ok-text="okText"
      :ok-loading="okButton.loading"
      :cancel-loading="cancelButton.loading"
      :ok-disabled="okButton.loading || okButton.disabled"
      :cancel-disabled="cancelButton.disabled"
      cancelText="Cancelar"
      @onOk="handleOk"
      @onCancel="handleCancel"
    />
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CounterText from '@/app/shared/components/molecules/CounterText'
import supportMixin from '@/app/shared/mixins/support'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import blacklistMixin from '@/app/settings/components/organisms/blacklistMenu/mixins/blacklistMixin'
import countriesMixin from '@/app/shared/mixins/countries'

export default {
  name: 'ModalFormBlacklist',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
      /**
       * Estructura
       * @param {String} dataSource.apiClientId
       * @param {Object[]} dataSource.channelsId
       * @param {String} dataSource._id
       * @param {Object} dataSource.reason
       * @param {Object} dataSource.countryId
       */
    },
    okText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
      enum: ['add', 'update'],
    },
  },
  components: { BasicFooter, CounterText, SocialIcon },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      loading: false,
      disabled: false,
    },
    message: '',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_blacklist' })
  },
  mixins: [supportMixin, blacklistMixin, countriesMixin],
  watch: {
    /**
     * Verifica que camnbia la data source
     */
    dataSource() {
      this.$nextTick(() => {
        if (this.action === 'update') this.setValues()
      })
    },
  },
  computed: {
    ...mapGetters(['lines', 'countriesNotRepeatByName']),
    /**
     * Canales de whatsapp y de gupshup
     * @return {Object} simpleChannelsFiltered
     * @return {String} simpleChannelsFiltered._id
     * @return {String} simpleChannelsFiltered.title
     */
    simpleChannelsFiltered() {
      return this.filterSimpleChannelWhatsAppByType({
        type: 'gupshup',
      })
    },
    /**
     * Deshabilita algunos campos cuando la accion es editar
     * @return {Boolean}
     */
    disabledSomeFields() {
      return this.action && this.action === 'update'
    },
  },
  methods: {
    ...mapActions([
      'addNumberToBlacklist',
      'updateNumberToBlacklist',
      'unBlockNumberInBlacklist',
    ]),

    /**
     * Setea valores al formulario
     */
    setValues() {
      if (!this.dataSource || !Object.keys(this.dataSource).length) return
      const country =
        this.dataSource.countryId &&
        this.searchCountry('_id', this.dataSource.countryId)
      this.form.setFieldsValue({
        channelsId: this.dataSource.channelCompanies?.map(
          (channel) => channel._id
        ),
        number: country
          ? this.dataSource.apiClientId.replace(country.dialCode, '')
          : this.dataSource.apiClientId,
        countryId: country ? country._id : undefined,
        reason: this.dataSource.reason,
      })
      this.message = this.dataSource.reason
    },
    /**
     * Evento para cancelar
     */
    handleCancel() {
      this.$emit('onClose')
      this.form.resetFields()
      this.okButton = {
        loading: false,
        disabled: false,
      }
      this.cancelButton = {
        loading: false,
        disabled: false,
      }
      this.message = ''
    },
    /**
     * Evento ok para el modal
     */
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const country = this.countriesNotRepeatByName.find(
            (country) => country._id === values.countryId
          )
          const newValues = {
            channel: 'whatsapp',
            apiClientId: `${country.dialCode}${values.number}`,
            blacklistId: this.dataSource?._id,
            ...values,
          }
          this.handleShowConfirmation(newValues)
        }
      })
    },
    /**
     * Muestra la confirmación de bloqueo
     * @param {Object} values
     * @param {String} values.blacklistId
     * @param {String} values.apiClientId
     * @param {String} values.channelsId
     * @param {String} values.reason
     * @param {String} values.channel
     */
    async handleShowConfirmation(values) {
      this.okButton.loading = true
      this.okButton.disabled = true
      this.cancelButton.disabled = true

      // AGREGA
      if (this.action === 'add') {
        const okCallback = async () => await this.onAddNumberToBlacklist(values)
        await this.showBlacklistConfirmation(values, 'block', okCallback)
      }
      // EDITA
      else if (this.action === 'update') {
        // si no tiene canales, desbloqueara
        if (values.channelsId && values.channelsId.length === 0) {
          const okCallback = async () =>
            await this.onUnblockNumberToBlacklist(values)
          await this.showBlacklistConfirmation(values, 'unblock', okCallback)
        }
        // si tiene canales, editará
        else await this.onUpdateNumberToBlacklist(values)
      }
      this.okButton.loading = false
      this.okButton.disabled = false
      this.cancelButton.disabled = false
    },
    /**
     * Ejecuta el agregar numero
     * @param {Object} values
     */
    async onAddNumberToBlacklist(values) {
      const response = await this.addNumberToBlacklist(values)
      if (response.success) {
        this.handleCancel()
        this.$message.success(response.details || 'Se agregó exitosamente')
      } else this.$message.error(response.details || 'Ocurrió un error')
    },
    /**
     * Ejecuta el actualizar numero
     * @param {Object} values
     */
    async onUpdateNumberToBlacklist(values) {
      const response = await this.updateNumberToBlacklist(values)
      if (response.success) {
        this.handleCancel()
        this.$message.success(response.details || 'Se actualizó exitosamente')
      } else this.$message.error(response.details || 'Ocurrió un error')
    },
    /**
     * Ejecuta el desbloquear numero
     * @param {Object} values
     */
    async onUnblockNumberToBlacklist(values) {
      const blacklistId = values._id
      const response = await this.unBlockNumberInBlacklist(blacklistId)
      if (response.success) {
        this.handleCancel()
        this.$message.success(response.details || 'Se desbloqueo exitosamente')
      } else this.$message.error(response.details || 'Ocurrió un error')
    },
    /**
     * Setear el valor al mensaje
     * @param {String}
     */
    handleSetMessage(value) {
      this.message = value
    },
    /**
     * Valida los nuevos valores del formulario en el DOM
     */
    validateNewValuesInDom() {
      this.$nextTick(() => {
        this.hasNewValues({
          valuesForm: this.form.getFieldsValue(),
        })
      })
    },
    /**
     * Verifica si hay un nuevos Valores
     * @param {Object} args
     * @param {Object} args.valuesForm
     */
    hasNewValues({ valuesForm }) {
      // si es que hay un valor que se esta exceptuando, se emite que no esta completo
      const copyValuesForm = Object.values({ ...valuesForm })
      // si tiene todos los datos completados
      const disabled =
        !copyValuesForm.length ||
        copyValuesForm.some((val) => {
          if (Array.isArray(val)) return !val || !val.length
          else return !val
        })

      this.okButton.disabled = disabled
    },
  },
}
</script>

<style scoped></style>
