<template>
  <a-modal
    :visible="visible"
    title="Transferir a cola"
    :footer="null"
    :bodyStyle="bodyStyle"
    centered
    @cancel="$emit('onClose')"
  >
    <a-input-search
      v-model="seeker"
      placeholder="Buscar por nombre de cola"
      style="width: 100%; margin-bottom: 4px"
    />
    <template v-if="filterLines.length > 0">
      <a-row
        v-for="(line, index) in filterLines"
        :key="`line_${index}`"
        type="flex"
        align="middle"
        class="agent-contain flex-no-wrap"
      >
        <!--Nombre del agente-->
        <span class="modal__name ellipsis-2 flex-grow mrg-right-12">{{
          line.name
        }}</span>
        <!--boton para transferir-->
        <a-button @click="$emit('onTransfer', line)" :size="buttons_size">
          Transferir</a-button
        >
      </a-row>
    </template>
    <template v-else>
      <div style="text-align: center; margin: 1rem">
        <span>No se encontraron colas disponibles.</span>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalTransferLine',
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: false,
    },
    dataSource: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    agents_local: [],
    seeker: '',
    bodyStyle: {
      height: '480px',
      overflowY: 'auto',
      padding: '12px',
    },
  }),
  computed: {
    ...mapGetters(['buttons_size']),

    /**
     * Filtrar las colas a transferir
     * @returns {Object[]}
     */
    filterLines() {
      let regex = new RegExp(this.seeker, 'gi')

      return this.dataSource.filter((line) => line.name.match(regex))
    },
  },
}
</script>

<style lang="sass" scoped>
.agent-contain
  border-bottom: 1px solid $x_gray_light
  padding: 8px
  .modal__name
    margin-bottom: 0px
    color: $gray_dark_900
  .agent-avatar
    border-radius: 50%
    height: 40px
    width: 40px
</style>
