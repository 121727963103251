import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Agregar comentario
   * @param {*} context
   * @param {Object} args
   * @param {String} args.ticketId
   * @param {String} args.messageId
   * @param {String} args.text
   * @returns
   */
  async addComment(context, { ticketId, messageId, text }) {
    try {
      const params = {
        ticketId,
        messageId,
        text,
      }
      const response = await axios.post(`${vari.UHR}/agent/comment`, params)
      if (response.data.success) {
        const comments = response.data.data
        comments.forEach((comment) => {
          if (comment.messageId) context.commit('SET_COMMENT_LINKED', comment)
          context.commit('ADD_COMMENT_TICKET', comment)
        })
      }
      return response.data
    } catch (error) {
      console.error('[addComment]', error)
      return { success: false }
    }
  },
  /**
   * Obtener comentario
   * @param {*} context
   * @param {Object} args
   * @param {String} args.commentId
   * @param {Boolean} args.set
   * @returns
   */
  async getComment(context, { commentId, set = false }) {
    try {
      const response = await axios.get(`${vari.UHR}/agent/comment/${commentId}`)
      if (response.data.success) {
        const comment = response.data.data
        if (comment.messageId && set)
          context.commit('SET_COMMENT_LINKED', comment)
      }
      return response.data
    } catch (error) {
      console.error('[getComment]', error)
    }
  },
  /**
   * Actualiza un comentario
   * @param {*} context
   * @param {Object} args
   * @param {String} args.commentId
   * @param {String} args.text
   * @returns
   */
  async updateComment(context, { commentId, text }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/agent/comment/${commentId}`,
        { text }
      )
      if (response.data.success) {
        const comment = response.data.data
        if (comment.messageId) {
          context.commit('SET_COMMENT_LINKED', comment)
        }
        context.commit('UPDATE_COMMENT_TICKET', comment)
      }
      return response.data
    } catch (error) {
      console.error('[updateComment]', error)
    }
  },
  /**
   * Elimina un comentario
   * @param {*} context
   * @param {Object} args
   * @param {String} args.commentId
   * @param {String} args.messageId
   * @param {String} args.ticketId
   * @returns
   */
  async deleteComment(context, { commentId, messageId, ticketId }) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/agent/comment/${commentId}`
      )
      if (response.data.success) {
        if (messageId && ticketId) {
          context.commit('DELETE_COMMENT_LINKED', {
            commentId,
            messageId,
            ticketId,
          })
        }
        context.commit('DELETE_COMMENT_TICKET', commentId)
      }
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtiene comentarios paginados de un ticket
   * @param {*} context
   * @param {Object} args
   * @param {String} args.ticketId
   * @param {Number} args.page
   * @param {Number} args.limit
   * @param {String} args.search
   * @returns
   */
  async getPaginateCommentsByTicket(
    context,
    { ticketId, page, limit = 10, search = '' }
  ) {
    try {
      const params = {
        page,
        limit,
        search,
      }
      const response = await axios.get(
        `${vari.UHR}/agent/comment/ticket/${ticketId}`,
        { params }
      )
      const newResponse = {
        data: response.data.data.data,
        success: response.data.success,
      }
      if (response.data.success) {
        context.commit('SET_PAGINATE_COMMENTS_TICKET', response.data.data.data)
        delete response.data.data.data
        context.commit('SET_PAGINATION_COMMENTS_TICKET', response.data.data)
      }
      return newResponse
    } catch (error) {
      console.error('[getPaginateCommentsByTicket]', error)
    }
  },
}
export default actions
