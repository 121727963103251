<template>
  <a-form :form="form" layout="vertical">
    <a-form-item label="Nombre de chatbot">
      <a-input
        placeholder="Escribe aquí..."
        v-decorator="[
          'name',
          {
            rules: [
              { required: true, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        :maxLength="70"
      />
    </a-form-item>
    <a-form-item label="Descripción de chatbot">
      <a-textarea
        placeholder="Escribe aquí..."
        v-decorator="[
          'description',
          {
            rules: [
              { required: false, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        :maxLength="maxDescription"
        @input="handleInput"
      />
      <span class="counter"
        >{{ currentDescription ? currentDescription.length : 0 }}/{{
          maxDescription
        }}</span
      >
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralForm',
  props: {
    formValues: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  data: () => ({
    currentDescription: '',
    maxDescription: 150,
    channelsSelected: [], // canales seleccionados
    channelsDesasigned: [],
    newChannels: [], // canales nuevos
    channelsAsigned: [], // canales asignados
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'general-form-chatbot' })
  },
  computed: {
    ...mapGetters(['company']),
    channelsCompany() {
      const array = Object.keys(this.company.channels)
        .map((channel) => {
          let api = this.company.channels[channel]
          api.name = channel
          if (channel === 'messenger') api.order = 2
          if (channel === 'whatsapp') api.order = 1
          return api
        })
        .filter((channel) => channel.active)
      return array.sort((a, b) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0
      )
    },
    /**
     * @returns {String}
     */
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}?text=Hola%2C%20quiero%20solicitar%20asesoria%20de%20un%20experto%20en%20Ticker`
    },
  },
  methods: {
    handleInput(e) {
      this.currentDescription = e.target.value
    },
    setFieldsValues() {
      this.form.setFieldsValue({
        name: this.formValues.name,
        description: this.formValues.description,
      })
    },
    resetFields() {
      this.form.resetFields()
      this.newChannels = [] // canales nuevos
    },
  },
}
</script>

<style lang="sass" scoped>
.counter
  position: absolute !important
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
.form
  &__label
    display: flex
    align-items: center
    margin-bottom: 4px
    color: $gray_dark_900
  &__icon
    margin-left: 4px
</style>
