import moment from 'moment'

export default {
  methods: {
    /**
     * Evalúa y compara si una fecha es menor que la otra solo en días
     * @param {Date} dateA Fecha a comparar si es menor
     * @param {Date} dateB Fecha de referencia
     * @return {Boolean} Devuelve si es el A es menor que el B
     */
    dayDateIsLessThan(dateA, dateB) {
      if (moment(dateA).diff(dateB, 'days') > 0) return true
      else return false
    },
    /**
     * Evalúa y compara si una fecha es menor que la otra
     * @param {Date} dateA Fecha a comparar si es menor
     * @param {Date} dateB Fecha de referencia
     * @return {Boolean} Devuelve si es el A es menor que el B
     */
    dateIsLessThan(dateA, dateB) {
      if (dateA < dateB) return true
      else return false
    },
    /**
     * Cuenta cuántas fechas están dentro de un rango de minutos
     * @param {Number} x Rango de tiempo en min
     * @param {Array} dates Arreglo de fechas
     * @return {Number} Cantidad de fechas que cumplen el rango
     */
    isThereInXMin(x, dates) {
      return dates.reduce((total, date) => {
        if (moment(date) <= moment().add(x, 'minutes')) return total + 1
        else return total
      }, 0)
    },
    /**
     * Comprueba q el texto ingresado tiene todos los caracteres como numéricos
     * @param {String} str
     * @returns {Boolean} Es número?
     */
    isNumber(str) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(str)
    },
    /**
     * Comprueba si el usuario logueado se  encuentra dentro del arreglo de los permitidos
     * @param {String}    currentType Tipo de usuario a comparar
     * @param {String[]}  userTypesAllowed Tipos de usuario permitidos ['agent','admin','supervisor','admin_agent','supervisor_agent','master_agent']
     * @param {Object}    [options={}] Opciones de devolución de respuesta
     * @param {Boolean}   [options.literal] Interpretar el userTypesAllowed textualmente sin compararlo con roles equivalentes
     * @param {Boolean}   [options.exception] En lugar de devolver un boolean, ejecuta un throw exception (para ejecutar un catch) - opcional
     * @param {String[]}  [options.exclude] - excluye de los usuarios permitidos a uno
     * @return {Boolean}  Está permitido
     */
    isAllowedFor(currentType, userTypesAllowed, options = {}) {
      let isAllow = userTypesAllowed.includes(currentType)
      if (!isAllow) {
        if (userTypesAllowed.includes('master_agent')) {
          if (!options.literal) userTypesAllowed.push('master')
        }
        if (userTypesAllowed.includes('master')) {
          if (!options.literal) userTypesAllowed.push('master_agent')
        }
        if (userTypesAllowed.includes('admin')) {
          if (!options.literal) userTypesAllowed.push('admin_agent')
        }
        if (userTypesAllowed.includes('supervisor')) {
          if (!options.literal) userTypesAllowed.push('supervisor_agent')
        }
        if (userTypesAllowed.includes('agent')) {
          if (!options.literal) {
            if (!options.exclude || !options.exclude.includes('master_agent'))
              userTypesAllowed.push('master_agent')
            userTypesAllowed.push('admin_agent')
            userTypesAllowed.push('supervisor_agent')
          }
        }
        isAllow = userTypesAllowed.includes(currentType)
      }

      if (options.exception === true) {
        if (isAllow) return isAllow
        else throw isAllow
      } else {
        return isAllow
      }
    },
    /**
     * Está entre los roles equivalentes?
     * @param {String} slug Nombre del rol
     * @returns {Boolean}
     */
    inEquivRoleTo(slug) {
      if (slug === 'agent') {
        return ['agent', 'admin_agent', 'supervisor_agent'].includes(slug)
      } else if (slug === 'supervisor') {
        return ['supervisor', 'supervisor_agent'].includes(slug)
      } else if (slug === 'admin') {
        return ['admin', 'admin_agent'].includes(slug)
      } else if (slug === 'supervisor_agent') {
        return ['supervisor', 'supervisor_agent'].includes(slug)
      } else if (slug === 'admin_agent') {
        return ['admin', 'admin_agent'].includes(slug)
      }
    },
  },
}
