import scheduleModule from './scheduleModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo de agenda */
  resetMoSchedule(context) {
    context.commit('RESET_SCHEDULE_MODULE')
  },
}

const modules = {
  scheduleModule,
}

export default {
  modules,
  actions,
}
