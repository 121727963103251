<template>
  <div class="lines">
    <LinesTemplate />
  </div>
</template>

<script>
import LinesTemplate from '@/app/lines/components/templates/LinesTemplate.vue'
export default {
  name: 'Lines',
  components: {
    LinesTemplate,
  },
}
</script>
