import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Asignar supervisores a una Cola
   * @param {*} context
   * @param {Object} args Objeto de un supervisor
   * @param {String} args.supervisorId Id del perfil del supervisor
   */
  async asignSupervisorInLine(context, { supervisorId }) {
    try {
      const lineId = context.getters.line._id
      const supervisor = context.getters.supervisors.find(
        (supervisor) => supervisor._id === supervisorId
      )
      const response = await axios.put(
        `${vari.UHR}/admin/line/supervisor/asign`,
        {
          supervisorId: supervisor._id,
          lineId: lineId,
        }
      )

      supervisor.company.lineId = lineId
      context.commit('ADD_SUPERVISOR_ASIGNED', supervisor)
      context.commit('SUM_ASIGNED_SUPERVISORS_IN_LINE', {
        idLine: lineId,
        sum: 1,
      })

      return response.data
    } catch (error) {
      console.error('[lineModule][asignSupervisorInLine]', error)
      return error
    }
  },
}

export default actions
