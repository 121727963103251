import PERMISSIONS from '../../utils/permissions'

const initialState = () => ({
  permissions: {},
})

const state = initialState

const getters = {
  permissions: (state) => state.permissions,
}

const mutations = {
  /**
   * Setea los permisos de acuerdo al perfil del usuario de la sesión
   * @param {*} state
   * @param {Object} profile
   * @param {String} profile.type
   */
  SET_PERMISSIONS(state, profile) {
    const permissions = PERMISSIONS[profile.type]
    state.permissions = permissions
  },
}

const actions = {
  /**
   * Carga los permisos
   * @param {*} context
   */
  loadPermissions(context) {
    context.commit('SET_PERMISSIONS', context.getters.profile)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
