import moment from 'moment'

export default {
  data: () => ({
    moment,
  }),
  methods: {
    /**
     * Deshabilita los dias a partir de mañana
     * @return {Boolean}
     */
    disabledDateAfterCurrent(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
  },
}
