<template>
  <div>
    <a-row class="template__header" type="flex" justify="space-between">
      <header-module
        title="MIS DESCARGAS"
        :show-tooltip="false"
        tipography="heading-h5"
        class="mrg-bottom-0"
      />
      <div>
        <a-tooltip
          title="Tu límite se actualizará, cada que se libere una descarga encolada."
        >
          <a-icon type="info-circle" />
        </a-tooltip>
        <p class="mrg-bottom-0 display-inline mrg-left-8">
          {{ max_downloads_per_company - limit_download.spent }} /
          {{ max_downloads_per_company }} descargas disponibles
        </p>
      </div>
    </a-row>
    <section-my-downloads :loading="loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import SectionMyDownloads from '@/app/downloads/components/sections/SectionMyDownloads.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'DownloadsTemplate',
  components: {
    HeaderModule,
    SectionMyDownloads,
  },
  data: () => ({
    loading: false,
  }),
  mixins: [attemptMixin],
  created() {
    this.intervalPromiseAttempt(() => {
      if (!this.company) throw 'no company'
      this.loadInitialData()
    })
  },
  computed: {
    ...mapGetters(['company', 'limit_download', 'max_downloads_per_company']),
  },
  methods: {
    ...mapActions(['getDownloads', 'getLimitDownloads']),

    async loadInitialData() {
      this.loading = true
      await this.getDownloads()
      await this.getLimitDownloads()
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.template
  height: 100%
  &__header
    margin: 20px auto
</style>
