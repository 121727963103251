import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtiene todos los usuarios masters
   * @param {*} context
   * @returns
   */
  async getMasters(context) {
    try {
      const response = await axios.get(`${vari.UHR}/master/list`)
      if (response.data.success) {
        context.commit('SET_MASTERS', response.data.data)
      }

      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },
  /**
   * Obtiene las configuraciones del usuario master
   * @param {*} context
   * @returns
   */
  async getSettingsMaster(context) {
    try {
      const response = await axios.get(`${vari.UHR}/master/configs`)
      if (response.data.success) {
        context.commit('SET_MASTER_SETTINGS', response.data.data)
      }
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },
  /**
   * Actualiza las configuraciones del usuario master
   * @param {*} context
   * @param {Object} args
   * @param {Boolean} args.agentMode
   * @param {String} args.defaultCompanyId
   * @returns
   */
  async updateSettingsMaster(context, { agentMode, defaultCompanyId }) {
    try {
      const params = { agentMode, defaultCompanyId }
      const response = await axios.put(`${vari.UHR}/master/configs`, params)
      if (response.data.success) {
        context.commit('UPDATE_MASTER_SETTINGS', response.data.data)
      }
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response
    }
  },
  /**Transferir el permiso de propietario
   * @param {vuex} context
   * @param {String} userIdTo - id del usuario al cual se le transferira el permiso
   */
  async transferMasterOwner(context, userIdTo) {
    try {
      const url = `${vari.UHR}/master/transfer/`
      const response = await axios.post(url, { userIdTo })
      if (response.data.success) {
        context.commit('REMOVE_PROFILE_OWNER')
      }
      return response.data
    } catch (error) {
      console.error('[transferMasterOwner]', error)
    }
  },
}

export default actions
