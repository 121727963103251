<template>
  <section>
    <underline-title title="Configurar alerta para tickets activos" />
    <card-switch
      class="section__card"
      title="Agentes que tienen tickets activos"
      description="Se alertará a los agentes cuando lleguen mensajes nuevos a sus tickets activos, siempre que se encuentren en servicios, ocupados o desconectados de la plataforma."
      :active="activeTickets.activeAgents"
      @onChangeActive="handleChangeActive('active-tickets-agents')"
      :loading="loadingAgents"
    />
    <card-switch
      class="section__card"
      title="Otros destinatarios"
      description="Se notificará a los teléfonos que registres, cuando existan mensajes nuevos de tickets activos de todos tus agentes. Recuerda agregar el código de país y números telefónicos válidos para ayudarte con este tipo de notificaciones."
      :active="activeTickets.activeOthers"
      @onChangeActive="handleChangeActive('active-tickets-others')"
      :loading="loadingOthers"
    >
      <div slot="extra-content" class="section__extra-content">
        <a-button
          type="primary"
          icon="plus"
          size="small"
          @click="modalAddPhone.visible = true"
          class="section__button"
          :disabled="!activeTickets.activeOthers"
          >Agregar nuevo teléfono</a-button
        >
        <editable-table
          v-if="activeTickets.phones"
          :columns="columns"
          :data-source="activeTickets.phones"
          @onDelete="handleDeletePhone"
          :disabled="!activeTickets.activeOthers"
        />
      </div>
    </card-switch>
    <modal-add-phone
      :visible="modalAddPhone.visible"
      @onCancel="modalAddPhone.visible = false"
      @onAddPhone="handleUpdateActiveTickets"
    />
    <modal-callback
      :visible="modalCallback.visible"
      @onCancel="handleCancelCallback"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardSwitch from '@/app/shared/components/cards/CardSwitch'
import UnderlineTitle from '@/app/settings/components/organisms/alertsMenu/UnderlineTitle'
import EditableTable from '@/app/settings/components/organisms/alertsMenu/EditableTable'
import ModalAddPhone from '@/app/settings/components/organisms/alertsMenu/ModalAddPhone'
import ModalCallback from '@/app/settings/components/organisms/alertsMenu/ModalCallback'

export default {
  name: 'SectionActiveTickets',
  components: {
    CardSwitch,
    UnderlineTitle,
    EditableTable,
    ModalAddPhone,
    ModalCallback,
  },
  data: () => ({
    columns: [
      {
        title: 'País',
        key: 'country',
        dataIndex: 'country',
        // width: '10%',
      },
      {
        title: 'Código de país',
        key: 'code',
        dataIndex: 'code',
      },
      {
        title: 'Número de teléfono',
        key: 'number',
        dataIndex: 'number',
      },
      {
        title: 'Acción',
        key: 'action',
        dataIndex: 'action',
        width: '30%',
        scopedSlots: { customRender: 'action' },
      },
    ],
    activeTickets: { phones: [] },
    modalAddPhone: { visible: false },
    modalCallback: { visible: false },
    loadingAgents: false,
    loadingOthers: false,
  }),
  mounted() {
    this.setValue()
  },
  computed: {
    ...mapGetters(['company', 'notificationsPhones']),
  },
  watch: {
    notificationsPhones() {
      this.setValue()
    },
  },
  methods: {
    ...mapActions(['updateNotificationsTickets', 'setAnalytics']),

    setValue() {
      this.activeTickets = {
        activeAgents:
          this.company.settings.notifications.ticket.received.active,
        activeOthers:
          this.company.settings.notifications.ticket.received.activeForOthers,
        phones: this.notificationsPhones.received
          ? this.notificationsPhones.received
          : [],
        inputValue: '',
      }
    },
    handleChangeActive(type) {
      let phones_target = null
      if (!this.company.settings.notifications.editable) {
        if (type === 'active-tickets-agents') this.loadingAgents = true
        else this.loadingOthers = true
        return (this.modalCallback.visible = true)
      }
      if (type === 'active-tickets-agents') {
        this.activeTickets.activeAgents = !this.activeTickets.activeAgents
        phones_target = 'agents'
      } else {
        this.activeTickets.activeOthers = !this.activeTickets.activeOthers
        phones_target = 'others'
        // this.handleExecutedToOthers()
      }
      this.handleUpdateActiveTickets()
      this.setAnalytics({
        eventName: 'alert_sms_tickets_active',
        extraData: {
          phones_target,
          switch: this.activeTickets.activeAgents ? 'on' : 'off',
        },
      })
    },
    async handleUpdateActiveTickets(phone) {
      if (phone) this.activeTickets.phones.push(phone)
      const updateNotificationsTickets = await this.updateNotificationsTickets({
        active: this.activeTickets.activeAgents,
        activeForOthers: this.activeTickets.activeOthers,
        phones: this.activeTickets.phones,
      })
      this.modalAddPhone.visible = false
      if (updateNotificationsTickets.success)
        this.$message.success('Se actualizó exitósamente')
      else {
        const message = updateNotificationsTickets.details
          ? updateNotificationsTickets.details
          : 'No se pudo actualizar'
        this.$message.error(message)
      }
    },
    handleExecutedToOthers() {
      console.log('ejecutar el cambio para otros destinatarios')
    },
    handleDeletePhone(record) {
      console.log(record)
      let n = 0
      const phones = this.activeTickets.phones.map((elem) => {
        n = n + 1
        elem.order = n
        return elem
      })
      console.log(phones)
      const newPhones = phones.filter((elem) => elem.order !== record.order)
      console.log(newPhones)
      this.activeTickets.phones = newPhones
      this.handleUpdateActiveTickets()
    },
    handleCancelCallback() {
      this.modalCallback.visible = false
      this.loadingAgents = false
      this.loadingOthers = false
      this.setValue()
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  &__card
    margin-top: 12px
  &__extra-content
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
  &__button
    margin: 12px auto 8px 0px
</style>
