const mutations = {
  /**
   * Setea todos los productos asignados
   * @param {*} state
   * @param {Array} products // Lista de objetos productos
   */
  SET_ASSIGNED_PRODUCTS(state, products) {
    state.assigned_products = products
  },

  /**
   * Agrega uno o mas productos a los productos asignados
   * @param {*} state
   * @param {Array} products Array de productos
   */
  ADD_PRODUCTS_TICKET(state, products) {
    state.assigned_products = state.assigned_products.concat(products)
  },
  /**
   * Remueve un producto de los productos asignados
   */
  REMOVE_ASSIGN_PRODUCT_TICKET(state, idProduct) {
    state.assigned_products = state.assigned_products.filter(
      (product) => product._id !== idProduct
    )
  },
}

export default mutations
