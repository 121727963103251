const breakpoints = {
  xs: {
    restrictions: 'screen and (max-width: 576px)',
    ex: '480px',
    // permissions: [

    // ],
    components: {
      buttons: {
        size: 'large',
      },
      sidebar: {
        visible: false,
      },
      navbar: {
        visible: true,
        submenu: true,
      },
      inputs: {
        size: 'large',
      },
      drawer_profile: {
        width: '280px',
      },
      modal_state: {
        font_title: 'heading-h6',
      },
      title_module: {
        visible: false,
      },
      banner_selection: {
        visible: true,
        styles: {
          marginTop: '56px', // de acuerdo al margen del navbar
        },
      },
      section_tickets: {
        styles: {
          paddingTop: '36px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'one-view',
      styles: { padding: '12px 20px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'submenu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '12px 20px', height: '100%' },
        navbar: { position: 'fixed' },
      },
      workspace: {
        type_menu: 'submenu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '12px 20px', height: '100%' },
        navbar: { position: 'fixed' },
      },
      ticket: {
        styles: { padding: '0px', height: '100%' },
        navbar: { position: 'normal' },
      },
      'drawer-ticket': {
        styles: { padding: '0px', height: '100%' },
        navbar: { position: 'normal' },
      },
    },
    is_mobile: true,
  },
  sm: {
    restrictions: 'screen and (min-width: 576px) and (max-width: 768px)',
    ex: '576px',
    components: {
      buttons: {
        size: 'large',
      },
      sidebar: {
        visible: false,
      },
      navbar: {
        visible: true,
        submenu: true,
      },
      inputs: {
        size: 'large',
      },
      drawer_profile: {
        width: '332px',
      },
      modal_state: {
        font_title: 'heading-h6',
      },
      title_module: {
        visible: false,
      },
      banner_selection: {
        visible: true,
        styles: {
          marginTop: '56px', // de acuerdo al margen del navbar
        },
      },
      section_tickets: {
        styles: {
          paddingTop: '36px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'one-view',
      styles: { padding: '12px 20px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'submenu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '12px 20px', height: '100%' },
      },
      workspace: {
        type_menu: 'submenu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '12px 20px', height: '100%' },
      },
      ticket: {
        styles: { padding: '0px', height: '100%' },
      },
      'drawer-ticket': {
        styles: { padding: '0px', height: '100%' },
      },
    },
    is_mobile: true,
  },
  md: {
    restrictions: 'screen and (min-width: 768px) and  (max-width: 992px)',
    ex: '768px',
    components: {
      buttons: {
        size: 'large',
      },
      sidebar: {
        visible: false,
      },
      navbar: {
        visible: true,
        submenu: false,
      },
      inputs: {
        size: 'large',
      },
      drawer_profile: {
        width: '332px',
      },
      modal_state: {
        font_title: 'heading-h4',
      },
      title_module: {
        visible: false,
      },
      banner_selection: {
        visible: true,
        styles: {
          marginTop: '56px', // de acuerdo al margen del navbar
        },
      },
      section_tickets: {
        styles: {
          paddingTop: '36px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'one-view',
      styles: { padding: '16px 20px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'menu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '16px 20px', height: '100%' },
      },
      workspace: {
        type_menu: 'menu', // sidebar, menu, submenu
        filters: 'row',
        styles: { padding: '16px 20px', height: '100%' },
      },
      ticket: {
        type_menu: 'menu', // sidebar, menu, submenu
        styles: { padding: '0px', height: '100%' },
      },
      'drawer-ticket': {
        type_menu: 'menu', // sidebar, menu, submenu
        styles: { padding: '0px', height: '100%' },
      },
    },
    is_mobile: true,
  },
  lg: {
    restrictions: 'screen and (min-width: 992px) and  (max-width: 1200px)',
    ex: '992px',
    components: {
      buttons: {
        size: 'default',
      },
      sidebar: {
        visible: true,
      },
      navbar: {
        visible: false,
        submenu_workspace: false,
      },
      inputs: {
        size: 'default',
      },
      workspace_selector_mailbox: {
        visible: true,
      },
      drawer_profile: {
        width: '332px',
      },
      modal_state: {
        font_title: 'heading-h4',
      },
      title_module: {
        visible: true,
      },
      banner_selection: {
        visible: false,
      },
      section_tickets: {
        styles: {
          marginTop: '0px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'multi-views',
      styles: { padding: '12px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      workspace: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      ticket: {},
      'drawer-ticket': {},
    },
    is_mobile: false,
  },
  xl: {
    restrictions: 'screen and (min-width: 1200px) and  (max-width: 1600px)',
    ex: '1200px',
    components: {
      buttons: {
        size: 'default',
      },
      sidebar: {
        visible: true,
      },
      navbar: {
        visible: false,
        submenu_workspace: false,
      },
      inputs: {
        size: 'default',
      },
      workspace_selector_mailbox: {
        visible: true,
      },
      drawer_profile: {
        width: '332px',
      },
      modal_state: {
        font_title: 'heading-h4',
      },
      title_module: {
        visible: true,
      },
      banner_selection: {
        visible: false,
      },
      section_tickets: {
        styles: {
          marginTop: '0px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'multi-views',
      styles: { padding: '12px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      workspace: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      ticket: {},
      'drawer-ticket': {},
    },
    is_mobile: false,
  },
  xxl: {
    restrictions: 'screen and (min-width: 1600px)',
    ex: '1200px',
    components: {
      buttons: {
        size: 'default',
      },
      sidebar: {
        visible: true,
      },
      navbar: {
        visible: false,
        submenu_workspace: false,
      },
      inputs: {
        size: 'default',
      },
      workspace_selector_mailbox: {
        visible: true,
      },
      drawer_profile: {
        width: '332px',
      },
      modal_state: {
        font_title: 'heading-h4',
      },
      title_module: {
        visible: true,
      },
      banner_selection: {
        visible: false,
      },
      section_tickets: {
        styles: {
          marginTop: '0px', // de acuerdo al padding top de main_layout
        },
      },
    },
    main_layout: {
      type: 'multi-views',
      styles: { padding: '12px' },
    },
    views: {
      // vistas de acuerdo al nombre de las rutas
      default: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      workspace: {
        type_menu: 'sidebar', // sidebar, menu, submenu
        filters: 'column',
      },
      ticket: {},
      'drawer-ticket': {},
    },
    is_mobile: false,
  },
}

export { breakpoints }
