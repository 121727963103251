<template>
  <base-custom-drawer title="Tickets anteriores">
    <custom-section-scroll
      ref="list"
      id-master="section-old-tickets"
      :master-styles="masterStyles"
      @onScroll="handleScroll"
    >
      <div class="section-previous-container">
        <template v-if="isLoadingOldTickets">
          <a-skeleton
            v-for="i in 10"
            :key="(i + 10).toString(36) + i"
            :loading="isLoadingOldTickets"
            active
            :paragraph="{ rows: 1, width: '100%' }"
            class="skeleton"
          >
          </a-skeleton>
        </template>
        <template v-else>
          <p v-if="oldTickets.length === 0" class="empty body-2">
            No se han encontrado tickets anteriores
          </p>
          <template v-else>
            <div ref="list_content" class="list-content">
              <card-old-ticket
                v-for="ticket in oldTickets"
                :key="ticket._id"
                class="old-ticket"
                @click.native="showModal(ticket)"
                :channel="ticket.channel"
                :number-ticket="ticket.ticket"
                :date="ticket.lastMessage.created_at || ''"
                :lastMessage="ticket.abstract.message.text"
                :totalMessages="ticket.totalMessages"
                :origin="ticketOrigin(ticket, false)"
              />
            </div>

            <modal-ticket-details
              v-if="oldTicketSelected"
              :visible="visible"
              :ticket="oldTicketSelected"
              @close="handleOk"
            />
          </template>
        </template>
      </div>
    </custom-section-scroll>
  </base-custom-drawer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import ModalTicketDetails from '@/app/shared/components/modals/ModalTicketDetails'
import BaseCustomDrawer from '@/app/tickets/components/organisms/sections/BaseCustomDrawer'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import CardOldTicket from '@/app/tickets/components/molecules/CardOldTicket'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'SectionOldTickets',
  components: {
    ModalTicketDetails,
    BaseCustomDrawer,
    CustomSectionScroll,
    CardOldTicket,
  },
  data: () => ({
    moment: moment,
    visible: false,
    stripeSection: null,
    error_range_scroll_list: 6, // Margen de error para el calculo del tope del scroll
    lastSkip: 0, // Para comprobar q no se mande 2 veces con el mismo skip
    isLoadingOldTickets: true,
  }),
  created() {
    this.intervalAttempt(async () => {
      if (!this.client) throw 'client'
      this.loadData()
    })
  },
  mixins: [formatMixin, attemptMixin, supportMixin],
  computed: {
    ...mapGetters(['oldTickets', 'oldTicketSelected', 'client', 'navbar']),

    /**
     * @return {Object} - estilos para el master del scroll
     */
    masterStyles() {
      const styles = {}
      this.navbar.visible
        ? (styles.height = 'calc( 100% - 1em)')
        : (styles.height = 'calc( 100% - 5em)')
      return styles
    },
  },
  methods: {
    ...mapMutations(['SET_SHOW_OLD_TICKETS']),
    ...mapActions(['selectOldTicket', 'paginateMessages', 'listOldTickets']),
    handleBack() {
      this.SET_SHOW_OLD_TICKETS(false)
    },
    async showModal(oldTicket) {
      this.visible = true
      await this.selectOldTicket(oldTicket)
      this.paginateMessages({ target: 'history' })
    },
    handleOk() {
      this.visible = false
    },
    handleScroll() {
      this.scrollBottomReached()
    },
    /**
     * Ejecuta una acción cuando el scroll llega a la parte baja de la lista de tickets
     */
    scrollBottomReached() {
      let list_container = document.getElementById(
        'section-old-tickets-scroll-container'
      )
      let list_content = this.$refs.list_content
      /** Altura del contenedor */
      const listHeight =
        list_container.offsetHeight + this.error_range_scroll_list
      /** Altura del contenido */
      const listHeightCalculated =
        list_content.scrollHeight - list_container.scrollTop

      /** Setear auto_scrollable si se está hasta 20px cerca al final de abajo */
      if (Math.ceil(listHeight) >= listHeightCalculated) {
        let skip = this.oldTickets.length
        if (skip !== this.lastSkip) {
          this.listOldTickets({ skip })
          this.lastSkip = skip
        }
      }
    },
    /**
     * Carga los tickets antiguos
     */
    async loadData() {
      this.isLoadingOldTickets = true
      await this.listOldTickets({ skip: 0 })
      this.isLoadingOldTickets = false
    },
  },
}
</script>

<style lang="sass" scoped>
.section-previous
  &-container
    margin: 0px 20px 2px 20px
    height: calc( 100% - 40px)
    position: relative
    overflow: hidden
    @include xs
      margin: 0px 20px
    @include sm
      margin: 0px 20px
    @include md
      margin: 0px 20px
    .empty
      color: $gray_dark_200
      margin: 17px 0 0 0
    .old-ticket
      margin-bottom: 12px
      &:last-child
        margin-bottom: 0
</style>
<style lang="sass">
.section-previous-container
  .skeleton
    .ant-skeleton-content
      .ant-skeleton-title
        margin-top: 0px
      .ant-skeleton-paragraph
        margin-top: 4px
        border: 1px solid #CDCDCD
        padding: 12px
        border-radius: 4px
</style>
