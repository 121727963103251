var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Solicitud de baja de número","centered":""},on:{"cancel":_vm.onCancel}},[_c('p',{staticClass:"body-2"},[_vm._v(" Tu solicitud será atendida en promedio de 7 a 10 días. Es posible que nos contactemos contigo para informarte sobre el proceso. ")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{attrs:{"label":"Correo de contacto"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,

                message: 'Por favor completa este campo',
              },
              { type: 'email', message: 'No es un email válido' },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n\n                message: 'Por favor completa este campo',\n              },\n              { type: 'email', message: 'No es un email válido' },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí..."}})],1),_c('a-form-item',{attrs:{"label":"Teléfono de contacto"}},[_c('a-row',{attrs:{"gutter":8,"type":"flex"}},[_c('a-col',{attrs:{"span":16,"order":2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"maxLenght":20,"placeholder":"Escribe aquí..."}})],1),_c('a-col',{attrs:{"span":8,"order":1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                ],
              },
            ]),expression:"[\n              'code',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","option-filter-prop":"children","placeholder":"País"}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country._id,attrs:{"title":country.nameSpanish}},[_vm._v(" "+_vm._s(country.dialCode)+" "+_vm._s(country.nameSpanish)+" ")])}),1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Motivo"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'reason',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              },
            ],
          },
        ]),expression:"[\n          'reason',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor completa este campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 3, maxRows: 4 },"maxLength":_vm.maxReason},on:{"input":_vm.handleSetCurrentDescription}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentDescription ? _vm.currentDescription.length : 0)+"/"+_vm._s(_vm.maxReason))])],1)],1),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.onCancel}},[_vm._v("Cancelar")]),_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("Enviar solicitud")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }