<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'LineChart',
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    // options: { type: Object, required: true },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        position: 'top',
        align: 'start',
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        bodySpacing: 8,
      },
    },
  }),
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options)
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
