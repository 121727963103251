<template>
  <a
    v-if="show_documentation || forceShow"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot>
      {{ title }}
    </slot>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Anchor',
  props: {
    href: { type: String, default: null, required: false },
    title: { type: String, default: '', required: false },
    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['show_documentation']),
  },
}
</script>

<style scoped></style>
