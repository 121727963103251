import Tickets from '@/app/tickets/views/Tickets'
import Ticket from '@/app/tickets/views/Ticket'
import DrawerTicket from '@/app/tickets/components/organisms/DrawerTicket'
import SectionDefaultTickets from '@/app/tickets/components/molecules/SectionDefaultTickets.vue'
const VUE_APP_NAME = process.env.VUE_APP_NAME

const Workspace = 'Workspace'

export default [
  {
    path: '/workspace',
    name: 'workspace',
    component: Tickets,
    meta: {
      requiresAuth: true,
      requiresType: [
        'agent',
        'admin_agent',
        'supervisor_agent',
        'master_agent',
      ],
      title: `${Workspace} | ${VUE_APP_NAME}`,
      is_workspace: true,
      requiresDevice: ['desktop', 'mobile'],
    },
    children: [
      {
        path: ':ticketId',
        name: 'ticket',
        component: Ticket,
        meta: {
          requiresAuth: true,
          requiresType: [
            'agent',
            'admin_agent',
            'supervisor_agent',
            'master_agent',
          ],
          title: `${Workspace} | ${VUE_APP_NAME}`,
          is_workspace: true,
          requiresDevice: ['desktop', 'mobile'],
        },
        children: [
          {
            path: 'drawer/:drawerId',
            name: 'drawer-ticket',
            component: DrawerTicket,
            meta: {
              requiresAuth: true,
              requiresType: [
                'agent',
                'admin_agent',
                'supervisor_agent',
                'master_agent',
              ],
              title: `${Workspace} | ${VUE_APP_NAME}`,
              is_workspace: true,
              requiresDevice: ['desktop', 'mobile'],
            },
          },
        ],
        redirect: {
          name: 'default',
        },
      },
      {
        path: 'default',
        name: 'default',
        component: SectionDefaultTickets,
        meta: {
          requiresAuth: true,
          requiresType: [
            'agent',
            'admin_agent',
            'supervisor_agent',
            'master_agent',
          ],
          title: `${Workspace} | ${VUE_APP_NAME}`,
          is_workspace: true,
          requiresDevice: ['desktop', 'mobile'],
        },
      },
    ],
    redirect: {
      name: 'default',
    },
  },
]
