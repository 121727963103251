import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**  Obtener los productos asignados
   * @param {Object}
   * @param {String} search
   * @param {Number} page
   * @param {Number} limit
   */
  async getAssignedProducts(
    context,
    { search, page, limit = 12, idTicket, next }
  ) {
    try {
      if (!idTicket) return
      const searchFormatted = search.replace(/#/gm, '')
      const params = {
        search: searchFormatted,
        page,
        limit,
      }
      const response = await axios.get(
        `${vari.UHR}/agent/ticket/${idTicket}/products`,
        { params }
      )
      const assignedProducts = response.data.result.data.map((product) => {
        const newObj = product.product
        newObj.idAssigned = product._id
        return newObj
      })
      assignedProducts.forEach((element) => {
        element.assign = true
      })
      if (next) context.commit('ADD_PRODUCTS_TICKET', assignedProducts)
      else context.commit('SET_ASSIGNED_PRODUCTS', assignedProducts)
      return response.data
    } catch (error) {
      console.error('[productsModule][getAssignedProducts]', error)
    }
  },
  /**  Asignar productos
   * @param {Array} productsIds id de todos los productos
   */
  async assignProducts(context, productsIds) {
    try {
      if (!context.getters.ticketSelected) return
      const idTicket = context.getters.ticketSelected._id
      const response = await axios.post(
        `${vari.UHR}/agent/ticket/${idTicket}/products`,
        { productsIds }
      )
      const assignedProducts = response.data.result.map(
        (product) => product.product
      )
      assignedProducts.forEach((product) => (product.assign = true))
      context.commit('ADD_PRODUCTS_TICKET', assignedProducts)
      return response.data
    } catch (error) {
      console.error('[productsModule][assignProducts]', error)
      return error.response.data
    }
  },
  /**  Remueve un producto asignado
   * @param {Object}
   * @param {String} idProduct
   */
  async removeAssignedProduct(context, idProduct) {
    try {
      if (!context.getters.ticketSelected) return
      const idTicket = context.getters.ticketSelected._id
      const response = await axios.put(
        `${vari.UHR}/agent/ticket/${idTicket}/products`,
        { productId: idProduct }
      )
      if (response.data.success)
        context.commit('REMOVE_ASSIGN_PRODUCT_TICKET', idProduct)
      return response.data
    } catch (error) {
      console.error('[productsModule][removeAssignedProduct]', error)
    }
  },
}

export default actions
