<template>
  <a-row
    type="flex"
    class="flex-no-wrap preview-comment"
    :class="{
      'preview-comment--linked': isLinked,
      'preview-comment-history': type === 'history',
    }"
  >
    <!--Avatar del usuario que creó el comentario-->
    <div class="mrg-right-8">
      <avatar-user :src="avatar" :size="24" />
    </div>

    <a-row class="flex-grow preview-comment__content">
      <a-row
        type="flex"
        justify="space-between"
        class="flex-no-wrap mrg-bottom-4"
      >
        <a-row type="flex" class="flex-no-wrap flex-grow">
          <p class="ellipsis mrg-bottom-0">
            <!--Nombre del usuario que creó el comentario-->
            <span class="semibold-14 preview-comment__names">{{
              fullName
            }}</span>
            <!--Fecha del comentario-->
            <span class="regular-12 mrg-left-4 preview-comment__date">{{
              moment(date).calendar()
            }}</span>
          </p>
        </a-row>
        <!--Acciones del comentario-->
        <a-row type="flex" align="middle" class="flex-no-wrap mrg-left-12">
          <!--Editar comentario-->
          <a-tooltip>
            <span slot="title">{{ updateAction.tooltip }} </span>
            <a-icon
              type="edit"
              class="mrg-right-12 preview-comment__icon"
              :class="{ 'icon--disabled': !updateAction.enabled }"
              @click.stop="handleShowModalComment"
            />
          </a-tooltip>
          <!--Eliminar comentario-->
          <a-tooltip>
            <span slot="title">{{ deleteAction.tooltip }} </span>
            <a-icon
              type="delete"
              class="preview-comment__icon preview-comment__icon--delete"
              :class="{ 'icon--disabled': !deleteAction.enabled }"
              @click.stop="
                deleteAction.enabled &&
                  showConfirmDeleteComment(handleDeleteComment)
              "
            />
          </a-tooltip>
        </a-row>
      </a-row>
      <!--Texto del comentario-->
      <p class="regular-14 text--left mrg-bottom-0 preview-comment__text">
        <span>
          {{ formattedComment.comment }}
        </span>
        <strong
          v-if="formattedComment.ellipsis"
          class="cursor-pointer"
          @click="collapse = !collapse"
          >{{ textCollapse }}</strong
        >
      </p>
      <!--Redireccion al mensaje linkeado-->
      <p v-if="messageId" class="regular-12 mrg-top-8 mrg-bottom-0 text--right">
        <anchor :force-show="true">
          <a-icon type="link" />
          Vinculado a un mensaje
        </anchor>
      </p>
    </a-row>
    <!--Modal de comentario, para editar-->
    <modal-comment
      ref="modal_comment"
      :visible="modalComment.visible"
      title="Editar comentario"
      okText="Guardar comentarios"
      cancelText="Cancelar"
      :message-id="messageId"
      @onClose="handleCloseModalComment"
      action="update"
      :commentId="commentId"
    />
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Anchor from '@/app/shared/components/atoms/Anchor'
import moment from 'moment'
import showConfirmDeleteComment from '@/app/tickets/mixins/comments'
import ModalComment from '@/app/tickets/components/molecules/ModalComment'
import AvatarUser from '@/app/shared/components/avatars/AvatarUser'

export default {
  name: 'Comment',
  components: {
    Anchor,
    ModalComment,
    AvatarUser,
  },
  props: {
    avatar: {
      type: String,
      required: false,
      default: null,
    },
    fullName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    isLinked: {
      type: Boolean,
      required: false,
      default: false,
    },
    comment: {
      type: String,
      required: true,
    },
    messageId: {
      type: String,
      required: false,
    },
    commentId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
      enum: ['default', 'history'],
    },
  },
  mixins: [showConfirmDeleteComment],
  data: () => ({
    moment,
    collapse: false,
    modalComment: {
      visible: false,
    },
    maxCommentLength: 120,
  }),
  computed: {
    ...mapGetters(['ticketSelected', 'profile']),

    /**
     * Texto de acuerdo si la descripción está colapsada o no
     * @return {String}
     */
    textCollapse() {
      return this.collapse ? 'Ver menos' : 'Ver más'
    },
    /**
     * Comentario formateado
     * @return {Object} formattedComment
     * @return {Boolean} formattedComment.ellipsis
     * @return {String} formattedComment.comment
     */
    formattedComment() {
      let formattedComment = {
        ellipsis: false,
        comment: this.comment,
      }
      const hasEllipsis = this.comment.length > this.maxCommentLength
      formattedComment = {
        ellipsis: hasEllipsis,
        comment:
          hasEllipsis && !this.collapse
            ? `${this.comment.slice(0, this.maxCommentLength)}...`
            : this.comment,
      }

      return formattedComment
    },
    /**
     * Estado del ticket
     * @return {String}
     */
    statusTicket() {
      return this.type === 'default' && this.$route.query
        ? this.$route.query.status
        : 'ended'
    },
    /**
     * Accion de actualizar
     * @return {Object} updateAction
     * @return {Boolean} updateAction.enabled
     * @return {String} updateAction.tooltip
     */
    updateAction() {
      const enabled =
        this.statusTicket === 'active' && this.userId === this.profile?.userId

      let tooltip = 'Editar'

      // si el ticket ya esta finalizado
      if (this.statusTicket === 'ended')
        tooltip =
          'Solo se puede editar la nota mientras se encuentre sin resolver.'
      // si el ticket esta activo pero el creador no es el mismo de la sesion
      else if (
        this.statusTicket === 'active' &&
        this.userId !== this.profile.userId
      )
        tooltip = 'Solo el propietario de la nota puede editarlo.'

      return {
        enabled,
        tooltip,
      }
    },
    /**
     * Accion de eliminar
     * @return {Object} updateAction
     * @return {Boolean} updateAction.enabled
     * @return {String} updateAction.tooltip
     */
    deleteAction() {
      const enabled =
        this.statusTicket === 'active' && this.userId === this.profile?.userId

      let tooltip = 'Eliminar'

      // si el ticket ya esta finalizado
      if (this.statusTicket === 'ended')
        tooltip =
          'Solo se puede eliminar la nota mientras se encuentre sin resolver.'
      // si el ticket esta activo pero el creador no es el mismo de la sesion
      else if (
        this.statusTicket === 'active' &&
        this.userId !== this.profile.userId
      )
        tooltip = 'Solo el propietario de la nota puede eliminarlo.'

      return {
        enabled,
        tooltip,
      }
    },
  },
  methods: {
    ...mapActions(['deleteComment']),

    /**
     * Elimina el comentario
     */
    async handleDeleteComment() {
      const response = await this.deleteComment({
        commentId: this.commentId,
        messageId: this.messageId,
        ticketId: this.ticketSelected._id,
      })
      if (response.success) this.$message.success('Se eliminó un comentario')
      else this.$message.error('Ocurrió un error 😥')
    },
    /**
     * Muestra el modal de comentario para editar
     */
    handleShowModalComment() {
      if (!this.updateAction.enabled) return
      this.modalComment.visible = true
      this.$refs.modal_comment?.setComment(this.comment)
    },
    /**
     * Cierra el modal de comentario
     */
    handleCloseModalComment() {
      this.modalComment.visible = false
    },
  },
}
</script>

<style lang="sass" scoped>
.preview-comment
  width: 100%
  border-bottom: 1px solid $gray_4
  padding-top: 12px
  padding-left: 12px
  padding-bottom: 12px
  &:last-child
    border-bottom: 1px solid transparent
  &__icon
    font-size: 16px
    &--delete
      color: $red_6
  &--linked
    border-left: 4px solid $gold_6
    cursor: pointer
    &:hover
      background-color: $x_blue
  &__content
    width: calc(100% - 32px)
  &-history
    background-color: $gray_2
    border-width: 0
    margin-bottom: 8px
    padding: 12px
  &__names
    color: $gray_9
  &__date
    color: $gray_7
  &__text
    color: $gray_8
    white-space: pre-wrap
    word-wrap: break-word
</style>
