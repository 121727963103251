<template>
  <div class="template">
    <header-module
      class="template__header"
      title="NOTIFICACIONES"
      :show-tooltip="false"
      tipography="heading-h5"
    />
    <ListNotifications
      @onBottomReached="searchNextPage"
      @onChangeCompany="changeCompany"
      :isLoading="isLoading"
      :morePaginate="morePaginate"
      :notifications="notifications"
      ref="list_notifications"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ListNotifications from '@/app/notifications/components/organisms/ListNotifications.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'NotificationsTemplate',
  mixins: [attemptMixin],
  components: {
    ListNotifications,
    HeaderModule,
  },
  data: () => ({
    filters: {
      page: 1,
      companyId: null,
    },
    isLoading: false,
    morePaginate: true,
  }),
  created() {
    this.intervalAttempt(async () => {
      this.cleanBadgeNotifications()
    })
  },
  beforeDestroy() {
    this.notifications.forEach((notification) => {
      if (notification.new) this.readNewNotification(notification._id)
    })
  },
  computed: {
    ...mapGetters(['profile', 'notifications']),
  },
  methods: {
    ...mapActions([
      'cleanNotifications',
      'getNotifications',
      'readNewNotification',
    ]),
    ...mapMutations(['SET_PROFILE_NOTIFICATIONS']),

    async cleanBadgeNotifications() {
      const response = await this.cleanNotifications()
      if (response.success) this.SET_PROFILE_NOTIFICATIONS(0)
    },
    /**
     * Busca notificaciones en la siguiente página
     */
    async searchNextPage() {
      this.filters.page = this.filters.page + 1
      this.isLoading = true
      const response = await this.getNotifications({
        companyId: this.filters.companyId,
        page: this.filters.page,
        next: true,
      })
      this.isLoading = false

      if (response.result.length === 0) {
        this.morePaginate = false
      }
      this.$refs.list_notifications.setLastChild()
    },
    /**
     * Escucha el cambio de empresas
     * @param {String} companyId
     * @param {Callback} afterCall
     */
    async changeCompany(companyId, afterCall) {
      this.filters.page = 1
      this.filters.companyId = companyId
      this.isLoading = true
      await this.getNotifications({
        companyId: this.filters.companyId,
        page: this.filters.page,
      })
      afterCall()
      this.isLoading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.template
  height: 100%
  &__header
    margin: 20px auto
</style>
