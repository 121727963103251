/**
 * Valida y carga los datos del Storage
 * @param {String} dKey nombre de llave del dato
 * @param {Function} callback valor de la llave dato
 * @param {String} [typeStorage='sessionStorage'] [sessionStorage,localStorage]
 * @callback {Function}
 * @example
 * vloadData('key_session', (found, data) => {
 *  if(found) {
 *    const data = data
 *    // ...
 *  }else{
 *    const data = {}
 *    saveData('key_session', data, 'localStorage')
 *  }
 * }, 'localStorage')
 */
const vloadData = (dKey, callback, typeStorage = 'sessionStorage') => {
  if (!['sessionStorage', 'localStorage'].includes(typeStorage))
    throw new Error(`typeStorage invalid`)

  const data = window[typeStorage].getItem(dKey)
  if (data) {
    const obj = JSON.parse(data)
    let parsedData = ''
    switch (obj.dataType) {
      case 'string': {
        parsedData = obj.dValue
        break
      }
      case 'number': {
        parsedData = parseInt(obj.dValue)
        break
      }
      case 'boolean': {
        parsedData = obj.dValue === 'true'
        break
      }
      case 'object': {
        parsedData = obj.dValue
        break
      }
    }
    return callback(true, parsedData)
  }
  return callback(false)
}

/**
 * Guarda los datos obtenidos en sesión
 * @param {String} dKey nombre de llave del dato
 * @param {any} dValue valor de la llave dato
 * @param {String} [typeStorage='sessionStorage'] [sessionStorage,localStorage]
 * @returns {Boolean}
 */
const saveData = (dKey, dValue, typeStorage = 'sessionStorage') => {
  if (!['sessionStorage', 'localStorage'].includes(typeStorage))
    throw new Error(`typeStorage invalid`)

  const dataType = typeof dValue
  const data = {
    dataType,
    dValue,
  }
  window[typeStorage].setItem(dKey, JSON.stringify(data))
  return true
}

export default {
  vloadData,
  saveData,
}
