<template>
  <grid-template>
    <NotificationsTemplate />
  </grid-template>
</template>

<script>
import NotificationsTemplate from '@/app/notifications/components/templates/NotificationsTemplate.vue'
import GridTemplate from '@/app/shared/components/templates/GridTemplate.vue'

export default {
  name: 'Notifications',
  components: {
    NotificationsTemplate,
    GridTemplate,
  },
}
</script>
