<template>
  <div class="button-whatsapp regular-14">
    <span v-if="type" class="anticon">
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="type === 'QUICK_REPLY'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.481 12L12 21.204v-5.332c3.31-.353 6.046.588 8.293 2.835L22 20.414V18c0-6.265-3.451-9.669-10-9.977V2.796L1.481 12zm3.038 0L10 7.204V10h1c5.2 0 8.05 1.851 8.799 5.716-2.543-1.701-5.549-2.271-8.963-1.702l-.836.139v2.643L4.519 12z" fill="currentColor"/></svg>
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="type === 'PHONE'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.857 8.338c.518-.698.703-1.434.117-2.084C9.66 4.414 8.775 3.277 8.22 2.73c-1.054-1.042-2.789-.901-3.702-.001-.49.483-.657.65-1.16 1.16C.551 6.697 2.262 12.63 6.81 17.184c4.548 4.552 10.482 6.264 13.293 3.45.465-.447.858-.84 1.169-1.169.895-.946 1.03-2.604-.006-3.681-.532-.553-1.617-1.394-3.534-2.766-.587-.525-1.284-.411-1.922.008-.307.201-.53.405-.953.827l-.766.767c-.1.1-1.471-.585-2.801-1.917C9.96 11.37 9.274 9.999 9.374 9.899l.767-.768c.134-.134.198-.198.28-.285.171-.18.313-.342.436-.508zm4.649 7.695l.766-.767c.232-.232.383-.375.505-.474 1.68 1.207 2.653 1.965 3.05 2.378.239.248.202.7-.006.92-.287.302-.66.675-1.117 1.117-1.818 1.819-6.608.437-10.479-3.438-3.872-3.876-5.253-8.666-3.449-10.471.502-.51.66-.669 1.144-1.145.182-.179.676-.219.896-.001.427.422 1.22 1.436 2.385 3.06-.06.074-.136.159-.228.255-.067.071-.123.128-.246.25l-.766.767c-1.303 1.303-.193 3.52 1.916 5.632 2.107 2.11 4.326 3.22 5.629 1.917z" fill="currentColor"/></svg>
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="type === 'URL'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.704 13.707l7.293-7.293V11h2V3h-8v2h4.586l-7.293 7.293 1.414 1.414zM19 19v-5h-2v5H5V7h5V5H5a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2z" fill="currentColor"/></svg>
    </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonWhatsApp',
  props: {
    type: {
      type: String,
      required: false,
      enum: ['quick-reply', 'phone', 'url'],
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
.button-whatsapp
  background-color: $gray_1
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))
  color: $gray_6
  border: 1px solid $gray_5
  border-radius: 6px
  padding: 1px 12px
  text-align: center
</style>
