<template>
  <div class="card-time-answer gray-dark-900">
    <h4 class="heading-h4">{{ nameline }}</h4>
    <p class="display-1 font-bold title-time">{{ time }} minutos</p>
    <p class="body-4 gray-dark-800 mrg-bottom-0">Promedio</p>
  </div>
</template>
<script>
export default {
  name: 'CardTimeAnswer',
  props: {
    nameline: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.font-bold
  font-weight: bold !important
.gray-dark-900
  color: $gray_dark_900
.gray-dark-800
  color: $gray_dark_800
.mrg-bottom-0
  margin-bottom: 0px
.card-time-answer
  background-color: $securitec_000
  padding: 54px
  margin: 50px 0
  border-radius: 8px
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  h4
    color: inherit
    margin-bottom: 20px
  .title-time
    margin-bottom: 12px
    line-height: 105%
</style>
