<template>
  <a-form
    :form="form"
    layout="vertical"
    @submit.prevent="handleSubmit"
    class="text--left"
  >
    <slot name="title">
      <h4 class="semibold-20">Visibilidad</h4>
    </slot>
    <p class="regular-14">
      Configura como y donde quieres que se muestre tu webchat.
    </p>
    <div class="form__item">
      <h6 class="semibold-14 text--left mrg-bottom-4">
        Dominio de tu página web
      </h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Ingresa las rutas de tu web en las que quieres que aparezca el widget
        del webchat. Por ejemplo, si deseas mostrar tu webchat en
        {{ `https://www.${transformWord(owner_app, 'lowercase')}.com/about` }}
        , deberás ingresar
        {{ `https://www.${transformWord(owner_app, 'lowercase')}.com` }} en este
        campo.
        <anchor
          :href="`${vari.WEB_DOC}webchat/configurar-webchat/`"
          title="Más información aquí"
        />
      </p>
      <!--Los decorators deben coincidir con las keys de los dominios del formulario-->
      <a-row
        v-for="(idDomain, index) in domains"
        :key="idDomain"
        type="flex"
        class="full-width flex-no-wrap"
      >
        <a-form-item class="flex-grow">
          <a-auto-complete
            v-decorator="[
              idDomain,
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor completa este campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se aceptan espacios vacios',
                  },
                  {
                    pattern: regexDomain,
                    message:
                      'No es un dominio válido, recuerda anteponer http o https',
                  },
                  {
                    validator: (rule, value, callback) =>
                      compareDomains(rule, value, callback, idDomain),
                  },
                ],
              },
            ]"
            :placeholder="`https://www.${transformWord(app, 'lowercase')}.com`"
            @change="(value) => handleChangeDomain(value, idDomain)"
            :maxLength="100"
            class="flex-grow"
          >
            <template slot="dataSource">
              <a-select-option
                v-for="domain in autoCompleteResult[idDomain]"
                :key="domain"
              >
                {{ domain }}
              </a-select-option>
            </template>
            <a-input>
              <a-popover
                title="Ingresa la URL donde deseas agregar tu Webchat"
                slot="suffix"
                :overlayStyle="{ width: '224px' }"
              >
                <template slot="content">
                  Recuerda lo siguiente:
                  <ul class="form__list">
                    <li>No es lo mismo anteponer en una url https y http</li>
                    <li>
                      Si ingresas o no www en tu URL podría hacer una gran
                      diferencia.
                    </li>
                  </ul>
                </template>
                <a-icon type="info-circle" style="color: rgba(0, 0, 0, 0.45)" />
              </a-popover>
            </a-input>
          </a-auto-complete>
        </a-form-item>
        <a :disabled="index === 0" @click="handleDeleteUrl(idDomain)">
          <a-icon type="delete" class="form__icon mrg-left-12 cursor-pointer"
        /></a>
      </a-row>
      <u v-if="allowAddUrl">
        <a @click="handleAddUrl" class="form__anchor"
          >+ Agregar nuevo dominio</a
        ></u
      >
    </div>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Móviles</h6>
      <div class="display-flex align--center">
        <p class="regular-14 mrg-bottom-0 text--left">
          Habilita esta opción si deseas mostrar tu widget en dispositivos
          móbiles.
        </p>
        <a-switch
          v-decorator="[
            'isResponsive',
            { valuePropName: 'checked', initialValue: true },
          ]"
          @change="hadleChangeResponsive"
        />
      </div>
    </a-form-item>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Posición en la web</h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Elige la opción que más se adecúe a la estructura de tu web.
      </p>
      <div class="cards__container display-flex flex-wrap">
        <card-position
          v-for="position in positions"
          :key="position.position"
          :position="position.position"
          :is-selected="positionSelected.position === position.position"
          @onChange="handleChangePosition(position)"
        />
      </div>
    </a-form-item>
  </a-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardPosition from '@/app/channels/components/webchat/cards/CardPosition'
import { isEqual } from '@/app/channels/utils/compare'
import transformMixin from '@/app/shared/mixins/transform'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'WebchatVisibility',
  components: {
    CardPosition,
    Anchor,
  },
  props: {
    hasMultiple: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    owner_app: process.env.VUE_APP_OWNER_APP,
    vari,
    positions: [
      { position: 'topLeft', value: 'TOP_LEFT' },
      { position: 'topRight', value: 'TOP_RIGHT' },
      { position: 'bottomRight', value: 'BOTTOM_RIGHT' },
      { position: 'bottomLeft', value: 'BOTTOM_LEFT' },
    ],
    positionSelected: { position: 'bottomRight', value: 'BOTTOM_RIGHT' },
    autoCompleteResult: {}, // para dominio
    urlsAllowed: [],
    originalValues: null,
    loadResponsive: false,
    domains: [],
    domainsRepeat: {},
    // eslint-disable-next-line no-useless-escape
    regexDomain: /^https?:\/\/[\w\-]+(\.\w+)*(:[0-9]+)?(\/)?$/m,
    validating: false,
    formValues: null, // usado solo para validar si hay algun cambio
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'normal',
      // onFieldsChange: (props, fields) => ,
    })
  },
  created() {
    this.handleAddUrl()
  },
  mixins: [transformMixin],
  computed: {
    ...mapGetters(['companyPricing', 'isPlanFree']),
    /**
     * Permitir agregar urls
     */
    allowAddUrl() {
      return !(this.domains.length === 10)
    },
  },
  methods: {
    ...mapActions(['updatedWebchat']),
    ...mapMutations(['SET_MODAL_UPGRADE']),

    /**
     * Valida que todos los campos esten correctos
     */
    validateFields() {
      this.form.validateFields(async (err, values) => {
        this.validating = true
        if (!err) {
          this.$emit('onLoading', true)

          if (!values.isResponsive) values.isResponsive = false
          values.position = this.positionSelected.value

          const updatedDomains = this.extractUrlsfromForm({
            values,
            onlyVals: true,
          })

          values.domains = this.formattedDomainsWithAction({
            originDomains: this.originalValues.domains
              ? this.convertDomainsStrToArray(this.originalValues.domains)
              : [],
            updatedDomains,
          })
          const response = await this.updatedWebchat({ newValues: values })
          if (response.success) {
            this.$emit('onSuccessSubmit', response.result)
            if (response.result) this.originalValues = response.result
          } else this.$message.error(response.details)
          this.$emit('onLoading', false)
        } else this.$emit('onLoading', false)
      })
    },
    /**
     * Submit de preferencias
     */
    handleSubmit() {
      this.$emit('onLoading', true)
      if (!this.validating) this.validateFields()
      else this.validating = false
      // verifica si aun no se ha ejecutado el validate
      setTimeout(() => {
        if (!this.validating) this.validateFields()
      }, 100)
    },
    /**
     * Cambio de posicion
     * @param {string} position
     */
    handleChangePosition(position) {
      if (this.isPlanFree) {
        return this.SET_MODAL_UPGRADE({ visible: true })
      }
      this.positionSelected = position
      this.hasNewValue({ field: { position: position.value } })
    },
    /**
     * Cambio de dominio
     * @param {string} value
     */
    handleChangeDomain(value, keyDomain) {
      const valuesExpected = 'http'
      let autoCompleteResult
      if (!value || value.includes(valuesExpected)) {
        autoCompleteResult = []
      } else {
        autoCompleteResult = ['.com', '.org', '.net', '.pe'].map(
          (domain) => `https://${value}${domain}`
        )
      }
      this.autoCompleteResult[keyDomain] = []
      this.autoCompleteResult[keyDomain] = autoCompleteResult
      this.hasNewValue({ field: { [keyDomain]: value } })
    },
    /**
     * Setea valores al formulario
     * @param {Object} newVals
     * @param {String} newVals.domain
     * @param {String} newVals.isResponsive
     * @param {String} newVals.position
     */
    handleSetValues(newVals) {
      if (!newVals) return
      this.originalValues = newVals
      if (newVals.position) {
        const newPosition = this.positions.find(
          (position) => position.value === newVals.position
        )
        if (newPosition) this.positionSelected = newPosition
      }
      // Si tiene la propiedad
      if (newVals.domains) {
        this.domains = []
        const arrDomains = [...this.convertDomainsStrToArray(newVals.domains)]
        // si tiene dominios
        if (arrDomains.length) {
          arrDomains.forEach((url, index) => {
            const date = new Date()
            const keyDomain = `url_${date.getTime()}${index}`
            this.domains.push(keyDomain)
            newVals[keyDomain] = url
            this.domainsRepeat[keyDomain] = {}
            this.domainsRepeat[keyDomain] = {
              before: {
                domains: [],
                value: url,
              },
              now: {
                domains: [],
                value: url,
              },
            }
          })
        }
      }
      setTimeout(() => this.form.setFieldsValue(newVals), 500)
    },
    /**
     * Agrega una url más
     */
    handleAddUrl() {
      if (this.domains.length === 10) return
      const date = new Date()
      this.domains.push(`url_${date.getTime()}`)
    },
    /*Resetea los valores*/
    handleResetValues() {
      this.positions = [
        { position: 'topLeft', value: 'TOP_LEFT' },
        { position: 'topRight', value: 'TOP_RIGHT' },
        { position: 'bottomRight', value: 'BOTTOM_RIGHT' },
        { position: 'bottomLeft', value: 'BOTTOM_LEFT' },
      ]
      this.positionSelected = {
        position: 'bottomRight',
        value: 'BOTTOM_RIGHT',
      }
      this.autoCompleteResult = {} // para dominio
      this.urlsAllowed = []
      this.handleAddUrl()
    },
    /**
     * Extrae las urls de los campos del formulario
     * @param {Object} values
     * @return {Object[]} urls
     */
    extractUrlsfromForm({ values, onlyKeys = false, onlyVals = false }) {
      const urls = []
      for (let property in values) {
        if (property.includes('url_')) {
          onlyKeys && !onlyVals && urls.push(property)
          onlyVals && !onlyKeys && urls.push(values[property])
          !onlyVals &&
            !onlyKeys &&
            urls.push({ key: property, val: values[property] })
        }
      }
      return urls
    },
    /**
     * Extrae las urls de los campos del formulario
     * @param {Object} values
     * @return {Object[]} urls
     */
    extractKeyUrlFromForm(values) {
      const keys = []
      for (let property in values) {
        property.includes('url_') && values[property] && keys.push(property)
      }
      return keys
    },
    /**
     * Checka si el valor de un campo ha cambiado con referencia al valor original
     * {title: 'this is my title'}
     * @param {Object} field
     * @param {String} field[fielName] - el nombre del campo
     * @param {String} field.fielName - el nuevo valor del campo
     * @param {Boolean} default
     */
    hasNewValue({ field, defaultValue = null }) {
      if (!this.originalValues) return
      if (!this.formValues) this.formValues = { ...this.form.getFieldsValue() }
      if (field) {
        const key = Object.keys(field)[0]
        if (!key) return
        this.formValues = { ...this.formValues, ...field }
      }
      let hasNew = null
      // si no hay valor por defecto
      if (defaultValue === null)
        hasNew = !isEqual({ ...this.formValues }, { ...this.originalValues })
      else {
        hasNew = defaultValue
      }
      this.$emit('onChangeValue', hasNew)
      return hasNew
    },
    /**
     * Cambio de responsive
     * @param {Boolean} isResponsive
     */
    hadleChangeResponsive(isResponsive) {
      if (this.isPlanFree) {
        // resetea el valor cambiado del switch
        setTimeout(() => this.form.setFieldsValue({ isResponsive: true }), 1000)
        return this.SET_MODAL_UPGRADE({ visible: true })
      }
      this.hasNewValue({ field: { isResponsive } })
    },
    /**
     * Eliminar una url
     * @param {Number} idDomain
     */
    handleDeleteUrl(idDomain) {
      this.domains = this.domains.filter((dom) => dom !== idDomain)
      // fuerza a los dominios repetidos a validarse, para que ya no se consideren como errores
      setTimeout(() => {
        this.form.validateFields(
          this.domainsRepeat[idDomain].now.domains.map((dom) => dom.key),
          {
            force: true,
          }
        )
        delete this.domainsRepeat[idDomain]
      }, 100)

      // Validar los nuevos valores, si este dominio se consideraba como un nuevo valor
      this.hasNewValue({ field: { [idDomain]: null } })
    },
    /**
     * Compara si los dominios se repiten
     * @param {Object} rule - regla para el formulario
     * @param {Number, String} value - valor del campo
     * @param {Function} callback - función que ejecuta el llamado de errores
     */
    compareDomains(rule, value, callback, idDomain) {
      if (!value || /\s/g.test(value) || !this.regexDomain.test(value)) {
        callback()
        return this.hasNewValue({ defaultValue: false })
      }
      const form = this.form
      // valores del formulario
      const formValues = { ...form.getFieldsValue() }
      // eliminar el dominio que esta siendo evaluado
      delete formValues[idDomain]
      const domains = this.extractUrlsfromForm({ values: formValues })
      const urlsRepeat = domains.filter((dom) => dom.val === value)
      // si el id del dominio no existe en los dominios repetidos, se inserta con el valor actual
      if (!this.domainsRepeat[idDomain]) {
        this.domainsRepeat[idDomain] = {
          now: {},
        }
      }
      this.domainsRepeat[idDomain].now = {
        val: value,
        domains: urlsRepeat,
      }
      // si existe en los dominios repetidos y no tiene un valor anterior
      if (
        !Object.prototype.hasOwnProperty.call(
          this.domainsRepeat[idDomain],
          'before'
        )
      ) {
        this.domainsRepeat[idDomain].before = {}
        this.domainsRepeat[idDomain].before = {
          val: value,
          domains: [],
        }
      }
      // si el valor que se esta ingresando esta en los dominios
      const domainsFormatted = domains.map((dom) => {
        return this.removeLastSlash(dom.val)
      })
      if (domainsFormatted.includes(this.removeLastSlash(value))) {
        callback('Este dominio ya está repetido.')
        urlsRepeat.forEach((urlRepeat) => {
          this.domainsRepeat[urlRepeat.key].before.domains.push({
            key: idDomain,
            val: value,
          })
        })
        this.hasNewValue({ defaultValue: false })
      } else if (
        this.domainsRepeat[idDomain].before.val !==
        this.domainsRepeat[idDomain].now.val
      ) {
        form.validateFields(
          this.domainsRepeat[idDomain].before.domains.map((dom) => dom.key),
          {
            force: true,
          }
        )
        this.domainsRepeat[idDomain].before = {
          val: value,
          domains: [],
        }
        this.domainsRepeat[idDomain].now = {
          val: value,
          domains: urlsRepeat,
        }
      } else {
        callback()
      }
    },
    /**
     * Verifica y formatea de acuerdo a si el dominio es nuevo o se ha eliminado tomando como referencia a los dominios originales
     * @param {String[]} originDomains - array de los dominios originales
     * @param {String[]} updatedDomains - array de los dominios actualizados
     * @return {Object[]}
     */
    formattedDomainsWithAction({ originDomains = [], updatedDomains = [] }) {
      let formattedDomains = []
      // cuales son los dominios orignales que no estan en los dominios actualizados -> estos seran los que tengan la action: remove
      const removeDomains = originDomains.filter(
        (originDomain) => !updatedDomains.includes(originDomain)
      )
      formattedDomains = removeDomains.map((removeDomain) => {
        const newObj = {
          url: removeDomain,
          action: 'remove',
        }
        return newObj
      })
      // si un dominio de los updated ya se encontraba en los orignales ya no se manda
      const addDomains = updatedDomains.filter(
        (updatedDomain) => !originDomains.includes(updatedDomain)
      )
      // todos los dominios restantes seran los que tengan la action: add
      addDomains.forEach((addDomain) => {
        const newObj = {
          url: addDomain,
          action: 'add',
        }
        formattedDomains.push(newObj)
      })
      // retorna el nuevo array con acciones
      return formattedDomains
    },
    /**
     * Convierte los dominios string a un array
     * @param {String} domains - "https://ticker.pe,https://ticker.com"
     */
    convertDomainsStrToArray(domains) {
      const arrDomains = domains.split(',')
      return arrDomains.map((domain) => domain.trim())
    },
    /**
     * Remover el ultimo slash si lo encuentra
     * @param {String} str
     * @return {String}
     */
    removeLastSlash(str) {
      const arrStrs = str.split('')
      const lastItem = [...arrStrs].pop()
      if (!lastItem.length || lastItem === '/')
        return str.substring(0, str.length - 1)
      return str
    },
  },
}
</script>

<style lang="sass" scoped>
.form__icon
  font-size: 20px
  margin-top: 5px
.form__item
  margin-bottom: 32px
.form__list
  padding-left: 20px
</style>
