/**
 * Crea una promesa a partir de una función
 * @param {FUnction} callback
 * @returns
 */
const promisify = (callback) => {
  return new Promise((resolve, reject) => {
    try {
      callback()
      resolve('success')
    } catch (err) {
      reject('error callback', err)
    }
  })
}

export { promisify }
