const COLORS_TAGS = [
  '#AFB1B7',
  '#C13131',
  '#5F233F',
  '#C17529',
  '#FFDB01',
  '#FDBD42',
  '#32C38C',
  '#1ABEC7',
  '#73B9D7',
  '#FB3611',
  '#5F4C8C',
  '#001A3D',
  '#96B2A6',
]

export { COLORS_TAGS }
