<template>
  <div class="contact">
    <ContactTemplate />
  </div>
</template>

<script>
import ContactTemplate from '@/app/contacts/components/templates/ContactTemplate.vue'

export default {
  name: 'Contact',
  components: {
    ContactTemplate,
  },
}
</script>
