<template>
  <div class="line-list">
    <a-table
      class="tickets-table table-scroll"
      :data-source="ticketsWithAlias"
      :pagination="pagination"
      :loading="loading"
      rowKey="_id"
      :locale="localeTable"
      :columns="selectedColumns"
      @change="handleTableChange"
      :row-selection="rowSelection"
      :scroll="{ x: '90vw', y: 'calc(100vh - 260px)' }"
      size="middle"
    >
      <template slot="ticket" slot-scope="ticket">
        <span> {{ ticketNumberFormatted(ticket) }} </span>
      </template>

      <!-- Columna de Contacto -->
      <template slot="client" slot-scope="client, record">
        <a-row class="client" type="flex">
          <template v-if="client">
            <a-avatar
              v-if="client.avatar"
              :src="client.avatar"
              :size="24"
              class="person__image"
            />
            <a-avatar v-else :size="24" class="person__image" icon="user" />

            <a
              v-if="record.locked"
              class="person__name"
              :disabled="record.locked"
              >Ticket restringido</a
            >
            <template v-else>
              <a
                v-if="(client.names || '') + (client.surnames || '')"
                class="person__name"
                @click="$emit('seeClient', client)"
              >
                {{ (client.names || '') + ' ' + (client.surnames || '') }}
              </a>
              <a
                @click="$emit('seeClient', client)"
                v-else
                class="person__name--alternative"
              >
                Sin nombre
              </a>
            </template>
          </template>
        </a-row>
      </template>
      <!-- Datos adicionales de contacto -->
      <template slot="client_email" slot-scope="client_email">
        <a-row type="flex">
          <span>{{ client_email }}</span>
        </a-row>
      </template>
      <template slot="client_documentType" slot-scope="client_documentType">
        <a-row type="flex">
          <span>{{ client_documentType }}</span>
        </a-row>
      </template>
      <template slot="client_documentNumber" slot-scope="client_documentNumber">
        <a-row type="flex">
          <span>{{ client_documentNumber }}</span>
        </a-row>
      </template>
      <template slot="client_city" slot-scope="client_city">
        <a-row type="flex">
          <span>{{ client_city }}</span>
        </a-row>
      </template>
      <template slot="client_address" slot-scope="client_address">
        <a-row type="flex">
          <span>{{ client_address }}</span>
        </a-row>
      </template>
      <template slot="client_birth" slot-scope="client_birth">
        <a-row type="flex">
          <span>{{
            client_birth ? moment(client_birth).format('MMMM DD YYYY') : ''
          }}</span>
        </a-row>
      </template>
      <template slot="client_gender" slot-scope="client_gender">
        <a-row type="flex">
          <span>{{ client_gender ? transforGender(client_gender) : '' }}</span>
        </a-row>
      </template>
      <template slot="client_remark" slot-scope="client_remark">
        <a-row type="flex">
          <span>{{ client_remark }}</span>
        </a-row>
      </template>
      <!-- Columna de Agente -->
      <template slot="agent" slot-scope="agent">
        <a-row type="flex">
          <template v-if="agent">
            <img
              v-if="agent.avatar"
              class="person__image"
              :src="agent.avatar"
            />
            <a-avatar
              v-else-if="agent.subType === 'chatbot'"
              src="/img/bot/bot.png"
              class="person__image"
            />
            <default-icon-letter
              v-else
              class="person__image"
              :names="agent.names"
              :size="24"
            />
            <p class="person__name mrg-bottom-0">
              {{ (agent.names || '') + ' ' + (agent.surnames || '') }}
            </p>
          </template>
          <template v-else>
            <!-- Ya no deberia entrar aqui ya que si no hay agente en la mutacion se setea un objeto con el nombre Sin agente -->
            <default-icon-letter class="person__image" names="Sin" :size="24" />
            <span class="person__name--alternative"> Sin agente </span>
          </template>
        </a-row>
      </template>

      <template slot="status" slot-scope="status, ticket">
        <!-- [queued, issued, opened, ended] -->
        <a-row type="flex" align="middle">
          <a-tag
            v-if="STATUSES_TICKETS_OBJECT[status]"
            :color="STATUSES_TICKETS_OBJECT[status].color"
            >{{ STATUSES_TICKETS_OBJECT[status].name }}</a-tag
          >
          <template v-if="!ticket.channel.hide">
            <a-tag color="green" v-if="ticket.pendings == 0"> Leído </a-tag>
            <a-tag v-if="ticket.pendings > 0"> Sin leer </a-tag>
          </template>
        </a-row>
      </template>
      <!--Estado de la primera plantilla enviada-->
      <template slot="status_template" slot-scope="status_template">
        <a-row type="flex" align="middle">
          <a-tag
            v-if="statusTemplate(status_template)"
            :color="statusTemplate(status_template).color"
          >
            {{ statusTemplate(status_template).name }}
          </a-tag>
          <span v-else>-</span>
        </a-row>
      </template>
      <template slot="channel" slot-scope="channel, record">
        <social-icon :type="channel.type" size="24" :disabled="record.locked" />
      </template>

      <template slot="channelCompanyId" slot-scope="channel, record">
        <span>{{
          record.channel.title + record.channel.alias ||
          record.channel.phoneTo ||
          record.channel.apiBusinessId
        }}</span>
      </template>

      <template slot="created" slot-scope="created_at">
        {{ formatDate(created_at) }}
      </template>

      <template slot="favorite" slot-scope="starred">
        <a-row type="flex" justify="center" align="middle">
          <a-icon
            class="star"
            type="star"
            theme="filled"
            :style="`font-size: 20px ;${
              starred ? 'color: #f8e479;' : 'color: #E8E8E8;'
            }`"
          />
        </a-row>
      </template>

      <template slot="mailbox" slot-scope="mailbox">
        <span>{{ getMailbox(mailbox).title }}</span>
      </template>

      <template slot="phone" slot-scope="phone, record">
        <a v-if="record.locked" :aria-disabled="true" :disabled="true">
          Ticket restringido
        </a>
        <span v-else>{{ phone }}</span>
      </template>
      <template slot="productsAssigned" slot-scope="productsAssigned, record">
        <a
          v-if="!record.productsAssigned"
          :aria-disabled="true"
          :disabled="true"
          >{{ record.productsAssigned }} productos</a
        >
        <a v-else @click="handleOpenDrawerProducts(record)">
          {{ record.productsAssigned }}
          {{ record.productsAssigned === 1 ? 'producto' : 'productos' }}</a
        >
      </template>
      <template slot="action" slot-scope="text, record">
        <a-row
          class="actions"
          type="flex"
          align="middle"
          justify="space-around"
        >
          <!-- Contenido del ticket -->
          <a-tooltip title="Ver chat">
            <a :disabled="record.locked">
              <a-icon
                class="action-icon"
                type="eye"
                @click="onSeeTicket(record)"
              />
            </a>
          </a-tooltip>
          <!-- TRansferir ticket -->
          <a-dropdown :trigger="['click']" placement="bottomLeft">
            <a-tooltip>
              <template slot="title"> Transferir </template>
              <a :disabled="record.status == 'ended' || record.locked">
                <a-icon class="action-icon" type="swap" />
              </a>
            </a-tooltip>
            <!-- menú -->
            <a-menu slot="overlay">
              <!-- Transferencia única a agente -->
              <a-menu-item key="0">
                <a @click="onTransferTicketToAgent(record)"
                  >Transferir a un agente</a
                >
              </a-menu-item>
              <!-- Transferencia única a Cola -->
              <a-sub-menu
                style="
                  max-height: 200px;
                  overflow-y: auto;
                  background-clip: initial;
                "
                class="scrollbar_basic"
                key="1"
              >
                <span slot="title"><span>Transferir a cola</span></span>
                <a-menu-item
                  v-for="(line, index) in companyLinesAvailable(record)"
                  :key="`item${index}`"
                  @click="onTransferTicketToLine(record, line)"
                >
                  {{ line.name }}
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-dropdown>
          <!-- Editar ticket -->
          <a-tooltip title="Editar ticket">
            <a
              :disabled="['ended'].includes(record.subStatus) || record.locked"
            >
              <a-icon
                type="edit"
                class="editable-cell-icon"
                @click="$emit('editTicket', record)"
              />
            </a>
          </a-tooltip>
          <!-- Finalizar ticket -->
          <a-tooltip title="Resolver ticket">
            <a
              :disabled="
                status_to_prevent_resolve_tickets.includes(record.subStatus) ||
                record.locked
              "
            >
              <a-icon
                class="action-icon"
                type="check"
                @click="onResolveTicket(record)"
              />
            </a>
          </a-tooltip>
        </a-row>
      </template>
    </a-table>
    <!--Modal agentes-->
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapActions, mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import EditableCustomFieldColumn from './EditableCustomFieldColumn.vue'
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import transformWordGender from '@/app/shared/mixins/transform'
import compareMixin from '@/app/shared/mixins/compare'
import attemptMixin from '@/app/shared/mixins/attempt'
import format from '@/app/shared/mixins/format'
import moment from 'moment'
import support from '@/app/shared/utils/support'
import {
  COLUMNS_TABLE_TICKETS,
  STATUSES_TEMPLATE,
  STATUSES_TICKETS_OBJECT,
} from '@/app/manageTickets/utils/ticketsUtil'

export default {
  name: 'TicketsTable',
  mixins: [transformWordGender, compareMixin, format, attemptMixin],
  props: {
    tickets: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    columnsToShow: {
      type: Array,
      required: true,
    },
    showGhost: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    DefaultIconLetter,
    EditableCustomFieldColumn,
    SocialIcon,
  },

  data: () => ({
    moment: moment,
    STATUSES_TICKETS_OBJECT,
    currentPage: 1,
    localeTable: {
      emptyText: 'No se han encontrado tickets',
    },
    nameLine: '',
    columns: {},
    selectedRowKeys: [], // keys de las filas seleccionadas
    customFieldsLoaded: false,
    customClientFieldsLoaded: false,
  }),
  created() {
    this.columns = { ...COLUMNS_TABLE_TICKETS }
    this.columns['tags'] = {
      title: 'Etiquetas',
      dataIndex: 'settings.tags',
      key: 'tags',
      width: '217px',
      customRender: (value, record) =>
        this.renderCustomField(value, record, {
          tags: this.tagsToSelect,
          update: this.updateTags,
        }),
    }

    this.listCustomFieldColumns({
      propertyCustomFields: 'company_custom_fields_tickets',
      propertyLoaded: 'customFieldsLoaded',
    })
    this.listCustomFieldColumns({
      propertyCustomFields: 'company_custom_fields_clients',
      dataIndex: 'client.fields.',
      propertyLoaded: 'customClientFieldsLoaded',
      searchPropertyInList: true,
    })
  },
  watch: {
    reloadTableLines() {
      this.handleTableChange({ current: this.currentPage })
    },
    company_custom_fields_tickets() {
      if (this.customFieldsLoaded) return
      this.listCustomFieldColumns({
        propertyCustomFields: 'company_custom_fields_tickets',
        propertyLoaded: 'customFieldsLoaded',
      })
    },
    company_custom_fields_clients() {
      if (this.customClientFieldsLoaded) return
      this.listCustomFieldColumns({
        propertyCustomFields: 'company_custom_fields_clients',
        dataIndex: 'client.fields.',
        propertyLoaded: 'customClientFieldsLoaded',
        searchPropertyInList: true,
      })
    },
  },
  computed: {
    ...mapGetters([
      'lines',
      'companyLines',
      'accounts',
      'profile',
      'reloadTableLines',
      'agentsAccounts',
      'company',
      'roles',
      'company_custom_fields_tickets',
      'simple_tags',
      'mailboxes',
      'modules_pagination',
      'simple_company_channels',
      'status_to_prevent_resolve_tickets',
      'company_custom_fields_clients',
    ]),

    /**
     * @return {Object} paginacion de la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: this.modules_pagination.manage_tickets,
        size: 'default',
        total: this.tickets.total || 0,
        current: this.page,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'tickets encontrados' : 'ticket encontrado'}
          </span>
        ),
      }
    },
    tagsToSelect() {
      if (!this.company) return []
      let toSelect = []
      toSelect = this.simple_tags.map((tag) => ({
        title: tag.title,
        key: tag._id,
        color: tag.color,
        tagId: tag._id,
      }))
      return toSelect
    },
    rowSelection() {
      return {
        columnWidth: '40px',
        fixed: true,
        selectedRowKeys: this.selectedRowKeys,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.locked, // Column configuration not to be checked
          },
        }),
        onChange: (_, selectedRows) => this.onSelectedChange(selectedRows),
      }
    },
    /**
     * @return {Aray} Array de tickets luego de agregar alias a sus canales
     *
     */
    ticketsWithAlias() {
      const channels = this.simple_company_channels

      this.tickets.data.forEach((data) => {
        const alias = support.channelCompanyAlias(
          data.channelCompanyId,
          channels
        )
        data.channel.alias = alias ? ' ( ' + alias + '  )' : ''

        // se formatea los campos personalizados del cliente
        if (
          data.client &&
          data.client.settings &&
          data.client.settings.customFields
        ) {
          const clientCustomFields = data.client.settings.customFields
          const newClientCustomFields = new Object()
          clientCustomFields.forEach((clientCustomField) => {
            newClientCustomFields[clientCustomField.customFieldId] =
              clientCustomField.value
          })
          data.client.fields = newClientCustomFields
        }
      })
      return this.tickets.data
    },
    /**
     * Columnas seleccionas para la tabla
     * @return {Object[]} selectedColumns
     * @return {String} selectedColumns.title
     * @return {String} selectedColumns.dataIndex
     * @return {Object} selectedColumns.scopedSlots
     * @return {String} selectedColumns.key
     */
    selectedColumns() {
      let selectedColumns = []
      const keysColumnsToShow = this.columnsToShow.map((column) => column.key)

      const keysColumns = Object.keys(this.columns)
      const arrayColumns = [...keysColumns].map((columnId) => {
        this.columns[columnId]._id = columnId
        return this.columns[columnId]
      })

      selectedColumns = [...arrayColumns].filter(
        (column) =>
          (column._id === 'status_template' && this.showGhost) ||
          keysColumnsToShow.includes(column._id) ||
          column.default
      )
      selectedColumns = selectedColumns.filter((column) => !column.isLast)

      const lastItem = arrayColumns.find((column) => column.isLast)
      selectedColumns.push(lastItem)
      return selectedColumns
    },
  },
  methods: {
    ...mapActions([
      'removeSupervisor',
      'removeAgent',
      'getLines',
      'updateAdminTicketTags',
      'updateAdminStarred',
    ]),
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleTableChange(pagination) {
      this.$emit('paginate', pagination.current)
    },
    formatDate(created_at) {
      const formatedDate = moment(created_at).format('MMMM DD YYYY, h:mm:ss a')
      return formatedDate[0].toUpperCase() + formatedDate.slice(1)
    },
    /**
     * Lista todos los campos personalizados en las columnas
     */
    listCustomFieldColumns({
      propertyCustomFields,
      dataIndex = 'fields.',
      propertyLoaded,
      searchPropertyInList = false,
    }) {
      if (this[propertyCustomFields].length > 0) this[propertyLoaded] = true

      this[propertyCustomFields].map((customField) => {
        this.columns[customField._id] = {
          dataIndex: dataIndex + customField._id,
          title: customField.name,
          key: customField._id,
          width: '150px',
          customRender: (value, record) => {
            let typeValue = 'text'
            switch (customField.type) {
              case 'dropdown':
                typeValue = 'options'
                break
              case 'date':
                typeValue = 'date'
                break
              case 'text':
                typeValue = 'text'
            }
            return this.renderCustomField(
              searchPropertyInList && value ? value[typeValue] : value,
              record,
              {
                ...customField,
              }
            )
          },
        }
      })
    },
    /**
     * Renderiza el campo en la celda de la tabla
     * @param {String} value
     * @param {Object} ticket
     * @param {Object} config
     */
    renderCustomField(value, ticket, config) {
      if (this.loading) return
      else
        return (
          <editable-custom-field-column
            customField={value}
            ticket={ticket}
            config={config}
          />
        )
    },
    onTransferTicketToAgent(ticket) {
      this.$emit('transferAgent', ticket)
    },
    onTransferTicketToLine(ticket, line) {
      this.$confirm({
        title: `¿Está seguro(a) de transferir el ticket a la cola "${line.name}"?`,
        content:
          'Recuerda que los tickets serán derivados y repartidos en la cola seleccionada ',
        okText: 'Transferir',
        cancelText: 'Cancelar',
        onOk: () => {
          this.$emit('transferLine', ticket, line)
        },
      })
    },
    getMailbox(mailboxId) {
      return (
        this.mailboxes.find((mailbox) => mailbox.idMailbox == mailboxId) || {}
      )
    },
    onResolveTicket(ticket) {
      this.$emit('resolveTicket', ticket, { massive: false })
    },
    onSeeTicket(ticket) {
      if (ticket.locked) return
      this.$emit('seeTicket', ticket)
    },
    /**
     * Escucha la selección de filas en la tabla
     * @param {Object[]} selectedRows
     * @param {String} selectedRows[]._id
     * @param {String} selectedRows[].status
     * @param {Object} selectedRows[].line
     * @param {String} selectedRows[].line._id
     * @param {Object} selectedRows[].agent
     * @param {String} selectedRows[].agent.userId
     */
    onSelectedChange(selectedRows) {
      // obtiene solo las ids de las filas
      const selectedRowKeys = selectedRows.map((row) => row._id)
      this.selectedRowKeys = selectedRowKeys

      this.$emit('selectedRows', selectedRows)
    },
    /**
     * Colas disponibles de la empresa que cuenten con el canal seleccionado
     * @param {Object} record
     * @param {Object} record.channel
     * @param {String} record.channel.type
     * @return {Object[]}
     */
    companyLinesAvailable(record) {
      if (!this.companyLines.length) return []
      return this.companyLines.filter((line) => {
        if (!line.channels_companies) return false
        else
          return line.channels_companies.some(
            (channels_company) =>
              channels_company._id === record.channelCompanyId
          )
      })
    },
    handleOpenDrawerProducts(record) {
      this.$emit('onShowProducts', record._id)
    },
    /**
     * Estado de plantilla
     * @param {String} statusTemplate - [queued, sent, delivered, read, failed]
     */
    statusTemplate(statusTemplate) {
      return STATUSES_TEMPLATE.find((status) => status._id === statusTemplate)
    },
  },
}
</script>
<style lang="sass" scoped>
.person__image
  border-radius: 50%
  margin-right: 8px
  height: 24px
  width: 24px
  cursor: auto!important
.person__name--alternative
  font-style: italic
.tag
  max-width: 24px
  min-width: 24px
  height: 8px
  border-radius: 8px
  margin-right: 4px
  margin-top: 4px
  flex-grow: 1
.actions
  max-width: 80%
.action-icon
  font-size: 16px
.person__name
  max-width: 200px
  text-overflow: ellipsis
  overflow: hidden
</style>

<style lang="sass">
.ant-dropdown-menu-submenu-content
  max-height: 100vh
  overflow: scroll
  &::-webkit-scrollbar
    width: 10px
  &::-webkit-scrollbar-track
    background: transparent
    border-left: solid 5px transparent
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    box-shadow: inset 0 0 10px 10px $gray_dark_100
    border: solid 3px transparent
  &::-webkit-scrollbar-thumb:hover
    box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
