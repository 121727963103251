<template>
  <span class="wait-points-animation">
    <slot></slot><span class="points">...</span>
  </span>
</template>

<script>
export default {
  name: 'WaitPointsAnimation',
}
</script>

<style lang="sass" scoped>
.wait-points-animation
  .points
    background: linear-gradient(to right, white 50%, black 50%)
    color: transparent
    -webkit-background-clip: text
    -webkit-animation: dots 1s infinite steps(6)
    padding-right: 30px
    margin-right: -30px
  @-webkit-keyframes dots
    0%
      background-position: 0px
    100%
      background-position: 50px
</style>
