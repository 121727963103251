<template>
  <div>
    <a-form :form="form" layout="vertical">
      <a-alert
        v-if="request && alertObj"
        :type="alertObj.type"
        class="form__alert"
      >
        <p slot="description" class="body-1 mrg-bottom-0 alert_description">
          {{ alertObj.description }}
        </p>
      </a-alert>
      <a-row type="flex" align="middle" class="contain-avatar">
        <a-upload
          v-if="request && broadcast.editable"
          class="upload"
          name="avatar"
          :multiple="false"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          :customRequest="selfUpload"
        >
          <img
            :src="avatar"
            height="64px"
            width="64px"
            alt="Avatar"
            class="upload_img"
          />
          <div class="overlay" :class="{ 'opacity-1': loading }">
            <a-icon
              v-if="!loading"
              type="edit"
              style="font-size: 20px; color: white"
            />
            <a-icon
              v-else
              type="loading"
              style="font-size: 20px; color: white"
            />
          </div>
        </a-upload>
        <img
          v-else
          :src="avatar"
          height="64px"
          width="64px"
          alt="Avatar"
          class="upload_img"
        />
        <span class="text">
          <template v-if="request">
            <p class="heading-h8 mrg-bottom-0">Sube aquí tu nuevo avatar</p>
            <p class="body-1 mrg-bottom-0">
              El tamaño máximo permitido es de 5MB
            </p>
            <p class="body-1 mrg-bottom-0">
              Las dimensiones ideales son 640px x 640px
            </p>
          </template>
        </span>
      </a-row>
      <a-form-item label="Descripción">
        <a-textarea
          v-decorator="[
            'whatsapp_description',
            {
              rules: [
                {
                  required: false,
                  message: 'Este campo es requerido',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @input="handleSetCurrentDescription"
          :auto-size="{ minRows: 2, maxRows: 4 }"
          :maxLength="maxDescription"
          :disabled="!broadcast.editable"
        />
        <span class="counter"
          >{{ currentDescription ? currentDescription.length : 0 }}/{{
            maxDescription
          }}</span
        >
      </a-form-item>
      <a-form-item label="Dirección">
        <a-input
          v-decorator="[
            'whatsapp_address',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :disabled="!broadcast.editable"
          @input="
            (e) =>
              handleEmitChange({ property: 'address', value: e.target.value })
          "
        />
      </a-form-item>
      <a-row :gutter="[12]">
        <a-col :span="12">
          <a-form-item label="País">
            <a-select
              show-search
              v-decorator="[
                'whatsapp_country',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              placeholder="Selecciona"
              :disabled="!broadcast.editable"
            >
              <a-select-option
                v-for="country in countriesNotRepeatByName"
                :key="country.nameSpanish"
              >
                {{ country.nameSpanish }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Ciudad">
            <a-input
              v-decorator="[
                'whatsapp_city',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor completa este campo',
                    },
                  ],
                },
              ]"
              placeholder="Escribe aquí..."
              :disabled="!broadcast.editable"
              @input="
                (e) =>
                  handleEmitChange({ property: 'city', value: e.target.value })
              "
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="Código postal">
        <a-input
          v-decorator="[
            'whatsapp_zipcode',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :disabled="!broadcast.editable"
          @input="
            (e) =>
              handleEmitChange({ property: 'zipcode', value: e.target.value })
          "
        />
      </a-form-item>
      <a-form-item label="Correo electrónico" v-if="broadcast.email">
        <a-input
          v-decorator="[
            'whatsapp_email',
            {
              rules: [
                {
                  required: false,
                  message: 'Este campo es requerido',
                },
                { type: 'email', message: 'No es un email válido' },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :disabled="!broadcast.editable"
          @input="
            (e) =>
              handleEmitChange({ property: 'email', value: e.target.value })
          "
        />
      </a-form-item>
      <a-form-item label="Página web">
        <a-input
          v-decorator="[
            'whatsapp_website',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :disabled="!broadcast.editable"
          @input="
            (e) =>
              handleEmitChange({ property: 'website', value: e.target.value })
          "
        />
      </a-form-item>
      <a-form-item label="Información">
        <a-textarea
          v-decorator="[
            'whatsapp_about',
            {
              rules: [
                {
                  required: false,
                  message: 'Este campo es requerido',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @input="handleSetCurrentAbout"
          :auto-size="{ minRows: 2, maxRows: 4 }"
          :maxLength="maxAbout"
          :disabled="!broadcast.editable"
        />
        <span class="counter"
          >{{ currentAbout ? currentAbout.length : 0 }}/{{ maxAbout }}</span
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BeexLogo from '@/app/channels/assets/beex-logo-52x52.png'
import SecuritecLogo from '@/app/channels/assets/securitec-full-logo.png'
import TwilioLogo from '@/app/channels/assets/twilio-full-logo.png'
import TedexisLogo from '@/app/channels/assets/tedexis-full-logo-dark.png'
import vari from '@/app/shared/utils/variables'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'FormProfileWba',
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
    supplier: {
      type: String,
      required: true,
    },
    request: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    loading: false,
    avatar: '',
    fileImage: null,
    defaultAvatar: false,
    wspbusiness: '',
    currentDescription: '',
    maxDescription: 256,
    currentAbout: '',
    maxAbout: 139,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-details-provider' })
  },
  mounted() {
    setTimeout(() => {
      this.setFieldsValue()
    }, 100)
  },
  mixins: [transformMixin],
  watch: {
    visible() {
      setTimeout(() => {
        this.setFieldsValue()
      }, 100)
    },
  },
  computed: {
    ...mapGetters(['industries', 'languages', 'countriesNotRepeatByName']),

    alertObj() {
      const broadcast = this.broadcast
      if (broadcast) {
        if (broadcast.status === 'published') {
          return {
            type: 'info',
            description: `Ten en cuenta que los datos que edites se enviarán como solicitud a ${this.transformNameProvider(
              this.supplier
            )} para que los modifique. Mientras tanto, tus datos seguirán siendo los mismos.`,
          }
        } else if (broadcast.status === 'changing') {
          return {
            type: 'warning',
            description:
              'Hay una solicitud pendiente de cambio, cuando se efectue se volverán a habilitar los campos de edición.',
          }
        }
      }
      return null
    },
  },
  methods: {
    setFieldsValue() {
      if (Object.keys(this.broadcast).length > 0) {
        const broadcast = this.broadcast
        this.form.setFieldsValue({
          whatsapp_description: broadcast.description,
          whatsapp_about: broadcast.about,
          whatsapp_email: broadcast.email,
          whatsapp_country: broadcast.country,
          whatsapp_city: broadcast.city,
          whatsapp_address: broadcast.address,
          whatsapp_website: broadcast.website,
          whatsapp_zipcode: broadcast.zipcode,
        })
        this.avatar =
          this.broadcast && this.broadcast.avatar
            ? this.broadcast.avatar
            : this.imgSupplier(this.supplier, this.request)
        this.currentDescription = broadcast.description
        this.currentAbout = broadcast.about
      }
    },
    /**
     * Retorna el icono de  cada proveedor
     * @param {String} supplier nombre de proveedor
     * @param {Boolean} request si es proveedor
     * @return {Component} image
     */
    imgSupplier(supplier, request) {
      // si es default
      if (request) supplier = 'beex'
      if (vari.OWNER_APP === 'Tedexis') supplier = 'tedexis'

      switch (supplier) {
        case 'Twilio':
          return TwilioLogo
        case 'beex':
          return BeexLogo
        case 'securitec':
          return SecuritecLogo
        case 'tedexis':
          return TedexisLogo
        default:
          return SecuritecLogo
      }
    },
    /** */
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png, jpeg o jpg')
      }
      const isLt5M = file.size / 1024 / 1024 <= 5
      if (!isLt5M) {
        this.$message.error('El tamaño máximo permitido es de 2MB')
      }
      return isJpgOrPng && isLt5M
    },
    /** */
    selfUpload({ action, file, onSuccess, onError, onProgress }) {
      console.log(action, file, onSuccess, onError, onProgress)
      this.loading = true
      const base64 = new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.avatar = fileReader.result
          this.defaultAvatar = false
          resolve(fileReader.result)
          this.loading = false
          this.newAvatar = true
          this.handleEmitChange({ property: 'avatar', value: this.avatar })
        }
      })
      console.log('base 64', base64)
    },
    handleSetCurrentDescription(e) {
      this.currentDescription = e.target.value
      this.handleEmitChange({
        property: 'description',
        value: this.currentDescription,
      })
    },
    handleSetCurrentAbout(e) {
      this.currentAbout = e.target.value
      this.handleEmitChange({ property: 'about', value: this.currentAbout })
    },
    handleEmitChange({ property, value }) {
      this.$emit('onChangeProfile', { property, value })
    },
  },
}
</script>

<style lang="sass" scoped>
.opacity-1
  opacity: 1!important

.contain-avatar
  margin-bottom: 16px
  .text
    margin-left: 12px
.upload
  position: relative
  cursor: pointer
  &_img
    border: 1px solid $gray_5
    object-fit: cover
    border-radius: 50px
  .overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    opacity: 0
    transition: .3s ease
    background: rgba(0, 0, 0, 0.4)
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
  &:hover
    .overlay
      opacity: 1
.counter
  position: absolute !important
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
.form__alert
  margin-bottom: 20px
</style>
