import vari from '@/app/shared/utils/variables'
import axios from 'axios'
import format from '@/app/shared/utils/format'
import compare from '@/app/shared/utils/compare'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)
const actions = {
  async getAllTicketsToManage(
    context,
    {
      idMailbox,
      search,
      page,
      dateRange,
      status,
      read,
      line,
      channel,
      tags,
      order,
      customFields,
      favorite,
      ticketStatuses,
      clientsId,
      agent,
      products,
      strategies,
      channelCompanyId = 'all',
      isGhost = false,
      lastStatusFirstTemplate = [],
    }
  ) {
    try {
      const response = await axios.post(`${vari.UHR}/admin/tickets/searches`, {
        idMailbox,
        search,
        page,
        dateRange: dateRange && dateRange.length > 0 ? dateRange : null,
        status,
        read,
        line,
        channel,
        tags,
        order,
        customFields,
        favorite,
        ticketStatuses: [...ticketStatuses, 'unanswered'],
        clientsId,
        agent,
        products,
        strategies,
        channelCompanyId,
        isGhost,
        lastStatusFirstTemplate,
      })
      context.commit('SET_TICKETS_TO_MANAGE', response.data)
    } catch (error) {
      console.error('getAllTicketsToManage', error)
    }
  },
  /**
   * @param {*} context
   * @param {Object} args
   * @param {Object} args.agent
   * @param {String} args.agent.userId
   * @param {String} args.agent.name
   * @param {String} args.agent.surnames
   * @param {String} args.agent._id
   * @param {Object} args.ticket
   * @param {String} args.ticket._id
   * @param {String} args.ticket.ticket
   */
  async updateAgentInTicketsToManage(context, { agent, ticket }) {
    try {
      if (ticket) {
        const ticketToManage = context.getters.ticketsToManage.data.find(
          (ticketStore) => ticketStore._id === ticket._id
        )
        if (ticketToManage) {
          // si el rol del usuario es solo agente
          // y el usuario al que se le transferirá es diferente al de la sessión actual
          const isAgent = await compare.isAllowedFor(context, ['agent'], {
            literal: true,
          })
          const isCurrent = context.getters.profile.userId === agent.userId

          if (isAgent && !isCurrent)
            context.commit('REMOVE_MANAGE_TICKET', ticket._id)

          // si el usuario al que se le transferirá es el de la sesión actual
          context.commit('SET_AGENT_IN_TICKET_TO_MANAGE', {
            agent,
            ticketId: ticket._id,
          })
        }
      }
    } catch (error) {
      console.error('updateAgentInTicketsToManage', error)
    }
  },
  updateStatusInTicketsToManage(
    context,
    { status, subStatus, ticket, tickets }
  ) {
    try {
      if (ticket) {
        context.getters.ticketsToManage.data.forEach((ticketStore, index) => {
          if (ticketStore._id == ticket._id) {
            context.commit('SET_STATUS_IN_TICKET_TO_MANAGE', {
              status,
              subStatus,
              index,
              pendings: ticket.pendings,
            })
            context.commit('SET_USERS_IN_TICKET_TO_MANAGE', {
              users: ticket.users,
              index,
            })
          }
        })
      } else {
        tickets.forEach((ticket) => {
          const index = context.getters.ticketsToManage.data.findIndex(
            (savedTicket) => savedTicket._id == ticket._id
          )
          context.commit('SET_STATUS_IN_TICKET_TO_MANAGE', {
            status,
            subStatus,
            index,
            pendings: ticket.pendings,
          })

          context.commit('SET_USERS_IN_TICKET_TO_MANAGE', {
            users: ticket.users,
            index,
          })
        })
      }
    } catch (error) {
      console.error('updateStatusInTicketsToManage', error)
    }
  },
  updateTagsInTicketsToManage(context, { ticket }) {
    try {
      const index = context.getters.ticketsToManage.data.findIndex(
        (savedTicket) => savedTicket._id == ticket._id
      )
      context.commit('SET_TAGS_IN_TICKET_TO_MANAGE', {
        tags: ticket.settings.tags,
        index,
      })
    } catch (error) {
      console.error('updateTagsInTicketsToManage', error)
    }
  },
  /**
   * Actualiza la cola de un ticket del modulo de gestor de tickets
   * @param {*} context
   * @param {Object} args
   * @param {Object} args.ticket
   * @param {String} args.ticket._id
   * @param {String} args.ticket.status
   * @param {String} args.ticket.subStatus
   * @param {Number} args.ticket.pendings
   * @param {Object} args.line
   * @param {String} args.line._id
   * @param {Object} args.agent
   * @param {String} args.agent.userId
   * @returns
   */
  async updateLineInTicketsToManage(context, { ticket, line, agent }) {
    try {
      const index = context.getters.ticketsToManage.data.findIndex(
        (savedTicket) => savedTicket._id == ticket._id
      )
      if (index === -1) return

      context.commit('SET_LINE_IN_TICKET_TO_MANAGE', {
        line,
        index,
      })
      context.commit('SET_STATUS_IN_TICKET_TO_MANAGE', {
        status: ticket.status,
        subStatus: ticket.subStatus,
        index,
        pendings: ticket.pendings,
      })
      context.commit('SET_AGENT_IN_TICKET_TO_MANAGE', {
        agent,
        ticketId: ticket._id,
      })
      context.commit('SET_USERS_IN_TICKET_TO_MANAGE', {
        users: ticket.users,
        index,
      })

      // si el rol del usuario es solo agente
      // y el usuario al que se le transferirá es diferente al de la sessión actual
      const isAgent = await compare.isAllowedFor(context, ['agent'], {
        literal: true,
      })
      const isCurrent =
        !agent || context.getters.profile.userId === agent.userId

      if (isAgent && !isCurrent)
        context.commit('REMOVE_MANAGE_TICKET', ticket._id)
    } catch (error) {
      console.error('updateLineInTicketsToManage', error)
    }
  },

  updateStoredTicket(context, ticket) {
    try {
      const index = context.getters.ticketsToManage.data.findIndex(
        (savedTicket) => savedTicket._id == ticket._id
      )
      context.commit('UPDATE_STORED_TICKET', {
        ticket,
        index,
      })
    } catch (error) {
      console.error('updateStoredTicket', error)
    }
  },
  /**
   * Exporta en excel los tickets
   * @param {*}       context
   * @param {Object} args
   * @param {String} args.search
   * @param {String} args.channel
   * @param {String[]} args.dateRange
   */
  exportTickets(context, { filters, headers }) {
    let response = null
    const token = format.fixedEncodeURIComp(context.getters.token)
    const filtersEncrypt = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(filters)
    )
    const headersEncrypt = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(headers)
    )
    const url = `${
      vari.UHM
    }/export/tickets?filters=${filtersEncrypt}&headers=${headersEncrypt}&token=${token}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Obtiene los productos asignados a un ticket sin paginacion
   * @param {String} idTicket
   */
  async getAssignedProductsNoPagedOfTicket(context, idTicket) {
    try {
      const response = await axios.get(
        `${vari.UHR}/agent/ticket/${idTicket}/products-crm`
      )
      if (response.data.success) {
        const assignedProducts = response.data.result.map(
          (item) => item.product
        )
        return { success: true, result: assignedProducts }
      } else return response.data.result
    } catch (err) {
      console.error('getAssignedProductsNoPagedOfTicket', err)
      return err.response?.data
    }
  },
  /**
   * Obtiene las campañas de la empresa con una paginacion maxima de 200
   * @return {Object[]} - array de estrategias
   * @return {String} - strategy[].name
   * @return {String} - strategy[].status
   * @return {String} - strategy[]._id
   * @return {Date} - strategy[].created_at
   * @return {Date} - strategy[].updated_at
   */
  async getStrategiesNoPaged() {
    try {
      const response = await axios.get(`${vari.UHR}/strategies/list/crm`)
      if (response.data.success) {
        return response.data
      } else return []
    } catch (err) {
      console.error('[getStrategiesNoPaged]', err)
      return err.response?.data
    }
  },
  /**
   * Resuelve un ticket desde el administrador
   * @param {*} context
   * @param {Object} args
   * @param {Object} args.ticket
   * @param {String} args.ticket._id
   * @param {Object[]} args.tickets
   * @param {String} args.tickets[]._id
   * @param {String} args.comment
   * @returns {Object} response
   * @returns {String} response.success
   * @returns {String} response.details
   */
  async resolveTicketFromAdmin(context, { ticket = null, tickets, comment }) {
    try {
      const mailboxEnd = context.getters.mailboxes.find((box) => box.end)

      const response = await context.dispatch('setAdminTicketMailbox', {
        mailbox: mailboxEnd,
        idTicket: ticket ? ticket._id : null,
        end: true,
        massive: !ticket,
        tickets: tickets,
        comment,
      })
      return response
    } catch (err) {
      console.error('[resolveTicketFromAdmin]', err)
    }
  },
}

export default actions
