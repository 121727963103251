<template>
  <layout-sider
    :menu-items="menuItems"
    :defaultSelectedKey="defaultSelectedKey"
  >
    <template slot="content">
      <router-view />
    </template>
  </layout-sider>
</template>

<script>
import LayoutSider from '@/app/products/components/templates/LayoutSider'
import { mapActions } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'ProductsTemplate',
  components: {
    LayoutSider,
  },
  data: () => ({
    menuItems: [
      {
        title: 'Mis productos',
        route: { name: 'products-menu-products' },
        icon: 'shopping',
        key: 'products',
      },
      {
        title: 'Campos personalizados',
        route: { name: 'products-menu-fields' },
        icon: 'form',
        key: 'fields',
      },
    ],
    defaultSelectedKey: 'products',
  }),
  mixins: [attemptMixin],
  created() {
    this.intervalAttempt(async () => {
      this.getDefaultCurrencies()
      this.getFieldsProducts({ page: 1 })
    })
  },
  methods: {
    ...mapActions(['getDefaultCurrencies', 'getFieldsProducts']),
  },
}
</script>

<style scoped></style>
