import { notificationDownload } from '@/app/downloads/utils/downloadsUtil'

const actions = {
  /**
   * Escucha la actualizacion del estado de descarga
   * @param {*} context
   */
  async listenStatusDownload(context) {
    try {
      this._vm.$socket.on('client:company:download:status', (payload) => {
        console.log('listenStatusDownload', payload)
        context.commit('UPDATE_DOWNLOAD', {
          downloadId: payload.idDownload,
          status: payload.status,
          file: { url: payload.link || null },
        })
        notificationDownload({ status: payload.status })
      })
    } catch (e) {
      console.error('[listenStatusDownload]', e)
    }
  },
  /**
   * Escucha el limite de descargas
   * @param {*} context
   */
  async listenUpdateLimitDownload(context) {
    try {
      this._vm.$socket.on('client:company:download:limit', (payload) => {
        console.log('listenUpdateLimitDownload', payload)
        console.log(Number(payload.downloadLimit))
        context.commit('SET_SPENT_LIMIT', Number(payload.downloadLimit))
      })
    } catch (e) {
      console.error('[listenStatusDownload]', e)
    }
  },
}

export default { actions }
