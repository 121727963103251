import Downloads from '@/app/downloads/views/Downloads'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/downloads',
    name: 'my-downloads',
    component: Downloads,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'master_agent', 'agent'],
      title: `Mis Descargas | ${VUE_APP_NAME}`,
    },
  },
]
