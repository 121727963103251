<template>
  <a-row class="upload-content">
    <div v-if="!loading && fileUrl" class="upload-content--full">
      <a-row
        type="flex"
        justify="center"
        class="upload-content--overlay direction-column"
      >
        <a-row>
          <a-icon type="edit" class="upload-content__icon--action" />
          <p>{{ textLoading }}</p>
        </a-row>
      </a-row>
      <!--Si es una imagen-->
      <img
        v-if="fileType === 'image'"
        :src="fileUrl"
        alt="Imagen de respuesta rápida"
        class="upload-content__picture"
      />
      <!--Si es un documento-->
      <a-row v-else-if="fileType === 'document'" class="upload-content__file">
        <a-icon class="upload-content__icon--file" type="file-text" />
        <p class="regular-14">{{ fileCaption }}</p>
      </a-row>
      <!--Si es un video-->
      <video
        v-else-if="fileType === 'video'"
        controls
        alt="Video de respuesta rápida"
        class="upload-content__picture"
      >
        <source type="video/mp4" :src="fileUrl" />
      </video>
    </div>
    <a-row
      v-else
      class="upload-content--default direction-column"
      type="flex"
      justify="center"
      align="middle"
    >
      <a-icon
        :type="loading ? 'loading' : 'inbox'"
        class="mrg-bottom-8 upload-content__icon--action"
      />
      <div class="ant-upload-text">
        <p class="regular-14">
          <span class="upload-content__text--strong">{{ textLoading }}</span>
          <br />
          {{ max_size_to_upload }}MB tamaño máximo
        </p>
      </div>
    </a-row>
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UploadContentPreview',
  props: {
    fileUrl: {
      type: String,
      required: false,
    },
    fileType: {
      type: String,
      required: false,
      enum: ['IMAGE', 'DOCUMENT', 'VIDEO'],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileCaption: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['max_size_to_upload']),

    /**
     * @returns {String} texto de carga
     */
    textLoading() {
      if (this.fileUrl) {
        return this.loading ? 'Cargando archivo...' : 'Cambiar archivo'
      }
      return this.loading ? 'Subiendo...archivo' : 'Sube aquí tu archivo'
    },
  },
}
</script>

<style lang="sass" scoped>
.upload-content
  height: 104px
  width: 100%
  position: relative
  &--default
    height: 104px
  &--full
    height: 104px
  &__picture
    height: 104px
  &__file
    height: 100%
    +flex(column, center, center)
    color: $gray_8
    z-index: 1
  &__icon--action
    font-size: 20px
  &--overlay
    height: 104px
    width: 100%
    border-radius: 2px
    position: absolute
    display: none
    background-color: rgba(67, 67, 67, 0.8)
    color: $gray_1
    transition: background-color 3s
    z-index: 5
  &:hover
    .upload-content--overlay
      display: flex
      background-color: rgba(67, 67, 67, 0.6)
  &__icon--file
    font-size: 28px
    margin-bottom: 8px
  &__text--strong
    color: $gray_10
</style>
