<template>
  <div class="section-new-tickets">
    <div class="header">
      <p class="title heading-h6">Resumen de tickets nuevos</p>
      <div class="container-popovers">
        <a-tooltip
          placement="bottomRight"
          :arrowPointAtCenter="true"
          overlayClassName="tooltip-dash1"
        >
          <template slot="title">
            Visualiza todos los tickets nuevos que ingresan por tus canales. Se
            mostrarán según el rango de fecha y hora que decidas en el filtro.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!--POPOVER DE CONFIRMACION-->
        <a-popconfirm
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="handleDownload"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template v-if="!isLoading" slot="title">
              Descargar en excel
            </template>
            <a-icon
              type="download"
              class="section__icon--download"
              :class="{
                'btn-dowload': !isLoading,
                'icon--disabled': isLoading,
              }"
            />
          </a-tooltip>
        </a-popconfirm>
      </div>
    </div>
    <div class="body">
      <LineChart
        v-if="dataNewTickets && dataNewTickets.length > 0"
        :styles="myStyles"
        :chartdata="chartdata"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LineChart from '@/app/dashboard/components/organisms/charts/LineChart.vue'
import moment from 'moment'

export default {
  name: 'SectionNewTickets',
  components: {
    LineChart,
  },
  props: {
    date: { type: Array, required: true },
    lineId: { type: String, default: 'allLines' },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '344px',
      margin: '12px 20px',
      position: 'relative',
    },
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
  }),
  computed: {
    ...mapGetters(['dataNewTickets']),
    chartdata() {
      const data = {
        labels: this.dataNewTickets.map((item) => item.date),
        datasets: [
          {
            label: 'Tickets nuevos',
            data: this.dataNewTickets.map((item) => item.quantity),
            backgroundColor: 'rgba(255, 222, 54, 0.4)',
            borderColor: '#FFDE36',
            borderWidth: 3,
          },
        ],
      }
      return data
    },
  },
  methods: {
    ...mapActions(['exportNewTickets', 'setAnalytics']),

    /**
     * Ejecuta la descarga del dashboard
     */
    handleDownload() {
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'new_tickets' },
      })
    },
    // vuelve a descargar
    handleOk() {
      this.exportDashboard()
    },
    async exportDashboard() {
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      await this.exportNewTickets({ date, lineId })
    },
  },
}
</script>
<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-new-tickets
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.section__icon--download
  font-size: 20px
  color: #595959
  margin-right: 8px
</style>
