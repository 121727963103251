var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.oldTicketSelected)?_c('a-modal',{attrs:{"visible":_vm.visible,"bodyStyle":{ ..._vm.styles, height: _vm.heightBody },"footer":null,"wrapClassName":"previewModal","width":_vm.widthModal,"centered":""},on:{"ok":_vm.handleOk,"cancel":_vm.handleClose}},[_c('template',{slot:"title"},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('span',{staticClass:"mrg-right-8"},[_vm._v(" "+_vm._s(_vm.titleModal))]),_c('span',{staticClass:"mrg-right-8"},[(_vm.oldTicketSelected.settings.starred)?_c('a-rate',{attrs:{"default-value":1,"disabled":"","count":1}}):_vm._e()],1),(_vm.tagOriginTicket)?_c('a-tooltip',{attrs:{"placement":"right","title":_vm.tagOriginTicket.title}},[_c('a-tag',{attrs:{"color":_vm.tagOriginTicket.color}},[_vm._v(_vm._s(_vm.tagOriginTicket.origin))])],1):_vm._e()],1)],1),_c('a-row',{staticStyle:{"height":"100%"},attrs:{"justify-content":"space-between"}},[_c('a-col',{style:(!_vm.active_breakpoint.is_mobile ? 'height: 100%' : 'height: auto'),attrs:{"xs":{ span: 24 },"sm":{ span: 24 },"md":{ span: 24 },"lg":{ span: _vm.showComments ? 6 : 8 },"xl":{ span: _vm.showComments ? 6 : 8 }}},[(_vm.active_breakpoint.is_mobile)?_c('a-collapse',{attrs:{"bordered":false}},[_c('a-collapse-panel',{key:"1",staticStyle:{"padding":"0px"},attrs:{"header":"Detalles del ticket"}},[_c('div',{staticClass:"modal__panel__content"},[_c('SectionTicketDetails',{attrs:{"origin-channel":_vm.originTicketLocal,"custom-fields-ticket":_vm.oldTicketSelected.settings.customFields
                  ? _vm.oldTicketSelected.settings.customFields
                  : [],"custom-fields-client":_vm.customFieldsClient,"tags":_vm.oldTicketSelected.settings.tags,"integrations":_vm.oldTicketSelected.integrations
                  ? _vm.oldTicketSelected.integrations
                  : [],"type":"history-manage"}})],1)]),_c('a-collapse-panel',{key:"2",staticStyle:{"padding":"0px"},attrs:{"header":"Comentarios del ticket"}},[_c('div',{staticClass:"modal__panel__content"},[_c('section-comments',{staticClass:"modal__section-comments",attrs:{"ticketId":_vm.ticketId,"showBack":true,"target":"history-manage"},on:{"onClose":function($event){_vm.showComments = false}}})],1)])],1):_c('SectionTicketDetails',{attrs:{"show-title":false,"origin-channel":_vm.originTicketLocal,"custom-fields-ticket":_vm.oldTicketSelected.settings.customFields
            ? _vm.oldTicketSelected.settings.customFields
            : [],"custom-fields-client":_vm.customFieldsClient,"tags":_vm.oldTicketSelected.settings.tags,"integrations":_vm.oldTicketSelected.integrations ? _vm.oldTicketSelected.integrations : [],"type":"history-manage"}})],1),(!_vm.active_breakpoint.is_mobile)?_c('a-col',{staticStyle:{"height":"100%"},attrs:{"span":1}},[_c('a-divider',{staticStyle:{"height":"100%","margin":"0px"},attrs:{"type":"vertical"}})],1):_vm._e(),_c('a-col',{style:(!_vm.active_breakpoint.is_mobile
          ? 'height: 100%'
          : 'height: calc(100% - 52px)'),attrs:{"xs":{ span: 24 },"sm":{ span: 24 },"md":{ span: 24 },"lg":{ span: _vm.showComments ? 10 : 15 },"xl":{ span: _vm.showComments ? 10 : 15 }}},[_c('SectionBoard',{class:{
          'modal__section-board--show-comments': _vm.showComments,
          'modal__section-board': !_vm.showComments,
        },attrs:{"data":_vm.oldTicketSelected,"data-client":_vm.dataClient,"type":"history-manage"}})],1),(!_vm.active_breakpoint.is_mobile)?_c('a-col',{staticStyle:{"height":"100%"},attrs:{"span":1}},[_c('a-divider',{staticStyle:{"height":"100%","margin":"0px"},attrs:{"type":"vertical"}})],1):_vm._e(),_c('a-col',{attrs:{"xs":{ span: 0 },"sm":{ span: 0 },"md":{ span: 0 },"lg":{ span: _vm.showComments ? 6 : 8 },"xl":{ span: _vm.showComments ? 6 : 8 }}},[_c('section-comments',{staticClass:"modal__section-comments",attrs:{"ticketId":_vm.ticketId,"showBack":true,"target":"history-manage"},on:{"onClose":function($event){_vm.showComments = false}}})],1)],1),(!_vm.showComments)?_c('button-comment-linked',{staticClass:"modal__button-comment",class:{ 'modal__button-comment--full': _vm.showFullButton },attrs:{"showFullButton":true,"isLinked":true},on:{"onShowTitle":_vm.setShowTitle},nativeOn:{"click":function($event){return _vm.handleShowComments.apply(null, arguments)}}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }