const initialState = () => ({
  loggedIn: false, // Muestra si el usuario se encuentra correctamente logueado
  token: null, // Setea el token de acceso al usuario
  modalSessions: {
    statusModal: false,
    typeModal: '',
  },
  modalUpgrade: {
    // Modal de upgrade de planes
    visible: false,
    type: null, // tipo de plan
  },
  basicModal: {
    visible: false,
  },
})

export default initialState
