<template>
  <div class="verification-email">
    <img
      src="/img/graphics/outlined/notifications-advanced.svg"
      class="verification-email__picture"
    />
    <h5 class="heading-h5 text-align-center title">
      Para verificar, te hemos enviado un link al siguiente correo:
    </h5>
    <p class="body-4 email" style="margin-bottom: 16px">{{ email }}</p>
    <div class="contain-link">
      <p class="body-4" style="margin-bottom: 0">Si este no es tu correo</p>
      <a
        class="body-4"
        style="margin-left: 8px"
        @click="handleBackToForm"
        :disabled="success"
        >click aquí</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerificationEmail',
  props: {
    email: { type: String, default: 'email', required: true },
    loading: { type: Boolean },
    success: { type: Boolean, required: false },
  },
  data: () => ({
    disabledLink: false,
  }),
  methods: {
    handleSubmit() {
      this.$emit('handleSubmit')
      this.disabledLink = false
    },
    handleBackToForm() {
      this.$emit('handleBackToForm')
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-center
  text-align: center
.verification-email
  // margin: 4% 32%
  color: $gray_dark_900
  display: flex
  flex-direction: column
  align-items: center
  .title
    margin: 24px auto 16px
  .email
    color: $gray_dark_800
  .contain-link
    margin-bottom: 24px
  &__picture
    @include xs
      max-width: 271px
      max-height: 141px
    @include sm
      max-width: 271px
      max-height: 141px
</style>
