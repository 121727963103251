<template>
  <div>
    <custom-section-scroll
      :master-styles="{
        height: 'auto',
      }"
      :containerStyles="{
        maxHeight: '344px',
      }"
    >
      <picker-channels
        v-for="(picker, index) in pickerChannels.data"
        :key="picker.key"
        :default-value="picker"
        :withLabel="!index"
        @onDelete="handleDeletePicker(picker.key)"
        @onChangeChannel="
          (channel) => handleChangePicker({ channel, key: picker.key })
        "
        @onChangeChannelCompanyId="
          (channelCompanyId) =>
            handleChangePicker({ channelCompanyId, key: picker.key })
        "
        :disabled-options="disabledOptions"
        :disabled="disabled"
        :enabled-channels="enabledChannels"
      />
    </custom-section-scroll>
    <a-tooltip>
      <span v-if="disabledAddOptions" slot="title"
        >No hay canales por asignar</span
      >
      <a
        class="list__button-link mrg-top-8"
        :disabled="disabledAddOptions"
        @click="handleAddPicker"
        >+ Asigna un nuevo canal</a
      >
    </a-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PickerChannels from '@/app/shared/components/molecules/PickerChannels'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'ListPickerChannels',
  props: {
    defaultValues: {
      type: Array,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabledChannels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    PickerChannels,
    CustomSectionScroll,
  },
  mounted() {
    if (this.defaultValues) {
      this.pickerChannels.data = [...this.defaultValues]
      this.setAllDisabledOptions()
    }
  },
  data: () => ({
    pickerChannels: {
      data: [],
    },
    disabledOptions: [],
  }),
  mixins: [supportMixin],
  watch: {
    defaultValues() {
      if (this.defaultValues) {
        this.pickerChannels.data = [...this.defaultValues]
        this.setAllDisabledOptions()
      }
    },
  },
  computed: {
    ...mapGetters(['simple_company_channels', 'company']),

    /**
     * Todos los canales hábiles para ser usados
     * @return {Object[]} allChannelsEnabled
     * @return {String} allChannelsEnabled[]._id
     * @return {String} allChannelsEnabled[].channel
     */
    allChannelsEnabled() {
      // obtener todos los canales de la empresa
      const channelsCompany = Object.keys(this.company.channels)

      let enabledChannels = []
      if (this.enabledChannels.length > 0)
        enabledChannels = channelsCompany.filter((channelCompany) =>
          this.enabledChannels.includes(channelCompany)
        )
      else enabledChannels = channelsCompany
      // recorre todos los canales de la empresa
      let allChannelsEnabled = []
      enabledChannels.forEach((channelCompany) => {
        const simpleChannels = this.filterSimpleByChannel({
          channel: channelCompany,
        })
        allChannelsEnabled = [...allChannelsEnabled, ...simpleChannels]
      })
      return allChannelsEnabled
    },

    /**
     * @return {Boolean} - verifca si todas las opciones estan deshabilitadas
     */
    disabledAddOptions() {
      // checka las opciones deshabilitadas
      return (
        this.disabled ||
        this.disabledOptions.length === this.allChannelsEnabled.length
      )
    },
  },
  methods: {
    /**
     * Agrega un selector de canal
     */
    handleAddPicker() {
      if (this.disabledAddOptions) return
      const newKey = `${new Date().getTime()}-${
        this.pickerChannels.data.length - 1
      }`

      const newChannel = this.newChannelCompany()
      this.pickerChannels.data.push({
        key: newKey,
        channelCompanyId: newChannel._id,
        channel: newChannel.channel,
      })

      // emite cambios
      this.emitChanges()
    },
    handleDeletePicker(key) {
      this.pickerChannels.data = this.pickerChannels.data.filter(
        (picker) => picker.key !== key
      )

      // checka las opciones deshabilitadas
      this.setAllDisabledOptions()
      // emite cambios
      this.emitChanges()
    },
    /**
     * Escucha los cambios para los valores del picker
     * @param {String} channelCompanyId - id del canal de la empresa
     * @param {String} channel - canal
     * @param {String} key - id del picker
     */
    handleChangePicker({ channelCompanyId = null, channel = null, key }) {
      const picker = this.pickerChannels.data.find(
        (picker) => picker.key === key
      )
      if (!picker) return
      if (channel) picker.channel = channel
      if (channelCompanyId) picker.channelCompanyId = channelCompanyId

      // setea las opciones deshabilitadas
      this.setAllDisabledOptions()
      // emite cambios
      this.emitChanges()
    },
    /**
     * Obtiene la lista de todos los canales seleccionados, usado por los padres componentes
     */
    handleGetList() {
      return this.pickerChannels.data
    },
    newChannelCompany() {
      let newChannel = null
      // si ya hay un nuevo canal o si no hay canales habilitados
      if (newChannel || !this.allChannelsEnabled.length) return
      const channelFound = this.allChannelsEnabled.find(
        (channelEnabled) => !this.disabledOptions.includes(channelEnabled._id)
      )
      if (channelFound) newChannel = channelFound

      return newChannel
    },
    /**
     * Setea todas las opciones deshabilitados tomando de referecia los canales
     */
    setAllDisabledOptions() {
      // checka las opciones deshabilitadas
      this.disabledOptions = this.pickerChannels.data.map(
        (picker) => picker.channelCompanyId
      )
    },
    emitChanges() {
      this.$emit('onChange', this.pickerChannels.data)
    },
  },
}
</script>

<style lang="sass" scoped></style>
