const getters = {
  line: (state) => state.line,
  lines: (state) => state.lines,
  companyLines: (state) => state.companyLines,
  agentsAccounts: (state) => state.accounts.agents,
  supervisorsAccounts: (state) => state.accounts.supervisors,
  actionDesasigned: (state) => state.action_desasigned,
  actionAsigned: (state) => state.action_asigned,
  actionChannelRemoved: (state) => state.action_channel_removed,
  actionChatPreferencesUpdated: (state) =>
    state.action_chat_preferences_updated,
  reloadTableLines: (state) => state.reloadTableLines,
  reloadTableAccounts: (state) => state.reloadTableAccounts,
}

export default getters
