<template>
  <card-base class="text--left">
    <h6 class="heading-h6 title">
      <a-icon type="thunderbolt" class="mrg-right-8" />Respuestas rápidas
    </h6>
    <p class="body-2 card__description">
      Crea plantillas de textos para usarlas en las conversaciones con tus
      contactos.
    </p>
    <a-row type="flex" justify="space-between" class="mrg-bottom-16">
      <a-input-search
        v-model="filters.search"
        @search="handleChangeTable(filters.page)"
        placeholder="Buscar por título"
        style="width: 256px"
      />
      <a-button type="primary" @click="handleShowCreate" icon="plus"
        >Crear respuesta rápida</a-button
      >
    </a-row>
    <!--Tabla-->
    <a-table
      class="table-scroll"
      :columns="columns"
      :data-source="dataSourceOrdered"
      :pagination="pagination"
      size="small"
      @change="handleChangeTable"
      :loading="loadTable"
      :scroll="{ y: 'calc(90.2vh - 19em - 250px)' }"
    >
      <!--Para el body-->
      <template slot="body" slot-scope="body">
        <empty-cell :value="body" text-empty="Sin mensaje" />
      </template>
      <!--Para Multimedia-->
      <template slot="media" slot-scope="media">
        <empty-cell
          :value="
            media &&
            media.typeAttachment &&
            MULTIMEDIA_SUPPORT[media.typeAttachment]
              ? MULTIMEDIA_SUPPORT[media.typeAttachment].title_singular
              : ''
          "
          text-empty="Sin multimedia"
        />
      </template>
      <!--Colas asignadas-->
      <template slot="lines" slot-scope="lines">
        <empty-cell :value="lines.length" text-empty="0" />
      </template>
      <!--Acciones-->
      <span slot="action" slot-scope="text, record">
        <a @click="handleShowPreview(record)">Ver</a>
        <a-divider type="vertical" />
        <a @click="handleShowEdit(record)">Editar</a>
        <a-divider type="vertical" />
        <a @click="handleShowDelete(record._id, record.title)">Eliminar</a>
      </span>
    </a-table>
    <!--Modales-->
    <modal-preview-fast-answers
      :visible="modalPreview.visible"
      title="Ver respuesta rápida"
      :data-source="modalPreview.dataSource"
      :keys-allowed="modalPreview.keysAllowed"
      @onClose="modalPreview.visible = false"
    />
    <modal-form-fast-answers
      ref="modal_form_fast_answers"
      :visible="modalForm.visible"
      :ok-text="modalForm.okText"
      :title="modalForm.title"
      :on-ok="modalForm.onOk"
      @onClose="handleCloseForm"
    />
  </card-base>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardBase from '@/app/shared/components/cards/CardBase'
import ModalPreviewFastAnswers from '@/app/settings/components/molecules/ModalPreviewFastAnswers'
import ModalFormFastAnswers from '@/app/settings/components/molecules/ModalFormFastAnswers'
import transformMixin from '@/app/shared/mixins/transform'
import EmptyCell from '../../atoms/EmptyCell.vue'
import { MULTIMEDIA_SUPPORT } from '@/app/tickets/utils/multimediaUtil'

export default {
  name: 'SectionFastAnswers',
  components: {
    CardBase,
    ModalPreviewFastAnswers,
    ModalFormFastAnswers,
    EmptyCell,
  },
  data: () => ({
    MULTIMEDIA_SUPPORT,
    columns: [
      {
        title: 'N°',
        dataIndex: 'order',
        key: 'order',
        width: '10%',
      },
      {
        title: 'Título',
        dataIndex: 'title',
        key: 'title',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Mensaje',
        key: 'body',
        dataIndex: 'body',
        scopedSlots: { customRender: 'body' },
        ellipsis: true,
      },
      {
        title: 'Multimedia',
        key: 'media',
        ellipsis: true,
        dataIndex: 'media',
        scopedSlots: { customRender: 'media' },
        width: 140,
      },
      {
        title: 'Colas asignadas',
        key: 'lines',
        dataIndex: 'lines',
        scopedSlots: { customRender: 'lines' },
        width: 150,
      },
      {
        title: 'Acciones',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        width: '20%',
      },
    ],
    modalPreview: {
      visible: false,
      dataSource: {},
      keysAllowed: [],
    },
    modalForm: {
      visible: false,
      dataSource: {},
      title: '',
      okText: 'Crear',
      onOk: () => ({}),
    },
    filters: {
      search: '',
      page: 1,
    },
    loadTable: false,
  }),
  mixins: [transformMixin],
  computed: {
    ...mapGetters(['company_fast_answers', 'paginate_fast_answers']),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 10,
        total: this.paginate_fast_answers.total || 0,
        current: this.paginate_fast_answers.page,
      }
    },
    /**
     * Data ordenada
     * @return {Array}
     */
    dataSourceOrdered() {
      if (!this.company_fast_answers.length) return []
      let startOrder =
        1 +
        (this.paginate_fast_answers.page - 1) * this.pagination.defaultPageSize
      return this.company_fast_answers.map((fastAnswer) => {
        fastAnswer.order = startOrder++
        return fastAnswer
      })
    },
  },
  methods: {
    ...mapActions([
      'addFastAnswer',
      'getFastAnswers',
      'updateFastAnswer',
      'deleteFastAnswer',
    ]),

    /**
     * Muestra el modal de previsualizacion
     * @param {String} record.title - titulo de la respuesta
     * @param {String} record.body - contenido de la respuesta
     * @param {Object[]} record.lines - lineas asignadas a la respuesta
     */
    handleShowPreview(record) {
      this.modalPreview.visible = true
      this.modalPreview.dataSource = record
      this.modalPreview.keysAllowed = ['title', 'body', 'lines']
    },
    /**
     * Muesta el modal de editar
     * @param {String} record.title - titulo de la respuesta
     * @param {String} record.body - contenido de la respuesta
     * @param {Object[]} record.lines - lineas asignadas a la respuesta
     */
    handleShowEdit(record) {
      this.modalForm.okText = 'Editar'
      this.modalForm.title = 'Editar respuesta rápida'
      this.modalForm.visible = true
      this.modalForm.onOk = this.handleUpdateFastAnswers

      const modalFastAnswers = this.$refs.modal_form_fast_answers
      if (!modalFastAnswers) return
      modalFastAnswers.setValues(record)
    },
    /**
     * Muestra el modal de crear
     */
    handleShowCreate() {
      this.modalForm.okText = 'Crear'
      this.modalForm.title = 'Crear respuesta rápida'
      this.modalForm.visible = true
      this.modalForm.onOk = this.handleAddFastAnswers
    },
    /**
     * Agrega una respuesta rápida
     * @param {Object} fastAnswer
     * @param {String} fastAnswer.title
     * @param {String} fastAnswer.body
     * @param {String[]} fastAnswer.linesIds
     */
    async handleAddFastAnswers(fastAnswer) {
      this.handleLoadTable(true)
      const response = await this.addFastAnswer(fastAnswer)
      if (response.success) {
        this.$message.success('Se creó con exito')
        this.handleCloseForm()
      } else {
        this.$message.error(
          response.details ? response.details : 'Ocurrió un error '
        )
      }
      this.handleLoadTable(false)
    },
    /**
     * Actualiza una respuesta rápida
     * @param {Object} fastAnswer
     * @param {String} fastAnswer._id
     * @param {String} fastAnswer.title
     * @param {String} fastAnswer.body
     * @param {String[]} fastAnswer.linesIds
     */
    async handleUpdateFastAnswers(fastAnswer) {
      this.handleLoadTable(true)
      const response = await this.updateFastAnswer(fastAnswer)
      if (response.success) {
        this.$message.success('Se actualizo con exito')
        this.handleCloseForm()
      } else {
        this.$message.error('Ocurrió un error')
      }
      this.handleLoadTable(false)
    },
    /**
     * Muestra el eliminacion
     * @param {String} isFastAnswer - id de la respuesta rápida
     * @param {String} title - titulo de la respuesta rápida
     */
    handleShowDelete(idFastAnswer, title) {
      let self = this
      this.$confirm({
        title: `¿Estás ${self.wordSure} de eliminar la respuesta rápida "${title}"?`,
        content:
          'Recuerda que esta respuesta rápida ya no podrá ser utilizada por tus agentes.',
        okText: 'Eliminar',
        okType: 'danger',
        cancelText: 'Cancelar',
        async onOk() {
          self.handleLoadTable(true)
          const response = await self.deleteFastAnswer(idFastAnswer)
          if (response.success) self.$message.success(response.details)
          else self.$message.error(response.details)
          self.handleLoadTable(false)
        },
      })
    },
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleChangeTable(pagination) {
      this.handleLoadTable(true)
      await this.getFastAnswers({
        page: pagination.current,
        search: this.filters.search,
        module: 'general_settings',
      })
      this.handleLoadTable(false)
    },
    /**
     * Carga la tabla
     * @param {Boolean} load
     */
    handleLoadTable(load) {
      this.loadTable = load
    },
    /**
     * Cierra el modal
     */
    handleCloseForm() {
      this.modalForm.visible = false
    },
  },
}
</script>

<style lang="sass" scoped>
.card-height
  min-height: 400px
.card__description
  margin-bottom: 20px
  text-align: left
  color: $gray_light_900
.table__cell--italic
  color: $gray_6
.table__cell--disabled
  color: $gray_6
</style>
