import { render, staticRenderFns } from "./AudioMessage.vue?vue&type=template&id=28a05568&scoped=true&"
import script from "./AudioMessage.vue?vue&type=script&lang=js&"
export * from "./AudioMessage.vue?vue&type=script&lang=js&"
import style0 from "./AudioMessage.vue?vue&type=style&index=0&id=28a05568&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a05568",
  null
  
)

export default component.exports