/*global FB*/
/* Install SDK de Facebook */

// const msgrFacebookAppId = process.env.VUE_APP_META_MESSENGER_CLIENT_ID
// const igFacebookAppId = process.env.VUE_APP_META_INSTAGRAM_CLIENT_ID
// const facebookVersion = process.env.VUE_APP_FACEBOOK_VERSION
const fb_sdk = 'https://connect.facebook.net/en_US/sdk.js'

export async function loadFacebook() {
  try {
    await fetch(fb_sdk)
    console.log('Facebook sdk is allowed')
    console.log(
      'Facebook status: https://developers.facebook.com/status/dashboard/'
    )
    await initFacebookSdk()
  } catch (error) {
    console.log('Facebook sdk is NOT allowed')
    console.log(
      'Facebook status: https://developers.facebook.com/status/issues/'
    )
    return error
  }
}

export function initFacebookSdk() {
  return new Promise((resolve, reject) => {
    // wait for facebook sdk to initialize before starting the vue app
    try {
      window.fbAsyncInit = function () {
        function metaFB(fb) {
          this.FB = fb
        }
        window.FB_msgr = new metaFB({ ...FB })
        window.FB_ig = new metaFB({ ...FB })
        // window.FB_msgr.FB.init({
        //   appId: msgrFacebookAppId,
        //   cookie: true,
        //   xfbml: true,
        //   version: facebookVersion,
        // })
        // window.FB_ig.FB.init({
        //   appId: igFacebookAppId,
        //   cookie: true,
        //   xfbml: true,
        //   version: facebookVersion,
        // })
        resolve()
      }
      console.log('Cargando sdk...')
      // load facebook sdk script
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = fb_sdk
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    } catch (error) {
      console.error(error)
      reject()
    }
  })
}
