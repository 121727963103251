<template>
  <a-modal
    :visible="visible"
    v-if="oldTicketSelected"
    @ok="handleOk"
    :bodyStyle="{ ...styles, height: heightBody }"
    :footer="null"
    wrapClassName="previewModal"
    :width="widthModal"
    @cancel="handleClose"
    centered
  >
    <!--Titulo del modal-->
    <template slot="title">
      <a-row type="flex" align="middle">
        <span class="mrg-right-8"> {{ titleModal }}</span>
        <span class="mrg-right-8">
          <a-rate
            v-if="oldTicketSelected.settings.starred"
            :default-value="1"
            disabled
            :count="1"
          />
        </span>
        <a-tooltip
          v-if="tagOriginTicket"
          placement="right"
          :title="tagOriginTicket.title"
        >
          <a-tag :color="tagOriginTicket.color">{{
            tagOriginTicket.origin
          }}</a-tag>
        </a-tooltip>
      </a-row>
    </template>
    <!--Cuerpo del modal-->
    <a-row justify-content="space-between" style="height: 100%">
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: showComments ? 6 : 8 }"
        :xl="{ span: showComments ? 6 : 8 }"
        :style="!active_breakpoint.is_mobile ? 'height: 100%' : 'height: auto'"
      >
        <a-collapse v-if="active_breakpoint.is_mobile" :bordered="false">
          <a-collapse-panel
            key="1"
            header="Detalles del ticket"
            style="padding: 0px"
          >
            <div class="modal__panel__content">
              <SectionTicketDetails
                :origin-channel="originTicketLocal"
                :custom-fields-ticket="
                  oldTicketSelected.settings.customFields
                    ? oldTicketSelected.settings.customFields
                    : []
                "
                :custom-fields-client="customFieldsClient"
                :tags="oldTicketSelected.settings.tags"
                :integrations="
                  oldTicketSelected.integrations
                    ? oldTicketSelected.integrations
                    : []
                "
                type="history-manage"
              />
            </div>
          </a-collapse-panel>
          <a-collapse-panel
            key="2"
            header="Comentarios del ticket"
            style="padding: 0px"
          >
            <div class="modal__panel__content">
              <section-comments
                :ticketId="ticketId"
                :showBack="true"
                class="modal__section-comments"
                @onClose="showComments = false"
                target="history-manage"
              />
            </div>
          </a-collapse-panel>
        </a-collapse>
        <SectionTicketDetails
          v-else
          :show-title="false"
          :origin-channel="originTicketLocal"
          :custom-fields-ticket="
            oldTicketSelected.settings.customFields
              ? oldTicketSelected.settings.customFields
              : []
          "
          :custom-fields-client="customFieldsClient"
          :tags="oldTicketSelected.settings.tags"
          :integrations="
            oldTicketSelected.integrations ? oldTicketSelected.integrations : []
          "
          type="history-manage"
        />
      </a-col>
      <a-col v-if="!active_breakpoint.is_mobile" :span="1" style="height: 100%">
        <a-divider type="vertical" style="height: 100%; margin: 0px"
      /></a-col>
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: showComments ? 10 : 15 }"
        :xl="{ span: showComments ? 10 : 15 }"
        :style="
          !active_breakpoint.is_mobile
            ? 'height: 100%'
            : 'height: calc(100% - 52px)'
        "
      >
        <SectionBoard
          :data="oldTicketSelected"
          :data-client="dataClient"
          type="history-manage"
          :class="{
            'modal__section-board--show-comments': showComments,
            'modal__section-board': !showComments,
          }"
        />
      </a-col>
      <a-col v-if="!active_breakpoint.is_mobile" :span="1" style="height: 100%">
        <a-divider type="vertical" style="height: 100%; margin: 0px"
      /></a-col>
      <a-col
        :xs="{ span: 0 }"
        :sm="{ span: 0 }"
        :md="{ span: 0 }"
        :lg="{ span: showComments ? 6 : 8 }"
        :xl="{ span: showComments ? 6 : 8 }"
      >
        <section-comments
          :ticketId="ticketId"
          :showBack="true"
          class="modal__section-comments"
          @onClose="showComments = false"
          target="history-manage"
        />
      </a-col>
    </a-row>
    <button-comment-linked
      v-if="!showComments"
      class="modal__button-comment"
      :class="{ 'modal__button-comment--full': showFullButton }"
      :showFullButton="true"
      :isLinked="true"
      @click.native="handleShowComments"
      @onShowTitle="setShowTitle"
    />
  </a-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import SectionBoard from '@/app/tickets/components/organisms/sections/SectionBoard'
import SectionTicketDetails from '@/app/tickets/components/molecules/SectionTicketDetails'
import ButtonCommentLinked from '@/app/tickets/components/molecules/ButtonCommentLinked'
import SectionComments from '@/app/tickets/components/organisms/sections/SectionComments'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'ModalTicketDetails',
  mixins: [formatMixin, supportMixin],
  components: {
    SectionBoard,
    SectionTicketDetails,
    ButtonCommentLinked,
    SectionComments,
  },
  props: {
    ticket: {
      type: Object,
      required: false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    async ticket() {
      if (!this.ticket) return
      await this.selectOldTicket(this.ticket)
      this.paginateMessages({
        target: 'history-manage',
        ticket: this.ticket,
      })
    },
  },
  data: () => ({
    moment: moment,
    styles: {
      padding: 0,
      overflow: 'hidden',
    },
    stripeSection: null,
    showComments: false,
    showFullButton: false,
  }),
  mounted() {
    this.existStripe()
  },
  computed: {
    ...mapGetters([
      'oldTickets',
      'oldTicketSelected',
      'client',
      'isLoadingOldTickets',
      'active_breakpoint',
    ]),

    /**
     * Origen del ticket
     * @returns {Object | null}
     * @returns {String} origin
     * @returns {String} color - para el tag
     * @returns {String} title - titulo para el tooltip
     * @returns {Boolean} isExternal - si el ticket fue generado externamente o no
     */
    tagOriginTicket() {
      let newObj = {
        isExternal: true,
      }
      if (!this.oldTicketSelected || !this.oldTicketSelected.channel)
        return null
      else {
        if (this.oldTicketSelected.channel.urlOrigin) {
          newObj.origin = this.oldTicketSelected.channel.urlOrigin
          newObj.title = 'Página web por donde se originó este ticket'
          newObj.color = 'orange'
        } else if (
          this.oldTicketSelected.channel.whatsappMassive &&
          this.oldTicketSelected.strategy
        ) {
          newObj.origin = 'Campaña'
          newObj.title = `Ticket generado por la campaña “${this.oldTicketSelected.strategy.name}”`
          newObj.color = 'green'
        } else newObj = null
      }
      return newObj
    },
    /**
     * @returns {Object} originTicketLocal
     * @returns {String} originTicketLocal.origin
     * @returns {String} originTicketLocal.title
     * @returns {String} originTicketLocal.channel
     */
    originTicketLocal() {
      return this.ticketOrigin(this.oldTicketSelected, false)
    },

    /**
     * @return {String} ancho del modal
     */
    widthModal() {
      const breakpoint = this.active_breakpoint.breakpoint
      // si es medium
      if (breakpoint === 'md') return '54em'
      else {
        if (this.showComments) return '84em'
        return '64em'
      }
    },
    /**
     * @return {String} altura del cuerpo del modal
     */
    heightBody() {
      const breakpoint = this.active_breakpoint.breakpoint
      // si es extra small
      if (breakpoint === 'xs') return '35em'
      else return '40em'
    },
    /**
     * @return {String} titulo del modal
     */
    titleModal() {
      const isMobile = this.active_breakpoint.is_mobile

      return isMobile
        ? `Ticket  ${this.ticketNumberFormatted(this.oldTicketSelected.ticket)}`
        : `Detalles del ticket  ${this.ticketNumberFormatted(
            this.oldTicketSelected.ticket
          )}`
    },
    /**
     * @return {String} id del ticket
     */
    ticketId() {
      return this.oldTicketSelected ? this.oldTicketSelected._id : null
    },
    /**
     * Campos personalizados del cliente
     * @return {Object[]} customFields
     * @return {String} customFields[].type
     * @return {String} customFields[].name
     * @return {Object} customFields[].value.text
     * @return {Object[]} customFields[].value.options
     * @return {String} customFields[].value.options[].name
     */
    customFieldsClient() {
      if (this.client) return this.client.settings?.customFields
      else if (this.ticket)
        return this.ticket.client.settings.customFields
          ? this.ticket.client.settings.customFields
          : []
      else return []
    },
    /**
     * Data del cliente
     * @return {Object} dataClient
     * @return {String} dataClient.surnames
     * @return {String} dataClient.names
     * @return {Object} dataClient.settings
     * @return {String} dataClient.email
     */
    dataClient() {
      if (!this.ticket) return
      else return this.ticket.client
    },
  },
  methods: {
    ...mapActions(['selectOldTicket', 'paginateMessages', 'listOldTickets']),
    handleOk() {
      this.$emit('close')
    },
    existStripe() {
      const stripe = document.getElementById('stripeSection') ? true : false
      this.stripeSection = stripe
    },
    /**
     * Muestra la seccion de comentarios
     */
    handleShowComments() {
      this.showComments = true
    },
    /**
     * Cierra la seccion de comentarios
     */
    handleClose() {
      this.showComments = false
      this.$emit('close')
    },
    /**
     * Escucha el hover del boton de ver notas
     * @param {Boolean} show
     */
    setShowTitle(show) {
      this.showFullButton = show
    },
  },
}
</script>
<style lang="sass" scoped>
.modal__section-board
  height: 100%
  @include lg
    margin-left: -6%
  @include xl
    margin-left: -6%
  @include xxl
    margin-left: -6%
  &--show-comments
    @include lg
      margin-left: -8%
    @include xl
      margin-left: -8%
    @include xxl
      margin-left: -8%
.modal__panel
  &__content
    height: 40em
    @include xs
      height: calc(35em - 54px)
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
    @include sm
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
    @include md
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
.modal__button-comment
  position: absolute
  right: -50px
  top: 70px
  &--full
    right: -100px
.modal__section-comments
  margin-left: -44px
  @include xs
    margin: 0
    background-color: $gray_3
  @include sm
    margin: 0
    background-color: $gray_3
  @include md
    margin: 0
    background-color: $gray_3
</style>
