import Contacts from '@/app/contacts/views/Contacts'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'agent', 'master_agent'],
      title: `Contactos | ${VUE_APP_NAME}`,
    },
  },
]
