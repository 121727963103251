import Signup from '@/app/signup/views/Signup'
import ActiveAccount from '@/app/signup/views/ActiveAccount'
import RedirectPlan from '@/app/signup/views/RedirectPlan'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      requiresVisitor: true,
      title: `Regístrate | ${VUE_APP_NAME}`,
    },
  },
  {
    path: '/signup/:namePlan',
    name: 'signup-plan',
    component: RedirectPlan,
    meta: {
      requiresVisitor: true,
      title: `Regístrate | ${VUE_APP_NAME}`,
    },
  },
  {
    path: '/active-account',
    name: 'active-account',
    component: ActiveAccount,
    meta: {
      requiresVisitor: true,
      title: `Activar cuenta | ${VUE_APP_NAME}`,
    },
  },
]
