<template>
  <div class="lines-template">
    <SectionLines />
  </div>
</template>
<script>
import SectionLines from '@/app/lines/components/organisms/SectionLines.vue'
export default {
  name: 'LinesTemplate',
  components: {
    SectionLines,
  },
}
</script>

<style lang="sass" scoped>
.lines-template
  margin: $padding-heigth-inner
</style>
