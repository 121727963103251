<template>
  <div class="stripe">
    <p style="margin-bottom: 0px" class="body-2">
      Estás monitoreando en tiempo real a:
      <strong class="heading-h6"
        >{{ profile.names }} {{ profile.surnames }}</strong
      >
    </p>
    <a-button @click="handleCLose">Salir de supervisión</a-button>
  </div>
</template>
<script>
export default {
  name: 'BannerSupervisor',
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleCLose() {
      window.close()
    },
  },
}
</script>
<style lang="sass" scoped>
.stripe
  background-color: $yellow_500
  padding: 10px 20px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  color: $gray_dark_900
</style>
