import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import router from '@/router'

const actions = {
  /**
   * Listar supervision
   * @param context
   * @param self
   * @returns {Promise<void>}
   */
  async listSupervision(context, { self }) {
    try {
      if (self.sectionSupervision.list.data) {
        self.sectionSupervision.list.data = []
        self.sectionSupervision.list.loading.table = true
      }
      const url = `${vari.UHR}/admin/supervision/agents`
      await axios
        .get(url, { params: self.sectionSupervision.list })
        .then((r) => {
          if (r.status === 200) {
            let supervisions = r.data.map((sup) => {
              sup.startTimeMenu = 0
              sup.startTimeEvent = 0
              sup.isTyping = false
              return sup
            })
            if (self.sectionSupervision.list.data)
              self.sectionSupervision.list.data = supervisions
            context.commit('SET_LIST_SUPERVISION', supervisions)
            if (self.sectionSupervision.list.loading)
              self.sectionSupervision.list.loading.table = false
          }
        })
        .catch((e) => {
          console.error('[eventModule][listSupervision]', e)
        })
    } catch (error) {
      console.error('[eventModule][listSupervision]', error)
    }
  },
  async getSimplySupervision(context, userId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/admin/supervision/simply/${userId}`
      )
      const simplySupervision = response.data
      context.commit('SET_SIMPLY_SUPERVISION', simplySupervision)
      context.commit('SET_TICKET_REMOTE_SELECTED', simplySupervision.ticketId)
    } catch (error) {
      console.error('[eventModule][getSimplySupervision]', error)
    }
  },
  /**
   * Guardar el evento actual [ticket seleccionado o llamada]
   * @param {vuex}    context
   * @param {Boolean} keepEventTime Evita enviar una fecha nueva del evento
   * @returns {Promise<void>}
   */
  async saveEventTicket(context, keepEventTime = false) {
    try {
      let client = {}
      if (context.getters.ticketSelected) {
        client = {
          clientId: context.getters.ticketSelected.client.clientId,
          names:
            context.getters.ticketSelected.client.names ===
            context.getters.ticketSelected.phoneFrom
              ? null
              : context.getters.ticketSelected.client.names, // Null si el nombre es el numero
          phone: context.getters.ticketSelected.phoneFrom,
        }
      }

      const payload_rest = {
        event: context.state.lastEvent,
        ticketId: context.getters.ticketSelected
          ? context.getters.ticketSelected._id
          : null,
        client: client,
        ticket: context.getters.ticketSelected
          ? context.getters.ticketSelected.ticket
          : null,
        ticketsPending: context.getters.total_unread_tickets,
      }
      if (!keepEventTime) {
        payload_rest.startedInEvent_at = new Date()
      }
      const payload_socket = {
        ...payload_rest,
        userId: context.getters.profile.userId,
        idCompany: context.getters.profile.company.companyId,
      }
      axios.put(`${vari.UHR}/event/update`, payload_rest)
      this._vm.$socket?.emit('server:update:event', payload_socket)
      // solo hará la petición en rutas autenticadas
      if (
        router.history &&
        router.history.current.meta.requiresAuth &&
        router.history.current.name !== 'password'
      )
        axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
    } catch (error) {
      console.error('[eventModule][saveEventTicket]', error)
    }
  },
  /**
   * TODO: Está acción no se ejecta, revisar luego para que se usaba
   * Actualiza los datos del buzón de un ticket
   * @param {vuex}    context
   * @param {Object}  args
   * @param {Object}  arg.mailbox Objeto del nuevo buzón
   * @param {String}  arg.idTicket Id del ticket
   */
  async emitMailboxTicketChange(context, { mailbox, idTicket }) {
    try {
      await axios.put(`${vari.UHR}/agent/room/mailbox`, {
        idTicket: idTicket,
        mailbox: mailbox,
      })

      const payload_socket = {
        userId: context.getters.user.userId,
        ticketId: idTicket,
        mailbox: mailbox,
      }

      this._vm.$socket.emit('server:ticket:mailbox', payload_socket)
    } catch (error) {
      console.error('[eventModule][emitMailboxTicketChange]', error)
    }
  },
  /**
   * Emite el estado del agente
   * @param {*}      context
   * @param {Object} statusObj Objeto del estado
   * @param {String} statusObj.status Nombre del estado del que se emitirá
   * @param {Date}   statusObj.startedInMenu_at Fecha del estado del que se emitirá
   */
  async emitSocketUserStatus(context, statusObj) {
    try {
      if (!context.getters.profile) return
      if (!(await compare.isAllowedFor(context, ['agent']))) return
      const profile = context.getters.profile
      const payload = {
        idCompany: profile.company.companyId,
        user: {
          idUser: profile.userId,
          status: statusObj.status,
          session: context.getters.loggedIn,
          startedInMenu_at: statusObj.startedInMenu_at,
        },
      }
      this._vm.$socket.emit('server:update:user:status', payload)
    } catch (error) {
      console.error('[userModule][setSocketUserStatus] error >>', error)
    }
  },
  /**
   * Setea el estado del escribiendo del agente remoto
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.userId Id del usuario del agente
   * @param {Boolean} args.istyping Escribiendo?
   */
  async setTypingRemote(context, { userId, istyping }) {
    if (router.history.current.meta.is_monitor) {
      const ticketSelected = context.getters.ticketSelected
      const ticketIdRemoteSelected = context.getters.ticketIdRemoteSelected
      if (!ticketSelected) return
      if (!ticketIdRemoteSelected) return
      if (ticketSelected._id === ticketIdRemoteSelected) {
        context.commit('SET_TYPING_REMOTE', istyping)
      }
    } else {
      if (router.history.current.name === 'supervision') {
        context.commit('SET_TYPING_ACCOUNTS_REMOTE', { userId, istyping })
      }
    }
  },
  async emitSelectedMailbox(context, mailboxId) {
    if (!(await compare.isAllowedFor(context, ['agent']))) return
    const payload_socket = {
      userId: context.getters.profile.userId,
      mailboxId: mailboxId,
    }
    this._vm.$socket.emit('server:mailbox:selected', payload_socket)
  },
  /**
   * Cerrar sesion por socket.io remotamente
   * @param {vuex}    context
   * @param {String}  userId
   * @returns {Promise<void>}
   */
  async remoteLogout(context, userId) {
    try {
      const url = `${vari.UHR}/admin/supervision/logout/${userId}`
      const response = await axios.post(url)
      if (response.data.success) {
        setTimeout(() => {
          let session = false
          let status = 'disconnected'
          let startedInMenu_at = null
          context.commit('SET_STATUS_AGENT_SUPERVISION', {
            userId,
            session,
            status,
            startedInMenu_at,
          })
        }, 2000)
      }
    } catch (error) {
      console.error('[userModule][remoteLogout]', error)
    }
  },

  /**
   * Obtener resumen de supervisión de un usuario
   * @param {vuex}   context
   * @param {String} userId
   */
  async abstractSupervision(context, userId) {
    try {
      const url = `${vari.UHR}/admin/supervision/abstract/${userId}`
      const response = await axios.get(url)
      return response
    } catch (error) {
      console.error('[eventModule][abstractSupervision]', error)
    }
  },
}

export default actions
