<template>
  <a-form :form="form" layout="vertical">
    <!--Esperar respuesta-->
    <a-row class="mrg-bottom-16" type="flex" align="middle">
      <a-checkbox
        :checked="waitAnswer"
        :disabled="action === 'none'"
        @change="handleChangeWait"
      >
        Esperar una respuesta
      </a-checkbox>
      <a-tooltip>
        <template slot="title">
          Tu chatbot esperará una respuesta de tu cliente para continuar.
        </template>
        <a-icon
          type="info-circle"
          class="radio-message__icon"
          style="height: fit-content; margin-top: 2px"
      /></a-tooltip>
    </a-row>
    <!-- Campo de texto para mostrar -->
    <a-form-item
      label="Mensaje"
      class="section-form-item section-form-item--message"
    >
      <p class="help text--left mrg-bottom-8">
        Recuerda que será un mensaje de tipo texto para tus usuarios.
      </p>

      <counter-text
        :maxLength="validations.maxLengthMessage"
        :text="form.getFieldValue('text') ? form.getFieldValue('text') : ''"
      >
        <a-mentions
          v-decorator="[
            'text',
            {
              rules: [
                {
                  required: requiredText,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          ref="message_form_text"
          rows="3"
          placeholder="Escribe aquí..."
          @change="handleChangeText"
          :maxLength="validations.maxLengthMessage"
          class="text--left"
          :prefix="PREFIX_VARS"
          :notFoundContent="notFoundVars"
          placement="bottom"
          :disabled="onlyRead"
          :filterOption="filterOption"
        >
          <a-mentions-option
            v-for="simpleVar in simpleVars"
            :key="simpleVar._id"
            :value="simpleVar.name"
          >
            {{ simpleVar.name }}</a-mentions-option
          >
        </a-mentions>
      </counter-text>
      <menu-options-content
        @onChangeEmoji="handleSetEmoji"
        @onOpenVars="handleOpenVars"
        :disabled-wait-answer="action === 'none' || onlyRead"
        :value-check="waitAnswer"
        :disabled-emoji="onlyRead"
        :show-wait="false"
      />
    </a-form-item>
    <!--Adjuntar archivo-->
    <a-form-item label="Archivo adjunto" class="text--left">
      <a-upload-dragger
        v-decorator="[
          'fileMedia',
          {
            valuePropName: 'fileList',
            getValueFromEvent: normFile,
            rules: [
              {
                required: requiredFile,
                message: 'Por favor sube el archivo',
              },
            ],
          },
        ]"
        name="fileMedia"
        :multiple="false"
        :customRequest="uploadFile"
        :before-upload="beforeUpload"
        :remove="handleRemoveFile"
        :accept="formatsToUpload"
        @preview="handlePreviewModal"
      >
        <p>
          <a-icon type="inbox" class="form__icon--inbox" />
        </p>
        <p class="ant-upload-text">Sube aquí tu archivo</p>
        <p class="ant-upload-hint">
          <span>
            Solo se aceptan
            {{ descriptionUpload }}.
          </span>
        </p>
      </a-upload-dragger>
    </a-form-item>

    <!-- Lista de radiobutton de acciones -->
    <a-form-item
      label="Selecciona una de las acciones"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'action',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
            ],
          },
        ]"
        placeholder="Selecciona"
        @change="handleChangeAction"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="actionToNode in actionsToNodeSelected"
          :key="actionToNode.value"
          :disabled="
            (actionToNode.value === 'continue_message' && disabledAddMessage) ||
            (actionToNode.actionToSave && actionToNode.actionToSave.disabled)
          "
          class="form__selector__option"
        >
          <a-popover
            v-if="
              actionToNode.value === 'continue_message' && disabledAddMessage
            "
            title="Agregar un nuevo mensaje"
            placement="left"
          >
            <template slot="content">
              <p>
                Recuerda que solo puedes tener <br />
                2 mensajes seguidos sin esperar <br />
                respuesta.
              </p>
            </template>
            <div class="selector__option-container">
              {{ actionToNode.title }}
            </div>
          </a-popover>
          <span v-else>
            {{ actionToNode.title }}
            <i
              v-if="
                actionToNode.value !== action &&
                actionToNode.actionToSave &&
                actionToNode.actionToSave.disabled
              "
              >( No hay variables disponibles )</i
            >
          </span>
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="action === 'transfer'"
      label="Selecciona una cola"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'lineId',
          {
            rules: [{ required: true, message: 'Completa el campo' }],
          },
        ]"
        placeholder="Selecciona"
        @change="handleChangeLines"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="line in dataSourceLines"
          :key="line.key"
          :value="line.key"
        >
          {{ line.title }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <template v-if="description">
      <p class="body-2 text--left">
        {{ description }}
      </p>
    </template>
    <modal-preview-media
      :visible="modalPreviewMedia.visible"
      :title="modalPreviewMedia.title"
      :type="modalPreviewMedia.type"
      :url="modalPreviewMedia.url"
      @onClose="handleCloseModalPreview"
    />
  </a-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CounterText from '@/app/shared/components/molecules/CounterText'
import transformActionNode from '@/app/shared/mixins/transform'
import actionsToNodeSelected from '@/app/chatbots/mixins/actionsToNodes'
import verifyAddDefaultNode from '@/app/chatbots/mixins/actionsToNodes'
import MenuOptionsContent from '@/app/chatbots/components/molecules/MenuOptionsContent'
import { PREFIX_VARS } from '@/app/chatbots/utils/componentsDataNodes'
import multimediaMixin from '@/app/chatbots/mixins/multimediaMixin'
import filterSimpleVarsBySave from '@/app/chatbots/mixins/actionsToNodes'
import ModalPreviewMedia from '@/app/chatbots/components/organisms/modals/ModalPreviewMedia'

export default {
  name: 'MessageForm',
  components: {
    CounterText,
    MenuOptionsContent,
    ModalPreviewMedia,
  },
  mixins: [
    transformActionNode,
    actionsToNodeSelected,
    verifyAddDefaultNode,
    filterSimpleVarsBySave,
    multimediaMixin,
  ],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeId: {
      type: String,
      required: true,
    },
    parentNodeId: {
      type: String,
      required: true,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    PREFIX_VARS,
    waitAnswer: false,
    activeMessageTransfer: true,
    action: null,
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
    actionNodeId: null,
    actionNodeType: null,
    isChangeAction: false,
    modalPreviewMedia: {
      visible: false,
      type: '',
      url: '',
      title: '',
    },
    requiredText: true,
    requiredFile: false,
    fileUploaded: {},
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'message-form-chatbot' })
  },
  computed: {
    ...mapGetters([
      'nodes',
      'simple_chatbots_vars',
      'selectedNodeId',
      'validations_chatbot',
    ]),

    /**
     * @returns {Boolean} - verifica si el agregar mensaje deberia estra deshabilitado
     */
    disabledAddMessage() {
      const parentNode = this.nodes.find(
        (node) => node._id === this.parentNodeId
      )
      if (
        parentNode !== undefined &&
        parentNode.drawer.question.type === 'message'
      ) {
        if (parentNode.drawer.action.wait === false && !this.waitAnswer)
          return true
        else return false
      }
      return false
    },
    /**
     * @returns {String} - descripcion de la accion seleccionada
     */
    description() {
      if (!this.action) return null
      const actionSelected = this.actionsToNodeSelected.find(
        (actionToNodeSelected) => actionToNodeSelected.action === this.action
      )
      return actionSelected.description || null
    },
    /**
     * @returns {Boolean} - verifica si existe un nodo de tipo catcher
     */
    existCatcher() {
      return this.verifyExistCatcher({ nodeId: this.nodeId })
    },
    /**
     * Filtra las variables simples de acuerdo al id del nodo
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVars() {
      return !this.existCatcher
        ? []
        : this.filterSimpleVarsBySave({ nodeId: this.nodeId })
    },
    /**
     * @returns {String} - mensaje que aparecerá cuando no haya datos
     */
    notFoundVars() {
      return !this.existCatcher
        ? 'Aún no haz agregado "Capturar variable"'
        : 'No se han encontrado variables'
    },
    /**
     * @return {String} - descripción de los formatos para subir archivo
     */
    descriptionUpload() {
      const multimedias = this.multimediaForNode.map(
        (multimedia) =>
          `${multimedia.title} (max. ${multimedia.max_size.title})`
      )
      return multimedias.join(', ')
    },
    /**
     * @return {String} - formatos permitidos para subir archivo
     */
    formatsToUpload() {
      const formats = this.multimediaForNode.map(
        (multimedia) => multimedia.formats.accept
      )
      return formats.join(', ')
    },
    /**
     * @return {Object} - validations
     * @return {Number} - validations.maxLengthMessage
     * @return {String} - validations.maxLengthErrorMessage
     */
    validations() {
      const node = 'message'
      return this.validations_chatbot[node]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions([
      'updateNodeMessage',
      'uploadFileToNode',
      'deleteFileToNode',
    ]),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.text
     * @param {Object} args.action
     * @param {String} args.action.type
     * @param {String} args.action.withMessage
     * @param {String} args.action.wait
     * @param {String} args.action.lineId
     * @param {String} args.action.message
     * @param {String} args.action.nodeType
     */
    setValues({ text, action, media }) {
      this.action = this.transformActionNode({
        type: action.type,
        nodeType: action.nodeType,
      })

      this.$nextTick(() => {
        this.form.setFieldsValue({
          text,
          action: this.action,
          message: action.message,
          lineId: action.lineId,
          wait: action.wait,
          fileMedia: media
            ? [
                {
                  uid: '1',
                  name: media.originalName,
                  status: 'done',
                  url: media.urlStorage,
                  type: media.type,
                },
              ]
            : [],
        })
      })

      this.actionNodeId = action?.nodeId
      this.actionNodeType = action?.nodeType
      this.waitAnswer = action.wait
      this.activeMessageTransfer =
        action.type === 'transfer' ? action.withMessage : false
      this.fileUploaded = media

      // si ya hay media, el texto no es requerido
      if (media) this.requiredText = false
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Cambia de accion
     * @param {String} value - transfer, contine_message, continue_options, none, rollback
     */
    handleChangeAction(value) {
      this.action = value
      this.setIsSavedItem(false)
      this.isChangeAction = true
    },
    /**
     * Cambia el valor de wait
     * @param {Boolean} wait - espera respuesta o no
     */
    handleChangeWait(event) {
      this.waitAnswer = event.target.checked
      this.setIsSavedItem(false)
    },
    /**
     * Escucha el cambios de colas
     */
    handleChangeLines() {
      this.setIsSavedItem(false)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        values.wait = this.waitAnswer
        // si la accion del nodo se cambio y tiene un hijo
        if (this.isChangeAction && this.hasChild) {
          const textSure = this.transformWordGender(
            'seguro',
            this.profile.gender
          )
          let self = this
          this.$confirm({
            title: `¿Estás ${textSure} de cambiar la acción del mensaje`,
            content:
              'Recuerda que al cambiar la acción, los mensajes siguientes serán eliminados.',
            okText: 'Guardar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
              self.handleUpdateNodeMessage(values)
              self.setIsSavedItem(true)
              self.isChangeAction = false
            },
          })
          return
        }
        this.handleUpdateNodeMessage(values)
        this.setIsSavedItem(true)
        this.isChangeAction = false
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.text - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     */
    async handleUpdateNodeMessage(values) {
      const settings = {
        drawer: {
          action: {
            nodeId: this.actionNodeId,
            nodeType: this.actionNodeType,
          },
        },
      }
      const message = {
        message: values.text,
        wait: values.wait,
        type_action: values.action,
        nodeId_action: settings.drawer.action.nodeId,
        lineId_action: values.lineId ? values.lineId : null,
        message_action: values.message ? values.message : null,
        withMessage: this.activeMessageTransfer,
        media: this.fileUploaded,
      }

      const { nodeType, type_action, nodeId_action } =
        await this.verifyAddDefaultNode({
          action: values.action,
          nodeIdAction: settings.drawer.action?.nodeId,
          nodeTypeAction: settings.drawer.action.nodeType,
        })
      if (type_action) message.type_action = type_action
      if (nodeType) message.nodeType = nodeType
      if (nodeId_action) message.nodeId_action = nodeId_action

      const responseMessage = await this.updateNodeMessage({
        chatbotId: this.chatbotId,
        nodeId: this.nodeId,
        nodeMessage: message,
      })
      if (responseMessage._id) {
        this.$emit('onChangeSettings', responseMessage)
        this.$message.success('Se agregó correctamente')
      } else {
        if (responseMessage.result.includes('down'))
          this.$message.error(
            'Este mensaje tiene 2 mensajes seguidos sin espera de respuesta 😪'
          )
        else if (responseMessage.result.includes('up'))
          this.$message.error(
            'Existen 2 mensajes seguidos arriba de este nodo 😪'
          )
        else if (responseMessage.details)
          this.$message.error(responseMessage.details)
        else this.$message.error('Ocurrió un error 😪')
      }
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      let text = this.form.getFieldValue('text')
      text = text + emoji
      this.form.setFieldsValue({
        text,
      })
      this.setIsSavedItem(false)
    },
    /**
     * Escucha el cambio de texto y verifica si el archivo es requerido
     * @param {String} text
     */
    handleChangeText(text) {
      this.setIsSavedItem(false)

      // si existe texto, el archivo no sera requerido
      if (text && !!text.length) this.requiredFile = false
    },
    /**
     * Muestra el listado de variables
     * @param {String} field - nombre de campo en el formulario
     */
    handleOpenVars() {
      let text = this.form.getFieldValue('text')
      const textArray = text.split('')
      // si el ultimo valor no es el prefijo de variables, seteará el prefijo al texto
      if (textArray.pop() !== this.PREFIX_VARS) {
        text = text + this.PREFIX_VARS
        this.form.setFieldsValue({
          text,
        })
      }

      this.$refs.message_form_text.focus()
      // Importante no borrar, porque si no, no renderizara el listado de variables
      setTimeout(() => {
        this.simulateKey(this.PREFIX_VARS, 'up')
      }, 0)
    },
    /**
     * Simula el #
     * @param {Number} keyCode The keyCode of the key to simulate
     * @param {String} type (optional) The type of event : down, up or press. The default is down
     * @param {Object} modifiers (optional) An object which contains modifiers keys { ctrlKey: true, altKey: false, ...}
     */
    simulateKey(keyCode, type, modifiers) {
      const mentions = document.getElementById('message-form-chatbot_text')
      var evtName = typeof type === 'string' ? 'key' + type : 'keydown'
      var modifier = typeof modifiers === 'object' ? modifier : {}
      var event = document.createEvent('HTMLEvents')
      event.initEvent(evtName, true, false)
      event.key = keyCode
      for (var i in modifiers) {
        event[i] = modifiers[i]
      }
      mentions.dispatchEvent(event)
    },
    /**
     * Filtra el valor de una opcion
     * @param {String} input
     * @param {Object} option
     */
    filterOption(input, option) {
      const optionFound = option.children[0].text.toLowerCase()
      const optionTrim = optionFound.trim()

      if (input.toLowerCase().includes(optionTrim)) {
        return optionFound
      } else {
        return optionFound.indexOf(input.toLowerCase()) >= 0
          ? optionFound
          : null
      }
    },
    /**
     * Obtiene el archivo que se subio para tomarlo en el formulario
     * @param {Object} e - evento
     * @return {Object[]} fileList
     * @return {String} fileList[].name
     * @return {String} fileList[].status
     * @return {String} fileList[].url
     */
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList && e.fileList.filter((file) => file.status)
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     */
    beforeUpload(file) {
      this.handleRemoveFile(false)

      const multimedia = this.typeMultimedia(file)

      if (!multimedia) {
        this.$message.error('Archivo no permitido')
        return false
      }

      // verificar el tamaño del archivo
      const isValidSize = file.size / 1024 / 1024 <= multimedia.max_size.size
      if (!isValidSize)
        this.$message.error(
          `El tamaño máximo para ${multimedia.title} es de ${multimedia.max_size.size}`
        )
      return isValidSize
    },
    /**
     * Subida local
     * @param {Object}   args
     * @param {String}   args.action
     * @param {File}     args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFile({ file, onSuccess, onError }) {
      const multimedia = this.typeMultimedia(file)
      await this.uploadFileToNode({
        file,
        type: multimedia.type,
      })
        .then((res) => {
          if (!res.success) {
            this.$message.error(
              res.details || 'Ocurrió un error mientras se subía el archivo 😥.'
            )
            this.handleRemoveFile()
            return
          }
          onSuccess(res.urlFile)
          this.requiredText = false
          this.$message.success('Se adjunto el archivo exitosamente')
          this.fileUploaded = res.urlFile
          this.setIsSavedItem(false)
        })
        .catch((err) => {
          console.error('[uploadFile]', err)
          this.$message.error(
            err.details || 'Ocurrió un error mientras se subía el archivo 😥.'
          )
          onError(err)
        })
    },
    /**
     * Devuelve el tipo de multimedia de acuerdo al archivo subido
     * @param {File} file
     * @return {Object} multimedia
     * @return {String} multimedia.type
     * @return {String} multimedia.title
     * @return {Object} multimedia.formats
     * @return {String[]} multimedia.formats.titles
     * @return {String[]} multimedia.formats.values
     * @return {String} multimedia.formats.accept
     * @return {Object} multimedia.max_size
     */
    typeMultimedia(file) {
      const type = file.type
      const multimedia = this.multimediaForNode.find((multimedia) =>
        multimedia.formats.accept.includes(type)
      )
      return multimedia
    },
    /**
     * Remueve el archivo subido
     * @param {Boolean} isChange - si debería considerarse como cambio
     */
    async handleRemoveFile(isChange) {
      // this.fileList = []
      this.form.resetFields(['fileMedia'])
      this.fileUploaded = {}
      // el texto se hace requerido
      this.requiredText = true
      if (isChange) this.setIsSavedItem(false)
    },
    /**
     * Previsualiza el archivo en un modal
     * @param {File}
     */
    handlePreviewModal(file) {
      if (file.response) {
        file.type = file.response.type
        file.url = file.response.urlStorage
      }
      // si es de tipo documento
      if (file.type === 'document') return window.open(file.url)
      // si es de tipo video o imagen
      this.modalPreviewMedia.title = file.name || file.originalName
      this.modalPreviewMedia.type = file.type
      this.modalPreviewMedia.url = file.url
      this.modalPreviewMedia.visible = true
    },
    /**
     * Cierra el modal de previsualizacion
     */
    handleCloseModalPreview() {
      this.modalPreviewMedia = {
        visible: false,
        type: '',
        url: '',
        title: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 14px
.form__selector__option
  width: 100%
.selector__option-container
  width: calc(100% + 12px)
  margin-left: -12px
  padding-left: 12px
.form__icon--inbox
  font-size: 24px
</style>
