s
<template>
  <div class="section-schedule">
    <header-module
      title="AGENDA"
      tipography="heading-h5"
      class="section__header"
      :link="`${vari.WEB_DOC}primeros-pasos/recordatorios-agenda/`"
      text-before-link="Mira en el calendario todos tus recordatorios creados."
      text-after-link="si deseas conocer más."
      textLink="Click aquí"
      positionTooltip="right"
    />
    <div class="section-schedule-container">
      <div class="legend">
        <div class="legend legend-1">
          <div class="block block-yellow"></div>
          <span>Pendientes</span>
        </div>
        <div class="legend legend-1">
          <div class="block block-red"></div>
          <span>Vencidos</span>
        </div>
        <div class="legend legend-1">
          <div class="block block-green"></div>
          <span>Finalizados</span>
        </div>
      </div>
      <Calendar />
    </div>
  </div>
</template>
<script>
import vari from '@/app/shared/utils/variables'
import Calendar from '@/app/schedule/components/organisms/ScheduleCalendar.vue'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionSchedule',
  components: {
    Calendar,
    HeaderModule,
  },
  data: () => ({
    vari,
  }),
  mounted() {},
  methods() {},
}
</script>

<style lang="sass" scoped>
.section-schedule
.title
  text-align: left
  margin-bottom: 12px
  margin-left: 12px
  margin-top: 20px
  color: $gray_dark_900
.section-schedule-container
  background-color: $white_000
  padding: 32px
  height: calc( 100vh - #{$padding-heigth-inner * 6} )
  margin: $padding-heigth-inner
  border-radius: 8px
  // overflow-y: auto
  .legend
    display: flex
    flex-direction: row
    margin: 0 0 8px 0
  .legend-1
    align-items: center
    margin-right: 20px
  .block
    width: 12px
    height: 12px
    margin-right: 8px
    border-radius: 100%
  .block-yellow
    background-color: $yellow_900
  .block-red
    background-color: #FF5757
  .block-green
    background-color: #52C41A
  .box-shadow-top
  box-shadow: 0px -1px 5px -3px rgba(0, 0, 0, 0.1)
.section
  &__header
    display: flex
    align-items: center
    margin: 20px auto 10px 12px
  &__icon
    margin-left: 8px
    font-size: 18px
    cursor: pointer
</style>
