const initialState = () => ({
  company: null,
  maxMailboxes: 5, // Máxima cantidad de Buzones a aceptar
  sandboxChannels: [], // Canales de la sandbox disponibles para usar
  notificationsPhones: {},
  industries: [
    'Banca',
    'Comidas y bebidas',
    'Contact Center',
    'Ecommerce',
    'Educación',
    'Entretenimiento',
    'Fintech',
    'Gobierno',
    'Hoteles',
    'Informática',
    'Mobiliario',
    'Retail',
    'Ropa y moda',
    'Seguros',
    'Servicios Financieros',
    'Supermercados',
    'Software',
    'Turismo',
    'Otros',
  ],
  languages: ['Español', 'Portugués', 'Francés', 'Inglés'],
  operations: [
    { value: 'Atención al cliente', key: 'ATC' },
    { value: 'Ventas', key: 'tele_ventas' },
    { value: 'Marketing', key: 'tele_marketing' },
    { value: 'Cobranzas', key: 'Cobranzas' },
  ],
  company_wallet: null,
})

export default initialState
