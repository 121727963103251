<template>
  <div class="card display-flex align--center justify--between">
    <div class="display-flex align--center">
      <!--icon de card -->
      <template v-if="icon.custom">
        <social-icon
          v-if="icon.social"
          :type="icon.social"
          class="card__icon"
        />
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon.src" v-bind:svg-inline="''" v-bind:class="'card__icon card__icon-default'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 8v3.5l-4 3V20h4a2 2 0 002 2h2v1h2v-1h2a2 2 0 002-2h4v-5.5l-4-3V8c0-3.156-1.914-7-5-7-3.086 0-5 3.844-5 7zm6 12h2V8c0-2.193-1.398-5-3-5S9 5.807 9 8v12h2v-6h2v6zm-8-4.5L7 14v4H5v-2.5zM17 18v-4l2 1.5V18h-2zm-5-7a2 2 0 110-4 2 2 0 010 4z" fill="#000"/></svg>
      </template>
      <a-icon v-else :type="icon" class="card__icon" />
      <div>
        <h6 class="semibold-14 card__title text--left">{{ title }}</h6>
        <p class="regular-14 mrg-bottom-0 text--left">{{ description }}</p>
      </div>
    </div>
    <div>
      <p class="regular-14 card__price text--right">{{ price }}</p>
      <a @click="$emit('onRemove')">Quitar</a>
    </div>
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'CardPurchase',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Title',
    },
    description: {
      type: String,
      required: false,
      default: 'This is a description',
    },
    price: {
      type: String,
      required: false,
      default: '$0',
    },
    // soporta objeto porque admite que se pueda customizar
    icon: { type: [String, Object], default: 'heart', required: false },
  },
  components: {
    SocialIcon,
  },
  data: () => ({}),
  computed: {},
}
</script>

<style lang="sass" scoped>
.card
  padding: 16px 20px
  border: 1px solid $gray_5
  background-color: $gray_2
  border-radius: 2px
  margin-bottom: 8px
  width: 100%
  &__icon
    font-size: 24px
    height: 24px
    width: 24px
    margin-right: 12px
    &-default
      fill: $orange_6
      path
        fill: $orange_6
  &__title
    margin-bottom: 2px
  &__price
    margin-bottom: 2px
</style>
