<template>
  <a-modal
    :visible="visible"
    :title="title"
    :bodyStyle="bodyStyle"
    width="40em"
    centered
    @cancel="$emit('onClose')"
    :footer="null"
  >
    <!--Card de saldo de whatsapp-->
    <p class="regular-14 mrg-bottom-12">
      Recuerda que los datos mostrados a continuación están basados a partir de
      los registros válidos .
    </p>
    <!--Mostrar los gastos de la campaña-->
    <a-row
      v-if="wallet.channel"
      class="modal__card flex-no-wrap"
      type="flex"
      align="middle"
    >
      <social-icon :type="wallet.channel" class="mrg-right-12" />
      <div class="modal__card-container">
        <h6 class="semibold-14 mrg-bottom-4 modal__card__title">
          Saldo de {{ transformApiChannel(wallet.channel) }}
        </h6>
        <a-row type="flex" justify="space-between" align="middle">
          <p class="mrg-bottom-0">
            Gastado en esta campaña:
            <strong class="semibold-16 modal__card__text--strong"
              >${{ wallet.spent }}</strong
            >
          </p>
          <a-divider type="vertical" class="modal__divider" />

          <p class="mrg-bottom-0">
            Disponible:
            <strong class="semibold-16 modal__card__text--strong"
              >${{ wallet.available }}</strong
            >
          </p>
        </a-row>
      </div>
    </a-row>
    <underline-title
      :title="titleForDashboard"
      tipography="semibold-14"
      class="modal__subtitle"
    />
    <a-row
      type="flex"
      align="middle"
      justify="space-between"
      class="modal__container-total"
    >
      <p class="regular-14 mrg-bottom-0">
        {{ titleForTotalDashboard }}: {{ total }}
      </p>
      <a-button @click="handleGetData" :disabled="disabledGetData">
        <a-icon type="sync" :spin="spin" />Traer datos recientes
      </a-button>
    </a-row>
    <PolarChart :styles="myStyles" :chartdata="chartdata" />
    <p class="regular-14 text--center mrg-bottom-0 mrg-top-24">
      <anchor :href="`${vari.WEB_DOC}campanas/resultados-campanas-masivas/`"
        >Click aquí</anchor
      >
      para conocer el detalle de cada estado.
    </p>
    <basic-footer
      okIcon="download"
      okText="Descargar dashboard"
      cancelText="Cancelar"
      @onCancel="$emit('onClose')"
      @onOk="exportValidStrategyRecords(dataSource._id)"
    />
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import UnderlineTitle from '@/app/settings/components/organisms/alertsMenu/UnderlineTitle'
import PolarChart from '@/app/dashboard/components/organisms/charts/PolarChart.vue'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import Anchor from '@/app/shared/components/atoms/Anchor'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ModalDashboard',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    wallet: {
      type: Object,
      required: true,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  components: {
    SocialIcon,
    UnderlineTitle,
    PolarChart,
    BasicFooter,
    Anchor,
  },
  mixins: [transformMixin],
  data: () => ({
    vari,
    bodyStyle: {
      padding: '32px 40px 80px',
    },
    myStyles: {
      height: '300px',
      position: 'relative',
    },
    spin: false,
    disabledGetData: false,
  }),
  computed: {
    /**
     * @return {String} total de plantillas
     */
    total() {
      if (!this.dataSource || !this.dataSource.stats_import) return 0
      return this.dataSource.stats_import.successes
    },
    /**
     * Estados para la campañas, usados solo para el dashboard
     * @return {Objec[]}
     * @return {String} label - nombre del estado
     * @return {Number} order - orden del estado
     * @return {Number} quantity - cantidad de plantillas enviadas por estado
     * @return {String} status - estado de la campaña
     */
    statuses() {
      const arr = []
      const statuses = { ...this.dataSource.stats_send }
      delete statuses.total_sent
      delete statuses.pendings
      for (let property in statuses) {
        const newObj = {}
        switch (property) {
          case 'queued':
            newObj.label = 'En cola'
            newObj.order = 0
            break
          case 'sent':
            newObj.label = 'Enviados'
            newObj.order = 1
            break
          case 'delivered':
            newObj.label = 'Entregados'
            newObj.order = 2
            break
          case 'read':
            newObj.label = 'Leidos'
            newObj.order = 3
            break
          case 'ignored':
            newObj.label = 'Ignorados'
            newObj.order = 4
            break
          case 'failed':
            newObj.label = 'Fallidos'
            newObj.order = 5
            break
          // case 'pending':
          //   newObj.label = 'Pendientes'
          //   newObj.order = 6
          //   break
        }
        newObj.quantity = this.dataSource.stats_send[property]
        newObj.status = property
        arr.push(newObj)
      }
      return arr.sort((a, b) => a.order - b.order)
    },
    /**
     * @return {Object} data usada para los graficos
     */
    chartdata() {
      const data = {
        labels: this.statuses.map((item) => item.label),
        datasets: [
          {
            data: this.statuses.map((item) => item.quantity),
            backgroundColor: [
              'rgba(194, 47, 179, 0.5)',
              'rgba(47, 194, 91, 0.5)',
              'rgba(255, 232, 24, 0.3)',
              'rgba(24, 144, 255, 0.5)',
              'rgba(191, 191, 191, 0.5)',
              'rgba(255, 58, 58, 0.5)',
              // 'rgba(250, 140, 22, 0.5)',
            ],
            borderWidth: 0.5,
            borderColor: [
              'rgba(194, 47, 179, 0.5)',
              'rgba(47, 194, 91, 0.5)',
              'rgba(255, 232, 24, 0.3)',
              'rgba(24, 144, 255, 0.5)',
              'rgba(191, 191, 191, 0.5)',
              'rgba(255, 58, 58, 0.5)',
              // 'rgba(250, 140, 22, 0.5)',
            ],
          },
        ],
      }
      return data
    },
    /**
     * @return {String} título para el total de envios
     */
    titleForTotalDashboard() {
      const channel = this.dataSource?.channel_company?.channel || 'whatsapp'

      switch (channel) {
        case 'whatsapp':
          return 'Total de plantillas'
        case 'sms':
          return 'Total de sms'
        default:
          return 'Total de envios'
      }
    },
    /**
     * @return {String} título para el modal
     */
    title() {
      const channel = this.dataSource?.channel_company?.channel

      if (channel) return `Dashboard de campaña - ${channel}`
      else return 'Dashboard de campaña'
    },
    /**
     * @return {String} titulo para el dashboard
     */
    titleForDashboard() {
      const channel = this.dataSource?.channel_company?.channel

      switch (channel) {
        case 'whatsapp':
          return 'Detalle de plantillas enviadas'
        case 'sms':
          return 'Detalle de sms enviadas'
        default:
          return 'Detalle de envios'
      }
    },
  },
  methods: {
    ...mapActions([
      'exportValidStrategyRecords',
      'getCompanyStrategy',
      'getCompanyWallet',
    ]),

    /**
     * Obtiene la data por cada campaña
     */
    async handleGetData() {
      this.disabledGetData = true
      this.spin = true
      const channel = this.dataSource?.channel_company?.channel
      switch (channel) {
        case 'whatsapp':
          this.getCompanyWallet()
          break
        default:
          console.log('not have wallet 😥')
      }
      await this.getCompanyStrategy(this.dataSource._id)
      this.disabledGetData = false
      this.spin = false
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__card
    border: 1px solid $gray_5
    border-radius: 4px
    padding: 12px
    &__title
      color: $gray_8
    &__text--strong
      color: $gray_9
  &__subtitle
    margin: 16px 0px
  &__container-total
    margin-bottom: 20px
  &__divider
    margin: 0px 20px
    height: 16px
    color: $gray_5
</style>
