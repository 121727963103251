<template>
  <div class="card-chat-contact">
    <div class="card-chat-contact_content">
      <span class="title">{{ title }}</span>
      <hr />
      <span class="space_bottom">{{ firstContact.formattedName }}</span>
      <ul>
        <li v-for="phone in firstContact.phones" :key="phone">
          <span>{{ phone }}</span>
        </li>
      </ul>
      <a v-if="contacts.length > 1" class="a_corner" @click="switchModal"
        >Ver todos</a
      >
    </div>
    <a-modal
      :visible="visibleModal"
      :title="`${title} compartidos`"
      @ok="switchModal"
      centered
      @cancel="visibleModal = false"
      :footer="null"
    >
      <ul class="modal__list">
        <li
          v-for="contact in contacts"
          :key="contact.formattedName.replace(' ', '')"
        >
          <span>{{ contact.formattedName }}</span>
          <ul>
            <li v-for="phone in contact.phones" :key="phone">
              <span>{{ phone }}</span>
            </li>
          </ul>
          <hr />
        </li>
      </ul>
      <basic-footer
        :showCancel="true"
        :showOk="false"
        cancelText="Cerrar contactos"
        @onCancel="visibleModal = false"
      />
    </a-modal>
  </div>
</template>

<script>
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'

export default {
  name: 'CardChatContact',
  props: {
    contacts: {
      type: Array,
      name: { type: String },
      phones: { type: Array },
    },
  },
  components: {
    BasicFooter,
  },
  data: () => ({
    visibleModal: false,
  }),
  computed: {
    /**
     * @return {String} titulo de la card y el modal
     */
    title() {
      return this.contacts && this.contacts.length > 1
        ? 'Contactos'
        : 'Contacto'
    },
    /**
     * @return {Object} firstContact
     * @return {String} firstContact.formattedName
     * @return {String[]} firstContact.phones
     */
    firstContact() {
      return this.contacts[0]
    },
  },
  methods: {
    switchModal() {
      this.visibleModal = !this.visibleModal
    },
  },
}
</script>

<style lang="sass" scoped>
.card-chat-contact
  &_content
    text-align: left
    width: 230px
    position: relative
    .title
      font-weight: bold
    .space_bottom
      margin: 0 0 8px 0
      display: block
    .a_corner
      position: absolute
      right: 0
      // bottom: 0
ul, li
  padding: 0
  margin: 0
  list-style: none
.modal__list
  margin-bottom: 48px
</style>
