const PERMISSIONS = {
  master_agent: {
    sinonyms: ['master'],
    general: {
      show_menu_companies: true,
      show_menu_notifications: true,
    },
    notifications: {
      show_module: true,
      filter: true,
      show_company: true,
    },
    accounts: {
      filter: true,
      users_to_manage: ['master_agent', 'admin', 'agent'],
    },
    profile: {
      menus: ['menu-user', 'menu-permissions', 'menu-company', 'menu-guide'],
    },
    lines: {
      create: true,
      update: true,
    },
  },
  admin: {
    general: {
      show_menu_companies: false,
      show_menu_notifications: false,
    },
    notifications: {
      show_module: false,
    },
    accounts: {
      filter: true,
      users_to_manage: ['admin', 'supervisor', 'agent'],
    },
    profile: {
      menus: ['menu-user', 'menu-company', 'menu-guide'],
    },
    lines: {
      create: true,
      update: true,
    },
  },
  admin_agent: {
    general: {
      show_menu_companies: false,
      show_menu_notifications: true,
    },
    notifications: {
      show_module: true,
      filter: false,
      show_company: false,
    },
    accounts: {
      filter: true,
      users_to_manage: ['admin', 'supervisor', 'agent'],
    },
    profile: {
      menus: ['menu-user', 'menu-company', 'menu-guide'],
    },
    lines: {
      create: true,
      update: true,
    },
  },
  agent: {
    general: {
      show_menu_companies: false,
      show_menu_notifications: true,
    },
    notifications: {
      show_module: true,
      filter: false,
      show_company: false,
    },
    profile: {
      menus: ['menu-user', 'menu-guide'],
    },
  },
}

export default PERMISSIONS
