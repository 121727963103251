import Companies from '@/app/companies/views/Companies'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/sub-accounts',
    name: 'sub-accounts',
    component: Companies,
    meta: {
      requiresAuth: true,
      requiresType: ['master_agent'],
      title: `Subcuentas | ${VUE_APP_NAME}`,
    },
  },
]
