import { MULTIMEDIA_SUPPORT } from '@/app/tickets/utils/multimediaUtil'

export default {
  methods: {
    /**
     * Devuelve el tipo de multimedia de acuerdo al archivo subido
     * @param {File} file
     * @param {String} typeFile
     * @return {Object} multimedia
     * @return {String} multimedia.type
     * @return {String} multimedia.title
     * @return {Object} multimedia.formats
     * @return {String[]} multimedia.formats.titles
     * @return {String[]} multimedia.formats.values
     * @return {String} multimedia.formats.accept
     * @return {Object} multimedia.max_size
     */
    typeMultimedia({ file, typeFile }) {
      const type = file ? file.type : typeFile
      if (!type) return
      const arrMultimedia = Object.values(MULTIMEDIA_SUPPORT)
      const multimedia = arrMultimedia.find((multimedia) =>
        multimedia.formats.accept.includes(type)
      )
      return multimedia
    },
  },
}
