import Products from '@/app/products/views/Products'
// import components from Products
import SectionProducts from '@/app/products/components/sections/SectionProducts'
import SectionFields from '@/app/products/components/sections/SectionFields'

export default [
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'products-menu-products',
        path: 'my-products',
        component: SectionProducts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'products-menu-fields',
        path: 'custom-fields',
        component: SectionFields,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]
