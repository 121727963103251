import fastAnswersModule from './fastAnswersModule'
import customFieldsModule from './customFieldsModule'
import blacklistModule from './blacklistModule'
import tagsModule from './tagsModule'

const modules = {
  fastAnswersModule,
  customFieldsModule,
  blacklistModule,
  tagsModule,
}

export default {
  modules,
}
