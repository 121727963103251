<template>
  <svg
    class="icon-key"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66699 7.50033C6.66699 4.27866 9.27866 1.66699 12.5003 1.66699C15.722 1.66699 18.3337 4.27866 18.3337 7.50033C18.3337 10.722 15.722 13.3337 12.5003 13.3337H10.8337V15.0003H9.16699V16.667H7.50033V18.3337H1.66699V13.8218L6.79033 8.69848C6.70862 8.30738 6.66699 7.90641 6.66699 7.50033ZM9.16699 11.667H12.5003C14.8015 11.667 16.667 9.80151 16.667 7.50033C16.667 5.19914 14.8015 3.33366 12.5003 3.33366C10.1991 3.33366 8.33366 5.19914 8.33366 7.50033C8.33366 7.91585 8.39423 8.32231 8.51206 8.71105L8.6569 9.18893L3.33366 14.5122V16.667H5.83366V15.0003H7.50033V13.3337H9.16699V11.667ZM10.8337 7.50033C10.8337 8.4208 11.5799 9.16699 12.5003 9.16699C13.4208 9.16699 14.167 8.4208 14.167 7.50033C14.167 6.57985 13.4208 5.83366 12.5003 5.83366C11.5799 5.83366 10.8337 6.57985 10.8337 7.50033Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChatbot',
  props: {
    fill: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
