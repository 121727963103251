import countriesModule from './countriesModule'
import introductionModule from './introductionModule'
import timezonesModule from './timezonesModule'

const modules = {
  countriesModule,
  introductionModule,
  timezonesModule,
}

export default {
  modules,
}
