<template>
  <div>
    <a-form
      v-if="defaultStep === 0"
      :form="form"
      layout="vertical"
      @submit.prevent="handleSubmit"
      class="form"
      autocomplete="off"
    >
      <a-alert
        v-if="error.visible"
        type="error"
        :message="error.message"
        show-icon
        class="mrg-bottom-24"
      />

      <a-form-item label="Usuario">
        <a-input
          v-decorator="[
            'user',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item label="Contraseña">
        <a-input-password
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          autocomplete="new-password"
        />
      </a-form-item>
      <p class="body-3 form__extra-info">
        Si todavía no cuentas con un usuario y contraseña, contáctate con el
        equipo Comercial de Tedexis.
      </p>
      <div class="form__footer">
        <a-button @click="$emit('onCancel')" class="footer_button__default"
          >Volver</a-button
        >
        <a-button html-type="submit" type="primary" :loading="loading"
          >Integrar con Tedexis</a-button
        >
      </div>
    </a-form>
    <div v-else-if="defaultStep === 1" class="text--center form__success">
      <img
        src="@/app/shared/assets/whoooa_graphics/solid/notifications-advanced-0.svg"
        height="200"
        width="475"
        class="form__success__picture"
      />
      <h5 class="heading-h5 text--center form__success__text--title">
        Se ha integrado correctamente tu canal de SMS
      </h5>
      <p class="body-2 text--center form__success__text">
        Ahora podrás realizar envío de campañas masivas de SMS. No olvides leer
        las políticas de cobros.
      </p>
      <a-button type="primary" @click="handleClose">
        Aceptar y volver a canales
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'FormProviderTedexis',
  props: {
    countries: {
      type: Array,
      required: false,
      default: () => [
        { _id: 1, nameSpanish: 'Perú', dialCode: '+51' },
        { _id: 2, nameSpanish: 'Argentina', dialCode: '+52' },
      ],
    },
    defaultStep: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  mixins: [supportMixin],
  data: () => ({
    loading: false,
    error: {
      visible: false,
      message: '',
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-provider-twilio' })
  },
  computed: {
    ...mapGetters(['channels']),

    providerDefaultSms() {
      if (!this.channels.length) return
      const provider = this.providerChannel({
        provider: 'tedexis_v2',
        channel: 'sms',
      })
      return provider
    },
  },
  methods: {
    ...mapActions(['integrateProviderSms']),

    /**
     * El submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const idProvider = this.providerDefaultSms
            ? this.providerDefaultSms._id
            : null
          if (!idProvider) return
          this.loading = true
          const response = await this.integrateProviderSms({
            apiChannelId: idProvider,
            ...values,
          })
          this.loading = false
          if (response.success) {
            this.$message.success(
              response.details || 'El canal de SMS se integró exitosamente'
            )
            this.$emit('onSubmit', values)
          } else {
            this.$message.error(response.details)
            this.error.visible = true
            this.error.message = response.details || 'Ocurrió un error 😥'
          }
        }
      })
    },
    handleClose() {
      this.loading = false
      this.error = {
        visible: false,
        message: '',
      }
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  &__extra-info
    margin-bottom: 10em
  &__success
    margin-top: 4em
    &__picture
      margin-bottom: 32px
    &__text--title
      width: 30em
      margin: auto auto 12px auto
    &__text
      width: 30em
      margin: auto auto 24px auto
  &__footer
    display: flex
    justify-content: flex-end
    .footer_button
      &__default
        margin-right: 12px
</style>
