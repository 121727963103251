<template>
  <div class="channels-template">
    <div class="channels-template__83w">
      <h5
        class="heading-h5 align-left gray-dark-900"
        style="margin-bottom: 8px"
      >
        CANALES
      </h5>
      <p class="align-left gray-dark-900 section__description">
        Integra tus canales favoritos y comienza a comunicarte con tus clientes
        en un solo hilo de conversación.
        <anchor
          :href="`${vari.WEB_DOC}primeros-pasos/canales/`"
          title="Click aquí"
        />
        si deseas conocer más.
      </p>
    </div>
    <div v-if="company" class="section-channels-cards">
      <CardChannel
        v-for="channel in channels"
        :key="channel._id"
        :channel="channel"
        :company="company"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardChannel from '@/app/channels/components/organisms/cards/CardChannel'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'SectionAllChannels',
  components: {
    CardChannel,
    Anchor,
  },
  data: () => ({
    vari,
  }),
  computed: {
    ...mapGetters(['company', 'channels']),
  },
}
</script>

<style lang="sass" scoped>
$max_width: 83.33333333%
.channels-template
  padding: 20px 12px 12px
  .gray-dark-900
    color: $gray_dark_900
  &__83w
    width: $max_width
    margin: 0 auto
.section-channels-cards
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 20px
  margin: 0 auto
  width: $max_width
.section__description
  margin-bottom: 4%
</style>
