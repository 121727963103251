<template>
  <a-modal
    v-model="visible"
    @cancel="handleClose"
    class="modal-info-list"
    :title="data.title"
    :width="data.width"
    :body-style="showNewFb ? { paddingBottom: '0px', height: '44em' } : {}"
    centered
  >
    <carousel-new-fb v-if="showCarousel" :key="key" :channel="data.channel" />
    <div v-else>
      <a-row v-i class="title">
        <SocialIcon class="icon" :type="data.channel" size="60px" />
        <p class="subtitle">{{ data.subtitle }}</p>
      </a-row>
      <hr />
      <custom-section-scroll
        :master-styles="{
          height: showNewFb ? '420px' : 'auto',
        }"
      >
        <a-row class="list">
          <ul>
            <li v-for="(el, i) in listEnumrated" :key="el.title">
              <div>
                <p class="heading-h6">{{ el.order }} {{ el.title }}</p>
                <p class="indentation">{{ el.description }}</p>
                <a-checkbox
                  v-if="el.checkbox"
                  class="checkbox indentation"
                  @change="handleCheck(i)"
                >
                  {{ el.checkbox }}
                </a-checkbox>
                <p class="indentation" v-html="el.info"></p>
              </div>
              <hr v-show="i !== listEnumrated.length - 1" />
            </li>
          </ul>
        </a-row>
      </custom-section-scroll>
    </div>
    <template slot="footer">
      <a-button v-if="cancelButton" @click="handleOmit">
        {{ cancelButton }}
      </a-button>
      <a-button
        :type="data.okButtonType"
        :disabled="okButtonDisabled"
        @click="handleOk"
        >{{ data.okButtonName }}</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import CarouselNewFb from '@/app/channels/components/messenger/CarouselNewFb.vue'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'

export default {
  name: 'ModalInfoList',
  components: {
    SocialIcon,
    CarouselNewFb,
    CustomSectionScroll,
  },
  props: {
    enumerated: { type: Boolean, default: false },
    showNewFb: { type: Boolean, default: true },
    data: {
      type: Object,
      required: true,
      width: { type: String, default: '650px' },
      key: { type: String, required: true }, // Un identificador que se mandará junto al evento
      channel: { type: String, required: true },
      companionIcon: { type: String }, // ïcono que acompañará al icono del canal
      title: { type: String, required: true },
      okButtonName: { type: String, required: true },
      okButtonType: {
        type: String,
        default: 'default',
        enum: ['default', 'primary'],
      },
      subtitle: { type: String },
      list: [
        {
          title: { type: String, required: true },
          description: { type: String },
          checkbox: { type: String },
          info: { type: String }, // HTML Insertado
        },
      ],
    },
  },
  data: () => ({
    visible: true,
    checked_fields: [], // Arreglo de booleanos con los valores de los checkbox existentes en la lista
    showCarousel: false,
  }),
  computed: {
    /**
     * @return {Array}
     */
    listEnumrated() {
      return this.data.list.map((el, i) => {
        el.order = this.enumerated ? `${i + 1}.` : ''
        return el
      })
    },
    /**
     * @return {Boolean}
     */
    okButtonDisabled() {
      if (this.showCarousel) return false
      return this.checked_fields.some((el) => !el.value)
    },
    cancelButton() {
      return this.showNewFb && !this.showCarousel
        ? 'Omitir esta información'
        : null
    },
  },
  created() {
    this.checked_fields = this.data.list
      .map((el, i) => {
        if (el.checkbox) return { value: false, originalIndex: i }
      })
      .filter((el) => el)
  },
  methods: {
    /**
     * @param {Number} originalIndex
     */
    handleCheck(originalIndex) {
      let el = this.checked_fields.find(
        (el) => el.originalIndex === originalIndex
      )
      el.value = !el.value
    },
    handleOk() {
      if (this.showNewFb && !this.showCarousel) this.showCarousel = true
      else this.$emit('handleOk', this.data.channel, this.data.key)
    },
    handleOmit() {
      if (this.showNewFb) this.showCarousel = true
    },
    handleClose() {
      this.$emit('handleClose', this.data.channel, this.data.key)
    },
  },
}
</script>

<style lang="sass" scoped>
.modal-info-list
  .title
    text-align: center
    & > *
      margin: 0 auto
      text-align: center
    .icon
      margin-bottom: 20px
    .subtitle
      margin-bottom: 14px
      color: $gray_dark_900
      font: 24px
      font-weight: 500
  .list
    p, .checkbox
      margin: 0 0 8px 0
    ul, li
      margin: 0
      padding: 0
      list-style: none
    p
      color: #000
    .indentation
      padding: 0 14px
      width: -webkit-fill-available;
</style>
