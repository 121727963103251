<template>
  <div class="modal-enterprise">
    <div class="modal-enterprise-content">
      <component
        v-if="currentModal"
        :is="currentModal"
        :visible="visible"
        @done="handleOk"
        @reset="resetSteps"
      />
    </div>
  </div>
</template>

<script>
import FormEnterprise from '@/app/shop/components/market/contact/FormEnterprise.vue'
import ResumeEnterprise from '@/app/shop/components/market/contact/ResumeEnterprise.vue'

export default {
  name: 'ModalEnterprise',
  components: {
    FormEnterprise,
    ResumeEnterprise,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1, // Numero del paso
  }),
  computed: {
    currentModal() {
      if (this.step === 1) return FormEnterprise
      else if (this.step === 2) return ResumeEnterprise
      else return null
    },
  },
  methods: {
    /**
     * Aborta el proceso del modal
     */
    handleCancel() {
      // this.visible = false
      this.$emit('hideModal')
      console.log('hide modal')
    },
    /**
     * Llama a la función del envío de formulario
     */
    handleOk() {
      this.step++
    },
    /**
     * Reinicia los pasos
     */
    resetSteps() {
      this.handleCancel()
      this.step = 1
    },
  },
}
</script>
