import { NODES, NODES_TO_SAVE_VARS } from './componentsDataNodes'

const fathers = Object.values(NODES)
  .filter((node) => node.is_father)
  .map((father) => father.type)

const nodes_to_save_vars = NODES_TO_SAVE_VARS
/**
 * Identifica la raiz de todos los nodos
 * @param {Array} nodes
 */
const identifyNodeRoot = (nodes) => {
  return nodes.find((elem) => !elem.connection.sourceId)
}

/**
 * Asigna hijos a un padre
 * @param {Array} father el padre de nodos
 * @param {Array} nodes los nodos
 */
const assignChild = (father, nodes) => {
  const typeFather = father.drawer.question.type

  // Si no es un nodo padre
  if (!fathers.includes(typeFather)) {
    if (father.drawer.action.type === 'continue') {
      const child = nodes.find(
        (node) => node._id === father.drawer.action.nodeId
      )
      const childStructure = NODES[child.drawer.question.type]
      if (child !== undefined) {
        child.parentNodeId = father._id
        father.child = child

        if (
          (childStructure.child && child.drawer[childStructure.child.group]) ||
          (child.drawer.action && child.drawer.action.type === 'continue')
        ) {
          assignChild(child, nodes)
        }
      } else {
        delete father.child
      }
    }
  }
  if (fathers.includes(typeFather)) {
    const nodeFather = NODES[typeFather]
    /**Ordenar los hijos del nodo */
    const children = father.drawer[nodeFather.child.group].sort(
      (a, b) => a.order - b.order
    )
    children.map((itemChild) => {
      itemChild.parentNodeId = father._id
      itemChild.parentNodeType = typeFather

      // si el hijo simular ser padre, entonces recorerra a su hijo
      if (nodeFather.child.simulate_parent && nodeFather.child.child) {
        const group = nodeFather.child.child.group
        const subchildren = itemChild[group]
          ? itemChild[group].sort((a, b) => a.order - b.order)
          : []
        subchildren.map((subchild) => {
          subchild.parentNodeId = father._id
          subchild.simulateParentNodeId =
            itemChild[nodeFather.child.id_property]
          subchild.parentNodeType = typeFather
          subchild.is_subchild = true

          if (subchild.action.type === 'continue') {
            const nextChild = nodes.find(
              (node) => node._id === subchild.action.nodeId
            )

            if (nextChild !== undefined) {
              nextChild.parentNodeId = father._id
              subchild.child = nextChild
              // verifica si el subhijo también es padre
              const subchildIsParent = fathers.includes(
                nextChild.drawer.question.type
              )
              if (
                subchildIsParent ||
                (nextChild.drawer.action &&
                  nextChild.drawer.action.type === 'continue')
              ) {
                assignChild(nextChild, nodes)
              }
            } else {
              delete subchild.child
            }
          }
        })
      } else if (itemChild.action.type === 'continue') {
        const child = nodes.find((node) => node._id === itemChild.action.nodeId)
        if (child !== undefined) {
          child.parentNodeId = itemChild[nodeFather.child.id_property]
          itemChild.child = child
          // verifica si su hijo también es padre
          const childIsParent = fathers.includes(child.drawer.question.type)
          if (
            childIsParent ||
            (child.drawer.action && child.drawer.action.type === 'continue')
          ) {
            assignChild(child, nodes)
          }
        } else {
          delete itemChild.child
        }
      }
    })
  }
}

/**
 * Genera el arbol de nodos
 * @param {Array} nodes
 */
const generateTreeNodes = (nodes) => {
  let tree = {}
  tree = identifyNodeRoot(nodes)
  if (tree !== undefined) {
    assignChild(tree, nodes)
    tree.default = true
  }
  return tree
}

/**
 * Asigna el ids de nodos usados en el flujo
 * @param {String} sourceId
 * @param {Array} nodes
 * @param {Array} usedNodes - array de los ids que estan siendo usados
 */
const assignUsedNodes = (sourceId, nodes, usedNodes) => {
  try {
    usedNodes.push(sourceId)
    const nodesChildren = nodes.filter(
      (node) => node.connection.sourceId === sourceId
    )
    nodesChildren.map((nodeChildren) => {
      assignUsedNodes(nodeChildren._id, nodes, usedNodes)
    })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Verifica los nodo que estan siendo usados en el flujo
 * @param {Array} nodes
 */
const verifyUsedNodes = (nodes) => {
  const usedNodes = []
  const father = identifyNodeRoot(nodes)
  if (father !== undefined) {
    assignUsedNodes(father._id, nodes, usedNodes)
  }
  return usedNodes
}
/**
 * Asigna las variables que han sido usadas en los nodos en una constante
 * @param {String} parentNodeId - id del nodo conector de nodos
 * @param {Object[]} nodes - los nodos que están siendo consultados
 * @param {String[]} simpleVars - aquí se adjuntarán todas las variables
 * @param {String} fromNodeId - id del nodo desde donde viene el nodo padre
 * @returns
 */
const assignVars = (parentNodeId, nodes, simpleVars, fromNodeId) => {
  const parentNode = nodes.find((node) => node._id === parentNodeId)
  if (!parentNode) return simpleVars
  // si es una tarjeta, buscará en sus hijos
  const isAllowToSave =
    parentNode.drawer &&
    parentNode.drawer.question &&
    nodes_to_save_vars.includes(parentNode.drawer.question.type)

  if (isAllowToSave) {
    // si es de tipo card, buscará en las variables que tiene guardada
    if (parentNode.drawer.cards) {
      if (fromNodeId) {
        const card = parentNode.drawer.cards.find((section) =>
          section.buttons.some(
            (item) => item.action.nodeId && item.action.nodeId === fromNodeId
          )
        )
        if (card.varToSave) {
          simpleVars.push(card.varToSave)
        }
      }
    }
    // si es de tipo lista, buscará en las variables que tiene guardada
    else if (parentNode.drawer.lists) {
      if (fromNodeId) {
        const sectionSource = parentNode.drawer.lists.find((section) =>
          section.options.some(
            (item) => item.action.nodeId && item.action.nodeId === fromNodeId
          )
        )
        if (sectionSource.varToSave) {
          simpleVars.push(sectionSource.varToSave)
        }
      }
    } else simpleVars.push(parentNode.drawer.question.varToSave)
  }

  if (parentNode.connection.sourceId) {
    assignVars(
      parentNode.connection.sourceId,
      nodes,
      simpleVars,
      parentNode._id
    )
  }
}
/**
 * Verifica los nodos que pueden capturar variables en su configuracion
 * @param {String} parentNodeId - id del nodo padre
 * @param {Array} nodes - nodos del chatbot
 * @param {String} fromNodeId - id del nodo desde donde viene el nodo padre
 */
const filterVarsToSaveOfParents = (parentNodeId, nodes, fromNodeId) => {
  try {
    const simpleVarsFiltered = []
    assignVars(parentNodeId, nodes, simpleVarsFiltered, fromNodeId)
    return simpleVarsFiltered
      ? simpleVarsFiltered.filter((simpleVar) => simpleVar !== undefined)
      : []
  } catch (err) {
    console.error(err)
  }
}

/**
 * Asigna variables
 * @param {String} parentNodeId
 * @param {Object[]} nodes
 * @param {Object[]} simpleVars
 * @param {Boolean} first - si es el nodo primero
 * @returns {Object[]} simpleVars
 * @returns {String} simpleVars[].name
 * @returns {String} simpleVars[]._id
 */
const assignVarsChildren = (currentNodeId, nodes, simpleVars, first) => {
  const currentNode = nodes.find((node) => node._id === currentNodeId)
  if (!currentNode) return simpleVars
  const isAllowToSave =
    !first &&
    currentNode.drawer &&
    currentNode.drawer.question &&
    nodes_to_save_vars.includes(currentNode.drawer.question.type)

  if (isAllowToSave) {
    // si es de tipo card, buscará en las variables que tiene guardada
    if (currentNode.drawer.cards) {
      const varsInCards = currentNode.drawer.cards.map((card) => card.varToSave)
      varsInCards.forEach((varInCard) => simpleVars.push(varInCard))
    }
    // si es de tipo lista, buscará en las variables que tiene guardada
    else if (currentNode.drawer.lists) {
      const varsInSections = currentNode.drawer.lists.map(
        (section) => section.varToSave
      )
      varsInSections.forEach((varInSection) => simpleVars.push(varInSection))
    } else simpleVars.push(currentNode.drawer.question.varToSave)
  }

  const children = nodes.filter(
    (node) => node.connection.sourceId === currentNodeId
  )
  children.map((child) =>
    assignVarsChildren(child._id, nodes, simpleVars, false)
  )
}
/**
 * Verifica los nodo que estan siendo usados en el flujo
 * @param {String} currentNodeId - nodo actual
 * @param {Object[]} nodes
 * @param {String} nodes[]._id
 * @param {Object} nodes[].drawer
 * @param {Object} nodes[].drawer.question
 * @param {String} nodes[].drawer.question.type
 */
const filterVarsToSaveOfChildren = (currentNodeId, nodes) => {
  try {
    const simpleVarsFiltered = []
    assignVarsChildren(currentNodeId, nodes, simpleVarsFiltered, true)
    return simpleVarsFiltered.filter((simpleVar) => simpleVar !== undefined)
  } catch (err) {
    console.error(err)
  }
}

export {
  generateTreeNodes,
  verifyUsedNodes,
  filterVarsToSaveOfParents,
  filterVarsToSaveOfChildren,
}
