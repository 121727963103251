<template>
  <div
    ref="chat_input"
    id="chat_input"
    class="chat-input display-flex align--center justify-content--end"
  >
    <template v-if="!startVoiceRecorder">
      <!--Opciones del input-->
      <menu-options-chat
        :disabled="!enabledDiv"
        @onAddEmoji="handleAddEmoji"
        @onAttachPicture="handleAttach('image')"
        @onAttachFile="handleAttach('document')"
        @onAttachVideo="handleAttach('video')"
        @onShowTemplates="openModalSelectTemplate"
        @onShowFastAnswers="handleShowModalAnswers"
        @onShowContinueFb="handleShowModalContinueFb"
      />
      <!--Input para adjuntar-->
      <input
        type="file"
        ref="fileMessage"
        value=""
        @change="(event) => requestUploadFile({ file: event.target.files[0] })"
        :accept="typeInputFile"
        v-show="false"
      />

      <!-- Input del mensaje -->
      <a-tooltip>
        <!--Texto tooltip al pasar 24hrs -->
        <template slot="title" v-if="!enabledDiv">
          {{ tooltipDisabledInput }}
        </template>
        <!-- Fin tooltip -->
        <!-- Campo editable -->
        <div
          ref="div_editable_container"
          class="div-editable-container"
          :class="{
            'disabled-input cursor-not-allowed':
              !enabledDiv || disabledInputChat,
            'enabled-input': enabledDiv,
          }"
          @paste="onPaste"
          @dragover.prevent="dragOver"
          @drop="drop"
        >
          <!--Previsualizacion de una imagen-->
          <ViewPrevious
            v-if="previewThumbnail"
            :urlFile="urlFile"
            :percent="percent"
            :name="nameImg"
            @delete="cleanImg"
          />
          <!--Previsualizacion de un archivo-->
          <ViewPreviousFile
            v-if="previewFile"
            :nameFile="nameImg"
            :percent="percent"
            :icon="icon"
            @delete="cleanFile"
          />
          <!-- Div para escribir -->
          <div
            v-if="inputEnabled"
            class="divEditable scrollbar_basic"
            :class="{ ellipsis: !enabledDiv }"
            @blur="noTyping"
            @input="onInput"
            @keypress="sendMessageWithIntro"
            ref="divEditable"
            :contenteditable="inputEnabled && enabledDiv"
            :data-placeholder="placeholderInput"
            id="divEditable"
          ></div>
          <!-- Drag & drop -->
          <div @mouseleave="dragLeave">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
            >
              <div>
                <div class="ant-upload-text">Suelta el archivo aquí...</div>
              </div>
            </a-upload>
          </div>
        </div>
      </a-tooltip>
    </template>

    <!--Grabadora de voz-->
    <voice-recorder
      v-if="showVoiceRecorder"
      :onUpload="requestUploadFile"
      :disabled="!enabledDiv"
      @onStart="startVoiceRecorder = true"
      @onDelete="startVoiceRecorder = false"
      @onSend="prepareMessage"
      @onChangeStart="(start) => (startVoiceRecorder = start)"
    />
    <!--Enviar mensaje-->
    <i
      v-else
      class="chat-input__icon chat-input__icon-send"
      :class="{ 'color-disabled cursor-not-allowed': !enabledDiv }"
      @click="prepareMessage"
    >
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'26'" v-bind:width="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.74 2.252l1.365 9.555.772.193-.772.193-1.365 9.555L22.236 12 2.74 2.252zm3.155 7.94L5.26 5.749 17.764 12 5.26 18.252l.635-4.445L13.123 12l-7.228-1.807z" fill="currentColor"/></svg>
    </i>
    <!--Modal para continuar el chat-->
    <modal-continue-message
      :visible="modalSelectTemplate.visible"
      @onClose="closeModalSelectTemplate"
    />
    <!--Modal para mostrar la lista de respuestas-->
    <modal-list-fast-answers
      :visible="modalFastAnswers.visible"
      :allowedMedias="typeFileAllowed"
      @onClose="modalFastAnswers.visible = false"
      @onPreview="handleShowPreview"
      @onAdd="handleAddFastAnswer"
    />
    <!--Modal para previsualizar-->
    <modal-preview-fast-answers
      :visible="modalPreview.visible"
      title="Ver respuesta rápida"
      :data-source="modalPreview.dataSource"
      :keys-allowed="modalPreview.keysAllowed"
      @onClose="modalPreview.visible = false"
    />
    <!--Modal para enviar mensajes de reconexion de Facebook-->
    <modal-continue-fb-message
      :visible="modalContinueFb.visible"
      @onClose="handleCloseModalContinueFb"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ViewPrevious from '@/app/tickets/components/organisms/ViewPrevious.vue'
import ViewPreviousFile from '@/app/tickets/components/organisms/ViewPreviousFile.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import VoiceRecorder from '@/app/tickets/components/VoiceRecorder'
import ModalContinueMessage from '@/app/shared/components/modals/ModalContinueMessage'
import ModalContinueFbMessage from '@/app/tickets/components/molecules/modals/ModalContinueFbMessage'
import ModalListFastAnswers from '@/app/tickets/components/molecules/ModalListFastAnswers'
import ModalPreviewFastAnswers from '@/app/settings/components/molecules/ModalPreviewFastAnswers'
import MenuOptionsChat from '@/app/tickets/components/molecules/MenuOptionsChat'
import {
  MULTIMEDIA_SUPPORT,
  CHANNELS_WITHOUT_FILES,
} from '@/app/tickets/utils/multimediaUtil'
import chatMixin from '@/app/tickets/mixins/chatMixin'

import moment from 'moment'
moment.locale('es')

export default {
  mixins: [attemptMixin, formatMixin, supportMixin, chatMixin],
  name: 'ChatInput',
  components: {
    ViewPrevious,
    ViewPreviousFile,
    VoiceRecorder,
    ModalContinueMessage,
    ModalListFastAnswers,
    ModalPreviewFastAnswers,
    MenuOptionsChat,
    ModalContinueFbMessage,
  },
  data: () => ({
    inputEnabled: true, // Habilitar input para escribir
    textMessage: '', // Texto del mensaje
    typeInputFile: null, // Tipo de archivo adjuntado
    urlFile: null, // URL del archivo adjuntado y subido
    urlFileTemp: null, // URL del archivo temporal
    filenameTemp: null, // URL del archivo temporal
    istyping: false, // El usuario está escribiendo?
    timeoutTyping: {
      // Temporizador de "escribiendo"
      delay: 1500, // tolerancia de "escribiendo" en ms
    },
    percent: 0, // porcentaje de progress
    fileAttach: false, // archivo adjuntado
    nameImg: '', // nombre de imagen
    moment: moment,
    timer: null, // funcion de intervalo de tiempo,
    passTime: null,
    typeFile: null,
    typeFileChatOption: null,
    icon: null, // ícono del archivo (solo si es de tipo thumbnail)
    keyMessage: 'keyMessage', // id del mensaje de warning
    maxSizeAttach: 5, // Peso máximo en MB de un archivo
    modalSelectTemplate: {
      visible: false,
    },
    disabledInputChat: false, // deshabilita el input cada 1 segundo
    fileList: [],
    stopBySecond: false,
    startVoiceRecorder: false,
    modalFastAnswers: {
      visible: false,
    },
    modalPreview: {
      visible: false,
      dataSource: {},
      keysAllowed: ['title', 'body', 'lines'],
    },
    captionFile: null,
    modalContinueFb: {
      visible: false,
      channelCompanyId: '',
    },
    isFastAnswerWithMedia: false,
  }),
  computed: {
    ...mapGetters(['ticketSelected', 'company']),
    /**
     * El canal de la empresa que tiene el ticket seleccionado
     * @returns {Object}
     */
    channelCompanyOfTicketSelected() {
      return this.channelCompanyOfTicket(this.ticketSelected)
    },
    /**
     * Verifica si esta habilitado la opcion de envio de voz
     * @returns {Boolean}
     */
    enabledSendAudio() {
      if (
        !this.channelCompanyOfTicketSelected ||
        !this.channelCompanyOfTicketSelected.api
      )
        return false
      return this.channelCompanyOfTicketSelected.api.chat_options?.audio.active
    },
    /**
     * Habilita el input mientras no haya pasado 24hrs de la última respuesta del cliente
     * @returns {Boolean}
     */
    enabledDiv() {
      if (!this.ticketSelected) return false
      return this.ticketSelected.inputEnabled
    },
    /**
     * Previsualización en miniatura
     * @returns {Boolean}
     */
    previewThumbnail() {
      if (!this.typeFile) return false
      const multimedia = this.typeMultimedia({ typeFile: this.typeFile })
      const preview = 'thumbnail'
      return (
        this.fileAttach &&
        this.typeFile &&
        multimedia &&
        multimedia.preview === preview
      )
    },
    /**
     * Previsualización tipo archivo
     * @returns {Boolean}
     */
    previewFile() {
      if (!this.typeFile) return false
      const multimedia = this.typeMultimedia({ typeFile: this.typeFile })
      const preview = 'file'
      return (
        this.fileAttach &&
        this.typeFile &&
        multimedia &&
        multimedia.preview === preview
      )
    },
    /**
     * Placeholder de input
     * @returns {String}
     */
    placeholderInput() {
      if (!this.ticketSelected) return ''

      const placeholderTextInput = this.ticketSelected.placeholderTextInput

      if (!placeholderTextInput) {
        if (this.inputEnabled && this.enabledDiv) return 'Escribe aquí...'
        else if (this.stopBySecond)
          return 'Campo bloqueado hasta que pase 1 segundo'
        else return 'Campo bloqueado hasta que el cliente responda'
      } else {
        return placeholderTextInput
      }
    },
    /**
     * Titulo del tooltip en el input deshabilitado
     * @returns {String}
     */
    tooltipDisabledInput() {
      if (!this.ticketSelected) return ''
      const placeholderTextInput =
        this.ticketSelected.tooltipTextInput ||
        this.ticketSelected.placeholderTextInput

      if (!placeholderTextInput) {
        if (this.stopBySecond)
          return 'Solo se puede enviar un mensaje por segundo'
        else
          return 'Han pasado más de 24h sin respuesta del cliente o has enviado una plantilla y no hay respuesta.'
      } else {
        return placeholderTextInput
      }
    },
    /**
     * Mostrar el microfono de voz
     * @return {Boolean}
     */
    showVoiceRecorder() {
      // sin soporte para iOS
      const typeFilesAllow = ['image', '.document', '.presentation']
      const isAttachSomeMedia =
        this.typeFile &&
        typeFilesAllow.some((typeFile) => this.typeFile.includes(typeFile))
      return (
        !isAttachSomeMedia &&
        !this.textMessage.length &&
        !this.captionFile &&
        this.enabledSendAudio
      )
    },
    /**
     * Opciones de envio del chat
     */
    chatOptions() {
      return (
        this.channelCompanyOfTicketSelected &&
        this.channelCompanyOfTicketSelected.api.chat_options
      )
    },
    /**
     * @returns {Boolean} - si esta permitido o no el envio de media con texto
     */
    allowedSendMediaWithText() {
      const formatsAllowed = this.chatOptions?.attachments?.formats
      if (!formatsAllowed) return null
      const format = formatsAllowed.find(
        (formats) => formats.type_file === this.typeFileChatOption
      )
      return format?.allowText
    },
    /**
     * @returns {Boolean} - tipo de archivos permitidos
     */
    typeFileAllowed() {
      if (!this.ticketSelected) return []
      const formatsAllowed = this.chatOptions?.attachments?.formats
      if (!formatsAllowed) return null
      let typeFiles = formatsAllowed.map((format) => format.type_file)
      // si el canal no tiene permitido el envio de documents
      const channel = this.ticketSelected.channel.type
      if (CHANNELS_WITHOUT_FILES.includes(channel)) {
        typeFiles = typeFiles.filter((file) => file !== 'document')
      }
      return typeFiles
    },
  },
  watch: {
    ticketSelected() {
      this.cleanInput()
      this.cleanFile()
    },
  },
  methods: {
    ...mapActions(['addMessageInTicket', 'uploadFileMessage', 'emitTyping']),

    /**
     * Mostrar la opción de adjuntar o no según los permisos del proveedor API en BD
     * @param {String} typeFile ["image", "document", "video", "audio", "location", "contact"]
     * @returns {Boolean}
     */
    showTypeFile(typeFile) {
      if (!this.channelCompanyOfTicketSelected) return false
      return this.channelCompanyOfTicketSelected.api.chat_options.attachments.formats.some(
        (format) => format.type_file === typeFile
      )
    },
    /** Al escribir */
    onInput(e) {
      this.textMessage = e.target.innerText
      if (!e.target.innerText?.trim().length) this.textMessage = '' // verificar que el contenido tenga palabras
      this.typing()
    },
    // Enviar con enter
    sendMessageWithIntro(event) {
      if (
        !event.shiftKey &&
        event.keyCode == 13 &&
        this.company.settings.chat.sendWithIntro
      ) {
        this.prepareMessage()
        setTimeout(() => {
          this.$refs.divEditable.innerHTML = ''
        }, 50)
      }
    },
    /**
     * Propiedades para enviar en el mensaje
     * @return {Object} properties
     * @return {String} properties.text
     * @return {String} properties.imageUrl
     * @return {String} properties.wordUrl
     * @return {String} properties.excelUrl
     * @return {String} properties.pptUrl
     * @returns {String} properties.audioUrl
     * @returns {String} properties.videoUrl
     */
    propertiesToSend() {
      const newTextMessage = this.textMessage.trim()
      const newUrlFile = this.urlFile

      const text = newTextMessage
      const imageUrl =
        this.typeInputFile &&
        this.typeFile &&
        this.typeFile.search('image') > -1
          ? newUrlFile
          : null
      const pdfUrl =
        this.typeInputFile && this.typeFile && this.typeFile.search('pdf') > -1
          ? newUrlFile
          : null
      const wordUrl =
        this.typeInputFile &&
        this.typeFile &&
        this.typeFile.includes('.document')
          ? newUrlFile
          : null
      const excelUrl =
        this.typeInputFile && this.typeFile && this.typeFile.includes('.sheet')
          ? newUrlFile
          : null
      const pptUrl =
        this.typeInputFile &&
        this.typeFile &&
        this.typeFile.includes('.presentation')
          ? newUrlFile
          : null
      const audioUrl =
        this.typeFile && this.typeFile.includes('audio') ? newUrlFile : null
      const videoUrl =
        this.typeFile && this.typeFile.includes('video') ? newUrlFile : null
      return {
        text,
        imageUrl,
        pdfUrl,
        wordUrl,
        excelUrl,
        pptUrl,
        audioUrl,
        videoUrl,
      }
    },
    /** Prepara el mensaje para enviar */
    async prepareMessage() {
      if (this.percent !== 100 && this.fileAttach)
        return this.$message.loading({
          content: 'Cargando... Espere',
          key: this.keyMessage,
        })

      const newTextMessage = this.textMessage.trim()
      const newUrlFile = this.urlFile
      if (!newUrlFile) {
        if (!newTextMessage) {
          return
        }
      } /** Comprobar que por lo menos haya url o texto */

      // si el mensaje es respuesta rápida con multimedia
      // y no está permitido enviarlo junto con el texto
      if (this.isFastAnswerWithMedia && !this.allowedSendMediaWithText)
        return this.sendFastAnswerWithMedia()

      const {
        text,
        imageUrl,
        pdfUrl,
        wordUrl,
        excelUrl,
        pptUrl,
        audioUrl,
        videoUrl,
      } = this.propertiesToSend()

      this.requestSendMessage({
        text,
        imageUrl,
        pdfUrl,
        wordUrl,
        excelUrl,
        pptUrl,
        audioUrl,
        videoUrl,
      })
      if (this.previewThumbnail && !text) this.cleanImg()
      else if (this.previewFile || audioUrl) this.cleanFile()
      else if (this.previewThumbnail && text) {
        this.cleanImg()
        this.cleanInput()
      } else this.cleanInput()
      this.startVoiceRecorder = false
    },
    /**
     * Solicita enviar el mensaje
     * @param {Object} args
     * @param {String} args.text Texto del mensaje
     * @param {String} args.imageUrl Url de la imagen en GCP
     * @param {String} args.pdfUrl Ruta del pdf en GCP
     * @param {String} args.wordUrl Ruta del word en GCP
     * @param {String} args.excelUrl Ruta del excel en GCP
     * @param {String} args.pptUrl Ruta del ppt en GCP
     * @param {String} args.audioUrl Ruta del audio en GCP
     * @param {String} args.videoUrl Ruta del video en GCP
     */
    async requestSendMessage({
      text,
      imageUrl,
      pdfUrl,
      wordUrl,
      excelUrl,
      pptUrl,
      audioUrl,
      videoUrl,
    }) {
      const response = await this.addMessageInTicket({
        message: {
          text,
          imageUrl,
          pdfUrl,
          wordUrl,
          excelUrl,
          pptUrl,
          audioUrl,
          videoUrl,
          urlFileTemp: this.urlFileTemp,
          filenameTemp: this.filenameTemp,
          caption: this.nameImg,
        },
      })
      if (response && response.result === 'only_one_message_per_second') {
        this.stopBySecond = true
        this.disabledInputChat = true
        setTimeout(() => {
          this.disabledInputChat = false
          this.stopBySecond = false
        }, 1000)
      }
    },
    /** Limpia el input */
    cleanInput(resetFastAnswer = true) {
      if (this.$refs.divEditable) {
        this.textMessage = ''
        this.$refs.divEditable.innerHTML = ''
        this.$refs.divEditable.focus()
        if (resetFastAnswer) this.isFastAnswerWithMedia = false
      }
    },
    /** Limpia campo de documentos */
    cleanFile() {
      this.percent = 0
      if (!this.isFastAnswerWithMedia) this.textMessage = ''
      this.typeInputFile = ''
      this.fileAttach = false
      this.urlFile = null
      this.urlFileTemp = null
      this.filenameTemp = null
      this.inputEnabled = true
      this.percent = 0
      if (this.$refs.fileMessage) this.$refs.fileMessage.value = ''
      this.typeFile = null
      this.typeFileChatOption = null
      this.nameImg = ''
      this.captionFile = null
      this.isFastAnswerWithMedia = false
    },
    /*limpia campos imagen */
    cleanImg() {
      this.typeInputFile = ''
      this.fileAttach = false
      this.nameImg = ''
      this.urlFile = null
      this.urlFileTemp = null
      this.filenameTemp = null
      this.percent = 0
      if (this.$refs.fileMessage) this.$refs.fileMessage.value = ''
      this.typeFile = null
      this.inputEnabled = true
      this.isFastAnswerWithMedia = false
    },
    /**
     * Seleccionar y adjuntar abre ventana
     * @param {String} typeInputFile [image, document, video]
     * @param {Boolean} openWindow - abre la ventana de seleccion de archivos
     */
    handleAttach(typeInputFile, openWindow = true) {
      this.typeFileChatOption = typeInputFile

      // obtienen el tipo de multimedia soportado de acuerdo
      // al tipo archivo subido
      const multimediaSupport = MULTIMEDIA_SUPPORT[typeInputFile]
      if (multimediaSupport) {
        // setea los formatos aceptados
        this.typeInputFile = multimediaSupport.formats.accept
        // abre la ventana de selección de archivos
        if (openWindow) setTimeout(() => this.$refs.fileMessage.click(), 1)
      } else this.typeInputFile = null
    },
    /** Subir archivo al seleccionar una imagen o archivo*/
    async requestUploadFile({ file }) {
      try {
        this.percent = 1
        this.isFastAnswerWithMedia = false
        if (!file) return
        if (!file.type) {
          this.$message.error(`Tipo de archivo desconocido o no soportado`)
          return
        }

        this.typeFile = file.type
        // valida y elimina los archivos que sobrepasen los 5MB
        if (
          this.toOtherUnitySize(file.size, 'B', 'toMB', 'Number') >=
          this.maxSizeAttach
        ) {
          this.$message.error(
            `Su archivo ha sobrepasado los ${this.maxSizeAttach}MB`
          )
          this.cleanFile()
          return
        }

        if (this.$refs.divEditable)
          this.$refs.divEditable.innerText = this.textMessage.trim()

        this.fileAttach = true
        this.percent = 70
        let caption = null
        if (this.typeFile) {
          this.nameImg = file.name // nombre de archivo
          if (this.previewThumbnail) {
            // Valida si después de subir la imagen se puede adjuntar texto
            this.inputEnabled =
              this.channelCompanyOfTicketSelected.api.chat_options.attachments.formats.some(
                (format) =>
                  format.type_file === this.typeFileChatOption &&
                  format.allowText // "allowText" viene de la BD
              )
            this.nameImg = file.name // nombre de imagen
            caption = file.name // nombre de imagen
          } else if (this.previewFile) {
            this.textMessage = ''
            this.captionFile = file.name
            // Después de subir un documento, deshabilitara el envio con documentos
            this.inputEnabled = false
            this.putThumbnailIcon()
          }
        }
        const response = await this.uploadFileMessage({
          file: file,
          caption: caption,
        })
        if (response.success) {
          this.urlFile = response.urlFile.gcp // URL de google cloud storage
          this.urlFileTemp = response.urlFile.temp // URL temp
          this.filenameTemp = response.urlFile.filename // nombre archivo
          this.percent = 100
          this.$refs.divEditable?.focus()
        } else if (response.result == 'heavy') {
          this.$message.error(
            `Su archivo ha sobrepasado los ${this.maxSizeAttach}MB`
          )
          this.cleanFile()
          this.cleanImg()
          return
        } else {
          if (response.details) this.$message.error(response.details)
          else this.$message.error(`Archivo no permitido`)
          this.cleanFile()
          this.cleanImg()
          return
        }
      } catch (error) {
        console.error(error)
      }
    },
    /** Acción cuando el usuario escribe */
    typing() {
      if (!this.istyping) {
        // console.warn('EMITE: TYPING')
        this.emitTyping(true)
      }
      this.istyping = true
      // Setean con un retraso tras haber terminado de escribir
      if (this.istyping === true) {
        this.endWaitTyping(() => {
          this.noTyping()
        }, this.timeoutTyping.delay)
      }
    },
    /** Acción cuando el usaurio deja de escribir */
    noTyping() {
      if (this.istyping === false) return // Si no estaba escribiendo desde antes, no hay porque decir nuevamente que no esta escribiendo
      // console.warn('EMITE: NO TYPING')
      this.emitTyping(false)
      this.istyping = false
    },
    /** Acción cuando el usuario pega un elemento dentro del div editable */
    onPaste(evt) {
      if (!this.enabledDiv) return
      if (evt.clipboardData.files && evt.clipboardData.files.length > 0)
        this.onPasteFile(evt)
      else this.onPastePlainText(evt)
    },
    /** Convierte los elementos que se pegan por defecto con hmtl a texto plano */
    onPastePlainText(evt) {
      evt.preventDefault()
      let paste = (evt.clipboardData || window.clipboardData).getData(
        'text/plain'
      )
      window.document.execCommand('insertText', false, paste)
      this.textMessage = document.getElementById('divEditable').innerText
    },
    /** Sube la imagen pegada como un archivo adjunto */
    onPasteFile(evt) {
      evt.preventDefault()
      this.typeInputFile = evt.clipboardData.files[0].type
      this.$refs.fileMessage.files = evt.clipboardData.files
      this.requestUploadFile({ file: this.$refs.fileMessage.files[0] })
    },
    /**Levanta el modal de seleccionar plantilla */
    openModalSelectTemplate(disabledTemplate) {
      if (disabledTemplate) return (this.modalSelectTemplate.visible = false)
      this.modalSelectTemplate.visible = true
    },
    /**Cierra el modal de seleccionar plantilla */
    closeModalSelectTemplate() {
      this.modalSelectTemplate.visible = false
    },
    /**Activa la funcion del hijo */
    afterCloseModalSelect() {
      this.$refs.formContinueMessage.emitCloseModal()
      this.$refs.formContinueMessage.resetFields()
    },
    /** Colocal el ícono del tipo de archivo q se subió */
    putThumbnailIcon() {
      if (this.typeFile.search('pdf') > -1) this.icon = 'file-pdf'
      else if (this.typeFile.search('docx') > -1) this.icon = 'file-word'
      else if (this.typeFile.includes('.document')) this.icon = 'file-word'
      else if (this.typeFile.includes('.sheet')) this.icon = 'file-excel'
      else if (this.typeFile.includes('.presentation')) this.icon = 'file-ppt'
      else if (this.typeFile.includes('video')) this.icon = 'play-circle'
    },
    /**
     * Mantiene el archivo sobre el input
     */
    dragOver(evt) {
      evt.preventDefault()
      if (!this.enabledDiv) return
      // Add some visual fluff to show the user can drop its files
      if (!evt.currentTarget.classList.contains('dragged')) {
        this.$refs.div_editable_container.classList.add('dragged')
        this.$refs.chat_input.classList.add('dragged-container')
      }
    },
    /**
     * Quiota el archivo que estaba mantenido sobre el input
     */
    dragLeave() {
      if (!this.enabledDiv) return
      this.$refs.div_editable_container.classList.remove('dragged')
      this.$refs.chat_input.classList.remove('dragged-container')
    },
    /**
     * Suelta el archivo sobre el input
     */
    drop(evt) {
      evt.preventDefault()

      if (!this.enabledDiv || evt.dataTransfer.files === 0) return
      if (!evt.dataTransfer.files[0]) return
      this.typeInputFile = evt.dataTransfer.files[0].type
      this.$refs.fileMessage.files = evt.dataTransfer.files
      this.$refs.div_editable_container.classList.remove('dragged')
      this.$refs.chat_input.classList.remove('dragged-container')

      this.requestUploadFile({ file: this.$refs.fileMessage.files[0] })
    },
    /**
     * Añade un emoji al texto
     */
    handleAddEmoji(emoji) {
      this.textMessage += emoji
      this.$refs.divEditable.innerText += emoji
    },
    /**
     * Muestra el modal
     */
    handleShowModalAnswers() {
      if (!this.enabledDiv) return
      this.modalFastAnswers.visible = true
    },
    /**
     * Muestra el modal de previsualizacion
     */
    handleShowPreview(data) {
      this.modalPreview.visible = true
      this.modalPreview.dataSource = data
    },
    /**
     * Agrega una respuesta rápida
     * @param {Object} data
     * @param {String} data.title
     * @param {String} data.body
     * @param {Object} data.media
     * @param {Array} data.lines
     */
    handleAddFastAnswer(data) {
      this.cleanInput()
      this.cleanFile()

      this.$nextTick(() => {
        if (data.body) {
          this.textMessage += data.body
          this.$refs.divEditable.innerText += data.body
          // debe tener timeout porque si no no funcionara
          setTimeout(() => {
            this.$refs.divEditable.focus()
          }, 1000)
        }
      })

      // si existe media
      if (data.media) {
        this.isFastAnswerWithMedia = true
        const media = data.media
        this.handleAttach(media.typeAttachment, false)

        this.typeFile = media.type
        this.fileAttach = true
        this.percent = 70
        this.nameImg = media.caption

        // si es video o documento
        if (['document', 'video'].includes(media.typeAttachment)) {
          this.captionFile = media.caption
          this.putThumbnailIcon()
        }

        this.urlFile = media.url
        this.urlFileTemp = media.urlTemp
        this.filenameTemp = media.caption
        this.percent = 100

        // si no tiene texto y no está permitido
        // el envio de texto y media juntos
        if (!data.body) {
          this.inputEnabled = this.allowedSendMediaWithText
          this.cleanInput(false)
        }
      }
    },
    /**
     * Envia una respuesta rápida con multimedia,
     * separa los envios de medias y textos en mensajes distintos
     */
    async sendFastAnswerWithMedia() {
      const { text, imageUrl, pdfUrl, wordUrl, excelUrl, pptUrl, videoUrl } =
        this.propertiesToSend()

      // si la respuesta rapida tiene media
      if (this.previewThumbnail || this.previewFile) {
        await this.requestSendMessage({
          imageUrl,
          pdfUrl,
          wordUrl,
          excelUrl,
          pptUrl,
          videoUrl,
        })
      }

      if (text)
        // al ultimo ejecuta el envio de texto
        this.requestSendMessage({
          text,
        })

      // limpia los envios
      // si habia imagen
      if (this.previewThumbnail) this.cleanImg()
      // si habia documentos
      if (this.previewFile) this.cleanFile()
      // si hay texto
      if (text) this.cleanInput()
    },
    /**
     * Muestra el modal para continuar con mensajes de FB
     * @param {Boolean} disabled
     */
    handleShowModalContinueFb(disabled) {
      if (disabled) return
      this.modalContinueFb.visible = true
    },
    /**
     * Cierra el modal de contiuar con mensajes de FB
     */
    handleCloseModalContinueFb() {
      this.modalContinueFb.visible = false
    },
  },
}
</script>

<style lang="sass">
/**corregir esta parte ya que debe ser escopeado */
.disabled-input
  background-color: #F5F5F5 !important
  border: 1px solid #D9D9D9
.color-disabled
  svg
    color: #BFBFBF !important
  path
    fill: #BFBFBF !important
.chat-input
  height: fit-content
  border-radius: 0px 0px 8px 8px
  background-color: $white_000
  border-top: 1px solid #EBEBEB
  padding: 8px 12px
  .div-editable-container
    flex-grow: 1
    background-color: $white_000
    margin: 0px 8px
    border: 1px solid $gray_dark_200
    border-radius: 4px
    transition: .1s
  .enabled-input
    &:hover
      border: 1px solid $gray_dark_100
    &:focus-within
      border: 1px solid $gray_dark_400
  .divEditable
    width: inherit
    height: fit-content
    min-height: 20px
    position: relative
    padding: 0px 12px
    margin: 8px 0px 8px 0px
    text-align: left
    max-height: 82px
    overflow-y: auto
    outline: none
    word-break: break-word
    &:empty:before
      content: attr(data-placeholder)
      color: $gray_dark_400
      // position: absolute
      bottom: 0px
      left: 12px
  .avatar-uploader
    display: none

.dragged-container
  .avatar-uploader
    display: block
    .ant-upload
      width: 100%
  .divEditable
    display: none
    border: none
  .div-editable-container
    border: none
    &:hover
      border: none
</style>
<style lang="sass" scoped>
.chat-input__icon
  margin-left: 12px
  outline: none
  cursor: pointer
  height: 26px
  color: $gray_dark_700
  &:hover
    color: $blue_light_900
  &:focus
    color: $blue_light_900
  &-send, &-mic
    margin: 0px
    &:hover,&:focus
      color: $gray_dark_700
  &-more
    margin: 0px
</style>
