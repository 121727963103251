const mutations = {
  /**
   * Setea todas las descargas
   * @param {*} state
   * @param {Object[]} downloads
   */
  SET_DOWNLOADS(state, downloads) {
    state.downloads = downloads
  },
  /**
   * Setea la paginacion actual de las descargas
   */
  SET_PAGINATE_DOWNLOADS(state, paginate) {
    state.paginate_downloads = paginate
  },
  /**
   * Agrega descargas de una siguiente pagina
   */
  ADD_DOWNLOADS(state, newDownloads) {
    newDownloads.forEach((download) => state.downloads.push(download))
  },
  /**
   * Actualiza una descarga
   * @param {*} state
   * @param {Object} args
   * @param {String} args.downloadId
   * @param {String} args.status
   * @param {Object} args.file
   */
  UPDATE_DOWNLOAD(state, { downloadId, status, file }) {
    const download = state.downloads.find((el) => el._id === downloadId)
    if (!download) return
    if (status) download.status = status
    if (file) {
      download.file = { ...download.file, ...file }
    }
  },
  /**
   * Elimina una descarga
   * @param {*} state
   * @param {String} downloadId
   */
  DELETE_DOWNLOAD(state, downloadId) {
    const index = state.downloads.findIndex(
      (download) => download._id === downloadId
    )
    state.downloads.splice(index, 1)
  },
  /**
   * Setea el limite gastado
   * @param {Number} spent
   */
  SET_SPENT_LIMIT(state, spent) {
    state.limit_download.spent = spent
  },
  /**
   * Agrega una descarga al listado de descargas
   * @param {*} state
   * @param {Object} args
   * @param {String} args.status
   * @param {String} args.caption
   * @param {String} args.downloadId
   */
  ADD_DOWNLOAD(state, { status, caption, downloadId, module = 'dashboard' }) {
    if (!downloadId) return
    const exist = state.downloads.find(
      (download) => download._id === downloadId
    )
    console.log('ADD_DOWNLOAD', exist, downloadId)
    if (exist) return
    state.downloads.unshift({
      created_at: new Date(),
      file: {
        caption: caption,
      },
      _id: downloadId,
      status: status,
      module,
    })
    console.log(state.downloads)
  },
}

export default mutations
