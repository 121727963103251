import vari from '@/app/shared/utils/variables'

const STATUS_CHANNELS = {
  approved: {
    color: 'blue',
    title: 'Conectado',
    value: 'approved',
  },
  pending: {
    color: 'orange',
    title: 'Pendiente',
    value: 'pending',
    tooltip:
      'Se ha enviado una solicitud para conectar un nuevo canal y aún se encuentra en revisión.',
  },
  rejected: {
    color: 'red',
    title: 'Rechazado',
    value: 'rejected',
    tooltip:
      'Se ha enviado una solicitud para conectar un nuevo canal y se no ha sido aprobada.',
  },
  troubled: {
    color: 'red',
    title: 'Expirado',
    value: 'troubled',
    tooltip:
      'Desconexión por causas del canal. Puedes volver a reconectar sin afectar el estado de tus tickets.',
  },
  'pre-unsuscribing': {
    color: 'orange',
    title: 'Pendiente de baja',
    value: 'pre-unsuscribing',
    tooltip:
      'Se ha enviado una solicitud para desconectar el canal y aún se encuentra en revisión.',
  },
  unsuscribing: {
    color: '',
    title: 'Desconectado',
    value: 'unsuscribing',
    tooltip: `Desconexión manual desde la plataforma de ${vari.APP_NAME}.`,
  },
}

export { STATUS_CHANNELS }
