<template>
  <div class="settings-template">
    <header class="template__header">
      <h5 class="heading-h5 gray-dark-900 mrg-bottom-0">AJUSTES GENERALES</h5>
      <a-tooltip placement="bottom">
        <template slot="title">
          Administra las propiedades y preferencias de {{ app }}.
        </template>
        <a-icon type="info-circle" class="template__icon" />
      </a-tooltip>
    </header>
    <a-row :gutter="[12, 12]" class="settings-row">
      <a-col :span="4" style="" class="settings-column">
        <div class="menu-column">
          <MenuSettings :itemSelected="itemSelected" />
        </div>
      </a-col>
      <a-col :span="20" class="settings-column">
        <div class="content-column">
          <router-view></router-view>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuSettings from '@/app/settings/components/organisms/MenuSettings.vue'

export default {
  name: 'SettingsTemplate',
  components: {
    MenuSettings,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
  }),
  computed: {
    ...mapGetters(['company', 'notificationsPhones']),
    // menu seleccionado de configuracion
    itemSelected() {
      if (this.$route.name === 'settings') return null
      return this.$route.name
    },
  },
}
</script>

<style lang="sass" scoped>
$size-title: 24px
$margin-bottom-title: 12px
.settings-template
  // margin: 20px $padding-heigth-inner $padding-heigth-inner
  // height: calc( 100vh - 24px )
  padding: $padding-heigth-inner
  height: 100vh
  h5
    text-align: left
    margin-bottom: $margin-bottom-title
  .settings-row
    height: calc(100vh - #{$size-title + $margin-bottom-title + $padding-heigth-inner})
    .settings-column
      .menu-column, .content-column
        height: 100%
        background-color: $white_000
        border-radius: 8px
        border: 1px solid #EBEBEB
      .menu-column
        padding-top: 20px
      .content-column
        // padding: 20px
        overflow: hidden
  .cards
    height: 100%
    .ant-row
      &.push-down
        // padding: 0 0 $padding-heigth-inner 0
        height: calc(90.2vh - 19em)
      .head
        border-bottom: 1px solid #E8E8E8
        padding: 16px 24px
        &-content
          display: flex
          justify-content: space-between
          align-items: center
</style>

<style lang="sass">
.gray-dark-900
  color: $gray_dark_900
.settings-template
  .master-content
    .content
      background-color: $white_000
  >.ant-row
    // height: 40%
    >.card
      background: $white_000
      border-radius: 2px
      border: 1px solid #E5E5E5
      height: 100%
      .head
        &-content
          .anticon
            width: 21px
            height: 21px
            cursor: pointer
            svg
              width: 100%
              height: 100%
          h2
            margin: 0
  .ant-col
    height: -webkit-fill-available
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
