/**Genera minusculas */
const getRandomLower = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
}

/**Genera mayusculas */
const getRandomUpper = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 65)
}

/**Genera numeros */
const getRandomNumber = () => {
  return +String.fromCharCode(Math.floor(Math.random() * 10) + 48)
}

/**Genera simbolos random */
const getRandomSymbol = () => {
  const symbols = '!@#$%^&*(){}[]=<>/,.'
  return symbols[Math.floor(Math.random() * symbols.length)]
}

/**Genera la contraseña con todos los parametros pasados
 * @param {Boolean} lower - si tendra minuscula
 * @param {Boolean} upper - si tendra mayuscula
 * @param {Boolean} number - si tendra numeros
 * @param {Boolean} symbol - si tendra symbolos
 * @param {Number} length - el tamaño de la contraseña
 */
const generatePassword = (lower, upper, number, symbol, length) => {
  let generatedPassword = ''
  const typesCount = lower + upper + number + symbol
  const typesArr = [{ lower }, { upper }, { number }, { symbol }].filter(
    (item) => Object.values(item)[0]
  )
  if (typesCount === 0) {
    return ''
  }

  // create a loop
  for (let i = 0; i < length; i += typesCount) {
    typesArr.forEach((type) => {
      const funcName = Object.keys(type)[0]
      if (funcName === 'lower') generatedPassword += getRandomLower()
      else if (funcName === 'upper') generatedPassword += getRandomUpper()
      else if (funcName === 'number') generatedPassword += getRandomNumber()
      else {
        generatedPassword += getRandomSymbol()
      }
    })
  }

  const finalPassword = generatedPassword.slice(0, length)
  return finalPassword
}

/**Genera el username
 * @param {String} names - nombres
 * @param {String} surnames - apellidos
 * @param {string} sufix - sufijo de la compañia
 */
const generateUsername = (names, surnames, sufix) => {
  const regExLower = /[a-z]/g
  const firstLetterNames =
    names !== undefined && names.length > 0
      ? names.toLowerCase().match(regExLower).slice(0, 1)
      : ''

  const arrSurnames = surnames !== undefined ? surnames.split(' ') : ''

  const firstSurname =
    arrSurnames !== '' && arrSurnames[0].length !== 0
      ? arrSurnames[0].toLowerCase().match(regExLower).join('')
      : ''

  const twoWordSecondSurname =
    arrSurnames.length > 1 && arrSurnames[1].length !== 0
      ? arrSurnames[1].toLowerCase().match(regExLower).join('').substring(0, 2)
      : ''

  return `${firstLetterNames}${firstSurname}${twoWordSecondSurname}-${sufix}`
}

export { generatePassword, generateUsername }
