import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import Notifications from '@/app/shared/utils/notification'

const actions = {
  /**
   * Suscribe las push notifications
   * @param {*} context
   * @param {Object} suscription
   * @param {String} suscription.endpoint
   * @param {Object} suscription.keys
   */
  async subscribeToPushNotification(context, suscription) {
    try {
      await axios.post(`${vari.UHR}/web-push/suscription`, suscription)
    } catch (error) {
      console.log('[subscribeToPushNotification]', error)
    }
  },
  /**
   * Desuscribe al usuario de las notificaciones, ya no le llegarán
   */
  async unsubscribeToPushNotification() {
    try {
      await axios.delete(`${vari.UHR}/web-push/suscriptions-user`)
    } catch (error) {
      console.log('[unsubscribeToPushNotification]', error)
    }
  },
  /**
   * Remueve el worker y desuscribe al usuario de las notificaciones
   * @param {*} context
   */
  async removeServiceWorker(context) {
    Notifications.unRegisterWorker()
    context.dispatch('unsubscribeToPushNotification')
  },
}

export default { actions }
