import { render, staticRenderFns } from "./SectionChat.vue?vue&type=template&id=00725e90&scoped=true&"
import script from "./SectionChat.vue?vue&type=script&lang=js&"
export * from "./SectionChat.vue?vue&type=script&lang=js&"
import style0 from "./SectionChat.vue?vue&type=style&index=0&id=00725e90&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00725e90",
  null
  
)

export default component.exports