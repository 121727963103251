import { render, staticRenderFns } from "./EditableCustomFieldColumn.vue?vue&type=template&id=805f3ecc&scoped=true&"
import script from "./EditableCustomFieldColumn.vue?vue&type=script&lang=js&"
export * from "./EditableCustomFieldColumn.vue?vue&type=script&lang=js&"
import style0 from "./EditableCustomFieldColumn.vue?vue&type=style&index=0&id=805f3ecc&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805f3ecc",
  null
  
)

export default component.exports