const initialState = () => ({
  line: null, // Cola seleccionada
  lines: [], //Colas accesibles para el usuario
  companyLines: [], //Todas las Colas de la empresa
  accounts: {
    agents: [],
    supervisors: [],
  },
  action_desasigned: {
    asigned: null,
    typeUser: null,
    nameLine: null,
  },
  action_asigned: {
    asigned: null,
    typeUser: null,
    nameLine: null,
  },
  action_channel_removed: {
    title: null,
    description: null,
  },
  action_chat_preferences_updated: {
    title: null,
    description: null,
  },
  reloadTableLines: 0,
  reloadTableAccounts: 0,
})

export default initialState
