<template>
  <card-base class="card-strategy">
    <social-icon :type="channel" :size="57" />
    <h5 class="semibold-20 card-strategy__title mrg-top-8">{{ title }}</h5>
    <p class="regular-14">
      {{ description }}
    </p>
  </card-base>
</template>

<script>
import CardBase from '@/app/shared/components/cards/CardBase'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'CardStrategyChannel',
  components: {
    CardBase,
    SocialIcon,
  },
  props: {
    channel: {
      type: String,
      enum: ['whatsapp', 'sms'],
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data: () => ({}),
}
</script>

<style lang="sass" scoped>
.card-strategy
  width: 20em
  text-align: center
  border-radius: 18px
  border-width: 2px
  cursor: pointer
  &__title
    color: $blue_6
  &:hover
    border: 2px solid $blue_6
    background-color: $blue_1
</style>
