<template>
  <modal-type
    :visible="visible"
    :title="title"
    :customRequest="resolveTicket"
    @onClose="cancelResolveTicket"
    type="confirmation"
    okText="Resolver"
    description="Recuerda que los tickets en cola de espera aún no han sido entregados a tus agentes, si los resuelves tus agentes no podrán responder."
  >
    <p class="regular-14 modal__description">
      Escribe un breve comentario sobre la gestión de este ticket para poder
      resolverlo.
    </p>
    <label class="semibold-14 modal__label full-width">Comentario</label>
    <a-form-model-item class="full-width modal__form-textarea">
      <counter-text :text="resolveComment" :maxLength="maxComment">
        <a-textarea
          placeholder="Escribe aquí, tienes caracteres limitados..."
          v-model="resolveComment"
          :maxLength="maxComment"
          :auto-size="{ minRows: 3, maxRows: 4 }"
          class="textarea"
        />
      </counter-text>
    </a-form-model-item>
  </modal-type>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalType from '@/app/shared/components/modals/ModalType'
import formatMixin from '@/app/shared/mixins/format'
import CounterText from '@/app/shared/components/molecules/CounterText'

export default {
  name: 'ModalConfirmationResolveTicket',
  components: {
    ModalType,
    CounterText,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    quantityTickets: {
      type: Number,
      required: false,
      default: 1,
    },
    onOk: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    maxComment: 150,
    resolveComment: '',
  }),
  mixins: [formatMixin],
  computed: {
    ...mapGetters(['buttons_size']),

    /**
     * @return {String}
     */
    title() {
      if (this.quantityTickets > 1)
        return '¿Estás segur@ de resolver tickets que están en cola de espera?'
      else return '¿Estás segur@ de resolver este ticket en cola de espera?'
    },
  },
  methods: {
    /**
     * Ejecuta el evento de resolucion de ticket y emite el cerrado del modal
     */
    async resolveTicket() {
      await this.onOk({ comment: this.resolveComment })
      this.resolveComment = ''
      this.$emit('onClose')
    },
    cancelResolveTicket() {
      this.resolveComment = ''
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.ant-form-item
  margin-bottom: 0px
.textarea
  position: relative
.modal__description
  color: $gray_dark_900
.modal__label
  color: $gray_dark_900
  margin-bottom: 2px
  text-align: left
.modal__form-textarea
  margin-bottom: 32px
</style>
