import axios from 'axios'
import io from 'socket.io-client'
import vari from '@/app/shared/utils/variables'
import attempt from '@/app/shared/utils/attempt'
import Vue from 'vue'
import router from '@/router'

const initialState = () => ({})

const state = initialState

const getters = {}

const mutations = {}

/**
 * Remover todos los eventos socket.io
 * @return {Promise<void>}
 */
const removeEventsIO = () => {
  if (!Vue.prototype.$socket) return
  console.log('Removing events...')
  Vue.prototype.$socket.removeEventListener('client-receive-message')
  Vue.prototype.$socket.removeEventListener('client:line:settings')
  Vue.prototype.$socket.removeEventListener('client:user:lineId')
  Vue.prototype.$socket.removeEventListener('client:user:lineId:channel')
  Vue.prototype.$socket.removeEventListener('client:line:asign:agent')
  Vue.prototype.$socket.removeEventListener('client:update:user:status')
  Vue.prototype.$socket.removeEventListener('client:update:event')
  Vue.prototype.$socket.removeEventListener('client:area:gross:mode:agent')
  Vue.prototype.$socket.removeEventListener('client:ticket:settings')
  Vue.prototype.$socket.removeEventListener('client:mailbox:selected')
  Vue.prototype.$socket.removeEventListener('client:chat:mailbox:pendings')
  Vue.prototype.$socket.removeEventListener('client:remote:logout')
  Vue.prototype.$socket.removeEventListener('client:accept:session')
  Vue.prototype.$socket.removeEventListener('client:reject:session')
  Vue.prototype.$socket.removeEventListener('client:other:sessions')
  Vue.prototype.$socket.removeEventListener('client-transfer-room-schedules')
  Vue.prototype.$socket.removeEventListener('client-receive-message')
  Vue.prototype.$socket.removeEventListener('client:ticket:new')
  Vue.prototype.$socket.removeEventListener('client:ticket:transfer')
  Vue.prototype.$socket.removeEventListener('client:ticket:client')
  Vue.prototype.$socket.removeEventListener('client:ticket:mailbox')
  Vue.prototype.$socket.removeEventListener('client-send-message')
  Vue.prototype.$socket.removeEventListener('client-receive-message-status')
  Vue.prototype.$socket.removeEventListener('client:company:new:line')
  Vue.prototype.$socket.removeEventListener('client:company:new:user')
  Vue.prototype.$socket.removeEventListener('client:company:settings')
  Vue.prototype.$socket.removeEventListener('client:company:download:status')
  Vue.prototype.$socket.removeEventListener('client:company:download:limit')
}

const actions = {
  /**
   * Crea un primer hilo de socket con el servidor para esperar respestas de denegación o acceso
   * @param {*} context
   * @param {Object} credentials
   * @param {String} credentials.userId userId del usuario quien se está logueando
   */
  async joinHoldConnection(context, { userId }) {
    try {
      await context.dispatch('validThreadConnection')
      await Vue.prototype.$socket.emit('server-first-connection-hold', userId)
    } catch (error) {
      console.error('[socketModule][joinHoldConnection]', error)
    }
  },

  /** Valida la conexión con el socket y conecta o reconect */
  async validThreadConnection(context) {
    try {
      if (!Vue.prototype.$socket) {
        Vue.prototype.$socket = await io(`${vari.UHS}`)
        Vue.prototype.$socket.on('reconnect', async () => {
          try {
            console.log('Escuchando todos los eventos...')
            console.log('[------- RECONECTADO -------]')
            await context.dispatch('validateSignin', { reconnection: true })
            context.commit('SET_INTERNET_STATE', true)
            context.dispatch('patchEvents')
          } catch (e) {
            console.log('[socketModule][validThreadConnection]', e)
          }
        })
        Vue.prototype.$socket.on('connect_error', async () => {
          try {
            removeEventsIO()
            context.commit('SET_INTERNET_STATE', false)
          } catch (e) {
            console.log('[socketModule][validThreadConnection]', e)
          }
        })
      }
      return true
    } catch (error) {
      console.error('[socketModule][validThreadConnection]', error)
      return false
    }
  },

  /** Cierra todas los hilos de conexiones con el socket */
  async closeThreads(context) {
    Vue.prototype.$socket.emit(
      'server-exit-connection-hold',
      context.getters.profile.username
    )
    Vue.prototype.$socket.emit('server-exit-channel-user', {
      idUser: context.getters.profile.userId,
    })
    Vue.prototype.$socket.disconnect()
  },

  /** Unirse a un canal segun su tipo de usuario */
  async joinThread(context) {
    return new Promise((res, rej) => {
      try {
        return attempt.endWaitAttempting(async () => {
          const profileLogged = context.getters.profile
          await context.dispatch('joinHoldConnection', {
            userId: profileLogged.userId,
          })

          switch (profileLogged.type) {
            case 'agent': {
              await context.dispatch('joinAgentThread')
              break
            }
            case 'supervisor': {
              await context.dispatch('joinSupervisorThread', false)
              break
            }
            case 'supervisor_agent': {
              await context.dispatch('joinSupervisorThread', true)
              break
            }
            case 'admin': {
              await context.dispatch('joinAdminThread', false)
              break
            }
            case 'admin_agent': {
              await context.dispatch('joinAdminThread', true)
              break
            }
            case 'master_agent': {
              await context.dispatch('joinAdminThread', true)
              break
            }
            case 'securitec': {
              break
            }
            default:
              return res(false)
          }
          return res(true)
        })
      } catch (error) {
        console.error('[socketModule][joinThread]', error)
        rej(false)
      }
    })
  },

  /**
   * Realiza las conexion respectivas a los hilos del socket para el Agente
   * @param {*} context
   */
  async joinAgentThread(context) {
    try {
      context.dispatch('registerSocket')
      context.dispatch('joinCompanyThread')
      await context.dispatch('joinUserThread')
      await context.dispatch('joinLinesThread')
      context.dispatch('listenWelcomeMessageThread')
      context.dispatch('listenNewTicketsThread')
      context.dispatch('listenNewReceivedMessagesThread')
      context.dispatch('listenStatusMessages')
      context.dispatch('listenTransferedTicketThread')
      context.dispatch('listenLineSettedThread')
      context.dispatch('listenLineChannel')
      context.dispatch('listenLineInserted')
      context.dispatch('listenCompanySettings')
      context.dispatch('listenValidateSession')
      context.dispatch('listenRemovedMailbox')
      context.dispatch('listenTicketsEnded')
      context.dispatch('listenTransferedSchedulesTicket')
      context.dispatch('listenMailboxTicketThread')
      context.dispatch('listenAssignChannel')
      context.dispatch('listenChatPreferencesUpdated')
      context.dispatch('listenUpdateSettings')
      context.dispatch('listenNotifications')
      context.dispatch('listenUpdateCompanyPricing')
      context.dispatch('listenNewSentMessages')
      context.dispatch('listenAddComment')
      context.dispatch('listenStatusDownload')
      context.dispatch('listenUpdateLimitDownload')

      setTimeout(() => {
        context.dispatch('listenRemoteLogout')
      }, 1000)
    } catch (error) {
      console.error('[socketModule][joinAgentThread]', error)
    }
  },
  /**
   * Realiza las conexion respectivas a los hilos del socket para el Administrador
   * @param {vuex}    context
   * @param {Boolean} [agentMode=false]
   */
  async joinAdminThread(context, agentMode = false) {
    try {
      context.dispatch('registerSocket')
      context.dispatch('joinCompanyThread')
      context.dispatch('listenSetMenuSelected')
      context.dispatch('listenSetStatusAgent')
      context.dispatch('listenTicketUnread')
      context.dispatch('listenSupervisionTicketsActive')
      context.dispatch('listenTicketRemoteSelected')
      context.dispatch('listenLineAgentAssigned')
      context.dispatch('listenCompanySettings')
      context.dispatch('listenValidateSession')
      context.dispatch('listenUpdateSettings')
      context.dispatch('listenLineChannel')
      context.dispatch('listenAssignChannel')
      context.dispatch('listenTicketsEnded')
      context.dispatch('listenChatPreferencesUpdated')
      context.dispatch('listenAgentMode')
      context.dispatch('listenUpdateChatbot')
      context.dispatch('listenRoutes')
      context.dispatch('listenUpdateCompanyPricing')
      context.dispatch('listenStatusStrategy')
      context.dispatch('listenStatusDownload')
      context.dispatch('listenUpdateLimitDownload')

      if (agentMode) {
        await context.dispatch('joinUserThread')
        context.dispatch('listenNewTicketsThread')
        await context.dispatch('joinLinesThread')
        context.dispatch('listenNewReceivedMessagesThread')
        context.dispatch('listenTransferedTicketThread')
        context.dispatch('listenStatusMessages')
        context.dispatch('listenMailboxTicketThread')
        context.dispatch('listenNotifications')
        context.dispatch('listenLineSettedThread')
        context.dispatch('listenNewSentMessages')
        context.dispatch('listenAddComment')
      }

      setTimeout(() => {
        context.dispatch('listenRemoteLogout')
      }, 1000)
    } catch (error) {
      console.error('[socketModule][joinAdminThread]', error)
    }
  },
  /**
   * Realiza las conexion respectivas a los hilos del socket para el Supervisor
   * @param {vuex}    context
   * @param {Boolean} [agentMode=false]
   */
  async joinSupervisorThread(context, agentMode = false) {
    try {
      context.dispatch('registerSocket')
      context.dispatch('joinCompanyThread')
      await context.dispatch('joinUserThread')
      await context.dispatch('joinLinesThread')
      context.dispatch('listenSetMenuSelected')
      context.dispatch('listenSetStatusAgent')
      context.dispatch('listenTicketUnread')
      context.dispatch('listenSupervisionTicketsActive')
      context.dispatch('listenTicketRemoteSelected')
      context.dispatch('listenLineAgentAssigned')
      context.dispatch('listenCompanySettings')
      context.dispatch('listenValidateSession')
      context.dispatch('listenLineSettedThread')
      context.dispatch('listenLineChannel')
      context.dispatch('listenUpdateSettings')
      context.dispatch('listenAssignChannel')
      context.dispatch('listenTicketsEnded')
      context.dispatch('listenChatPreferencesUpdated')
      context.dispatch('listenAgentMode')
      context.dispatch('listenUpdateCompanyPricing')

      if (agentMode) {
        await context.dispatch('joinUserThread')
        context.dispatch('listenNewTicketsThread')
        await context.dispatch('joinLinesThread')
        context.dispatch('listenNewReceivedMessagesThread')
        context.dispatch('listenTransferedTicketThread')
        context.dispatch('listenStatusMessages')
        context.dispatch('listenMailboxTicketThread')
        context.dispatch('listenNotifications')
        context.dispatch('listenAddComment')

        setTimeout(() => {
          context.dispatch('listenRemoteLogout')
        }, 1000)
      }
    } catch (error) {
      console.error('[socketModule][joinSupervisorThread]', error)
    }
  },
  /** Registra el Id del socket para saber cuando hay algun tab activo del usuario logueado */
  async registerSocket() {
    /** Intenta guardar el id del socket, cuando lo encuentre */
    const tryingSaveSocketId = setInterval(() => {
      if (Vue.prototype.$socket.id) {
        //context.dispatch("updateUserStatus", { status: "connected" })
        axios.put(`${vari.UHR}/session/addSocket`, {
          socketId: Vue.prototype.$socket.id,
        })
        clearInterval(tryingSaveSocketId)
      }
    }, 300)
    setTimeout(() => clearInterval(tryingSaveSocketId), 5000) // Si no conecta en 5 seg, ya no lo intenta más
  },
  /** Cierra las conexiones de las salas y canales del socket */
  async closeSocket(context) {
    const profile = context.getters.profile
    Vue.prototype.$socket?.emit('server-exit-connection-hold', profile.username)
    Vue.prototype.$socket?.emit('server-exit-channel-user', {
      idUser: profile.userId,
    })
    Vue.prototype.$socket?.disconnect()
    Vue.prototype.$socket = null
  },

  /** Parcha eventos que hayan ocurrido cuando se perdió la conexión a internet */
  async patchEvents(context) {
    try {
      if (router.history.current.meta.is_workspace) {
        const numberTicketSelected = router.history.current.params?.ticketId
        let ticketId = null

        // si existen tickets
        if (context.getters.tickets) {
          const ticketFound = context.getters.tickets.find(
            (ticket) => ticket.ticket == numberTicketSelected
          )
          ticketId = ticketFound?._id
        }

        context.dispatch('patchStatusMessagesInTicket', ticketId)
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
