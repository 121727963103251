<template>
  <a-modal
    :visible="visible"
    @cancel="$emit('onClose')"
    :body-style="bodyStyle"
    width="48em"
    :footer="null"
    :afterClose="handleReset"
    centered
  >
    <a-row type="flex" class="flex-no-wrap full-height">
      <a-steps
        v-if="currentStep !== 4"
        :current="currentStep"
        direction="vertical"
        class="modal__steps"
      >
        <a-step v-for="item in steps" :key="item.title" />
      </a-steps>

      <div class="full-height">
        <div class="modal__title" v-if="currentStep !== 4">
          <h6 class="semibold-20">{{ title }}</h6>
        </div>

        <custom-section-scroll
          v-if="currentStep !== 4"
          :master-styles="{ height: 'calc( 94% - 58px)' }"
          :content-styles="{ padding: '0px 36px 0px 36px' }"
        >
          <supported-channels
            v-if="currentStep === 0"
            @onSelect="handleSelectSuppotedChannel"
            :default-selected="supportSelected ? supportSelected.value : ''"
          />
          <general-form
            v-if="currentStep === 1"
            ref="generalForm"
            class="modal__general-form"
            :form-values="formValues"
            type="create"
          />
          <template v-else-if="currentStep === 2">
            <p
              class="body-2"
              style="color: #747781; margin-top: -4px; margin-bottom: 8px"
              v-if="!isThereChannelsActive"
            >
              ¡Hola! 👋 Vemos que aún no conectas tu primer canal. Te podemos
              ayudar
              <a :href="appPhoneNumberWsp" target="_blank"
                >chatea con un experto</a
              >
            </p>
            <p v-else>{{ descriptionForStepAssignChannels }}</p>
            <list-picker-channels
              ref="list_picker_channels"
              :default-values="channelsFormValues(formValues.channels)"
              :disabled="!isThereChannelsActive"
              :enabled-channels="supportSelected.channels"
              @onChange="saveChannels"
            />

            <a-row v-if="infoExtra" class="modal__info-extra">
              <a-col>
                <p class="semibold-14">Recuerda</p>
                <p class="info_i2">{{ infoExtra }}</p>
              </a-col>
            </a-row>
          </template>
          <schedule-form
            v-else-if="currentStep === 3"
            ref="scheduleForm"
            :form-values="scheduleFormValues(formValues)"
          />

          <img
            v-if="currentStep === 1"
            src="/img/graphics/outlined/chatbot-walking.png"
            class="modal__picture"
          />
        </custom-section-scroll>
        <success-create
          v-if="currentStep === 4"
          @onOther="handleOpenNewCreate"
          @onGoToChatbot="handleGoToChatbot"
          :nameChatbot="formValues.name"
        />
        <div
          v-if="(currentStep !== 0 || supportSelected) && currentStep !== 4"
          class="modal__footer display-flex"
        >
          <a-button
            v-if="currentStep === 2"
            type="link"
            @click="handleChangeStep(currentStep, 'sum')"
          >
            Omitir
          </a-button>
          <div style="flex: 1 1 0%"></div>

          <div>
            <a-button v-if="currentStep === 0" @click="$emit('onClose')"
              >Cancelar</a-button
            >
            <a-button
              v-if="currentStep > 0 && currentStep < 4"
              @click="handleChangeStep(currentStep, 'minus')"
              >Volver</a-button
            >
            <a-button
              type="primary"
              :disabled="disabledOkButton"
              @click="handleSubmit"
              class="modal__button--primary"
              >{{ okButton }}</a-button
            >
          </div>
        </div>
      </div>
    </a-row>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralForm from '@/app/chatbots/components/organisms/forms/GeneralForm'
import ScheduleForm from '@/app/chatbots/components/organisms/forms/ScheduleForm'
import ListPickerChannels from '@/app/shared/components/organisms/ListPickerChannels'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import SuccessCreate from '@/app/chatbots/components/organisms/SuccessCreate'
import moment from 'moment'
import SupportedChannels from '@/app/chatbots/components/organisms/SupportedChannels'

export default {
  name: 'ModalCreateChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    moment,
    currentStep: 0,
    formValues: { channels: [] },
    idChatbot: null,
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
    steps: [
      { title: 'first', content: '' },
      { title: 'second', content: '' },
      { title: 'third', content: '' },
      { title: 'last', content: '' },
    ],
    supportSelected: null,
  }),
  components: {
    GeneralForm,
    ScheduleForm,
    ListPickerChannels,
    CustomSectionScroll,
    SuccessCreate,
    SupportedChannels,
  },
  watch: {
    /**Dependiendo del paso , hara una accion */
    currentStep(val) {
      switch (val) {
        case 0:
          {
            this.saveGeneralForm()
          }
          break
        case 1:
          {
            this.saveChannelsForm()
            this.$nextTick(() => this.handleSetFields('general'))
          }
          break
        case 2:
          {
            const scheduleForm = this.$refs.scheduleForm
            this.formValues.schedule = {}
            this.formValues.schedule.timezone = scheduleForm?.timezoneSelected
            this.formValues.schedule.days = scheduleForm?.arrayRangePickers
          }
          break
        case 3:
          this.$nextTick(() => this.handleSetFields('schedule'))
      }
    },
  },
  computed: {
    ...mapGetters(['company']),

    /**
     * Devuelve el nombre del button
     * @return {String} - button's name
     */
    okButton() {
      switch (this.currentStep) {
        case 0:
          return 'Continuar'
        case 1:
          return 'Continuar'
        case 2:
          return 'Continuar'
        case 3:
          return 'Crear chatbot'
        default:
          return 'okButton'
      }
    },
    /**
     * Deshabilita el boton principal
     * @return {Boolean}
     */
    disabledOkButton() {
      if (this.currentStep !== 2) return false
      else
        return !(
          this.currentStep === 2 &&
          this.formValues &&
          this.formValues.channels &&
          this.formValues.channels.length > 0
        )
    },
    /**
     * Devuelve el titulo del modal dependiendo del paso
     * @return {String} - title
     */
    title() {
      switch (this.currentStep) {
        case 0:
          return '¿Dónde te gustaría agregar este chatbot?'
        case 1:
          return 'Ahora, completa los detalles de tu chatbot'
        case 2:
          return 'Asigna canales a tu chatbot'
        case 3:
          return 'Configura el horario de trabajo de tu chatbot'
        default:
          return 'title'
      }
    },
    /**
     * @returns {String}
     */
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}?text=Hola%2C%20quiero%20solicitar%20asesoria%20de%20un%20experto%20en%20${this.app}`
    },
    /**
     * Devuelve true or false si es que hay canales activos
     * @return {Boolean}
     */
    isThereChannelsActive() {
      const company = this.company

      if (
        company &&
        company.channels &&
        Object.keys(company.channels).length > 0
      )
        return true
      else return false
    },
    /**
     * @return {String} descripcion por asignacion de canal
     */
    descriptionForStepAssignChannels() {
      if (!this.supportSelected) return ''
      else if (this.supportSelected.value === 'whatsapp')
        return 'Se listarán solo identificadores del canal de WhatsApp. Puedes elegir si asignarlo ahora o al editar tu chatbot.'
      else
        return 'Se listarán solo identificadores de los canales de Webchat, Instagram y Messenger. Puedes elegir si asignarlo ahora o al editar tu chatbot.'
    },
    /**
     * Estilos del modal de acuerdo a el paso en que se encuentre
     * @return {Object} bodyStyle
     */
    bodyStyle() {
      switch (this.currentStep) {
        case 4:
          return {
            padding: '0px',
            height: '50em',
            position: 'relative',
          }
        default:
          return {
            padding: '32px 0px 0px 32px',
            height: '50em',
            position: 'relative',
          }
      }
    },
    /**
     * @return {String} información extra por canal
     */
    infoExtra() {
      if (!this.supportSelected) return null
      else if (this.supportSelected.value === 'whatsapp')
        return 'El chatbot no atenderá tickets de WhatsApp si es que no cuentas con saldo disponible para este canal.'
      else return null
    },
  },
  methods: {
    ...mapActions(['addChatbot']),

    /**
     * Continua los pasos
     */
    handleContinue() {
      if (this.currentStep === 0) {
        this.handleChangeStep(this.currentStep, 'sum')
      } else if (this.currentStep === 1) {
        const generalForm = this.$refs.generalForm
        const form = generalForm.form
        form.validateFields((err, values) => {
          if (!err) {
            this.formValues = { ...this.formValues, ...values }
            this.handleChangeStep(this.currentStep, 'sum')
          }
        })
      } else if (this.currentStep === 2) {
        const listPicker = this.$refs.list_picker_channels
        const listChannels = listPicker?.handleGetList()

        this.formValues.channels = listChannels

        this.handleChangeStep(this.currentStep, 'sum')
      } else if (this.currentStep === 3) {
        this.saveScheduleForm(true)
      }
    },
    /**
     * Cambia el paso del modal
     * @param {Number} currentStep
     * @param {String} operation - sum | minus
     */
    handleChangeStep(currentStep, operation) {
      if (operation === 'sum') this.currentStep = currentStep + 1
      else if (operation === 'minus') this.currentStep = currentStep - 1
      else this.currentStep
    },
    /**
     * Guarda los valores del formulario general
     */
    saveGeneralForm() {
      const generalForm = this.$refs.generalForm
      if (!generalForm) return

      const form = generalForm.form
      const values = form.getFieldsValue()

      this.formValues = { ...this.formValues, ...values }
    },
    /**
     * Guarda los valores del formulario de canales
     */
    saveChannelsForm() {
      const listPicker = this.$refs.list_picker_channels
      if (!listPicker) return
      const listChannels = listPicker.handleGetList()
      this.formValues.channels = listChannels
    },
    /**
     * Guarda los valores de canales
     */
    saveChannels(channels) {
      this.formValues.channels = channels
    },
    /**
     * Guarda los valores del formulario de horario
     */
    saveScheduleForm(create) {
      const scheduleForm = this.$refs.scheduleForm
      const form = scheduleForm.form
      scheduleForm.handleValidateListRanges(false)
      form.validateFields(async (err, values) => {
        const isFilledDays = scheduleForm.daysSelecteds.length > 0
        const isFilledRanges = scheduleForm.arrayRangePickers.some((day) =>
          day.hours.some((range) => !range.start || !range.end)
        )
        if (isFilledDays && !err && !isFilledRanges) {
          /**
           * El timezone offset se deberá obtener a partir de la zona horaria seleccionada
           */
          scheduleForm.arrayRangePickers.forEach((range) =>
            range.hours.map((hour) => {
              hour.start = this.converToLocal(
                hour.start,
                scheduleForm.timezoneOffset
              )
              hour.end = this.converToLocal(
                hour.end,
                scheduleForm.timezoneOffset
              )
              return hour
            })
          )
          this.formValues.schedule = {
            timezone: values.timezone,
            days: scheduleForm.arrayRangePickers,
          }
          if (create) this.handleCreateChatbot()
        } else if (isFilledDays && !isFilledRanges) {
          scheduleForm.handleVerifiedDays()
        } else {
          this.formValues.schedule = {
            timezone: values.timezone,
            days: [],
          }
          this.handleCreateChatbot()
        }
      })
    },
    /**
     * Crea el chatbot
     */
    async handleCreateChatbot() {
      this.formValues.channels = this.formValues.channels.map(
        (channel) => channel.channelCompanyId
      )
      this.formValues.supportedChannels = this.supportSelected.value

      const response = await this.addChatbot(this.formValues)
      if (response.type === 'tree') {
        this.idChatbot = response._id
        this.handleChangeStep(this.currentStep, 'sum')
      } else {
        this.$message.error({
          content: response.details ? response.details : 'Ocurrió un error',
          duration: 5,
        })
      }
    },
    /**
     * Submit del formulario
     */
    handleSubmit() {
      if (this.currentStep <= 3) this.handleContinue()
    },
    handleGoToChatbot() {
      this.$emit('onClose')
      this.$router.push({ name: 'id', params: { id: this.idChatbot } })
    },
    handleOpenNewCreate() {
      this.$emit('onClose')
      setTimeout(() => this.$emit('onVisible'), 300)
    },
    handleReset() {
      this.currentStep = 0
      this.formValues = { channels: [] }
      this.$refs.generalForm?.resetFields()
      this.$refs.scheduleForm?.resetFields()
      this.idChatbot = null
      this.supportSelected = null
    },
    /**
     * Setea los valores dependiendo del keytab
     * @param {String} keyTab - general | schedule
     */
    handleSetFields(keyTab) {
      const generalForm = this.$refs.generalForm
      const scheduleForm = this.$refs.scheduleForm
      if (keyTab === 'general') {
        generalForm.setFieldsValues()
      } else if (keyTab === 'schedule') {
        scheduleForm.setFieldsValues()
      }
    },
    /**
     * Retorna solo el schedule del chatbot
     * @param {Object} chatbot
     * @param {Object} chatbot.schedule
     * @param {String} chatbot.schedule.timezone
     * @param {Array} chatbot.schedule.days
     */
    scheduleFormValues(chatbot) {
      let schedule = {
        timezone: chatbot.schedule?.timezone,
        timezoneoffset: chatbot.schedule?.timezoneoffset,
        days: chatbot.schedule?.days,
      }
      return schedule
    },
    channelsFormValues(channels) {
      if (!channels) return
      return channels
    },
    /**
     * Convierte la hora a tu zona horaria local
     */
    converToLocal(hour, timezoneOffset) {
      const formatted = new Date(hour.format())
      formatted.setFullYear(2000, 0, 1)
      const isoDate = formatted.toISOString()
      const utcZero = moment(isoDate).subtract(timezoneOffset, 'minutes')
      const utcZeroFormatted = utcZero.format('YY-MM-DD HH:mm')

      const timezoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone
      const dateLocal = moment.tz(utcZeroFormatted, timezoneLocal)
      return moment(dateLocal).format()
    },
    /**
     * Escucha la selección de los canales soportados
     * @param {Object} supportedChannels
     * @param {String} supportedChannels.channel
     * @param {String} supportedChannels.title
     * @param {String} supportedChannels.value
     * @param {String[]} supportedChannels.channels
     */
    handleSelectSuppotedChannel(supportedChannels) {
      this.currentStep++
      // si los canales soportados son distintos al seleccionado anteriormente
      // se limpian los canales seleccionados previamente
      if (
        this.supportSelected &&
        this.supportSelected.channel !== supportedChannels.channel
      ) {
        this.formValues.channels = []
      }
      this.supportSelected = supportedChannels
    },
  },
}
</script>

<style lang="sass" scoped>

.ant-steps-item
  height: 100px
.modal__picture
  position: absolute
  right: 24px
  bottom: 0px
.modal__title
  padding: 0px 36px 0px 36px
.modal__footer
  padding: 12px
  border-top: 1px solid $gray_5
  width: 100%
  position: absolute
  left: 0
.modal__button--primary
  margin-left: 8px
.modal__steps
  width: 32px
.modal__info-extra
  max-width: 80%
  position: absolute
  bottom: 20px
.modal__general-form
  width: 38em
</style>
