<template>
  <a-table
    :columns="columns"
    :data-source="dataSorted"
    size="middle"
    :loading="loading"
    :pagination="pagination"
    :locale="{ emptyText: 'No se encontraron variables' }"
    @change="handleChangePagination"
    :scroll="{ y: 'calc(100vh - 20em)' }"
    class="table-scroll"
  >
    <span slot="destinityTitle">
      Destino del campo
      <a-tooltip title="Lugar donde se guardará la información registrada">
        <a-icon type="info-circle" class="mrg-right-4" />
      </a-tooltip>
    </span>

    <!--Columa de teléfono del cliente-->
    <template slot="destinity" slot-scope="destinity, record">
      <span
        v-if="
          record.default &&
          (!record.customField || !Object.keys(record.customField).length)
        "
        class="info_i2 table-item--default"
        >Datos personales del cliente</span
      >
      <span v-else>{{ transformDestinity(destinity) }}</span>
    </template>
    <template slot="action" slot-scope="record">
      <a @click="handleShowEdit(record)">Editar</a>
      <a-divider type="vertical" />
      <a @click="handleShowDelete(record)" :disabled="record.default"
        >Eliminar</a
      >
    </template>
  </a-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VarsChatbotTable',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    columns: [
      {
        title: 'N°',
        key: 'order',
        dataIndex: 'order',
        width: 60,
      },
      {
        title: 'Nombre de la variable',
        dataIndex: 'name',
        key: 'name',
        width: 300,
      },
      {
        dataIndex: 'customField.module',
        key: 'destinity',
        slots: { title: 'destinityTitle' },
        scopedSlots: { customRender: 'destinity' },
      },
      {
        title: 'Nombre del campo personalizado',
        key: 'customField.name',
        dataIndex: 'customField.name',
        customRender: (value) => value || '-',
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    localeTable: {
      emptyText: 'No se han encontrado variables',
    },
  }),
  computed: {
    ...mapGetters([
      'paginate_chatbots_vars',
      'type_vars',
      'pagination_chatbots_vars',
    ]),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.pagination_chatbots_vars.total || 0,
        current: this.pagination_chatbots_vars.page,
      }
    },

    dataSorted() {
      //Ordena el array de estrategias
      let startOrder =
        1 +
        (this.pagination_chatbots_vars.page - 1) *
          this.pagination.defaultPageSize
      return this.paginate_chatbots_vars.map((chatbotVar) => {
        chatbotVar.order = startOrder++
        return chatbotVar
      })
    },
  },
  methods: {
    transformDestinity(destinity) {
      switch (destinity) {
        case 'workspace':
          return 'Datos personalizados del ticket'
        case 'clients':
          return 'Datos personalizados del cliente'
      }
    },
    handleShowEdit(record) {
      this.$emit('onShowEdit', record)
    },
    handleShowDelete(record) {
      if (record.default) return
      this.$emit('onShowDelete', record)
    },
    /**
     * Emite el cambio de paginacion de la tabla
     * @param {Object} pagination
     */
    handleChangePagination(pagination) {
      this.$emit('onPaginate', pagination.current)
    },
  },
}
</script>

<style lang="sass" scoped>
.table-item--default
  color: $gray_6
</style>
