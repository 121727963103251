<template>
  <div class="schedules">
    <SectionSchedule />
  </div>
</template>

<script>
import SectionSchedule from '@/app/schedule/components/templates/ScheduleTemplate.vue'
export default {
  name: 'Schedules',
  components: {
    SectionSchedule,
  },
}
</script>
