/**Evalua canales, usuarios, etc soportados por ticker */
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'company',
      'ticketSelected',
      'channels',
      'simple_company_channels',
      'client',
      'active_breakpoint',
      'profile',
      'lines',
      'profile_states',
      'origin_simple_company_channels',
    ]),

    /**
     * Retorna todos los canales habilitados para los filtros
     * y verifica si el canal de sms está en la empresa
     * @returns {Object[]}
     */
    channelsForFilters() {
      return this.filterGeneralChannels(['sms'])
    },
    /**
     * Retorna todos los canales habilitados para los filtros
     * sin importar si están agregados a la empresa
     * @returns {Object[]}
     */
    activeChannelsForFilters() {
      return this.filterGeneralChannels([])
    },

    /**
     * Si la empresa tiene permitida la recarga para whatsapp
     * @returns {Boolean}
     */
    allowRechargeWba() {
      return (
        this.simple_company_channels &&
        !!this.simple_company_channels.length &&
        !!this.filterSimpleChannelWhatsAppByType({ type: 'gupshup_v1' }).length
      )
    },
    /**
     * Ruta dinamica del ticker
     * @returns {String} - drawer-ticket: para la ruta de workspace, drawer-remote-ticket: para la ruta de workspace-monitor
     */
    routeTicket() {
      return this.$route.meta.is_workspace
        ? 'drawer-ticket'
        : 'drawer-remote-ticket'
    },
    /**
     * @returns {Object} item del menu
     * @returns {Object} item.route
     * @returns {String} item.route.name
     * @returns {Object} item.query
     * @returns {String} item.query.mailbox
     * @returns {Object} item.query.status
     * @returns {Object} item.avatar
     * @returns {String} item.avatar.src
     * @returns {String} item.title
     */
    menuItemsTicket() {
      return [
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'client' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          avatar: {
            src: this.client?.avatar,
            icon: !this.client || !this.client.avatar ? 'user' : null,
          },
          title: 'Perfil de cliente',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'old-tickets' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          component: 'IconShoppingCoupon',
          title: 'Tickets anteriores',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'custom-fields' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: 'form',
          title: 'Campos customizados',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'files' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: 'folder-open',
          title: 'Archivos',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'products' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          component: 'IconShoppingBag',
          title: 'Productos',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'comments' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: 'message',
          title: 'Comentarios',
        },
      ]
    },
    /**
     * Vista actual del breakpoint
     * @returns {Object} view
     * @returns {String} view.type_menu
     * @returns {String} view.filters
     * @returns {Object} view.styles
     */
    viewBreakpoint() {
      const name = this.$route.name
      const view = this.active_breakpoint.views[name]
      if (!view) return {}
      else return view
    },
    /**
     * El estao del perfil
     * @returns {Number} profileStatus.border
     * @returns {Number} profileStatus.padding
     * @returns {String} profileStatus.state
     */
    profileStatus() {
      if (!this.profile) return {}
      const status = this.profile_states[this.profile.status]

      if (status && status.action_when_select.includes('show-status-in-avatar'))
        return { border: 4, ...status, borderMin: 2, paddingMin: 2 }
      else return { border: 0, ...status, borderMin: 0, paddingMin: 0 }
    },
  },
  methods: {
    ...mapActions(['getLine']),

    /**
     * @param {Object} ticket
     * @param {Object}  ticket.channel
     * @param {String} ticket.settings.type
     * @param {Object} ticket.settings
     * @param {Boolean} ticket.settings.sandbox
     * @returns {Object} el canal de la empresa que le pertenece al ticket
     */
    channelCompanyOfTicket(ticket) {
      if (!this.channels || !ticket) return null

      // encontrar el canal en la data de los canales de la empresa
      const channelCompany = this.simple_company_channels.find(
        (simple_channel) =>
          simple_channel._id === ticket.channelCompanyId &&
          !!simple_channel.isSandbox === !!ticket.settings.sandbox
      )
      if (!channelCompany) return
      // encontrar el canal en la data de los canales generales
      const generalChannel = this.channels.find(
        (chc) => chc._id === channelCompany.channelId
      )
      if (!generalChannel) return

      generalChannel.api = generalChannel.apis[channelCompany.api_version]

      return generalChannel
    },
    /**
     * Filtra los canales simples de WhatsApp por el tipo
     * @param {String} type - gupshup, twilio
     * @param {Object} defaultChannel
     * @param {String} defaultChannel._id
     * @param {String} defaultChannel.title
     * @param {Object[]} dataSource
     */
    filterSimpleChannelWhatsAppByType({
      type,
      dataSource = null,
      defaultChannel = null,
    }) {
      const arrChannels = dataSource || this.simple_company_channels
      let channelsFiltered = [...arrChannels].filter(
        (simpleChannel) =>
          simpleChannel.channel === 'whatsapp' &&
          simpleChannel.api_version.includes(type)
      )
      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Filtra los canales simples de la empresa por el canal
     * @param {Object} args
     * @param {String}  args.channel - all, whatsapp, messenger, instagram, webchat
     * @param {Object} args.dataSource
     * @param {Object}  args.defaultChannel
     * @param {String}  args.[defaultChannel._id]
     * @param {String}  args.[defaultChannel.title]
     * @param {Boolean} args.request - si el canal proviene de gusphup
     */
    filterSimpleByChannel({
      channel,
      dataSource = null,
      defaultChannel = null,
      request = null,
    }) {
      const arrChannels = dataSource || this.simple_company_channels
      let channelsFiltered =
        channel === 'all'
          ? [...arrChannels]
          : [...arrChannels].filter(
              (simpleChannel) =>
                simpleChannel.channel === channel &&
                (request === null ? true : simpleChannel.request === request)
            )
      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Filtra los canales de un cliente
     * @param {} client
     * @returns {Object[]}
     */
    formatClienthannels(client) {
      if (!client || !client.channels_companies) return []
      return [...client.channels_companies].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        if (!isIncludeInAcc)
          acc[current.channel] = {
            channel: current.channel,
            channelTitles: [current.title], // ids de los canales conectados que tienen este canal
          }
        else acc[current.channel].channelTitles.push(current.title)
        return acc
      }, {})
    },
    /**
     * Retorna el alias de un canal de la empresa
     * @param {String} channelCompanyId
     */
    channelCompanyAlias(channelCompanyId) {
      if (!this.simple_company_channels) return null
      const channelFound = this.simple_company_channels.find(
        (simpleChannel) => simpleChannel._id === channelCompanyId
      )
      return channelFound && channelFound.alias ? channelFound.alias : null
    },
    /**
     * Retorna el número de un canal de la empresa
     * @param {String} channelCompanyId
     */
    channelCompanyNumber(channelCompanyId) {
      if (!this.simple_company_channels) return null
      const channelFound = this.simple_company_channels.find(
        (simpleChannel) => simpleChannel._id === channelCompanyId
      )
      return channelFound && channelFound.title ? channelFound.title : null
    },
    /**
     * Carga la lista de colas a la cual el usuario esta asignado
     * @return {Object[]} completeAssignedLines[]
     * @return {String} completeAssignedLines[]._id
     * @return {String} completeAssignedLines[].name
     */
    async completeAssignedLines() {
      const arrLines = []
      const idAssignedLines = this.profile.company.linesId

      idAssignedLines.forEach(async (idAssignedLine) => {
        let lineFound = this.lines.find((line) => line._id === idAssignedLine)
        if (!lineFound) {
          lineFound = await this.getLine(idAssignedLine)
        }
        arrLines.push(lineFound)
      })

      return arrLines
    },
    /**
     * Obtiene el origen del ticket
     * @param {Object} ticket
     * @param {String} ticket.channel
     * @param {String} ticket.channel.type
     * @param {String} ticket.channel.title
     * @param {String} ticket.channel.phoneTo
     * @param {String} ticket.channel.apiBusinessId
     * @param {String} ticket.channelCompanyId
     * @param {Object} ticket.settings
     * @param {Boolean} ticket.settings.sandbox
     * @param {Boolean} showLabel - mostrar o no label "ID de canal"
     * @param {Boolean} ticketOrigin.showLabelTitle - mostrar o no label "Identificador de canal"
     * @returns {Object} ticketOrigin
     * @returns {String} ticketOrigin.origin
     * @returns {String} ticketOrigin.title
     * @returns {String} ticketOrigin.channel
     */
    ticketOrigin(ticket, showLabel = true, showLabelTitle = false) {
      if (!ticket || !ticket.channel) return null
      else if (ticket.settings && ticket.settings.sandbox) {
        return {
          origin: 'Ticket de Sandbox',
        }
      } else {
        const channelOrigin = { channel: ticket.channel.type }
        const alias = this.channelCompanyAlias(ticket.channelCompanyId)
        const channelCompany =
          ticket.channel.title ||
          ticket.channel.phoneTo ||
          ticket.channel.apiBusinessId

        // Si el canal no tiene alias
        if (!alias) {
          channelOrigin.origin =
            !this.active_breakpoint.is_mobile && showLabel
              ? `ID de canal: ${channelCompany}`
              : `${channelCompany}`
        } else {
          channelOrigin.origin =
            !this.active_breakpoint.is_mobile && showLabel
              ? `ID de canal: ${alias}`
              : `${alias}`
        }

        const title = alias ? ` ${channelCompany} ( ${alias} )` : channelCompany
        channelOrigin.title = showLabelTitle
          ? `Identificador de canal: ${title}`
          : title

        return channelOrigin
      }
    },
    /**
     * Filtra los canales simples por el canal y proveedor
     * @param {String} provider - gupshup, twilio, sms
     * @param {String} channel - whatsapp, messenger, sms, instagram
     * @param {Object} defaultChannel
     * @param {String} defaultChannel._id
     * @param {String} defaultChannel.title
     * @param {Object[]} dataSource
     */
    filterSimpleChannels({
      provider,
      channel,
      dataSource = null,
      defaultChannel = null,
    }) {
      const arrChannels = dataSource || this.simple_company_channels
      let channelsFiltered = [...arrChannels].filter(
        (simpleChannel) =>
          simpleChannel.channel === channel &&
          simpleChannel.api_version.includes(provider)
      )
      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Proveedor de canal
     * @param {Object} args
     * @param {String} args.provider
     * @param {String} args.channel
     * @returns {Object|null} providerChannel
     */
    providerChannel({ provider, channel }) {
      const channelFound = this.channels.find(
        (item) => item.channel === channel
      )
      if (!channelFound) return
      const providers = channelFound.apis
      return providers[provider] && providers[provider].integrable
        ? providers[provider]
        : null
    },
    /**
     * Filtra los canales simples por el canal y proveedor
     * @param {String} provider - gupshup, twilio, sms
     * @param {String} channel - whatsapp, messenger, sms, instagram
     * @param {Object} defaultChannel
     * @param {String} defaultChannel._id
     * @param {String} defaultChannel.title
     * @param {Object[]} dataSource
     */
    filterOriginSimpleChannels({
      provider,
      channel,
      dataSource = null,
      defaultChannel = null,
    }) {
      const arrChannels = dataSource || this.origin_simple_company_channels
      let channelsFiltered = [...arrChannels].filter(
        (simpleChannel) =>
          simpleChannel.channel === channel &&
          simpleChannel.api_version.includes(provider)
      )
      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Retorna todos los canales habilitados para los filtros
     * @param {String[]} verifyChannels - verifica los canales en la empresa
     * @returns {Object[]}
     */
    filterGeneralChannels(verifyChannels = []) {
      let channelsToSelect = []
      // Filtra todos los canales activos
      if (this.channels && !!this.channels.length)
        channelsToSelect = this.channels.filter((channel) => channel.active)
      // Si tiene canales para verificar que existan en la empresa
      if (verifyChannels.length > 0) {
        // recorre cada canal que se necesita verificar
        verifyChannels.forEach((channel) => {
          const thereInCompany = this.company && this.company.channels[channel]
          // si el canal no existe en la empresa, entonces no lo devolverá
          if (!thereInCompany)
            channelsToSelect = [...channelsToSelect].filter(
              (item) => item.channel !== channel
            )
        })
      }

      channelsToSelect.unshift({
        _id: 'all',
        channel: 'all',
        name: 'Todos los canales',
        default: true,
      })
      return channelsToSelect
    },
  },
}
