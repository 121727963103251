<template>
  <footer :class="`footer display-flex align--center ${justify}`">
    <div>
      <slot name="extra">
        <a-button
          v-if="showDanger"
          type="danger"
          :style="{ marginRight: '8px' }"
          @click="$emit('onDelete')"
          :disabled="dangerDisabled"
          ghost
        >
          {{ dangerText }}
        </a-button>
      </slot>
    </div>
    <div class="footer__main-actions">
      <a-button
        v-if="showCancel"
        class="footer__button-cancel"
        @click="$emit('onCancel')"
        :icon="cancelIcon"
        :disabled="cancelDisabled"
      >
        {{ cancelText }}
      </a-button>
      <a-button
        v-if="showOk"
        type="primary"
        @click="$emit('onOk')"
        :icon="okIcon"
        :loading="okLoading"
        :disabled="okDisabled"
      >
        {{ okText }}
        <slot name="ok-icon"></slot>
      </a-button>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BasicFooter',
  props: {
    cancelText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    okText: {
      type: String,
      required: false,
      default: 'Ok',
    },
    dangerText: {
      type: String,
      required: false,
      default: 'Delete',
    },
    okLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    showOk: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDanger: {
      type: Boolean,
      required: false,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dangerDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okIcon: {
      type: String,
      required: false,
      default: null,
    },
    cancelIcon: {
      type: String,
      required: false,
      default: null,
    },
    justify: {
      type: String,
      required: false,
      default: 'justify--between',
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.footer
  position: absolute
  right: 0
  bottom: 0
  width: 100%
  border-top: 1px solid $gray_5
  padding: 10px 16px
  background: $white_000
  text-align: right
  z-index: 1
  &__button-cancel
    margin-right: 8px

.ant-modal-footer .footer__button-cancel
  margin-right: 0px
</style>
