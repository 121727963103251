<template>
  <div>
    <h5 class="semibold-14">Calculador de caracteres SMS</h5>
    <a-list size="small" bordered :data-source="dataForList">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-row class="full-width" type="flex" justify="space-between">
          <span class="semibold-14"
            >{{ item.title }}
            <a-tooltip v-if="item.info" :title="item.info">
              <a-icon type="info-circle" />
            </a-tooltip>
            <a-popover v-if="item.popup" title="Codificación detectada">
              <template slot="content">
                <p>
                  <strong>GSM-7:</strong> Mensaje que contiene <br />caracteres
                  de texto.
                  <br />
                  <strong>UCS-2:</strong>
                  Mensaje que contiene <br />
                  Unicode (emojis o idiomas <br />
                  internacionales).
                </p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </span>
          <div class="list__value">{{ item.value }}</div>
        </a-row>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import SmsCounter from '@/app/shared/utils/SmsCounter'

export default {
  name: 'CalculatorSms',
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    SmsCounter,
  }),
  computed: {
    /**
     * @return {Object} settings
     * @return {Number} settings.encoding
     * @return {Number} settings.per_message
     * @return {Number} settings.remaining
     * @return {Number} settings.messages
     */
    smsSettings() {
      return this.SmsCounter.count(this.text)
    },
    /**
     * @return {Object[]} data
     * @return {String} data.title
     * @return {String} data.value
     * @return {String} data.info
     */
    dataForList() {
      return [
        {
          title: 'Codificación detectada',
          popup: true,
          value: this.smsSettings.encoding,
        },
        {
          title: 'N° de mensajes',
          value: this.smsSettings.messages || 0,
        },
        {
          title: 'Total de caracteres usados',
          value: this.smsSettings.length,
        },
        {
          title: 'Máx. Caracteres por mensaje',
          value: this.smsSettings.per_message,
        },
      ]
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.list
  &__value
    width: 100px
</style>
