<template>
  <a-drawer
    :title="drawer_filters.title"
    :width="drawer_filters.width"
    :visible="visible"
    class="drawer"
    :mask="false"
    @close="onClose"
  >
    <a-form :form="form" class="filters" layout="vertical" hide-required-mark>
      <!-- Canales -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Canales</label>
        </template>
        <a-select
          v-model="localFilters.channel"
          default-value="all"
          style="margin-right: 12px"
          @change="handleChangeChannel"
        >
          <a-select-option
            v-for="channel in channelsForFilters"
            :key="channel.channel"
            :title="channel.default ? channel.name : `${channel.name}`"
          >
            {{ channel.default ? channel.name : `${channel.name}` }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Ids de canales -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">ID de canal</label>
        </template>
        <a-select v-model="localFilters.channelCompanyId">
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex" align="middle">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>{{
                simpleChannel.alias ? `(${simpleChannel.alias})` : ''
              }}</span>
            </a-row>
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Agente -->
      <a-form-item v-if="showFieldAgents" class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Agente</label>
        </template>
        <a-select
          class="filter-item__select"
          v-model="localFilters.agent"
          show-search
          option-filter-prop="children"
        >
          <a-select-option value="all"> Todos los agentes </a-select-option>
          <a-select-option
            v-for="agent in simpleAgents"
            :key="agent.userId"
            :title="agent.names"
          >
            {{ agent.names }} {{ agent.surnames }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- ¨Productos -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Productos</label>
        </template>
        <a-select
          class="filter-item__select"
          mode="multiple"
          v-model="localFilters.products"
          placeholder="Selecciona"
        >
          <a-select-option
            v-for="product in company_products"
            :key="product._id"
            :title="product.name"
          >
            {{ product.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <basic-footer
      :okText="okText"
      cancelText="Cancelar"
      @onCancel="handleCancel"
      @onOk="handleSubmit"
      :okLoading="loadingOk"
      :okDisabled="disabledOk"
      :cancelDisabled="disabledCancel"
    />
  </a-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'DrawerFiltersContact',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  components: {
    BasicFooter,
    SocialIcon,
  },
  data: () => ({
    localFilters: {},
    loadingOk: false,
    disabledCancel: false,
    disabledOk: false,
    simpleChannelsFiltered: [],
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'FormFiltersContact' })
  },
  created() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'no profile'
      if (this.isAllowedFor(this.profile.type, ['admin', 'master']))
        this.getSimpleAgents()
    })
  },
  mixins: [supportMixin, compareMixin, attemptMixin],
  watch: {
    /**
     * Observa si el valor de visible cambia, si es verdadero seteara los valores de los filtros a los locales
     */
    visible(val) {
      if (val) {
        this.localFilters = this.filters
        this.handleChangeChannel(this.localFilters.channel, false)
      }
    },
  },
  computed: {
    ...mapGetters([
      'company_products',
      'profile',
      'simpleAgents',
      'drawer_filters',
      'company',
    ]),
    /**
     * @returns {String} - Aplicando o Aplicar
     */
    okText() {
      return this.loadingOk ? 'Aplicando' : 'Aplicar'
    },
    /**
     * Mostrar o no el campo de agentes
     * @returns {Boolean}
     */
    showFieldAgents() {
      if (
        this.profile &&
        this.isAllowedFor(this.profile.type, ['admin', 'master'])
      )
        return true
      else return false
    },
  },
  methods: {
    ...mapActions(['fetchContacs', 'getSimpleAgents']),
    /**
     * Cerrar y resetear
     */
    onClose() {
      this.$emit('onClose')
      this.disabledCancel = false
      this.disabledOk = false
      this.loadingOk = false
    },
    /**
     * Submit del formulario
     */
    async handleSubmit() {
      this.form.validateFields(async (err) => {
        if (!err) {
          this.loadingOk = true
          this.disabledCancel = true
          this.onSetFilters()
          this.$emit('onLoading', true)
          await this.fetchContacs(this.localFilters)
          this.$emit('onLoading', false)
          this.loadingOk = false
          this.onClose()
        }
      })
    },
    /**
     * Emitir el seteo de filtros
     */
    onSetFilters() {
      this.$emit('onSetFilters', this.localFilters)
    },
    /**
     * Cancela los filtros
     */
    handleCancel() {
      this.onClose()
    },
    /**
     * Escucha el cambio de canales
     * @param {String} channel
     */
    handleChangeChannel(channel, setDefault = true) {
      const simpleChannels = this.filterSimpleByChannel({
        channel,
        defaultChannel: {
          _id: 'all',
          title: 'Todos los IDs',
        },
      })
      if (setDefault) this.localFilters.channelCompanyId = simpleChannels[0]._id
      this.simpleChannelsFiltered = simpleChannels
    },
  },
}
</script>

<style scoped></style>
