var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-avatar"},[_c('div',{staticClass:"status bg-color-inactive",class:{
      'bg-color-active':
        _vm.status === 'Tickets' ||
        _vm.status === 'Agenda' ||
        _vm.status === 'connected' ||
        _vm.status === 'conectado',
      'bg-color-busy': _vm.status === 'ocupado' || _vm.status === 'bussy',
      'bg-color-services': _vm.status === 'servicios' || _vm.status === 'services',
      'bg-color-training': _vm.status === 'capacitación' || _vm.status === 'training',
      'bg-color-paused': _vm.status === 'paused',
      'bg-color-snacking': _vm.status === 'snacking',
      'status-list': _vm.component === 'List',
      'status-drawer': _vm.component === 'Drawer',
    }}),(_vm.info.subType === 'chatbot')?_c('a-avatar',{staticClass:"avatar-chat",staticStyle:{"margin-left":"4px"},attrs:{"src":require('@/app/tickets/assets/icons/bot.png'),"size":_vm.size}}):(_vm.info.avatar)?_c('a-avatar',{staticClass:"avatar-chat",staticStyle:{"margin-left":"4px"},attrs:{"src":_vm.info.avatar,"size":_vm.size}}):_c('DefaultIconLetter',{attrs:{"size":_vm.size,"names":_vm.info.names}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }