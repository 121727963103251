<template>
  <a-drawer
    :title="title"
    :placement="placement"
    :visible="visible"
    @close="onClose"
    width="410px"
    :bodyStyle="bodyStyle"
  >
    <a-form :form="form" layout="vertical" @submit.prevent="handleSubmit">
      <custom-section-scroll
        :master-styles="{
          height: `calc(100vh - (${heightHeader} + ${heightFooter}))`,
        }"
        :content-styles="{ padding: '24px' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Imagen de producto">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="drawer__uploader drawer-product__uploader"
                :show-upload-list="false"
                v-decorator="['picture']"
                :before-upload="beforeUpload"
                :customRequest="selfUpload"
              >
                <div v-if="imageUrl" class="drawer__content-picture">
                  <div
                    class="
                      drawer__overlay
                      align--center
                      justify-content--center
                      direction-column
                    "
                  >
                    <a-icon type="edit" />
                    <p class="regular-14">Cambiar imagen</p>
                  </div>
                  <img
                    :src="imageUrl"
                    alt="imagen del producto"
                    class="drawer__picture"
                  />
                </div>
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">Subir imagen</div>
                </div>
              </a-upload>
            </a-form-item>
            <a-form-item label="Nombre">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellena este campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                placeholder="Casaca jeans, Sandalias negras..."
                :maxLength="30"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Descripción">
              <a-textarea
                v-decorator="[
                  'description',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Por favor rellena este campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                :maxLength="maxDescription"
                style="width: 100%"
                placeholder="Disponible en tienda física y tienda online..."
                @input="handleInputDescription"
              />
              <span class="counter"
                >{{ currentDescription ? currentDescription.length : 0 }}/{{
                  maxDescription
                }}</span
              >
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Marca">
              <a-input
                v-decorator="[
                  'brand',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                placeholder="Adidas..."
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Tipo de Moneda">
              <a-select
                v-decorator="[
                  'currency_code',
                  {
                    initialValue: currency.currency_code,
                    rules: [
                      { required: true, message: 'Please select an owner' },
                    ],
                  },
                ]"
                placeholder="Please a-s an owner"
                option-filter-prop="children"
                show-search
                @change="handleChangeCurrency"
              >
                <a-select-option
                  v-for="currency in currencies"
                  :key="currency.code"
                >
                  {{ currency.symbol }} {{ currency.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Precio">
              <a-input-number
                v-decorator="[
                  'price',
                  {
                    initialValue: 0,
                    rules: [
                      { required: true, message: 'Completa este campo' },
                      { validator: validateMaxDiscount },
                    ],
                  },
                ]"
                :formatter="(value) => `${currency.currency_symbol} ${value}`"
                :parser="handleParserNumber"
                placeholder="50"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <span slot="label">
                Descuento máximo&nbsp;
                <a-tooltip
                  title="El descuento no debe ser mayor al precio"
                  placement="topRight"
                >
                  <a-icon type="info-circle-o" />
                </a-tooltip>
              </span>
              <a-input-number
                v-decorator="[
                  'maximum_discount',
                  {
                    initialValue: 0,
                    rules: [
                      { required: true, message: 'Completa este campo' },
                      { validator: compareToPrice },
                    ],
                  },
                ]"
                :formatter="(value) => `${currency.currency_symbol} ${value}`"
                :parser="handleParserNumber"
                placeholder="50"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <h6 class="semibold-14">Campos personalizados</h6>
          <template v-if="fields_company_products.length === 0">
            <p class="empty">No hay campos personalizados</p>
          </template>
          <template v-else>
            <a-col
              v-for="(localField, index) in localCustomFields"
              :key="localField.customFieldId"
              :span="24"
            >
              <custom-field-option
                :field="localField"
                :hasRange="false"
                :dataSource="disabledFields(fields_company_products)"
                :isLast="localCustomFields.length - 1 === index"
                @onChangeField="updateCustomField"
                @onRemove="onRemoveField(localField.customFieldId)"
              />
            </a-col>
          </template>
          <template v-if="fields_company_products.length > 0">
            <a @click="addLocalField" class="add">
              <a-icon type="plus" /> Agregar campo customizado
            </a>
          </template>
        </a-row>
      </custom-section-scroll>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="onClose"
          :disabled="disabledCancel"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loadingOk"
          :disabled="disabledOk"
        >
          {{ okText }}
        </a-button>
      </div>
    </a-form>
  </a-drawer>
</template>

<script>
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import CustomFieldOption from '@/app/shared/components/molecules/CustomFieldOption'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductDrawer',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'right',
    },
    values: {
      type: Object,
      default: () => {},
    },
    title: { type: String, required: false, default: 'Drawer' },
    onSaveChanges: { type: Function, required: false },
    successMessage: {
      type: String,
      required: false,
      default: 'Mensaje exitoso',
    },
    okText: { type: String, required: false, default: 'ok' },
  },
  components: {
    CustomSectionScroll,
    CustomFieldOption,
  },
  data: () => ({
    loading: false,
    imageUrl: '',
    fileImage: '',
    bodyStyle: {
      padding: '0px',
    },
    heightHeader: '54.6px',
    heightFooter: '53px',
    disabledCancel: false,
    loadingOk: false,
    disabledOk: false,
    currency: {
      currency_symbol: '$',
      currency_code: '',
      currency_name: '',
    },
    currentDescription: '',
    maxDescription: 350,
    localCustomFields: [],
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  watch: {
    visible(val) {
      if (val) this.$nextTick(() => this.setValues())
    },
    default_currencies: {
      handler() {
        if (this.default_currencies && this.default_currencies[0])
          this.setSelectedCurrency(
            this.default_currencies[0].symbol,
            this.default_currencies[0].name,
            this.default_currencies[0].code
          )
      },
    },
  },
  computed: {
    ...mapGetters([
      'default_currencies',
      'countriesNotRepeatByName',
      'fields_company_products',
    ]),
    /**
     * Divisas de todos los paises
     * @return {Array}
     */
    currencies() {
      let arr = []
      if (!this.countriesNotRepeatByName.length) arr = []
      else {
        this.countriesNotRepeatByName.forEach((country) => {
          if (country.currencies)
            country.currencies.forEach((currency) => {
              const exist = arr.find((elem) => elem.code === currency.code)
              if (exist === undefined) arr.push(currency)
            })
        })
      }
      return arr
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose')
      this.form.resetFields()
      this.imageUrl = ''
      this.fileImage = ''
      this.disabledCancel = false
      this.disabledOk = false
      this.loadingOk = false
      this.localCustomFields = []
      this.setSelectedCurrency(
        this.default_currencies[0].symbol,
        this.default_currencies[0].name,
        this.default_currencies[0].code
      )
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.picture = this.fileImage
          values.currency_symbol = this.currency.currency_symbol
          values.currency_name = this.currency.currency_name
          if (this.values._id) values._id = this.values._id
          values = this.removeEmpty(values)
          values = this.formmatedDropdown(values, 'submit')
          values = this.identifyCustomFields(values)
          this.loadingOk = true
          this.disabledOk = true
          this.disabledCancel = true
          this.$emit('onLoading', true)
          const response = await this.onSaveChanges(values)
          if (response.success) {
            this.onClose()
            this.$message.success(this.successMessage)
          } else this.$message.error(response.details)
          this.$emit('onLoading', false)
          this.loadingOk = false
          this.disabledOk = false
          this.disabledCancel = false
        }
      })
    },
    /**
     * Checkea el peso de los archivos antes de subirse
     * @param {Object} file - archivo que se subirá
     * @param {Number} file.size
     * @param {String} file.type
     */
    beforeUpload(file) {
      const typeFiles = ['image/jpeg', 'image/png', 'image/jpg']
      const isJpgOrPng = typeFiles.includes(file.type)
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png, jpeg o jpg')
      }
      const isLt2M = file.size / 1024 / 1024 <= 2
      if (!isLt2M) {
        this.$message.error('El tamaño máximo permitido es de 2MB')
      }
      return isJpgOrPng && isLt2M
    },
    /**
     * Subida de archivo
     * @param {Object} file
     */
    selfUpload({ file }) {
      this.loading = true
      new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.imageUrl = fileReader.result
          resolve(fileReader.result)
          this.loading = false
        }
      })
    },
    /**
     * Setea valores al formulario
     */
    setValues() {
      this.form.setFieldsValue(this.values)
      if (this.values.description)
        this.currentDescription = this.values.description
      if (this.values.customFields) {
        this.localCustomFields = this.setCustomFields({
          ...this.values.customFields,
        })

        this.$nextTick(() =>
          this.form.setFieldsValue(
            this.formmatedDropdown({ ...this.values.customFields }, 'set')
          )
        )
      }
      if (this.values.pictures) {
        this.imageUrl = this.values.pictures[0] // mejorar para cuando se carguen varias imagenes
        this.fileImage = this.values.pictures[0]
      }
      if (this.values.currency)
        this.setSelectedCurrency(
          this.values.currency.symbol,
          this.values.currency.name,
          this.values.currency.code
        )
    },
    /**
     * Escucha cuando se cambia la divisa
     * @param {String} code - codigo de la divisa
     */
    handleChangeCurrency(code) {
      const currency = this.currencies.find(
        (currency) => currency.code === code
      )
      this.setSelectedCurrency(currency.symbol, currency.name, currency.code)
    },
    /**
     * Setea valores a la data local de currency
     * @param {String} symbol - simbolo de la divisa -> ex: $
     * @param {String} name - nombre de la divisa -> ex: DOLLAR
     * @param {String} code - codigo de la divisa -> ex: USD
     */
    setSelectedCurrency(symbol, name, code) {
      this.currency.currency_symbol = symbol
      this.currency.currency_name = name
      this.currency.currency_code = code // este dato ya existe dentro de los valores del form
    },
    /**
     * Escucha el evento input en la descripcion
     * @param {Object} e
     */
    handleInputDescription(e) {
      this.currentDescription = e.target.value
    },
    /**
     * Extrae el verdadero valor del valor formateado
     * @param {String} value - $400
     * @return {Number} - 400
     */
    handleParserNumber(value) {
      const str = value.replaceAll(this.currency.currency_symbol, '')
      return str.replaceAll(/[^\d.]/g, '')
      // \d+(,\d+)?
    },
    /**
     * Formatea el campo customizado de dropdown
     * @param {Object} values- campos del producto
     * @param {String} action - submit, set
     */
    formmatedDropdown(values, action) {
      for (const property in values) {
        const fieldFound = [...this.fields_company_products].find(
          (field) => field._id === property
        )
        if (fieldFound !== undefined)
          if (fieldFound.type === 'dropdown') {
            let valueFormatted = ''
            if (action === 'submit')
              valueFormatted =
                typeof values[property] === 'string'
                  ? [values[property]]
                  : values[property]
            else
              valueFormatted = values[property].map((option) => option.idOption)
            values[property] = valueFormatted
          }
      }
      return values
    },
    /**
     * Formatea y crea un array nuevo
     * @param {Array} fields - campos personalizados
     * @param {String} field[].type - text, dropdown, date
     * @param {String} field[].name
     */
    setCustomFields(fields) {
      let newFields = []
      const formattedFields = this.formmatedDropdown(fields, 'set')

      for (let property in formattedFields) {
        newFields.push({
          customFieldId: property,
          value: formattedFields[property],
        })
      }
      return newFields
    },
    /**
     * Remueve las keys que tienen valor undefined
     * @param {Object} obj
     */
    removeEmpty(obj) {
      for (const property in obj) {
        if (obj[property] === undefined) delete obj[property]
        else if (
          typeof obj[property] === 'string' &&
          obj[property].trim().length === 0
        )
          delete obj[property]
      }
      return obj
    },
    /**
     * Identificar los campos personalizados
     * @param {Object} values -valores del formulario
     */
    identifyCustomFields(values) {
      values.customFields = {}
      for (const property in values) {
        const fieldFound = this.fields_company_products.find(
          (field) => field._id === property
        )
        if (fieldFound !== undefined) {
          values.customFields[property] = values[property]
          delete values[property]
        }
      }
      return values
    },
    addLocalField() {
      const idsLocalFields = this.localCustomFields.map(
        (localField) => localField.customFieldId
      )
      const defaultField = this.fields_company_products.filter(
        (field) => !idsLocalFields.includes(field._id)
      )[0]
      let value = this.setDefaultValueField(defaultField)
      this.localCustomFields.push({
        customFieldId: defaultField._id,
        value: value,
      })
    },
    onRemoveField(key) {
      this.localCustomFields = this.localCustomFields.filter(
        (customField) => customField.customFieldId !== key
      )
    },
    /**
     * Actualizar el campo customizado
     *  @param {String} key - id del campo que se cambiara
     *  @param {Object} newField - nuevo campo customizado
     */
    updateCustomField(oldKey, newField) {
      const index = this.localCustomFields.findIndex(
        (localField) => localField.customFieldId === oldKey
      )
      this.localCustomFields[index].value = this.setDefaultValueField(newField)
      this.localCustomFields[index].type = newField.type
      this.localCustomFields[index].customFieldId = newField._id
    },
    /**
     * Devuelve el valor por defecto de un campo
     * @param {Object} field
     * @param {String} field.type - text, dropdown, date
     * @param {String} field.multiple - solo si el tipo es dropdown
     * @returns {String, Object} valor por defecto según el type
     */
    setDefaultValueField(field) {
      let value = ''
      switch (field.type) {
        case 'text':
          value = undefined
          break
        case 'dropdown':
          value = field.multiple ? [] : ''
          break
        case 'date':
          value = {}
      }
      return value
    },
    /**
     * Deshabilitar campos personalizados
     * @param {Array} fields - campos personalizados
     * @param {String} field[].type - text, dropdown, date
     * @param {String} field[].name
     * @param {String} field[]._id - id del campo
     */
    disabledFields(fields) {
      const arrFields = JSON.parse(JSON.stringify(fields))
      const idsLocalFields = this.localCustomFields.map(
        (localField) => localField.customFieldId
      )
      arrFields.forEach((field) => {
        if (idsLocalFields.includes(field._id)) field.disabled = true
        else field.disabled = false
      })
      return arrFields
    },
    /**
     * Valida el valor del input de Maximo descuento
     * @param {Object} rule - regla para el formulario
     * @param {Number, String} value - valor del campo
     * @param {Function} callback - función que ejecuta el llamado de errores
     */
    validateMaxDiscount(rule, value, callback) {
      const form = this.form
      if (value) form.validateFields(['maximum_discount'], { force: true })
      callback()
    },
    /**
     * Valida el valor del input de Maximo descuento
     * @param {Object} rule - regla para el formulario
     * @param {Number, String} value - valor del campo
     * @param {Function} callback - función que ejecuta el llamado de errores
     */
    compareToPrice(rule, value, callback) {
      const form = this.form
      const price = form.getFieldValue('price')

      if (value >= price) callback('Debe ser menor al precio')
      else if (value < 0) callback('Número no válido')
      else callback()
    },
  },
}
</script>

<style lang="sass" scoped>

.drawer__uploader
  width: 100%
  .ant-upload.ant-upload-select-picture-card
    width: 100%
.drawer__picture
  height: 104px
.drawer__content-picture
  height: 104px
  width: 100%
  position: relative
  &:hover
    .drawer__overlay
      display: flex
      opacity: 0.6
.drawer__overlay
  height: 104px
  width: 100%
  border-radius: 2px
  position: absolute
  display: none
  background-color: $gray_9
  opacity: 0.8
  color: $gray_1
  transition: opacity 1s
.counter
  position: absolute !important
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
</style>
<style lang="sass">
.drawer-product__uploader
  width: 100%
  height: 124px
  >.ant-upload.ant-upload-select-picture-card
    width: 100%
    height: 100%
</style>
