<template>
  <div class="reply-chat">
    <p class="reply-chat-content-title body-1 mrg-bottom-4">{{ title }}</p>
    <div class="reply-chat-content-media">
      <div class="reply-chat-bubble">
        <!-- Documentos -->
        <div v-if="isFile" class="icon-type">
          <a-icon type="file-text" />
        </div>
        <!-- Ubicación -->
        <div v-else-if="contextMessage.location" class="icon-type">
          <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.462 24.34L16 25.617l-1.462-1.277C9.318 19.782 6.666 15.748 6.666 12c0-5.305 4.139-9.334 9.334-9.334 5.195 0 9.333 4.03 9.333 9.334 0 3.748-2.651 7.782-7.871 12.34zM7.58 19.738c.547.75 1.157 1.512 1.829 2.287-2.483.56-4.077 1.353-4.077 1.975 0 1.076 4.772 2.666 10.667 2.666 5.894 0 10.666-1.59 10.666-2.666 0-.622-1.593-1.416-4.076-1.975a35.296 35.296 0 001.83-2.287c2.997.91 4.913 2.369 4.913 4.262 0 3.342-5.973 5.333-13.333 5.333S2.666 27.343 2.666 24c0-1.893 1.916-3.352 4.915-4.262zM16 5.333c3.738 0 6.666 2.851 6.666 6.667 0 2.73-2.17 6.088-6.666 10.075C11.503 18.087 9.333 14.729 9.333 12c0-3.816 2.928-6.667 6.667-6.667zM16 8a4 4 0 110 8 4 4 0 010-8zm-1.334 4a1.333 1.333 0 112.667 0 1.333 1.333 0 01-2.666 0z" fill="#8C8C8C"/></svg>
        </div>
        <!-- Imágenes -->
        <div v-else-if="contextMessage.imageUrl" class="icon-type">
          <img :src="contextMessage.imageUrl" />
        </div>
        <!--Stickers-->
        <div v-else-if="contextMessage.stickerUrl" class="icon-type">
          <img :src="contextMessage.stickerUrl" />
        </div>
        <!-- Video -->
        <!-- <div v-else-if="contextMessage.videoUrl" class="icon-type">
          <video
            :poster="contextMessage.videoUrl"
            style="margin-bottom: 0px"
            class="border-radius-8 message__video"
          >
            <source
              :src="contextMessage.videoUrl"
              :type="contextMessage.videoType"
            />
          </video>
        </div> -->
        <div v-else-if="contextMessage.videoUrl" class="icon-type">
          <a-icon type="play-circle" />
        </div>
        <!-- Contactos -->
        <div v-else-if="contextMessage.contacts" class="icon-type">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm7.4-5.875a9 9 0 10-14.799 0C5.726 15.638 8.37 15 12 15c3.63 0 6.274.638 7.4 2.125zm-1.435 1.615C17.672 17.687 15.569 17 12 17c-3.57 0-5.672.687-5.965 1.74A8.966 8.966 0 0012 21a8.966 8.966 0 005.965-2.26zM12 15c-2.24 0-4-1.573-4-5 0-2.244 1.58-4 4-4 2.414 0 4 1.922 4 4.2 0 3.28-1.782 4.8-4 4.8zm-2-5c0 2.27.818 3 2 3 1.178 0 2-.702 2-2.8 0-1.25-.784-2.2-2-2.2-1.266 0-2 .816-2 2z" fill="#8C8C8C"/></svg>
        </div>
        <!-- Audio -->
        <div v-else-if="contextMessage.audioUrl" class="icon-type">
          <a-icon type="play-circle" />
        </div>
        <!-- Contenido -->
        <div>
          <!--Contenido para anuncios-->
          <div v-if="publication">
            <p class="regular-14 mrg-bottom-0">
              <!--Titulo del anuncio-->
              <strong
                v-if="publication.headline && !!publication.headline.length"
                >{{ publication.headline }}</strong
              >
              <!--Espacio si existe titulo y existe body-->
              <br
                v-if="
                  publication.headline &&
                  !!publication.headline.length &&
                  publication.body &&
                  !!publication.body.length
                "
              />
              <!--Descripcion de anuncio-->
              <span
                v-if="publication.body && !!publication.body.length"
                class="ellipsis-2"
              >
                {{ publication.body }}
              </span>
              <!--Espacio si existe descripcion y existe url-->
              <br
                v-if="
                  publication.source_url &&
                  !!publication.source_url.length &&
                  publication.body &&
                  !!publication.body.length
                "
              />
              <!--Url de anuncio-->
              <span
                v-if="publication.source_url && !!publication.source_url.length"
              >
                <anchor
                  :href="publication.source_url"
                  :title="publication.source_url"
                  class="ellipsis"
                  :force-show="true"
                />
              </span>
            </p>
            <img
              v-if="mediaPublication"
              :src="mediaPublication"
              alt="Este contenido ya no está disponible"
              class="reply-chat__publication-picture"
            />
          </div>
          <p
            v-else
            v-html="
              !formats.active
                ? textFormatter(caption, [], ['link'])
                : textFormatter(caption, nameFormats)
            "
            class="regular-12 mrg-bottom-0"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textFormatter from '@/app/shared/mixins/format'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'ReplyChat',
  props: {
    method: { type: String, required: true, enum: ['sent', 'received'] },
    contextMessage: { type: Object, required: true },
    formats: {
      type: Object,
      required: false,
      default: () => ({
        active: false,
      }),
    },
  },
  components: {
    Anchor,
  },
  mixins: [textFormatter],
  computed: {
    /**
     * @return {String}
     */
    title() {
      switch (this.contextMessage.origin_owner.method) {
        case 'received':
          return 'Respondió su mensaje'
        case 'sent': {
          // si es publicacion
          if (this.contextMessage.publication) return 'Respondió a tu anuncio'
          else return 'Respondió a tu mensaje'
        }
        default:
          return 'Respondió'
      }
    },
    /**
     * @return {Boolean}
     */
    isFile() {
      return (
        this.contextMessage.pdfUrl ||
        this.contextMessage.wordUrl ||
        this.contextMessage.excelUrl ||
        this.contextMessage.pptUrl
      )
    },
    /**
     * @return {String}
     */
    caption() {
      if (this.contextMessage.location) {
        return this.contextMessage.text || 'Ubicación'
      } else if (this.contextMessage.imageUrl) {
        return this.contextMessage.text || 'Foto'
      } else if (this.contextMessage.contacts) {
        return (
          'Contacto: ' +
          this.contextMessage.contacts
            .map((c) => c.name + ' ' + c.lastname)
            .join(', ')
        )
      } else if (this.contextMessage.audioUrl) {
        return 'Mensaje de voz'
      } else if (this.contextMessage.videoUrl) {
        return 'Video'
      } else {
        return this.contextMessage.text
      }
    },
    /**
     * Nombre de todos los formatos
     * @return {String[]}
     */
    nameFormats() {
      if (!this.formats.active) return false
      const nameFormats = this.formats.formats.map((item) => item.format)
      return nameFormats
    },
    /**
     * @return {Object} publicacion
     */
    publication() {
      return this.contextMessage.publication
    },
    /**
     * Multimedia del anuncio
     * @return {String| null}
     */
    mediaPublication() {
      if (!this.contextMessage.publication) return null
      else {
        const publication = this.contextMessage.publication
        if (!publication.ads_context_data) return null
        else {
          const contextData = publication.ads_context_data
          const photoUrl =
            contextData.photo_url && !!contextData.photo_url.length
              ? contextData.photo_url
              : null
          const videoUrl =
            contextData.video_url && !!contextData.video_url.length
              ? contextData.video_url
              : null

          return photoUrl || videoUrl || null
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.reply-chat
  margin-bottom: 12px
  text-align: initial
  color: $gray_8
  &-content-media
    border-left: 1px solid #c4c4c4
    padding-left: 10px
    cursor: default
  &-content-title
  &-content-message
    margin-bottom: 15px
  &-bubble
    background-color: $gray_3
    padding: 10px
    border-radius: 8px
    max-width: 360px
    width: fit-content
    display: flex
    align-items: center
    &>*>*
      margin: 0
      color: $gray_7
  &__publication-picture
    width: 100%
    max-height: min-content
    margin-top: 12px
    border-radius: 4px
.reply-chat
  &-bubble
    .icon-type
      margin: 0 8px 0 0
      svg,
        width: 24px
        height: 24px
        font-size: 24px
      img,
      video
        font-size: 1px
        height: 24px
        object-fit: contain
        width: min-content
</style>
