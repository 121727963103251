/**
 * Agrega un listener por cada breakpoint pasado
 * @param {String} breakpoint
 * @param {String} media_query
 * @param {FUnction} callback
 */
const addListenerBreakpoint = (breakpoint, media_query, callback) => {
  const mql = window.matchMedia(media_query)
  mql.addEventListener('change', () => {
    if (mql.matches) callback(breakpoint)
  })
}

/**
 * Verifica la breakpoint en la que se encuentra la app
 * @param {Object} breakpoints
 * @param {String} breakpoints.restrictions
 * @returns {Object}
 */
const verifyBreakpoint = (breakpoints) => {
  for (let breakpoint in breakpoints) {
    const mql = window.matchMedia(breakpoints[breakpoint].restrictions)
    if (mql.matches) return { breakpoint, aditional: breakpoints[breakpoint] }
  }
}

export { addListenerBreakpoint, verifyBreakpoint }
