<template>
  <svg
    class="icon-headphone"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.5C5.85742 2.5 2.5 5.85742 2.5 10V17.0312C2.5 17.291 2.70898 17.5 2.96875 17.5H6.5625C7.25195 17.5 7.8125 16.9395 7.8125 16.25V12.1875C7.8125 11.498 7.25195 10.9375 6.5625 10.9375H3.90625V10C3.90625 6.63477 6.63477 3.90625 10 3.90625C13.3652 3.90625 16.0938 6.63477 16.0938 10V10.9375H13.4375C12.748 10.9375 12.1875 11.498 12.1875 12.1875V16.25C12.1875 16.9395 12.748 17.5 13.4375 17.5H17.0312C17.291 17.5 17.5 17.291 17.5 17.0312V10C17.5 5.85742 14.1426 2.5 10 2.5ZM6.40625 12.3438V16.0938H3.90625V12.3438H6.40625ZM16.0938 16.0938H13.5938V12.3438H16.0938V16.0938Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChatbot',
  props: {
    fill: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
}
</script>
