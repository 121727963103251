<template>
  <div class="card" :style="{ boxShadow }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardBase',
  props: {
    boxShadow: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  border: 1px solid $gray_5
  padding: 20px
  border-radius: 8px
</style>
