import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_CHAT_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },
  /**
   * Setea las plantillas asignadas al usuario
   * @param {*} state
   * @param {Object[]} templates
   * @param {String} templates[].name
   * @param {String} templates[].friendlyName
   * @param {String} templates[].body
   */
  SET_ASSIGNED_TEMPLATES(state, templates) {
    state.assigned_templates = templates
  },
  /**
   * Limpia los valores escritos en los parametros de un plantilla
   * @param {*} state
   * @param {String} idTemplate Id del template
   */
  CLEAN_PARAMETERS_TEMPLATE(state, idTemplate) {
    const template = state.assigned_templates.find(
      (template) => template._id === idTemplate
    )
    template.parameters = template.parameters.map((template) => {
      template.value = null
      return template
    })
  },
  /**
   * El usuario tiene plantillas asignadas
   * @param {*} state
   * @param {Boolean} haveTemplates
   */
  SET_HAVE_ASSIGNED_TEMPLATES(state, haveTemplates) {
    state.have_assigned_templates = haveTemplates
  },
}

export default mutations
