<template>
  <a-table
    :data-source="dataWithOrderAndAlias"
    :pagination="pagination"
    :loading="loading"
    :locale="localeTable"
    :columns="columns"
    @change="handleChangeTable"
    size="middle"
    :scroll="{ x: 'max-content', y: 'calc(100vh - 200px)' }"
    class="table table-scroll"
  >
    <a-row
      type="flex"
      align="middle"
      slot="createdBy"
      slot-scope="createdBy"
      class="flex-no-wrap"
    >
      <a-avatar v-if="createdBy.avatar" :src="createdBy.avatar" :size="24" />
      <a-avatar v-else :size="24" class="person__image" icon="user" />
      <p class="mrg-left-8 ellipsis mrg-bottom-0 table__names">
        {{ createdBy.names }} {{ createdBy.surnames }}
      </p>
    </a-row>
    <span slot="channel" slot-scope="channel">
      <social-icon :type="channel" />
    </span>
    <div
      slot="stats_import"
      slot-scope="stats_import, record"
      class="table__progress"
    >
      <a-row type="flex" justify="space-between" class="gray_9">
        <span class="regular-12"
          >Cant. enviado: {{ record.stats_send.total_sent }}</span
        >
        <span class="regular-12">{{
          statuses_strategies[record.status].value
        }}</span>
      </a-row>
      <a-tooltip
        :title="`${record.stats_send.total_sent} / ${stats_import.successes}`"
      >
        <a-progress
          :percent="
            toPercentage(stats_import.successes, record.stats_send.total_sent)
          "
          :strokeColor="statuses_strategies[record.status].progress_color"
          :show-info="record.status !== 'uploading'"
        />
      </a-tooltip>
    </div>
    <span slot="total_failed" slot-scope="total_failed, record">
      <a
        :disabled="!total_failed"
        @click="exportInvalidStrategyRecords(record._id)"
        >{{ total_failed }} inválidos</a
      >
    </span>
    <span slot="created_at" slot-scope="created_at">{{
      moment(created_at).format('LLL')
    }}</span>
    <span slot="channelTitle" slot-scope="channelTitle, record">{{
      channelTitle + record.channel_company.alias
    }}</span>
    <span slot="typeAttachment" slot-scope="typeAttachment">{{
      transformTypeAttachment(typeAttachment)
    }}</span>
    <span
      slot="firstPlayAt"
      slot-scope="firstPlayAt, record"
      :class="{
        'info_i2 table__valid-until':
          record.firstPlayAtFormatted.isInTimeBeforeUntil,
      }"
    >
      {{ record.firstPlayAtFormatted.date }}
      <a-tooltip
        v-if="record.firstPlayAtFormatted.tooltip"
        :title="record.firstPlayAtFormatted.tooltip"
      >
        <a-icon type="info-circle" class="table__icon-info" />
      </a-tooltip>
    </span>
    <template slot="action" slot-scope="text, record">
      <a-row type="flex" align="middle" justify="space-around">
        <a-tooltip
          v-if="actionsAllowedPerStatus[record.status].includes('pause')"
          title="Pausar"
        >
          <!--Pausa-->
          <a
            @click="
              handleChangeStatus({
                status: 'pause',
                strategyId: record._id,
              })
            "
          >
            <i class="anticon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'20'" v-bind:width="'20'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 2H7a2 2 0 00-2 2v16a2 2 0 002 2h2a2 2 0 002-2V4a2 2 0 00-2-2zm8 0h-2a2 2 0 00-2 2v16a2 2 0 002 2h2a2 2 0 002-2V4a2 2 0 00-2-2zM7 4v16h2V4H7zm8 16V4h2v16h-2z" fill="currentColor"/></svg>
            </i>
          </a>
        </a-tooltip>
        <!--Play-->
        <template v-else>
          <a-tooltip v-if="!record.stats_import.successes">
            <template v-if="!record.stats_import.successes" slot="title">
              No existen registros válidos
            </template>
            <template
              v-if="
                !record.firstPlayAtFormatted.isInTimeBeforeUntil &&
                (!record.typeAttachment || record.typeAttachment !== 'TEXT')
              "
              slot="title"
            >
              La campaña sobrepaso el tiempo de vencimiento
            </template>
            <a class="cursor-default gray_6">
              <i class="anticon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'20'" v-bind:width="'20'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 3v18a1 1 0 001.54.841l14-9a1 1 0 000-1.682l-14-9A1 1 0 005 3zm13.15 9L7 19.168V4.832L18.15 12z" fill="currentColor"/></svg>
              </i>
            </a>
          </a-tooltip>
          <a-tooltip v-else title="Ejecutar">
            <a
              :disabled="
                !actionsAllowedPerStatus[record.status].includes('play') ||
                !record.stats_import.successes ||
                disabledPlayLocal
              "
              @click="handlePlay(record)"
            >
              <i class="anticon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'20'" v-bind:width="'20'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 3v18a1 1 0 001.54.841l14-9a1 1 0 000-1.682l-14-9A1 1 0 005 3zm13.15 9L7 19.168V4.832L18.15 12z" fill="currentColor"/></svg>
              </i>
            </a>
          </a-tooltip>
        </template>
        <!--Detener-->
        <a-tooltip title="Detener">
          <a
            :disabled="!actionsAllowedPerStatus[record.status].includes('stop')"
            @click="
              handleShowConfirmation({
                status: 'stop',
                strategyId: record._id,
                onOk: handleChangeStatus,
                strategy: record,
              })
            "
          >
            <i class="anticon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'20'" v-bind:width="'20'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12zm20 0a9 9 0 11-18 0 9 9 0 0118 0zm-6-5a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9a2 2 0 012-2h6zm-6 8V9h6v6H9z" fill="currentColor"/></svg>
            </i>
          </a>
        </a-tooltip>
        <!--Abre el dashboard-->
        <a-tooltip title="Abrir dashboard">
          <a
            :disabled="
              !actionsAllowedPerStatus[record.status].includes('openDashboard')
            "
          >
            <a-icon
              type="appstore"
              class="table__icon"
              @click="$emit('onShowDashboard', record)"
          /></a>
        </a-tooltip>
        <!--Previsualizacion de plantilla-->
        <a-tooltip title="Previsualiza">
          <a
            :disabled="
              !actionsAllowedPerStatus[record.status].includes('openPreview')
            "
          >
            <a-icon
              type="eye"
              class="table__icon"
              @click="$emit('onShowPreview', record)"
          /></a>
        </a-tooltip>
        <!--Elimina el dashboard-->
        <a-tooltip
          :title="
            !actionsAllowedPerStatus[record.status].includes('delete')
              ? actionsText.delete.disabled.text
              : actionsText.delete.enabled.text
          "
          :overlayStyle="tooltipStyles"
          :placement="
            !actionsAllowedPerStatus[record.status].includes('delete')
              ? actionsText.delete.disabled.placement
              : actionsText.delete.enabled.placement
          "
        >
          <div>
            <a
              :disabled="
                !actionsAllowedPerStatus[record.status].includes('delete')
              "
            >
              <a-icon
                type="delete"
                class="table__icon"
                @click="
                  handleShowConfirmation({
                    strategyId: record._id,
                    toDelete: true,
                    onOk: handleDeleteStrategy,
                    strategy: record,
                  })
                "
            /></a>
          </div>
        </a-tooltip>
      </a-row>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'
import { TYPE_TEMPLATES_FOR_LIST } from '@/app/channels/utils/componentsDataTemplates'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'TableStrategies',
  props: {
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  components: {
    SocialIcon,
  },
  data: () => ({
    moment,
    localeTable: {
      emptyText: 'No se han encontrado campañas',
    },
    actionsAllowedPerStatus: {
      uploading: [],
      uploaded: ['delete', 'play', 'openPreview'],
      play: ['openDashboard', 'pause', 'stop', 'openPreview'],
      pause: ['openDashboard', 'play', 'stop', 'openPreview'],
      stop: ['openDashboard', 'delete', 'openPreview'],
      finished: ['openDashboard', 'delete', 'openPreview'],
    },
    disabledPlayLocal: false, // deshabilitar el play localmente
    actionsText: {
      delete: {
        disabled: {
          text: 'Detén o espera a que finalice tu campaña',
          placement: 'right',
        },
        enabled: { text: 'Eliminar', placement: 'top' },
      },
    },
    tooltipStyles: {
      maxWidth: '180px',
    },
  }),
  mixins: [transformMixin],
  computed: {
    ...mapGetters([
      'statuses_strategies',
      'paginate_company_strategies',
      'simple_company_channels',
    ]),

    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.total || 0,
        current: this.page,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'campañas encontradas' : 'campaña encontrada'}
          </span>
        ),
      }
    },
    /**
     * @return {String}
     */
    textSure() {
      return this.profile && this.profile.gender
        ? this.transformWordGender('seguro', this.profile.gender)
        : 'segur@'
    },
    /**
     * @return {Object[]} array de estrategias con orden y alias
     */
    dataWithOrderAndAlias() {
      if (!this.dataSource) return []
      //Le agrega el alias al titulo del canal
      this.dataSource.forEach((data) => {
        const channel = this.simple_company_channels.find(
          (channel) => channel._id == data.channel_company._id
        )

        data.channel_company.alias = ''
        if (channel && channel.alias) {
          data.channel_company.alias = ' (' + channel.alias + ')'
        }

        // para verficar el tiempo de lanzamiento

        data.firstPlayAtFormatted = this.transformFirstPlayAt(
          data.firstPlayAt,
          data.validUntil || null,
          data.template?.typeAttachment || 'TEXT'
        )
      })

      //Ordena el array de estrategias
      let startOrder =
        1 +
        (this.paginate_company_strategies.page - 1) *
          this.pagination.defaultPageSize
      return this.dataSource.map((strategy) => {
        strategy.order = startOrder++
        return strategy
      })
    },
  },
  methods: {
    ...mapActions([
      'deleteCompanyStrategy',
      'updateStatusStrategy',
      'exportInvalidStrategyRecords',
    ]),
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    handleChangeTable(pagination) {
      this.$emit('onPaginate', pagination.current)
    },
    /**
     * Obtiene el pocentaje de acuerdo al total y la cantidad
     * @param {Number} total
     * @param {Number} quantity
     */
    toPercentage(total, quantity) {
      const percentage = (quantity / total) * 100
      return Math.round(percentage)
    },
    /**
     * Muestra el modal de confirmacion para eliminar o ejecutar el play
     * @param {Object} record
     * @param {String} record._id
     * @param {Boolean} toDelete
     */
    handleShowConfirmation({
      status,
      strategyId,
      strategy,
      failedInImport,
      toDelete = false,
      onOk,
    }) {
      let title = null
      let content = null
      let okType = 'primary'
      let okText = null
      if (toDelete) {
        title = `¿Estás ${this.textSure} de eliminar la campaña "${strategy.name}"?`
        content =
          'Recuerda que se removerá del listado de campañas y ya no podrás verlo.'
        okType = 'danger'
        okText = 'Eliminar'
      } else
        switch (status) {
          case 'stop':
            {
              title = `¿Estás ${this.textSure} de detener la campaña "${strategy.name}"?`
              content =
                'Recuerda que se detendrá el envio de plantillas y no podrás volverle a dar play'
              okText = 'Detener'
            }
            break
          case 'play': {
            title = `¿Estás ${this.textSure} de ejecutar la campaña "${strategy.name}"?`
            content = () => (
              <ul style="padding-left: 20px">
                <li>
                  Tipo de plantilla:{' '}
                  {this.transformTypeAttachment(
                    strategy.template.typeAttachment
                  )}
                </li>
                <li>Nombre de plantilla: {strategy.template.name}</li>
                <li>Registros válidos: {strategy.stats_import.successes}</li>
                {failedInImport ? (
                  <li>Registros inválidos: {failedInImport}</li>
                ) : (
                  ''
                )}
                <li>
                  Creador por: {strategy.createdBy.names}{' '}
                  {strategy.createdBy.surnames}
                </li>
              </ul>
            )
            okText = 'Ejecutar'
          }
        }
      const self = this
      const modal = this.$confirm({
        title,
        content,
        okText,
        okType,
        cancelText: 'Cancelar',
        centered: true,
        okButtonProps: {
          props: { disabled: false },
        },
        cancelButtonProps: {
          props: { disabled: false },
        },
        async onOk() {
          modal.update({
            okButtonProps: {
              props: { disabled: true },
            },
            cancelButtonProps: {
              props: { disabled: true },
            },
          })
          await onOk({ status, strategyId })
        },
        onCancel() {
          self.disabledPlayLocal = false
        },
      })
    },
    /**
     * Cambia el estado de una campaña
     * @param {Object} args
     * @param {String} args.status
     * @param {String} args.strategy
     */
    async handleChangeStatus({ status, strategyId }) {
      const response = await this.updateStatusStrategy({ strategyId, status })
      if (response.success)
        this.$message.success('Se cambió el estado con éxito')
      else this.$message.error(response.details)
      this.disabledPlayLocal = false
    },
    /**
     * Elimina una campaña
     * @param {Object} args
     * @param {String} args.strategy
     */
    async handleDeleteStrategy({ strategyId }) {
      const response = await this.deleteCompanyStrategy(strategyId)
      if (response && response.success)
        this.$message.success('Se eliminó exitosamente')
      else
        this.$message.error(
          !response.details ? 'Ocurrió un error' : response.details
        )
    },
    /**
     * Evento ejecuado cuando se le da play a una campaña
     * @param {Object} record
     * @param {String} record._id - id de la campaña
     * @param {Object} record.stats_import
     * @param {Number} record.stats_import.total_failed - total de plantillas fallidas en la importacion
     */
    handlePlay(record) {
      this.disabledPlayLocal = true
      this.handleShowConfirmation({
        status: 'play',
        strategyId: record._id,
        strategy: record,
        failedInImport: record.stats_import.total_failed,
        onOk: this.handleChangeStatus,
      })
    },
    /**
     * Transforma el tiempo de lanzamiento
     * @param {Date} firstPlayAt
     * @param {Date} validUntil
     * @param {String} typeAttachment
     */
    transformFirstPlayAt(firstPlayAt, validUntil, typeAttachment) {
      // si existe la fecha de lanzamiento, quiere decir que ya fue lanzada
      if (firstPlayAt)
        return {
          date: moment(firstPlayAt).format('LLL'),
          existFirstPlayAt: true,
        }
      if (!typeAttachment || typeAttachment === 'TEXT')
        return { isInTimeBeforeUntil: true }

      if (!validUntil) return { date: '', isInTimeBeforeUntil: false }
      const currentDate = moment(new Date())
      const validUntilDate = moment(validUntil)
      const diff = validUntilDate.diff(currentDate, 'days')
      const isInTime = diff > 0

      // si la fecha actual aun esta dentro del tiempo
      if (isInTime)
        return {
          date: `Puede ser lanzada hasta el ${moment(validUntil).format('LL')}`,
          tooltip:
            'Esta campaña solo podrá ser lanzada durante los próximos 7 días desde la fecha de su creación. Luego se detendrá forzosamente.',
          isInTimeBeforeUntil: true,
        }
      else {
        return { date: '', isInTimeBeforeUntil: false }
      }
    },
    /**
     * Transforma el valor de la propiedad "typeAttachment"
     * @param {String} typeAttachment
     * @return {String}
     */
    transformTypeAttachment(typeAttachment) {
      const typeTemplate = TYPE_TEMPLATES_FOR_LIST.find(
        (typeTemplateForList) =>
          typeTemplateForList.value === (typeAttachment || 'TEXT')
      )
      return typeTemplate.title
    },
  },
}
</script>

<style lang="sass" scoped>
.gray_9
  color: $gray_9
.gray_6
  color: $gray_6

.table
  margin-top: 12px
  &__icon
    font-size: 20px
  &__progress
    width: 90%
  &__names
    max-width: 140px
  &__valid-until
    color: $gray_6
  &__icon-info
    color: $gray_9
</style>
