import authModule from './authModule'
import loadDataModule from './loadDataModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Login */
  resetMoLogin(context) {
    context.commit('RESET_LOGIN_MODULE')
  },
}

const modules = {
  authModule,
  loadDataModule,
}

export default {
  actions,
  modules,
}
