<template>
  <div class="section-lines">
    <header-module
      title="COLAS"
      tipography="heading-h5"
      class="template__header"
      :link="`${vari.WEB_DOC}caracteristicas/colas/`"
      text-before-link="Crea colas y asigna usuarios a ellas. Podrás enrutar las
          conversaciones de tus clientes a colas específicas."
      text-after-link="si deseas conocer más."
      textLink="Click aquí"
      positionTooltip="bottomRight"
    />
    <!-- Filtros -->

    <div class="section__header">
      <div class="section__filters">
        <a-input-search
          placeholder="Buscar cola"
          style="width: 312px; margin-right: 12px"
          @input="onInput"
          @search="onSearch"
        />
        <a-select
          placeholder="Seleccciona"
          default-value="all"
          style="width: 312px; margin-right: 12px"
          @change="handleChangeStatus"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option value="active"> Activos </a-select-option>
          <a-select-option value="inactive"> Inactivos </a-select-option>
        </a-select>
      </div>
      <a-button
        v-if="profile && permissionsModule && permissionsModule.create"
        type="primary"
        icon="plus"
        class="btn"
        @click="handleCreate"
        >Nueva cola</a-button
      >
    </div>
    <!-- Tabla -->
    <LineList
      v-if="company"
      :sectionLine="sectionLine"
      @handleEdit="handleEdit"
      @onChangeActive="handleActivateLine"
      @onDelete="handleDeleteLine"
    />
    <!--modal de nueva cola-->
    <a-modal
      v-model="visible"
      :footer="null"
      :bodyStyle="bodyStyle"
      :afterClose="afterClose"
      width="51em"
      centered
    >
      <StepsNewLine
        :sectionLine="sectionLine"
        ref="formNewLine"
        @handleOk="handleOk"
        @handleCancel="handleCancel"
      />
    </a-modal>
    <!--modal de editar cola-->
    <a-modal
      v-model="visibleEdit"
      :title="`Editar cola - ${record.name}`"
      :footer="null"
      :bodyStyle="bodyStyleEdit"
      :afterClose="afterCloseEdit"
      width="43em"
      centered
    >
      <FormEdit
        :sectionLine="sectionLine"
        ref="formEdit"
        :record="record"
        :tabDefault="tabDefault"
        @cancelModal="hideModal"
        @okModalEdit="hideModal"
      />
    </a-modal>
    <ModalActions
      :visible="modalActions.visible"
      :data-source-selects="lines"
      :item-source="record"
      :icon="modalActions.icon"
      :title="modalActions.title"
      :descriptions="modalActions.descriptions"
      :typeButton="modalActions.typeButton"
      :keyWord="modalActions.keyWord"
      :name-button="modalActions.nameButton"
      :onOk="modalActions.onOk"
      :show-alert-error="true"
      @onClose="handleHideModalActions"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import LineList from '@/app/lines/components/organisms/LineList.vue'
import StepsNewLine from '@/app/lines/components/organisms/StepsNewLine.vue'
import FormEdit from '@/app/lines/components/organisms/FormEdit.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import ModalActions from '@/app/shared/components/modals/ModalActions'
import transformWordGender from '@/app/shared/mixins/transform'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionLines',
  mixins: [attemptMixin, compareMixin, transformWordGender],
  components: {
    LineList,
    StepsNewLine,
    FormEdit,
    ModalActions,
    HeaderModule,
  },
  data: () => ({
    vari,
    visible: false,
    visibleEdit: false,
    tabDefault: 'general',
    bodyStyle: {
      padding: '40px',
      height: '44em',
    },
    bodyStyleEdit: {
      padding: '0',
    },
    record: {},
    sectionLine: {
      modal: {
        loading: {
          button: false,
        },
      },
      createoredit: {
        agentsId: [],
        supervisorsId: [],
        name: null,
        channels: [],
        lineId: null,
      },
      list: {
        search: null,
        page: 1,
        total: 0,
        data: [],
        loading: {
          table: false,
        },
        active: 'all',
      },
    },
    modalActions: {
      visible: false,
      onOk: () => {},
    },
    gold_6: '#FAAD14',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  created() {
    this.intervalPromiseAttempt(() => {
      if (!this.company) throw 'No company'
      if (!this.$socket) throw 'socket'
      this.loadInitialData()
    })
  },
  computed: {
    ...mapGetters(['lines', 'profile', 'company', 'permissions']),

    /**
     * @return {Object} permisos para el modulo
     */
    permissionsModule() {
      const name = 'lines'
      return this.permissions[name]
    },
  },
  methods: {
    ...mapActions([
      'getLines',
      'getAssignedLines',
      'listenLineInserted',
      'getAccountsUsers',
      'listAccounts',
      'changeActiveLine',
      'deleteLine',
      'addNotification',
    ]),
    /**
     * Carga la data principal para el módulo
     */
    async loadInitialData() {
      try {
        await this.listAccounts({})
        await this.getAssignedLines()
        await this.getAccountsUsers()
        this.listenLineInserted({ self: this })
        this.getLines({ self: this })
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Muestra el modal de creación de colas
     */
    handleCreate() {
      this.visible = true
    },
    /**
     * Cierra el modal de creación de colas
     */
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.visible = false
    },
    /** Cuando termina de escrbir en el buscador */
    onInput(e) {
      let value = e.target.value
      this.endWaitTyping(() => {
        this.onSearch(value)
      }, 1000)
    },
    onSearch(value) {
      this.sectionLine.list.page = 1
      this.sectionLine.list.search = value
      this.getLines({ self: this }) // Completa los datos de las colas desde Vuex
    },
    handleEdit(record, tabDefault = 'general') {
      this.visibleEdit = true
      this.record = record
      this.tabDefault = tabDefault
    },
    hideModal() {
      this.visibleEdit = false
    },
    afterClose() {
      this.$refs.formNewLine.handleReset()
    },
    afterCloseEdit() {
      this.$refs.formEdit.handleClose()
    },
    handleDeleteLine(active, record) {
      this.record = record
      const wordSure = this.profile.gender
        ? this.transformWordGender('segura', this.profile.gender)
        : 'segur@'
      this.modalActions.title = `¿Estás ${wordSure} de eliminar  la cola "${record.name}”?`
      this.modalActions.icon = {
        type: 'delete',
        styles: { color: 'red' },
      }
      this.modalActions.descriptions = [
        'Recuerda que en una cola pueden existir tickets asociados a un canal que tus agentes y tus chatbots estén atendiendo, por eso es necesario que escojas una acción a realizar.',
      ]
      this.modalActions.typeButton = 'danger'
      this.modalActions.keyWord = 'Eliminar'
      this.modalActions.nameButton = 'Eliminar cola'
      this.modalActions.visible = true
      this.modalActions.onOk = this.handleExecuteDelete
    },
    handleHideModalActions(load) {
      this.modalActions.visible = false
      if (load) {
        this.sectionLine.list.page = 1
        this.getLines({ self: this })
      }
    },
    handleActivateLine(active, record) {
      this.record = record
      const wordSure = this.profile.gender
        ? this.transformWordGender('segura', this.profile.gender)
        : 'segur@'
      /** Si es para inactivar */
      if (active) {
        this.modalActions.title = `¿Estás ${wordSure} de inactivar la cola "${record.name}”?`
        this.modalActions.icon = {
          type: 'question-circle',
          styles: { color: this.gold_6 },
        }
        this.modalActions.descriptions = [
          'Recuerda que en una cola pueden existir tickets asociados a un canal que tus agentes y tus chatbots estén atendiendo, por eso es necesario que escojas una acción a realizar.',
          'Y  también podrás volver a activar esta cola cuando quieras.',
        ]
        this.modalActions.typeButton = 'primary'
        this.modalActions.keyWord = 'Inactivar'
        this.modalActions.nameButton = 'Inactivar cola'
        this.modalActions.visible = true
        this.modalActions.onOk = this.handleExecuteActive
      } else {
        let self = this
        this.$confirm({
          title: `¿Deseas activar la cola “${record.name}”?`,
          content:
            'Recuerda que según los canales asociados a esta cola comenzarán a llegar tickets.',
          cancelText: 'Cancelar',
          okText: 'Activar',
          async onOk() {
            const response = await self.changeActiveLine({
              idLine: record._id,
              active: !active,
            })
            if (response.success) {
              self.$message.success('Se activo la cola correctamente ✅')
              self.getLines({ self: self })
            } else {
              const messageError = response.details || 'Ocurrió un error 😪'
              self.$message.error(messageError)
            }
          },
        })
      }
    },
    handleChangeStatus(value) {
      let newValue = value
      if (value !== 'all') newValue = value === 'active' ? true : false
      this.sectionLine.list.active = newValue
      this.sectionLine.list.page = 1
      this.getLines({ self: this })
    },
    /**
     * Ejecuta el cambio de estado de la cola
     * @param {String} id - id de la cola
     * @param {Boolean} active
     * @param {Object} action
     * @param {String} action.type specific, default
     * @param {Object} action.channels
     */
    async handleExecuteActive({ id, active, action }) {
      const key = 'change-active'
      const response = await this.changeActiveLine({
        idLine: id,
        active,
        action,
      })
      if (response.success) {
        this.$message.success({
          key,
          content: !active
            ? 'La cola se inactivó correctamente ✅'
            : 'La cola se activó correctamente',
        })
        this.createNotificationToAgents(
          this.record.agents.map((agent) => agent.userId),
          'deassign-to-line',
          this.record.name
        )
      }
      return response
    },
    /**
     * Ejecuta el cambio de estado de la cola
     * @param {String} id - id de la cola
     * @param {Boolean} active
     * @param {Object} action
     * @param {String} action.type specific, default
     * @param {Object} action.channels
     */
    async handleExecuteDelete({ id, active, action }) {
      const key = 'delete'
      const response = await this.deleteLine({
        idLine: id,
        active,
        action,
      })
      if (response.success) {
        this.$message.success({
          key,
          content: 'La cola se eliminó correctamente ✅',
        })
        this.createNotificationToAgents(
          this.record.agents.map((agent) => agent.userId),
          'deassign-to-line',
          this.record.name
        )
      }
      return response
    },
    /**
     * Crea notificacion para los agentes
     * @param {Array} users - array de los ids de usuarios
     * @param {String} action - ['deassign-to-line', 'assing-to-line']
     * @param {String} lineName - nombre de cola
     */
    createNotificationToAgents(users, action, lineName) {
      if (users.length === 0) return
      const activity = {
        type: action,
        line: lineName,
        users: users,
      }
      this.addNotification(activity)
    },
  },
}
</script>

<style lang="sass" scoped>
.section-lines
  padding-top: 8px
  h5
    text-align: left
    color: $gray_dark_900
  .section__header
    margin-bottom: 12px
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
  .section__filters

.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
