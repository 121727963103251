<template>
  <card-base class="card" style="border-radius: 4px; padding: 12px">
    <!--Canal e identificador del ticket-->
    <a-row type="flex" align="middle">
      <social-icon :size="16" :type="channel.type" />
      <span class="regular-12 mrg-bottom-0 mrg-left-4">{{ origin.title }}</span>
    </a-row>
    <!--Numero del ticket y fecha-->
    <a-row
      type="flex"
      justify="space-between"
      class="card__content-title mrg-bottom-4"
    >
      <h6 class="card__title semibold-14 mrg-bottom-0">
        Ticket: {{ ticketNumberFormatted(numberTicket) }}
      </h6>
      <span class="regular-14">{{ moment(date).format('L') }}</span>
    </a-row>
    <!--Ultimo mensaje y total de mensajes-->
    <a-row
      type="flex"
      justify="space-between"
      align="middle"
      class="flex-no-wrap"
    >
      <p class="regular-14 ellipsis mrg-bottom-0">{{ lastMessage }}</p>
      <span class="regular-14 mrg-left-12 card__total-messages"
        >{{ totalMessages }} mensajes</span
      >
    </a-row>
  </card-base>
</template>

<script>
import CardBase from '@/app/shared/components/cards/CardBase'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import ticketNumberFormatted from '@/app/shared/mixins/format'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'CardOldTicket',
  props: {
    origin: {
      type: Object,
      required: true,
    },
    channel: {
      type: Object,
      required: true,
    },
    numberTicket: {
      type: Number,
      required: true,
    },
    date: {
      type: [String, Date],
      required: true,
    },
    lastMessage: {
      type: String,
      required: true,
    },
    totalMessages: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    SocialIcon,
    CardBase,
  },
  mixins: [ticketNumberFormatted],
  data: () => ({
    moment: moment,
  }),
}
</script>

<style lang="sass" scoped>
.card
  cursor: pointer
  color: $gray_7
  &__content-title
      color: $gray_8
  &__title
      color: inherit
  &__total-messages
    min-width: max-content
  &:hover
    background-color: $x_blue
</style>
