const actions = {
  /** Escuchar y setear agendas transferidas junto a los tickets */
  async listenTransferedSchedulesTicket(context) {
    try {
      this._vm.$socket.on('client-transfer-room-schedules', (schedules) => {
        schedules.forEach((schedule) => {
          schedule.scheduled_for = new Date(schedule.scheduled_for)
          context.commit('ADD_SCHEDULE', schedule)
        })
        // context.commit('REORDER_SCHEDULES')
      })
    } catch (error) {
      console.error(
        '[settingsRoomModule][listenTransferedSchedulesTicket]',
        error
      )
    }
  },
}

export default {
  actions,
}
