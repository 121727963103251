import chatbotModule from './chatbotModule'
import varsModule from './varsModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Chatbots */
  resetMoChatbot(context) {
    context.commit('RESET_CHATBOT_MODULE')
  },
}

const modules = {
  chatbotModule,
  varsModule,
}

export default {
  modules,
  actions,
}
