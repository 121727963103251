/**
 * Corrige el encodeURIComponent para enviarlo como query en la url
 * @param {String} str Texto a encodear
 * @return {String}
 */
const fixedEncodeURIComp = (str) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A')
}

export default {
  fixedEncodeURIComp,
}
