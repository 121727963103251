import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['simple_chatbots_vars', 'nodes']),
  },
  methods: {
    ...mapActions([
      'addNodeOptions',
      'addNodeMessage',
      'addNodeCatcher',
      'addNodeButtons',
      'addNodeCards',
      'addNodeLists',
      'addOptionToNode',
      'addChildToNode',
      'addSubchildToNode',
    ]),

    /**
     * Crear un nodo de opciones
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton o tarjeta
     * @param {String} childParent.childId - id del nodo opcion, boton o tarjeta
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
     */
    async addDefaultNodeOptions(
      parentNodeId,
      { childId = null, subChildId = null } = {}
    ) {
      const newNodeOptions = {
        message: 'Hola, bienvenid@ a tu primer chatbot',
        parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
        option: {
          text: 'Una opción',
          action: {
            type: 'none',
            message: 'Actualmente no atendemos por este medio, gracias.',
          },
        },
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeOptions({
        chatbotId: this.$route.params.id,
        nodeOptions: newNodeOptions,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar las opciones múltiples 😓')
      }
      return response
    },
    /**
     * Crear un nodo de mensajes
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton o tarjeta
     * @param {String} childParent.childId - id del nodo opcion, boton o tarjeta
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
     */
    async addDefaultNodeMessage(
      parentNodeId,
      { childId = null, subChildId = null } = {}
    ) {
      const newNodeMessage = {
        message: 'Hola que onda',
        action: 'none',
        wait: false,
        parentNodeId: parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
      }
      const response = await this.addNodeMessage({
        chatbotId: this.$route.params.id,
        nodeMessage: newNodeMessage,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar el mensaje 😓')
      }
      return response
    },
    /**
     * Crear un nodo capturador de variables
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton o tarjeta
     * @param {String} childParent.childId - id del nodo opcion, boton o tarjeta
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
     * @param {String} chatbotVarId
     */
    async addDefaultNodeCatcher(
      parentNodeId,
      { childId = null, subChildId = null } = {},
      chatbotVarId = null
    ) {
      const newNodeCatcher = {
        message: 'Hola que onda',
        action: 'none',
        wait: false,
        parentNodeId: parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
        chatbotVarId: chatbotVarId || this.simple_chatbots_vars[0]._id,
      }
      const response = await this.addNodeCatcher({
        chatbotId: this.$route.params.id,
        nodeCatcher: newNodeCatcher,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar el mensaje 😓')
      }
      return response
    },
    /**
     * Crear un nodo de botones
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton o tarjeta
     * @param {String} childParent.childId - id del nodo opcion, boton o tarjeta
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
     */
    async addDefaultNodeButtons(
      parentNodeId,
      { childId = null, subChildId = null } = {}
    ) {
      const newNodeButtons = {
        message: 'Hola, bienvenid@ a tu primer chatbot',
        parentNodeId: parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
        button: {
          text: 'Un botón',
          action: {
            type: 'none',
            message: 'Actualmente no atendemos por este medio, gracias.',
          },
        },
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeButtons({
        chatbotId: this.$route.params.id,
        nodeButtons: newNodeButtons,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadió correctamente')
      } else {
        this.$message.error('No pudimos agregar los botones 😓')
      }
      return response
    },
    /**
     * Crear un nodo cards
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton o tarjeta
     * @param {String} childParent.childId - id del nodo opcion, boton o tarjeta
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta, manda el id del boton de una opcion
     */
    async addDefaultNodeCards(
      parentNodeId,
      { childId = null, subChildId = null } = {}
    ) {
      const newNodeCards = {
        parentNodeId: parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
        card: {
          title: 'Tarjeta 1',
          button: {
            text: 'Botón 1',
            action: {
              type: 'none',
              message: 'Actualmente no atendemos por este medio, gracias.',
            },
          },
        },
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeCards({
        chatbotId: this.$route.params.id,
        nodeCards: newNodeCards,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar las opciones múltiples 😓')
      }
      return response
    },
    /**
     * Agrega una opción por defecto
     * @param {String} nodeId
     */
    async addDefaultOption(nodeId) {
      const newOption = {
        text: 'Opción',
        type_action: 'none',
        message_action: 'Actualmente no atendemos por este medio, gracias.',
      }
      const response = await this.addOptionToNode({
        chatbotId: this.chatbotId,
        nodeId,
        newOption: newOption,
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Agrega un botón por defecto
     * @param {String} nodeId
     */
    async addDefaultButton(nodeId) {
      const newButton = {
        text: 'Botón',
        type_action: 'none',
        message_action: 'Actualmente no atendemos por este medio, gracias.',
      }
      const response = await this.addChildToNode({
        chatbotId: this.chatbotId,
        nodeId,
        newChild: newButton,
        typeNode: 'buttons',
        propertyText: 'text',
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Agrega una tarjeta por defecto
     * @param {String} nodeId
     */
    async addDefaultCard(nodeId) {
      const newCard = {
        title: 'Tarjeta',
        button: {
          action: {
            type: 'none',
            message: 'Actualmente no atendemos por este medio, gracias.',
          },
          text: 'Botón',
        },
      }
      const response = await this.addChildToNode({
        chatbotId: this.chatbotId,
        nodeId,
        newChild: newCard,
        typeNode: 'cards',
        propertyText: 'title',
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Agrega un botón de tarjeta por defecto
     * @param {String} nodeId
     * @param {String} childId
     */
    async addDefaultButtonCard(nodeId, childId) {
      const newSubchild = {
        text: 'Botón',
        action: {
          type: 'none',
          message: 'Actualmente no atendemos por este medio, gracias.',
        },
      }
      const response = await this.addSubchildToNode({
        chatbotId: this.chatbotId,
        nodeId,
        nodeType: 'cards',
        subchildType: 'buttons',
        childId,
        newSubchild: newSubchild,
        propertyText: 'text',
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Crear un nodo listas
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {Object} childParent - si su padre es una opcion, boton, tarjeta y un item de lista
     * @param {String} childParent.childId - id del nodo opcion, boton, tarjeta y un item de lista
     * @param {String} childParent.subChildId - si viene del boton de una tarjeta o de un item de listas, manda el id del boton de una opcion
     */
    async addDefaultNodeLists(
      parentNodeId,
      { childId = null, subChildId = null } = {}
    ) {
      const newNodeList = {
        parentNodeId: parentNodeId,
        childParent: {
          childId,
          subChildId,
        },
        item: {
          title: 'Sección 1',
          option: {
            title: 'Item 1',
            action: {
              type: 'none',
              message: 'Actualmente no atendemos por este medio, gracias.',
            },
          },
        },
        globalOptionTitle: 'Menú',
        title: 'Título',
        body: 'Hola! Estes es nuestro contenido',
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeLists({
        chatbotId: this.$route.params.id,
        nodeList: newNodeList,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar las opciones múltiples 😓')
      }
      return response
    },
    /**
     * Agrega una sección de lista por defecto
     * @param {String} nodeId
     */
    async addDefaultListSection(nodeId) {
      const newSection = {
        title: 'Sección',
        option: {
          action: {
            type: 'none',
            message: 'Actualmente no atendemos por este medio, gracias.',
          },
          title: 'Item 1',
        },
      }
      const response = await this.addChildToNode({
        chatbotId: this.chatbotId,
        nodeId,
        newChild: newSection,
        typeNode: 'lists',
        propertyText: 'title',
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Agrega un botón de tarjeta por defecto
     * @param {String} nodeId
     * @param {String} childId
     */
    async addDefaultListItem(nodeId, childId) {
      const newSubchild = {
        title: 'Item',
        action: {
          type: 'none',
          message: 'Actualmente no atendemos por este medio, gracias.',
        },
      }
      const response = await this.addSubchildToNode({
        chatbotId: this.chatbotId,
        nodeId,
        nodeType: 'lists',
        subchildType: 'options',
        childId,
        newSubchild: newSubchild,
        propertyText: 'title',
      })
      if (response.success)
        this.$message.success('Se agregó la opción correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
