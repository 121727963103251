<template>
  <div class="icon-round">
    <a-icon
      v-if="type === 'multi-channel'"
      class="icon-round--multichannel"
      type="message"
      theme="filled"
    />
    <social-icon v-else :type="type" :size="19" />
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'IconRoundChannel',
  components: {
    SocialIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.icon-round
  height: 30px
  width: 30px
  background-color: $gray_1
  border: 1px solid $gray_4
  border-radius: 50%
  @include flex(row, center, center)
  &--multichannel
    font-size: 19px
    color: $orange_4
</style>
