<template>
  <!-- Carga la vista de no encontrado -->
  <Error v-if="error" />
  <!-- El módulo carga normal -->
  <div
    v-else
    :class="{
      tickets: !active_breakpoint.is_mobile,
      'tickets--mobile': active_breakpoint.is_mobile,
    }"
  >
    <banner-supervisor
      v-if="$route && $route.meta.is_monitor && profileRemote"
      id="stripeSection"
      :profile="profileRemote"
    />
    <TicketsTemplate :class="{ 'height-banner': $route.meta.is_monitor }" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TicketsTemplate from '@/app/tickets/components/templates/TicketsTemplate.vue'
import mixinAttempt from '@/app/shared/mixins/attempt'
import mixinCompare from '@/app/shared/mixins/compare'
import BannerSupervisor from '@/app/commons/components/BannerSupervisor'
import Error from '@/app/shared/views/Error.vue'
// import Notifications from '@/app/shared/utils/notification'

export default {
  name: 'Tickets',
  components: {
    TicketsTemplate,
    BannerSupervisor,
    Error,
  },
  data: () => ({
    error: null,
  }),
  mixins: [mixinAttempt, mixinCompare],
  computed: mapGetters([
    'profile',
    'profileRemote',
    'company',
    'active_breakpoint',
  ]),
  created() {
    // Comprueba que tipo de usuario es el que está entrando al módulo
    this.intervalPromiseAttempt(() => {
      if (!this.profile) throw 'profile ticket'
      if (!this.company) throw 'company ticket'
      if (!this.$socket) throw 'socket'

      if (this.$route.meta.is_monitor) {
        this.loadGrantedTicket()
      } else if (this.$route.meta.is_workspace) {
        // Obtiene todas las respuesta rapidas
        this.getFastAnswers({
          // trae todas las respuestas rapidas
          page: 1,
          search: '',
          module: 'workspace',
        })
      }
    })
  },
  methods: {
    ...mapActions([
      'joinUserIdThread',
      'listenNewReceivedMessagesThread',
      'listenNewSentMessages',
      'listenNewTicketsThread',
      'listenTransferedTicketThread',
      'listenRemovedMailbox',
      'getProfileRemote',
      'listStatusTickets',
      'getTotalUnreadTickets',
      'getSimplySupervision',
      'listenClientTicket',
      'listenMailboxTicketThread',
      'listenSettingsTicket',
      'listenMailboxSelectedThread',
      'listenMailboxPendingsThread',
      'listenLineSettedThread',
      'listenLineChannel',
      'listenStatusMessages',
      'getAssignedLines',
      'getFastAnswers',
    ]),
    // Carga los tickets como Admin visualizando a agente
    async loadGrantedTicket() {
      try {
        const userId = this.$route.params.agentUserId
        const response = await this.getProfileRemote(userId)
        if (response.success) {
          // Lista datos
          this.getSimplySupervision(userId)
          this.getTotalUnreadTickets()
          //this.listStatusTickets('active')
          // this.listStatusTickets('ended')
          // Escucha datos
          this.joinUserIdThread(userId)
          this.getAssignedLines()
          // this.listenNewReceivedMessagesThread()
          this.listenNewSentMessages()
          this.listenNewTicketsThread()
          this.listenRemovedMailbox()
          this.listenTransferedTicketThread()
          this.listenClientTicket()
          this.listenMailboxTicketThread()
          this.listenSettingsTicket()
          this.listenMailboxSelectedThread()
          this.listenMailboxPendingsThread()
          this.listenLineSettedThread()
          this.listenLineChannel()
          this.listenStatusMessages()
        } else {
          this.error = response.result
        }
      } catch (error) {
        console.warn('error 404')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.tickets--mobile
  height: 100%
.tickets
  height: 100vh
.height-banner
  height: calc(100vh - 52px)!important
</style>
