/**
 * Retorna el alias de un canal de la empresa
 */
const channelCompanyAlias = (channelCompanyId, dataSource) => {
  if (!dataSource) return null
  const channelFound = dataSource.find(
    (simpleChannel) => simpleChannel._id === channelCompanyId
  )
  return channelFound && channelFound.alias ? channelFound.alias : null
}

/**
 * Canales permitidos en la plataforma
 */
const channelsAllowed = ['whatsapp', 'instagram', 'webchat', 'messenger', 'sms']

export default {
  channelCompanyAlias,
  channelsAllowed,
}
