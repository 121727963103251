/** Archivos de variables globales de configuración personalizados de la instancia */
/** Llamar a este fichero como "instanceJson" en las importaciones */
let instanceConfig
try {
  instanceConfig = require('@/../instance.config.json')
} catch (error) {
  instanceConfig = {}
}
import instanceConfigDefault from '@/../instance.config.default.json'

function InstanceConfig() {
  // Carga las configuraciones por defecto
  for (const el of Object.keys(instanceConfigDefault)) {
    console.log(el)
    this[el] = instanceConfigDefault[el]
  }
  // Chanca a las configuraciones declarada para poner a las propias
  for (const el of Object.keys(instanceConfig)) {
    console.log(el)
    this[el] = instanceConfig[el]
  }
}

export default new InstanceConfig()
