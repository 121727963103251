import eventModule from './eventModule'
import visorModule from './visorModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Supervision */
  resetMoSupervision(context) {
    context.commit('RESET_EVENT_MODULE')
    context.commit('RESET_VISOR_MODULE')
  },
}

const modules = {
  eventModule,
  visorModule,
}

export default {
  modules,
  actions,
}
