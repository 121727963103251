<template>
  <div class="app-modals">
    <!-- Banda informativa -->
    <div v-for="bandNew in bandNews" :key="bandNew._id">
      <no-connection
        v-show="visibleBand"
        :textHTML="bandNew.band.infoHTML"
        :bgColor="bandNew.band.bgColor"
        :color="bandNew.band.color"
        class="band width--no-collapsed"
        @onClose="handleCloseConnection"
      />
    </div>
    <ModalLogin
      v-if="modalRemoteLogout.statusModal"
      :visible="modalRemoteLogout.statusModal"
      typeModal="logout"
      :typeUser="modalRemoteLogout.typeUser"
      @handleNoVisible="handleNoVisible"
    />
    <ModalLogin
      v-if="modalSessionExpired.statusModal"
      :visible="modalSessionExpired.statusModal"
      typeModal="expired"
      :typeUser="modalSessionExpired.typeUser"
      @handleNoVisible="handleNoVisible"
    />
    <ModalLogin
      v-if="modalSessions.statusModal"
      :visible="modalSessions.statusModal"
      :typeModal="modalSessions.typeModal"
      @handleNoVisible="handleNoVisible"
    />
    <!-- Modal de Features -->
    <template v-if="showModalNewFeatures">
      <template v-for="newItem in handleFilterNews(news)">
        <ModalNewFeatures
          :key="newItem._id"
          :visible="!!news.length"
          :data="newItem"
          @onClose="handleCloseModalNews"
          :goToWorkspace="
            profile.type === 'admin_agent' || profile.type === 'master_agent'
              ? true
              : false
          "
          @onContinue="handleOpenStartModal"
        />
      </template>
    </template>
    <UpgradeModal
      v-if="showModalUpgrade && recommendedPlan"
      :title="recommendedPlan.name"
      :description="upgradeDescription"
      :benefits="benefits"
      :visible="modalUpgrade.visible"
      :plan="recommendedPlan.key"
      @handleClose="handleCloseModalUpgrade"
      @onUpgrade="addPurchaseToCart(recommendedPlan)"
    />
    <!-- Modal Enterprise -->
    <ModalEnterprise
      v-else-if="showUpgradeEnterprise"
      :visible="modalUpgrade.visible"
      @hideModal="handleCloseModalUpgrade"
    />
    <FormChooseDowngrade
      type="expired"
      v-if="loggedIn && profile && expirationPlanModal"
      :visible="expirationPlanModal"
      :minimalQuantityDay="0"
      @hide="hideDowngrade"
    />
    <PaymentRejectedModal v-if="loggedIn && profile" />
    <routes-start-modal
      v-if="profile && isAllowedFor(profile.type, ['admin', 'master_agent'])"
      :visible="routesStartModal.visible"
      @onSelectRoute="handleSelectRoute"
      @onClose="handleCloseStartModal"
    />
    <tuto-modal
      :visible="tutoModal.visible"
      :title="tutoModal.title"
      :description="tutoModal.description"
      :video="tutoModal.video"
      :video-picture="tutoModal.videoPicture"
      :paragraph-bottom="tutoModal.paragraphBottom"
      :paragraph-footer="tutoModal.paragraphFooter"
      :actionBottom="tutoModal.actionBottom"
      :link-footer="tutoModal.linkFooter"
      @onClose="handleCloseTuto"
    />
    <introductory-modal
      :visible="introductoryModal.visible"
      :desdription="introductoryModal.description"
      @onClose="handleCloseIntroductory"
    />
    <basic-modal
      :visible="basicModal.visible"
      :title="basicModal.title"
      :subtitle="basicModal.subtitle"
      :description="basicModal.description"
      :ok-text="basicModal.nameButton"
      cancel-text="Quizás más tarde"
      :icon="basicModal.icon"
      :routeNameButtonOk="basicModal.routeNameButtonOk"
      :id="basicModal.id"
      @onClose="handleCloseBasicModal"
      @onOk="handleOkBasicModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ModalLogin from '@/app/shared/components/modals/ModalLogin'
import ModalNewFeatures from '@/app/commons/components/ModalNewFeatures.vue'
import UpgradeModal from '@/app/commons/components/UpgradeModal.vue'
import PaymentRejectedModal from '@/app/shop/components/market/paymentRejected/PaymentRejectedModal.vue'
import FormChooseDowngrade from '@/app/shop/components/market/downgrade/FormChooseDowngrade.vue'
import transformMixin from '@/app/shared/mixins/transform'
import transformWordGender from '@/app/shared/mixins/transform'
import RoutesStartModal from '@/app/shared/components/modals/RoutesStartModal'
import TutoModal from '@/app/shared/components/modals/TutoModal'
import IntroductoryModal from '@/app/shared/components/modals/IntroductoryModal'
import BasicModal from '@/app/commons/components/organisms/BasicModal.vue'
import compareMixin from '@/app/shared/mixins/compare'
import ModalEnterprise from '@/app/shop/components/market/contact/ModalEnterprise.vue'
import NoConnection from '@/app/commons/components/NoConnection.vue'

export default {
  name: 'AppModals',
  components: {
    ModalLogin,
    ModalNewFeatures,
    UpgradeModal,
    FormChooseDowngrade,
    PaymentRejectedModal,
    RoutesStartModal,
    TutoModal,
    IntroductoryModal,
    BasicModal,
    ModalEnterprise,
    NoConnection,
  },
  mixins: [transformMixin, transformWordGender, compareMixin],
  data: () => ({
    app: process.env.VUE_APP_NAME,
    visibleBand: true,
    visibleExpirationPlan: false,
    routesStartModal: {
      visible: false,
    },
    description:
      'Recuerda que mientras tu empresa no cuente son saldo, tus usuarios no podrán enviar o recibir mensajes de WhatsApp, por lo que te aconsejamos que recargues lo antes posible para que no pierdas comunicación con tus clientes por este canal.',
  }),
  watch: {
    actionDesasigned(val) {
      if (val.asigned === false) {
        this.openNotification(val.typeUser, val.nameLine, false)
      }
    },
    actionAsigned(val) {
      if (val.asigned === true) {
        this.openNotification(val.typeUser, val.nameLine, true)
      }
    },
    actionChannelRemoved(notification) {
      this.openInformativeNotification(notification)
    },
    actionChatPreferencesUpdated(notification) {
      this.openInformativeNotification(notification)
    },
    reloadNotification(val) {
      if (val.visible) this.openReloadNotification(val.title, val.description)
    },
    /**
     * Escucha cuando el valor de la notificacion cambia
     * @param {Object} val
     * @param {Boolean} val.visible
     * @param {String} val.title
     * @param {String} val.description
     * @param {String} val.type
     * @param {Boolean} val.showButton
     * @param {String} val.okText
     * @param {Object} val.route
     * @param {Boolean} val.reload
     */
    notification_with_type(val) {
      if (val.visible) {
        this.openNotificationWithType({
          type: val.type,
          title: val.title,
          description: val.description,
          showButton: val.showButton,
          okText: val.okText,
          route: val.route,
          reload: val.reload,
        })
      }
    },
  },
  mounted() {
    this.loadModalRemoteLogout()
  },
  computed: {
    ...mapGetters([
      'menuCollapse',
      'actionDesasigned',
      'actionAsigned',
      'actionChannelRemoved',
      'actionChatPreferencesUpdated',
      'modalRemoteLogout',
      'modalSessionExpired',
      'modalSessions',
      'profile',
      'loggedIn',
      'news',
      'modalUpgrade',
      'companyPricing',
      'pricings',
      'minimalQuantityDay',
      'expirationPlanModal',
      'company',
      'tutoModal',
      'introductoryModal',
      'basicModal',
      'profileRemote',
      'reloadNotification',
      'menuItems',
      'purchase',
      'notification_with_type',
      'active_breakpoint',
      'show_documentation',
    ]),
    /**
     * Plan recomendado para hacer upgrade
     * @returns {Object}
     */
    recommendedPlan() {
      let recommendedPlan = {}
      if (!this.pricings || this.pricings.length === 0) return {}

      const pricings = this.pricings
      const routesBusiness = ['supervision', 'strategies'] // rutas con plan business
      const planSpecific =
        this.modalUpgrade.type || routesBusiness.includes(this.$route.name)
          ? 'business'
          : ''
      switch (planSpecific) {
        case 'business':
          {
            recommendedPlan = pricings.find(
              (princing) => princing.key === 'business'
            )
            if (!recommendedPlan)
              recommendedPlan = pricings.find(
                (princing) => princing.key === 'enterprise'
              )
          }
          break
        default: {
          const indexCurrentPlan = pricings.findIndex(
            (princing) => princing._id === this.companyPricing.pricingId
          )
          const indexRecommend = indexCurrentPlan + 1
          // esta validacion es necesaria para evitar que se renderize el modal de upgrade cuando el index no exista en los planes
          if (indexRecommend > pricings.length) return
          if (indexCurrentPlan !== -1)
            recommendedPlan = pricings[indexRecommend]
          else recommendedPlan.key = 'enterprise' // por defecto enterprise, cuando no se encuentre un plan
          break
        }
      }
      return recommendedPlan
    },
    benefits() {
      if (!this.recommendedPlan) return
      switch (this.recommendedPlan.key) {
        case 'advanced':
          return [
            { nameIcon: 'usergroup-add', description: 'Usuarios ilimitados' },
            { nameIcon: 'robot', description: '2 chatbots' },
            {
              nameIcon: 'setting',
              description: 'Etiquetas y campos ilimitados',
            },
            { nameIcon: 'message', description: '500 tickets al mes' },
          ]
        case 'business':
          return [
            { nameIcon: 'usergroup-add', description: 'Usuarios ilimitados' },
            { nameIcon: 'robot', description: '4 chatbots' },
            { nameIcon: 'eye', description: 'Monitoreo por agente' },
            { nameIcon: 'message', description: '2,000 tickets al mes' },
          ]
        case 'enterprise':
          return [
            { nameIcon: 'usergroup-add', description: 'Mínimo de 10 usuarios' },
            { nameIcon: 'robot', description: 'Chatbots ilimitados' },
            { nameIcon: 'message', description: 'Tickets ilimitados' },
            { nameIcon: 'api', description: `Consumo de ${this.app} API` },
          ]
        default:
          return [
            { nameIcon: 'usergroup-add', description: 'Mínimo de 10 usuarios' },
            { nameIcon: 'robot', description: 'Chatbots ilimitados' },
            { nameIcon: 'message', description: 'Tickets ilimitados' },
            { nameIcon: 'api', description: `Consumo de ${this.app} API` },
          ]
      }
    },
    /**
     * Descripcion del modal de upgrade
     */
    upgradeDescription() {
      if (!this.recommendedPlan) return ''
      switch (this.recommendedPlan.key) {
        case 'advanced':
          return '¡Sube de plan y obtén más beneficios!'
        case 'business':
          return '¡Sube de plan y obtén más beneficios!'
        case 'enterprise':
          return 'Contáctanos y obtén todos los beneficios disponibles'
        default:
          return '¡Sube de plan y obtén más beneficios!'
      }
    },
    /**
     * @return {Array}
     */
    bandNews() {
      if (!this.news || !this.news.length) return []
      return this.news.filter((item) => item.layout === 'band')
    },
    /**
     * Mostrar el modal de upgrade
     */
    showModalUpgrade() {
      return (
        this.companyPricing &&
        this.modalUpgrade.visible &&
        this.modalUpgrade.type !== 'enterprise' &&
        this.recommendedPlan.key !== 'enterprise'
      )
    },
    /**
     * Mostrar el modal de enterprise
     */
    showUpgradeEnterprise() {
      return (
        (this.companyPricing &&
          this.modalUpgrade.visible &&
          this.modalUpgrade?.type === 'enterprise') ||
        (this.recommendedPlan && this.recommendedPlan?.key === 'enterprise')
      )
    },
    /**
     * @return {Boolean} - los modales de features solo se mostraran para desktop
     */
    showModalNewFeatures() {
      return (
        this.profile && !this.profileRemote && !this.active_breakpoint.is_mobile
      )
    },
  },
  methods: {
    ...mapActions(['readNews', 'pricePerUser', 'setReadAlerts']),
    ...mapMutations([
      'SET_ACTION_DESASIGNED',
      'SET_MODAL_REMOTE_LOGOUT',
      'SET_MODAL_SESSION_EXPIRED',
      'SET_MODAL_UPGRADE',
      'ADD_PRODUCT_TO_PURCHASE',
      'SET_TOTAL_TO_PURCHASE',
      'SET_IS_ALLOW_BUY_PLANS',
      'SET_TUTO_MODAL',
      'SET_INTRODUCTORY_MODAL',
      'SET_EXPIRATION_PLAN_MODAL',
      'SET_BASIC_MODAL',
    ]),
    hideDowngrade() {
      this.SET_EXPIRATION_PLAN_MODAL(false)
    },
    loadModalRemoteLogout() {
      setTimeout(() => {
        if (
          localStorage.getItem('logout_remote') &&
          this.$route.name === 'login'
        ) {
          this.SET_MODAL_REMOTE_LOGOUT({
            statusModal: true,
            typeUser: localStorage.getItem('logout_remote'),
          })
        }
      }, 1000)
    },
    /**
     * Abre una notificación
     * @param {String} typeUser Tipo de usuario que desasignó  o asigno al agente de esa cola
     * @param {String} nameLine Nombre de la cola de la cual fue desasignado(a) o asignado
     * @param {Boolean} assign True si esta siendo asignado o desasignado
     * */
    openNotification(typeUser, nameLine, assign) {
      typeUser = this.transformTypeUser(typeUser).toLowerCase()
      const article = this.transformWordGender('lo', this.profile.gender)
      const wordAdd = this.transformWordGender('agregado', this.profile.gender)
      const wordDessasign = this.transformWordGender(
        'retirado',
        this.profile.gender
      )
      const description = assign
        ? `Un ${typeUser} ${article} ha agregado a la cola "${nameLine}", usted ahora podrá atender tickets en esta cola. Si esta acción es incorrecta consúltelo con sus encargados.`
        : `Un ${typeUser} ${article} ha retirado de la cola "${nameLine}", usted ya no podrá atender tickets en esa cola. Si esta acción es incorrecta consúltelo con sus encargados.`
      const message = assign
        ? `Ha sido ${wordAdd} a una cola`
        : `Ha sido ${wordDessasign} de una cola`
      const close = () => {
        assign ? 'asignado' : this.SET_ACTION_DESASIGNED(false)
      }
      const key = `open${Date.now()}`
      this.$notification.open({
        message: message,
        description: description,
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => this.$notification.close(key),
              },
            },
            'Aceptar'
          )
        },
        key,
        onClose: close,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },

    /**
     * Abre una notificación informativa
     * @param {String} title titulo de la notificacion
     * @param {String} description descripcion de la notificacion
     * */
    openInformativeNotification({ title, description }) {
      if (!title || !description) return
      const key = `open${Date.now()}`
      this.$notification.open({
        message: title,
        description: description,
        icon: <a-icon type="fire" theme="twoTone" />,
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => this.$notification.close(key),
              },
            },
            'Aceptar'
          )
        },
        key,
        onClose: close,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },

    // ocultar modal de logout remoto
    handleNoVisible() {
      this.modalRemoteLogout.statusModal = false
      localStorage.removeItem('session_expired')
      this.SET_MODAL_SESSION_EXPIRED({
        statusModal: false,
        typeUser: null,
      })
      localStorage.removeItem('logout_remote')
      this.SET_MODAL_REMOTE_LOGOUT({
        statusModal: false,
        typeUser: null,
      })
    },
    /**
     * cerrar el modal 0 de news
     * @param {String} newsId
     */
    handleCloseModalNews(newsId, type, evaluationValue) {
      let idChannel = null
      if (evaluationValue)
        idChannel = this.company.channels[evaluationValue]._id
      this.readNews({ newsId, type: type, channel_company_id: idChannel })
    },
    /**Cerrar modal de upgrade */
    handleCloseModalUpgrade() {
      // this.upgradeModal.visible = false
      this.SET_MODAL_UPGRADE({ visible: false })
    },
    /**Añade una compra al carro de compras */
    async addPurchaseToCart(item) {
      if (this.$route.name !== 'menu-market') {
        this.$router.push({ name: 'menu-market' })
      }
      // si ya hay algun plan seleccionado
      if (this.purchase.products.some((product) => product.type === 'plan'))
        return
      const response = await this.pricePerUser({ pricingId: item._id })
      let purchase = {}
      purchase = {
        _id: `purchase${item._id}${new Date()}`,
        idPlan: item._id,
        totalUsers: response.quantity,
        price: response.pricePlan,
        type: 'plan',
        title: item.name,
        description: `Precio por ${response.quantity} usuarios`,
        total: response.total,
        icon: {
          src: '@/app/shared/assets/evericons/various-rocket.svg',
          custom: true,
        },
      }
      this.ADD_PRODUCT_TO_PURCHASE(purchase)
      this.SET_TOTAL_TO_PURCHASE(response.total)
      this.SET_IS_ALLOW_BUY_PLANS(false)
      this.handleCloseModalUpgrade()
    },
    handleOpenStartModal() {
      this.routesStartModal.visible = true
    },
    /**
     * Seleccion de una ruta
     * @param {Object} route
     */
    handleSelectRoute(route) {
      if (!this.show_documentation) return
      const tuto = {
        title: route.title,
        description: route.description,
        video: route.assets.videos[0].iframe,
        videoPicture: route.assets.videos[0].picture,
        id: route._id,
        paragraphBottom: route.extras.paragraphs.first,
        paragraphFooter: route.extras.paragraphs.second,
        actionBottom: route.extras.actions.paragraph_first,
        linkFooter: route.extras.linkMoreInformation,
        visible: true,
      }
      this.routesStartModal.visible = false

      const hasWsp =
        this.company.channels.whatsapp && this.company.channels.whatsapp.active
      if (route._id === 'integrateWsp' && hasWsp) {
        this.$router.push({
          name: 'channel',
          params: { channel: 'whatsapp' },
          query: { channelId: this.company.channels.whatsapp.channelIds[0] },
        })
      } else {
        this.SET_TUTO_MODAL(tuto)
        this.$router.push({ name: route.route.name })
      }
    },
    handleCloseTuto() {
      this.SET_TUTO_MODAL({ visible: false })
    },
    handleCloseStartModal() {
      this.routesStartModal.visible = false
    },
    handleCloseIntroductory() {
      this.SET_INTRODUCTORY_MODAL({ visible: false })
    },
    /**
     * Filtrar las noticias
     * @param {Array} news - array de todas las noticias
     * @return {Array}
     */
    handleFilterNews(news) {
      if (!news) return
      const welcomeNews = news.find((item) => item.type === 'welcome')
      // si existe la noticia de bienvenida
      if (welcomeNews) {
        const otherNews = news.filter((item) => item.type !== 'welcome')
        // se toman como leidas las otras noticias
        if (otherNews.length > 0) {
          otherNews.forEach((item) =>
            this.readNews({
              newsId: item._id,
              type: 'feature',
              channel_company_id: null,
            })
          )
        }
        return [welcomeNews]
      }
      // Se excluyen a los layouts que no abrirán en modal
      else {
        const exludeLayoutsModals = ['band']
        const justModalNews = news.filter(
          (item) => !exludeLayoutsModals.includes(item.layout)
        )
        return justModalNews
      }
    },
    handleCloseBasicModal(id = null) {
      this.basicModal.visible = false
      this.SET_BASIC_MODAL(false)

      // Endpoint set modal read
      if (id) {
        this.setReadAlerts(id)
      }
    },
    handleOkBasicModal(routeName = 'shop', id = null) {
      // Endpoint set modal read
      if (id) {
        this.setReadAlerts(id)
      }

      // Hide modal
      this.basicModal.visible = false

      // El catch es para validar el error: 'redundant navigation'
      this.$router.push({ name: routeName }).catch(() => {})
    },
    openReloadNotification(title, description) {
      const key = `open${Date.now()}`
      this.$notification.open({
        message: title,
        description: description,
        duration: 0,
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => {
                  if (
                    !this.menuItems.some(
                      (item) => item.route === this.$route.name
                    )
                  ) {
                    window.location.assign('/redirect')
                  } else {
                    window.location.reload()
                  }
                },
              },
            },
            'Actualizar'
          )
        },
        onClose: () => {
          if (!this.menuItems.some((item) => item.route === this.$route.name)) {
            window.location.assign('/redirect')
          } else {
            window.location.reload()
          }
        },
        key,
      })
    },
    /**
     * cerrar el componente de la banda informativa del top
     */
    handleCloseConnection() {
      this.visibleBand = !this.visibleBand
    },
    /**
     * Abre una notificación de acuerdo al tipo
     * Esta notificación no tiene botones
     * @param {Object} args
     * @param {String} args.type - success, error, info, warning
     * @param {String} args.title - titulo de la noficacion
     * @param {String} args.description - descripcion para la notificacion
     * @param {Object} args.route
     * @param {String} args.route.name
     * @param {Object} args.route.params
     * @param {Object} args.route.query
     * @param {Object} args.reload
     */
    openNotificationWithType({
      type,
      title,
      description,
      showButton = false,
      okText = 'ok',
      route,
      reload = false,
    }) {
      let self = this
      const key = `open${Date.now()}`
      this.$notification[type]({
        message: title,
        description,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
        btn: (h) => {
          return (
            showButton &&
            h(
              'a-button',
              {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    if (reload) window.location.reload()
                    else self.$router.push(route)
                    this.$notification.close(key)
                  },
                },
              },
              okText
            )
          )
        },
        key,
        onClose: close,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.app-modals
  .band
    top: 0
    right: 0
  .width--no-collapsed
    width: calc( 100% - 58px )
</style>
