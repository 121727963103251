import menuModule from './menuModule'
import soundsModule from './soundsModule'
import versionModule from './versionModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Navegación */
  resetMoNav(context) {
    context.commit('RESET_SIDEBAR_MODULE')
    // context.commit("RESET_SOUNDS_MODULE") // No sería necesario porque los audio son los mismos para todos
  },
}

const modules = {
  menuModule,
  soundsModule,
  versionModule,
}

export default {
  modules,
  actions,
}
