import initialState from './initialState'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = initialState

export default {
  modules: {
    companiesModule: {
      state,
      getters,
      mutations,
      actions,
    },
  },
}
