<template>
  <a-modal
    :visible="visible"
    width="80vw"
    :footer="null"
    :bodyStyle="bodyStyle"
    :maskClosable="false"
    :closable="false"
    centered
  >
    <a-row
      class="modal__header"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <a-row type="flex" align="middle">
        <a-avatar v-if="user.avatar" :src="user.avatar" class="mrg-right-12" />
        <a-avatar v-else class="mrg-right-12" icon="user" />
        <div>
          <h4 v-if="user.isEmpty" class="mrg-bottom-2 info_i2">Desconocido</h4>
          <h4 v-else class="mrg-bottom-2">
            {{ user.isCurrent ? 'Tú' : user.fullName }}
          </h4>
          <p class="mrg-bottom-0">{{ date }}</p>
        </div>
      </a-row>
      <div>
        <template v-if="!active_breakpoint.is_mobile">
          <a-tooltip v-if="imageUrl" title="Descargar">
            <a-icon
              type="download"
              class="modal__icon"
              @click="handleDownload(imageUrl)"
            ></a-icon>
          </a-tooltip>
          <a-tooltip title="Acercar">
            <a-icon
              type="zoom-in"
              class="modal__icon"
              @click="(e) => handleScroll(e, 'zoomIn')"
            />
          </a-tooltip>
          <a-tooltip title="Alejar">
            <a-icon
              type="zoom-out"
              class="modal__icon"
              @click="(e) => handleScroll(e, 'zoomOut')"
            />
          </a-tooltip>
        </template>
        <a-tooltip title="Cerrar">
          <a-icon type="close" class="modal__icon" @click="handleClose" />
        </a-tooltip>
      </div>
    </a-row>
    <div
      class="
        modal__body
        display-flex
        flex-grow
        align--center
        justify-content--center
      "
      :class="{ 'cursor-move': isDragging }"
      @mousedown="handlePressDownPicture"
      @mousewheel="handleScroll"
    >
      <img
        ref="modal_picture"
        :src="imageUrl"
        alt=" Este contenido no está disponible"
        class="modal__picture"
        :class="{
          'cursor-move': isDragging,
          'cursor-zoom-in': !isDragging && isZoomIn,
          'cursor-zoom-out': !isDragging && isZoomOut,
        }"
        @click="handleClikPicture"
      />
    </div>
    <a-row
      v-if="active_breakpoint.is_mobile"
      type="flex"
      align="middle"
      justify="center"
    >
      <a-tooltip v-if="imageUrl" title="Descargar">
        <a-icon
          type="download"
          class="modal__icon modal__icon--28"
          @click="handleDownload(imageUrl)"
        ></a-icon>
      </a-tooltip>
      <a-tooltip title="Acercar">
        <a-icon
          type="zoom-in"
          class="modal__icon modal__icon--28"
          @click="(e) => handleScroll(e, 'zoomIn')"
        />
      </a-tooltip>
      <a-tooltip title="Alejar">
        <a-icon
          type="zoom-out"
          class="modal__icon modal__icon--28"
          @click="(e) => handleScroll(e, 'zoomOut')"
        />
      </a-tooltip>
    </a-row>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalImagePreview',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    user: {
      /**
       * Este prop acepta:
       * { fullName: String, isCurrent: Boolean, isEmpty: Boolean, avatar: String}
       */
      type: Object,
      required: true,
    },
    date: { type: String, required: true },
  },
  data: () => ({
    bodyStyle: {
      height: '90vh',
      display: 'flex',
      flexDirection: 'column',
    },
    propertiesDragStart: {},
    initialPosition: {}, // posición inicial de la imagen
    currentPosition: { x: 0, y: 0 },
    lastDiff: { x: 0, y: 0 },
    imageZ: 0,
    isDragging: false,
    isZoomIn: true,
    isZoomOut: false,
  }),
  watch: {
    /**
     * Observa si cambia la imagen, se vuelve a setear la posición inicial de la imagen
     *  @param {String} url
     */
    imageUrl(url) {
      if (url) {
        const picture = this.$refs.modal_picture
        this.initialPosition = picture ? picture.getBoundingClientRect() : {}
      }
    },
  },
  computed: {
    ...mapGetters(['active_breakpoint']),
  },
  methods: {
    /**
     * Setea la traslacion Z como estilo a la imagen
     * @param {Number} n - cantidad de px que se ha trasladado desde su posición anterior
     */
    setImageZ(n) {
      const picture = this.$refs.modal_picture
      // maximo zoom
      if (this.imageZ + n > 150) {
        this.imageZ = 150
        this.setTypeZoom(false, true)
      } else if (this.imageZ + n < -80) {
        // minimo zoom
        this.imageZ = -80
        this.setTypeZoom(true, false)
      } else {
        this.imageZ += n
      }

      let self = this
      requestAnimationFrame(function () {
        picture.style.transform = `translate(${
          self.currentPosition.x + self.lastDiff.x
        }px, ${self.currentPosition.y + self.lastDiff.y}px) translateZ(${
          self.imageZ
        }px)`
      })
    },
    /**
     * Scroll zoom in o zoom out
     * @param {Object} e - event
     * @param {String} type - zoomIn, zoomOut
     */
    handleScroll(e, type) {
      e.preventDefault()
      e.stopPropagation()

      const ratio = 12.5
      const wheel = e.wheelDelta ? e.wheelDelta : -e.detail
      const wheelChanged = type === 'zoomIn' ? wheel * -1 : wheel

      let n = 0

      if (wheelChanged > 0) {
        n = ratio
        this.setTypeZoom(true, false)
      } else {
        n = -ratio
        this.setTypeZoom(false, true)
      }

      this.setImageZ(n)
    },
    /**
     * Activa el arrastre y no arrastre de imagenes cuando se mantenga presionada la imagen
     * @param {Object} e - event
     */
    handlePressDownPicture(e) {
      e.preventDefault()
      this.propertiesDragStart.x = e.clientX
      this.propertiesDragStart.y = e.clientY
      this.currentPosition.x += this.lastDiff.x
      this.currentPosition.y += this.lastDiff.y
      this.lastDiff = { x: 0, y: 0 }
      window.addEventListener('mousemove', this.handleDragPicture)
      window.addEventListener('mouseup', this.handleStopDragPicture)
    },
    /**
     * Arrastre de imagen y seteo de estilos
     * @param {Object} e - event
     */
    handleDragPicture(e) {
      e.preventDefault()
      this.isDragging = true

      const picture = this.$refs.modal_picture
      // calcula la diferencia de la posición a la que se traslado y el de la posición anterior
      this.lastDiff.x = e.clientX - this.propertiesDragStart.x
      this.lastDiff.y = e.clientY - this.propertiesDragStart.y

      let self = this
      // suma la diferencia a la posición actual
      // setea estilos a la imagen
      requestAnimationFrame(function () {
        picture.style.transform = `translate(${
          self.currentPosition.x + self.lastDiff.x
        }px, ${self.currentPosition.y + self.lastDiff.y}px) translateZ(${
          self.imageZ
        }px)`
      })
    },
    /**
     * Detiene el arrastre de imagenes
     * @param {Object} e - event
     */
    handleStopDragPicture(e) {
      e.preventDefault()
      window.removeEventListener('mousemove', this.handleDragPicture)
      window.removeEventListener('mouseup', this.handleStopDragPicture)
      // se espera un tiempo para que el click de la imagen no se ejecute al mismo tiempo que el stop
      setTimeout(() => {
        this.isDragging = false
      }, 1000)
    },
    /**
     * Click a la imagen para agradarla o achicarla
     * @param {Object} e - event
     */
    handleClikPicture(e) {
      // si esta activo el arrastre no se ejecuta
      if (this.isDragging) return
      this.isZoomIn && this.handleScroll(e, 'zoomIn')
      this.isZoomOut && this.handleScroll(e, 'zoomOut')
    },
    /**
     * Setea el tipo de zoom activo
     * @param {Boolean} isZoomIn
     * @param {Boolean} isZoomOut
     */
    setTypeZoom(isZoomIn, isZoomOut) {
      this.isZoomIn = isZoomIn
      this.isZoomOut = isZoomOut
    },
    /**
     * Resetea y emite el cierre del modal
     */
    handleClose() {
      this.$emit('onClose')
      this.propertiesDragStart = {}
      this.initialPosition = {} // posición inicial de la imagen
      this.currentPosition = { x: 0, y: 0 }
      this.lastDiff = { x: 0, y: 0 }
      this.imageZ = 0
      this.isDragging = false
      this.isZoomIn = true
      this.isZoomOut = false

      const picture = this.$refs.modal_picture
      // se espera un tiempo para que el cambio no se vea mientras se esta cerrando el modal
      setTimeout(() => (picture.style = ''), 200)
    },
    /**
     * @param {String} url URL a descargar
     */
    handleDownload(url) {
      window.open(url, '_blank', 'noopener')
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__icon
    font-size: 20px
    margin-left: 12px
    color: $gray_7
    transition: color 0.2s
    &:hover
      color: $gray_9
    &--28
      font-size: 28px
      margin-left: 20px
      &:first-child
        margin-left: 0px
      @include xs
        margin-top: 46px
      @include sm
        margin-top: 76px
      @include md
        margin-top: 110px
  &__header
    border-bottom: 1px solid $gray_4
    margin-bottom: 4px
    padding-bottom: 12px
  &__body
    overflow: hidden
    position: relative
    perspective: 225px
  &__picture
    height: min-content
    width: 30rem
    object-fit: cover
    @include xs
      width: 17rem
    @include sm
      width: 17rem
    @include md
      width: 17rem
</style>
