import axios from 'axios'
import vari from '@/app/shared/utils/variables'
/**
 * Obtener el nuevo precio de un plan si se le descuenta un usuario
 */
async function getNewPlanPrice(totalUsers = -1) {
  try {
    const response = await axios.get(
      `${
        vari.UHR
      }/admin/users/payments?date=${new Date()}&totalUsers=${totalUsers}`
    )
    return response.data
  } catch (error) {
    return { success: false }
  }
}

/**
 * Saber si un usuario esta pagado o no
 */
async function userIsPayed(userId) {
  try {
    const response = await axios.get(
      `${vari.UHR}/admin/users/payments/${userId}?date=${new Date()}`
    )
    return response.data
  } catch (error) {
    return { success: false }
  }
}

export default {
  getNewPlanPrice,
  userIsPayed,
}
