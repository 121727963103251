<template>
  <div>
    <header-module
      title="SUBCUENTAS"
      :text-before-link="`Crea subcuentas independientes en tu cuenta principal de ${app}. Ideal para tiendas, franquicias, etc.`"
      text-after-link="si deseas conocer más."
      text-link="Click aquí"
      :link="`${vari.WEB_DOC}primeros-pasos/workspace/`"
      tipography="heading-h5"
    />
    <table-filters
      :onOk="handleShowModalCompany"
      okText="Crear subcuenta"
      :showMoreFilters="false"
      :showOrderCols="false"
    >
      <template slot="filters">
        <a-input-search
          class="filters__item"
          v-model="filters.name"
          placeholder="Buscar por nombre"
          @search="onSearch"
        />
        <a-select
          class="filters__item"
          v-model="filters.country"
          placeholder="Selecciona un país"
          show-search
          @popupScroll="handleLoadMoreCountries"
          @dropdownVisibleChange="handleLoadMoreCountries"
          @change="loadPaginateCompanies"
        >
          <a-select-option value="all"> Todos los países </a-select-option>
          <a-select-option
            v-for="localCountry in localCountries"
            :key="localCountry.nameSpanish"
          >
            {{ localCountry.nameSpanish }}
          </a-select-option>
        </a-select>
        <a-select
          class="filters__item"
          v-model="filters.operation"
          placeholder="Selecciona una operación"
          @change="loadPaginateCompanies"
        >
          <a-select-option value="all">
            Todos los tipos de operación
          </a-select-option>
          <a-select-option v-for="operation in operations" :key="operation.key">
            {{ operation.value }}
          </a-select-option>
        </a-select>
      </template>
    </table-filters>
    <a-divider class="template__divider" />
    <list-companies :loading="loadList" />
    <modal-form-company
      :visible="modalFormCompany.visible"
      :title="modalFormCompany.title"
      :okText="modalFormCompany.okText"
      @onClose="modalFormCompany.visible = false"
      :onOk="handleAddCompany"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import ListCompanies from '@/app/companies/components/organisms/ListCompanies'
import ModalFormCompany from '@/app/companies/components/organisms/ModalFormCompany'
import attemptMixin from '@/app/shared/mixins/attempt'
import companiesMixin from '@/app/companies/mixins/companiesMixin'

export default {
  name: 'CompaniesTemplate',
  components: {
    HeaderModule,
    TableFilters,
    ListCompanies,
    ModalFormCompany,
  },
  data: () => ({
    vari,
    app: process.env.VUE_APP_NAME,
    filters: {
      name: '',
      country: 'all',
      operation: 'all',
    },
    modalFormCompany: {
      visible: false,
      title: 'Crear una subcuenta',
      okText: 'Crear',
    },
    loadList: false,
    localCountries: [], // paises para el componente
    loadMoreCountries: false,
  }),
  created() {
    this.loadInitialData()
  },
  mixins: [attemptMixin, companiesMixin],
  computed: {
    ...mapGetters(['company', 'countriesNotRepeatByName', 'operations']),
  },
  methods: {
    ...mapActions(['getPaginateCompanies', 'addCompany', 'getSimpleCompanies']),

    handleShowModalCompany() {
      this.modalFormCompany.visible = true
    },
    async loadInitialData() {
      this.intervalAttempt(() => {
        if (!this.company) throw 'Not company found'
        this.setInitialValueToLocalCountries()
        this.getSimpleCompanies()
        this.loadPaginateCompanies()
      })
    },
    async loadPaginateCompanies() {
      this.loadList = true
      const response = await this.getPaginateCompanies({ ...this.filters })
      if (!response.success) this.$message.error('Ocurrió un error')
      this.loadList = false
    },
    /**
     * Busqueda por nombre de empresa
     */
    onSearch() {
      this.loadPaginateCompanies()
    },
    /**
     * Setea un valor inicial a los paises locales
     */
    setInitialValueToLocalCountries() {
      const countries = this.setInitialValueToCountries()
      this.localCountries = countries
    },
    /**
     * Carga más paises
     */
    handleLoadMoreCountries() {
      if (!this.loadMoreCountries) {
        this.loadMoreCountries = true

        let indexStart = this.localCountries.length - 1
        // para que el listado de paises encontrados no se vea de forma bruca
        setTimeout(() => {
          this.localCountries.push(
            [...this.countriesNotRepeatByName].slice(
              indexStart,
              indexStart + 10
            )
          )
          indexStart = indexStart + 10
        }, 100)
        setTimeout(() => {
          this.localCountries = [...this.countriesNotRepeatByName]
        }, 200)
      }
    },
    /**
     * Agregar una empresa
     * @param {*} values
     */
    async handleAddCompany(values) {
      const response = await this.addCompany(values)
      if (response.success) {
        this.$message.success('Se agregó una subcuenta exitosamente')
        this.modalFormCompany.visible = false
      } else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
</script>

<style lang="sass" scoped>
.template__divider
  margin: 12px 0px
.filters__item
  margin-right: 12px
  width: 18em
  &:first-child
    width: 22em
</style>
