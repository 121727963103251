<template>
  <a-row
    type="flex"
    align="middle"
    class="navbar flex-no-wrap"
    :class="`navbar--${positionNavbar}`"
  >
    <!--Logo o icono-->
    <template v-if="!currentRoute.empty">
      <router-link v-if="currentRoute.isBack" :to="currentRoute.routeBack">
        <div class="navbar__container-icon">
          <a-icon type="arrow-left" class="navbar__icon" />
        </div>
      </router-link>
      <logo
        v-else
        type="light"
        size="small"
        :size-mobile="28"
        :app="owner_app"
        class="mrg-right-8"
      />
    </template>
    <!--Nombre del modulo-->
    <a-dropdown
      v-if="currentRoute.name"
      :trigger="['click']"
      :overlayStyle="{ width: '100%' }"
    >
      <div
        class="navbar__item"
        @click="handleSelectTitle(currentRoute.routeTo)"
      >
        <h4
          class="semibold-16 navbar__title mrg-bottom-0 ellipsis text--left"
          :class="{ 'navbar__title--underline': currentRoute.isChat }"
        >
          {{ currentRoute.name }}
          <a-icon
            v-if="currentRoute.showFavorite"
            type="star"
            theme="filled"
            class="navbar__icon--star"
          />
          <a-tag
            v-if="currentRoute.totalTickets && currentRoute.totalTickets.show"
            class="mrg-left-8"
            color="geekblue"
            >{{ currentRoute.totalTickets.total }} tickets activos</a-tag
          >
        </h4>
        <span v-if="currentRoute.isChat" class="regular-12"
          >Ver información</span
        >
      </div>
      <!--Menu del ticket-->
      <template v-if="currentRoute.visibleSubmenu">
        <!--TODO: Esto debería mejorar para cuando sea reactivo-->
        <menu-ticket
          slot="overlay"
          :show-default="false"
          class="ant-dropdown-menu"
          style="box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)"
        />
      </template>
    </a-dropdown>
    <div style="flex: 1 1 0%"></div>
    <!--Boton para resolver ticket-->
    <a-button
      v-if="currentRoute.showResolveTicket"
      size="large"
      type="primary"
      class="navbar__button"
      @click="resolveTicketVisible = true"
      >Resolver</a-button
    >
    <!--Avatar del usuario-->
    <avatar-status
      v-else-if="
        !currentRoute.showResolveTicket && profileStatus && profileStatus.state
      "
      :status="profileStatus.state"
      :border="profileStatus.borderMin"
      :padding="profileStatus.paddingMin"
    >
      <avatar-user
        :src="profile && profile.avatar ? profile.avatar : null"
        :names="profile ? profile.names : ''"
        :size="40"
        @onClick="handleShowDrawerProfile"
      />
    </avatar-status>

    <profile-menu-drawer
      :visible="drawerProfile.visible"
      :timer="timerFormatted"
      @onClose="drawerProfile.visible = false"
      @onSelectStatus="handleSetStatus"
      @onReconnect="handleReconnect"
    />
    <modal-state
      :type="modalState.type"
      :visible="modalState.visible"
      :timer="timerFormatted"
      @onReconnect="handleReconnect"
    />
    <!--Resolver ticket-->
    <resolve-ticket
      :visible="resolveTicketVisible"
      :onOk="resolveTicket"
      @onClose="resolveTicketVisible = false"
    />
  </a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Logo from '@/app/shared/components/logos/Logo'
import AvatarUser from '@/app/shared/components/avatars/AvatarUser'
import MenuTicket from '@/app/tickets/components/organisms/MenuTicket'
import ProfileMenuDrawer from '@/app/commons/components/organisms/ProfileMenuDrawer.vue'
import ResolveTicket from '@/app/manageTickets/components/organisms/ResolveTicket'
import supportMixin from '@/app/shared/mixins/support'
import formatMixin from '@/app/shared/mixins/format'
import AvatarStatus from '@/app/shared/components/avatars/AvatarStatus.vue'
import ModalState from '@/app/commons/components/organisms/ModalState'
import moment from 'moment'

export default {
  components: {
    Logo,
    MenuTicket,
    ProfileMenuDrawer,
    AvatarUser,
    ResolveTicket,
    AvatarStatus,
    ModalState,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    owner_app: !process.env.VUE_APP_OWNER_APP
      ? process.env.VUE_APP_NAME
      : process.env.VUE_APP_OWNER_APP,
    drawerProfile: {
      visible: false,
    },
    resolveTicketVisible: false,
    idStatus: null,
    timerSeconds: 0,
    timer: null, // Funcion del timer
    modalState: {
      type: null,
      visible: false,
      timer: null,
    },
  }),
  mixins: [supportMixin, formatMixin],
  mounted() {
    this.counter()
    this.handleSetModalState(this.profile)
  },
  watch: {
    profile: {
      deep: true,
      handler(nv) {
        // nv: nuevo valor
        this.handleSetModalState(nv)
      },
    },
    loggedIn() {
      if (!this.loggedIn) this.cleanData()
    },
  },
  computed: {
    ...mapGetters([
      'ticketSelected',
      'active_breakpoint',
      'profile',
      'mailboxes',
      'profile_states',
      'loggedIn',
      'total_assigned_tickets',
    ]),

    /**
     * @return {Boolean} mostrar submenu
     */
    visibleSubmenu() {
      if (!this.active_breakpoint) return false
      else {
        const navbar = this.active_breakpoint.components.navbar

        if (navbar && navbar.visible) return navbar.submenu
        else return false
      }
    },
    /**
     * Posicion del navbar
     */
    positionNavbar() {
      if (this.viewBreakpoint && this.viewBreakpoint.navbar)
        return this.viewBreakpoint.navbar.position
      else return 'normal'
    },
    /**
     * @return {Object} currentRoute
     * @return {String} currentRoute.name
     * @return {Boolean} currentRoute.isBack
     * @return {Boolean} currentRoute.visibleSubmenu
     * @return {Object} currentRoute.routeBack
     * @return {Object} currentRoute.routeTo
     * @return {Boolean} currentRoute.isChat
     * @return {Boolean} currentRoute.showResolveTicket
     */
    currentRoute() {
      const route = this.$route

      if (route.name === 'default')
        return {
          name: 'Workspace',
          isBack: false,
          totalTickets: {
            show: true,
            total: this.total_assigned_tickets,
          },
        }
      else {
        if (this.$route.meta.is_workspace || this.$route.meta.is_monitor) {
          if (route.query.main === 'chat') {
            return {
              name: this.ticketSelected?.client.names,
              isBack: true,
              routeBack: { name: 'default' },
              visibleSubmenu: this.visibleSubmenu,
              routeTo: this.visibleSubmenu
                ? null
                : {
                    name: 'drawer-ticket',
                    params: { drawerId: 'client' },
                    query: {
                      mailbox: route.query.mailbox,
                      status: route.query.status,
                      main: 'chat,drawer',
                    },
                  },
              showResolveTicket: this.ticketSelected?.status === 'active', // mostrara el boton para resolver ticket
              isChat: true,
              showFavorite: this.ticketSelected?.settings.starred,
            }
          } else {
            const newRoute = {
              isBack: true,
              routeBack: {
                name: this.$route.meta.is_workspace
                  ? 'drawer-ticket'
                  : 'drawer-remote-ticket',
                params: { drawerId: 'client' },
                query: { main: 'chat' },
              },
            }

            switch (route.params.drawerId) {
              case 'client':
                newRoute.name = 'Perfil del cliente'
                break
              case 'old-tickets':
                newRoute.name = 'Tickets anteriores'
                break
              case 'custom-fields':
                newRoute.name = 'Campos customizados'
                break
              case 'files':
                newRoute.name = 'Archivos'
                break
              case 'products':
                newRoute.name = 'Productos'
                break
              case 'comments':
                newRoute.name = 'Comentarios'
                break
            }

            return newRoute
          }
        } else return { empty: true }
      }
    },
    /**
     * Devuelve el tiempo que ha pasado en un formato de texto
     * @return {String} tiempo formateado
     */
    timerFormatted() {
      if (this.timerSeconds < 60) return `${this.timerSeconds} segundos`
      else if (this.timerSeconds < 3600)
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toMinutes'
        )} minutos`
      else
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toHours'
        )} horas`
    },
  },
  methods: {
    ...mapActions(['setTicketMailbox', 'saveStatusUser']),

    /**
     * Selecciona el menu
     * @param {Object} route
     * @param {String} route.name
     * @param {String} route.params
     * @param {String} route.query
     */
    handleSelectTitle(route) {
      if (!route) return
      else this.$router.push(route)
    },
    /**
     * Muestra el perfil del usuario
     */
    handleShowDrawerProfile() {
      this.drawerProfile.visible = true
    },
    /**
     * Resuelve el ticket actual
     * @param {*} args
     * @param {String} args.comment
     */
    resolveTicket({ comment }) {
      const idTicket = this.ticketSelected._id
      const mailbox = this.mailboxes.find((box) => box.end)
      this.setTicketMailbox({
        mailbox,
        idTicket,
        end: true,
        comment,
      })
    },

    /** Evalúa el estado y si activar el modal de otro estado tras cargar el sidebar */
    async counter() {
      let statusObj = JSON.parse(localStorage.getItem('status'))
      const statusProfile = this.profile_states[statusObj.status]
      if (
        statusProfile &&
        statusProfile.action_when_select.includes('start-timer')
      ) {
        /** Setea contador de la supervision del estado y menu de la fila seleccionada */
        let diff_ms = moment(new Date()).diff(statusObj.startedIn)
        this.startTimer(Math.round(diff_ms / 1000))
      }
    },
    /**
     * @param {String} status Modal que se mostrará según su estado
     */
    async handleSetStatus(status, actions) {
      const startedIn = new Date()
      this.stopTimer()
      if (actions.includes('start-timer')) this.startTimer()
      if (actions.includes('save')) {
        const response = await this.saveStatusUser({
          status,
          startedIn,
          cleanTicketSelected: true,
        })
        if (response.success && actions.includes('show-notification-on-save')) {
          this.$notification.success({
            message: this.profile_states[status].notification_title,
            description: this.profile_states[status].notification_description,
            duration: 5,
          })
        }
      }
      this.idStatus = status

      // emite que se ha hecho visible al modal de estado
      this.$emit('onShowModalState')
    },

    /**
     * Inicia la cuenta del contador
     * @param {Number} initialTime - tiempo desde que comenzara a contar
     */
    startTimer(initialTime = 0) {
      this.timerSeconds = initialTime
      this.timer = setInterval(() => {
        this.timerSeconds++
      }, 1000)
    },

    /**
     * Limpia la data si no esta logueado
     */
    cleanData() {
      this.timer = null
      this.timerSeconds = 0
    },
    /**
     * Finaliza la cuenta del contador
     */
    stopTimer() {
      clearInterval(this.timer)
      this.timerSeconds = 0
    },
    /**
     * Reconecta el estado
     */
    handleReconnect() {
      this.saveStatusUser({ status: 'connected' })
      this.stopTimer()

      const divElementStatus = document.getElementById(this.idStatus)
      if (divElementStatus) {
        divElementStatus.classList.remove('ant-menu-item-selected')
      }

      const divElement = document.getElementById('statuses')
      if (divElement) {
        divElement.classList.remove('ant-menu-submenu-selected')
      }

      const divElementLogout = document.getElementById('logout')
      if (divElementLogout) {
        divElementLogout.classList.remove('ant-menu-item-selected')
      }
    },
    /**
     * Setea el estado del perfil del usuario al modal
     * @param {Object} profile
     * @param {String} profile.status
     */
    handleSetModalState(profile) {
      if (!profile) return
      const currentStatus = this.profile_states[profile.status]
      if (!currentStatus) return

      if (currentStatus.action_when_select.includes('show-modal')) {
        this.modalState.visible = true
        this.modalState.type = profile.status
      } else {
        this.modalState.visible = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$height-navbar: 56px
$padding-right: 12px
$padding-left: 20px
.navbar
  background-color: $gray_dark_900
  color: $white_000
  padding: 0px $padding-right 0px $padding-left
  height: $height-navbar
  z-index: 1000
  &__title
    color: $white_000
    &--underline
      text-decoration: underline
  &__item
    height: 100%
    color: $white_000
    flex-grow: 1
    @include flex(column, center, flex-start)
  &--fixed
    position: fixed
    width: 100%
  &__container-icon
    height: $height-navbar
    margin-left: -$padding-left
    width: 52px
    +flex(row, center, center)
  &__icon
    font-size: 24px
    color: $white_000
    &--star
      color: $yellow_6
  &__button
    min-width: 120px
    @include xs
      min-width: 112px
    @include sm
      min-width: 120px
    @include md
      min-width: 176px
</style>
