<template>
  <a-form :form="form" layout="vertical">
    <!-- Campo de texto para mostrar -->
    <a-form-item
      label="Texto / Pregunta"
      class="section-form-item section-form-item--message"
    >
      <p class="help text--left mrg-bottom-8">
        Recuerda que será un mensaje de tipo texto para tus usuarios.
      </p>
      <counter-text
        :maxLength="validations.maxLengthMessage"
        :text="
          form.getFieldValue('content') ? form.getFieldValue('content') : ''
        "
      >
        <a-mentions
          v-decorator="[
            'content',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          ref="message_form_text"
          rows="3"
          placeholder="Escribe aquí..."
          @input="onInput"
          @change="setIsSavedItem(false)"
          :maxLength="validations.maxLengthMessage"
          class="text--left"
          :prefix="PREFIX_VARS"
          :notFoundContent="notFoundVars"
          :disabled="onlyRead"
          :filterOption="filterOption"
          placement="bottom"
        >
          <a-mentions-option
            v-for="simpleVar in simpleVars"
            :key="simpleVar._id"
            :value="simpleVar.name"
          >
            {{ simpleVar.name }}</a-mentions-option
          >
        </a-mentions>
      </counter-text>
      <menu-options-content
        @onChangeWait="handleChangeWait"
        @onChangeEmoji="handleSetEmoji"
        :disabled-emoji="onlyRead"
        @onOpenVars="handleOpenVars"
      />
    </a-form-item>
    <!-- Lista de variables -->
    <a-form-item
      label="Guarda la respuesta en una variable"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'varToSave',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
            ],
          },
        ]"
        placeholder="Selecciona"
        @change="setIsSavedItem(false)"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="simpleVar in simpleVarsForSave"
          :key="simpleVar._id"
          :disabled="
            (simpleVar.isUsed && simpleVar._id !== varToSave._id) ||
            simpleVar.deleted
          "
          :title="formatNameSimpleVar(simpleVar)"
        >
          {{ simpleVar.name }}
          <i v-if="simpleVar.deleted">( Variable eliminada )</i>
          <i v-else-if="simpleVar.isUsed && simpleVar._id !== varToSave._id"
            >( Usado en otro nodo )</i
          >
        </a-select-option>
      </a-select>
      <p slot="extra" class="text--left regular-14 mrg-bottom-0">
        Puedes crear tus propias variables.
        <router-link :to="{ name: 'manage-chatbots-vars' }" target="_blank"
          >Click aquí.</router-link
        >
      </p>
    </a-form-item>
    <!-- Lista de acciones -->
    <a-form-item
      label="Selecciona una de las acciones"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'action',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
            ],
          },
        ]"
        placeholder="Selecciona"
        @change="handleChangeAction"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="actionToNode in actionsToNodeSelected"
          :key="actionToNode.value"
          :disabled="
            actionToNode.actionToSave && actionToNode.actionToSave.disabled
          "
        >
          {{ actionToNode.title }}
          <i
            v-if="
              actionToNode.value !== action &&
              actionToNode.actionToSave &&
              actionToNode.actionToSave.disabled
            "
            >( No hay variables disponibles )</i
          >
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      v-if="action === 'transfer'"
      label="Selecciona una cola"
      class="section-form-item"
    >
      <a-select
        v-decorator="[
          'lineId',
          {
            rules: [{ required: true, message: 'Completa el campo' }],
          },
        ]"
        placeholder="Selecciona"
        @change="handleChangeLines"
        :disabled="onlyRead"
      >
        <a-select-option
          v-for="line in dataSourceLines"
          :key="line.key"
          :value="line.key"
        >
          {{ line.title }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <template v-if="description">
      <p class="body-2 text--left">
        {{ description }}
      </p>
    </template>
  </a-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CounterText from '@/app/shared/components/molecules/CounterText'
import MenuOptionsContent from '@/app/chatbots/components/molecules/MenuOptionsContent'
import transformActionNode from '@/app/shared/mixins/transform'
import actionsToNodeSelected from '@/app/chatbots/mixins/actionsToNodes'
import verifyAddDefaultNode from '@/app/chatbots/mixins/actionsToNodes'
import filterSimpleVarsBySave from '@/app/chatbots/mixins/actionsToNodes'
import { PREFIX_VARS } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'CatcherForm',
  components: {
    CounterText,
    MenuOptionsContent,
  },
  mixins: [
    transformActionNode,
    actionsToNodeSelected,
    verifyAddDefaultNode,
    filterSimpleVarsBySave,
  ],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    nodeId: {
      type: String,
      required: false,
    },
    parentNodeId: {
      type: String,
      required: false,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    PREFIX_VARS,
    waitAnswer: false,
    activeMessageTransfer: true,
    action: null,
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
    actionNodeId: null,
    actionNodeType: null,
    isChangeAction: false,
    varToSave: {
      name: null,
      _id: null,
    }, // solo se usará para comprobar si la variable, existe en el array de variables
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'catcher-form-chatbot' })
  },
  computed: {
    ...mapGetters([
      'nodes',
      'simple_chatbots_vars',
      'company',
      'selectedNodeId',
      'validations_chatbot',
    ]),

    /**
     * @returns {Boolean} - verifica si el agregar mensaje deberia estra deshabilitado
     */
    disabledAddMessage() {
      const parentNode = this.nodes.find(
        (node) => node._id === this.parentNodeId
      )
      if (
        parentNode !== undefined &&
        parentNode.drawer?.question?.type === 'message'
      ) {
        if (parentNode.drawer.action.wait === false && !this.waitAnswer)
          return true
        else return false
      }
      return false
    },
    /**
     * @returns {String} - descripcion de la accion seleccionada
     */
    description() {
      if (!this.action) return null
      const actionSelected = this.actionsToNodeSelected.find(
        (actionToNodeSelected) => actionToNodeSelected.action === this.action
      )
      return actionSelected.description || null
    },
    /**
     * @returns {Boolean} - verifica si existe un nodo de tipo catcher
     */
    existCatcher() {
      return this.nodes.some(
        (node) =>
          node.drawer &&
          node.drawer.question &&
          node.drawer.question.type === 'catcher' &&
          this.selectedNodeId !== node._id
      )
    },
    /**
     * Filtra las variables simples de acuerdo al id del nodo
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVars() {
      return !this.existCatcher
        ? []
        : this.filterSimpleVarsBySave({ nodeId: this.nodeId })
    },
    /**
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVarsForSave() {
      const existVarInSimple = this.varToSave._id
        ? this.simple_chatbots_vars.some(
            (simpleVar) => simpleVar._id === this.varToSave._id
          )
        : true
      if (existVarInSimple)
        return this.formatSimpleVars({
          nodeId: this.nodeId,
          simpleVars: this.simple_chatbots_vars,
        })
      else {
        const simpleVars = [...this.simple_chatbots_vars]
        simpleVars.push(this.varToSave)
        return this.formatSimpleVars({ nodeId: this.nodeId, simpleVars })
      }
    },
    /**
     * @returns {String} - mensaje que aparecerá cuando no haya datos
     */
    notFoundVars() {
      return !this.existCatcher
        ? 'Aún no haz agregado "Capturar variable"'
        : 'No se han encontrado variables'
    },
    /**
     * Validaciones del nodo de acuerdo al soporte
     * @return {Object} validations
     * @return {Number} validations.maxLengthMessage
     */
    validations() {
      const node = 'catcher'
      return this.validations_chatbot[node]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions(['updateNodeCatcher', 'getSimpleChatbotsVars']),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.content
     * @param {String} args.errorMessage
     * @param {Object} args.action
     * @param {String} args.action.type
     * @param {String} args.action.withMessage
     * @param {String} args.action.wait
     * @param {String} args.action.lineId
     * @param {String} args.action.message
     * @param {String} args.action.nodeType
     * @param {Object} args.varToSave
     * @param {String} args.varToSave.name
     * @param {String} args.varToSave._id
     */
    setValues({ content, action, errorMessage, varToSave }) {
      const actionFormatted = this.transformActionNode({
        type: action.type,
        nodeType: action.nodeType,
      })
      this.action = actionFormatted

      this.$nextTick(() => {
        this.form.setFieldsValue({
          content,
          errorMessage,
          action: actionFormatted,
          message: action.message,
          lineId: action.lineId,
          wait: action.wait,
          varToSave: varToSave._id,
        })
      })

      this.varToSave = varToSave
      this.actionNodeId = action?.nodeId
      this.actionNodeType = action?.nodeType
      this.waitAnswer = action.wait
      this.activeMessageTransfer =
        action.type === 'transfer' ? action.withMessage : false
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Cambia de accion
     * @param {String} value - transfer, contine_message, continue_options, none, rollback
     */
    handleChangeAction(value) {
      this.action = value
      this.setIsSavedItem(false)
      this.isChangeAction = true
    },
    /**
     * Cambia el valor de wait
     * @param {Boolean} checked - espera respuesta o no
     */
    handleChangeWait(checked) {
      this.waitAnswer = checked
    },
    /**
     * Escucha el cambio de colas
     */
    handleChangeLines() {
      this.setIsSavedItem(false)
    },
    /**
     * Quita el check del 'Esperar respuesta'
     */
    uncheckWaitAnswer() {
      this.form.setFieldsValue({
        wait: false,
      })
      this.waitAnswer = false
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        // si la accion del nodo se cambio y tiene un hijo
        if (this.isChangeAction && this.hasChild) {
          const textSure = this.transformWordGender(
            'seguro',
            this.profile.gender
          )
          let self = this
          this.$confirm({
            title: `¿Estás ${textSure} de cambiar la acción del mensaje`,
            content:
              'Recuerda que al cambiar la acción, los mensajes siguientes serán eliminados.',
            okText: 'Guardar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
              self.handleUpdateNodeCatcher(values)
              self.setIsSavedItem(true)
              self.isChangeAction = false
            },
          })
          return
        }
        this.handleUpdateNodeCatcher(values)
        this.setIsSavedItem(true)
        this.isChangeAction = false
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.content - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     * @param {Boolean} values.varToSave
     */
    async handleUpdateNodeCatcher(values) {
      const settings = {
        drawer: {
          action: {
            nodeId: this.actionNodeId,
            nodeType: this.actionNodeType,
          },
        },
      }

      let message = {
        message: values.content,
        wait: this.waitAnswer,
        type_action: values.action,
        nodeId_action: this.actionNodeId,
        lineId_action: values.lineId ? values.lineId : null,
        message_action: values.message ? values.message : null,
        withMessage: this.activeMessageTransfer,
        chatbotVarId: values.varToSave,
      }

      const { nodeType, type_action, nodeId_action } =
        await this.verifyAddDefaultNode({
          action: values.action,
          nodeIdAction: settings.drawer.action?.nodeId,
          nodeTypeAction: settings.drawer.action.nodeType,
        })
      if (type_action) message.type_action = type_action
      if (nodeType) message.nodeType = nodeType
      if (nodeId_action) message.nodeId_action = nodeId_action

      const responseMessage = await this.updateNodeCatcher({
        chatbotId: this.chatbotId,
        nodeId: this.nodeId,
        nodeCatcher: message,
      })
      if (responseMessage._id) {
        this.$emit('onChangeSettings', responseMessage)
        this.$message.success('Se actualizó correctamente')
      } else {
        if (responseMessage.result.includes('down'))
          this.$message.error(
            'Este mensaje tiene 2 mensajes seguidos sin espera de respuesta 😪'
          )
        else if (responseMessage.result.includes('up'))
          this.$message.error(
            'Existen 2 mensajes seguidos arriba de este nodo 😪'
          )
        else
          this.$message.error(responseMessage.details || 'Ocurrió un error 😪')
      }
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      let content = this.form.getFieldValue('content')
      content = content + emoji
      this.form.setFieldsValue({
        content,
      })
      this.setIsSavedItem(false)
    },
    /**
     * Filtra el valor de una opcion
     * @param {String} input
     * @param {Object} option
     */
    filterOption(input, option) {
      const optionFound = option.children[0].text.toLowerCase()
      const optionTrim = optionFound.trim()

      if (input.toLowerCase().includes(optionTrim)) {
        return optionFound
      } else {
        return optionFound.indexOf(input.toLowerCase()) >= 0
          ? optionFound
          : null
      }
    },
    /**
     * Formatea el nombre de una variable
     * @param {Object} simpleVar
     * @param {Boolean} simpleVar.delete
     * @param {Boolean} simpleVar.isUsed
     * @param {String} simpleVar.name
     */
    formatNameSimpleVar(simpleVar) {
      let extra = ''
      if (simpleVar.delete) extra = '( Variable eliminada )'
      else if (simpleVar.isUsed) extra = '( Usada en otro nodo )'

      return `${simpleVar.name} ${extra}`
    },
    /**
     * Muestra el listado de variables
     * @param {String} field - nombre de campo en el formulario
     */
    handleOpenVars() {
      let text = this.form.getFieldValue('content')
      const textArray = text.split('')
      // si el ultimo valor no es el prefijo de variables, seteará el prefijo al texto
      if (textArray.pop() !== this.PREFIX_VARS) {
        text = text + this.PREFIX_VARS
        this.form.setFieldsValue({
          content: text,
        })
      }

      this.$refs.message_form_text.focus()
      // Importante no borrar, porque si no, no renderizara el listado de variables
      setTimeout(() => {
        this.simulateKey(this.PREFIX_VARS, 'up')
      }, 0)
    },
    /**
     * Simula el #
     * @param {Number} keyCode The keyCode of the key to simulate
     * @param {String} type (optional) The type of event : down, up or press. The default is down
     * @param {Object} modifiers (optional) An object which contains modifiers keys { ctrlKey: true, altKey: false, ...}
     */
    simulateKey(keyCode, type, modifiers) {
      const mentions = document.getElementById('catcher-form-chatbot_content')
      var evtName = typeof type === 'string' ? 'key' + type : 'keydown'
      var modifier = typeof modifiers === 'object' ? modifier : {}
      var event = document.createEvent('HTMLEvents')
      event.initEvent(evtName, true, false)
      event.key = keyCode
      for (var i in modifiers) {
        event[i] = modifiers[i]
      }
      mentions.dispatchEvent(event)
    },
  },
}
</script>

<style lang="sass" scoped>
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 17px
</style>
