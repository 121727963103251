<template>
  <a-modal
    :visible="visible"
    :bodyStyle="bodyStyle"
    width="52em"
    centered
    @cancel="$emit('onClose')"
    :footer="null"
  >
    <a-row type="flex" justify="space-around">
      <h3 class="semibold-30 text--center modal__title">
        Tipo de campaña que deseas crear
      </h3>
      <card-strategy-channel
        v-for="channelSupported in CHANNELS_SUPPORTED"
        :key="channelSupported.value"
        :channel="channelSupported.channel"
        :title="channelSupported.title"
        :description="channelSupported.description"
        @click.native="handleGoToAdd(channelSupported.value)"
      />
      <p class="regular-14 modal__extra-info">
        <a-icon type="info-circle" />
        Solo las campañas de WhatsApp permitirán una respuesta por parte del
        cliente. Y se creará <span class="modal__extra--empty"></span> un
        ticket.
      </p>
    </a-row>
    <basic-footer
      :showOk="false"
      cancelText="Cerrar"
      @onCancel="$emit('onClose')"
      justify="justify-content--center"
    />
  </a-modal>
</template>

<script>
import { CHANNELS_SUPPORTED } from '@/app/strategies/utils/utilStrategies'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CardStrategyChannel from '@/app/strategies/components/molecules/CardStrategyChannel'

export default {
  name: 'ModalPreview',
  components: {
    BasicFooter,
    CardStrategyChannel,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    CHANNELS_SUPPORTED,
    bodyStyle: {
      padding: '60px 60px 80px',
    },
  }),
  computed: {},
  methods: {
    /**
     * Va hacia la vista de creacion
     */
    handleGoToAdd(channel) {
      this.$router.push({ name: 'campaigns', query: { create: true, channel } })
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__divider
  margin: 12px 0px
.modal
  &__title
    margin-bottom: 40px
    color: $gray_9
  &__extra
    &-info
      margin: 12em 1em 0em 1em
      color: $gray_7
    &--empty
      width: 15px
      display: inline-flex
</style>
