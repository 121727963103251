import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_EVENT_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },
  /**
   * Setea el último evento como ticket o menú seleccionado
   * @param {*} state
   * @param {String} lastEvent
   */
  SET_LAST_EVENT(state, lastEvent) {
    state.lastEvent = lastEvent
  },
  /**
   * lista de supervision
   * @param {*} state
   * @param {Array} supervision
   */
  SET_LIST_SUPERVISION(state, supervision) {
    state.supervision = supervision
  },
  /**
   * Setea datos de Supervision de un agente
   * @param {*} state
   * @param {Object} supervisionSimply
   */
  SET_SIMPLY_SUPERVISION(state, supervisionSimply) {
    state.supervisionSimply = supervisionSimply
  },
  /**
   * Setear menu del agente en lista de supervision
   * @param {*}       state
   * @param {Object}  payload
   * @param {String}  payload.userId
   * @param {String}  payload.menuSelected
   * @param {Date}    payload.startedInMenu_at
   */
  SET_MENU_AGENT_SUPERVISION(
    state,
    { userId, menuSelected, startedInMenu_at }
  ) {
    state.supervision.forEach((item) => {
      if (item.userId === userId) {
        item.menu = menuSelected
        item.startedInMenu_at = !startedInMenu_at
          ? null
          : startedInMenu_at.toISOString()
      }
    })
  },
  /**
   * Setear estado de agente en lista de supervision
   * @param {*}       state
   * @param {Object}  payload
   * @param {String}  payload.userId
   * @param {String}  payload.status
   * @param {Boolean} payload.session
   * @param {Date}    payload.startedInMenu_at
   */
  SET_STATUS_AGENT_SUPERVISION(state, payload) {
    const sup = state.supervision.find((sup) => sup.userId === payload.userId)
    if (!sup) return
    sup.user.status = payload.status
    sup.user.session = payload.session
    sup.startedInMenu_at = !payload.startedInMenu_at
      ? null
      : payload.startedInMenu_at.toISOString()
  },
  /**
   * Setea el Id del ticket remoto selecionado por un agente
   * @param {vuex}    state
   * @param {String}  ticket Id del ticket
   */
  SET_TICKET_REMOTE_SELECTED(state, ticketId) {
    state.ticketIdRemoteSelected = ticketId
  },
  /**
   * Setea el Id del buzón remoto selecionado por un agente
   * @param {vuex}    state
   * @param {String}  mailboxId Id del buzón
   */
  SET_MAILBOX_REMOTE_SELECTED(state, mailboxId) {
    state.mailboxIdRemoteSelected = mailboxId
  },
  /**
   * Setea la cantidad de no leídos de un ticket en supervision
   * @param {vuex} state
   * @param {Object} payload
   * @param {String} payload.userId
   * @param {Boolean} payload.ticketsPending
   */
  SET_TICKETS_PENDINGS(state, payload) {
    state.supervision.forEach((item) => {
      if (item.userId === payload.userId) {
        item.ticketsPending = payload.ticketsPending
      }
    })
  },
  /**
   * Setea la cantidad de no leídos de un ticket en supervision
   * @param {vuex} state
   * @param {Object} payload
   * @param {String} payload.userId
   * @param {Boolean} payload.ticketsAssigned
   */
  SET_SUPERVISION_ACTIVE_TICKETS(state, payload) {
    const user = state.supervision.find(
      (user) => user.userId === payload.userId
    )
    if (!user) return
    user.ticketsAssigned = payload.ticketsAssigned
  },
  /**
   * Setea el estado del escribiendo del agente remoto
   * @param {vuex}    state
   * @param {Boolean} istyping Escribiendo?
   */
  SET_TYPING_REMOTE(state, istyping) {
    state.typingRemote = istyping
  },
  /**
   * Setea el estado del escribiendo del agente remoto
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.userId Id del usuario del agente
   * @param {Boolean} args.istyping Escribiendo?
   */
  SET_TYPING_ACCOUNTS_REMOTE(state, { userId, istyping }) {
    const sup = state.supervision.find((sup) => sup.userId === userId)
    if (!sup) return
    sup.isTyping = istyping
  },
  /**
   *
   * @param {*}       state
   * @param {Object}  args
   * @param {String}  args.userId Id de usuario del agente
   * @param {String}  args.event Evento o canal donde se encuentra el agente remoto
   * @param {Object}  args.client Objeto del cliente
   * @param {String}  args.client.clientId Id del cliente
   * @param {String}  args.client.names Nombres del cliente, o su número si no cuenta con nombre
   * @param {Date}    args.startedInEvent_at Fecha en la que se inició el evento
   */
  SET_EVENT_REMOTE(state, { userId, event, client, startedInEvent_at }) {
    const supervisionAgent = state.supervision.find(
      (sup) => sup.userId === userId
    )
    if (!supervisionAgent) return
    supervisionAgent.event = event
    supervisionAgent.client = client
    if (startedInEvent_at)
      supervisionAgent.startedInEvent_at = startedInEvent_at
  },
  /**
   * Setea el tiempo transcurrido en supervision
   * @param {*}       state
   * @param {Object}  args
   * @param {String}  args.idSupervision Id de supervisión del agente
   * @param {Number}  args.startTimeMenu Tiempo transcurrido en el menú o estado seleccionado
   * @param {Number}  args.startTimeEvent Tiempo transcurrido en el evento seleccionado
   */
  SET_TIME_SUPERVISION(
    state,
    { idSupervision, startTimeMenu, startTimeEvent }
  ) {
    const sup = state.supervision.find((sup) => sup._id === idSupervision)
    if (!sup) return
    sup.startTimeMenu = startTimeMenu
    sup.startTimeEvent = startTimeEvent
  },
  /**
   * Setea el estado del usuario y si la sesion aun es válida
   * @param {*}       state
   * @param {Object}  args
   * @param {String}  args.userId Id del usuario del agente
   * @param {Boolean} args.session Tiene una sesion valida?
   * @param {String}  args.status Estado del agente
   */
  SET_SESSION_SUPERVISION(state, { userId, session, status }) {
    const sup = state.supervision.find((sup) => sup.userId === userId)
    if (!sup) return
    sup.user.status = status
    sup.user.session = session
  },
  /**
   * Actualiza el valor de la variable para que se ejecute el observador que llama al método de llenar la tabla de Supervision
   * @param {*}       state
   * @param {Number} reloadTableSupervision
   */
  SET_RELOAD_TABLE_SUPERVISIONS(state, reloadTableSupervision) {
    state.reloadTableSupervision = reloadTableSupervision
  },
}

export default mutations
