<template>
  <a-row :gutter="[12]" class="layout">
    <a-col :span="4" class="layout-col">
      <div class="layout-col__content">
        <a-menu
          class="layout__menu"
          :defaultSelectedKeys="[localMenuSelected.key]"
          :inlineIndent="12"
        >
          <a-menu-item v-for="item in menuItems" :key="item.key">
            <router-link :to="item.route" class="text--left">
              <!--Aun falta checkar bien la funcionalidad de este componente-->
              <ever-icon
                v-if="item.icon.type === 'evericon'"
                :type="item.icon.name"
              />
              <a-icon v-else :type="item.icon" />
              {{ item.title }}
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
    </a-col>
    <a-col :span="20" class="layout-col">
      <div class="layout-col__content">
        <slot name="content"></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import EverIcon from '@/app/shared/components/icons/EverIcon'

export default {
  name: 'LayoutSider',
  props: {
    menuItems: {
      type: Array,
      default: () => [
        {
          title: 'First item',
          route: { name: 'first' },
          icon: { type: 'ant', name: 'pie-chart' },
          key: 'first',
        },
        {
          title: 'Second item',
          route: { name: 'second' },
          icon: 'pie-chart',
          key: 'second',
        },
      ],
    },
    defaultSelectedKey: {
      type: String,
      default: 'first',
    },
  },
  components: {
    EverIcon,
  },
  computed: {
    /**
     * Menu local seleccionado
     * @returns {Object} {name, route, key, title}
     */
    localMenuSelected() {
      const route = this.$route
      const menu = this.menuItems.find((menu) => menu.route.name === route.name)
      if (menu !== undefined) return menu
      else {
        this.handleGoToMenu(this.menuItems[0].route)
        return this.menuItems[0]
      }
    },
  },
  methods: {
    /**
     * LLeva hacia la ruta especificada
     * @param {Object} route
     * @param {String} route.name
     */
    handleGoToMenu(route) {
      this.$router.push(route).catch((e) => e)
    },
  },
}
</script>

<style lang="sass" scoped>
.layout
  height: 100vh
  padding: $padding-heigth-inner
  margin-right: 0px

.layout-col
  height: 100%
.layout-col__content
  height: 100%
  border: 1px solid $gray-5
  border-radius: 4px
  background-color: $white_000
.layout__menu
  margin-top: 20px
</style>
