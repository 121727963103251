<template>
  <a-form :form="form" class="picker" @submit.prevent="handleSubmit">
    <div
      class="display-flex align--center justify-content--center range__content"
    >
      <a-form-item :validate-status="validateStatusStart" class="picker__field">
        <a-time-picker
          v-decorator="[
            'start',
            {
              rules: [{ validator: hasValueStart }],
            },
          ]"
          style="width: 10em"
          placeholder="Hora de inicio"
          @openChange="handleStartOpenChange"
          format="HH:mm"
          @change="handleChangePicker($event, 'start')"
          :disabled-hours="() => filterDisabledHours(disabledHours)"
          :disabled-minutes="
            () => filterDisabledMinutes(disabledMinutes, 'start')
          "
          :allowClear="false"
        />
      </a-form-item>
      <a-icon type="minus" class="range__icon" />
      <a-form-item :validate-status="validateStatusEnd" class="picker__field">
        <a-time-picker
          v-decorator="[
            'end',
            {
              rules: [{ validator: hasValueEnd }],
            },
          ]"
          style="width: 10em"
          placeholder="Hora de fin"
          format="HH:mm"
          @openChange="handleEndOpenChange"
          @change="handleChangePicker($event, 'end')"
          :disabled-hours="() => filterDisabledHours(disabledHours)"
          :disabled-minutes="
            () => filterDisabledMinutes(disabledMinutes, 'end')
          "
          :allowClear="false"
        />
      </a-form-item>
      <a-icon
        v-if="showIconRemove"
        type="delete"
        @click="$emit('onRemove', idPicker)"
        class="picker__icon--remove"
      />
    </div>
  </a-form>
</template>

<script>
import moment from 'moment'
export default {
  name: 'RangePicker',
  props: {
    idPicker: { type: String, default: null },
    orderDay: { type: Number, default: 0 },
    showIconRemove: { type: Boolean, default: true },
    hourStart: { type: [String, Object], default: null },
    hourEnd: { type: [String, Object], default: null },
    disabledHours: { type: Array, default: () => [] },
    disabledMinutes: { type: Array, default: () => [] },
  },
  data: () => ({
    endOpen: false,
    moment,
    validateStatusEnd: '',
    validateStatusStart: '',
    localRange: null,
    showErrors: true,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-range-pickers' })
  },
  mounted() {
    this.handleSetStart()
    this.handleSetEnd()
  },
  methods: {
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    /**
     * Esuchca el cambio de tangos
     * @param {Object} values - valor de fecha
     * @param {String} type - start | end
     */
    handleChangePicker(values, type) {
      const range = { start: this.hourStart, end: this.hourEnd }
      if (type === 'start') range.start = values
      if (type === 'end') range.end = values
      this.localRange = range
      this.$emit('onChangePicker', this.idPicker, range, this.orderDay, type)
    },
    handleSetStart() {
      this.form.setFieldsValue({ start: this.hourStart })
    },
    handleSetEnd() {
      this.form.setFieldsValue({ end: this.hourEnd })
    },
    /**
     * Submit de todos los rangos
     * @param {Boolean} [showErrors=false]
     */
    handleSubmit(showErrors = false) {
      this.showErrors = showErrors
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
        }
      })
      this.showErrors = true
    },
    /**
     * Si tiene un valor inicial
     * @param {*} rule - esto es un parámetro de ant
     * @param {String} value
     */
    hasValueStart(rule, value) {
      if (!value) {
        this.validateStatusStart = 'error'
        this.showErrors && this.$message.error('Por favor completa el campo')
      } else if (this.localRange && this.localRange.end) {
        setTimeout(() => {
          this.validateEnd(this.localRange.end)
        }, 200)
        this.validateStatusStart = ''
      } else this.validateStatusStart = ''
    },
    /**
     * Si tiene un valor final
     * @param {*} rule - esto es un parámetro de ant
     * @param {String} value
     */
    hasValueEnd(rule, value) {
      if (!value) {
        this.validateStatusEnd = 'error'
        this.showErrors && this.$message.error('Por favor completa el campo')
      } else this.validateStatusEnd = ''

      this.validateEnd(value)
    },
    /**
     * Valida el valor final del rango
     * @param {String} value
     */
    validateEnd(value) {
      const endIsAfter =
        this.localRange && moment(value).isAfter(this.localRange.start)
      if (endIsAfter) this.validateStatusEnd = ''
      else {
        this.validateStatusEnd = 'error'
        this.showErrors &&
          this.$message.error(
            'Por favor coloca un horario después del horario inicial'
          )
      }
    },
    /**
     * Valida el valor inicial del rango
     * @param {String} value
     */
    validateStart(value) {
      const startIsBefore =
        this.localRange && moment(value).isBefore(this.localRange.end)
      if (!startIsBefore) {
        this.validateStatusEnd = 'error'
        this.showErrors &&
          this.$message.error(
            'Por favor coloca un horario antes del horario final'
          )
      }
    },
    filterDisabledHours(disabledHours) {
      const ownHours = this.range(
        moment(this.hourStart).hours(),
        moment(this.hourEnd).hours()
      )
      return disabledHours.filter((hour) => !ownHours.includes(hour))
    },
    filterDisabledMinutes(disabledMinutes, type) {
      const range = {
        start: {
          hour: moment(this.hourStart).hours(),
          minutes: moment(this.hourStart).minutes(),
        },
        end: {
          hour: moment(this.hourEnd).hours(),
          minutes: moment(this.hourEnd).minutes(),
        },
      }
      let ownMinutes = []
      if (range.start.hour < range.end.hour) {
        // si es start tomara del min q tenga hasta el min 59
        if (type === 'start')
          ownMinutes.push(...this.range(range.start.minutes, 59))
        else if (type === 'end')
          ownMinutes.push(...this.range(0, range.end.minutes))
      } else {
        ownMinutes.push(...this.range(range.start.minutes, range.end.minutes))
      }

      return disabledMinutes.filter((minute) => !ownMinutes.includes(minute))
    },
    range(start, end) {
      const result = []
      for (let i = start; i <= end; i++) {
        result.push(i)
      }
      return result
    },
  },
}
</script>

<style lang="sass" scoped>
.range__icon
  font-size: 8px
  margin: 8px
.picker__field
  width: 10em
  margin-bottom: 0px
.range__content
  margin-bottom: 12px
.picker__icon--remove
  color: $red_6
  margin-left: 12px
</style>
