const initialState = () => ({
  company_strategies: [],
  paginate_company_strategies: {},
  statuses_strategies: {
    uploading: {
      color: '',
      value: 'Importando datos',
      progress_color: '',
    },
    uploaded: {
      color: 'orange',
      value: 'Importado',
      progress_color: '',
    },
    play: {
      color: 'blue',
      value: 'Enviando...',
      progress_color: '',
    },
    pause: {
      color: 'magenta',
      value: 'En pausa',
      progress_color: '#EB2F96',
    },
    stop: {
      color: 'cyan',
      value: 'Detenido',
      progress_color: '#13C2C2',
    },
    finished: {
      color: 'green',
      value: 'Finalizado',
      progress_color: '#52C41A',
    },
  },
  simple_templates: [],
  selected_strategy: {},
})

export default initialState
