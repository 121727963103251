<template>
  <div class="section-products__current-item">
    <div class="section-products__current-item-image">
      <SocialIcon
        size="40"
        :type="pack.image ? pack.image.toLowerCase() : ''"
      />
    </div>
    <div class="section-products__current-item-info">
      <div class="section-products__current-item-info-title">
        <span class="heading-h7"
          >{{ pack.name }}
          <a-tooltip v-if="pack.tooltip" placement="right">
            <template slot="title">{{ pack.tooltip.text }} </template>
            <a-icon
              type="info-circle"
              class="template__icon"
              :style="{ fontSize: '14px' }"
            /> </a-tooltip
        ></span>
      </div>
      <div class="section-products__current-item-left">
        <span class="heading-h3">{{ pack.left }}</span>
      </div>
      <div v-if="showCountries">
        <template
          v-for="(country, index) in Object.keys(smsPack.countries)
            .map((elem) => smsPack.countries[elem])
            .splice(0, 2)"
        >
          <span class="body-2" :key="index">{{ country.name }}, </span>
        </template>
        <a class="body-2" @click="modalCountries.visible = true"
          >ver más detalles</a
        >
        <modal-countries
          :visible="modalCountries.visible"
          @onCancel="modalCountries.visible = false"
        />
      </div>
    </div>
    <div v-if="withAction" class="section-products__current-item-buy">
      <a-button type="primary" @click="handleRedirectToMarket">
        {{ pack.action }}
      </a-button>
    </div>
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import ModalCountries from '@/app/shop/components/spendings/ModalCountries'
import { mapGetters } from 'vuex'
export default {
  components: {
    SocialIcon,
    ModalCountries,
  },
  props: {
    pack: {
      type: Object,
      required: true,
    },
    withAction: {
      type: Boolean,
      default: false,
      required: false,
    },
    showCountries: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    modalCountries: { visible: false },
  }),
  computed: {
    ...mapGetters(['companyPricing']),
    smsPack() {
      return this.companyPricing.packs.find((elem) => elem.image == 'SMS')
    },
  },
  methods: {
    handleRedirectToMarket() {
      const tabKey = this.pack.image.toLowerCase()
      this.$router.push({ name: 'menu-market', params: { tabKey: tabKey } })
    },
  },
}
</script>

<style lang="sass" scoped>
.section-products__title
  display: flex
  justify-content: space-between
.section-products__current
  display: flex
.section-products__current-item
  padding: 16px
  display: flex
  border: 1px solid $gray_dark_100
  border-radius: 8px
  min-height: 91px
  align-items: center
  text-align: left
  &::first-child
    margin-left: 12px
.section-products__current-item-info
  // margin-left: 12px
.section-products__current-item-buy
  height: 100%
  display: flex
  align-items: flex-end
  margin-left: 24px
.section-products__current-item-image
  margin-right: 12px
</style>
