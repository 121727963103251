<template>
  <a-modal
    :visible="visible"
    @cancel="$emit('onClose')"
    width="max-content"
    :bodyStyle="bodyStyle"
    centered
  >
    <span slot="title" class="modal__title ellipsis">
      {{ `Visualización del flujo de chatbot - ${chatbotName}` }}
    </span>
    <template slot="footer">
      <a-button key="back" @click="$emit('onClose')">
        Cerrar visualización
      </a-button>
    </template>
    <container-tree
      v-if="chatbotId"
      :chatbotId="chatbotId"
      :disabled-actions="true"
    />
  </a-modal>
</template>

<script>
import ContainerTree from '@/app/chatbots/components/organisms/tree/ContainerTree'
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'

export default {
  name: 'ModalPreviewChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    chatbotId: { type: String, required: true },
    chatbotName: { type: String, required: true },
  },
  components: {
    ContainerTree,
  },
  data: () => ({
    moment,
    count: 0,
    bodyStyle: {
      overflow: 'scroll',
      height: '70vh',
      maxHeight: '80vh',
      padding: '36px',
      maxWidth: '710px',

      minWidth: '34rem',
    },
  }),
  mixins: [transformMixin],
}
</script>

<style lang="sass" scoped>
.modal__title
  width: 90%
</style>
