<template>
  <section
    class="template"
    :style="{
      ...main_layout.styles,
      ...parentStyles,
      ...viewBreakpoint.styles,
    }"
  >
    <section class="template__body">
      <slot></slot>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'FullTemplate',
  props: {
    parentStyles: {
      type: Object,
      default: () => ({
        height: '100%',
      }),
    },
  },
  mixins: [supportMixin],
  computed: {
    ...mapGetters(['main_layout']),
  },
}
</script>

<style lang="sass" scoped>
.template__body
  height: 100%
</style>
