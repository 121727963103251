import Error from '@/app/shared/views/Error'

export default [
  {
    path: '*',
    name: 'error',
    component: Error,
  },
  {
    path: '/err',
    name: 'err',
    component: Error,
  },
]
