<template>
  <div
    class="node-basic"
    :class="{
      'node-basic--selected': selected,
      'cursor-auto': disabled,
      'cursor-pointer': !disabled,
    }"
    @click="handleSelect"
  >
    <div class="node-basic__header">
      <div class="node-basic__title">
        <!--Icono del nodo-->
        <icon-node :type="iconType" class="node-basic__icon--profile" />
        <!--Título del nodo-->
        <h5 class="heading-h7 mrg-bottom-0">
          {{ title }}
          <a-tooltip v-if="attachment" :title="titleMediaTooltip">
            <a class="mrg-left-8" @click="$emit('onPreviewAttachment')"
              ><a-icon type="paper-clip"
            /></a>
          </a-tooltip>
        </h5>
      </div>
      <!--Acción del nodo-->
      <div>
        <a-tooltip v-if="showTooltip" placement="bottom">
          <template slot="title">
            <span>{{ tooltipAction }}</span>
          </template>
          <a-icon type="info-circle" class="node-basic__icon--info" />
        </a-tooltip>
        <i class="info-2 node-basic__action">{{ action }}</i>
      </div>
    </div>
    <div class="node-basic__body">
      <p
        class="body-2 node-basic__description mrg-bottom-0 ellipsis text--left"
      >
        <span
          v-if="!description || !description.length"
          class="node-basic__description--empty"
        >
          Sin texto configurado
        </span>
        <template v-else>
          {{ description }}
        </template>
      </p>
      <div class="node-basic__actions">
        <a
          :class="{ 'node-basic__action--danger': !disabled && !onlyRead }"
          @click.stop="handleDelete"
          :disabled="disabled || onlyRead"
          >Eliminar</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import IconNode from '@/app/chatbots/components/atoms/IconNode'

export default {
  name: 'BasicNode',
  props: {
    iconType: {
      type: String,
      required: true,
      enum: ['number', 'message', 'profile', 'button'],
    },
    tooltipAction: {
      type: String,
      required: false,
      default: null,
    },
    title: { type: String, default: 'Nodo' },
    description: { type: String, default: 'Descripcion' },
    action: { type: String, default: 'Finalizar chatbot' },
    selected: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, default: false },
    onlyRead: { type: Boolean, required: false, default: false },
    attachment: {
      type: String,
      required: false,
      enum: ['document', 'image', 'video'],
      default: null,
    },
  },
  components: {
    IconNode,
  },
  computed: {
    /**
     * @return {Boolean} mostrar tooltip o no
     */
    showTooltip() {
      return this.tooltipAction && this.tooltipAction.length > 0
    },
    /**
     * @return {String} - titulo para tooltip de multimedia
     */
    titleMediaTooltip() {
      if (!this.attachment) return ''
      switch (this.attachment) {
        case 'document':
          return 'Con documento adjunto'
        case 'image':
          return 'Con imagen adjunta'
        case 'video':
          return 'Con video adjunto'
        default:
          return 'Con archivo adjunto'
      }
    },
  },
  methods: {
    /**
     * Emite la seleccion del nodo solo si no esta deshabilitado
     */
    handleSelect() {
      !this.disabled && this.$emit('onSelect')
    },
    handleDelete() {
      !this.disabled && !this.onlyRead && this.$emit('onDelete')
    },
  },
}
</script>
<style lang="sass" scoped>
.node-basic
  border: 1px solid $gray_dark_100
  border-radius: 4px
  margin-bottom: 8px
  padding: 12px
  &__header
    display: flex
    justify-content: space-between
  &__title
    display: flex
    align-items: center
    margin-bottom: 8px
  &__description
    text-align: left
    color: $gray_dark_800
    margin-right: 20px
    &--empty
      color: $gray_6
  &__icon
    font-size: 17px
    color: $blue_6
    margin-right: 8px
  &__icon--profile
    font-size: 17px
    color: $blue_6
    margin-right: 8px
  &__icon--info
    font-size: 17px
    color: $gray_7
    margin-right: 8px
  &__action
    color: $gray_7
    &--danger
      color: $red_6
  &__body
    display: flex
    justify-content: space-between
  &--selected
    border: 1px solid $blue_6
    background-color: $blue_1
  &:hover
    border: 1px solid $blue_6
</style>
