var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-section-scroll',{attrs:{"master-styles":{ height: 'calc(100% - 6em)' },"content-styles":{ padding: '0px 24px' },"scroll-pagination":{
    observedElement: 'list-item--last',
    onNext: _vm.onNextSearch,
  }}},[_c('a-list',{staticClass:"list",attrs:{"item-layout":"horizontal","data-source":_vm.dataSource,"locale":{
      emptyText: 'No se han encontrado descargas de acuerdo a los filtros.',
    },"loading":_vm.loading},scopedSlots:_vm._u([{key:"renderItem",fn:function(download, index){return _c('list-item-download',_vm._b({class:{
        'list-item--last': index === _vm.dataSource.length - 1,
      },attrs:{"type-module":download.module}},'list-item-download',download,false))}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }