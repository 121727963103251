<template>
  <a-modal
    :visible="visible"
    title="Detalles de paises disponibles"
    @cancel="$emit('onCancel')"
    :footer="null"
    centered
  >
    <!-- <p class="body-2">Recuerda que</p> -->
    <editable-table :columns="columns" :data-source="dataSource" />
    <div class="modal__footer">
      <a-button type="primary" @click="$emit('onCancel')"
        >Cerrar detalles</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import EditableTable from '@/app/settings/components/organisms/alertsMenu/EditableTable'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalCountries',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    columns: [
      {
        title: 'País',
        key: 'name',
        dataIndex: 'name',
        // width: '10%',
      },
      {
        title: 'Cantidad',
        key: 'limit',
        dataIndex: 'limit',
      },
      {
        title: 'Usados',
        key: 'used',
        dataIndex: 'used',
      },
    ],
  }),
  components: {
    EditableTable,
  },
  computed: {
    ...mapGetters(['companyPricing']),

    smsPack() {
      if (!this.companyPricing) return
      return this.companyPricing.packs.find((elem) => elem.image == 'SMS')
    },
    dataSource() {
      if (!this.smsPack) return
      return Object.keys(this.smsPack.countries).map((key) => {
        return this.smsPack.countries[key]
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__footer
  margin-top: 24px
  display: flex
  justify-content: center
</style>
