<template>
  <div>
    <div class="collapse-header">
      <a-icon
        type="caret-right"
        :rotate="collapse ? 90 : 0"
        @click="handleChangeCollapse"
      />
      <h6 class="heading-h7 title">Ver más detalles del ticket</h6>
    </div>
    <a-divider class="collapse-divider" />
    <div v-if="collapse" class="collapse-body">
      <div class="item">
        <h6 class="heading-h7 item_title">Cliente asignado</h6>
        <p class="body-2 item_description">{{ fullName }}</p>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Agente asignado</h6>
        <p class="body-2 item_description">
          {{ ticket.agent.names }} {{ ticket.agent.surnames }}
        </p>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Estados</h6>
        <!-- [queued, issued, opened, ended] -->
        <a-row type="flex" align="middle">
          <a-tag color="#C22FB3" v-if="ticket.subStatus == 'queued'">
            Cola de espera
          </a-tag>
          <a-tag color="#CE8934" v-else-if="ticket.subStatus == 'issued'">
            Entregado
          </a-tag>
          <a-tag color="#2FC25B" v-else-if="ticket.subStatus == 'opened'">
            Respondido
          </a-tag>
          <a-tag color="#1890FF" v-else-if="ticket.subStatus == 'ended'">
            Finalizado
          </a-tag>

          <a-tag color="green" v-if="ticket.pendings == 0"> Leído </a-tag>
          <a-tag v-else> Sin leer </a-tag>
        </a-row>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Canal</h6>
        <social-icon :type="ticket.channel.type" />
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Teléfono</h6>
        <p class="body-2 item_description">
          {{ ticket.phone }}
        </p>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Cola</h6>
        <p class="body-2 item_description">
          {{ ticket.company.lineName }}
        </p>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Buzon</h6>
        <p class="body-2 item_description">
          {{ getMailbox(ticket.mailboxId).title }}
        </p>
      </div>
      <div class="item">
        <h6 class="heading-h7 item_title">Fecha de creación</h6>
        <p class="body-2 item_description">
          {{ formatDate(ticket.created_at) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'CollapseTicketDetails',
  components: {
    SocialIcon,
  },
  props: {
    ticket: {
      type: Object,
      require: false,
    },
  },
  data: () => ({
    collapse: false,
  }),
  computed: {
    ...mapGetters(['mailboxes']),

    fullName() {
      if (this.ticket.client.names && this.ticket.client.surnames)
        return `${this.ticket.client.names} ${this.ticket.client.surnames}`
      else if (this.ticket.client.names && !this.ticket.client.surnames)
        return this.ticket.client.names
      else if (!this.ticket.client.names && this.ticket.client.surnames)
        return this.ticket.client.surnames
      else return 'Sin nombres'
    },
  },
  methods: {
    handleChangeCollapse() {
      this.collapse = !this.collapse
    },
    getMailbox(mailboxId) {
      return (
        this.mailboxes.find((mailbox) => mailbox.idMailbox == mailboxId) || {}
      )
    },
    formatDate(created_at) {
      const formatedDate = moment(created_at).format('MMMM DD YYYY, h:mm:ss a')
      return formatedDate[0].toUpperCase() + formatedDate.slice(1)
    },
  },
}
</script>
<style lang="sass" scoped>
.collapse-divider
  margin: 4px auto 4px
.collapse-header
  display: flex
  align-items: center
  .title
    margin-bottom: 0px
    margin-left: 8px
.collapse-body
  .item
    margin-bottom: 8px
    &_title
      margin-bottom: 2px
      color: $gray_dark_800
    &_description
      margin-bottom: 0px
      color: $gray_dark_700
</style>
