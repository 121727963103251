import Support from '@/app/support/views/Support'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: {
      requiresAuth: true,
      title: `Soporte al Cliente | ${VUE_APP_NAME}`,
    },
  },
]
