const actions = {
  /**
   * Escuchar cerrar sesion por remoto
   * @param {vuex} context
   * @returns {Promise<void>}
   */
  async listenRemoteLogout(context) {
    try {
      await this._vm.$socket.on('client:remote:logout', async (iodata) => {
        localStorage.setItem('logout_remote', iodata.chief.type) // Es lo mismo que true
        context.dispatch('logout', true)
        location.assign('/login')
      })
    } catch (error) {
      console.error('[userThread][joinUserIdThread]', error)
    }
  },
  /**
   * Emitir evento para aceptar inicio de sesion
   * @param {vuex} context
   * @param {String} username
   * @returns {Promise<void>}
   */
  async emitAcceptSession(context, username) {
    try {
      await this._vm.$socket.emit('server:accept:session', {
        username: username,
        socket_id: this._vm.$socket.id,
      })

      //Cerrar modal
      context.commit('SET_MODAL_SESSIONS', {
        statusModal: false,
        typeModal: 'pending',
      })
      context.dispatch('logout')
    } catch (e) {
      console.error('[AuthThread][emitRejectSession]', e)
    }
  },
  /**
   * Escuhar evento para aceptar permiso de inicio de sesion
   * @param context
   * @returns {Promise<void>}
   */
  async listenAcceptSession() {
    try {
      await this._vm.$socket.on('client:accept:session', () => {
        // console.log("Se concedió el permiso!")
        // let credentials = {
        //   username: '',
        //   password: '',
        //   once: false,
        // }
        // context.dispatch('signin', credentials)
      })
    } catch (e) {
      console.error('[AuthThread][listenAcceptSession]', e)
    }
  },
  /**
   * Emitir evento para rechazar permiso de inicio de sesion
   * @param {vuex} context
   * @param {String} userId
   * @returns {Promise<void>}
   */
  async emitRejectSession(context, userId) {
    try {
      await this._vm.$socket.emit('server:reject:session', {
        userId: userId,
        socket_id: this._vm.$socket.id,
      })

      //Cerrar modal
      context.commit('SET_MODAL_SESSIONS', {
        statusModal: false,
        typeModal: 'pending',
      })
    } catch (e) {
      console.error('[AuthThread][emitRejectSession]', e)
    }
  },
  /**
   * Escuchar evento para rechazar permiso de inicio de sesion
   * @param context
   * @returns {Promise<void>}
   */
  async listenRejectSession(context) {
    try {
      await this._vm.$socket.on('client:reject:session', () => {
        //Cerrar modal
        context.commit('SET_MODAL_SESSIONS', {
          statusModal: false,
          typeModal: 'question',
        })

        //Mostrar modal
        context.commit('SET_MODAL_SESSIONS', {
          statusModal: true,
          typeModal: 'fail',
        })

        setTimeout(() => {
          window.location.reload(true)
        }, 999)
      })
    } catch (e) {
      console.error('[AuthThread][listenRejectSession]', e)
    }
  },
  /**
   * Emitir doble sesion a un usuario conectado | mostrar modal
   * @param context
   * @param userId
   * @returns {Promise<void>}
   */
  async emitValidateSession(context, userId) {
    try {
      //Mostrar modal
      context.commit('SET_MODAL_SESSIONS', {
        statusModal: true,
        typeModal: 'pending',
      })
      //Emitir evento
      await this._vm.$socket.emit('server:other:sessions', userId)
    } catch (error) {
      console.error('[AuthThread][emitUserDoubleSession]', error)
    }
  },
  /**
   * Escuchar evento para validar doble sesion
   * @param context
   * @returns {Promise<void>}
   */
  async listenValidateSession(context) {
    try {
      await this._vm.$socket.on('client:other:sessions', () => {
        //Mostrar modal
        context.commit('SET_MODAL_SESSIONS', {
          statusModal: true,
          typeModal: 'question',
        })
      })
    } catch (e) {
      console.error('[AuthThread][listenAccept]', e)
    }
  },
}

export default {
  actions,
}
