/**
 * Compara si dos elementos son iguales, esta funcion aun falta mejorar para los strings ya arrays
 * @param {Object|String|String[]} firstElement
 * @param {Object|String|String[]} secondElement
 * @return {Boolean}
 */
export const isEqual = (firstElement, secondElement) => {
  let firstType
  let secondType
  // comprueba si es un array
  firstType = Array.isArray(firstElement) ? 'array' : typeof firstElement
  secondType = Array.isArray(secondElement) ? 'array' : typeof secondElement
  // si los tipos de datos son diferentes
  if (firstType !== secondType) return false
  // si los tipos de datos son los mismos
  // si ambos son string
  else if (firstType === 'string') {
    return firstElement === secondElement
  } else if (firstType === 'array') {
    // aun falta, para mas adelante
    return 'not support'
  } else if (firstType === 'object') {
    return !Object.keys(firstElement).some((field) => {
      if (
        typeof firstElement[field] === 'boolean' ||
        typeof secondElement[field] === 'boolean'
      ) {
        firstElement[field] = firstElement[field].toString()
        secondElement[field] = secondElement[field].toString()
      } else if (!firstElement[field] && !secondElement[field]) return false
      return firstElement[field] !== secondElement[field]
    })
  }
}
