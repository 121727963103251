<template>
  <a-modal
    :visible="visible"
    :bodyStyle="styles"
    @cancel="$emit('onClose')"
    centered
    width="56%"
  >
    <div class="modal__body">
      <img
        src="../../assets/whoooa_graphics/outlined/question.svg"
        class="modal__picture"
      />
      <div>
        <h5 class="heading-h5">¿Qué es "..."?</h5>
        <p class="body-2">
          Son las siglas en inglés de “Private Branch Exchange”, la cual es una
          red telefónica privada utilizada dentro de una empresa. Los usuarios
          de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las
          líneas de salida para realizar llamadas telefónicas externas.
        </p>
        <p class="body-2 modal__paragraph--bottom">
          Para saber mas revisa nuestro blog haciendo click<a href="#"> aqui</a
          >.
        </p>
      </div>
    </div>
    <div slot="footer" class="modal__footer">
      <a-button class="" type="primary" @click="$emit('onClose')"
        >Cerrar</a-button
      >
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'IntroductoryModal',
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Route TIcker' },
    description: { type: String, default: 'This is description Ticker' },
  },
  data: () => ({
    styles: {
      padding: '40px',
    },
  }),
}
</script>

<style lang="sass" scoped>
.modal
  &__carousel
    margin-top: 24px
  &__body
    display: flex
    align-items: center
  &__paragraph--bottom
    margin-top: 32px
  &__picture
    margin-right: 40px
  &__footer
    display: flex
    justify-content: flex-end
</style>
