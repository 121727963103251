<template>
  <div
    class="card"
    :class="{
      'card--selected': isSelected,
      'card--selected--disabled': disabled && isSelected,
      'card--disabled': disabled && !isSelected,
    }"
    @click="handleChange"
  >
    <div
      class="card__circle"
      :class="{
        'circle-bottom--right': position === 'bottomRight',
        'circle-bottom--left': position === 'bottomLeft',
        'circle-top--left': position === 'topLeft',
        'circle-top--right': position === 'topRight',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CardPosition',
  props: {
    position: {
      type: String,
      default: 'bottomRight',
      required: false,
      enum: ['bottomRight', 'bottomLeft', 'topLeft', 'topRight'],
    },
    isSelected: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
  },
  methods: {
    /**
     * Evento de cambio de posición
     */
    handleChange() {
      if (this.disabled) return
      this.$emit('onChange', this.position)
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  height: 80px
  width: 80px
  border-radius: 4px
  background-color: $gray_4
  position: relative
  padding: 8px
  transition: all 0.3s
  margin-right: 16px
  cursor: pointer
  &--selected
    border: 1px solid $blue_6
    background-color: $gray_2
    .card__circle
      background-color: $blue_6
  &:last-child
    margin-right: 0px
  &--selected--disabled
    border: 1px solid $blue_4
    cursor: not-allowed
    .card__circle
      background-color: $blue_4
  &--disabled
    background-color: $gray_2
    cursor: not-allowed
    .card__circle
      background-color:  $gray_6
.card__circle
  height: 16px
  width: 16px
  border-radius: 50px
  background-color: $gray_7
  position: absolute
  transition: all 0.3s
.circle-bottom--right
  bottom: 8px
  right: 8px
.circle-bottom--left
  bottom: 8px
  left: 8px
.circle-top--left
  top: 8px
  left: 8px
.circle-top--right
  top: 8px
  right: 8px
</style>
