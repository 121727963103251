<template>
  <a-modal
    :visible="visible"
    title="Solicitud de baja de número"
    @cancel="onCancel"
    centered
  >
    <p class="body-2">
      Tu solicitud será atendida en promedio de 7 a 10 días. Es posible que nos
      contactemos contigo para informarte sobre el proceso.
    </p>
    <a-form :form="form" layout="vertical">
      <a-form-item label="Correo de contacto">
        <a-input
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,

                  message: 'Por favor completa este campo',
                },
                { type: 'email', message: 'No es un email válido' },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
      <a-form-item label="Teléfono de contacto">
        <a-row :gutter="8" type="flex">
          <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
          <a-col :span="16" :order="2">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              :maxLenght="20"
              placeholder="Escribe aquí..."
            />
          </a-col>
          <a-col :span="8" :order="1">
            <a-select
              show-search
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena el campo',
                    },
                  ],
                },
              ]"
              option-filter-prop="children"
              placeholder="País"
            >
              <a-select-option
                v-for="country in countries"
                :key="country._id"
                :title="country.nameSpanish"
              >
                {{ country.dialCode }}
                {{ country.nameSpanish }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="Motivo">
        <a-textarea
          v-decorator="[
            'reason',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @input="handleSetCurrentDescription"
          :auto-size="{ minRows: 3, maxRows: 4 }"
          :maxLength="maxReason"
        />
        <span class="counter"
          >{{ currentDescription ? currentDescription.length : 0 }}/{{
            maxReason
          }}</span
        >
      </a-form-item>
    </a-form>
    <template slot="footer">
      <a-button @click="onCancel">Cancelar</a-button>
      <a-button :loading="loading" @click="handleSubmit" type="primary"
        >Enviar solicitud</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ModalUnsubscribe',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    channelCompanyId: {
      type: String,
      required: true,
    },
  },
  data: () => ({ loading: false, currentDescription: '', maxReason: 380 }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal-unsubscribe' })
  },
  computed: {
    ...mapGetters(['countries']),
  },
  methods: {
    ...mapActions(['sendRequestToDown']),
    async handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) return
        this.loading = true
        const country = this.countrySelected(values.code)
        const phone = {}
        if (country) {
          phone.code = country.dialCode
          phone.phone = values.phone
        }

        const response = await this.sendRequestToDown({
          contact: { email: values.email, ...phone },
          reason: values.reason,
          channelCompanyId: this.channelCompanyId,
        })
        this.loading = false
        if (response.success) {
          this.$message.success('Solicitud enviada correctamente')
          this.form.resetFields()
          this.$emit('onClose')
          return
        } else {
          const messageError =
            response.details ||
            'No pudimos enviar tu solicitud, por favor intentelo más tarde'
          this.$message.error(messageError)
        }
      })
    },
    countrySelected(_id) {
      const countryFound = this.countries.find((country) => country._id === _id)
      if (countryFound !== undefined) {
        return countryFound
      }
    },
    verifiedError(attr) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(`${attr}`) && getFieldError(`${attr}`)
    },
    onCancel() {
      this.form.resetFields()
      this.$emit('onClose')
    },
    handleSetCurrentDescription(e) {
      this.currentDescription = e.target.value
    },
  },
}
</script>

<style lang="sass" scoped>
.counter
  position: absolute !important
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
</style>
