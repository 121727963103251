<template>
  <a-modal
    :visible="visible"
    title="Detalles del horario de tu chatbot"
    @cancel="$emit('onCancel')"
  >
    <template slot="footer">
      <a-button key="back" @click="$emit('onCancel')">
        Cerrar detalles
      </a-button>
      <a-button
        v-if="!dataSource.active"
        key="submit"
        type="primary"
        @click="$emit('onOk', dataSource._id)"
      >
        Editar chatbot
      </a-button>
    </template>
    <p class="semibold-14 modal__label">Zona horaria elegida</p>
    <p>{{ dataSource.schedule.timezone }}</p>
    <p class="semibold-14 modal__label">Días y horarios de ejecución</p>
    <ul class="modal__list">
      <li
        v-for="(day, index) in dataSource.schedule.days"
        :key="day.day"
        class="modal__item-content"
      >
        <a-divider v-if="index !== 0" class="modal__divider" />
        <div class="display-flex align--center modal__item-day">
          <p class="mrg-bottom-0 modal__label-day">{{ nameDay(day.day) }}</p>
          <ul class="modal__list-ranges display-flex justify--between">
            <li
              v-for="(range, index) in day.hours"
              :key="index"
              class="list__range"
            >
              {{
                moment(range.start)
                  .tz(dataSource.schedule.timezone)
                  .format('HH:mm a')
              }}
              <a-icon type="minus" style="font-size: 8px; margin: auto 1em" />
              {{
                moment(range.end)
                  .tz(dataSource.schedule.timezone)
                  .format('HH:mm a')
              }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </a-modal>
</template>

<script>
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'

export default {
  name: 'ModalDetailsChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Object,
      default: () => ({
        schedule: {
          timezone: 'America/Latina',
          days: [],
        },
      }),
    },
  },
  mixins: [transformMixin],
  data: () => ({
    moment,
  }),
  methods: {
    /**
     * Retorna el nombre del día de
     * @param {Number} index - 0 |1 | 2| 3 | 4| 5| 6
     * @return {String} nombre del día
     */
    nameDay(index) {
      const days = this.transformIndexDays([index])
      return days[0]?.day
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__label
  margin-bottom: 2px
.modal__item-content
  list-style: none
.modal__item-day
  padding: 8px
.modal__list
  padding-left: 0px
.modal__divider
  margin: 12px auto
.modal__label-day
  width: 80px
.list__range
  list-style: outside
.modal__list-ranges
  width: 100%
  color: $gray_8
  flex-wrap: wrap
</style>
