<template>
  <div
    class="avatar-status"
    :class="`avatar-status--${shape}`"
    :style="`border-width: ${border}px; padding: ${padding}px;`"
  >
    <slot></slot>
    <div
      v-if="border > 0"
      :class="`avatar-status__container-icon avatar-status__container-icon--${iconSize}`"
    >
      <a-icon type="experiment" class="avatar-status__icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
    shape: {
      type: String,
      default: 'circle',
      required: false,
    },
    border: {
      type: Number,
      default: 4,
      required: false,
    },
    padding: {
      type: Number,
      default: 3,
      required: false,
    },
    iconSize: {
      type: String,
      default: 'small',
      required: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.avatar-status
  position: relative
  padding: 3px
  -moz-border-radius: 50%
  -webkit-border-radius: 50%
  border-radius: 50%
  display: flex
  justify-content: center
  &--circle
    border: 4px solid $paused
  &--semicircle
    height: 50%
    -moz-border-radius: 100px 100px 0 0
    -webkit-border-radius: 100px 100px 0 0
    border-radius: 100px 100px 0 0
    border-top: 4px solid $paused
    border-right: 4px solid $paused
    border-left: 4px solid $paused
  &__container-icon
    background-color: $paused
    color: $white_000
    position: absolute
    border-radius: 50%
    z-index: 1
    display: flex
    align-items: center
    justify-content: center
    &--small
      padding: 2%
      height: 50%
      width: 50%
      bottom: 0px
      right: -8px
      .avatar-status__icon
        font-size: 0.7rem !important
        margin: auto
    &--large
      padding: 5px
      height: 25px
      width: 25px
      bottom: -39px
      right: 2px
      .avatar-status__icon
        font-size: 14px
        margin: auto
  &__icon
    margin-right: auto !important
</style>
