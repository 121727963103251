<template>
  <section class="template">
    <section class="template__body">
      <slot></slot>
    </section>
  </section>
</template>

<script>
export default {
  name: 'BoxTemplate',
}
</script>

<style lang="sass" scoped>
.template
  background-color: $white_000
  min-height: calc(100vh - #{$margin-default*2})
  max-height: calc(100vh - #{$margin-default*2})
  overflow: auto
  position: relative
  margin: $margin-default
  border: 1px solid $gray_4
  border-radius: 8px
</style>
