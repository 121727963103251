<template>
  <div class="menu-companies">
    <a-menu
      class="menu-companies__content"
      mode="vertical"
      :class="{
        'menu-footer__content--collapse': collapse,
        'menu-footer__content--no-collapse': !collapse,
      }"
      @openChange="handleOpenChangePopup"
    >
      <a-sub-menu
        key="submenu-footer-first"
        class="menu-companies__submenu"
        placement="rightBottom"
        :popupClassName="popupClassNameFooter"
        :disabled="disabled"
      >
        <menu-item-footer
          slot="title"
          :collapse="collapse"
          :title="companyName"
          class="menu-companies__item-footer"
        >
          <template slot="avatar">
            <avatar-company
              class="menu-item-footer__avatar"
              :color="company.color"
              :url="company.avatar || null"
            />
          </template>
        </menu-item-footer>

        <template v-if="showPopup && !!firstFourCompanies.length">
          <a-menu-item-group>
            <h6 slot="title" class="heading-h7 mrg-bottom-0">
              Seleccionar subcuenta
            </h6>
            <a-menu-item
              v-for="firstCompany in firstFourCompanies"
              :key="firstCompany._id"
              class="menu-companies__item"
              @click="handleShowModalChangeCompany(firstCompany)"
              :disabled="isChecked(firstCompany._id)"
            >
              <a-radio
                :checked="isChecked(firstCompany._id)"
                :style="radioStyle"
              >
                <avatar-company
                  class="mrg-right-8"
                  :size="24"
                  :color="firstCompany.color"
                  :url="firstCompany.avatar || null"
                  :size-icon="14"
                />
                {{ firstCompany.name }}</a-radio
              >
            </a-menu-item>
            <a-menu-item
              v-if="isMaster"
              key="other-subaccounts"
              @click="handleGoToCompanies"
            >
              Ver más subcuentas
            </a-menu-item>
          </a-menu-item-group>
        </template>
      </a-sub-menu>
      <!--menu item para q no aparezca el menu inline-->
      <a-menu-item key="" style="height: 0px; margin: 0">
        <a-icon type="" />
        <span></span>
      </a-menu-item>
    </a-menu>
    <modal-change-company
      :visible="modalChangeCompany.visible"
      :data-source="modalChangeCompany.dataSource"
      @onClose="handleCloseModalChangeCompany"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MenuItemFooter from '@/app/commons/components/molecules/MenuItemFooter'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import compareMixin from '@/app/shared/mixins/compare'
import companiesMixin from '@/app/companies/mixins/companiesMixin'
import AvatarCompany from '@/app/shared/components/avatars/AvatarCompany'
import ModalChangeCompany from '@/app/companies/components/organisms/ModalChangeCompany'

export default {
  name: 'MenuCompanies',
  components: {
    MenuItemFooter,
    AvatarCompany,
    ModalChangeCompany,
  },
  props: {
    companyName: { type: String, required: false, default: null },
    collapse: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, default: false },
    showPopup: { type: Boolean, default: false },
  },
  data: () => ({
    radioStyle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      marginLeft: '-20px',
      marginTop: '8px',
    },
    modalChangeCompany: {
      visible: false,
      dataSource: {},
    },
  }),
  mixins: [formatMixin, compareMixin, supportMixin, companiesMixin],
  watch: {
    loggedIn() {
      if (!this.loggedIn) this.cleanData()
    },
  },
  computed: {
    ...mapGetters(['profile', 'loggedIn', 'simple_companies', 'company']),

    /**
     * Clase del popup footer
     * @returns {String}
     */
    popupClassNameFooter() {
      const classNameForTypeUser = this.isAllowedFor(
        this.profile.type,
        ['agent'],
        { literal: true }
      )
        ? 'menu-companies__popup--agent'
        : `menu-companies__popup--admin--${this.firstFourCompanies.length}`

      return `menu-companies__popup ${classNameForTypeUser}`
    },

    /**
     * Clase del popup footer
     */
    firstFourCompanies() {
      return [this.company, ...this.simple_companies].slice(0, 4)
    },

    /**
     * @returns {Boolean}
     */
    isMaster() {
      if (!this.profile) return false
      return this.isAllowedFor(this.profile.type, ['master'])
    },
  },
  methods: {
    ...mapActions(['saveStatusUser', 'logout', 'selectMenu', 'setAnalytics']),
    ...mapMutations(['SET_IS_OPEN_SOME_POPUP_FOOTER']),

    /**
     * Ir hacia la vista de profile
     */
    handleGoToProfile() {
      const statesElement = document.getElementById('states')
      if (statesElement)
        statesElement.classList.remove('ant-menu-submenu-selected')
      const routeName = 'profile'
      this.$router.push({ name: 'menu-user' })
      const menuItem = { route: routeName }
      this.selectMenu(menuItem)
      this.setAnalytics({ eventName: 'link_profile' })
    },
    /**
     * Limpia la data si no esta logueado
     */
    cleanData() {
      this.timer = null
      this.timerSeconds = 0
    },
    /**
     * Cambia según si el popup se encuentra abierto o no
     * @param {Array} val - es el submenu
     */
    handleOpenChangePopup(val) {
      const menuStates = document.getElementById('states')
      if (menuStates) menuStates.classList.remove('ant-menu-submenu-selected')

      const menuSelecteds = document.querySelectorAll(
        '.menu-companies__popup .ant-menu-item-selected'
      )
      if (menuSelecteds.length > 0) {
        menuSelecteds.forEach((menuSelected) => {
          menuSelected.classList.remove('ant-menu-item-selected')
        })
      }
      if (val.includes('submenu-footer-first')) {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(true)
      } else {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(false)
      }
    },
    isChecked(companyId) {
      return this.company._id === companyId
    },
    handleGoToCompanies() {
      this.$router.push({ name: 'sub-accounts' })
    },
    handleShowModalChangeCompany(company) {
      this.modalChangeCompany.dataSource = company
      this.modalChangeCompany.visible = true
    },
    handleCloseModalChangeCompany() {
      this.modalChangeCompany.visible = false
      this.modalChangeCompany.dataSource = {}
    },
  },
}
</script>

<style lang="sass" scoped>
.menu-companies
  border-top: 1px solid $white_000
  height: 72px
  &__content
    background-color: $gray_dark_900
    border-right: 0px
    height: 72px
    display: flex
    align-items: center
    width: inherit
    &--no-collapse
      width: inherit
  &__item
    text-align: center
  &__submenu
    width: 100%
  &__content-item
    display: flex
    flex-direction: column
    align-items: center
</style>
<style lang="sass">
.menu-companies__popup
  position: fixed!important
  &--admin--1
    >ul
      margin-top: -100px
  &--admin--2
    >ul
      margin-top: -130px
  &--admin--3
    >ul
      margin-top: -178px
  &--admin--4
    >ul
      margin-top: -207px
  &--agent
    >ul
      margin-top: 34px
.menu-item-footer--no-collapse
  & + .ant-menu-submenu-arrow
    display: none
.menu-companies .menu-item-footer__content
  height: 40px
</style>
