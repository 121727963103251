import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtiene las variables del chatbot
   */
  async getChatbotsVars(context, { page = 1, limit = 12, search, type }) {
    try {
      const url = `${vari.UHR}/chatbots-vars/`
      const params = {
        page,
        limit,
        search,
        type,
      }
      const response = await axios.get(url, { params })

      context.commit('SET_PAGINATE_CHATBOTS_VARS', response.data.data.data)
      delete response.data.data.data
      context.commit('SET_PAGINATION_CHATBOTS_VARS', response.data.data)

      return response.data
    } catch (error) {
      console.error('[getVarsChatbots]', error.response.data)
    }
  },
  /**
   * Obtiene las variables con datos basicos del chatbot
   */
  async getSimpleChatbotsVars(context) {
    try {
      const url = `${vari.UHR}/chatbots-vars/simply`
      const response = await axios.get(url)

      if (response.data.success)
        context.commit('SET_SIMPLE_CHATBOTS_VARS', response.data.data)

      return response.data
    } catch (error) {
      console.error('[getSimpleChatbotsVars]', error.response.data)
    }
  },
  /**
   * Crea la variable
   */
  async addChatbotVar(
    context,
    {
      name,
      type = 'text',
      excludeWords = [],
      customFieldId,
      saveDataFirstTime = false,
    }
  ) {
    try {
      const url = `${vari.UHR}/chatbots-vars/`
      const response = await axios.post(url, {
        name,
        type,
        excludeWords,
        customFieldId,
        saveDataFirstTime,
      })

      if (response.data.success) {
        context.commit('ADD_CHATBOT_VAR', response.data.data)
        context.commit('ADD_SIMPLE_CHATBOT_VAR', response.data.data)
      }

      return response.data
    } catch (error) {
      console.error('[addChatbotVar]', error.response.data)
    }
  },
  /**
   * Actualiza una variable de los chatbots
   * @param {*} context
   * @param {Object} args
   * @param {String} args.name
   * @param {String} args.chatbotVarId
   */
  async updateChatbotVar(
    context,
    { name, type = 'text', excludeWords = [], customFieldId, chatbotVarId }
  ) {
    try {
      const url = `${vari.UHR}/chatbots-vars/${chatbotVarId}`
      const response = await axios.put(url, {
        name,
        type,
        excludeWords,
        customFieldId,
      })
      if (response.data.success) {
        context.commit('UPDATE_CHATBOT_VAR', {
          chatbotVarId,
          chatbotVar: response.data.data,
        })
        context.commit('SET_CUSTOM_FIELD_TO_SIMPLE_CHATBOT_VAR', {
          chatbotVarId,
          customField: response.data.data.customField || {},
        })
      }

      return response.data
    } catch (error) {
      console.error('[updateChatbotVar]', error.response.data)
    }
  },
  /**
   * Elimina una variable de los chatbots
   * @param {*} context
   * @param {String} chatbotVarId
   */
  async deleteChatbotVar(context, chatbotVarId) {
    try {
      const url = `${vari.UHR}/chatbots-vars/${chatbotVarId}`
      const response = await axios.delete(url)
      if (response.data.success) {
        context.commit('DELETE_CHATBOT_VAR', chatbotVarId)
        context.commit('REMOVE_SIMPLE_CHATBOT_VAR', chatbotVarId)
      }

      return response.data
    } catch (error) {
      console.error('[deleteChatbotVar]', error.response.data)
      return error.response.data
    }
  },
  /**
   * Obtiene los chatbots que tienen la variables que se eliminara
   */
  async getChatbotsWithVarDelete(
    context,
    { page = 1, limit = 4, chatbotVarId }
  ) {
    try {
      const url = `${vari.UHR}/chatbots-vars/chatbots/${chatbotVarId}`
      const params = {
        page,
        limit,
      }
      const response = await axios.get(url, { params })

      return response.data
    } catch (error) {
      console.error('[getChatbotsWithVarDelete]', error.response.data)
    }
  },
}

export default actions
