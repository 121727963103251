import instanceJson from '@/app/shared/utils/instance'
import userStatus from '@/app/commons/utils/userStatus'
import { MAX_READ_EXPIRED_BANNER } from '@/app/commons/utils/banners'

const getters = {
  profile: (state) => state.profile,
  profileRemote: (state) => state.profileRemote,
  modalRemoteLogout: (state) => state.modalRemoteLogout,
  modalSessionExpired: (state) => state.modalSessionExpired,
  internet: (state) => state.internet,
  news: (state) => state.news,
  tutoModal: (state) => state.tutoModal,
  modal_integrate_channel: (state) => state.modal_integrate_channel,
  modalCreateChatbot: (state) => state.modalCreateChatbot,
  reloadNotification: (state) => state.reloadNotification,
  notification_with_type: (state) => state.notification_with_type,
  profile_states: () => {
    const hideStatus = instanceJson.hide_status
    // elimina los estados que no se mostraran
    hideStatus.forEach((hideStatus) => delete userStatus[hideStatus])
    return userStatus
  },
  profile_states_for_list: (state) => {
    const currentStatus = state.profile.status

    const hideStatus = instanceJson.hide_status
    // elimina los estados que no se mostraran
    hideStatus.forEach((hideStatus) => delete userStatus[hideStatus])
    const profile_states = userStatus

    const userStatusArray = Object.values(profile_states)
    return userStatusArray.filter(
      (status) =>
        (!status.show_when || status.show_when[currentStatus]) && !status.hide
    )
  },
  is_guest: (state) =>
    state.profile && state.profile.subType && state.profile.subType === 'guest',
  allow_show_expired_banner: (state) => {
    // si el contador del banner de los canales expirados es igual a 2
    // entonces no se mostrará el banner
    return (
      state.profile &&
      state.profile.expiredChannelBannerCounter < MAX_READ_EXPIRED_BANNER
    )
  },
}

export default getters
