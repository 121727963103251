<template>
  <div class="preview" :class="{ 'preview--default': !text }">
    <!--IMAGEN-->
    <div v-if="mediaType === 'image'" class="preview__media media-image">
      <img v-if="mediaUrl" :src="mediaUrl" class="media-image__content" />
      <i v-else class="anticon preview__media-icon">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2zm0 2v11.586l4-4 3.5 3.5 6.5-6.5 2 2V4H4zm0 16v-1.586l4-4L13.586 20H4zm16 0h-3.586l-3.5-3.5L18 11.414l2 2V20zM14 8a3 3 0 10-6 0 3 3 0 006 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0z" fill="currentColor"/></svg>
      </i>
    </div>
    <!--VIDEO-->
    <div v-else-if="mediaType === 'video'" class="preview__media media-video">
      <video v-if="mediaUrl" class="media-video__content" controls>
        <source :src="mediaUrl" type="video/mp4" />
      </video>
      <i v-else class="anticon preview__media-icon">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zM3 12a9 9 0 1018 0 9 9 0 00-18 0zm5-5a1 1 0 011.53-.848l8 5a1 1 0 010 1.696l-8 5A1 1 0 018 17V7zm2 1.804L15.113 12 10 15.196V8.804z" fill="currentColor"/></svg>
      </i>
    </div>
    <!--DOCUMENTO-->
    <div
      v-else-if="mediaType === 'document'"
      class="preview__media media-document"
    >
      <a-icon type="file-pdf" class="preview__media-icon--file" />
      <span class="ellipsis mrg-right-4 mrg-left-4">{{
        fileName || 'Nombre de archivo'
      }}</span>
      <i class="anticon preview__media-icon--file">
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zM11 7v6.586l-2.293-2.293-1.414 1.414L12 17.414l4.707-4.707-1.414-1.414L13 13.586V7h-2z" fill="currentColor"/></svg>
      </i>
    </div>
    {{ text || defaultText }}
  </div>
</template>

<script>
export default {
  name: 'PreviewMessage',
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
    defaultText: {
      type: String,
      required: false,
      default:
        'Selecciona una plantilla, para previsualizar el contenido de una plantilla.',
    },
    mediaType: {
      type: String,
      required: false,
      enum: ['image', 'video', 'document'],
    },
    mediaUrl: {
      type: String,
      required: false,
    },
    fileName: {
      type: String,
      required: false,
      default: 'Nombre de archivo',
    },
  },
}
</script>

<style lang="sass" scoped>
$bg-blue: #373E4E
$background_media: #272E3E
.preview
  background-color: $bg-blue
  padding: 12px 16px
  color: $white_000
  border-radius: 4px
  &--default
    color: $gray_dark_400
  &__media
    background-color: $background_media
    border-radius: 10px
    height: 94px
    width: 100%
    margin-bottom: 10px
    @include flex(row, center, center)
    &-icon
      font-size: 48px
      color: $bg-blue
      &--file
        font-size: 20px
        color: $bg-blue
  .media-document
    height: 36px
    padding: 6px
    justify-content: space-between
  .media-image__content, .media-video__content
    height: 100%
    width: 100%
    object-fit: cover
</style>
