<template>
  <a-popover
    title="Etiquetas"
    :placement="placement"
    :trigger="trigger"
    overlayClassName="popover-tags"
  >
    <template slot="content">
      <custom-section-scroll
        :master-styles="{ maxHeight: '160px', marginRight: '-12px' }"
        :container-styles="{ maxHeight: '160px' }"
        :content-styles="{ paddingRight: '12px' }"
      >
        <ul class="popover__list">
          <li
            v-for="(item, index) in dataSource"
            :key="index"
            class="mrg-bottom-8"
          >
            <a-tag :color="item.color">{{ item.title }}</a-tag>
          </li>
        </ul>
      </custom-section-scroll>
    </template>
    <slot>
      <a-button> Mostrar</a-button>
    </slot>
  </a-popover>
</template>

<script>
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'PopoverTagsList',
  props: {
    dataSource: {
      type: Array,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'bottomRight',
    },
    trigger: {
      type: String,
      required: false,
      default: 'click',
    },
  },
  components: {
    CustomSectionScroll,
  },
}
</script>

<style lang="sass" scoped>
.popover__list
  padding-left: 20px
.popover-tags
</style>
