<template>
  <a-row class="template" type="flex" justify="center">
    <a-col :span="grid" class="col">
      <slot></slot>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'GridTemplate',
  props: {
    grid: {
      type: Number,
      required: false,
      default: 16,
    },
  },
}
</script>

<style lang="sass" scoped>
.template
  height: 100vh
  .col
    height: max-content
</style>
