<template>
  <full-template :parent-styles="{ padding: '20px' }">
    <header-module title="Lista negra" tipography="semibold-20" />
    <p class="text--left">
      En esta sección podrás bloquear los números de WhatsApp, que no desees que
      te escriban por este medio.
    </p>
    <!-- Filtros -->
    <a-row class="mrg-bottom-16" type="flex" justify="space-between">
      <a-col :span="12" class="display-flex align--start">
        <a-input-search
          placeholder="Buscar por número"
          class="filters__item mrg-right-12"
          v-model="filters.search"
          @search="handleChangeFilters"
        />
        <a-select
          v-model="filters.channelId"
          class="filters__item"
          placeholder="Escoge un ID"
          @change="handleChangeFilters"
        >
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex" align="middle">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>{{
                simpleChannel.alias ? `(${simpleChannel.alias})` : ''
              }}</span>
            </a-row>
          </a-select-option>
        </a-select>
      </a-col>
      <!--Boton de bloqueo-->
      <a-button type="primary" icon="plus" @click="handleShowAddModalBlacklist">
        Bloquear nuevo número
      </a-button>
    </a-row>
    <!-- Tabla de números -->
    <table-blacklist
      :data-source="company_blacklist"
      :loading="filters.loading"
      @onPreview="handleShowModalPreviewBlacklist"
      @onUnBlock="handleShowConfirmationForUnblock"
      @onChangeTable="handleChangeTable"
      @onUpdate="handleShowUpdateModalBlacklist"
    />
    <!--Modal de Lista negra-->
    <modal-form-blacklist
      v-bind="modalFormBlacklist"
      @onClose="handleCloseModalBlacklist"
    />
    <!--Modal de previsualizacion-->
    <modal-preview-fast-answers
      v-bind="modalPreviewBlacklist"
      @onClose="handleCloseModalPreviewBlacklist"
    />
  </full-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FullTemplate from '@/app/shared/components/templates/FullTemplate'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import TableBlacklist from './TableBlacklist'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import ModalFormBlacklist from '@/app/settings/components/molecules/ModalFormBlacklist'
import supportMixin from '@/app/shared/mixins/support'
import ModalPreviewFastAnswers from '@/app/settings/components/molecules/ModalPreviewFastAnswers'
import attemptMixin from '@/app/shared/mixins/attempt'
import blacklistMixin from './mixins/blacklistMixin'

export default {
  name: 'SectionMenuBlacklist',
  components: {
    FullTemplate,
    HeaderModule,
    TableBlacklist,
    SocialIcon,
    ModalFormBlacklist,
    ModalPreviewFastAnswers,
  },
  data: () => ({
    filters: {
      page: 1,
      search: '',
      channelId: 'all',
      loading: false,
    },
    modalFormBlacklist: {
      visible: false,
      title: '',
      dataSource: {},
      action: 'add',
      okText: '',
    },
    modalPreviewBlacklist: {
      visible: false,
      title: 'Ver detalles de bloqueo',
      keysAllowed: [
        'apiClientId',
        'channelCompanies',
        'created_at',
        'blockedFor',
        'reason',
      ],
      dataSource: {},
    },
  }),
  mounted() {
    this.$nextTick(() => {
      this.intervalAttempt(() => {
        if (!this.company) throw 'No company'
        this.loadInitialData()
      })
    })
  },
  mixins: [supportMixin, attemptMixin, blacklistMixin],
  computed: {
    ...mapGetters(['company_blacklist', 'company']),

    /**
     * @return {Object} simpleChannelsFiltered
     * @return {String} simpleChannelsFiltered._id
     * @return {String} simpleChannelsFiltered.title
     */
    simpleChannelsFiltered() {
      return this.filterSimpleChannelWhatsAppByType({
        type: 'gupshup',
        defaultChannel: {
          title: 'Todos los ID',
          _id: 'all',
        },
      })
    },
  },
  methods: {
    ...mapActions(['getBlacklist', 'unBlockNumberInBlacklist']),

    /**
     * Carga la data principal
     */
    loadInitialData() {
      this.handleChangeFilters()
    },
    /**
     * Escucha el cambio de filtros
     */
    async handleChangeFilters() {
      this.filters.loading = true
      await this.getBlacklist(this.filters)
      this.filters.loading = false
    },
    /**
     * Muestra el modal de bloqueo
     */
    handleShowAddModalBlacklist() {
      this.modalFormBlacklist.title = 'Bloquear número de WhatsApp'
      this.modalFormBlacklist.okText = 'Bloquear número'
      this.modalFormBlacklist.action = 'add'
      this.modalFormBlacklist.dataSource = {}
      this.modalFormBlacklist.visible = true
    },
    /**
     * Cierra el modal de bloqueo
     */
    handleCloseModalBlacklist() {
      this.modalFormBlacklist.visible = false
      this.modalFormBlacklist.action = 'add'
      this.modalFormBlacklist.dataSource = {}
    },
    /**
     * Muestra el modal de previsualizacion
     */
    handleShowModalPreviewBlacklist(data) {
      this.modalPreviewBlacklist.dataSource = data
      this.modalPreviewBlacklist.visible = true
    },
    /**
     * Muestra el modal de confirmacion para bloquear o desbloquear
     * @param {Object} values
     * @param {String} values._id
     * @param {Object[]} values.channelCompanies
     */
    handleShowConfirmationForUnblock(values) {
      const blacklistId = values._id
      const okCallback = async () =>
        await this.unBlockNumberInBlacklist(blacklistId)

      this.showBlacklistConfirmation(values, 'unblock', okCallback)
    },
    /**
     * Cierra el modal de previsualizacion
     */
    handleCloseModalPreviewBlacklist() {
      this.modalPreviewBlacklist.visible = false
    },
    /**
     * Escucha el cambio de paginacion de la tabla
     * @param {Number} page
     */
    handleChangeTable(page) {
      this.filters.page = page
      this.handleChangeFilters()
    },
    /**
     * Muestra el modal para editar
     */
    handleShowUpdateModalBlacklist(record) {
      this.modalFormBlacklist.title = 'Editar número bloqueado de WhatsApp'
      this.modalFormBlacklist.okText = 'Editar'
      this.modalFormBlacklist.action = 'update'
      this.modalFormBlacklist.dataSource = record
      this.modalFormBlacklist.visible = true
    },
  },
}
</script>

<style lang="sass" scoped>
.filters__item
  min-width: 14em
  max-width: 20em
</style>
