<template>
  <modal-type
    :visible="visible"
    :title="title"
    :description="description"
    type="confirmation"
    :custom-request="handleChangeCompany"
    ok-text="Cambiar a esta subcuenta"
    :ok-disabled="disabledOk"
    @onClose="handleCloseModal"
  >
    <a-form
      layout="vertical"
      :form="form"
      @submit.prevent="handleChangeCompany"
    >
      <!--contraseña-->
      <p>
        Ingresa tu contraseña con la que iniciaste sesión para validar el cambio
        de subcuenta.
      </p>
      <a-form-item v-if="visible" label="Contraseña">
        <a-input-password
          ref="form_item_password"
          v-decorator="[
            'password',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          @input="hasErrorInForm"
        />
        {{ setFocusInPassword() }}
      </a-form-item>
    </a-form>
  </modal-type>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalType from '@/app/shared/components/modals/ModalType'
import transformMixin from '@/app/shared/mixins/transform'
import companiesMixin from '@/app/companies/mixins/companiesMixin'

export default {
  name: 'ModalDeleteCompany',
  mixins: [transformMixin, companiesMixin],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  components: {
    ModalType,
  },
  data: () => ({
    description:
      '<p>Toda la información que visualices en los distintos módulos serán de la subcuenta seleccionada.</p>',
    disabledOk: true,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_change_company' })
  },

  computed: {
    ...mapGetters(['profile', 'simple_companies']),

    /**
     * Titulo del modal
     * @return {String}
     */
    title() {
      if (!this.profile) return ''
      return `¿Estás  ${this.transformWordGender(
        'seguro',
        this.profile.gender
      )} de cambiar a la subcuenta "${this.dataSource.name}"?`
    },
  },
  methods: {
    ...mapActions(['deleteCompany']),

    /**
     * Cambia de empresa
     */
    async handleChangeCompany() {
      // validar nuevamente
      const hasErrors = this.hasErrorInForm()
      if (hasErrors) return
      // obtiene los valores del formulario
      const values = this.form.getFieldsValue()

      await this.executeChangeCurrentCompany({
        companyName: this.dataSource.name,
        companyId: this.dataSource._id,
        password: values.password,
      })
    },
    /**
     * Verifica si hay errores en el formulario
     */
    hasErrorInForm() {
      if (!this.form) this.disabledOk = true
      const fieldsError = this.form.getFieldsError()
      this.disabledOk = Object.keys(fieldsError).some(
        (field) => fieldsError[field]
      )
      return this.disabledOk
    },
    /**
     * Cierra el modal
     */
    handleCloseModal() {
      this.$emit('onClose')
      this.form.resetFields()
      this.disabledOk = true
    },
    /**
     * Setea focus en el campo de contraseña
     */
    setFocusInPassword() {
      setTimeout(() => {
        const fieldPassword = this.$refs.form_item_password
        fieldPassword.focus()
      }, 0)
    },
  },
}
</script>

<style scoped></style>
