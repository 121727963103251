const PREVIEW_NODES = {
  card_button: {
    title: 'Ejemplo de visualización de Tarjetas',
    image: 'preview-cards.png',
  },
  lists: {
    title: 'Ejemplo de visualización de Listas',
    image: 'preview-list.png',
  },
}

export { PREVIEW_NODES }
