<template>
  <a-modal
    :visible="visible"
    :title="title"
    :body-style="bodyStyles"
    :footer="null"
    @cancel="$emit('onClose')"
  >
    <img v-if="image" :src="require(`@/app/chatbots/assets/${image}`)" />
  </a-modal>
</template>

<script>
import { PREVIEW_NODES } from '@/app/chatbots/utils/previewNodes'

export default {
  name: 'ModalPreviewNode',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    typeNode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    PREVIEW_NODES,
    bodyStyles: {},
  }),
  computed: {
    /**
     * @return {String} titulo para el modal
     */
    title() {
      const previewNode = PREVIEW_NODES[this.typeNode]
      if (previewNode) return previewNode.title
      else return 'Ejemplo de visualización'
    },
    /**
     * @return {String} ruta de la imagen de previsualización
     */
    image() {
      const previewNode = PREVIEW_NODES[this.typeNode]
      if (previewNode) return previewNode.image
      else return null
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__media
  height: 100%
  max-width: 100%
  text-align: center
</style>
