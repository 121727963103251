import vari from '@/app/shared/utils/variables'

const tutos = [
  {
    _id: 'integrateWsp',
    title: 'Solicita tu WhatsApp Business API',
    main: true,
    abstract: {
      description:
        '¡Vamos a otro nivel! Accede al API de WhatsApp, fácil y rápido con nuestra ayuda. Migra tu número a la nube y descubre el verdadero potencial de este canal.',
    },
    description:
      '¡Vamos a otro nivel! Solicita y accede al API de WhatsApp Business con nuestra ayuda. No dependas de la App del smartphone y aprovecha todo el potencial de esta herramienta. Crea chatbots que atiendan 24x7 a tus clientes. Atiéndelos siempre y automatiza tu proceso de atención al clientes o ventas. Además, enruta las conversaciones según tus preferencias, incluyendo gestión multiagente.',
    tags: [
      {
        text: 'Compromiso',
        color: 'purple',
      },
      {
        text: 'Atención al cliente',
        color: 'blue',
      },
    ],
    assets: {
      picture: 'whatsapp-route.png',
      videos: [
        {
          picture: '/img/tutos/channels.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/F3YbMtvUnCM?rel=0&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
        },
      ],
    },
    route: { name: 'channels' },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
      actions: {
        paragraph_first: 'openStepsWBA',
      },
      linkMoreInformation:
        'https://www.securitec.pe/docs/ticker/whatsapp/acceder-api-whatsapp-business/',
    },
  },
  {
    _id: 'createTicket',
    title: 'Gestión de tickets',
    main: true,
    abstract: {
      description:
        'Genera un chat y gestiona el ticket de atención. Aprende a usar Workspace, que es tu área principal donde conversarás con tus clientes.',
    },
    description: `En ${vari.APP_NAME}, cada conversación que tienes con un cliente se convierte en un ticket. Todos los tickets pueden ser gestionados en el área de Workspace. Recuerda que tus clientes pueden iniciar la conversación, o tu también con el uso de plantillas pre-aprobadas (para el caso de WhatsApp). También, puedes personalizar tus tickets con etiquetas, campos personalizados (que se crean en módulo de configuración), agendas y más.`,
    tags: [
      {
        text: 'Compromiso',
        color: 'purple',
      },
      {
        text: 'Atención al cliente',
        color: 'blue',
      },
    ],
    assets: {
      picture: 'laptop-1.svg',
      videos: [
        {
          picture: '/img/tutos/workspace.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/dCg6Y05wwLY?rel=0&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
        },
      ],
    },
    route: { name: 'workspace' },
    extras: {
      paragraphs: {
        first: null,
        second: 'Para más información de Workspace',
      },
      actions: {
        paragraph_first: null,
      },
      linkMoreInformation:
        'https://www.securitec.pe/docs/ticker/primeros-pasos/workspace/',
    },
  },
  {
    _id: 'activateChatbot',
    title: 'Crea tu chatbot',
    main: true,
    abstract: {
      description:
        'Implementa y activa un chatbot tu mismo en menos de 5 minutos. Brindar atención 24x7 a tus clientes nunca fue tan fácil.',
    },
    description: `Crea tu primer chatbot en menos de 5 minutos. Recuerda que debes tener un canal activo para activar un chatbot, por eso te aconsejamos que integres tu página de Facebook a ${vari.APP_NAME} y empieces a recibir las conversaciones de tus clientes, o, solicita el API de WhatsApp. Podrás crear chatbots por diferentes rangos horarios para que tengas todos los casos cubiertos. ¡Mejora tus tiempos de atención y prospecta mejor a tus posibles compradores!`,
    tags: [
      {
        text: 'Compromiso',
        color: 'purple',
      },
      {
        text: 'Atención al cliente',
        color: 'blue',
      },
    ],
    assets: {
      picture: 'chatbot-cat.svg',
      videos: [
        {
          picture: '/img/tutos/chatbots.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/Jp9r64IYEvQ?rel=0&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=Jp9r64IYEvQ&ab_channel=Ticker',
        },
      ],
    },
    route: { name: 'chatbots' },
    extras: {
      paragraphs: {
        first: 'para iniciar la creación de tu primer chatbot',
        second: `Para más información de cómo crear chatbots en ${vari.APP_NAME}`,
      },
      actions: {
        paragraph_first: 'openCreateChatbot',
      },
      linkMoreInformation:
        'https://www.securitec.pe/docs/ticker/chatbots/creacion-chatbot/',
    },
  },
  {
    _id: 'tuto4',
    title: 'Usuarios',
    module: 'accounts',
    abstract: {
      description:
        'Gestiona usuarios por diferentes perfiles y asígnalos a tus colas preferidas.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      picture: 'chatbot-cat.png',
      urlDoc:
        'https://www.securitec.pe/docs/ticker/primeros-pasos/gestion-usuarios/',
      videos: [
        {
          picture: '/img/tutos/basic_picture.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/E0EzysG7DLE?start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto5',
    title: 'Canales',
    module: 'channels',
    abstract: {
      description:
        'Habilita tus canales de comunicación como WhatsApp, Facebook Messenger y más.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc: 'https://www.securitec.pe/docs/ticker/primeros-pasos/canales/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          picture: '/img/tutos/basic_picture.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/E0EzysG7DLE?start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  // se comenta, hasta que vuelva sandbox
  // {
  //   _id: 'tuto6',
  //   title: 'Sandbox de WhatsApp Business API',
  //   module: 'channels',
  //   abstract: {
  //     description:
  //       'Mira cómo funciona WhatsApp en nuestro ambiente de pruebas. ¡Querrás solicitar tu API al instante!',
  //   },
  //   description:
  //     'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
  //   assets: {
  //     urlDoc:
  //       'https://www.securitec.pe/docs/ticker/primeros-pasos/sandbox-whatsapp-business-api/',
  //     picture: 'chatbot-cat.png',
  //     videos: [
  //       {
  //         picture: '/img/tutos/basic_picture.png',
  //         iframe:
  //           '<iframe width="90%" height="515" src="https://www.youtube.com/embed/E0EzysG7DLE?start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  //         link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
  //       },
  //     ],
  //   },
  //   extras: {
  //     paragraphs: {
  //       first: 'para completar el formulario de solicitud del API de WhatsApp',
  //       second: 'Para más información de cómo solicitar tu WBA',
  //     },
  //   },
  // },
  {
    _id: 'tuto7',
    title: 'Supervisión',
    module: 'supervision',
    abstract: {
      description:
        'Monitorea en tiempo real las conversaciones de tus agentes con tus clientes.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/caracteristicas/supervision/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          picture: '/img/tutos/basic_picture.png',
          iframe:
            '<iframe width="90%" height="515" src="https://www.youtube.com/embed/E0EzysG7DLE?start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          link: 'https://www.youtube.com/watch?v=E0EzysG7DLE',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto8',
    title: 'Dashboard',
    module: 'dashboard',
    abstract: {
      description: 'Visualiza tus datos en tiempo real y descárgalos en Excel.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/caracteristicas/dashboards/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto9',
    title: 'Colas',
    module: 'queues',
    abstract: {
      description:
        'Gestiona colas de atención según tus reglas y asígnales agentes.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc: 'https://www.securitec.pe/docs/ticker/caracteristicas/colas/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto10',
    title: 'Gestor de tickets',
    module: 'tickets',
    abstract: {
      description:
        'Administra los tickets de toda tu empresa. Revisa sus propiedades, asignaciones y más.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/caracteristicas/gestor-tickets/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto11',
    title: 'Contactos',
    module: 'contacts',
    abstract: {
      description:
        'Administra todos los clientes que te han contactado. Revisa sus propiedades, historiales y más.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc: 'https://www.securitec.pe/docs/ticker/caracteristicas/contactos/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto12',
    title: '¿Qué necesito para acceder al API de WhatsApp?',
    module: 'channels',
    abstract: {
      description:
        'Entérate que necesita tu empresa para acceder al API de WhatsApp Business. Nosotros te ayudamos.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/whatsapp/acceder-api-whatsapp-business/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto13',
    title: '¿Qué son y cómo funcionan las plantillas de WhatsApp?',
    module: 'channels',
    abstract: {
      description:
        'Comunícate primero con tus clientes a través de las plantillas aprobadas por WhatsApp.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/whatsapp/plantillas-whatsapp/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto14',
    title: `Integra Facebook Messenger a ${vari.APP_NAME}`,
    module: 'channels',
    abstract: {
      description:
        'Integra las conversaciones del Facebook de tu empresa en un par de minutos.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/facebook/integracion-facebook-ticker/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  {
    _id: 'tuto15',
    title: 'Configuración de Workspace',
    module: 'settings',
    abstract: {
      description:
        'Administra propiedas exclusivas de tu negocio, como etiquetas, campos personalizados y buzones.',
    },
    description:
      'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
    assets: {
      urlDoc:
        'https://www.securitec.pe/docs/ticker/caracteristicas/configuracion-workspace/',
      picture: 'chatbot-cat.png',
      videos: [
        {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
      ],
    },
    extras: {
      paragraphs: {
        first: 'para completar el formulario de solicitud del API de WhatsApp',
        second: 'Para más información de cómo solicitar tu WBA',
      },
    },
  },
  // {
  //   _id: 'tuto5',
  //   title: 'Market',
  //   module: 'market',
  //   abstract: {
  //     description:
  //       'Automatiza las notificaciones y proporciona soporte a través de Inbox o de la API  de WhatsApp.',
  //   },
  //   description:
  //     'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
  //   assets: {
  //     videos: [
  //       {
  //         description:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //       },
  //     ],
  //   },
  // },
  // {
  //   _id: 'tuto6',
  //   title: 'Agenda',
  //   module: 'agend',
  //   abstract: {
  //     description:
  //       'Automatiza las notificaciones y proporciona soporte a través de Inbox o de la API  de WhatsApp.',
  //   },
  //   description:
  //     'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
  //   assets: {
  //     videos: [
  //       {
  //         description:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //       },
  //     ],
  //   },
  // },
  // {
  //   _id: 'tuto7',
  //   title: 'Perfil',
  //   module: 'profile',
  //   abstract: {
  //     description:
  //       'Automatiza las notificaciones y proporciona soporte a través de Inbox o de la API  de WhatsApp.',
  //   },
  //   description:
  //     'Son las siglas en inglés de “Private Branch Exchange”, la cual es una red telefónica privada utilizada dentro de una empresa. Los usuarios de un Sistema Telefónico PBX (teléfonos o extensiones) comparten las líneas de salida para realizar llamadas telefónicas externas.',
  //   assets: {
  //     videos: [
  //       {
  //         description:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //       },
  //     ],
  //   },
  // },
]

export default tutos
