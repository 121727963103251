var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{attrs:{"label":"Seleccione la zona horaria"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'timezone',
        {
          rules: [
            { required: false, message: 'Por favor rellene el campo' },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            },
          ],
        },
      ]),expression:"[\n        'timezone',\n        {\n          rules: [\n            { required: false, message: 'Por favor rellene el campo' },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"show-search":"","placeholder":"Seleccione la zona horaria"},on:{"change":_vm.handleSetTimezone}},_vm._l((_vm.globalTimezones),function(timezone){return _c('a-select-option',{key:timezone.timezone},[_vm._v(" "+_vm._s(timezone.utc)+" "+_vm._s(timezone.timezone)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Seleccione los días","required":false,"help":_vm.helpStatusDays}},[_c('GroupSelectedButtons',{ref:"groupSelectedButtons",attrs:{"dataButtons":_vm.days,"allSelecteds":false,"selecteds":_vm.daysSelecteds},on:{"buttonSelecteds":_vm.setDaysSelected}})],1),(_vm.arrayRangePickers.length > 0)?_c('a-form-item',{attrs:{"label":"Seleccione los rangos horarios por día","required":false}},[_c('list-range-pickers',{ref:"arrayRangePickers",attrs:{"data-source":_vm.sortDataSource(_vm.arrayRangePickers).slice()},on:{"onChangeRange":_vm.handleChangeRange,"onAddRange":_vm.handleAddRange,"onRemoveRange":_vm.handleRemoveRange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }