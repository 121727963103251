<template>
  <a-row type="flex" justify="space-between">
    <a-row type="flex">
      <!--Emojis-->
      <div class="menu__option">
        <emoji-picker
          :icon-styles="{
            fontSize: '16px',
          }"
          :show-tooltip="true"
          @onChangeEmoji="(emoji) => $emit('onChangeEmoji', emoji)"
          :disabled="disabledEmoji"
        />
      </div>
      <!--Esta opcion estará deshabilitada hasta que se habilite la carga de archivos-->
      <div v-if="false" class="menu__option">
        <a-icon type="paper-clip" class="option__icon" />
      </div>
      <!--Listado de variables-->
      <a-tooltip>
        <span v-if="!existCatcher" slot="title">
          Variables: Para utilizar esta opción, recuerda primero agregar alguno
          de estos nodos: “capturar variable”, "listas", "tarjetas" o "botones".
        </span>
        <div
          class="menu__option"
          :class="{ 'menu__option--disabled': !existCatcher || disabledVars }"
          @click="handleOpenVars"
        >
          <a-icon type="number" class="option__icon" />
        </div>
      </a-tooltip>
    </a-row>
    <!--Esperar respuesta-->
    <a-row v-if="showWait" type="flex" align="middle">
      <a-checkbox
        :checked="valueCheck"
        :disabled="disabledWaitAnswer"
        @change="handleChangeWait"
      >
        Esperar una respuesta
      </a-checkbox>
      <a-tooltip>
        <template slot="title">
          Tu chatbot esperará una respuesta de tu cliente para continuar.
        </template>
        <a-icon
          type="info-circle"
          class="radio-message__icon"
          style="height: fit-content; margin-top: 2px"
      /></a-tooltip>
    </a-row>
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'
import EmojiPicker from '@/app/tickets/components/molecules/EmojiPicker'
import verifyExistCatcher from '@/app/chatbots/mixins/actionsToNodes'

export default {
  name: 'MenuOptionsContent',
  components: {
    EmojiPicker,
  },
  mixins: [verifyExistCatcher],
  props: {
    disabledWaitAnswer: {
      type: Boolean,
      required: false,
      default: true,
    },
    valueCheck: {
      type: Boolean,
      required: false,
      default: true,
    },
    showWait: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabledEmoji: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledVars: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['nodes', 'selectedNodeId']),

    /**
     * @return {Boolean} - comprueba si existe un nodo de tipo catcher
     */
    existCatcher() {
      return this.verifyExistCatcher({ nodeId: this.selectedNodeId })
    },
  },
  methods: {
    /**
     * Cambia el valor de wait
     * @param {Boolean} wait - espera respuesta o no
     */
    handleChangeWait(e) {
      const checked = e.target.checked
      this.$emit('onChangeWait', checked)
    },
    handleOpenVars() {
      if (!this.existCatcher) return
      this.$emit('onOpenVars')
    },
  },
}
</script>

<style lang="sass" scoped>
$size_option: 28px

.menu__option
  padding: 6px
  background-color: $gray_3
  width: $size_option
  margin-right: 4px
  border-radius: 4px
  height: $size_option
  @include flex(row, center, center)
  cursor: pointer
  &--disabled
    color: $gray_6
    cursor: not-allowed
.option__icon
  font-size: 16px
.radio-message
  &__icon
    font-size: 14px
</style>
