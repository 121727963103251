import { mapGetters } from 'vuex'
import { MULTIMEDIA } from '@/app/chatbots/utils/utilMultimedia'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  computed: {
    ...mapGetters(['nodeSelected']),

    /**
     * Retorna el tipo de nodo
     * @returns {String}
     */
    type() {
      if (!this.nodeSelected) return ''
      else {
        if (!this.nodeSelected.drawer) {
          const nodeParent = this.nodes.find(
            (node) => node._id === this.nodeSelected.parentNodeId
          )
          const structureParent = NODES[nodeParent.drawer.question.type]
          if (this.nodeSelected.is_subchild)
            return structureParent.child.child.type
          else return structureParent.child.type
        } else return this.nodeSelected.drawer.question.type
      }
    },
    /**
     * Multimedia permitida para el nodo
     * @returns {Object}
     */
    multimediaForNode() {
      return MULTIMEDIA.filter((item) => item.enabled_nodes.includes(this.type))
    },
  },
}
