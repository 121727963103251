<template>
  <div class="bubble">
    <div>
      <a-avatar :src="avatar" alt="Avatar" class="bubble_avatar" :size="32" />
    </div>
    <div class="bubble_content" :style="{ backgroundColor: ` ${bgColor}` }">
      <p class="body-2 message">{{ message }}</p>
      <p class="body-1 subtext mrg-bottom-0">{{ subText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bubble',
  props: {
    bgColor: {
      type: String,
      required: false,
      default: '#E6F7FF',
    },
    message: {
      type: String,
      required: false,
      default: 'Hola este es un mensaje',
    },
    subText: {
      type: String,
      required: false,
      default: 'Mariela',
    },
    avatar: {
      type: String,
      required: false,
      default:
        'https://images.unsplash.com/photo-1622385159874-2c5823309a0e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    },
  },
}
</script>

<style lang="sass" scoped>
.bubble
  display: flex
  align-items: flex-end
  margin-bottom: 20px
  &_avatar
    margin-right: 8px
  &_content
    padding: 12px
    border-radius: 16px 16px 16px 0px
    width: -webkit-fill-available
    text-align: left
    .message
      margin-bottom: 4px
      color: $gray_dark_900
    .subtext
      color: $gray_dark_800
</style>
