import Dashboard from '@/app/dashboard/views/Dashboard'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/dashboards',
    name: 'dashboards',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'master_agent'],
      title: `Dashboards | ${VUE_APP_NAME}`,
    },
  },
]
