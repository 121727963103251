import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import router from '@/router'

/**
 * Escuchar log de advertencias de Vuejs
 * @param msg: messaje de advertencia
 * @param vm: componente de Vue
 * @param info: stack de advertencia o ruta de componente
 * @returns {Promise<void>}
 */
const onWarn = async (msg, vm, info) => {
  const body = {
    route: router.currentRoute.path,
    data: {
      warn: {
        name: 'warning',
        message: msg,
        stack: info,
      },
    },
  }
  if (process.env.NODE_ENV === 'development') console.warn(msg, '\n', info)
  /* createLogger(body) */
  return body
}

/**
 * Escuchar log de errores de Vuejs
 * @param err: messaje de advertencia
 * @returns {Promise<void>}
 */
const onErr = async (err) => {
  const body = {
    route: router.currentRoute.path,
    data: {
      error: {
        name: 'error',
        message: err.message,
        stack: err.stack,
      },
    },
  }
  if (process.env.NODE_ENV === 'development') throw err
  createLogger(body)
}

/**
 * Crear log en base de datos
 * @param {Object} body
 * @returns {Promise<void>}
 */
const createLogger = (body) => {
  body._v = vari.APP_VERSION
  const access_token = localStorage.getItem('access_token')
  if (!access_token || router.currentRoute.name === 'error') return

  const url = `${vari.UHR}/log`
  const uninterceptedAxiosInstance = axios.create()
  uninterceptedAxiosInstance
    .post(url, body, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(() => {
      console.log('createLogger')
    })
    .catch(() => {
      console.warn('Cannot createLogger')
    })
}

export { onWarn, onErr }
