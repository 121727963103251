<template>
  <div class="manage-tickets-template">
    <SectionManageTickets />
  </div>
</template>
<script>
import SectionManageTickets from '@/app/manageTickets/components/organisms/SectionManageTickets.vue'

export default {
  name: 'ManageTicketsTemplate',
  components: {
    SectionManageTickets,
  },
}
</script>
<style lang="sass" scoped>
.manage-tickets-template
  padding: $padding-heigth-inner
</style>
