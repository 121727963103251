<template>
  <div class="voice-recorder display-flex align--center">
    <!--Contenido del audio-->
    <div v-if="isStart" class="voice__container display-flex align--center">
      <!--boton de eliminar audio-->
      <a-tooltip title="Eliminar audio">
        <a-icon
          type="delete"
          @click="handleDelete"
          class="voice__icon voice__icon-delete cursor-pointer"
        />
      </a-tooltip>
      <div class="voice__content">
        <audio v-if="urlAudio" class="voice__audio" controls>
          <source :src="urlAudio" />
        </audio>
        <div v-else class="display-flex align--center">
          <div class="voice__rec blink"></div>
          <span class="voice__timer">{{ timerFormatted }}</span>
          <span class="regular-14 mrg-bottom-0"> Grabando...</span>
        </div>
      </div>
      <!--boton de finalizar audio-->
      <a-tooltip title="Detener">
        <i
          v-if="!urlAudio"
          class="voice__icon anticon voice__icon-end cursor-pointer"
          @click="handleStop"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'24'" v-bind:width="'24'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12zm20 0a9 9 0 11-18 0 9 9 0 0118 0zm-6-5a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9a2 2 0 012-2h6zm-6 8V9h6v6H9z" fill="currentColor"/></svg>
        </i>
      </a-tooltip>
    </div>
    <!--Boton de comenzar audio-->
    <i
      v-if="!isStart"
      class="voice__icon anticon cursor-pointer voice__icon-start"
      :class="{ 'voice__icon--disabled cursor-not-allowed': disabled }"
      @click="handleStart"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'26'" v-bind:width="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1a4 4 0 014 4v7a4 4 0 01-8 0V5a4 4 0 014-4zm1 18.938V21h3v2H8v-2h3v-1.062A8.001 8.001 0 014 12v-2h2v2a6 6 0 0012 0v-2h2v2a8.001 8.001 0 01-7 7.938zM10 5a2 2 0 114 0v7a2 2 0 11-4 0V5z" fill="currentColor"/></svg>
    </i>
    <!--Boton de enviar audio-->
    <i
      v-else
      class="voice__icon anticon cursor-pointer voice__icon-send"
      @click="handleSendAudio"
    >
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'26'" v-bind:width="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.74 2.252l1.365 9.555.772.193-.772.193-1.365 9.555L22.236 12 2.74 2.252zm3.155 7.94L5.26 5.749 17.764 12 5.26 18.252l.635-4.445L13.123 12l-7.228-1.807z" fill="currentColor"/></svg>
    </i>
  </div>
</template>

<script>
import MicRecorder from 'mic-recorder-to-mp3'
import formatMixin from '@/app/shared/mixins/format'

export default {
  name: 'VoiceRecorder',
  props: {
    onUpload: {
      type: Function,
      required: true,
      default: null,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    recorder: null,
    urlAudio: null,
    isStart: false,
    timerSeconds: 0,
    timer: null, // Funcion del timer
    isDeleted: false,
  }),
  mixins: [formatMixin],
  mounted() {
    this.$nextTick(() => this.verifiedRecorder())
  },
  computed: {
    /**
     * Devuelve el tiempo que ha pasado en un formato de texto
     * @return {String} tiempo formateado
     */
    timerFormatted() {
      if (this.timerSeconds < 60)
        return this.timerSeconds < 10
          ? `0:0${this.timerSeconds}`
          : `0:${this.timerSeconds}`
      else if (this.timerSeconds < 3600)
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toMinutes'
        )}`
      else
        return `${this.timeFormatted(this.timerSeconds, 'seconds', 'toHours')}`
    },
  },
  methods: {
    /**Verifica que el navegador pueda usar la grabadora */
    verifiedRecorder() {
      if (!navigator.mediaDevices?.getUserMedia)
        return console.error('getUserMedia not supported on your browser!')
      else {
        console.log('getUserMedia supported on your browser')
      }
    },
    /**
     * Comienza a grabar
     */
    handleStart() {
      if (this.disabled) return
      this.$emit('onStart')
      this.isDeleted = false
      // New instance
      this.recorder = new MicRecorder({
        bitRate: 128,
      })

      // Start recording. Browser will request permission to use your microphone.
      this.recorder
        .start()
        .then(() => {
          this.handleSetStart(true)
          this.startTimer()
        })
        .catch((e) => {
          console.error(e)
          console.warn('error start', e)
          this.handleSetStart(false)
          this.$emit('onDelete')
          this.$message.error('Se necesitan permisos para poder grabar')
        })
    },
    /**
     * Finaliza la grabacion
     * @param {Boolean} forceSend - fuerza a enviar directamente el audio
     */
    handleStop({ forceSend = false } = {}) {
      this.stopTimer()
      this.recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          // si ya esta eliminado no hara nada
          if (this.isDeleted) return
          // convierte a un archivo
          const fileName = new Date().toISOString() + '.mp3'
          const file = new File(buffer, fileName, {
            type: blob.type,
            lastModified: Date.now(),
          })
          if (forceSend) this.handleSetStart(false)
          if (!forceSend) {
            const url = URL.createObjectURL(file)
            this.urlAudio = url
          }
          await this.onUpload({ file })
          if (forceSend) {
            this.$emit('onSend')
            this.handleReset()
          }
        })
        .catch((e) => {
          alert('We could not retrieve your message')
          console.error(e)
        })
    },
    /**
     * Elimina el audio
     */
    handleDelete() {
      this.isDeleted = true
      this.handleStop()
      this.urlAudio = null
      this.handleSetStart(false)
      this.$emit('onDelete')
    },
    /**
     * Inicia la cuenta del contador
     */
    startTimer(initialTime = 0) {
      this.timerSeconds = initialTime
      this.timer = setInterval(() => {
        this.timerSeconds++
      }, 1000)
    },
    /**
     * Finaliza la cuenta del contador
     */
    stopTimer() {
      clearInterval(this.timer)
      this.timerSeconds = 0
    },
    /**
     * Enviar audio
     */
    handleSendAudio() {
      if (!this.urlAudio) this.handleStop({ forceSend: true })
      else {
        this.$emit('onSend')
        this.handleReset()
      }
    },
    /**
     * Setea valor a isStart
     * @param {Boolean} isStart
     */
    handleSetStart(isStart) {
      this.isStart = isStart
      this.$emit('onChangeStart', isStart)
    },
    /**
     * Resetea todos datos
     */
    handleReset() {
      this.recorder = null
      this.urlAudio = null
      this.handleSetStart(false)
      this.timerSeconds = 0
      this.timer = null // Funcion del timer
      this.isDeleted = false
      this.stopTimer()
    },
  },
}
</script>

<style lang="sass" scoped>
.voice
  &-recorder
    padding: 4px 0px
    height: 40px
  &__container
    margin: 0px 12px
  &__icon
    font-size: 24px
    &-start
      color: $gray_dark_700
      font-size: 26px
    &-end
      color: $red_6
      margin-left: 12px
    &-delete
      color: $gray_dark_900
      margin-right: 12px
    &-send
      color: $gray_dark_700
    &--disabled
      color: $gray_6
  &__rec
    height: 12px
    width: 12px
    border-radius: 50%
    background-color: $red_6
    margin-right: 8px
  &__timer
    margin-right: 12px
  &__content
    color: $gray_dark_900
    max-height: 43px
  &__audio
    height: 43px

.blink
  animation-name: blinking
  animation-duration: 1s
  animation-timing-function: linear
  animation-iteration-count: infinite

  -webkit-animation-name:blinking
  -webkit-animation-duration: 1s
  -webkit-animation-timing-function: linear
  -webkit-animation-iteration-count: infinite

@keyframes blinking
  0%
    opacity: 1.0
  50%
    opacity: 0.0
  100%
    opacity: 1.0
</style>
