var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-item-footer",class:{
    'menu-item-footer--collapse': _vm.collapse,
    'menu-item-footer--no-collapse': !_vm.collapse,
  }},[_c('div',{staticClass:"menu-item-footer__content"},[_vm._t("avatar",function(){return [_c('avatar-status',{staticClass:"menu-item-footer__avatar",class:{
          'menu-item-footer__avatar--show-status':
            _vm.profileStatus.borderMin > 0,
        },attrs:{"shape":"circle","status":_vm.profileStatus.state,"border":_vm.profileStatus.borderMin,"padding":_vm.profileStatus.paddingMin}},[(_vm.avatar)?_c('a-avatar',{attrs:{"src":_vm.avatar,"size":32,"alt":"Avatar de perfil"}}):_c('DefaultIconLetter',{attrs:{"names":_vm.title}})],1)]}),(_vm.collapse)?_c('div',{staticClass:"menu-item-footer__texts"},[_c('p',{staticClass:"body-3 menu-item-footer__text menu-item-footer__text--username mrg-bottom-0 ellipsis",staticStyle:{"display":"inherit"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c('p',{staticClass:"body-1 menu-item-footer__text menu-item-footer__text--subtitle mrg-bottom-0 ellipsis",staticStyle:{"display":"inherit"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }