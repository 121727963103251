<template>
  <ticket-template />
</template>

<script>
import TicketTemplate from '@/app/tickets/components/templates/TicketTemplate'

export default {
  name: 'Ticket',
  components: {
    TicketTemplate,
  },
}
</script>

<style scoped></style>
