import axios from 'axios'
import router from '@/router'
import vari from '@/app/shared/utils/variables'
import filters from '@/app/shared/utils/filters'

const actions = {
  /**
   * Marcar un ticket como no leído
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {String} args.mailboxId Id del buzón actual
   * @param {Boolean} args.massive ¿Es masivo? solo se emitirá al visor si no lo es
   */
  async updateAsUnread(context, { idTicket, mailboxId, massive = false }) {
    try {
      context.commit('REMOVE_TICKET_ID_UNREAD', idTicket)
      const success = context.dispatch('addTicketUnread', {
        idTicket,
        mailboxId,
      })
      if (!success) return

      axios.put(`${vari.UHR}/agent/ticket/unread`, { idTicket })
      if (!massive) {
        context.dispatch('cleanTicketSelected')
        context.commit('SET_CLIENT', null)
        context.dispatch('saveEventTicket')
      }
      context.dispatch('setAnalytics', { eventName: 'ticket_mark_as_unread' })
    } catch (error) {
      console.error('[ticketSettingsModule][updateAsUnread]', error)
    }
  },
  /**
   * Marcar tickets como no leidos - MASIVO
   * @param {*} context
   * @param {Object} args
   * @param {String} args.ticketsIds - ids de tickets
   * @param {String} args.mailboxId - Id del buzón actual
   */
  async updateMassiveAsUnread(context, { ticketsIds, mailboxId }) {
    try {
      // verifica que los tickets no tengan pendientes
      // es decir, que deben estar leidos
      const ticketsReaded = context.getters.tickets.filter(
        (ticket) =>
          ticketsIds.includes(ticket._id) &&
          (!ticket.pendings || ticket.pendings == 0)
      )

      if (!ticketsReaded.length) return

      const ticketsIdsReaded = ticketsReaded.map((ticket) => ticket._id)
      const response = await axios.put(
        `${vari.UHR}/agent/ticket/unread-massive`,
        {
          ticketsIds: ticketsIdsReaded,
        }
      )

      if (response.data.success) {
        // si en los tickets no leidos está el ticket seleccionado, se limpia la vista principal
        if (
          context.getters.ticketSelected &&
          ticketsIdsReaded.includes(context.getters.ticketSelected._id)
        ) {
          context.dispatch('cleanTicketSelected')
          context.commit('SET_CLIENT', null)
          context.dispatch('saveEventTicket')
        }

        ticketsReaded.forEach((ticket) => {
          context.commit('REMOVE_TICKET_ID_UNREAD', ticket._id)
          context.dispatch('addTicketUnread', {
            idTicket: ticket._id,
            mailboxId,
            sync: false,
          })
        })
        context.dispatch('setAnalytics', { eventName: 'ticket_mark_as_unread' })
        axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
      }
      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][updateMassiveAsUnread]', error)
    }
  },
  /**
   * Marcar un ticket como favorito
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Boolean} args.starred ¿Es favorito?
   */
  async updateStarred(context, { idTicket, starred }) {
    try {
      const response = await axios.put(`${vari.UHR}/agent/room/starred`, {
        idTicket,
        starred,
      })
      await context.commit('SET_STARRED_IN_TICKET', { idTicket, starred })
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: idTicket,
        settings: { starred },
      }
      this._vm.$socket.emit('server:ticket:settings', payload_socket)
      context.dispatch('setAnalytics', { eventName: 'ticket_favorite' })
      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][updateStarred]', error)
    }
  },
  /**
   * Marcar un ticket como favorito
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Boolean} args.starred ¿Es favorito?
   */
  async updateAdminStarred(context, { idTicket, starred }) {
    try {
      const response = await axios.put(`${vari.UHR}/admin/room/starred`, {
        idTicket,
        starred,
      })
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: idTicket,
        settings: { starred },
      }
      this._vm.$socket.emit('server:ticket:settings', payload_socket)
      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][updateStarred]', error)
    }
  },
  /**
   * [AGENTES] Mover un ticket a otro buzón
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.mailbox Objeto del buzón destino
   * @param {String} args.comment
   */
  async setTicketMailbox(context, { mailbox, idTicket, comment }) {
    try {
      const body = {
        idTicket: idTicket,
        mailbox: mailbox,
      }
      if (!mailbox.end) {
        /** Mueve las cuentas de los mensajes sin leer en los buzones */
        const ticket = context.getters.tickets.find(
          (ticket) => ticket._id === idTicket
        )
        const isUnread = ticket.pendings && ticket.pendings > 0
        if (isUnread) {
          const ticket = context.getters.tickets.find(
            (ticket) => ticket._id === idTicket
          )
          const oldMailbox = ticket.mailboxId
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: oldMailbox,
            quantity: -1,
          })
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: mailbox.idMailbox,
            quantity: 1,
          })
        }
      } else {
        body.comment = comment
      }
      await axios.put(`${vari.UHR}/agent/room/mailbox`, body)
      // Quita la estrella de favoritos al finalizar
      if (mailbox.end) {
        await context.commit('SET_STARRED_IN_TICKET', {
          idTicket,
          starred: false,
        })
        context.dispatch('cleanTicketSelected')
        context.dispatch('saveEventTicket')
        router.push({ name: 'workspace' })
        context.commit('UPDATE_TOTAL_ASSIGNED_TICKETS')
        context.dispatch('setAnalytics', { eventName: 'ticket_closed' })
      }

      await context.commit('SET_MAILBOX_IN_TICKET', {
        mailbox,
        idTicket,
        end: mailbox.end,
      })
      await context.commit('QUIT_TICKETID_SELECTED', idTicket)
    } catch (error) {
      console.error('[ticketSettingsModule][setTicketMailbox] error -', error)
    }
  },
  /**
   * [AGENTES] Mover un ticket a otro buzón - MASIVO
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.mailbox Objeto del buzón destino
   * @param {String} args.comment
   */
  async moveMassiveTicketsMailbox(context, { mailbox, ticketsIds }) {
    try {
      const body = {
        ticketsIds,
        newMailboxId: mailbox.idMailbox,
      }
      // TODO: Este proceso se ejecuta por sockets
      // /** Mueve las cuentas de los mensajes sin leer en los buzones */
      // const ticketsUnread = context.getters.tickets.filter(
      //   (ticket) =>
      //     ticketsIds.includes(ticket._id) &&
      //     ticket.pendings &&
      //     ticket.pendings > 0
      // )
      // if (ticketsUnread.length > 0) {
      //   ticketsUnread.forEach((ticketUnread) => {
      //     const oldMailbox = ticketUnread.mailboxId
      //     context.commit('SUM_NOTIFICATIONS_MAILBOX', {
      //       idMailbox: oldMailbox,
      //       quantity: -1,
      //     })
      //     context.commit('SUM_NOTIFICATIONS_MAILBOX', {
      //       idMailbox: mailbox.idMailbox,
      //       quantity: 1,
      //     })
      //   })
      // }

      const response = await axios.put(
        `${vari.UHR}/agent/room/mailbox-massive`,
        body
      )

      ticketsIds.forEach(async (ticketId) => {
        await context.commit('SET_MAILBOX_IN_TICKET', {
          mailbox,
          idTicket: ticketId,
          end: false,
        })
        await context.commit('QUIT_TICKETID_SELECTED', ticketId)
      })
      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][setTicketMailbox] error -', error)
    }
  },
  /**
   * [ADMINISTRADORES] Mover un ticket a otro buzón,
   * TODO: Usado actualmente para mover los tickets al buzón de finalizados,
   * desde el Gestor de tickets
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.mailbox Objeto del buzón destino
   */
  async setAdminTicketMailbox(
    context,
    { mailbox, idTicket, comment, tickets, massive }
  ) {
    try {
      const body = {
        idTicket: idTicket,
        mailbox: mailbox,
        massive,
        tickets,
      }

      if (mailbox.end) {
        body.comment = comment
      }
      const response = await axios.put(`${vari.UHR}/admin/room/mailbox`, body)

      let ticketsUnread = []
      // si existen tickets
      if (tickets && !!tickets.length) {
        // filtra los tickets no leidos
        ticketsUnread = tickets.filter(
          (ticket) => ticket.pendings && ticket.pendings > 0
        )
      }
      // si el ticket que se esta moviendo está dentro de los tickets no Leidos
      if (idTicket) {
        {
          // busqueda del ticket en los tickets del gestor
          const ticket = context.getters.ticketsToManage.data.find(
            (ticket) => ticket._id === idTicket
          )
          const isUnread = ticket.pendings && ticket.pendings > 0
          ticket !== undefined && isUnread && ticketsUnread.push(ticket)
        }
      }

      // actualiza las notificaciones de los buzones, solo si son mis tickets
      const { ticketsId } = filters.filterTicketsByUserId(
        ticketsUnread,
        context.getters.profile.userId
      )
      const ticketsIdUnread = ticketsId
      ticketsUnread.forEach((ticketUnread) => {
        if (ticketsIdUnread.includes(ticketUnread._id)) {
          const oldMailbox = ticketUnread.mailboxId
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: oldMailbox,
            quantity: -1,
          })
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: mailbox.idMailbox,
            quantity: 1,
          })
        }
      })
      // Al ultimo para evitar posible errores con la peticion
      // Obtiene el total de tickets asignados del agente
      context.dispatch('getTotalAssignedTickets')

      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][setTicketMailbox]', error)
      return { success: false }
    }
  },
  /**
   * Se transfiere el ticket a una cola o a otro agente
   * @param {*} context
   * @param {Object} args
   * @param {String} args.typeTransfer // Tipo de transferencia [line, agent]
   * @param {Object} args.ticket // objeto del ticket a transferir
   * @param {Object} [args.agent] // Objeto del agente destino a quien se va a transferir
   * @param {Object} [args.line] // Objeto de la cola destino a donde se va a transferir
   * @param {Boolean} [args.massive] // Es una transferencia masiva?
   * @param {Object[]} [args.tickets] // Objetos de los tickets a transferir
   */
  async transferAdminTicket(
    context,
    { typeTransfer, ticket, agent, line, massive, tickets }
  ) {
    // Solo se recibe el objeto "agent" o "line"
    try {
      const nameCurrentAgent = context.getters.profile.names
      let last_userId = null
      let response = null
      let text = ''

      // Transferencia a agente directo
      if (typeTransfer === 'agent') {
        last_userId = ticket.users
          ? ticket.users[ticket.users.length - 1]?.userId
          : null
        text = `"${nameCurrentAgent}" ha transferido este ticket a "${agent.names}"`
        response = await axios.put(
          `${vari.UHR}/admin/room/transfer/${typeTransfer}`,
          { idTicket: ticket._id, agent, text }
        )
        let ticketToTransfer = ticket
        ticketToTransfer.pendings = response.data.ticket.pendings
        ticketToTransfer.abstract = {
          message: {
            text: text,
          },
          method: 'note',
        }
        ticketToTransfer.client = {
          ...ticket.client,
          clientId: ticket.client._id,
        }

        context.dispatch('updateStatusInTicketsToManage', {
          status: response.data.ticket,
          subStatus: response.data.ticket.subStatus,
          ticket: { ...response.data.ticket },
        })

        // si el ticket que se esta transfiriendo está dentro de los tickets no Leidos
        if (ticketToTransfer) {
          const { ticketsId } = filters.filterTicketsByUserId(
            [ticketToTransfer],
            context.getters.profile.userId
          )
          const ticketsIdUnread = ticketsId

          if (ticketsIdUnread.includes(ticketToTransfer._id)) {
            context.commit('SUM_NOTIFICATIONS_MAILBOX', {
              idMailbox: ticketToTransfer.mailboxId,
              quantity: -1,
            })
            context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
          }
        }

        // Al ultimo para evitar posible errores con la peticion
        // Obtiene el total de tickets asignados del agente
        context.dispatch('getTotalAssignedTickets')

        return response.data
      }

      // Transferencia a cola

      text = `"${nameCurrentAgent}" ha transferido este ticket a la Cola "${line.name}"`
      const payloadTransfer = {
        idTicket: ticket ? ticket._id : null,
        line,
        text,
        massive,
        tickets,
      }
      response = await axios.put(
        `${vari.UHR}/admin/room/transfer/${typeTransfer}`,
        payloadTransfer
      )
      if (!response.data.success) return { success: false }

      agent = context.getters.profile._id

      if (!massive) {
        last_userId = ticket.users
          ? ticket.users[ticket.users.length - 1]?.userId
          : null

        let ticketToTransfer = ticket
        ticketToTransfer.abstract = text
        ticketToTransfer.userId = last_userId
        context.dispatch('updateLineInTicketsToManage', {
          line: response.data.newLine,
          ticket: response.data.ticket,
          agent: response.data.profile,
        })
        const { ticketsId } = filters.filterTicketsByUserId(
          [ticketToTransfer],
          context.getters.profile.userId
        )
        const ticketsIdUnread = ticketsId
        // si el ticket que se esta transfiriendo está dentro de los tickets no Leidos
        if (
          ticketToTransfer &&
          ticketsIdUnread.includes(ticketToTransfer._id)
        ) {
          context.commit('SUM_NOTIFICATIONS_MAILBOX', {
            idMailbox: ticketToTransfer.mailboxId,
            quantity: -1,
          })
          context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
        }
        context.commit('QUIT_TICKET', ticketToTransfer._id)

        // Al ultimo para evitar posible errores con la peticion
        // Obtiene el total de tickets asignados del agente
        context.dispatch('getTotalAssignedTickets')

        return response.data
      } else {
        const ticketsToCompare = response.data.transfers.map(
          (transfer) => transfer.ticket
        )
        const { ticketsId } = filters.filterTicketsByUserId(
          ticketsToCompare,
          context.getters.profile.userId
        )
        const ticketsIdUnread = ticketsId

        await Promise.all(
          response.data.transfers.map(async (transfer) => {
            // busca al ultimo usuario antes de hacer la transferencia
            const ticket = transfer.ticket

            last_userId = ticket.users
              ? ticket.users[ticket.users.length - 2]?.userId
              : null

            ticket.abstract = text
            context.dispatch('updateLineInTicketsToManage', {
              line: transfer.newLine,
              ticket: ticket,
              agent: transfer.profile,
            })

            // si el ticket que se esta transfiriendo está dentro de los tickets no Leidos
            if (ticket && ticketsIdUnread.includes(ticket._id)) {
              context.commit('SUM_NOTIFICATIONS_MAILBOX', {
                idMailbox: ticket.mailboxId,
                quantity: -1,
              })
              context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
            }
            context.commit('QUIT_TICKET', ticket._id)
          })
        )

        // Al ultimo para evitar posible errores con la peticion
        // Obtiene el total de tickets asignados del agente
        context.dispatch('getTotalAssignedTickets')

        return response.data
      }
    } catch (error) {
      console.error('[ticketSettingsModule][transferTicket]', error)
      return { success: false, error }
    }
  },
  /**
   * Se transfiere un solo ticket a una cola o a otro agente
   * @param {*}       context
   * @param {Object}  args
   * @param {String}  args.typeTransfer // Tipo de transferencia [line, agent]
   * @param {String}  args.ticketsIds // Ids de tickets a transferir
   * @param {Object}  args.agent // Objeto del agente destino a quien se va a transferir - opcional
   * @param {Object}  args.line // Objeto de la cola destino a donde se va a transferir - opcional
   * @param {Boolean} args.massive // Es una transferencia masiva?
   */
  async transferTickets(
    context,
    { typeTransfer, ticketsIds, agent, line, massive }
  ) {
    // Solo se recibe el objeto "agent" o "line"
    try {
      // Verifica que el ticket que se va a transferir aún le pertenezca al agente actual
      const sampleTicketToTransfer = context.getters.tickets.find(
        (ticket) => ticket._id === ticketsIds[0]
      )
      if (!sampleTicketToTransfer) return { success: false }
      const nameCurrentAgent = context.getters.profile.names
      const mailboxToTransfer = context.getters.mailboxes.find((mailbox) =>
        sampleTicketToTransfer.mailboxId
          ? sampleTicketToTransfer.mailboxId === mailbox.idMailbox
          : mailbox.default
      )
      const mailboxToTransferId = mailboxToTransfer.idMailbox
      let textNote = ''
      let response = null

      if (typeTransfer === 'agent') {
        console.warn('t-agent')
        textNote = `"${nameCurrentAgent}" ha transferido este ticket al usuario "${agent.names}"`
        response = await axios.put(
          `${vari.UHR}/agent/ticket/transfer/${typeTransfer}`,
          {
            ticketsIds,
            agent,
            mailboxToTransferId,
            textNote,
          }
        )
        // context.commit('QUIT_TICKETID_SELECTED', idTicket)
      } else if (typeTransfer === 'line') {
        console.warn('t-line')
        textNote = `"${nameCurrentAgent}" ha transferido este ticket a la Cola "${line.name}"`
        response = await axios.put(
          `${vari.UHR}/agent/ticket/transfer/${typeTransfer}`,
          {
            ticketsIds,
            line,
            mailboxToTransferId,
            textNote,
          }
        )
        // context.commit('QUIT_TICKETID_SELECTED', idTicket)
        agent = context.getters.profile._id
      }

      const { quantityTransfered, quantityAsRead } = response.data
      context.commit('SUM_NOTIFICATIONS_MAILBOX', {
        idMailbox: sampleTicketToTransfer.mailboxId,
        quantity: quantityAsRead * -1,
      })
      context.commit('UPDATE_TOTAL_UNREAD_TICKETS')
      for (const idTicket of ticketsIds) {
        context.dispatch('addNoteMessage', {
          idTicket,
          textNote,
          mailboxId: mailboxToTransferId,
          removed: true,
          emit: false,
        })
        context.commit('QUIT_TICKETID_SELECTED', idTicket)
        context.commit('QUIT_TICKET', idTicket)
        context.commit('QUIT_SCHEDULES_IN_TICKET', idTicket)
      }

      if (!massive) {
        // Si el ticket es igual al ticket seleccionado se limpiara
        if (context.getters.ticketSelected) {
          if (ticketsIds.includes(context.getters.ticketSelected._id)) {
            context.dispatch('cleanTicketSelected')
          }
        }
        context.dispatch('saveEventTicket')
      }

      context.dispatch('setAnalytics', {
        eventName: 'ticket_transfer',
        extraData: {
          transfer_target: typeTransfer !== 'line' ? typeTransfer : 'queue',
        },
      })

      return { success: true, quantityTransfered, quantityAsRead }
    } catch (error) {
      console.error('[ticketSettingsModule][transferTicket]', error)
      return { success: false, ...error.response.data }
    }
  },
  /**
   * TODO: Para Workspace
   * Agrega el tag a un ticket si no existe, en caso contrario, lo quita
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.tag Objeto del tag a guardar
   * @param {String} args.tag.idTag Id de la etiqueta
   * @param {Boolean} args.checked
   * @return {Object} Respuesta de exito del servidor
   */
  async saveTagTicket(context, { idTicket, tag, checked }) {
    try {
      const ticket = context.getters.tickets.find(
        (ticket) => ticket._id === idTicket
      )
      if (!ticket) return

      const tagsAdded = []
      const tagsRemoved = []

      let ticketTags = ticket.settings.tags
      // si la etiqueta no existe en las etiquetas del ticket
      if (checked && !ticketTags.some((tg) => tg.tagId === tag._id)) {
        tag.tagId = tag._id
        ticketTags.push(tag)
        tagsAdded.push(tag)
      } else {
        if (checked) return
        // si la etiqueta existe en el ticket, entonces no lo contará
        ticketTags = ticketTags.filter((tg) => tg.tagId !== tag._id)
        tagsRemoved.push(tag)
      }

      context.commit('SET_TAG_TICKETS', { idTicket, tags: ticketTags })

      const response = await axios.put(
        `${vari.UHR}/agent/room/tags/${idTicket}`,
        { tagsAdded, tagsRemoved }
      )

      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: idTicket,
        settings: { ticketTags },
      }
      this._vm.$socket.emit('server:ticket:settings', payload_socket)
      context.dispatch('setAnalytics', { eventName: 'ticket_tag' })
      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][saveTagsTicket]', error)
    }
  },

  /**
   * TODO: No se esta usando esta funcion
   * Agrega el tag a un ticket si no existe, en caso contrario, lo quita
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.tag Objeto del tag a guardar
   * @param {String} args.tag.idTag Id de la etiqueta
   * @return {Object} Respuesta de exito del servidor
   */
  async updateTicketTags(context, { idTicket, tags }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/agent/room/tags/${idTicket}`,
        { tags }
      )
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: idTicket,
        settings: { tags },
      }
      this._vm.$socket.emit('server:ticket:settings', payload_socket)

      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][saveTagsTicket]', error)
    }
  },
  /**
   * TODO: Para el CRM - Gestor de tickets
   * Agrega el tag a un ticket si no existe, en caso contrario, lo quita
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.tag Objeto del tag a guardar
   * @param {String} args.tag.idTag Id de la etiqueta
   * @param {Object[]} args.ticket
   * @return {Object} Respuesta de exito del servidor
   */
  async updateAdminTicketTags(
    context,
    { idTicket, tags, tagsRemoved, tagsAdded }
  ) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/room/tags/${idTicket}`,
        { tagsRemoved, tagsAdded }
      )
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: idTicket,
        settings: { tags },
      }
      this._vm.$socket.emit('server:ticket:settings', payload_socket)

      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][saveTagsTicket]', error)
    }
  },
  /**
   * TODO: Para el CRM - Gestor de tickets
   * Agrega el tag a varios tickets si no existe, en caso contrario, lo quita
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Object} args.tag Objeto del tag a guardar
   * @param {String} args.tag.idTag Id de la etiqueta
   * @param {Boolean} args.checked
   * @return {Object} Respuesta de exito del servidor
   */
  async updateTagMasive(context, { tag, tickets, checked }) {
    try {
      const formattedTickets = tickets.map((ticket) => {
        let ticketTags = ticket.settings.tags
        const newObj = {}
        const tagsAdded = []
        const tagsRemoved = []
        // si la etiqueta no existe en las etiquetas del ticket
        if (checked && !ticketTags.some((tg) => tg.tagId === tag._id)) {
          tag.tagId = tag._id
          tagsAdded.push(tag)
        } else {
          // si la etiqueta existe en el ticket, entonces no lo contará
          if (!checked) tagsRemoved.push(tag)
        }
        newObj.tagsAdded = tagsAdded
        newObj.tagsRemoved = tagsRemoved
        newObj.ticketId = ticket._id
        return newObj
      })

      const response = await axios.put(`${vari.UHR}/admin/room/tags`, {
        tickets: formattedTickets,
      })
      const data = response.data
      if (data.success) {
        await Promise.all(
          data.tickets.map(async (ticket) => {
            context.dispatch('updateTagsInTicketsToManage', { ticket })
            const payload_socket = {
              userId: context.getters.profile.userId,
              ticketId: ticket._id,
              settings: { tags: ticket.settings.tag },
            }
            await this._vm.$socket.emit(
              'server:ticket:settings',
              payload_socket
            )
          })
        )
      }

      return response.data
    } catch (error) {
      console.error('[ticketSettingsModule][saveTagsTicket]', error)
    }
  },
  /**
   * Redirecciona y abre el chat del ticket perteneciente a la agenda
   * @async
   * @param {vuex} context
   * @param {Object} args Objeto de la agenda
   * @param {Object} args.schedule Objeto de la agenda
   * @param {String} args.schedule.ticketId Id del ticket de la agenda
   * @param {Boolean} [args.schedule.attempt=false] Intentar recursividad
   */
  async selectTicketFromSchedule(context, { schedule, attempt = false }) {
    try {
      let ticket = context.getters.tickets.find(
        (ticket) => ticket._id === schedule.ticketId
      )
      if (!ticket) {
        ticket = context.getters.ticketsEnded.find(
          (ticket) => ticket._id === schedule.ticketId
        )
      }
      if (!ticket) {
        await context.dispatch('getTicket', {
          ticketId: schedule.ticketId,
          playSound: false,
        })
        if (attempt === true) {
          return context.dispatch('selectTicketFromSchedule', {
            schedule,
            attemp: false,
          })
        }
      }
      if (!ticket) return

      const menuItem = context.getters.menuItems.find(
        (item) => item.route === 'workspace'
      )

      router.push({
        name: 'drawer-ticket',
        params: { ticketId: ticket.ticket, drawerId: 'client' },
        query: {
          mailbox: ticket.mailboxId,
          status: ticket.status === 'active' ? 'active' : 'ended',
          page: 1,
        },
      })
      context.dispatch('selectMenu', menuItem)
    } catch (error) {
      console.error('[ticketSettingsModule][selectTicketFromSchedule]', error)
    }
  },
  /**
   * Redirecciona y abre el chat del ticket perteneciente a la agenda
   * @async
   * @param {vuex} context
   * @param {Object} args Objeto de la agenda
   * @param {Object} args.schedule Objeto de la agenda
   * @param {String} args.schedule.ticketId Id del ticket de la agenda
   */
  async selectTicketFromContacts(
    context,
    { ticketId, ticketNumber, mailboxId }
  ) {
    try {
      let ticket = context.getters.tickets.find(
        (ticket) => ticket._id === ticketId
      )
      if (!ticket) return

      const menuItem = context.getters.menuItems.find(
        (item) => item.route === 'workspace'
      )
      if (!menuItem) return

      router.push({
        name: 'drawer-ticket',
        params: { ticketId: ticketNumber, drawerId: 'client' },
        query: {
          mailbox: mailboxId,
          status: 'active',
          page: 1,
          main: 'chat',
        },
      })
      context.dispatch('selectMenu', menuItem)
      // context.dispatch('selectTicket', ticket)
    } catch (error) {
      console.error('[ticketSettingsModule][selectTicketFromSchedule]', error)
    }
  },
  /**
   * Obtiene los campos personalizados para los agentes
   * @param {*} context
   * @param {String} idTicket
   * @param {String} userId
   */
  async getFieldsToTicket(context, { idTicket, userId }) {
    try {
      let prefixRole = null
      if (router.currentRoute.meta.is_workspace) {
        prefixRole = 'agent'
      } else if (router.currentRoute.meta.is_monitor) {
        prefixRole = 'admin'
        userId = context.getters.profileRemote.userId
      }
      const response = await axios.get(
        `${vari.UHR}/${prefixRole}/ticket/${idTicket}?agentUserId=${userId}`
      )
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },
  /**
   * Actuliza los datos de los campos personalizados en el ticket
   * @param {*} context
   * @param {Object} ticket
   * @param {Object} field
   */
  async updateFieldsToTicket(context, { ticket, field, isAgent = true }) {
    try {
      const idTicket = ticket._id
      const response = await axios.put(
        `${vari.UHR}/agent/ticket/fields/${idTicket}`,
        field
      )
      if (isAgent) context.commit('SET_FIELDS_TO_TICKET', response.data.fields)
      return response.data
    } catch (error) {
      console.error('[updateFieldsToTicket]', error)
      return error.response.data
    }
  },
}

export default actions
