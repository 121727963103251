const SUPPORT_WBA = 'whatsapp'
const SUPPORT_MSG = 'messenger'
const SUPPORT_IG = 'instagram'
const SUPPORT_WBT = 'webchat'
const SUPPORT_WBA_IG_MSG_WBT = 'all_channels'

const SUPPORTED_CHANNELS = [
  {
    channel: 'whatsapp',
    channels: ['whatsapp'],
    title: 'WhatsApp',
    description: 'Incluye botones y listas.',
    value: SUPPORT_WBA,
  },
  {
    channel: 'messenger',
    channels: ['messenger'],
    title: 'Messenger',
    description: 'Incluye botones y tarjetas.',
    value: SUPPORT_MSG,
  },
  {
    channel: 'instagram',
    channels: ['instagram'],
    title: 'Instagram',
    description: 'Caracteres restringidos en nodos',
    value: SUPPORT_IG,
  },
  {
    channel: 'webchat',
    channels: ['webchat'],
    title: 'Webchat',
    description: 'Incluye botones y tarjetas.',
    value: SUPPORT_WBT,
  },
  {
    channel: 'multi-channel',
    channels: ['whatsapp', 'webchat', 'instagram', 'messenger'],
    title: 'Multicanal',
    description: 'Para todos los canales con acciones generales. ',
    value: SUPPORT_WBA_IG_MSG_WBT,
  },
]

export { SUPPORTED_CHANNELS }
