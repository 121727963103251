const initialState = () => ({
  channels: [],
  paginate_company_channels: [],
  pagination_company_channels: {
    total: 0,
    page: 0,
  },
  simple_company_channels: [],
  current_channel: {}, // canal que está siendo visualizado en su propio modulo
  allTemplates: [],
})

export default initialState
