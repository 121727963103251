const initialState = () => ({
  type_vars: [
    { type: 'number', title: 'Númerico' },
    { type: 'text', title: 'Texto' },
    { type: 'date', title: 'Fecha' },
  ],
  paginate_chatbots_vars: [],
  pagination_chatbots_vars: { total: 0, page: 0 },
  simple_chatbots_vars: [],
})

export default initialState
