<template>
  <full-template>
    <companies-template />
  </full-template>
</template>

<script>
import FullTemplate from '@/app/shared/components/templates/FullTemplate'
import CompaniesTemplate from '@/app/companies/components/templates/CompaniesTemplate'

export default {
  name: 'Companies',
  components: {
    CompaniesTemplate,
    FullTemplate,
  },
}
</script>

<style scoped></style>
