<template>
  <div class="item">
    <div>
      <p class="regular-14 mrg-bottom-0">{{ title }}</p>
      <span class="regular-12">{{ description }}</span>
    </div>
    <a-radio :disabled="true"> </a-radio>
  </div>
</template>

<script>
export default {
  name: 'ItemMessageList',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.item
  +flex(row, space-between, center)
  width: 100%
  margin-bottom: 8px
</style>
