<template>
  <base-custom-drawer
    title="Comentarios"
    :showBack="showBack"
    textBack="Cerrar"
    @onBack="$emit('onClose')"
  >
    <div class="section__content-input">
      <a-input-search
        v-model="filters.search"
        placeholder="Buscar por palabra"
        @search="handleSearch"
      />
    </div>
    <a-row
      type="flex"
      class="flex-no-wrap direction-column section__container"
      :class="{ 'section__container-history': target.includes('history') }"
    >
      <custom-section-scroll
        :master-styles="{ flexGrow: 1, overflow: 'auto' }"
        :scroll-pagination="{
          observedElement: 'preview-comment--last',
          isLoading: loading || filters.loading,
          showEmpty: !paginate_comments_ticket.length,
          onNext: searchNextPage,
        }"
      >
        <div class="section__content">
          <comment
            v-for="(comment, index) in paginate_comments_ticket"
            :key="comment._id"
            :full-name="fullName({ ...comment.user })"
            :comment="comment.text"
            :avatar="comment.user.avatar"
            :userId="comment.user._id"
            :date="comment.created_at"
            :messageId="comment.messageId"
            :is-linked="!!comment.messageId"
            :class="{
              'preview-comment--last':
                index === paginate_comments_ticket.length - 1,
            }"
            :commentId="comment._id"
            :type="target.includes('history') ? 'history' : 'default'"
            @click.native="
              !!comment.messageId &&
                handleSelectLinkedComment(comment._id, comment.messageId)
            "
          />
        </div>
      </custom-section-scroll>
      <input-comment v-if="showInputComment" />
    </a-row>
  </base-custom-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseCustomDrawer from '@/app/tickets/components/organisms/sections/BaseCustomDrawer'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import Comment from '@/app/tickets/components/molecules/Comment'
import InputComment from '@/app/tickets/components/organisms/inputs/InputComment'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SectionComments',
  props: {
    ticketId: {
      type: [String, null],
      required: false,
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: String,
      required: false,
      default: 'inbox',
    },
  },
  components: {
    BaseCustomDrawer,
    CustomSectionScroll,
    Comment,
    InputComment,
  },
  mixins: [attemptMixin],
  data: () => ({
    filters: {
      page: 1,
      loading: true,
      search: '',
    },
  }),
  mounted() {
    this.intervalAttempt(() => {
      if (!this.ticketId) throw 'No ticket selected'
      this.loadFirstData()
    })
  },
  watch: {
    ticketId(value) {
      if (value) this.loadFirstData()
    },
    'pagination_comments_ticket.page'(value) {
      this.filters.page = value
    },
  },
  computed: {
    ...mapGetters([
      'paginate_comments_ticket',
      'company',
      'pagination_comments_ticket',
      'ticketSelected',
    ]),

    /**
     * Mostrará el input de comentario solo para target de tipo inbox
     * @return {Boolean}
     */
    showInputComment() {
      return this.target === 'inbox' && this.ticketSelected.status !== 'ended'
    },
  },
  methods: {
    ...mapActions(['getPaginateCommentsByTicket', 'paginateMessages']),

    /**
     * Carga los primeros datos
     */
    async loadFirstData() {
      this.filters.loading = true
      await this.getPaginateCommentsByTicket({
        page: this.filters.page,
        ticketId: this.ticketId,
        search: this.filters.search,
      })
      this.filters.loading = false
    },
    /**
     * Busqueda de palabras
     */
    async handleSearch() {
      this.filters.loading = true
      this.filters.page = 1
      await this.getPaginateCommentsByTicket({
        page: this.filters.page,
        ticketId: this.ticketId,
        search: this.filters.search,
      })
      this.filters.loading = false
    },
    /**
     * Busqueda de palabras en los comentarios
     */
    async searchNextPage() {
      this.filters.page = this.filters.page + 1
      const response = await this.getPaginateCommentsByTicket({
        page: this.filters.page,
        ticketId: this.ticketId,
        search: this.filters.search,
      })
      let morePaginate = true
      if (!response.data.length) morePaginate = false
      return { morePaginate }
    },
    /**
     * Nombre completo del usuario
     * @param {Object} args
     * @return {String} args.names
     * @return {String} args.surnames
     */
    fullName({ names, surnames }) {
      return `${names} ${surnames}`
    },
    /**
     * Escucha la seleccion de un comentario linkeado a un mensaje
     * @param {String} commentId
     * @param {String} messageId
     */
    async handleSelectLinkedComment(commentId, messageId) {
      await this.paginateMessages({
        target: this.target,
        commentId,
      })
      this.$nextTick(() => {
        const message = document.getElementById(messageId)

        message.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        message.classList.add('bubble--focus')
        setTimeout(() => {
          message.classList.remove('bubble--focus')
        }, 8000)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
$header_size: 118px
$header_modal_ticket_details: 42em
.section
  &__container
    height: calc( 100vh - #{$header_size} )
    &-history
      height: calc( #{$header_modal_ticket_details} - #{$header_size} )
  &__content
    padding: 0px 12px 12px 0px
  &__content-input
    padding: 0px 12px 12px 12px
</style>
