/**
 * Transforma una palabra a lowercase, uppercase, capitalize
 * @param {String} word
 * @param {String} type lowercase, uppercase, capitalize
 * @return {String}
 */
const transformWord = (word, type) => {
  switch (type) {
    case 'lowercase':
      return word.toLowerCase()
    case 'capitalize':
      return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
    case 'uppercase':
      return word.toUpperCase()
    default:
      return word
  }
}

export default {
  transformWord,
}
