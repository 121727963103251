<template>
  <div
    class="node-option"
    :class="{
      'node-option--selected': selected,
      'cursor-auto': disabled,
      'cursor-pointer': !disabled,
    }"
    @click="handleSelect"
  >
    <!--Header de nodo-->
    <div class="node-option__header">
      <h5 class="heading-h7 node-option__order">
        {{ order }}. {{ title }} {{ order }}
        <!--Tooltip para archivos adjuntos-->
        <a-tooltip
          v-if="attachment && dataSource.media && dataSource.media.urlStorage"
          :title="titleMediaTooltip"
        >
          <a class="mrg-left-8" @click="$emit('onPreviewAttachment')"
            ><a-icon type="paper-clip"
          /></a>
        </a-tooltip>
      </h5>
      <!--Si el nodo simula ser padre-->
      <div v-if="simulateParent" class="node-option__actions">
        <!--Agregar subhijos-->
        <a-tooltip v-if="disabledAdd || onlyRead" :title="textTooltipAdd">
          <a href="#" class="color--disabled">{{ addText }}</a>
        </a-tooltip>
        <a
          v-else
          href="#"
          :disabled="disabled || disabledAdd || onlyRead"
          @click="handleAdd"
          >{{ addText }}</a
        >
        <a-divider type="vertical" />

        <a-dropdown
          :trigger="['click']"
          placement="topRight"
          :disabled="disabled || onlyRead"
        >
          <a href="#" :disabled="disabled || onlyRead">Cambiar de posición</a>

          <a-menu v-if="quantityOrders !== 0" slot="overlay">
            <a-menu-item
              v-for="item in quantityOrders"
              :key="item"
              @click="$emit('onChangeOrder', dataSource, item, nodeTypeParent)"
              :disabled="item === order || !isAllowedChange"
            >
              {{
                item === order
                  ? `Posición ${item} (actual)`
                  : `Posición ${item}`
              }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <template v-if="showDelete">
          <a-divider type="vertical" />
          <a
            :class="{ 'node-option__action--danger': !disabled && !onlyRead }"
            @click.stop="handleDelete"
            :disabled="disabled || onlyRead"
            >Eliminar</a
          >
        </template>
      </div>
      <i v-else class="info-2 node-option__action">{{ action }}</i>
    </div>

    <!--Contenido de nodo-->
    <div class="node-option__content">
      <p class="body-2 node-option__description mrg-bottom-0 text--left">
        {{ description }}
      </p>
      <div v-if="!simulateParent" class="node-option__actions">
        <a-dropdown
          :trigger="['click']"
          placement="topRight"
          :disabled="disabled || onlyRead"
        >
          <a href="#" :disabled="disabled || onlyRead">Cambiar de posición</a>

          <a-menu v-if="quantityOrders !== 0" slot="overlay">
            <a-menu-item
              v-for="item in quantityOrders"
              :key="item"
              @click="$emit('onChangeOrder', dataSource, item, nodeTypeParent)"
              :disabled="item === order || !isAllowedChange"
            >
              {{
                item === order
                  ? `Posición ${item} (actual)`
                  : `Posición ${item}`
              }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <template v-if="showDelete">
          <a-divider type="vertical" />
          <a
            :class="{ 'node-option__action--danger': !disabled && !onlyRead }"
            @click.stop="handleDelete"
            :disabled="disabled || onlyRead"
            >Eliminar</a
          >
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'ChildNode',
  props: {
    title: { type: String, default: 'ChildNode' },
    order: { type: [String, Number], default: '1' },
    description: { type: String, default: 'Descripcion' },
    quantityOrders: { type: Number, required: false, default: 0 },
    dataSource: { type: Object, required: false },
    selected: { type: Boolean, required: false, default: false },
    showDelete: { type: Boolean, required: false, default: true },
    action: { type: String, default: 'Finalizar chatbot' },
    isAllowedChange: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, default: false },
    nodeTypeParent: {
      type: String,
      required: true,
      enum: ['buttons', 'options', 'cards'],
    },
    simulateParent: {
      type: Boolean,
      default: false,
    },
    addText: {
      type: String,
      required: false,
    },
    attachment: {
      type: String,
      required: false,
      enum: ['document', 'image', 'video'],
      default: null,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  computed: {
    ...mapGetters(['validations_chatbot']),

    /**
     * @return {String} - titulo para tooltip de multimedia
     */
    titleMediaTooltip() {
      if (!this.attachment) return ''
      switch (this.attachment) {
        case 'document':
          return 'Con documento adjunto'
        case 'image':
          return 'Con imagen adjunta'
        case 'video':
          return 'Con video adjunto'
        default:
          return 'Con archivo adjunto'
      }
    },
    /**
     * @return {Boolean} deshabilita el agregar subhijos
     */
    disabledAdd() {
      if (!this.simulateParent && !this.nodeTypeParent) return
      const nodeType = this.nodeTypeParent
      const subchildren = NODES[nodeType]?.child?.child
      if (!subchildren) return
      return (
        this.validations_chatbot[nodeType]?.maxSubchildren ===
        this.dataSource[subchildren.group].length
      )
    },
    /**
     * Texto para el tooltip de agregar
     * @return {String}
     */
    textTooltipAdd() {
      if (!this.nodeTypeParent) return
      const nodeType = this.nodeTypeParent
      const subchildren = NODES[nodeType]?.child?.child

      return `Solo se pueden agregar hasta ${this.validations_chatbot[nodeType].maxSubchildren} ${subchildren.title_plural}`
    },
  },
  methods: {
    /**
     * Emite la seleccion del nodo solo si no esta deshabilitado
     */
    handleSelect() {
      !this.disabled && this.$emit('onSelect')
    },
    /**
     * Emite el evento de agregar solo si no esta deshabilitado
     */
    handleAdd() {
      if ((!this.disabled && !this.onlyRead) || !this.disabledAdd)
        this.$emit('onAdd')
    },
    /**
     * Emite el evento para eliminar una opcion
     */
    handleDelete() {
      !this.disabled && !this.onlyRead && this.$emit('onDelete')
    },
  },
}
</script>
<style lang="sass" scoped>
.node-option
  margin-bottom: 8px
  border: 1px solid $gray_dark_100
  border-radius: 4px
  padding: 10px 12px
  &__header
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 4px
  &__order
    text-align: left
    margin-bottom: 2px
  &__content
    display: flex
    justify-content: space-between
  &__description
    color: $gray_dark_800
  &--selected
    border: 1px solid $blue_6
    background-color: $blue_1
  &:hover
    border: 1px solid $blue_6
  &__action
    color: $gray_7
    &--danger
      color: $red_6
</style>
