var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.defaultStep === 0)?_c('a-form',{staticClass:"form",attrs:{"form":_vm.form,"layout":"vertical","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.error.visible)?_c('a-alert',{staticClass:"mrg-bottom-24",attrs:{"type":"error","message":_vm.error.message,"show-icon":""}}):_vm._e(),_c('a-form-item',{attrs:{"label":"Usuario"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'user',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'user',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor completa este campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Por favor completa este campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor completa este campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","autocomplete":"new-password"}})],1),_c('p',{staticClass:"body-3 form__extra-info"},[_vm._v(" Si todavía no cuentas con un usuario y contraseña, contáctate con el equipo Comercial de Tedexis. ")]),_c('div',{staticClass:"form__footer"},[_c('a-button',{staticClass:"footer_button__default",on:{"click":function($event){return _vm.$emit('onCancel')}}},[_vm._v("Volver")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_vm._v("Integrar con Tedexis")])],1)],1):(_vm.defaultStep === 1)?_c('div',{staticClass:"text--center form__success"},[_c('img',{staticClass:"form__success__picture",attrs:{"src":require("@/app/shared/assets/whoooa_graphics/solid/notifications-advanced-0.svg"),"height":"200","width":"475"}}),_c('h5',{staticClass:"heading-h5 text--center form__success__text--title"},[_vm._v(" Se ha integrado correctamente tu canal de SMS ")]),_c('p',{staticClass:"body-2 text--center form__success__text"},[_vm._v(" Ahora podrás realizar envío de campañas masivas de SMS. No olvides leer las políticas de cobros. ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleClose}},[_vm._v(" Aceptar y volver a canales ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }