import Chatbots from '@/app/chatbots/views/Chatbots'
import Chatbot from '@/app/chatbots/views/Chatbot'
import SectionChatbots from '@/app/chatbots/components/organisms/sections/SectionChatbots'
import SectionManageChatbotsVars from '@/app/chatbots/components/organisms/sections/SectionManageChatbotsVars'

const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/chatbots',
    name: 'chatbots',
    component: Chatbots,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'master_agent'],
      title: `Chatbots | ${VUE_APP_NAME}`,
    },
    children: [
      {
        path: 'default',
        name: 'all-chatbots',
        component: SectionChatbots,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'supervisor', 'master_agent'],
        },
      },
      {
        path: 'manage-vars',
        name: 'manage-chatbots-vars',
        component: SectionManageChatbotsVars,
        meta: {
          requiresAuth: true,
          requiresType: ['admin', 'supervisor', 'master_agent'],
        },
      },
    ],
    redirect: {
      name: 'all-chatbots',
    },
  },
  {
    path: '/tree/:id',
    name: 'id',
    component: Chatbot,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'master_agent'],
      title: `Editor de Chatbot | ${VUE_APP_NAME}`,
    },
  },
]
