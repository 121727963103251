<template>
  <div class="dashboard">
    <DashboardTemplate />
  </div>
</template>
<script>
import DashboardTemplate from '@/app/dashboard/components/templates/DashboardTemplate.vue'
export default {
  name: 'Dashboard',
  components: {
    DashboardTemplate,
  },
}
</script>
