<template>
  <div>
    <a-col
      style="width: 100%; height: 100%"
      :xs="24"
      :sm="24"
      :md="12"
      :lg="10"
      :xl="10"
    >
      <div class="card-channel" style="height: 100%">
        <!-- Título -->
        <a-row
          type="flex"
          justify="space-between"
          class="section-icon-channels"
          style="margin-bottom: 8px"
        >
          <div class="section-title">
            <SocialIcon :type="channel.channel" />
            <h5
              class="heading-h5 gray-dark-900"
              style="margin-bottom: 0px; margin-left: 8px"
            >
              {{ channel.name }}
            </h5>
          </div>
          <a-row>
            <a-tag
              v-for="channel_status in channel_statuses"
              :key="channel_status.name"
              :color="channel_status.color"
              style="margin-right: 0px"
            >
              {{ channel_status.name }}
            </a-tag>
          </a-row>
        </a-row>
        <!-- Descripción -->
        <a-row>
          <h6 class="heading-h7 gray-dark-900" style="font-weight: 600">
            {{ channel.subtitle }}
          </h6>
          <p class="body-1 margin-text" style="margin: 0px; height: 40px">
            {{ channel.description }}
          </p>
        </a-row>
        <!-- Botones bajos -->
        <a-row
          type="flex"
          :justify="usedChannels > 0 ? 'space-between' : 'end'"
        >
          <!-- Ejecuta método cuando NO está conectado -->
          <template v-if="!channel.down">
            <span v-if="usedChannels > 0" class="regular-12 gray_7">
              {{ usedChannels }}
              {{ usedChannels > 1 ? 'canales conectados' : 'canal conectado' }}
            </span>
            <!--Si el canal es WhatsApp-->
            <a-col v-if="channel.channel === 'whatsapp' && channel.active">
              <a-row type="flex" justify="end" align="bottom">
                <!--Modal de sandbox-->
                <ModalSandbox
                  v-show="hasSandboxWsp"
                  nameButton="Conectar con la sandbox"
                  :isLink="true"
                  step="one"
                />
                <!--Link de conectar-->
                <p class="body-3 link-text-channels">
                  <!--Cantidad de canales conectados-->
                  <a-tooltip placement="top">
                    <template slot="title" v-if="!isAllowConnectChannel">
                      Ya tienes
                      {{ limitChannel }}
                      {{
                        limitChannel > 1 ? 'canales activos' : 'canal activo'
                      }}
                    </template>
                    <a
                      @click="
                        isAllowConnectChannel &&
                          handleSetDataModalIntegrate({
                            visible: true,
                            channel: 'whatsapp',
                          })
                      "
                      :class="{ 'link-desactive': !isAllowConnectChannel }"
                    >
                      <u>Conectar un número de WhatsApp</u>
                    </a>
                  </a-tooltip>
                </p>
                <!--Modal para conectar whatsapp-->
                <modal-integrate-channel
                  v-if="modal_integrate_channel.channel === 'whatsapp'"
                  :visible="modal_integrate_channel.visible"
                  :channel="modal_integrate_channel.channel"
                  @onClose="
                    handleSetDataModalIntegrate({
                      visible: false,
                      channel: 'whatsapp',
                    })
                  "
                />
              </a-row>
            </a-col>
            <!--Si el canal es SMS-->
            <a-col v-else-if="channel.channel === 'sms' && channel.active">
              <a-row type="flex" justify="end" align="bottom">
                <!--Link de conectar-->
                <p class="body-3 link-text-channels">
                  <!--Cantidad de canales conectados-->
                  <a-tooltip placement="top">
                    <template slot="title" v-if="!isAllowConnectChannel">
                      Ya tienes
                      {{ limitChannel }}
                      {{
                        limitChannel > 1 ? 'canales activos' : 'canal activo'
                      }}
                    </template>
                    <a
                      @click="
                        isAllowConnectChannel &&
                          handleSetDataModalIntegrate({
                            visible: true,
                            channel: 'sms',
                          })
                      "
                      :class="{ 'link-desactive': !isAllowConnectChannel }"
                    >
                      <u>Conectar con SMS</u>
                    </a>
                  </a-tooltip>
                </p>
                <!--Modal para conectar SMS-->
                <modal-integrate-channel
                  v-if="modal_integrate_channel.channel === 'sms'"
                  :visible="modal_integrate_channel.visible"
                  :channel="modal_integrate_channel.channel"
                  @onClose="
                    handleSetDataModalIntegrate({
                      visible: false,
                      channel: 'sms',
                    })
                  "
                />
              </a-row>
            </a-col>
            <!--Si el canal es otro-->
            <div v-else>
              <template
                v-if="channel.channel === 'messenger' && channel.active"
              >
                <p class="body-3 link-text-channels">
                  <a-tooltip placement="top">
                    <template slot="title" v-if="!isAllowConnectChannel">
                      Ya tienes
                      {{ limitChannel }}
                      {{
                        limitChannel > 1 ? 'canales activos' : 'canal activo'
                      }}
                    </template>
                    <a
                      @click="
                        isAllowConnectChannel ? handleShowModalStart() : ''
                      "
                      :class="{ 'link-desactive': !isAllowConnectChannel }"
                      ><u>Conectar con Messenger</u></a
                    >
                  </a-tooltip>
                </p>
                <modal-fb-start
                  :visible="modalFbStart.visible"
                  @onOk="handleExecuteGuideMsg"
                  @onClose="modalFbStart.visible = false"
                />
              </template>
              <p
                v-if="channel.channel === 'instagram' && channel.active"
                class="body-3 link-text-channels"
              >
                <a-tooltip placement="top">
                  <template slot="title" v-if="!isAllowConnectChannel">
                    Ya tienes
                    {{ limitChannel }}
                    {{ limitChannel > 1 ? 'canales activos' : 'canal activo' }}
                  </template>
                  <a
                    @click="isAllowConnectChannel && handleExecuteGuideIg()"
                    :class="{ 'link-desactive': !isAllowConnectChannel }"
                    ><u>Conectar con Instagram</u></a
                  >
                </a-tooltip>
              </p>
              <p
                v-if="channel.channel === 'webchat' && channel.active"
                class="body-3 link-text-channels"
              >
                <a-tooltip placement="top">
                  <template slot="title" v-if="!isAllowConnectChannel">
                    Ya tienes
                    {{ limitChannel }}
                    {{ limitChannel > 1 ? 'canales activos' : 'canal activo' }}
                  </template>
                  <router-link
                    :to="
                      isAllowConnectChannel
                        ? {
                            name: 'channel',
                            params: { channel: 'webchat' },
                            query: { create: true },
                          }
                        : {}
                    "
                    :class="{
                      'link-desactive': !isAllowConnectChannel,
                    }"
                    ><u>Conectar un WebChat</u></router-link
                  >
                </a-tooltip>
              </p>
            </div>
          </template>
        </a-row>
      </div>
    </a-col>

    <modal-fb-login ref="modalFbLogin" :channel="channel.channel" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import transformWordGender from '@/app/shared/mixins/transform'
import ModalSandbox from '@/app/channels/components/whatsapp/modals/ModalSandbox'
import ModalIntegrateChannel from '@/app/channels/components/organisms/modals/ModalIntegrateChannel.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import ModalFbLogin from '@/app/channels/components/molecules/ModalFbLogin'
import ModalFbStart from '@/app/channels/components/messenger/ModalFbStart'

export default {
  name: 'CardChannel',
  props: {
    channel: {
      // este canal viene de canales
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    SocialIcon,
    ModalSandbox,
    ModalIntegrateChannel,
    ModalFbLogin,
    ModalFbStart,
  },
  data: () => ({
    sandboxNumber: '',
    modalFbStart: {
      visible: false,
    },
  }),
  async created() {
    // SE HABILITARÁ PROXIMAMENTE, ES NECESARIO OPTIMIZAR
    // this.intervalAttempt(async () => {
    //   if (!this.channel) throw 'No channel'
    //   if (this.channel.channel === 'whatsapp')
    //     this.sandboxNumber = await getSandboxIdentifier('whatsapp')
    // })
  },
  mixins: [attemptMixin, transformWordGender],
  computed: {
    ...mapGetters(['profile', 'modal_integrate_channel', 'spent_channels']),
    /**
     * @returns {Boolean}
     */
    hasSandboxWsp() {
      return !!this.sandboxNumber
    },
    /**
     * @return {Object} {status_es, fontColor, bgColor, borColor}
     */
    channel_statuses() {
      let statuses = []
      if (this.channel && this.channel.down) {
        // Servicio caído
        statuses.push({
          status_es: `Error de canal`, // Texto en español
          nameColor: 'red', // Nombre del color
        })
      } else statuses = this.channel.tags

      return statuses
    },
    /**
     * Permitir conectar canales
     * @return {Boolean}
     */
    isAllowConnectChannel() {
      if (!this.spent_channels) return false
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? !spentChannel.isFull : false
    },
    /**
     * @return {Number} - limite del canal
     */
    limitChannel() {
      if (!this.spent_channels) return 0
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? spentChannel.limit : 0
    },
    /**
     * @return {Number} - canales usados
     */
    usedChannels() {
      if (!this.spent_channels) return 0
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? spentChannel.used : 0
    },
  },
  methods: {
    ...mapActions(['setAnalytics']),
    ...mapMutations(['SET_MODAL_INTEGRATION_CHANNELS']),

    handleShowModalStart() {
      this.modalFbStart.visible = true
    },
    handleExecuteGuideMsg() {
      this.modalFbStart.visible = false
      this.$refs.modalFbLogin.onGuideFacebookLogin()
    },
    handleExecuteGuideIg() {
      this.$refs.modalFbLogin.onGuideInstagramLogin()
    },
    /**
     * Setea como visible el modal de integracion de canal
     * @param {Boolean} visible
     * @param {String} channel
     */
    handleSetDataModalIntegrate({ visible, channel }) {
      this.SET_MODAL_INTEGRATION_CHANNELS({
        visible,
        channel: !visible ? '' : channel,
      })
      // solo si es whatsapp enviará un evento a analytics
      if (!visible && channel === 'whatsapp') {
        this.setAnalytics({ eventName: 'modal_wab_request_close' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.gutter-row
  width: 50%

.gray_7
  color: $gray_7
.card-channel
  background-color: $white_000
  padding: 20px 20px 10px
  border-radius: 8px
  height: auto
  text-align: left
  border: 1px solid $gray_5
  &:hover
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 8px
    border-color: $gray_4
  .section-icon-channels
    .section-title
      display: flex
      flex-direction: row
  .gray-color-700
    color: $gray_dark_700
  .gray-color-300
    color: $gray_dark_300
  .gray-dark-900
    color: $gray_dark_900
  .link-desactive
    color: $gray_dark_300
    cursor: no-drop
  .button-cards-channels
    text-align: right
  .link-text-channels
    text-align: right
    font-size: 12px
    line-height: 20px
    margin: 0px
    position: relative
    margin-left: 8px
.connected-page
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  border: 1px solid $gray_dark_200
  border-radius: 8px
  width: 256px
  padding: 8px 12px
  img
    margin-right: 12px
  &-name
    margin-bottom: 0
    color: $gray_dark_900
  &-status
    display: flex
    flex-direction: row
    align-items: center
    p
      margin-bottom: 0
      margin-right: 4px
      color: $gray_dark_800
</style>

<style lang="sass" scoped></style>
