const url = require('url')

/**
 * Extrae el nombre el archivo de una url
 * @param {String} url
 * @return {String}
 */
const getFilenameFromUrl = (urlString) => {
  if (!urlString) return 'Archivo'
  try {
    const urlObject = url.parse(urlString)
    const filename = urlObject.pathname.split('/').pop()
    return filename
  } catch (err) {
    return 'Archivo'
  }
}

export default {
  getFilenameFromUrl,
}
