var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"container width-full flex-no-wrap",attrs:{"type":"flex","justify":"space-between","align":"middle"}},[(!_vm.active_breakpoint.is_mobile)?[_c('div',{ref:"container_tags",staticClass:"container__tags display-flex flex-grow flex-wrap"},[_vm._l((_vm.sortTags({ tags: _vm.tagsSelectedFormatted })),function(tag){return _c('a-tag',{key:`tag${tag.tagId}`,ref:`tag${tag.tagId}`,refInFor:true,class:{
          hidden: !tag.visible && !tag.wasChecked,
          'display-none': !tag.visible && tag.wasChecked,
        },attrs:{"color":tag.color}},[_vm._v(" "+_vm._s(_vm.verifyRender(tag.tagId))+" "+_vm._s(tag.title))])}),(!!_vm.aditional)?_c('popover-tags-list',{attrs:{"data-source":_vm.tagsNoVisible,"placement":"right"}},[_c('a-tag',{staticStyle:{"border-radius":"12px"}},[_vm._v("+"+_vm._s(_vm.aditional))])],1):_vm._e()],2)]:[_c('div',{ref:"container_tags",staticClass:"container__tags display-flex flex-grow flex-wrap align--center"},[_vm._l((_vm.sortTags({ tags: _vm.tagsSelectedFormatted })),function(tag){return _c('color-pick',{key:`tag${tag.tagId}`,ref:`tag${tag.tagId}`,refInFor:true,staticClass:"mrg-right-12",class:{
          hidden: !tag.visible && !tag.wasChecked,
          'display-none': !tag.visible && tag.wasChecked,
        },attrs:{"color":tag.color,"pick-styles":_vm.pickStyles,"master-styles":_vm.masterStyles}},[_vm._v(_vm._s(_vm.verifyRender(tag.tagId, 12)))])}),(!!_vm.aditional)?_c('a-tag',{staticStyle:{"border-radius":"12px"}},[_vm._v("+"+_vm._s(_vm.aditional))]):_vm._e()],2)],(_vm.addTags)?_c('popover-tags-checkboxes',{attrs:{"data-source":_vm.dataSource},on:{"onCheck":_vm.handleCheckTag}},[_c('a',{staticClass:"mrg-left-12 container__add-link text--right"},[_c('a-icon',{staticClass:"mrg-right-2",attrs:{"type":"tag"}}),_c('span',[_vm._v("Añadir etiquetas")])],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }