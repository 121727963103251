import transformMixin from '@/app/shared/mixins/transform'

export default {
  mixins: [transformMixin],
  methods: {
    showConfirmDeleteComment(fnOk) {
      this.$confirm({
        title: `¿Estás ${this.wordSure} de eliminar este comentario`,
        content:
          'Recuerda que puedes volver a agregar otro comentario mientras el ticket se encuentre sin resolver.  ',
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        onOk() {
          fnOk()
        },
        centered: true,
      })
    },
  },
}
