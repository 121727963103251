<template>
  <a-row class="card text--center" :class="{ 'card--selected': selected }">
    <a-icon
      v-if="channel === 'multi-channel'"
      class="card__icon mrg-bottom-8"
      type="message"
      theme="filled"
    />
    <social-icon v-else class="mrg-bottom-8" :type="channel" :size="57" />

    <h6 class="semibold-14 mrg-bottom-0 card__title">{{ title }}</h6>
    <p class="mrg-bottom-0 regular-12">
      {{ description }}
    </p>
  </a-row>
</template>

<script>
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'CardChannelSetting',
  components: {
    SocialIcon,
  },
  props: {
    channel: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  padding: 12px 16px
  border-radius: 18px
  cursor: pointer
  border: 2px solid $gray_5
  min-height: 11em
  &__title
    color: $blue_6
    margin-bottom: 4px
  &__icon
    font-size: 46px
    color: $orange_4
  &:hover
    border-color: $blue_6
  &--selected
    background-color: $blue_1
    border-color: $blue_6
</style>
