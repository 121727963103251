const initialState = () => ({
  tickets: {
    data: [], // todos los porductos agregados al carro
    total: 0, // precio total a pagar
    page: 1,
    lastPage: 1,
  },
  fetchedFromOtherModule: false, // Filtrado desde otro módulo
  clientsIdFilterTicket: [], // IDs de cliente para filtrar (arreglo por el merge de clientes)
  rangeFilterTickets: [], // rango de fechas para filtrar tickets (usado para los tickets de contacto)
})

export default initialState
