const IGNORE = 'ignore'

const TYPE_TEMPLATES_FOR_LIST = [
  {
    title: 'Texto', // se mostrará en el selector
    value: 'TEXT', // valor que se enviará
    template: 'TEMPLATE_TEXT', // variable de la plantilla que se usará
    type: 'TEXT', // tipo de plantilla
    apis_version: ['gupshup_v1', 'twilio_v3'], // aun no se usará
    default: true, // se mostrará por defecto
  },
  {
    title: 'Texto e imagen',
    value: 'IMAGE',
    template: 'TEMPLATE_IMAGE',
    type: 'MEDIA',
    apis_version: ['gupshup_v1'],
  },
  {
    title: 'Texto y documento',
    value: 'DOCUMENT',
    template: 'TEMPLATE_DOCUMENT',
    type: 'MEDIA',
    apis_version: ['gupshup_v1'],
  },
  {
    title: 'Texto y video',
    value: 'VIDEO',
    template: 'TEMPLATE_VIDEO',
    type: 'MEDIA',
    apis_version: ['gupshup_v1'],
  },
]
const TEMPLATE_TEXT = {
  actions: {
    create: {
      channel_request: true,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
    update: {
      channel_request: IGNORE,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
  },
}
const TEMPLATE_VIDEO = {
  actions: {
    create: {
      channel_request: true,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
    update: {
      channel_request: IGNORE,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
  },
  formats: { titles: ['MP4'], values: ['MP4'], accept: 'video/mp4' },
  max_size: { size: 5, title: '5MB' },
  list: false, // no se permitirá subir mas de 1 video
}

const TEMPLATE_IMAGE = {
  actions: {
    create: {
      channel_request: true,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
    update: {
      channel_request: IGNORE,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
  },
  formats: {
    titles: ['JPG', 'JPEG', 'PNG'],
    values: ['JPG', 'JPEG', 'PNG', 'jpg', 'png', 'jpeg'],
    accept: 'image/jpeg, image/png, image/jpg',
  },
  max_size: { size: 2, title: '2MB' },
  list: false, // no se permitirá subir mas de 1 video
}

const TEMPLATE_DOCUMENT = {
  actions: {
    create: {
      channel_request: true,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
    update: {
      channel_request: IGNORE,
      allowed_roles: ['admin', 'admin-agent'], // aun no se usara
    },
  },
  formats: { titles: ['PDF'], values: ['PDF'], accept: 'application/pdf' },
  max_size: { size: 2, title: '2MB' },
  list: false, // no se permitirá subir mas de 1 video
}

const TEMPLATES_STATUSES = {
  approved: {
    color: 'green',
    value: 'approved',
    title: 'Aprobado',
  },
  pending: {
    color: 'orange',
    value: 'pending',
    title: 'Pendiente',
  },
  rejected: {
    color: 'red',
    value: 'rejected',
    title: 'Rechazado',
  },
  disabled: {
    color: 'red',
    value: 'disabled',
    title: 'Rechazado',
  },
  paused: {
    color: 'orange',
    value: 'paused',
    title: 'Pausado',
  }
}

const TEMPLATES_BUTTONS = {
  CTA: {
    value: 'CTA',
    title: 'Llamada a la acción',
    types: [
      { value: 'URL', title: 'URL', keys_required: ['text', 'cta'] },
      {
        value: 'PHONE',
        title: 'LLamar',
        keys_required: ['text', 'cta'],
      },
    ],
  },
  QUICK_REPLY: {
    value: 'QUICK_REPLY',
    title: 'Respuesta rápida',
  },
}

const TYPES_TEMPLATES = {
  TEMPLATE_TEXT,
  TEMPLATE_VIDEO,
  TEMPLATE_IMAGE,
  TEMPLATE_DOCUMENT,
}

export {
  TYPE_TEMPLATES_FOR_LIST,
  TYPES_TEMPLATES,
  TEMPLATE_TEXT,
  TEMPLATE_VIDEO,
  TEMPLATE_IMAGE,
  TEMPLATE_DOCUMENT,
  TEMPLATES_STATUSES,
  TEMPLATES_BUTTONS,
}
