<template>
  <custom-section-scroll
    :master-styles="{ height: 'calc(100% - 6em)' }"
    :content-styles="{ padding: '0px 24px' }"
    :scroll-pagination="{
      observedElement: 'list-item--last',
      onNext: onNextSearch,
    }"
  >
    <a-list
      class="list"
      item-layout="horizontal"
      :data-source="dataSource"
      :locale="{
        emptyText: 'No se han encontrado descargas de acuerdo a los filtros.',
      }"
      :loading="loading"
    >
      <list-item-download
        slot="renderItem"
        slot-scope="download, index"
        :class="{
          'list-item--last': index === dataSource.length - 1,
        }"
        :type-module="download.module"
        v-bind="download"
      />
    </a-list>
  </custom-section-scroll>
</template>

<script>
import ListItemDownload from './ListItemDownload.vue'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'

export default {
  name: 'ListMyDownloads',
  props: {
    dataSource: { type: Array, required: true },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    onNextSearch: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  components: {
    ListItemDownload,
    CustomSectionScroll,
  },
  methods: {},
}
</script>
