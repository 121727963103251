const IMAGE = 'image'
const DOCUMENT = 'document'
const VIDEO = 'video'

const MULTIMEDIA_SUPPORT = {
  [IMAGE]: {
    type: IMAGE,
    title: 'imágenes', // no se usa
    title_singular: 'Imagen',
    title_plural: 'Imágenes',
    formats: {
      titles: ['JPG', 'JPEG', 'PNG'],
      values: ['JPG', 'JPEG', 'PNG', 'jpg', 'png', 'jpeg'],
      accept: 'image/jpeg, image/png, image/jpg',
    },
    max_size: { size: 5, title: '5MB' },
    preview: 'thumbnail', // previsualización en minatura
  },
  [VIDEO]: {
    type: VIDEO,
    title: 'videos',
    title_singular: 'Video',
    title_plural: 'Videos',
    formats: { titles: ['MP4'], values: ['MP4'], accept: 'video/mp4' },
    max_size: { size: 5, title: '5MB' },
    preview: 'file', // previsualizacion tipo archivo
  },
  [DOCUMENT]: {
    type: DOCUMENT,
    title: 'documentos',
    title_singular: 'Documento',
    title_plural: 'Documentos',
    formats: {
      titles: ['PDF', 'EXCEL', 'WORD', 'PPT'],
      values: ['PDF', 'EXCEL', 'WORD', 'PPT'],
      accept:
        'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    max_size: { size: 5, title: '5MB' },
    preview: 'file', // previsualizacion tipo archivo
  },
}
const CHANNELS_WITHOUT_FILES = ['instagram']

export { MULTIMEDIA_SUPPORT, CHANNELS_WITHOUT_FILES }
