import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_MULTIMEDIA_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },

  /**
   * Setear Lista de multimdia
   * @param {*} state
   * @param {Array} multimedias // Lista de objetos multimedia
   */
  SET_MULTIMEDIAS(state, multimedias) {
    state.multimedias = multimedias
  },

  /**
   * Agrega un objeto multimedia al arreglo
   * @param {*} state
   * @param {Object} multimedia Objeto de un multimedia
   */
  ADD_MULTIMEDIA(state, multimedia) {
    state.multimedias.push(multimedia)
  },
}

export default mutations
