<template>
  <div class="section">
    <section-active-tickets class="section__row" />
    <section-tickets-in-queue class="section__row" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import SectionActiveTickets from '@/app/settings/components/organisms/alertsMenu/SectionActiveTickets'
import SectionTicketsInQueue from '@/app/settings/components/organisms/alertsMenu/SectionTicketsInQueue'

export default {
  name: 'SectionAlerts',
  mixins: [compareMixin],
  props: {
    notifications: {
      type: Object,
      required: true,
    },
    notificationsPhones: {
      type: Object,
      required: true,
    },
  },
  components: {
    SectionActiveTickets,
    SectionTicketsInQueue,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    app_email: process.env.VUE_APP_OFICIAL_EMAIL,
    activeTickets: {
      // tickets activos
      agents: false,
      others: false,
      phones: [],
      inputValue: '',
    },
    ticketsInQueue: {
      // tickets en cola de espera
      supervisors: { active: false, minTickets: '0' },
      others: { active: false, minTickets: '0' },
      phones: [],
      inputValue: '',
    },
    loadingBtnSave: false,
    loadingBtnCancel: false,
    visibleModal: false,
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '44px',
      color: '#262A31',
    },
  }),
  mounted() {
    this.setValue()
  },
  computed: {
    ...mapGetters(['roles', 'companyPricing']),
  },
  methods: {
    ...mapActions(['updateNotificationsTickets', 'updateNotificationsQueue']),

    setValue() {
      setTimeout(() => {
        this.activeTickets = {
          agents: this.notifications.ticket.received.active,
          others: this.notifications.ticket.received.activeForOthers,
          phones: this.notificationsPhones.received
            ? [...this.notificationsPhones.received]
            : [],
          inputValue: '',
        }
        this.ticketsInQueue = {
          supervisors: {
            active: this.notifications.ticket.queue.active,
            minTickets: this.notifications.ticket.queue.quantityForSupervisors,
          },
          others: {
            active: this.notifications.ticket.queue.activeForOthers,
            minTickets: this.notifications.ticket.queue.quantityForOthers,
          },
          phones: this.notificationsPhones.queue
            ? [...this.notificationsPhones.queue]
            : [],
          inputValue: '',
        }
      }, 500)
    },
    // setea el valor a input value de tickets activos
    handleChangeActive(e) {
      this.activeTickets.inputValue = e.target.value
    },
    // setea el valor a input value de tickets en cola
    handleChangeQueue(e) {
      this.ticketsInQueue.inputValue = e.target.value
    },
    /**
     * Agrega el telefono al array phones
     * @param {String} // tipo de ticket , 'active', 'queue'
     */
    handleConfirm(type) {
      if (type === 'active') {
        const inputValue = this.activeTickets.inputValue
        let tags = this.activeTickets.phones
        if (inputValue.length) {
          if (inputValue && !tags.includes(inputValue)) {
            tags.push(inputValue)
          } else {
            this.$message.error('Este número telefónico ya existe')
          }
          this.activeTickets.inputValue = ''
        }
      } else {
        const inputValueQueue = this.ticketsInQueue.inputValue
        if (inputValueQueue.length) {
          let tags = this.ticketsInQueue.phones
          if (inputValueQueue && !tags.includes(inputValueQueue)) {
            tags.push(inputValueQueue)
          } else {
            this.$message.error('Este número telefónico ya existe')
          }
          this.ticketsInQueue.inputValue = ''
        }
      }
    },
    /**
     * Evento que se ejecuta cuando se remueve un tag
     * @param {String}  removeTag el teléfono que se removera
     * @param {String}  type tipo de ticket
     */
    handleRemoveTag(removedTag, type) {
      if (type === 'active') {
        const tags = this.activeTickets.phones.filter(
          (tag) => tag !== removedTag
        )
        this.activeTickets.phones = tags
      } else if (type === 'queue') {
        const tags = this.ticketsInQueue.phones.filter(
          (tag) => tag !== removedTag
        )
        this.ticketsInQueue.phones = tags
      } else {
        console.log('no es de ningun tipo')
      }
    },
    // guarda la configuración
    async handleSave() {
      this.loadingBtnSave = true
      const updateNotificationsTickets = await this.updateNotificationsTickets({
        active: this.activeTickets.agents,
        activeForOthers: this.activeTickets.others,
        phones: this.activeTickets.phones,
      })
      const updateNotificationsQueue = await this.updateNotificationsQueue({
        quantityForSupervisors: this.ticketsInQueue.supervisors.minTickets,
        quantityForOthers: this.ticketsInQueue.others.minTickets,
        active: this.ticketsInQueue.supervisors.active,
        activeForOthers: this.ticketsInQueue.others.active,
        phones: this.ticketsInQueue.phones,
      })
      console.log(updateNotificationsTickets, updateNotificationsQueue)
      if (
        updateNotificationsTickets.success &&
        updateNotificationsQueue.success
      ) {
        this.$message.success('Los datos se guardaron correctamente')
      } else if (!updateNotificationsQueue.data.success) {
        this.$message.error(updateNotificationsQueue.data.details)
      } else {
        this.$message.error('No pudimos guardar la configuración 😓')
      }
      this.loadingBtnSave = false
    },
    handleCandel() {
      this.loadingBtnCancel = true
      this.setValue()
      setTimeout(() => {
        this.loadingBtnCancel = false
        this.$message.success('Los datos se cancelaron correctamente')
      }, 500)
    },
    handleModal() {
      if (!this.notifications.editable) {
        this.setValue()
        this.visibleModal = true
      }
    },
    handleCloseModal() {
      this.visibleModal = false
    },
  },
}
</script>

<style lang="sass" scoped>
.text-left
  text-align: left
.description
  margin-right: 76px

// .section-notifications
//   min-height: 300px
//   width: 100%
.section
  &__row
    margin-top: 20px
    &::first-child
      margin-top: 0px
</style>
