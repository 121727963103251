<template>
  <a-drawer
    title="Información de cliente"
    :width="drawer_filters.width"
    :visible="visible"
    :mask="false"
    @close="onClose"
  >
    <a-row class="client__section client__section--primary">
      <a-row class="client__header">
        <a-avatar
          v-if="client && dataClient.avatar"
          :src="dataClient.avatar"
          :size="64"
          class="client__avatar"
        />
        <a-avatar v-else :size="64" class="client__avatar" icon="user" />
      </a-row>
      <a-row class="client__header">
        <span
          v-if="(dataClient.names || '') + (dataClient.surnames || '')"
          class="client__name heading-h7"
        >
          {{ (dataClient.names || '') + ' ' + (dataClient.surnames || '') }}
        </span>
        <span v-else class="heading-h7 client__name--alternative">
          Sin nombre
        </span>
      </a-row>
      <a-row class="client__header">
        <span v-if="dataClient.phones" class="client__number">
          {{ dataClient.phones[0] ? dataClient.phones[0].number : '' }}
        </span>
      </a-row>
    </a-row>

    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Canales de contacto</h6>
      <div class="display-flex">
        <popover-channel
          v-for="clientChannel in formatClienthannels(client)"
          :key="clientChannel.channel"
          :channel="clientChannel.channel"
          :list-titles="clientChannel.channelTitles"
          class="mrg-right-8 mrg-bottom-8"
        />
      </div>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Correo electrónico</h6>
      <span v-if="dataClient.email">{{ dataClient.email }}</span>
      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Documento de identidad</h6>
      <template v-if="dataClient.documentType || dataClient.documentNumber">
        <span v-if="dataClient.documentType" class="client__document">{{
          dataClient.documentType ? dataClient.documentType.toUpperCase() : ''
        }}</span>
        <span>{{ dataClient.documentNumber }}</span>
      </template>
      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">País</h6>
      <span v-if="dataClient.country">{{ dataClient.country }}</span>
      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Ciudad</h6>
      <span v-if="dataClient.city">{{ dataClient.city }}</span>

      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Dirección</h6>
      <span v-if="dataClient.address">{{ dataClient.address }}</span>
      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <a-row class="client__section">
      <h6 class="heading-h8 section__title">Observación</h6>
      <span v-if="dataClient.remark">{{ dataClient.remark }}</span>
      <span v-else style="font-style: italic">Sin datos</span>
    </a-row>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button type="primary" @click="seeMoreDetails">
        Ir a más detalles del cliente
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import supportMixin from '@/app/shared/mixins/support'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    dataClient: {
      type: Object,
      required: true,
    },
  },
  components: {
    PopoverChannel,
  },
  created() {},
  mixins: [supportMixin],
  computed: {
    ...mapGetters(['drawer_filters']),
  },
  methods: {
    ...mapActions(['fetchContacs']),
    ...mapMutations(['SET_FETCHED_FROM_OTHER_MODULE']),
    onClose() {
      this.$emit('close')
    },
    async seeMoreDetails() {
      this.SET_FETCHED_FROM_OTHER_MODULE(true)
      this.$router.push({ name: 'contacts' })
      await this.fetchContacs({
        page: 1,
        search: '',
        channel: 'all',
        channelCompanyId: 'all',
        dateRange: [],
        clientId: this.dataClient._id,
      })
      setTimeout(() => {
        this.SET_FETCHED_FROM_OTHER_MODULE(false)
      }, 2000)
      console.log('See more details')
    },
  },
}
</script>

<style lang="sass" scoped>
.client__section
  margin-bottom: 12px

.client__section--primary
  display: flex
  justify-content: center
  align-items: center
  flex-flow: column
.client__avatar
  border-radius: 50%
  margin-bottom: 12px
.client__channel
  height: 23px
  width: 23px
  margin-right: 10px
.section__title
  margin-bottom: 2px
.client__document
  margin-right: 20px
</style>
