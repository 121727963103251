<template>
  <a-avatar
    :size="size"
    :style="{
      backgroundColor: colorCompany.bgColor,
      color: colorCompany.color,
    }"
    :src="url"
    :class="{ 'avatar--border': url }"
    alt="Avatar de empresa"
  >
    <icon-case
      slot="icon"
      class="avatar__icon"
      :style="`margin-right: 0px; font-size: ${sizeIcon}`"
    />
  </a-avatar>
</template>

<script>
import IconCase from '@/app/shared/components/icons/IconCase'
import { COMPANY_COLORS } from '@/app/companies/utils/utilsCompanies'

export default {
  name: 'AvatarCompany',
  components: {
    IconCase,
  },
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 32,
    },
    color: {
      type: String,
      required: false,
    },
    sizeIcon: {
      type: [Number, String],
      required: false,
      default: '18px',
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    colorCompany() {
      const colorCompany = COMPANY_COLORS[this.color]
      if (!colorCompany) return { bgColor: this.color, color: '#FFFFFF' }
      return colorCompany
    },
  },
}
</script>
<style lang="sass" scoped>
.avatar--border
  border: 1px solid $gray_5
</style>
