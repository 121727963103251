var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-form"},[_c('a-form',{staticClass:"form",attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"section-form-item",staticStyle:{"padding-bottom":"0px"},attrs:{"label":"Nombre de empresa","hasFeedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'name',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-form-item',{staticClass:"section-form-item",staticStyle:{"padding-bottom":"0px"},attrs:{"label":"País","hasFeedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'country',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
            ],
          },
        ]),expression:"[\n          'country',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"show-search":"","placeholder":"Selecciona aquí","size":_vm.inputs_size}},_vm._l((_vm.countriesNotRepeatByName),function(country){return _c('a-select-option',{key:country.nameSpanish},[_vm._v(" "+_vm._s(country.nameSpanish)+" ")])}),1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Tipo de operación","hasFeedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'operation',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
            ],
          },
        ]),expression:"[\n          'operation',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí","size":_vm.inputs_size}},_vm._l((_vm.operations),function(operation){return _c('a-select-option',{key:operation.key},[_vm._v(" "+_vm._s(operation.value)+" ")])}),1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":`¿Cómo llegaste a ${_vm.app}?`,"hasFeedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vias']),expression:"['vias']"}],attrs:{"placeholder":"Selecciona aquí","size":_vm.inputs_size},on:{"change":_vm.handleChange}},_vm._l((_vm.vias),function(via){return _c('a-select-option',{key:via.key},[_vm._v(" "+_vm._s(via.value)+" ")])}),1)],1),_c('div',{staticClass:"checkboxes"},[_c('a-checkbox',{staticClass:"form__checkbox display-inline text--left",on:{"change":_vm.onChangePrivacity}},[_vm._v(" Acepto los términos y políticas de seguridad de "+_vm._s(_vm.getOwnerCopyright())+". ")]),_c('a-checkbox',{staticClass:"form__checkbox display-inline text--left",on:{"change":_vm.onChangeSuscribe}},[_vm._v(" Deseo suscribirme al blog de "+_vm._s(_vm.getOwnerCopyright())+". ")])],1),_c('a-button',{staticStyle:{"width":"100%"},attrs:{"html-type":"submit","type":"primary","loading":_vm.loading,"size":_vm.buttons_size}},[_vm._v("Continuar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }