import vari from '@/app/shared/utils/variables'

const initialState = () => ({
  appVersion: vari.APP_VERSION,
})
const state = initialState

const actions = {
  /**
   * Verifica si hay una nueva versión disponible
   * @param {*} context
   */
  checkUpdates(context) {
    console.warn(
      `Versión Actual: ${process.env.VUE_APP_NAME} ${context.state.appVersion}`
    )
    // TODO: En contrucción
    // if (context.state.appVersion !== '2.x.x') {
    //   console.warn(
    //     'Hay actualizaciones pendientes, limpiar caché para obtener la última versión de esta'
    //   )
    // }
  },
}

export default {
  state,
  actions,
}
