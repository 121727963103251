<template>
  <!-- FORMULARIO DE CAMPOS -->
  <a-form-model
    v-if="currentFields.length > 0"
    id="form-content2"
    layout="vertical"
    class="form-client form-content"
  >
    <a-form-model-item
      v-for="field in currentFields"
      :key="field._id"
      :label="field.name"
      :prop="field.name"
      style="margin-bottom: 0"
    >
      <template v-if="field.type === 'text'">
        <a-input
          v-model="selectedFields[field._id]"
          :maxLength="30"
          placeholder="Escribir aquí..."
          :disabled="disabledField"
          @blur="saveFieldCustom(field._id, selectedFields[field._id])"
          @input="grantSave"
          :size="inputs_size"
        />
      </template>
      <template v-if="field.type === 'dropdown'">
        <a-select
          v-model="selectedFields[field._id]"
          :mode="field.multiple ? 'multiple' : ''"
          @change="handleChange($event, field._id)"
          :disabled="disabledField"
          placeholder="Seleccione una opción"
          :size="inputs_size"
          :allowClear="true"
        >
          <a-select-option
            v-for="option in field.options"
            :key="option.idOption"
            :value="option.idOption"
            :title="option.name"
          >
            {{ option.name }}
          </a-select-option>
        </a-select>
      </template>
      <template v-if="field.type === 'date'">
        <a-date-picker
          v-model="selectedFields[field._id]"
          @change="handleChangeDate($event, field._id)"
          placeholder="2020-05-25"
          class="form__date-picker"
          :disabled="disabledField"
          :size="inputs_size"
          :locale="localePicker"
        />
      </template>
    </a-form-model-item>
  </a-form-model>
  <p v-else class="text--center">No hay campos</p>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import formatMixin from '@/app/shared/mixins/format'

export default {
  name: 'ClientCustomFieldsForm',
  props: {
    dataSourceClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    allowSave: false, // permite guardar los cambios
    selectedFields: {},
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      this.loadFirstData()
    })
  },
  mixins: [attemptMixin, formatMixin],
  watch: {
    // escucha el cambio de los campos personalizados
    'dataSourceClient.settings.customFields'() {
      this.setCurrentFields()
    },
  },
  computed: {
    ...mapGetters([
      'profileRemote',
      'company',
      'company_custom_fields_clients',
      'profile',
      'inputs_size',
      'active_breakpoint',
    ]),

    /**
     * @return {Object[]} campos personalizados
     */
    currentFields() {
      return [...this.company_custom_fields_clients]
    },
    /**
     * @return {Boolean} Disabled del campo
     */
    disabledField() {
      return (
        (this.dataSourceClient && this.dataSourceClient.isDeleted) ||
        this.profileRemote ||
        false
      )
    },
  },
  methods: {
    ...mapActions(['updateCustomFieldClient', 'getListCustomFields']),

    /**
     * Setea los campos personalizados a uno local
     */
    setCurrentFields() {
      if (!this.dataSourceClient || !this.dataSourceClient.settings)
        return (this.selectedFields = {})

      // si customFields es un array
      if (Array.isArray(this.dataSourceClient.settings.customFields))
        this.selectedFields = this.formattedFields([
          ...this.dataSourceClient.settings.customFields,
        ])
      // si customFields es un objeto
      else
        this.selectedFields = { ...this.dataSourceClient.settings.customFields }

      // identificar que campo dropdown es, para formatear sus valores
      this.currentFields.forEach((currentField) => {
        if (currentField.type === 'dropdown') {
          const existFieldDropdown = Object.prototype.hasOwnProperty.call(
            this.selectedFields,
            currentField._id
          )
          const idOptions = currentField.options.map(
            (option) => option.idOption
          )
          if (existFieldDropdown)
            this.selectedFields[currentField._id] = this.selectedFields[
              currentField._id
            ]
              .filter((selectField) => idOptions.includes(selectField.idOption))
              .map((e) => e.idOption)
        }
      })
    },
    /**
     * Habilita el guardado
     */
    grantSave() {
      this.allowSave = true
    },
    /**
     * Guarda el campo personalizado
     */
    saveFieldCustom(idField, value) {
      if (!this.allowSave) return

      const currentField = this.currentFields.find((ele) => ele._id === idField)
      let newField = {}
      if (currentField === undefined) return
      if (currentField.type === 'dropdown') {
        const valueField = !value ? [] : value
        newField[idField] =
          typeof valueField !== 'string' ? valueField : [valueField]
      } else newField[idField] = value

      this.updateCustomFieldClient({
        clientId: this.dataSourceClient._id,
        clientChannel: this.dataSourceClient.channelOrigin.channel,
        field: newField,
      })
      this.$message.success('Se guardó correctamente')
    },
    /**
     * Escucha el cambio de un campo de tipo dropdown
     * @param {String} value
     * @param {String} idField
     */
    handleChange(value, idField) {
      this.grantSave()
      this.saveFieldCustom(idField, value)
    },
    /**
     * Escucha cuando la fecha cambia
     * @param {String|Array|Date} value
     * @param {String} idField
     */
    handleChangeDate(value, idField) {
      this.handleChange(value ? value.format() : null, idField)
    },
    /**
     * Carga los primeros datos
     */
    async loadFirstData() {
      await this.getListCustomFields()
      this.$nextTick(() => this.setCurrentFields())
    },
    /**
     * Formatea los campos del cliente a un objeto
     * @param {Object[]} fieldsClient
     * @return {Object} field.key
     * @return {String|Array} field.value
     */
    formattedFields(fieldsClient) {
      if (!fieldsClient) return
      let newFormat = {}
      fieldsClient.forEach((fieldClient) => {
        switch (fieldClient.type) {
          case 'date':
            newFormat[fieldClient.customFieldId] = fieldClient.value.date
            break
          case 'dropdown':
            newFormat[fieldClient.customFieldId] = fieldClient.value.options
            break
          case 'text':
            newFormat[fieldClient.customFieldId] = fieldClient.value.text
            break
        }
        return newFormat
      })
      return newFormat
    },
  },
}
</script>

<style lang="sass" scoped>
.form__date-picker
  width: 100%
</style>
