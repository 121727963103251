<template>
  <div
    class="section-tickets full-height"
    :style="showBannerSelection ? section_tickets.styles : {}"
  >
    <banner-selection
      v-if="showBannerSelection"
      :quantity="ticketsIdSelected.length"
      :dataSourceMailboxes="filterMailBoxes"
      :showMenuAgents="ticketsIdSelected.length == 1"
      @onClose="onCheckAllCards({ target: { checked: false } })"
      @onTransferToAgent="showModalTransfer"
      @onTransferToLine="modalTransferLine.visible = true"
      @onMoveMailbox="handleMassiveMailbox"
      @onUnread="haddleMassiveUnread"
    />

    <div class="head" ref="header">
      <a-row
        v-if="show_title_module"
        type="flex"
        align="middle"
        justify="space-between"
        class="mrg-bottom-12"
      >
        <header-module
          title="WORKSPACE"
          text-before-link="Responde todos los mensajes de WhatsApp, Messenger y más canales de
            tus clientes."
          text-after-link="si deseas conocer más."
          :link="`${vari.WEB_DOC}primeros-pasos/workspace/`"
          tipography="heading-h5"
          class="mrg-bottom-0"
        />
        <a-tooltip
          title="La cantidad de tickets activos mostrados toman en cuenta a todos los tickets de los buzones excepto el “Buzón Finalizados”."
          placement="rightTop"
        >
          <a-tag color="geekblue" class="mrg-0"
            >{{ total_assigned_tickets }} tickets activos</a-tag
          >
        </a-tooltip>
      </a-row>
      <a-row
        :type="active_breakpoint.is_mobile ? 'flex' : null"
        justify="space-between"
        class="flex-no-wrap"
      >
        <a-tooltip
          title=" Buscar por # de ticket, nombre de cliente o número de teléfono"
        >
          <a-input-search
            placeholder="Buscar"
            v-model="seeker"
            @keyup="onSearch"
            :size="inputs_size"
          />
        </a-tooltip>
        <div
          class="filter-options"
          :style="
            active_breakpoint.is_mobile
              ? 'margin-top: 0px; width: min-content'
              : '8px'
          "
        >
          <!-- Combo de Buzones -->
          <a-select
            v-if="
              loggedIn &&
              workspace_selector_mailbox_visible &&
              ticketFilters.mailbox
            "
            class="mailbox"
            v-model="ticketFilters.mailbox"
            label-in-value
            @change="handleChangeMailbox"
            :size="inputs_size"
          >
            <a-select-option
              v-for="mailbox in mailboxes"
              :key="mailbox.idMailbox"
              :value="mailbox.idMailbox"
              :title="`Buzón ${mailbox.title} (${mailbox.notifications} no leidos)`"
            >
              <a-icon
                v-if="mailbox.idMailbox === mailboxIdRemoteSelected"
                type="caret-right"
                style="color: #4894cd"
              />Buzón {{ mailbox.title }} ({{ mailbox.notifications }}
              no leidos)
            </a-select-option>
          </a-select>
          <a-tooltip
            :defaultVisible="defaultVisibleTooltip"
            placement="bottomRight"
          >
            <span v-if="showTooltipForFilters" slot="title">
              {{
                !!totalUnreadTickets
                  ? `${totalUnreadTickets} tickets no leídos`
                  : null
              }}
              <br v-if="!!totalUnreadTickets" />
              <!--Si hay filtros aplicados-->
              {{ !!hasFilters ? 'Fitros aplicados' : null }}
            </span>
            <a-badge
              :dot="showBadgeFilters"
              :numberStyle="{
                top: '5px',
                right: '5px',
                width: active_breakpoint.is_mobile ? '10px' : '8px',
                height: active_breakpoint.is_mobile ? '10px' : '8px',
              }"
            >
              <a-button
                shape="circle"
                class="filter-options__toggle"
                @click="handlerOpenFilters"
                :data-test="DATA_TEST.TICKET.LIST.OPEN_FILTER_BUTTON"
                :size="buttons_size"
              >
                <a-icon
                  type="filter"
                  :theme="hasFilters ? 'filled' : 'outlined'"
                />
              </a-button>
            </a-badge>
          </a-tooltip>
        </div>
      </a-row>
      <!-- Opciones masivas no aparecen cuando están en el buzón de finalizados -->
      <div class="massive" v-if="mailboxSelected && listTickets.length > 0">
        <div
          :style="{ borderBottom: '1px solid #E9E9E9' }"
          class="section-tickets-checkbox"
        >
          <!-- Combo de Seleccionar todo -->
          <a-checkbox
            v-if="!mailboxSelected.end && !profileRemote"
            @change="onCheckAllCards"
            :checked="allChecked.checked"
            :indeterminate="allChecked.indeterminate"
            class="chk-all"
          >
            Seleccionar todo
          </a-checkbox>
          <!-- Opciones masivas --><!-- Opciones masivas -->
          <div class="options" v-if="showMasiveOptions">
            <!-- Poner tags -->
            <popover-tags-checkboxes
              v-if="simple_tags.length > 0"
              :data-source="dataSourceTags"
              @onCheck="putTags"
              placement="right"
            >
              <!-- tooltip -->
              <a-tooltip placement="top" title="Añadir etiquetas">
                <a-icon
                  class="icon"
                  type="tag"
                  style="font-size: 18px; padding-right: 8px"
                  @click="(e) => e.preventDefault()"
                />
              </a-tooltip>
            </popover-tags-checkboxes>
            <!-- Transferir ticket -->
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <!-- tooltip -->
              <a-tooltip placement="top" title="Transferir ticket">
                <a-icon
                  class="icon"
                  type="swap"
                  style="font-size: 18px; padding-right: 8px"
                  :data-test="DATA_TEST.TICKET.TRANSFER.SHOW_OPTIONS_ICON"
                  @click="(e) => e.preventDefault()"
                />
              </a-tooltip>
              <a-menu
                slot="overlay"
                style="
                  max-height: 200px;
                  overflow-y: auto;
                  background-clip: initial;
                "
                class="scrollbar_basic"
              >
                <a-menu-item v-if="ticketsIdSelected.length == 1" key="0">
                  <a
                    @click="showModalTransfer"
                    :data-test="
                      DATA_TEST.TICKET.TRANSFER.SHOW_AGENT_SEARCH_MODAL
                    "
                    >Transferir a un agente</a
                  >
                </a-menu-item>
                <a-sub-menu key="sub1">
                  <span slot="title"><span>Transferir a cola</span></span>
                  <a-menu-item
                    v-for="(line, index) in filterCompanyLines"
                    :key="`item${index}`"
                    @click="confirmTransfer(line)"
                  >
                    {{ line.name }}
                  </a-menu-item>
                </a-sub-menu>
              </a-menu>
            </a-dropdown>
            <!-- Cambiar de buzon -->
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a-tooltip placement="top">
                <!-- tooltip -->
                <template slot="title"> Cambiar de buzón </template>
                <a-icon
                  class="icon"
                  :class="{ 'icon--disabled': isMovingMailbox }"
                  type="inbox"
                  style="font-size: 18px; padding-right: 8px"
                  @click="(e) => e.preventDefault()"
                  :disabled="isMovingMailbox"
                />
              </a-tooltip>
              <a-menu
                slot="overlay"
                style="
                  max-height: 200px;
                  overflow-y: auto;
                  background-clip: initial;
                "
                class="scrollbar_basic"
              >
                <a-menu-item
                  v-for="mailbox in filterMailBoxes"
                  :key="`Mailbox${mailbox.idMailbox}`"
                  :disabled="isMovingMailbox"
                  @click="handleMassiveMailbox(mailbox)"
                >
                  Cambiar a {{ mailbox.title }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <!-- Marcar como no leído -->
            <a-tooltip placement="top">
              <!-- tooltip -->
              <template slot="title"> Marcar como no leído </template>
              <a-icon
                @click="haddleMassiveUnread"
                class="icon-svg icon anticon anticon-inbox ant-dropdown-trigger"
                style="font-size: 18px"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.38471 2.99786L2.38113 1L1 2.44003L1.89483 3.3323C1.35841 3.68964 1.00511 4.29898 1.00511 4.99063V18.9401C1.00511 20.0406 1.89967 20.9328 3.00317 20.9328H19.5458L21.6189 23L23 21.56L4.40025 3.01335L4.41578 2.99786H4.38471ZM3.55792 4.99065H3.00366V7.03321L8.17536 9.59489L3.55792 4.99065ZM12.5323 13.9394L12.4944 13.9582L3.00366 9.25712V18.9401H17.5473L12.5323 13.9394ZM20.9862 7.52853L14.2845 10.8477L15.7744 12.3337L20.9862 9.7524V17.5318L22.916 19.4565C22.9602 19.2918 22.9838 19.1187 22.9838 18.9401V8.76305L22.9848 8.76255L22.9838 8.76055V4.99063C22.9838 3.89005 22.0892 2.99786 20.9857 2.99786H6.41376L8.41183 4.99065H20.9862V7.52853Z"
                  />
                </svg>
              </a-icon>
            </a-tooltip>
          </div>
          <span v-else class="regular-12 mrg-right-8 gray_7"
            >{{ pagination_tickets.total }} tickets en este buzón</span
          >
        </div>
      </div>
    </div>
    <div
      ref="list"
      id="list"
      class="list scrollbar_basic"
      @scroll="scrollBottomReached"
    >
      <div ref="list_content">
        <!--Si los tickets estan cargando-->
        <template v-if="loadListTickets">
          <CardTicket
            v-for="i in 8"
            :key="(i + 8).toString(36) + i"
            @check="onCheckCard"
            :loading="loadListTickets"
          />
        </template>
        <!--Si los ticketts ya terminaron de cargar-->
        <template v-else>
          <span v-if="listTickets.length === 0" class="empty"
            >No se han encontrado tickets de acuerdo a los filtros.</span
          >
          <CardTicket
            v-else
            v-for="ticket in listTickets.filter(
              (ticket) => ticket.mailboxId == ticketFilters.mailbox.key
            )"
            :key="`Ticket${ticket._id}`"
            :ticket="ticket"
            :ref="`cardTicket${ticket._id}`"
            @check="onCheckCard"
            :loading="false"
            :disabled="ticket.locked"
            :mailbox-selected="mailboxSelected"
            :page="pagination_tickets.page"
          />
        </template>
      </div>
    </div>
    <!-- Nuevo Mensaje -->
    <a-tooltip v-if="showButtonNewTicket" title="Nuevo mensaje">
      <a-button
        type="primary"
        @click="visible = true"
        shape="circle"
        :data-test="DATA_TEST.TICKET.CREATE_TICKET.OPEN_MODAL_BUTTON"
        class="section__main-button"
        style="height: 52px; width: 52px"
      >
        <a-icon type="message" style="font-size: 24px" />
      </a-button>
    </a-tooltip>
    <!--modal de nuevo mensaje-->
    <modal-new-message
      :visible="visible"
      @onClose="visible = false"
      @onOk="visible = true"
    />
    <!---------MODAL DE FILTROS---------->
    <a-modal
      class="filter-modal"
      v-model="filterTicketsModal.visible"
      title="Aplicar filtros"
      width="480px"
      centered
    >
      <!-- Filtro de Buzones -->
      <div class="filter-item">
        <label class="filter-item__label"> Buzones </label>
        <a-select
          class="filter-item__select"
          label-in-value
          v-model="mailboxSelected"
          :size="inputs_size"
        >
          <a-select-option
            v-for="mailbox in mailboxes"
            :key="mailbox.idMailbox"
            :value="mailbox.idMailbox"
            :title="`Buzón ${mailbox.title} (${mailbox.notifications} no leidos)`"
          >
            <a-icon
              v-if="mailbox.idMailbox === mailboxIdRemoteSelected"
              type="caret-right"
              style="color: #4894cd"
            />
            Buzón {{ mailbox.title }} ({{ mailbox.notifications }} no leidos)
          </a-select-option>
        </a-select>
      </div>
      <!-- Filtro de Colas -->
      <div class="filter-item">
        <label class="filter-item__label"> Colas </label>
        <a-select
          class="filter-item__select"
          label-in-value
          v-model="lineSelected"
          :size="inputs_size"
        >
          <a-select-option
            v-for="line in linesAsigned"
            :key="`Mailbox${line._id}`"
            :value="line._id"
            :title="line.default ? line.name : `Cola ${line.name}`"
          >
            {{ line.default ? line.name : `Cola ${line.name}` }}
          </a-select-option>
        </a-select>
      </div>
      <!-- Filtro de Canales -->
      <div class="filter-item">
        <label class="filter-item__label"> Canales </label>
        <a-select
          class="filter-item__select"
          v-model="channelSelected"
          @change="(value) => handleChangeChannel(value.key, true)"
          label-in-value
          :size="inputs_size"
        >
          <a-select-option
            v-for="channel in channelsForFilters"
            :key="channel.channel"
            :title="channel.name"
          >
            {{ channel.name }}
          </a-select-option>
        </a-select>
      </div>
      <!-- Filtro de IDs de canales -->
      <div class="filter-item">
        <label class="filter-item__label"> ID de canal </label>
        <a-select
          v-model="channelCompanyId"
          class="filter-item__select"
          placeholder="Escoge un Id"
          :size="inputs_size"
        >
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex" align="middle">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>{{
                simpleChannel.alias ? `(${simpleChannel.alias})` : ''
              }}</span>
            </a-row>
          </a-select-option>
        </a-select>
      </div>
      <!-- Filtro de estados de tickets leidos o sin leer -->
      <div class="filter-item">
        <label class="filter-item__label"> Leídos </label>
        <a-select
          class="filter-item__select"
          v-model="ticketStatusSelected"
          label-in-value
          :size="inputs_size"
        >
          <a-select-option
            v-for="status in ticketStatus"
            :key="`Status${status.key}`"
            :value="status.key"
            :title="status.title"
          >
            {{ status.title }}
          </a-select-option>
        </a-select>
      </div>
      <!-- Filtro de etiquetas -->
      <div class="filter-item">
        <label class="filter-item__label"> Etiquetas </label>
        <a-select
          class="filter-item__select"
          v-model="tagSelected"
          label-in-value
          :size="inputs_size"
        >
          <a-select-option
            v-for="tag in tagsFilter"
            :key="`Tags${tag.key}`"
            :value="tag.key"
            :title="tag.title"
          >
            {{ tag.title }}
          </a-select-option>
          <a-select-option value="none" title="Sin etiquetas">
            Sin etiquetas
          </a-select-option>
        </a-select>
      </div>
      <!-- Botones a aplicar Filtros -->
      <template slot="footer">
        <div style="display: flex; justify-content: flex-end">
          <a-button @click="handlerCancelFilter" :size="buttons_size"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            :data-test="DATA_TEST.TICKET.LIST.APPLY_FILTER_BUTTON"
            @click="handlerApplyFilter"
            :size="buttons_size"
            >Aplicar</a-button
          >
        </div>
      </template>
    </a-modal>
    <!--modal de lista de agentes-->
    <modal-transfer-agent
      :visible="visibleMoTransAgent"
      :ticket="ticketToTransfer"
      @onClose="visibleMoTransAgent = false"
      @onSuccess="successTranferToAgent"
      role="agent"
      :user-id-exclude="profile ? profile.userId : null"
    />
    <!--modal de lista de colas-->
    <modal-transfer-line
      :visible="modalTransferLine.visible"
      :data-source="filterCompanyLines"
      @onClose="modalTransferLine.visible = false"
      @onTransfer="confirmTransfer"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardTicket from '@/app/tickets/components/organisms/cards/CardTicket.vue'
import ModalNewMessage from '@/app/tickets/components/organisms/ModalNewMessage.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import { DATA_TEST } from '@/app/shared/utils/dataTest'
import ModalTransferAgent from '@/app/shared/components/modals/ModalTransferAgent'
import supportMixin from '@/app/shared/mixins/support'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import BannerSelection from '@/app/tickets/components/molecules/BannerSelection'
import ModalTransferLine from '@/app/shared/components/modals/ModalTransferLine'
import PopoverTagsCheckboxes from '@/app/shared/components/molecules/PopoverTagsCheckboxes'

export default {
  name: 'SectionTickets',
  components: {
    CardTicket,
    ModalNewMessage,
    ModalTransferAgent,
    SocialIcon,
    HeaderModule,
    BannerSelection,
    ModalTransferLine,
    PopoverTagsCheckboxes,
  },
  data: () => ({
    vari,
    DATA_TEST,
    mailboxSelected: null,
    lineSelected: { key: 'all', title: 'Todas' },
    channelSelected: {
      key: 'all',
      title: 'Todos',
    },
    seeker: '', // Valor del buscador
    allChecked: {
      checked: false, // Setea el checked al Seleccionar todo
      indeterminate: false, // Setea el cuadrado de indeterminado del Seleccionar todo
    },
    visible: false,
    top: 10,
    bottom: 10,
    visibleMoTransAgent: false,
    seekerAgent: '', // valor del buscador de agentes
    error_range_scroll_list: 6, // Margen de error para el calculo del tope del scroll
    lastPage: 0, // Para comprobar q no se mande 2 veces con la misma paginación
    filterTicketsModal: {
      visible: false,
    },
    ticketStatusSelected: { title: 'Todos', key: 'all' },
    dateFilterSelected: { title: 'Recientes', key: 'new' },
    tagSelected: { title: 'Todas', key: 'all' },
    ticketStatus: [
      { title: 'Todos', key: 'all' },
      { title: 'Leído', key: 'read' },
      { title: 'Sin leer', key: 'unread' },
    ],
    dateFilter: [
      { title: 'Recientes', key: 'new' },
      { title: 'Antiguos', key: 'old' },
    ],
    listTickets: [],
    ticketFilters: {
      status: { title: 'Todos', key: 'all' },
      order: { title: 'Recientes', key: 'new' },
      tag: { title: 'Todas', key: 'all' },
      line: { key: 'all', title: 'Todas' },
      channel: { key: 'all', title: 'Todos' },
      channelCompanyId: 'all',
      mailbox: null,
    },
    morePaginate: true,
    loadListTickets: true, // carga de lista de tickets
    localShowMassiveOptions: false, // mostrar opciones
    simpleChannelsFiltered: [{ _id: 'all', title: 'Todos los IDs' }],
    channelCompanyId: 'all',
    modalTransferLine: {
      visible: false,
    },
    defaultVisibleTooltip: true,
    isMovingMailbox: false,
  }),
  created() {
    this.loadInitialData()
  },
  mounted() {
    this.loadSizeForScroll()
    this.handleChangeChannel(this.ticketFilters.channel.key)
    // agrega un listener para dejar de mostrar el tooltip de filtros
    window.addEventListener('click', this.hideFilterTooltip)
  },
  beforeUpdate() {
    this.loadSizeForScroll()
  },
  destroyed() {
    // sin redirecionar
    this.cleanTicketSelected(false)
    // remueve el listener
    window.removeEventListener('click', this.hideFilterTooltip)
  },
  mixins: [attemptMixin, compareMixin, supportMixin],
  computed: {
    ...mapGetters([
      'tickets',
      'ticketsEnded',
      'ticketsIdSelected',
      'mailboxes',
      'client',
      'company',
      'ticketSelected',
      'profile',
      'profileRemote',
      'lines',
      'companyLines',
      'simple_tags',
      'loggedIn',
      'mailboxIdRemoteSelected',
      'agents',
      'assigned_templates',
      'have_assigned_templates',
      'workspace_selector_mailbox_visible',
      'buttons_size',
      'inputs_size',
      'active_breakpoint',
      'main_layout',
      'show_title_module',
      'banner_selection',
      'section_tickets',
      'navbar',
      'pagination_tickets',
      'total_assigned_tickets',
    ]),

    /**
     * Retorna el perfil del usuario q gestiona el ticket, puede ser para el visor o de manera propia
     * @returns {Object} Datos dl perfil
     */
    profileCurrent() {
      if (!this.profile) return null
      if (this.$route.meta.is_workspace) return this.profile
      else if (this.$route.meta.is_monitor) return this.profileRemote
      else return null
    },

    /** Lista las colas completas a las que se encuentra asignado el agente */
    linesAsigned() {
      if (!this.profileCurrent) return []
      let linesAsigned = []
      linesAsigned = this.lines.filter((line) =>
        this.profileCurrent.company.linesId.some(
          (lineId) => lineId === line._id
        )
      )
      linesAsigned.push({
        _id: 'all',
        name: 'Todas',
        default: true,
      })
      linesAsigned = linesAsigned.sort((a, b) =>
        a.default ? -1 : b.default ? 1 : 0
      )
      return linesAsigned
    },
    /** Los tags a filtrar */
    tagsFilter() {
      let tagsFilter = []
      tagsFilter = this.simple_tags.map((tag) => ({
        title: tag.title,
        key: tag._id,
      }))
      tagsFilter.push({
        key: 'all',
        title: 'Todas',
        default: true,
      })
      tagsFilter = tagsFilter.sort((a, b) =>
        a.default ? -1 : b.default ? 1 : 0
      )
      return tagsFilter
    },
    /** Lista los buzones que se pueden usar para mover los tickets */
    filterMailBoxes() {
      return this.mailboxes.filter((box) => !box.end)
    },
    /** Ticket seleccionado para la transferencia */
    ticketToTransfer() {
      if (!this.ticketsIdSelected.length) return
      const ticketSelected = this.tickets.find(
        (ticket) => ticket._id == this.ticketsIdSelected[0]
      )
      if (!ticketSelected) return
      return ticketSelected
    },
    /**
     * Filtra las colas con los canales comunes de los tickets seleccionados
     * @return {Object[]}
     */
    filterCompanyLines() {
      const tickets = []
      const channels = []
      const lines = []
      for (let ticket of this.tickets) {
        for (let id of this.ticketsIdSelected) {
          if (ticket._id === id) {
            tickets.push(ticket)
          }
        }
      }
      // encontrar los canales del ticket
      for (let ticket of tickets) {
        if (!channels.includes(ticket.channelCompanyId))
          channels.push(ticket.channelCompanyId)
      }
      // encontrar las colas que tienen los mismos canales del ticket seleccionado
      for (let line of this.companyLines) {
        // si los tickets seleccionados solo tienen un canal en comun
        if (channels.length === 1) {
          const include = line.channels_companies?.some(
            (channels_company) => channels_company._id === channels[0]
          )
          if (include) lines.push(line)
        } else {
          const compare = channels.every((channel) => {
            return line.channels_companies?.some(
              (channel_company) => channel_company._id === channel
            )
          })
          if (compare) lines.push(line)
        }
      }
      return lines
    },
    /**
     * Valida si debería mostrar el boton de nuevo ticket
     * @return {Boolean}
     */
    showButtonNewTicket() {
      const isActiveWBA =
        this.company &&
        this.company.channels.whatsapp &&
        this.company.channels.whatsapp.active
      return (
        this.$route.meta.is_workspace &&
        isActiveWBA &&
        this.have_assigned_templates
      )
    },
    /**
     * Titulo del modal de agentes
     * @returns {String}
     */
    titleModal() {
      if (!this.ticketsIdSelected && !this.ticketsIdSelected.length) return ''
      const ticketSelected = this.tickets.find(
        (ticket) => ticket._id == this.ticketsIdSelected[0]
      )
      if (!ticketSelected) return ''
      return `Agentes disponibles de la cola "${ticketSelected.company.lineName}"`
    },
    /**
     * Mostrar las opciones masivas
     * @returns {Boolean}
     */
    showMasiveOptions() {
      if (this.banner_selection.visible) return false
      return this.ticketsIdSelected.length !== 0 || this.localShowMassiveOptions
    },
    showBannerSelection() {
      return (
        this.banner_selection.visible && this.ticketsIdSelected.length !== 0
      )
    },
    /**
     * Verifica si se han aplicado filtro o no
     * @returns {Boolean} - no aplica si tiene los filtros generales
     */
    hasFilters() {
      const filters = Object.keys(this.ticketFilters).map((keyFilter) => {
        if (keyFilter !== 'channelCompanyId') {
          keyFilter = { ...this.ticketFilters[keyFilter] }
          keyFilter.filter =
            (keyFilter.key && keyFilter.key === 'new') || keyFilter.default
              ? 'all'
              : keyFilter.key

          if (!keyFilter.filter) keyFilter.filter = 'all'
        } else {
          keyFilter = {
            filter: this.ticketFilters[keyFilter],
          }
        }
        return keyFilter
      })
      return filters.some((filter) => filter.filter !== 'all')
    },
    /**
     * Etiquetas filtradas
     */
    dataSourceTags() {
      const ticketsSelected = this.tickets.filter((ticket) =>
        this.ticketsIdSelected.includes(ticket._id)
      )

      // filtra las etiquetas asignadas a todas las colas y a las colas de los tickets seleccionados
      const filteredTags = this.simple_tags.filter(
        (tag) =>
          tag.allLines ||
          ticketsSelected.every((ticket) =>
            tag.linesIds.includes(ticket.company.lineId)
          )
      )

      return filteredTags.map((tag) => {
        // encontrar si ya esta seleccionado o no
        const isChecked = ticketsSelected.every((ticket) =>
          ticket.settings.tags.some((ticketTag) => ticketTag.tagId === tag._id)
        )
        const newObj = { ...tag }
        newObj.checked = !!isChecked
        newObj.key = tag._id
        return newObj
      })
    },
    /**
     * @return {Number} - total de tickets no leidos
     */
    totalUnreadTickets() {
      let total = this.mailboxes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.notifications,
        0
      )
      return total
    },
    /**
     * @return {Boolean} - mostrar tooltip para filtros
     */
    showTooltipForFilters() {
      return (
        this.active_breakpoint.is_mobile &&
        (!!this.totalUnreadTickets || !!this.hasFilters) &&
        this.defaultVisibleTooltip
      )
    },
    /**
     * @return {Boolean} Mostrar el badge
     */
    showBadgeFilters() {
      return (
        this.hasFilters ||
        (this.active_breakpoint.is_mobile && !!this.totalUnreadTickets)
      )
    },
  },
  watch: {
    linesAsigned: function () {
      this.lineSelected = { key: 'all', title: 'Todas' }
    },
    tickets: {
      deep: true,
      handler: function () {
        this.listTickets = this.tickets
      },
    },
    ticketsEnded: function () {
      if (!this.ticketFilters.mailbox) return
      const mailbox = this.mailboxes.find(
        (el) => el.idMailbox == this.ticketFilters.mailbox.key
      )
      if (mailbox.end) this.listTickets = this.ticketsEnded
    },
    profileRemote() {
      this.getTotalAssignedTickets()
    },
  },
  methods: {
    ...mapMutations([
      'ADD_TICKETID_SELECTED',
      'QUIT_TICKETID_SELECTED',
      'CLEAN_TICKETSID_SELECTED',
      'SET_TICKETS',
      'SET_IS_LOADING_SELECTED_TICKET',
      'SET_MODAL_UPGRADE',
      'SET_CURRENT_MAILBOX',
    ]),
    ...mapActions([
      'selectTicket',
      'moveMassiveTicketsMailbox',
      'transferTickets',
      'cleanTicketsSelected',
      'saveTagTicket',
      'emitSelectedMailbox',
      'searchTickets',
      'listStatusTickets',
      'getFieldsToTicket',
      'cleanTicketSelected',
      'getTotalAssignedTickets',
      'getTotalUnreadTickets',
      'updateMassiveAsUnread',
    ]),
    /**
     * Carga los data inicial
     */
    loadInitialData() {
      this.intervalPromiseAttempt(() => {
        if (this.mailboxes.length === 0) throw 'mailboxes empty'
        const currentKeyMailbox = this.$route.query.mailbox
        const mailbox = currentKeyMailbox
          ? this.mailboxes.find(
              (el) => el.idMailbox.toString() === currentKeyMailbox
            )
          : this.mailboxes.find((el) => el.default)

        this.ticketFilters.mailbox = {
          title: `Buzón ${mailbox.title} (${mailbox.notifications} no leidos)`,
          key: mailbox.idMailbox.toString(),
          end: mailbox.end ? true : false,
          default: mailbox.default || false,
        }
        this.mailboxSelected = {
          title: `Buzón ${mailbox.title} (${mailbox.notifications} no leidos)`,
          key: mailbox.idMailbox.toString(),
          end: mailbox.end ? true : false,
          default: mailbox.default || false,
        }
        this.SET_CURRENT_MAILBOX({
          mailboxId: this.mailboxSelected.key,
          end: this.mailboxSelected.end,
        })
        this.onSearch()
        // Se ejecuta ultimo para esperar que se encuentren tickets
        // actualiza el contador de tickets asignados
        this.getTotalAssignedTickets()
        this.getTotalUnreadTickets(true)
      })
    },
    /**
     * Buscando y reseteando
     */
    onSearch() {
      this.loadListTickets = true
      this.morePaginate = false
      this.endWaitTyping(async () => {
        let idMailbox = this.ticketFilters.mailbox.key
        let seek = this.seeker.trim()
        const mailbox = this.mailboxes.find(
          (el) => el.idMailbox == this.ticketFilters.mailbox.key
        )
        let status = !mailbox.end ? 'active' : 'ended'
        await this.searchTickets({
          idMailbox,
          seek,
          status,
          page: 1,
          order: this.ticketFilters.order.key,
          line: this.ticketFilters.line.key,
          channel: this.ticketFilters.channel.key,
          channelCompanyId: this.ticketFilters.channelCompanyId,
          tag: this.ticketFilters.tag.key,
          read: this.ticketFilters.status.key,
        })
        this.loadListTickets = false
        this.morePaginate = true
      })
    },

    handlerOpenFilters() {
      this.dateFilterSelected = this.ticketFilters.order
      this.mailboxSelected = this.ticketFilters.mailbox
      this.lineSelected = this.ticketFilters.line
      this.channelSelected = this.ticketFilters.channel
      this.channelCompanyId = this.ticketFilters.channelCompanyId
      this.tagSelected = this.ticketFilters.tag
      this.ticketStatusSelected = this.ticketFilters.status
      this.filterTicketsModal.visible = true
      // filtrar todos los canales de empresa por canal
      this.handleChangeChannel(this.ticketFilters.channel.key, false)
    },
    handlerCancelFilter() {
      this.filterTicketsModal.visible = false
    },
    handlerApplyFilter() {
      this.ticketFilters.order = this.dateFilterSelected
      this.ticketFilters.line = this.lineSelected
      this.ticketFilters.channel = this.channelSelected
      this.ticketFilters.channelCompanyId = this.channelCompanyId
      this.ticketFilters.tag = this.tagSelected
      this.ticketFilters.status = this.ticketStatusSelected
      this.ticketFilters.mailbox = this.mailboxSelected
      const mailbox = this.mailboxes.find(
        (el) => el.idMailbox == this.ticketFilters.mailbox.key
      )
      this.mailboxSelected.end = mailbox.end
      // setea el buzon por defecto
      this.ticketFilters.mailbox.default = mailbox.default || false
      this.onSearch()
      this.filterTicketsModal.visible = false
    },

    /**
     * Ejecuta una acción cuando el scroll llega a la parte baja de la lista de tickets
     */
    async scrollBottomReached() {
      let list = this.$refs.list
      let list_content = this.$refs.list_content
      /** Altura del contenedor */
      const listHeight = list.offsetHeight
      /** Altura del contenido */
      const listHeightCalculated =
        list_content.scrollHeight -
        list.scrollTop +
        this.error_range_scroll_list
      /** Setear auto_scrollable si se está hasta 20px cerca al final de abajo */
      if (Math.ceil(listHeight) >= listHeightCalculated && this.morePaginate) {
        this.morePaginate = false
        const currentPage = this.pagination_tickets.page
        if (currentPage !== this.lastPage) {
          let idMailbox = this.ticketFilters.mailbox.key
          let seek = this.seeker.trim()
          const mailbox = this.mailboxes.find(
            (el) => el.idMailbox == this.ticketFilters.mailbox.key
          )
          let status = !mailbox.end ? 'active' : 'ended'
          await this.searchTickets({
            idMailbox,
            seek,
            status,
            page: currentPage + 1,
            order: this.ticketFilters.order.key,
            line: this.ticketFilters.line.key,
            channel: this.ticketFilters.channel.key,
            tag: this.ticketFilters.tag.key,
            read: this.ticketFilters.status.key,
          })
          this.lastPage = currentPage
          this.morePaginate = true
        }
      }
    },
    /**
     * Cambio en el combo de filtro de buzones
     * @param {String} value Id del buzón
     */
    async handleChangeMailbox(value) {
      const mailbox = this.mailboxes.find(
        (el) => el.idMailbox == this.ticketFilters.mailbox.key
      )
      this.mailboxSelected.end = mailbox.end
      this.mailboxSelected.key = value.key
      this.mailboxSelected.default = mailbox.default || false
      // setea el buzon por defecto a los filtros del ticket
      this.ticketFilters.mailbox.default = mailbox.default || false
      this.lastPage = 0
      this.SET_TICKETS({ status: 'active', tickets: [] })
      this.SET_TICKETS({ status: 'ended', tickets: [] })
      this.CLEAN_TICKETSID_SELECTED()
      this.allChecked.checked = false
      this.allChecked.indeterminate = false
      this.onSearch()
      this.emitSelectedMailbox(value)
      this.SET_CURRENT_MAILBOX({
        mailboxId: this.mailboxSelected.key,
        end: this.mailboxSelected.end,
      })
    },
    /** Cambio en el combo de filtro de colas */
    handleChangeLine() {
      this.CLEAN_TICKETSID_SELECTED()
      this.allChecked.checked = false
      this.allChecked.indeterminate = false
    },
    /** Setea una acción a todos los tickets actuales */
    onCheckAllCards(e) {
      if (e.target.checked === true) {
        this.listTickets.forEach((ticket) => {
          const child = this.$refs[`cardTicket${ticket._id}`]
          if (!child || child.length == 0) return
          else if (child[0].disabled) return
          child[0].checkTicket(true)
          this.onCheckCard(e, ticket)
        })
        // this.allChecked.checked = true
        // this.allChecked.indeterminate = false
      } else if (e.target.checked === false) {
        this.ticketsIdSelected.forEach((ticketId) => {
          const child = this.$refs[`cardTicket${ticketId}`]
          if (!child || child.length == 0) return
          child[0].checkTicket(false)
          this.onCheckCard(e, { _id: ticketId })
        })
      }
    },
    /**
     * Selecciona un ticket y los guarda o quita de la lista de tickets ids seleccionados
     * @param {event} e Evento del checkbox
     * @param {Object} ticket Objeto del ticket seleccionado
     */
    onCheckCard(e, ticket) {
      if (e.target.checked === true) {
        this.ADD_TICKETID_SELECTED(ticket._id)
        const ticketsNoDisabled = this.listTickets.filter(
          (ticket) => !ticket.locked
        )
        if (ticketsNoDisabled.length === this.ticketsIdSelected.length) {
          this.allChecked.indeterminate = false
          this.allChecked.checked = true
        } else {
          this.allChecked.indeterminate = true
        }
      } else if (e.target.checked === false) {
        this.QUIT_TICKETID_SELECTED(ticket._id)
        if (this.ticketsIdSelected.length == 0)
          this.allChecked.indeterminate = false
        else this.allChecked.indeterminate = true

        this.allChecked.checked = false
      }
    },
    /** Calcula el tamaño de la sección de la lista */
    loadSizeForScroll() {
      setTimeout(() => {
        const headerHeigth = this.$refs.header?.offsetHeight // Obtiene la altura del header
        const stripeHeigth = document.getElementById('stripeSection')
          ? document.getElementById('stripeSection')?.offsetHeight
          : 0 // obtiene la altura de la section stripe
        // const showNavbar = this.navbar.visible
        const totalHeight = this.active_breakpoint.is_mobile ? '100%' : '100vh'
        const margins = this.active_breakpoint.is_mobile ? '8px' : '26px'
        const list = document.getElementById('list')
        if (!list) return
        list.style.height = `calc(${totalHeight} - ${
          headerHeigth + stripeHeigth
        }px - ${margins})`
      }, 500)
    },
    /** Marcar como no leídos a los tickets seleccionados */
    async haddleMassiveUnread() {
      if (this.ticketsIdSelected.length === 0) return

      const key = 'unread-tickets'
      this.$message.loading({
        key,
        content: 'Marcando tickets como no leídos...',
      })

      const response = await this.updateMassiveAsUnread({
        ticketsIds: this.ticketsIdSelected,
        mailboxId: this.ticketFilters.mailbox.key,
      })
      if (response && response.success) {
        this.$message.success({
          key,
          content: 'Tus tickets se marcaron como no leídos',
        })
      } else {
        this.$message.error({
          key,
          content: 'No se pueden marcar tickets que ya están como no leidos',
        })
      }
      this.onCheckAllCards({ target: { checked: false } })
    },
    /**
     * Mueve a otro buzón a los tickets seleccionados
     * @param {Object} mailboxFinal
     * @param {String} mailboxFinal.title
     * @param {String} mailboxFinal.idMailbox
     * @param {Boolean} mailboxFinal.default
     * @param {Boolean} mailboxFinal.active
     * @param {Boolean} mailboxFinal.editable
     * @param {Number} mailboxFinal.notifications
     */
    async handleMassiveMailbox(mailboxFinal) {
      if (this.ticketsIdSelected.length === 0) return

      const key = 'change-mailbox'
      this.$message.loading({
        key,
        content: 'Cambiando los tickets a otro buzón...',
      })
      this.isMovingMailbox = true
      const response = await this.moveMassiveTicketsMailbox({
        ticketsIds: this.ticketsIdSelected,
        mailbox: mailboxFinal,
      })
      this.isMovingMailbox = false
      if (response.success) {
        this.$message.success({
          key,
          content:
            this.ticketsIdSelected.length > 1
              ? `Tus tickets se cambiaron al buzón ${mailboxFinal.title}`
              : `Tu ticket se cambió al buzón ${mailboxFinal.title}`,
        })
        this.onCheckAllCards({ target: { checked: false } }) // Limpia el checkbox de seleccionados
      }
    },
    /**
     * Prepara la transferencia de varios tickets, puede ser hacia un agente o una cola
     * @param {String} typeTransfer Tipo de transferencia [line, agent]
     * @param {Object} agent Objeto del agente de destino - opcional
     * @param {Object} line Objeto de la cola destino - opcional
     */
    async handleTransfer(typeTransfer, { agent, line }) {
      try {
        switch (typeTransfer) {
          case 'line': {
            const ticketsIdSelected = this.ticketsIdSelected
            await this.transferTickets({
              typeTransfer,
              ticketsIds: ticketsIdSelected,
              line,
              massive: true,
            })
            this.allChecked.checked = false
            this.allChecked.indeterminate = false
            // cierra el modal de transferia a colas
            this.modalTransferLine.visible = false
            break
          }
          case 'agent': {
            this.transferTickets({
              typeTransfer,
              ticketsIds: this.ticketsIdSelected,
              agent,
              massive: false,
            })
            this.allChecked.indeterminate = false
            this.handleOkModal()
            break
          }
        }
      } catch (error) {
        console.error('[handleTransfer]', error)
      }
    },
    /**
     * Confirmación de transferencia del ticket a una cola
     * @param {Object} line Objeto de la Cola destino
     */
    confirmTransfer(line) {
      const self = this
      const title =
        this.ticketsIdSelected.length === 1
          ? `¿Está seguro(a) de transferir el ticket a la cola "${line.name}"?`
          : `¿Está seguro(a) de transferir los ${this.ticketsIdSelected.length} tickets a la cola "${line.name}"?`
      this.$confirm({
        title: title,
        content:
          'Recuerda que los tickets serán derivados y repartidos en la cola seleccionada ',
        okText: 'Transferir',
        cancelText: 'Cancelar',
        centered: true,
        onOk() {
          return new Promise((res, rej) => {
            // Promesa para que aparezca el ícono de carga del dialog
            self
              .handleTransfer('line', { line })
              .then(() => res(true))
              .catch(() => rej(false))
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {},
      })
    },
    /**
     * Pone etiquetas a los tickets
     * @param {Boolean} checked
     * @param {String} key
     */
    async putTags(checked, key) {
      const tag = this.simple_tags.find((tag) => tag._id === key)

      if (this.ticketsIdSelected.length === 0) return
      for (let index = 0; index < this.ticketsIdSelected.length; index++) {
        await this.saveTagTicket({
          idTicket: this.ticketsIdSelected[index],
          tag,
          checked,
        })
      }
    },
    afterModalSteps() {
      this.$refs.stepsNewMessage.clean()
    },
    /* muestra el modal de transferencia a un agente */
    showModalTransfer() {
      this.visibleMoTransAgent = true
      this.localShowMassiveOptions = true
    },
    handleOkModal() {
      this.visibleMoTransAgent = false
    },
    /**
     * Transferencia de ticket exitosa
     */
    successTranferToAgent() {
      this.allChecked.indeterminate = false
      this.handleOkModal()
      this.localShowMassiveOptions = false
    },
    /**
     * Escucha el cambio de canales
     * @param {String} channel
     */
    handleChangeChannel(channel, setDefault = true) {
      //supportMixin
      const simpleChannels = this.filterSimpleByChannel({
        channel,
        defaultChannel: {
          _id: 'all',
          title: 'Todos los IDs',
        },
      })
      if (setDefault) this.channelCompanyId = simpleChannels[0]._id
      this.simpleChannelsFiltered = simpleChannels
    },
    /**
     * Oculta el tooltip para filtros
     */
    hideFilterTooltip() {
      this.defaultVisibleTooltip = false
    },
  },
}
</script>

<style lang="sass" scoped>
$margin_grids: 8px
$width-btn-filter: 32px

.gray_7
  color: $gray_7
.section-tickets
  position: relative
  // overflow-y: auto
  // height: 100vh
  .head
    position: sticky
    top: 0
    z-index: 10
    padding-top: 8px
    &.botpad
      padding: 8px 0 12px 0
  h5
    text-align: left
    margin-bottom: 12px
    color: $gray_dark_900
  .filter-options
    margin-top: $margin_grids
    width: 100%
    display: flex
    flex-direction: row
    .mailbox
      width: calc(100% - #{$width-btn-filter + $margin_grids}) !important
    .filter-options__toggle
      margin-left: #{$margin_grids}
      display: flex
      align-items: center
      justify-content: center
  .massive
    padding: 0 0 0 4%
    .chk-all
      height: 24px
      justify-items: center
    .fix-size
      width: 24px
      height: 24px
  &-checkbox
    padding-bottom: 4px
    padding-top: 12px
    display: flex
    justify-items: flex-start
    justify-content: space-between
  .icon
    color: $gray_dark_900
    &:hover
      color: #1890FF
    &:focus
      color: #1890FF
  .icon-svg
    cursor: pointer
    svg
      path:hover
        fill: #1890FF
  .card-ticket
    cursor: pointer
  .list
    // height: calc(100vh - #{$padding-heigth-inner * 2})
    overflow-y: auto
    .empty
      color: $gray_dark_200
      font-size: 14px
      margin: 36px 0 0 0
      position: absolute
      left: 0
      width: 100%
.agent-contain
  display: flex
  flex-direction: row
  align-items: center
  border-bottom: 1px solid #C4C4C4
  padding: 8px
  position: relative
  p
    margin-bottom: 0px
    margin-left: 12px
  button
    position: absolute
    right: 8px
.filter-item
  width: 100%
  display: flex
  font-weight: 600
  flex-direction: column
  margin-bottom: 1em
.filter-item__label
  font-size: 14px
  margin-bottom: 0.5em
.filter-modal
  line-height: 20px
  font-weight: bold
.section
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
  &__main-button
    z-index: 2
    box-shadow: 5px 5px 10px #BABABA
    position: absolute
    right: 15px
    bottom: 26px
</style>
