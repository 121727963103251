const MAX_READ_EXPIRED_BANNER = 2

const ALERTS_CHANNELS = {
  troubled: {
    type: 'warning',
    params: {
      page: '#PAGE',
      channel: '#CHANNEL',
      total_pages: '#TOTAL_PAGES',
    },
    message:
      'Tu página “#PAGE” de #CHANNEL se expiró recientemente.  Verifica su estado ahora.',
    message_plural:
      'Tienes “#TOTAL_PAGES" páginas de #CHANNEL expiradas recientemente.  Verifica su estado ahora.',
    link: {
      text: 'Ver todos los estados.',
      href: '#',
      style: {
        color: '#FAAD14',
      },
    },
  },
}

const BANNER_CHANNElS = {
  troubled: {
    params: {
      page: '#PAGE',
      channel: '#CHANNEL',
      total_pages: '#TOTAL_PAGES',
    },
    message:
      "<div><svg style='margin-right:8px' width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9.06251 14.0625C9.06251 14.3111 9.16128 14.5496 9.33709 14.7254C9.51291 14.9012 9.75137 15 10 15C10.2486 15 10.4871 14.9012 10.6629 14.7254C10.8387 14.5496 10.9375 14.3111 10.9375 14.0625C10.9375 13.8139 10.8387 13.5754 10.6629 13.3996C10.4871 13.2238 10.2486 13.125 10 13.125C9.75137 13.125 9.51291 13.2238 9.33709 13.3996C9.16128 13.5754 9.06251 13.8139 9.06251 14.0625ZM9.37501 8.125V11.7188C9.37501 11.8047 9.44532 11.875 9.53126 11.875H10.4688C10.5547 11.875 10.625 11.8047 10.625 11.7188V8.125C10.625 8.03906 10.5547 7.96875 10.4688 7.96875H9.53126C9.44532 7.96875 9.37501 8.03906 9.37501 8.125ZM18.666 16.7188L10.541 2.65625C10.4199 2.44727 10.2109 2.34375 10 2.34375C9.78907 2.34375 9.57813 2.44727 9.45899 2.65625L1.33399 16.7188C1.09376 17.1367 1.39454 17.6562 1.87501 17.6562H18.125C18.6055 17.6562 18.9063 17.1367 18.666 16.7188ZM3.36329 16.1738L10 4.68555L16.6367 16.1738H3.36329Z' fill='#51555A'/></svg><span style='color:#51555A' class='regular-14'><span class='heading-h7'>Tu página “#PAGE” de #CHANNEL ha expirado.</span> Vuelve a conectar para poder seguir respondiendo mensajes de esta cuenta.</div>",
    message_plural:
      "<div><svg style='margin-right:8px' width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9.06251 14.0625C9.06251 14.3111 9.16128 14.5496 9.33709 14.7254C9.51291 14.9012 9.75137 15 10 15C10.2486 15 10.4871 14.9012 10.6629 14.7254C10.8387 14.5496 10.9375 14.3111 10.9375 14.0625C10.9375 13.8139 10.8387 13.5754 10.6629 13.3996C10.4871 13.2238 10.2486 13.125 10 13.125C9.75137 13.125 9.51291 13.2238 9.33709 13.3996C9.16128 13.5754 9.06251 13.8139 9.06251 14.0625ZM9.37501 8.125V11.7188C9.37501 11.8047 9.44532 11.875 9.53126 11.875H10.4688C10.5547 11.875 10.625 11.8047 10.625 11.7188V8.125C10.625 8.03906 10.5547 7.96875 10.4688 7.96875H9.53126C9.44532 7.96875 9.37501 8.03906 9.37501 8.125ZM18.666 16.7188L10.541 2.65625C10.4199 2.44727 10.2109 2.34375 10 2.34375C9.78907 2.34375 9.57813 2.44727 9.45899 2.65625L1.33399 16.7188C1.09376 17.1367 1.39454 17.6562 1.87501 17.6562H18.125C18.6055 17.6562 18.9063 17.1367 18.666 16.7188ZM3.36329 16.1738L10 4.68555L16.6367 16.1738H3.36329Z' fill='#51555A'/></svg><span style='color:#51555A' class='regular-14'><span class='heading-h7'>Tienes #TOTAL_PAGES páginas de #CHANNEL que han expirado. </span> Vuelve a conectar para poder seguir respondiendo mensajes de estas cuentas.</div>",
    type: 'banner',
    bgColor: '#F8E479',
    color: '#51555A',
    redirect: {
      title: 'Ir a Mis Canales',
      route: {
        name: 'my-channels',
        query: {
          status: 'troubled',
        },
      },
    },
  },
}
/**
 * Reemplaza el mensaje de la alerta del canal de acuerdo al status
 * @param {Object} args
 * @param {String} [args.status = 'troubled']
 * @param {String} [args.type_message = 'message']
 * @param {String} [args.type_data = 'banner']
 * @param {Object} args.values
 * @param {String} args.values.key - nombre del parametro
 * @param {String} args.values.value - valor del parametro
 * @returns
 */
const replaceMessageAlertChannel = ({
  status = 'troubled',
  type_message = 'message',
  type_data = 'banner',
  values,
}) => {
  const data = type_data === 'alert' ? ALERTS_CHANNELS : BANNER_CHANNElS
  let message = data[status][type_message]
  for (let property in data[status].params) {
    if (values[property])
      message = message.replace(data[status].params[property], values[property])
  }
  return message
}

export {
  MAX_READ_EXPIRED_BANNER,
  ALERTS_CHANNELS,
  BANNER_CHANNElS,
  replaceMessageAlertChannel,
}
