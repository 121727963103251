<template>
  <div class="custom-drawer">
    <div class="custom-drawer__header">
      <a v-if="showBack" class="mrg-right-12" @click="$emit('onBack')">
        <a-icon type="arrow-left" />
        {{ textBack }}
      </a>
      <h6 class="semibold-16 text--left mrg-bottom-0">{{ title }}</h6>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseCustomDrawer',
  props: {
    title: { type: String, required: true, default: 'Title' },
    showBack: {
      type: Boolean,
      required: false,
      default: false,
    },
    textBack: {
      type: String,
      required: false,
      default: 'Regresar',
    },
  },
}
</script>

<style lang="sass" scoped>
.custom-drawer
  height: 100%
  min-height: 300px
  background-color: $gray_1
  border-radius: 4px
  @include xs
    padding-top: 12px
  @include sm
    padding-top: 12px
  @include md
    padding-top: 12px
  &__header
    padding: 12px 20px
    display: flex
    align-items: center
    @include xs
      display: none
    @include sm
      display: none
    @include md
      display: none
</style>
