const mutations = {
  /**
   * Setea datos para un banner de alerta
   * @param {*} state
   * @param {Object} args
   * @param {Boolean} args.visible
   * @param {String} args.textHTML
   * @param {String} args.bgColor
   * @param {String} args.color
   * @param {Object} args.redirect
   * @param {String} args.text
   * @param {String} args.type
   */
  SET_ALERT_BANNER_CHANNEL(
    state,
    { visible = true, textHTML, bgColor, color, redirect, text, type }
  ) {
    state.alert_banner_channel = {
      visible,
      textHTML,
      bgColor,
      color,
      redirect,
      text,
      type,
    }
  },
  /**
   * Resetea la alerta
   * @param {*} state
   */
  RESET_ALERT_BANNER_CHANNEL(state) {
    state.alert_banner_channel = { visible: false }
  },
}

export default mutations
