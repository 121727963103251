const getters = {
  schedules: (state) =>
    state.schedules.sort((a, b) =>
      a.scheduled_for > b.scheduled_for
        ? -1
        : a.scheduled_for < b.scheduled_for
        ? 1
        : 0
    ), // Ordena las agendas de manera descendiente
}

export default getters
