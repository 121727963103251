const getters = {
  getListSupervision: (state) => state.supervision,
  lastEvent: (state) => state.lastEvent,
  ticketIdRemoteSelected: (state) => state.ticketIdRemoteSelected,
  typingRemote: (state) => state.typingRemote,
  mailboxIdRemoteSelected: (state) => state.mailboxIdRemoteSelected,
  reloadTableSupervision: (state) => state.reloadTableSupervision,
}

export default getters
