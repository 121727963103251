<template>
  <div class="error" :class="{ 'full-absolute': stringError === true }">
    <div>
      <ImageError />
    </div>
    <div class="error-content">
      <!-- Número del Error -->
      <h1
        class="title-error color-gray-title mrg-bottom-0"
        :class="{ 'error-text': stringError }"
      >
        {{ titleError }}
      </h1>
      <!-- Descripción del error -->
      <p class="body-4 color-black-45">{{ textError }}</p>
      <a-button type="primary" @click="handleRedirect">Ir al inicio</a-button>
    </div>
  </div>
</template>
<script>
import ImageError from '@/app/shared/components/images/ImageError.vue'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'Error',
  props: {
    status: {
      // Se puede establecer un estado de error mediante prop - Prioridad 2
      type: Number,
      default: 404,
    },
  },
  components: {
    ImageError,
  },
  data: () => ({
    localError: null, // se puede establecer un estado mediante un seteo de localError desde vuex de ser necesario - Prioridad 1
    stringError: false, // LocalError no será un Number, sino un String
    titleError: null,
  }),
  computed: {
    textError() {
      if (!this.stringError) {
        let status = this.localError
        switch (status) {
          case 404:
            return 'UPS! No se pudo encontrar el módulo'
          case 429:
          case 500:
            return 'UPS! Se produjo un error inesperado'
          default:
            return ''
        }
      } else {
        return 'UPS! Se produjo un error inesperado'
      }
    },
  },
  mixins: [compareMixin],
  created() {
    // localError toma el valor de 404 establecido en el prop si esque no cuenta con un valor
    let queryError = this.$route.query.st // Estado del error mediante la ruta
    if (queryError) {
      if (this.isNumber(queryError)) {
        queryError = parseInt(queryError)
        this.localError = [429, 500].includes(queryError)
          ? queryError
          : this.status
        this.titleError = this.localError
        return
      } else if (['connection'].includes(queryError)) {
        this.stringError = true
        this.localError = queryError
        this.titleError = 'No hay conexión'
        return
      }
    }
    this.localError = this.localError ? this.localError : this.status
    this.titleError = this.localError
  },
  methods: {
    handleRedirect() {
      location.assign('/redirect')
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-bottom-0
  margin-bottom: 0px !important
.color-black-45
  color: rgba(0, 0, 0, 0.45)
.color-gray-title
  color: #434E59
.title-error
  font-style: normal
  font-weight: 500
  font-size: 96px
  line-height: 115%
  letter-spacing: 1px
.error
  height: 100vh
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  &.full-absolute
    position: absolute
  .error-content
    margin-left: 120px
    display: flex
    flex-direction: column
    align-items: flex-start
    .error-text
      font-size: 62px
</style>
