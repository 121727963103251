const initialState = () => ({
  profile: null,
  profileRemote: null,
  modalRemoteLogout: {
    statusModal: false,
    typeUser: null,
  },
  modalSessionExpired: {
    statusModal: false,
    typeUser: null,
  },
  internet: true,
  news: [],
  tutoModal: { visible: false },
  // modalStepsWBA: { visible: false }, // TODO: Borrar
  modal_integrate_channel: { visible: false, channel: '' },
  modalCreateChatbot: { visible: false },
  reloadNotification: { visible: true },
  notification_with_type: { visible: false }, // notificaciones con tipo especifico
  modal_state: { visible: false, type: null },
  profile_states: [
    {
      state: 'connected',
      value: 'Conectado',
      color: '#52c41a',
      _id: '0',
      show_when: { paused: true },
      action_when_select: ['save', 'show-notification-on-save'],
    },
    {
      state: 'bussy',
      value: 'Ocupado',
      color: '#F5222D',
      _id: '1',
      action_when_select: ['save', 'show-modal', 'start-timer'],
    },
    {
      state: 'services',
      value: 'En Servicios',
      color: '#1890FF',
      _id: '2',
      action_when_select: ['save', 'show-modal', 'start-timer'],
    },
    {
      state: 'training',
      value: 'En Capacitación',
      color: '#FA8C16',
      _id: '3',
      action_when_select: ['save', 'show-modal', 'start-timer'],
    },
    {
      state: 'paused',
      value: 'Indispuesto',
      color: '#13c2c2',
      _id: '4',
      show_when: { connected: true },
      action_when_select: [
        'save',
        'show-notification-on-save',
        'show-status-in-avatar',
        'start-timer',
      ],
    },
  ],
})

export default initialState
