<template>
  <div class="settings">
    <SettingsTemplate />
  </div>
</template>

<script>
import SettingsTemplate from '@/app/settings/components/templates/SettingsTemplate.vue'
export default {
  name: 'Settings',
  components: {
    SettingsTemplate,
  },
}
</script>
