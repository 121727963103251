import manageTicketsModule from './manageTicketsModule'

const modules = {
  manageTicketsModule,
}
const actions = {
  /** Resetear Todos los Submodulos del Módulo de Market */
  resetMoManageTickets(context) {
    context.commit('RESET_TICKETS_TO_MANAGE_MODULE')
  },
}
export default {
  modules,
  actions,
}
