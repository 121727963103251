import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Añadir un producto
   * @param {*} context
   * @param {Object} product
   * @param {String} product.name
   * @param {String} product.description
   * @param {String} product.brand
   * @param {String} product.price
   * @param {String} product.currency_code
   * @param {String} product.currency_name
   * @param {String} product.currency_symbol
   * @param {String} product.maximum_discount
   * @param {String} product.picture
   * @param {Array} product.customFields
   */
  async addProduct(context, product) {
    let formData = new FormData()
    // Recorre el objeto de productos
    for (const property in product) {
      let value = product[property]
      // Valida si la propiedad maximum_discount no tiene valor y le setea 0 por defecto
      if (property === 'maximum_discount' && !value) {
        value = 0
      }
      // Convierte el objeto de campos a String
      if (property === 'customFields') {
        value = JSON.stringify(value)
      }
      if (value) formData.append(property, value)
    }
    try {
      const url = `${vari.UHR}/companies-products`
      const response = await axios.post(url, formData)
      context.commit('ADD_COMPANY_PRODUCT', response.data.result)
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  /**
   * Actualizar un producto
   * @param {*} context
   * @param {Object} product
   * @param {String} product._id
   * @param {String} product.name
   * @param {String} product.description
   * @param {String} product.brand
   * @param {String} product.price
   * @param {String} product.currency_code
   * @param {String} product.currency_name
   * @param {String} product.currency_symbol
   * @param {String} product.maximum_discount
   * @param {String} product.picture
   * @param {Array} product.customFields
   */
  async updateProduct(context, product) {
    let formData = new FormData()
    for (const property in product) {
      let value = product[property]
      if (property === 'maximum_discount' && !value) {
        value = 0
      }
      if (property === 'customFields') {
        value = JSON.stringify(value)
      }
      if (value) formData.append(property, value)
    }
    try {
      const url = `${vari.UHR}/companies-products/${product._id}`
      const response = await axios.put(url, formData)
      context.commit('UPDATE_COMPANY_PRODUCT', {
        idProduct: response.data.result._id,
        newProduct: response.data.result,
      })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  /**
   * Eliminar un producto
   * @param {*} context
   * @param {String} idProduct
   */
  async deleteProduct(context, idProduct) {
    try {
      const url = `${vari.UHR}/companies-products/${idProduct}`
      const response = await axios.delete(url)
      context.commit('REMOVE_COMPANY_PRODUCT', idProduct)
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  /**
   * Obteniene las divisas por defecto de la empresa
   */
  async getDefaultCurrencies(context) {
    try {
      const url = `${vari.UHR}/companies-products/currencies`
      const response = await axios.get(url)
      context.commit('SET_DEFAULT_CURRENCIES', response.data.result)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtener todos los productos
   * @param {Object} products
   * @param {Number} products.page - numero de paginacion
   * @param {Number} products.limit - cantidad de datos
   * @param {String} products.search - valor de Busqueda
   * @param {String} products.status - enabled, disabled
   * @param {Array} products.customFields
   * @param {String, Date} customFields[].value - valor del campo customizado
   * @param {Boolean} products.next
   */
  async getCompanyProducts(
    context,
    { page, limit = 12, search, status, customFields, next }
  ) {
    const searchFormatted = search.replace(/#/gm, '')
    try {
      const params = {
        page,
        limit,
        search: searchFormatted,
        status,
        customFields: JSON.stringify(customFields),
        next,
      }
      const url = `${vari.UHR}/companies-products/`
      const response = await axios.get(url, { params })
      if (next)
        context.commit('ADD_COMPANY_PRODUCTS', response.data.result.data)
      else context.commit('SET_COMPANY_PRODUCTS', response.data.result.data)
      const pagination = { ...response.data.result }
      delete pagination.data
      context.commit('SET_PAGINATE_PRODUCTS', pagination)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Cambia de estado al producto
   * @param {Object} product
   * @param {String} product.idProduct
   * @param {Boolean} product.status
   */
  async updateStatusProduct(context, { idProduct, status }) {
    try {
      const url = `${vari.UHR}/companies-products/${idProduct}/on-off/`
      const response = await axios.put(url)
      context.commit('UPDATE_STATUS_PRODUCT', { idProduct, status })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },
  /**
   * Obtener los campos personalizados de productos
   * @param {Object} fields
   * @param {Number} fields.page
   * @param {Number} fields.limit
   */
  async getFieldsProducts(context, { page, limit = 11 }) {
    try {
      const params = {
        page,
        limit,
      }
      const url = `${vari.UHR}/companies-products/custom-fields`
      const response = await axios.get(url, { params })
      context.commit('SET_FIELDS_PRODUCT', response.data.result)
      return response.data
    } catch (error) {
      console.error(error)
      return error.data
    }
  },
  /** Resetear Todos el modulo de productos */
  resetMoProduct(context) {
    context.commit('RESET_PRODUCT_MODULE')
  },
}
export default actions
