<template>
  <a-modal
    :visible="visible"
    :body-style="{ padding: '0px' }"
    @cancel="handleCancel"
    :afterClose="handleCancel"
    centered
  >
    <div class="modal__title">
      <h4 class="semibold-20">{{ title }}</h4>
    </div>
    <custom-section-scroll
      :master-styles="{ height: '48em' }"
      :content-styles="{ padding: '0px 36px 36px 36px' }"
    >
      <p>Completa todos los campos requeridos.</p>
      <vars-form ref="var_form" />
    </custom-section-scroll>
    <basic-footer
      slot="footer"
      :ok-text="okText"
      :cancelText="cancelText"
      :okLoading="okButton.loading"
      :okDisabled="okButton.disabled"
      :cancelDisabled="cancelButton.disabled"
      @onCancel="handleCancel"
      @onOk="handleSubmit"
    />
  </a-modal>
</template>

<script>
import VarsForm from '@/app/chatbots/components/organisms/forms/VarsForm'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ModalVarChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    okText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      default: 'Cancelar',
      required: false,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  components: {
    VarsForm,
    BasicFooter,
    CustomSectionScroll,
  },
  data: () => ({
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      disabled: false,
    },
  }),
  methods: {
    /**
     * Cancela todos los cambios
     */
    handleCancel() {
      this.$emit('onClose')
      const varsForm = this.$refs.var_form
      varsForm.resetFields()
      this.okButton.loading = false
      this.okButton.disabled = false
      this.cancelButton.disabled = false
    },
    /**
     * Escucha el submit del formulario
     */
    async handleSubmit() {
      const varsForm = this.$refs.var_form
      this.okButton.loading = true
      this.okButton.disabled = true
      this.cancelButton.disabled = true

      varsForm.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        values.excludeWords = varsForm.localAvoidWords
        await this.onOk(values)
        this.okButton.loading = false
        this.okButton.disabled = false
        this.cancelButton.disabled = false
      })
    },
    /**
     * Setea valores a los campos del formulario
     * @param {Object} values
     * @param {String} values.name
     * @param {String[]} values.excludeWords
     * @param {String} values.customFieldId
     */
    setFieldsValue(values) {
      this.$nextTick(() => {
        const varsForm = this.$refs.var_form
        varsForm.setFieldsValue(values)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__title
    padding: 36px 36px 0px 36px
</style>
