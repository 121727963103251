<template>
  <a-modal
    width="300px"
    centered
    :closable="false"
    :visible="visible"
    @cancel="onClose"
    :afterClose="handleReset"
    :maskClosable="false"
  >
    <h4>Ingresa tu código de seguridad (CVV). Para procesar tu pago.</h4>
    <a-input-password v-model="securityCode" placeholder="CVV" :maxLenght="4" />
    <template slot="footer">
      <a-button key="back" @click="onClose"> Cancelar </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Pagar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalCvv',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    securityCode: null,
  }),
  methods: {
    /**
     * Escucha el submit y emite el evento con el codigo de seguridad
     */
    handleSubmit() {
      if (!this.securityCode) return
      this.$emit('onOk', this.securityCode)
    },
    /**Resetea todos los valores*/
    handleReset() {
      this.securityCode = null
    },
    /**Escucha cuando el modal de se cierra y emite hacia el padre*/
    onClose() {
      this.$emit('onClose')
    },
  },
}
</script>

<style scoped></style>
