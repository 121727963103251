<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-row type="flex" align="middle" justify="space-between">
        <a-input
          v-if="config.type == 'text'"
          style="max-width: 80%; min-width: 80%"
          :value="value"
          @change="handleChange"
          @pressEnter="check"
        />
        <a-select
          class="select"
          v-else-if="config.type == 'dropdown'"
          style="max-width: 80%; min-width: 80%"
          v-model="value"
        >
          <a-select-option
            v-for="option in config.options"
            :key="option.idOption"
            :value="option.idOption"
            :title="option.name"
          >
            {{ option.name }}
          </a-select-option>
        </a-select>
        <a-date-picker
          v-else-if="config.type == 'date'"
          v-model="value"
          style="max-width: 80%; min-width: 80%"
          placeholder="2020-05-25"
        />
        <a-select
          v-else
          class="select"
          style="max-width: 80%; min-width: 80%"
          mode="multiple"
          v-model="value"
        >
          <a-select-option
            v-for="tag in config.tags"
            :key="`Tags${tag.key}`"
            :value="tag.key"
            :title="tag.title"
          >
            {{ tag.title }}
          </a-select-option>
        </a-select>
        <a-row style="width: 18%" type="flex" justify="space-around">
          <a-icon
            type="close"
            style="color: #f5222d"
            class="editable-cell-icon-check"
            @click="editable = false"
          />
          <a-icon
            type="check"
            style="color: #389e0d"
            class="editable-cell-icon-check"
            @click="check"
          />
        </a-row>
      </a-row>
    </div>
    <div v-else class="editable-cell-text-wrapper">
      <a-row type="flex" justify="space-between">
        <p v-if="config.type == 'text'" class="content mrg-bottom-0">
          {{ text || ' ' }}
        </p>
        <p v-else-if="config.type == 'dropdown'" class="content mrg-bottom-0">
          {{ text ? text : ' ' }}
        </p>
        <p v-else-if="config.type == 'date'" class="content mrg-bottom-0">
          {{ text ? formatDate(text) : ' ' }}
        </p>
        <a-row v-else type="flex">
          <popover-tags-list
            v-if="!!text.length"
            :data-source="text"
            placement="top"
            trigger="hover"
          >
            <a-row type="flex">
              <div
                v-for="tag in text"
                :key="`tag${tag.tagId}`"
                class="tag"
                :style="`background-color: ${tag.color}`"
              ></div>
            </a-row>
          </popover-tags-list>
        </a-row>
      </a-row>
    </div>
  </div>
</template>

<script>
import PopoverTagsList from '@/app/shared/components/molecules/PopoverTagsList'
import moment from 'moment'

export default {
  props: {
    customField: [String, Array],
    ticket: {
      type: Object,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  components: {
    PopoverTagsList,
  },
  data() {
    return {
      value: '',
      text: '',
      type: '',
      editable: false,
    }
  },
  created() {
    this.setValues()
  },
  watch: {
    customField: {
      deep: true,
      handler() {
        this.setValues()
      },
    },
  },
  methods: {
    setValues() {
      if (!this.customField) {
        return
      }
      switch (this.config.type) {
        case 'text':
          this.text = this.customField
          this.value = this.customField
          break
        case 'dropdown':
          this.value = this.customField
          this.text = this.value.map((option) => option.name).join(', ')
          break
        case 'date':
          this.text = this.customField
          this.value = this.customField
          break
        default:
          this.text = this.customField
          this.value = []
          this.customField.forEach((tag) => {
            if (
              this.config.tags.some((companyTag) => companyTag.key == tag.tagId)
            )
              this.value.push(tag.tagId)
            else this.value.push(tag.title)
          })

          break
      }
    },
    handleChange(e) {
      const value = e.target.value
      this.value = value
    },
    check() {
      this.editable = false
      switch (this.config.type) {
        case 'text':
          this.text = this.value
          break
        case 'dropdown':
          this.text = this.config.options.find(
            (option) => option.idOption == this.value
          )
          this.text = this.text ? this.text.name : ''
          break
        case 'date':
          this.text = this.value

          break
        default: {
          const selectedTags = this.value.map((tag) => {
            let founded = this.config.tags.find(
              (companyTag) => companyTag.key == tag
            )
            if (!founded) {
              founded = this.text.find((showTag) => showTag.title == tag)
            }
            return founded
          })
          this.text = selectedTags
          this.$emit('updated', this.ticket._id, this.text)
          return
        }
      }
      const field = {}
      field[this.config._id] = this.value
      this.$emit('updated', this.ticket, field)
    },
    edit() {
      this.editable = true
    },
    formatDate(created_at) {
      const formatedDate = moment(created_at).format('MMMM DD, YYYY')
      return formatedDate[0].toUpperCase() + formatedDate.slice(1)
    },
  },
}
</script>

<style lang="sass" scoped>
.tag
  max-width: 24px
  min-width: 24px
  height: 8px
  border-radius: 8px
  margin-right: 4px
  margin-top: 4px
  flex-grow: 1
.select
  height: 32px
  overflow: scroll
.content
  width: 100%
</style>
