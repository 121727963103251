<template>
  <full-template>
    <a-row class="full-height">
      <a-col
        class="full-height section__col"
        :span="grid_responsive.section_tickets"
      >
        <SectionTickets />
      </a-col>
      <a-col
        v-if="!!grid_responsive.view_ticket"
        :span="grid_responsive.view_ticket"
        class="full-height section__col"
      >
        <router-view></router-view>
      </a-col>
    </a-row>
    <ModalLogin
      :visible="visibleModal"
      typeModal="logout"
      @handleNoVisible="handleNoVisible"
    />
  </full-template>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import SectionTickets from '@/app/tickets/components/organisms/SectionTickets.vue'
import ModalLogin from '@/app/shared/components/modals/ModalLogin'
import attemptMixin from '@/app/shared/mixins/attempt'
import FullTemplate from '@/app/shared/components/templates/FullTemplate'

export default {
  name: 'TicketsTemplate',
  components: {
    SectionTickets,
    ModalLogin,
    FullTemplate,
  },
  data: () => ({
    visibleModal: false,
    showList: false,
  }),
  beforeDestroy() {
    this.SET_IS_LOADING_OLD_TICKETS(false)
    this.SET_IS_LOADING_SELECTED_TICKET(false)
  },
  mixins: [attemptMixin],
  computed: {
    ...mapGetters([
      'ticketSelected',
      'client',
      'isLoadingSelectedTicket',
      'company',
      'main_layout',
    ]),

    /**
     * Grillas responsive
     */
    grid_responsive() {
      const params = this.$route.params

      if (this.main_layout.type === 'one-view')
        return {
          section_tickets:
            params.ticketId && params.ticketId !== 'default' ? 0 : 24,
          view_ticket:
            params.ticketId && params.ticketId !== 'default' ? 24 : 0,
        }
      else
        return {
          section_tickets: 6,
          view_ticket: 18,
        }
    },
  },
  methods: {
    ...mapMutations([
      'SET_IS_LOADING_OLD_TICKETS',
      'SET_IS_LOADING_SELECTED_TICKET',
    ]),

    // ocultar modal
    handleNoVisible() {
      this.visibleModal = false
    },
    // mostrar modal
    handleModal() {
      this.visibleModal = true
    },
  },
}
</script>

<style lang="sass" scoped>
.tickets-template
  padding: $padding-heigth-inner
.section__col
  padding-right: 12px
  &:last-child
    padding-right: 0px
</style>
