import Vue from 'vue'

const actions = {
  /**
   * Ejecuta plugins de analytics obteniendo datos del usuario logueado
   * @param {vuex} context
   * @param {Object} args
   * @param {String} args.eventName Nombre con el que se mandará al evento
   * @param {Object} [args.extraData={}] Datos adicionales para mandar por el evento
   */
  async setAnalytics(context, { eventName, extraData = {} }) {
    try {
      if (
        !context.getters.profile ||
        !context.getters.company ||
        !context.getters.companyPricing
      ) {
        return
      }
      const platform = process.env.VUE_APP_NAME
      const license = context.getters.companyPricing.plan.key
      const data = {
        userId: context.getters.profile.userId,
        email: context.getters.profile.email,
        username: context.getters.profile.username,
        user_type: `${context.getters.profile.type}_${platform.toLowerCase()}`,
        name_user: context.getters.profile.names,
        lastname_user: context.getters.profile.surnames,
        gender_user: context.getters.profile.gender || '',
        license_type: `${license}_license_${platform.toLowerCase()}`,
        source_registration: context.getters.company.vias,
        country_registration: context.getters.company.country?.name,
        year_registration: new Date(
          context.getters.company.created_at
        ).toLocaleDateString('es-MX', { year: 'numeric' }),
        month_registration: new Date(
          context.getters.company.created_at
        ).toLocaleDateString('es-MX', { month: 'long' }),
        date_registration: new Date(
          context.getters.company.created_at
        ).toLocaleDateString('es-MX'),
        ...extraData,
      }
      if (context.getters.company.operation.length) {
        const operationsFormatted = context.getters.company.operation.map(
          (op) => op.replace('tele_', '').toLowerCase()
        )
        const operations = {}
        for (let op of operationsFormatted) {
          operations[`operation_${op}`] = 1
        }
        Object.assign(data, operations)
      }
      const dataLayer = {
        event: eventName,
        platform: process.env.VUE_APP_NAME,
        ...data,
      }
      console.log('setAnalytics -> dataLayer', dataLayer)
      Vue.gtm.push(dataLayer)
    } catch (error) {
      console.error(error)
    }
  },

  /**
   * Envía los datos de registro de una nueva empresa a Google Tag Manager
   * @param {*} context
   * @param {Object} args
   * @param {String} args.license
   * @param {Object} args.user
   * @param {Object} args.company
   */
  async pushNewCompany(context, { license, user, company }) {
    try {
      const platform = process.env.VUE_APP_NAME
      const operationsFormatted = company.operation.map((op) =>
        op.replace('tele_', '').toLowerCase()
      )
      const dataLayer = {
        event: `register-${license}-company`,
        platform: platform,
        license_type: `${license}_license_${platform.toLowerCase()}`,
        user_type: `${user.type}_${platform.toLowerCase()}`,
        name_user: user.names,
        lastname_user: user.surnames,
        email_user: user.email,
        company_user: company.name,
        country_user: company.country,
        source_registration: company.vias,
        month_registration: new Date().toLocaleDateString('es-MX', {
          month: 'long',
        }),
        year_registration: new Date().toLocaleDateString('es-MX', {
          year: 'numeric',
        }),
        id_user: user.userId || 'only_email_updated',
      }
      const operations = {}
      for (let op of operationsFormatted) {
        operations[`operation_${op}`] = 1
      }
      Object.assign(dataLayer, operations)
      // console.log('pushNewCompany', dataLayer)
      Vue.gtm.push(dataLayer)
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Envía los datos de la cuenta empresa activada a Google Tag Manager
   * @param {*} context
   * @param {Object} args
   * @param {String} args.license
   * @param {Object} args.user
   */
  async pushActiveCompany(context, { user }) {
    try {
      const platform = process.env.VUE_APP_NAME
      const dataLayer = {
        event: 'activated-company',
        platform: platform,
        // license_type: `${license}_license_${platform.toLowerCase()}`,
        email_user: user.email,
        month_activation: new Date().toLocaleDateString('es-MX', {
          month: 'long',
        }),
        year_activation: new Date().toLocaleDateString('es-MX', {
          year: 'numeric',
        }),
        // id_user: user.userId,
      }
      // console.log('pushActiveCompany', dataLayer)
      Vue.gtm.push(dataLayer)
    } catch (error) {
      console.error(error)
    }
  },
}

export default { actions }
